import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/marketingActions';
import axios from 'axios';
import NotificationManager from '../../common/components/notification';
import EditOrViewEventComponent from './editOrViewEventComponent';
import PropTypes from 'prop-types';
import MarketingServices from '../services/marketing-services';

class EditOrViewEvent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            addEventData: '',
            modalName: '',
            photographyModalOpen: false,
            layoutModalOpen: false,
            copyWriteModalOpen: false,
            briefModalOpen: false,
            oActiveModal: {},
            oEditItems: [
                {
                    modName: "briefModal",
                    modState: "briefModalOpen"
                },
                {
                    modName: "copyWriteModal",
                    modState: "copyWriteModalOpen"
                },
                {
                    modName: "layoutModal",
                    modState: "layoutModalOpen"
                },
                {
                    modName: "photographyModal",
                    modState: "photographyModalOpen"
                },
                {
                    modName: "productModal",
                    modState: ""
                }
            ],
            activeEventID: "",
            saveSubmitDisable: true,
            briefOrCopySavedisable: true,
            imageArr: [],
            inputFile: [],
            inputFileName: "",
            mainImageSrc: "",
            isTaskReview: false,
            showReject: false,
            rejectText: "",
            rejectComments: "",
            eventStateData: {},
            noTaskContent: false,
            viewMode: false,
            isCancelModalForPhotoOpen: false,
            isCancelModalForRejectOpen: false,
            rejectDisable: true,
            user: {}
        };

        this.handleDetailsModal = this.handleDetailsModal.bind(this);
        this.closeDetailsModal = this.closeDetailsModal.bind(this);
        this.closeBriefModal = this.closeBriefModal.bind(this);
        this.saveEventBrief = this.saveEventBrief.bind(this);
        this.closeCopyWriteModal = this.closeCopyWriteModal.bind(this);
        this.saveCopywrite = this.saveCopywrite.bind(this);
        this.closeLayoutModal = this.closeLayoutModal.bind(this);
        this.saveLayout = this.saveLayout.bind(this);
        this.submitLayout = this.submitLayout.bind(this);
        this.closePhotographyModal = this.closePhotographyModal.bind(this);
        this.savePhotography = this.savePhotography.bind(this);
        this.submitPhotography = this.submitPhotography.bind(this);
        this.closeBriefModal = this.closeBriefModal.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.changeValueHolder = this.changeValueHolder.bind(this);
        this.handleLayoutSubmit = this.handleLayoutSubmit.bind(this);
        this.updateMainViewer = this.updateMainViewer.bind(this);
        this.enableReject = this.enableReject.bind(this);
        this.approveEventTask = this.approveEventTask.bind(this);
        this.handleRejectField = this.handleRejectField.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getTodayDate = this.getTodayDate.bind(this);
        this.resetTaskModalStates = this.resetTaskModalStates.bind(this);
        this.closeLayoutModalFunc = this.closeLayoutModalFunc.bind(this);
        this.closePhotographyModalFunc = this.closePhotographyModalFunc.bind(this);
        this.closePhotoorLayoutModal = this.closePhotoorLayoutModal.bind(this);
        this.closeBriefModalFunc = this.closeBriefModalFunc.bind(this);
        this.closeCopyWriteModalFunc = this.closeCopyWriteModalFunc.bind(this);
        this.closeRejectModal = this.closeRejectModal.bind(this);
    }

    componentDidMount() {
        console.log('editOrViewEvent componentDidMount');
    }

    componentDidUpdate(prevProps, prevState) {
        let _this = this;
        var dateFormat = "mm/dd/yy";
        var dateToday = _this.getTodayDate();

        var dueDate = $(".datepicker.editduedate").datepicker({
            defaultDate: "+1w",
            changeMonth: true,
            changeYear: true,
            numberOfMonths: 1,
            yearRange: '(new Date).getFullYear():+10',
            dateFormat: dateFormat,
            minDate: dateToday,
            onSelect: function (dateText) {
                var value = this.value,
                    type = this.getAttribute('data-type');

                let { eventStateData } = _this.state;
                let newFormData = eventStateData;

                if (type === 'event') {
                    var key = this.getAttribute('data-name');

                    newFormData[key] = value;
                }

                if (type === 'task') {
                    var key = this.getAttribute('data-name');
                    var arrayIndex = this.getAttribute('data-index');

                    newFormData['tasks'][arrayIndex][key] = value;
                }

                _this.setState({ eventStateData: newFormData });
                _this.props.editScreenModified();
            }
        });

        var eventDate = $(".datepicker.editeventdate").datepicker({
            defaultDate: "+1w",
            changeMonth: true,
            changeYear: true,
            numberOfMonths: 1,
            yearRange: '(new Date).getFullYear():+10',
            dateFormat: dateFormat,
            minDate: dateToday,
            onSelect: function (dateText) {
                var value = this.value,
                    type = this.getAttribute('data-type');

                let { eventStateData } = _this.state;
                let newFormData = eventStateData;

                if (type === 'event') {
                    var key = this.getAttribute('data-name');

                    newFormData[key] = value;
                }

                if (type === 'task') {
                    var key = this.getAttribute('data-name');
                    var arrayIndex = this.getAttribute('data-index');

                    newFormData['tasks'][arrayIndex][key] = value;
                }

                _this.setState({ eventStateData: newFormData });
                _this.props.editScreenModified();

                eventEndDate.datepicker("option", "minDate", _this.getDate(this));
            }
        });

        var eventEndDate = $(".datepicker.editeventenddate").datepicker({
            defaultDate: "+1w",
            changeMonth: true,
            changeYear: true,
            numberOfMonths: 1,
            yearRange: '(new Date).getFullYear():+10',
            dateFormat: dateFormat,
            minDate: dateToday,
            onSelect: function (dateText) {
                var value = this.value,
                    type = this.getAttribute('data-type');

                let { eventStateData } = _this.state;
                let newFormData = eventStateData;

                if (type === 'event') {
                    var key = this.getAttribute('data-name');

                    newFormData[key] = value;
                }

                if (type === 'task') {
                    var key = this.getAttribute('data-name');
                    var arrayIndex = this.getAttribute('data-index');

                    newFormData['tasks'][arrayIndex][key] = value;
                }

                _this.setState({ eventStateData: newFormData });
                _this.props.editScreenModified();

                eventDate.datepicker("option", "maxDate", _this.getDate(this));
            }
        });

        //Initializing WYSIWYG editor
        if (_this.state.oActiveModal.modName == "copyWriteModal") {
            if (prevState.oActiveModal.modState !== _this.state.oActiveModal.modState) {
                let _this = this;

                $('.jqte-test').jqte({
                    change: function () {
                        let __this = _this;
                        let value = $('.event-copywrite').val();

                        if (value !== "" && value.trim() !== "") {
                            __this.setState({
                                saveSubmitDisable: false,
                                briefOrCopySavedisable: false
                            });
                        } else {
                            __this.setState({
                                saveSubmitDisable: true,
                                briefOrCopySavedisable: true
                            });
                        }
                    }
                });
            }
        }
    }

    componentWillReceiveProps(props) {
        if ('eventId' in props.eventStateData && this.props.isOpen) {
            let { eventStateData } = this.state;

            let newFormData = eventStateData;
            newFormData = props.eventStateData;

            if ('eventId' in this.state.eventStateData) {
                if (props.eventStateData.eventId !== this.state.eventStateData.eventId) {
                    this.setState({ eventStateData: newFormData });
                }
            } else {
                this.setState({ eventStateData: newFormData });
            }
        } else {
            this.setState({ eventStateData: {} });
        }
    }

    //details page button handling
    handleDetailsModal(e) {
        e.preventDefault();

        if ($(e.target).text() === '+Add Product Selection') {
            location.href = '/product';

            return false;
        }

        let _this = this;
        let ind = $(e.target).attr('data-val');
        let dataView = $(e.target).attr('data-view');
        let bViewMode = false;

        if (dataView == "false") {
            bViewMode = false;
        } else if (dataView == "true") {
            bViewMode = true;
        } else {
            bViewMode = false;
        }

        let thismodal = _this.state.oEditItems[ind];

        if (thismodal.modName != 'productModal') {
            _this.setState({
                modalName: thismodal.modName,
                [thismodal.modState]: true,
                oActiveModal: thismodal,
                viewMode: bViewMode
            });
        }

        if (e.target.classList.contains("reviewButton")) {
            _this.setState({ isTaskReview: true });
        }

        //Load modal data according to the event type
        let eventID = $("#edittaskId" + ind).val();

        if (thismodal.modName == "briefModal") {
            _this.loadBriefData(eventID, thismodal.modName);
            _this.setState({ activeEventID: eventID });
        } else if (thismodal.modName == "copyWriteModal") {
            _this.loadBriefData(eventID, thismodal.modName);
            _this.setState({ activeEventID: eventID });
        } else if (thismodal.modName == "layoutModal") {
            _this.loadLayoutData(eventID);
            _this.setState({ activeEventID: eventID });
        } else if (thismodal.modName == "photographyModal") {
            _this.loadLayoutData(eventID);
            _this.setState({ activeEventID: eventID });
        }
    }

    loadBriefData(id, modal) {
        let _this = this;

        let postdata = {};
        postdata.id = id;

        MarketingServices.getEventBrief(postdata).then((response) => {
            let res = response;

            if (res.responseMessage === "success") {
                _this.props.eventTaskData(res.data[0]);

                if (res.data[0].taskData) {
                    if (modal == "briefModal") {
                        $('.value-holder').val(res.data[0].taskData);
                        let tempVal = $('.value-holder').val();

                        if (tempVal !== '') {
                            _this.setState({ saveSubmitDisable: false });
                        }

                        _this.setState({ briefOrCopySavedisable: true, rejectDisable: true });
                    } else if (modal == "copyWriteModal") {
                        $(".event-copywrite").jqteVal(res.data[0].taskData);

                        if (res.data[0].taskData) {
                            _this.setState({ saveSubmitDisable: false });
                        }

                        _this.setState({ briefOrCopySavedisable: true, rejectDisable: true });
                    }
                } else {
                    if (_this.state.viewMode) {
                        _this.setState({ noTaskContent: false });
                    } else {
                        _this.setState({ noTaskContent: true });
                    }
                }

                //  new approach in event details page popups
                if (!_this.state.noTaskContent) {
                    if (res.data[0].rejectComments) {
                        _this.setState({ rejectText: res.data[0].rejectComments });
                    } else {
                        _this.setState({ rejectText: '' });
                    }

                    if (res.data[0].approvalStatus == "Approved") {
                        $('.status').text(res.data[0].approvalStatus + ' by ' + res.data[0].userId + ' on ' + res.data[0].approvalDate);
                        $('.modal-container').find('.gray-button:not([data-action="cancel"])').attr('disabled', true);
                        $('.value-holder').attr('disabled', true);

                        if (modal == "copyWriteModal") {
                            $('.modal-container').find('.jqte_editor').attr('contenteditable', "false");
                        }
                    } else if (res.data[0].approvalStatus == "Rejected") {
                        if (res.data[0].submittedStatus == "Save") {
                            if (_this.state.isTaskReview) {
                                $('.value-holder').attr('disabled', true);

                                if (modal == "copyWriteModal") {
                                    $('.modal-container').find('.jqte_editor').attr('contenteditable', "false");
                                }

                                $('.gray-button[data-action="reject"]').trigger('click');
                                $('.reject-field').val(res.data[0].rejectComments).attr('disabled', true);
                                $('.modal-container').find('.gray-button:not([data-action="cancel"])').attr('disabled', true);
                            } else {
                                $('.status').html(res.data[0].approvalStatus + ' by ' + res.data[0].userId + ' on ' + res.data[0].approvalDate + '<br/><br/>' + "Review Notes: " + res.data[0].rejectComments);
                            }
                        } else if (res.data[0].submittedStatus == "Submitted") {
                            $('.value-holder').attr('disabled', true);

                            if (modal == "copyWriteModal") {
                                $('.modal-container').find('.jqte_editor').attr('contenteditable', "false");
                            }

                            if (_this.state.isTaskReview) {
                                console.log('no need to do anything: Rejected Submitted EO');
                            } else {
                                $('.modal-container').find('.gray-button:not([data-action="cancel"])').attr('disabled', true);
                            }
                        }
                    } else if (res.data[0].approvalStatus == "none") {
                        if (res.data[0].submittedStatus == "Save") {
                            if (_this.state.isTaskReview) {
                                console.log('no need to do anything: none Save: EO');
                            } else {
                                console.log('no need to do anything: none Save: TO');
                            }
                        } else if (res.data[0].submittedStatus == "Submitted") {
                            $('.value-holder').attr('disabled', true);

                            if (modal == "copyWriteModal") {
                                $('.modal-container').find('.jqte_editor').attr('contenteditable', "false");
                            }

                            if (_this.state.isTaskReview) {
                                console.log('no need to do anything: none Save: TO');
                            } else {
                                $('.modal-container').find('.gray-button:not([data-action="cancel"])').attr('disabled', true);
                            }
                        }
                    } else if (res.data[0].approvalStatus == "save") {
                        $('.value-holder').attr('disabled', true);

                        if (modal == "copyWriteModal") {
                            $('.modal-container').find('.jqte_editor').attr('contenteditable', "false");
                        }

                        if (_this.state.isTaskReview) {
                            $('.gray-button[data-action="reject"]').trigger('click');
                            $('.reject-field').val(res.data[0].rejectComments);
                        } else {
                            $('.modal-container').find('.gray-button:not([data-action="cancel"])').attr('disabled', true);
                        }
                    }
                }
            }
        }).catch((error) => {
            console.log('marketingContainer EditOrViewEvent loadBriefData error: api call: ', error);

            NotificationManager({ message: 'could not get the data', type: 4 });
        });
    }

    loadLayoutData(id) {
        let _this = this;
        let postdata = {};

        postdata.id = id;

        MarketingServices.getPhotos(postdata).then((response) => {
            let res = response;

            if (res.responseCode === 200) {
                let arrImgData = res.data;

                if (res.data[0].taskData) {
                    _this.setState({ noTaskContent: false });
                } else {
                    if (_this.state.viewMode) {
                        _this.setState({
                            noTaskContent: false,
                            isTaskReview: false
                        });
                    } else {
                        _this.setState({ noTaskContent: true });
                    }
                }

                _this.setState({ rejectDisable: true });

                if (res.data[0].rejectComments) {
                    _this.setState({ rejectText: res.data[0].rejectComments });
                } else {
                    _this.setState({ rejectText: '' });
                }

                if (!_this.state.noTaskContent) {
                    let arrImgResult = [];

                    arrImgData.map(function (oData) {
                        if (oData.taskData) {
                            arrImgResult.push(oData.taskData);
                        }
                    });

                    _this.setState({
                        imageArr: arrImgResult,
                        mainImageSrc: ((arrImgResult.length > 0) ? arrImgResult[0] : '')
                    });

                    // new approach edit page events photo and layout
                    if (res.data[0].approvalStatus == "Approved") {
                        $('.status').text(res.data[0].approvalStatus + ' by ' + res.data[0].userId + ' on ' + res.data[0].approvalDate);
                        $('.modal-container').find('.gray-button:not([data-action="cancel"])').attr('disabled', true);
                        $('.icon-holder').attr('disabled', true);
                    } else if (res.data[0].approvalStatus == "Rejected") {
                        if (res.data[0].submittedStatus == "Save") {
                            if (_this.state.isTaskReview) {
                                $('.icon-holder').attr('disabled', true);
                                $('.gray-button[data-action="reject"]').trigger('click');
                                $('.reject-field').val(res.data[0].rejectComments).attr('disabled', true);
                                $('.modal-container').find('.gray-button:not([data-action="cancel"])').attr('disabled', true);
                            } else {
                                $('.status').html(res.data[0].approvalStatus + ' by ' + res.data[0].userId + ' on ' + res.data[0].approvalDate + '<br/><br/>' + "Review Notes: " + res.data[0].rejectComments);
                            }
                        } else if (res.data[0].submittedStatus == "Submitted") {
                            $('.icon-holder').attr('disabled', true);

                            if (_this.state.isTaskReview) {
                                console.log('no need to do anything: Rejected Submitted EO');
                            } else {
                                $('.modal-container').find('.gray-button:not([data-action="cancel"])').attr('disabled', true);
                            }
                        }
                    } else if (res.data[0].approvalStatus == "none") {
                        if (res.data[0].submittedStatus == "Save") {
                            if (_this.state.isTaskReview) {
                                console.log('no need to do anything: none Save: EO');
                            } else {
                                console.log('no need to do anything: none Save: TO');
                            }
                        } else if (res.data[0].submittedStatus == "Submitted") {
                            $('.icon-holder').attr('disabled', true);

                            if (_this.state.isTaskReview) {
                                console.log('no need to do anything: none Save: TO');
                            } else {
                                $('.modal-container').find('.gray-button:not([data-action="cancel"])').attr('disabled', true);
                            }
                        }
                    } else if (res.data[0].approvalStatus == "save") {
                        $('.icon-holder').attr('disabled', true);

                        if (_this.state.isTaskReview) {
                            $('.gray-button[data-action="reject"]').trigger('click');
                            $('.reject-field').val(res.data[0].rejectComments);
                        } else {
                            $('.modal-container').find('.gray-button:not([data-action="cancel"])').attr('disabled', true);
                        }
                    }
                }
            }
        }).catch((error) => {
            console.log('marketingContainer componentDidMount error: api call: ', error);

            NotificationManager({ message: error, type: 4 });
        });
    }

    resetTaskModalStates() {
        let _this = this;

        _this.setState({ noTaskContent: false, viewMode: false });
    }

    closeDetailsModal() {
        let _this = this;
        let clearActiveModal = {};

        _this.setState({
            modalName: '',
            [oActiveModal.modState]: false,
            oActiveModal: clearActiveModal
        });

        _this.resetTaskModalStates();
    }

    closeBriefModal() {
        if (!this.state.briefOrCopySavedisable) {
            this.setState({ isCancelModalForPhotoOpen: true });
        } else if (!this.state.rejectDisable) {
            this.setState({ isCancelModalForRejectOpen: true });
        } else {
            this.closeBriefModalFunc();
        }
    }

    closeBriefModalFunc() {
        let _this = this;
        let clearActiveModal = {};

        _this.setState({
            modalName: '',
            briefModalOpen: false,
            oActiveModal: clearActiveModal,
            activeEventID: '',
            rejectText: ''
        });

        if (_this.state.isTaskReview) {
            _this.setState({
                isTaskReview: false,
                showReject: false
            });
        }

        _this.resetTaskModalStates();
    }

    saveSubmitCall(data, type, event) {
        const _this = this;

        if (event == 'brief') {
            if (type == 'save') {
                MarketingServices.saveEventBrief(data).then((response) => {
                    let res = response;

                    if (res.responseCode === 200) {
                        NotificationManager({
                            message: res.responseMessage,
                            type: 1
                        });

                        _this.closeBriefModalFunc();
                        _this.setState({
                            briefOrCopySavedisable: true,
                            rejectDisable: true,
                            saveSubmitDisable: true
                        });
                    }
                }).catch((error) => {
                    console.log('marketingContainer saveEventBrief error: api call: ', error);

                    NotificationManager({
                        message: 'could not get the data ',
                        type: 4
                    });
                });
            } else {
                MarketingServices.submitEventBrief(data).then((response) => {
                    let res = response;

                    if (res.responseCode === 200) {
                        NotificationManager({
                            message: res.responseMessage,
                            type: 1
                        });

                        _this.closeBriefModalFunc();
                        _this.setState({
                            briefOrCopySavedisable: true,
                            rejectDisable: true,
                            saveSubmitDisable: true
                        });
                    }
                }).catch((error) => {
                    console.log('marketingContainer saveEventBrief error: api call: ', error);

                    NotificationManager({
                        message: 'could not get the data ',
                        type: 4
                    });
                });
            }
        } else {
            if (type == 'save') {
                MarketingServices.saveEventBrief(data).then((response) =>{
                    let res = response;

                    if (res.responseCode === 200) {
                        NotificationManager({
                            message: res.responseMessage,
                            type: 1
                        });

                        _this.closeCopyWriteModalFunc();
                        _this.setState({
                            briefOrCopySavedisable: true,
                            rejectDisable: true,
                            saveSubmitDisable: true
                        });
                    } else {
                        NotificationManager({
                            message: res.responseMessage,
                            type: 4
                        });
                    }
                }).catch((error) => {
                    console.log('marketingContainer saveCopywrite error: api call: ', error);

                    NotificationManager({
                        message: 'could not get the data ',
                        type: 4
                    });
                });
            } else {
                MarketingServices.submitEventBrief(data).then((response) =>{
                    let res = response;

                    if (res.responseCode === 200) {
                        NotificationManager({
                            message: res.responseMessage,
                            type: 1
                        });

                        _this.closeCopyWriteModalFunc();
                        _this.setState({
                            briefOrCopySavedisable: true,
                            rejectDisable: true,
                            saveSubmitDisable: true
                        });
                    } else {
                        NotificationManager({
                            message: res.responseMessage,
                            type: 4
                        });
                    }
                }).catch((error) => {
                    console.log('marketingContainer saveCopywrite error: api call: ', error);
                    NotificationManager({
                        message: 'could not get the data ',
                        type: 4
                    });
                });
            }
        }
    }

    saveEventBrief(event) {
        let _this = this;
        let value = $('.value-holder').val();

        if (value !== "" || value.trim() !== "") {
            if (value.length > 1000) {
                NotificationManager({
                    message: "Please shorten your Event Breif to <1000 characters",
                    type: 4
                });

                return false;
            }

            let postdata = {};
            let listUrl = "";

            if ($(event.target).attr('data-action') == "save") {
                listUrl = 'save';
            } else if ($(event.target).attr('data-action') == "submit") {
                listUrl = 'submit';
            } else {
                return false;
            }

            let eventId = $(event.target).attr('data-val');

            postdata.id = eventId;
            postdata.value = value;

            this.saveSubmitCall(postdata, listUrl, 'brief');
        } else {
            NotificationManager({
                message: "Please enter the event brief.",
                type: 4
            });
        }
    }

    closeCopyWriteModal() {
        if (!this.state.briefOrCopySavedisable) {
            this.setState({ isCancelModalForPhotoOpen: true });
        } else if (!this.state.rejectDisable) {
            this.setState({ isCancelModalForRejectOpen: true });
        } else {
            this.closeCopyWriteModalFunc();
        }
    }

    closeCopyWriteModalFunc() {
        let _this = this;
        let clearActiveModal = {};

        _this.setState({
            modalName: '',
            copyWriteModalOpen: false,
            oActiveModal: clearActiveModal,
            activeEventID: '',
            rejectText: ''
        });

        if (_this.state.isTaskReview) {
            _this.setState({ isTaskReview: false, showReject: false });
        }

        _this.resetTaskModalStates();
    }

    saveCopywrite(event) {
        let _this = this;
        let value = $('.event-copywrite').val();

        if (value !== "" || value.trim() !== "") {
            if (value.length > 1000) {
                NotificationManager({
                    message: "Please shorten your Event Copy to <1000 characters",
                    type: 4
                });

                return false;
            }

            let postdata = {};
            let listUrl = "";

            if ($(event.target).attr('data-action') == "save") {
                listUrl = 'save';
            } else if ($(event.target).attr('data-action') == "submit") {
                listUrl = 'submit';
            } else {
                return false;
            }

            let eventId = $(event.target).attr('data-val');

            postdata.id = eventId;
            postdata.value = value;

            this.saveSubmitCall(postdata, listUrl, 'copy');
        } else {
            NotificationManager({
                message: "Please enter the event copywrite.",
                type: 4
            });
        }
    }

    closeLayoutModal() {
        if (this.state.inputFile.length > 0) {
            this.setState({ isCancelModalForPhotoOpen: true });
        } else if (!this.state.rejectDisable) {
            this.setState({ isCancelModalForRejectOpen: true });
        } else {
            this.closeLayoutModalFunc();
        }
    }

    closeLayoutModalFunc() {
        let _this = this;
        let clearActiveModal = {};

        _this.setState({
            modalName: '',
            layoutModalOpen: false,
            oActiveModal: clearActiveModal,
            activeEventID: '',
            imageArr: [],
            mainImageSrc: "",
            inputFile: []
        });

        if (_this.state.isTaskReview) {
            _this.setState({ isTaskReview: false, showReject: false });
        }

        _this.resetTaskModalStates();
    }

    saveLayout() {
        let _this = this;
        let postdata = {};

        postdata.id = id;

        MarketingServices.submitPhotos(postdata).then((response) => {
            let res = response;

            if (res.responseCode === 200) {
                NotificationManager({ message: res.responseMessage, type: 1 });
            }
        }).catch((error) => {
            console.log('marketingContainer saveLayout error: api call: ', error);

            NotificationManager({ message: error, type: 4 });
        });
    }

    handleLayoutSubmit(e) {
        e.preventDefault();

        let _this = this;
        var form = new FormData($('[name="event-images-upload"]')[0]);

        MarketingServices.addPhotos(form).then((response) => {
            let data = response;
            let arrImg = _this.state.imageArr;
            let arrResult = data.data[0].taskData;

            arrImg.push(arrResult);

            _this.setState({ imageArr: arrImg, mainImageSrc: arrResult });
            _this.setState({ inputFile: [], inputFileName: "" });

            NotificationManager({ message: "Image uploaded successfully", type: 1 });
        }).catch((error) => {
            console.log('marketingContainer handleLayoutSubmit error: api call: ', error);

            _this.setState({ inputFile: [], inputFileName: "" });

            NotificationManager({
                message: "failed to upload image due to technical reasons, please try again",
                type: 4
            });
        });
    }

    submitLayout(e) {
        e.preventDefault();

        let _this = this;

        if (_this.state.imageArr.length > 0) {
            let postdata = {};

            postdata.id = $(e.target).attr('data-val');

            MarketingServices.submitPhotos(postdata).then((response) => {
                let data = response;

                _this.setState({ inputFile: [], inputFileName: "", inputFile: [] });

                NotificationManager({ message: data.responseMessage, type: 1 });

                $('.gray-button[data-action="cancel"]').trigger('click');
            }).catch((error) => {
                console.log('marketingContainer handleLayoutSubmit error: api call: ', error);

                _this.setState({ inputFile: [], inputFileName: "", inputFile: [] });

                NotificationManager({
                    message: "failed to submit due to technical reasons, please try again",
                    type: 4
                });
            });
        } else {
            NotificationManager({
                message: "Please save some images before submitting for approval",
                type: 4
            });
        }
    }

    closePhotographyModal() {
        if (this.state.inputFile.length > 0) {
            this.setState({ isCancelModalForPhotoOpen: true });
        } else if (!this.state.rejectDisable) {
            this.setState({ isCancelModalForRejectOpen: true });
        } else {
            this.closePhotographyModalFunc();
        }
    }

    closePhotographyModalFunc() {
        let _this = this;
        let clearActiveModal = {};

        _this.setState({ modalName: '', photographyModalOpen: false, oActiveModal: clearActiveModal, activeEventID: '', imageArr: [], mainImageSrc: "", inputFile: [] });

        if (_this.state.isTaskReview) {
            _this.setState({ isTaskReview: false, showReject: false });
        }
    }

    closePhotoorLayoutModal(action) {
        let _this = this;

        switch (action) {
            case 'yes':
                if (_this.state.photographyModalOpen) {
                    $('#savePhotodataInner').trigger('click');
                    _this.closePhotoorLayoutModal('no');
                } else if (_this.state.layoutModalOpen) {
                    $('#saveLayoutDataInner').trigger('click');
                    _this.closePhotoorLayoutModal('no');
                } else if (_this.state.briefModalOpen) {
                    $('#saveBriefInner').trigger('click');
                    _this.closePhotoorLayoutModal('no');
                } else if (_this.state.copyWriteModalOpen) {
                    $('#saveCopyInner').trigger('click');
                    _this.closePhotoorLayoutModal('no');
                }

                break;

            case 'no':
                if (_this.state.photographyModalOpen) {
                    _this.closePhotographyModalFunc();
                    _this.closePhotoorLayoutModal('cancel');
                } else if (_this.state.layoutModalOpen) {
                    _this.closeLayoutModalFunc();
                    _this.closePhotoorLayoutModal('cancel');
                } else if (_this.state.briefModalOpen) {
                    _this.closeBriefModalFunc();
                    _this.closePhotoorLayoutModal('cancel');
                    _this.setState({ briefOrCopySavedisable: true, saveSubmitDisable: true });
                } else if (_this.state.copyWriteModalOpen) {
                    _this.closeCopyWriteModalFunc();
                    _this.closePhotoorLayoutModal('cancel');
                    _this.setState({ briefOrCopySavedisable: true, saveSubmitDisable: true });
                }

                break;

            case 'cancel':
                _this.setState({ isCancelModalForPhotoOpen: false });
                break;
        }
    }

    closeRejectModal(action) {
        let _this = this;

        switch (action) {
            case 'yes':
                if (_this.state.briefModalOpen) {
                    $('#rejectBrief').trigger('click');
                    _this.closeRejectModal('no');
                } else if (_this.state.copyWriteModalOpen) {
                    $('#rejectCopy').trigger('click');
                    _this.closeRejectModal('no');
                } else if (_this.state.layoutModalOpen) {
                    $('#rejectLayout').trigger('click');
                    _this.closeRejectModal('no');
                } else if (_this.state.photographyModalOpen) {
                    $('#rejectPhoto').trigger('click');
                    _this.closeRejectModal('no');
                }

                break;

            case 'no':
                if (_this.state.briefModalOpen) {
                    _this.closeBriefModalFunc();
                    _this.closeRejectModal('cancel');
                } else if (_this.state.copyWriteModalOpen) {
                    _this.closeCopyWriteModalFunc();
                    _this.closeRejectModal('cancel');
                } else if (_this.state.layoutModalOpen) {
                    _this.closeLayoutModalFunc();
                    _this.closeRejectModal('cancel');
                } else if (_this.state.photographyModalOpen) {
                    _this.closePhotographyModalFunc();
                    _this.closeRejectModal('cancel');
                }

                break;

            case 'cancel':
                _this.setState({ isCancelModalForRejectOpen: false });
                break;
        }
    }

    savePhotography() { }

    submitPhotography(e) {
        e.preventDefault();
        let _this = this;

        if (_this.state.imageArr.length > 0) {
            let postdata = {};

            postdata.id = $(e.target).attr('data-val');

            MarketingServices.submitPhotos(postdata).then((response) => {
                let data = response;

                _this.setState({ inputFile: [], inputFileName: "", inputFile: [] });

                NotificationManager({ message: data.responseMessage, type: 1 });

                $('.gray-button[data-action="cancel"]').trigger('click');
            }).catch((error) => {
                console.log('marketingContainer handleLayoutSubmit error: api call: ', error);

                _this.setState({ inputFile: [], inputFileName: "", inputFile: [] });

                NotificationManager({
                    message: "failed to submit due to technical reasons, please try again",
                    type: 4
                });
            });
        } else {
            NotificationManager({
                message: "Please save some images before submitting for approval",
                type: 4
            });
        }
    }

    handleFileChange(e) {
        let _this = this;
        e.preventDefault();

        var target = e.target,
            list = target.files;

        var fileArray = [];
        for (var i = 0; i < list.length; i++) {
            var fileList = {};

            for (var l in list[i]) {
                fileList[l] = list[i][l];
            }

            fileArray.push(fileList);
        }

        if (list.length > 0 && fileArray.length > 0) {
            this.setState({ inputFile: fileArray, inputFileName: fileArray[0].name });
        }
    }

    updateMainViewer(val) {
        let _this = this;
        var currentSrc = _this.state.imageArr[val];

        _this.setState({ mainImageSrc: currentSrc });
    }

    changeValueHolder(event) {
        let _this = this;

        if (event.target.value !== "" && event.target.value.trim() !== "") {
            _this.setState({ saveSubmitDisable: false, briefOrCopySavedisable: false });
        } else {
            _this.setState({ saveSubmitDisable: true, briefOrCopySavedisable: true });
        }
    }

    getDate(element) {
        var dateFormat = "mm/dd/yy", date;

        try {
            date = $.datepicker.parseDate(dateFormat, element.value);
        } catch (error) {
            date = null;
        }

        return date;
    }

    getTodayDate() {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();

        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }

        today = mm + '/' + dd + '/' + yyyy;

        return today;
    }

    enableReject() {
        this.setState({ showReject: true });
    }

    approveEventTask(event) {
        event.preventDefault();

        let _this = this;
        let postdata = {};
        let flag = 'approve';

        postdata.id = $(event.target).attr('data-val');

        if ($(event.target).attr('data-action') == "approve") {
            postdata.comments = "";
            postdata.value = "true";
        } else if ($(event.target).attr('data-action') == "rejectsubmit") {
            postdata.comments = $('.reject-field').val();
            postdata.value = "false";
        } else if ($(event.target).attr('data-action') == "rejectsave") {
            postdata.comments = $('.reject-field').val();
            postdata.value = "false";
            flag = 'reject';
        }

        if (flag == 'approve') {
            MarketingServices.approveReject(postdata).then((response) => {
                let data = response;

                NotificationManager({ message: data.responseMessage, type: 1 });

                if (_this.state.briefModalOpen) {
                    _this.closeBriefModalFunc();
                } else if (_this.state.copyWriteModalOpen) {
                    _this.closeCopyWriteModalFunc();
                } else if (_this.state.layoutModalOpen) {
                    _this.closeLayoutModalFunc();
                } else if (_this.state.photographyModalOpen) {
                    _this.closePhotographyModalFunc();
                }

                _this.props.UpdateMarketingRow();
            }).catch((error) => {
                console.log('marketingContainer handleLayoutSubmit error: api call: ', error);

                NotificationManager({
                    message: "Failed to approve due to technical reasons",
                    type: 4
                });
            });
        } else {
            MarketingServices.saveReject(postdata).then((response) => {
                let data = response;

                NotificationManager({ message: data.responseMessage, type: 1 });

                if (_this.state.briefModalOpen) {
                    _this.closeBriefModalFunc();
                } else if (_this.state.copyWriteModalOpen) {
                    _this.closeCopyWriteModalFunc();
                } else if (_this.state.layoutModalOpen) {
                    _this.closeLayoutModalFunc();
                } else if (_this.state.photographyModalOpen) {
                    _this.closePhotographyModalFunc();
                }

                _this.props.UpdateMarketingRow();
            }).catch((error) => {
                console.log('marketingContainer handleLayoutSubmit error: api call: ', error);

                NotificationManager({
                    message: "Failed to approve due to technical reasons",
                    type: 4
                });
            });
        }
    }

    handleRejectField(e) {
        let _this = this;
        let elemText = $(event.target).val();

        if (elemText !== "" && elemText.trim() !== "") {
            this.setState({ rejectText: elemText, rejectDisable: false });
        } else {
            this.setState({ rejectText: "", rejectDisable: true });
        }
    }

    handleChange(e) {
        var target = e.target,
            value = target.value,
            type = target.getAttribute('data-type');

        let { eventStateData } = this.state;
        let newFormData = eventStateData;

        if (type === 'event') {
            var key = target.getAttribute('data-name');
            newFormData[key] = value;
        }

        if (type === 'task') {
            var key = target.getAttribute('data-name');
            var arrayIndex = target.getAttribute('data-index');

            newFormData['tasks'][arrayIndex][key] = value;
        }

        this.setState({ eventStateData: newFormData });
        this.props.editScreenModified();
    }

    render() {
        console.log('editOrViewEvent render: ', this.props, this.state);

        let imageErrorCheck = this.state.mainImageSrc ? <img src={this.state.mainImageSrc}/> : '';

        if (this.props.addEventData !== '' && Object.keys(this.props.eventData).length !== 0 && this.props.isOpen) {
            var types = this.props.addEventData.eventTypes;

            //Generate Event type Select box
            var events = types.map((opt, opt_idx) => {
                return <option key={opt_idx} value={opt.eventTypes}>{opt.eventTypes}</option>;
            });

            var tasks = this.props.addEventData.listDropdowns;
            var taskOwneer = this.props.eventData.tasks ? this.props.eventData.tasks[0].owner : '';
            var taskHtml = tasks.map((val, index) => {
                return (
                    <div key={index} className="edit-task-row-container task-row-containerFirst">
                        <div className="task-column form-group label">
                            <label id={"editlabel" + index}>{val.label}</label>
                        </div>
                        <div className="task-column form-group">
                            <select data-type="task" data-index={index} data-name="owner" onChange={this.handleChange}
                                value={this.state.eventStateData.tasks ? this.state.eventStateData.tasks[index].owner : ''}
                                className="owner-dropdwon form-control" id={"editowner" + index}
                                disabled={(this.props.user.defaultRole === "EventOwner" && this.props.user.userName === taskOwneer) ? false : true}>
                                <option value="0">Please select the owner</option>
                                {
                                    val.values.map((val, i) => {
                                        return <option key={i} value={val}>{val}</option>;
                                    })
                                }
                                {val.label !== 'Event Brief' &&
                                <option value="NA">N/A</option>
                                }
                            </select>
                        </div>
                        <div className="task-column form-group">
                            <input data-type="task" data-index={index} data-name="duedate" onChange={this.handleChange}
                                disabled={(this.props.user.defaultRole === "EventOwner" && this.props.user.userName === taskOwneer) ? false : true}
                                id={"editduedate" + index} type="text"
                                value={this.state.eventStateData.tasks ? this.state.eventStateData.tasks[index].duedate : ''}
                                placeholder="mm/dd/yyyy" className="form-control datepicker editduedate" name="dueDate"
                                readOnly="readonly"
                            />
                        </div>
                        <div className="task-column form-group">
                            <input disabled={true} type="text" value={this.props.eventData.tasks[index].completedDate ? this.props.eventData.tasks[index].completedDate : ''} placeholder="mm/dd/yyyy" className="form-control datepicker duedate" name="dueDate" readOnly="readonly" />
                        </div>
                        <div className="task-column form-group task-action">
                            {/*-----Event Owner Buttons-----*/}
                            <button className="eventButton reviewButton" onClick={this.handleDetailsModal} data-val={index} data-view={this.props.eventData.tasks[index].buttonStatus}
                                disabled={(val.label == "Product Selection") || (this.props.eventData.eventStaus == "Prelimanary" || this.props.eventData.eventStaus == "Preliminary" || this.props.eventData.eventStaus == "preliminary")}>Review {val.label}</button>
                        </div>
                        <div className="task-column form-group task-action">
                            {/*-----Task Owner Buttons-----*/}
                            {
                                val.label == 'Product Selection' ?
                                    ''
                                    :
                                    <button disabled={(this.props.eventData.tasks[index].taskButtonStatus === "true" ? false : true) || (this.props.eventData.eventStaus == "Prelimanary" || this.props.eventData.eventStaus == "Preliminary" || this.props.eventData.eventStaus == "preliminary")}
                                        className="eventButton" onClick={this.handleDetailsModal} data-val={index} data-view={this.props.eventData.tasks[index].taskButtonStatus}>
                            +Add {val.label}</button>
                            }
                        </div>
                        <div className="task-column form-group task-action">
                            <div className='healthcheckBox'>
                                <div className={"grid-text task-status-circle " + this.props.eventData.tasks[index].healthcheckClass}></div>
                            </div>
                            <input id={"edittaskId" + index} type="hidden"
                                value={this.props.eventData.tasks ? this.props.eventData.tasks[index].taskId : ''}
                                className="form-control" />
                        </div>
                    </div>
                );
            });
        } else {
            return false;
        }

        return (
            <EditOrViewEventComponent
                state = { this.state }
                props = { this.props }
                handleChange = { this.handleChange }
                changeValueHolder = { this.changeValueHolder }
                closeBriefModal = { this.closeBriefModal }
                saveEventBrief = { this.saveEventBrief }
                enableReject = { this.enableReject }
                approveEventTask = { this.approveEventTask }
                closePhotographyModal = { this.closePhotographyModal }
                handleLayoutSubmit = { this.handleLayoutSubmit }
                submitPhotography = { this.submitPhotography }
                handleRejectField = { this.handleRejectField }
                closePhotoorLayoutModal = { this.closePhotoorLayoutModal }
                closeRejectModal = { this.closeRejectModal}
                events = {events}
                taskHtml = {taskHtml}
                taskOwneer = {taskOwneer}
                handleFileChange = {this.handleFileChange}
                closeLayoutModal = {this.closeLayoutModal}
                closeCopyWriteModal = {this.closeCopyWriteModal}
                updateMainViewer = {this.updateMainViewer}
                saveCopywrite = {this.saveCopywrite}
                submitLayout = {this.submitLayout}
                imageErrorCheck = {imageErrorCheck}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentTaskData: state.mk_eventTaskData,
        allTaskData: state.mk_allEventDetails
    };
};

const mapDispatchToprops = (dispatch) => {
    return {
        eventTaskData: (data) => {
            dispatch(actions.eventTaskData(data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToprops)(EditOrViewEvent);
