/**
 * column width declaration
 */
const columnWidth = {
    productId: 120,
    prodName: 300,
    activeNotes: 400,
    registrationDate: 160,
    lastLoggedDate: 180,
};

let SuperiorData;
let AllSuperiorData;

const requiredFieldsList = ['fieldsToClone', 'readyToOrder', 'reqdToAdd'];
/*Date Editor funtion for Jqwidgets*/

/**
 * JQGrid date editor
 * @param {*} row
 * @param {*} cellvalue
 * @param {*} editor
 * @param {*} cellText
 * @param {*} width
 * @param {*} height
 */
const createDateEditor = function (row, cellvalue, editor, cellText, width, height) {
    // construct the editor.
    cellvalue = cellvalue ? cellvalue : new Date();
    editor.jqxDateTimeInput({
        width: width,
        height: height,
        value: cellvalue,
        culture: 'en-US',
    });
};

/**
 * JQGrid date init
 * @param {*} row
 * @param {*} cellvalue
 * @param {*} editor
 * @param {*} celltext
 * @param {*} width
 * @param {*} height
 */
const initDateEditor = function (row, cellvalue, editor, celltext, width, height) {
    // set the editor's current value. The callback is called each time the editor is displayed.
    cellvalue = cellvalue ? cellvalue : new Date();
    editor.jqxDateTimeInput('setDate', cellvalue);
};

/**
 * JQGrid get selected date
 * @param {*} row
 * @param {*} cellvalue
 * @param {*} editor
 * @return {Date}
 */
const getDateEditor = function (row, cellvalue, editor) {
    // return the editor's value.
    return $(editor).jqxDateTimeInput('getDate');
};

/**
 * Cell double click event
 * @param {*} row
 * @param {*} columnfield
 * @param {*} value
 * @param {*} rowdata
 * @return {object}
 */
const CellDoubleClickEvent = function (row, columnfield, value, rowdata) {
    return '<div class="grid-text gridUserId" data-row="' + row + '">' + value + '<span class="icon-expand-grid right"></span></div>';
};

/**
 * Status dropdown redner
 * @param {*} row
 * @param {*} columnfield
 * @param {*} value
 * @param {*} rowdata
 * @return {object}
 */
const StatusDropDownInitRenderer = function (row, columnfield, value, rowdata) {
    if (value == 1 || value === 'Enabled') {
        return '<div class="grid-dropdown"><select class="greenText" onchange="this.className= this.options[this.selectedIndex].className" data-row="' + row + '" data-userid=""><option value="1" selected class="greenText">Enabled</option><option value="0" class="redText">Disabled</option></select>';
    } else {
        return '<div class="grid-dropdown"><select class="redText" onchange="this.className= this.options[this.selectedIndex].className" data-row="' + row + '" data-userid=""><option value="1" class="greenText">Enabled</option><option value="0" class="redText" selected>Disabled</option></select>';
    }
};

/**
 *  If the user is assigned to more than one user group, this field will display the first assigned user group, followed by elides (...) or an asterisk.
 * @param {*} row
 * @param {*} columnfield
 * @param {*} value
 * @param {*} defaulthtml
 * @param {*} columnproperties
 * @param {*} rowdata
 * @return {object}
 */
const UsergroupInitRenderer = function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
    let flag = 0;

    for (let i = 0; i < rowdata.roles.length; i++) {
        if (rowdata.roles[i].status === 'True') {
            flag++;
        }
    }

    const elides = (flag > 1) ? '...' : '';

    return '<div class="grid-text">' + value + elides + '</div>';
};

/**
 * Superior dropdown render
 * @param {*} row
 * @param {*} columnfield
 * @param {*} value
 * @param {*} defaulthtml
 * @param {*} columnproperties
 * @param {*} rowdata
 * @return {object}
 */
const SuperiroRender = function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
    const userList = AllSuperiorData;

    for (let i = 0; i < userList.length; i += 1) {
        if (userList[i].fieldId == value) {
            return '<div class="grid-text">' + userList[i].fieldName + '</div>';
        }
    }
};
//

//Ends User Status - Enable/Disable

/* End User Grid Status*/
/**
 * Grid data builder is used to create the object format required for
 * Jqwidgets.
 * @param {*} res
 * @param {*} pageName
 * @return {Array}
 */
function GridDataBuilder(res, pageName) {
    SuperiorData = res.dropdowns['superior'];
    AllSuperiorData = res.dropdowns['superiorAll'];

    /**
     * this will generate drop down template for the field name with type as dropdown,
     *  the source for the dropdown should be available in the list response under dropdown with
     *  the same name as fieldName.
     * @param {*} row
     * @param {*} fieldName
     * @param {*} editor
     * @param {*} cellText
     * @param {*} width
     * @param {*} height
     */
    const DropDownEditor = function (row, fieldName, editor, cellText, width, height) {
        // assign a new data source to the dropdownlist.
        const list = res.dropdowns.superior;
        const enableAutoHieght = list.length < 7 ? true : false;

        list.unshift({ fieldId: '', fieldName: 'Select ' + fieldName });

        editor.jqxDropDownList({
            autoDropDownHeight: enableAutoHieght,
            source: list,
            dropDownHeight: 205,
            width: width,
            height: height,
            displayMember: 'fieldName',
            valueMember: 'fieldId',
            theme: 'light',
            enableBrowserBoundsDetection: true,
            filterable: true,
            autoOpen: false,
            placeHolder: 'Select ' + fieldName,
        });
    };

    /**
     * called when the dropdown loads
     * @param {*} row
     * @param {*} cellvalue
     * @param {*} editor
     * @param {*} celltext
     * @param {*} width
     * @param {*} height
     */
    const DropDownInit = function (row, cellvalue, editor, celltext, width, height) {
        // set the editor's current value. The callback is called each time the editor is displayed.
        cellvalue = cellvalue ? cellvalue : '';
        editor.jqxDropDownList('selectItem', cellvalue);
        editor.jqxDropDownList('clearFilter');
    };

    /**
     * updates the selected value
     * @param {*} row
     * @param {*} cellvalue
     * @param {*} editor
     * @param {*} celltext
     * @return {object}
     */
    const DropDownUpdater = function (row, cellvalue, editor, celltext) {
        // return the old value, if the new value is empty.
        return editor.val();
    };

    // dataField is used for providing type of data and its name.
    // columnList is used to define column name, type, and extra fields
    //  to define additional plugins inside the cell eg: drop down, calender ...,
    const dataField = [];
    const columnList = [];
    let primayKey = res.columnDetails[0].fieldName;
    // check for the type of fieldname future

    //sort columndetails in product pageName
    res.columnDetails.sort(function (a, b) {
        return a.order - b.order;
    });

    for (let i = 0; i < res.columnDetails.length; i++) {
        dataField.push({
            'name': res.columnDetails[i].fieldName,
            'type': res.columnDetails[i].type.toLowerCase(),
        });

        /* Enable the below condition after pk(primary key) availabel from backend
         *  in JSON response.
         */

        if (res.columnDetails[i].hasOwnProperty('pk') && res.columnDetails[i].pk) {
            primayKey = res.columnDetails[i].fieldName;
        }

        columnList.push(columnObj(res.columnDetails[i]));
    }


    /**
     * UC words
     * @param {string} str
     * @return {string}
     */
    function titleCase(str) {
        return str.toLowerCase().split(' ').map(function (word) {
            return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
    }
    /** columnobj function will  return the object fields
     *  based on the field type in list.
     * e.g. for date type and other types need to build the cellrender function.
     * @param {object} list
     * @return {object}
     */
    function columnObj(list) {
        const obj = {};
        const titleCaseStr = titleCase(list.columnTitle);

        obj.text = '<span class="pd-drop" data-field="' + list.fieldName + '" title="' + titleCaseStr + '">' + titleCaseStr + '</span>';
        obj.dataField = list.fieldName;
        obj.width = (columnWidth[list.fieldName]) ? columnWidth[list.fieldName] : 150;
        obj.hidden = !list.visibility;

        /* if list type is date, pass the methods related to date to intialize
         *  date picker */
        if (list.fieldName === 'userId') {
            obj.cellsrenderer = CellDoubleClickEvent;
            obj.editable = false;
            obj.pinned = true;
        } else if (list.type === 'Date') {
            obj.columnType = 'template';
            obj.cellsFormat = 'MM/dd/yyyy';
            obj.createEditor = createDateEditor;
            obj.initEditor = initDateEditor;
            obj.getEditorValue = getDateEditor;
            obj.editable = false; // remove this if required
        } else if (list.fieldName === 'status') {
            obj.text = '<span class="pd-drop" data-field="' + list.fieldName + '" title="Status">Status</span>';
            obj.cellsrenderer = StatusDropDownInitRenderer;
            obj.editable = false;
            obj.cellsAlign = 'center';
            obj.pinned = true;
            obj.enabletooltips = false;
        } else if (list.fieldName === 'userName') {
            obj.editable = false;
            obj.cellsAlign = 'center';
            obj.pinned = true;
        } else if (list.fieldName === 'defaultRole') {
            obj.cellsrenderer = UsergroupInitRenderer;
            obj.editable = false;
        } else if (list.fieldName === 'nickName') {
            obj.cellvaluechanging = function (row, datafield, columntype, oldvalue, newvalue) {
                if (newvalue.length > 10) {
                    return newvalue.substring(0, 10);
                }
            };
        } else if (list.type === 'DropDown') {
            obj.columntype = 'template';
            obj.createEditor = DropDownEditor;
            obj.initEditor = DropDownInit;
            obj.getEditorValue = DropDownUpdater;

            if (list.fieldName === 'superior') {
                obj.cellsrenderer = SuperiroRender;
            }
        } else if (list.fieldName === 'speciality') {
            obj.editable = false;
        }

        return obj;
    }

    const source = {
        dataType: 'json',
        dataFields: dataField,
        id: primayKey,
        localdata: res.data,
    };

    const dataAdapter = new $.jqx.dataAdapter(source);

    return {
        dataAdapter: dataAdapter,
        columns: columnList,
    };
}

export default GridDataBuilder;
