import React, { Component } from 'react';
import ConfirmModal from './confirm-modal.js';

class CancelModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false
        };

        this.closeConfirmModal = this.closeConfirmModal.bind(this);
    }

    closeConfirmModal(key) {
        this.props.modalClose(key);
    }

    render() {
        return (
            <ConfirmModal isOpen={this.props.isOpen}>
                <div className="modal-content">
                    <h3 className="confirm-warning-text">Do you want to Save Changes?</h3>
                    <div className="modal-footer">
                        <input type="button" className="gray-button" value="Yes" onClick={() => this.closeConfirmModal((this.props.isChangePassword) ? 'changePassword' : 'updateUser')}></input>
                        <input type="button" className="gray-button" value="No" onClick={() => this.props.modalClose('no')}></input>
                    </div>
                </div>
            </ConfirmModal>
        );
    }
}

export default CancelModal;
