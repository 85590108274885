import React, { Component } from 'react';
import CategoryTree from './categoryTree';


class SelectCategory extends Component {
    constructor(props) {
        super(props);
        this.setCategoryId = this.setCategoryId.bind(this);
        this.state = {
            cancelClicked: false,
            okBtnDisabled: true,
        };
        this.enableOkayButton = this.enableOkayButton.bind(this);
        this.setCategoryId = this.setCategoryId.bind(this);
    }


    componentDidMount() {

    }

    componentWillUnmount() {
        console.log('okbutndisabled: ', this.state.okBtnDisabled, this.state.cancelClicked);

        if (!this.state.okBtnDisabled || !this.state.cancelClicked) {
            this.child.setCategoryId();
        }
    }

    handleCancel() {
        this.setState({ cancelClicked: true }, () => {
            this.props.modalClose();
        });
    }
    /**
   * Sets the category ID
   */
    setCategoryId() {
        this.child.setCategoryId();
    }

    /**
   * Enable/Disble the ok button
   * @param {boolean} flag True/False flag
   */
    enableOkayButton(flag) {
        this.setState({ okBtnDisabled: !flag });
    }



    render() {
        const modalClass = 'SelectCategoryModal';
        return (
            <div>
                <div className="modal-header">
          Select Category
                    <span className="close" onClick={this.props.modalClose}>x</span>
                </div>
                <div className="modal-content">
                    <div className="add-copy modal-form">
                        <div className="form-container scroll">
                            <CategoryTree ref={(instance) => {
                                this.child = instance;
                            }}
                            {...this.props}
                            enableOkayButton={this.enableOkayButton}
                            />
                        </div>
                        <div className="modal-footer text-center">
                            <input type="button" className="gray-button" disabled={this.state.okBtnDisabled} onClick={this.setCategoryId} value="Ok" />
                            <input type="button" className="gray-button" onClick={this.handleCancel.bind(this)} value="Cancel" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default SelectCategory;
