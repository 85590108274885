import React, { Component } from 'react';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import JqxDateTimeInput from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxdatetimeinput';

// import JqxDateTimeInput from '../../common/vendor/jqwidgets-react/react_jqxdatetimeinput';
import Modal from '../../modals/common-modal.js';
import ConfirmSubmit from './confirmsubmit.js';
import axios from 'axios';

var constants = require('../../common/constants.js');
// var NotificationManager = require('../../common/notification');
import NotificationManager from '../../common/components/notification';


class AddPOModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            channel: "",
            ecomPoDueDate: "",
            ecomPoReceivedDate: "",
            retailPoDueDate: "",
            retailPoReceivedDate: "",
            quantity: "1",
            poNumber: "",
            productId: null,
            vendorId: this.props.vendorId,
            isModalOpen: false,
            channelText: ''
        };
        this.AllFields = ["channel", "ecomPoDueDate", "ecomPoReceivedDate", "retailPoDueDate", "retailPoReceivedDate", "quantity", "vendorId"];
        this.modalDialog = "SaveChangesCancel";
        this.handleChange = this.handleChange.bind(this);
        this.addPODetails = this.addPODetails.bind(this);
        this.formatDateValue = this.formatDateValue.bind(this);
        this.closeConfirmModal = this.closeConfirmModal.bind(this);
        this.confirmSaveChanges = this.confirmSaveChanges.bind(this);
        this.didValueChanged = this.didValueChanged.bind(this);
        this.closeAllModal = this.closeAllModal.bind(this);
        this.getTodayDate = this.getTodayDate.bind(this);
        this.handleChangeAllowOnlyNumber = this.handleChangeAllowOnlyNumber.bind(this);
        this.getTodayDateEditor = this.getTodayDateEditor.bind(this);
    }

    componentDidUpdate() {
        var _this = this;
        //setting value on change of date value
        if (this.state.channelText == "ecom") {
            _this.ecomPoDueDate.on('change', function (e) {
                _this.state.ecomPoDueDate = _this.formatDateValue($(this).val());
            });
            // _this.ecomPoReceivedDate.on('change', function(e){
            //   _this.state.ecomPoReceivedDate=_this.formatDateValue($(this).val());
            // });
        }
        if (this.state.channelText == "retail") {
            _this.retailPoDueDate.on('change', function (e) {
                _this.state.retailPoDueDate = _this.formatDateValue($(this).val());
            });
            // _this.retailPoReceivedDate.on('change', function(e){
            //   _this.state.retailPoReceivedDate=_this.formatDateValue($(this).val());
            // });
        }
    }



    getTodayDateEditor() {
        var today = new Date();
        var dd = today.getDate();

        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }
        today = mm + '/' + dd + '/' + yyyy;
        return today;
    }

    getTodayDate() {
        var today = new Date();
        var dd = today.getDate();

        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }
        today = yyyy + '-' + mm + '-' + dd;
        return today;
    }

    closeConfirmModal() {
        this.setState({ isModalOpen: false });
    }

    openConfirmModal() {
        this.setState({ isModalOpen: true });
    }

    closeAllModal() {
        this.closeConfirmModal();
        this.props.modalClose();
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        var regex = /^[a-z0-9]+$/i;
        if (value.length > 0 && name === "poNumber") {
            if (regex.test(value)) {
                this.setState({ [name]: value });
            }
            return false;
        }
        if (name === "channel") {
            let channelText = $("#channel option:selected").text().toLowerCase();
            this.setState({ channelText: channelText });
        }

        this.setState({ [name]: value });
    }

    handleChangeAllowOnlyNumber(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const re = /^[0-9\b]+$/;
        if (parseInt(target.value, 10) === 0 && name == 'quantity') {
            target.value = 1;
        }
        if (target.value == '' || re.test(target.value)) {
            this.setState({ [name]: target.value });
        }
    }

    /** Showing popup to ask confirmation to cancel(Yes/No/Cancel) */
    confirmSaveChanges() {
        if (this.didValueChanged()) {
            this.modalDialog = "SaveChangesCancel";
            this.openConfirmModal();
        } else {
            this.props.modalClose();
        }
    }

    didValueChanged() {
        let isChanged = false;
        let prev, next;
        let cd = new Date(), y = cd.getFullYear(), d = cd.getDate(),
            m = cd.getMonth() + 1;
        m = m < 10 ? "0" + m : m;
        d = d < 10 ? "0" + d : d;
        let cdformat = y + '-' + m + '-' + d;
        this.initState = {
            channel: "",
            ecomPoDueDate: (this.state.ecomPoDueDate == "") ? "" : cdformat,
            ecomPoReceivedDate: (this.state.ecomPoReceivedDate == "") ? "" : cdformat,
            retailPoDueDate: (this.state.retailPoDueDate == "") ? "" : cdformat,
            retailPoReceivedDate: (this.state.retailPoReceivedDate == "") ? "" : cdformat,
            quantity: "1",
            vendorId: this.props.vendorId,
        };
        for (var i = 0; i < this.AllFields.length; i++) {
            prev = this.state[this.AllFields[i]];
            next = this.initState[this.AllFields[i]];
            //converting prev and next to string if incase they are number or some other type
            // Reason: localeCompare is string method it will not work for other litteral types
            prev = prev !== null ? '' + prev : "";
            next = next !== null ? '' + next : "";
            if (prev.localeCompare(next) !== 0) {
                isChanged = true;
                break;
            }
        }
        return isChanged;
    }

    addPODetails(e) {
        if (e) { e.preventDefault(); }
        let _this = this, ecomPoDueDate = "", ecomPoReceivedDate = "", retailPoDueDate = "", retailPoReceivedDate = "";
        if (this.state.channelText == "ecom") {
            ecomPoDueDate = this.formatDateValue(this.ecomPoDueDate.val()),
            // ecomPoReceivedDate = this.formatDateValue(this.ecomPoReceivedDate.val());
            ecomPoReceivedDate = this.getTodayDate();
        }
        if (this.state.channelText == "retail") {
            retailPoDueDate = this.formatDateValue(this.retailPoDueDate.val()),
            // retailPoReceivedDate = this.formatDateValue(this.retailPoReceivedDate.val());
            retailPoReceivedDate = this.getTodayDate();
        }
        this.state.ecomPoDueDate = ecomPoDueDate;
        this.state.ecomPoReceivedDate = ecomPoReceivedDate;
        this.state.retailPoDueDate = retailPoDueDate;
        this.state.retailPoReceivedDate = retailPoReceivedDate;

        _this.closeConfirmModal();
        let error = false;
        if (this.state.channel == "" || this.state.vendorId == "" || this.state.poNumber == "" || isNaN(parseInt(this.state.quantity, 10))) {
            error = true;
        }
        if (error) {
            NotificationManager({ message: "Please enter all fields", type: 4 });
            return;
        }
        var pIds = this.props.productId;
        var postdata = pIds.map(function (e, i) {
            var postobj = Object.assign({}, _this.state);
            postobj.productId = e;
            return postobj;
        });


        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.addPO(postdata)
            .then((response) => {
                console.log('productContainer helperComponents add-po-modal API call success data: ', response.data);
                let res = response.data;

                if (res.status) {
                    NotificationManager({ message: res.responseMessage, type: 1 });
                    _this.props.updateProductForPO(res.data);
                    _this.props.modalClose();
                } else {
                    NotificationManager({ message: res.responseMessage, type: 4 });
                }
            })
            .catch(error => {
                console.log('productContainer helperComponents add-po-modal API call failed', error);
                NotificationManager({ message: error, type: 4 });
            });
    }


    formatDateValue(val) {
        let dateArr = val.split('/');
        return dateArr[2] + "-" + dateArr[0] + '-' + dateArr[1];
    }

    render() {
        console.log('add po modal: ', this.props);

        let channelForProduct = this.props.channel;
        let channelList = window.dropdownslist.channel;
        let channelOption = '';
        let modalClass = 'ConfirmSubmitModal';
        let min = this.getTodayDateEditor();


        var valarray = channelForProduct.split(',');
        var obj = [];
        for (var i = 0; i < channelList.length; i++) {
            //if (list[i]['fieldId'] == valarray) {
            if (valarray.includes(channelList[i]['fieldId'])) {
                obj.push(channelList[i]);
            }
        }

        return (
            <div>
                <div className="modal-header">
          Add PO  (SWEFT ID:{this.props.productId[0]})
                    <span className="close" onClick={this.confirmSaveChanges}>x</span>
                </div>
                <div className="modal-content">
                    <div className="add-copy modal-form">
                        <form className="AddPODetails" name="AddPODetails" method="post" onSubmit={this.addPODetails}>
                            <div className="form-container scroll">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Channel</label>
                                            <select name="channel" id="channel" onChange={this.handleChange}>
                                                <option value="">Select Channel</option>
                                                {/* <option value="ecom">Ecom</option>
                        <option value="retail">Retail</option> */}
                                                {obj &&
                          obj.map((val, i) => {
                              return <option key={i} value={val.fieldId}>{val.fieldName}</option>;
                          })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">PO Number</label>
                                            <input type="text" placeholder="Add PO Number" className="form-control col-12" name="poNumber" maxLength="15" onChange={this.handleChange} value={this.state.poNumber}></input>
                                        </div>
                                    </div>
                                </div>
                                {this.state.channelText == "ecom" &&
                  <div>
                      <div className="row">
                          <div className="col-12">
                              <div className="form-group">
                                  <label className="form-label">Ecom PO due date</label>
                                  <JqxDateTimeInput ref={(date) => { this.ecomPoDueDate = date; }} height={25} formatString={"MM/dd/yyyy"} min={min} />
                              </div>
                          </div>
                      </div>

                  </div>
                                }
                                {this.state.channelText == "retail" &&
                  <div>
                      <div className="row">
                          <div className="col-12">
                              <div className="form-group">
                                  <label className="form-label">Retail PO due date</label>
                                  <JqxDateTimeInput ref={(date) => { this.retailPoDueDate = date; }} height={25} formatString={"MM/dd/yyyy"} min={min} />
                              </div>
                          </div>
                      </div>

                  </div>
                                }
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Quantity</label>
                                            <input type="text" className="form-control col-12" name="quantity" maxLength="4" value={this.state.quantity} onChange={this.handleChangeAllowOnlyNumber}></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Vendor Id</label>
                                            <input type="text" className="form-control col-12" disabled={true} value={this.props.vendorId} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer text-center">
                                <input type="submit" className="gray-button saveBtn" title="Save" value="Save" />
                                <input type="button" className="gray-button" title="Cancel" value="Cancel" onClick={this.confirmSaveChanges} />
                            </div>
                        </form>
                    </div>
                </div>
                {this.state.isModalOpen &&
          <Modal isOpen={this.state.isModalOpen} addClass={modalClass}>
              <ConfirmSubmit dialog={this.modalDialog} modalClose={this.closeConfirmModal} yesSaveChange={this.addPODetails} noSaveChange={this.closeAllModal}>

              </ConfirmSubmit>
          </Modal>
                }
            </div>
        );
    }
}
export default AddPOModal;
