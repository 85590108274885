import React from 'react';
import axios from 'axios';
import DashboardServices from '../services/dashboard-services';

class RTOSummary extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            user:{},
            rtoSummary: null,
        };
    }

    componentDidMount(){
        console.log('RTO Summary: ', this.props);

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };
        DashboardServices.getRTOSummary().then( (response) => {
            console.log('RTO Summary success data: ', response.data);
            let res = response;

            if(res.code == 200 && res.httpStatus == 'OK')
            {
                let data = res.responseData;
                this.setState({rtoSummary: data});
            }
        })
            .catch( error => {
                console.log('RTO Summary API call failed', error);
            });
    }

    componentWillUnmount(){
    }

    componentWillReceiveProps(nextProps){

    }


    render(){
        console.log('Ready To Order Summary Alerts render: ', this.props);

        var intViewportHeight = window.innerHeight;
        var containerheight = intViewportHeight - 64 - 30;
        var ht = (containerheight / 2) - 81;
        var containerStyle = {
            height:ht
        };

        let listUI;
        if(this.state.rtoSummary){
            listUI = this.state.rtoSummary.map(function(obj, index){
                return <li key={index} className="accoplish-cursor" >{obj.season} <span>{obj.readyToOrderSummary}{'%'}</span></li>;
            });
        }



        return (
            <div className="accomplishments">
                <div className="headbox">
                    {/* <i className="icon-acc"></i> */}
                    <i className="far fa-comment-alt fa-2x message-icon-dashboard"></i>
                    <div className="message-count-dashboard">{6}</div>
                    <span className="heading-text">My Messages</span>
                    {/* <select onChange={this.applyFilter}>
                             <option value="">Filter By</option>
                             <option value="hourly">Last Hour</option>
                             <option value="daily">Last day</option>
                             <option value="weekly">Last Week</option>
                             <option value="monthly">Last Month</option>
                         </select> */}

                </div>
                {
                    <ul className="scroll">
                        <li className="accoplish-cursor message-items" >
                            <div className='style-number'>#1983712</div>
                            <div className='product-name'>Palomar Ankle Boots</div>
                            <div className='sender-name'>Susan Fleming</div>
                            <div className='sent-time'>2h</div>
                            <div className='message-desc'>Hi, I'm OOTO Today, could you please set this item up in the mean time.</div>
                        </li>
                        {/* {listUI} */}
                        {/* <li className="accoplish-cursor" >{'New From PLM updates'}<span>{327}</span></li>
                            <li className="accoplish-cursor" >{'Items Ready To Order'}<span>{158}</span></li>
                            <li className="accoplish-cursor" >{'Attributes Completed'}<span>{214}</span></li>
                            <li className="accoplish-cursor" >{'Carry-Forward Updates'}<span>{622}</span></li>
                            <li className="accoplish-cursor" >{'Samples Requested'}<span>{463}</span></li>
                            <li className="accoplish-cursor" >{'Images Approved'}<span>{846}</span></li>
                            <li className="accoplish-cursor" >{'Copy Approved'}<span>{571}</span></li> */}
                    </ul>
                }

            </div>
        );
    }
}

export default RTOSummary;




