import React from 'react';

class MandatoryFieldPopup extends React.Component {
    render() {
        let props = this.props;

        return (
            <div className="modal-content">
                <div className="text-center confirm-delete">
                    <h4>Please update the mandatory field(s) -"{this.props.mandatoryAddColumns.join(", ")}"</h4>
                </div>
                <div className="modal-footer text-center">
                    <button className="gray-button"
                        onClick={() => {
                            props.addBackTheField();
                            props.modalClose();
                        }}>
						ok
          			</button>
                </div>
            </div>
        );
    }
}

export default MandatoryFieldPopup;
