import React, { Component } from 'react';
import CronJobSettingsComponent from './cronJobSettingsComponent';
import GridDataBuilder from './helperComponents/cronJobGridDataBuilder';
import NotificationManager from '../common/components/notification';
import axios from 'axios';
import './cronJobSettings.css';
import AdminServices from '../admin/admin-services/admin-services';

class CronJobSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
        };
        this.counter = 0;
        this.initializeGrid = this.initializeGrid.bind(this);
    }

    componentDidMount() {
        const _this = this;
        this.initializeGrid();

        $('.cronjobGrid').on('cellsvaluechanged', function (event) {
            if (_this.counter) {
                _this.counter = 0;
                return false;
            }
            const args = event.args;
            const datafield = args.datafield;
            const newvalue = args.newvalue;
            const rowindex = args.rowindex;
            const rowdata = _this.cronJobGrid.getrowdata(rowindex);
            oldvalue = args.oldvalue;

            const numregex = /^[0-9+-]+$/i;

            if (datafield === 'displayValue' && !numregex.test(newvalue)) {
                if (newvalue.trim() !== '') {
                    NotificationManager({
                        message: 'Invalid Format.',
                        type: 4,
                    });
                    if (!oldvalue) {
                        oldvalue = '';
                    }
                    _this.counter = 1;
                    _this.cronJobGrid.setcellvalue(rowindex, datafield, oldvalue.trim());
                    _this.cronJobGrid.begincelledit(rowindex, datafield);
                    return;
                }
            }
            let req = {};
            const cronTime = rowdata.startTime.split(':');
            const pattervalue = '0 ' + cronTime[1] + ' ' + cronTime[0] + ' ? * *';

            req = {
                id: rowdata.id,
                jobCode: rowdata.jobCode,
                jobName: rowdata.jobName,
                displayValue: rowdata.displayValue,
                active: rowdata.active,
                pattrenValue: pattervalue,
                startTime: rowdata.startTime,

            };


            AdminServices.updateJobData(req).then((response) => {
                const res = response.data;
                if (res.status) {
                    NotificationManager({ message: res.responseMessage, type: 1 });
                } else {
                    NotificationManager({ message: res.responseMessage, type: 4 });
                }
            }).catch((error) => {
                console.log('cronJobSettingsContainer componentDidMount API call failed', error);
                NotificationManager({ message: error, type: 4 });
            });
        });
    }

    initializeGrid() {
        const base = this;

        AdminServices.getJobData().then((response) => {
            const res = response;
            base.gridData = GridDataBuilder(res, 'jobsetting');
            base.setState({ isLoaded: true });
        }).catch((error) => {
            console.log('cronJobSettingsContainer initializeGrid componentDidMount API call failed', error);
        });
    }

    render() {
        const footerHeight = 50;
        const headerHeight = 64;
        $('.page-title a').text("ADMIN: CronJob Settings");

        const gridHeight = window.innerHeight - footerHeight - headerHeight;
        const editSettings = {
            saveOnPageChange: true,
            saveOnBlur: true,
            saveOnSelectionChange: true,
            cancelOnEsc: true,
            saveOnEnter: true,
            editSingleCell: true,
            editOnDoubleClick: true,
            editOnF2: true,
        };
        return (
            <CronJobSettingsComponent
                state={this.state}
                props={this.props}
                cronJobSettingsGrid={(grid) => {
                    this.cronJobGrid = grid;
                }}
                gridData={this.gridData}
                gridHeight={gridHeight}
                editSettings={editSettings}
            />
        );
    }
}
export default CronJobSettings;
