import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import JqxGrid from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';
import EmailCreator from './helperComponents/emailCreator';
import Modal from '../common/modals/commonModal';
import ConfirmDelete from './helperComponents/confirmDelete';
import EmailTemplatesFooter from './helperComponents/footer';


const EmailTemplatesComponent = ({
    state,
    props,

    emailGridRef,
    gridData,
    gridHeight,

    closeRulesModal,
    data,

    modalClass,
    modalClose,
    deleteRules,
    modalName,
    addOrUpdateRules,
    closeAllModal,

    openRulesModal,
    cloneRules,
    pauseRules,
    deleteRuleDialog,
    onCelldoubleclick,

}) => {
    return (
        <div>
            <div className="emailGrid pos-relative">
                {state.isLoaded &&
                    <JqxGrid
                        ref={emailGridRef}
                        width={'100%'}
                        source={gridData.dataAdapter}
                        pageable={false}
                        editable={false}
                        hierarchicalCheckboxes={true}
                        columnsResize={true}
                        columns={gridData.columns}
                        sortable={false}
                        autoRowHeight={false}
                        autoWidth={true}
                        height={gridHeight}
                        theme={'light'}
                        columnsreorder={true}
                        columnsmenu={false}
                        onCelldoubleclick={onCelldoubleclick}

                    />
                }
                {state.isRulesOpen &&
                    <EmailCreator
                        close={closeRulesModal}
                        isOpen={state.isRulesOpen}
                        data={data}
                    />
                }
                {state.isModalOpen &&
                    <Modal
                        isOpen={state.isModalOpen}
                        addClass={modalClass}
                    >
                        <ConfirmDelete
                            modalClose={modalClose}
                            deleteRules={deleteRules}
                            modalName={modalName}
                            yesSaveChanges={addOrUpdateRules}
                            noSaveChanges={closeAllModal} />
                    </Modal>
                }
            </div>
            <EmailTemplatesFooter
                addRule={openRulesModal}
                enableFooterOption={state.isRulesOpen}
                cloneRules={cloneRules}
                closeRule={closeRulesModal}
                pauseRules={pauseRules}
                deleteRuleDialog={deleteRuleDialog}
                addOrUpdateRules={addOrUpdateRules}
            />
        </div>
    );
};

EmailTemplatesComponent.propTypes = {
    state: PropTypes.object,
    props: PropTypes.object,

};

export default withRouter(EmailTemplatesComponent);


