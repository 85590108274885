import React, { Component } from 'react';
import AddPOModal from './add-po-modal';
// import NotificationManager from '../../../common/components/notification';
import './addPo.css';

export class AddPORenderer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: false,
        };
    }

    render() {
        let addPO = '';
        if (this.props.node.data) {
            if (this.props.node.data.displayLevel == 'Color' && this.props.node.level == 1) {
                addPO = 'Add PO';
            }

            if (this.props.node.data.displayLevel == 'Style' && this.props.node.level == 0) {
                addPO = 'Add PO';
            }

            if (this.props.node.data.displayLevel == 'Size' && this.props.node.level == 2) {
                addPO = 'Add PO';
            }

            if (this.props.node.data.dataLevel == 'SINGLE') {
                addPO = 'Add PO';
            }
        }

        return (
            <div>
                {addPO}
            </div>
        );
    }
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////////////

export class AddPOEditor extends React.Component {
    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
        this.state = {
            notificationContent: false,
        };
    }

    isPopup() {
        return true;
    }

    componentWillMount() {
        // If Add PO is clicked before the item ready to flag is clicked system should give a
        // warning “The required product details for Item Ready to Order is missing”.
        // Users can click Ok and proceed to Adding the PO
        const rowData = this.props.node.data;
        if (!rowData['itemReadyToOrder'] || rowData['itemReadyToOrder'] == false) {
            // alert("The required product details for Item Ready to Order is missing");
            this.setState({notificationContent: true});
        }
    }

    /**
     * Gets the value when the component unmounts and pushes it for render
     * @return {string} cell value
     */
    getValue() {
        return this.props.value;
    }

    /**
    * Closes the modal
    */
    closeModal() {
        this.setState({ showPopup: false });
        this.props.api.stopEditing();
    }

    /**
     * Hides the notification by setting state
     */
    hideNotification() {
        this.setState({notificationContent: false});
    }

    render() {
        // Checks for the channel list in the data array and holds the values
        const productChannelList = this.props.node.data && this.props.node.data.channelIds ? this.props.node.data.channelIds : [];

        // Checks the display level of the record for validation
        const displayLevel = (this.props.node.data && this.props.node.data.displayLevel) ? this.props.node.data.displayLevel : null;

        let content = '';
        if (this.state.notificationContent == true) {
            content = 'The required product details for Item Ready to Order is missing';
        }


        if (this.props.node.data.dataLevel == 'SINGLE') {
            return (
                <div>
                    {
                        <div className="ag-modal-container">
                            <div className="ag-modal-holder">
                                <AddPOModal
                                    rowData = {this.props.node.data}
                                    productId = {this.props.node.data.chldProdId}
                                    data = {this.props}
                                    user = {this.props.user}
                                    modalClose = {this.closeModal}
                                    productChannelList = {productChannelList}
                                    channelsList = {this.props.channelsList}
                                    vendorId = {this.props.node.data.vendorId}
                                    api = {this.props.api}
                                />
                            </div>
                            {
                                this.state.notificationContent &&
                            <div className="notifi-iro-container">
                                <div className="addpo-content">
                                    {content}
                                    <div><button className="gray-button n-close" onClick={this.hideNotification.bind(this)}>Close</button></div>
                                </div>
                            </div>
                            }
                        </div>
                    }
                </div>
            );
        }


        if (displayLevel) {
            switch (displayLevel) {
                case 'Style':
                    if (this.props.node.level == 0) {
                        return (
                            <div>
                                {
                                    <div className="ag-modal-container">
                                        <div className="ag-modal-holder">
                                            {/* AddPOModal requires all the props passed as default props*/}
                                            <AddPOModal
                                                rowData = {this.props.node.data}
                                                productId = {this.props.node.data.parentProductId}
                                                data = {this.props}
                                                user = {this.props.user}
                                                modalClose = {this.closeModal}
                                                productChannelList = {productChannelList}
                                                channelsList = {this.props.channelsList}
                                                vendorId = {this.props.node.data.vendorId}
                                                api = {this.props.api}
                                            />
                                        </div>
                                        {
                                            this.state.notificationContent &&
                                    <div className="notifi-iro-container">
                                        <div className="addpo-content">
                                            {content}
                                            <div><button className="gray-button n-close" onClick={this.hideNotification.bind(this)}>Close</button></div>
                                        </div>
                                    </div>
                                        }
                                    </div>
                                }
                            </div>
                        );
                    } else {
                        return null;
                    }
                case 'Color':
                    if (this.props.node.level == 1) {
                        return (
                            <div>
                                {
                                    <div className="ag-modal-container">
                                        <div className="ag-modal-holder">
                                            {/* AddPOModal requires all the props passed as default props*/}
                                            <AddPOModal
                                                rowData = {this.props.node.data}
                                                productId = {this.props.node.data.intProdId}
                                                data = {this.props}
                                                user = {this.props.user}
                                                modalClose = {this.closeModal}
                                                productChannelList = {productChannelList}
                                                channelsList = {this.props.channelsList}
                                                vendorId = {this.props.node.data.vendorId}
                                                api = {this.props.api}
                                            />
                                        </div>
                                        {
                                            this.state.notificationContent &&
                                        <div className="notifi-iro-container">
                                            <div className="addpo-content">
                                                {content}
                                                <div><button className="gray-button n-close" onClick={this.hideNotification.bind(this)}>Close</button></div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                }
                            </div>
                        );
                    } else {
                        return null;
                    }
                case 'Size':
                    if (this.props.node.level == 2) {
                        return (
                            <div>
                                {
                                    <div className="ag-modal-container">
                                        <div className="ag-modal-holder">
                                            {/* AddPOModal requires all the props passed as default props*/}
                                            <AddPOModal
                                                rowData = {this.props.node.data}
                                                productId = {this.props.node.data.chldProdId}
                                                data = {this.props}
                                                user = {this.props.user}
                                                modalClose = {this.closeModal}
                                                productChannelList = {productChannelList}
                                                channelsList = {this.props.channelsList}
                                                vendorId = {this.props.node.data.vendorId}
                                                api = {this.props.api}
                                            />
                                        </div>
                                        {
                                            this.state.notificationContent &&
                                        <div className="notifi-iro-container">
                                            <div className="addpo-content">
                                                {content}
                                                <div><button className="gray-button n-close" onClick={this.hideNotification.bind(this)}>Close</button></div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                }
                            </div>
                        );
                    } else {
                        return null;
                    }
                default:
                    return null;
            }
        } else {
            return null;
        }
    }
}
// ////////////////////////////////////////////////////////////////////////////////////////

