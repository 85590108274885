import React from 'react';
import axios from 'axios';
import NotificationManager from '../../common/components/notification';
import * as utils from '../../common/utils';

import { ProductServices } from '../../product2/services/product-services';


class PiUploadModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            errorMsg: '',
            isValid: false,
            pdfUrl: this.props.piPdfUrl
        };

        this.closePIModal = this.closePIModal.bind(this);
    }

    onFileChange(event) {
        let _this = this;
        _this.setState({ isError: false });
        let file = document.getElementById('pi-file');
        let last = file.files[0].name.substring(file.files[0].name.lastIndexOf(".") + 1, file.files[0].name.length);
        if (last != 'csv' && last != 'pdf' && last.toLowerCase() != "json") {
            _this.setState({ isError: true, errorMsg: "Invalid file format.  Please upload files in CSV, JSON or PDF format." });
            _this.setState({ isValid: false });
            $(file).val('');
        } else {
            _this.setState({ isValid: true });
        }
    }

    uploadFile(event) {
        let _this = this;
        let file = document.getElementById('pi-file');
        let last = file.files[0].name.substring(file.files[0].name.lastIndexOf(".") + 1, file.files[0].name.length);
        if (last === 'pdf') {
            let formdata = new FormData();
            formdata.append('files', file.files[0]);
            formdata.append('productIdLst', _this.props.productId);

		    let headers = {
		          'Authorization': this.props.user.token,
		          'content-type': 'application/json'
		    };

            ProductServices.importFilePIUploadpdf(formdata)
                .then( (response) => {
		        console.log('productContainer helperComponents piUploadModal API call success data: ', response.data);
	            let data = response.data;

                    if (data.status) {
                        // _this.props.updatePIRecived(true, _this.props.productId); // updating PI received
                        NotificationManager({ message: "PI has been updated.", type: 1 });
                        _this.setState({ isValid: false, pdfUrl: data.data.pdfUrl });
                        document.getElementById("piupload-form").reset();
                        //_this.props.piReceivedModalClose(true);
                        //utils.openInNewTab(data.data.pdfUrl);
                    } else {
                        NotificationManager({ message: data.responseMessage, type: 4 });
                    }
		    })
		    .catch( error => {
		        console.log('productContainer helperComponents piUploadModal API call failed', error);
                    NotificationManager({ message: "Failed to update PI.", type: 4 });
		    });
        } else {
            let formdata = new FormData();
            formdata.append('files', file.files[0]);
            formdata.append('productIdLst', _this.props.productId);



		    let headers = {
		          'Authorization': this.props.user.token,
		          'content-type': 'application/json'
		    };

            ProductServices.importFilePIUpload(formdata)
                .then( (response) => {
		        console.log('productContainer helperComponents piUploadModal API call success data: ', response.data);
	            let data = response.data;

                    if (data.status) {
                        NotificationManager({ message: data.responseMessage, type: 1 });
                        _this.setState({ isValid: false, pdfUrl: '' });
                        _this.props.piReceivedModalClose(true);
                    } else {
                        NotificationManager({ message: data.responseMessage, type: 4 });
                    }
		    })
		    .catch( error => {
		        console.log('productContainer helperComponents piUploadModal API call failed', error);
                    NotificationManager({ message: "Failed to update PI.", type: 4 });
		    });
        }
    }

    closePIModal(key) {
        this.setState({ pdfUrl: '' });
        this.props.piReceivedModalClose(key, this.props.productId);
    }
    downloadFile() {
        utils.openInNewTab(this.state.pdfUrl);
    }

    deleteFile() {
        let productArray = this.props.productId;
        let _this = this;


        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
	  	};

	  ProductServices.deletePiForProduct(productArray)
	  .then( (response) => {
		  console.log('productContainer helperComponents piUploadModal API call success data: ', response.data);
		  let data = response.data;

		  if (data.status) {
                    _this.setState({ pdfUrl: '' });
			  	NotificationManager({ message: data.responseMessage, type: 1 });
		  } else {
			  	NotificationManager({ message: data.responseMessage, type: 4 });
		  }
	  })

	  .catch( error => {
		  console.log(error);
	  });
    }

    componentWillReceiveProps(props) {
        this.setState({ pdfUrl: props.piPdfUrl });
    }

    render() {
        return (
            <div>
                <div className="modal-header">
					Import PI from file
                    <span className="close" onClick={() => this.closePIModal(false)}>x</span>
                </div>
                <div className="modal-content">
                    <div className="pi-upload-form">
                        <form id="piupload-form" encType="multipart/form-data" multiple>
                            <div className="form-container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <input accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,application/pdf" type="file" id="pi-file" onChange={this.onFileChange.bind(this)} />
                                            {this.state.isError &&
												<span className="error-msg">
												    {this.state.errorMsg}
												</span>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <input type="button" disabled={(this.state.isValid) ? false : true} className="gray-button" value="Upload Records" onClick={this.uploadFile.bind(this)} />
                                <input type="button" disabled={(this.state.pdfUrl !== '') ? false : true} className="gray-button" value="Download" onClick={this.downloadFile.bind(this)} />
                                <input type="button" disabled={(this.state.pdfUrl !== '') ? false : true} className="gray-button" value="Delete" onClick={this.deleteFile.bind(this)} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default PiUploadModal;
