import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import AdminFooter from './helperComponents/adminFooter';
import CancelModal from './helperComponents/cancelModal';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import JqxGrid from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';


const WorkflowAllocationComponent = ({
    state,
    props,

    workflowAllocationGridRef,
    gridData,
    gridHeight,
    editSettings,
    applyChanges,
    cancelChanges,

    modalClose,

}) => {
    return (
        <div id="workFlowContainer">
            {state.isLoaded &&
                <div>
                    <div id="workFlowid">
                        <JqxGrid
                            ref={workflowAllocationGridRef}
                            width={'99%'}
                            source={gridData.dataAdapter}
                            pageable={false}
                            editable={true}
                            hierarchicalCheckboxes={true}
                            columnsResize={true}
                            columns={gridData.columns}
                            sortable={false}
                            autoRowHeight={false}
                            autoWidth={true}
                            height={gridHeight}
                            theme={'light'}
                            editSettings={editSettings}
                            columnsreorder={true}
                            enabletooltips={true}
                            enableellipsis={true}
                        />
                    </div>
                    <AdminFooter
                        userCount={state.TotalCount}
                        disabled={state.isDisabled}
                        applyChanges={applyChanges}
                        cancelChanges={cancelChanges}
                        applayChangesText={state.applyButtonText}
                    />
                </div>
            }
            <CancelModal
                isOpen={state.isCancelModalOpen}
                modalClose={modalClose}
            />
        </div>
    );
};

WorkflowAllocationComponent.propTypes = {
    state: PropTypes.object,
    props: PropTypes.object,

};

export default withRouter(WorkflowAllocationComponent);


