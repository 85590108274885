let token = localStorage.getItem('token');

if (!token && localStorage.getItem("user")) {
    token = JSON.parse(localStorage.getItem("user")).token;
}

export const headers = {
    'Authorization': token,
    'content-type': 'application/json'
};
