import React, { Component } from 'react';
import { connect } from 'react-redux';
import { rulesForAlerts } from './helperComponents/actions';
import NotificationManager from '../common/components/notification';
import axios from 'axios';
import EditAlertGridComponent from './Edit-alerts-gridComponent';
import AdminServices from '../admin/admin-services/admin-services';

class EditAlert extends Component {
    constructor(props) {
        super(props);
        const selectedRow = props.selectedRow;
        this.state = {
            formData: selectedRow,
            isFormValid: true,
            isConfirmOpen: false,
            submitBtnDisabled: true,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.confirmOpen = this.confirmOpen.bind(this);
        this.confirmClose = this.confirmClose.bind(this);
        this.cancelPopup = this.cancelPopup.bind(this);
    }

    confirmClose() {
        this.setState({ isConfirmOpen: false });
    }

    confirmOpen() {
        this.setState({ isConfirmOpen: true });
    }

    cancelPopup() {
        if (this.state.formData.alertName !== this.props.selectedRow.alertName) {
            this.confirmOpen();
        } else {
            this.props.modalClose();
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        const data = this.state.formData;
        const regex = /^[A-Za-z0-9']+(\s[A-Za-z0-9']+)*$/i;
        const _this = this;
        if (data.alertName.trim() !== '') {
            if (!data.alertName.match(regex)) {
                data.alertName = data.alertName.trim();
                data.alertName = data.alertName.replace(/\s\s+/g, ' ');
                $('#editAlert').val(data.alertName);
            }
            //  let alerObj={"id":data.amId,"value":data.alertName};

            const alerObj = {
                alertId: data.amId,
                alertName: data.alertName,
                alertType: null,
                roleid: 1,
                ruleid: data.appliedRuleId,
            }; //new request payload to edit an alert

            AdminServices.addAlert2(alerObj).then((response) => {
                const res = response;
                if (res.status == 'success') {
                    NotificationManager({ message: res.message, type: 1 });
                    _this.setState({ isFormValid: true });
                    _this.props.modalClose();
                    _this.props.updateRow(_this.state.formData);
                } else {
                    NotificationManager({ message: res.message, type: 4 });
                }
            }).catch((error) => {
                console.log('AdminRules edit-alert-grid Validation handleSubmit API call failed', error.response.data.message);
                NotificationManager({ message: error.response.data.message, type: 4 });
            });
        } else {
            NotificationManager({ message: 'There was an error occurred during the validation, please provide the valid data.', type: 4 });
            this.setState({ isFormValid: false });
        }
    }

    handleChange(e) {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        const { formData } = this.state;
        const newFormData = Object.assign({}, formData);
        newFormData[name] = value;
        let submitBtnDisabled = true;
        if (newFormData.alertName !== this.props.selectedRow.alertName) {
            submitBtnDisabled = false;
        }
        if (newFormData.appliedRuleId != this.props.selectedRow.appliedRuleId) {
            submitBtnDisabled = false;
        }
        this.setState({ formData: newFormData, submitBtnDisabled: submitBtnDisabled });
    }

    render() {
        return (
            <EditAlertGridComponent
                state={this.state}
                props={this.props}
                cancelPopup={this.cancelPopup}
                handleSubmit={this.handleSubmit}
                handleChange={this.handleChange}
                confirmClose={this.confirmClose}
                modalClose={this.modalClose}
            />
        );
    }
}

export default EditAlert;
