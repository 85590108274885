import React, { Component } from 'react';
import WorkflowAllocationComponent from './workflowAllocationComponent';
import GridDataBuilder from './helperComponents/dataBuilder';
import NotificationManager from '../common/components/notification';
import axios from 'axios';

class WorkflowAllocation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isDisabled: true,
            TotalCount: 0,
            workFlowData: [],
            updateWorkflowData: [],
            applyButtonText: 'Apply Changes',
            isCancelModalOpen: false,
        };
        this.applyChanges = this.applyChanges.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.loadData = this.loadData.bind(this);
        this.updateCancelChanges - this.updateCancelChanges.bind(this);
    }


    componentDidMount() {
        this.loadData(this.props.catId);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.catId != this.props.catId) {
            this.setState({ isLoaded: false, catId: nextProps.catId });
            this.loadData(nextProps.catId);
        }
    }

    /**
     * Getter for data
     * @param {*} catId
     */
    loadData(catId) {
        const req = { 'catId': catId };
        const postData = { 'id': this.props.user.groupId };
        const base = this;

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };
        axios({
            method: 'post',
            url: require('../common/configurations.js').workflowAllocation.getWorkflowData,
            headers: headers,
            data: req,
        })
            .then( (response) => {
                console.log('Admin WorkflowAllocation loadData API call success data: ', response.data);
                const res = response.data;
                if (res.responseCode === 200) {
                    // the data will be parsed as required for jqwidgets
                    base.gridData = GridDataBuilder(res, 'workflowAllocation');
                    base.setState({ isLoaded: true, workFlowData: res.data, updateWorkflowData: res.data, TotalCount: res.data.length });
                }
            })
            .catch( (error) => {
                console.log('Admin WorkflowAllocation loadData API call failed', error);
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.isLoaded !== this.state.isLoaded) {
            const _this = this;
            const workflowView = $('#workFlowid');
            workflowView.off();
            workflowView.on('change', 'select', function (event) {
                if (_this.state.isDisabled) {
                    _this.setState({ isDisabled: false });
                }
                const _me = $(this);
                const value = _me.val();
                const rowId = _me.data('row');
                const columnfield = _me.data('columnfield');

                _this.workFlowid.setcellvalue(rowId, columnfield, value);
                //Setting up the object with a Updated value to pass it to State variable
                const workflow = _this.state.updateWorkflowData;
                const newWorkflow = workflow;
                //Updating the only changed object with changed value
                newWorkflow[rowId][columnfield] = value;
                _this.setState({ updateWorkflowData: newWorkflow });
            });
        }
    }

    /**
     * Calls backend to save changes
     */
    applyChanges() {
        const _this = this;
        const workflowData = this.state.updateWorkflowData;
        _this.setState({ isDisabled: true, applyButtonText: 'Please Wait..' });
        if (workflowData.length > 0) {
            const headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json',
            };
            axios({
                method: 'post',
                url: require('../common/configurations.js').workflowAllocation.updateWorkflowData,
                headers: headers,
                data: workflowData,
            })
                .then( (response) => {
                    const data = response.data;

                    if (data.status) {
                        NotificationManager({
                            message: data.responseMessage,
                            type: 1,
                        });
                        _this.setState({ applyButtonText: 'Apply Changes' });
                    } else {
                        NotificationManager({
                            message: data.responseMessage,
                            type: 4,
                        });
                        _this.setState({ isDisabled: false, applyButtonText: 'Apply Changes' });
                    }
                })
                .catch( (error) => {
                    NotificationManager({
                        message: 'Error, Service error ' + error,
                        type: 4,
                    });
                    _this.setState({ isDisabled: false, applyButtonText: 'Apply Changes' });
                });
        } else {
            NotificationManager({
                message: 'Changes has not been affected',
                type: 4,
            });
        }
    }

    /**
     * Cancels current changes
     */
    cancelChanges() {
        this.setState({ isCancelModalOpen: true });
    }

    /**
     * Updates cancelled changes
     */
    updateCancelChanges() {
        const req = { 'catId': this.props.catId };
        const base = this;

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };
        axios({
            method: 'post',
            url: require('../common/configurations.js').workflowAllocation.getWorkflowData,
            headers: headers,
            data: req,
        })
            .then( (response) => {
                const res = response.data;

                if (res.responseCode === 200) {
                    // the data will be parsed as required for jqwidgets
                    base.gridData = GridDataBuilder(res, 'workflowAllocation');
                    base.setState({ isLoaded: true, workFlowData: res.data, updateWorkflowData: res.data, TotalCount: res.data.length, isDisabled: true });
                }
            })
            .catch( (error) => {
                console.log('Admin WorkflowAllocation updateCancelChanges API call failed: ', error);
            });
    }

    /**
     * Closes the modal
     * @param {*} key
     */
    modalClose(key) {
        switch (key) {
            case 'no':
                this.setState({ isCancelModalOpen: false });
                break;
            case 'yes':
                this.setState({ isLoaded: false });
                this.updateCancelChanges();
                this.setState({ isCancelModalOpen: false });
                break;
        }
    }

    render() {
        const footerHeight = 50;
        const headerHeight = 64;

        const gridHeight = window.innerHeight - footerHeight - headerHeight;
        const editSettings = {
            saveOnPageChange: true,
            saveOnBlur: true,
            saveOnSelectionChange: true,
            cancelOnEsc: true,
            saveOnEnter: true,
            editSingleCell: true,
            editOnDoubleClick: true,
            editOnF2: true,
        };

        return (
            <WorkflowAllocationComponent
                state = {this.state}
                props = {this.props}

                workflowAllocationGridRef={(grid) => {
                    this.workFlowid = grid;
                }}
                gridData = { this.gridData }
                gridHeight = { gridHeight }
                editSettings = { editSettings }

                applyChanges = {this.applyChanges}
                cancelChanges = {this.cancelChanges}

                modalClose = {this.modalClose}
            />

        );
    }
}

export default WorkflowAllocation;
