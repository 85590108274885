
export const addColumnDetails = (data) => {
    return {
        type:"ADD_COLUMN_DETAILS",
        data
    };
};

export const allEventDetails = (data) => {
    return {
        type:"ADD_ALL_EVENT_DETAILS",
        data
    };
};

export const eventTaskData = (data) => {
    return {
        type:"ADD_EVENT_TASK_DATA",
        data
    };
};

export const updateSelectedRows = (data) => {
    return {
        type:"UPDATE_SELECTED_ROWS",
        data
    };
};

export const addGridData = (data) => {
    return {
        type:"ADD_GRID_DETAILS",
        data
    };
};
