/**
 * column width declaration
 */
const columnWidth = {
    productId: 120,
    prodName: 300,
    activeNotes: 400,
    //required fields column width
    fields: '40%',
    fieldsToClone: '20%',
    readyToOrder: '20%',
    reqdToAdd: '20%',
    //rules fields column width
    description: '30%',
    details: '30%',
    ruleName: '25%',
    status: '15%',
};

const requiredFieldsList = ['fieldsToClone', 'readyToOrder', 'reqdToAdd'];

/**
 * Date Editor funtion for Jqwidgets
 * @param {*} row
 * @param {*} cellvalue
 * @param {*} editor
 * @param {*} cellText
 * @param {*} width
 * @param {*} height
 */
const createDateEditor = function (row, cellvalue, editor, cellText, width, height) {
    // construct the editor.
    cellvalue = cellvalue ? cellvalue : new Date();

    editor.jqxDateTimeInput({
        width: width,
        height: height,
        value: cellvalue,
        culture: 'en-US',
    });
};

/**
 * Init date
 * @param {*} row
 * @param {*} cellvalue
 * @param {*} editor
 * @param {*} celltext
 * @param {*} width
 * @param {*} height
 */
const initDateEditor = function (row, cellvalue, editor, celltext, width, height) {
    // set the editor's current value. The callback is called each time the editor is displayed.
    cellvalue = cellvalue ? cellvalue : new Date();

    editor.jqxDateTimeInput('setDate', cellvalue);
};

/**
 * Getter for selected date
 * @param {*} row
 * @param {*} cellvalue
 * @param {*} editor
 * @return {Date}
 */
const getDateEditor = function (row, cellvalue, editor) {
    // return the editor's value.
    return $(editor).jqxDateTimeInput('getDate');
};

/**
 * Including image in a column
 * @param {*} row
 * @param {*} datafield
 * @param {*} value
 * @return {object}
 */
const imageRenderer = function (row, datafield, value) {
    let image;

    if (value.length && value[0].imageName) {
        image = '<div class="product-image"><img width="60px" height="50px" src='
            + value[0].imageName + '/></div>';
    } else {
        image = '';
    }

    return image;
};

/**
 * swatchOnly checkbox renderer
 * @param {*} row
 * @param {*} columnfield
 * @param {*} value
 * @param {*} defaulthtml
 * @param {*} columnproperties
 * @param {*} rowdata
 * @return {object}
 */
const swatchOnlyCellRender = function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
    const disabled = (rowdata['Default view'] === columnfield) ? 'disabled' : '';

    if (value.status === 'V' || value.status === 'DEF') {
        return '<span class="blue-checkbox usergroup-cell-control"><input class="usergroup-view" type="checkbox" checked ' + disabled + ' data-fieldname="' +
            columnfield + '" data-row="' +
            row + '" ><label></label></span>';
    } else {
        return '<span class="blue-checkbox usergroup-cell-control"><input class="usergroup-view" type="checkbox" data-fieldname="' +
            columnfield + '"  data-row="' +
            row + '" ><label></label></span>';
    }
};

/**
 * UserGroup Title
 * @param {*} row
 * @param {*} columnfield
 * @param {*} value
 * @param {*} rowdata
 * @return {object}
 */
const CellDoubleClickEvent = function (row, columnfield, value, rowdata) {
    return '<div class="grid-text gridGroupTitle" data-row="' + row + '">' + value + '<span class="icon-expand-grid right"></span></div>';
};

/**
 * checkbox renderer
 * @param {*} row
 * @param {*} columnfield
 * @param {*} value
 * @param {*} rowdata
 * @return {object}
 */
const requiredFieldcheckboxes = function (row, columnfield, value, rowdata) {
    if (value) {
        return '<div class="blue-checkbox custom-grid-checkbox"><input class="required-checkbox" type="checkbox" data-fieldname="' +
            columnfield + '" checked data-row="' + row + '" ><label></label></div>';
    } else {
        return '<div class="blue-checkbox custom-grid-checkbox"><input class="required-checkbox" type="checkbox" data-fieldname="' +
            columnfield + '" data-row="' + row + '" ><label></label></div>';
    }
};

/**
 * UserGroup Status - Enable/Disable
 * @param {*} row
 * @param {*} columnfield
 * @param {*} value
 * @param {*} rowdata
 * @return {object}
 */
const StatusDropDownInitRenderer = function (row, columnfield, value, rowdata) {
    if (value || value === 'Enabled') {
        return '<div class="grid-dropdown"><select class="greenText" onchange="this.className= this.options[this.selectedIndex].className" data-row="' + row + '" data-userid=""><option value="true" selected class="greenText">Enabled</option><option value="false" class="redText">Disabled</option></select>';
    } else {
        return '<div class="grid-dropdown"><select class="redText" onchange="this.className= this.options[this.selectedIndex].className" data-row="' + row + '" data-userid=""><option value="true" class="greenText">Enabled</option><option value="false" class="redText" selected>Disabled</option></select>';
    }
};

/**
 * AdminRules Status - Active/Paused
 * @param {*} row
 * @param {*} columnfield
 * @param {*} value
 * @param {*} rowdata
 * @return {object}
 */
const AdminRulesStatus = function (row, columnfield, value, rowdata) {
    if (value === 'Active') {
        return '<div class="jqx-grid-cell-left-align rulesActiveColor" style="margin-top: 6px;" data-row="' + row + '">Active</div>';
    } else {
        return '<div class="jqx-grid-cell-left-align rulesPausedColor" style="margin-top: 6px;" data-row="' + row + '">Paused</div>';
    }
};

/**
 * AdminRules Level - Copy Level/Display Level/None
 * @param {*} row
 * @param {*} columnfield
 * @param {*} value
 * @param {*} rowdata
 * @return {object}
 */
const AdminRulesLevel = function (row, columnfield, value, rowdata) {
    if (value === 'Copy Level') {
        return '<div class="jqx-grid-cell-left-align rulesActiveColor" style="margin-top: 6px;" data-row="' + row + '">Copy Level</div>';
    }

    if (value === 'Display Level') {
        return '<div class="jqx-grid-cell-left-align rulesActiveColor" style="margin-top: 6px;" data-row="' + row + '">Display Level</div>';
    }

    if (value === 'None') {
        return '<div class="jqx-grid-cell-left-align rulesActiveColor" style="margin-top: 6px;" data-row="' + row + '">None</div>';
    }
};

// Ends UserGroup Status - Enable/Disable

/**
 * Grid data builder is used to create the object format required for
 * Jqwidgets.
 * @param {*} res
 * @param {*} pageName
 * @return {object}
 */
function GridDataBuilder(res, pageName) {
    // dataField is used for providing type of data and its name.
    // columnList is used to define column name, type, and extra fields
    //  to define additional plugins inside the cell eg: drop down, calender ...,
    const dataField = [];
    const columnList = [];
    let primayKey = res.columnDetails[0].fieldName;
    let source = {};
    // check for the type of fieldname future

    //sort columndetails in product pageName
    res.columnDetails.sort(function (a, b) {
        return a.order - b.order;
    });

    for (let i = 0; i < res.columnDetails.length; i++) {
        dataField.push({
            'name': res.columnDetails[i].fieldName,
            'type': res.columnDetails[i].type.toLowerCase()
        });

        /* Enable the below condition after pk(primary key) availabel from backend
         *  in JSON response.
         */

        if (res.columnDetails[i].hasOwnProperty('pk') && res.columnDetails[i].pk) {
            primayKey = res.columnDetails[i].fieldName;
        }

        columnList.push(columnObj(res.columnDetails[i]));
    }

    /**
     * UC words
     * @param {string} str
     * @return {string}
     */
    function titleCase(str) {
        return str.toLowerCase().split(' ').map(function (word) {
            return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
    }

    /**
     * columnobj function will  return the object fields
     *  based on the field type in list.
     * e.g. for date type and other types need to build the cellrender function.
     * @param {*} list
     * @return {object}
     */
    function columnObj(list) {
        const obj = {};
        const titleCaseStr = titleCase(list.columnTitle);

        obj.text = '<span title="' + titleCaseStr + '">' + titleCaseStr + '</span>';
        obj.dataField = list.fieldName;
        obj.width = (columnWidth[list.fieldName]) ? columnWidth[list.fieldName] : 160;
        obj.hidden = !list.visibility;

        /* if list type is date, pass the methods related to date to intialize
         *  date picker
         */
        if (list.fieldName === 'title') {
            obj.cellsrenderer = CellDoubleClickEvent;
            obj.editable = false;
            obj.pinned = true;
        } else if (list.type === 'image') {
            obj.editable = false;
            obj.cellsrenderer = imageRenderer;
        } else if (list.fieldName == 'swatchOnly') {
            obj.cellsrenderer = swatchOnlyCellRender;
            obj.editable = false;
            obj.cellsAlign = 'center';
        } else if (pageName === 'requiredFields' &&
                   requiredFieldsList.indexOf(list.fieldName) >= 0) {
            obj.cellsrenderer = requiredFieldcheckboxes;
            obj.editable = false;
            obj.cellsAlign = 'center';
        } else if (list.fieldName === 'enabled') {
            obj.text = '<span title="Status">Status</span>';
            obj.cellsrenderer = StatusDropDownInitRenderer;
            obj.editable = false;
            obj.cellsAlign = 'center';
            obj.pinned = true;
            obj.enabletooltips = false;
        } else if (list.fieldName === 'marketing' || list.fieldName === 'product' || list.fieldName === 'creative' || list.fieldName === 'publication' || list.fieldName === 'user') {
            obj.cellclassname = 'greybg';
            obj.enabletooltips = false;
        }

        if (pageName === 'userGroup') {
            if (list.order != 2 && list.fieldName != 'enabled') {
                obj.cellsrenderer = swatchOnlyCellRender;
                obj.editable = false;
                obj.cellsAlign = 'center';
                obj.enabletooltips = false;
            }

            if (list.fieldName === 'title') {
                obj.sortable = true;
            } else {
                obj.sortable = false;
            }
        }

        if (pageName === 'rules') {
            if (list.fieldName === 'status') {
                obj.cellclassname = 'activeColor';
                obj.cellsrenderer = AdminRulesStatus;
            }

            if (list.fieldName === 'level') {
                obj.cellclassname = 'activeColor';
                obj.cellsrenderer = AdminRulesLevel;
            }
        }

        return obj;
    }

    if (pageName == 'product') {
        const obj = {};
        obj.text = '<span class=\'header-checkbox\' data-checked=\'false\'></span>';
        obj.dataField = null;
        obj.width = 50;
        obj.editable = false;
        obj.sortable = false;

        // place the checkboxs in first columnList
        columnList.unshift(obj);
    }

    source = {
        dataType: 'json',
        dataFields: dataField,
        id: primayKey,
        localdata: res.data,
    };

    const dataAdapter = new $.jqx.dataAdapter(source);

    return {
        dataAdapter: dataAdapter,
        columns: columnList,
    };
}

export default GridDataBuilder;
