import React, { Component } from 'react';
import Modal from '../../../modals/common-modal';
import ConfirmSubmit from '../confirmsubmit';
import axios from 'axios';

const constants = require('../../../common/constants.js');
const utils = require('../../../common/utils.js');
import NotificationManager from '../../../common/components/notification';

import { ProductServices } from '../../services/product-services';

class AddCopyModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: this.props.copyData.status ? this.props.copyData.status : '',
            date: this.props.copyData.date ? this.props.copyData.date : '',
            buyerName: this.props.copyData.buyername ? this.props.copyData.buyername : '',
            isModalOpen: false,
            displayName: this.props.copyData.displayName ? this.props.copyData.displayName : '',
            approvalDisabled: true,
            afterAPIStatus: null,
            displayNameMaxLength: 100,
            copyNoteMaxLength: 100,
            prodId: [],
            imageData: null,
            dispImage: null,
        };

        this.copyData = {
            productId: '',
            displayName: this.props.copyData.displayName ? this.props.copyData.displayName : '',
            shortdesc: this.props.copyData.shortdesc ? this.props.copyData.shortdesc : '',
            longdesdc: this.props.copyData.longdesdc ? this.props.copyData.longdesdc : '',
            copyNotes: this.props.copyData.copyNotes ? this.props.copyData.copyNotes : '',
        };
        this.requiredFields = ['displayName', 'shortdesc', 'longdesdc'];
        this.AllFields = ['shortdesc', 'longdesdc', 'displayName'];
        this.validationData = {};
        this.bgClassList = {};
        this.NotEmpty = false;

        this.submitHandler = this.submitHandler.bind(this);
        this.submitForApproval = this.submitForApproval.bind(this);
        this.showCopyNotes = this.showCopyNotes.bind(this);
        this.textareaChange = this.textareaChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.approve = this.approve.bind(this);
        this.reject = this.reject.bind(this);
        this.closeConfirmModal = this.closeConfirmModal.bind(this);
        this.openConfirmModal = this.openConfirmModal.bind(this);
        this.confirmApproveSubmit = this.confirmApproveSubmit.bind(this);
        this.checkObjHasValueInRequiredKeys = this.checkObjHasValueInRequiredKeys.bind(this);
        this.didValueChanged = this.didValueChanged.bind(this);
        this.closeAllModal = this.closeAllModal.bind(this);
        this.confirmSaveChanges = this.confirmSaveChanges.bind(this);
        this.getAllElementValue = this.getAllElementValue.bind(this);
        this.ApplyBGColorClassName = this.ApplyBGColorClassName.bind(this);
        this.ApplyBGColorClassName(); // Call @ only once at the time of loading
        this.changeImage = this.changeImage.bind(this);
    }

    /**
     * Opens the confirm popup
     */
    openConfirmModal() {
        this.setState({ isModalOpen: true });
    }

    /**
     * Closes the confirm popup
     */
    closeConfirmModal() {
        this.setState({ isModalOpen: false });
    }

    /**
     * Closes all the popups including parent
     */
    closeAllModal() {
        this.props.modalClose();
        this.closeConfirmModal();
    }

    /**
     * Checks for any changes in the copynotes textarea
     * @param {*} e Captures event
     */
    textareaChange(e) {
        this.copyData.copyNotes = e.target.value;
        if (e.target.value.length > 3) {
            $('.submitBtn').removeAttr('disabled');
        } else {
            $('.submitBtn').attr('disabled', true);
        }
    }

    /**
     * Show Copy notes when click reject button and enable submit btn
     * @param {event} e
     */
    showCopyNotes(e) {
        $(e.currentTarget).addClass('active');
        $('.copynotes').show();
        $('.submitBtn').show();
        $('#copynotes').focus();
        if ($('#copynotes').val().length > 3) {
            $('.submitBtn').removeAttr('disabled');
        }
    }


    componentWillMount() {
        const _this = this;
        // adding this to get image data
        const req = {'chldProuctId': [this.props.currentRowData.productId], 'dataLevel': this.props.currentRowData.dataLevel };

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        axios({
            method: 'post',
            url: require('../../../common/configurations.js').product.getImageLst,
            headers: headers,
            data: req,
        })
            .then( (response) => {
                if (response.data.data[0].images.length > 0) {
                    let imageArr = response.data.data[0].images;
                    _this.setState({imageData: imageArr, dispImage: imageArr[0].imageUrl});
                } else {
                    _this.setState({ noImageText: 'Image not available for this product' });
                }
            })
            .catch( (error) => {
                console.log('productContainer helperComponents add-copy-modal API call failed', error);
            });
    }

    componentDidMount() {
        const _this = this;
        if ([constants.ROLES.BUYER, constants.ROLES.ADMIN].indexOf(this.props.user.defaultRole) !== -1) { // Diables submit button for all roles apart from buyer
            $('.submitBtn').attr('disabled', true);
        }

        let contenteditable = 'true';
        if ([constants.ROLES.BUYER, constants.ROLES.ADMIN].indexOf(this.props.user.defaultRole) > -1 || window.readableFields.indexOf('copywrite') > -1) { // Disables form from being editable
            contenteditable = 'false';
        }

        /**
         * Editor for the textareas
         */
        $('.jqte-test').jqte({
            // $('.jqte-test').jqte({
            change: function () {
                _this.getAllElementValue();
                if (_this.didValueChanged) {
                    $('.saveBtn').removeAttr('disabled');
                    if (['Approved', 'Submitted'].indexOf(_this.props.copyData.status) === -1) {
                        _this.setState({ approvalDisabled: false });
                    }
                } else {
                    $('.saveBtn').attr('disabled');
                }
            },
            contenteditable: contenteditable,
        });

        // $('.jqte-test').change(
        //   function(){
        //     _this.getAllElementValue();
        //     if(_this.didValueChanged){
        //       $('.saveBtn').removeAttr('disabled');
        //       if(["Approved","Submitted"].indexOf(_this.props.copyData.status)===-1){
        //         _this.setState({approvalDisabled:false});
        //       }
        //     }else{
        //       $('.saveBtn').attr('disabled');
        //     }
        //   });

        // //////////////////////////////// saves the Product Id to state to it can be used anywhere //////////////////////////////////

        let sendId = null;
        const newArr = []; // ID's to be sent to the backend for fetching the data

        if (this.props.currentRowData.dataLevel == 'SINGLE') {
            sendId = this.props.currentRowData.chldProdId;
        } else if (this.props.currentRowData.dataLevel == 'PARENT') {
            sendId = this.props.currentRowData.parentProductId;
        } else if (this.props.currentRowData.dataLevel == 'INTERMEDIATE') {
            sendId = this.props.currentRowData.intProdId;
        } else if (this.props.currentRowData.dataLevel == 'CHILD') {
            sendId = this.props.currentRowData.chldProdId;
        }

        newArr.push(sendId);

        this.setState({ prodId: newArr });
    }

    /**
     * Calls to the backend save the data for approval of copy
     * @param {object} data Data object sent to the backend to save the data
     * @param {*} approve Status of the copy being sent
     */
    approval(data, approve) {
        const _this = this;

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        ProductServices.approveByBuyer(data)
            .then((response) => {
                console.log('productContainer helperComponents add-copy-modal API call success data: ', response.data);
                const res = response.data;

                NotificationManager({ message: res.message, type: 1 });
                let status = 'rejected';
                if (approve) {
                    status = 'approved';
                }
                // _this.props.setCellValueAndCopyToChild('copyStatus',status);
                _this.props.modalClose(res.currentStatus);
                _this.setState({ afterAPIStatus: res.currstatusentStatus });
            })
            .catch((error) => {
                console.log('productContainer helperComponents add-copy-modal API call failed', error);
                NotificationManager({ message: error, type: 4 });
            });
    }

    /**
     * Formats the object that is accepted by the backend
     * @param {*} e Event is being captrued
     */
    approve(e) {
        const _this = this;
        $(e.currentTarget).parent().find('.rejectBtn').removeClass('active');
        const postdata = _this.state.prodId.map((e, i) => {
            const data = {
                'productcode': e,
                'status': true,
                'processName': 'copywritter',
                'copynotes': _this.copyData.copyNotes,
                'userid': this.props.user.userId,
            };
            // _this.props.productGrid.setCellValue(e,'isCopywriterApproved',true);
            return data;
        });
        // _this.props.setCellValueAndCopyToChild('isCopywriterApproved',true);
        this.approval(postdata, 1);
    }

    /**
     * Formats the object that is accepted by the backend
     * @return {void}
     */
    reject() {
        const _this = this;
        const postdata = _this.state.prodId.map((e, i) => {
            const data = {
                'productcode': e,
                'status': false,
                'processName': 'copywritter',
                'copynotes': _this.copyData.copyNotes,
                'userid': this.props.user.userId,
            };
            return data;
        });
        if (postdata[0].copynotes.length >= 100) {
            NotificationManager({
                message: 'Please enter less than 100 characters',
                type: 4,
            });
            return false;
        } else {
            this.approval(postdata, 0);
        }
    }

    /**
     * Handles the submit and calls the backend to save the data
     * @param {object} e Event being captrued
     */
    submitHandler(e) {
        if (e) {
            e.preventDefault();
        }
        const _this = this;
        _this.getAllElementValue();

        this.checkObjHasValueInRequiredKeys(this.copyData);
        if (this.validationData.valid) {
            const postdata = _this.state.prodId.map((e, i) => {
                const newobj = Object.assign({}, _this.copyData);
                newobj.productId = e;
                return newobj;
            });


            const headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json',
            };

            ProductServices.updateCopyWritter(postdata)
                .then((response) => {
                    console.log('productContainer helperComponents add-copy-modal API call success data: ', response.data);
                    const res = response.data;

                    NotificationManager({ message: res.message, type: 1 });

                    // _this.props.setCellValueAndCopyToChild('copywrite','123');
                    _this.props.modalClose(res.currentStatus);
                    _this.setState({ afterAPIStatus: res.currstatusentStatus });
                })
                .catch((error) => {
                    console.log('productContainer helperComponents add-copy-modal API call failed', error);
                    NotificationManager({ message: error, type: 4 });
                });
        } else {
            NotificationManager({ message: 'Please enter atlease one description', type: 4 });
        }
    }

    /**
     * Handles the submit event
     * @param {*} e Event being captured
     */
    submitForApproval(e) {
        e.preventDefault();
        // Validate Keyword data
        this.getAllElementValue();
        this.checkObjHasValueInRequiredKeys(this.copyData);
        if (this.validationData.valid) {
            if (this.validationData.force) {
                this.modalDialog = 'SubmitConfirm';
                this.openConfirmModal();
            } else {
                this.confirmApproveSubmit();
            }
        } else {
            NotificationManager({ message: 'Please enter atlease one keyword' });
        }
        /**/
    }

    /**
     * Getter for the values in the form
     */
    getAllElementValue() {
        const shortDesc = $('.editor-short-desc').val();
        const fullDesc = $('.editor-long-desc').val();
        this.copyData.shortdesc = shortDesc ? $.trim(shortDesc) : '';
        this.copyData.longdesdc = fullDesc ? $.trim(fullDesc) : '';
        this.copyData.displayName = $.trim($('.displayName').val());
    }

    /**
     * Confimation popup check before submitting for approval
     */
    confirmApproveSubmit() {
        const _this = this;
        _this.getAllElementValue();
        const postdata = _this.state.prodId.map((e, i) => {
            const newobj = Object.assign({}, _this.copyData);
            newobj.productId = e;
            return newobj;
        });


        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        ProductServices.submitCopyWritter(postdata)
            .then((response) => {
                console.log('productContainer helperComponents add-copy-modal API call success data: ', response.data);
                const res = response.data;

                NotificationManager({ message: res.message, type: 1 });
                // _this.props.setCellValueAndCopyToChild('copyStatus','submitted');
                _this.props.modalClose(res.currentStatus);
                _this.setState({ afterAPIStatus: res.currstatusentStatus });
            })
            .catch((error) => {
                console.log('productContainer helperComponents add-copy-modal API call failed', error);
                NotificationManager({ message: error, type: 4 });
            });
    }

    /**
     * Checks and validates the object
     * @param {object} obj Object data saved by the form
     */
    checkObjHasValueInRequiredKeys(obj) {
        const needcount = this.requiredFields.length;
        this.validationData = { valid: false, validcount: 0, needcount: needcount };
        for (let i = 0; i < needcount; i++) {
            let val = obj[this.requiredFields[i]];
            val = $('<div/>').html(val).text();
            if (val != null && val != undefined && val != '' && val.localeCompare('') != 0) {
                this.validationData.valid = true;
                this.validationData.validcount++;
            }
        }
        if (this.validationData.validcount > 0 && this.validationData.validcount !== this.requiredFields.length) {
            this.validationData.force = true;
        }
    }

    /**
     * Showing popup to ask confirmation to cancel(Yes/No/Cancel)
     */
    confirmSaveChanges() {
        let getCurrentStatus;
        if (this.state.afterAPIStatus) {
            getCurrentStatus = this.state.afterAPIStatus;
        } else if (this.state.afterAPIStatus == null) {
            getCurrentStatus = this.state.status;
        } else {
            getCurrentStatus = this.state.status;
        }
        this.getAllElementValue();
        if (this.didValueChanged() && [constants.ROLES.BUYER].indexOf(this.props.user.defaultRole) === -1) {
            this.modalDialog = 'SaveChangesCancel';
            this.openConfirmModal();
        } else {
            this.props.modalClose(getCurrentStatus);
        }
    }

    /**
     * Checks if any values on the form have been changed
     * @return {boolean} checks for value change
     */
    didValueChanged() {
        let isChanged = false;
        for (let i = 0; i < this.AllFields.length; i++) {
            let prev = this.props.copyData[this.AllFields[i]];
            let next = this.copyData[this.AllFields[i]];
            prev = prev !== null ? $('<div/>').html(prev).text() : '';
            next = next !== null ? $('<div/>').html(next).text() : '';
            if (prev.localeCompare(next) !== 0) {
                isChanged = true;
                break;
            }
        }
        return isChanged;
    }

    /**
     * Checks if there are any changes in the display name input
     * @param {event} event Event handler data
     */
    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.copyData[name] = value;
        this.setState({ [name]: value });
        if (this.didValueChanged) {
            $('.saveBtn').removeAttr('disabled');
            if (['Approved', 'Submitted'].indexOf(this.props.copyData.status) === -1) {
                this.setState({ approvalDisabled: false });
            }
        } else {
            $('.saveBtn').attr('disabled');
            this.setState({ approvalDisabled: true });
        }
    }

    /**
     * Apply bg color based on status
     */
    ApplyBGColorClassName() {
        const currentStatus = this.state.status.toLowerCase();
        for (let i = 0; i < this.requiredFields.length; i++) {
            this.bgClassList[this.requiredFields[i]] = '';
            if (this.props.copyData[this.requiredFields[i]]) {
                this.bgClassList[this.requiredFields[i]] = 'notempty';
                this.NotEmpty = true;
                if (['approved', 'rejected', 'submitted'].indexOf(currentStatus) !== -1) {
                    this.bgClassList[this.requiredFields[i]] = currentStatus;
                }
            }
        }
    }

    /**
     * Changes the main image based on the thumbnail clicked
     */
    changeImage(event) {
        const _this = this;
        const imgID = event.target.id;
        const ImgData = this.state.imageData;
        ImgData.forEach((i, x) => {
            if (i.imageId == imgID) {
                _this.setState({ dispImage: i.imageUrl });
            }
        });
    }

    render() {
        const tools = ' font size | bold italic underline | background  | left center right | outdent indent | ul ol';
        let isShowHideCopyNotes = { display: 'none' }; // Visibility of the copynotes
        let isCopyNotesDisabled = 'true'; // Enable/Disable copynotes textarea
        let isShowApproveRejectBtn = false; // Enable/Disable Approve & Reject buttons
        let isDisableRejectBtn = false; // Enable/Disable Reject button
        let isDisableApproveBtn = false; // Enable/Disable Approve button
        let statusClassName = ''; // Stores the classname for status
        let showStatusSec = false; // Enable/Disable status
        const currentStatus = this.state.status.toLowerCase(); // Stores current status of the copy popup
        let popupheader = 'Add/Edit Copy'; // Stores default name of the popup header
        const dataString = this.props.copyData.date; // Stores date when copy was Approved/Rejected
        const date = new Date(dataString); // new Date variable
        const dateFormat = constants.MONTHNAMEOFYEAR[date.getMonth()] + ' ' + utils.nth(date.getDate()) + ' ' + date.getFullYear(); // Formatting date
        const modalClass = 'ConfirmSubmitModal'; // Classname for confirm popup
        let formFieldDisable = false; // Enable/Disable the fields in the form

        /**
         * Access conditions set using variables defined above
         */
        if (currentStatus === 'approved' || currentStatus === '') {
            isDisableApproveBtn = true;
            isDisableRejectBtn = true;
        }
        if (['rejected', 'approved'].indexOf(currentStatus) !== -1) {
            isDisableRejectBtn = true;
        }
        if (this.copyData.copyNotes && this.copyData.copyNotes !== '') {
            isShowHideCopyNotes = {};
        }
        if (this.state.status) {
            statusClassName = currentStatus;
            if (['approved', 'rejected'].indexOf(currentStatus) !== -1) {
                showStatusSec = true;
            }
        }
        if (['approved', 'submitted', 'rejected'].indexOf(currentStatus) === -1 && this.NotEmpty) {
            this.state.approvalDisabled = false;
        }
        if ([constants.ROLES.BUYER].indexOf(this.props.user.defaultRole) !== -1) {
            popupheader = 'Approve Copy';
            isShowApproveRejectBtn = true;
            formFieldDisable = true;
            isCopyNotesDisabled = '';
            this.state.approvalDisabled = true;
        }
        if ([constants.ROLES.ADMIN].indexOf(this.props.user.defaultRole) !== -1 || window.readableFields.indexOf('copywrite') > -1) {
            popupheader = 'View Copy';
            isShowApproveRejectBtn = false;
            formFieldDisable = true;
            this.state.approvalDisabled = true;
            isCopyNotesDisabled = 'true';
        }
        if (this.props.copyData.status == 'Approved' || this.props.copyData.status == 'approved') {
            isCopyNotesDisabled = true;
            // $('#copynotes').css({"background":"#6bf183"})
            $('.addCopyDetails .description .jqte_editor, #copynotes').css({ 'background': '#6bf183', 'color': '#545454' });
        }
        if (this.props.copyData.status == 'rejected' || this.props.copyData.status == 'Rejected') {
            $('.addCopyDetails .description .jqte_editor').css({ 'background': '#cd5c5c', 'color': '#545454' });
        }

        let imgThumbs = this.state.imageData && this.state.imageData.map((i, x) => {
            return <img key={i.imageId} id={i.imageId} src={i.imageUrl} onClick={this.changeImage} />;
        });

        return (

            <div>
                <div className="modal-header">
                    {popupheader}
                    <span className="close" onClick={this.confirmSaveChanges}>x</span>
                </div>
                <div className="modal-content">
                    { this.state.imageData ?
                        <div className="imageContainer">
                            <div className="imageThumbs">
                                {imgThumbs}
                            </div>
                            <div className="imageBig">
                                <img src={this.state.dispImage}/>
                            </div>
                        </div>
                        :
                        <div className="imageContainer">
                            <div className="noImageText">{this.state.noImageText}</div>
                        </div>
                    }
                    <div className="add-copy modal-form">
                        <form className="addCopyDetails" name="addCopyDetails" onSubmit={this.submitHandler}>
                            <div className="form-container scroll">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <div className="prodName">
                                                {this.props.currentRowData.prodName}
                                            </div>
                                            {showStatusSec &&
                                                <div className={'status ' + statusClassName}>{this.state.status}</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Display Name</label>
                                            <input type="text" className={'form-control col-12 displayName ' + this.bgClassList.displayName}
                                                name="displayName" value={this.state.displayName} onChange={this.handleChange}
                                                maxLength={this.state.displayNameMaxLength} disabled={formFieldDisable} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Product Copy</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className={'description ' + this.bgClassList.shortdesc}>
                                            <textarea name="textarea" className="jqte-test editor-short-desc" value={this.copyData.shortdesc} />

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Product Details</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className={'description ' + this.bgClassList.longdesdc}>
                                            <textarea name="textarea" className="jqte-test editor-long-desc" value={this.copyData.longdesdc} />
                                            {/*
                        <JqxEditor ref={(editor)=>{this.fullDesc= editor}} min-height={80} disabled={formFieldDisable}>
                          {this.copyData.longdesdc}
                        </JqxEditor>
                        */}
                                        </div>
                                    </div>
                                </div>
                                {isShowApproveRejectBtn &&
                                    <div className="row">
                                        <div className="col-12 addcopy-buyer-action">
                                            <input type="button" disabled={isDisableRejectBtn} className="gray-button rejectBtn" value="Reject" onClick={this.showCopyNotes} />
                                            <input type="button" disabled={isDisableApproveBtn} className="gray-button" value="Approve" onClick={this.approve} />
                                        </div>
                                    </div>
                                }
                                <div className="row copynotes" style={isShowHideCopyNotes}>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Copy Notes</label>
                                            <textarea disabled={isCopyNotesDisabled}
                                                className="form-control col-12 texrarea-fld" name="copynotes" id="copynotes"
                                                maxLength={this.state.copyNoteMaxLength} cols="7" onChange={this.textareaChange}
                                                defaultValue={this.copyData.copyNotes} />

                                        </div>
                                    </div>
                                </div>
                                {showStatusSec &&
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <p>&lt;{this.state.status}&gt; by {this.state.buyerName}, {dateFormat}</p>
                                        </div>
                                    </div>
                                </div>
                                }
                                {isShowApproveRejectBtn &&
                                <div className="modal-footer text-center">
                                    <input style={isShowHideCopyNotes} type="button" className="gray-button submitBtn" value="Submit" onClick={this.reject} />
                                    {this.props.user.defaultRole === constants.ROLES.ADMIN &&
                                        <input type="submit" className="gray-button saveBtn" value="Save" />
                                    }
                                </div>
                                }
                                {!isShowApproveRejectBtn &&
                                <div className="modal-footer text-right">
                                    <input type="submit" disabled="true" className="gray-button saveBtn" value="Save" />
                                    <input type="button" disabled={this.state.approvalDisabled} className="gray-button submitApprove" value="Submit for Approval" onClick={this.submitForApproval} />
                                </div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
                {this.state.isModalOpen &&
                    <Modal isOpen={this.state.isModalOpen} addClass={modalClass}>
                        <ConfirmSubmit dialog={this.modalDialog} modalClose={this.closeConfirmModal} confirmApproveSubmit={this.confirmApproveSubmit}
                            validationData={this.validationData} yesSaveChange={this.submitHandler} noSaveChange={this.closeAllModal} />
                    </Modal>
                }
            </div>
        );
    }
}

export default AddCopyModel;
