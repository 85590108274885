import React from 'react';
import axios from 'axios';
import { ProductServices } from '../../services/product-services';

export class KeywordRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const copyLevel = (this.props.data && this.props.data.copyLevel) ? this.props.data.copyLevel : null;
        const IconDisplay = <div className="ag-popupIndicator"><img src={require('../../../common/icons/Expand Grid.svg')} /></div>;

        if (this.props.node.data) {
            if (this.props.node.data.dataLevel == 'SINGLE') {
                return (
                    <div>
                        {IconDisplay}
                    </div>
                );
            }
        }

        if (copyLevel) {
            switch (copyLevel) {
                case 'Style':
                    if (this.props.node.level == 0) {
                        return (
                            <div>
                                {IconDisplay}
                            </div>
                        );
                    } else {
                        return null;
                    }
                case 'Color':
                    if (this.props.node.level == 1) {
                        return (
                            <div>
                                {IconDisplay}
                            </div>
                        );
                    } else {
                        return null;
                    }
                case 'Size':
                    if (this.props.node.level == 2) {
                        return (
                            <div>
                                {IconDisplay}
                            </div>
                        );
                    } else {
                        return null;
                    }
                default: return null;
            }
        } else {
            return null;
        }
    }
}


export class KeywordEditor extends React.Component {
    constructor(props, context) {
        super(props, context);
    }


    /**
     * Setter for data and validation when popup opens
     */
    componentWillMount() {
        // this.props.openKeywordPopup();

        let flag = false;

        if (this.props.node.data.copyLevel == 'Color' && this.props.node.level == 1) {
            flag = true;
        }

        if (this.props.node.data.copyLevel == 'Style' && this.props.node.level == 0) {
            flag = true;
        }

        if (this.props.node.data.copyLevel == 'Size' && this.props.node.level == 2) {
            flag = true;
        }

        if (this.props.node.data.dataLevel == 'SINGLE') {
            flag = true;
        }

        if (!flag) {
            this.props.api.stopEditing();
        } else {
            // this.props.openKeywordPopup(this.props.node.data);

            let sendId = null;

            if (this.props.node.data.dataLevel == 'SINGLE') {
                sendId = this.props.node.data.chldProdId;
            } else if (this.props.node.data.dataLevel == 'PARENT') {
                sendId = this.props.node.data.parentProductId;
            } else if (this.props.node.data.dataLevel == 'INTERMEDIATE') {
                sendId = this.props.node.data.intProdId;
            } else if (this.props.node.data.dataLevel == 'CHILD') {
                sendId = this.props.node.data.chldProdId;
            }

            ProductServices.getKeywords({ id: sendId })
                .then((response) => {
                    console.log('productContainer componentDidUpdate Image Approval elsesubcall  API call success data: ', response.data);
                    const res = response.data;

                    if (res.status) {
                        // _this.keywordData = res.data;
                        this.props.openKeywordPopup(res.data, this.props.node.data, this.props.node);
                        // _this.setState({ modalName: 'addKeywordModal' });
                        // _this.modalOpen();
                    } else {
                        NotificationManager({ message: 'Product Data doesnt contains related data', type: 4 });
                    }
                })
                .catch( (error) => {
                    console.log('productContainer componentDidUpdate Image Approval else subcall  API call failed', error);
                });
        }
    }


    isPopup() {
        return true;
    }

    componentWillUnmount() {
        // this.props.api.tabToNextCell();
    }

    /**
     * Gets the value when the component unmounts and pushes it for render
     * @return {string}
     */
    getValue() {
        return this.props.value;
    }

    render() {
        console.log('copyWrite Editor: props', this.props);
        return (
            <div />
        );
    }
}
