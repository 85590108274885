import React from 'react';

class UserSettingsForm extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (<div className="setting-modal">
            <div className="modal-header">
				Settings
                <span className="close" onClick={this.props.modalClose}>x</span>
            </div>
            <div className="modal-content">
                <form>
                    <div className="form-container scroll">
                        <h4>Communication Preferences</h4>
                    </div>
                </form>
                <div className="modal-footer">
                    <input type="button" value="Cancel" onClick={this.formViewable}></input>;
                    <input type="submit" value="Save"></input>;
                </div>
            </div>
        </div>
        );
    }
}

export default UserSettingsForm;
