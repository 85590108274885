import React, { Component } from 'react';
import PropTypes from 'prop-types';

const CreateEventComponent = ({
    props,
    events,
    taskHtml
}) => {
    return (
        <div>
            {props.isLoaded && props.isOpen &&
                <div className={`add-edit-popup scroll ${(props.isOpen) ? "active" : ""}`}>
                    <div>
                        <a
                            onClick={() => {
                                props.close();
                            }}
                            className="BackButton">&#8592;Back</a>
                    </div>
                    <div className="modal-form">
                        <div className="form-group">
                            <input type="hidden" className="event-id form-control" value='0' />
                        </div>
                        <div className="task-container">
                            <div className="task-row-containerFirst task-event-row-manage">
                                <div className="form-group task-column label">
                                    <label>Event Name*:</label>
                                </div>
                                <div className="form-group task-column col-3">
                                    <input type="text" className="event-desc form-control" placeholder="Event Name" maxLength={30} />
                                </div>
                                <div className="form-group task-column label margin-label">
                                    <label>Event Date*:</label>
                                </div>
                                <div className="form-group task-column col-3">
                                    <input type="text" className=" datepicker eventdate form-control cursor-hand" placeholder="mm/dd/yyyy" readOnly="readonly" />
                                </div>
                                <div className="form-group task-column label margin-label">
                                    <label>Event Type*:</label>
                                </div>
                                <div className="form-group task-column col-3">
                                    <select className="event-type form-control">
                                        <option value="0">Please select the event type</option>
                                        {events}
                                    </select>
                                </div>
                                <div className="form-group task-column label margin-label">
                                    <label>End Date*:</label>
                                </div>
                                <div className="form-group task-column col-3">
                                    <input type="text" className="datepicker eventEndDate form-control cursor-hand" placeholder="mm/dd/yyyy" readOnly="readonly" />
                                </div>
                            </div>
                        </div>
                        <div className="task-container">
                            <div className="task-row-container">
                                <div className="task-column form-group"></div>
                                <div className="task-column form-group">Owner*</div>
                                <div className="task-column form-group">Due*</div>
                            </div>
                            {taskHtml}
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

CreateEventComponent.propTypes = {
    props: PropTypes.object,
};

export default CreateEventComponent;
