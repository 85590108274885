import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import CancelModal from './cancelChanges';
import { checkHeight } from '../utils';

export class QuantityBreaksEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rowData: [],
            columnDefs: [],
            overlayNoRowsTemplate: 'No Data Found',
            defaultColDef: {
                editable: true,
                resizable: false,
                menuTabs: [],
            },
            disableSave: true,
            confirmOpen: false,
        };

        this.cancelEditing = this.cancelEditing.bind(this);
        this.closeConfirm = this.closeConfirm.bind(this);
    }

    componentDidMount() {
        let retailPrice = this.props.node.data.retailPrice ? this.props.node.data.retailPrice : '';

        let columnDetails = [
            {
                headerName: 'Quantity',
                field: 'qty',
                width: 135,
                editable: this.checkEditFunction.bind(this)
            },
            {
                headerName: 'Retail',
                field: 'retail',
                width: 135,
                editable: this.checkEditFunction.bind(this)
            },
        ];

        let constData = JSON.stringify([
            {"qty" : "1", "retail" : retailPrice.toString()},
            {"qty":"", "retail":""},
            {"qty":"", "retail":""},
            {"qty":"", "retail":""}
        ]);

        let setData = this.props.node.data.quantityBreaks ? this.props.node.data.quantityBreaks : constData;

        let data = JSON.parse(setData);

        data.map((i, x) => {
            if (x == 0) {
                i.retail;
            }
        });

        this.setState({
            columnDefs: columnDetails,
            rowData: data,
        });

        checkHeight(this.props);
    }

    getValue() {
        return JSON.stringify(this.state.rowData);
    }

    isPopup() {
        return true;
    }

    checkEditFunction(params) {
        if (params.node.rowIndex == 0) {
            return false;
        } else {
            return true;
        }
    }


    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    cancelEditing() {
        if (!this.state.disableSave) {
            this.setState({
                confirmOpen: true
            });
        } else {
            this.props.api.stopEditing();
        }
    }

    closeConfirm(key) {
        switch (key) {
            case 'yes':
                if (!this.state.disableSave) {
                    this.saveData();
                }
                this.props.api.stopEditing();
                break;
            case 'no':
                this.setState({
                    confirmOpen: false,
                });
                break;

            default:
                break;
        }
    }

    onCellValueChanged(params) {
        console.log('on cell value changed: ', params);
        const _this = this;
        let data = this.state.rowData;
        const oldValue = params.oldValue;
        const newValue = params.value;
        const rowIndex = params.rowIndex;

        if (oldValue == newValue) {
            return;
        }

        data.map((i, x) => {
            if (params.rowIndex == x) {
                i = params.data;
            }
        });

        this.setState({
            rowData: data,
        });

        this.gridApi.forEachNode(function (rowNode) {
            const data = rowNode.data;
            if (rowIndex == rowNode.rowIndex) {
                if (data.qty && data.retail) {
                    _this.setState({
                        disableSave: false
                    });
                } else if (!data.qty && !data.retail) {
                    _this.setState({
                        disableSave: false
                    });
                } else {
                    _this.setState({
                        disableSave: true
                    });
                }
            }
        });


        // checks for retail price and disables save
        if (!this.props.node.data.retailPrice) {
            this.setState({
                disableSave: true
            });
        }
    }

    saveData() {
        const rowData = this.props.node.data;
        const data = this.state.rowData;
        rowData[this.props.column.colId] = JSON.stringify(data);
        console.log('on saveData clicked: ', this.props, this.state, data, rowData);
        this.setState({
            rowData: data,
            disableSave: true
        });

        this.props.rowUpdate(rowData);
    }

    render() {
        return(
            <div className="message-editor-container qty-breaks-col">
                <div className="message-editor-header">
                    <div className="modal-header">BULK PRICING</div>
                    <span className="close" onClick={this.cancelEditing.bind(this)}>x</span>
                </div>
                <div className="message-editor-body">
                    <div
                        id="myGrid"
                        style={{
                            height: '100%',
                            width: '100%',
                        }}
                        class
                        Name="ag-theme-alpine"
                    >
                        <AgGridReact
                            columnDefs={this.state.columnDefs}
                            rowData={this.state.rowData}
                            defaultColDef={this.state.defaultColDef}
                            overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                            onGridReady={this.onGridReady.bind(this)}
                            onCellValueChanged = {this.onCellValueChanged.bind(this)}
                            rowSelection={'multiple'}
                            stopEditingWhenGridLosesFocus={true}
                            singleClickEdit={true}
                            enterMovesDown={true}
                            enterMovesDownAfterEdit={true}
                            suppressRowClickSelection={true}
                            animateRows={true}
                            suppressContextMenu={true}
                            suppressHorizontalScroll = {true}
                            rowHeight={25}
                            headerHeight={25}
                        />
                    </div>
                </div>
                <div className="message-editor-footer">
                    <div className='add-row'>
                        <button disabled={this.state.disableSave ? true : false} className="message-button new" onClick={this.saveData.bind(this)}>Save</button>
                    </div>
                </div>

                <CancelModal
                    isOpen={this.state.confirmOpen}
                    modalClose={this.closeConfirm}
                />
            </div>
        );
    }
}


export class QuantityBreaksRenderer extends React.Component {
    render() {
        let newArr = [];
        let vals = this.props.value ? this.props.value : null;
        const data = JSON.parse(vals);
        data && data.map((i) => {
            if (i.qty == '' || i.retail == '') {
                return;
            }
            newArr.push(i.qty + '/' + i.retail);
        });

        return(
            <div>
                {
                    newArr.length > 1 &&
                    newArr.join(",")
                }
            </div>
        );
    }
}
