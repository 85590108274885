import React, { Component } from 'react';
import * as actions from '../redux/marketingActions';
import Modal from '../../modals/common-modal.js';
import ConfirmModal from '../../modals/confirm-modal.js';
import axios from 'axios';

import CancelModalForPhotoComponent from './CancelModalForPhotoComponent.js';

class CancelModalForPhoto extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false
        };

        this.closeConfirmModal = this.closeConfirmModal.bind(this);
    }

    closeConfirmModal(key) {
        this.props.modalClose(key);
    }

    render() {
        return (
            <ConfirmModal isOpen={this.props.isOpen}>
                <CancelModalForPhotoComponent
                    state = {this.state}
                    props = {this.props}
                    closeConfirmModal={this.closeConfirmModal}
                />
            </ConfirmModal>
        );
    }
}

export default CancelModalForPhoto;
