const config = require('../../common/configurations.js');

export const endpoint = {
    login: {
        url: config.login,
        method: "POST"
    },
    getUser: {
        url: config.userData,
        method: "GET"
    },
    getUserData: {
        url: config.myaccount.getUserData,
        method: "POST"
    },
    insertUsersData: {
        url: config.myaccount.insertUsersData,
        method: "POST"
    },
    getGridView: {
        url: config.product.gridView,
        method: "POST"
    },
    product: {
        productSearch: {
            url: config.product2.search,
            method: "POST"
        },
        getProductCategories: {
            url: config.product.productcategories,
            method: "GET"
        },
        getProductDropdown: {
            url: config.product2.dropdownData,
            method: "GET"
        },
        getRequiredFields: {
            url: config.product.requiredFields,
            method: "GET"
        },
        addUpdateProduct: {
            url: config.product2.addupdate,
            method: "POST"
        },
        updateOrAddProduct: {
            url: config.product.updateOrAddProduct,
            method: "POST"
        },
        deleteRecord: {
            url: config.product.deleteRecord,
            method: "delete"
        },
        swatchUpdate: {
            url: config.product.swatchUpdate,
            method: "POST"
        },
        getProductId: {
            url: config.product.getProductId,
            method: "GET"
        },
        updateKeywords: {
            url: config.product.updateKeywords,
            method: "POST"
        },
        getKeywords: {
            url: config.product.getKeywords,
            method: "POST"
        },
        updateCopyWritter: {
            url: config.product.updateCopyWritter,
            method: "POST"
        },

        submitCopyWritter: {
            url: config.product.submitCopyWritter,
            method: "POST"
        },
        viewColumndetails: {
            url: config.product.viewColumndetails,
            method: "POST"
        },
        getCopyWritter: {
            url: config.product.getCopyWritter,
            method: "POST"
        },
        saveNewView: {
            url: config.product.saveNewView,
            method: "POST"
        },
        approveByBuyer: {
            url: config.product.approveByBuyer,
            method: "POST"
        },
        isViewExists: {
            url: config.product.isViewExists,
            method: "POST"
        },
        filters: {
            url: config.product.filters,
            method: "POST"
        },
        productsearch: {
            url: config.product.productsearch,
            method: "POST"
        },
        deleteView: {
            url: config.product.deleteView,
            method: "POST"
        },
        getSamplesData: {
            url: config.product.getSamplesData,
            method: "POST"
        },
        receiveSamples: {
            url: config.product.receiveSamples,
            method: "POST"
        },
        moveSample: {
            url: config.product.moveSample,
            method: "POST"
        },
        getSampleIds: {
            url: config.product.getSampleIds,
            method: "POST"
        },
        imageUpload: {
            url: config.product2.imageUploadV2,
            method: "POST"
        },
        importPOFile: {
            url: config.product.importPOFile,
            method: "POST"
        },
        addPO: {
            url: config.product.addPO,
            method: "POST"
        },
        generateSampleCard: {
            url: config.product.generateSampleCard,
            method: "POST"
        },
        updateSample: {
            url: config.product.updateSample,
            method: "POST"
        },
        updateProductSample: {
            url: config.product.updateProductSample,
            method: "POST"
        },
        saveSamples: {
            url: config.product.saveSamples,
            method: "POST"
        },
        importFilePIUpload: {
            url: config.product.importFilePIUpload,
            method: "POST"
        },
        importFilePIUploadpdf: {
            url: config.product.importFilePIUploadpdf,
            method: "POST"
        },
        getPiForProduct: {
            url: config.product.getPiForProduct,
            method: "POST"
        },
        deletePiForProduct: {
            url: config.product.deletePiForProduct,
            method: "POST"
        },
        resizeColOrderUpdate: {
            url: config.product.resizeColOrderUpdate,
            method: "POST"
        },
        updateAttrCompleted: {
            url: config.product.updateAttrCompleted,
            method: "POST"
        },
        activateOnWebsite: {
            url: config.product.activateOnWebsite,
            method: "POST"
        },
        saveScanner: {
            url: config.product.saveScanner,
            method: "POST"
        },
        saveParentChld: {
            url: config.product.saveParentChld,
            method: "POST"
        },
        getLevelList: {
            url: config.product.getLevelList,
            method: "GET"
        },
        groupByPopUp: {
            url: config.product.groupByPopUp,
            method: "POST"
        },
        listproductEvents: {
            url: config.product.listproductEvents,
            method: "POST"
        },
        saveorupdateproductevent: {
            url: config.product.saveorupdateproductevent,
            method: "POST"
        },
        moveSampleRequest: {
            url: config.product.moveSampleRequest,
            method: "POST"
        },
        getcloneProductIds: {
            url: config.product.getcloneProductIds,
            method: "GET"
        },
        activeliveeupdate: {
            url: config.product.activeliveeupdate,
            method: "POST"
        },
        forceActivateOnWebsite: {
            url: config.product.forceActivateOnWebsite,
            method: "POST"
        },
        getAngles: {
            url: config.product.getAngles,
            method: "GET"
        },
        saveAngles: {
            url: config.product.saveAngles,
            method: "POST"
        },
        sessionLock: {
            url: config.product.sessionLock,
            method: "GET"
        },
        getAdminSettings: {
            url: config.product.getAdminSettings,
            method: "GET"
        },
        exportPO: {
            url: config.product.exportPO,
            method: "POST"
        },
        getAttributeDropDown: {
            url: config.product.getAttributeDropDown,
            method: "GET"
        },
        getImageLst: {
            url: config.product.getImageLst,
            method: "POST"
        },
        deleteImageUploadData: {
            url: config.product.deleteImageUploadData,
            method: "POST"
        },
        getFeatureColor: {
            url: config.product.getFeatureColor,
            method: "POST"
        },
        releaseLock: {
            url: config.product.releaseLock,
            method: "GET"
        },
        saveOrSendMessage: {
            url: config.product2.saveOrSendMessage,
            method: "POST"
        },
        getMessages: {
            url: config.product2.getMessages,
            method: "GET"
        },
        readMessage: {
            url: config.product2.readMessage,
            method: "PATCH"
        },
        usersList: {
            url: config.product2.usersList,
            method: "GET"
        },
        excelUploadHistory: {
            url: config.product2.excelUploadHistory,
            method: "GET"
        },
        excelUploadStatus: {
            url: config.product2.excelUploadStatus,
            method: "GET"
        },
        uploadFiles: {
            url: config.product2.uploadFiles,
            method: "POST"
        },
        getMultiFiles: {
            url: config.product2.getMultiFiles,
            method: "GET"
        },
        uploadMultiFiles: {
            url: config.product2.uploadMultiFiles,
            method: "POST"
        },
        deleteMultiFiles: {
            url: config.product2.deleteMultiFiles,
            method: "DELETE"
        },
    },

    // all admin API's
    admin: {
        getRoleList: {
            url: config.admin.getRoleList,
            method: "GET"
        },
        getUserData: {
            url: config.admin.getUserData,
            method: "POST"
        },
        insertUsersData: {
            url: config.admin.insertUsersData,
            method: "POST"
        },
        updateUserStatus: {
            url: config.admin.updateUserStatus,
            method: "POST"
        },
        deleteUserData: {
            url: config.admin.deleteUserData,
            method: "POST"
        },
        addOrUpdateRule: {
            url: config.admin.addOrUpdateRule,
            method: "POST"
        },
        cloneRule: {
            url: config.admin.cloneRule,
            method: "POST"
        },
        pauseRule: {
            url: config.admin.pauseRule,
            method: "POST"
        },
        deleteRule: {
            url: config.admin.deleteRule,
            method: "POST"
        },
        resetPassword: {
            url: config.admin.resetPassword,
            method: "POST"
        },
        getUserMandatoryfields: {
            url: config.admin.getUserMandatoryfields,
            method: "GET"
        },
        updateUserMandatoryfields: {
            url: config.admin.updateUserMandatoryfields,
            method: "POST"
        },
        dropdownList: {
            url: config.admin.dropdownList,
            method: "GET"
        },
        getDropdownData: {
            url: config.admin.getDropdownData,
            method: "POST"
        },
        saveOrUpdateDropdownData: {
            url: config.admin.saveOrUpdateDropdownData,
            method: "POST"
        },
        deleteDropdownData: {
            url: config.admin.deleteDropdownData,
            method: "POST"
        },
        getAuditList: {
            url: config.admin.getAuditList,
            method: "GET"
        },
        getAdminsettingsVal: {
            url: config.admin.getAdminsettingsVal,
            method: "GET"
        },
        updateAdminsettingsVal: {
            url: config.admin.updateAdminsettingsVal,
            method: "POST"
        },
        getUserGroupList: {
            url: config.admin.getUserGroupList,
            method: "GET"
        },
        getAccomplishmentsList: {
            url: config.admin.getAccomplishmentsList,
            method: "POST"
        },
        updateAccomplishmentsData: {
            url: config.admin.updateAccomplishmentsData,
            method: "POST"
        },
        getTemplateNames: {
            url: config.admin.getTemplateNames,
            method: "GET"
        },
        saveTemplates: {
            url: config.admin.saveTemplates,
            method: "POST"
        },
        getTemplate: {
            url: config.admin.getTemplate,
            method: "POST"
        },
        addField: {
            url: config.admin.addField,
            method: "POST"
        },
        getJobData: {
            url: config.admin.getJobData,
            method: "GET"
        },
        updateJobData: {
            url: config.admin.updateJobData,
            method: "POST"
        },
        deleteFieldRoles: {
            url: config.admin.deleteFieldRoles,
            method: "POST"
        },
        // moved from user
        userList: {
            url: config.admin.userList,
            method: "GET"
        },
        insertOrUpdate: {
            url: config.admin.insertOrUpdate,
            method: "GET"
        },
        getAlertList2: {
            url: config.admin.getAlertList2,
            method: "GET"
        },
        addAlert2: {
            url: config.admin.addAlert2,
            method: "POST"
        },
        updateAlert2: {
            url: config.admin.updateAlert2,
            method: "POST"
        },
        deleteAlert: {
            url: config.admin.deleteAlert,
            method: "POST"
        },
        getRulesForAlerts: {
            url: config.admin.getRulesForAlerts,
            method: "GET"
        },
        getRulesList: {
            url: config.admin.getRulesList,
            method: "GET"
        },
        getRequiredFields: {
            url: config.admin.getRequiredFields,
            method: "GET"
        },
        updateRequiredFields: {
            url: config.admin.updateRequiredFields,
            method: "POST"
        },
        updateUserGlobalPermission: {
            url: config.admin.updateUserGlobalPermission,
            method: "POST"
        },
        getFieldPermissions: {
            url: config.admin.getFieldPermissions,
            method: "GET"
        },
        updateFieldPermissions: {
            url: config.admin.updateFieldPermissions,
            method: "POST"
        },
        getUserGlobalPermission: {
            url: config.admin.getUserGlobalPermission,
            method: "GET"
        },
        filterOperators: {
            url: config.admin.filterOperators,
            method: "GET"
        },
        recentMessage: {
            url: config.admin.recentMessage,
            method: "GET"
        },
        insertUserGroups: {
            url: config.admin.insertUserGroups,
            method: "POST"
        },
        getUserGroupsList: {
            url: config.admin.getUserGroupsList,
            method: "GET"
        },
        updateUserGroupStatus: {
            url: config.admin.updateUserGroupStatus,
            method: "POST"
        },
        updateGroupPermissionList: {
            url: config.admin.updateGroupPermissionList,
            method: "POST"
        },
        isUserMapped: {
            url: config.admin.isUserMapped,
            method: "POST"
        },
    },

    //all the marketing end points start here
    marketing: {
        getGridData: {
            url: config.marketing.getGridData,
            method: "GET"
        },
        addEventDataDetails: {
            url: config.marketing.addEventDataDetails,
            method: "GET"
        },
        addOrUpdateEvents: {
            url: config.marketing.addOrUpdateEvents,
            method: "POST"
        },
        getEventDetails: {
            url: config.marketing.getEventDetails,
            method: "POST"
        },
        approvedByAdmin: {
            url: config.marketing.approvedByAdmin,
            method: "POST"
        },
        rejectEvent: {
            url: config.marketing.rejectEvent,
            method: "POST"
        },
        deleteEvent: {
            url: config.marketing.deleteEvent,
            method: "POST"
        },
        updateEventStatus: {
            url: config.marketing.updateEventStatus,
            method: "POST"
        },
        getEventBrief: {
            url: config.marketing.getEventBrief,
            method: "POST"
        },
        saveEventBrief: {
            url: config.marketing.saveEventBrief,
            method: "POST"
        },
        submitEventBrief: {
            url: config.marketing.submitEventBrief,
            method: "POST"
        },
        getPhotos: {
            url: config.marketing.getPhotos,
            method: "POST"
        },
        addPhotos: {
            url: config.marketing.addPhotos,
            method: "POST"
        },
        submitPhotos: {
            url: config.marketing.submitPhotos,
            method: "POST"
        },
        approveReject: {
            url: config.marketing.approveReject,
            method: "POST"
        },
        saveReject: {
            url: config.marketing.saveReject,
            method: "POST"
        },
        campaignOverview: {
            url: config.marketing.campaignOverview,
            method: "POST"
        },
    },

    // all creative endpoints start here
    creative: {
        gridData: {
            url: config.creative.gridData,
            method: "GET"
        },
        getMaxId: {
            url: config.creative.getMaxId,
            method: "GET"
        },
        deletePicturePacket: {
            url: config.creative.deletePicturePacket,
            method: "POST"
        },
        getAcceptedImages: {
            url: config.creative.getAcceptedImages,
            method: "POST"
        },
        acceptBuyerResult: {
            url: config.creative.acceptBuyerResult,
            method: "POST"
        },
        updatePhotographFlag: {
            url: config.creative.updatePhotographFlag,
            method: "POST"
        },
        updateLocation: {
            url: config.creative.updateLocation,
            method: "POST"
        },
        findTaskNameRslt: {
            url: config.creative.findTaskNameRslt,
            method: "POST"
        },
        addShots: {
            url: config.creative.addShots,
            method: "POST"
        },
        deleteShot: {
            url: config.creative.deleteShot,
            method: "POST"
        },
        sendAlertCreative: {
            url: config.creative.sendAlertCreative,
            method: "POST"
        },
        photographyComplete: {
            url: config.creative.photographyComplete,
            method: "POST"
        },
        loadImages: {
            url: config.creative.loadImages,
            method: "POST"
        },
        buyerReview: {
            url: config.creative.buyerReview,
            method: "POST"
        },
    },

    //all category endpoints
    category: {
        productcategories: {
            url: config.category.productcategories,
            method: "GET"
        },
        subcategories: {
            url: config.category.subcategories,
            method: "POST"
        },
        saveCategory: {
            url: config.category.saveCategory,
            method: "POST"
        },
        deleteCategory: {
            url: config.category.deleteCategory,
            method: "POST"
        }
    },
    dashboard: {
        priorityAlerts: {
            url: config.dashboard.priorityAlerts,
            method: "POST"
        },
        getRecentAlerts: {
            url: config.dashboard.getRecentAlerts,
            method: "POST"
        },
        getAccomplishments: {
            url: config.dashboard.getAccomplishments,
            method: "POST"
        },
        searchAlertsOrAccomplishment: {
            url: config.dashboard.searchAlertsOrAccomplishment,
            method: "POST"
        },
        getSuperiorData: {
            url: config.dashboard.getSuperiorData,
            method: "POST"
        },
        getFilterData: {
            url: config.dashboard.getFilterData,
            method: "POST"
        },
        getRTOSummary: {
            url: config.dashboard.getRTOSummary,
            method: "GET"
        },
        getUnreadMessages: {
            url: config.dashboard.getUnreadMessages,
            method: "GET"
        }
    },
    publication: {
        checkListName: {
            url: config.publication.checkListName,
            method: "GET"
        },
        saveListName: {
            url: config.publication.saveListName,
            method: "POST"
        },
        getPublicationList: {
            url: config.publication.getPublicationList,
            method: "POST"
        },
        getSingleListName: {
            url: config.publication.getSingleListName,
            method: "POST"
        },
        deleteListName: {
            url: config.publication.deleteListName,
            method: "POST"
        },
        updatePublicationList: {
            url: config.publication.updatePublicationList,
            method: "POST"
        },
        clearPublicationList: {
            url: config.publication.clearPublicationList,
            method: "POST"
        },
        RemoveSelectedData: {
            url: config.publication.RemoveSelectedData,
            method: "POST"
        },
        generateCSV: {
            url: config.publication.generateCSV,
            method: "POST"
        },
        generatePDF: {
            url: config.publication.generatePDF,
            method: "POST"
        }
    },
    presentation: {
        getFolioFormats: {
            url: config.presentation.getFolioFormats,
            method: "POST"
        },
        getUserFolio: {
            url: config.presentation.getUserFolio,
            method: "POST"
        },
        saveOrUpdate: {
            url: config.presentation.saveOrUpdate,
            method: "POST"
        },
        getPageByPageNo: {
            url: config.presentation.getPageByPageNo,
            method: "POST"
        },
        listReports: {
            url: config.presentation.listReports,
            method: "GET"
        },
    },
    login: {
        url: config.login,
        method: "POST"
    },
    logout: {
        url: config.logout,
        method: "POST"
    },
    view: {
        getViews: {
            url: config.view.getViews,
            method: 'GET'
        }
    },
    vendor: {
        addorupdatevendor: {
            url: config.vendor.addorupdatevendor,
            method: "POST"
        },
        listsvendor: {
            url: config.vendor.listsvendor,
            method: "GET"
        },
        deletevendor: {
            url: config.vendor.deletevendor,
            method: "POST"
        },
        vendorNameList: {
            url: config.vendor.vendorNameList,
            method: "POST"
        }
    },
    forgotPassword: {
        url: config.forgotPassword,
        method: "POST"
    },
    changePassword: {
        url: config.changePassword,
        method: "POST"
    },
    personalAlerts: {
        url: config.personalAlerts,
        method: "POST"
    },
    addVendor: {
        url: config.addVendor,
        method: "POST"
    }
};
