import React, { Component } from 'react';

class FilterStrip extends Component {
    constructor(props){
        super(props);

        this.filters = {};
        // this.filterPopulted=false;
        // this.filters = this.createFilterArray(props);
        this.removeSingle = this.removeSingle.bind(this);
    }

    componentDidMount() {

    }

    createFilterArray(filtersObj) {
        let filters = [];

        if(filtersObj.catIds && filtersObj.catIds.length > 0 && filtersObj.catIdsObj){
            filtersObj.catIds.map((item, index) =>{
                let obj = {};
                obj.name = filtersObj.catIdsObj[item];
                obj.val = item;
                obj.type = "catIds";
                obj.index = index;

                filters.push(obj);
            });
        }

        if(filtersObj.customFilters && filtersObj.customFilters.length > 0){
            let obj = {}, isValuePresent = false;
            obj.name = "Refinement";
            obj.type = "customFilters";

            filtersObj.customFilters.map((item, index) =>{
                if(item.value){
                    isValuePresent = true;
                }
            });

            if(isValuePresent){
                filters.push(obj);
            }
        }

        return filters;
    }

    componentWillReceiveProps(nextProps){
        // if(nextProps.filters.length>1 && !this.filterPopulted){
        this.filters = this.createFilterArray(nextProps.filters);
        // 	this.filterPopulted = true;
        // }
    }

    removeSingle(type, position){
        // this.props.toggleSpinner(true)
        let removeFilter  = {type, position};
        console.log('remove single type and position: ', type, position);
        this.props.updateFilters(removeFilter);
        // this.props.reloadGrid();
    }

    render() {
        return (
            <div  className="filter-strip-holder">
                <ul className="filter-strip">
                    {this.filters.length > 0 &&
						this.filters.map((item, index) =>{
						    return (
						        <li key={index}>
						            {item.name}
						            { this.filters.length > 1 &&
									<span className="remove" onClick={() =>{this.removeSingle(item.type, item.index);}}></span>
						            }
						        </li>
						    );
						})
                    }
                </ul>
            </div>
        );
    }
}

export default FilterStrip;
