import React, { Component } from 'react';
import VendorComponent from './vendorComponent';
import GridDataBuilder from './helperComponents/vendorDataBuilder';
import NotificationManager from '../common/components/notification';
import {CommonServices} from '../common/services/common-services';

import axios from 'axios';

class VendorPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isModalOpen: false,
            userList: {},
            vendorTotalCount: 0,
            selectedRowsCount: 0,
            checkedRows: [],
            checkedVendorData: [],
            vendorUpdateData: {},
        };

        this.modalOpen = this.modalOpen.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.removeGridRows = this.removeGridRows.bind(this);
        this.addVendorRow = this.addVendorRow.bind(this);
        this.updateGridRow = this.updateGridRow.bind(this);
        this.reloadGrid = this.reloadGrid.bind(this);
    }

    componentDidMount() {
        const postData = { 'id': this.props.user.groupId };
        this.loadGrid();
    }

    componentDidUpdate(prevProps, prevState) {
        // if (prevState.isLoaded !== this.state.isLoaded) {
        //     const _this = this;
        //     _this.isReadyForUpdate = true;
        //     const vendorView = $('#vendorGrid');
        //     vendorView.off();

        //     // Single rowclick to edit user in grid
        //     // this.vendorGrid.on('rowclick', function(event) {
        //     //     let args = event.args;
        //     //     console.log(args);
        //     // });

        //     //Double rowclick to open pop-up to edit user
        //     vendorView.on('dblclick', '.gridVendorId', function (event) {
        //         const _me = $(this);
        //         const row = _me.data('row');
        //         const rowData = _this.vendorGrid.getrowdata(row);

        //         const vendorObj = {
        //             vendorId: rowData.vendorId,
        //             name: rowData.name,
        //             email: rowData.email,
        //             phone: rowData.phone,
        //             contactPerson: rowData.contactPerson,
        //             orgVendorId: rowData.orgVendorId,
        //             status: rowData.status,
        //             type: rowData.type,
        //             rowindex: rowData.uid,
        //             effectiveDate: rowData.effectiveDate,
        //         };
        //         _this.vendorUpdateData = vendorObj;
        //         _this.setState({ vendorUpdateData: vendorObj });
        //         _this.modalOpen();
        //     });

        //     // this.vendorGrid.on('rowdoubleclick', function(event) {
        //     //     let args = event.args, vendor = args.row.bounddata;
        //     //     console.log(event);

        //     //     // Edit User value appending in modal
        //     //     let vendorObj = {
        //     //         vendorId: vendor.vendorId,
        //     //         name: vendor.name,
        //     //         email: vendor.email,
        //     //         phone: vendor.phone,
        //     //         contactPerson: vendor.contactPerson,
        //     //         orgVendorId: vendor.orgVendorId,
        //     //         status: vendor.status,
        //     //         type: vendor.type,
        //     //         rowindex: vendor.uid,
        //     //         effectiveDate: vendor.effectiveDate
        //     //     };
        //     //     _this.vendorUpdateData = vendorObj;
        //     //     _this.setState({vendorUpdateData: vendorObj});
        //     //     _this.modalOpen();
        //     // });

        //     // Row select
        //     this.vendorGrid.on('rowselect', function (event) {
        //         const rowIndexs = _this.vendorGrid.getselectedrowindexes();
        //         const newCheckedVendorData = [];
        //         rowIndexs.map((val, key) => {
        //             const vendor = _this.vendorGrid.getrowdata(val);
        //             newCheckedVendorData.push(vendor);
        //         });
        //         _this.setState({ selectedRowsCount: rowIndexs.length, checkedRows: rowIndexs, checkedVendorData: newCheckedVendorData });
        //     });

        //     // Row Unselect
        //     this.vendorGrid.on('rowunselect', function (event) {
        //         const rowIndexs = _this.vendorGrid.getselectedrowindexes();
        //         const newCheckedVendorData = [];
        //         rowIndexs.map((val, key) => {
        //             const vendor = _this.vendorGrid.getrowdata(val);
        //             newCheckedVendorData.push(vendor);
        //         });
        //         _this.setState({ selectedRowsCount: rowIndexs.length, checkedRows: rowIndexs, checkedVendorData: newCheckedVendorData });
        //     });

        //     // Start: On cell value changed
        //     this.vendorGrid.on('cellvaluechanged', function (event) {
        //         const args = event.args;
        //         const datafield = args.datafield;
        //         const newvalue = args.newvalue;
        //         const rowindex = args.rowindex;
        //         const rowdata = _this.vendorGrid.getrowdata(rowindex);
        //         let updateCounter = 0;

        //         // if (datafield != 'status') {
        //         //     _this.inlineVendorUpdate(rowdata); // Updating vendor
        //         // }

        //         if (datafield != 'status') {
        //             if (datafield === 'name' && args.value === '') {
        //                 NotificationManager({
        //                     message: 'Name should not be empty.',
        //                     type: 4,
        //                 });
        //                 _this.isReadyForUpdate = false, updateCounter = 1;
        //                 _this.vendorGrid.setcellvalue(rowindex, datafield, args.oldvalue);
        //             } else if (datafield === 'email' && _this.isReadyForUpdate) {
        //                 const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //                 if (args.value === '' || !regex.test(args.value.trim())) {
        //                     NotificationManager({
        //                         message: 'Invalid email address.',
        //                         type: 4,
        //                     });
        //                     _this.isReadyForUpdate = false, updateCounter = 1;
        //                     _this.vendorGrid.setcellvalue(rowindex, datafield, args.oldvalue);
        //                 }
        //             }

        //             if (updateCounter == 0) {
        //                 if (args.oldvalue != args.newvalue && _this.isReadyForUpdate) {
        //                     _this.inlineVendorUpdate(rowdata, args.oldvalue, datafield); // Updating vendor
        //                 }
        //                 if (!_this.isReadyForUpdate) {
        //                     _this.isReadyForUpdate = true;
        //                 }
        //             }
        //         }
        //     });
        //     // End: On cell value changed

        //     // Start: Vendor-Status update
        //     vendorView.on('change', 'select', function (e) {
        //         const _me = $(this);
        //         const value = (_me.val() === 'true');
        //         const rowId = _me.data('row');
        //         const rowData = _this.vendorGrid.getrowdata(rowId);
        //         _this.vendorGrid.setcellvalue(rowId, 'status', value);

        //         _this.vendorStatusUpdate(rowData.vendorId, value); // updating vendor status
        //     });
        //     //End: Vendor-Status update
        // }
    }

    // loads the grid when called (setState to false, to refresh data)
    loadGrid() {
        const base = this;

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        CommonServices.listsvendor()
            .then((response) => {
                // console.log('Admin vendorContainer componentDidMount API call success data: ', response.data);
                const res = response;

                if (res.responseCode === 0) {
                    base.gridData = GridDataBuilder(res);
                    base.setState({ isLoaded: true, vendorTotalCount: res.data.length });
                } else {
                    NotificationManager({ message: 'Error:Response failed, Please try again', type: 4 });
                }
            })
            .catch((error) => {
                console.log('Admin vendorContainer componentDidMount API call failed', error);
                NotificationManager({ message: 'Error:Response failed, Please try again', type: 4 });
            });
    }

    /**
     * Remove Grid rows after delete Vendors
     * @param {*} rows
     */
    removeGridRows(rows) {
        const _this = this;
        rows.forEach((e, index) => {
            this.vendorGrid.deleterow(e.vendorId);
        });

        _this.setState({ selectedRowsCount: 0, checkedRows: [], checkedVendorData: [] }, () => {
            this.reloadGrid();
        });
    }

    /**
     * Inline Update vendors
     * @param {object} rowdata vendor grid row data
     * @param {*} oldValue
     * @param {*} datafield
     */
    inlineVendorUpdate(rowdata, oldValue, datafield) {
        const _this = this;
        const req = JSON.stringify(rowdata);


        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        CommonServices.addorupdatevendor(req)
            .then((response) => {
            // console.log('admin vendorContainer  inlineVendorUpdate API call success data: ', response.data);
                const res = response;

                if (res.status) {
                    NotificationManager({
                        message: res.responseMessage,
                        type: 1,
                    });
                } else {
                    _this.isReadyForUpdate = false;
                    _this.vendorGrid.setcellvalue(rowdata.visibleindex, datafield, oldValue);
                    NotificationManager({
                        message: res.responseMessage,
                        type: 4,
                    });
                }
            })
            .catch((error) => {
                console.log('admin vendorContainer  inlineVendorUpdate call failed', error);
                NotificationManager({
                    message: 'Failed to update vendor',
                    type: 4,
                });
            });
    }


    /**
     * Update vendor status
     * @param {int} id Vendor Id
     * @param {boolean/string} status vendor status
     */
    vendorStatusUpdate(id, status) {
        const req = JSON.stringify({ userId: id, status: status });

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        axios({
            method: 'post',
            url: require('../common/configurations.js').vendor.updatevendorstatus,
            headers: headers,
            data: req,
        })
            .then((response) => {
                // console.log('admin vendorContainer  vendorStatusUpdate API call success data: ', response.data);
                const res = response.data;

                if (res.status) {
                    NotificationManager({
                        message: 'SUCCESS: Vendor status updated.',
                        type: 1,
                    });
                }
            })
            .catch((error) => {
                console.log('admin vendorContainer  vendorStatusUpdate API call failed', error);
                NotificationManager({
                    message: 'Failed to update Vendor status',
                    type: 4,
                });
            });
    }

    /**
     * Opens the modal
     */
    modalOpen() {
        this.setState({ isModalOpen: true });
    }

    /**
     * Closes the modal
     */
    modalClose() {
        this.setState({ isModalOpen: false });
    }

    /**
     * Added to reload grid after update is done - Suraj
     */
    reloadGrid() {
        this.setState({ isLoaded: false }, () => {
            this.loadGrid();
        });
    }

    /**
     * Append new row once save vendor gets success
     * @param {*} req
     */
    addVendorRow(req) {
        const data = req;
        this.vendorGrid.addrow(data.orgVendorId, data);
    }

    /**
     * Update vendor grid after edit
     * @param {*} req
     */
    updateGridRow(req) {
        const data = req;
        this.vendorGrid.updaterow(data.rowindex, data);
    }


    /**
     * Selecting a row on the grid
     * @param {*} params
     */
    onRowselect(params) {
        const _this = this;
        const rowIndexs = _this.vendorGrid.getselectedrowindexes();
        const newCheckedVendorData = [];
        rowIndexs.map((val, key) => {
            const vendor = _this.vendorGrid.getrowdata(val);
            newCheckedVendorData.push(vendor);
        });
        _this.setState({ selectedRowsCount: rowIndexs.length, checkedRows: rowIndexs, checkedVendorData: newCheckedVendorData });
    }

    /**
     * Un-selecting a row on the grid
     * @param {*} params
     */
    onRowunselect(params) {
        const _this = this;
        const rowIndexs = _this.vendorGrid.getselectedrowindexes();
        const newCheckedVendorData = [];
        rowIndexs.map((val, key) => {
            const vendor = _this.vendorGrid.getrowdata(val);
            newCheckedVendorData.push(vendor);
        });
        _this.setState({ selectedRowsCount: rowIndexs.length, checkedRows: rowIndexs, checkedVendorData: newCheckedVendorData });
    }

    //Updated to react JQGrid click handler(Fields have been disabled for edit on the grid) - Suraj
    onCelldoubleclick(event) {
        console.log('vendor Conatainer double click: ', event);
        const rowData = this.vendorGrid.getrowdata(event.args.rowindex);
        console.log('rowdata is: ', rowData);
        const vendorObj = {
            vendorId: rowData.vendorId,
            name: rowData.name,
            email: rowData.email,
            phone: rowData.phone,
            contactPerson: rowData.contactPerson,
            orgVendorId: rowData.orgVendorId,
            status: rowData.status,
            type: rowData.type,
            rowindex: rowData.uid,
            effectiveDate: rowData.effectiveDate,
        };
        this.vendorUpdateData = vendorObj;
        this.setState({ vendorUpdateData: vendorObj });
        this.modalOpen();
    }


    render() {
        const footerHeight = 50;
        const headerHeight = 64;
        $('.page-title a').text("ADMIN: Vendor");

        const gridHeight = window.innerHeight - footerHeight - headerHeight;
        const editSettings = {
            saveOnPageChange: true,
            saveOnBlur: true,
            saveOnSelectionChange: true,
            cancelOnEsc: true,
            saveOnEnter: true,
            editSingleCell: true,
            editOnDoubleClick: true,
            editOnF2: true,
        };

        return (

            <VendorComponent
                state={this.state}
                props={this.props}
                vendorGridRef={(grid) => {
                    this.vendorGrid = grid;
                }}
                gridData={this.gridData}
                gridHeight={gridHeight}
                editSettings={editSettings}

                modalClose={this.modalClose}
                vendorUpdateData={this.vendorUpdateData}
                updateGridRow={this.updateGridRow}

                removeGridRows={this.removeGridRows}
                addVendorRow={this.addVendorRow}
                onCelldoubleclick={this.onCelldoubleclick.bind(this)}
                reloadGrid = {this.reloadGrid}
                onRowselect={this.onRowselect.bind(this)}
                onRowunselect={this.onRowunselect.bind(this)}
            />


        );
    }
}


export default VendorPage;
