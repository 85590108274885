export function checkHeight(params) {
    let val = params.reactContainer.children[0].classList[0];

    setTimeout(() => {
        let screenPosition = document.querySelector("." + val);

        let pos = screenPosition.getBoundingClientRect();

        let actualPos = window.innerHeight - pos.y;

        console.log('checkHeight params', params, val, pos, window.innerHeight, actualPos);

        if (actualPos <= 250) {
            screenPosition.style.top = '-' + pos.height;
        }
    }, 500);
}
