
import React from 'react';
import NotificationManager from '../../common/components/notification';
import { CustomDropdownEditor } from './customDropdownEditor';

const samplesList = ['Sample', 'Swatch Only', 'None'];

/**
 * renders the sameple option dropdown
 */
export class SampleOptionDropdownRender extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        if (this.props.node.data && this.props.node.data.sampleOptions) {
            return (
                <div>
                    {this.props.node.data.sampleOptions}
                </div>
            );
        } else {
            return (
                <div></div>
            );
        }
    }
}


// /////////////////////////////////////////////////////////////////////////////////////////////

/**
 * renders the sample option dropdown in the edit mode
 */

export class SampleOptionDropdownEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedVal: '',
            samplesList: samplesList,
            width: null,
        };
        // this.handleChange = this.handleChange.bind(this);
    }

    /**
     * determines the value in the cell based on the displayLevel and the level of the record
     */
    componentWillMount() {
        const cellWidth = this.props.eGridCell.style.width;

        let flag = false;

        if (this.props.node.data.displayLevel == 'Color' && this.props.node.level == 1) {
            flag = true;
        }

        if (this.props.node.data.displayLevel == 'Style' && this.props.node.level == 0) {
            flag = true;
        }

        if (this.props.node.data.displayLevel == 'Size' && this.props.node.level == 2) {
            flag = true;
        }

        if (this.props.node.data.dataLevel == 'SINGLE') {
            flag = true;
        }

        if (!flag) {
            this.props.api.stopEditing();
        } else {
            this.setState({
                selectedVal: this.props.node.data.sampleOptions,
                width: cellWidth,
            });
        }
    }

    /**
     * stops the editing on the cell
     */
    componentWillUnmount() {
        this.props.api.stopEditing();
    }

    isPopup() {
        return true;
    }

    getValue() {
        return this.state.selectedVal;
    }


    changeSelectedValue(value) {
        this.setState({
            selectedVal: value,
        }, () => this.stopEditing());
    }

    stopEditing() {
        this.props.api.stopEditing();
    }


    render() {
        return (
            <div>
                <CustomDropdownEditor
                    width={this.state.width}
                    selectedVal={this.state.selectedVal}
                    samplesList={this.state.samplesList}
                    changeSelectedValue={this.changeSelectedValue.bind(this)}
                    {...this.props}
                />
            </div>
        );
    }
}
