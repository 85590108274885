import React, { Component } from 'react';
import { connect } from 'react-redux';

const TotalRowCount = ({ rowCount, selectedRowsCount }) => (
    <div className="total-row-count">
        <span className="total-rows">{rowCount} Total Rows</span>
        <span className="selected-rows"> | Selected: {selectedRowsCount} </span>
    </div>
);

const getTotalCount = (details) => {
    if (details && details.length) {
        return details.length;
    }

    return 0;
};

const getSelectedCount = (rows) => {
    if (rows) {
        return rows.length;
    }

    return 0;
};

function mapStateToProps(state) {
    return {
        rowCount: getTotalCount(state.gridData),
        selectedRowsCount: getSelectedCount(state.selectedRows)
    };
}

export default connect(mapStateToProps)(TotalRowCount);
