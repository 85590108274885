import {endpoint} from '../../common/REST/endpoints';
import {headers} from '../../common/REST/headers';
import request from '../../common/REST/request';


let MarketingServices = {
    getGridData: function() {
        return request({
            method: endpoint.marketing.getGridData.method,
            url: endpoint.marketing.getGridData.url,
            headers: headers
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    addEventDataDetails: function() {
        return request({
            method: endpoint.marketing.addEventDataDetails.method,
            url: endpoint.marketing.addEventDataDetails.url,
            headers: headers
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    addOrUpdateEvents: function(payload) {
        return request({
            method: endpoint.marketing.addOrUpdateEvents.method,
            url: endpoint.marketing.addOrUpdateEvents.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    getEventDetails: function(payload) {
        return request({
            method: endpoint.marketing.getEventDetails.method,
            url: endpoint.marketing.getEventDetails.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    approvedByAdmin: function(payload) {
        return request({
            method: endpoint.marketing.approvedByAdmin.method,
            url: endpoint.marketing.approvedByAdmin.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    rejectEvent: function(payload) {
        return request({
            method: endpoint.marketing.rejectEvent.method,
            url: endpoint.marketing.rejectEvent.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    deleteEvent: function(payload) {
        return request({
            method: endpoint.marketing.deleteEvent.method,
            url: endpoint.marketing.deleteEvent.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    updateEventStatus: function(payload) {
        return request({
            method: endpoint.marketing.updateEventStatus.method,
            url: endpoint.marketing.updateEventStatus.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    getEventBrief: function(payload) {
        return request({
            method: endpoint.marketing.getEventBrief.method,
            url: endpoint.marketing.getEventBrief.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    saveEventBrief: function(payload) {
        return request({
            method: endpoint.marketing.saveEventBrief.method,
            url: endpoint.marketing.saveEventBrief.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    submitEventBrief: function(payload) {
        return request({
            method: endpoint.marketing.submitEventBrief.method,
            url: endpoint.marketing.submitEventBrief.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    getPhotos: function(payload) {
        return request({
            method: endpoint.marketing.getPhotos.method,
            url: endpoint.marketing.getPhotos.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    addPhotos: function(payload) {
        return request({
            method: endpoint.marketing.addPhotos.method,
            url: endpoint.marketing.addPhotos.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    submitPhotos: function(payload) {
        return request({
            method: endpoint.marketing.submitPhotos.method,
            url: endpoint.marketing.submitPhotos.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    approveReject: function(payload) {
        return request({
            method: endpoint.marketing.approveReject.method,
            url: endpoint.marketing.approveReject.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    saveReject: function(payload) {
        return request({
            method: endpoint.marketing.saveReject.method,
            url: endpoint.marketing.saveReject.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    campaignOverview: function(payload) {
        return request({
            method: endpoint.marketing.campaignOverview.method,
            url: endpoint.marketing.campaignOverview.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

};

export default MarketingServices;
