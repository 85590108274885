import React from 'react';
import { Route, Switch, Suspense } from 'react-router-dom';
import LoginPage from './login/loginContainer';
import Layout from './layout/layout';

import ChangePassword from './changePassword/changePasswordContainer';


const SweftRoutes = () => (
    <Switch>
        <Route path="/changePassword" component={ChangePassword} />,
        <Route path="/" component={Layout} />,
    </Switch>
);

export default SweftRoutes;
