import React from 'react';

import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemBody,
} from 'react-accessible-accordion';

const CampaignOverviewComponent = ({
    state,
    props,
    handleChange,
    OverviewData,
    updateMainViewerOverview,
}) => {
    return (
        <div className="add-copy modal-form">
            <Accordion onChange={handleChange}>

                <AccordionItem expanded={true}>
                    <AccordionItemTitle>
                        <h3 className="u-position-relative">
                                View Brief
                            <div className="accordion__arrow" role="presentation"></div>
                        </h3>
                    </AccordionItemTitle>

                    <AccordionItemBody>
                        <div className="col-12 float-none">
                            {(OverviewData[0].data[0].taskData) ?
                                <div className={"description"}>
                                    <span>{OverviewData[0].data[0].taskData}</span>
                                </div>
                                :
                                <span>No Event content to display</span>
                            }
                        </div>
                    </AccordionItemBody>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemTitle>
                        <h3 className="u-position-relative">
                                View Copy
                            <div className="accordion__arrow" role="presentation"></div>
                        </h3>
                    </AccordionItemTitle>

                    <AccordionItemBody>
                        <div className="col-12 float-none">
                            {(OverviewData[1].data[0].taskData) ?
                                <div className={"description"}>
                                    <textarea disabled={true} readOnly={true} name="textarea" className="jqte-test-overview event-copywrite"></textarea>
                                </div>
                                :
                                <span>No Event content to display</span>
                            }
                        </div>
                    </AccordionItemBody>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemTitle>
                        <h3 className="u-position-relative">
                                View Layout
                            <div className="accordion__arrow" role="presentation"></div>
                        </h3>
                    </AccordionItemTitle>

                    <AccordionItemBody>
                        {(OverviewData[2].data[0].taskData) ?
                            <div className="vertical-image-holder">
                                <div className="thumbnail-holder scroll">
                                    <ul>
                                        {OverviewData[2].data.map((item, index) => (
                                            <li className="thumbnails" key={index}>
                                                <img data-img={item.taskData} src={item.taskData} onClick={updateMainViewerOverview} />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="main-image">
                                    <img src={OverviewData[2].data[0].taskData} />
                                </div>
                            </div>
                            :
                            <div className="col-12 float-none">
                                <span>No Event content to display</span>
                            </div>
                        }
                    </AccordionItemBody>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemTitle>
                        <h3 className="u-position-relative">
                                Campaign Photos
                            <div className="accordion__arrow" role="presentation"></div>
                        </h3>
                    </AccordionItemTitle>

                    <AccordionItemBody>
                        {(OverviewData[3].data[0].taskData) ?
                            <div className="vertical-image-holder">
                                <div className="thumbnail-holder scroll">
                                    <ul>
                                        {OverviewData[3].data.map((item, index) => (
                                            <li className="thumbnails" key={index}>
                                                <img data-img={item.taskData} src={item.taskData} onClick={updateMainViewerOverview} />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="main-image">
                                    <img src={OverviewData[3].data[0].taskData} />
                                </div>
                            </div>
                            :
                            <div className="col-12 float-none">
                                <span>No Event content to display</span>
                            </div>
                        }
                    </AccordionItemBody>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemTitle>
                        <h3 className="u-position-relative">
                                View Products
                            <div className="accordion__arrow" role="presentation"></div>
                        </h3>
                    </AccordionItemTitle>

                    <AccordionItemBody>
                        {(OverviewData[4].data[0].taskData) ?
                            <div className="campaign-overview-product-container">
                                <ul>
                                    {OverviewData[4].data.map((item, index) => (
                                        <li key={index}>
                                            {item.taskData}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            :
                            <div className="col-12 float-none">
                                <span>No Event content to display</span>
                            </div>
                        }
                    </AccordionItemBody>
                </AccordionItem>

            </Accordion>
        </div>
    );
};

CampaignOverviewComponent.propTypes = {
    state: PropTypes.object,
    props: PropTypes.object,
};

export default withRouter (CampaignOverviewComponent);
