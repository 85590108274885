import React from 'react';
import ConfirmModal from '@app/modals/confirm-modal';

class DismissableConfirmModal extends ConfirmModal {

    render() {  
        return (
            <div onClick={this.props.close} className={`modal-container confirm ${(this.props.isOpen) ? "open" : ''} `}>
                <div className="modal-holder">
                    {this.props.children ? this.props.children : ''}
                </div>
            </div>
        );
    }
}

export default DismissableConfirmModal;
