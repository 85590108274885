import React from 'react';
import NotificationManager from '../../common/components/notification';

export class ChannelDropdownPopupRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            channelList: props.getChannelList(),
            selectedValues: [],
            flag: false,
        };
    }

    componentWillMount() {
        const selectedValues = this.state.selectedValues;
        const channelIds = this.props.data && this.props.data.channelIds ? this.props.data.channelIds : [];
        const _this = this;
        channelIds.forEach(function (val) {
            _this.state.channelList.forEach(function (obj) {
                if (obj.fieldId.localeCompare(val) == 0) {
                    selectedValues.push(obj.fieldName);
                }
            });
        });

        const removeDuplicates = [];
        const arr = selectedValues.filter(function (el) {
            // If it is not a duplicate, return true
            if (removeDuplicates.indexOf(el) == -1) {
                removeDuplicates.push(el);
                return true;
            }
            return false;
        });

        this.setState({ removeDuplicates });
    }


    render() {
        const stringVals = this.state.removeDuplicates.toString();

        const displayLevel = (this.props.data && this.props.data.displayLevel) ? this.props.data.displayLevel : null;

        // render for single reocord
        if (this.props.node.data && this.props.node.data.dataLevel && this.props.node.data.dataLevel == 'SINGLE') {
            return (
                <div>
                    {stringVals && stringVals.length > 0 ? stringVals : <div className="arrow downPos"></div>}
                </div>
            );
        }

        // render for intm reocord
        if (this.props.node.data && this.props.node.data.dataLevel && this.props.node.data.dataLevel == 'INTERMEDIATE' && stringVals) {
            return (
                <div>
                    {stringVals && stringVals.length > 0 ? stringVals : <div className="arrow downPos"></div>}
                </div>
            );
        }

        // render for parent level - roll up records
        if (this.props.node.data && this.props.node.data.dataLevel && this.props.node.data.dataLevel == 'PARENT' && stringVals) {
            return (
                <div>
                    {stringVals}
                </div>
            );
        }

        if (displayLevel) {
            switch (displayLevel) {
                case 'Style':
                    if (this.props.node.level == 0) {
                        return (
                            <div>
                                {stringVals && stringVals.length > 0 ? stringVals : <div className="arrow downPos"></div>}
                            </div>
                        );
                    } else {
                        return null;
                    }
                case 'Color':
                    if (this.props.node.level == 1) {
                        return (
                            <div>
                                {stringVals && stringVals.length > 0 ? stringVals : <div className="arrow downPos"></div>}
                            </div>
                        );
                    } else {
                        return null;
                    }
                case 'Size':
                    if (this.props.node.level == 2) {
                        return (
                            <div>
                                {stringVals && stringVals.length > 0 ? stringVals : <div className="arrow downPos"></div>}
                            </div>
                        );
                    } else {
                        return null;
                    }
                default: return null;
            }
        } else {
            return null;
        }
    }
}


// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * renders the channel dropdown in edit mode
 */
export class ChannelDropdownPopupEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValues: [],
            channelList: props.getChannelList(),
            isDirty: false,
            searchValue: '',
            filterData: [],
            width: null,
            checked: {},
            searchValue: null,
            searchedValues: [],
        };
    }


    componentWillMount() {
        const channelList = this.state.channelList;
        const _this = this;
        const cellWidth = this.props.eGridCell.style.width;
        let flag = false;

        if (this.props.node.data.displayLevel == 'Color' && this.props.node.level == 1) {
            flag = true;
        }

        if (this.props.node.data.displayLevel == 'Style' && this.props.node.level == 0) {
            flag = true;
        }

        if (this.props.node.data.displayLevel == 'Size' && this.props.node.level == 2) {
            flag = true;
        }

        if (this.props.node.data.dataLevel == 'SINGLE') {
            flag = true;
        }


        if (!flag) {
            NotificationManager({ message: 'Channel not available at the current Display Level: ', type: 4 });
            this.props.api.stopEditing();
        } else {
            const selectedValues = _this.state.selectedValues;
            const checked = _this.state.checked;

            if (this.props.node.data.channelIds && (this.props.node.data.channelIds.length > 0)) {
                const channelIds = this.props.node.data.channelIds;
                channelIds.forEach(function (val) {
                    _this.state.channelList.forEach(function (obj) {
                        if (obj.fieldId == val) {
                            selectedValues.push(obj.fieldName);
                            checked[obj.fieldName] = true;
                        }
                    });
                });
            }

            this.setState({
                selectedValues, // : this.props.node.data.channelIds ? this.props.node.data.channelIds : this.state.selectedValues,
                channelList,
                width: cellWidth,
                checked,
            });
        }
    }

    componentWillUnmount() {
        if (this.state.isDirty) {
            const _this = this;
            const rowData = this.props.node.data;
            const channelId = [];
            this.state.selectedValues.forEach(function (val) {
                _this.state.channelList.forEach(function (obj) {
                    if (obj.fieldName.localeCompare(val) == 0) {
                        channelId.push(obj.fieldId);
                    }
                });
            });
            rowData['channelIds'] = channelId;
            this.props.rowUpdate(rowData, this.props.node.rowIndex);
        }
    }

    isPopup() {
        return true;
    }

    getValue() {
        return this.state.selectedValues.toString();
    }

    checkBoxSelect(event) {
        const target = event.target;
        const isChecked = target.type === 'checkbox' ? target.checked : target.value;
        // const name = target.name;
        const name = target.name;
        const checked = this.state.checked;
        const selectedObjs = this.state.selectedValues;

        if (checked[name] == true) {
            checked[name] = false;
        } else {
            checked[name] = true;
        }

        if (isChecked == true) {
            selectedObjs.push(name);
        } else {
            const index = selectedObjs.indexOf(name);
            if (index > -1) {
                selectedObjs.splice(index, 1);
            }
        }

        this.setState({ selectedValues: selectedObjs, isDirty: true, checked });
    }

    searchText(event) {
        const searchArr = [];
        const value = event.target.value;
        this.state.channelList.forEach(function (row) {
            if ((row.fieldName).toLowerCase().indexOf(value) != -1) {
                searchArr.push(row);
            }
        });

        this.setState({ searchValue: event.target.value, searchedValues: searchArr });
    }

    onMouseLeave(event) {
        this.props.api.stopEditing();
    }

    render() {
        const data = this.state.channelList;
        const searchedList = this.state.searchedValues;

        const showValues = (searchedList.length > 0) ? searchedList : data;


        const items = showValues.map((val, i) => {
            return (
                <div className="agCustomDropdownItemsCheckbox" key={i}>
                    <input type="checkbox" checked={this.state.checked[val.fieldName]} onChange={this.checkBoxSelect.bind(this)} name={val.fieldName} /> &nbsp;
                    <span>{val.fieldName}</span>
                </div>
            );
        });

        const listStyle = {
            width: this.state.width,
            overflowY: 'scroll',
            textAlign: 'left',
            padding: 2,
        };

        return (
            <div>
                <div
                    style={listStyle}
                    onMouseLeave={this.onMouseLeave.bind(this)}
                >
                    <input style={{ width: '100%', height: '30px' }} type="text" className="ag-DropdownSearchInput" placeholder="Search.." onChange={this.searchText.bind(this)}></input>
                    {items}
                </div>
            </div>
        );
    }
}


// ////////////////////////////////////////////////////////////////////////////////////
