import React from 'react';

class ReceiveSampleModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: this.props.isOpen,
            qty: 1,
        };
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.handleChangeAllowOnlyNumber = this.handleChangeAllowOnlyNumber.bind(this);
    }

    /**
     * Handling Change event to check for only numbers
     * @param {*} event
     */
    handleChangeAllowOnlyNumber(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const re = /^[0-9\b]+$/;
        if (parseInt(target.value, 10) === 0) {
            target.value = 1;
        }
        if (target.value == '' || re.test(target.value)) {
            this.setState({[name]: target.value});
        }
    }

    /**
     * Closes the popup
     */
    close() {
        this.setState({isOpen: false});
    }

    /**
     * Opens the popup
     */
    open() {
        this.setState({isOpen: true});
    }

    render() {
        return (
            <div className={`Sample-SimpleModal ${(this.state.isOpen) ? 'open' : ''}`}>
                <h3>RECEIVING SAMPLE(S)</h3>
                <div className="field-data">
                    <label>Quantity Received: <input type="text" name="qty" className="qtyReceived" value={this.state.qty} maxLength="3" onChange={this.handleChangeAllowOnlyNumber}/></label>
                </div>
                <div className="field-data">
                    <button className="gray-button" onClick={this.props.close}>Cancel</button>
                    <button className="sample-btn generate-card" disabled={this.props.disableClick ? true : false} onClick={this.props.generateSampleCard}>Generate Sample Card</button>
                </div>
            </div>
        );
    }
}

export default ReceiveSampleModal;
