import React, { Component } from 'react';
import DropDownComponent from './dropDownComponent';
import GridDataBuilder from './helperComponents/gridDataBuilder';
// import GridDataBuilder from '../common/gridDataBuilder';
import NotificationManager from '../common/components/notification';
import axios from 'axios';
import AdminServices from '../admin/admin-services/admin-services';


class DropDowns extends React.Component {
    /**
   * Represents a Vendor Page.
   * @constructor
   * @param {object} props - Component properties
   */
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isModalOpen: false,
            dropdownlist: null,
            userList: {},
            vendorTotalCount: 0,
            selectedRowsCount: 0,
            checkedRows: [],
            checkedVendorData: [],
            vendorUpdateData: {},
            addRowCount: 0,
            isApplyEnabled: false,
        };
        this.isModified = false;
        this.oldData = null;
        this.modifiedRows = [],
        this.modalOpen = this.modalOpen.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.addGridRow = this.addGridRow.bind(this);
        this.updateGridRow = this.updateGridRow.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.loadDropdownGridData = this.loadDropdownGridData.bind(this);
        this.applySaveChanges = this.applySaveChanges.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
    }

    handleSelectChange(e) {
        const base = this;
        const target = e.target;
        const value = target.value;
        base.setState({ isLoaded: false });
        base.loadDropdownGridData(value);
    }

    loadDropdownGridData(atrType) {
    // console.log('loadDropdownGridData', atrType);
        const base = this;
        const data = { 'id': atrType };
        AdminServices.getDropdownData(data).then((response) => {
            const res = response;
            base.oldData = res.data;
            base.gridData = GridDataBuilder(res);
            base.setState({ isLoaded: true, vendorTotalCount: res.data.length, selectedRowsCount: 0 });
        }).catch((error) => {
            console.log('Admin DropDowns loadDropdownGridData API call failed', error);
        });
    }


    /**
   * Menthod - To get vendor list and update state once component begind created into DOM
   */
    componentDidMount() {
        const postData = { 'id': this.props.user.groupId };
        const base = this;

        AdminServices.dropdownList().then((response) => {
            const res = response;
            base.setState({ dropdownlist: res.dropdowns });
            const atrtype = res.dropdowns[0];
            base.loadDropdownGridData(atrtype);
        }).catch(() => {
            console.log('Admin DropDowns componentDidMount API call failed', error);
        });
    }

    /**
   * component where prps are updated
   * @param{*} prevProps
   * @param{*} prevState
   */
    componentDidUpdate(prevProps, prevState) {
    // const _this = this;
    // if (prevState.isLoaded !== this.state.isLoaded && this.dropdownsGrid) {
    //   // Row select
    //   this.dropdownsGrid.on('rowselect', function (event) {
    //     const rowIndexs = _this.dropdownsGrid.getselectedrowindexes();
    //     const newCheckedVendorData = [];
    //     rowIndexs.map((val, key) => {
    //       const vendor = _this.dropdownsGrid.getrowdata(val);
    //       newCheckedVendorData.push(vendor);
    //     });
    //     _this.setState({ selectedRowsCount: rowIndexs.length, checkedRows: rowIndexs, checkedVendorData: newCheckedVendorData });
    //   });

        //   // Row Unselect
        //   this.dropdownsGrid.on('rowunselect', function (event) {
        //     const rowIndexs = _this.dropdownsGrid.getselectedrowindexes();
        //     const newCheckedVendorData = [];
        //     rowIndexs.map((val, key) => {
        //       const vendor = _this.dropdownsGrid.getrowdata(val);
        //       newCheckedVendorData.push(vendor);
        //     });
        //     _this.setState({ selectedRowsCount: rowIndexs.length, checkedRows: rowIndexs, checkedVendorData: newCheckedVendorData });
        //   });

    //   // Row Unselect
    //   this.dropdownsGrid.on('cellvaluechanged', function (event) {
    //     _this.isModified = true;
    //     _this.setState({ isApplyEnabled: true });
    //   });
    // }
    }


    modalOpen() {
        this.setState({ isModalOpen: true });
    }

    modalClose(key) {
        if (key == 'yes') {
            //this.deleteRow();
            //this.setState({isModalOpen:false});
            this.applySaveChanges();
        } else if (key == 'no') {
            const atGroup = $('.atrGroup').val();
            this.setState({ isLoaded: false });
            this.loadDropdownGridData(atGroup);
            this.setState({ isModalOpen: false });
        } else {
            this.setState({ isModalOpen: false });
        }
    }

    // Append new row once save vendor gets success
    addGridRow() {
        const _this = this;
        this.state.addRowCount++;
        const atGroup = $('.atrGroup').val();
        const id = 'NEW' + this.state.addRowCount;
        const data = { idDropDown: id, atGroup: atGroup, ordering: 0};
        const selectedrowindexs = this.dropdownsGrid.getselectedrowindexes();
        this.dropdownsGrid.addrow(id, data, 'first');
    }

    deleteItem() {
        const _this = this;
        const selectedrowindexs = _this.dropdownsGrid.getselectedrowindexes();
        const selectedrowids = [];
        const selectedrowdata = null;
        if (selectedrowindexs.length > 0) {
            //this.setState({isModalOpen:true});
            _this.deleteRow();
        } else {
            NotificationManager({ message: 'Please select atleast one items to delete', type: 4 });
        }
    }

    deleteRow() {
        const _this = this;
        const selectedrowindexs = _this.dropdownsGrid.getselectedrowindexes();
        const selectedrowids = [];
        let selectedrowdata = null;
        const atGroup = $('.atrGroup').val();
        if (selectedrowindexs.length > 0) {
            let selectedrowdata = [],
                unsavedrows = [];
            selectedrowindexs.map((val, key) => {
                const dt = _this.dropdownsGrid.getrowdata(val);
                if (dt.idDropDown.toString().indexOf('NEW') > -1) {
                    unsavedrows.push(dt.idDropDown);
                } else {
                    selectedrowdata.push(dt.idDropDown);
                }
            });
            if (unsavedrows.length > 0) {
                _this.dropdownsGrid.deleterow(unsavedrows);
            }
            console.log(selectedrowdata);
            if (selectedrowdata.length > 0) {
                const data = { id: selectedrowdata, value: atGroup };

                AdminServices.deleteDropdownData(data).then((response) => {
                    const res = response;

                    if (res.status) {
                        NotificationManager({ message: res.responseMessage, type: 1 });
                        _this.setState({ isLoaded: false });
                        _this.loadDropdownGridData(atGroup);
                    } else {
                        NotificationManager({ message: res.responseMessage, type: 4 });
                    }
                }).catch((error) => {
                    console.log('Admin DropDowns deleteRow API call failed', error);
                    NotificationManager({ message: 'Please select atleast one items to delete', type: 4 });
                });
            }
        } else {
            NotificationManager({ message: 'Please select atleast one items to delete', type: 4 });
        }
    }

    // Update vendor grid after edit
    updateGridRow(req) {
        const data = req;
        this.dropdownsGrid.updaterow(data.rowindex, data);
    }

    applySaveChanges() {
        const _this = this;
        // const rows = _this.dropdownsGrid.getrows();
        const rows = this.modifiedRows;

        if (!_this.isModified) {
            NotificationManager({ message: 'No changes done', type: 4 });
            return;
        }

        let error = false;
        const postData = [];
        // rows[0].atrType = 'urban'; // added so dropdown values save for Urban Demo
        rows.map((e, i) => {
            // if (e.atrType === null || e.atrType === '' || e.atrType === undefined) {
            //   _this.dropdownsGrid.showvalidationpopup(i, 'atrType', 'Invalid data');
            //   error = true;
            // }
            if (e.userGroup === null || e.userGroup.trim() === '' || e.userGroup === undefined) {
                _this.dropdownsGrid.showvalidationpopup(i, 'userGroup', 'Invalid data');
                error = true;
            }
            if (e.userGroup.length > 150) {
                // _this.dropdownsGrid.showvalidationpopup(i,'userGroup','Shorten <100 characters');
                NotificationManager({ message: 'Please shorten values to <150 characters', type: 4 });
                _this.dropdownsGrid.begincelledit(i, 'userGroup');
                error = true;
            }
            if (e.ordering === null || e.ordering === '' || e.ordering === undefined) {
                _this.dropdownsGrid.showvalidationpopup(i, 'ordering', 'Invalid data');
                error = true;
            }
            if (typeof (e.idDropDown) === 'string' && e.idDropDown.indexOf('NEW') > -1) {
                e.idDropDown = null;
            }
        });
        if (!error) {
            AdminServices.saveOrUpdateDropdownData(rows).then((response) => {
                const res = response;
                _this.state.addRowCount = 0;
                const atGroup = $('.atrGroup').val();
                if (res.status) {
                    NotificationManager({ message: res.responseMessage, type: 1 });
                    _this.setState({ isLoaded: false }, () => {
                        _this.dropDownFooter.disableFooter();
                    });
                    _this.loadDropdownGridData(atGroup);
                    _this.modalClose();
                    _this.modifiedRows = [];
                } else {
                    NotificationManager({ message: res.responseMessage, type: 4 });
                }
            }).catch((error) => {
                console.log('Admin DropDowns deleteRow API call failed', error);
                NotificationManager({ message: error, type: 4 });
            });
        }
    }

    cancelChanges() {
        const _this = this;
        if (_this.isModified) {
            const atGroup = $('.atrGroup').val();
            _this.modalOpen();
        } else {
            NotificationManager({ message: 'No changes done in the grid', type: 4 });
        }
        _this.setState({ isApplyEnabled: false });
    //_this.setState({isLoaded:false});
    //_this.loadDropdownGridData(atGroup);
    }


    // checks for Cellvaluechanged on the grid.
    onCellvaluechanged(event) {
        const _this = this;
        let changedArr = this.modifiedRows;
        let uniqueArr = [];
        let getData = this.dropdownsGrid.getrowdata(event.args.rowindex);

        // pushes changed rows to a new array
        changedArr.push(getData);

        // filters the array for duplicates
        let newArr = changedArr.reduce((unique, o) => {
            if(!uniqueArr.some(obj => obj.uniqueid === o.uniqueid)) {
                uniqueArr.push(o);
            }
            return uniqueArr;
        }, []);

        this.modifiedRows = newArr;
        this.isModified = true;
        _this.dropDownFooter.enableFooter();
    }

    // Used for selected records on the grid.
    onRowselect(event) {
        const rowIndexs = this.dropdownsGrid.getselectedrowindexes();
        const newCheckedVendorData = [];
        rowIndexs.map((val, key) => {
            const vendor = this.dropdownsGrid.getrowdata(val);
            newCheckedVendorData.push(vendor);
        });
        this.setState({ selectedRowsCount: rowIndexs.length, checkedRows: rowIndexs, checkedVendorData: newCheckedVendorData });
    }

    // Used for UnSelected records on the grid.
    onRowunselect(event) {
        const rowIndexs = this.dropdownsGrid.getselectedrowindexes();
        const newCheckedVendorData = [];
        rowIndexs.map((val, key) => {
            const vendor = this.dropdownsGrid.getrowdata(val);
            newCheckedVendorData.push(vendor);
        });
        this.setState({ selectedRowsCount: rowIndexs.length, checkedRows: rowIndexs, checkedVendorData: newCheckedVendorData });
    }

    render() {
        const footerHeight = 50;
        const headerHeight = 64;
        $('.page-title a').text("ADMIN: Dropdowns");

        const gridHeight = window.innerHeight - footerHeight - headerHeight - 70;
        const MakeItem = function (x, i) {
            return <option key={i} value={x}>{x}</option>;
        };

        return (
            <DropDownComponent
                state={this.state}
                props={this.props}
                MakeItem={MakeItem}

                onRowselect={this.onRowselect.bind(this)}
                onRowunselect={this.onRowunselect.bind(this)}
                onCellvaluechanged={this.onCellvaluechanged.bind(this)}

                handleSelectChange={this.handleSelectChange}
                dropDownGridRef={(grid) => {
                    this.dropdownsGrid = grid;
                }}
                dropDownFooterRef={(grid) => {
                    this.dropDownFooter = grid;
                }}
                gridData={this.gridData}
                gridHeight={gridHeight}

                deleteItem={this.deleteItem}
                addGridRow={this.addGridRow}
                applySaveChanges={this.applySaveChanges}
                cancelChanges={this.cancelChanges}

                modalClose={this.modalClose}
            />
        );
    }
}

export default DropDowns;
