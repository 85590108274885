import React from 'react';
import CancelModal from '../../common/modals/cancelModal';
import NotificationManager from '../../common/components/notification';
import axios from 'axios';
import AdminServices from '../../admin/admin-services/admin-services';
import {CommonServices} from '../../common/services/common-services';

class AddUserGroup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            group: {
                title: '',
                defaultView: '',
                viewListDTOs: [],
            },
            viewList: [],
            isDirty: false,
            errorMessage: '',
            isModified: false,
            isModalOpen: false,
            isFirstView: true,
        };

        this.openModalCancel = this.openModalCancel.bind(this);
        this.modalClose = this.modalClose.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.saveUserGroup = this.saveUserGroup.bind(this);
    }

    componentDidMount() {
        this.getViews();
    }

    /**
     * Getter for views calls backend
     */
    getViews() {
        const _this = this;

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        CommonServices.getViews()
            .then( (response) => {
                const data = response;

                const viewLists = data;
                viewLists.map((val, index) => {
                    viewLists[index].checked = false;
                    viewLists[index].default = false;
                });

                _this.setState({viewList: viewLists});
            })
            .catch( (error) => {
                console.log('UserGroup updateUserGroup API call failed', error);

                NotificationManager({
                    message: error,
                    type: 4,
                });
            });
    }

    /**
     * Closes the popup
     * @param {*} key
     */
    modalClose(key) {
        this.setState({ isModalOpen: false });

        switch (key) {
            case 'updateUser':
                this.saveUserGroup();
                break;

            case 'no':
                this.props.modalClose();
                break;
        }
    }

    /**
     * Opens the cancel modal
     */
    openModalCancel() {
        if (this.state.isModified) {
            this.setState({ isModalOpen: true });
        } else {
            this.props.modalClose();
        }
    }

    /**
     * Checks input fields
     * @param {*} event
     */
    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (value.trim() === '' || value.trim() === undefined) {
            target.className += ' invalid';
        } else {
            target.classList.remove('invalid');
        }

        const {group} = this.state;
        const newGroup = group;

        const regex = /^[a-z0-9 ]+$/i;

        if (regex.test(value) || value === '') {
            newGroup[name] = value;
        }

        this.setState({ group: newGroup, isModified: true });
    }

    /**
     * Toggles the checkboxes
     * @param {*} key
     */
    toggleCheckbox(key) {
        const { viewList } = this.state;
        const newViewList = viewList;

        newViewList.forEach((e, index) => {
            if (index == key) {
                e.checked = !e.checked;

                if (this.state.isFirstView) {
                    e.default = true;
                }

                if (!e.checked) {
                    e.default = false;
                }
            }
        });

        this.setState({ viewList: newViewList, isFirstView: false });
    }

    /**
     * Toggles the checkbox menu
     * @param {*} key
     */
    toggleCheckboxMenu(key) {
        const { viewList } = this.state;
        const newViewList = viewList;

        newViewList.forEach((e, index) => {
            if (index == key) {
                e.checked = !e.checked;
            }
        });

        this.setState({ viewList: newViewList });
    }

    /**
     * Toggles the radio buttons
     * @param {*} key
     */
    toggleRadio(key) {
        const {viewList} = this.state;
        const newViewList = viewList;

        newViewList.forEach((e, index) => {
            if (index != key) {
                e.default = false;
            }
        });

        newViewList[key].default = !newViewList[key].default;
        this.setState({ viewList: newViewList });
    }

    /** Formats the passed object in jqwidget required format
     * @param {object} obj
     * @return {object}
     */
    formatNewUserObject(obj) {
        const newObj = {};
        newObj['Default View'] = obj.defaultView;
        newObj.title = obj.title;
        newObj.enabled = true;
        newObj.roleId = obj.roleId;

        obj.viewListDTOs.forEach((item, index) =>{
            newObj[item.viewName] = {
                status: 'V',
                viewId: item.viewId,
                isViewObject: item.viewObject,
            };
        });

        return newObj;
    }

    /**
     * Calls backend to save the user group
     * @param {*} event
     */
    saveUserGroup(event) {
        const _this = this;
        const {group, viewList} = this.state;

        const newGroup = group;
        const newViewList = viewList;
        const checkedGroupList = [];
        newViewList.map((e, index) => {
            if (e.checked) {
                checkedGroupList.push({
                    viewId: e.viewId,
                    viewName: e.viewName,
                    viewObject: e.viewObject
                });
            }

            if (e.default) {
                newGroup.defaultView = e.viewName;
            }
        });

        newGroup.viewListDTOs = checkedGroupList;

        // Validation
        let flag = 0;
        const regex = /^[A-Za-z0-9']+(\s[A-Za-z0-9']+)*$/i;

        if (newGroup.title === '' || newGroup.title.trim() === '' ) {
            flag++;

            this.setState({
                isDirty: true,
                errorMessage: 'Group title should not be empty.'
            });
        } else if (newGroup.defaultView === '') {
            flag++;

            this.setState({
                isDirty: true,
                errorMessage: 'Atleast one ‘View’ needs to be selected for the user group.'
            });
        } else {
            this.setState({ isDirty: false });

            if (!newGroup.title.match(regex)) {
                newGroup.title = newGroup.title.trim();
                newGroup.title = newGroup.title.replace(/\s\s+/g, ' ');
            }
        }

        if (flag === 0) {
            const req = JSON.stringify([newGroup]);

            AdminServices.insertUserGroups(req).then((response) => {
                const res = response;

                // User group name already exists. Either use a new user group name or cancel and edit the original user group
                if (res.status) {
                    _this.props.modalClose();

                    if (res.responseMessage.includes('already exists')) {
                        NotificationManager({
                            message: res.responseMessage,
                            type: 4,
                        });
                    } else {
                        NotificationManager({
                            message: res.responseMessage,
                            type: 1,
                        });
                    }

                    if (res.data && res.data.id) {
                        newGroup.roleId = res.data.id;
                        _this.props.addNewGroup(_this.formatNewUserObject(newGroup));
                    }
                } else {
                    _this.setState({
                        isDirty: true,
                        errorMessage: res.responseMessage
                    });
                }
            }).catch((error) => {
                console.log('UserGroup updateUserGroup API call failed', error);
                NotificationManager({
                    message: error,
                    type: 4,
                });
            });
        }
    }

    render() {
        const views = this.state.viewList.map((val, index) => {
            if (val.viewObject) {
                return (
                    <div className="row" key={index}>
                        <div className="col-4">
                            <label className="form-label" title={val.viewName}>{val.viewName}:</label>
                        </div>
                        <div className="col-4">
                            <label className="form-check-label">
                                <input
                                    type="checkbox"
                                    className="form-control-check"
                                    id={val.viewName}
                                    disabled={val.default}
                                    name={val.viewName}
                                    checked={val.checked}
                                    onChange={() => this.toggleCheckbox(index)}
                                />
                                <span htmlFor={val.viewName} className="from-checkbox"></span>
                            </label>
                        </div>
                        <div className="col-4">
                            <label className="form-radio-label">
                                <input
                                    type="radio"
                                    name="default"
                                    checked={val.default}
                                    disabled={!val.checked}
                                    className="form-control-radio"
                                    onChange={() => this.toggleRadio(index)}
                                />
                            </label>
                        </div>
                    </div>
                );
            }
        });

        const menuItems = this.state.viewList.map((val, index) => {
            if (!val.viewObject) {
                return (
                    <div className="row" key={index}>
                        <div className="col-4">
                            <label className="form-label">{val.viewName}:</label>
                        </div>
                        <div className="col-4">
                            <label className="form-check-label">
                                <input
                                    type="checkbox"
                                    className="form-control-check"
                                    id={val.viewName}
                                    disabled={val.default}
                                    name={val.viewName}
                                    checked={val.checked}
                                    onChange={() => this.toggleCheckboxMenu(index)}
                                />
                                <span htmlFor={val.viewName} className="from-checkbox"></span>
                            </label>
                        </div>
                    </div>
                );
            }
        });

        return (
            <div>
                <div className="modal-header">
                    Add New Group
                    <span className="close" onClick={this.openModalCancel}>x</span>
                </div>
                <div className="modal-content">
                    <div className="add-user-group modal-form">
                        <form className="addNewGroup" name="addNewUser">
                            <div className="form-container scroll">
                                <h4>User Group Details</h4>
                                <hr/>
                                <div className="form-group">
                                    <label className="form-label">Group Title</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="title"
                                        value={this.state.group.title}
                                        onChange={this.handleChange}
                                        maxLength="50"
                                    />
                                </div>

                                <div className="row">
                                    <div className="col-4"><span className="form-subtitle userGroup">View Permissions</span></div>
                                    <div className="col-4"><span className="form-subtitle userGroup">Visibility*</span></div>
                                    <div className="col-4"><span className="form-subtitle userGroup">Default*</span></div>
                                </div>
                                <div className="form-group">
                                    {views}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <input
                                    type="button"
                                    className="gray-button"
                                    value="Add User Group"
                                    onClick={this.saveUserGroup}
                                />
                            </div>
                            {(this.state.isDirty) ? <span className="error-message">{this.state.errorMessage}</span> : '' }
                        </form>
                    </div>
                </div>
                <CancelModal
                    data={this.state.group}
                    isOpen={this.state.isModalOpen}
                    modalClose={this.modalClose}
                />
            </div>
        );
    }
}

export default AddUserGroup;
