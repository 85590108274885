import React, { Component } from 'react';
import { connect } from 'react-redux';

class AdminFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addLabel: 'Add',
            deleteLabel: 'Delete',
            isModalOpen: false,
            isConfirmModalOpen: false,
            vendorIds: [],
            modalName: '',
            enableFooter: false,
        };
    }

    enableFooter() {
        this.setState ({enableFooter:true});
    }

    disableFooter() {
        this.setState ({enableFooter:false});
    }

    render() {
        let isDisableDelete = true;
        if (this.props.selectedRows > 0) {
            isDisableDelete = false;
        }
        const footerContent = (
            <div>
                <div className="footer-content">
                    <button className="admin-addrecord gray-button" onClick={this.props.addGridRow}>{this.state.addLabel}</button>
                    <button className="admin-addrecord gray-button" onClick={this.props.delete} disabled={isDisableDelete}>{this.state.deleteLabel}</button>
                </div>
                <div className="footer-row-details">
                    <span className="row-count">{this.props.vendorCount} Total Rows</span>
                    <span className="row-selected"> | Selected: {this.props.selectedRows}</span>
                </div>
                <div className="footer-content right">
                    <button className="cancel_changes gray-button" onClick={this.props.cancelChanges} disabled={!this.state.enableFooter}>Cancel Changes</button>
                    <button className="apply_changes gray-button" onClick={this.props.applySaveChanges} disabled={!this.state.enableFooter}>Apply Changes</button>
                </div>
            </div>
        );

        return (
            <div className="footer admin-footer">
                {footerContent}
            </div>
        );
    }
}

export default AdminFooter;
// const mapSateToProps = (state) => {
//   return {
//     userView: state.userView,
//   };
// };

// export default connect(mapSateToProps)(AdminFooter);
