import React, { Component } from 'react';

class DropDown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected :''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(e) {
        var target = e.target;
        var value = target.value;
        this.setState({ selected: value });
    }

    render() {
        return (
            <select defaultValue={'productId'} onChange={this.handleInputChange}>
                {this.props.list.map((item, index) => {
                    return (
                        <option key={index} value={item.fieldName}>{item.columnTitle}</option>
                    );
                })}
            </select>
        );
    }
}

export default DropDown;
