import React, {Component} from 'react';

class SavedPresentationDeleteConfirm extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let props = this.props;
        let header = 'Are you sure you want to delete this list?';

        return (
            <div>
                <div className="modal-content">
                    <div className="text-center confirm-delete">
                        <h4>{header}</h4>
                    </div>
                    <div className="modal-footer text-center">
                        <button className="gray-button" onClick={() => {
                            props.confirmDelete();
                            props.modalClose();
                        }}>
                            Yes
                        </button>

                        <button className="gray-button" onClick={() => {
                            props.modalClose();
                        }}>No
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default SavedPresentationDeleteConfirm;
