import React from 'react';
import NotificationManager from '../../common/components/notification';

export class CheckboxRender extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: props.value || false,
            displayCheckbox: false,
            disabled: true,
            disabledParent: true,
            parentChecked: props.value || false,
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentWillMount() {
        const userRole = this.props.userData.defaultRole.toLowerCase();
        const _this = this;

        if (!this.props.node.group) {
            this.setState({ displayCheckbox: true });
        }

        if (userRole == 'photographer' && _this.props.colDef.colId == 'photographFlag') {
            this.setState({ disabled: false });
        }

        if (userRole == 'retoucher' && _this.props.colDef.colId == 'reTouchFlag') {
            this.setState({ disabled: false });
        }

        this.checkParentLevel();
    }

    checkParentLevel(data) {
        const colId = this.props.colDef.colId;

        const _this = this;
        let flag = false;

        // to check parent level when the checkbox renders
        if (this.props.node.data && this.props.node.group == true) {
            this.props.node.data.creativeShots.map((i) => {
                if (i.colId) {
                    flag = true;
                } else {
                    flag = false;
                }
            });
        }

        // for on click check at parent level
        if (data) {
            this.props.api.forEachNode((i) => {
                if (i.data.ppId == data.ppId) {
                    i.setDataValue(colId, true);
                }

                if (i.group) {
                    if (i.data.productId == data.sweftId) {
                        i.data.creativeShots.map((j) => {
                            if (j.ppId == data.ppId) {
                                flag = true;
                            } else {
                                flag = false;
                            }
                        });

                        if (flag) {
                            i.setDataValue(colId, true);
                        }
                    }
                }
            });
        }

        if (flag) {
            _this.setState({ parentChecked: true });
        }
    }

    checkboxForPhotographer() {
        const _this = this;
        const colId = this.props.colDef.field;
        const val = this.state.checked;
        const ppId = this.props.data.ppId;
        const rowNode = this.props.node;

        let data = {};
        data[colId] = val;
        data['ppId'] = ppId;
        data['sweftId'] = this.props.data.sweftId;
        data['retouchNotes'] = this.props.data.retouchNotes;

        _this.props.updateCheckboxes(data, rowNode);
        this.checkParentLevel(data);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const data = this.props.data;
        const userData = this.props.userData;
        const colId = this.props.colDef.colId;
        const _this = this;
        let flag = false;

        // validations for the checkboxes
        this.setState({
            checked : value,
        }, () => {
            this.checkboxForPhotographer();
        });
    }

    render() {
        return (
            <div className="ag-checkboxContainer">
                <div className="ag-check-box-container blue-checkbox custom-grid-checkbox" >
                    { this.state.displayCheckbox ?
                        <div>
                            <input
                                type="checkbox"
                                checked={this.state.checked}
                                disabled = {this.state.disabled}
                                onChange={this.handleInputChange} >
                            </input>
                            <label></label>
                        </div>
                        :
                        <div>
                            <input
                                type="checkbox"
                                checked={this.state.parentChecked}
                                disabled = {this.state.disabledParent}
                            >
                            </input>
                            <label></label>
                        </div>
                    }
                </div>
            </div>
        );
    }
}
