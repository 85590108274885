import React from 'react';
import axios from 'axios';
import request from '../../common/REST/request';
import {endpoint} from '../../common/REST/endpoints';
import {headers} from '../../common/REST/headers';

var ProductServices = {
    /** Product List API with search query **/
    productSearch: function(data, source) {
        return request({
            method: endpoint.product.productSearch.method,
            url: endpoint.product.productSearch.url,
            headers: headers,
            data: data || ''
        })
            .then((response) => {
                console.log('applyFilter response is: ', response.data);
                let product2GridData = {};
                product2GridData['data'] = response.data.responseData.data;
                product2GridData['columnDetails'] = response.data.responseData.columnDetails;
                product2GridData['recordCount'] = response.data.responseData.recordCount;
                product2GridData['req'] = data;
                product2GridData['catIds'] = response.data.responseData.catIds;
                product2GridData['fromLeftDrawer'] = ( source == "fromLeftDrawer" ? true : false );
                if (response.data.responseData.recordCount == 0 && response.data.responseData.data.length == 0) {
                    NotificationManager({
                        message: 'No records found.',
                        type: 4,
                    });
                }
                return product2GridData;
            })
            .catch(error => {
                console.error('leftDrawerContainer applyFilter  API call failed', error);
                return error;
            });
    },

    getProductCategories: function() {
        return request({
            method: endpoint.product.getProductCategories.method,
            url: endpoint.product.getProductCategories.url,
            headers: headers
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    getProductDropdown: function() {
        return request({
            method: endpoint.product.getProductDropdown.method,
            url: endpoint.product.getProductDropdown.url,
            headers: headers
        })
            .then((response) => {
                if (response.data && response.data.status === 'success') {
                    return response.data;
                } else {
                    return Promise.reject(response);
                }
            })
            .catch(error => {
                console.error('leftDrawerContainer getProductCategory API call failed', error);
                return Promise.reject(error);
            });
    },

    getRequiredFields: function() {
        return request({
            method: endpoint.product.getRequiredFields.method,
            url: endpoint.product.getRequiredFields.url,
            headers: headers
        })
            .then((response) => {
                if (response.data && response.data.status === 'success') {
                    return response.data.responseData;
                } else {
                    return Promise.reject(response);
                }
            })
            .catch(error => {
                console.error('leftDrawerContainer getProductCategory API call failed', error);
                return Promise.reject(error);
            });
    },

    addUpdateProduct: function(dataObj) {
        return request({
            method: endpoint.product.addUpdateProduct.method,
            url: endpoint.product.addUpdateProduct.url,
            headers: headers,
      		data: dataObj
        })
            .then((response) => {
                if (response.data && response.data.status === 'success') {
                    return response.data;
                } else {
                    return Promise.reject(response);
                }
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },

    updateOrAddProduct: function(dataObj) {
        return request({
            method: endpoint.product.updateOrAddProduct.method,
            url: endpoint.product.updateOrAddProduct.url,
            headers: headers,
      		data: dataObj
        })
            .then((response) => {
                if (response.data && (response.data.status === 'success' || response.data.status === true )) {
                    return response.data;
                } else {
                    return Promise.reject(response.data);
                }
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },

    deleteRecord: function(dataObj) {
        return request({
            method: endpoint.product.deleteRecord.method,
            url: endpoint.product.deleteRecord.url,
            headers: headers,
      		data: dataObj
        })
            .then((response) => {
                return response.data || response;
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },

    swatchUpdate: function(dataObj) {
        return request({
            method: endpoint.product.swatchUpdate.method,
            url: endpoint.product.swatchUpdate.url,
            headers: headers,
      		data: dataObj
        })
            .then((response) => {
                return response;
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },

    updateKeywords: function(dataObj) {
        return request({
            method: endpoint.product.updateKeywords.method,
            url: endpoint.product.updateKeywords.url,
            headers: headers,
      		data: dataObj
        })
            .then((response) => {
                return response;
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },

    getProductId: function() {
        return request({
            method: endpoint.product.getProductId.method,
            url: endpoint.product.getProductId.url,
            headers: headers
        })
            .then((response) => {
                return response;
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },

    getKeywords: function(dataObj) {
        return request({
            method: endpoint.product.getKeywords.method,
            url: endpoint.product.getKeywords.url,
            headers: headers,
      		data: dataObj
        })
            .then((response) => {
                return response;
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },

    updateCopyWritter: function(dataObj) {
        return request({
            method: endpoint.product.updateCopyWritter.method,
            url: endpoint.product.updateCopyWritter.url,
            headers: headers,
      		data: dataObj
        })
            .then((response) => {
                return response;
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },

    submitCopyWritter: function (dataObj) {
        let option = {
            method: endpoint.product.submitCopyWritter.method,
            url: endpoint.product.submitCopyWritter.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    viewColumndetails: function (dataObj) {
        let option = {
            method: endpoint.product.viewColumndetails.method,
            url: endpoint.product.viewColumndetails.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    getCopyWritter: function (dataObj) {
        let option = {
            method: endpoint.product.getCopyWritter.method,
            url: endpoint.product.getCopyWritter.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    saveNewView: function (dataObj) {
        let option = {
            method: endpoint.product.saveNewView.method,
            url: endpoint.product.saveNewView.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    approveByBuyer: function (dataObj) {
        let option = {
            method: endpoint.product.approveByBuyer.method,
            url: endpoint.product.approveByBuyer.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    isViewExists: function (dataObj) {
        let option = {
            method: endpoint.product.isViewExists.method,
            url: endpoint.product.isViewExists.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    filters: function (dataObj) {
        let option = {
            method: endpoint.product.filters.method,
            url: endpoint.product.filters.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    productsearch: function (dataObj) {
        let option = {
            method: endpoint.product.productsearch.method,
            url: endpoint.product.productsearch.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    deleteView: function (dataObj) {
        let option = {
            method: endpoint.product.deleteView.method,
            url: endpoint.product.deleteView.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    getSamplesData: function (dataObj) {
        let option = {
            method: endpoint.product.getSamplesData.method,
            url: endpoint.product.getSamplesData.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    receiveSamples: function (dataObj) {
        let option = {
            method: endpoint.product.receiveSamples.method,
            url: endpoint.product.receiveSamples.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    moveSample: function (dataObj) {
        let option = {
            method: endpoint.product.moveSample.method,
            url: endpoint.product.moveSample.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    getSampleIds: function (dataObj) {
        let option = {
            method: endpoint.product.getSampleIds.method,
            url: endpoint.product.getSampleIds.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    imageUpload: function (dataObj) {
        let option = {
            method: endpoint.product.imageUpload.method,
            url: endpoint.product.imageUpload.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },
    importPOFile: function (dataObj) {
        let option = {
            method: endpoint.product.importPOFile.method,
            url: endpoint.product.importPOFile.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    addPO: function (dataObj) {
        let option = {
            method: endpoint.product.addPO.method,
            url: endpoint.product.addPO.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    generateSampleCard: function (dataObj) {
        let option = {
            method: endpoint.product.generateSampleCard.method,
            url: endpoint.product.generateSampleCard.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    updateSample: function (dataObj) {
        let option = {
            method: endpoint.product.updateSample.method,
            url: endpoint.product.updateSample.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    updateProductSample: function (dataObj) {
        let option = {
            method: endpoint.product.updateProductSample.method,
            url: endpoint.product.updateProductSample.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    saveSamples: function (dataObj) {
        let option = {
            method: endpoint.product.saveSamples.method,
            url: endpoint.product.saveSamples.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    importFilePIUpload: function (dataObj) {
        let option = {
            method: endpoint.product.importFilePIUpload.method,
            url: endpoint.product.importFilePIUpload.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    importFilePIUploadpdf: function (dataObj) {
        let option = {
            method: endpoint.product.importFilePIUploadpdf.method,
            url: endpoint.product.importFilePIUploadpdf.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    getPiForProduct: function (dataObj) {
        let option = {
            method: endpoint.product.getPiForProduct.method,
            url: endpoint.product.getPiForProduct.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    deletePiForProduct: function (dataObj) {
        let option = {
            method: endpoint.product.deletePiForProduct.method,
            url: endpoint.product.deletePiForProduct.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    resizeColOrderUpdate: function (dataObj) {
        let option = {
            method: endpoint.product.resizeColOrderUpdate.method,
            url: endpoint.product.resizeColOrderUpdate.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    updateAttrCompleted: function (dataObj) {
        let option = {
            method: endpoint.product.updateAttrCompleted.method,
            url: endpoint.product.updateAttrCompleted.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    activateOnWebsite: function (dataObj) {
        let option = {
            method: endpoint.product.activateOnWebsite.method,
            url: endpoint.product.activateOnWebsite.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    saveScanner: function (dataObj) {
        let option = {
            method: endpoint.product.saveScanner.method,
            url: endpoint.product.saveScanner.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    saveParentChld: function (dataObj) {
        let option = {
            method: endpoint.product.saveParentChld.method,
            url: endpoint.product.saveParentChld.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    getLevelList: function (qryString) {
        let option = {
            method: endpoint.product.getLevelList.method,
            url: endpoint.product.getLevelList.url,
            headers: headers
        };
        if (qryString) {
            url += qryString;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    groupByPopUp: function (dataObj) {
        let option = {
            method: endpoint.product.groupByPopUp.method,
            url: endpoint.product.groupByPopUp.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    listproductEvents: function (dataObj) {
        let option = {
            method: endpoint.product.listproductEvents.method,
            url: endpoint.product.listproductEvents.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    saveorupdateproductevent: function (dataObj) {
        let option = {
            method: endpoint.product.saveorupdateproductevent.method,
            url: endpoint.product.saveorupdateproductevent.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    moveSampleRequest: function (dataObj) {
        let option = {
            method: endpoint.product.moveSampleRequest.method,
            url: endpoint.product.moveSampleRequest.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    getcloneProductIds: function (qryString) {
        let option = {
            method: endpoint.product.getcloneProductIds.method,
            url: endpoint.product.getcloneProductIds.url + qryString,
            headers: headers
        };
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    activeliveeupdate: function (dataObj) {
        let option = {
            method: endpoint.product.activeliveeupdate.method,
            url: endpoint.product.activeliveeupdate.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    forceActivateOnWebsite: function (dataObj) {
        let option = {
            method: endpoint.product.forceActivateOnWebsite.method,
            url: endpoint.product.forceActivateOnWebsite.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    getAngles: function (qryString) {
        let option = {
            method: endpoint.product.getAngles.method,
            url: endpoint.product.getAngles.url + qryString,
            headers: headers
        };

        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    saveAngles: function (dataObj) {
        let option = {
            method: endpoint.product.saveAngles.method,
            url: endpoint.product.saveAngles.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    sessionLock: function (qryString) {
        let option = {
            method: endpoint.product.sessionLock.method,
            url: endpoint.product.sessionLock.url + qryString,
            headers: headers
        };

        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    getAdminSettings: function (dataObj) {
        let option = {
            method: endpoint.product.getAdminSettings.method,
            url: endpoint.product.getAdminSettings.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    exportPO: function (dataObj) {
        let option = {
            method: endpoint.product.exportPO.method,
            url: endpoint.product.exportPO.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    getAttributeDropDown: function (dataObj) {
        let option = {
            method: endpoint.product.getAttributeDropDown.method,
            url: endpoint.product.getAttributeDropDown.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    getImageLst: function (dataObj) {
        let option = {
            method: endpoint.product.getImageLst.method,
            url: endpoint.product.getImageLst.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    deleteImageUploadData: function (dataObj) {
        let option = {
            method: endpoint.product.deleteImageUploadData.method,
            url: endpoint.product.deleteImageUploadData.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    getFeatureColor: function (dataObj) {
        let option = {
            method: endpoint.product.getFeatureColor.method,
            url: endpoint.product.getFeatureColor.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    releaseLock: function (dataObj) {
        let option = {
            method: endpoint.product.releaseLock.method,
            url: endpoint.product.releaseLock.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    saveOrSendMessage: function (dataObj) {
        let option = {
            method: endpoint.product.saveOrSendMessage.method,
            url: endpoint.product.saveOrSendMessage.url,
            headers: headers
        };
        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    getMessages: function (id) {
        let option = {
            method: endpoint.product.getMessages.method,
            url: endpoint.product.getMessages.url + id,
            headers: headers
        };
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    readMessage: function (id) {
        let option = {
            method: endpoint.product.readMessage.method,
            url: endpoint.product.readMessage.url + id,
            headers: headers
        };
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    usersList: function () {
        let option = {
            method: endpoint.product.usersList.method,
            url: endpoint.product.usersList.url,
            headers: headers
        };
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    excelUploadHistory: function (data) {
        let option = {
            method: endpoint.product.excelUploadHistory.method,
            url: endpoint.product.excelUploadHistory.url + data,
            headers: headers
        };
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    excelUploadStatus: function (processId) {
        let option = {
            method: endpoint.product.excelUploadStatus.method,
            url: endpoint.product.excelUploadStatus.url + processId,
            headers: headers
        };
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    uploadFiles: function (dataObj) {
        let option = {
            method: endpoint.product.uploadFiles.method,
            url: endpoint.product.uploadFiles.url,
            headers: headers
        };

        if (dataObj) {
            option.data = dataObj;
        }
        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    uploadMultiFiles: (dataObj, colId, productId) => {
        const { method, url } = endpoint.product.uploadMultiFiles;

        const option = {
            method: method,
            url: `${url}/${productId}/${colId}`,
            headers: headers
        };

        if (dataObj) {
            option.data = dataObj;
        }

        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    getMultiFiles: (colId, productId) => {
        const { method, url } = endpoint.product.getMultiFiles;

        const option = {
            method: method,
            url: `${url}/${productId}/${colId}`,
            headers: headers
        };

        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

    deleteMultiFiles: (productId, colId, fileName) => {
        const { method, url } = endpoint.product.deleteMultiFiles;

        const option = {
            method: method,
            url: `${url}/${productId}/${colId}/${fileName}`,
            headers: headers
        };

        return axios(option).then((response) => {
            return response;
        }).catch(error => {
            return Promise.reject(error);
        });
    },

};

export {ProductServices};
