import React from 'react';
import axios from 'axios';
import DashboardServices from '../services/dashboard-services';


class RecentAlerts extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isloadAlerts:false,
        };
        this.recentAlertsData = null;
        this.buildRecentAlerts = this.buildRecentAlerts.bind(this);
        this.loadData = this.loadData.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.openProdLink = this.openProdLink.bind(this);
        this.refereshView = this.refereshView.bind(this);
    }

    componentDidMount(){
        console.log('Dashboard RecentAlerts componentDidMount: ', this.props);
        this.loadData(this.props.user.userId);
    }

    componentWillReceiveProps(nextProps){
        console.log('Recent Alets componentWillReceiveProps: ', nextProps);
        if(nextProps.userId !== this.props.user.userId){
            this.refereshView(nextProps);
        }
    }


    refereshView(nextProps){
        this.setState({isloadAlerts:false});
        this.loadData(nextProps.user.userId);
    }

    openProdLink(obj){
        var allow = true;
        if(allow){
            var redirecturl = obj.url;

            let pagenames = obj.page.toLowerCase();

            this.props.setCurrentPage(pagenames);

            console.log(pagenames);

            // if(obj.page=="product"){
            //     require('../../common/utils').createCookie('alertProductClickUrl',redirecturl);
            //     location.href="/product";
            // }
            // if(obj.page=="creative"){
            //     require('../../common/utils').createCookie('creativeAlert',redirecturl);
            //     location.href="/creative";
            // }
            // if(obj.page=="Marketing"){
            //     require('../../common/utils').createCookie('marketingAlert',redirecturl);
            //     location.href="/marketing";
            // }
        }
    }
    // openProdLink(obj){
    //     var allow = true;
    //     if(allow){
    //         var redirecturl=obj.url;
    //         if(obj.page=="product"){
    //             require('../../common/utils').createCookie('alertProductClickUrl',redirecturl);
    //             location.href="/product";
    //         }
    //         if(obj.page=="creative"){
    //             require('../../common/utils').createCookie('creativeAlert',redirecturl);
    //             location.href="/creative";
    //         }
    //         if(obj.page=="Marketing"){
    //             require('../../common/utils').createCookie('marketingAlert',redirecturl);
    //             location.href="/marketing";
    //         }
    //     }
    // }

    buildRecentAlerts(list){
        var items = null;
        if(list && list.length > 0){
            items = list.map((o, i) =>{
                return(<li key= {i} data-row-key={i} onClick={() =>{this.openProdLink(o);}}>{o.title}<span>{o.value}</span></li>);
            });
        }else{
            items = <li>No Recent Alerts</li>;
        }
        return items;
    }

    loadData(userId) {
        console.log('Dashboard Recent Alerts loadData userId: ', userId);

        DashboardServices.getRecentAlerts({id:userId}).then( (response) => {
            console.log('Dashboard recent alerts loadData API call success data: ', response.data);
            let res = response;
            if(res.general){
                this.recentAlertsData = res.general;
                this.setState({isloadAlerts:true});
            }
        })
            .catch( error => {
                console.log('Dashboard recent alerts loadData API call failed', error);
            });
    }

    applyFilter(e){
        this.setState({isloadAlerts:false}); // Hide Loaded component
        var target = e.target;
        var value = target.value;
        var userId = this.props.user.userId;

        if(value !== ""){
            var filter = {
                "field":"RecentAlerts",
                "value":value,
                "userId":userId
            };

            let headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json'
            };

            DashboardServices.searchAlertsOrAccomplishment(filter).then( (response) => {
                console.log('Dashboard recent alerts applyFilter API call success data: ', response.data);
                let res = response;
                if(res.general){
                    this.recentAlertsData = res.general;
                    this.setState({isloadAlerts:true});
                }
            })
                .catch( error => {
                    console.log('Dashboard recent alerts applyFilter API call failed', error);
                });
        }else{
            this.loadData(userId);
        }
    }

    render() {
        console.log('Dashboard RecentAlerts render: ', this.props);

        var intViewportHeight = window.innerHeight;
        var containerheight = intViewportHeight - 64 - 30;
        var ht = (containerheight / 2) - 90;
        var containerStyle = {
            height:ht
        };

        return (
            <div className="recent-alerts">
                <div className="headbox">
                    <i className="icon-notification"></i>
                    <span className="heading-text">Recent Alerts</span>
                    <select onChange={this.applyFilter}>
                        <option value="">Filter By</option>
                        <option value="hourly">Last Hour</option>
                        <option value="daily">Last day</option>
                        <option value="weekly">Last Week</option>
                        <option value="monthly">Last Month</option>
                    </select>
                </div>
                {this.state.isloadAlerts &&
                 <ul className="scroll" style={containerStyle}>
                     {this.buildRecentAlerts(this.recentAlertsData)}
                 </ul>
                }
            </div>
        );
    }
}

export default RecentAlerts;
