import React from 'react';
import RejectEventComponent from './rejectEventComponent';

class RejectEvent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            'reason': (this.props.rejectReason === "") ? '' : this.props.rejectReason
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        const target = event.target;

        let value = target.type === 'checkbox' ? target.checked : target.value;

        const name = target.name;

        this.setState({ 'reason': value });
    }

    render() {
        console.log('rejectEvent Container render: ', this.props);

        return (
            <RejectEventComponent
                {...this.props}
                handleChange = {this.handleChange}
            />
        );
    }
}

export default RejectEvent;
