import React from 'react';
import axios from 'axios';
import CancelModal from './cancelModal';
import NotificationManager from '../../common/components/notification';
import {CommonServices} from '../../common/services/common-services';

class AddVendorForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vendor: {
                contactPerson: '',
                email: '',
                name: '',
                orgVendorId: '',
                phone: '',
                status: true,
                type: '',
            },
            validation: {
                contactPerson: false,
                email: true,
                name: true,
                orgVendorId: false,
                phone: false,
                status: false,
                type: false,
            },
            isDirty: false,
            errorMessage: '',
            isModalOpen: false,
            isModified: false,
        };

        this.openModalCancel = this.openModalCancel.bind(this);
        this.modalClose = this.modalClose.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.saveVendor = this.saveVendor.bind(this);
    }

    // modalOpen() {
    // 	this.setState({isModalOpen: true});
    // }

    /**
	 * Closes the modal
	 * @param {*} key
	 */
    modalClose(key) {
        this.setState({isModalOpen: false});
        switch (key) {
            case 'save':
                this.saveVendor();
                break;
            case 'no':
                this.props.modalClose();
                break;
        }
    }

    /**
	 * Opens the cancel modal
	 */
    openModalCancel() {
        if (this.state.isModified) {
            this.setState({isModalOpen: true});
        } else {
            this.props.modalClose();
        }
    }


    /**
	 * Checks input values
	 * @param {*} event
	 */
    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const telregex = /^[0-9+-]+$/i;

        if (value.trim() === '' || value.trim() === undefined) {
            if (this.state.validation[name]) {
                target.className += ' invalid';
            }
        } else {
            target.classList.remove('invalid');
        }

        const {vendor} = this.state;
        const newVendor = vendor;
        if (value.length > 0) {
            if (name === 'phone' && !telregex.test(value)) {
                newVendor[name] = '';
            } else {
                newVendor[name] = value;
            }
        } else {
            newVendor[name] = value;
        }
        this.setState({vendor: newVendor, isModified: true});
    }

    /**
	 * Validates email
	 * @param {*} email
	 * @return {string}
	 */
    validateEmail(email) {
        const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(email);
    }

    /**
	 * Calls backend to save the new vendor
	 * @param {*} event
	 */
    saveVendor(event) {
        // event.preventDefault();
        const _this = this;
        const {vendor} = this.state;
        const newVendor = vendor;
        // validation
        let flag = 0;
        let errorMessage = '*Mandatory fields marked with an asterisk are missing.';

        if (this.state.validation.name && newVendor.name === '') {
            flag++;
        } else {
            this.setState({isDirty: false});
        }

        if (this.state.validation.email && newVendor.email === '') {
            flag++;
        } else {
            if (this.validateEmail(this.state.vendor.email)) {
                this.setState({isDirty: false});
            } else {
                flag++;
                errorMessage = 'Invalid Email address.';
            }
        }

        if (this.state.validation.phone && newVendor.phone === '') {
            flag++;
        } else {
            this.setState({isDirty: false});
        }

        //
        if (flag === 0) {
            const req = JSON.stringify(newVendor);


            const headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json',
            };

            CommonServices.addorupdatevendor(req)
                .then( (response) => {
                    console.log('admin addVendorForm saveVendor  API call success data: ', response);
                    const data = response;

                    if (data.responseCode == 200) {
                        _this.state.vendor.orgVendorId = data.data.id;
                        _this.props.reloadGrid();
                        // _this.props.addVendorRow(_this.state.vendor);
                        _this.props.modalClose();
                        NotificationManager({
                            message: data.responseMessage,
                            type: 1,
                        });
                    } else {
                        _this.setState({isDirty: true});
                        NotificationManager({
                            message: data.responseMessage,
                            type: 4,
                        });
                    }
                })
                .catch( (error) => {
                    console.log('admin addVendorForm saveVendor API call failed', error);
                    _this.setState({isDirty: true, errorMessage: data.response});
                });
        } else {
            this.setState({isDirty: true, errorMessage: errorMessage});
        }
    }

    render() {
        return (
            <div>
                <div className="modal-header">
					Add New Vendor
                    <span className="close" onClick={this.openModalCancel}>x</span>
                </div>
                <div className="modal-content">
                    <div className="add-vendor modal-form">
                        <form className="addNewVendor" name="addNewVendor">
                            <div className="form-container scroll">
                                <h4>Vendor Details</h4>
                                <hr/>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Org Vendor ID</label>
                                            <input type="text" className="form-control" name="orgVendorId" value={this.state.vendor.orgVendorId} onChange={this.handleChange} maxLength={30}></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Name*</label>
                                            <input type="text" className="form-control" name="name" value={this.state.vendor.name} onChange={this.handleChange} maxLength={30}></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Email*</label>
                                            <input type="text" className="form-control" name="email" value={this.state.vendor.email} onChange={this.handleChange}></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Contact Person</label>
                                            <input type="text" className="form-control" name="contactPerson" value={this.state.vendor.contactPerson} onChange={this.handleChange} maxLength={30}></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Phone</label>
                                            <input type="text" className="form-control" name="phone" value={this.state.vendor.phone} onChange={this.handleChange} maxLength="15"></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <input type="button" className="gray-button" value="Cancel" onClick={this.openModalCancel}></input>
                                <input type="button" className="gray-button" value="Add Vendor" onClick={this.saveVendor}></input>
                            </div>
                            {(this.state.isDirty) ? <span className="error-message">{this.state.errorMessage}</span> : ''}
                        </form>
                    </div>
                </div>

                <CancelModal data={this.state.user} isOpen={this.state.isModalOpen} modalClose={this.modalClose}/>
            </div>
        );
    }
}

export default AddVendorForm;
