import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Footer from './helperComponents/fieldLengthFooter';

const FieldLengthComponent = ({
    state,
    props,
    formFields,
    updateFieldsLength,
    resetFormData,
}) => {
    return (
        <div>
            <div className="container fieldLength">
                {state.isLoaded &&
                    <div className="row cont-row">
                        <div className="col-6">
                            <form className="updateFieldLength">
                                <div className="form-container">
                                    {formFields}
                                </div>
                                {/*<div className="form-actions">
                                <input type="button" className="gray-button" value={state.updateBtnLabel} disabled={state.btnStatus} onClick={updateFieldsLength}></input>
                            </div>*/}
                            </form>
                            {
                            }
                        </div>

                    </div>
                }
            </div>
            <Footer updateFieldsLength={updateFieldsLength} isModified={state.isModified} btnLabel={state.updateBtnLabel} btnStatus={state.btnStatus} resetFormData={resetFormData} />
        </div>
    );
};

FieldLengthComponent.propTypes = {
    state: PropTypes.object,
    props: PropTypes.object,

};

export default withRouter(FieldLengthComponent);


