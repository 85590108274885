import React, { Component } from 'react';
import ConfirmModal from '../../common/modals/confirmModal';

class FieldLengthFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cancelLbl: 'Cancel Changes',
            applyLbl: 'Apply Changes',
            isModalOpen: false,
        };

        this.modalOpen = this.modalOpen.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.cancelModal = this.cancelModal.bind(this);
    }

    modalOpen() {
        this.setState({ isModalOpen: true });
    }

    modalClose() {
        this.setState({ isModalOpen: false });
    }

    // triggerModalOpen(target) {
    // 	this.setState({modalName: target})
    // 	this.modalOpen();
    // }

    cancelModal() {
        this.setState({ isModalOpen: true });
    }

    cancelChanges(key) {
        if (key === 'yes') {
            this.setState({ isModalOpen: false });
            this.props.resetFormData();
        } else {
            this.setState({ isModalOpen: false });
        }
    }


    render() {
        const footerContent = (
            <div>
                <div className="footer-content right">
                    <button className="cancel_changes gray-button" onClick={this.cancelModal} disabled={!this.props.isModified}>{this.state.cancelLbl}</button>
                    <button className="apply_changes gray-button" onClick={this.props.updateFieldsLength} disabled={!this.props.isModified}>{this.props.btnLabel}</button>
                </div>
            </div>
        );

        return (
            <div className="footer admin-footer">
                {footerContent}

                <CancelModal isOpen={this.state.isModalOpen} modalClose={this.modalClose} cancelChanges={this.cancelChanges} />
            </div>
        );
    }
}

class CancelModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
        };

        this.closeConfirmModal = this.closeConfirmModal.bind(this);
    }

    closeConfirmModal(key) {
        this.props.modalClose(key);
    }

    render() {
        return (<ConfirmModal isOpen={this.props.isOpen}>
            <div className="modal-content">
                <h3 className="confirm-warning-text">Changes have been made in this section. Are you sure you want to cancel the changes made?</h3>
                <div className="modal-footer">
                    <input type="button" className="gray-button" value="Yes" onClick={() => this.props.cancelChanges('yes')}></input>
                    <input type="button" className="gray-button" value="No" onClick={() => this.props.cancelChanges('no')}></input>
                </div>
            </div>
        </ConfirmModal>);
    }
}

export default FieldLengthFooter;
