import React from 'react';
import Campaigns from './campaign-modal';

export class CampaignRender extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const displayLevel = (this.props.data && this.props.data.displayLevel) ? this.props.data.displayLevel : null; // Checks the displayLevel of the record validate the render based on displayLevel
        let dispChecks = this.props.data && this.props.data.campaign && this.props.data.campaign.length > 0;
        let campaignDisplay =  dispChecks ? this.props.data.campaign.toString() : <div className="ag-popupIndicator"><img src={require('../../../common/icons/Expand Grid.svg')} /></div>; // Used to display the icon

        if (this.props.node.data.dataLevel == 'SINGLE') {
            return (
                <div>
                    {campaignDisplay}
                </div>
            );
        }

        if (displayLevel) {
            switch (displayLevel) {
                case 'Style':
                    if (this.props.node.level == 0) {
                        return (
                            <div>
                                {campaignDisplay}
                            </div>
                        );
                    } else {
                        return null;
                    }
                case 'Color':
                    if (this.props.node.level == 1) {
                        return (
                            <div>
                                {campaignDisplay}
                            </div>
                        );
                    } else {
                        return null;
                    }
                case 'Size':
                    if (this.props.node.level == 2) {
                        return (
                            <div>
                                {campaignDisplay}
                            </div>
                        );
                    } else {
                        return null;
                    }
                default: return null;
            }
        } else {
            return null;
        }
    }
}
// //////////////////////////////////////////////////////////////////////////////////////////////


export class CampaignEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: false,
        };
        this.modalClose = this.modalClose.bind(this);
    }

    componentWillMount() {
        let flag = false; // Using a flag to set the edit status of the cell

        // Set of checks for setting flag
        if (this.props.node.data.displayLevel == 'Color' && this.props.node.level == 1) {
            flag = true;
        }

        if (this.props.node.data.displayLevel == 'Style' && this.props.node.level == 0) {
            flag = true;
        }

        if (this.props.node.data.displayLevel == 'Size' && this.props.node.level == 2) {
            flag = true;
        }

        if (this.props.node.data.dataLevel == 'SINGLE') {
            flag = true;
        }

        if (!flag) {
            this.props.api.stopEditing();
        } else {
            this.setState({ showPopup: true });
        }

        // this.setState ({ showPopup: true })
    }

    // componentWillUnmount() {
    //     this.props.api.tabToNextCell();
    // }

    /**
     * Gets the value when the component unmounts and pushes it for render
     * @return {true} opens as a popup
     */
    isPopup() {
        return true;
    }

    /**
     * Gets the value when the component unmounts and pushes it for render
     * @return {string} cell value
     */
    getValue() {
        return this.props.value;
    }

    /**
    * Closes the modal
    */
    modalClose() {
        this.setState({ showPopup: false });
        this.props.api.stopEditing();
    }


    render() {
        return (
            <div>
                {this.state.showPopup &&
                    <div className="ag-modal-container">
                        <div className="ag-modal-holder">
                            {/* All props passed are default required props for the popup to open */}
                            <Campaigns
                                rowData = {this.props.node.data}
                                data = {this.props}
                                user = {this.props.user}
                                modalClose = {this.modalClose}
                            />
                        </div>
                    </div>
                }
            </div>
        );
    }
}

// ////////////////////////////////////////////////////////////////////////////////////

