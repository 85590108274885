import React, { Component } from 'react';
import GridDataBuilder from '../adminUserGroup/helperComponents/userGroupDataBuilder';
import axios from 'axios';
import NotificationManager from '../common/components/notification';
import RulesGridComponent from './rules-gridComponent';
import AdminServices from '../admin/admin-services/admin-services';

class RulesGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            isRulesOpen: false,
            isModalOpen: false,
            filters: null,

            // added for jqxgrid
            columns: [],
            source: null,
        };

        //params
        this.existRuleData = {};
        this.inputData = {};
        this.AllFields = ['ruleName', 'description', 'status', 'level'];
        this.modalName = '';
        //methods

        this.closeRulesModal = this.closeRulesModal.bind(this);
        this.openRulesModal = this.openRulesModal.bind(this);
        this.pauseRules = this.pauseRules.bind(this);
        this.cloneRules = this.cloneRules.bind(this);
        this.deleteRules = this.deleteRules.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.modalOpen = this.modalOpen.bind(this);
        this.deleteRuleDialog = this.deleteRuleDialog.bind(this);
        this.addOrUpdateRules = this.addOrUpdateRules.bind(this);
        this.getRulesFormData = this.getRulesFormData.bind(this);
        this.didValueChanged = this.didValueChanged.bind(this);
        this.closeAllModal = this.closeAllModal.bind(this);
        this.includeGraphIdInRules = this.includeGraphIdInRules.bind(this);
    }

    /**
     * Disable the rules from Active to Paused which are selected
     */
    pauseRules() {
        const _this = this;
        const selectedrowindexs = _this.rulesGrid.getselectedrowindexes();
        let selectedrowids = [];

        if (selectedrowindexs.length > 0) {
            selectedrowids = selectedrowindexs.map((val, key) => {
                const rowData = _this.rulesGrid.getrowdata(val);

                return { 'id': rowData.ruleId };
            });

            AdminServices.pauseRule(selectedrowids).then((response) => {
                const res = response;

                if (res.status) {
                    NotificationManager({
                        message: res.responseMessage,
                        type: 1
                    });

                    const rowids = res.data.map((val, key) => {
                        res.data[key].status = res.data[key].status == 'true' ? 'Active' : 'Paused';

                        return val.ruleId;
                    });

                    _this.rulesGrid.updaterow(rowids, res.data);
                } else {
                    NotificationManager({
                        message: res.responseMessage,
                        type: 4
                    });
                }
            }).catch((error) => {
                console.log('AdminRules rules-grid pauseRules API call failed', error);

                NotificationManager({
                    message: xhr.message,
                    type: 4
                });
            });
        } else {
            NotificationManager({
                message: 'Please select one or more rules to perform this action',
                type: 4
            });
        }
    }

    /**
     * Copy and add new the rules which are selected
     */
    cloneRules() {
        const _this = this;
        const selectedrowindexs = _this.rulesGrid.getselectedrowindexes();
        let selectedrowids = [];
        const rowids = [];

        if (selectedrowindexs.length > 0) {
            if (selectedrowindexs.length > 1) {
                NotificationManager({
                    message: 'Please clone only one Rule at a time',
                    type: 4
                });

                return;
            }

            selectedrowids = selectedrowindexs.map((val, key) => {
                const rowData = _this.rulesGrid.getrowdata(val);
                rowids.push(rowData.ruleId);

                return { 'id': rowData.ruleId };
            });

            AdminServices.cloneRule(selectedrowids).then((response) => {
                const res = response;

                if (res.status) {
                    NotificationManager({
                        message: res.responseMessage,
                        type: 1
                    });

                    const rowids = res.data.map((val, key) => {
                        res.data[key].status = res.data[key].status == 'true' ? 'Active' : 'Paused';
                        return val.ruleId;
                    });

                    _this.rulesGrid.addrow(rowids, res.data);
                } else {
                    NotificationManager({
                        message: res.responseMessage,
                        type: 4
                    });
                }
            }).catch((error) => {
                console.log('AdminRules rules-grid cloneRules API call failed', error);
                NotificationManager({
                    message: 'Error:Response failed, Please try again',
                    type: 4
                });
            });
        } else {
            NotificationManager({
                message: 'Please select one rule to perform this action',
                type: 4
            });
        }
    }

    /**
     * Calls backend to delete rules
     */
    deleteRules() {
        const _this = this;
        const selectedrowindexs = _this.rulesGrid.getselectedrowindexes();
        let selectedrowids = [];
        const rowids = [];

        if (selectedrowindexs.length > 0) {
            selectedrowids = selectedrowindexs.map((val, key) => {
                const rowData = _this.rulesGrid.getrowdata(val);
                rowids.push(rowData.ruleId);

                return { 'id': rowData.ruleId };
            });

            AdminServices.deleteRule(selectedrowids).then((response) => {
                const res = response;

                if (res.status) {
                    _this.modalClose();

                    NotificationManager({ message: res.message, type: 1 });

                    _this.rulesGrid.deleterow(rowids);
                } else {
                    NotificationManager({ message: res.message, type: 4 });
                }
            }).catch((error) => {
                console.log('AdminRules rules-grid deleteRule API call failed', error.response);

                NotificationManager({
                    message: 'Error:Response failed, Please try again',
                    type: 4
                });
            });
        } else {
            NotificationManager({
                message: 'Please select one or more rules to perform this action',
                type: 4
            });
        }
    }

    /**
     * Delete selected rows in the rules grid
     */
    deleteRuleDialog() {
        const _this = this;
        const selectedrowindexs = _this.rulesGrid.getselectedrowindexes();

        if (selectedrowindexs.length > 0) {
            this.modalName = 'ConfirmDelete';
            this.setState({ isModalOpen: true });
        } else {
            NotificationManager({
                message: 'Please select one or more rules to perform this action',
                type: 4
            });
        }
    }

    /**
     * Opens the rule modal
     */
    openRulesModal() {
        this.ruleData = {
            ruleId: '0',
            ruleName: '',
            status: '',
            details: '',
            description: '',
            tableName: '',
            pageName: ''
        };

        this.setState({ isRulesOpen: true });
    }

    /**
     * Close all the modals
     */
    closeAllModal() {
        this.setState({ isModalOpen: false });
        this.setState({ isRulesOpen: false });
    }

    /**
     * Closes the rule modal
     */
    closeRulesModal() {
        const _this = this;
        this.getRulesFormData();

        if (this.didValueChanged()) {
            this.modalName = 'SaveChanges';
            this.setState({ isModalOpen: true });
        } else {
            this.closeAllModal();
        }
    }

    /**
     * Checks if the value has changed
     * @return {boolean}
     */
    didValueChanged() {
        let isChanged = false;
        let prev;
        let next;

        for (let i = 0; i < this.AllFields.length; i++) {
            prev = this.ruleData[this.AllFields[i]];
            next = this.inputData[this.AllFields[i]];
            prev = prev !== null ? prev : '';
            next = next !== null ? next : '';

            if (this.AllFields[i] === 'status') {
                if (next == 'true') {
                    next = 'Active';
                }

                if (next == 'false') {
                    next = 'Paused';
                }
            }

            if (this.AllFields[i] === 'level') {
                if (next == 'Copy Level') {
                    next = 'Copy Level';
                }

                if (next == 'Display Level') {
                    next = 'Display Level';
                }

                if (next == 'None') {
                    next = 'None';
                }
            }

            if (prev.localeCompare(next) !== 0) {
                isChanged = true;

                break;
            }
        }

        return isChanged;
    }

    /**
     * Includes graph in the rules
     * @param {*} rules
     * @return {Array}
     */
    includeGraphIdInRules(rules) {
        const rulesArray = rules.rules;
        const rulesArrayLength = rulesArray.length;

        for (let r = 0; r < rulesArrayLength; r++) {
            const ruleObj = rulesArray[r];

            if (ruleObj.id) {
                const ruleObjId = ruleObj.id;
                const filtersData = this.state.filters.data;
                const filtersDataLength = filtersData.length;

                for (let i = 0; i < filtersDataLength; i++) {
                    if (filtersData[i].id == ruleObjId) {
                        ruleObj.graphField = filtersData[i].graphId;

                        break;
                    }
                }

                rulesArray[r] = ruleObj;
            }

            if (ruleObj.condition) {
                this.includeGraphIdInRules(ruleObj);
            }
        }

        rules.rules = rulesArray;

        return rules;
    }

    /**
     * Getter for data from the form
     */
    getRulesFormData() {
        const ruleId = $('.currentRuleId').val();

        const rules = $('#query-builder').queryBuilder('getRules');

        // adding graph data to rules
        console.log('rules is : ', $('.rule-name').val(), $('#ruleLevel').val());

        const newRules = this.includeGraphIdInRules(rules);
        const rulesStr = (newRules) ? JSON.stringify(newRules) : null;

        const ruleName = $('.rule-name').val();
        const ruleStatus = $('#ruleStatus').val();
        const ruleLevel = $('#ruleLevel').val();
        const ruleDesc = $('.rule-description').val();
        const pageName = $('#pageName').val();

        this.inputData = {
            ruleId: ruleId ? ruleId : '0',
            description: ruleDesc,
            details: rulesStr,
            ruleName: ruleName,
            status: ruleStatus,
            level: ruleLevel,
            tableName: '',
            pageName: pageName,
        };
    }

    /**
     * Create/Update rules, if rold !=0 [update] else [create]
     * @param {*} e
     */
    addOrUpdateRules() {
        const _this = this;

        // Retreive values from input fields
        this.getRulesFormData();
        let error = false;

        //validation for all mandatory fields
        if (this.inputData.ruleName === '' || this.inputData.ruleName.trim() == '') {
            error = true;
        } else if (this.inputData.status == '') {
            error = true;
        } else if (this.inputData.level == '') {
            error = true;
        } else if (this.inputData.details === null) {
            error = true;
        } else if (this.inputData.description === '' || this.inputData.description.trim() == '') {
            error = true;
        } else if (this.inputData.pageName === '' || this.inputData.pageName.trim() == '') {
            error = true;
        }

        if (error) {
            const errmsg = '*Mandatory information marked with an asterisk is missing.';

            NotificationManager({ message: errmsg, type: 4 });

            _this.modalClose();

            return;
        }

        const inputDataGet = this.inputData;

        AdminServices.addOrUpdateRule(inputDataGet).then((response) => {
            const res = response;

            if (res.status == 'success') {
                NotificationManager({ message: res.message, type: 1 });

                this.reloadGrid();

                _this.closeAllModal();
            } else {
                NotificationManager({ message: res.responseMessage, type: 4 });

                _this.modalClose();
            }
        }).catch((error) => {
            console.log('AdminRules rules-grid addOrUpdateRules API call failed', error);

            NotificationManager({
                message: 'Error:Response failed, Please try again',
                type: 4
            });
        });
    }

    reloadGrid() {
        const base = this;

        base.setState({ isLoaded: false}, () => {
            AdminServices.getRulesList().then((response) => {
                const res = response.responseData;

                if (res.data.length > 0) {
                    for (let i = 0; i < res.data.length; i++) {
                        res.data[i].status = res.data[i].status == 'true' ? 'Active' : 'Paused';
                    }

                    for (let j = 0; j < res.columnDetails.length; j++) {
                        if (res.columnDetails[j].fieldName === 'status') {
                            res.columnDetails[j].type = 'String';

                            break;
                        }

                        if (res.columnDetails[j].fieldName === 'level') {
                            res.columnDetails[j].type = 'String';

                            break;
                        }
                    }
                }

                res.columnDetails.push({
                    columnTitle: "Level",
                    fieldName: "level",
                    isPopup: false,
                    maxLength: 0,
                    order: 0,
                    pk: false,
                    popup: false,
                    type: 'String',
                    viewOrder: 0,
                    visibility: true,
                    width: 0,
                });

                base.gridData = GridDataBuilder(res, 'rules');
                base.setState({ isLoaded: true });
            }).catch((error) => {
                console.log('AdminRules rules-grid componentDidMount API call failed', error);

                NotificationManager({
                    message: 'Error:Response failed, Please try again',
                    type: 4
                });
            });
        });
    }

    componentDidMount() {
        const base = this;

        AdminServices.getRulesList().then((response) => {
            const res = response.responseData;

            if (res.data.length > 0) {
                for (let i = 0; i < res.data.length; i++) {
                    res.data[i].status = res.data[i].status == 'true' ? 'Active' : 'Paused';
                }

                for (let j = 0; j < res.columnDetails.length; j++) {
                    if (res.columnDetails[j].fieldName === 'status') {
                        res.columnDetails[j].type = 'String';

                        break;
                    }

                    if (res.columnDetails[j].fieldName === 'level') {
                        res.columnDetails[j].type = 'String';

                        break;
                    }
                }
            }

            res.columnDetails.push({
                columnTitle: "Level",
                fieldName: "level",
                isPopup: false,
                maxLength: 0,
                order: 0,
                pk: false,
                popup: false,
                type: 'String',
                viewOrder: 0,
                visibility: true,
                width: 0,
            });

            base.gridData = GridDataBuilder(res, 'rules');
            base.setState({ isLoaded: true });
        }).catch((error) => {
            console.log('AdminRules rules-grid componentDidMount API call failed', error);

            NotificationManager({
                message: 'Error:Response failed, Please try again',
                type: 4
            });
        });

        // calling filterOperators here as it is needed here to check the graph id

        AdminServices.filterOperators().then((response) => {
            const dataRes = response.responseData;

            base.setState({
                filters: dataRes,
            });
        }).catch((error) => {
            console.log('rules-gridContainer componentDidMount API call failed', error);
        });

        this.sortDropDown();

        $('#drop-down').on('click', 'li', function () {
            const fieldName = $(this).closest('#drop-down').attr('data-field');
            let val = $(this).attr('data-val');

            val = (val === 'null') ? null : val;

            $('#drop-down').hide();

            base.rulesGrid.sortby(fieldName, val);
        });
    }

    /**
     * Sorts the dropdown
     */
    sortDropDown() {
        $('#drop-down').remove();

        const container = '<div id="drop-down" class="pd-hl"></div>';
        $('body').append(container);

        const list = this.generateDropDowns();
        $('#drop-down').append(list);
    }

    /**
     * Builds the dropdowns
     * @return {object}
     */
    generateDropDowns() {
        const list = [{
            name: '0-9,A-Z,blank-non_blank',
            val: 'asc',
        }, {
            name: '9-0,Z-A,non_blank-blank',
            val: 'desc',
        }, {
            name: 'clear',
            val: 'null',
        }];

        let li = '';
        list.forEach((item, index) => {
            li += '<li class="product-sort-options" data-val="' + item.val + '">' + item.name + '</li>';
        });

        const ul = '<ul class=\'sort-holder\'>' + li + '</ul>';

        return ul;
    }

    componentDidUpdate(prevProps, prevState) { }

    /**
     * Opens the modal
     */
    modalOpen() {
        this.setState({ isModalOpen: true });
    }

    /**
     * Closes the modal
     */
    modalClose() {
        this.setState({ isModalOpen: false });
    }

    onCelldoubleclick(event) {
        console.log(' rules grid double click: ', event);

        const args = event.args;
        const dataField = args.datafield;

        console.log('data fied is: ', dataField);
        console.log('this: ', this);

        const value = args.value;
        const row = args.row;
        const rowdata = row.bounddata;
        this.ruleData = rowdata;

        this.setState({ isRulesOpen: true });
    }

    render() {
        const footerHeight = 50;
        const headerHeight = 64;
        const gridHeight = window.innerHeight - footerHeight - headerHeight;
        const modalClass = 'ConfirmRulesDelete';

        $('.page-title a').text("ADMIN: Rules");

        return (
            <RulesGridComponent
                rulesGridRef={(grid) => {
                    this.rulesGrid = grid;
                }}
                state={this.state}
                props={this.props}
                gridData={this.gridData}
                closeRulesModal={this.closeRulesModal}
                modalClose={this.modalClose}
                ruleData={this.ruleData}
                deleteRules={this.deleteRules}
                modalName={this.modalName}
                addOrUpdateRules={this.addOrUpdateRules}
                closeAllModal={this.closeAllModal}
                openRulesModal={this.openRulesModal}
                cloneRules={this.cloneRules}
                pauseRules={this.pauseRules}
                deleteRuleDialog={this.deleteRuleDialog}
                gridHeight={gridHeight}
                modalClass={modalClass}
                onCelldoubleclick={this.onCelldoubleclick.bind(this)}
            />
        );
    }
}

export default RulesGrid;
