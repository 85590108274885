import React, { Component } from 'react';
import ConfirmModal from '../../modals/confirm-modal';
import {connect} from 'react-redux';

import NotificationManager from '../../common/components/notification';

class AdminFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editReportsLabel: 'Edit Reports',
            cancelChangesLabel: 'Cancel Changes',
            applyChangesLabel: 'Apply Changes',
            isModalOpen: false,
            isConfirmModalOpen: false,
            modalName: '',
        };

        this.cancelChanges = this.cancelChanges.bind(this);
        this.modalClose = this.modalClose.bind(this);
    }

    /**
	 * Cancels changes
	 */
    cancelChanges() {
        this.setState({isModalOpen: true});
    }

    /**
	 * Closes modal
	 * @param {*} status
	 */
    modalClose(status) {
        if (status === 'yes') {
            this.props.gridRerender();
            this.setState({isModalOpen: false});
        } else {
            this.setState({isModalOpen: false});
        }
    }

    render() {
        const footerContent = (
            <div>
                <div className="footer-content">
                    <button className="admin-addrecord gray-button" disabled>{this.state.editReportsLabel}</button>
                </div>
                <div className="footer-content right">
                    <button className="cancel_changes gray-button" disabled={!this.props.isModified} onClick={this.cancelChanges}>{this.state.cancelChangesLabel}</button>
                    <button className="apply_changes gray-button" disabled={!this.props.isModified} onClick={this.props.applyChanges}>{this.state.applyChangesLabel}</button>
                </div>
            </div>
        );

        return (
            <div className="footer admin-footer">
                {footerContent}

                <CancelModal isOpen={this.state.isModalOpen} modalClose={this.modalClose}/>
            </div>
        );
    }
}


class CancelModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
        };
    }

    render() {
        return (<ConfirmModal isOpen={this.props.isOpen}>
            <div className="modal-content">
                <h3 className="confirm-warning-text">Changes have been made in this section. Are you sure you want to cancel the changes made?</h3>
                <div className="modal-footer">
                    <input type="button" className="gray-button" value="Yes" onClick={ () => this.props.modalClose('yes') }></input>
                    <input type="button" className="gray-button" value="No" onClick={ () => this.props.modalClose('no') }></input>
                </div>
            </div>
        </ConfirmModal>);
    }
}

const mapSateToProps = (state) => {
    return {
        userView: state.userView,
    };
};

export default connect(mapSateToProps)(AdminFooter);
