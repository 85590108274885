/**
 * Defines the column wdith
 */
const columnWidth = {
    productId: 120,
    prodName: 300,
    activeNotes: 400,
};

/**
 * returns the checkboxes for user group
 * @param {*} row
 * @param {*} columnfield
 * @param {*} value
 * @param {*} defaulthtml
 * @param {*} columnproperties
 * @param {*} rowdata
 * @return {object}
 */
const userGroupCheckbox = function(row, columnfield, value, defaulthtml, columnproperties, rowdata) {
    const checked = (value) ? 'checked' : '';

    return '<span class="blue-checkbox publishing-cell-control"><input class="publishing-view" type="checkbox" ' + checked + ' data-fieldname="' +
            columnfield + '" data-row="' +
          row + '" ><label></label></span>';
};

/**
 * returns mandetory field div
 * @param {*} row
 * @param {*} columnfield
 * @param {*} value
 * @param {*} defaulthtml
 * @param {*} columnproperties
 * @param {*} rowdata
 * @return {object}
 */
const reportModifyField = function(row, columnfield, value, defaulthtml, columnproperties, rowdata) {
    return '<div class="publishReportModify"><a href="#" class="publish-reporturl" target="_blank" data-report="' + rowdata.reportUrl + '">Edit <i class=""></i></a></div>';
};

/**
 * Grid data builder is used to create the object format required for JQWidgets
 * @param {*} res
 * @param {*} pageName
 * @return {object} send back
 */
function GridDataBuilder(res, pageName) {
    // dataField is used for providing type of data and its name.
    // columnList is used to define column name, type, and extra fields
    //  to define additional plugins inside the cell eg: drop down, calender ...,
    const dataField = [];
    const columnList = [];
    let primayKey = res.columnDetails[0].fieldName;
    // check for the type of fieldname future


    for (let i = 0; i < res.columnDetails.length; i++) {
        dataField.push(dataFieldObj(res.columnDetails[i]));

        /* Enable the below condition after pk(primary key) availabel from backend
        *  in JSON response.
        */

        if (res.columnDetails[i].hasOwnProperty('pk') && res.columnDetails[i].pk) {
            primayKey = res.columnDetails[i].fieldName;
        }

        columnList.push(columnObj(res.columnDetails[i]));
    }

    /**
     * mapes datafield name
     * @param {*} list
     * @return {object}
     */
    function dataFieldObj(list) {
        const obj = {};

        obj.name = list.fieldName;
        obj.type = list.type.toLowerCase();

        if (list.hasOwnProperty('map')) {
            obj.map = list.map;
        }

        return obj;
    }
    /** columnobj function will return the object fields
  *  based on the field type in list.
  * e.g. for date type and other types need to build the cellrender function.
  * @param {*} list
  * @return {object}
  */
    function columnObj(list) {
        const obj = {};

        obj.text = '<span title="' + list.columnTitle + '">' + list.columnTitle + '</span>';
        obj.dataField = list.fieldName;
        obj.width = (columnWidth[list.fieldName]) ? columnWidth[list.fieldName] : 140;
        obj.hidden = !list.visibility;
        /* if list type is date, pass the methods related to date to intialize
        *  date picker
        */

        if (list.type === 'boolean') {
            obj.cellsrenderer = userGroupCheckbox;
            obj.editable = false;
            obj.cellsAlign = 'center';
        } else if (list.fieldName === 'reportName' || list.fieldName === 'reporttype') {
            obj.pinned = true;
        } else if (list.fieldName === 'modify') {
            obj.cellsrenderer = reportModifyField;
            obj.cellsAlign = 'center';
        }

        return obj;
    }

    /**
     * Parses data based on permission
     * @return {object}
     */
    function parsedData() {
        const data = [].concat(res.data);
        const parseableFieldName = 'permission';

        const newData = data.map((item, index) => {
            item[parseableFieldName] = JSON.parse(item[parseableFieldName]);
            return item;
        });

        return newData;
    }

    const source = {
        dataType: 'json',
        dataFields: dataField,
        id: primayKey,
        localdata: parsedData(),
    };

    const dataAdapter = new $.jqx.dataAdapter(source);

    return {
        dataAdapter: dataAdapter,
        columns: columnList,
    };
}

export default GridDataBuilder;
