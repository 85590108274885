
import React from 'react';
/**
 * used to render the currency values.
 */
export class CurrencyRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const getColid = this.props.column.colId;
        let formattedValue;

        const usdFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        });

        const pctFormatter = new Intl.NumberFormat('en-US', {
            style: 'percent',
            minimumFractionDigits: 2,
        });

        if (getColid == 'grossMargin') {
            formattedValue = pctFormatter.format(this.props.node.data && this.props.column.colId &&
                this.props.node.data[this.props.column.colId] ? this.props.node.data[this.props.column.colId] : 0);
        } else {
            formattedValue = usdFormatter.format(this.props.node.data && this.props.column.colId &&
                this.props.node.data[this.props.column.colId] ? this.props.node.data[this.props.column.colId] : 0);
        }

        return (
            <React.Fragment>
                <div style={{ textAlign: 'right' }}>
                    {formattedValue}
                </div>
            </React.Fragment>
        );
    }
}

// //////////////////////////////////////////////////////////////////////////////

/**
 * used to edit the currency values.
 */

export class CurrencyEditor extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            selectedVal: props.node.data[props.column.colId] || '',
        };
    }

    isPopup() {
        return false;
    }

    isCancelBeforeStart() {

    }

    isCancelAfterEnd() { }

    afterGuiAttached() {
        const eInput = this.refs.input;
        eInput.focus();
        this.props.api.startEditingCell({
            'rowIndex': this.props.rowIndex,
            'colKey': this.props.column.colId,
        });
    }

    getValue() {
        return this.state.selectedVal;
    }

    componentWillUnmount() {
        const rowData = this.props.node.data;
        if (rowData[this.props.column.colId] != parseFloat(this.state.selectedVal)) {
            rowData[this.props.column.colId] = parseFloat(this.state.selectedVal);
            this.props.rowUpdate(rowData, this.props.node.rowIndex);
        }
        this.props.api.tabToNextCell();
    }

    handleOnChange(e) {
        if (e.target.value) {
            this.setState({ selectedVal: e.target.value });
        }
    }

    render() {
        return (
            <div>
                <input
                    ref="input"
                    style={{ width: '100%', height: '100%' }}
                    type={'text'}
                    onChange={this.handleOnChange.bind(this)}
                    defaultValue={this.state.selectedVal}
                />
            </div>

        );
    }
}
