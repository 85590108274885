import React from 'react';
import PropTypes from 'prop-types';
import CancelModal from '../modals/cancelModal';
import { withRouter } from 'react-router-dom';

const ProfileComponent = ({
    state,
    formEditable,
    formViewable,
    showProfileView,
    showChangePassword,
    handleSubmit,
    handleChange,
    changeUserGroup,
    handleChangePassword,
    submitChangePassword,
    openModalCancel,
    modalClose,
    getSpecialityData,
    generateSpecialityData,
    optionItem,
    cancelBtn,
    saveBtn,
    currentUser,
}) => {
    return (
        <div className="profile-modal">
            { state.userData.hasOwnProperty('user') &&
            <div>
                <div className="modal-header">
                    {state.modalTitle}
                    <span className="close" onClick={openModalCancel}>x</span>
                </div>
                <div className="modal-content">
                    <div className={`user-profile-form ${(state.profileView) ? '' : 'hide'}`}>
                        <form className={`profile-view ${(state.formEdit) ? 'form-edit' : ''}`} name="user-profile" id="user-profile">
                            <div className="form-container scroll">
                                { (state.user.userAuthority.email != 'N') &&
                                <div className="form-group">
                                    <label className="form-label">Email{state.validation.email ? "*" : ""}:</label>
                                    { (state.user.userAuthority.email === 'W') ?
                                        (<input disabled={state.formEdit ? "" : "disabled"} type="email" className="form-control" name="email" value={state.user.email} onChange={handleChange}></input>)
                                        : (<label className="form-control-label">{state.user.email}</label>)
                                    }
                                </div>
                                }
                                <div className="form-group">
                                    <label className="form-label">Password:</label>
                                    <a href="javascript:void(0)" onClick={showChangePassword}>Change Password</a>
                                </div>
                                { (state.user.userAuthority.firstName != 'N') &&
                                <div className="form-group">
                                    <label className="form-label">First Name{state.validation.firstName ? "*" : ""}:</label>
                                    { (state.user.userAuthority.firstName === 'W') ?
                                        (<input disabled= {state.formEdit ? "" : "disabled"} type="text" className="form-control" name="firstName" value={state.user.firstName} onChange={handleChange}></input>)
                                        : (<label className="form-control-label">{state.user.firstName}</label>)
                                    }
                                </div>
                                }
                                { (state.user.userAuthority.lastName != 'N') &&
                                <div className="form-group">
                                    <label className="form-label">Last Name{state.validation.lastName ? "*" : ""}:</label>
                                    { (state.user.userAuthority.lastName === 'W') ?
                                        (<input disabled={state.formEdit ? "" : "disabled"} type="text" className="form-control" name="lastName" value={state.user.lastName} onChange={handleChange}></input>)
                                        : (<label className="form-control-label">{state.user.lastName}</label>)
                                    }
                                </div>
                                }
                                { (state.user.userAuthority.nickName != 'N') &&
                                <div className="form-group">
                                    <label className="form-label">Nick Name{state.validation.nickName ? "*" : ""}:</label>
                                    { (state.user.userAuthority.nickName === 'W') ?
                                        (<input disabled={state.formEdit ? "" : "disabled"} type="text" className="form-control" name="nickName" value={state.user.nickName} onChange={handleChange} maxLength="10"></input>)
                                        : (<label className="form-control-label">{state.user.nickName}</label>)
                                    }
                                </div>
                                }
                                { (state.user.userAuthority.company != 'N') &&
                                <div className="form-group">
                                    <label className="form-label">Company{state.validation.company ? "*" : ""}:</label>
                                    { (state.user.userAuthority.company === 'W') ?
                                        <input disabled={currentUser ? false : true} type="text" className="form-control" name="company" value={state.user.company} onChange={handleChange}></input>
                                        : (<label className="form-control-label">{state.user.company}</label>)
                                    }
                                </div>
                                }
                                { (state.user.userAuthority.phone != 'N') &&
                                <div className="form-group">
                                    <label className="form-label">Phone No.{state.validation.phone ? "*" : ""}:</label>
                                    { (state.user.userAuthority.phone === 'W') ?
                                        <input disabled={state.formEdit ? "" : "disabled"} type="text" className="form-control" name="phone" value={state.user.phone} onChange={handleChange} maxLength="10"></input>
                                        : (<label className="form-control-label">{state.user.phone}</label>)
                                    }
                                </div>
                                }
                                { (state.user.userAuthority.designation != 'N') &&
                                <div className="form-group">
                                    <label className="form-label">Designation{state.validation.designation ? "*" : ""}:</label>
                                    { (state.user.userAuthority.designation === 'W') ?
                                        <input disabled={state.formEdit ? "" : "disabled"} type="text" className="form-control" name="designation" value={state.user.designation} onChange={handleChange}></input>
                                        : (<label className="form-control-label">{state.user.designation}</label>)
                                    }
                                </div>
                                }
                                { (state.user.userAuthority.speciality != 'N') &&
                                <div className="form-group">
                                    <label className="form-label">Speciality:</label>
                                    <label className="form-control-label speciality-label">{state.user.speciality}</label>
                                </div>
                                }
                                { (state.user.userAuthority.group != 'N') &&
                                <div className="form-group">
                                    <label className="form-label">User Group{state.validation.userGroups ? "*" : ""}:</label>
                                    { (state.user.userAuthority.group === 'W') ?
                                        <select className="form-control" name="defaultRole" onChange={changeUserGroup}>
                                            {optionItem}
                                        </select>
                                        : (<label className="form-control-label">{state.user.defaultRole}</label>)
                                    }
                                </div>
                                }
                            </div>
                            <div className="modal-footer">
                                {cancelBtn}

                                {(state.isFormEditable) ? saveBtn : ''}
                            </div>
                            {state.invalid && <span className="error-message">{state.errorMessage}</span>}
                        </form>
                    </div>

                    <div className={`user-change-password ${(state.changePassword) ? '' : 'hide'}`}>
                        <form
                            className="change-password"
                            name="change-password"
                            id="change-password"
                        >
                            <div className="form-group">
                                <label className="form-label">Current Password*</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="oldPassword"
                                    name="oldPassword"
                                    value={state.changePswd.oldPassword}
                                    onChange={handleChangePassword} />
                            </div>
                            <div className="form-group">
                                <label className="form-label">New Password*</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="newPassword"
                                    name="newPassword"
                                    value={state.changePswd.newPassword}
                                    onChange={handleChangePassword} />
                            </div>
                            <div className="form-group">
                                <label className="form-label">Confirm Password*</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="confNewPassword"
                                    name="confNewPassword"
                                    value={state.changePswd.confNewPassword}
                                    onChange={handleChangePassword} />
                                {(state.invalidPassword) ? <span className="error-msg">{state.errorMsg}</span> : ''}
                            </div>

                            <div className="modal-footer">
                                <input
                                    type="button"
                                    value="Cancel"
                                    onClick={openModalCancel} />
                                <input
                                    type="button"
                                    value="Save"
                                    onClick={submitChangePassword} />
                            </div>

                        </form>
                    </div>

                    <CancelModal
                        data={state.user}
                        isOpen={state.isModalOpen}
                        modalClose={modalClose}
                        isChangePassword={state.changePassword}
                    />

                </div>
            </div>
            }
        </div>
    );
};

ProfileComponent.propTypes = {
    state: PropTypes.object,
    formEditable: PropTypes.func,
    formViewable: PropTypes.func,
    showProfileView: PropTypes.func,
    showChangePassword: PropTypes.func,
    handleSubmit: PropTypes.func,
    handleChange: PropTypes.func,
    changeUserGroup: PropTypes.func,
    handleChangePassword: PropTypes.func,
    submitChangePassword: PropTypes.func,
    openModalCancel: PropTypes.func,
    modalClose: PropTypes.func,
    getSpecialityData: PropTypes.func,
    generateSpecialityData: PropTypes.func,
    optionItem: PropTypes.array,
    cancelBtn: PropTypes.object,
    saveBtn: PropTypes.object
};

export default withRouter(ProfileComponent);
