import React, { Component } from 'react';
// import JqxEditor from '../../common/vendor/jqwidgets-react/react_jqxeditor.js';
import Modal from '../../../modals/common-modal';
import ConfirmSubmit from '../confirmsubmit';
import axios from 'axios';


const constants = require('../../../common/constants');
const utils = require('../../../common/utils.js');
// var NotificationManager = require('../../common/notification');
import NotificationManager from '../../../common/components/notification';

import { ProductServices } from '../../services/product-services';

class AddKeywordModel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            keyword1: this.props.keywordData.keyword1,
            keyword2: this.props.keywordData.keyword2,
            keyword3: this.props.keywordData.keyword3,
            keyword4: this.props.keywordData.keyword4,
            keyword5: this.props.keywordData.keyword5,
            status: this.props.keywordData.status ? this.props.keywordData.status : '',
            date: this.props.keywordData.date,
            buyerName: this.props.keywordData.buyername,
            isModalOpen: false,
            afterAPIStatus: null,
            keywordNoteMaxLength: 100,
            prodId: [],
        };
        this.keywordData = {
            productId: null,
            copyNotes: this.props.keywordData.copyNotes ? this.props.keywordData.copyNotes : '',
            // copyNotes:this.props.keywordData.copyNotes,
            keyword1: this.props.keywordData.keyword1,
            keyword2: this.props.keywordData.keyword2,
            keyword3: this.props.keywordData.keyword3,
            keyword4: this.props.keywordData.keyword4,
            keyword5: this.props.keywordData.keyword5,
        };
        this.modalDialog = null;
        this.requiredFields = ['keyword1', 'keyword2', 'keyword3', 'keyword4', 'keyword5'];
        this.AllFields = ['keyword1', 'keyword2', 'keyword3', 'keyword4', 'keyword5', 'copyNotes'];
        this.validationData = {};
        this.approvalDisabled = true;
        this.bgClassList = {};
        this.NotEmpty = false;

        this.saveKeywords = this.saveKeywords.bind(this);
        this.submitForApproval = this.submitForApproval.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.showCopyNotes = this.showCopyNotes.bind(this);
        this.textareaChange = this.textareaChange.bind(this);
        this.approve = this.approve.bind(this);
        this.reject = this.reject.bind(this);
        this.closeConfirmModal = this.closeConfirmModal.bind(this);
        this.openConfirmModal = this.openConfirmModal.bind(this);
        this.confirmApproveSubmit = this.confirmApproveSubmit.bind(this);
        this.checkObjHasValueInRequiredKeys = this.checkObjHasValueInRequiredKeys.bind(this);
        this.didValueChanged = this.didValueChanged.bind(this);
        this.closeAllModal = this.closeAllModal.bind(this);
        this.confirmSaveChanges = this.confirmSaveChanges.bind(this);
        this.ApplyBGColorClassName = this.ApplyBGColorClassName.bind(this);
        this.ApplyBGColorClassName(); // Call @ only once at the time of loading
    }

    /**
     * Setter for data to the popup
     */
    componentDidMount() {
    // //////////////////////////////// saves the Product Id to state to it can be used anywhere //////////////////////////////////

        let sendId = null;
        const newArr = [];

        if (this.props.currentRowData.dataLevel == 'SINGLE') {
            sendId = this.props.currentRowData.chldProdId;
        } else if (this.props.currentRowData.dataLevel == 'PARENT') {
            sendId = this.props.currentRowData.parentProductId;
        } else if (this.props.currentRowData.dataLevel == 'INTERMEDIATE') {
            sendId = this.props.currentRowData.intProdId;
        } else if (this.props.currentRowData.dataLevel == 'CHILD') {
            sendId = this.props.currentRowData.chldProdId;
        }

        newArr.push(sendId);

        this.setState({ prodId: newArr});

        // this.props.getKeywordPopupData(sendId);

        if ([constants.ROLES.BUYER, constants.ROLES.ADMIN].indexOf(this.props.user.defaultRole) !== -1) {
            $('.submitBtn').attr('disabled', true);
        }
    }

    /**
     * Opens confirm modal
     */
    openConfirmModal() {
        this.setState({isModalOpen: true});
    }

    /**
     * Closes confirm modal
     */
    closeConfirmModal() {
        this.setState({isModalOpen: false});
    }

    /**
     * Closes all modals
     */
    closeAllModal() {
        this.closeConfirmModal();
        this.props.modalClose();
    }

    /**
     * Detects a change in the textarea
     * @param {event} e
     */
    textareaChange(e) {
        this.keywordData.copyNotes = e.target.value;
        if (e.target.value.length > 3) {
            $('.submitBtn').removeAttr('disabled');
        } else {
            $('.submitBtn').attr('disabled', true);
        }
    }

    /**
     * Show Copy Notes if they click reject and enable submit Btn
     * @param {*} e Event handler
     */
    showCopyNotes(e) {
        $(e.currentTarget).addClass('active');
        $('.copynotes').show();
        $('.submitBtn').show();
        this.messagesEnd.scrollIntoView({ behavior: 'smooth' });
        if ($('#copynotes').val().length > 3) {
            $('.submitBtn').removeAttr('disabled');
        }
    }


    /**
     * Calls the backend to save data for the approval process
     * @param {object} data Request Payload
     * @param {string} approve
     */
    approval(data, approve) {
        const _this = this;

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };
        ProductServices.approveByBuyer(data)
            .then( (response) => {
                console.log('productContainer helperComponents add-keyword-modal API call success data: ', response.data);
                const res = response.data;

                NotificationManager({message: res.message, type: 1});
                let status = 'rejected';
                if (approve) {
                    status = 'approved';
                }
			  // _this.props.setCellValueAndCopyToChild('keywordsStatus',status);
                _this.props.modalClose(res.currentStatus);
                _this.setState({ afterAPIStatus: res.currstatusentStatus});
            })
            .catch( (error) => {
                console.log('productContainer helperComponents add-keyword-modal API call failed', error);
                NotificationManager({message: error, type: 4});
            });
    }

    /**
     * Formats the request payload sent to the backend
     * @param {*} e Event
     */
    approve(e) {
        const _this = this;
        $(e.currentTarget).parent().find('.rejectBtn').removeClass('active');
        const postdata = _this.state.prodId.map((e, i)=>{
            const data = {
                'productcode': e,
                'status': true,
                'processName': 'keywords',
                'copynotes': this.keywordData.copyNotes,
                'userid': this.props.user.userId,
            };
            // _this.props.productGrid.setCellValue(e,'isWebproducerApproved',true);
            return data;
        });
        // _this.props.setCellValueAndCopyToChild('isWebproducerApproved',true);
        this.approval(postdata, 1);
    }

    /**
     * Formats the request payload sent to the backend
     * @return {void}
     */
    reject() {
        const _this = this;
        const postdata = _this.state.prodId.map((e, i)=>{
            const data = {
                'productcode': e,
                'status': false,
                'processName': 'keywords',
                'copynotes': this.keywordData.copyNotes,
                'userid': this.props.user.userId,
            };
            return data;
        });
        if (postdata[0].copynotes.length >= 100) {
            NotificationManager({
                message: 'Please enter less than 100 characters',
                type: 4,
            });
            return false;
        } else {
            this.approval(postdata, 0);
        }
    }

    /**
   * Calls the backend to save keywords data
   * @param {Object} e
   */
    saveKeywords(e) {
        if (e) {
            e.preventDefault();
        }
        const _this = this;
        this.checkObjHasValueInRequiredKeys(this.keywordData);
        // let prodId = [];

        // let sendId = null

        // if (this.props.currentRowData.dataLevel == 'SINGLE') {
        // sendId = this.props.currentRowData.chldProdId;
        // } else if (this.props.currentRowData.dataLevel == 'PARENT') {
        //     sendId = this.props.currentRowData.parentProductId;
        // } else if (this.props.currentRowData.dataLevel == 'INTERMEDIATE') {
        //     sendId = this.props.currentRowData.intProdId;
        // } else if (this.props.currentRowData.dataLevel == 'CHILD') {
        //     sendId = this.props.currentRowData.chldProdId;
        // }

        // prodId.push(sendId);

        if (this.validationData.valid) {
            const postdata = _this.state.prodId.map((e, i)=>{
                const newobj = Object.assign({}, _this.keywordData);
                newobj.productId = e;
                return newobj;
            });

            ProductServices.updateKeywords(postdata)
                .then( (response) => {
                    console.log('productContainer helperComponents add-keyword-modal API call success data: ', response.data);
                    const res = response.data;

                    NotificationManager({message: res.message, type: 1});
                    // _this.props.setCellValueAndCopyToChild('keywords','123');
                    _this.props.modalClose(res.currentStatus);
                    _this.setState({ afterAPIStatus: res.currstatusentStatus});
                })
                .catch( (error) => {
                    console.log('productContainer helperComponents add-keyword-modal API call failed', error);
                    NotificationManager({message: error, type: 4});
                });
        } else {
            NotificationManager({message: 'Please enter atleast one keyword'});
        }
    }

    /**
   * Validates when Submit for approval is done
   * @param {Object} e
   */
    submitForApproval(e) {
        e.preventDefault();
        // Validate Keyword data
        this.checkObjHasValueInRequiredKeys(this.keywordData);
        if (this.validationData.valid) {
            if (this.validationData.force) {
                this.modalDialog = 'SubmitConfirm';
                this.openConfirmModal();
            } else {
                this.confirmApproveSubmit();
            }
        } else {
            NotificationManager({message: 'Please enter atlease one keyword'});
        }
    /**/
    }

    /**
     * Validates based on required fields
     * @param {*} obj
     */
    checkObjHasValueInRequiredKeys(obj) {
        const needcount = this.requiredFields.length;
        this.validationData = {valid: false, validcount: 0, needcount: needcount};
        for (let i = 0; i < needcount; i++) {
            const val = $.trim(obj[this.requiredFields[i]]);
            if (val != null && val != undefined && val != '') {
                this.validationData.valid = true;
                this.validationData.validcount++;
            }
        }
        if (this.validationData.validcount > 0 && this.validationData.validcount !== this.requiredFields.length) {
            this.validationData.force = true;
        }
    }

    /**
   * Showing popup to ask confirmation to cancel(Yes/No/Cancel)
   */
    confirmSaveChanges() {
        let getCurrentStatus;

        if (this.state.afterAPIStatus) {
            getCurrentStatus = this.state.afterAPIStatus;
        } else if (this.state.afterAPIStatus == null) {
            getCurrentStatus = this.state.status;
        } else {
            getCurrentStatus = this.state.status;
        }
        // let getCurrentStatus = this.state.afterAPIStatus ? this.state.afterAPIStatus : this.state.status
        if (this.didValueChanged() && [constants.ROLES.BUYER].indexOf(this.props.user.defaultRole) === -1) {
            this.modalDialog = 'SaveChangesCancel';
            this.openConfirmModal();
        } else {
            this.props.modalClose(getCurrentStatus);
        }
    }

    /**
     * Checks if value has been changed
     * @return {date} date value
     */
    didValueChanged() {
        let isChanged = false;
        let prev;
        let next;
        for (let i = 0; i < this.AllFields.length; i++) {
            prev = this.props.keywordData[this.AllFields[i]];
            next = this.keywordData[this.AllFields[i]];
            prev = prev !== null ? prev : '';
            next = next !== null ? next : '';
            if (prev.localeCompare(next) !== 0) {
                isChanged = true;
                break;
            }
        }
        return isChanged;
    }

    /**
     * Confirms approval and submits
     */
    confirmApproveSubmit() {
        const _this = this;
        _this.closeConfirmModal();

        //   var postdata =_this.props.productId.map((e,i)=>{
        //   var newobj = Object.assign({},_this.keywordData);
        //   newobj.productId=e;
        //   return newobj;
        // });


        const postdata = _this.state.prodId.map((e, i)=>{
            const newobj = Object.assign({}, _this.keywordData);
            newobj.productId = e;
            return newobj;
        });


        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };
        axios({
            method: 'post',
            url: require('../../../common/configurations.js').product.submitKeywords,
            headers: headers,
            data: postdata,
        })
            .then( (response) => {
                console.log('productContainer helperComponents add-keyword-modal API call success data: ', response.data);
                const res = response.data;

                NotificationManager({message: res.message, type: 1});
                // _this.props.setCellValueAndCopyToChild('keywordsStatus','submitted');
                _this.props.modalClose(res.currentStatus);
                _this.setState({ afterAPIStatus: res.currstatusentStatus});
            })
            .catch( (error) => {
                console.log('productContainer helperComponents add-keyword-modal API call failed', error);
                NotificationManager({message: error, type: 4});
            });
    }

    /**
     * Handles changes in all inputs
     * @param {*} event Event
     */
    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.keywordData[name] = value;
        this.setState({[name]: value});
        if (this.didValueChanged) {
            $('.saveBtn').removeAttr('disabled');
            if (['approved', 'submitted'].indexOf(this.state.status.toLowerCase()) === -1) {
                this.approvalDisabled = false;
            }
        } else {
            $('.saveBtn').attr('disabled');
            this.approvalDisabled = true;
        }
    }

    /**
   * Apply bg color based on status
   */
    ApplyBGColorClassName() {
        const currentStatus = this.state.status.toLowerCase();
        for (let i = 0; i < this.requiredFields.length; i++) {
            this.bgClassList[this.requiredFields[i]] = '';
            if (this.state[this.requiredFields[i]]) {
                this.bgClassList[this.requiredFields[i]] = 'notempty';
                this.NotEmpty = true;
                if (['approved', 'rejected', 'submitted'].indexOf(currentStatus) !== -1) {
                    this.bgClassList[this.requiredFields[i]] = currentStatus;
                }
            }
        }
    }

    render() {
        const tools = ' font size | bold italic underline | background  | left center right | outdent indent';
        let isShowHideCopyNotes = {display: 'none'};
        let isCopyNotesDisabled = 'true';
        let isShowApproveRejectBtn = false;
        let isDisableRejectBtn = false;
        let isDisableApproveBtn = false;
        let statusClassName = '';
        let showStatusSec = false;
        const currentStatus = this.state.status.toLowerCase();
        let popupheader = 'Add/Edit Keywords';
        const modalClass = 'ConfirmSubmitModal';
        let formFieldDisable = false;
        const dataString = this.props.keywordData.date;
        const date = new Date(dataString);
        const dateFormat = constants.MONTHNAMEOFYEAR[date.getMonth()] + ' ' + utils.nth(date.getDate()) + ' ' + date.getFullYear();
        if (currentStatus === 'approved' || currentStatus === '') {
            isDisableApproveBtn = true;
            isDisableRejectBtn = true;
        }
        if (currentStatus === 'rejected') {
            isDisableRejectBtn = true;
        }
        if (this.keywordData.copyNotes && this.keywordData.copyNotes !== '') {
            isShowHideCopyNotes = {};
        }
        if (this.state.status) {
            statusClassName = currentStatus;
            if (['approved', 'rejected'].indexOf(currentStatus) !== -1) {
                showStatusSec = true;
            }
        }
        if (['approved', 'submitted', 'rejected'].indexOf(currentStatus) === -1 && this.NotEmpty) {
            this.approvalDisabled = false;
        }
        if ([constants.ROLES.BUYER].indexOf(this.props.user.defaultRole) !== -1) {
            popupheader = 'Approve Keywords';
            isShowApproveRejectBtn = true;
            formFieldDisable = true;
            isCopyNotesDisabled = '';
        }
        if ([constants.ROLES.ADMIN].indexOf(this.props.user.defaultRole) !== -1 || window.readableFields.indexOf('keywords') > -1) {
            popupheader = 'View Keywords';
            isShowApproveRejectBtn = false;
            formFieldDisable = true;
            this.approvalDisabled = true;
            isCopyNotesDisabled = 'true';
        }
        return (
            <div>
                <div className="modal-header">
                    {popupheader}
                    <span className="close" onClick={this.confirmSaveChanges}>x</span>
                </div>
                <div className="modal-content">
                    <div className="add-copy modal-form">
                        <form className="addCopyDetails" name="addCopyDetails" onSubmit={this.saveKeywords}>
                            <div className="form-container scroll">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <div className="prodName">
                                                {this.props.currentRowData.prodName}
                                            </div>
                                            {showStatusSec &&
                          <div className={'status ' + statusClassName}>{this.state.status}</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Keyword 1</label>
                                            <input type="text" className={'form-control col-12 ' + this.bgClassList.keyword1}
                                                name="keyword1" defaultValue={this.state.keyword1} onChange={this.handleChange}
                                                maxLength="50" disabled={formFieldDisable} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Keyword 2</label>
                                            <input type="text" className={'form-control col-12 ' + this.bgClassList.keyword2}
                                                name="keyword2" defaultValue={this.state.keyword2} onChange={this.handleChange}
                                                maxLength="50" disabled={formFieldDisable} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Keyword 3</label>
                                            <input type="text" className={'form-control col-12 ' + this.bgClassList.keyword3}
                                                name="keyword3" defaultValue={this.state.keyword3} onChange={this.handleChange}
                                                maxLength="50" disabled={formFieldDisable} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Keyword 4</label>
                                            <input type="text" className={'form-control col-12 ' + this.bgClassList.keyword4}
                                                name="keyword4" defaultValue={this.state.keyword4} onChange={this.handleChange}
                                                maxLength="50" disabled={formFieldDisable} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Keyword 5</label>
                                            <input type="text" className={'form-control col-12 ' + this.bgClassList.keyword5}
                                                name="keyword5" defaultValue={this.state.keyword5} onChange={this.handleChange}
                                                maxLength="50" disabled={formFieldDisable} />
                                        </div>
                                    </div>
                                </div>
                                { showStatusSec &&
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <p>&lt;{this.state.status}&gt; by {this.state.buyerName}, {dateFormat}</p>
                            </div>
                        </div>
                    </div>
                                }
                                { isShowApproveRejectBtn &&
                      <div className="row">
                          <div className="col-12 addcopy-buyer-action">
                              <input type="button" disabled={isDisableRejectBtn} className="gray-button rejectBtn" value="Reject" onClick={this.showCopyNotes}/>
                              <input type="button" disabled={isDisableApproveBtn} className="gray-button" value="Approve" onClick={this.approve}/>
                          </div>
                      </div>
                                }
                                <div
                                    className="row copynotes"
                                    style={isShowHideCopyNotes}
                                    ref={(el) => {
                                        this.messagesEnd = el;
                                    }}

                                >
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Keyword Notes</label>
                                            <textarea disabled={isCopyNotesDisabled} className="form-control col-12 texrarea-fld"
                                                name="copynotes" id="copynotes" cols="7" onChange={this.textareaChange} maxLength={this.state.keywordNoteMaxLength}
                                                defaultValue={this.keywordData.copyNotes}/>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            { isShowApproveRejectBtn &&
                <div className="modal-footer text-center">
                    <input type="button" style={isShowHideCopyNotes} className="gray-button submitBtn" title="Reject" value="Submit" onClick={this.reject}/>
                    { this.props.user.defaultRole === constants.ROLES.ADMIN &&
                    <input type="submit" disabled="true" className="gray-button saveBtn" title="Save" value="Save"/>
                    }
                </div>
                            }
                            { !isShowApproveRejectBtn &&
                <div className="modal-footer text-right">
                    <input type="submit" disabled="true" className="gray-button saveBtn" title="Save" value="Save"/>
                    <input type="button" disabled={this.approvalDisabled} className="gray-button submitApprove"
                        title="Save" value="Submit for Approval" onClick={this.submitForApproval}/>
                </div>
                            }
                        </form>
                    </div>
                </div>
                { this.state.isModalOpen &&
        <Modal isOpen={this.state.isModalOpen} addClass={modalClass}>
            <ConfirmSubmit dialog={this.modalDialog} modalClose={this.closeConfirmModal} confirmApproveSubmit={this.confirmApproveSubmit}
                validationData={this.validationData} yesSaveChange={this.saveKeywords} noSaveChange={this.closeAllModal} />
        </Modal>
                }
            </div>
        );
    }
}


export default AddKeywordModel;
