import React, { Component, useState, useEffect } from 'react';
import Confirm from './confirm';
import NotificationManager from '../../common/components/notification';

class CopyWritingFooter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showProductInfoSection: false,
            openConfirm: false,
        };

        this.openConfirm = this.openConfirm.bind(this);
        this.closeConfirm = this.closeConfirm.bind(this);
    }


    componentDidUpdate(prevProps, prevState) {
        console.log('CopyWritingFooter componentDidUpdate', this.props, prevProps, this.state, prevState);

        const _this = this;
        if (prevProps.selectedTab != _this.props.selectedTab && _this.props.selectedTab == 'product') {
            _this.setState ({ showProductInfoSection: true });
        }

        if (prevProps.selectedTab != _this.props.selectedTab && _this.props.selectedTab == 'image') {
            _this.setState ({ showProductInfoSection: false });
        }
    }

    openConfirm() {
        const _this = this;
        if (_this.props.selectedRowData.length == 0) {
            NotificationManager({ message: 'Please select a record to continue', type: 4 });
            return;
        }
        this.setState ({ openConfirm: true });
    }

    closeConfirm(key) {
        const _this = this;
        console.log('CopyWritingFooter openConfirm', key);
        if (key == 'no') {
            _this.props.cancelChangesCopywrite();
            _this.setState ({ openConfirm: false });
        } else if (key == 'yes') {
            _this.props.saveCopywrite();
            _this.setState ({ openConfirm: false });
        } else {
            _this.setState ({ openConfirm: false });
        }
    }


    render() {
        console.log('CopyWritingFooter render', this.props, this.state);

        return (
            <div className="cw-footer">
                {
                    this.state.showProductInfoSection &&
            <div className="cw-pi-footer">
                <button className={`cw-footer-button cancel ${this.props.leftDrawerPulled ? 'cw-pulled' : ''}`} onClick={this.props.removeCopywritingGrid}><i className="fa fa-times" aria-hidden="true"></i>Cancel Changes</button>
                <button className={`cw-footer-button save ${this.props.leftDrawerPulled ? 'cw-pulled' : ''}`} onClick={this.props.removeCopywritingGrid}><i className="fa fa-arrow-up" aria-hidden="true"></i>Save Changes</button>
            </div>
                }
                <button disabled={this.props.enableApproveReject} className={`cw-footer-button approve ${this.props.leftDrawerPulled ? 'cw-pulled' : ''}`} onClick={this.props.approveCopywrite}><i className="fa fa-check" aria-hidden="true"></i>Approve Copy</button>
                <button disabled={this.props.enableApproveReject} className={`cw-footer-button reject ${this.props.leftDrawerPulled ? 'cw-pulled' : ''}`} onClick={this.props.rejectCopywrite}><i className="fa fa-reply" aria-hidden="true"></i>Reject Copy</button>
                <button disabled={this.props.enableSubmitSave} className={`cw-footer-button submit ${this.props.leftDrawerPulled ? 'cw-pulled cw-reduce-margin' : ''}`} onClick={this.props.submitCopywrite}><i className="fa fa-arrow-left" aria-hidden="true"></i>Submit Changes</button>
                <button disabled={this.props.enableSubmitSave} className={`cw-footer-button save ${this.props.leftDrawerPulled ? 'cw-pulled' : ''}`} onClick={this.props.saveCopywrite}><i className="fa fa-arrow-up" aria-hidden="true"></i>Save Changes</button>
                <button disabled={this.props.enableSubmitSave} className={`cw-footer-button cancel ${this.props.leftDrawerPulled ? 'cw-pulled' : ''}`} onClick={this.openConfirm}><i className="fa fa-times" aria-hidden="true"></i>Cancel Changes</button>
                <Confirm open={this.state.openConfirm} closeModal={this.closeConfirm} />
            </div>
        );
    }
}

export default CopyWritingFooter;
