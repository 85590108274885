import React, { Component } from 'react';

class FilterStrip extends Component {
    constructor(props) {
        super(props);

        this.filters = this.createFilterArray(props);
        this.removeSingle = this.removeSingle.bind(this);
    }

    createFilterArray(filtersObj) {
        let filters = [];

        if (filtersObj.catIds && filtersObj.catIds.length > 0 && filtersObj.catIdsObj) {
            filtersObj.catIds.map((item, index) => {
                let obj = {};
                obj.name = filtersObj.catIdsObj[item];
                obj.val = item;
                obj.type = "catIds";
                obj.index = index;

                filters.push(obj);
            });
        }

        if (filtersObj.customFilters && filtersObj.customFilters.length > 0) {
            let obj = {}, isValuePresent = false;
            obj.name = "Refinement";
            obj.type = "customFilters";

            filtersObj.customFilters.map((item, index) => {
                if (item.value) {
                    isValuePresent = true;
                }
            });

            if (isValuePresent) {
                filters.push(obj);
            }
        }

        return filters;
    }

    componentWillReceiveProps(nextProps) {
        this.filters = this.createFilterArray(nextProps.filters);
    }

    removeSingle(type, position) {
        let removeFilter  = { type, position };
        this.props.updateFilters(removeFilter);
    }

    render() {
        let props = this.props;

        console.log('FilterStrip render: ', this.props);

        return (
            <div className="filter-strip-holder">
                <ul className="filter-strip">

                    {this.filters.length > 0 &&
                    this.filters.map((item, index) => {
                        return (
                            <li key={index}>
                                {item.name}
                                { this.filters.length > 1 &&
                        <span className="remove" onClick={()=>{this.removeSingle(item.type, item.index);}}></span>
                                }
                            </li>
                        );
                    })
                    }
                </ul>
            </div>
        );
    }
}

export default FilterStrip;
