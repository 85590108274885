import React from 'react';
import {ProductServices} from '../../product2/services/product-services';

class ImageInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageData: [],
            mainImage: null,
        };
    }

    componentWillMount() {
        if (!this.state.mainImage && this.props.selectedRowData) {
            let imagesArr = this.props.selectedRowData.images?.digitalAssetInfos?.map(i => (
                {
                    image: i.baseUrl + i.digitalAssetSummary.mediumImage,
                    id: i.fileName
                }
            ));

            this.setState({ imageData: imagesArr, mainImage: imagesArr ? imagesArr[0].image : '' });
        }
    }

    componentWillReceiveProps(nextProps) {
        const _this = this;
        if (nextProps.selectedRowData && _this.props.selectedRowData && nextProps.selectedRowData.productId != _this.props.selectedRowData.productId) {
            let imagesArr = nextProps.selectedRowData.images?.digitalAssetInfos?.map(i => (
                {
                    image: i.baseUrl + i.digitalAssetSummary.mediumImage,
                    id: i.fileName
                }
            ));

            this.setState({ imageData: imagesArr, mainImage: imagesArr ? imagesArr[0].image : '' });
        }
    }

    changeImage(e) {
        const id = e.currentTarget.id;
        this.state.imageData.map((i, x) => {
            if (i.id == id) {
                this.setState({ mainImage: i.image });
            }
        });
    }

    render() {
        let thumbnailImages = this.state.imageData && this.state.imageData.map((i, x) => {
            return <img key={x} id={i.id} alt={i.id} src={i.image} onClick={this.changeImage.bind(this)} />;
        });
        return(
            <div className='tabItems'>
                <div className="image-container">
                    <div className="thumbnails">
                        {thumbnailImages}
                    </div>
                    <div className="main-images">
                        {this.state.mainImage &&
                    <img alt={'Hero Image'} src={this.state.mainImage} />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default ImageInfo;
