import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import CancelModal from './helperComponents/cancelModal.js';
import AdminFooter from './helperComponents/adminFooter.js';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import JqxGrid from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';

const DropDownComponent = ({
    state,
    props,
    handleSelectChange,
    MakeItem,
    dropDownGridRef,
    gridData,
    gridHeight,

    deleteItem,
    addGridRow,
    applySaveChanges,
    cancelChanges,

    modalClose,
    onRowselect,
    onRowunselect,
    onCellvaluechanged,
    dropDownFooterRef,
}) => {
    return (
        <div>
            <div className="pos-relative">
                <div className="dropdown-section">
                    <h3>Drop Down Group:&nbsp;
                        {state.dropdownlist &&
              <select
                  className="form-input-select atrGroup"
                  onChange={handleSelectChange}>

                  {state.dropdownlist.map(MakeItem)}
              </select>
                        }
                    </h3>
                </div>
                {state.isLoaded &&
          <div id="dropdownsGrid">
              <JqxGrid
                  ref={dropDownGridRef}
                  width={'99%'}
                  source={gridData.dataAdapter}
                  pageable={false}
                  checkboxes={true}
                  editable={'click'}
                  columns={gridData.columns}
                  sortable={true}
                  autorowheight={false}
                  autowidth={true}
                  height={gridHeight}
                  columnsautoresize={true}
                  theme={'light'}
                  selectionmode={'checkbox'}
                  columnsreorder={true}
                  columnsmenu={false}
                  onRowselect={onRowselect}
                  onRowunselect={onRowunselect}
                  onCellvaluechanged={onCellvaluechanged}
              />
          </div>
                }
                <AdminFooter
                    vendorCount={state.vendorTotalCount}
                    selectedRows={state.selectedRowsCount}
                    checkedRows={state.checkedRows}
                    checkedVendorData={state.checkedVendorData}
                    delete={deleteItem}
                    addGridRow={addGridRow}
                    enableApply={state.isApplyEnabled}
                    applySaveChanges={applySaveChanges}
                    cancelChanges={cancelChanges}
                    ref = {dropDownFooterRef}
                />
                <CancelModal
                    isOpen={state.isModalOpen}
                    modalClose={modalClose}
                />
            </div>
        </div>
    );
};

DropDownComponent.propTypes = {
    state: PropTypes.object,
    props: PropTypes.object,

};

export default withRouter(DropDownComponent);


