import React, { Component } from 'react';
import RejectEvent from './rejectEventContainer';

import CampaignOverview from './campaignOverviewContainer';

import 'react-accessible-accordion/dist/react-accessible-accordion.css';

class DeleteConfirm extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let modalHead = "";
        let content = null;

        if (this.props.modalName === "ConfirmDelete") {
            modalHead = "Confirm Delete";
            content = <ConfirmDelete {...this.props} />;
        } else if (this.props.modalName === "SaveChanges") {
            modalHead = "Save Changes";
            content = <SaveChanges {...this.props} />;
        } else if (this.props.modalName === "SaveEditChanges") {
            modalHead = "Save Edited Changes";
            content = <SaveEditChanges {...this.props} />;
        } else if (this.props.modalName === "CampaignOverview") {
            modalHead = "Campaign Overview";
            content = <CampaignOverview {...this.props} />;
        } else {
            modalHead = "Reason for Rejection";
            content = <RejectEvent {...this.props} />;
        }

        return (
            <div>
                <div className="modal-header">
                    {modalHead}
                    <span className="close" onClick={this.props.modalClose}>x</span>
                </div>
                <div className="modal-content">
                    {content}
                </div>
            </div>
        );
    }
}

class SaveChanges extends React.PureComponent {
    render() {
        return (
            <div className="add-copy modal-form">
                <form className="confirmdelete" name="confirmdelete">
                    <div className="form-container scroll">
                        <p>Do you want to Save Changes?</p>
                    </div>
                    <div className="modal-footer text-center">
                        <input type="button" className="gray-button" name="yes" value="Yes" onClick={this.props.yesSaveChanges} />
                        <input type="button" className="gray-button" name="no" value="No" onClick={this.props.noSaveChanges} />
                        <input type="button" className="gray-button" name="Cancel" value="Cancel" onClick={this.props.modalClose} />
                    </div>
                </form>
            </div>
        );
    }
}

class SaveEditChanges extends React.PureComponent {
    render() {
        return (
            <div className="add-copy modal-form">
                <form className="confirmdelete" name="confirmdelete">
                    <div className="form-container scroll">
                        <p>Do you want to Save Changes?</p>
                    </div>
                    <div className="modal-footer text-center">
                        <input type="button" className="gray-button" name="yes" value="Yes" onClick={this.props.yesSaveEditChanges} />
                        <input type="button" className="gray-button" name="no" value="No" onClick={this.props.noSaveEditChanges} />
                        <input type="button" className="gray-button" name="Cancel" value="Cancel" onClick={this.props.modalClose} />
                    </div>
                </form>
            </div>
        );
    }
}

class ConfirmDelete extends React.PureComponent {
    render() {
        return (
            <div className="add-copy modal-form">
                <form className="confirmdelete" name="confirmdelete">
                    <div className="form-container scroll">
                        <p>Are you sure on deleting the rule?</p>
                    </div>
                    <div className="modal-footer text-right">
                        <input type="button" className="gray-button" name="yesDelete" value="Yes" onClick={this.props.deleteRules} />
                        <input type="button" className="gray-button" name="noDelete" value="No" onClick={this.props.modalClose} />
                    </div>
                </form>
            </div>
        );
    }
}

export default DeleteConfirm;
