import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import JqxGrid from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';

const UserFieldsComponent = ({
    state,
    props,
    userFieldsGridRef,
    gridData,
    gridHeight,
    editSettings,
}) => {
    return (
        <div>
            <div className="UserFieldGrid">
                {state.isLoaded &&
                    <JqxGrid
                        ref={userFieldsGridRef}
                        width={'99%'}
                        source={gridData.dataAdapter}
                        pageable={false}
                        editable={false}
                        hierarchicalCheckboxes={true}
                        columnsResize={true}
                        columns={gridData.columns}
                        sortable={true}
                        autoRowHeight={false}
                        autoWidth={true}
                        height={gridHeight}
                        theme={'light'}
                        editSettings={editSettings}
                        columnsreorder={true}
                        enabletooltips={true}
                        enableellipsis={true}
                        columnsmenu={false}
                    />
                }
            </div>
        </div>
    );
};

UserFieldsComponent.propTypes = {
    state: PropTypes.object,
    props: PropTypes.object,
};

export default withRouter(UserFieldsComponent);
