import React from 'react';
import ImageListerModal from './imageListerModal';
import NotificationManager from '../../../common/components/notification';
import axios from 'axios';

export class ImagePopupRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            // imageData: props.imageData(),
            rowHeight: props.getRowHeight(),
            imageData: '',
        };
    }

    render() {
        const emprtyArray = [];
        const imgArray = this.props.value == null ? emprtyArray : this.props.value; // Array of data with image urls

        const _this = this;
        let getImages = this.props.node.data && this.props.node.data.images ? this.props.node.data.images : [];
        let url = '';

        getImages && getImages.digitalAssetInfos && getImages.digitalAssetInfos.map((i) => {
            i.objectTags && i.objectTags.map((j) => {
                if (j.key == 'order' && j.value == '0') {
                    url = i.baseUrl + i.digitalAssetSummary.thumbnailImage;
                }
            });
        });


        if (getImages && getImages.length > 0 && getImages[0].thumbnailImage) {
            url = this.props.value[0].thumbnailImage;
        }

        const width = this.state.rowHeight === 50 ? this.state.rowHeight - 2 : '100%';
        return (
            <div style={{ height: '100%', textAlign: 'center' }}>
                <span style={{ display: 'inline-block', verticalAlign: 'middle', height: '100%' }}></span>
                {
                    url
                        ? <img src={url} width={width} height={'100%'} />
                        : null
                }
            </div>
        );
    }
}

// ////////////////////////////////////////////////////////////////////////////////////////

export class ImagePopupEditor extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            showPopup: false,
            imageData: props.imageData(),
            oldImage: this.props.value,
        };
    }

    componentWillMount() {
        const data = [];
        this.props.updateUploadedImage(data);

        if (this.props.node.data.catId) {
            this.setState({ showPopup: true, imageData: null });
        } else {
            NotificationManager({
                message: 'Image can only be added once the product has been created',
                type: 4,
            });
        }
    }

    // Comes with AG grid used to set a cell to be a popup or not
    isPopup() {
        return true;
    }

    getValue() {
        return this.state.imageData;
    }

    getHeroImage(data) {
        this.setState({
            imageData: data
        });
    }

    // Closes the image popup
    closeImagePopup() {
        this.setState({ showPopup: false });
        this.props.api.stopEditing();
    }

    render() {
        const imageInfo = {};
        const images = this.props.value;
        //        const imageObj = {};
        //        imageObj.deleteFlag = false;
        //        imageObj.imageId = 1226;
        //        imageObj.imageName = 'truest me';
        //        imageObj.imageUrl = this.props.value;
        //        imageObj.thumbNailUrl = this.props.value;
        //        imageObj.tinyImageUrl = this.props.value;
        //        images.push(imageObj);
        imageInfo['images'] = images;
        if (this.props.node.data.dataLevel == 'SINGLE') {
            imageInfo['dataLevel'] = this.props.node.data.dataLevel;
            imageInfo['chldProuctId'] = this.props.node.data.chldProdId;
        } else if (this.props.node.data.dataLevel == 'PARENT') {
            imageInfo['dataLevel'] = this.props.node.data.dataLevel;
            imageInfo['chldProuctId'] = this.props.node.data.parentProductId;
        } else if (this.props.node.data.dataLevel == 'INTERMEDIATE') {
            imageInfo['dataLevel'] = this.props.node.data.dataLevel;
            imageInfo['childrenIds'] = this.props.node.data.intProdId;
        } else if (this.props.node.data.dataLevel == 'CHILD') {
            imageInfo['dataLevel'] = this.props.node.data.dataLevel;
            imageInfo['childrenIds'] = this.props.node.data.chldProdId;
        }

        return (
            <div>
                {
                    this.state.showPopup &&
                    <div className="ag-modal-container">
                        <div className="ag-modal-holder">
                            <ImageListerModal
                                modalClose={this.closeImagePopup.bind(this)}
                                imageInfo={imageInfo}
                                user={this.props.user}
                                updateImage={this.props.updateUploadedImage}
                                getHeroImage = {this.getHeroImage.bind(this)}
                                {...this.props}
                            />
                        </div>
                    </div>
                }
            </div>
        );
    }
}

