import {endpoint} from '../../common/REST/endpoints';
import {headers} from '../../common/REST/headers';
import request from '../../common/REST/request';

let DashboardServices = {
    priorityAlerts: function(payload) {
        return request({
            method: endpoint.dashboard.priorityAlerts.method,
            url: endpoint.dashboard.priorityAlerts.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    getRecentAlerts: function(payload) {
        return request({
            method: endpoint.dashboard.getRecentAlerts.method,
            url: endpoint.dashboard.getRecentAlerts.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    getAccomplishments: function(payload) {
        return request({
            method: endpoint.dashboard.getAccomplishments.method,
            url: endpoint.dashboard.getAccomplishments.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    searchAlertsOrAccomplishment: function(payload) {
        return request({
            method: endpoint.dashboard.searchAlertsOrAccomplishment.method,
            url: endpoint.dashboard.searchAlertsOrAccomplishment.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    getSuperiorData: function(payload) {
        return request({
            method: endpoint.dashboard.getSuperiorData.method,
            url: endpoint.dashboard.getSuperiorData.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    getFilterData: function(payload) {
        return request({
            method: endpoint.dashboard.getFilterData.method,
            url: endpoint.dashboard.getFilterData.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    getRTOSummary: function(payload) {
        return request({
            method: endpoint.dashboard.getRTOSummary.method,
            url: endpoint.dashboard.getRTOSummary.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    getUnreadMessages: function(payload) {
        return request({
            method: endpoint.dashboard.getUnreadMessages.method,
            url: endpoint.dashboard.getUnreadMessages.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    }
};

export default DashboardServices;
