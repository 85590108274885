import React from 'react';

export class CopyLevelDropdownRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const recordType = this.props.data && this.props.data.dataLevel ? this.props.data.dataLevel : null; // Checks the datalevel for validation
        const copyLevel = this.props.value ? this.props.value : 'Color'; // Holds the current value or defaults
        if (this.props.data.childCount) {
            return (
                <div>
                    {this.props.value}
                </div>
            );
        } else {
            if (recordType == 'SINGLE' || recordType == null) {
                return null;
            } else {
                return (
                    <div>
                        {copyLevel}
                    </div>
                );
            }
        }
    }
}


// /////////////////////////////////////////////////////////////////////////////////////////////

export class CopyLevelDropdownEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedVal: '',
            copyLevelList: ['Style', 'Color', 'Size'],
        };
        this.handleChange = this.handleChange.bind(this);
    }

    /**
     * Gets the selected value when the component is going to mount
     */
    componentWillMount() {
        this.setState({ selectedVal: this.props.node.data.copyLevel || 'Please Select' });
    }

    /**
     * Stops editing of the cell when the component unmounts
     */
    componentWillUnmount() {
        this.props.api.stopEditing();
    }

    /**
     * Gets the value of the dropdown selected and renders
     * @return {string} cell value
     */
    getValue() {
        return this.state.selectedVal;
    }

    /**
     * Handles a change in the dropdown value and sets the selected value
     * @param {*} event Event from the dropdown
     */
    handleChange(event) {
        const rowData = this.props.node.data;
        rowData['copyLevel'] = event.target.value;

        const _this = this;
        const childrenIds = this.props.node.data.childrenIds;

        this.props.api.forEachNode(function (row, index) {
            if (index == _this.props.rowIndex) {
                if (event.target.value == 'Style') {
                    row.data.keywords = '';
                    row.data.copywrite = ''; // swatchOnly field takes just 15 characters, this value is referenced in sampleOption.js
                } else {
                    row.data.keywords = '';
                    row.data.copywrite = '';
                }
            }

            // updating the intermediates of the parent
            if (childrenIds.includes(row.data.intProdId)) {
                row.data.copyLevel = event.target.value;
                if (event.target.value == 'Color') {
                    row.data.keywords = '';
                    row.data.copywrite = '';
                } else {
                    row.data.keywords = '';
                    row.data.copywrite = '';
                }

                // getting children of the intermediates
                const intChildrenIds = row.data.childrenIds;
                _this.props.api.forEachNode(function (introw) {
                    if (intChildrenIds.includes(introw.data.chldProdId)) {
                        introw.data.copyLevel = event.target.value;

                        if (event.target.value == 'Size') {
                            introw.data.keywords = '';
                            introw.data.copywrite = '';
                        } else {
                            introw.data.keywords = '';
                            introw.data.copywrite = '';
                        }
                    }
                });
            }
        });
        this.setState({ selectedVal: event.target.value });
        this.props.rowUpdate(rowData, this.props.node.rowIndex);

        // adding refreshCells here to rerender the displayLevel cells to see the updated value
        this.props.api.refreshCells();
    }


    render() {
        const recordType = this.props.node.data && this.props.node.data.dataLevel ? this.props.node.data.dataLevel : null; // Checks the displayLevel for validation

        // Returns the data options for the dropdown to render
        const copyLevelList = this.state.copyLevelList.map((val, index) => {
            return <option key={index} value={val}>{val}</option>;
        });

        if (recordType == 'SINGLE' || recordType == null) {
            return null;
        } else if (recordType == 'INTERMEDIATE' || recordType == 'CHILD') {
            return (
                <div>
                    {
                        this.props.node.data && this.props.node.data.copyLevel &&
                        <div>
                            {this.props.node.data.copyLevel}
                        </div>
                    }
                </div>
            );
        } else {
            if (this.props.node.data && (this.props.node.data.itemReadyToOrder == true)) {
                return (
                    <div>
                        {
                            this.props.node.data && this.props.node.data.copyLevel &&
                            <div>
                                {this.props.node.data.copyLevel}
                            </div>
                        }
                    </div>
                );
            } else {
                return (
                    <div>
                        {
                            this.props.node.data && this.props.node.data.parentProductId &&
                            <select style={{ 'width': '100%' }} onChange={this.handleChange.bind(this)} value={this.state.selectedVal}>
                                <option disabled>Please Select</option>
                                {copyLevelList}
                            </select>
                        }
                    </div>
                );
            }
        }
    }
}
