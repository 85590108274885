import React from 'react';

export class DropdownInputs extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            dropdownList: props.values,
            showDropdown: false,
            searchedValues: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.searchText = this.searchText.bind(this);
    }

    componentWillMount() {
    }

    searchText(event) {
        const searchArr = [];
        const value = event.target.value.toLowerCase();

        this.state.dropdownList.forEach(function (row) {
            if ((row).toLowerCase().indexOf(value) != -1) {
                searchArr.push(row);
            }
        });

        this.setState({ searchedValues: searchArr });
    }

    handleChange(e) {
        const value = e.target.id;
        const colId = this.props.data.data.field;

        let obj = {};
        obj['value'] = value;
        obj['col'] = colId;

        this.props.update(obj);
        this.props.close();
    }

    render() {
        let searched = this.state.searchedValues.length > 0 ? this.state.searchedValues : this.state.dropdownList;

        const dropdownListValues = searched.map((i, x) => {
            if (i == this.props.selected) {
                return <li className="agCustomDropdownItems agCustomDropdownSelected" key={x} id={i} onClick={this.handleChange}>{i}</li>;
            } else {
                return <li className="agCustomDropdownItems" key={x} id={i} onClick={this.handleChange}>{i}</li>;
            }
        });

        return (
            <div>
                <div
                    className="ag-DropdownSearchContainer"
                    style={{ width: this.props.width }}
                >
                    {this.state.dropdownList.length > 5 &&
                    <input type="text" className="ag-DropdownSearchInput" placeholder="Search.." onChange={this.searchText}></input>
                    }
                    <ul style={{ width: '100%' }} className="agCustomDropdown">
                        {dropdownListValues}
                    </ul>
                </div>
            </div>
        );
    }
}
