import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/marketingActions';
import Modal from '../../modals/common-modal.js';
import ConfirmModal from '../../modals/confirm-modal.js';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const CancelModalForPhotoComponent = ({
    state,
    props,
    closeConfirmModal,
}) => {
    return (
        <ConfirmModal isOpen={props.isOpen}>
            <div className="modal-content">
                <h3 className="confirm-warning-text">Do you want to Save Changes?</h3>
                <div className="modal-footer">
                    <input type="button" className="gray-button" value="Yes" onClick={() => closeConfirmModal('yes')}></input>
                    <input type="button" className="gray-button" value="No" onClick={() => closeConfirmModal('no')}></input>
                    <input type="button" className="gray-button" value="Cancel" onClick={() => closeConfirmModal('cancel')}></input>
                </div>
            </div>
        </ConfirmModal>
    );
};

CancelModalForPhotoComponent.propTypes = {
    closeConfirmModal: PropTypes.func,
};

export default withRouter (CancelModalForPhotoComponent);
