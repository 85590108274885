import React from 'react';
import LeftDrawerComponent from './leftDrawerComponent';
import axios from 'axios';
import NotificationManager from '../common/components/notification';

import * as actions from '../product/redux/productActions';
import * as db_actions from '../dashboard/redux/dashboardActions';
import * as sample_actions from '../samples/redux/samplesActions';
import * as product2_actions from '../product2/redux/product2Actions';
import * as m_actions from '../marketing/redux/marketingActions';
import * as creative_actions from '../creative/redux/creativeActions';

import { updateView } from '../admin/redux/adminActions';

import { connect } from 'react-redux';

import '../leftDrawer/leftDrawer.less';
import { ProductServices } from '../product2/services/product-services';
import request from '../common/REST/request';


class LeftDrawer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filterDetails: [],
            filterOperands: [],
            customFiltersValue: [],
            searchValues: { value: "" },
            showDeletedItems: false,
            isLoaded: true,
            criteriaLevelCount: 1,
            isFilterRemoved: false,
            searchFilterDropdownValue: null,
            searchFilterDropdownSearchedValues: [],
            selectedFilterName: {},
            selectedSearchName: null,
            toggleFieldDropdown: {},
            filterForSortingProductsGrid: {},
            product2GridDataState: {},
            toggleSearchDropdown: false,
            searchSearchDropdownValue: null,
            searchSearchDropdownSearchedValues: [],
            showCopywritingGrid: false,
            copywritingGridData: [],
            selectedOperatorName: {},
            keepSearchVal: false,
            disableFilter: true,
            disableSearch: true,
        };

        this.applyFilter = this.applyFilter.bind(this);
        this.addCriteriaLevel = this.addCriteriaLevel.bind(this);
        this.changeOperatorValue = this.changeOperatorValue.bind(this);
        this.changeConnector = this.changeConnector.bind(this);
        this.handleCriteriaValue = this.handleCriteriaValue.bind(this);
        this.handelSearchValue = this.handelSearchValue.bind(this);
        this.changeSearchField = this.changeSearchField.bind(this);
        this.getProductCategory = this.getProductCategory.bind(this);
        this.getFilterOperand = this.getFilterOperand.bind(this);
        this.formatFilterData = this.formatFilterData.bind(this);
        this.showDeletedProduct = this.showDeletedProduct.bind(this);
        this.toggleLeftDrawer = this.toggleLeftDrawer.bind(this);
        this.searchFilterDropdown = this.searchFilterDropdown.bind(this);
        this.searchSearchDropdown = this.searchSearchDropdown.bind(this);
        this.toggleSearchDropdown = this.toggleSearchDropdown.bind(this);

        this.fieldDropdownOpen = this.fieldDropdownOpen.bind(this);
        this.fieldDropdownClose = this.fieldDropdownClose.bind(this);
        this.removeCopywritingGrid = this.removeCopywritingGrid.bind(this);
        this.keepSearchValue = this.keepSearchValue.bind(this);
        this.activeCategoryCheck = this.activeCategoryCheck.bind(this);
        this.applySearch = this.applySearch.bind(this);
    }

    componentWillMount() {
        if (localStorage.getItem('keepSearchValue')) {
            let searchData = JSON.parse(localStorage.getItem("keepSearchValue"));

            this.setState({
                keepSearchVal: searchData[0].keepSearchValue,
                searchValues: searchData[0].searchedValues,
                selectedSearchName: searchData[0].selectedSearchName,
                disableSearch: false,
            });
        }
    }

    componentDidMount() {
        var _this = this;

        if (this.props.currentPage == "product2" || this.props.currentPage == "product") {
            $(document).keypress(function (e) {
                $('.grid-empty-message').hide();

                if (e.which == 13) {
                    if ($('.drawer-content .tree-node-list').hasClass('selected') && (_this.props.leftDrawerPulled === true)) {
                        _this.applyFilter();
                    }

                    if ((_this.props.leftDrawerPulled === true) && ($('.drawer-content input[name="filterValue"]').val() != "") || ($('.drawer-content input[name="searchValue"]').val() != "")) {
                        _this.applyFilter();
                    }
                }
            });
        }

        $('#apply-filter').click(function () {
            $('.grid-empty-message').hide();
        });

        this.getProductCategory();

        // Gets operand data when a page is refreshed
        this.getFilterOperand(this.props.currentPage);

        // close the search dropdown when clicking anywhere - exclude the UL of the dropdown - suraj
        // added for the custom dropdown impliment

        $('.drawer, .right-content').on('click', function (e) {
            if ($(e.target).is(".leftDrawer-ul-input")) return;

            if ($('.leftDrawer-ul-input').next().hasClass("leftDrawer-ul-shown")) {
                _this.setState({ toggleFieldDropdown: {} });
                _this.hideSearchDropdown();
            }
        });

        $(".custom-filter").scroll(function () {
            if ($('.leftDrawer-ul-input').next().hasClass("leftDrawer-ul-shown")) {
                _this.setState({ toggleFieldDropdown: {} });
                _this.hideSearchDropdown();
            }
        });
    }

    componentWillReceiveProps(nextProps) {
        // to reset the filter when there is a page change
        if (nextProps.currentPage != this.props.currentPage) {
            this.setState({
                customFiltersValue: [],
                filterDetails: [],
                filterOperands: [],
                selectedFilterName: {}
            });

            this.getFilterOperand(nextProps.currentPage);
            this.getProductCategory();

            if (this.props.appliedFilters.catIds) {
                this.props.appliedFilters.catIds = [];
            }

            if (this.props.appliedFilters.catIdsObj) {
                this.props.appliedFilters.catIdsObj = {};
            }

            if (this.props.appliedFilters.department) {
                this.props.appliedFilters.department = [];
            }
        }

        // added so filter is reset and shows correct operand values
        if (nextProps.removeFilter.count != this.props.removeFilter.count && nextProps.removeFilter.count > 0 && nextProps.removeFilter.data.type === 'customFilters') {
            this.setState({
                selectedFilterName: {},
                selectedOperatorName: {},
                searchFilterDropdownValue: null
            });
        }

        if (nextProps.currentPage != this.props.currentPage && this.props.currentPage == "admin") {
            localStorage.setItem('currentAdminPage', 'user');
        }

        let searchData = JSON.parse(localStorage.getItem("keepSearchValue"));

        if (searchData && searchData[0].keepSearchValue && nextProps.currentPage != this.props.currentPage && nextProps.currentPage == 'product2') {
            setTimeout(() => {
                this.setState({
                    keepSearchVal: searchData[0].keepSearchValue,
                    searchValues: searchData[0].searchedValues,
                    selectedSearchName: searchData[0].selectedSearchName,
                    disableFilter: false,
                    disableSearch: false,
                });
            }, 3500);
        }
    }

    componentDidUpdate(prevProps, prevStates) {
        var _this = this;

        if (prevProps.removeFilter.count != this.props.removeFilter.count && !this.state.isFilterRemoved) {
            // Resetting custom filter values once removes the filter strips from product grid
            if (this.props.removeFilter.data.type === 'customFilters') {
                let fields = [], operators = [];

                this.state.filterOperands.map((val, index) => {
                    fields.push(this.state.filterOperands[index].displayName);
                });

                operators = this.state.filterOperands[0].operator;

                let customFilter = [];

                // update fieldtype
                let fieldType = 'text';

                if (operators[0] === 'Between') {
                    fieldType = 'date';
                }

                customFilter.push({
                    operands: this.state.filterOperands,
                    fields: fields,
                    operators: operators,
                    field: this.state.filterOperands[0].columnName,
                    operator: operators[0],
                    value: '',
                    fieldType: fieldType
                });

                // getting filters array value from tree-view component - Category ids
                let filtersData = this.treeView.refSelectedFilter();

                this.setState({ criteriaLevelCount: 1, customFiltersValue: customFilter, isFilterRemoved: true, filtersData: filtersData });
            } else if (this.props.removeFilter.data.type === 'catIds') {
                // getting filters array value from tree-view component - Category ids
                let filtersData = this.treeView.refSelectedFilter();

                filtersData.category.splice(this.props.removeFilter.data.position, 1);
                filtersData.department.splice(this.props.removeFilter.data.position, 1);

                this.setState({
                    filtersData: filtersData,
                    isFilterRemoved: true
                });
            }
        }

        if (!prevStates.isFilterRemoved && this.state.isFilterRemoved) {
            let { removeFilter, appliedFilters } = _this.props;

            if (removeFilter.data.type === "catIds") {
                let type = removeFilter.data.type;
                let position = removeFilter.data.position;
                let catId = appliedFilters[removeFilter.data.type][removeFilter.data.position];

                appliedFilters[type].splice(position, 1);

                delete appliedFilters.catIdsObj[catId];
            } else if (removeFilter.data.type === "customFilters") {
                appliedFilters[removeFilter.data.type] = [];
            }

            _this.props.appliedFiltersAction(appliedFilters);
            _this.setState({ isFilterRemoved: false });

            this.applyFilter(null, true);
        }

        if (!prevStates.keepSearchVal != this.state.keepSearchVal
            && this.state.keepSearchVal == true
            && !localStorage.getItem('keepSearchValue')
            && this.state.searchValues.value) {
            let newObj = [];

            newObj.push({
                'keepSearchValue': this.state.keepSearchVal,
                'searchedValues' : this.state.searchValues,
                'selectedSearchName': this.state.selectedSearchName
            });

            localStorage.setItem('keepSearchValue', JSON.stringify(newObj));
        }

        var dateFormat = "mm/dd/yy";

        var searchDate = $(".datepicker.searchDate").datepicker({
            defaultDate: "+1w",
            changeMonth: true,
            numberOfMonths: 1,
            dateFormat: dateFormat
        }).on("change", function () {
            toDate.datepicker("option", "minDate", _this.getDate(this));

            const target = this;
            const value = target.value;
            const name = target.name;
            const level = $(target).data('level');

            let { searchValues } = _this.state;
            let newSearchValues = searchValues;

            newSearchValues.value = value;

            _this.setState({ searchValues: newSearchValues });
        });

        var fromDate = $(".datepicker.fromdate").datepicker({
            defaultDate: "+1w",
            changeMonth: true,
            numberOfMonths: 1,
            dateFormat: dateFormat
        }).on("change", function () {
            toDate.datepicker("option", "minDate", _this.getDate(this));

            const target = this;
            const value = target.value;
            const name = target.name;
            const level = $(target).data('level');

            let { customFiltersValue } = _this.state;
            let customFilter = customFiltersValue;

            customFilter[level].value = value;

            _this.setState({ customFiltersValue: customFilter });
        });

        var toDate = $(".datepicker.todate").datepicker({
            defaultDate: "+1w",
            changeMonth: true,
            numberOfMonths: 1,
            dateFormat: dateFormat
        }).on("change", function () {
            fromDate.datepicker("option", "maxDate", _this.getDate(this));

            const target = this;
            const value = this.value;
            const name = target.name;
            const level = $(target).data('level');

            let { customFiltersValue } = _this.state;
            let customFilter = customFiltersValue;

            // Datefield to-date
            customFilter[level].value2 = value;

            _this.setState({ customFiltersValue: customFilter });
        });
    }

    toggleLeftDrawer() {
        this.props.toggleLeftDrawer();
    }

    removeCopywritingGrid() {
        this.setState({ showCopywritingGrid: false, copywritingGridData: [] });
    }

    /**
     * Checks if a category/sub-cat has been selected
     * Toggles the Save&Filter and Filter Buttons
     * @param {Array} params
     */
    activeCategoryCheck(data) {
        if (data && data.category.length > 0 || data.department.length > 0 || data.campaignType.length > 0) {
            this.setState({ disableFilter: false });
        } else {
            this.setState({ disableFilter: true });
        }
    }

    /**
     * Toggles the boolean for Keep Search Value Checkbox
     */
    keepSearchValue() {
        this.setState({ keepSearchVal: !this.state.keepSearchVal }, () => {

            if (this.state.keepSearchVal == false && localStorage.getItem('keepSearchValue')) {
                localStorage.removeItem('keepSearchValue');

                let filtersData = this.treeView.refSelectedFilter();

                this.setState({
                    disableSearch: true,
                    searchValues: { value: "" },
                    selectedSearchName: null,
                    disableFilter: filtersData ? false : true,
                });
            } else {

                let newObj = [];

                newObj.push({
                    'keepSearchValue': this.state.keepSearchVal,
                    'searchedValues': this.state.searchValues,
                    'selectedSearchName': this.state.selectedSearchName
                })

                localStorage.setItem('keepSearchValue', JSON.stringify(newObj));
            }
        });
    }

    getDate(element) {
        var dateFormat = "mm/dd/yy", date;

        try {
            date = $.datepicker.parseDate(dateFormat, element.value);
        } catch (error) {
            date = null;
        }

        return date;
    }

    getProductCategory() {
        // using new service for product search
        ProductServices.getProductCategories()
            .then((response) => {
                this.setState({ filterDetails: response, isLoaded: true });

                let filterData = response;

                filterData.map((val, key) => {
                    filterData[key].isTabActive = false;
                    filterData[key].isOpen = false;

                    if (filterData[key].productCategory.length > 0) {
                        filterData[key].hasChild = true;

                        this.formatFilterData(response, key);
                    } else {
                        filterData[key].hasChild = false;
                    }
                });
            })
            .catch(error => {
                console.log('leftDrawerContainer getProductCategory API call failed', error);

                this.setState({ isLoaded: false });
            });
    }

    getFilterOperand(currentPage) {
        let URL = '';

        switch (currentPage) {
            case 'layout':
            case 'dashboard':
            case 'product':
                URL = require('../common/configurations.js').product.listoperandsnoperators;
                break;

            case 'product2':
                URL = require('../common/configurations.js').product.listoperandsnoperators;
                break;

            case 'samples':
                URL = require('../common/configurations.js').product.listoperandsnoperators;
                break;

            case 'copywriting':
                URL = require('../common/configurations.js').product.listoperandsnoperators;
                break;

            case 'creative':
                URL = require('../common/configurations.js').creative.listoperandsnoperators;
                break;

            case 'marketing':
                URL = require('../common/configurations.js').marketing.listOfOperends;
                break;
        }

        if (URL) {
            let headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json',
            };

            axios({
                method: 'get',
                url: URL,
                headers: headers,
            })
                .then((response) => {
                    console.log('leftDrawerContainer getFilterOperand API call success data: ', response.data);

                    let customFilter = [];

                    let res = response.data;

                    let fields = [], operators = [];

                    res.map((val, index) => {
                        fields.push(res[index].displayName);
                    });

                    operators = res[0].operator;

                    // update fieldtype
                    var fieldType = 'text';

                    if (operators[0] === 'Between') {
                        fieldType = 'date';
                    }

                    customFilter.push({
                        operands: res,
                        connector: null,
                        fields: fields,
                        operators: operators,
                        field: res[0].columnName,
                        operator: operators[0],
                        value: '',
                        fieldType: fieldType,
                        isBlank: (operators[0].toLowerCase() === "is blank" || operators[0].toLowerCase() === "is not blank") ? true : false
                    });

                    // Update default Search field value
                    let { searchValues } = this.state;
                    let search = searchValues;
                    search.field = res[0].columnName;
                    search.fieldIndex = 0;
                    // End

                    this.setState({ filterOperands: res, customFiltersValue: customFilter, searchValues: search }, () => {
                        let filterForSortingProductsGrid = this.state.filterForSortingProductsGrid;
                        filterForSortingProductsGrid['customFilters'] = customFilter;

                        this.props.filterForSortingProductsGrid(filterForSortingProductsGrid);
                    });
                })
                .catch((error) => {
                    console.log('leftDrawerContainer getFilterOperand API call failed', error);
                });
        }
    }

    // re-structuring the filterdetails
    formatFilterData(data, l1, l2, l3) {
        let filterData = data;

        if (l1 != undefined && l2 === undefined) {
            filterData[l1].productCategory.map((val, key) => {
                filterData[l1].productCategory[key].isTabActive = false;
                filterData[l1].productCategory[key].isOpen = false;

                if (filterData[l1].productCategory[key].productCategory.length > 0) {
                    filterData[l1].productCategory[key].hasChild = true;

                    this.formatFilterData(data, l1, key);
                } else {
                    filterData[l1].productCategory[key].hasChild = false;
                }
            });
        } else if (l1 != null && l2 != null) {
            filterData[l1].productCategory[l2].productCategory.map((val, key) => {
                filterData[l1].productCategory[l2].productCategory[key].isTabActive = false;
                filterData[l1].productCategory[l2].productCategory[key].isOpen = false;

                if (filterData[l1].productCategory[l2].productCategory[key].productCategory.length > 0) {
                    filterData[l1].productCategory[l2].productCategory[key].hasChild = true;
                } else {
                    filterData[l1].productCategory[l2].productCategory[key].hasChild = false;
                }
            });
        }
    }

    /**
     * Method - updating showDeletedItem state by changing the status
     * @param {object} event - target element
     */
    showDeletedProduct(event) {
        this.setState({ showDeletedItems: !this.state.showDeletedItems });
    }

    /**
     * Update criteria count & push new level into state
     */
    addCriteriaLevel() {
        let { criteriaLevelCount, customFiltersValue } = this.state;

        // Level count increment
        let count = criteriaLevelCount + 1;

        // Pushing custom filter value into customFiltersValue

        let fields = [], operators = [];

        this.state.filterOperands.map((val, index) => {
            fields.push(this.state.filterOperands[index].displayName);
        });

        operators = this.state.filterOperands[0].operator;

        let customFilter = customFiltersValue;

        customFilter.push({
            operands: this.state.filterOperands,
            connector: 'And',
            fields: fields,
            operators: operators,
            field: this.state.filterOperands[0].columnName,
            operator: operators[0],
            value: ''
        });

        this.setState({
            criteriaLevelCount: count,
            customFiltersValue: customFilter
        });
    }

    // Onchange field value
    changeFiledValue(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value; // operand index
        const id = event.target.id;
        const level = event.target.dataset.level;
        const field = event.target.dataset.field;
        const type = event.target.dataset.type;
        const operator = event.target.dataset.operator;

        let { customFiltersValue } = this.state;
        let customFilter = customFiltersValue;
        let operatorArray = operator.split(',');

        customFilter[level].operators = operatorArray;
        customFilter[level].field = field;
        customFilter[level].fieldIndex = value;
        customFilter[level].operator = operatorArray[0];
        customFilter[level].operatorIndex = level;
        customFilter[level].fieldType = type;
        customFilter[level].isBlank = (this.state.filterOperands[value].operator[0].toLowerCase() === "is blank" || this.state.filterOperands[value].operator[0].toLowerCase() === "is not blank") ? true : false;
        customFilter[level].value = '';

        let selectedVales = this.state.selectedFilterName;
        selectedVales["selectedFiltername" + level] = id;

        // Destroy datepicker on change of operator
        $(".datepicker.fromdate, .datepicker.todate").datepicker("destroy");

        this.setState({
            customFiltersValue: customFilter,
            selectedFilterName: selectedVales,
            searchFilterDropdownSearchedValues: [],
            searchFilterDropdownValue: null,
        });

        this.fieldDropdownClose(level);
    }

    // Onchange operator value
    changeOperatorValue(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const level = $(target).data('level');

        let { customFiltersValue } = this.state;
        let customFilter = customFiltersValue;

        customFilter[level].operator = customFilter[level].operators[value];
        customFilter[level].operatorIndex = value;

        // Destroy datepicker on change of operator
        $(".datepicker.fromdate, .datepicker.todate").datepicker("destroy");

        if (customFilter[level].operators[value].toLowerCase() === "is blank" || customFilter[level].operators[value].toLowerCase() === "is not blank") {
            customFilter[level].isBlank = true;
        } else {
            customFilter[level].isBlank = false;
        }

        // added to set the selected operand value
        let selectedVales = this.state.selectedOperatorName;
        selectedVales["selectedOperatorName" + level] = value;

        this.setState({ customFiltersValue: customFilter });
    }

    handleCriteriaValue(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const level = $(target).data('level');

        let { customFiltersValue } = this.state;
        let customFilter = customFiltersValue;

        // Datefield for Number vals
        if (name === 'filterValue') {
            customFilter[level].value = value;
        }

        // Datefield to-date
        if (name === 'toDate') {
            customFilter[level].value2 = value;
        }

        // Datefield for Number vals
        if (name === 'filterValue2') {
            customFilter[level].value2 = value;
        }

        this.setState({ customFiltersValue: customFilter });
    }

    changeConnector(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const level = $(target).data('level');

        let { customFiltersValue } = this.state;
        let customFilter = customFiltersValue;

        customFilter[level].connector = value;
        this.setState({ customFiltersValue: customFilter });
    }

    handelSearchValue(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let { searchValues } = this.state;
        let search = searchValues;

        search.value = value;

        this.setState({ searchValues: search }, () => {
            if (this.state.selectedSearchName && value.length > 0) {
                this.setState({
                    disableSearch: false,
                    disableFilter: false,
                });
            } else {
                this.setState({
                    disableSearch: true,
                    disableFilter: false,
                });
            }
        });
    }

    changeSearchField(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const level = $(target).data('level');
        const id = event.target.id;
        const field = event.target.dataset.field;

        let { searchValues } = this.state;
        let search = searchValues;
        search.field = field;
        search.type = event.target.dataset.type;
        search.fieldIndex = value;

        // Destroy datepicker on change of operator
        $(".datepicker.searchDate").datepicker("destroy");

        this.setState({ searchValues: search, selectedSearchName: id }, () => {
            if (this.state.searchValues.value) {
                this.setState({
                    disableSearch: false,
                    disableFilter: false,
                });
            } else {
                this.setState({
                    disableSearch: true,
                    disableFilter: false,
                });
            }
        });

        this.toggleSearchDropdown();
    }

    /**
     * Reset filter object
     */
    resetFilter() {
        let { customFiltersValue } = this.state;
        let filterObject = customFiltersValue;

        filterObject[0].value = "";

        this.setState({ customFiltersValue, filterObject });
    }

    applySearch() {
        let req, req_url, filters = { userRole: this.props.user.defaultRole, deactivated: this.state.showDeletedItems };
        let searchValue = this.state.searchValues;
        let currentPage = this.props.currentPage;
        let _this = this;

        req = searchValue;
        req['catIds'] = [];

        console.log('going through search value: ', req);
        let field = req.field;
        let allFields = this.state.filterOperands;
        let allFieldsLength = allFields.length;

        let fieldType = '';

        for (let i = 0; i < allFieldsLength; i++) {
            if (allFields[i].columnName && allFields[i].columnName.includes(field)) {
                fieldType = allFields[i].type;

                break;
            }
        }

        req.fieldType = fieldType;
        req.type = 'search';
        req.deactivated = this.state.showDeletedItems;

        req_url = {
            product: require('../common/configurations.js').product.productsearch,
            creative: require('../common/configurations.js').creative.creativeSearch,
            publication: '',
            marketing: require('../common/configurations.js').marketing.searchValue,
            samples: require('../common/configurations.js').product.listSamples,
            product2: require('../common/configurations.js').product2.search,
        };

        if (currentPage === "dashboard") {
            utils.createCookie('productSearcClickUrl', JSON.stringify(req));

            window.location.href = "/product";

            return;
        }

        if (currentPage === "marketing") {
            if (req.field === "date_added") {
                let dateVal = req.value;

                dateVal = dateVal.replace(/\//g, "-");
                req.value = dateVal;
            }
        }

        if (currentPage === "product") {
            req.userRole = this.props.user.defaultRole;
        }

        // clears the search once api call is made
        if (!this.state.keepSearchVal) {
            setTimeout(() => {
                this.setState({
                    searchValues: { value: "" },
                    selectedSearchName: null,
                    disableSearch: true,
                });
            }, 5000);
        }

        this.props.toggleSpinner(true);

        if (this.props.leftDrawerPulled && this.props.currentPage != 'copywriting') {
            this.props.toggleLeftDrawer();
        }

        if (currentPage === 'samples' || currentPage === 'product2') {
            req['startRow'] = 0;
            req['endRow'] = 100000;
            req['recordCount'] = 0;
            req['paginateRequest'] = false;
        }

        if (currentPage === 'creative') {
            if (req.type == 'filter') {
                let filterVals = req.customFilters[0];
                let customFilters = [];
                const field = filterVals.field;
                let setField;

                this.state.filterOperands.forEach((i) => {
                    if (i.columnName == field) {
                        setField = i.graphName;
                    }
                });

                customFilters.push({
                    "field": setField,
                    "value": filterVals.value,
                    "fieldType": filterVals.fieldType,
                    "operator": filterVals.operator
                });

                delete req['catIds'];
                delete req['catIdsObj'];
                delete req['deactivated'];
                delete req['type'];
                delete req['userRole'];

                req['customFilters'] = customFilters;
            } else {
                let customFilters = [];
                let setField;
                const field = req.field;

                this.state.filterOperands.forEach((i) => {
                    if (i.columnName == field) {
                        setField = i.graphName;
                    }
                });

                customFilters.push({
                    "field": setField,
                    "value": req.value,
                    "fieldType": req.fieldType,
                    "operator": 'Contains'
                });

                delete req['deactivated'];
                delete req['catIds'];
                delete req['fieldIndex'];
                delete req['type'];

                req['customFilters'] = customFilters;
            }
        }

        if (localStorage.getItem('keepSearchValue')) {
            let newObj = [];
            newObj.push({
                'keepSearchValue': this.state.keepSearchVal,
                'searchedValues' : this.state.searchValues,
                'selectedSearchName': this.state.selectedSearchName
            });

            localStorage.setItem('keepSearchValue', JSON.stringify(newObj));
        }

        if (req_url[currentPage]) {
            return request({
                method: 'post',
                url: req_url[currentPage],
                data: req
            }).then((response) => {
                console.log('applyFilter response is: ', response.data);

                this.props.toggleSpinner(false);

                // Update state
                _this.setState({ isFilterRemoved: false, filtersData: null });

                if (currentPage === 'product2') {
                    // added to test samples grid data
                    let product2GridData = _this.state.product2GridDataState;
                    product2GridData['data'] = response.data.responseData.data;
                    product2GridData['recordCount'] = response.data.responseData.recordCount;
                    product2GridData['req'] = req;
                    product2GridData['catIds'] = response.data.responseData.catIds;
                    product2GridData['customFilters'] = _this.state.customFiltersValue;
                    product2GridData['fromLeftDrawer'] = true;
                    product2GridData['filterOperands'] = _this.state.filterOperands;

                    _this.props.addProduct2GridData(product2GridData);
                    _this.props.actionPerformedFrom({LeftPanel: true});

                    let req_obj = Object.assign({}, req);

                    _this.props.appliedFiltersAction(req_obj);
                    _this.props.filterDataChanged();

                    if (response.data.responseData.recordCount == 0 && response.data.responseData.data.length == 0) {
                        NotificationManager({
                            message: 'No records found.',
                            type: 4,
                        });
                    }

                    return;
                }

                if (currentPage === 'samples') {
                    console.log('response for samples', response);

                    _this.props.addSamplesGridData(response.data.responseData);

                    if (response.data.responseData.recordCount == 0 && response.data.responseData.data.length == 0) {
                        NotificationManager({
                            message: 'No records found.',
                            type: 4,
                        });
                    }

                    _this.props.filterDataChanged();

                    return;
                }

                if (currentPage === 'copywriting') {
                    console.log('response for copywriting', response);

                    const data = response.data.responseData;

                    _this.setState({
                        copywritingGridData: data,
                        showCopywritingGrid: true
                    });

                    return;
                }

                if (currentPage === 'dashboard') {
                    let dashboardReq = Object.assign({}, req);

                    _this.props.addDashboardPAData(response.data.responseData.healthTab);
                    _this.props.appliedFiltersAction(dashboardReq);
                    _this.props.filterDataChanged();

                    return;
                }

                if (currentPage === 'marketing') {
                    _this.props.addMarketingGridData(response.data.data);
                    _this.props.addMarketingColumnDetails(response.data.columnDetails);

                    return;
                }

                if (currentPage === 'creative') {
                    let obj = {};
                    obj['data'] = response.data.responseData;
                    obj['fromLeftPanel'] = true;

                    _this.props.addCreativeGridData(obj);

                    return;
                }
            }).catch(error => {
                console.log('leftDrawerContainer applyFilter  API call failed', error);

                NotificationManager({
                    message: 'Error, Service failed to get products',
                    type: 4,
                });

                this.props.toggleSpinner(true);
            });
        }
    }

    /*
     * Method - Apply Filter
     * @param {object} event - target element
     * @param {boolean} isSave - whether to save the serach or not
     */
    applyFilter(e, afterRemoveFilter, isSave) {
        let _this = this;
        let req, req_url, filters = { userRole: this.props.user.defaultRole, deactivated: this.state.showDeletedItems };

        let currentPage = this.props.currentPage;

        let filtersData = this.treeView ? this.treeView.refSelectedFilter() : null;

        if (this.state.filtersData) {
            filtersData = this.state.filtersData;
        }

        if (['product', 'dashboard', 'samples', 'product2', 'copywriting'].indexOf(currentPage) > -1) { // this works only if pageType is 'product'
            let categoryArray = filtersData.category;

            let channelsArray = filtersData.channels;
            let newChannelsArray = [];

            if (channelsArray && channelsArray.length > 0) {
                let channelsArrayLength = channelsArray.length;

                for (let i = 0; i < channelsArrayLength; i++) {
                    if (categoryArray.indexOf(channelsArray[i]) != -1) {
                        newChannelsArray.push(channelsArray[i]);
                    }
                }
            }

            filters['catIds'] = filtersData.category;
            filters['campaignType'] = filtersData.campaignType;
            filters['channels'] = newChannelsArray;
            filters['department'] = filtersData.department;
            filters['seasons'] = filtersData.seasons;
            filters['catIdsObj'] = filtersData.catIdsObj;
        } else {
            filters['catIds'] = [];
            filters['catIdsObj'] = [];
        }

        filters['customFilters'] = []; // custome filters value

        this.state.customFiltersValue.map((val, map) => {
            if (val.value2) { // For date format
                if (val.value != "" || val.value2 != "") {
                    filters.customFilters.push(val);
                }
            } else {
                if (val.value != "" || val.isBlank) {
                    filters.customFilters.push(val);
                }
            }
        });

        // If filters and custom filters are not selected
        if (!afterRemoveFilter) {
            if (filters.catIds.length <= 0 && filters.customFilters.length <= 0 && this.state.searchValues.value === "") {
                let errormsg = 'Please select a filter or search option, and then click Apply.';

                NotificationManager({
                    message: errormsg,
                    type: 4,
                });

                return false;
            }
        }

        if (currentPage === "dashboard") {
            var userId = this.props.user.userId;

            if (this.props.user.userId) {
                userId = this.props.user.userId;
            }

            req = {
                id: userId,
                catIds: filters.catIds,
                seasonIds: filters.seasons,
                channelIds: filters.channels
            };
        } else {
            req = filters;
            req.type = 'filter';
        }

        req_url = {
            product: require('../common/configurations.js').product.filters,
            creative: require('../common/configurations.js').creative.creativeSearch,
            dashboard: require('../common/configurations.js').dashboard.priorityAlerts,
            publication: '',
            marketing: require('../common/configurations.js').marketing.customFilter,
            samples: require('../common/configurations.js').product.listSamples,
            product2: require('../common/configurations.js').product2.search,
            copywriting: require('../common/configurations.js').product2.search,
        };

        this.props.toggleSpinner(true);

        if (this.props.leftDrawerPulled && this.props.currentPage != 'copywriting') {
            this.props.toggleLeftDrawer();
        }

        if (currentPage === 'samples' || currentPage === 'product2') {
            req['startRow'] = 0;
            req['endRow'] = 100000;
            req['recordCount'] = 0;
            req['paginateRequest'] = false;
        }

        if (currentPage === 'creative') {
            if (req.type == 'filter') {
                let filterVals = req.customFilters[0];
                let customFilters = [];
                const field = filterVals.field;
                let setField;

                this.state.filterOperands.forEach((i) => {
                    if (i.columnName == field) {
                        setField = i.graphName;
                    }
                });

                customFilters.push({
                    "field": setField,
                    "value": filterVals.value,
                    "fieldType": filterVals.fieldType,
                    "operator": filterVals.operator
                });

                delete req['catIds'];
                delete req['catIdsObj'];
                delete req['deactivated'];
                delete req['type'];
                delete req['userRole'];

                req['customFilters'] = customFilters;
            } else {
                let customFilters = [];
                let setField;
                const field = req.field;

                this.state.filterOperands.forEach((i) => {
                    if (i.columnName == field) {
                        setField = i.graphName;
                    }
                });

                customFilters.push({
                    "field": setField,
                    "value": req.value,
                    "fieldType": req.fieldType,
                    "operator": 'Contains'
                });

                delete req['deactivated'];
                delete req['catIds'];
                delete req['fieldIndex'];
                delete req['type'];

                req['customFilters'] = customFilters;
            }
        }

        if (req_url[currentPage]) {
            return request({
                method: 'post',
                url: req_url[currentPage],
                data: req
            }).then((response) => {
                console.log('applyFilter response is: ', response.data);

                this.props.toggleSpinner(false);

                // Update state
                _this.setState({ isFilterRemoved: false, filtersData: null });

                if (currentPage === 'product2') {
                    // added to test samples grid data
                    let product2GridData = _this.state.product2GridDataState;
                    product2GridData['data'] = response.data.responseData.data;
                    product2GridData['recordCount'] = response.data.responseData.recordCount;
                    product2GridData['req'] = req;
                    product2GridData['catIds'] = response.data.responseData.catIds;
                    product2GridData['customFilters'] = _this.state.customFiltersValue;
                    product2GridData['fromLeftDrawer'] = true;
                    product2GridData['filterOperands'] = _this.state.filterOperands;

                    _this.props.addProduct2GridData(product2GridData);
                    _this.props.actionPerformedFrom({LeftPanel: true});

                    let req_obj = Object.assign({}, req);

                    _this.props.appliedFiltersAction(req_obj);
                    _this.props.filterDataChanged();

                    if (response.data.responseData.recordCount == 0 && response.data.responseData.data.length == 0) {
                        NotificationManager({
                            message: 'No records found.',
                            type: 4,
                        });
                    } else if(isSave) {
                        // this block is to save the searched products request and reuse it when ever user visits products page
                        console.log('Found some product results, keep it in saved searches for ' + currentPage);

                        localStorage.setItem(currentPage + "_filters", JSON.stringify(req));
                    }

                    return;
                }

                if (currentPage === 'samples') {
                    console.log('response for samples', response);

                    _this.props.addSamplesGridData(response.data.responseData);

                    if (response.data.responseData.recordCount == 0 && response.data.responseData.data.length == 0) {
                        NotificationManager({
                            message: 'No records found.',
                            type: 4,
                        });
                    }

                    _this.props.filterDataChanged();

                    return;
                }

                if (currentPage === 'copywriting') {
                    console.log('response for copywriting', response);

                    const data = response.data.responseData;

                    _this.setState({
                        copywritingGridData: data,
                        showCopywritingGrid: true
                    });

                    return;
                }

                if (currentPage === 'dashboard') {
                    let dashboardReq = Object.assign({}, req);

                    _this.props.addDashboardPAData(response.data.responseData.healthTab);
                    _this.props.appliedFiltersAction(dashboardReq);
                    _this.props.filterDataChanged();

                    return;
                }

                if (currentPage === 'marketing') {
                    _this.props.addMarketingGridData(response.data.data);
                    _this.props.addMarketingColumnDetails(response.data.columnDetails);

                    return;
                }

                if (currentPage === 'creative') {
                    let obj = {};
                    obj['data'] = response.data.responseData;
                    obj['fromLeftPanel'] = true;

                    _this.props.addCreativeGridData(obj);

                    return;
                }
            }).catch(error => {
                console.log('leftDrawerContainer applyFilter  API call failed', error);

                NotificationManager({
                    message: 'Error, Service failed to get products',
                    type: 4,
                });

                this.props.toggleSpinner(true);
            });
        }
    }

    fieldDropdownOpen(event) {
        // added to position the dropdown under the input
        var bb = event.target.getBoundingClientRect();

        $('.filterFieldDropDown').css({ 'top': bb.top + 30 });

        let toggleObj = this.state.toggleFieldDropdown;
        toggleObj[event.target.id] = true;

        this.setState({ toggleFieldDropdown: toggleObj });
    }

    fieldDropdownClose(id) {
        let toggleObj = this.state.toggleFieldDropdown;
        let getID = "dropdownSelect" + id;

        toggleObj[getID] = false;

        this.setState({ toggleFieldDropdown: toggleObj });
    }

    toggleSearchDropdown() {
        this.setState({
            toggleSearchDropdown: !this.state.toggleSearchDropdown
        });
    }

    hideSearchDropdown() {
        this.setState({ toggleSearchDropdown: false });
    }

    searchFilterDropdown(event) {
        let filteredArray = [], value = event.target.value;
        let lCase = value.toLowerCase();

        this.state.filterOperands.forEach(function (row) {
            if ((row.displayName).toLowerCase().indexOf(lCase) != -1) {
                filteredArray.push(row);
            }
        });

        this.setState({
            searchFilterDropdownValue: value,
            searchFilterDropdownSearchedValues: filteredArray
        });
    }

    searchSearchDropdown(event) {
        let searchArray = [], value = event.target.value;
        let lCase = value.toLowerCase();

        this.state.filterOperands.forEach(function (row) {
            if ((row.displayName).toLowerCase().indexOf(lCase) != -1) {
                searchArray.push(row);
            }
        });

        this.setState({
            searchSearchDropdownValue: value,
            searchSearchDropdownSearchedValues: searchArray
        });
    }

    render() {
        let currentPage = this.props.currentPage;

        let currentUser = this.props.user;

        //added for new custom filter search - suraj
        let displaySearch = this.state.toggleSearchDropdown ? { 'display': 'block' } : { 'display': 'none' };

        let displaySearchDropdown = this.state.toggleSearchDropdown ? 'leftDrawer-ul-shown' : 'leftDrawer-ul';

        let displayBottomSearchDropdown = this.state.toggleSearchDropdown ? 'leftDrawer-bsul-shown' : 'leftDrawer-ul';

        let selectedSearchName = this.state.selectedSearchName ? this.state.selectedSearchName : 'Select Field';

        let displayDefaultSearch = this.state.toggleSearchDropdown ? { 'display': 'none' } : { 'display': 'block' };

        let operands = "";
        let searchOperands;

        if (currentPage === 'marketing') {
            searchOperands = this.state.filterOperands.map((val, index) => {
                return <option className="form-options leftDrawer-li" key={index} id={val.displayName} value={index} onClick={this.changeSearchField} >{val.displayName}</option>;
            });
        } else {
            let searchSearchCheck = this.state.searchSearchDropdownSearchedValues.length > 0 ? this.state.searchSearchDropdownSearchedValues : this.state.filterOperands;

            //Sorting the search param list alphabatic - SP-2354
            let names = [], newSearch = {};

            searchSearchCheck.forEach(function(v, i){
                let disName = v.displayName.replace(/\s/g, "_");

                newSearch[disName] = v;

                names.push(disName);
            });

            names.sort();

            searchOperands = names.map((v, index) => {
                let val = newSearch[v];

                return  <li className="form-options leftDrawer-li" key={index} id={val.displayName} value={index} data-type={val.type} data-field={val.columnName} onClick={this.changeSearchField} > {val.displayName} </li>;
            });
        }

        let levels = this.state.customFiltersValue.map((item, i) => {
            let displayDropdown = this.state.toggleFieldDropdown["dropdownSelect" + i] ? 'leftDrawer-ul-shown' : 'leftDrawer-ul';
            let displayFilter = this.state.toggleFieldDropdown["dropdownSelect" + i] ? { 'display': 'block' } : { 'display': 'none' };
            let displayDefaultBool = this.state.toggleFieldDropdown["dropdownSelect" + i] ? { 'display': 'none' } : { 'display': 'block' };
            let selectedFilterNameDisplay = this.state.selectedFilterName["selectedFiltername" + i] ? this.state.selectedFilterName["selectedFiltername" + i] : 'Select Field';
            let selectedOperatorDisplay = this.state.customFiltersValue[i].operatorIndex ? this.state.customFiltersValue[i].operatorIndex : this.state.selectedOperatorName["selectedOperatorName" + i];

            let filterSearchCheck = this.state.searchFilterDropdownSearchedValues.length > 0 ? this.state.searchFilterDropdownSearchedValues : this.state.filterOperands;

            //Sorting the search param list alphabatic
            let names = [], newSearch = {};

            filterSearchCheck.forEach(function(v, i){
                let disName = v.displayName.replace(/\s/g, "_");

                newSearch[disName] = v;

                names.push(disName);
            });

            names.sort();

            operands = names.map((v, index) => {
                let val = newSearch[v];

                if (this.state.customFiltersValue[i].fieldIndex == index) {
                    return <li className="form-options leftDrawer-li" key={index} id={val.displayName} value={index} data-field={val.columnName} data-type={val.type} data-operator={val.operator} data-level={i} onClick={this.changeFiledValue.bind(this)}>{val.displayName}</li>;
                } else {
                    return <li className="form-options leftDrawer-li" key={index} id={val.displayName} value={index} data-field={val.columnName} data-type={val.type} data-operator={val.operator} data-level={i} onClick={this.changeFiledValue.bind(this)}>{val.displayName}</li>;
                }
            });

            let operators = this.state.customFiltersValue[i].operators.map((opt, opt_idx) => {
                if (this.state.customFiltersValue[i].operator == opt) {
                    return <option className="form-options" key={opt_idx} value={opt_idx} >{opt}</option>;
                } else {
                    return <option className="form-options" key={opt_idx} value={opt_idx}>{opt}</option>;
                }
            });

            return (
                <div key={i} className="criteria-level">
                    {(i > 0) &&
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label className="form-label">Connector</label>
                                <select className="form-control" name="filterConnecter" data-level={i} onChange={this.changeConnector}>
                                    <option value="And">And</option>
                                    <option value="Or">Or</option>
                                    <option value="And Not">And Not</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    }

                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label className="form-label">Field</label>
                                <input type="text" className="leftDrawer-ul-input" placeholder="Search.." style={displayFilter} onChange={this.searchFilterDropdown}></input>
                                <ul className={"form-control clickControlDD filterFieldDropDown " + displayDropdown} name="filterField" data-level={i}>
                                    {operands}
                                </ul>

                                <div className="form-control showSelectedFilterCF" style={displayDefaultBool} id={"dropdownSelect" + i} onClick={this.fieldDropdownOpen}>{selectedFilterNameDisplay}</div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group">
                                <label className="form-label">Operator</label>
                                <select value={selectedOperatorDisplay} className="form-control" name="filterOperator" data-level={i} onChange={this.changeOperatorValue}>
                                    {operators}
                                </select>
                            </div>
                        </div>
                    </div>
                    {!item.isBlank &&
                        <React.Fragment>
                            {(item.operator.toLowerCase() === 'between') ?
                                <React.Fragment>
                                    {
                                        ['Date'].includes(item.fieldType) ?
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="form-label">From</label>
                                                        <input type="text" className="form-control datepicker fromdate" name="fromDate" value={this.state.customFiltersValue[i].value} data-level={i}></input>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="form-label">To</label>
                                                        <input type="text" className="form-control datepicker todate" name="toDate" value={this.state.customFiltersValue[i].value2} data-level={i}></input>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="form-label">From</label>
                                                        <input type="text" className="form-control" name="filterValue" value={this.state.customFiltersValue[i].value} data-level={i} onChange={this.handleCriteriaValue}></input>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="form-label">To</label>
                                                        <input type="text" className="form-control" name="filterValue2" value={this.state.customFiltersValue[i].value2} data-level={i} onChange={this.handleCriteriaValue}></input>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </React.Fragment>
                                :
                                <div className="row">
                                    {
                                        [
                                            ['Date'].includes(item.fieldType) ?
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Value</label>
                                                        <input type="text" className="form-control datepicker fromdate" name="fromDate" value={this.state.customFiltersValue[i].value} data-level={i}></input>
                                                    </div>
                                                </div>
                                                :
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Value</label>
                                                        <input type="text" className="form-control" name="filterValue" value={this.state.customFiltersValue[i].value} data-level={i} onChange={this.handleCriteriaValue}></input>
                                                    </div>
                                                </div>
                                        ]
                                    }
                                </div>
                            }
                        </React.Fragment>
                    }
                </div>
            );
        });

        let adminLeftMenu = [
            {
                title: "Users",
                isToggle: false,
                content: "",
                isCollapsed: false,
                isTabActive: true,
                menuId: 'user',
                accordionOnly: false
            },
            {
                title: "User Groups",
                isToggle: true,
                content: [
                    {
                        title: "- User Views",
                        menuId: "user_group"
                    },
                    {
                        title: "- Modules",
                        menuId: "user_modules"
                    }
                ],
                isCollapsed: false,
                isTabActive: false,
                menuId: 'user_group',
                accordionOnly: false
            },
            {
                title: "Fields",
                isToggle: true,
                isCollapsed: false,
                isTabActive: false,
                menuId: 'fields_records',
                content: [
                    {
                        title: "- Fields Permissions",
                        menuId: "fields_records"
                    },
                    {
                        title: "- Required fields",
                        menuId: "mandatory_records"
                    }
                ],
                accordionOnly: false
            },
            {
                title: "Rules & Alerts",
                isToggle: true,
                isCollapsed: false,
                isTabActive: false,
                menuId: 'rules_alerts',
                content: [
                    {
                        title: "- Rules",
                        menuId: "admin_rules"
                    },
                    {
                        title: "- Alerts",
                        menuId: "admin_alerts"
                    }
                ],
                accordionOnly: false
            },
            {
                title: "Product Categories",
                isToggle: true,
                content: [],
                isCollapsed: false,
                isTabActive: false,
                menuId: 'product_category',
                accordionOnly: false
            },
            {
                title: "Vendor",
                isToggle: false,
                content: [],
                isCollapsed: false,
                isTabActive: false,
                menuId: 'vendor',
                accordionOnly: false
            },
            {
                title: "Drop downs",
                isToggle: false,
                content: [],
                isCollapsed: false,
                isTabActive: false,
                menuId: 'dropdowns',
                accordionOnly: false
            },
            {
                title: "Settings",
                isToggle: true,
                isCollapsed: false,
                isTabActive: false,
                menuId: 'user_fields',
                accordionOnly: false,
                content: [
                    {
                        title: "User Fields",
                        menuId: "user_fields"
                    },
                    {
                        title: "Field Limits",
                        menuId: "field_length"
                    },
                    {
                        title: "Email Templates",
                        menuId: "email_templates"
                    },
                    {
                        title: "Job Setting",
                        menuId: "cron_job_setting"
                    }
                ]
            },
            {
                title: "Audit Log",
                isToggle: false,
                content: [],
                isCollapsed: false,
                isTabActive: false,
                menuId: 'audit_log',
                accordionOnly: false
            },
        ];

        // Using this to set specific admin menu
        // for rockler based on the users company
        // if company is not sweft they are denined
        // access to certain menu items.
        if (currentUser?.company?.toLowerCase() != 'sweft') {
            const filterArr = [
                'product_category',
                'vendor',
                'dropdowns',
                'user_group',
                'user',
                'fields_records',
                'rules_alerts'
            ];

            let filtered = adminLeftMenu.filter((i) => filterArr.includes(i.menuId));

            filtered.map((j) => {
                let content = j.content;
                let filteredSubMenu = content && content.filter((j) => ['user_group', 'admin_alerts', 'fields_records', 'mandatory_records'].includes(j.menuId));

                content = filteredSubMenu;

                j.content = content;
            });

            adminLeftMenu = filtered;
        }

        return (
            <LeftDrawerComponent
                state={this.state}
                props={this.props}

                adminLeftMenu={adminLeftMenu}
                toggleLeftDrawer={this.toggleLeftDrawer}
                currentPage={currentPage}
                levels={levels}
                searchOperands={searchOperands}
                applyFilter={this.applyFilter}
                changeSearchField={this.changeSearchField}
                handelSearchValue={this.handelSearchValue}
                addCriteriaLevel={this.addCriteriaLevel}
                showDeletedProduct={this.showDeletedProduct}
                treeViewRef={view => { this.treeView = view; }}

                removeCopywritingGrid={this.removeCopywritingGrid}

                displaySearch={displaySearch}
                displaySearchDropdown={displaySearchDropdown}
                displayBottomSearchDropdown={displayBottomSearchDropdown}
                searchSearchDropdown={this.searchSearchDropdown}
                displayDefaultSearch={displayDefaultSearch}
                toggleSearchDropdown={this.toggleSearchDropdown}
                selectedSearchName={selectedSearchName}
                hideSearchDropdown={this.hideSearchDropdown.bind(this)}
                fieldDropdownClose={this.fieldDropdownClose.bind(this)}
                keepSearchValue={this.keepSearchValue}
                activeCategoryCheck={this.activeCategoryCheck}
                applySearch={this.applySearch}
            />
        );
    }
}

const mapDispatchToprops = (dispatch) => {
    return {
        addGridData: (data) => {
            dispatch(actions.addGridData(data));
        },
        addColumnDetails: (data) => {
            dispatch(actions.addColumnDetails(data));
        },
        addGridDropDowns: (data) => {
            dispatch(actions.addGridDropDowns(data));
        },
        gridDataChanged: () => {
            dispatch(actions.gridDataChanged());
        },
        appliedFiltersAction: (data) => {
            dispatch(actions.appliedFilters(data));
        },
        addColumnDetails: (data) => {
            dispatch(actions.addColumnDetails(data));
        },
        updateView: data => {
            dispatch(updateView(data));
        },
        filterDataChanged: () => {
            dispatch(actions.filterDataChanged());
        },

        addMarketingColumnDetails: (data) => {
            dispatch(m_actions.addColumnDetails(data));
        },
        addMarketingGridData: (data) => {
            dispatch(m_actions.addGridData(data));
        },

        addDashboardPAData: (data) => {
            dispatch(db_actions.addDashboardPriorityAlertsData(data));
        },

        addSamplesGridData: (data) => {
            dispatch(sample_actions.addSamplesGridData(data));
        },

        addProduct2GridData: (data) => {
            dispatch(product2_actions.addProduct2GridData(data));
        },

        filterForSortingProductsGrid: (data) => {
            dispatch(product2_actions.filterForSortingProductsGrid(data));
        },

        addCreativeGridData: (data) => {
            dispatch(creative_actions.addCreativeGridData(data));
        },

        actionPerformedFrom: (data) => {
            dispatch(product2_actions.actionPerformedFrom(data));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        appliedFilters: state.appliedFilters,
        removeFilter: state.removeFilter
    };
};

export default connect(mapStateToProps, mapDispatchToprops)(LeftDrawer);
