import {endpoint} from '../../common/REST/endpoints';
import {headers} from '../../common/REST/headers';
import request from '../../common/REST/request';

let AdminServices = {
    getRoleList: function() {
        return request({
            method: endpoint.admin.getRoleList.method,
            url: endpoint.admin.getRoleList.url,
            headers: headers
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    getUserData: function(payload) {
        return request({
            method: endpoint.admin.getUserData.method,
            url: endpoint.admin.getUserData.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    insertUsersData: function(payload) {
        return request({
            method: endpoint.admin.insertUsersData.method,
            url: endpoint.admin.insertUsersData.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    deleteUserData: function(payload) {
        return request({
            method: endpoint.admin.deleteUserData.method,
            url: endpoint.admin.deleteUserData.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    addOrUpdateRule: function(payload) {
        return request({
            method: endpoint.admin.addOrUpdateRule.method,
            url: endpoint.admin.addOrUpdateRule.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    cloneRule: function(payload) {
        return request({
            method: endpoint.admin.cloneRule.method,
            url: endpoint.admin.cloneRule.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    pauseRule: function(payload) {
        return request({
            method: endpoint.admin.pauseRule.method,
            url: endpoint.admin.pauseRule.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    deleteRule: function(payload) {
        return request({
            method: endpoint.admin.deleteRule.method,
            url: endpoint.admin.deleteRule.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    resetPassword: function(payload) {
        return request({
            method: endpoint.admin.resetPassword.method,
            url: endpoint.admin.resetPassword.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    getUserMandatoryfields: function() {
        return request({
            method: endpoint.admin.getUserMandatoryfields.method,
            url: endpoint.admin.getUserMandatoryfields.url,
            headers: headers
        })
            .then((response) => {
                return response;
            })
            .catch(error => {
                return error;
            });
    },

    updateUserMandatoryfields: function(payload) {
        return request({
            method: endpoint.admin.updateUserMandatoryfields.method,
            url: endpoint.admin.updateUserMandatoryfields.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    dropdownList: function() {
        return request({
            method: endpoint.admin.dropdownList.method,
            url: endpoint.admin.dropdownList.url,
            headers: headers
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    getDropdownData: function(payload) {
        return request({
            method: endpoint.admin.getDropdownData.method,
            url: endpoint.admin.getDropdownData.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    saveOrUpdateDropdownData: function(payload) {
        return request({
            method: endpoint.admin.saveOrUpdateDropdownData.method,
            url: endpoint.admin.saveOrUpdateDropdownData.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    deleteDropdownData: function(payload) {
        return request({
            method: endpoint.admin.deleteDropdownData.method,
            url: endpoint.admin.deleteDropdownData.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    getAuditList: function() {
        return request({
            method: endpoint.admin.getAuditList.method,
            url: endpoint.admin.getAuditList.url,
            headers: headers
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    getAdminsettingsVal: function() {
        return request({
            method: endpoint.admin.getAdminsettingsVal.method,
            url: endpoint.admin.getAdminsettingsVal.url,
            headers: headers
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    updateAdminsettingsVal: function(payload) {
        return request({
            method: endpoint.admin.updateAdminsettingsVal.method,
            url: endpoint.admin.updateAdminsettingsVal.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    getUserGroupList: function() {
        return request({
            method: endpoint.admin.getUserGroupList.method,
            url: endpoint.admin.getUserGroupList.url,
            headers: headers
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    getAccomplishmentsList: function(payload) {
        return request({
            method: endpoint.admin.getAccomplishmentsList.method,
            url: endpoint.admin.getAccomplishmentsList.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    updateAccomplishmentsData: function(payload) {
        return request({
            method: endpoint.admin.updateAccomplishmentsData.method,
            url: endpoint.admin.updateAccomplishmentsData.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    getTemplateNames: function() {
        return request({
            method: endpoint.admin.getTemplateNames.method,
            url: endpoint.admin.getTemplateNames.url,
            headers: headers
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    saveTemplates: function(payload) {
        return request({
            method: endpoint.admin.saveTemplates.method,
            url: endpoint.admin.saveTemplates.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    getTemplate: function(payload) {
        return request({
            method: endpoint.admin.getTemplate.method,
            url: endpoint.admin.getTemplate.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    addField: function(payload) {
        return request({
            method: endpoint.admin.addField.method,
            url: endpoint.admin.addField.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    deleteField: function(payload) {
        return request({
            method: endpoint.admin.deleteField.method,
            url: endpoint.admin.deleteField.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    getJobData: function() {
        return request({
            method: endpoint.admin.getJobData.method,
            url: endpoint.admin.getJobData.url,
            headers: headers
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    updateJobData: function(payload) {
        return request({
            method: endpoint.admin.updateJobData.method,
            url: endpoint.admin.updateJobData.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    deleteFieldRoles: function(payload) {
        return request({
            method: endpoint.admin.deleteFieldRoles.method,
            url: endpoint.admin.deleteFieldRoles.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    // moved from user
    userList: function() {
        return request({
            method: endpoint.admin.userList.method,
            url: endpoint.admin.userList.url,
            headers: headers
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    insertOrUpdate: function(payload) {
        return request({
            method: endpoint.admin.insertOrUpdate.method,
            url: endpoint.admin.insertOrUpdate.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    getAlertList2: function(payload) {
        return request({
            method: endpoint.admin.getAlertList2.method,
            url: endpoint.admin.getAlertList2.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    addAlert2: function(payload) {
        return request({
            method: endpoint.admin.addAlert2.method,
            url: endpoint.admin.addAlert2.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    updateAlert2: function(payload) {
        return request({
            method: endpoint.admin.updateAlert2.method,
            url: endpoint.admin.updateAlert2.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    deleteAlert: function(payload) {
        return request({
            method: endpoint.admin.deleteAlert.method,
            url: endpoint.admin.deleteAlert.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    getRulesForAlerts: function() {
        return request({
            method: endpoint.admin.getRulesForAlerts.method,
            url: endpoint.admin.getRulesForAlerts.url,
            headers: headers
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    getRulesList: function() {
        return request({
            method: endpoint.admin.getRulesList.method,
            url: endpoint.admin.getRulesList.url,
            headers: headers
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    getRequiredFields: function() {
        return request({
            method: endpoint.admin.getRequiredFields.method,
            url: endpoint.admin.getRequiredFields.url,
            headers: headers
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    updateRequiredFields: function(payload) {
        return request({
            method: endpoint.admin.updateRequiredFields.method,
            url: endpoint.admin.updateRequiredFields.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    updateUserGlobalPermission: function(payload) {
        return request({
            method: endpoint.admin.updateUserGlobalPermission.method,
            url: endpoint.admin.updateUserGlobalPermission.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    getFieldPermissions: function() {
        return request({
            method: endpoint.admin.getFieldPermissions.method,
            url: endpoint.admin.getFieldPermissions.url,
            headers: headers
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    updateFieldPermissions: function(payload) {
        return request({
            method: endpoint.admin.updateFieldPermissions.method,
            url: endpoint.admin.updateFieldPermissions.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    getUserGlobalPermission: function() {
        return request({
            method: endpoint.admin.getUserGlobalPermission.method,
            url: endpoint.admin.getUserGlobalPermission.url,
            headers: headers
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    filterOperators: function() {
        return request({
            method: endpoint.admin.filterOperators.method,
            url: endpoint.admin.filterOperators.url,
            headers: headers
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    recentMessage: function() {
        return request({
            method: endpoint.admin.recentMessage.method,
            url: endpoint.admin.recentMessage.url,
            headers: headers
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    insertUserGroups: function(payload) {
        return request({
            method: endpoint.admin.insertUserGroups.method,
            url: endpoint.admin.insertUserGroups.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    getUserGroupsList: function() {
        return request({
            method: endpoint.admin.getUserGroupsList.method,
            url: endpoint.admin.getUserGroupsList.url,
            headers: headers
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    updateUserGroupStatus: function(payload) {
        return request({
            method: endpoint.admin.updateUserGroupStatus.method,
            url: endpoint.admin.updateUserGroupStatus.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    updateGroupPermissionList: function(payload) {
        return request({
            method: endpoint.admin.updateGroupPermissionList.method,
            url: endpoint.admin.updateGroupPermissionList.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    isUserMapped: function(payload) {
        return request({
            method: endpoint.admin.isUserMapped.method,
            url: endpoint.admin.isUserMapped.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    recentMessage: function() {
        return request({
            method: endpoint.admin.recentMessage.method,
            url: endpoint.admin.recentMessage.url,
            headers: headers
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
};

export default AdminServices;
