import axios from 'axios';
import {endpoint} from './endpoints';
import {headers} from './headers';

function request(option) {
    option.headers = option.headers || headers;

    return axios(option)
        .then((response) => {
            if (response.data && (response.data.status === 'success' || response.data.status)) {
                return response;
            } else if (!response.data || !response.data.status) {
                return response;
            } else {
                return Promise.reject(response);
            }
        })
        .catch(error => {
            return Promise.reject(error);
        });
}

export default request;
