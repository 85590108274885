export function cloneTheKeys(keys, cloneFrom) {
    const obj = {};

    keys.forEach(function(item, index) {
        if (cloneFrom.hasOwnProperty(item)) {
            obj[item] = cloneFrom[item];
        }
    });

    return obj;
}

/**	sets the cookie with the given name,value and no.of days
 * @param {string} name - cookie Name
 * @param {string} value - cookie value
 * @param {integer} days - cookie age in days
 */
export function createCookie(name, value, days) {
    let expires = '';

    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toGMTString();
    } else {
        expires = '';
    }

    document.cookie = name + '=' + value + expires + '; path=/';
}

/** retuns the cookie value if present else returns null
 * @param {string} name - cookie name
 */
export function getCookie(name) {
    let nameEQ = name + '=';
    let ca = document.cookie.split(';');

    for (let i = 0; i < ca.length ; i++) {
        let c = ca[i];

        while (c.charAt(0) == ' ') c = c.substring(1, c.length);

        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
}

/** deletes the cookie with provided name
 * @param {string} name
 */
export function deleteCookie(name) {
    return createCookie(name, '', -1);
}

/**
 * To specify the date with string padding
 * Date Ordinal (st, nd, rd, th)
 * @param {date} d
 */
export function nth(d) {
    if(d > 3 && d < 21) return d + 'th'; // thanks kennebec

    switch (d % 10) {
        case 1:  return d + 'st';
        case 2:  return d + 'nd';
        case 3:  return d + 'rd';
        default: return d + 'th';
    }
}

/**
 * in JQwidgets grid, when column reorder or column resize is enabled there is an issue
 * while hiding or showing a column.
 * Issue: column header is not working(not movable).
 * Solution: there are two header div's generated by jqwidgets hide the first div which will solve the
 * problem
 */

export function hideFirstGridHeader() {
    const headers = $('[id*="columntablejqxTreeGrid"]');

    if (headers.length > 1) {
        $.each(headers, function(index, item) {
            if (index < headers.length - 1) {
                $(headers[index]).hide();
            }
        });
    }
}

/**
 * TO the given url in new tab
 * @param {String} url
 */
export function openInNewTab(url) {
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
}

export function htmlEncode(value) {
    // create a in-memory div, set it's inner text(which jQuery automatically encodes)
    // then grab the encoded contents back out.  The div never exists on the page.
    return $('<div/>').text(value).html();
}

export function htmlDecode(value) {
    return $('<div/>').html(value).html();
}

export function findObjByKeyVal(arr, value) {
    let obj = null;

    for (let i = 0; i < arr.length; i++) {
        if (arr[i].fieldId == value) {
            obj = arr[i];
        }
    }

    if (obj) {
        return obj.fieldName;
    }

    return null;
}

/**
 *
 * @param {*} list
 * @param {*} idAttr
 * @param {*} parentAttr
 * @param {*} childrenAttr
 */
export function treeify(list, idAttr, parentAttr, childrenAttr) {
    let checboxFields = ['isPiReceived',
        'isPiApproved',
        'isPiRequestSent',
        'isSampleapproved',
        'isSamplerecieved',
        'deleted',
        'attrApproved',
        'readyToActivate',
        'isPiRequestEarly',
        'isResendPiRequest',
        'itemReadyToOrder',
        'accessToItemReady',
        'earlyReqSampleSwatch',
        'backOrder',
        'sampleMoved',
        'attrCompleted',
        'isRetoucherApproved',
        'isCopywriterApproved',
        'isWebproducerApproved'
    ];

    if (!idAttr) idAttr = 'id';
    if (!parentAttr) parentAttr = 'parent';
    if (!childrenAttr) childrenAttr = 'children';

    const treeList = [];
    const lookup = {};

    list.forEach(function(obj) {
        for (let key in obj.dynaData) {
            if (obj.dynaData.hasOwnProperty(key)) {
                obj[key] = obj.dynaData[key];
            }
        }

        lookup[obj[idAttr]] = obj;
        obj[childrenAttr] = [];
    });

    list.forEach(function(obj) {
        for (let i = 0 ; i < checboxFields.length ; i++) {
            if (obj[checboxFields[i]]) {
                obj[checboxFields[i]] = obj[checboxFields[i]].toString() != 'true' ? false : true;
            } else {
                obj[checboxFields[i]] = false;
            }
        }

        if (obj[parentAttr] != null && lookup[obj[parentAttr]]) {
            lookup[obj[parentAttr]][childrenAttr].push(obj);
        } else {
            treeList.push(obj);
        }
    });

    return treeList;
}

export function convertDataType(newvalue) {
    if (newvalue && typeof(newvalue) === 'string') {
        if (newvalue.toLowerCase() === 'none') {
            newvalue = false;
        } else if (newvalue === 'true') {
            newvalue = true;
        } else if (newvalue === 'false') {
            newvalue = false;
        }
    }

    return newvalue;
}

export function getParameterByName(url, name) {
    let newname = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + newname + '=([^&#]*)');
    const results = regex.exec(url);

    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export function updateParameterByName(url, keyvalue) {
    let separator = url.indexOf('?') !== -1 ? '&' : '?';
    let newUrl = url;

    $.each(keyvalue, function(key, value) {
        const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
        separator = url.indexOf('?') !== -1 ? '&' : '?';

        if (newUrl.match(re)) {
            newUrl = url.replace(re, '$1' + key + '=' + value + '$2');
        } else {
            newUrl = url + separator + key + '=' + value;
        }
    });

    return newUrl;
}

export function agGridKey () {
    return 'Q29tcGFueU5hbWU9U3dlZnQgTExDLExpY2Vuc2VkQXBwbGljYXRpb249U3dlZnQsTGljZW5zZVR5cGU9U2luZ2xlQXBwbGljYXRpb24sTGljZW5zZWRDb25jdXJyZW50RGV2ZWxvcGVyQ291bnQ9MSxMaWNlbnNlZFByb2R1Y3Rpb25JbnN0YW5jZXNDb3VudD0wLEFzc2V0UmVmZXJlbmNlPUFHLTAwOTc1OSxFeHBpcnlEYXRlPTEzX1NlcHRlbWJlcl8yMDIxX1t2Ml1fTVRZek1UUTROell3TURBd01BPT1hMjBmN2M0ZjM2NGM1NDc5MDhhNTAyYjM3MzRhZWJjOAo=';
}
