import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
import { agGridKey } from '../common/utils';
const getKey = agGridKey();
const agKey = atob(getKey);
LicenseManager.setLicenseKey(agKey);
import axios from 'axios';
import NotificationManager from '../common/components/notification';
import { connect } from 'react-redux';
import * as actions from '../product/redux/productActions';
import * as p2Actions from './redux/product2Actions';

import ProductOptions from './helperComponents/multiproduct/product-options';
import AddVendorForm from './helperComponents/vendor/add-vendor';
import { VendorDropdownPopUpRenderer, VendorDropdownPopUpEditor } from './helperComponents/vendor/vendor';
import { CopyWriteEditor, CopyWriteRenderer } from './helperComponents/copywrite/copywrite';
import AddCopyModel from './helperComponents/copywrite/add-copy-modal';
import { KeywordEditor, KeywordRenderer } from './helperComponents/keyword/keyword';
import AddKeywordModel from './helperComponents/keyword/add-keyword-modal';
import { SizeDropdownPopupEditor, SizeDropdownPopupRenderer } from './helperComponents/size';
import { ColorDropdownPopupRenderer, ColorDropdownPopupEditor } from './helperComponents/color';
import { BuyerDropdownRenderer, BuyerDropdownEditor } from './helperComponents/buyer';
import { DepartmentPopUpRenderer, DepartmentPopUpEditor } from './helperComponents/department/department';
import { DivisionRenderer } from './helperComponents/department/division';
import { ClassColumnPopUpRenderer, ClassColumnPopUpEditor } from './helperComponents/classColumn';
import { SubClassColumnPopUpRenderer, SubClassColumnPopUpEditor } from './helperComponents/subClassColumn';
import { DateRenderer, DateEditor } from './helperComponents/date';
import { CurrencyRenderer, CurrencyEditor } from './helperComponents/currency';
import { ImagePopupRenderer, ImagePopupEditor } from './helperComponents/image/image';
import { CheckboxRender } from './helperComponents/checkbox';
import { TextRender, TextEditor } from './helperComponents/text';
import { FeatureColorEditor, FeatureColorRenderer } from './helperComponents/featureColor';
import { ShotAngleEditor, ShotAngleRenderer } from './helperComponents/shotAngles/shotAngles';
import { AddPOEditor, AddPORenderer } from './helperComponents/addPo/addPo';
import { CopyLevelDropdownEditor, CopyLevelDropdownRenderer } from './helperComponents/copyLevel/copyLevel';
import { DisplayLevelDropdownEditor, DisplayLevelDropdownRenderer } from './helperComponents/displayLevel';
import { SampleOptionDropdownEditor, SampleOptionDropdownRender } from './helperComponents/sampleOption';
import { SamplesEditor, SamplesRender } from './helperComponents/samples/samples';
import { CampaignEditor, CampaignRender } from './helperComponents/campaign/campaign';
import ScanModal from '../modals/scan-modal';
import FilterStrip from './helperComponents/filter-strip';
import PiUploadModal from './helperComponents/piUploadModal';
import { SeasonsEditor, SeasonsRenderer } from './helperComponents/seasons';
import { PiUploadEditor, PiUploadRender } from './helperComponents/piUpload';
import { ChannelDropdownPopupRenderer, ChannelDropdownPopupEditor } from './helperComponents/channel';
import SwatchReferenceModal from './helperComponents/sampleReference/swatchReferenceModal';
import { SampleReferenceRender, SampleReferenceEditor } from './helperComponents/sampleReference/samplesReference';
import AddRecordsFromFiles from './helperComponents/addRecordsFromFiles';
import SamplePopup from './helperComponents/samples/sample-modal';
import './product2Container.less';
import { withRouter } from 'react-router-dom';
import CancelModal from './helperComponents/cancelModal';
import { RingLoader } from 'react-spinners';
import { FilterComponent } from './helperComponents/filterComponent';
import CustomHeader from './helperComponents/customHeader';
import { WestElmSeasonsEditor } from '../we-seasons/we-seasons';
import { BulkUpdatePopup } from './helperComponents/bulkUpdate/bulkUpdate';



import { ProductServices } from './services/product-services';
import { AttrDropdownEditor, AttrDropdownRenderer } from './helperComponents/attrDropdown';
import { MessageRender, MessageEditor } from './helperComponents/messages/message';
import { SingleViewRender, SingleViewEditor } from './helperComponents/singleView/singleView';
import { PhotoDirectionEditor, PhotoDirectionRender } from './helperComponents/photoDirection';
import { ProductTypeDropdownEditor, ProductTypeDropdownRenderer } from './helperComponents/productType/productType';
import { SetDetailEditor, SetDetailRenderer } from './helperComponents/setDetail/setDetail';
import { QuantityBreaksEditor, QuantityBreaksRenderer } from './helperComponents/quantityBreaks/quantityBreaks';
import { GenericDropdownEditor, GenericDropdownRenderer } from './helperComponents/genericDropdown';
import { NumbersEditor, NumbersRenderer } from './helperComponents/numbers';
import { GeneralFileUploadEditor, GeneralFileUploadRenderer } from './helperComponents/generalFileUpload';
import { UpperCaseEditor, UpperCaseRenderer } from './helperComponents/upperCase';
import fixtures from '../common/fixtures/productGridFixtures.json';
import { Prompt } from 'react-router-dom';
import NavigateAway from './helperComponents/navigateConfirm';
import { MultipleFileUploadEditor } from './helperComponents/multiFileUpload/multipleFileUploadEditor';
import { MultipleFileUploadRenderer } from './helperComponents/multiFileUpload/multipleFileUploadRender';

class Product2 extends React.Component {
    constructor(props) {
        super(props);

        props.setCurrentPage('product2');

        this.state = {

            error: null, errorInfo: null,
            data: [],       // to hold the grid data
            groupedData: [], // to hold the grouped data
            groupedIntData: [],
            groupedByColumn: null,
            savedGridData: null,
            aggregationState: {
                'retailPrice': 'avg',
                'onlinePrice': 'avg',
                'landedCost': 'avg',
            },
            aggregationCalled: false,

            gridParams: null,
            columnDefs: [],
            imageData: null,

            // below added to have the excel like look with vertical lines on the grid
            defaultColDef: {
                cellStyle: {
                    borderRight: '1px solid lightgrey',
                },
                cellStyle: function (params) {
                    if (params.data) {
                        if (params.data.isDeleted == true) {
                            return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                        }
                    }
                },
                headerClass: 'ag-HeaderCustomStyle',
                headerComponentParams: { menuIcon: "fa-bars" },
                sortable: false,
                resizable: true,
                filter: true
            },

            gridData: null,

            autoGroupColumnDef: {
                headerName: '',
                width: 40,
                cellRenderer: 'agGroupCellRenderer',
                pinned: 'left',
                lockPosition: true,
                cellRendererParams: {
                    // below is used so that the default group column values are
                    // empty else parentProductId,
                    // intProductId shows up along with the arrow
                    innerRenderer: function (params) {
                    },
                },
                cellStyle: {
                    border: 0,
                    paddingLeft: 5,
                    paddingTop: 2,
                },
            },

            frameworkComponents: { imagePopupRenderer: ImagePopupRenderer, FilterComponent: FilterComponent, agColumnHeader: CustomHeader }, //

            clickedOnAddRecord: false,

            selectedRow: null,  // to hold values of the row being edited or added

            selectedRows: [], // if using custom checkbox renderer

            addNewRow: false,

            newRowSaveWait: false, // new record data saved flag

            showMultiProduct: false,
            multiProductRow: null,
            addNewVendor: false,
            departmentPopup: false,
            copyWritePopup: false,
            keywordPopup: false,

            showViewBy: false, // for the footer section

            showBulkUpdateMenu: false,
            showBulkUpdatePopup: false,

            domLayout: 'normal', // options rae normal / autoHeight

            categoriesList: null,
            buyersList: null,
            channelsList: null,
            colorsList: null,
            sampleRecepientsList: null,
            seasonsList: null,
            sizeList: null,
            variantsList: null,
            vendorsList: null,

            overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Loading</span>',
            overlayNoRowsTemplate:
        '<span style="padding: 10px; font-size: 28px; font-weight:\'lighter\' ; color: #525E61;">Please Select filter or alert to proceed</span>',

            recordCount: null,          // coming from left drawer
            catIds: null,               // coming from leftdrawer
            customFilters: null,        // coming from left drawer
            seasons: null,               // coming from leftdrawer
            channels: null,               // coming from leftdrawer

            sortModel: null,

            selectedRowCount: 0,
            vendorRowData: null,

            cloneRecord: false,
            fieldsToClone: null,

            mandatoryFields: null,
            itemReadyToOrderFields: null,
            mpr2Approved: null,

            enableSaveRecordButton: false,

            piUploadRowData: null,

            swatchReferencePopup: false,

            importFilePopup: false,

            copyData: null,
            copyRowData: null,
            copyRowNode: null,

            keywordData: null,
            keywordRowData: null,
            keywordRowNode: null,

            // added to open samples popup when samples received is checked
            openSamplesPopup: false,
            samplePopupData: null,

            isConfirmModalOpen: false, // for delete confirm popup
            rowClass: 'default-row',
            rowHeight: 50,

            spinner: false,
            gridInGroupedMode: false,
            intmRowGroupIndexCheck: false,

            clipboardData: null,
            hideDefaultScroll: false,
            filterOperands: null,
            filterOpened: false,
            currentCol: null,
            listOperandAndOperator: null,
            isPasted: false,
            importModalType: '',
            fetchChildren: false,
            checkedRecord: false,
            dataQueue: [],
            saveInProgress: false,
            blockNav: false,
            toggleNavigateAway: false,
            startSort: false,
        };

        this.isMandatory = this.isMandatory.bind(this);
        this.mandatoryFieldsCheck = this.mandatoryFieldsCheck.bind(this);
        this.checkChildRecords = this.checkChildRecords.bind(this);
        this.checkParentRecord = this.checkParentRecord.bind(this);
        this.navigateAwayCheck = this.navigateAwayCheck.bind(this);
    }


    //----- This introduced to make a search call after the component is mounted and if saved query is available in local storage
    //----- 9/27/19 - Guna
    componentDidUpdate(prevProps, prevState) {
        let _this = this,
            checkForColDefs = this.props.columnDetails.length > 0 ? this.props.columnDetails : this.props.gridData.columnDetails,
            savedData = this.state.savedGridData,
            isFromAlertClick = this.props.sourceLink === "alertClick";

        if (savedData && savedData.gridData && checkForColDefs && !isFromAlertClick) {
            let req_obj = Object.assign({}, savedData.req);
            _this.props.addProduct2GridData(savedData.gridData);
            _this.props.filterDataChanged();
            _this.props.appliedFiltersAction(req_obj);
            _this.props.filterDataChanged();
            _this.props.toggleSpinner(false);
            _this.setState({
                savedGridData: null
            });
        }

        _this.onColumnPinned();
        setTimeout(function () {
            _this.onColumnPinned();
        }, 200);

        if (this.state.dataQueue.length > 0 && !this.state.saveInProgress && prevState.dataQueue[0].productDetails.productId == this.state.dataQueue[0].productDetails.productId) {
            this.saveProductData(this.state.dataQueue[0]);
        }

        if (prevState.dataQueue.length == 1 && this.state.dataQueue.length == 0 && prevState.saveInProgress) {
            this.setState({ blockNav: false });
            this.closeNavigateAway();
        }

        if (_this.props.gridData && _this.props.gridData.data) {
            let changeLeftPanel = this.props.actionPerformedFromState.LeftPanel && prevProps.actionPerformedFromState.LeftPanel != this.props.actionPerformedFromState.LeftPanel;
            let checkAddNewRow = prevState.addNewRow != _this.state.addNewRow && _this.state.addNewRow;
            let changeViews = this.props.actionPerformedFromState.changeViews && prevProps.actionPerformedFromState.changeViews != this.props.actionPerformedFromState.changeViews;
            let changeAlerts = this.props.actionPerformedFromState.fromAlerts && prevProps.actionPerformedFromState.fromAlerts != this.props.actionPerformedFromState.fromAlerts;
            let changeFields = this.props.actionPerformedFromState.changeFields && prevProps.actionPerformedFromState.changeFields != this.props.actionPerformedFromState.changeFields;
            let fromMessages = this.props.actionPerformedFromState.fromMessages && prevProps.actionPerformedFromState.fromMessages != this.props.actionPerformedFromState.fromMessages;
            let gridData = this.props.gridData.data;

            if (changeLeftPanel || changeViews || checkAddNewRow || changeFields || changeAlerts || fromMessages) {
                if (checkAddNewRow || changeViews || changeFields) {
                    let newArr = [];
                    _this.gridApi.forEachNode((i) => {
                        newArr.push(i.data);
                    });

                    gridData = newArr;
                }

                let intmExpand = false;

                if (this.props.spinner != prevProps.spinner && this.gridApi) {
                    this.gridApi.deselectAll();
                    if (prevProps.leftDrawerPulled) {
                        prevProps.toggleLeftDrawer();
                    }
                }

                const gridDataLength = gridData.length;
                for (let i = 0; i < gridDataLength; i++) {
                    gridData[i].productId = gridData[i].parentProductId || gridData[i].intProdId || gridData[i].chldProdId;
                }

                // added for intm custom filter search - makes expand of only intm records possible
                //10-18-19 - Guna - Adding proper error condition check
                if (this.props.gridData && this.props.gridData.customFilters && this.props.gridData.customFilters.length > 0) {
                    if (this.props.gridData.customFilters[0].field == 'productId') {
                        for (let i = 0; i < this.props.gridData.data.length; i++) {
                            if (this.props.gridData.data[i].dataLevel == 'INTERMEDIATE') {
                                this.setState({ intmRowGroupIndexCheck: true });
                                intmExpand = true;
                            }
                        }
                    }
                }

                let checkForColDefs = this.props.columnDetails.length > 0 ? this.props.columnDetails : this.props.gridData.columnDetails;
                let filteredColumns = checkForColDefs.filter(i => i.visibility);
                let columnDetails = this.colDefs(filteredColumns, intmExpand);

                const recordCount = this.props.gridData.recordCount;
                const catIds = this.props.gridData.catIds;
                const seasons = this.props.gridData && this.props.gridData.req && this.props.gridData.req.seasons
                    ? this.props.gridData.req.seasons : [];
                const channels = this.props.gridData && this.props.gridData.req && this.props.gridData.req.channels
                    ? this.props.gridData.req.channels : [];

                const customFilters = this.props.gridData.customFilters;
                const filterOperands = this.props.gridData.filterOperands;

                this.setState({
                    gridData: this.props.gridData,
                    data: gridData,
                    recordCount,
                    catIds,
                    seasons,
                    channels,
                    customFilters,
                    columnDefs: columnDetails,
                    hideDefaultScroll: false,
                    filterOperands: filterOperands,
                    listOperandAndOperator: this.props.filterForSortingProductsGrid?.customFilters[0].operands,
                }, () => {
                    if (changeLeftPanel || changeAlerts) {
                        _this.gridApi?.purgeServerSideCache();
                    }

                    if (changeViews) {
                        const getColState = _this.gridColumnApi.getColumnState();
                        let newCols = [];

                        getColState.map((i, x) => {
                            if (x == 0 || x == 1) {
                                newCols.push(i);
                            }

                            columnDetails.map((j) => {
                                if (i.colId == j.colId) {
                                    i['order'] = j.order;
                                    newCols.push(i);
                                }
                            });
                        });

                        newCols.sort(function (a, b) {
                            return a.order - b.order;
                        });

                        _this.gridColumnApi.setColumnState(newCols);
                    }

                    if (changeFields) {
                        let changedCol = _this.props.actionPerformedFromState.changeFields;

                        setTimeout(() => {
                            this.gridColumnApi.moveColumn(changedCol, columnDetails.length);
                        }, 1000);
                    }

                    _this.props.actionPerformedFrom([]);
                    _this.gridApi?.hideOverlay();
                    _this.gridApi?.deselectAll();

                    // using this as a fallback to allow columns to be moved
                    // when the grid loads or view is changed.
                    setTimeout(() => {
                        const getColState = _this.gridColumnApi.getColumnState();
                        _this.gridColumnApi.setColumnState(getColState);
                    }, 500);
                });
            }
        }
    }

    /**
   *gets all the dropdown data listed below which are used
   *all around in the products grid and its columns.
   *the second call is to get all the required or mandatory fields.
   *This is used for validation while adding a new record.
   *@param {void} there are no params
   *@return {void} nothing is returned
   */
    componentWillMount() {
        const config = require('../common/configurations.js');

        localStorage.setItem('currentPage', 'product2');
        if (!this.props.leftDrawerPulled) {
            this.props.toggleLeftDrawer();
        }

        ProductServices.getProductDropdown()
            .then((response) => {
                const res = response;

                if (res.status === 'success') {
                    const categoriesList = res.responseData.catId;
                    const buyersList = res.responseData.buyer;
                    const channelsList = res.responseData.channel;
                    const colorsList = res.responseData.color;
                    const sampleRecepientsList = res.responseData.sampleRecepient;
                    const seasonsList = res.responseData.season;
                    const sizeList = res.responseData.size;
                    const variantsList = res.responseData.variants;
                    const vendorsList = res.responseData.vendorId;
                    const attrDropDownValues = res.responseData;
                    const productTypes = res.responseData.productTypes;
                    const dispositionCode = res.responseData.dispositionCode;
                    const origin = res.responseData.origin;
                    const importerOfRecord = res.responseData.importerOfRecord;
                    const itemGroup = res.responseData.itemGroup;
                    const itemContainsMagnetOrLithiumBattery = res.responseData.itemContainsMagnetOrLithiumBattery;
                    const sellingUnitOfMeasure = res.responseData.sellingUnitOfMeasure;
                    const countryOfOrigin = res.responseData.countryOfOrigin;
                    const brand = res.responseData.brand;
                    const prop65Code = res.responseData.prop65Code;
                    const subBrand = res.responseData.subBrand;
                    const warrantyInfo = res.responseData.warrantyInfo;
                    const freightShippingCharge = res.responseData.freightShippingCharge;
                    const proprietary = res.responseData.proprietary;
                    const btbPercentage = res.responseData.btbPercentage;
                    const employeeDiscount = res.responseData.employeeDiscount;
                    const marketingLevel = res.responseData.marketingLevel;
                    const productStatus = res.responseData.productStatus;
                    const retailStatusCode = res.responseData.retailStatusCode;
                    const stageStatus = res.responseData.stageStatus;
                    const websiteNavigationCategory = res.responseData.websiteNavigationCategory;
                    const planner = res.responseData.planner;
                    const designerOrSourcing = res.responseData.designerOrSourcing;
                    const instructions = res.responseData.instructions;
                    const packagingTypes = res.responseData.packagingTypes;
                    const videoType = res.responseData.videoType;
                    const hazardousShippingMessage = res.responseData.hazardousShippingMessage;

                    this.setState({
                        categoriesList,
                        buyersList,
                        channelsList,
                        colorsList,
                        sampleRecepientsList,
                        seasonsList,
                        sizeList,
                        variantsList,
                        vendorsList,
                        attrDropDownValues,
                        productTypes,
                        dispositionCode,
                        importerOfRecord,
                        itemGroup,
                        itemContainsMagnetOrLithiumBattery,
                        origin,
                        sellingUnitOfMeasure,
                        countryOfOrigin,
                        brand,
                        prop65Code,
                        subBrand,
                        warrantyInfo,
                        freightShippingCharge,
                        proprietary,
                        btbPercentage,
                        employeeDiscount,
                        marketingLevel,
                        productStatus,
                        retailStatusCode,
                        stageStatus,
                        websiteNavigationCategory,
                        planner,
                        designerOrSourcing,
                        instructions,
                        packagingTypes,
                        videoType,
                        hazardousShippingMessage,
                    });
                } else {
                    NotificationManager({ message: res.message, type: 4 });
                }
            })
            .catch((error) => {
                console.log('Server Error Failed', error);
                NotificationManager({ message: error.message || "Unexpected error, Data didn't load", type: 4 });
            });

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        // to get list of fields to be cloned
        axios({
            method: 'get',
            url: require('../common/configurations.js').product.requiredFields,
            headers: headers,
        })
            .then((response) => {
                const res = response.data;

                const fieldsToClone = res.data;

                const mandatoryFields = fieldsToClone.filter(function (obj) {
                    return obj.reqdToAdd == true;
                });

                const itemReadyToOrderFields = fieldsToClone.filter(function (obj) {
                    return obj.readyToOrder == true;
                });

                const mpr2Approved = fieldsToClone.filter(function (obj) {
                    return obj.mpr2Approved == true;
                });

                this.setState({ fieldsToClone, mandatoryFields, itemReadyToOrderFields, mpr2Approved });
            })
            .catch((error) => {
                console.log('productContainer helperComponents footer API call failed', error);
            });

        //Guna - Temp fix for sample page to product page issue
        this.props.addProduct2GridData([]);

        /* check is saved filters are ther, if yes, make an api call and load them */
        if (localStorage.getItem('product2_filters')) {
            let _this = this,
                reqData = JSON.parse(localStorage.getItem("product2_filters")),
                endpoint = config.product2.search;

            _this.props.toggleSpinner(true);

            ProductServices.productSearch(reqData, "fromLeftDrawer", false).then((product2GridData) => {
                let savedDataObj = {
                    gridData: product2GridData,
                    req: reqData
                };
                _this.setState({ savedGridData: savedDataObj });
            }).catch(error => {
                console.error('Product search failed');
                NotificationManager({
                    message: 'Error, Service failed to get products',
                    type: 4,
                });
            });
        }
    }

    componentDidMount() {
    // stops the user from reloading the page
    // when data queue is processing
        window.onbeforeunload = function () {
            if (this.state.dataQueue.length > 0 && this.props.currentPage == 'product2') {
                return "";
            }
        }.bind(this);
    }

    /**
   * handles all the updates coming in from the left drawer to
   * modify the grid. This also resets certain state information based
   * on the new updates coming in from the left drawer or the dashboard.
   *
   * @param {object} nextProps nextprops is internally sent
   * @return {void} does not return anything but sets some state variables
   */

    /**
    * function checks if object is empty
    *
    * @param {object} obj obj is an object
    * @return {boolean} return a boolean
   */
    isEmpty(obj) {
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                return false;
            }
        }

        return true;
    }

    /**
   * used to find the graph name for the respective column
   * @param {string} key is a string
   * @return {object} return an object
   */
    findKeyGraphName(key) {
        if (key.localeCompare('color_attribute') == 0) {
            key = 'varientValue_color';
        }

        if (key.localeCompare('size_attribute') == 0) {
            key = 'varientValue_size';
        }

        if (key.localeCompare('season') == 0) {
            key = 'dropDownData.content';
        }

        if (key.localeCompare('channel') == 0) {
            key = 'content';
        }

        let flag = false;
        let operandsArray = this.state.listOperandAndOperator;

        const operandsArrayLength = operandsArray.length;

        if (key == 'departmentName' || key == 'classColumnName' || key == 'subClassColumnName' || key == 'instructions' || key == 'itemLeadTimeInDaysFromPO') {
            this.state.columnDefs.map((j) =>{
                if (j.colId == key) {
                    key = j.headerName;
                    flag = true;
                }
            });
        }

        for (let i = 0; i < operandsArrayLength; i++) {
            if (operandsArray[i].displayName == key && flag) {
                return { 'columnName': operandsArray[i].graphName, 'dataType': operandsArray[i].type };
            }
            if (operandsArray[i].actualName == key && !flag) {
                return { 'columnName': operandsArray[i].graphName, 'dataType': operandsArray[i].type };
            }
        }
    }

    /**
   * capitalized the first letter of the string
   * @param {string} string is a field name
   * @return {string} return the string with capitalized first letter
   */
    capitalizeFirstLetter(string) {
        const lowerCaseString = string.toLowerCase();
        return lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
    }

    componentWillUnmount() {
        this.props.addProduct2GridData([]);
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.isCellEdit != nextProps.isCellEdit) {
            return false;
        } else {
            return true;
        }
    }

    setSpinner(value) {
        this.setState({ spinner: value });
    }

    // Keep filerting from activating when grouping the grid.
    openFilterCall(boolean) {
        this.setState({ filterOpened: boolean });
    }

    /**
  * updates vendor id
  *when a new vendor is created on the grid using the dropdown
  */
    updatedVendorId() {
        const _this = this;

        ProductServices.getProductDropdown()
            .then((response) => {
                const vendorsListNew = response.responseData.vendorId;
                _this.setState({
                    vendorsList: vendorsListNew,
                });
            })
            .catch((error) => {
                console.log('Server Error Failed', error);
                NotificationManager({ message: error.message || "Unexpected error, Data didn't load", type: 4 });
            });
    }

    /**
   * Enables/Disables edit of cells
   * @param {Array} params is an array
   */
    checkEdit(params) {
        if (params.data.isDeleted || params.colDef.feildLevel == 'R') {
            return false;
        } else {
            return true;
        }
    }

    /**
   * Creates the columns for the grid.
   * Against every column are attributes required for the column to render
   * on the grid.
   * @param {Array} columnDetails is an array
   * @param {Boolean} shouldParentProductIdColumnExist is a boolean
   * @return {Array} return the columns array
   */
    colDefs(columnDetails, intmExpand) {
        const _this = this;
        const renameColdefArray = [];
        let count = 26;

        if (!columnDetails || columnDetails.length == 0) {
            return;
        }

        columnDetails.forEach(function (obj) {
            switch (obj.fieldName) {
                case 'hazardousShippingMessage': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: GenericDropdownRenderer,
                        cellEditorFramework: GenericDropdownEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            dropdownValues: _this.hazardousShippingMessage.bind(_this)
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'photoPack': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: GeneralFileUploadRenderer,
                        cellEditorFramework: GeneralFileUploadEditor,
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'videoType': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: GenericDropdownRenderer,
                        cellEditorFramework: GenericDropdownEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            dropdownValues: _this.videoType.bind(_this)
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'packagingTypes': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: GenericDropdownRenderer,
                        cellEditorFramework: GenericDropdownEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            dropdownValues: _this.packagingTypes.bind(_this)
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'instructions': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: GenericDropdownRenderer,
                        cellEditorFramework: GenericDropdownEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            dropdownValues: _this.instructions.bind(_this)
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'designerOrSourcing': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: GenericDropdownRenderer,
                        cellEditorFramework: GenericDropdownEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            dropdownValues: _this.designerOrSourcing.bind(_this)
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'systemDescription': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        maxLength: obj.maxLength,
                        headerTooltip: obj.fieldName,
                        fieldType: 'string',
                        editable: _this.checkEdit.bind(_this),
                        cellEditorFramework: UpperCaseEditor,
                        cellRendererFramework: UpperCaseRenderer,
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );
                    break;

                case 'planner': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: GenericDropdownRenderer,
                        cellEditorFramework: GenericDropdownEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            dropdownValues: _this.plannerDropdown.bind(_this)
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'websiteNavigationCategory': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: GenericDropdownRenderer,
                        cellEditorFramework: GenericDropdownEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            dropdownValues: _this.websiteNavigationCategoryDropdown.bind(_this)
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'stageStatus': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: GenericDropdownRenderer,
                        cellEditorFramework: GenericDropdownEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            dropdownValues: _this.stageStatusDropdown.bind(_this)
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'retailStatusCode': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: GenericDropdownRenderer,
                        cellEditorFramework: GenericDropdownEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            dropdownValues: _this.retailStatusCodeDropdown.bind(_this)
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'productStatus': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: GenericDropdownRenderer,
                        cellEditorFramework: GenericDropdownEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            dropdownValues: _this.productStatusDropdown.bind(_this)
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'marketingLevel': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: GenericDropdownRenderer,
                        cellEditorFramework: GenericDropdownEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            dropdownValues: _this.marketingLevelDropdown.bind(_this)
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'employeeDiscount': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: GenericDropdownRenderer,
                        cellEditorFramework: GenericDropdownEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            dropdownValues: _this.employeeDiscountDropdown.bind(_this)
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'btbPercentage': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: GenericDropdownRenderer,
                        cellEditorFramework: GenericDropdownEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            dropdownValues: _this.btbPercentageDropdown.bind(_this)
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'itemContainsMagnetOrLithiumBattery': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: GenericDropdownRenderer,
                        cellEditorFramework: GenericDropdownEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            dropdownValues: _this.itemContainsMagnetOrLithiumBatteryDropdown.bind(_this)
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'importerOfRecord': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: GenericDropdownRenderer,
                        cellEditorFramework: GenericDropdownEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            dropdownValues: _this.importerOfRecordDropdown.bind(_this)
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'origin': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: GenericDropdownRenderer,
                        cellEditorFramework: GenericDropdownEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            dropdownValues: _this.originDropdown.bind(_this)
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'dispositionCode': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: GenericDropdownRenderer,
                        cellEditorFramework: GenericDropdownEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            dropdownValues: _this.dispositionCodeDropdown.bind(_this)
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'sellingUnitOfMeasure': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: GenericDropdownRenderer,
                        cellEditorFramework: GenericDropdownEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            dropdownValues: _this.sellingUnitOfMeasureDropdown.bind(_this)
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'itemGroup': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: GenericDropdownRenderer,
                        cellEditorFramework: GenericDropdownEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            dropdownValues: _this.itemGroupDropdown.bind(_this)
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'brand': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: GenericDropdownRenderer,
                        cellEditorFramework: GenericDropdownEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            dropdownValues: _this.brandDropdown.bind(_this)
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'countryOfOrigin': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: GenericDropdownRenderer,
                        cellEditorFramework: GenericDropdownEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            dropdownValues: _this.countryOfOriginDropdown.bind(_this)
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'subBrand': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: GenericDropdownRenderer,
                        cellEditorFramework: GenericDropdownEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            dropdownValues: _this.subBrandDropdown.bind(_this)
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'warrantyInfo': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: GenericDropdownRenderer,
                        cellEditorFramework: GenericDropdownEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            dropdownValues: _this.warrantyInfoDropdown.bind(_this)
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'freightShippingCharge': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: GenericDropdownRenderer,
                        cellEditorFramework: GenericDropdownEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            dropdownValues: _this.freightShippingChargeDropdown.bind(_this)
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'proprietary': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: GenericDropdownRenderer,
                        cellEditorFramework: GenericDropdownEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            dropdownValues: _this.proprietaryDropdown.bind(_this)
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;


                case 'prop65Code': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        fieldId: obj.id,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: GenericDropdownRenderer,
                        cellEditorFramework: GenericDropdownEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            dropdownValues: _this.prop65CodeDropdown.bind(_this)
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'quantityBreaks': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        suppressPaste: true,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: QuantityBreaksRenderer,
                        cellEditorFramework: QuantityBreaksEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                        },
                        suppressKeyboardEvent(params) {
                            const KEY_LEFT = 37;
                            const KEY_UP = 38;
                            const KEY_RIGHT = 39;
                            const KEY_DOWN = 40;
                            const KEY_ENTER = 13;
                            const KEY_TAB = 9;

                            // return true (to suppress) if editing and user hit up/down keys
                            const keyCode = params.event.keyCode;
                            const gridShouldDoNothing = params.editing && (keyCode === KEY_LEFT ||
                keyCode === KEY_RIGHT ||
                keyCode === KEY_UP ||
                keyCode === KEY_DOWN ||
                keyCode === KEY_LEFT ||
                keyCode === KEY_ENTER ||
                keyCode === KEY_TAB);

                            return gridShouldDoNothing;
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'setDetail': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        suppressPaste: true,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: SetDetailRenderer,
                        cellEditorFramework: SetDetailEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                        },
                        suppressKeyboardEvent(params) {
                            const KEY_LEFT = 37;
                            const KEY_UP = 38;
                            const KEY_RIGHT = 39;
                            const KEY_DOWN = 40;
                            const KEY_ENTER = 13;
                            const KEY_TAB = 9;

                            // return true (to suppress) if editing and user hit up/down keys
                            const keyCode = params.event.keyCode;
                            const gridShouldDoNothing = params.editing && (keyCode === KEY_LEFT ||
                keyCode === KEY_RIGHT ||
                keyCode === KEY_UP ||
                keyCode === KEY_DOWN ||
                keyCode === KEY_LEFT ||
                keyCode === KEY_ENTER ||
                keyCode === KEY_TAB);

                            return gridShouldDoNothing;
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'type': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: ProductTypeDropdownRenderer,
                        cellEditorFramework: ProductTypeDropdownEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            getproductTypes: _this.getproductTypes.bind(_this),
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'SingleView': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: SingleViewRender,
                        cellEditorFramework: SingleViewEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            colDefs: _this.getColDefs.bind(_this),
                            rowUpdate: _this.rowUpdate.bind(_this),
                            itemReadyToOrderFields: _this.state.itemReadyToOrderFields,
                            getVendorsList: _this.getVendorsList.bind(_this),
                            getColorsList: _this.getColorsList.bind(_this),
                            getSizeList: _this.getSizeList.bind(_this),
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'photographyDirection': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        width: 55,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: PhotoDirectionRender,
                        cellEditorFramework: PhotoDirectionEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        width: obj.width,
                        order: obj.order,
                    }
                );

                    break;

                case 'Msg': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        width: 55,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: MessageRender,
                        cellEditorFramework: MessageEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                        },
                        suppressKeyboardEvent(params) {
                            const KEY_LEFT = 37;
                            const KEY_UP = 38;
                            const KEY_RIGHT = 39;
                            const KEY_DOWN = 40;
                            const KEY_ENTER = 13;
                            const KEY_TAB = 9;

                            // return true (to suppress) if editing and user hit up/down keys
                            const keyCode = params.event.keyCode;
                            const gridShouldDoNothing = params.editing && (keyCode === KEY_LEFT ||
                keyCode === KEY_RIGHT ||
                keyCode === KEY_UP ||
                keyCode === KEY_DOWN ||
                keyCode === KEY_LEFT ||
                keyCode === KEY_ENTER ||
                keyCode === KEY_TAB);

                            return gridShouldDoNothing;
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: false,
                        order: 1,
                    }
                );
                    break;

                case 'images': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        colId: obj.fieldName,
                        headerTooltip: obj.fieldName,
                        width: 70,
                        editable: _this.checkEdit.bind(_this),
                        cellRendererFramework: ImagePopupRenderer,
                        cellRendererParams: {
                            getRowHeight: _this.getRowHeight.bind(_this),
                        },
                        cellEditorFramework: ImagePopupEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                            updateUploadedImage: _this.updateUploadedImage.bind(_this),
                            imageData: _this.getImageList.bind(_this),
                        },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        suppressSorting: false,
                        suppressFilter: true,
                        suppressMenu: false,
                        cellStyle: {
                            'height': '100%',
                            'padding': 2,
                            'border-right': '1px solid lightgrey',
                        },
                        hide: !obj.visibility,
                        order: obj.order,
                    }
                );
                    break;
                case 'productId': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        colId: obj.fieldName,
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        editable: false,
                        sortable: false,
                        headerComponentParams: { menuIcon: "fa-bars" },
                        filter: 'FilterComponent',
                        suppressFilter: true,
                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                        hide: !obj.visibility,
                        suppressMenu: false,
                        width: obj.width,
                        order: obj.order,
                        colId: obj.fieldName,
                        cellStyle: function (params) {
                            if (params.data) {
                                if (params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                } else {
                                    return { borderRight: '1px solid lightgrey' };
                                }
                            }
                        },
                    },
                );
                    break;
                case 'catId': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        colId: obj.fieldName,
                        editable: false,
                        hide: true,
                    },
                );
                    break;
                case 'department': renameColdefArray.push(
                    {
                        headerName: 'Department',
                        headerTooltip: 'Department',
                        field: 'departmentName',
                        colId: 'departmentName',
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        filter: 'FilterComponent',
                        suppressFilter: true,
                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                        enableRowGroup: false,
                        newRowsAction: 'keep',
                        width: obj.width,
                        editable: _this.checkEdit.bind(_this),
                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                        hide: !obj.visibility,
                        order: obj.order,
                        cellEditorParams: {
                            rowUpdate: _this.rowUpdate.bind(_this),
                            user: _this.props.user,
                            getCategoriesList: _this.getCategoriesList.bind(_this),
                        },
                        cellEditorFramework: DepartmentPopUpEditor,

                        cellRendererParams: {
                            getCategoriesList: _this.getCategoriesList.bind(_this),
                        },
                        cellRendererFramework: DepartmentPopUpRenderer,
                        // used to customize the look of cells based on the below conditions
                        cellStyle: function (params) {
                            if (params.data && params.data.childCount) {
                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }
                            if (_this.isMandatory(params.colDef.colId)) {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }
                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }
                                if (params.value == '' || !params.value) {
                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                } else {
                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                }
                            } else {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }
                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }
                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }
                        },

                    },
                );
                    break;
                case 'classColumnName': renameColdefArray.push(
                    {
                        headerName: 'Class',
                        field: 'classColumnName',
                        colId: 'classColumnName',
                        headerTooltip: 'Class',
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        width: obj.width,
                        editable: _this.checkEdit.bind(_this),
                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                        filter: 'FilterComponent',
                        suppressFilter: true,
                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                        enableRowGroup: false,
                        enablePivot: true,
                        newRowsAction: 'keep',
                        hide: !obj.visibility,
                        order: obj.order,
                        cellEditorParams: {
                            rowUpdate: _this.rowUpdate.bind(_this),
                            user: _this.props.user,
                            getCategoriesList: _this.getCategoriesList.bind(_this),
                        },
                        cellEditorFramework: ClassColumnPopUpEditor,
                        cellRendererParams: {
                            getCategoriesList: _this.getCategoriesList.bind(_this),
                        },
                        cellRendererFramework: ClassColumnPopUpRenderer,
                        cellStyle: function (params) {
                            if (params.data && params.data.childCount) {
                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }
                            if (_this.isMandatory(params.colDef.colId)) {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }
                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }
                                if (params.value == '' || !params.value) {
                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                } else {
                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                }
                            } else {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }
                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }
                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }
                        },


                    }
                );
                    break;
                case 'subClassColumnName': renameColdefArray.push(
                    {
                        headerName: 'Sub Class',
                        field: 'subClassColumnName',
                        colId: 'subClassColumnName',
                        headerTooltip: 'Sub Class',
                        width: obj.width,
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        editable: _this.checkEdit.bind(_this),
                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                        filter: 'FilterComponent',
                        suppressFilter: true,
                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                        enableRowGroup: false,
                        enablePivot: true,
                        newRowsAction: 'keep',
                        hide: !obj.visibility,
                        order: obj.order,
                        cellEditorParams: {
                            rowUpdate: _this.rowUpdate.bind(_this),
                            user: _this.props.user,
                            getCategoriesList: _this.getCategoriesList.bind(_this),
                        },
                        cellEditorFramework: SubClassColumnPopUpEditor,
                        cellRendererParams: {
                            getCategoriesList: _this.getCategoriesList.bind(_this),
                        },
                        cellRendererFramework: SubClassColumnPopUpRenderer,
                        cellStyle: function (params) {
                            if (params.data && params.data.childCount) {
                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }
                            if (_this.isMandatory(params.colDef.colId)) {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }
                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }
                                if (params.value == '' || !params.value) {
                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                } else {
                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                }
                            } else {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }
                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }
                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }
                        },
                    }
                );
                    break;
                case 'division': renameColdefArray.push(
                    {
                        headerName: 'Division',
                        field: 'division',
                        colId: 'division',
                        headerTooltip: 'Division',
                        filter: 'FilterComponent',
                        suppressFilter: true,
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                        enableRowGroup: false,
                        enablePivot: true,
                        newRowsAction: 'keep',
                        width: obj.width,
                        editable: _this.checkEdit.bind(_this),
                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                        hide: !obj.visibility,
                        order: obj.order,
                        cellRendererParams: {
                            getCategoriesList: _this.getCategoriesList.bind(_this),
                        },
                        cellRendererFramework: DivisionRenderer,
                        cellStyle: function (params) {
                            if (params.data && params.data.childCount) {
                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }
                            if (_this.isMandatory(params.colDef.colId)) {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }
                                if (params.value == '' || !params.value) {
                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                } else {
                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                }
                            } else {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }
                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }
                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }
                        },
                    },
                );
                    break;

                case 'buyer': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        headerTooltip: obj.columnTitle,
                        field: 'buyer',
                        width: obj.width,
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        editable: _this.checkEdit.bind(_this),
                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                        filter: 'FilterComponent',
                        suppressFilter: true,
                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                        enableRowGroup: false,
                        enablePivot: true,
                        newRowsAction: 'keep',
                        hide: !obj.visibility,
                        colId: obj.fieldName,
                        order: obj.order,
                        productVariant: fixtures && fixtures.productVariantCols.includes(obj.fieldName),
                        cellEditorParams: {
                            getBuyersList: _this.getBuyersList.bind(_this),
                            rowUpdate: _this.rowUpdate.bind(_this),
                            openMultiProductPopup: _this.openMultiProductPopup.bind(_this),
                        },
                        cellEditorFramework: BuyerDropdownEditor,

                        cellRendererParams: {
                            user: _this.props.user,
                        },
                        cellRendererFramework: BuyerDropdownRenderer,
                        cellStyle: function (params) {
                            if (params.data && params.data.childCount) {
                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }
                            if (_this.isMandatory(params.colDef.colId)) {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }
                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }
                                if (params.value == '' || !params.value) {
                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                } else {
                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                }
                            } else {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }
                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }
                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }
                        },
                    },
                );
                    break;
                case 'vendorId': renameColdefArray.push(
                    {
                        headerName: 'Vendor Name',
                        field: 'vendorName',
                        headerTooltip: 'Vendor Name',
                        width: obj.width,
                        editable: _this.checkEdit.bind(_this),
                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        filter: 'FilterComponent',
                        suppressFilter: true,
                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                        enableRowGroup: false,
                        enablePivot: true,
                        newRowsAction: 'keep',
                        colId: obj.fieldName,
                        hide: !obj.visibility,
                        order: obj.order,
                        productVariant: fixtures && fixtures.productVariantCols.includes(obj.fieldName),
                        cellEditorParams: {
                            openAddNewVendorPopup: _this.openAddNewVendorPopup.bind(_this),
                            user: _this.props.user,
                            getVendorsList: _this.getVendorsList.bind(_this),
                            rowUpdate: _this.rowUpdate.bind(_this),
                            openMultiProductPopup: _this.openMultiProductPopup.bind(_this),
                        },
                        cellEditorFramework: VendorDropdownPopUpEditor,

                        cellRendererParams: {
                            getVendorsList: _this.getVendorsList.bind(_this),
                        },

                        cellRendererFramework: VendorDropdownPopUpRenderer,
                        cellStyle: function (params) {
                            if (params.data && params.data.childCount) {
                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }

                            if (_this.isMandatory('vendorId')) {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }

                                if (params.data && params.data.vendorId == '' || params.data && !params.data.vendorId) {
                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                } else {
                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                }
                            } else {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }

                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }
                        },
                    },
                );
                    break;
                case 'channel': renameColdefArray.push(
                    {
                        headerName: 'Channel',
                        headerTooltip: 'Channel',
                        field: 'channel',
                        width: obj.width,
                        editable: _this.checkEdit.bind(_this),
                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        hide: !obj.visibility,
                        colId: obj.fieldName,
                        filter: 'FilterComponent',
                        suppressFilter: true,
                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                        enableRowGroup: false,
                        enablePivot: true,
                        newRowsAction: 'keep',
                        order: obj.order,
                        cellClass: ['ag-dropdownCustomClass'],
                        cellEditorParams: {
                            getChannelList: _this.getChannelsList.bind(_this),
                            rowUpdate: _this.rowUpdate.bind(_this),
                        },
                        cellEditorFramework: ChannelDropdownPopupEditor,

                        cellRendererParams: {
                            getChannelList: _this.getChannelsList.bind(_this),
                        },
                        cellRendererFramework: ChannelDropdownPopupRenderer,
                        cellStyle: function (params) {
                            if (params.data && params.data.childCount) {
                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }

                            if (_this.isMandatory(params.colDef.colId)) {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }

                                if (params.value == '' || !params.value) {
                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                } else {
                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                }
                            } else {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }

                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }
                        },
                    },
                );
                    break;
                case 'shotAngle': renameColdefArray.push(
                    {
                        headerName: 'Shotangle',
                        field: 'shotAngle',
                        headerTooltip: 'Shotangle',
                        width: obj.width,
                        editable: _this.checkEdit.bind(_this),
                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                        filter: 'FilterComponent',
                        suppressFilter: true,
                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                        hide: !obj.visibility,
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        colId: obj.fieldName,
                        enableRowGroup: false,
                        enablePivot: true,
                        newRowsAction: 'keep',
                        order: obj.order,
                        cellEditorParams: {
                            rowUpdate: _this.rowUpdate.bind(_this),
                            user: _this.props.user,
                        },
                        cellEditorFramework: ShotAngleEditor,
                        cellRendererParams: {
                            user: _this.props.user,
                        },
                        cellRendererFramework: ShotAngleRenderer,
                        cellStyle: function (params) {
                            if (params.data && params.data.childCount) {
                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }

                            if (_this.isMandatory(params.colDef.colId)) {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.value == '' || !params.value) {
                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                } else {
                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                }
                            } else {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }

                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }
                        },
                    },
                );
                    break;
                case 'color_attribute': renameColdefArray.push(
                    {
                        headerName: 'Color',
                        headerTooltip: obj.columnTitle,
                        field: 'color_attribute',
                        width: obj.width,
                        editable: _this.checkEdit.bind(_this),
                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        hide: !obj.visibility,
                        filter: 'FilterComponent',
                        suppressFilter: true,
                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                        enableRowGroup: false,
                        newRowsAction: 'keep',
                        order: obj.order,
                        colId: obj.fieldName,
                        productVariant: fixtures && fixtures.productVariantCols.includes(obj.fieldName),
                        cellClass: function (params) {
                            if (params.data) {
                                return ((params.data.itemReadyToOrder == true || params.data.dataLevel == 'PARENT') ? 'grey-cell-text' : '');
                            }
                        },
                        cellEditorParams: {
                            openMultiProductPopup: _this.openMultiProductPopup.bind(_this),
                            getColorsList: _this.getColorsList.bind(_this),
                            rowUpdate: _this.rowUpdate.bind(_this),
                        },
                        cellEditorFramework: ColorDropdownPopupEditor,
                        cellRendererFramework: ColorDropdownPopupRenderer,
                        cellStyle: function (params) {
                            if (params.data && params.data.childCount) {
                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }

                            if (_this.isMandatory(params.colDef.colId)) {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.value == '' || !params.value) {
                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                } else {
                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                }
                            } else {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }

                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }
                        },
                    },
                );
                    break;
                case 'size_attribute': renameColdefArray.push(
                    {
                        headerName: 'Size',
                        headerTooltip: obj.columnTitle,
                        field: 'size_attribute',
                        width: obj.width,
                        editable: _this.checkEdit.bind(_this),
                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        hide: !obj.visibility,
                        filter: 'FilterComponent',
                        suppressFilter: true,
                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                        enableRowGroup: false,
                        colId: obj.fieldName,
                        newRowsAction: 'keep',
                        order: obj.order,
                        productVariant: fixtures && fixtures.productVariantCols.includes(obj.fieldName),
                        cellClass: function (params) {
                            if (params.data) {
                                return ((params.data.itemReadyToOrder == true || params.data.dataLevel == 'PARENT') ? 'grey-cell-text' : '');
                            }
                        },

                        cellEditorParams: {
                            openMultiProductPopup: _this.openMultiProductPopup.bind(_this),
                            getSizeList: _this.getSizeList.bind(_this),
                            rowUpdate: _this.rowUpdate.bind(_this),
                        },
                        cellEditorFramework: SizeDropdownPopupEditor,
                        cellRendererFramework: SizeDropdownPopupRenderer,
                        cellStyle: function (params) {
                            if (params.data && params.data.childCount) {
                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }

                            if (_this.isMandatory(params.colDef.colId)) {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.value == '' || !params.value) {
                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                } else {
                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                }
                            } else {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }

                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }
                        },
                    },
                );
                    break;
                case 'copywrite': renameColdefArray.push(
                    {
                        headerName: 'Product Copy',
                        headerTooltip: obj.columnTitle,
                        field: 'copyWrite',
                        width: obj.width,
                        hide: !obj.visibility,
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        colId: obj.fieldName,
                        enableRowGroup: false,
                        editable: _this.checkEdit.bind(_this),
                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                        filter: 'FilterComponent',
                        suppressFilter: true,
                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                        order: obj.order,
                        cellStyle: function (params) {
                            if (params.data) {
                                if (params.data.copyStatus == 'Approved') {
                                    return { color: 'white', backgroundColor: '#2FACB2', borderRight: '1px solid lightgrey' };
                                } else if (params.data.copyStatus == 'Rejected') {
                                    return { color: 'black', backgroundColor: '#ff0000', borderRight: '1px solid lightgrey' };
                                } else if (params.data.copyStatus == 'Submitted' || params.data.copyStatus == 'submitted') {
                                    return { color: 'black', backgroundColor: '#f8e984', borderRight: '1px solid lightgrey' };
                                } else if (params.data.copyStatus == 'saved' || params.data.copyStatus == 'Saved') {
                                    return { color: 'black', backgroundColor: '#dddddd', borderRight: '1px solid lightgrey' };
                                } else if (params.data.copyStatus == 'Rejected') {
                                    return { color: 'black', backgroundColor: '#ff0000', borderRight: '1px solid lightgrey' };
                                } else if (params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                } else {
                                    return { borderRight: '1px solid lightgrey' };
                                }
                            }
                        },

                        cellEditorParams: {
                            setSpinner: _this.setSpinner.bind(_this),
                            user: _this.props.user,
                            openCopyWritePopup: _this.openCopyWritePopup.bind(_this),
                            closeCopyWritePopup: _this.closeCopyWritePopup.bind(_this),
                        },

                        cellEditorFramework: CopyWriteEditor,
                        cellRendererFramework: CopyWriteRenderer,
                    },
                );
                    break;
                case 'keywords': renameColdefArray.push(
                    {
                        headerName: 'Keywords',
                        headerTooltip: obj.columnTitle,
                        field: 'keywords',
                        width: obj.width,
                        hide: !obj.visibility,
                        colId: obj.fieldName,
                        enableRowGroup: false,
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        editable: _this.checkEdit.bind(_this),
                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                        filter: 'FilterComponent',
                        suppressFilter: true,
                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },

                        order: obj.order,
                        cellStyle: function (params) {
                            if (params.data) {
                                if (params.data.keywordsStatus == 'Approved') {
                                    return { color: 'white', backgroundColor: '#2FACB2', borderRight: '1px solid lightgrey' };
                                } else if (params.data.keywordsStatus == 'Rejected') {
                                    return { color: 'black', backgroundColor: 'red', borderRight: '1px solid lightgrey' };
                                } else if (params.data.keywordsStatus == 'saved' || params.data.keywordsStatus == 'Saved') {
                                    return { color: 'black', backgroundColor: '#dddddd', borderRight: '1px solid lightgrey' };
                                } else if (params.data.keywordsStatus == 'Submitted' || params.data.keywordsStatus == 'submitted') {
                                    return { color: 'black', backgroundColor: '#f8e984', borderRight: '1px solid lightgrey' };
                                } else if (params.data.keywordsStatus == 'Rejected') {
                                    return { color: 'black', backgroundColor: 'red', borderRight: '1px solid lightgrey' };
                                } else if (params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                } else {
                                    return { borderRight: '1px solid lightgrey' };
                                }
                            }
                        },

                        cellEditorParams: {
                            setSpinner: _this.setSpinner.bind(_this),
                            user: _this.props.user,
                            openKeywordPopup: _this.openKeywordPopup.bind(_this),
                            closeKeywordPopup: _this.closeKeywordPopup.bind(_this),
                        },
                        cellEditorFramework: KeywordEditor,
                        cellRendererFramework: KeywordRenderer,
                    },
                );
                    break;

                case 'copyLevel': renameColdefArray.push(
                    {
                        headerName: 'Copy Level',
                        headerTooltip: obj.columnTitle,
                        field: 'copyLevel',
                        width: obj.width,
                        editable: _this.checkEdit.bind(_this),
                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                        filter: 'FilterComponent',
                        suppressFilter: true,
                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                        colId: obj.fieldName,
                        enableRowGroup: false,
                        newRowsAction: 'keep',
                        order: obj.order,
                        hide: !obj.visibility,
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        cellClass: ['ag-dropdownCustomClass'],
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                        },
                        cellEditorFramework: CopyLevelDropdownEditor,
                        cellRendererFramework: CopyLevelDropdownRenderer,
                        cellStyle: function (params) {
                            if (_this.isMandatory(params.colDef.colId)) {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.value == '' || !params.value) {
                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                } else {
                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                }
                            } else {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }

                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }
                        },
                    },
                );
                    break;
                case 'displayLevel': renameColdefArray.push(
                    {
                        headerName: 'Display Level',
                        headerTooltip: obj.columnTitle,
                        field: 'displayLevel',
                        width: obj.width,
                        colId: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                        filter: 'FilterComponent',
                        suppressFilter: true,
                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                        hide: !obj.visibility,
                        enableRowGroup: false,
                        newRowsAction: 'keep',
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        order: obj.order,
                        cellClass: ['ag-dropdownCustomClass'],
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                        },
                        cellEditorFramework: DisplayLevelDropdownEditor,
                        cellRendererFramework: DisplayLevelDropdownRenderer,
                        cellStyle: function (params) {
                            if (params.data && params.data.childCount) {
                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }

                            if (_this.isMandatory(params.colDef.colId)) {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.value == '' || !params.value) {
                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                } else {
                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                }
                            } else {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }

                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }
                        },
                    },
                );
                    break;
                case 'addPO': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        headerTooltip: obj.columnTitle,
                        field: 'addPO',
                        width: obj.width,
                        colId: obj.fieldName,
                        enableRowGroup: false,
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        enablePivot: true,
                        editable: _this.checkEdit.bind(_this),
                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                        filter: 'FilterComponent',
                        suppressFilter: true,
                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                        hide: !obj.visibility,
                        order: obj.order,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            channelsList: _this.getChannelsList.bind(_this),
                        },
                        cellEditorFramework: AddPOEditor,
                        cellRendererFramework: AddPORenderer,
                        cellStyle: function (params) {
                            if (params.data && params.data.childCount) {
                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }

                            if (_this.isMandatory(params.colDef.colId)) {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.value == '' || !params.value) {
                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                } else {
                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                }
                            } else {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }

                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }
                        },
                    },
                );
                    break;
                case 'featureColor': renameColdefArray.push(
                    {
                        headerName: 'Feature Color',
                        field: 'featureColor',
                        width: obj.width,
                        colId: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                        filter: 'FilterComponent',
                        suppressFilter: true,
                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        enableRowGroup: false,
                        newRowsAction: 'keep',
                        hide: !obj.visibility,
                        order: obj.order,
                        cellClass: ['ag-dropdownCustomClass'],
                        cellEditorParams: {
                            rowUpdate: _this.rowUpdate.bind(_this),
                        },
                        cellEditorFramework: FeatureColorEditor,
                        cellRendererFramework: FeatureColorRenderer,
                        cellStyle: function (params) {
                            if (_this.isMandatory(params.colDef.colId)) {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.value == '' || !params.value) {
                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                } else {
                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                }
                            } else {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }

                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }
                        },
                    },
                );
                    break;
                case 'attr1': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        headerTooltip: obj.columnTitle,
                        field: obj.fieldName,
                        width: obj.width,
                        enableRowGroup: false,
                        enablePivot: true,
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        colId: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                        filter: 'agDateColumnFilter',
                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                        hide: !obj.visibility,
                        order: obj.order,
                        cellEditorFramework: AttrDropdownEditor,
                        cellEditorParams: {
                            getAttrDropdowns: _this.getAttrDropdowns.bind(_this),
                            rowUpdate: _this.rowUpdate.bind(_this),
                        },
                        cellRendererFramework: AttrDropdownRenderer,
                        cellStyle: function (params) {
                            if (_this.isMandatory(params.colDef.colId)) {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.value == '' || !params.value) {
                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                } else {
                                    return { backgroundColor: 'white', borderRight: '1px solid lightgrey' };
                                }
                            } else {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }

                                return { backgroundColor: 'white', borderRight: '1px solid lightgrey' };
                            }
                        },
                    },
                );
                    break;
                case 'attr2': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        headerTooltip: obj.columnTitle,
                        field: obj.fieldName,
                        width: obj.width,
                        enableRowGroup: false,
                        enablePivot: true,
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        colId: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                        filter: 'agDateColumnFilter',
                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                        hide: !obj.visibility,
                        order: obj.order,
                        cellEditorFramework: AttrDropdownEditor,
                        cellEditorParams: {
                            getAttrDropdowns: _this.getAttrDropdowns.bind(_this),
                            rowUpdate: _this.rowUpdate.bind(_this),
                        },
                        cellRendererFramework: AttrDropdownRenderer,
                        cellStyle: function (params) {
                            if (_this.isMandatory(params.colDef.colId)) {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.value == '' || !params.value) {
                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                } else {
                                    return { backgroundColor: 'white', borderRight: '1px solid lightgrey' };
                                }
                            } else {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }

                                return { backgroundColor: 'white', borderRight: '1px solid lightgrey' };
                            }
                        },
                    },
                );
                    break;
                case 'attr3': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        headerTooltip: obj.columnTitle,
                        field: obj.fieldName,
                        width: obj.width,
                        enableRowGroup: false,
                        enablePivot: true,
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        colId: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                        filter: 'agDateColumnFilter',
                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                        hide: !obj.visibility,
                        order: obj.order,
                        cellEditorFramework: AttrDropdownEditor,
                        cellEditorParams: {
                            getAttrDropdowns: _this.getAttrDropdowns.bind(_this),
                            rowUpdate: _this.rowUpdate.bind(_this),
                        },
                        cellRendererFramework: AttrDropdownRenderer,
                        cellStyle: function (params) {
                            if (_this.isMandatory(params.colDef.colId)) {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.value == '' || !params.value) {
                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                } else {
                                    return { backgroundColor: 'white', borderRight: '1px solid lightgrey' };
                                }
                            } else {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }

                                return { backgroundColor: 'white', borderRight: '1px solid lightgrey' };
                            }
                        },
                    },
                );
                    break;

                case 'sampleOptions': renameColdefArray.push(
                    {
                        headerName: 'Sample Option',
                        field: 'sampleOptions',
                        width: obj.width,
                        editable: _this.checkEdit.bind(_this),
                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        colId: obj.fieldName,
                        order: obj.order,
                        filter: 'FilterComponent',
                        suppressFilter: true,
                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                        enableRowGroup: false,
                        newRowsAction: 'keep',
                        hide: !obj.visibility,
                        cellClass: ['ag-dropdownCustomClass'],
                        cellEditorParams: {
                            rowUpdate: _this.rowUpdate.bind(_this),
                            openSwatchPopup: _this.openSwatchPopup.bind(_this),
                            closeSwatchPopup: _this.closeSwatchPopup.bind(_this),
                            user: _this.props.user,
                        },
                        cellEditorFramework: SampleOptionDropdownEditor,
                        cellRendererFramework: SampleOptionDropdownRender,
                        cellStyle: function (params) {
                            if (params.data && params.data.childCount) {
                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }

                            if (_this.isMandatory(params.colDef.colId)) {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.value == '' || !params.value) {
                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                } else {
                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                }
                            } else {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }

                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }
                        },
                    },
                );
                    break;

                case 'campaign': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        headerTooltip: obj.columnTitle,
                        field: 'campaign',
                        width: obj.width,
                        colId: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                        filter: 'FilterComponent',
                        suppressFilter: true,
                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        order: obj.order,
                        hide: !obj.visibility,
                        cellClass: ['ag-popupCustomClass'],
                        enableRowGroup: false,
                        enablePivot: true,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                        },
                        cellEditorFramework: CampaignEditor,
                        cellRendererFramework: CampaignRender,
                        cellStyle: function (params) {
                            if (params.data && params.data.childCount) {
                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }

                            if (_this.isMandatory(params.colDef.colId)) {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.value == '' || !params.value) {
                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                } else {
                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                }
                            } else {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }

                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }
                        },
                    },
                );
                    break;
                case 'samples': renameColdefArray.push(
                    {
                        headerName: 'Samples',
                        headerTooltip: obj.columnTitle,
                        field: 'samples',
                        width: obj.width,
                        colId: obj.fieldName,
                        enableRowGroup: false,
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        enablePivot: true,
                        editable: _this.checkEdit.bind(_this),
                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                        filter: 'FilterComponent',
                        suppressFilter: true,
                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                        order: obj.order,
                        hide: !obj.visibility,
                        cellClass: ['ag-popupCustomClass'],
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            openSamplesPopup: _this.openSamplesPopup.bind(_this),
                        },
                        cellEditorFramework: SamplesEditor,
                        cellRendererFramework: SamplesRender,
                        cellStyle: function (params) {
                            if (params.data && params.data.childCount) {
                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }

                            if (_this.isMandatory(params.colDef.colId)) {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.value == '' || !params.value) {
                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                } else {
                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                }
                            } else {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }

                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }
                        },
                    },
                );
                    break;
                case 'season': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        field: obj.fieldName,
                        headerTooltip: obj.columnTitle,
                        width: obj.width,
                        editable: _this.checkEdit.bind(_this),
                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                        filter: 'FilterComponent',
                        suppressFilter: true,
                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        hide: !obj.visibility,
                        colId: obj.fieldName,
                        enableRowGroup: false,
                        enablePivot: true,
                        order: obj.order,
                        cellEditorParams: {
                            user: _this.props.user,
                            getSeasonsList: _this.getSeasonsList.bind(_this),
                            rowUpdate: _this.rowUpdate.bind(_this),
                        },
                        cellEditorFramework: SeasonsEditor,
                        cellRendererParams: {
                            getSeasonsList: _this.getSeasonsList.bind(_this),
                        },
                        cellRendererFramework: SeasonsRenderer,
                        cellStyle: function (params) {
                            if (params.data && params.data.childCount) {
                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }

                            if (_this.isMandatory(params.colDef.colId)) {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.value == '' || !params.value) {
                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                } else {
                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                }
                            } else {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }

                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }
                        },
                    },
                );
                    break;
                case 'WestelmSeason': renameColdefArray.push(
                    {
                        headerName: 'Westelm Season',
                        field: obj.fieldName,
                        headerTooltip: obj.columnTitle,
                        width: obj.width,
                        editable: _this.checkEdit.bind(_this),
                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                        filter: 'FilterComponent',
                        suppressFilter: true,
                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                        hide: !obj.visibility,
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        colId: obj.fieldName,
                        enableRowGroup: false,
                        enablePivot: true,
                        order: obj.order,
                        suppressKeyboardEvent(params) {
                            const KEY_LEFT = 37;
                            const KEY_UP = 38;
                            const KEY_RIGHT = 39;
                            const KEY_DOWN = 40;
                            const KEY_ENTER = 13;
                            const KEY_TAB = 9;

                            // return true (to suppress) if editing and user hit up/down keys
                            const keyCode = params.event.keyCode;
                            const gridShouldDoNothing = params.editing && (keyCode === KEY_LEFT ||
                keyCode === KEY_RIGHT ||
                keyCode === KEY_UP ||
                keyCode === KEY_DOWN ||
                keyCode === KEY_LEFT ||
                keyCode === KEY_ENTER ||
                keyCode === KEY_TAB);

                            return gridShouldDoNothing;
                        },
                        cellEditorFramework: WestElmSeasonsEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                        },
                        cellStyle: function (params) {
                            if (params.data && params.data.childCount) {
                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }

                            if (_this.isMandatory(params.colDef.colId)) {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.value == '' || !params.value) {
                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                } else {
                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                }
                            } else {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }

                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }
                        },
                    },
                );
                    break;
                case 'swatchReference': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        headerTooltip: obj.columnTitle,
                        field: 'swatchReference',
                        width: obj.width,
                        colId: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                        filter: 'FilterComponent',
                        suppressFilter: true,
                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        order: obj.order,
                        hide: !obj.visibility,
                        cellClass: ['ag-popupCustomClass'],
                        enableRowGroup: false,
                        enablePivot: true,
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            openSwatchPopup: _this.openSwatchPopup.bind(_this),
                            closeSwatchPopup: _this.closeSwatchPopup.bind(_this),
                        },
                        cellEditorFramework: SampleReferenceEditor,
                        cellRendererFramework: SampleReferenceRender,
                        cellStyle: function (params) {
                            if (params.data && params.data.childCount) {
                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }

                            if (_this.isMandatory(params.colDef.colId)) {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.value == '' || !params.value) {
                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                } else {
                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                }
                            } else {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }

                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }
                        },
                    },
                );
                    break;

                case 'piUpload': renameColdefArray.push(
                    {
                        headerName: obj.columnTitle,
                        headerTooltip: obj.columnTitle,
                        field: 'piUpload',
                        width: obj.width,
                        enableRowGroup: false,
                        lockPinned: false,
                        feildLevel: obj.feildLevel,
                        enablePivot: true,
                        colId: obj.fieldName,
                        editable: _this.checkEdit.bind(_this),
                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                        filter: 'FilterComponent',
                        suppressFilter: true,
                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                        order: obj.order,
                        hide: !obj.visibility,
                        cellClass: ['ag-popupCustomClass'],
                        cellEditorParams: {
                            user: _this.props.user,
                            rowUpdate: _this.rowUpdate.bind(_this),
                            openPiUploadPopup: _this.openPiUploadPopup.bind(_this),
                        },
                        cellEditorFramework: PiUploadEditor,
                        cellRendererFramework: PiUploadRender,
                        cellStyle: function (params) {
                            if (params.data && params.data.childCount) {
                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }

                            if (_this.isMandatory(params.colDef.colId)) {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.value == '' || !params.value) {
                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                } else {
                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                }
                            } else {
                                if (params.node.selected) {
                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                }

                                if (params.data && params.data.isDeleted == true) {
                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                }

                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                            }
                        },
                    },
                );
                    break;

                default:
                    if (obj && obj.feildLevel) {
                        if (!obj.feildLevel.includes('Deleted-')) {
                            if (obj.feildLevel.includes('W') || obj.feildLevel.includes('R')) {
                                if (obj.columnTitle) {
                                    // eslint-disable-next-line default-case
                                    switch (obj.type) {
                                        case 'int':
                                            if (obj.format == 'Currency') {
                                                renameColdefArray.push(
                                                    {
                                                        headerName: obj.columnTitle,
                                                        headerTooltip: obj.columnTitle,
                                                        lockPinned: false,
                                                        feildLevel: obj.feildLevel,
                                                        field: obj.fieldName,
                                                        format: 'Currency',
                                                        enableRowGroup: false,
                                                        enablePivot: true,
                                                        colId: obj.fieldName,
                                                        width: obj.width,
                                                        editable: _this.checkEdit.bind(_this),
                                                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                                                        filter: 'FilterComponent',
                                                        suppressFilter: true,
                                                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                                                        hide: !obj.visibility,
                                                        order: obj.order,
                                                        cellRendererFramework: CurrencyRenderer,
                                                        cellStyle: function (params) {
                                                            if (params.data && params.data.childCount) {
                                                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                            }

                                                            if (_this.isMandatory(params.colDef.colId)) {
                                                                if (params.node.selected) {
                                                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                                                }

                                                                if (params.value == '' || !params.value) {
                                                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                                                } else {
                                                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                                }
                                                            } else {
                                                                if (params.node.selected) {
                                                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                                                }

                                                                if (params.data && params.data.isDeleted == true) {
                                                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                                                }

                                                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                            }
                                                        },
                                                    },
                                                );
                                            } else if (obj.format == 'Integers') {
                                                renameColdefArray.push(
                                                    {
                                                        headerName: obj.columnTitle,
                                                        headerTooltip: obj.columnTitle,
                                                        lockPinned: false,
                                                        feildLevel: obj.feildLevel,
                                                        field: obj.fieldName,
                                                        format: 'int',
                                                        enableRowGroup: false,
                                                        enablePivot: true,
                                                        colId: obj.fieldName,
                                                        width: obj.width,
                                                        editable: _this.checkEdit.bind(_this),
                                                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                                                        filter: 'FilterComponent',
                                                        suppressFilter: true,
                                                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                                                        hide: !obj.visibility,
                                                        order: obj.order,
                                                        cellEditorFramework: NumbersEditor,
                                                        cellRendererFramework: NumbersRenderer,
                                                        cellStyle: function (params) {
                                                            if (params.data && params.data.childCount) {
                                                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                            }

                                                            if (_this.isMandatory(params.colDef.colId)) {
                                                                if (params.node.selected) {
                                                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                                                }

                                                                if (params.value == '' || !params.value) {
                                                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                                                } else {
                                                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                                }
                                                            } else {
                                                                if (params.node.selected) {
                                                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                                                }

                                                                if (params.data && params.data.isDeleted == true) {
                                                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                                                }

                                                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                            }
                                                        },
                                                    },
                                                );
                                            } else {
                                                renameColdefArray.push(
                                                    {
                                                        headerName: obj.columnTitle,
                                                        headerTooltip: obj.columnTitle,
                                                        field: obj.fieldName,
                                                        format: 'int',
                                                        width: obj.width,
                                                        colId: obj.fieldName,
                                                        lockPinned: false,
                                                        feildLevel: obj.feildLevel,
                                                        enableRowGroup: false,
                                                        editable: _this.checkEdit.bind(_this),
                                                        cellClass: [obj.feildLevel == 'R' ? 'read-only-cells' : '', 'ag-NumberAlignClass'],
                                                        filter: 'FilterComponent',
                                                        suppressFilter: true,
                                                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                                                        hide: !obj.visibility,
                                                        order: obj.order,
                                                        cellStyle: function (params) {
                                                            if (params.data && params.data.childCount) {
                                                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                            }

                                                            if (_this.isMandatory(params.colDef.colId)) {
                                                                if (params.node.selected) {
                                                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                                                }

                                                                if (params.value == '' || !params.value) {
                                                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                                                } else {
                                                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                                }
                                                            } else {
                                                                if (params.node.selected) {
                                                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                                                }

                                                                if (params.data && params.data.isDeleted == true) {
                                                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                                                }

                                                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                            }
                                                        },
                                                    },
                                                );
                                            }
                                            break;

                                        case 'FileList':
                                            renameColdefArray.push({
                                                headerName: obj.columnTitle,
                                                field: obj.fieldName,
                                                colId: obj.fieldName,
                                                headerTooltip: obj.fieldName,
                                                width: obj.width,
                                                editable: _this.checkEdit.bind(_this),
                                                lockPinned: false,
                                                feildLevel: obj.feildLevel,
                                                suppressSorting: false,
                                                suppressFilter: true,
                                                suppressMenu: false,
                                                hide: !obj.visibility,
                                                order: obj.order,
                                                cellEditorFramework: MultipleFileUploadEditor,
                                                cellEditorParams: {
                                                    spinner: _this.props.toggleSpinner,
                                                },
                                                cellRendererFramework: MultipleFileUploadRenderer,
                                            })
                                            break;


                                        case 'Double':
                                            if (obj.format == 'Currency') {
                                                renameColdefArray.push(
                                                    {
                                                        headerName: obj.columnTitle,
                                                        headerTooltip: obj.columnTitle,
                                                        field: obj.fieldName,
                                                        format: 'Currency',
                                                        colId: obj.fieldName,
                                                        enableRowGroup: false,
                                                        enablePivot: true,
                                                        lockPinned: false,
                                                        feildLevel: obj.feildLevel,
                                                        enableValue: true,
                                                        width: obj.width,
                                                        editable: _this.checkEdit.bind(_this),
                                                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                                                        filter: 'FilterComponent',
                                                        suppressFilter: true,
                                                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                                                        hide: !obj.visibility,
                                                        order: obj.order,
                                                        cellRendererFramework: CurrencyRenderer,
                                                        cellStyle: function (params) {
                                                            if (params.data && params.data.childCount) {
                                                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                            }
                                                            if (_this.isMandatory(params.colDef.colId)) {
                                                                if (params.node.selected) {
                                                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                                                }

                                                                if (params.value == '' || !params.value) {
                                                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                                                } else {
                                                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                                }
                                                            } else {
                                                                if (params.node.selected) {
                                                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                                                }

                                                                if (params.data && params.data.isDeleted == true) {
                                                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                                                }

                                                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                            }
                                                        },
                                                    },
                                                );
                                            } else {
                                                renameColdefArray.push(
                                                    {
                                                        headerName: obj.columnTitle,
                                                        headerTooltip: obj.columnTitle,
                                                        field: obj.fieldName,
                                                        colId: obj.fieldName,
                                                        enableRowGroup: false,
                                                        format: obj.format,
                                                        enablePivot: true,
                                                        width: obj.width,
                                                        editable: _this.checkEdit.bind(_this),
                                                        cellClass: [obj.feildLevel == 'R' ? 'read-only-cells' : '', 'ag-NumberAlignClass'],
                                                        lockPinned: false,
                                                        filter: 'FilterComponent',
                                                        suppressFilter: true,
                                                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                                                        feildLevel: obj.feildLevel,
                                                        hide: !obj.visibility,
                                                        order: obj.order,
                                                        cellStyle: function (params) {
                                                            if (params.data && params.data.childCount) {
                                                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                            }

                                                            if (_this.isMandatory(params.colDef.colId)) {
                                                                if (params.node.selected) {
                                                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                                                }

                                                                if (params.value == '' || !params.value) {
                                                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                                                } else {
                                                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                                }
                                                            } else {
                                                                if (params.node.selected) {
                                                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                                                }

                                                                if (params.data && params.data.isDeleted == true) {
                                                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                                                }

                                                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                            }
                                                        },
                                                    },
                                                );
                                            }
                                            break;
                                        case 'Date':
                                            renameColdefArray.push(
                                                {
                                                    headerName: obj.columnTitle,
                                                    headerTooltip: obj.columnTitle,
                                                    field: obj.fieldName,
                                                    width: obj.width,
                                                    enableRowGroup: false,
                                                    enablePivot: true,
                                                    lockPinned: false,
                                                    feildLevel: obj.feildLevel,
                                                    colId: obj.fieldName,
                                                    editable: _this.checkEdit.bind(_this),
                                                    cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                                                    filter: 'agDateColumnFilter',
                                                    filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                                                    format: obj.type,
                                                    hide: !obj.visibility,
                                                    order: obj.order,
                                                    cellEditorFramework: DateEditor,
                                                    cellEditorParams: {
                                                        rowUpdate: _this.rowUpdate.bind(_this),
                                                    },
                                                    cellRendererFramework: DateRenderer,
                                                    cellStyle: function (params) {
                                                        if (params.data && params.data.childCount) {
                                                            return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                        }

                                                        if (_this.isMandatory(params.colDef.colId)) {
                                                            if (params.node.selected) {
                                                                return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                                            }

                                                            if (params.value == '' || !params.value) {
                                                                return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                                            } else {
                                                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                            }
                                                        } else {
                                                            if (params.node.selected) {
                                                                return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                                            }

                                                            if (params.data && params.data.isDeleted == true) {
                                                                return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                                            }

                                                            return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                        }
                                                    },
                                                },
                                            );
                                            count++;
                                            break;
                                        case 'boolean':
                                            renameColdefArray.push(
                                                {
                                                    headerName: obj.columnTitle,
                                                    headerTooltip: obj.columnTitle,
                                                    field: obj.fieldName,
                                                    colId: obj.fieldName,
                                                    enableRowGroup: false,
                                                    enablePivot: true,
                                                    lockPinned: false,
                                                    feildLevel: obj.feildLevel,
                                                    width: 100,
                                                    editable: false,
                                                    cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                                                    suppressFilter: true,
                                                    hide: !obj.visibility,
                                                    order: obj.order,
                                                    cellRendererFramework: CheckboxRender,
                                                    cellRendererParams: {
                                                        isEditable: obj.feildLevel,
                                                        rowUpdate: _this.rowUpdate.bind(_this),
                                                        itemReadyToOrderFields: _this.state.itemReadyToOrderFields,
                                                        openPiUploadPopup: _this.openPiUploadPopup.bind(_this),
                                                        openSamplesPopup: _this.openSamplesPopup.bind(_this),
                                                        mpr2Approved: _this.state.mpr2Approved,
                                                    },
                                                    cellStyle: function (params) {
                                                        if (params.data && params.data.childCount) {
                                                            return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                        }

                                                        if (_this.isMandatory(params.colDef.colId)) {
                                                            if (params.node.selected) {
                                                                return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                                            }

                                                            if (params.value == '' || !params.value) {
                                                                return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                                            } else {
                                                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                            }
                                                        } else {
                                                            if (params.node.selected) {
                                                                return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                                            }

                                                            if (params.data && params.data.isDeleted == true) {
                                                                return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                                            }

                                                            return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                        }
                                                    },
                                                },
                                            );
                                            count++;
                                            break;
                                        case 'String':
                                            if (obj.fieldName == 'prodName') {
                                                renameColdefArray.push(
                                                    {
                                                        headerName: obj.columnTitle,
                                                        field: obj.fieldName,
                                                        fieldType: 'string',
                                                        headerTooltip: obj.columnTitle,
                                                        width: obj.width,
                                                        enableRowGroup: false,
                                                        lockPinned: false,
                                                        feildLevel: obj.feildLevel,
                                                        enablePivot: true,
                                                        colId: obj.fieldName,
                                                        editable: _this.checkEdit.bind(_this),
                                                        enableSorting: true,
                                                        filter: 'FilterComponent',
                                                        suppressFilter: true,
                                                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                                                        hide: !obj.visibility,
                                                        newRowsAction: 'keep',
                                                        order: obj.order,
                                                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                                                        cellStyle: function (params) {
                                                            if (params.data && params.data.childCount) {
                                                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                            }

                                                            if (_this.isMandatory(params.colDef.colId)) {
                                                                if (params.node.selected) {
                                                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                                                }

                                                                if (params.data && params.data.isDeleted == true) {
                                                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                                                }

                                                                if (params.value == '' || !params.value) {
                                                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                                                } else {
                                                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                                }
                                                            } else {
                                                                if (params.node.selected) {
                                                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                                                }

                                                                if (params.data && params.data.isDeleted == true) {
                                                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                                                }

                                                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                            }
                                                        },
                                                    },
                                                );
                                            } else if (obj.format == 'LargeTextArea') {
                                                renameColdefArray.push(
                                                    {
                                                        headerName: obj.columnTitle,
                                                        field: obj.fieldName,
                                                        fieldType: 'string',
                                                        format: obj.format,
                                                        headerTooltip: obj.columnTitle,
                                                        width: obj.width,
                                                        enableRowGroup: false,
                                                        lockPinned: false,
                                                        feildLevel: obj.feildLevel,
                                                        cellEditor: 'agLargeTextCellEditor',
                                                        cellEditorParams: {maxLength: obj.maxLength},
                                                        enablePivot: true,
                                                        colId: obj.fieldName,
                                                        editable: _this.checkEdit.bind(_this),
                                                        enableSorting: true,
                                                        filter: 'FilterComponent',
                                                        suppressFilter: true,
                                                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                                                        hide: !obj.visibility,
                                                        newRowsAction: 'keep',
                                                        order: obj.order,
                                                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                                                        cellStyle: function (params) {
                                                            if (params.data && params.data.childCount) {
                                                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                            }

                                                            if (_this.isMandatory(params.colDef.colId)) {
                                                                if (params.node.selected) {
                                                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                                                }

                                                                if (params.data && params.data.isDeleted == true) {
                                                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                                                }

                                                                if (params.value == '' || !params.value) {
                                                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                                                } else {
                                                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                                }
                                                            } else {
                                                                if (params.node.selected) {
                                                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                                                }

                                                                if (params.data && params.data.isDeleted == true) {
                                                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                                                }

                                                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                            }
                                                        },
                                                    },
                                                );
                                            } else {
                                                renameColdefArray.push(
                                                    {
                                                        headerName: obj.columnTitle,
                                                        field: obj.fieldName,
                                                        fieldType: 'string',
                                                        headerTooltip: obj.columnTitle,
                                                        width: obj.width,
                                                        enableRowGroup: false,
                                                        lockPinned: false,
                                                        feildLevel: obj.feildLevel,
                                                        enablePivot: true,
                                                        colId: obj.fieldName,
                                                        editable: _this.checkEdit.bind(_this),
                                                        cellClass: obj.feildLevel == 'R' ? 'read-only-cells' : '',
                                                        filter: 'FilterComponent',
                                                        suppressFilter: true,
                                                        filterParams: { apply: true, newRowsAction: 'keep', clearButton: true, getFilterOperands: _this.getFilterOperands.bind(_this), openFilterCall: _this.openFilterCall.bind(_this) },
                                                        hide: !obj.visibility,
                                                        order: obj.order,
                                                        cellStyle: function (params) {
                                                            if (params.data && params.data.childCount) {
                                                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                            }

                                                            if (_this.isMandatory(params.colDef.colId)) {
                                                                if (params.node.selected) {
                                                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                                                }

                                                                if (params.data && params.data.isDeleted == true) {
                                                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                                                }

                                                                if (params.value == '' || !params.value) {
                                                                    return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                                                                } else {
                                                                    return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                                }
                                                            } else {
                                                                if (params.node.selected) {
                                                                    return { backgroundColor: '#b7e4ff', borderRight: '1px solid lightgrey' };
                                                                }

                                                                if (params.data && params.data.isDeleted == true) {
                                                                    return { color: '#ff0000', borderRight: '1px solid lightgrey' };
                                                                }

                                                                return { backgroundColor: 'transparent', borderRight: '1px solid lightgrey' };
                                                            }
                                                        },
                                                    },
                                                );
                                                count++;
                                            }
                                            break;
                                    }
                                }
                            }
                        }
                    }
            }

            if (['catId', 'department'].includes(obj.fieldName) === false && _this.gridColumnApi) {
                _this.gridColumnApi.setColumnVisible(obj.fieldName, obj.visibility);
            }
        });

        renameColdefArray.unshift(
            {
                headerName: '',
                field: 'checkbox',
                width: 50,
                minWidth: 50,
                editable: false,
                hide: false,
                checkboxSelection: true,
                pinned: 'left',
                lockPosition: true,
                cellStyle: function (params) {
                    if (params.data) {
                        if (params.data.dataLevel == 'INTERMEDIATE') {
                            return {
                                paddingLeft: '15px',
                                paddingTop: '3px',
                                borderRight: '1px solid lightgrey',
                            };
                        }

                        if (params.data.dataLevel == 'CHILD') {
                            return {
                                paddingLeft: '30px',
                                paddingTop: '3px',
                                borderRight: '1px solid lightgrey',
                            };
                        } else {
                            return {
                                paddingLeft: '1px',
                                paddingRight: '0px',
                                paddingTop: '3px',
                                borderRight: '1px solid lightgrey',
                            };
                        }
                    }
                },
            },
        );

        if (intmExpand == true) {
            renameColdefArray.unshift(
                {
                    headerName: 'Int Id',
                    field: 'intProdId',
                    colId: 'intProdId',
                    enableRowGroup: false,
                    width: 100,
                    hide: true,
                    editable: false,
                    rowGroupIndex: 0,
                },
            );
        } else {
            renameColdefArray.unshift(
                {
                    headerName: 'Grouped Intermediate Product Id',
                    field: 'realIntProdId',
                    colId: 'realIntProdId',
                    enableRowGroup: false,
                    width: 100,
                    hide: true,
                    editable: false,
                    rowGroupIndex: 2,
                },
                {
                    headerName: 'Parent Product Id',
                    field: 'parentProductId',
                    colId: 'parentProductId',
                    enableRowGroup: false,
                    width: 100,
                    hide: true,
                    editable: false,
                    rowGroupIndex: 0,
                },
                {
                    headerName: 'Int Id',
                    field: 'intProdId',
                    colId: 'intProdId',
                    enableRowGroup: false,
                    width: 100,
                    hide: true,
                    editable: false,
                    rowGroupIndex: 1,
                },
            );
        }

        renameColdefArray.unshift(
            {
                headerName: 'Child Id',
                field: 'chldProdId',
                hide: true,
                editable: false,
            },
        );

        const renameColdefArrayLength = renameColdefArray.length;
        for (let i = 0; i < renameColdefArrayLength; i++) {
            if (renameColdefArray[i].order == null || renameColdefArray[i].order == undefined) {
                renameColdefArray[i].order = 999;
            }
        }

        renameColdefArray.sort(function (a, b) {
            return a.order - b.order;
        });

        return renameColdefArray;
    }

    /**
  * return the colors List requested by colors column for the grid.
  * @return {Array} return colors array
  */
    getColorsList() {
        return this.state.colorsList;
    }

    /**
  * return the size List requested by size column for the grid.
  * @return {Array} return size array
  */
    getSizeList() {
        return this.state.sizeList;
    }

    /**
  * return the variants List requested by variants column for the grid.
  * @return {Array} return variants array
  */
    getVariantsList() {
        return this.state.variantsList;
    }

    /**
  * return the vendorsList requested by vendors column for the grid.
  * @return {Array} return vendorsList array
  */
    getVendorsList() {
        return this.state.vendorsList;
    }

    /**
  * return the vendorsList requested by vendors column for the grid.
  * @return {Array} return vendorsList array
  */
    getAttrDropdowns() {
        return this.state.attrDropDownValues;
    }

    /**
  * return the productTypes requested by Sku Type column for the grid.
  * @return {Array} return productTypes array
  */
    getproductTypes() {
        return this.state.productTypes;
    }

    /**
  * return the buyersList requested by buyers column for the grid.
  * @return {Array} return buyersList array
  */
    getBuyersList() {
        return this.state.buyersList;
    }

    /**
  * return the categoriesList requested by categories column for the grid.
  * @return {Array} return categoriesList array
  */
    getCategoriesList() {
        return this.state.categoriesList;
    }

    /**
  * return the filterOperands requested by Filter Component.
  * @return {Array} return Filter Operands array
  */
    getFilterOperands() {
        return this.state.filterOperands;
    }

    /**
  * return the imagesList requested by images column for the grid.
  * @return {Array} return imagesList array
  */
    getImageList() {
        return this.state.imageData;
    }

    /**
  * return the channelsList requested by channel column for the grid.
  * @return {Array} return channelsList array
  */
    getChannelsList() {
        return this.state.channelsList;
    }

    /**
  * return the seasonsList requested by seasons column for the grid.
  * @return {Array} return seasonsList array
  */
    getSeasonsList() {
        return this.state.seasonsList;
    }

    /**
  * return the dispositionCodeDropdown requested by dispositionCode column for the grid.
  * @return {Array} return dispositionCodeDropdown array
  */
    dispositionCodeDropdown() {
        return this.state.dispositionCode;
    }

    /**
  * return the sellingUnitOfMeasureDropdown requested by sellingUnitOfMeasure column for the grid.
  * @return {Array} return sellingUnitOfMeasureDropdown array
  */
    sellingUnitOfMeasureDropdown() {
        return this.state.sellingUnitOfMeasure;
    }

    /**
  * return the importerOfRecordDropdown requested by importerOfRecord column for the grid.
  * @return {Array} return importerOfRecordDropdown array
  */
    importerOfRecordDropdown() {
        return this.state.importerOfRecord;
    }

    /**
  * return the itemGroupDropdown requested by itemGroup column for the grid.
  * @return {Array} return itemGroupDropdown array
  */
    itemGroupDropdown() {
        return this.state.itemGroup;
    }

    /**
  * return the brandDropdown requested by brand column for the grid.
  * @return {Array} return brandDropdown array
  */
    brandDropdown() {
        return this.state.brand;
    }

    /**
  * return the countryOfOriginDropdown requested by countryOfOrigin column for the grid.
  * @return {Array} return countryOfOriginDropdown array
  */
    countryOfOriginDropdown() {
        return this.state.countryOfOrigin;
    }

    /**
  * return the prop65CodeDropdown requested by prop65Code column for the grid.
  * @return {Array} return prop65CodeDropdown array
  */
    prop65CodeDropdown() {
        return this.state.prop65Code;
    }

    /**
  * return the subBrandDropdown requested by subBrand column for the grid.
  * @return {Array} return subBrandDropdown array
  */
    subBrandDropdown() {
        return this.state.subBrand;
    }

    /**
  * return the warrantyInfoDropdown requested by warrantyInfo column for the grid.
  * @return {Array} return warrantyInfo array
  */
    warrantyInfoDropdown() {
        return this.state.warrantyInfo;
    }

    /**
  * return the freightShippingChargeDropdown requested by freightShippingCharge column for the grid.
  * @return {Array} return freightShippingCharge array
  */
    freightShippingChargeDropdown() {
        return this.state.freightShippingCharge;
    }

    /**
  * return the proprietaryDropdown requested by proprietary column for the grid.
  * @return {Array} return proprietary array
  */
    proprietaryDropdown() {
        return this.state.proprietary;
    }

    /**
  * return the originDropdown requested by origin column for the grid.
  * @return {Array} return originDropdown array
  */
    originDropdown() {
        return this.state.origin;
    }

    /**
  * return the itemContainsMagnetOrLithiumBatteryDropdown requested by itemContainsMagnetOrLithiumBattery column for the grid.
  * @return {Array} return itemContainsMagnetOrLithiumBatteryDropdown array
  */
    itemContainsMagnetOrLithiumBatteryDropdown() {
        return this.state.itemContainsMagnetOrLithiumBattery;
    }

    /**
  * return the btbPercentageDropdown requested by btbPercentage column for the grid.
  * @return {Array} return btbPercentageDropdown array
  */
    btbPercentageDropdown() {
        return this.state.btbPercentage;
    }

    /**
  * return the employeeDiscountDropdown requested by employeeDiscount column for the grid.
  * @return {Array} return employeeDiscountDropdown array
  */
    employeeDiscountDropdown() {
        return this.state.employeeDiscount;
    }

    /**
  * return the marketingLevelDropdown requested by marketingLevel column for the grid.
  * @return {Array} return marketingLevelDropdown array
  */
    marketingLevelDropdown() {
        return this.state.marketingLevel;
    }

    /**
  * return the productStatusDropdown requested by productStatus column for the grid.
  * @return {Array} return productStatusDropdown array
  */
    productStatusDropdown() {
        return this.state.productStatus;
    }

    /**
  * return the retailStatusCodeDropdown requested by retailStatusCode column for the grid.
  * @return {Array} return retailStatusCodeDropdown array
  */
    retailStatusCodeDropdown() {
        return this.state.retailStatusCode;
    }

    /**
  * return the stageStatusDropdown requested by stageStatus column for the grid.
  * @return {Array} return stageStatusDropdown array
  */
    stageStatusDropdown() {
        return this.state.stageStatus;
    }

    /**
  * return the websiteNavigationCategoryDropdown requested by websiteNavigationCategoryDropdown column for the grid.
  * @return {Array} return websiteNavigationCategoryDropdown array
  */
    websiteNavigationCategoryDropdown() {
        return this.state.websiteNavigationCategory;
    }

    /**
  * return the planner dropdown requested by planner column for the grid.
  * @return {Array} return planner array
  */
    plannerDropdown() {
        return this.state.planner;
    }

    /**
  * return the Designer/Sourcing dropdown requested by Designer/Sourcing column for the grid.
  * @return {Array} return planner array
  */
    designerOrSourcing() {
        return this.state.designerOrSourcing;
    }

    /**
  * return the instructions dropdown requested by instructions column for the grid.
  * @return {Array} return planner array
  */
    instructions() {
        return this.state.instructions;
    }

    /**
  * return the packagingTypes dropdown requested by packagingTypes column for the grid.
  * @return {Array} return planner array
  */
    packagingTypes() {
        return this.state.packagingTypes;
    }

    /**
  * return the videoType dropdown requested by videoType column for the grid.
  * @return {Array} return planner array
  */
    videoType() {
        return this.state.videoType;
    }

    /**
  * return the hazardousShippingMessage dropdown requested by hazardousShippingMessage column for the grid.
  * @return {Array} return planner array
  */
    hazardousShippingMessage() {
        return this.state.hazardousShippingMessage;
    }

    getColDefs() {
        return this.state.columnDefs;
    }

    /**
  * sets state for imageData
  * @param {object} obj is image object
  * @return {void} does not return anything
  */
    updateUploadedImage(obj) {
        this.setState({ imageData: obj.data });
    }

    /**
  * ag-grid's default function that loads up the grid which
  * also takes care of adding new row on the grid.
  * also takes care of cloning a row on the grid.
  * also takes care of sending in the appropriate requests while sorting by a column.
  * also takes care of sending in the appropriate requests while filtering by a column.
  * also takes care of sending in the appropriate requests while grouping by a column.
  * @param {object} params is a grid oject
  * @return {void} does not return anything
  */
    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const _this = this;

        this.setState({ gridParams: params });

        const dataSource = {
            getRows: function (params) {
                // for adding new row

                if (_this.state.addNewRow) {
                    const data = _this.state.data || [];
                    const columns = _this.state.columnDefs;
                    const obj = {};
                    let mandatoryFields = [];
                    let missingCols = [];
                    /////////////////////////////////////////////

                    _this.state.mandatoryFields.map(function (obj) {
                        console.log('mandatory: ', obj);
                        if (obj.actualFieldName === 'vendorId') {
                            obj.actualFieldName = 'vendorName';
                        }

                        mandatoryFields.push({
                            'actualName': obj.actualFieldName,
                            'displayName': obj.fields
                        });
                    });

                    mandatoryFields.map((i) => {
                        columns.map((j) => {
                            if (i.actualName == j.field) {
                                missingCols.push(i.actualName);
                            }
                        });
                    });

                    let difference = [];
                    mandatoryFields.map((x) => {
                        if (!missingCols.includes(x.actualName)) {
                            difference.push(x.displayName);
                        }
                    });

                    if (difference.length > 0) {
                        NotificationManager({ message: 'The following columns are mandatory and not on the grid:<br/>' + difference.toString(), type: 4 });
                        params.successCallback(data, data.length);
                        return;
                    }

                    columns.forEach(function (col) {
                        obj[col.field] = null;
                    });

                    // sets default values when new row is added.
                    obj['buyer'] = _this.props.user.userName;
                    obj['sampleOptions'] = 'Sample';

                    data.unshift(obj);

                    _this.setState({
                        addNewRow: false,
                        selectedRow: obj,
                        data,
                        clickedOnAddRecord: !_this.state.clickedOnAddRecord,
                        newRowSaveWait: true,
                    });

                    params.successCallback(data, data.length);
                    return;
                }

                // clone a record on the grid
                if (_this.state.cloneRecord) {
                    const data = _this.state.data || [];

                    const cloneFields = _this.state.fieldsToClone;

                    const selectedRows = _this.gridApi.getSelectedRows();

                    const obj = selectedRows[0];
                    const newobj = {};

                    for (const key in obj) {
                        if (obj.hasOwnProperty(key)) {
                            // eslint-disable-next-line no-loop-func
                            cloneFields.every(function (k, i) {
                                if (key.localeCompare(k.actualFieldName) == 0 && k.fieldsToClone == true) {
                                    newobj[key] = obj[key];
                                    return false;
                                } else {
                                    newobj[key] = null;
                                    return true;
                                }
                            });
                        }
                    }

                    const columns = _this.state.columnDefs;
                    /////////////////////////////////////////////

                    const mandatoryFields = _this.state.mandatoryFields.map(function (obj) {
                        if (obj.actualFieldName === 'vendorId') {
                            return 'vendorName';
                        }
                        return obj.actualFieldName;
                    });

                    let mandatoryColumnsInGrid = [];

                    for (let i = 0; i < mandatoryFields.length; i++) {
                        for (let j = 0; j < columns.length; j++) {
                            if (mandatoryFields[i] === columns[j].field) {
                                mandatoryColumnsInGrid.push(mandatoryFields[i]);
                            }
                        }
                    }

                    const difference = mandatoryFields.filter(x => !mandatoryColumnsInGrid.includes(x));

                    if (difference.length > 0) {
                        NotificationManager({ message: 'The following columns are mandatory and not on the grid: ' + difference.toString(), type: 4 });
                    }

                    data.unshift(newobj);

                    _this.setState({
                        cloneRecord: false,
                        selectedRow: newobj,
                        data,
                    });

                    _this.gridApi.deselectAll();

                    params.successCallback(data, data.length);
                    return;
                }

                if (!_this.isEmpty(params.request.sortModel) && params.request.groupKeys.length === 0 && _this.state.startSort) {
                    const sortModel = params.request.sortModel;
                    const sortModelLength = sortModel.length;
                    const newSortModelArray = [];

                    for (let i = 0; i < sortModelLength; i++) {
                        const sortModelObject = sortModel[i];
                        const data = _this.findKeyGraphName(sortModelObject['colId']);
                        const obj = {};
                        obj.colId = data.columnName;
                        obj.sort = sortModelObject['sort'];
                        newSortModelArray.push(obj);
                    }

                    // setting up the request object requested by back end for sorting
                    const reqObject = params.request;
                    reqObject['type'] = 'sort';
                    reqObject['userRole'] = _this.props.user.defaultRole;

                    if (params.request.startRow == 0) {
                        reqObject['paginateRequest'] = false;
                    }

                    if (params.request.startRow > 0) {
                        reqObject['paginateRequest'] = true;
                    }

                    reqObject['recordCount'] = _this.state.recordCount;

                    reqObject['catIds'] = _this.state.catIds;
                    reqObject['seasons'] = _this.state.seasons;
                    reqObject['channels'] = _this.state.channels;
                    reqObject['customFilters'] = _this.state.customFilters &&
            _this.state.customFilters[0] && _this.state.customFilters[0].field && _this.state.customFilters[0].value != '' ? _this.state.customFilters : [];
                    reqObject['sortModel'] = newSortModelArray;
                    delete reqObject['filterModel'];
                    reqObject['rowGroupCols'] = reqObject['rowGroupCols'].filter((obj) => !['parentProductId', 'intProdId'].includes(obj.id));
                    const rowGroupCols = reqObject['rowGroupCols'];
                    const newRowGroupCols = [];
                    const rowGroupColsLength = rowGroupCols.length;

                    for (let i = 0; i < rowGroupColsLength; i++) {
                        if (rowGroupCols[i].field === 'realIntProdId') {
                            continue;
                        }

                        newRowGroupCols.push(_this.findKeyGraphName(rowGroupCols[i].field).columnName);
                    }

                    reqObject['rowGroupCols'] = newRowGroupCols;

                    let url = require('../common/configurations.js').product2.search;
                    let alertObj = {};

                    // This has been added to sorting works
                    // when a user redirects from the dashboard
                    // using a custom URL and no custom filter params
                    if (_this.props.gridData.fromAlerts) {
                        let alertFilterData = _this.props.gridData.priorityAlertsForSort;
                        url = alertFilterData.url;
                        alertObj['catIds'] = alertFilterData.filters.catIds;
                        alertObj['seasons'] = alertFilterData.filters.seasonIds;
                        alertObj['channels'] = alertFilterData.filters.channelIds;
                        alertObj['sortModel'] = newSortModelArray;
                    }

                    let setReqData = alertObj.sortModel ? alertObj : reqObject;

                    const headers = {
                        'Authorization': _this.props.user.token,
                        'content-type': 'application/json',
                    };

                    axios({
                        method: 'post',
                        url: url,
                        headers: headers,
                        data: setReqData,
                    })
                        .then((response) => {
                            const res = response.data;

                            if (res.status == 'success') {
                                // to add
                                if (reqObject['rowGroupCols'].length > 0) {
                                    const data = [];
                                    let tempData = res.responseData.data;

                                    for (let i = 0; i < tempData.length; i++) {
                                        let columnValue = tempData[i]['fieldValue'] + ' (' + tempData[i].totalCount + ')';

                                        if (tempData[i].fieldName === 'vendorId') {
                                            columnValue = tempData[i]['fieldValue'];
                                        }

                                        let retailPrice = 0;
                                        let onlinePrice = 0;
                                        let landedCost = 0;

                                        const summaryData = tempData[i].summaryData;

                                        if (_this.state.gridInGroupedMode) {
                                            retailPrice = summaryData[0].avg;
                                            onlinePrice = summaryData[1].avg;
                                            landedCost = summaryData[2].avg;
                                        }

                                        data.push({
                                            'parentProductId': i,
                                            [tempData[i].fieldName]: columnValue,
                                            'childCount': tempData[i].totalCount,
                                            'retailPrice': retailPrice,
                                            'onlinePrice': onlinePrice,
                                            'landedCost': landedCost,
                                        });
                                    }

                                    params.successCallback(data, data.length);
                                    return;
                                }

                                const data = res.responseData.data;
                                const dataLength = data.length;

                                for (let i = 0; i < dataLength; i++) {
                                    data[i].productId = data[i].parentProductId || data[i].intProdId || data[i].chldProdId;

                                    // setting the parent and int product ids to the intermediate records and child records respectively
                                    if (reqObject['groupKeys'].length == 1) {
                                        data[i].parentProductId = reqObject['groupKeys'][0];
                                    }

                                    if (reqObject['groupKeys'].length == 2) {
                                        data[i].parentProductId = reqObject['groupKeys'][0];
                                        data[i].intProdId = reqObject['groupKeys'][1];
                                    }
                                }

                                let count = res.responseData.recordCount ? res.responseData.recordCount : data.length;
                                params.successCallback(data, count);
                                _this.setState({ startSort: false });
                                return;
                            } else {
                                NotificationManager({ message: 'Failed to load Sort Search Data though api success', type: 4 });
                            }
                        })
                        .catch((error) => {
                            NotificationManager({ message: 'Failed to Load Sort Search Data api failure', type: 4 });
                        });

                    _this.setState({ sortModel: newSortModelArray });

                    return;
                }

                if (!_this.isEmpty(params.request.filterModel) && params.request.groupKeys.length == 0 && _this.state.filterOpened) {
                    const reqObject = params.request;
                    const filterModelObject = params.request.filterModel;
                    const filterModelArray = [];

                    // setting up the request parameters for the filter request

                    for (const key in filterModelObject) {
                        if (filterModelObject.hasOwnProperty(key)) {
                            const keyObj = filterModelObject[key];

                            for (const conditionKey in keyObj) {
                                if (keyObj.hasOwnProperty(conditionKey)) {
                                    const data = _this.findKeyGraphName(key);
                                    const obj = {};

                                    if (conditionKey == 'condition1') {
                                        obj.field = data.columnName;
                                        obj.fieldType = data.dataType;
                                        obj.operator = keyObj[conditionKey]['type'] && _this.capitalizeFirstLetter(keyObj[conditionKey]['type']);
                                        obj.value = keyObj[conditionKey]['filter'];
                                        filterModelArray.push(obj);
                                    }

                                    if (conditionKey == 'condition2') {
                                        obj.field = data.columnName;
                                        obj.fieldType = data.dataType;
                                        obj.operator = keyObj[conditionKey]['type'] && _this.capitalizeFirstLetter(keyObj[conditionKey]['type']);
                                        obj.value = keyObj[conditionKey]['filter'];
                                        obj.connector = keyObj['operator'] && _this.capitalizeFirstLetter(keyObj['operator']);
                                        filterModelArray.push(obj);
                                    }

                                    if (conditionKey == 'type') {
                                        obj.field = data.columnName;
                                        obj.fieldType = data.dataType;
                                        obj.operator = keyObj['type'] && _this.capitalizeFirstLetter(keyObj['type']);
                                        obj.value = keyObj['filter'];
                                        filterModelArray.push(obj);
                                    }
                                }
                            }
                        }
                    }

                    reqObject['customFilters'] = filterModelArray;
                    reqObject['recordCount'] = _this.state.recordCount;

                    reqObject['type'] = 'filter';
                    reqObject['userRole'] = _this.props.user.defaultRole;

                    if (params.request.startRow == 0) {
                        reqObject['paginateRequest'] = false;
                    }

                    if (params.request.startRow > 0) {
                        reqObject['paginateRequest'] = true;
                    }

                    reqObject['catIds'] = _this.state.catIds;
                    reqObject['seasons'] = _this.state.seasons;
                    reqObject['channels'] = _this.state.channels;

                    delete reqObject['filterModel'];
                    delete reqObject['rowGroupCols'];

                    const headers = {
                        'Authorization': _this.props.user.token,
                        'content-type': 'application/json',
                    };

                    axios({
                        method: 'post',
                        url: require('../common/configurations.js').product2.search,
                        headers: headers,
                        data: reqObject,
                    })
                        .then((response) => {
                            const res = response.data;

                            if (res.status === 'success') {
                                const data = res.responseData.data;
                                const dataLength = data.length;

                                for (let i = 0; i < dataLength; i++) {
                                    data[i].productId = data[i].parentProductId || data[i].intProdId || data[i].chldProdId;

                                    // setting the parent and int product ids to the intermediate records and child records respectively
                                    if (reqObject['groupKeys'].length == 1) {
                                        data[i].parentProductId = reqObject['groupKeys'][0];
                                    }

                                    if (reqObject['groupKeys'].length == 2) {
                                        data[i].parentProductId = reqObject['groupKeys'][0];
                                        data[i].intProdId = reqObject['groupKeys'][1];
                                    }
                                }

                                if (data.length === 0) {
                                    params.successCallback([], 0);
                                } else {
                                    params.successCallback(data, data.length);
                                }

                                _this.setState({ filterOpened: false });
                                return;
                            } else {
                                NotificationManager({ message: 'Failed to load Filter Search Data though api success', type: 4 });
                                return;
                            }
                        })
                        .catch((error) => {
                            console.log('error at filtering: ', error);
                            return;
                        });

                    return;
                }

                // preparation for grouping
                const rowGroupCols = params.request.rowGroupCols;

                let colGroupingRequired = null;
                let allIds;

                // checking if grouping columns are more than just parentProductId and intProdId
                if (rowGroupCols && rowGroupCols.length > 0) {
                    allIds = rowGroupCols.map((a) => a.id);
                    if (allIds.length === 3 && allIds.includes('parentProductId')
            && allIds.includes('intProdId')
            && allIds.includes('realIntProdId')
                    ) {
                        colGroupingRequired = false;
                    } else {
                        colGroupingRequired = true;
                    }
                }


                console.log('all ids: ', allIds, colGroupingRequired);
                if (params.request.startRow === 0 && colGroupingRequired && !_this.state.gridInGroupedMode && !_this.state.intmRowGroupIndexCheck) {
                    let rowGroupColsModified = allIds;
                    if (
                        allIds.includes('parentProductId')
            || allIds.includes('intProdId')
            || allIds.includes('realIntProdId')
                    ) {
                        rowGroupColsModified = allIds.filter((a) => a != 'parentProductId'
              && a != 'intProdId'
              && a != 'realIntProdId'
                        );
                    }

                    if (rowGroupColsModified.length > 1) {
                        NotificationManager({ message: 'Only one column Grouping allowed', type: 4 });
                        return;
                    }

                    const groupReqObject = params.request;
                    groupReqObject['userRole'] = _this.props.user.defaultRole;
                    groupReqObject['catIds'] = _this.state.catIds;
                    groupReqObject['seasons'] = _this.state.seasons;
                    groupReqObject['channels'] = _this.state.channels;
                    groupReqObject['recordCount'] = _this.state.recordCount;
                    groupReqObject['customFilters'] = _this.state.customFilters && _this.state.customFilters[0]
            && _this.state.customFilters[0].field && _this.state.customFilters[0].value != '' ? _this.state.customFilters : [];
                    groupReqObject['type'] = 'filter';
                    delete groupReqObject['sortModel'];
                    delete groupReqObject['filterModel'];

                    const rowGroupColsModifiedLength = rowGroupColsModified.length;
                    const groupedColumnsWithGraphName = [];
                    for (let i = 0; i < rowGroupColsModifiedLength; i++) {
                        groupedColumnsWithGraphName.push(_this.findKeyGraphName(rowGroupColsModified[i]).columnName);
                    }

                    groupReqObject['rowGroupCols'] = groupedColumnsWithGraphName;

                    const headers = {
                        'Authorization': _this.props.user.token,
                        'content-type': 'application/json',
                    };

                    axios({
                        method: 'post',
                        url: require('../common/configurations.js').product2.search,
                        headers: headers,
                        data: groupReqObject,
                    })
                        .then((response) => {
                            console.log('productContainer grouped call success data: ', response.data);

                            const res = response.data;
                            let columnName;
                            if (res.status === 'success') {
                                const tempData = res.responseData.data;
                                const data = [];

                                for (let i = 0; i < tempData.length; i++) {
                                    let columnValue = tempData[i]['fieldValue'] + ' (' + tempData[i].totalCount + ')';
                                    columnName = rowGroupColsModified[0] === 'vendorId' ? 'vendorName' : rowGroupColsModified[0];

                                    let retailPrice = 0;
                                    let onlinePrice = 0;
                                    let landedCost = 0;

                                    const summaryData = tempData[i].summaryData;
                                    retailPrice = summaryData[0].avg;
                                    onlinePrice = summaryData[1].avg;
                                    landedCost = summaryData[2].avg;

                                    data.push({
                                        'parentProductId': i,
                                        [columnName]: columnValue,
                                        'childCount': tempData[i].totalCount,
                                        'retailPrice': retailPrice,
                                        'landedCost': landedCost,
                                        'onlinePrice': onlinePrice,
                                    });
                                }
                                const columns = _this.state.columnDefs;

                                _this.setState({
                                    groupedData: tempData,
                                    gridInGroupedMode: true,
                                    groupedByColumn: rowGroupColsModified[0],
                                    columnDefs: columns,
                                }, () => {
                                    params.successCallback(data, data.length);
                                    setTimeout(() => {
                                        if (columnName === 'vendorName') {
                                            columnName = 'vendorId';
                                        }
                                        _this.gridColumnApi.setColumnPinned(columnName, 'left');
                                        _this.gridApi.getColumnDef(rowGroupColsModified[0]).headerName = _this.gridApi.getColumnDef(rowGroupColsModified[0]).headerName + '(Grouped)';
                                        _this.gridApi.refreshHeader();
                                    }, 50);
                                });
                                return;
                            } else {
                                NotificationManager({ message: 'Failed to load Grouped Data in', type: 4 });
                                return;
                            }
                        })
                        .catch((error) => {
                            NotificationManager({ message: 'Failed to Load Grouped Data', type: 4 });
                            return;
                        });
                    return;
                } else if (params.request.startRow > 0 && colGroupingRequired) {
                    // for scrolled down on grouping
                    return;
                }

                // for expanding grouped records
                if (params.request.groupKeys.length > 0 &&
          colGroupingRequired === true && _this.state.gridInGroupedMode && !_this.state.intmRowGroupIndexCheck) {
                    // for expanding the grouped record
                    if (params.request.groupKeys.length === 1) {
                        const originalData = _this.state.groupedData;
                        const data = [];

                        for (let i = 0; i < originalData.length; i++) {
                            if (i == params.request.groupKeys[0]) {
                                const tempArray = originalData[i].productData;

                                for (let j = 0; j < tempArray.length; j++) {
                                    tempArray[j].productId = tempArray[j].parentProductId || tempArray[j].intProdId || tempArray[j].chldProdId;

                                    if (tempArray[j] && tempArray[j].parentProductId) {
                                        tempArray[j].intProdId = tempArray[j].parentProductId;
                                    }

                                    if (tempArray[j] && tempArray[j].intProdId) {
                                        tempArray[j].realIntProdId = tempArray[j].intProdId;
                                    }

                                    data.push(tempArray[j]);
                                }
                                break;
                            }
                        }

                        params.successCallback(data, data.length);
                        return;
                    }

                    // for expanding the parent product id within grouped record
                    if (params.request.groupKeys.length === 2) {
                        const originalData = _this.state.groupedData;
                        let productData;

                        for (let i = 0; i < originalData.length; i++) {
                            if (i == params.request.groupKeys[0]) {
                                productData = originalData[i].productData;
                                break;
                            }
                        }

                        let childIds;
                        for (let i = 0; i < productData.length; i++) {
                            if (productData[i].parentProductId === params.request.groupKeys[1]) {
                                childIds = productData[i].childrenIds;
                            }
                        }

                        const reqObject = params.request;
                        reqObject['groupKeys'] = [params.request.groupKeys[1]];
                        reqObject['prodIds'] = childIds;
                        delete reqObject['filterModel'];
                        reqObject['userRole'] = _this.props.user.defaultRole;
                        reqObject['filterModel'] = [];
                        reqObject['catIds'] = _this.state.catIds;
                        reqObject['seasons'] = _this.state.seasons;
                        reqObject['channels'] = _this.state.channels;

                        const sortModel = params.request.sortModel;
                        const sortModelLength = sortModel.length;
                        const newSortModelArray = [];

                        for (let i = 0; i < sortModelLength; i++) {
                            const sortModelObject = sortModel[i];
                            const data = _this.findKeyGraphName(sortModelObject['colId']);
                            const obj = {};
                            obj.colId = data.columnName;
                            obj.sort = sortModelObject['sort'];
                            newSortModelArray.push(obj);
                        }

                        reqObject['sortModel'] = newSortModelArray;

                        delete reqObject['rowGroupCols'];

                        const headers = {
                            'Authorization': _this.props.user.token,
                            'content-type': 'application/json',
                        };

                        axios({
                            method: 'post',
                            url: require('../common/configurations.js').product2.search,
                            headers: headers,
                            data: reqObject,
                        })
                            .then((response) => {
                                const res = response.data;

                                if (res.status == 'success') {
                                    const data = res.responseData.data;
                                    _this.setState({ groupedIntData: data });
                                    const dataLength = data.length;

                                    for (let i = 0; i < dataLength; i++) {
                                        data[i].productId = data[i].parentProductId || data[i].intProdId || data[i].chldProdId;
                                        data[i].realIntProdId = data[i] && data[i].intProdId ? data[i].intProdId : null;

                                        // setting the parent and int product ids to the intermediate records and child records respectively
                                        if (reqObject['groupKeys'].length == 2) {
                                            data[i].parentProductId = reqObject['groupKeys'][1];
                                        }

                                        if (reqObject['groupKeys'].length == 3) {
                                            data[i].parentProductId = reqObject['groupKeys'][1];
                                            data[i].realIntProdId = reqObject['groupKeys'][2];
                                        }
                                    }

                                    params.successCallback(data, data.length);
                                    return;
                                } else {
                                    NotificationManager({ message: 'Failed to load Intermediate Data in', type: 4 });
                                    return;
                                }
                            })
                            .catch((error) => {
                                NotificationManager({ message: 'Failed to Load Intermediate Data', type: 4 });
                                return;
                            });

                        return;
                    }

                    // for expanding the intermediate product id
                    if (params.request.groupKeys.length === 3) {
                        console.log(' grouped by column: ', _this.state.groupedByColumn);
                        let childIds;
                        const groupedIntData = _this.state.groupedIntData;
                        for (let i = 0; i < groupedIntData.length; i++) {
                            if (groupedIntData[i].intProdId === params.request.groupKeys[2]) {
                                childIds = groupedIntData[i].childrenIds;
                            }
                        }

                        const reqObject = params.request;
                        reqObject['groupKeys'] = [params.request.groupKeys[1], params.request.groupKeys[2]];
                        reqObject['prodIds'] = childIds;
                        delete reqObject['filterModel'];
                        reqObject['userRole'] = _this.props.user.defaultRole;
                        reqObject['filterModel'] = [];
                        reqObject['catIds'] = _this.state.catIds;
                        reqObject['seasons'] = _this.state.seasons;
                        reqObject['channels'] = _this.state.channels;

                        const sortModel = params.request.sortModel;
                        const sortModelLength = sortModel.length;
                        const newSortModelArray = [];

                        for (let i = 0; i < sortModelLength; i++) {
                            const sortModelObject = sortModel[i];
                            const data = _this.findKeyGraphName(sortModelObject['colId']);
                            const obj = {};
                            obj.colId = data.columnName;
                            obj.sort = sortModelObject['sort'];
                            newSortModelArray.push(obj);
                        }

                        reqObject['sortModel'] = newSortModelArray;

                        delete reqObject['rowGroupCols'];

                        const headers = {
                            'Authorization': _this.props.user.token,
                            'content-type': 'application/json',
                        };

                        axios({
                            method: 'post',
                            url: require('../common/configurations.js').product2.search,
                            headers: headers,
                            data: reqObject,
                        })
                            .then((response) => {
                                const res = response.data;

                                if (res.status == 'success') {
                                    const data = res.responseData.data;
                                    const dataLength = data.length;
                                    for (let i = 0; i < dataLength; i++) {
                                        data[i].productId = data[i].parentProductId || data[i].intProdId || data[i].chldProdId;
                                        delete data[i][_this.state.groupedByColumn];
                                    }

                                    params.successCallback(data, data.length);
                                    return;
                                } else {
                                    NotificationManager({ message: 'Failed to load Intermediate Data in', type: 4 });
                                    return;
                                }
                            })
                            .catch((error) => {
                                NotificationManager({ message: 'Failed to Load Intermediate Data ' + error, type: 4 });
                                return;
                            });

                        return;
                    }

                    if (params.request.groupKeys.length > 3) {
                        params.successCallback([], 0);
                        return;
                    }

                    return;
                }

                // for opening grouped parent /intermediate / child'
                if (params.request.groupKeys.length > 0 &&
          params.parentNode && params.parentNode.data &&
          params.parentNode.data.childrenIds && !_this.state.intmRowGroupIndexCheck &&
          colGroupingRequired === false) {
                    _this.setState({ rowClass: "child-row", fetchChildren: true });
                    const reqObject = params.request;
                    reqObject['prodIds'] = params.parentNode.data.childrenIds;
                    delete reqObject['filterModel'];
                    reqObject['userRole'] = _this.props.user.defaultRole;
                    reqObject['filterModel'] = [];
                    reqObject['customFilters'] = _this.state.customFilters;
                    reqObject['catIds'] = _this.state.catIds;
                    reqObject['seasons'] = _this.state.seasons;
                    reqObject['channels'] = _this.state.channels;

                    const sortModel = params.request.sortModel;
                    const sortModelLength = sortModel.length;
                    const newSortModelArray = [];

                    for (let i = 0; i < sortModelLength; i++) {
                        const sortModelObject = sortModel[i];
                        const data = _this.findKeyGraphName(sortModelObject['colId']);
                        const obj = {};
                        obj.colId = data.columnName;
                        obj.sort = sortModelObject['sort'];
                        newSortModelArray.push(obj);
                    }

                    reqObject['sortModel'] = newSortModelArray;

                    delete reqObject['rowGroupCols'];

                    const headers = {
                        'Authorization': _this.props.user.token,
                        'content-type': 'application/json',
                    };

                    axios({
                        method: 'post',
                        url: require('../common/configurations.js').product2.search,
                        headers: headers,
                        data: reqObject,
                    })
                        .then((response) => {
                            const res = response.data;

                            if (res.status == 'success') {
                                const data = res.responseData.data;
                                const dataLength = data.length;

                                for (let i = 0; i < dataLength; i++) {
                                    data[i].productId = data[i].parentProductId || data[i].intProdId || data[i].chldProdId;

                                    // setting the parent and int product ids to the intermediate records and child records respectively
                                    if (reqObject['groupKeys'].length == 1) {
                                        data[i].parentProductId = reqObject['groupKeys'][0];
                                    }

                                    if (reqObject['groupKeys'].length == 2) {
                                        data[i].parentProductId = reqObject['groupKeys'][0];
                                        data[i].intProdId = reqObject['groupKeys'][1];
                                    }
                                }

                                params.successCallback(data, data.length);
                                _this.setState({ fetchChildren: false });

                                // Added a timeout here because the grid
                                // takes time to redraw with new data
                                setTimeout(() => {
                                    _this.checkChildRecords();
                                }, 500);

                                return;
                            } else {
                                NotificationManager({ message: 'Failed to load Intermediate Data in', type: 4 });
                                return;
                            }
                        })
                        .catch((error) => {
                            NotificationManager({ message: 'Failed to Load Intermediate Data', type: 4 });
                            return;
                        });

                    return;
                } else if (params.request.groupKeys.length > 0 &&
          params.parentNode && params.parentNode.data &&
          params.parentNode.data.childrenIds &&
          _this.state.intmRowGroupIndexCheck) {
                    const reqObject = params.request;
                    reqObject['prodIds'] = params.parentNode.data.childrenIds;
                    delete reqObject['filterModel'];
                    reqObject['userRole'] = _this.props.user.defaultRole;
                    reqObject['filterModel'] = [];
                    reqObject['catIds'] = _this.state.catIds;
                    reqObject['seasons'] = _this.state.seasons;
                    reqObject['channels'] = _this.state.channels;

                    const sortModel = params.request.sortModel;
                    const sortModelLength = sortModel.length;
                    const newSortModelArray = [];

                    for (let i = 0; i < sortModelLength; i++) {
                        const sortModelObject = sortModel[i];
                        const data = _this.findKeyGraphName(sortModelObject['colId']);
                        const obj = {};
                        obj.colId = data.columnName;
                        obj.sort = sortModelObject['sort'];
                        newSortModelArray.push(obj);
                    }

                    reqObject['sortModel'] = newSortModelArray;
                    delete reqObject['rowGroupCols'];

                    const headers = {
                        'Authorization': _this.props.user.token,
                        'content-type': 'application/json',
                    };

                    axios({
                        method: 'post',
                        url: require('../common/configurations.js').product2.search,
                        headers: headers,
                        data: reqObject,
                    })
                        .then((response) => {
                            const res = response.data;

                            if (res.status == 'success') {
                                const data = res.responseData.data;
                                const dataLength = data.length;

                                for (let i = 0; i < dataLength; i++) {
                                    data[i].productId = data[i].parentProductId || data[i].intProdId || data[i].chldProdId;
                                    // setting the parent and int product ids to the intermediate records and child records respectively
                                    if (reqObject['groupKeys'].length == 1) {
                                        data[i].parentProductId = reqObject['groupKeys'][0];
                                    }
                                    if (reqObject['groupKeys'].length == 2) {
                                        data[i].parentProductId = reqObject['groupKeys'][0];
                                        data[i].intProdId = reqObject['groupKeys'][1];
                                    }
                                }

                                params.successCallback(data, data.length);

                                return;
                            } else {
                                NotificationManager({ message: 'Failed to load Intermediate Data in', type: 4 });
                                return;
                            }
                        })
                        .catch((error) => {
                            NotificationManager({ message: 'Failed to Load Intermediate Data', type: 4 });
                            return;
                        });

                    return;
                }

                // resetting grid to ungrouped original mode for ungrouping
                if (!colGroupingRequired && _this.state.gridInGroupedMode === true) {
                    const reqObject = params.request;

                    if (params.request.startRow == 0) {
                        reqObject['paginateRequest'] = false;
                    } else {
                        reqObject['paginateRequest'] = true;
                    }
                    reqObject['userRole'] = _this.props.user.defaultRole;
                    reqObject['filterModel'] = [];

                    reqObject['catIds'] = _this.state.catIds;
                    reqObject['seasons'] = _this.state.channels;
                    reqObject['channels'] = _this.state.seasons;
                    reqObject['recordCount'] = _this.state.recordCount;
                    reqObject['customFilters'] = _this.state.customFilters && _this.state.customFilters[0]
            && _this.state.customFilters[0].field && _this.state.customFilters[0].value != '' ? _this.state.customFilters : [];
                    reqObject['type'] = 'filter';
                    reqObject['sortModel'] = _this.state.sortModel;

                    delete reqObject['rowGroupCols'];

                    const headers = {
                        'Authorization': _this.props.user.token,
                        'content-type': 'application/json',
                    };

                    axios({
                        method: 'post',
                        url: require('../common/configurations.js').product2.search,
                        headers: headers,
                        data: reqObject,
                    })
                        .then((response) => {
                            const res = response.data;

                            if (res.status == 'success') {
                                const data = res.responseData.data;
                                const dataLength = data.length;

                                for (let i = 0; i < dataLength; i++) {
                                    data[i].productId = data[i].parentProductId || data[i].intProdId || data[i].chldProdId;
                                }

                                _this.setState({
                                    gridInGroupedMode: false,
                                    data: data,
                                });

                                params.successCallback(data, data.length);
                                setTimeout(() => {
                                    _this.gridColumnApi.setColumnPinned(_this.state.groupedByColumn, null);
                                    _this.gridApi.getColumnDef(_this.state.groupedByColumn).headerName = _this.gridApi.getColumnDef(_this.state.groupedByColumn).headerName.replace('(Grouped)', '');
                                    _this.gridApi.refreshHeader();
                                }, 50);

                                return;
                            } else {
                                NotificationManager({ message: 'Failed to load Scroll Data in', type: 4 });
                                return;
                            }
                        })
                        .catch((error) => {
                            NotificationManager({ message: 'Failed to Load Scroll Data', type: 4 });
                        });

                    return;
                }

                // for aggregation
                if (params.request.startRow === 0 && colGroupingRequired && _this.state.gridInGroupedMode &&
          _this.state.aggregationCalled === true && !_this.state.intmRowGroupIndexCheck) {
                    let rowGroupColsModified = allIds;
                    if (allIds.includes('parentProductId') || allIds.includes('intProdId') || allIds.includes('realIntProdId')) {
                        rowGroupColsModified = allIds.filter((a) => a != 'parentProductId' && a != 'intProdId' && a != 'realIntProdId');
                    }
                    const groupedDataForAggregation = _this.state.groupedData;
                    const aggregationState = _this.state.aggregationState;

                    const data = [];
                    for (let i = 0; i < groupedDataForAggregation.length; i++) {
                        const summaryData = groupedDataForAggregation[i].summaryData;
                        let columnValue = groupedDataForAggregation[i]['fieldValue'] + ' (' + groupedDataForAggregation[i].totalCount + ')';

                        if (rowGroupColsModified[0] === 'vendorId') {
                            columnValue = groupedDataForAggregation[i][rowGroupColsModified[0]];
                        }

                        let retailPrice = 0;
                        let onlinePrice = 0;
                        let landedCost = 0;

                        for (const column in aggregationState) {
                            if (aggregationState.hasOwnProperty(column)) {
                                if (column === 'retailPrice') {
                                    retailPrice = summaryData[0][aggregationState['retailPrice']];
                                }
                                if (column === 'onlinePrice') {
                                    onlinePrice = summaryData[1][aggregationState['onlinePrice']];
                                }
                                if (column === 'landedCost') {
                                    landedCost = summaryData[2][aggregationState['landedCost']];
                                }
                            }
                        }

                        _this.setState({ aggregationCalled: false });

                        data.push({
                            'parentProductId': i,
                            [rowGroupColsModified[0]]: columnValue,
                            'childCount': groupedDataForAggregation[i].totalCount,
                            'retailPrice': retailPrice,
                            'onlinePrice': onlinePrice,
                            'landedCost': landedCost,
                        });
                    }

                    params.successCallback(data, data.length);
                    return;
                }

                // resetting grid to unsorted of grouped mode
                if (colGroupingRequired === true && params.request.sortModel && params.request.sortModel.length === 0 && !_this.state.intmRowGroupIndexCheck) {
                    const tempData = _this.state.groupedData;
                    const data = [];

                    for (let i = 0; i < tempData.length; i++) {
                        let columnValue = tempData[i]['fieldValue'] + ' (' + tempData[i].totalCount + ')';

                        if (tempData[i].fieldName === 'vendorId') {
                            columnValue = tempData[i]['fieldValue'];
                        }

                        data.push({
                            'parentProductId': i,
                            [tempData[i].fieldName]: columnValue,
                            'childCount': tempData[i].totalCount,
                        });
                    }

                    params.successCallback(data, data.length);
                    return;
                }

                params.successCallback(_this.state.data, _this.state.recordCount);
                if (_this.state.data && _this.state.data.length === 0) {
                    _this.gridApi.showNoRowsOverlay();
                }
            },
        };

        this.refs.agGrid && this.refs.agGrid.api && this.refs.agGrid.api.setServerSideDatasource && this.refs.agGrid.api.setServerSideDatasource(dataSource);
    }

    onColumnPinned(params) {
        if (params && params.pinned === "right") {
            $('.ag-body-viewport').css({ 'overflow-y': 'hidden' });
        } else if (params && params.pinned === null && params.columns.length > 0) {
            $('.ag-body-viewport').css({ 'overflow-y': 'auto' });
        }

        let headerListWidth = $('.ag-pinned-right-cols-viewport-wrapper').width();
        $('.ag-pinned-right-header').css({ 'width': (headerListWidth) + "px" });
    }

    /**
   * is used to reload the grid with the updated values.
   */
    reloadGrid() {
        const _this = this;
        _this.props.toggleSpinner(true);
        let url = require('../common/configurations.js').product2.search;
        let setReqData = {};

        if (_this.props.gridData.fromAlerts) {
          let alertFilterData = _this.props.gridData.priorityAlertsForSort;
          url = alertFilterData.url;
          setReqData['catIds'] = alertFilterData.filters.catIds;
          setReqData['seasons'] = alertFilterData.filters.seasonIds;
          setReqData['channels'] = alertFilterData.filters.channelIds;
        }

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        axios({
            method: 'post',
            url: url,
            headers: headers,
            data: this.state.gridData.req ? this.state.gridData.req : setReqData,
        })
            .then((response) => {
                const gridData = response.data.responseData.data;
                const dataLength = gridData.length;
                for (let i = 0; i < dataLength; i++) {
                    gridData[i]['productId'] = gridData[i].parentProductId || gridData[i].intProdId || gridData[i].chldProdId;
                }

                const recordCount = response.data.responseData.recordCount;
                this.setState({ data: gridData, recordCount });

                const product2GridData = this.props.gridData;
                product2GridData['data'] = response.data.responseData.data;
                _this.props.addProduct2GridData(product2GridData);
                _this.props.filterDataChanged();

                this.onGridReady(this.state.gridParams);
                _this.props.toggleSpinner(false);
            })
            .catch((error) => {
                _this.props.toggleSpinner(false);
                NotificationManager({ message: error.response.data?.message || 'An error occured while fetching the latest data. Please update the search filter and try again.', type: 4 });
            });
    }


    onSelectionChanged() {
        const selectedRows = this.gridApi.getSelectedRows();
        this.setState({ selectedRowCount: selectedRows.length, selectedRows: selectedRows });
        this.props.addSelectedRows(selectedRows);
    }

    checkChildRecords() {
        const _this = this;

        _this.state.selectedRows.map((i) => {
            _this.gridApi.forEachNode((node) => {
                if (i.childrenIds.length > 0) {
                    if (node.data && i.productId == node.data.productId) {
                        node.childStore && node.childStore.allRowNodes.map((childNode) => {
                            childNode.setSelected(true);
                        });
                    }
                }
            });
        });
    }

    checkParentRecord(selectedNode) {
        let flag = selectedNode.node.parent.childStore.allRowNodes.every(j => j.selected);
        let currentNode = selectedNode.node.parent;

        if (flag) {
            currentNode.setSelected(true);
        }
    }

    onRowSelected(params) {
        if (!params.node.selected) {
            if (params.node.level == 0 && !this.state.checkedRecord) {
                params.node.childStore && params.node.childStore.allRowNodes.forEach((i) => {
                    i.setSelected(false);

                    i.childStore.allRowNodes.forEach((j) => {
                        j.setSelected(false);
                    });
                });

                this.setState({ checkedRecord: true });
            }
            if (params.node.level == 1 && !this.state.checkedRecord) {
                params.node.parent.setSelected(false);
                params.node.childStore && params.node.childStore.allRowNodes.forEach((i) => {
                    i.setSelected(false);
                });

                this.setState({ checkedRecord: true });
            }

            if (params.node.level == 2 && !this.state.checkedRecord) {
                params.node.parent.setSelected(false);
                params.node.parent.parent.setSelected(false);

                this.setState({ checkedRecord: true });
            }
        }

        if (params.node.selected) {
            if (params.node.level == 0 || params.node.level == 1) {
                params.node.childStore && params.node.childStore.allRowNodes.forEach((i) => {
                    i.setSelected(true);
                });
            }

            if (params.node.level == 2) {
                params.node.setSelected(true);
            }

            this.checkParentRecord(params);
        }

        setTimeout(() => {
            this.setState({ checkedRecord: false });
        }, 500);
    }

    /**
   *  is used to clone the selected records and perform validation while cloning
   */
    cloneRecords() {
        const selectedRows = this.gridApi.getSelectedRows();

        if (selectedRows.length == 1) {
            this.toggleRecordOptions();
            this.setState({ cloneRecord: true, enableSaveRecordButton: true });
            this.onGridReady(this.state.gridParams);
        } else if (selectedRows.length == 0) {
            this.toggleRecordOptions();
            NotificationManager({ message: 'Please select a record to clone', type: 4 });
        } else {
            this.toggleRecordOptions();
            NotificationManager({ message: 'Only one record can be cloned at a time', type: 4 });
        }
    }


    onBtRemove() {
        const selectedRows = this.gridApi.getSelectedNodes();
        if (!selectedRows || selectedRows.length === 0) {
            return;
        }

        this.gridApi.purgeServerSideCache();
    }

    /**
   *  is used to check if a column is mandatory or not
   * @param {string} column is a string
   * @return {string}
   */
    isMandatory(column) {
        let status = false;
        if (column && this.state.mandatoryFields) {
            const mandatoryFields = this.state.mandatoryFields;
            const mandatoryFieldsLength = mandatoryFields.length;
            for (let i = 0; i < mandatoryFieldsLength; i++) {
                if (mandatoryFields[i].actualFieldName == column) {
                    status = true;
                    break;
                }
            }
        }
        return status;
    }

    /**
   * this field creates the set of mandatory fields that is not populated yet.
   * @param {object} data is an object
   * @return {Array}
   */
    mandatoryFieldsCheck(data) {
        const mandatoryFields = this.state.mandatoryFields.map(function (obj) {
            return { [obj.actualFieldName]: obj.fields };
        });

        const stringofMandatoryFields = [];

        mandatoryFields.forEach((obj) => {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    stringofMandatoryFields.push(key);
                }
            }
        });

        const mandatoryFieldsNotPopulated = [];

        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                if (stringofMandatoryFields.indexOf(key) != -1) {
                    if (!data[key]) {
                        for (let i = 0; i < mandatoryFields.length; i++) {
                            for (const k in mandatoryFields[i]) {
                                if (mandatoryFields[i].hasOwnProperty(k)) {
                                    if (k == key) {
                                        mandatoryFieldsNotPopulated.push(mandatoryFields[i][k]);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return mandatoryFieldsNotPopulated;
    }


    onBtSave() {
        const _this = this;

        const rowNode = this.gridApi.getRowNode('0');
        const data = rowNode.data;


        /////////////////////////mandatory fields check//////////////////////////////////
        const fieldsNotPopulated = this.mandatoryFieldsCheck(data);

        if (fieldsNotPopulated.length) {
            NotificationManager({ message: 'The following fields are mandatory. ' + fieldsNotPopulated.toString(), type: 4 });
            return;
        }
        //////////////////////////////////////////////////////////////////////////////////////


        const date = data['dateAdded'];
        data['dateAdded'] = date ? new Date(date) : new Date();

        const buyer = data['buyer'];
        data['buyer'] = buyer ? buyer : this.props.user.userName;

        data['copyLevel'] = 'Color'; // defaulted to Color
        data['displayLevel'] = 'Color'; // defaulted to Color

        const dataWithoutNullValues = {};

        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                if (data[key]) {
                    dataWithoutNullValues[key] = data[key];
                }
            }
        }

        const dataArray = [];
        dataArray.push(dataWithoutNullValues);

        console.log('row data while saving new: ', dataWithoutNullValues);
        // pushing required data to an object to make it compatible for the api to consume
        const obj = {
            'productDetails': dataArray,
            'userRole': this.props.user.defaultRole,
        };

        ProductServices.addUpdateProduct(obj)
            .then((res) => {
                if (res.status) {
                    let recordCount = this.state.recordCount;
                    recordCount = recordCount + 1;

                    _this.setState({
                        recordCount,
                        enableSaveRecordButton: false,
                    });

                    _this.reloadGrid();

                    NotificationManager({ message: res.message, type: 1 });
                } else {
                    NotificationManager({ message: res.responseMessage, type: 4 });
                }
            })
            .catch((error) => {
                console.log('Server Error Failed', error);
            });
    }

    /**
   * adds a record to the grid
   */
    onBtAdd() {
        $('.ag-body-viewport').animate({ scrollLeft: '0' }, 500);

        this.setState({ addNewRow: true }, () => {
            setTimeout(() => {
                this.onGridReady(this.state.gridParams);
            }, 500);
        });
    }


    /**
   * toggles state variable to pull down the dropdown that opens up while adding new record to grid
   */
    toggleRecordOptions() {
        this.setState((prevState, props) => (
            {
                clickedOnAddRecord: !prevState.clickedOnAddRecord,
            })
        );
    }

    /**
   * toggles state variable to pull down the dropdown that opens up while adding new record to grid
   */
    toggleViewBy() {
        this.setState({ clickedOnViewBy: !this.state.clickedOnViewBy });
    }

    /**
   * toggles state variable to pull down the dropdown that opens up while adding new record to grid
   *
   * @param {oject} row is a object
   */
    openMultiProductPopup(row) {
        this.setState({ showMultiProduct: true, multiProductRow: row });
    }

    /**
  * closes the multi product popup
  */
    closeMultiProductPopup() {
        this.setState({ showMultiProduct: false });
    }

    /**
  * opens add new vendor popup
  * @param {object} rowData
  * @return {void}
  */
    openAddNewVendorPopup(rowData) {
        this.setState({ vendorRowData: rowData, addNewVendor: true });
    }

    /**
  * closes add new vendor popup
  */
    closeAddNewVendorPopup() {
        this.setState({ addNewVendor: false });
        this.updatedVendorId();
    }

    /**
  * opens copywriter popup
  * @param {object} copyData
  * @param {object} rowData
  * @param {object} rowNode
  * @return {void}    *
  */
    openCopyWritePopup(copyData, rowData, rowNode) {
        this.setState({ copyData: copyData, copyRowData: rowData, copyRowNode: rowNode, copyWritePopup: true });
    }

    /**
  * closes copywriter popup
  * @param {string} status is a string
  *
  */
    closeCopyWritePopup(status) {
        const _this = this;
        const rows = [];
        const copyRowNode = this.state.copyRowNode;
        let colorIntm = true;
        let colorIntmParent = true;

        copyRowNode.data['copyStatus'] = status;
        rows.push(copyRowNode);

        this.gridApi.forEachNode(function (rowNode) {
            const checkChilds = copyRowNode.data.childrenIds;
            if (checkChilds.includes(rowNode.data.productId) && copyRowNode.data.dataLevel == 'INTERMEDIATE') {
                rowNode.data['copyStatus'] = status;
                rows.push(rowNode);
            }

            if (checkChilds.includes(rowNode.data.productId) && copyRowNode.data.dataLevel == 'PARENT') {
                rowNode.data['copyStatus'] = status;
                rows.push(rowNode);

                _this.gridApi.forEachNode(function (rowNodeChild) {
                    if (rowNode.data.intProdId == rowNodeChild.data.productId) {
                        _this.gridApi.forEachNode(function (rowNodeChild2) {
                            if (rowNodeChild.data.childrenIds.includes(rowNodeChild2.data.productId)) {
                                rowNodeChild2.data['copyStatus'] = status;
                                rows.push(rowNodeChild2);
                            }
                        });
                    }
                });
            }

            if (copyRowNode.data.dataLevel == 'CHILD') {
                _this.gridApi.forEachNode(function (rowNodeChild) {
                    if (copyRowNode.data.intProdId == rowNodeChild.data.productId) {
                        rowNodeChild.data.childrenIds.forEach((item, index) => {
                            _this.gridApi.forEachNode(function (rowNodeChild2) {
                                if (rowNodeChild2.data.productId == item) {
                                    if (!rowNodeChild2.data['copyStatus']) {
                                        colorIntm = false;
                                    }

                                    if (rowNodeChild2.data.copyStatus != status) {
                                        colorIntm = false;
                                    }
                                }
                            });
                        });

                        if (colorIntm) {
                            rowNodeChild.data['copyStatus'] = status;
                            rows.push(rowNodeChild);
                        }
                    }
                });
            }

            if (copyRowNode.data.parentProductId == rowNode.data.productId) {
                rowNode.data.childrenIds.forEach((item, index) => {
                    _this.gridApi.forEachNode(function (rowNodeParent2) {
                        if (rowNodeParent2.data.productId == item) {
                            if (!rowNodeParent2.data['copyStatus']) {
                                colorIntmParent = false;
                            }

                            if (rowNodeParent2.data.copyStatus != status) {
                                colorIntmParent = false;
                            }
                        }
                    });
                });

                if (colorIntmParent) {
                    rowNode.data['copyStatus'] = status;
                    rows.push(rowNode);
                }
            }
        });

        this.setState({ copyWritePopup: false });
        this.gridApi.redrawRows({ rowNodes: rows });
    }

    /**
  * opens keyword popup
  * @param {object} keywordData
  * @param {object} rowData
  * @param {object} rowNode
  * @return {void}
  *
  */
    openKeywordPopup(keywordData, rowData, rowNode) {
        this.setState({ keywordData: keywordData, keywordRowData: rowData, keywordRowNode: rowNode, keywordPopup: true });
    }

    /**
  * closes keyword popup
  * @param {Boolean} status
  */
    closeKeywordPopup(status) {
        const _this = this;
        const rows = [];
        const keywordRowNode = this.state.keywordRowNode;
        let colorParent = true;
        let colorIntm = true;
        let colorIntmParent = true;

        keywordRowNode.data['keywordsStatus'] = status;
        rows.push(keywordRowNode);

        this.gridApi.forEachNode(function (rowNode) {
            const checkChilds = keywordRowNode.data.childrenIds;

            if (checkChilds.includes(rowNode.data.productId) && keywordRowNode.data.dataLevel == 'INTERMEDIATE') {
                rowNode.data['keywordsStatus'] = status;
                rows.push(rowNode);
            }

            if (checkChilds.includes(rowNode.data.productId) && keywordRowNode.data.dataLevel == 'PARENT') {
                rowNode.data['keywordsStatus'] = status;
                rows.push(rowNode);

                _this.gridApi.forEachNode(function (rowNodeChild) {
                    if (rowNode.data.intProdId == rowNodeChild.data.productId) {
                        _this.gridApi.forEachNode(function (rowNodeChild2) {
                            if (rowNodeChild.data.childrenIds.includes(rowNodeChild2.data.productId)) {
                                rowNodeChild2.data['keywordsStatus'] = status;
                                rows.push(rowNodeChild2);
                            }
                        });
                    }
                });
            }

            if (keywordRowNode.data.dataLevel == 'CHILD') {
                _this.gridApi.forEachNode(function (rowNodeChild) {
                    if (keywordRowNode.data.intProdId == rowNodeChild.data.productId) {
                        rowNodeChild.data.childrenIds.forEach((item, index) => {
                            _this.gridApi.forEachNode(function (rowNodeChild2) {
                                if (rowNodeChild2.data.productId == item) {
                                    if (!rowNodeChild2.data['keywordsStatus']) {
                                        colorIntm = false;
                                    }

                                    if (rowNodeChild2.data.keywordsStatus != status) {
                                        colorIntm = false;
                                    }
                                }
                            });
                        });

                        if (colorIntm) {
                            rowNodeChild.data['keywordsStatus'] = status;
                            rows.push(rowNodeChild);
                        }
                    }
                });
            }

            if (keywordRowNode.data.parentProductId == rowNode.data.productId) {
                rowNode.data.childrenIds.forEach((item, index) => {
                    _this.gridApi.forEachNode(function (rowNodeParent2) {
                        if (rowNodeParent2.data.productId == item) {
                            if (!rowNodeParent2.data['keywordsStatus']) {
                                colorIntmParent = false;
                            }

                            if (rowNodeParent2.data.keywordsStatus != status) {
                                colorIntmParent = false;
                            }
                        }
                    });
                });

                if (colorIntmParent) {
                    rowNode.data['keywordsStatus'] = status;
                    rows.push(rowNode);
                }
            }
        });

        this.setState({ keywordPopup: false });
        this.gridApi.redrawRows({ rowNodes: rows });
    }

    /**
  * opens pi upload popup
  * @param {object} rowData
  * @return {void}
  */
    openPiUploadPopup(rowData) {
        this.setState({ piUploadRowData: rowData, piUploadPopup: true });
    }

    /**
  * closes pi upload popup
  */
    closePiUploadPopup() {
        this.setState({ piUploadPopup: false });
    }

    /**
  * opens swatch reference popup
  */
    openSwatchPopup(node) {
        this.setState({ swatchReferencePopup: true, swatchReferenceNode: node });
    }

    /**
  * closes swatch reference popup
  */
    closeSwatchPopup() {
        this.setState({ swatchReferencePopup: false });
    }


    /**
  * opens samples popup
  * @param {object} productData
  */
    openSamplesPopup(productData) {
        console.log('openSamplesPopup: ', productData);
        this.setState({ openSamplesPopup: true, samplePopupData: productData });
    }

    /**
  * closes samples popup
  */
    closeSamplesPopup() {
        this.setState({ openSamplesPopup: false });
    }


    /**
  * updates cell values in the database when we tab out of one cell
  * to another.
  */
    rowUpdate(selectedRow, rowIndex, fieldName, paramNode) {
        const _this = this;

        const data = selectedRow;

        // Stops multiple api calls when bulk update is done
        if (_this.state.showBulkUpdatePopup) {
            return;
        }

        const dataArray = [];

        if (_this.state.newRowSaveWait) {
            // if dataLevel is not present its a new row.... so do not call row update here
            if (!selectedRow['dataLevel']) {
                selectedRow['dataLevel'] = 'SINGLE';
            }

            // updating fields with some default values. format has to be yyyy-mm-dd else it does not save
            const dateValue = selectedRow['dateAdded'];
            selectedRow['dateAdded'] = dateValue ? new Date(dateValue) : new Date();

            const buyer = selectedRow['buyer'];
            selectedRow['buyer'] = buyer ? buyer : this.props.user.userName;

            const dataArray = [];

            const columns = this.state.columnDefs;

            const mandatoryFields = this.state.mandatoryFields.map(function (obj) {
                return obj.actualFieldName;
            });


            let mandatoryColumnsInGrid = [];

            for (let i = 0; i < mandatoryFields.length; i++) {
                for (let j = 0; j < columns.length; j++) {
                    if (mandatoryFields[i] === columns[j].field) {
                        mandatoryColumnsInGrid.push(mandatoryFields[i]);
                    }
                }
            }

            let mandatoryFieldsWithNoValue = [];
            for (let i = 0; i < mandatoryFields.length; i++) {
                console.log('mandatory field and its value', mandatoryFields[i], data[mandatoryFields[i]]);
                if (!data[mandatoryFields[i]] || data[mandatoryFields[i]] === '') {
                    mandatoryFieldsWithNoValue.push(mandatoryFields[i]);
                }
            }

            if (mandatoryFieldsWithNoValue.length > 0) {
                return;
            }
        }

        // added to send only the updated values to the backend instead of all data when a cell is updated
        let deptChecks =  _this.state.currentCol == 'departmentName' || _this.state.currentCol == 'classColumnName' || _this.state.currentCol == 'subClassColumnName';
        if (!_this.state.newRowSaveWait && !deptChecks) {
            let newObj = {};

            const currentCol = fieldName ? fieldName : this.state.currentCol;

            for (const key in selectedRow) {
                if (selectedRow.hasOwnProperty(key)) {
                    const element = selectedRow[key];
                    if (key == currentCol) {
                        newObj['productId'] = selectedRow['productId'];
                        newObj[key] = element;
                    }

                    // added to seasons saves seasonIds does not
                    // match the fieldName in the colDefs
                    if (currentCol == 'season') {
                        newObj['productId'] = selectedRow['productId'];
                        newObj['seasonIds'] = selectedRow['seasonIds'];
                    }

                    // added because when MPR2 Approved is checked,
                    // Stage Status needs to be set to "MPR2 Approved"
                    // rockler requirement -
                    if (currentCol == 'mpr2Approved') {
                        newObj['productId'] = selectedRow['productId'];
                        newObj['stageStatus'] = 'MPR2 Approved';
                        newObj['mpr2Approved'] = selectedRow['mpr2Approved'];
                    }

                    // added because when IRO is checked,
                    // Stage Status needs to be set to "PMP1 Ready"
                    // rockler requirement -
                    if (currentCol == 'itemReadyToOrder') {
                        newObj['productId'] = selectedRow['productId'];
                        newObj['stageStatus'] = 'PMP1 Ready';
                        newObj['itemReadyToOrder'] = selectedRow['itemReadyToOrder'];
                    }
                }
            }

            dataArray.push(newObj);
        }

        // for new records
        if (_this.state.newRowSaveWait) {
            dataArray.push(selectedRow);
        }

        // fallback for dept,class and subclass to send all
        // the required data to the backend, "ALL" properties
        // are no longer sent back to the backend.
        if (deptChecks && !_this.state.newRowSaveWait) {
            dataArray.push({
                'department': selectedRow.department,
                'departmentName': selectedRow.departmentName,
                'categoryPath': selectedRow.categoryPath,
                'catId': selectedRow.catId,
                'classColumn': selectedRow.classColumn,
                'classColumnName': selectedRow.classColumnName,
                'division': selectedRow.division,
                'divisionName': selectedRow.divisionName,
                'subClassColumn': selectedRow.subClassColumn,
                'subClassColumnName': selectedRow.subClassColumnName,
                'productId': selectedRow.productId,
            });
        }

        // pushing required data to an object to make it compatible for the api to consume
        const obj = {
            'productDetails': dataArray,
            'userRole': this.props.user.defaultRole,
        };

        // adds the spinner for new rows and next edit to avoid duplicates
        if (_this.state.newRowSaveWait) {
            _this.gridApi.stopEditing(true); // kills edit before save
            _this.props.toggleSpinner(true);
            this.saveProductData(obj);
        }

        let setStack = this.state.dataQueue;
        setStack.push(obj);

        this.setState({ dataQueue: setStack, blockNav: true });
    }

    saveProductData(data) {
        const _this = this;
        this.setState({ saveInProgress: true }, () => {
            ProductServices.addUpdateProduct(data)
                .then((res) => {
                    if (res.status) {
                        NotificationManager({ message: res.message, type: 1 });
                        const gridData = res.responseData.data;

                        // do below condition only for new row.
                        if (_this.state.newRowSaveWait) {
                            gridData[0]['productId'] = gridData[0].parentProductId || gridData[0].intProdId || gridData[0].chldProdId;

                            _this.reloadGrid();

                            this.setState({
                                newRowSaveWait: false,
                                currentCol: null,
                                isPasted: false,
                            });
                        }

                        // Added to update the products grid based on
                        // the backends response with most recent data
                        // when a property value is changed on the grid

                        let newData = res.responseData.data[0];
                        _this.gridApi.forEachNode(function (nodes) {
                            if (nodes.data.productId == newData.productId) {
                                nodes.setData(newData);
                            }
                        });

                        if (this.state.isPasted) {
                            this.setState({
                                isPasted: false
                            });
                        }

                        let redoStack = [...this.state.dataQueue];
                        redoStack.shift();

                        this.setState({ dataQueue: redoStack, saveInProgress: false });
                    } else {
                        NotificationManager({ message: res.responseMessage, type: 4 });
                    }
                })
                .catch((error) => {
                    console.log('Server Error Failed', error);
                    NotificationManager({ message: error.response?.data.message || 'Failed to save data.', type: 5 });

                    // Allows users to continue editing even if
                    // a queued call fails to save data and removes
                    // failed call from the queue

                    let redoStack = [...this.state.dataQueue];
                    redoStack.shift();

                    this.setState({ dataQueue: redoStack, saveInProgress: false, blockNav: false });
                });
        });
    }

    /**
   * deletes product record
   */
    deleteProducts() {
        const selectedRows = this.gridApi.getSelectedRows();
        const deleteArray = [];
        let passFlag = false;
        this.setState({ enableSaveRecordButton: false });

        selectedRows.forEach(function (obj) {
            if (!obj.productId) {
                passFlag = true;
            }
        });

        selectedRows.forEach(function (obj) {
            if (obj.chldProdId) {
                deleteArray.push(obj.chldProdId);
            } else if (obj.intProdId) {
                deleteArray.push(obj.intProdId);
            } else {
                deleteArray.push(obj.parentProductId);
            }
        });

        if (selectedRows.length > 0 && passFlag == false) {
            ProductServices.deleteRecord(deleteArray)
                .then((response) => {
                    this.gridApi.deselectAll();
                    this.reloadGrid();
                    NotificationManager({ message: 'Product Deleted', type: 1 });
                })
                .catch((error) => {
                    console.log('productContainer delete API call failed', error);
                });
        } else if (passFlag == true) {
            NotificationManager({ message: 'Unsaved products can not be deleted', type: 4 });
        } else {
            NotificationManager({ message: 'Select records to delete', type: 4 });
        }
    }

    /**
   * opens scan modal pop up
   */
    openScanModal() {
        this.setState({ scanModalOpen: true });
    }

    /**
   * closes scan modal pop up
   */

    closeScanModal() {
        this.setState({ scanModalOpen: false });
    }

    /**
   * closes import file pop up
   */
    closeImportFile() {
        this.setState({ importFilePopup: false });
    }

    /**
   * opens import file pop up and sets the type
   */

    openImportFile(e) {
        this.setState({ importFilePopup: true, importModalType: e.target.id});
    }


    /**
   * closes the confirm delete pop up
   */
    confirmDelete() {
        this.deleteProducts();
        this.closeConfirmDelete();
    }

    /**
  * opens the confirm delete modal
  */
    openConfirmDelete() {
        this.setState({ isConfirmModalOpen: true });
    }

    /**
  * closes the confirm delete modal
  */
    closeConfirmDelete() {
        this.setState({ isConfirmModalOpen: false });
    }


    triggerViewBy(event) {
        this.setState({ showViewBy: !this.state.showViewBy });
    }


    unCheckAllRows() {
        this.gridApi.deselectAll();
        this.gridApi.clearRangeSelection();
    }

    viewBy(e) {
        const _this = this;
        const target = $(e.target);
        let value = target.text();
        let Urlval;
        if (value === 'Size/Sku') {
            value = 'Sku';
        }
        _this.triggerViewBy();
        const sourceFrom = this.props.sourceFrom || { type: '', url: '' };
        let postData = null;
        if (sourceFrom.type) {
            Urlval = sourceFrom.url;
            Urlval = Urlval.replace('null', value);
            method = sourceFrom.serviceType;
            postData = sourceFrom.navObj;
            url = Urlval;
            postData;
        } else {
            method = 'post';
            const filter = this.props.appliedFilters;
            url = require('../common/configurations.js').product.filters;
            if (filter.type == 'search') {
                url = require('../common/configurations.js').product.productsearch;
            }
            filter.viewBy = value;
            postData = filter;
        }

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };
        axios({
            method: 'post',
            url: require('../common/configurations.js').product.filters,
            headers: headers,
            data: postData,
        })
            .then((response) => {
                const res = response.data;
                console.log('view by data: ', res);
            })
            .catch((error) => {
                console.log('productContainer helperComponents footer API call failed', error);
            });
    }

    /**
   * saves the column width when it is resized
   */
    onColumnResized(params) {
        const columnState = this.gridColumnApi.getColumnState();
        const columnStateLength = columnState.length;
        const columns = this.props.columnDetails;
        const columnsLength = columns.length;
        for (let i = 0; i < columnsLength; i++) {
            for (let j = 0; j < columnStateLength; j++) {
                if (columns[i].fieldName == 'department') {
                    columns[i].fieldName = 'departmentName';
                }
                if (columns[i].fieldName == columnState[j].colId) {
                    columns[i].width = columnState[j].width;
                }
            }
        }

        this.props.addColumnDetails(columns);
        this.props.columnDetailsUpdated();

        if (params.column != null) {
            this.props.isColumnTouched(params.column.colId);
        }
    }

    /**
   * saves the column sequence when it is rearranged
   */
    onColumnMoved(params) {
        const columnState = this.gridColumnApi.getColumnState();
        const columnStateLength = columnState.length;
        const columns = this.props.columnDetails;
        const columnsLength = columns.length;
        let match = false;
        for (let i = 0; i < columnsLength; i++) {
            for (let j = 0; j < columnStateLength; j++) {
                const columnFieldName = columns[i].fieldName;
                let columnStateFieldName = columnState[j].colId;
                if (columnStateFieldName == 'departmentName') {
                    columnStateFieldName = 'department';
                }

                if (columnStateFieldName == 'vendorName') {
                    columnStateFieldName = 'vendorId';
                }
                if (columnFieldName.localeCompare(columnStateFieldName) == 0) {
                    columns[i].order = j;
                    match = true;
                    break;
                }
            }
            if (!match) {
                columns[i].order = 999;
            }
        }

        console.log('columns before saving: ', columns);
        this.props.addColumnDetails(columns);
        this.props.columnDetailsUpdated();
        if (params.column && params.column.colId) {
            this.props.isColumnTouched(params.column.colId);
        }
    }


    /**
   *  updates cells with values that are pasted on to the cells.
   *
   * @param {object} params
   */
    onPasteEnd(params) {
        console.log('onPasteEnd: ', params);
        const selectionArray = this.gridApi.getRangeSelections();

        console.log('getRangeSelections: ', selectionArray);
        const startRowObj = selectionArray[0].start;
        const endRowObj = selectionArray[0].end;

        const colName = selectionArray[0].columns[0].colId;

        if (colName == 'quantityBreaks' || colName == 'setDetail') {
            this.gridApi.stopEditing();
            return;
        }

        const rowIdx = startRowObj.rowIndex;
        const endRowIdx = endRowObj.rowIndex;
        const rowNode = this.gridApi.getRowNode(rowIdx.toString());

        if (rowIdx == endRowIdx) {
            this.rowUpdate(rowNode.data, rowIdx);
        }
    }

    onPasteStart(params) {
        console.log('onPasteStart: ', params);
        const selectionArray = this.gridApi.getRangeSelections();
        const _this = this;
        const startRowObj = selectionArray[0].start;
        const endRowObj = selectionArray[0].end;

        const colName = selectionArray[0].columns[0].colId;
        const startRowIdx = startRowObj.rowIndex;
        const endRowIdx = endRowObj.rowIndex;

        if (endRowIdx != startRowIdx) {
            this.reloadGrid();
        }

        this.setState({
            currentCol: colName,
            isPasted: true,
        });
    }

    /**
   *  is used to set color for header for read only columns.
   *
   * @param {void} params
   */
    readonlyHeader() {
        const columnsReadOnly = this.props.columnDetails;

        columnsReadOnly.forEach(function (obj) {
            if (obj.feildLevel == 'R') {
                const headerVar = document.querySelectorAll('[title=\'' + obj.columnTitle + '\']');
                for (let i = 0; i < headerVar.length; i++) {
                    headerVar[i].style.color = '#9B9898';
                    headerVar[i].style.backgroundColor = '#E7F6F5';
                }
            }
        });
    }

    /**
  *  is used when user scrolls and new set of headers appear in the dom, the headers
  * of those columns are styled based on whether the column is read only.
  *
  */
    onVirtualColumnsChanged() {
        this.readonlyHeader();
    }

    /**
   *  is called when a cell value is changed
   * @param {object} params
   */
    onCellValueChanged(params) {
        console.log('on cell value changed: ', params, this.state.isPasted);
        const oldValue = params.oldValue;
        const newValue = params.value;
        let newObj = {};

        if (oldValue == newValue || this.state.isPasted == true) {
            return;
        }

        const field = params.colDef.field;
        const fieldType = params && params.colDef && params.colDef.fieldType ? params.colDef.fieldType : '';
        const format = params && params.colDef && params.colDef.format ? params.colDef.format : '';

        if (field == 'prodName' || fieldType == 'string' || format == 'Currency' || field == 'sampleOptions' || format == 'int' || format == 'Double') {
            const rowData = params.data;
            for (const key in rowData) {
                if (rowData.hasOwnProperty(key)) {
                    const element = rowData[key];
                    if (key == field) {
                        newObj['productId'] = params.data.productId;
                        newObj[key] = element;
                    }
                }
            }
            console.log('rowdata: ', rowData);
            if (rowData['childcount']) {
                console.log('grouped record being edited');
                return;
            }
            this.rowUpdate(newObj, params.rowIndex);
        }
    }

    getRowClass() {
        return this.state.rowClass;
    }

    getRowHeight() {
        return this.state.rowHeight;
    }

    getMainMenuItems(params) {
        const menuItems = params.defaultItems.slice(0);

        switch (params.column.getId()) {
            case 'images':
                menuItems.push({
                    name: 'Tiny Image',
                    action: () => {
                        this.setState({ rowHeight: 25 }, () => {
                            this.gridApi.purgeServerSideCache();
                        });
                    },
                });

                menuItems.push({
                    name: 'Thumbnail Image',
                    action: () => {
                        this.setState({ rowHeight: 50 }, () => {
                            this.gridApi.purgeServerSideCache();
                        });
                    },
                });

                return menuItems;

            default:
                menuItems.splice(0, 0, { // removed sorting for rockler
                    name: 'Sorting',
                    subMenu: [
                        {
                            name: 'Ascending ',
                            action: () => {
                                let sort = [
                                    {
                                        colId: params.column.getId(),
                                        sort: 'asc',
                                    }
                                ];

                                this.gridApi.setSortModel(sort);
                                this.setState({ startSort: true });
                            },
                        },
                        {
                            name: 'Descending',
                            action: () => {
                                let sort = [
                                    {
                                        colId: params.column.getId(),
                                        sort: 'desc',
                                    }
                                ];

                                this.gridApi.setSortModel(sort);
                                this.setState({ startSort: true });
                            },
                        },
                        {
                            name: 'Reset',
                            action: () => {
                                this.gridApi.setSortModel(null);
                            },
                        }
                    ],
                });

                return menuItems;
        }
    }

    onBodyScroll(params) {
    }

    bulkUpdate() {
        if (this.state.selectedRows && this.state.selectedRows.length < 1) {
            NotificationManager({ message: 'You need to select atleast two records for bulk update', type: 4 });
            this.toggleBulkUpdateMenu({ showBulkUpdateMenu: !this.state.showBulkUpdateMenu });
            this.setState({ showBulkUpdateMenu: false });
            return;
        }
        this.setState({ showBulkUpdatePopup: !this.state.showBulkUpdatePopup });
    }
    hideBulkUpdatePopup() {
        this.setState({ showBulkUpdatePopup: false });
    }
    toggleBulkUpdateMenu() {
        this.setState({ showBulkUpdateMenu: !this.state.showBulkUpdateMenu });
    }

    onCellEditingStarted(params) {
        if (params.node.data && params.node.data.childCount) {
            this.gridApi.stopEditing(true);
        }
        this.setState({
            currentCol: params.column.colId,
        });
    }

    onCellClicked(params) {
    // sets the current column on click, ensures row update for only that column
        this.setState({
            currentCol: params.column.colId,
        });
    }

    // copy to clipboard override to stop copy of range selected
    processCellForClipboard(params) {
        let flag = true;

        // gets the selected range on the grid
        const selectionArray = this.gridApi.getRangeSelections();
        const selectedRows = this.gridApi.getSelectedRows();

        console.log('copy to clip params inside', params, selectedRows, selectionArray);

        // removes all empty values
        if (!params.value || params.value == '' || params.value == ' -> ' || selectedRows.length > 0) {
            return;
        }

        // selecting the start point of the selected
        // items on the grid
        const getFirstNode = selectionArray[0].start;

        // getting the index of the start point
        const firstCellIndex = getFirstNode.rowIndex;

        // get the column ID of the selected node
        const firstCellId = getFirstNode.column.colId;

        if (params.node.rowIndex == firstCellIndex && params.column.colId == firstCellId && params.value && flag) {
            console.log('copy to clip params inside', params.value);
            flag = false;
            return params.value;
        }
    }

    sendToClipboard(params) {
        console.log('copy sendToClipboard', params);
        let data = params.data.trim();
        navigator.clipboard.writeText(data);
    }

    navigateAwayCheck(e) {
        if (this.state.blockNav) {
            this.setState({ toggleNavigateAway: true });
            return false;
        }
        return true;
    }

    closeNavigateAway() {
        this.setState({ toggleNavigateAway: false });
    }

    /**
  *  is called to render the grid and the other visual elements on the file
  */
    render() {
        const dropdowns = {};
        dropdowns['variants'] = this.state.variantsList ? this.state.variantsList : [];
        dropdowns['size'] = this.state.sizeList ? this.state.sizeList : [];
        dropdowns['color'] = this.state.colorsList ? this.state.colorsList : [];

        const currentRowData = {};

        if (this.state.selectedRow && this.state.selectedRow.parentProductId) {
            currentRowData['productId'] = this.state.selectedRow.parentProductId;
        }

        const scrollHider = this.state.hideDefaultScroll ? '105%' : '100%';

        const columnDefs = this.state.columnDefs;

        return (
            <div>
                <Prompt
                    when={this.state.blockNav}
                    message={this.navigateAwayCheck}
                />

                <FilterStrip filters={this.props.appliedFilters} updateFilters={this.props.removeFilter} />

                <div
                    id="myGrid"
                    style={{
                        boxSizing: 'border-box',
                        width: scrollHider,
                        marginLeft: '1px',
                        height: 'calc(100% - 155px)',
                    }}
                    className="ag-theme-balham"
                >

                    <AgGridReact
                        ref="agGrid"
                        columnDefs={columnDefs}
                        defaultColDef={this.state.defaultColDef}

                        applyColumnDefOrder={true}

                        enableServerSideSorting={true}
                        enableRangeSelection={true}
                        onPasteEnd={this.onPasteEnd.bind(this)}
                        onPasteStart={this.onPasteStart.bind(this)}

                        enableServerSideFilter={true}
                        serverSideSortingAlwaysResets={true}

                        enableColResize={true}

                        maxBlocksInCache={5} // 10 / How many blocks to cache in the client
                        cacheBlockSize={2000} // 50 / How many rows for each block in the cache or number of rows that will get fetched in each server request

                        onGridReady={this.onGridReady.bind(this)}
                        onColumnPinned={this.onColumnPinned.bind(this)}
                        rowSelection={'multiple'} // multiple / allows for multiple rows to be selected
                        rowModelType={'serverSide'} // serverSide / enables server side row model

                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                        suppressDragLeaveHidesColumns={true}

                        enableGroupEdit={true} // enables grouped rows also to be editable
                        onCellEditingStarted={this.onCellEditingStarted.bind(this)}

                        onSelectionChanged={this.onSelectionChanged.bind(this)}
                        onRowSelected={this.onRowSelected.bind(this)}

                        frameworkComponents={this.state.frameworkComponents}

                        suppressMenuHide={true}

                        suppressHorizontalScroll={false}    // adds the horizontal scroll bar
                        onCellValueChanged={this.onCellValueChanged.bind(this)} // this is for catching when a cell in the grid changes values

                        enableCellChangeFlash={true}

                        domLayout={this.state.domLayout}  // used for auto resizing the rows inside the table but looks bad before data is loaded

                        singleClickEdit={true} // enables single click edit

                        overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}

                        onColumnResized={this.onColumnResized.bind(this)}
                        onColumnMoved={this.onColumnMoved.bind(this)}

                        enterMovesDown={true}
                        enterMovesDownAfterEdit={true}
                        rowGroupPanelShow={'never'} // 'always', never

                        suppressRowClickSelection={true}

                        onVirtualColumnsChanged={this.onVirtualColumnsChanged.bind(this)}

                        getRowClass={this.getRowClass.bind(this)}
                        rowClass={this.state.rowClass}
                        suppressContextMenu={true}
                        rowHeight={this.state.rowHeight}
                        getMainMenuItems={this.getMainMenuItems.bind(this)}
                        headerHeight={38}

                        stopEditingWhenGridLosesFocus={true}
                        onBodyScroll={this.onBodyScroll.bind(this)}
                        onCellClicked = {this.onCellClicked.bind(this)}
                        processCellForClipboard = {this.processCellForClipboard.bind(this)}
                        sendToClipboard = {this.sendToClipboard.bind(this)}
                    />
                </div>

                <div style={{ width: '100%' }}>
                    <div className="product-footer footer">

                        <div className="record-creation-holder">
                            <div className={`record-action gray-button ${(this.state.clickedOnAddRecord) ? 'open active' : ''}`}
                                onClick={this.toggleRecordOptions.bind(this)}>Add Record</div>
                            <div className="record-options">
                                <ul>
                                    <li onClick={this.onBtAdd.bind(this)}>Add Record [to grid]</li>
                                    <li onClick={this.openImportFile.bind(this)} id='excel-import'>Import Records from File [Excel]</li>
                                </ul>
                            </div>
                        </div>

                        <div className="record-delete-holder inline-block">
                            <button type="button" className="gray-button"
                                disabled={this.state.selectedRows.length > 0 ? false : true}
                                onClick={this.openConfirmDelete.bind(this)}>
                Delete Record
                            </button>
                        </div>

                        <div className="record-delete-holder inline-block">
                            <button type="button" className="gray-button"
                                disabled={this.state.selectedRows.length > 0 ? false : true}
                                onClick={this.unCheckAllRows.bind(this)}>
                UnCheck All
                            </button>
                        </div>

                        <div className="footer-content right">
                            {
                                this.state.data && this.state.data.length > 0 &&
                <div className="record-creation-holder2">
                    <div className={`record-action gray-button ${(this.state.showBulkUpdateMenu) ? 'open active' : ''}`}
                        onClick={this.toggleBulkUpdateMenu.bind(this)}>Actions</div>
                    <div className="record-options">
                        <ul>
                            <li onClick={this.openImportFile.bind(this)} id='copy-import'>Upload Copy</li>
                            <li onClick={this.openScanModal.bind(this)}>Scan Actions</li>
                            <li onClick={this.bulkUpdate.bind(this)}>Bulk Update</li>
                        </ul>
                    </div>
                </div>
                            }

                            {(this.props.user.defaultRole.toLowerCase() == 'planner') &&
                <button type="button" className="gray-button left record-delete-holder"
                    disabled={(this.props.selectedRows && this.props.selectedRows.length > 0) ? false : true} onClick={this.exportPO}>Export PO</button>
                            }
                        </div>

                        <div className="total-row-count">
                            <span className="total-rows">{this.state.recordCount} Total Rows</span>
                            <span className="selected-rows"> | Selected: {this.state.selectedRowCount} </span>
                        </div>

                    </div>
                    <CancelModal isOpen={this.state.isConfirmModalOpen} modalClose={this.closeConfirmDelete.bind(this)} confirmDelete={this.confirmDelete.bind(this)} />
                    <NavigateAway isOpen={this.state.toggleNavigateAway} modalClose={this.closeNavigateAway.bind(this)} />
                </div>

                <ScanModal isOpen={this.state.scanModalOpen} modalClose={this.closeScanModal.bind(this)} />

                {
                    this.state.showMultiProduct &&
          <div className="ag-modal-container">
              <div className="ag-modal-holder">
                  <ProductOptions
                      dropdowns2={dropdowns}
                      currentRowData={currentRowData}
                      modalClose={this.closeMultiProductPopup.bind(this)}
                      rowData={this.state.multiProductRow}
                      reloadGrid={this.reloadGrid.bind(this)}
                      mandatoryFieldsCheck={this.mandatoryFieldsCheck.bind(this)}
                      {...this.props}
                  />
              </div>
          </div>
                }
                {
                    this.state.addNewVendor &&
          <div className="ag-modal-container">
              <div className="ag-modal-holder">
                  <AddVendorForm
                      modalClose={this.closeAddNewVendorPopup.bind(this)}
                      gridApi={this.gridApi}
                      currentRowData={currentRowData}
                      rowData={this.state.vendorRowData}
                      rowUpdate={this.rowUpdate.bind(this)}
                      {...this.props}
                  />

              </div>
          </div>
                }
                {
                    this.state.copyWritePopup &&
          <div className="ag-modal-container">
              <div className="ag-modal-holder-copywrite">
                  <AddCopyModel
                      modalClose={this.closeCopyWritePopup.bind(this)}
                      copyData={this.state.copyData}
                      currentRowData={this.state.copyRowData}
                      {...this.props}
                  />
              </div>
          </div>
                }
                {
                    this.state.keywordPopup &&
          <div className="ag-modal-container">
              <div className="ag-modal-holder">
                  <AddKeywordModel
                      modalClose={this.closeKeywordPopup.bind(this)}
                      keywordData={this.state.keywordData}
                      currentRowData={this.state.keywordRowData}
                      {...this.props}
                  />
              </div>
          </div>
                }

                {
                    this.state.piUploadPopup &&
          <div className="ag-modal-container">
              <div className="ag-modal-holder">
                  <PiUploadModal
                      modalClose={this.closePiUploadPopup.bind(this)}
                      currentRowData={currentRowData}
                      rowData={this.state.piUploadRowData}
                      {...this.props}
                  />
              </div>
          </div>
                }
                {
                    this.state.swatchReferencePopup &&
          <div className="ag-modal-container">
              <div className="ag-modal-holder-swatchReference">
                  <SwatchReferenceModal
                      modalClose={this.closeSwatchPopup.bind(this)}
                      isOpen={this.state.swatchReferencePopup}
                      currentRowData={currentRowData}
                      rowUpdate={this.rowUpdate.bind(this)}
                      {...this.props}
                      node={this.state.swatchReferenceNode}
                      closeSwatchPopup={this.closeSwatchPopup.bind(this)}
                      gridApi={this.gridApi}
                  />
              </div>
          </div>
                }

                {
                    this.state.importFilePopup &&
          <div className="ag-modal-container import-from-excel">
              <div className="ag-modal-holder">
                  <AddRecordsFromFiles
                      modalClose={this.closeImportFile.bind(this)}
                      currentRowData={currentRowData}
                      closeMenu = {this.toggleRecordOptions.bind(this)}
                      modalType = {this.state.importModalType}
                      {...this.props}
                  />
              </div>
          </div>
                }

                {
                    this.state.openSamplesPopup &&
          <div className="ag-modal-container">
              <div className="ag-modal-holder">
                  <SamplePopup
                      modalClose={this.closeSamplesPopup.bind(this)}
                      productData={this.state.samplePopupData}
                      user={this.props.user}
                      {...this.props}
                  />
              </div>
          </div>
                }

                {
                    this.state.spinner &&
          <div className='sweet-loading'>
              <RingLoader
                  color={'#36D7B7'}
                  loading={this.state.spinner}
              />
          </div>
                }

                {
                    this.state.showBulkUpdatePopup &&
          <div className="ag-modal-container">
              <div
                  style={{
                      position: 'relative',
                      backgroundColor: '#fefefe',
                      margin: 'auto',
                      padding: 0,
                      border: '1px solid #888',
                      maxWidth: '800px',
                      maxHeight: '400px',
                      borderRadius: '16px 16px 16px 16px',
                  }}
              >
                  <BulkUpdatePopup
                      hideBulkUpdatePopup={this.hideBulkUpdatePopup.bind(this)}
                      toggleBulkUpdateMenu={this.toggleBulkUpdateMenu.bind(this)}
                      findKeyGraphName={this.findKeyGraphName.bind(this)}
                      selectedRows={this.state.selectedRows}
                      columns={this.state.columnDefs}
                      user={this.props.user}
                      categoriesList={this.state.categoriesList}
                      buyersList={this.state.buyersList}
                      channelsList={this.state.channelsList}
                      colorsList={this.state.colorsList}
                      seasonsList={this.state.seasonsList}
                      sizeList={this.state.sizeList}
                      variantsList={this.state.variantsList}
                      vendorsList={this.state.vendorsList}
                      attrDropDownValues={this.state.attrDropDownValues}
                      gridApi={this.gridApi}
                      toggleSpinner={this.props.toggleSpinner}
                      fetchChildren = {this.state.fetchChildren}
                  />
              </div>
          </div>
                }
            </div >

        );
    }
}

const mapStateToProps = (state) => {
    return {
        gridData: state.product2GridData,
        appliedFilters: state.appliedFilters,
        currentViewId: state.updateCurrentView,
        columnResequence: state.columnResequence,
        columnDetails: state.columnDetails,
        actionPerformedFromState: state.actionPerformedFrom,
        filterForSortingProductsGrid: state.filterForSortingProductsGrid,
    };
};

const mapDispatchToprops = (dispatch) => {
    return {
        addColumnDetails: (data) => {
            dispatch(actions.addColumnDetails(data));
        },
        columnDetailsUpdated: () => {
            dispatch(actions.gridColumnChanged());
        },
        removeFilter: (data) => {
            dispatch(actions.removeFilter(data));
        },
        appliedFiltersAction: (data) => {
            dispatch(actions.appliedFilters(data));
        },
        filterDataChanged: () => {
            dispatch(actions.filterDataChanged());
        },
        addProduct2GridData: (data) => {
            dispatch(p2Actions.addProduct2GridData(data));
        },
        addSelectedRows: (data) => {
            dispatch(p2Actions.addSelectedRows(data));
        },
        isColumnTouched: (data) => {
            dispatch(p2Actions.isColumnTouched(data));
        },
        actionPerformedFrom: (data) => {
            dispatch(p2Actions.actionPerformedFrom(data));
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToprops)(Product2));
