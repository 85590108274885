import React, { useState, useEffect } from 'react';

class ParentDropdown extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: null,
            selected: null,
            searched: null,
        };
    }

    componentWillMount() {
        this.setState({
            value: this.props.value
        });
    }

    clickHandler(e) {
        const id = e.currentTarget.id;

        this.setState({
            selected: id
        });
    }

    searchText(e) {
        let newArr = [];
        const value = this.state.value;
        const val = e.currentTarget.value.toLowerCase();

        console.log('ParentDropdown searchText', val, val.length);

        value.map((i) => {
            if ((i).toLowerCase().indexOf(val) != -1) {
                newArr.push(i);
            }
        });

        if (val.length > 0) {
            this.setState({
                value: newArr
            });
        } else {
            this.setState({
                value: this.props.value
            });
        }
    }

    sendData() {
        this.props.setParentDropdownValue(this.state.selected);
    }

    render() {
        console.log('ParentDropdown render', this.props, this.state);

        const value = this.state.value;
        const selected = this.state.selected;

        return (
            <div className='ag-creative-parent-dropdown-container'>
                <h3>Select a parent value</h3>
                <input type="text" className="ag-DropdownSearchInput" placeholder="Search.." onChange={this.searchText.bind(this)}></input>
                <ul style={{ width: '100%' }} className="agCustomDropdown">
                    {
                        this.props.colId == 'location' ?

                            value && value.map((i, x) => {
                                return <li onClick={this.clickHandler.bind(this)} key={x} id={i} className={`agCustomDropdownItems ${(selected == i ? 'selectedVal' : '')}`}>{i}</li>;
                            })

                            :

                            value && value.map((i, x) => {
                                return <li onClick={this.clickHandler.bind(this)} key={x} id={i.name} className={`agCustomDropdownItems ${(selected == i.name ? 'selectedVal' : '')}`}>{i.name}</li>;
                            })

                    }
                </ul>
                <div className="ag-creative-parent-dropdown-footer">
                    <input type="button" className="gray-button" disabled={selected ? false : true} value="Submit" onClick={() => this.sendData()}/>
                    <input type="button" className="gray-button" value="Cancel" onClick={this.props.close}/>
                </div>
            </div>
        );
    }
}

export default ParentDropdown;
