import React from 'react';

export class CreativeDropdownEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value || '',
            oldVal: props.value || '',
            dropdownList: props.dropdownList,
            searchedArray: props.dropdownList,
        };
    }

    searchText(event) {
        const searchArr = [];
        const value = event.target.value.toLowerCase();

        this.state.dropdownList.forEach(function (row) {
            if ((row.name).toLowerCase().indexOf(value) != -1) {
                searchArr.push(row);
            }
        });

        this.setState({ value: event.target.value, searchedArray: searchArr });
    }

    componentDidMount() {
        console.log('CreativeDropdownEditor componentDidMount', this.props);
        const _this = this;

        const childNodes = _this.props.node.parent.allLeafChildren;

        if (_this.props.column.colDef.colId == 'shotCount') {
            let dropdownVals = [..._this.props.dropdownList];

            childNodes.map((i) => {
                dropdownVals.map((j, x) => {
                    if (i.data.shotCount == j) {
                        dropdownVals.splice(x, 1);
                    }
                });
            });

            _this.setState({
                searchedArray: dropdownVals,
            });
        }
    }

    onMouseLeave() {
        this.props.api.stopEditing();
    }

    componentWillUnmount() {
    }

    handleChange(event) {
        const _this = this;
        const colName = _this.props.column.colDef.colId;
        let setVals;
        let addShot = {};
        const rowNode = this.props.node;
        let data = {};

        if (colName == 'shotCount') {
            setVals = event.currentTarget.dataset.id;

            if (!_this.props.value && _this.props.value == null) { // adding new row
                addShot['shotAngle'] = setVals;
                addShot['parentId'] = _this.props.node.data.parentId;

                _this.props.addShots(addShot, rowNode);
            }

            if (_this.props.value) {
                addShot['shotAngle'] = setVals;
                addShot['ppidLst'] = [_this.props.node.data.ppId];

                _this.props.addShots(addShot, rowNode);
            }
        } else if (colName == 'location') {
            setVals = event.currentTarget.dataset.id;

            if (_this.props.column.colId == 'location') {
                data[_this.props.column.colId] = setVals;
                data['ppidLst'] = [_this.props.node.data.ppId];

                _this.props.updateFields(data);
            }
        } else {
            setVals = event.currentTarget.dataset.name;
            const id = event.currentTarget.dataset.id;

            if (_this.props.column.colId == 'photographer') {
                data[_this.props.column.colId] = setVals;
                data['ppidLst'] = [_this.props.node.data.ppId];
                data['photographerId'] = id;

                _this.props.updateFields(data);
            }

            if (_this.props.column.colId == 'retoucher') {
                data[_this.props.column.colId] = setVals;
                data['ppidLst'] = [_this.props.node.data.ppId];
                data['retoucherId'] = id;

                _this.props.updateFields(data);
            }
        }

        _this.setState({
            value: setVals
        }, ()=> {
            _this.props.api.stopEditing();
        });
    }

    isPopup() {
        return true;
    }

    getValue() {
        return this.state.value;
    }

    render() {
        console.log('dropdown editor: ', this.props, this.state);

        return (
            <div
                className="ag-DropdownSearchContainer"
                style={{ width: this.props.width }}
                onMouseLeave={this.onMouseLeave.bind(this)}
            >
                {
                    <input type="text" className="ag-DropdownSearchInput" placeholder="Search.." onChange={this.searchText.bind(this)}></input>
                }
                <ul style={{ width: '100%' }} className="agCustomDropdown">
                    { this.props.column.colDef.colId == 'shotCount' || this.props.column.colDef.colId == 'location' ?
                        this.state.searchedArray.map((value, index) => {
                            return <li className="agCustomDropdownItems" onClick={this.handleChange.bind(this)} key={index} data-id={value}>{value}</li>;
                        })
                        :
                        this.state.searchedArray.map((value, index) => {
                            return <li className="agCustomDropdownItems" onClick={this.handleChange.bind(this)} key={index} data-name={value.name} data-id={value.id}>{value.name}</li>;
                        })
                    }

                </ul>
            </div>
        );
    }
}
