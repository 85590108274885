import NotificationManager from '../../common/components/notification';
// var NotificationManager = require('../../common/notification');
/*column width declaration
*/
var columnWidth = {
    productId: 120,
    prodName: 300,
    activeNotes: 400
};

var readableFields = [];
var writableFields = [];
window.readableFields = [];
window.writableFields = [];

var checboxFields = ["isPiReceived",
    "isPiApproved",
    "isPiRequestSent",
    "isSampleapproved",
    "isSamplerecieved",
    "deleted",
    "attrApproved",
    "readyToActivate",
    "isPiRequestEarly",
    "isResendPiRequest",
    "itemReadyToOrder",
    "accessToItemReady",
    "earlyReqSampleSwatch",
    "backOrder",
    "sampleMoved",
    "attrCompleted",
    "isRetoucherApproved",
    "isCopywriterApproved",
    "isWebproducerApproved"];



var requiredFieldsList = ['fieldsToClone', 'readyToOrder', 'reqdToAdd'];
/*Date Editor funtion for Jqwidgets
*/

var copyPopupFields = ["itemReadyToOrder", "isPiRequestSent", "isPiReceived", "piUpload", "isPiApproved", 'copywrite', 'keywords',
    'attr1', 'attr2', 'attr3', 'attr4', 'attr5', 'attr6', 'attr7', 'attr8', 'attr9', 'attr10', 'attr11', 'attr12', 'attr13', 'attr14',
    'attr15', 'attr16', 'attr17', 'attr18', 'attr19', 'attr20', 'attr', 'attrCompleted', 'attrApproved', 'addPO'];
var displayPopupFields = ["earlyReqSampleSwatch", "isSamplerecieved", "isSampleapproved", "swatchOnly", 'swatchReference',
    'featureColor', 'launchDate', 'onlineActivatedDate', 'activeNotes', 'quantityInventory', 'shotAngle', 'imageApproval',
    'isActiveLive', 'campaign', 'campaignDate'];

var createDateEditor = function (row, cellvalue, editor, cellText, width, height){
    // construct the editor.
    cellvalue = cellvalue ? new Date(cellvalue) : new Date();
    editor.jqxDateTimeInput({
        width: width,
        height:height,
        value:cellvalue,
        culture: 'en-US',
        formatString: "MM/dd/yyyy",
        enableBrowserBoundsDetection:true
    });
};
var createDateTimeEditor = function (row, cellvalue, editor, cellText, width, height){
    // construct the editor.
    cellvalue = cellvalue ? new Date(cellvalue) : new Date();
    editor.jqxDateTimeInput({
        width: width,
        height:height,
        value:cellvalue,
        culture: 'en-US',
        formatString: "f",
        showTimeButton: true,
        enableBrowserBoundsDetection:true
    });
};

var initDateEditor =  function (row, cellvalue, editor, celltext, width, height) {
    // set the editor's current value. The callback is called each time the editor is displayed.
    cellvalue = cellvalue ? new Date(cellvalue) : new Date();
    editor.jqxDateTimeInput('setDate', cellvalue);
    editor.jqxDateTimeInput({  height: height});
};
var getDateEditor =  function (row, cellvalue, editor) {
		 var rowData = this.owner.treeGrid.getRow(row);
		 var cdate = $(editor).jqxDateTimeInput('getDate');
		 var fieldName = this.datafield;
		 if(fieldName === "launchDate" && rowData.campaignDate){
        var campaignDate = new Date(rowData.campaignDate);
        var launchDate = new Date(cdate);
        var formatCDateStr = campaignDate.getFullYear() + "-" + (campaignDate.getMonth() + 1) + "-" + campaignDate.getDate();
        var formatLDateStr = launchDate.getFullYear() + "-" + (launchDate.getMonth() + 1) + "-" + launchDate.getDate();
        var formatCDate = new Date(formatCDateStr);
        var formatLDate = new Date(formatLDateStr);
        if(formatLDate > formatCDate ){
            NotificationManager({message:"Launch date should not be greater than Campain date", type:4});
            return cellvalue;
        }
    }
    if(fieldName === "launchDate" && ["SP", "Intm"].indexOf(rowData.dataLevel) > -1){
        return "";
    }
    // return the editor's value.
    return cdate;
};

var getDateTimeEditor =  function (row, cellvalue, editor) {
		 var rowData = this.owner.treeGrid.getRow(row);
		 var cdate = $(editor).jqxDateTimeInput('getDate');
		 var fieldName = this.datafield;

    var newData = new Date(cdate);
    var hours = newData.getHours() > 12 ? newData.getHours() - 12 : newData.getHours();
    var am_pm = hours >= 12 ? "PM" : "AM";
    hours = hours < 10 ? "0" + hours : hours;
    var formatDate = (newData.getMonth() + 1) + "/" + newData.getDate() + "/" + newData.getFullYear() + " " + hours + ":" + newData.getMinutes() + " " + am_pm;

    return formatDate;
};

// Including image in a column
var imageRenderer = function (row, datafield, value) {
    var image ;
    if(value.length && value[0].imageName){
        image = '<div class="product-image" data-row="' + row + '"><img src="'
			+ value[0].imageUrl + '" /></div>';
    }else{
        image = '<div class="product-image empty" data-row="' + row + '"></div>'; // remove if required
        // image = '';
    }

    if (datafield === 'imageApproval') {
        image = '';
    }

    return image;
};

// swatchOnly checkbox renderer
var swatchOnlyCellRender = function(row, columnfield, value, rowdata) {
    if(value && value > 0){
        return '<span class="blue-checkbox"><input class="swatch-only" type="checkbox" checked data-row="' +
				row + '" ><label></label></span>';
    }else{
        return '<span class="blue-checkbox"><input class="swatch-only" type="checkbox"  data-row="' +
					row + '" ><label></label></span>';
    }
};
var convertDataType = function(value){
    if(value && typeof(value) === "string"){
        if(value.toLowerCase() === "none"){
            value = false;
        }
        else if(value === "true"){
            value = true;
        }
        else if(value === "false"){
            value = false;
        }
    }
    return value;
};

var productCheckbox = function(row, columnfield, value, rowdata){
    if(rowdata.isLocked === true){
        return '';
    }
    if(value && typeof(value) === "string"){
        if(value.toLowerCase() === "none"){
            value = false;
        }
        else if(value === "true"){
            value = true;
        }
        else if(value === "false"){
            value = false;
        }
    }
    value = convertDataType(value);
    // Removed "isPiRequestSent" added back @01/06/2018
    var oneTimeCheck = ["isPiReceived", "isPiApproved", "isSampleapproved", "isSamplerecieved", "attrApproved", "readyToActivate", "attrCompleted", "earlyReqSampleSwatch", "sampleMoved", "isPiRequestEarly", "isPiRequestSent"],

        defaultDisabledList = ["isCopywriterApproved", "isWebproducerApproved", "isRetoucherApproved"],
        readyToActivateList = defaultDisabledList.concat(["attrApproved"]),
        disabled = '',
        defaultDisabled = '',
        sampledisabled = '';

		   if(readableFields.indexOf(columnfield) > -1){
        defaultDisabled = 'disabled';
    }

    if(writableFields.indexOf(columnfield) > -1){
        defaultDisabled = '';
    }

    disabled = (oneTimeCheck.indexOf(columnfield) > -1 ) ? ' disabled ' : '';
    defaultDisabled = (defaultDisabledList.indexOf(columnfield) > -1 ) ? ' disabled ' : '';

    if(columnfield === oneTimeCheck[2]){
        sampledisabled = convertDataType(rowdata[oneTimeCheck[3]]) != true ? 'disabled' : '';
    }else if(columnfield === oneTimeCheck[4] && rowdata.swatchOnly && rowdata.swatchOnly.toLowerCase() == "none"){
        sampledisabled = 'disabled';
    }else if (columnfield === "readyToActivate"){
        // to enable checkbox for item ready to order disablist list(3) plus attrApproved should be checked
        readyToActivateList.forEach(function(item, index){
            if(!rowdata[item]){
                defaultDisabled = ' disabled ';
            }
        });
    }else if (columnfield === "isPiReceived"){
        if (convertDataType(rowdata["isPiRequestSent"]) != true){
            defaultDisabled = ' disabled ';
        }
    } else if (columnfield === "isPiApproved"){
        if (convertDataType(rowdata["isPiRequestSent"]) != true || convertDataType(rowdata["isPiReceived"]) != true){
            defaultDisabled = ' disabled ';
        }
    }else if(columnfield === "attrApproved"){
        if(convertDataType(rowdata['attrCompleted']) != true){
            defaultDisabled = ' disabled ';
        }
    }else if(columnfield === "isPiRequestSent" || columnfield === "isSamplerecieved"){
        if(convertDataType(rowdata["itemReadyToOrder"]) != true){
            defaultDisabled = ' disabled ';
        }
    }
    else if(columnfield === "isActiveLive"){
        if(convertDataType(rowdata["readyToActivate"]) != true){
            defaultDisabled = ' disabled ';
        }
    }


    if(rowdata.copyEditable === false && copyPopupFields.indexOf(columnfield) > -1){
        defaultDisabled = 'disabled';
    }
    if(rowdata.displayEditable === false && displayPopupFields.indexOf(columnfield) > -1){
        defaultDisabled = 'disabled';
    }

    var className = "product-boolean";
    if(columnfield === "attrCompleted"){
        className = "product-attr-completed";
        var attributeFieds = ['attr1', 'attr2', 'attr3', 'attr4', 'attr5', 'attr6', 'attr7', 'attr8', 'attr9', 'attr10',
            'attr11', 'attr12', 'attr13', 'attr14', 'attr15', 'attr16', 'attr17', 'attr18', 'attr19', 'attr20'];
        var maxlength = window.settings ? window.settings.maxAttributeLength : 0;
			 var notEmptyCount = 0;
			 if(maxlength > 0){
				 for(i = 0;i < attributeFieds.length;i++){
                if(rowdata[attributeFieds[i]]){
                    notEmptyCount++;
                }
				 }
				 if(notEmptyCount < maxlength){
                defaultDisabled = "disabled";
				 }
			 }
    }

    // if (columnfield === 'isActiveLive' && rowdata.dataLevel != 'Sku') {
    // 	return  '';
    // }

    if(value){
        return '<span class="blue-checkbox "><input class="' + className + '" type="checkbox" data-fieldname="' +
			columnfield + '" checked data-row="' + row + '"' + disabled + defaultDisabled + ' data-row-type="' + rowdata.dataLevel + '" data-parent="' + rowdata.referrence + '"><label></label></span>';
    }else{
        return '<span class="blue-checkbox "><input class="' + className + '" type="checkbox" data-fieldname="' +
			columnfield + '" data-row="' + row + '"' + defaultDisabled + ' ' + sampledisabled + ' data-row-type="' + rowdata.dataLevel + '" data-parent="' + rowdata.referrence + '"><label></label></span>';
    }
};
// Product Copywrite and Keyword Click
var CellDoubleClickEvent = function(row, columnfield, value, rowdata) {
    if(rowdata.isLocked === true || columnfield == "copywrite" || columnfield == "keywords"){
        value = "";
    }
    if(rowdata.copyEditable === false && copyPopupFields.indexOf(columnfield) > -1){
        return value;
    }
    else if(rowdata.displayEditable === false && displayPopupFields.indexOf(columnfield) > -1){
        return value;
    }else{
        return '<div class="grid-text ' + columnfield + '" data-row="' + row + '" dataField="' + columnfield + '">' + value + '<span class="icon-expand-grid right"></span></div>';
    }
};

// Product Copywrite and Keyword Click
var ClickAddPOLink = function(row, columnfield, value, rowdata) {
    if(rowdata.isLocked === true){
        return '';
    }
    if(rowdata.copyEditable === false && copyPopupFields.indexOf(columnfield) > -1){
        return '';
    }
    return '<div class="' + columnfield + '" data-row="' + row + '" dataField="' + columnfield + '" data-vendorId="' + rowdata.vendorId + '" data-row-type="' + rowdata.dataLevel + '" data-parent="' + rowdata.referrence + '">Add PO</div>';
};

var cellClass = function (row, dataField, cellText, rowData) {
    var cellValue = rowData[dataField];
    var colorindicator = '';
    if(cellValue && cellValue != ""){
        colorindicator = "notempty";
    }
    if(dataField == "copywrite" && rowData.copyStatus){
        colorindicator = rowData.copyStatus.toLowerCase();
    }
    if(dataField == "keywords" && rowData.keywordsStatus){
        colorindicator = rowData.keywordsStatus.toLowerCase();
    }
    return colorindicator;
};

// var campaignPopup = function(row, columnfield, value, rowdata) {
//    return '<div class="grid-text '+columnfield+'" data-row="'+row+'" dataField="'+columnfield+'">'+value+'<span class="icon-expand-grid right campaign"></span></div>'
// }

var campaignPopup = function (row, columnfield, value, rowdata) {
    // var rowdata = this.owner.getrowdata(row);
    var key = rowdata.productId;
    if (rowdata.isLocked === true) {
        return '';
    }
    if (rowdata.displayEditable === false && displayPopupFields.indexOf(columnfield) > -1) {
        return '';
    }
    return '<div class="jqx-grid-cell-middle-left" style="margin-top: 6px;padding-right: 2px;"><div class="grid-text ' + columnfield + '" data-row="' + row + '" data-key="' + key + '" dataField="' + columnfield + '">' + value + '<span class="icon-expand-grid right campaign"></span></div></div>';
};

function findObjByKeyValue(arr, key, value) {
    var obj = null;
    for(var i = 0; i < arr.length;i++){
        if(arr[i][key] == value){
            obj = arr[i];
        }
    }
    return obj;
}


/**Grid data builder is used to create the object format required for
 * Jqwidgets.
 */
function GridDataBuilder(res, pageName, build){
    /** this will generate drop down template for the field name with type as DropDown,
	 *  the source for the dropdown should be available in the list response under dropdowns with
	 *  the same name as fieldName.
	 */
    var catNameAndVendorNameRender = function(row, columnfield, value, rowdata) {
        var dropdownKey = columnfield;
        if(columnfield == "catId"){
            dropdownKey = "allCatId";
        }
        var list = res.dropdowns[dropdownKey] ? res.dropdowns[dropdownKey] : [];
        if(columnfield == "vendorId" && window.newVendorListRes && window.newVendorListRes.length > 0){
            window.newVendorListRes.map(function(o, i){
                list.push(o);
            });
            window.newVendorListRes = null;
        }
        var obj = findObjByKeyValue(list, 'fieldId', value);
        var addVendorBtn = '';
        if(columnfield === "vendorId" && window.writableFields.indexOf(columnfield) > -1){
            addVendorBtn = '<span title="Add New Vendor" class="icon-expand-grid right addVendorFromProductGrid" data-row="' + row + '"></span>';
        }
        if(columnfield === "catId" && window.writableFields.indexOf(columnfield) > -1 ){
		    addVendorBtn = '<span title="Select Category" class="icon-expand-grid right selectcategory"></span>';
        }
        if(rowdata.isLocked === true){
            addVendorBtn = '';
        }
        if((value == "" || value == undefined || value == null)  && columnfield === "vendorId"){
            value = "Select Vendor";
        }
        if((value == "" || value == undefined || value == null) && columnfield === "catId"){
            value = "Select Category";
        }

        if(obj){
            if((rowdata.itemReadyToOrder == true && columnfield === "vendorId")
			|| (rowdata.isSampleapproved == true && columnfield === "catId")
			|| (rowdata.dataLevel !== "SP" && rowdata.parentRef != null)){
                return obj.fieldName;
            }
            return '<span>' + obj.fieldName + addVendorBtn + '</span>';
        }else{
            return '<span>' + value + addVendorBtn + '</span>';
        }
    };

    var  PiUploadField = function(row, columnfield, value, rowdata) {
        if(rowdata.copyEditable === false && copyPopupFields.indexOf(columnfield) > -1){
            return '';
        }

        let btn = '<span title="Upload PI" class="icon-expand-grid right upload-pi"></span>';
        return '<span>' + btn + '</span>';
    };


    var catFieldsRenderer = function(row, columnfield, value, rowdata) {
        var list = res.dropdowns.allCatId ? res.dropdowns.allCatId : [];
        var obj = findObjByKeyValue(list, 'fieldId', value);
        if(obj){
            return '<span>' + obj.fieldName + '</span>';
        }else{
            return '<span>' + value + '</span>';
        }
    };

    var ComboBoxEditor = function(row, fieldName, editor, cellText, width, height) {
        var fieldName = this.datafield;
        var list = res.dropdowns[fieldName] ? res.dropdowns[fieldName] : [],
            enableAutoHieght = list.length < 7 ? true : false;
        var placeHolder = {
            vendorId: 'Vendor',
            catId: 'Category',
            swatchOnly:'Swatch'
        };
        editor.jqxComboBox({
            autoDropDownHeight: enableAutoHieght,
            //selectedIndex: 0,
            source: list,
            displayMember: "fieldName",
            valueMember: "fieldId",
            width: '100%',
            theme: 'light',
            height:"auto",
            placeHolder: 'Select ' + placeHolder[fieldName],
            autoComplete:true
        });
    };

    var ComboBoxInit =  function (row, cellvalue, editor, celltext, width, height) {
        // set the editor's current value. The callback is called each time the editor is displayed.
        if(window.newVendorList && window.newVendorList.length > 0){
            window.newVendorList.map(function(o, i){
                var item = editor.jqxComboBox('getItemByValue', o.fieldId);
                if(item === undefined){
                    editor.jqxComboBox('addItem', { label:o.fieldName, value: o.fieldId});
                }
            });
        }

        cellvalue = cellvalue ? cellvalue : 0;
        editor.jqxComboBox('selectItem', cellvalue);
    };

    var ComboBoxUpdater = function (row, cellvalue, editor) {
        // return the old value, if the new value is empty.
        return editor.val();
    };

    var VariantDropDownEditorInit =  function (row, cellvalue, editor, celltext, width, height) {
        // set the editor's current value. The callback is called each time the editor is displayed.
        editor.jqxDropDownList('selectItem', cellvalue);
    };

    var VariantDropDownEditorUpdater = function (row, cellvalue, editor) {
        // return the old value, if the new value is empty.
        if(cellvalue && cellvalue.indexOf(',') > -1){
            return cellvalue;
        }else{
            return editor.val();
        }
    };
    // Dropdown editor for product variants ex: color, size, model, etc
    var VariantDropDownEditor = function(row, fieldName, editor, cellText, width, height) {
        // assign a new data source to the dropdownlist.
        var fieldName = this.datafield.split('_')[0];
        var list = Object.assign([], res.dropdowns[fieldName] ? res.dropdowns[fieldName] : []),
            enableAutoHieght = list.length < 7 ? true : false;

        // Pushing static value for variants
        if (list.indexOf('Create Multi Product') < 0) {
            list.push('Create Multi Product');
        }
        if(fieldName === "color_attribute"){
            unshift.unshift({fieldId:"", fieldName:"Select Color"});
        }
        if(fieldName === "size_attribute"){
            unshift.unshift({fieldId:"", fieldName:"Select Size"});
        }

        editor.jqxDropDownList({
            autoDropDownHeight: enableAutoHieght,
            source: list,
            dropDownHeight: 205,
            width:'100%',
            height:height,
            displayMember:"fieldName",
            valueMember:"fieldId",
            theme:'light',
            autoOpen:true,
            enableBrowserBoundsDetection:true,
            filterable:true,
            filterDelay:300,
            // selectedIndex: 0,
            placeHolder: 'Select ' + fieldName
        });
    };

    var DropDownEditor = function(row, fieldName, editor, cellText, width, height) {
        // assign a new data source to the dropdownlist.
        var fieldName = this.datafield;
        var list = res.dropdowns[fieldName] ? res.dropdowns[fieldName] : [],
            enableAutoHieght = list.length < 7 ? true : false;
        var placeHolder = {
            vendorId: 'Vendor',
            catId: 'Category',
            swatchOnly:'Swatch',
            color_attribute:"Color",
            size_attribute:"Size",
        };
        if(fieldName === "vendorId"){
            list.push({fieldId:"", fieldName:"Select Vendor"});
        }
        if(fieldName === "color_attribute"){
            list.push({fieldId:"", fieldName:"Select Color"});
        }
        if(fieldName === "size_attribute"){
            list.push({fieldId:"", fieldName:"Select Size"});
        }

        var finalPlaceholder = (placeHolder[fieldName]) ? placeHolder[fieldName] : fieldName;

        editor.jqxDropDownList({
            autoDropDownHeight: enableAutoHieght,
            source: list,
            dropDownHeight: 205,
            width:'100%',
            height:height,
            displayMember:"fieldName",
            valueMember:"fieldId",
            theme:'light',
            autoOpen:true,
            enableBrowserBoundsDetection:true,
            filterable:true,
            // selectedIndex: 0,
            placeHolder: 'Select ' + finalPlaceholder
        });
    };

    var DropDownInit =  function (row, cellvalue, editor, celltext, width, height) {
        // set the editor's current value. The callback is called each time the editor is displayed.
        if(window.newVendorList && window.newVendorList.length > 0){
            window.newVendorList.map(function(o, i){
                var item = editor.jqxDropDownList('getItemByValue', o.fieldId);
                if(item === undefined){
                    editor.jqxDropDownList('addItem', { label:o.fieldName, value: o.fieldId});
                }
            });
        }
        cellvalue = cellvalue ? cellvalue : "";
        editor.jqxDropDownList('selectItem', cellvalue);
    };

    var DropDownUpdater = function (row, cellvalue, editor) {
        // return the old value, if the new value is empty.
        //return editor.val();
        if(cellvalue && cellvalue.indexOf(',') > -1){
            return cellvalue;
        }else{
            return editor.val();
        }
    };

    // checkbox renderer
    var itemReadyCellRender = function (row, columnfield, value, rowdata) {
        var disabled = "", checked = "", defaultDisabled = "",
            disabledClass = '';
        if(rowdata.isLocked === true){
            return '';
        }

        if(readableFields.indexOf(columnfield) > -1){
            defaultDisabled = ' disabled';
        }

        if(writableFields.indexOf(columnfield) > -1){
            defaultDisabled = '';
        }

        if(window.readyToOrderFields && window.readyToOrderFields.length > 0){
            // checking if all the fields required for ready to order is filled.
            window.readyToOrderFields.forEach(function(item, index){
                if(!rowdata[item]){
                    //disabled = ' disabled ';
                    disabledClass = 'disabledReadyToOrder';
                    checked = '';
                }
            });
        }

        // disable the check box until all the users provide required fields for item ready to order

        if (rowdata.addPoStatus && value) {
            disabled = " disabled ";
        }
        else if(rowdata.copyEditable == false){
            if(copyPopupFields.indexOf(columnfield) > -1){
                disabled = 'disabled';
            }
        }
        else if(rowdata.displayEditable == false){
            if(displayPopupFields.indexOf(columnfield) > -1){
                disabled = 'disabled';
            }
        }

        if (value) {
            checked = " checked ";
            disabled = 'disabled';
        }


        return '<span class="blue-checkbox "><input class="product-bool ' + disabledClass + '" type="checkbox" data-fieldname="' +
		columnfield + '" ' + disabled + ' ' + defaultDisabled + checked + ' data-row="' + row + '" data-row-type="' + rowdata.dataLevel + '" data-parent="' + rowdata.referrence + '"/><label></label></span>';
    };

    // dataField is used for providing type of data and its name.
    // columnList is used to define column name, type, and extra fields
    //  to define additional plugins inside the cell eg: drop down, calender ...,
    var dataField = [],
        columnList = [],
        primayKey = res.columnDetails[0].fieldName,
        pinnedList = [];
    // check for the type of fieldname future

    //Forcing product and Image order
    // for(var i = 0; i < res.columnDetails.length; i++){
    // 	if(res.columnDetails[i].fieldName==="productId"){
    // 		res.columnDetails[i].order=1;
    // 	}
    // 	if(res.columnDetails[i].fieldName==="image"){
    // 		res.columnDetails[i].order=0;
    // 	}
    // }
    //sort columndetails in product pageName
    res.columnDetails.sort(function (a, b) {
        return a.order - b.order;
    });

    for(var i = 0; i < res.columnDetails.length; i++){
        dataField.push({"name":res.columnDetails[i].fieldName,
            "type":res.columnDetails[i].type.toLowerCase()});

        /* Enable the below condition after pk(primary key) availabel from backend
		*  in JSON response.
		*/

        if(res.columnDetails[i].hasOwnProperty("pk") && res.columnDetails[i].pk){
            primayKey = res.columnDetails[i].fieldName;
        }
        // Forcing product and Image order
        // if(res.columnDetails[i].fieldName==="productId"){
        // res.columnDetails[i].order=1;
        // }
        // if(res.columnDetails[i].fieldName==="image"){
        // res.columnDetails[i].order=0;
        // }

        columnList.push(columnObj(res.columnDetails[i]));
    }

    //sort columndetails in product pageName
    res.columnDetails.sort(function (a, b) {
        return a.order - b.order;
    });

    function titleCase(str) {
        return str.toLowerCase().split(' ').map(function(word) {
            return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
    }

    /** columnobj function will  return the object fields
   *  based on the field type in list.
   * e.g. for Date type and other types need to build the cellrender function.
   */
  	function columnObj(list) {
        var obj = {};
        var colTitle = list.columnTitle != null ? titleCase(list.columnTitle) : "";
        obj.text = '<span class="pd-drop" data-field="' + list.fieldName + '">' + colTitle + '</span>';
        obj.dataField = list.fieldName;
        // obj.width = (columnWidth[list.fieldName]) ? columnWidth[list.fieldName] : 120;
        obj.width = list.width;
        obj.hidden = !list.visibility;
        obj.cellClassName = function (row, column, value, data) {
            if (data.deleted) {
                return "strike-through deleted";
            }
        };

        //enable pinned/frozen column
        if(pinnedList.indexOf(list.fieldName) > -1){
            obj.pinned = true;
        }
        /* if list type is date, pass the methods related to date to intialize
		*  date picker
		*/

        /**
		 * Read Write Field Permission
		 */
        if(list.feildLevel === "R"){
            obj.editable = false;
            readableFields.push(list.fieldName);
            window.readableFields.push(list.fieldName);
        } else if(list.feildLevel === "W"){
            obj.editable = true;
            writableFields.push(list.fieldName);
            window.writableFields.push(list.fieldName);
        } else{
            obj.hidden = true;
        }

        if(["division", "department", "classColumn", "subClassColumn"].indexOf(list.fieldName) > -1){
            obj.editable = false;
            obj.cellsrenderer = catFieldsRenderer;
        }
        else if (["shotAngle", "copywrite", "keywords"].indexOf(list.fieldName) > -1) {
            obj.cellsrenderer = CellDoubleClickEvent;
            obj.editable = false;
            obj.cellClassName = cellClass;
        } else if (list.fieldName === 'productId') {
            obj.editable = false;
            //obj.draggable = false;
            //obj.pinned=true;
            obj.cellsrenderer = function(row, columnfield, value, rowdata){
                if(rowdata.dataLevel === "Grp"){
                    return value + "(" + rowdata.children.length + ")";
                }
                return value;
            };
        } else if(list.format === "Date & Time"){
            obj.columnType = "template";
            obj.cellsFormat = 'MM/dd/yyyy H:M';
            obj.createEditor = createDateTimeEditor ;
            obj.initEditor = initDateEditor ;
            obj.getEditorValue = getDateTimeEditor;
        }
        else if(list.type === "Date"){
            obj.columnType = "template";
            obj.cellsFormat = 'MM/dd/yyyy';
            obj.createEditor = createDateEditor ;
            obj.initEditor = initDateEditor ;
            obj.getEditorValue = getDateEditor;
            if(["sampleRecievedDate", "sampleApprovedDate", "piRequestedDate", "piReceivedDate", "copyWrittenDate",
                "isCopywritterApprovedDate", "photographedDate", "retouchercompletedDate", "piApprovedDate",
			    "imgRetouchedDate", "campaignDate", "targetDate"].indexOf(list.fieldName) > -1){
                obj.editable = false;
            }
        }
        else if (["attentionTo", "copyLevel", "displayLevel"].indexOf(list.fieldName) > -1) {
            obj.editable = false;
        } else if(list.type === 'image'){
            obj.editable = false;
            obj.cellsrenderer = imageRenderer;
            //obj.draggable = false;
            if(list.fieldName === 'images'){
                //obj.pinned=true;

            }
        }
        else if(list.fieldName === "addPO"){
            obj.cellsrenderer = ClickAddPOLink;
            obj.editable = false;
        }else if (list.fieldName == 'swatchOnly') {
            obj.cellsrenderer = CellDoubleClickEvent;
            obj.columntype = 'template';
            obj.createEditor = DropDownEditor;
            obj.initEditor = DropDownInit;
            obj.getEditorValue = DropDownUpdater;
        }else if (list.fieldName == 'campaign') {
            obj.cellsrenderer = campaignPopup;
            obj.editable = false;
        }else if(list.fieldName == 'swatchReference'){
            obj.editable = false;
        }
        else if(list.fieldName === 'vendorId'){
            obj.columntype = 'template';
            obj.cellsrenderer = catNameAndVendorNameRender;
            /*obj.createEditor = ComboBoxEditor;
			obj.initEditor =ComboBoxInit;
			obj.getEditorValue = ComboBoxUpdater;*/
            obj.createEditor = DropDownEditor;
            obj.initEditor = DropDownInit;
            obj.getEditorValue = DropDownUpdater;
            //obj.editable=true;
        }
        else if(list.fieldName === "catId"){
            obj.cellsrenderer = catNameAndVendorNameRender;
            obj.editable = false;
        }
        else if(list.type === "DropDown"){
            obj.columntype = 'template';
            obj.createEditor = DropDownEditor;
            obj.initEditor = DropDownInit;
            obj.getEditorValue = DropDownUpdater;
            obj.editable = true;
        }else if(list.fieldName === "itemReadyToOrder" ||
			list.fieldName === "accessToItemReady"){
            obj.editable = false;
            obj.cellsrenderer = itemReadyCellRender;
            obj.cellsAlign = 'center';
        }else if(list.type === "boolean"){
            obj.editable = false;
            obj.cellsrenderer = productCheckbox;
            obj.cellsAlign = 'center';
        } else if (list.type === 'list') {
            obj.columntype = 'template';
            obj.createEditor = VariantDropDownEditor;
			 obj.initEditor = VariantDropDownEditorInit;
			 obj.getEditorValue = VariantDropDownEditorUpdater;
            //obj.initEditor = DropDownInit;
            //obj.getEditorValue = DropDownUpdater;
            obj.type = "list";
            obj.editable = true;
            if(["color_attribute", "size_attribute"].indexOf(list.fieldName) > -1){
                obj.cellsrenderer = function(row, columnfield, value, rowdata){
                    var label = {
                        color_attribute:"Select Color",
                        size_attribute:"Select Size"
                    };

                    if(value == "" || value == undefined || value == null){
                        value = label[columnfield];
                    }
                    return value;
                };
            }
        } else if(list.fieldName === 'piUpload'){
            obj.cellsrenderer = PiUploadField;
            obj.editable = false;
        }else if(["promoPrice", "retailPrice", "salesPrice"].indexOf(list.fieldName) > -1){
            /*obj.cellsrenderer = function(row, columnfield, value, rowdata){
				if(rowdata.cronJobField===true){
					return '<span class="" style="background-color:#f15a29">'+value+'</span>';
				}
				return value;
			};*/
            obj.cellClassName = function (row, dataField, cellText, rowData) {
                if (rowData.cronJobField === true) {
                    return "nonedit";
                }
                return "";
            };
        }

        // cellsformat property
        var cellsFormat = {
            Standard: "f2",
            Integers: "n",
            Currency: "c2",
        };

        if (cellsFormat[list.format]) {
            obj.cellsFormat = cellsFormat[list.format];
        }
        return obj;
  	}

    if(pageName == 'product'){
        var obj = {};
        obj.text = "<span class='header-checkbox pd-drop' data-checked='false' data-field='checkboxId'></span>";
        obj.dataField = 'checkboxId';
        obj.width = 100;
        // obj.columntype = 'checkbox';
        obj.editable = false;
        obj.sortable = true;
        //obj.pinned = true;
        // place the checkboxs in first columnList
        columnList.unshift(obj);
        dataField.push({
            name:'isLocked',
            type:'boolean'
        });
        dataField.push({
            name:'cronJobField',
            type:'boolean'
        });
        dataField.push({
            name:"dynaData",
            type:"json"
        });
    }
    /*dataField.push({
		name:'id',
		type:'number'
	});*/
    dataField.push({
        name:'children',
        type:'array'
    });

    var source = {
        dataType:"json",
        dataFields:dataField,
        id:primayKey,
        hierarchy: {
            //keyDataField: { name: 'productId' },
            //parentDataField: { name: 'referrence' }
            root:"children"
        },
        localdata: build ? res.data : treeify(res.data, "productId", "referrence", "children")
    };

    var dataAdapter = new $.jqx.dataAdapter(source);

    return {
        dataAdapter:dataAdapter,
        columns:columnList
    };
}
function generateData(data){
    for(var i = 0;i < data.length;i++){
	  	data[i].id = i + 1;
    }
    var list = treeify(data, "productId", "referrence", "children");
    return list;
}
function treeify(list, idAttr, parentAttr, childrenAttr) {
    if (!idAttr) idAttr = 'id';
    if (!parentAttr) parentAttr = 'parent';
    if (!childrenAttr) childrenAttr = 'children';

    var treeList = [];
    var lookup = {};
    list.forEach(function(obj) {
        if(obj.dynaData) {
            for(var key in obj.dynaData){
                if(obj.dynaData.hasOwnProperty(key)){
                    obj[key] = obj.dynaData[key];
                }
            }
        }
        lookup[obj[idAttr]] = obj;
        obj[childrenAttr] = [];
    });

    list.forEach(function(obj) {
        for(var i = 0;i < checboxFields.length;i++){
            if(obj[checboxFields[i]]){
                obj[checboxFields[i]] = obj[checboxFields[i]].toString() != "true" ? false : true;
            }
            else{
                obj[checboxFields[i]] = false;
            }
        }
        if (obj[parentAttr] != null && lookup[obj[parentAttr]]) {
            lookup[obj[parentAttr]][childrenAttr].push(obj);
        } else {
            treeList.push(obj);
        }
    });
    return treeList;
}

export default GridDataBuilder;
