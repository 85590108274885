import React, { Component } from 'react';
import Modal from '../../common/modals/commonModal';
import AddVendorForm from './addVendorForm';

class VendorModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let modalContent = '';
        if (this.props.modalName === 'add_vendor') {
            modalContent = <AddVendorForm modalClose={this.props.modalClose} reloadGrid = {this.props.reloadGrid} userData={this.props.userData} addVendorRow={this.props.addVendorRow} user={this.props.user}/>;
        }

        return (
            <Modal isOpen={this.props.isOpen}>
                {modalContent}
            </Modal>
        );
    }
}

export default VendorModal;
