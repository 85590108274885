import React, { Component } from 'react';
import axios from 'axios';
import AdminServices from '../../admin/admin-services/admin-services.js';

/** Showing Recent Message */
class RecentMessage extends Component{
    constructor(props){
        super(props);

        this.recentMessageList = [];
        this.state = {
            isLoaded: false
        };
    }

    componentDidMount() {
        AdminServices.recentMessage().then((response) => {
            if (response.status) {
                console.log('RecentMessages Dropdown API success', response);

                this.recentMessageList = response.data;
                this.setState({ isLoaded: true });
            }
        }).catch((error) => {
            console.log('Recent Message Component Did Mount api call Error: ', error);
        });
    }

    render() {
        var list = this.recentMessageList;

        let content = list.map((currElement, index) => {
            return <li key={index}>{currElement}</li>;
        });

        return (
            <div>
                { this.state.isLoaded &&
                <div className={`scroll recentmessage-dropdown ${(this.props.isOpen) ? "open" : ''}`}>
                    <ul>
                        {content}
                    </ul>
                </div>
                }
            </div>
        );
    }
}

export default RecentMessage;
