import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import SavedListDropDown from './helperComponents/savedListDropDown';
import Accordion from '../common/components/accordion.js';
import Modal from '../modals/common-modal.js';
import CancelModal from '../modals/cancelModal.js';

const RightDrawerComponent = ({
    state,
    props,
    presentationPanelAccordions,
    selectedPresentationNames,
    openListNameModal,
    clearList,
    addToList,
    reportPdfType,
    activateItemsOnWebsite,
    generateCsvForGrid,
    goToPresentationGrid,
    closeListNameModal,
    handleListName,
    saveListName,
    closeConfirmOverideModal,
    finalSaveListName,
    finalClearList,
    clearAll,
    modalClose,
    clrFlag,
    addToListButtonDisabledStatus,
    closeClearListModal,
    openClearListModal,
    exportFileForMagento,
}) => {
    return (
        <div className={`presentation-drawer drawer ${(props.rightDrawerPulled) ? "presentation-drawer-open" : ""}`}>
            <div>
                <div className="presentation-panel-content">
                    <div className="drawer-title">
                        Reporting
                        <span className="close-btn">
                            <i className="icon-close" onClick={props.toggleRightDrawer}></i>
                        </span>
                    </div>
                    <div className="drawer-content">
                        <div className="saved-list">
                            <SavedListDropDown pageType={props.pageType} clearflag={state.bResetPanel} clrFlag={clrFlag} user={props.user} {...props} />
                        </div>
                        {
                            selectedPresentationNames.length > 0 &&
                        <div className="saved-list-actions clearfix">
                            <label>Selected Items to Publish</label>
                            <a href="javascript:void(0)" className="save" onClick={openListNameModal}>Save this List</a>
                        </div>
                        }
                        <div className="selected-items-container">
                            {
                                selectedPresentationNames.length > 0 &&
                            <a href="javascript:void(0)" className={"clear"} onClick={clearList}>Clear List</a>
                            }
                            <ul className="element-list scroll">
                                {
                                    selectedPresentationNames.map((name, i) => {
                                        return <li key={i} className="list-item">{name}</li>;
                                    })
                                }
                            </ul>
                            <div className={"footer clearfix " + (state.isItemsinList ? "" : "hide")}>
                                <span className="selected">Items selected - <span>{state.selectedPresentationNames.length}</span></span>
                            </div>
                        </div>
                        <div className="selected-items-actions">
                            <button className="gray-button" disabled>Add to List</button>
                            <br />
                            <button className="gray-button" disabled>View Presentation Grid</button>
                        </div>
                        <div className="panel-bottom actions">
                            <div className="panel-accordions">
                                {
                                    state.loadAccordion &&
                                <Accordion
                                    updateView={reportPdfType}
                                    pageTitle={props.pageType}
                                    activateItemsOnWebsite={activateItemsOnWebsite}
                                    generateCsvForGrid={generateCsvForGrid}
                                    data={presentationPanelAccordions}
                                    user={props.user}
                                    exportFileForMagento = {exportFileForMagento}
                                />
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="puller" onClick={props.toggleRightDrawer}>
                    <i className="pane-arrow"></i>
                </div>

                {/* Panel Modals */}
                <Modal isOpen={state.isSaveListModalOpen}
                    addClass={`${(state.modalName == "ListNameModal") ? 'list-name-popup' : ''} `}>
                    {
                        state.modalName === "ListNameModal" &&
                    <div>
                        <div className={"save-list " + (state.isClearAll ? "hide" : "")}>
                            <div className="modal-header">
                                Presentation List Name
                                <span className="close" onClick={closeListNameModal}>x</span>
                            </div>
                            <div className="modal-content">
                                <form name="list-name-form" className={"list-name-form " + (!state.hideConfirmSection ? "hide" : "")}>
                                    <div className="form-group">
                                        <label>List Name</label>
                                        <input type="text" className="form-control" name="list-name" onChange={handleListName} value={state.currentListName} />
                                    </div>
                                    <div className="modal-footer text-center">
                                        <button className="gray-button" onClick={closeListNameModal}>Cancel</button>
                                        <button className="gray-button" onClick={saveListName}>Save</button>
                                    </div>
                                </form>
                                <div className={"confirm-content " + (state.hideConfirmSection ? "hide" : "")}>
                                    Do you want to Overwrite the list
                                    <div className="modal-content text-center">
                                        <button className="gray-button" onClick={closeConfirmOverideModal}>Cancel</button>
                                        <button className="gray-button" onClick={finalSaveListName}>Overwrite</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"clear-list " + (!state.isClearAll ? "hide" : "")}>
                            <div className="modal-content">
                                <div className="confirm-content">
                                    {
                                        state.bListSelected ? <h4 className="text-center">Do you want to clear the contents of the saved list?</h4> : <h4 className="text-center">Do you want to save this new list?</h4>
                                    }
                                    <div className="modal-footer text-center">
                                        <button className="gray-button" onClick={openClearListModal}>Yes</button>
                                        <button className="gray-button" onClick={closeListNameModal}>No</button>
                                        <button className="gray-button" onClick={closeListNameModal}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </Modal>
                <Modal isOpen={state.isClearListModalOpen}>
                    <div className="modal-content">
                        <div className="confirm-content">
                            <div className="modal-footer text-center">
                                <h4 className="text-center">Click Yes to confirm clearing the list</h4>
                                <button className="gray-button" onClick={finalClearList}>Yes</button>
                                <button className="gray-button" onClick={closeClearListModal}>No</button>
                                <button className="gray-button" onClick={closeClearListModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <CancelModal isOpen={state.isCancelModalOpen} modalClose={modalClose} />

            </div>
        </div>
    );
};

RightDrawerComponent.propTypes = {
    toggleLeftDrawer: PropTypes.func,
    state: PropTypes.object,
    props: PropTypes.object
};

export default withRouter(RightDrawerComponent);
