import React from 'react';
import ReactPaginate from 'react-paginate';

class PacketPreview extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='preview-container'>
                <div className="preview-holder scroll">
                    <p>Packet Preview</p>
                    <div className="preview">
                        {this.props.picPacUrl !== '' &&
                        <div id="embedHolder">
                            <embed src={this.props.picPacUrl + '#toolbar=0'} width="500px" height="400px" />
                        </div>
                        }
                    </div>
                </div>
                {this.props.totalPages > 1 &&
                <ReactPaginate previousLabel={"<< Previous Page"}
                    nextLabel={"Next Page >>"}
                    breakLabel={<a href="">...</a>}
                    breakClassName={"break-me"}
                    pageCount={this.props.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.props.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"} />
                }
            </div>
        );
    }
}

export default PacketPreview;
