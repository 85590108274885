import React from 'react';
import ImageUpload from '../image/imageUpload';
import {Checkbox} from './inputComponents';
import { DropdownInputs } from './dropdownInputs';

export class SingleViewRender extends React.Component {
    constructor(props){
        super(props);

        this.state = {

        };
    }

    render() {
        const IconDisplay = <div className="ag-popupIndicator"><img src={require('../../../common/icons/Expand Grid.svg')} /></div>; // Used to display the icon

        return(
            <div>{IconDisplay}</div>
        );
    }
}


export class SingleViewEditor extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            imageArr: [],
            showImage: null,
            colData: [],
            rowData: null,
            enableSubmit: true,
            dispDropdown: {},
        };

        this.updateRowData = this.updateRowData.bind(this);
        this.saveModalData = this.saveModalData.bind(this);
        this.textChange = this.textChange.bind(this);
        this.showDropdown = this.showDropdown.bind(this);
        this.closeDropdown = this.closeDropdown.bind(this);
        this.updateDropdown = this.updateDropdown.bind(this);
    }

    componentDidMount() {
        let colDefs = this.props.colDefs();

        const data = this.props.node.data;
        const columns = this.props.columnApi.getColumnState();
        //  ? this.props.node.data.images : [],
        if (this.props.node.data.images.length > 0) {
            let newData = this.state.imageArr;
            newData['images'] = this.props.node.data.images;
            this.setState({
                imageArr: newData,
            }, () => {
                this.setImage();
            });
        }

        let colArr = [];
        let finalArr = [];

        colDefs.map((i) => {
            if (i.order == 999 || ['images', 'productId', 'styleNumber', 'prodName'].includes(i.field)) {
                i.hide = true;
            }

            if (!i.hide) {
                finalArr.push({
                    'field': i.headerName,
                    'value': data[i.field],
                    'order': i.order,
                    'data': i,
                });
            }
        });

        this.setState({
            colData: finalArr,
            rowData: this.props.node.data,
        });
    }

    // Comes with AG grid used to set a cell to be a popup or not
    isPopup() {
        return true;
    }

    // Gets value when the popup has been closed
    getValue() {
        return this.props.status;
    }

    // closes the modal
    closeModal() {
        this.props.api.stopEditing();
    }

    updateRowData(data) {
        let colData = this.state.colData;
        let obj = this.state.rowData;
        let colName = data.col;
        obj[colName] = data.isBool;

        this.setState({
            rowData: obj,
            enableSubmit: false,
        });
    }

    updateDropdown(data) {
        let colData = this.state.colData;
        let obj = this.state.rowData;
        let colName = data.col;
        obj[colName] = data.value;

        colData.forEach((i) =>{
            if (i.data.colId == colName) {
                i.value = data.value;
            }
        });

        this.setState({
            rowData: obj,
            enableSubmit: false,
            colData: colData,

        });
    }

    textChange(e) {
        let value = e.target.value;
        let colId = e.target.id;
        let obj = this.state.rowData;
        let colName = colId;
        obj[colName] = value;

        this.setState({
            rowData: obj,
            enableSubmit: false,
        });
    }


    saveModalData() {
        console.log('SingleViewEditor saveModalData', this.state);

        this.props.rowUpdate(this.state.rowData, this.props.node.rowIndex);
    }

    setImage() {
        console.log('SingleViewEditor setImage', this.state);

        let setImg = this.state.imageArr.images.length > 0 ?
            <img src={this.state.imageArr.images[0].imageUrl}/>
            :
            '';

        // return setImg
        this.setState({
            showImage: setImg,
        });
    }

    uploadImage() {
        let imageInfo = {};
        const images = this.props.node.data.images;
        const user = this.props.user;

        imageInfo['images'] = images;
        if (this.props.node.data.dataLevel == 'SINGLE') {
            imageInfo['dataLevel'] = this.props.node.data.dataLevel;
            imageInfo['chldProuctId'] = this.props.node.data.chldProdId;
        } else if (this.props.node.data.dataLevel == 'PARENT') {
            imageInfo['dataLevel'] = this.props.node.data.dataLevel;
            imageInfo['chldProuctId'] = this.props.node.data.parentProductId;
        } else if (this.props.node.data.dataLevel == 'INTERMEDIATE') {
            imageInfo['dataLevel'] = this.props.node.data.dataLevel;
            imageInfo['childrenIds'] = this.props.node.data.intProdId;
        } else if (this.props.node.data.dataLevel == 'CHILD') {
            imageInfo['dataLevel'] = this.props.node.data.dataLevel;
            imageInfo['childrenIds'] = this.props.node.data.chldProdId;
        }

        return(
            <ImageUpload imageInfo={imageInfo}
                user={this.props.user}
                imageArray={this.state.imageArr}
                updateImage = {this.updateImageAfterSave}
                imageData = {this.keepImgData.bind(this)}
            />
        );
    }

    updateImageAfterSave(data) {
    }

    keepImgData(data) {
        const img = <img src={data.data[0].imageUrl}/>;
        this.setState({
            showImage: img,
        });
        this.props.node.setDataValue('images', data.data);
    }

    changeSelectedValue(data) {
    }

    showDropdown(e) {
        const id = e.target.id;
        let sSet = this.state.dispDropdown;

        if (this.props.node.data.dataLevel == 'PARENT') {
            return;
        }

        sSet[id] = true;

        this.setState({
            dispDropdown: sSet,
        });
    }

    closeDropdown() {
        this.setState({
            dispDropdown: [],
        });
    }


    render() {
        const data = this.props.node.data;
        let setInput;
        const checkBoxes = ['Item Ready To Order', 'Pi request Sent', 'Pi Received', 'Pi Approved', 'Sample Requested', 'Sample Received', 'Sample Passed',
            'Copy Approved', 'Keywords Approved'];
        let disabledStatus = this.props.node.data.dataLevel == 'PARENT' ? true : false;

        let inputFields = this.state.colData && this.state.colData.map((i, x) => {
            let dropdownVal =  i.value ?
                <input id={i.data.colId} disabled={disabledStatus} type="text" id={i.field} onClick={this.showDropdown} value={i.value} />
                :
                <input disabled={disabledStatus} onClick={this.showDropdown} id={i.field} type="text"/>;


            if (checkBoxes.includes(i.field)) {
                setInput = <div className='component-container'>
                    <Checkbox data={i} props={this.props} saveValues={this.updateRowData} itemReadyToOrderFields={this.props.itemReadyToOrderFields}/>
                </div>;
            } else if (i.field == 'Color') {
                setInput = <div className='component-container'>
                    {
                        this.state.dispDropdown[i.field] ?
                            <DropdownInputs open={this.state.dispDropdown} update={this.updateDropdown} data={i} selected={i.value} values={this.props.getColorsList()} close={this.closeDropdown} />
                            :
                            dropdownVal
                    }
                </div>;
            } else if (i.field == 'Size') {
                setInput = <div className='component-container'>
                    {
                        this.state.dispDropdown[i.field] ?
                            <DropdownInputs open={this.state.dispDropdown} update={this.updateDropdown} data={i} selected={i.value} values={this.props.getSizeList()} close={this.closeDropdown} />
                            :
                            dropdownVal
                    }
                </div>;
            } else {
                setInput = <div className='component-container'><input id={i.data.colId} type="text" value={i.value} /></div>;
            }


            return(
                <div className='input-container' key={x}>
                    <label className="form-label">{i.field}</label>
                    { setInput }
                </div>
            );
        });

        return(

            <div className="ag-modal-container">
                <div className="ag-modal-holder">
                    <div className="modal-header">
                    Single View
                        <span className="close" onClick={this.closeModal.bind(this)}>x</span>
                    </div>
                    <div className='single-view-container'>
                        <div className='static-section'>
                            <div className='image'>
                                {this.state.showImage ? this.state.showImage : this.uploadImage() }
                            </div>
                            <div className='prod-desc'>
                                <div className='section-sweft-id'>
                                    <label className="form-label">Sweft-ID</label>
                                    <input type="text" disabled value={data.productId}/>
                                </div>
                                <div className='section-style-number'>
                                    <label className="form-label">Style Number</label>
                                    <input type="text" id='styleNumber' onChange={this.textChange} value={data.styleNumber}/>
                                </div>
                                <div className='section-prod-name'>
                                    <label className="form-label">Product Name</label>
                                    <textarea id='prodName' onChange={this.textChange} value={data.prodName} />
                                </div>
                            </div>
                        </div>
                        <div className='fields-section'>
                            {inputFields}
                        </div>
                        <div className='close-section'>
                            <div className='button-container'>
                                <input type="button" className="gray-button" value="Back" onClick={this.closeModal.bind(this)} />
                                <input type="button" disabled={this.state.enableSubmit} className="gray-button" value="Submit" onClick={this.saveModalData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
