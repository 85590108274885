import React from 'react';
import axios from 'axios';

class RolesDropDown extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            showRolesDropDown: false
        };

        this.userHeirarchy = this.userHeirarchy.bind(this);
        this.toggleRolesDropDown = this.toggleRolesDropDown.bind(this);
    }

    componentDidMount() {
    }

    userHeirarchy(list, level) {
        let paddingLeft = "";
        let cssPadding = 0;

        if (level > 0) {
            paddingLeft = "--";
            cssPadding = level * 10;
        }

        var cssstyle = {
            "paddingLeft": cssPadding + 15
        };

        let items = list.map((o, i) => {
            return (
                <ul key={i} className={`lineitem level-` + level}>
                    <li style={cssstyle} onClick={() =>{  }}>
                        { paddingLeft + o.userName }
                    </li>
                    { o.subUsers && o.subUsers.length > 0 &&
                        this.userHeirarchy(o.subUsers, level + 1)
                    }
                </ul>
            );
        });

        return items;
    }

    toggleRolesDropDown() {
        this.setState({ showRolesDropDown: !this.state.showRolesDropDown });

        $('.dropdown-el').toggleClass("active");
    }

    render() {
        let userlist = [];
        userlist.push(this.props.roleslist.data);

        return (
            <div className="dropdown-el">
                <label onClick={this.toggleRolesDropDown}>Based on: <span id="selecteduser" >{this.props.username}</span></label>
                { this.state.showRolesDropDown &&
                <div className="dropdownl-list scroll expanded"  >
                    {this.userHeirarchy(userlist, 0)}
                </div>
                }
            </div>
        );
    }
}

export default RolesDropDown;
