import React from 'react';

import ImageListerModal from './helperComponents/imageListerModal';
import DeleteConfirm from './helperComponents/deleteConfirm';
import RequiredFieldsConfirm from './helperComponents/requiredFieldsConfirm';
import AddCopyModel from './helperComponents/add-copy-modal';
import AddKeywordModal from './helperComponents/add-keyword-modal';
import MandatoryFieldPopup from './helperComponents/mandatoryFieldPopup';
import SampleModal from './helperComponents/sample-modal';
import AddPOModal from './helperComponents/add-po-modal';
import SampleMessagePopup from './helperComponents/sampleMessagePopup';
import SampleSwatchDropDown from './helperComponents/sampleSwatchDropDown';
import PiUploadModal from './helperComponents/piUploadModal';
import SubmitConfirm from './helperComponents/confirmsubmit';
import AddVendorModal from './helperComponents/add-vendor';
import ProductOptionModal from './helperComponents/product-options';
import CopyDisplayModal from './helperComponents/copy-display-modal';
import ProductGridPopup from './helperComponents/product-group-popup';
import SelectCategory from './helperComponents/select-category';
import CampaignModal from './helperComponents/campaign-modal';
import ShotAngles from './helperComponents/add-shot-angles';
import FeatureColorList from './helperComponents/featureColorList';



class SwitchTheModal extends React.Component {
    render() {
        let props = this.props;
        console.log('product switch the modal props modalname', this.props);
        switch (props.modalName) {
            case "image":
                return <ImageListerModal {...props} />;
            case "deleteConfirm":
                return <DeleteConfirm {...props} />;
            case "requiredFieldConfirm":
                return <RequiredFieldsConfirm {...props} />;
            case "addCopyModel":
                return <AddCopyModel {...props} />;
            case "addKeywordModal":
                return <AddKeywordModal {...props} />;
            case "emptyMandatoryfield":
                return <MandatoryFieldPopup {...props} />;
            case "SampleModal":
                return <SampleModal {...props} />;
            case "AddPOModal":
                return <AddPOModal {...props} />;
            case "sampleMessagePopup":
                return <SampleMessagePopup {...props} />;
            case "sampleSwatchDropDown":
                return <SampleSwatchDropDown {...props} />;
            case "PiUploadModal":
                return <PiUploadModal {...props} />;
            case "submitConfirm":
                return <SubmitConfirm {...props} />;
            case "addVendorModal":
                return <AddVendorModal {...props} />;
            case "productOption":
                return <ProductOptionModal {...props} />;
            case "copyordisplayLevel":
                return <CopyDisplayModal {...props} />;
            case "ProductGridPopup":
                return <ProductGridPopup {...props} />;
            case "SelectCategory":
                return <SelectCategory {...props} />;
            case "CampaignModal":
                return <CampaignModal {...props} />;
            case "ShotAngles":
                return <ShotAngles {...props} />;
            case "featureColorList":
                return <FeatureColorList {...props} />;
            default:
                return null;
        }
    }
}

export default SwitchTheModal;
