import React, { Component } from 'react';

class ViewDeleteConfirm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isConfirmed: false
        };

        this.toggleConfirmed = this.toggleConfirmed.bind(this);
    }

    toggleConfirmed() {
        this.setState({ isConfirmed: true });
    }

    render() {
        let props = this.props;
        let isConfirmed = this.state.isConfirmed;
        let isSystemView = (props.viewDetails.type === "systemView") ? true : false;
        let header = (!isConfirmed) ? `Confirm Deletion of "${props.viewDetails.viewName}" view` :
            'Are you sure you want to permanently delete this system view?';

        if (!isSystemView) {
            header = `Are you sure you want to delete "${props.viewDetails.viewName}" view?`;
        }

        return (
            <div>
                <div className="modal-content">
                    <div className="text-center confirm-delete">
                        <h4 className="text-transform-none">{header}</h4>
                    </div>
                    <div className="modal-footer text-center">

                        { !isConfirmed &&
                        <button className="gray-button" onClick={() => {
                            if (isSystemView) {
                                this.toggleConfirmed();
                            } else {
                                props.confirmDelete();
                                props.modalClose();
                            }
                        }}>Yes</button>
                        }
                        <button className="gray-button" onClick={() => {
                            props.modalClose();
                        }}>No</button>
                        { isConfirmed &&
                        <button className="gray-button" onClick={() => {
                            props.confirmDelete();
                            props.modalClose();
                        }}>Delete System View</button>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default ViewDeleteConfirm;
