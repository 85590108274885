import React from 'react';
import { CustomDropdownEditor } from '../customDropdownEditor';

export class VendorDropdownPopUpRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            // vendorsList: props.getVendorsList(),
        };
    }


    render() {
        let flag = false;

        if (this.props.data) {
            if (this.props.node.data.displayLevel == 'Color' && this.props.node.level == 1) {
                flag = true;
            }

            if (this.props.node.data.displayLevel == 'Style' && this.props.node.level == 0) {
                flag = true;
            }

            if (this.props.node.data.displayLevel == 'Size' && this.props.node.level == 2) {
                flag = true;
            }

            if (this.props.node.data.dataLevel == 'SINGLE') {
                flag = true;
            }

            if (!this.props.node.data['vendorId']) {
                flag = true;
            }

            if (!this.props.node.data.dataLevel) {
                flag = true;
            }
        }

        let vendorName = this.props.value;

        if (vendorName) {
            return (
                <div className={flag == true ? '' : 'grey-cell-text'}>
                    {vendorName}
                </div>
            );
        } else {
            return (
                <div className="arrow downPos" />
            );
        }
    }
}
// ///////////////////////////////////////////////////////////////////////////////////////////////

export class VendorDropdownPopUpEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedVal: '',
            vendorsList: props.getVendorsList(),
            width: null,
            searchValue: null,
            searchedValues: [],
        };
    }

    // vendorsList.push({fieldId: '', fieldName: 'Add New Vendor'}); // adding a value so new vendor can be added


    componentWillMount() {
        let flag = false;
        const cellWidth = this.props.eGridCell.style.width;

        if (this.props.node.data.displayLevel == 'Color' && this.props.node.level == 1) {
            flag = true;
        }

        if (this.props.node.data.displayLevel == 'Style' && this.props.node.level == 0) {
            flag = true;
        }

        if (this.props.node.data.displayLevel == 'Size' && this.props.node.level == 2) {
            flag = true;
        }

        if (this.props.node.data.dataLevel == 'SINGLE') {
            flag = true;
        }

        if (!this.props.node.data.dataLevel) {
            flag = true;
        }

        if (!this.props.node.data['vendorId']) {
            flag = true;
        }

        if (!flag) {
            this.props.api.stopEditing();
        } else {
            const _this = this;
            const vendorsList = this.state.vendorsList;

            const filteredVendorsList = vendorsList.filter((obj) => obj.fieldName != 'Add New Vendor');

            filteredVendorsList.sort(function(a, b) {
                const alc = a.fieldName.toLowerCase();
                const blc = b.fieldName.toLowerCase();
                return alc > blc ? 1 : alc < blc ? -1 : 0;
            });

            filteredVendorsList.push({fieldId: '', fieldName: 'Add New Vendor'});

            let vendorName;
            if (this.props.node.data.vendorId) {
                filteredVendorsList.forEach(function(row) {
                    if (row.fieldId === _this.props.node.data.vendorId) {
                        vendorName = row.fieldName;
                    }
                });
                this.setState({ selectedVal: vendorName });
            }
            this.setState({vendorsList: filteredVendorsList, width: cellWidth });
        }
    }

    isPopup() {
        return true;
    }

    componentWillUnmount() {
        this.props.api.stopEditing();
        this.props.api.tabToNextCell();
    }

    /**
     * Gets the value when the component unmounts and pushes it for render
     * @return {string} cell value
     */
    getValue() {
        return this.state.selectedVal;
    }

    /**
     * Changes the selected value of the dropdown
     * @param {*} value
     */
    changeSelectedValue(value) {
        this.setState({
            selectedVal: value,
        }, () => {
            this.props.api.stopEditing();
        });
    }

    render() {
        return (
            <div>
                <CustomDropdownEditor
                    width = {this.state.width}
                    selectedVal = {this.state.selectedVal}
                    vendorsList = {this.state.vendorsList}
                    changeSelectedValue = {this.changeSelectedValue.bind(this)}
                    {...this.props}
                />
            </div>
        );
    }
}

// ////////////////////////////////////////////////////////////////////////////////////

