import React, { Component } from 'react';
import { connect } from 'react-redux';
import { rulesForAlerts } from './helperComponents/actions';

import AlertGridComponent from './alerts-gridComponent';
import NotificationManager from '../common/components/notification';

import AlertsDataBuilder from './helperComponents/alerts-data-builder';

import axios from 'axios';
import AdminServices from '../admin/admin-services/admin-services';

class AlertsGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isModalOpen: false,
            selectedRow: {},
            isCancelEnable: false,
            checkedRows: [],
            selectedRowsCount: 0,
            checkedAlertData: [],
        };

        this.modalOpen = this.modalOpen.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.updateRow = this.updateRow.bind(this);
        this.sendData = this.sendData.bind(this);
        this.addRow = this.addRow.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.removeGridRows = this.removeGridRows.bind(this);
    }

    modalClose() {
        this.setState({ isModalOpen: false });
    }

    modalOpen() {
        this.setState({ isModalOpen: true });
    }

    updateRow(rowDetails) {
        this.alertGrid.updaterow(rowDetails.uid, rowDetails);
    }

    /**
   * Removes rows after user has been deleted
   * @param {*} rows
   */
    removeGridRows(rows) {
    // const _this = this;
    // console.log(_this.alertGrid);
    // rows.forEach((e, index) => {
    //     _this.alertGrid.deleterow(e);
    // });
    // _this.setState({ selectedRowsCount: 0, checkedRows: [], checkedAlertData: [] });
    // _this.alertGrid.refresh();
        this.addRow();
    }

    addRow(data, newRow) {
        const base = this;
        base.setState({ isLoaded: false });

        AdminServices.getAlertList2().then((response) => {
            const res = response.responseData;
            base.gridData = AlertsDataBuilder(res);
            base.parseKeys = { normalKeys: res.normalKeys, stringKeys: res.stringKeys };
            base.setState({ isLoaded: true });
        }).catch((error) => {
            console.log('AdminRules alert-grid AddRow API call failed', error);
            NotificationManager({ message: 'Error:Response failed, Please try again', type: 4 });
        });
    }

    getNewRow(row) {
    // generates new row by removing all the reservedKeys , the returned object
    // will provide all keys with null value.
        const reservedKeys = ['boundindex', 'uid', 'uniqueid', 'visibleindex'];

        const newRow = Object.assign({}, row);

        reservedKeys.map((item, index) => {
            if (newRow.hasOwnProperty(item)) {
                delete newRow[item];
            }
        });

        for (const item in newRow) {
            if (newRow.hasOwnProperty(item)) {
                newRow[item] = null;
            }
        }

        return newRow;
    }
    sendData() {
    // ajax.loader(true);

        const editedData = this.alertGrid.getrows();
        const base = this;
        /*clonkeys expects keys as array and cloneFrom as object,
    * returns object with cloned properties
    */
        function cloneTheKeys(keys, cloneFrom) {
            const obj = {};
            keys.forEach((item, index) => {
                if (cloneFrom.hasOwnProperty(item)) {
                    obj[item] = cloneFrom[item];
                }
            });
            return obj;
        }

        // parsedData has array of objects in backend required format
        const parsedData = [];

        editedData.forEach((item, index) => {
            const normalProps = cloneTheKeys(this.parseKeys.normalKeys, item);
            const stringProps = cloneTheKeys(this.parseKeys.stringKeys, item);
            const obj = normalProps;
            //make the roleName dynamic, get it in backend response
            obj.roleName = JSON.stringify(stringProps);
            parsedData.push(obj);
        });

        AdminServices.updateAlert2(parsedData).then((response) => {
            const res = response;
            // base.setState({ isCancelEnable: false });
            this.alertFooterRef.disableFooter();
            this.setState({
                isLoaded: false
            }, () => {
                this.getOrReloadGrid();
            });
            if (res.status == 'success') {
                NotificationManager({
                    message: res.message,
                    type: 1,
                });
            } else {
                NotificationManager({
                    message: res.message,
                    type: 4,
                });
            }
        }).catch((error) => {
            console.log('AdminRules alert-grid sendData API call failed', error);
            NotificationManager({ message: 'Error:Response failed, Please try again', type: 4 });
        });
    }

    cancelChanges() {
        this.setState({ isLoaded: false });
        const base = this;

        AdminServices.getAlertList2().then((response) => {
            const res = response.responseData;
            base.gridData = AlertsDataBuilder(res);
            base.parseKeys = { normalKeys: res.normalKeys, stringKeys: res.stringKeys };
            base.setState({ isLoaded: true, isCancelEnable: false });
        }).catch((error) => {
            console.log('AdminRules alert-grid cancelChanges API call failed', error);
            NotificationManager({ message: 'Error:Response failed, Please try again', type: 4 });
        });
    }

    componentDidMount() {
    // let postData = {"id":JSON.parse(localStorage.getItem("userdata")).groupId};
        const base = this;

        //fetching the alert list

        this.getOrReloadGrid();

        AdminServices.getRulesForAlerts().then((response) => {
            const res = response;
            base.props.addRulesForAlerts(res);
        }).catch((error) => {
            console.log('AdminRules alert-grid componentDidMount Rules For Alerts API call failed', error);
            NotificationManager({ message: 'Error:Response failed, Please try again', type: 4 });
        });

        this.sortDropDown();
        $('#drop-down').on('click', 'li', function () {
            const fieldName = $(base).closest('#drop-down').attr('data-field');
            let val = $(base).attr('data-val');

            val = (val === 'null') ? null : val;
            $('#drop-down').hide();
            base.alertGrid.sortby(fieldName, val);
        });
    }
    sortDropDown() {
        $('#drop-down').remove();
        const container = '<div id="drop-down" class="pd-hl"></div>';
        $('body').append(container);
        const list = this.generateDropDowns();

        $('#drop-down').append(list);
    }

    getOrReloadGrid() {
        const base = this;
        AdminServices.getAlertList2().then((response) => {
            const res = response.responseData;
            // base.gridData = AlertsDataBuilder(res);
            base.gridData = AlertsDataBuilder(res);
            base.parseKeys = { normalKeys: res.normalKeys, stringKeys: res.stringKeys };
            base.setState({ isLoaded: true });
        }).catch((error) => {
            console.log('AdminRules alert-grid componentDidMount API call failed', error);
            NotificationManager({ message: 'Error:Response failed, Please try again', type: 4 });
        });
    }

    generateDropDowns() {
        const list = [{
            name: '0-9,A-Z,blank-non_blank',
            val: 'asc',
        }, {
            name: '9-0,Z-A,non_blank-blank',
            val: 'desc',
        }, {
            name: 'clear',
            val: 'null',
        }];

        let li = '';
        list.forEach((item, index) => {
            li += '<li class="product-sort-options" data-val="' + item.val + '">' + item.name + '</li>';
        });

        const ul = '<ul class=\'sort-holder\'>' + li + '</ul>';

        return ul;
    }
    // this method is invoked when props or state is updated
    componentDidUpdate(prevProps, prevState) {
        const _this = this;

        if (JSON.stringify(prevProps.addNewAlert) !== JSON.stringify(this.props.addNewAlert)) {
            //this.alertGrid.addrow(null,this.props.addNewAlert);
            const alertValues = this.props.addNewAlert;
            const requiredObj = {
                'alertId': null,
                'alertName': alertValues.alertName,
                'alertType': null,
                'roleid': 1,
                'ruleid': alertValues.appliedRuleId,
            };
        }

        const alertsGrid = $('.alertGrid');
        alertsGrid.off();
        alertsGrid.on('click', '.edit-alertName', function (e) {
            const rowId = $(this).attr('data-rowid');
            const rowData = _this.alertGrid.getrowdata(rowId);
            _this.selectedRow = rowData;
            _this.modalOpen();
        });


        // alertsGrid.on('cellendedit', function (e) {
        //   _this.setState({ isCancelEnable: true });
        // });

        // Row select - Checkbox
        //     alertsGrid.on('rowselect', function (event) {
        //     const rowIndexs = _this.alertGrid.getselectedrowindexes();
        //     const newAlertUserData = [];
        //     rowIndexs.map((val, key) => {
        //         const amId = _this.alertGrid.getrowdata(val).amId;
        //         if (newAlertUserData.indexOf(amId) < 0) {
        //           newAlertUserData.push(amId);
        //         }
        //     });

        //     _this.setState({ selectedRowsCount: rowIndexs.length, checkedRows: rowIndexs, checkedAlertData: newAlertUserData });
        // });

        // Row Unselect - Checkbox
        // alertsGrid.on('rowunselect', function (event) {
        //     const rowIndexs = _this.alertGrid.getselectedrowindexes();
        //     const newAlertUserData = [];
        //     rowIndexs.map((val, key) => {
        //         const amId = _this.alertGrid.getrowdata(val).amId;
        //         if (newAlertUserData.indexOf(amId) < 0) {
        //           newAlertUserData.push(amId);
        //         }
        //     });

        //     _this.setState({ selectedRowsCount: rowIndexs.length, checkedRows: rowIndexs, checkedAlertData: newAlertUserData });
        // });
    }

    onCellvaluechanged(event) {
        console.log('Alerts Grid onCellvaluechanged:', event);
        const _this = this;
        const args = event.args;
        const datafield = args.datafield;
        const newvalue = args.newvalue;
        const oldvalue = args.oldvalue;
        const rowindex = args.rowindex;
        const rowdata = _this.alertGrid.getrowdata(rowindex);

        if (newvalue != oldvalue) {
            if (newvalue == '') {
                _this.alertGrid.setcellvalue(rowindex, datafield, 0);
                _this.alertFooterRef.enableFooter();
                return;
            }
            _this.alertFooterRef.enableFooter();
            _this.alertGrid.setcellvalue(rowindex, datafield, newvalue);
            // _this.fieldPermissionsGrid.refresh();
        }
    }

    onRowselect(event) {
        let _this = this;
        const rowIndexs = _this.alertGrid.getselectedrowindexes();
        const newAlertUserData = [];
        rowIndexs.map((val, key) => {
            const amId = _this.alertGrid.getrowdata(val).amId;
            if (newAlertUserData.indexOf(amId) < 0) {
                newAlertUserData.push(amId);
            }
        });

        _this.setState({ selectedRowsCount: rowIndexs.length, checkedRows: rowIndexs, checkedAlertData: newAlertUserData });
    }

    onRowunselect() {
        let _this = this;
        const rowIndexs = _this.alertGrid.getselectedrowindexes();
        const newAlertUserData = [];
        rowIndexs.map((val, key) => {
            const amId = _this.alertGrid.getrowdata(val).amId;
            if (newAlertUserData.indexOf(amId) < 0) {
                newAlertUserData.push(amId);
            }
        });

        _this.setState({ selectedRowsCount: rowIndexs.length, checkedRows: rowIndexs, checkedAlertData: newAlertUserData });
    }

    render() {
        const footerHeight = 50;
        const headerHeight = 64;
        const gridHeight = window.innerHeight - footerHeight - headerHeight;

        $('.page-title a').text("ADMIN: Alerts");


        return (
            <div>
                <AlertGridComponent

                    AlertGridRef={(grid) => {
                        this.alertGrid = grid;
                    }}

                    alertFooterRef={(grid2) => {
                        this.alertFooterRef = grid2;
                    }}

                    state={this.state}
                    props={this.props}
                    alertGrid={this.alertGrid}
                    gridData={this.gridData}
                    addRow={this.addRow}
                    sendData={this.sendData}
                    gridHeight={gridHeight}
                    cancelChanges={this.cancelChanges}
                    isCancelEnable={this.isCancelEnable}
                    modalClose={this.modalClose}
                    selectedRow={this.selectedRow}
                    updateRow={this.updateRow}
                    selectedRowsCount = {this.state.selectedRowsCount}
                    checkedRows = {this.state.checkedRows}
                    checkedAlertData = {this.state.checkedAlertData}
                    removeGridRows = {this.removeGridRows}

                    onRowselect={this.onRowselect.bind(this)}
                    onRowunselect={this.onRowunselect.bind(this)}
                    onCellvaluechanged={this.onCellvaluechanged.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        addNewAlert: state.addNewAlert,
        alertRules: state.rulesForAlerts,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addRulesForAlerts: (data) => {
            dispatch(rulesForAlerts(data));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(AlertsGrid);
