
import React from 'react';
import moment from 'moment';
import CancelModal from './cancelChanges';
import { CustomDropdownEditor } from '../customDropdownEditor';
import AdminServices from '../../../admin/admin-services/admin-services';
import NotificationManager from '../../../common/components/notification';
import { ProductServices } from '../../services/product-services';
import { checkHeight } from '../utils';

/**
 * renders the messages column
 */
export class MessageRender extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        let count = this.props.data && this.props.data.messageCount ? this.props.data.messageCount : '';
        return (
            <div className="message-container-render">
                {
                    this.props.data && this.props.data.childrenHasMessages && count == 0 ?
                        <div>
                            <div className="msg-img"></div>
                            <div className="message-count">*</div>
                        </div>
                        :
                        <div>
                            {
                                count > 0 ?
                                    <div>
                                        <div className="msg-img"></div>
                                        <div className="message-count">{count}</div>
                                    </div>
                                    :
                                    ''
                            }
                        </div>
                }
            </div>
        );
    }
}









export class MessageEditor extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            addNewMessage: false,
            confirmOpen: false,
            selectedVal: null,
            userDropdownData: null,
            hideDropdown: true,
            userError: false,
            messageError: false,
            inputMessage: null,
            noMessages: false,
            allMessages: [],
            totalMessages: 0,

        };

        this.cancelEditing = this.cancelEditing.bind(this);
        this.addNewMessage = this.addNewMessage.bind(this);
        this.closeConfirm = this.closeConfirm.bind(this);
        this.showDropdown = this.showDropdown.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.inputMessage = this.inputMessage.bind(this);
    }


    componentDidMount() {
        const id = this.props.node.data.productId;

        checkHeight(this.props);

        ProductServices.usersList().then((response) => {
            const res = response.data;
            if (res.status) {
                let newArr = [];
                res.data.map((i) =>{
                    newArr.push(i.userName);
                });
                this.setState({ userDropdownData: newArr });
            } else {
                NotificationManager({ message: 'Error:Response failed, Please try again', type: 4 });
            }
        }).catch((error) => {
            console.log('User componentDidMount userList API call failed', error);
            NotificationManager({ message: 'Error:Response failed, Please try again', type: 4 });
        });

        ProductServices.getMessages(id).then((response) => {
            const res = response.data;

            if (res.responseData.messages.length > 0) {
                this.setState({
                    allMessages: res.responseData.messages,
                    totalMessages: res.responseData.total_messages,
                    noMessages: false,
                });
            } else {
                this.setState({
                    noMessages: true,
                });
            }
        }).catch((error) => {
            console.log('Get all messages API call failed', error);
        });
    }


    isPopup() {
        return true;
    }

    cancelEditing() {
        if (this.state.addNewMessage) {
            this.setState({
                confirmOpen: true
            });
        } else {
            this.props.api.stopEditing();
        }
        // this.props.api.stopEditing();
    }

    addNewMessage() {
        this.setState({
            addNewMessage: true,
            noMessages: false,
        });
    }

    closeConfirm(key) {
        switch (key) {
            case 'yes':
                this.setState({
                    confirmOpen: false,
                });
                break;
            case 'no':
                this.props.api.stopEditing();
                break;

            default:
                break;
        }
    }

    changeSelectedValue(value) {
        this.setState({
            selectedVal: value,
            hideDropdown: true,
        });
    }

    showDropdown() {
        this.setState({
            hideDropdown: false,
            userError: false,
        });
    }

    readMessage(id) {
        ProductServices.readMessage(id).then((response) => {
            const res = response.data;
            if (res.status == "success") {
                NotificationManager({ message: res.message, type: 1 });
                let mesLot = this.state.allMessages;
                mesLot.map((i) => {
                    if (i.id == id) {
                        i.readFlag = true;
                        i.readDate = new Date();
                    }
                });

                this.setState({
                    allMessages: mesLot,
                });
            }
        }).catch(() => {
            console.log('Message Column API call failed', error);
            NotificationManager({ message: 'Error:Response failed, Please try again', type: 4 });
        });
    }

    sendMessage() {
        const _this = this;
        const fromUser = _this.props.user.userName;
        const rowNode = _this.props.node;
        const rowData = _this.props.node.data;
        let obj = {};
        obj.sweftId = _this.props.node.data.productId;
        obj.from = fromUser;
        obj.to = _this.state.selectedVal;
        obj.content = _this.state.inputMessage;

        if (_this.state.addNewMessage && !_this.state.selectedVal) {
            _this.setState({
                userError: true
            });
            return;
        }
        if (_this.state.addNewMessage && !_this.state.inputMessage) {
            _this.setState({
                messageError: true
            });
            return;
        }

        ProductServices.saveOrSendMessage(obj).then((response) => {
            const res = response.data;
            if (res.status == 'success') {
                let messages = _this.state.allMessages;
                const data = res.responseData;
                messages.unshift(data);
                _this.setState({
                    allMessages: messages,
                    addNewMessage: false,
                    inputMessage: null,
                    selectedVal: null,
                    totalMessages: _this.state.totalMessages + 1,
                }, () => {
                    rowData['messageCount'] = _this.state.totalMessages;
                    rowNode.setData(rowData);
                    // _this.props.api.redrawRows();
                });
                NotificationManager({ message: res.message, type: 1 });
            }
        }).catch((error) => {
            console.log('Message Column API call failed', error);
            NotificationManager({ message: 'Error:Response failed, Please try again', type: 4 });
        });
    }

    inputMessage(e) {
        const value = e.currentTarget.value;
        this.setState({
            inputMessage: value,
            messageError: false,
        });
    }

    // getValue() {
    //     return this.state.selectedVal;
    // }

    // stopEditing() {
    //     this.props.api.stopEditing();
    // }

    render() {
        const data = this.props.node.data;
        const user = this.props.user.userName;

        let setMessages = this.state.allMessages && this.state.allMessages.map((i) => {
            return(
                <div className="message-section" key={i.id}>
                    <div className="section-sender">
                        <span><em>To: </em>{i.to}</span>
                        <span><em>From: </em>{i.from}</span>
                    </div>
                    <div className="section-comment">
                        <span>{i.content}</span>
                    </div>
                    <div className="section-date">
                        {
                            i.createDate &&
                        <span>{moment(i.createDate).format("MM/DD/YYYY, h:mm a")}</span>
                        }
                        {
                            (i.readFlag) &&
                        <span className='read-message'>Read {moment(i.readDate).format("MM/DD/YYYY, h:mm a")}</span>
                        }

                        {   (!i.readFlag && user == i.to) ?
                            <span><button onClick={() => this.readMessage(i.id)} className="message-button send">Read</button></span>
                            :
                            <span className='read-message'>
                                {
                                    i.readDate ?
                                        ''
                                        :
                                        'Unread'
                                }
                            </span>
                        }

                    </div>
                </div>
            );
        });

        return (
            <div className="message-editor-container">
                <div className="message-editor-header">
                    <div className="sku-info">
                        <span>Vendor Part#: </span>
                        <span>{data.vendorStyle}</span>
                        <span>{data.prodName}</span>
                        <span onClick={this.cancelEditing}>X</span>
                    </div>
                    <div className="comment-count">
                           COMMENTS ({this.state.totalMessages})
                    </div>
                </div>
                <div className="message-editor-body">

                    {
                        this.state.addNewMessage &&
                            <div className="message-section use-input-section">
                                <div className="section-sender">
                                    <span><em>To*: </em>
                                        { this.state.hideDropdown ?

                                            <div className="dropdownClick" onClick={this.showDropdown}>
                                                {
                                                    this.state.selectedVal ? this.state.selectedVal : 'Select User'
                                                }
                                            </div>
                                            :
                                            <CustomDropdownEditor
                                                width={125}
                                                selectedVal={this.state.selectedVal}
                                                userDropdownData={this.state.userDropdownData}
                                                changeSelectedValue={this.changeSelectedValue.bind(this)}
                                                {...this.props}
                                            />
                                        }
                                    </span>
                                    <span><em>From: </em>{user}</span>
                                    {
                                        this.state.userError &&
                                    <span className="to-user-error">Cannot send without entering a user name in the 'to' field.</span>
                                    }
                                </div>
                                <div className="section-comment">
                                    <textarea maxLength={200} onChange={this.inputMessage} className="input-message"></textarea>
                                </div>
                                {
                                    this.state.messageError &&
                                    <span className="to-message-error">Cannot send without entering a message.</span>
                                }
                            </div>
                    }

                    {
                        this.state.noMessages &&
                            <h3>No Messages, please click 'New' to send a new message.</h3>
                    }

                    {setMessages}


                </div>
                <div className="message-editor-footer">
                    <button className="message-button new" onClick={this.addNewMessage}>New</button>
                    <button className="message-button send" onClick={this.sendMessage}>Save</button>
                    <button className="message-button cancel" onClick={this.cancelEditing}>Cancel</button>
                </div>

                <CancelModal
                    isOpen={this.state.confirmOpen}
                    modalClose={this.closeConfirm}
                />

            </div>
        );
    }
}
