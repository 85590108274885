export const PublicationGridData = (state = {}, action) => {
    switch (action.type) {
        case "ADD_PUBLICATION_GRID_DETAILS":
            return action.data;
        default:
            return state;
    }
};

let pubGridDataCount = 0;
export const PublicationCounter = (state = 0, action) => {
    switch (action.type) {
        case "PUBLICATION_COUNTER":
            return ++pubGridDataCount;
        default:
            return state;
    }
};

export const addListItemsNamesData = (state = {}, action) => {
    switch (action.type) {
        case "ADD_LIST_ITEMS_NAMES":
            return action.data;
        default:
            return state;
    }
};

export const addPublicationColumnNames = (state = {}, action) => {
    switch (action.type) {
        case "ADD_PUBLICATION_COLUMN_NAMES":
            return action.data;
        default:
            return state;
    }
};

export const addGridDropDowns = (state = {}, action) => {
    switch (action.type) {
        case "ADD_GRID_DROP_DOWNS":
            return action.data;
        default:
            return state;
    }
};

export const addSelectedPresentationName = (state = {name: "", id: ""}, action) => {
    switch (action.type) {
        case "ADD_SELECTED_PRES_NAME":
            return action.data;
        default:
            return state;
    }
};

export const addPubListDropdownData = (state = {}, action) => {
    switch (action.type) {
        case "ADD_PUB_LIST_DROPDOWN_DATA":
            return action.data;
        default:
            return state;
    }
};

export const updateCheckedPublicationRows = (state = [], action) => {
    switch (action.type) {
        case "UPDATE_PUB_CHECKED_ROWS":
            return action.data;
        default:
            return state;
    }
};

export const updateListDirty = (state = {}, action) => {
    switch (action.type) {
        case "UPDATE_LIST_DIRTY":
            return action.data;
        default:
            return false;
    }
};

export const publicationToggleFind = (state = {}, action) => {
    switch (action.type) {
        case "PUB_TOGGLE_FIND":
            return action.data;
        default:
            return  state;
    }
};
