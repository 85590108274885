import React from 'react';
import axios from 'axios';
import NotificaitonManager from '../../../common/components/notification';

import { ProductServices } from '../../services/product-services';

class ImageUpload extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inputFile: [],
            inputFileName: '',
            tags: [],
        };

        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    /**
     * Handles the upload click and calls the backend to save data
     * @param {*} e // Event when sbumit is clicked
     */
    handleSubmit(e) {
        e.preventDefault();
        const _this = this;
        const obj = {};
        obj.rowId = this.props.imageInfo.uid;
        obj.fieldName = 'images';
        const form = document.getElementById('ProductImagesUpload');
        const formData = new FormData(form);
        const colId = this.props.column.colId;
        const prodId = this.props.node.data.productId;
        let tags = {
            'tags':  _this.state.tags
        };

        formData.append('fileData', JSON.stringify(tags));

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        axios({
            method: 'post',
            url: require('../../../common/configurations.js').product2.imageUploadV2 + colId + '/' + prodId,
            headers: headers,
            data: formData,
        }).then((res) => {
            console.log('image uploaded', res);
            if (res.data.status == 'success') {
                NotificaitonManager({ message: res.data.message, type: 1 });
                _this.props.setS3ImagesAfterUpload(res.data);
            }
        }).catch((err) => {
            NotificaitonManager({
                message: err.message,
                type: 4,
            });
        });

        // 	ProductServices.imageUpload(formData)
        // 	.then( (response) => {
        //     const data = response.data;

        //       // if (data.status) {

        //       //   obj.data = _this.props.imageArray;

        //       //   if (data.data) {
        //       //     obj.data.push(data.data);
        //       //   }
        //       //   _this.props.updateImage(obj);
        //       //   _this.props.imageData(obj);

        //       //   NotificaitonManager({ message: "Image uploaded successfully", type: 1 });
        //       // }
        //     if (data.status) {
        //         obj.data = _this.props.imageArray;

        //         if (data.responseData) {
        //             obj.data.push(data.responseData);
        //         }
        //         _this.props.updateImage(obj);
        //         _this.props.imageData(obj);

        //         NotificaitonManager({ message: 'Image uploaded successfully', type: 1 });
        //     } else {
        //         NotificaitonManager({ message: data.responseMessage, type: 4 });
        //     }
        // })
        // .catch( (error) => {
        //     NotificaitonManager({
        //         message: 'failed to upload image due to technical reasons, please try again',
        //         type: 4,
        //     });
        // });

        _this.setState({ inputFile: [], inputFileName: '' });
    }

    /**
     * Checks if file has been changed and validates
     * @param {*} e // Event when file is changed
     * @return {void} setsState
     */
    handleFileChange(e) {
        e.preventDefault();
        const _this = this;
        const target = e.target;
        const list = target.files;
        let fileNames = [];
        let tags = [];

        let currentImages = this.props.currentImageData();

        let currentOrder = currentImages.length > 0 ? currentImages.length : null;

        const fileArray = [];
        for (let i = 0; i < list.length; i++) {
            const fileList = {};
            // var allowedImageTypes = ["image/jpeg", "image/png", "image/gif", "image/jpg", "image/tff", "image/tf"];
            const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/jpg', 'image/tiff', 'image/tif'];

            if (allowedImageTypes.indexOf(list[i].type) < 0) {
                NotificaitonManager({
                    message: 'Only PNG, JPG, GIF and TIF are allowed',
                    type: 4,
                });
                return false;
            }
            if (list) {
                // if(list[i].size < 1048576) {
                for (const l in list[i]) {
                    fileList[l] = list[i][l];
                }
                fileArray.push(fileList);
                fileNames.push(list[i].name);

                tags.push({
                    'fileName': list[i].name,
                    'order': currentOrder ? i + currentOrder : i,
                    'base64FileName':  btoa(list[i].name),
                });

                console.log(tags);
            }

            if (list.length > 0 && fileArray.length > 0) {
                this.setState({ inputFile: fileArray, inputFileName: fileNames, tags: tags });
            }
        }
    }

    render() {
        const props = this.props;

        const imgData = props.imageInfo;

        const pIds = [];
        // if (imgData.dataLevel == "Intm") {
        //   let arrIntm = imgData.chldProuctId;
        //   arrIntm.map(function (id, ind) {
        //     pIds.push(id);
        //   });
        // } else if (imgData.dataLevel == "SP") {
        //   let arrIntm = imgData.chldProuctId;
        //   arrIntm.map(function (id, ind) {
        //     pIds.push(id);
        //   });
        // } else {
        //   pIds.push(imgData.productId);
        // }

        if (imgData.dataLevel == 'SINGLE') {
            pIds.push(imgData.chldProuctId);
        } else if (imgData.dataLevel == 'PARENT') {
            pIds.push(imgData.chldProuctId);
        } else if (imgData.dataLevel == 'INTERMEDIATE') {
            pIds.push(imgData.childrenIds);
        } else if (imgData.dataLevel == 'CHILD') {
            pIds.push(imgData.childrenIds);
        }

        // let editable = false;
        // if (window.writableFields.indexOf('images') > -1) {
        //   editable = true;
        // }

        return (
            <div className="upload-holder">
                {
                    <form id="ProductImagesUpload" name="product-images-upload" className="images-upload" onSubmit={this.handleSubmit} encType="multipart/form-data">
                        {/* <input type="hidden" name="pid" defaultValue={pIds} /> */}
                        {/* <div className="upload-input">
              <div className="icon-holder">
                <span className="plus-icon" />
              </div>
              <input type="file" multiple="multiple" name="files" onChange={this.handleFileChange} />
            </div>

            <label className="block text-center"> Add Photo(s) :
              <span className={'image-name' + (this.state.inputFile.length > 0 ? '' : 'hide')}>
                  {
                     this.state.inputFileName && this.state.inputFileName.map((i, x) => (
                          <div key={x}>{x+1}. {i}</div>
                      ))
                  }
              </span>
            </label> */}
                        <fieldset className="text-center upload-footer">
                            <div className="image-icon-container" title='Upload'>
                                <label htmlFor="file-input">
                                    <i className="fas fa-plus"></i>
                                </label>
                                <input id="file-input" type="file" multiple="multiple" name="files" onChange={this.handleFileChange} />
                            </div>
                            <button type="submit" className="orange-button"
                                disabled={this.state.inputFile.length > 0 ? false : true}>
                Save
                            </button>
                            <div className="image-icon-container" onClick={this.props.downloadImages} title='Download'>
                                <i className="fas fa-arrow-down"></i>
                            </div>
                        </fieldset>
                        {/* <input type="hidden" name="extraField" value="_dontcare" /> */}
                    </form>
                }
            </div>
        );
    }
}

export default ImageUpload;


// import React from 'react';
// import axios from 'axios';
// import NotificaitonManager from '../../../common/components/notification';

// class ImageUpload extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       inputFile: [],
//       inputFileName: ""
//     }

//     this.handleFileChange = this.handleFileChange.bind(this);
//     this.handleSubmit = this.handleSubmit.bind(this);
//   }

//   handleSubmit(e) {
//     e.preventDefault();
//     let _this = this;
//     let obj = {};
//     obj.rowId = this.props.imageInfo.uid;
//     obj.fieldName = 'images';
//     var form = document.getElementById('ProductImagesUpload');
//     var formData = new FormData(form);
//     formData.append('dataLevel', _this.props.imageInfo.dataLevel);


//     let headers = {
//           'Authorization': this.props.user.token,
//           'content-type': 'application/json'
//     }
//     axios({
//       method: 'post',
//       url: require('../../../common/configurations.js').product.imageUpload,
//       headers: headers,
//       data: formData
//     })
//     .then( (response) => {
//           let data = response.data

//           if (data.status) {

//             obj.data = _this.props.imageArray;
//             if (data.data) {
//               obj.data.push(data.data);
//             }
//             _this.props.updateImage(obj);
//             _this.props.imageData(obj);

//             NotificaitonManager({ message: "Image uploaded successfully", type: 1 });
//           }
//           else {
//             NotificaitonManager({ message: data.responseMessage, type: 4 });
//           }

//     })
//     .catch( error => {
//         NotificaitonManager({
//           message: "failed to upload image due to technical reasons, please try again",
//           type: 4
//         });
//     });

//     _this.setState({ inputFile: [], inputFileName: "" });

//   }

//   handleFileChange(e) {
//     e.preventDefault();
//     let _this = this;
//     var target = e.target,
//       list = target.files;

//     var fileArray = [];
//     for (var i = 0; i < list.length; i++) {
//       var fileList = {};
//       // var allowedImageTypes = ["image/jpeg", "image/png", "image/gif", "image/jpg", "image/tff", "image/tf"];
//       var allowedImageTypes = ["image/jpeg", "image/png", "image/gif", "image/jpg", "image/tiff", "image/tif"];

//       if (allowedImageTypes.indexOf(list[i].type) < 0) {
//         NotificaitonManager({
//           message: "Only PNG, JPG, GIF and TIF are allowed",
//           type: 4
//         });
//         return false;
//       }
//       //if(list[i].size < 1048576) {
//       for (var l in list[i]) {
//         fileList[l] = list[i][l];
//       }
//       fileArray.push(fileList);

//     }

//     if (list.length > 0 && fileArray.length > 0) {
//       this.setState({ inputFile: fileArray, inputFileName: fileArray[0].name })
//     }
//   }

//   render() {
//     let props = this.props;

//     let imgData = props.imageInfo;
//     let pIds = [];
//     if (imgData.dataLevel == "Intm") {
//       let arrIntm = imgData.chldProuctId;
//       arrIntm.map(function (id, ind) {
//         pIds.push(id);
//       });
//     } else if (imgData.dataLevel == "SP") {
//       let arrIntm = imgData.chldProuctId;
//       arrIntm.map(function (id, ind) {
//         pIds.push(id);
//       });
//     } else {
//       pIds.push(imgData.productId);
//     }

//     // let editable = false;
//     // if (window.writableFields.indexOf('images') > -1) {
//     //   editable = true;
//     // }

//     return (
//       <div className="upload-holder">
//         {
//           <form id="ProductImagesUpload" name="product-images-upload" className="images-upload" onSubmit={this.handleSubmit} encType="multipart/form-data">
//             <input type='hidden' name="pid" defaultValue={pIds} />
//             <div className="upload-input">
//               <div className="icon-holder">
//                 <span className="plus-icon"></span>
//               </div>
//               <input type="file" name="files" onChange={this.handleFileChange} />
//             </div>

//             <label className="block text-center"> Add Photo(s)
//               <span className={"image-name " + (this.state.inputFile.length > 0 ? "" : "hide")}>{" : " + this.state.inputFileName}</span>
//             </label>
//             <fieldset className="text-center upload-footer">
//               <button type="submit" className="orange-button"
//                 disabled={this.state.inputFile.length > 0 ? false : true}>
//                 Save
//             </button>
//             </fieldset>
//             <input type="hidden" name="extraField" value="_dontcare" />
//           </form>
//         }
//       </div>
//     )
//   }
// }

// export default ImageUpload;
