var CONSTANTS = {
    ROLES:{
        ADMIN:"ADMIN",
        BUYER:"Buyer",
        COPYWRITER:"CopyWriter",
        WEBPRODUCER:"WebProducer",
        PLANNER:"Planner"
    },
    DAYNAMEOFWEEK:["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    MONTHNAMEOFYEAR:["January ", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    IMGTHUMBNAIL:{
        width: 45,
        height: 58
    },
    IMGMAIN:{
        width: 300,
        height: 350
    },
    IMGDAMURL:"http://i1.adis.ws/s/sweft/",
};

module.exports = CONSTANTS;
