
import React from 'react';

export class PiUploadRender extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            flag: false,
        };
    }


    render() {
        const copyLevel = (this.props.data && this.props.data.copyLevel) ? this.props.data.copyLevel : null;
        const IconDisplay = <div className="ag-popupIndicator"><img src={require('../../common/icons/Expand Grid.svg')} /></div>;

        if (this.props.node.data.dataLevel == 'SINGLE') {
            return (
                <div>
                    {IconDisplay}
                </div>
            );
        }

        if (copyLevel) {
            switch (copyLevel) {
                case 'Style':
                    if (this.props.node.level == 0) {
                        return (
                            <div>
                                {IconDisplay}
                            </div>
                        );
                    } else {
                        return null;
                    }
                case 'Color':
                    if (this.props.node.level == 1) {
                        return (
                            <div>
                                {IconDisplay}
                            </div>
                        );
                    } else {
                        return null;
                    }
                case 'Size':
                    if (this.props.node.level == 2) {
                        return (
                            <div>
                                {IconDisplay}
                            </div>
                        );
                    } else {
                        return null;
                    }
                default: return null;
            }
        } else {
            return null;
        }
    }
}


export class PiUploadEditor extends React.Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        let flag = false;

        if (this.props.node.data.displayLevel == 'Color' && this.props.node.level == 1) {
            flag = true;
        }

        if (this.props.node.data.displayLevel == 'Style' && this.props.node.level == 0) {
            flag = true;
        }

        if (this.props.node.data.displayLevel == 'Size' && this.props.node.level == 2) {
            flag = true;
        }

        if (this.props.node.data.dataLevel == 'SINGLE') {
            flag = true;
        }

        if (!flag) {
            this.props.api.stopEditing();
        } else {
            this.props.openPiUploadPopup(this.props.node.data);
        }
    }

    componentWillUnmount() {
        this.props.api.tabToNextCell();
    }

    isPopup() {
        return true;
    }

    render() {
        return (
            <div>
            </div>
        );
    }
}
