import React from 'react';

class List extends React.Component {
    render() {
        let props = this.props;

        props.list.sort(function (a, b) {
            return a.viewOrder - b.viewOrder;
        });

        return (
            <ul>
                { props.list.map((item, index) => {
                    return ( item.feildLevel !== 'H' ?
                        <li key={index} onClick={this.preventClick}>
                            <label className="blue-checkbox wt">
                                <input
                                    type="checkbox"
                                    checked={item.visibility}
                                    name={item.fieldName}
                                    onChange={(e) => {
                                        var checked = e.target.checked;
                                        props.inputChange(item.fieldName, checked);
                                    }
                                    } /><label></label>
                                {item.columnTitle.toLowerCase()}
                            </label>
                        </li> : ''
                    );
                })}
                {props.list.length == 0 &&
                <li>no fields found</li>
                }
            </ul>
        );
    }
}

export default List;
