import React from 'react';
import ProfileComponent from './profileComponent';
import CancelModal from '../modals/confirm-modal';
import { connect } from 'react-redux';
import './profileModal.css';
import axios from 'axios';

import NotificationManager from '../common/components/notification';
import {UserServices} from '../common/services/user-services';
import {CommonServices} from '../common/services/common-services';
import AdminServices from '../admin/admin-services/admin-services';
import CategoryServices from '../product2/services/category-services';

class Profile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            profileView: true,
            changePassword: false,
            userData: {},
            formEdit: false,
            modalTitle: 'Profile',
            user: {},
            changePswd: {
                oldPassword: '',
                newPassword: '',
                confNewPassword: ''
            },
            email: '',
            name: '',
            nickname: '',
            company: '',
            department: '',
            designation: '',
            invalid: false,
            invalidPassword: false,
            errorMsg: '*Mandatory fields marked with an asterisk is missing.',
            isFormEditable: false,
            isUpdateUser: false,
            isModalOpen: false,
            validation: {
                userName: true,
                nickName: true,
                firstName: true,
                lastName: true,
                email: true,
                phone: true,
                company: false,
                designation: true,
                speciality: false,
                defaultRole: true
            },
            specialityData: [],
            errorMessage: '*Mandatory fields marked with an asterisk is missing.'
        };

        this.formEditable = this.formEditable.bind(this);
        this.formViewable = this.formViewable.bind(this);
        this.showProfileView = this.showProfileView.bind(this);
        this.showChangePassword = this.showChangePassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.changeUserGroup = this.changeUserGroup.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.submitChangePassword = this.submitChangePassword.bind(this);
        this.openModalCancel = this.openModalCancel.bind(this);
        this.editModalCancel = this.editModalCancel.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.getSpecialityData = this.getSpecialityData.bind(this);
        this.generateSpecialityData = this.generateSpecialityData.bind(this);
        this.getUserData = this.getUserData.bind(this);
        this.getSpecialityData = this.getSpecialityData.bind(this);
        this.isValidPassword = this.isValidPassword.bind(this);
    }

    componentDidMount() {
        console.log('profileContainer componentDidMount: ', this.props);

        this.getUserData();
        this.getSpecialityData();

        $('.jqx-widget-header').hide();
        $('.footer').hide();
    }

    componentWillUnMount() {
        this.mounted = false;
    }

    getUserData() {
        var _this = this;
        var URL = '';
        let req = { id: this.props.user.userId };

        if (this.props.userId) {
            AdminServices.getUserData(req).then((response) => {
                const data = response;
                let isFormEditable = false;

                for (var role in data.user.userAuthority) {
                    if (data.user.userAuthority[role] === "W") {
                        isFormEditable = true;

                        break;
                    }
                }

                let validationData = _this.state.validation;
                let arrMandatoryFields = data.user.userMandatoryFields;

                arrMandatoryFields.map(function (o, i) {
                    validationData[o.userFields] = o.mandatoryFields;
                });

                this.setState({
                    userData: data,
                    user: data.user,
                    email: data.user.email,
                    name: data.user.firstName + ' ' + data.user.lastName,
                    nickname: data.user.nickName,
                    company: data.user.company,
                    department: data.user.deptName,
                    designation: data.user.designation,
                    validation: validationData,
                    isFormEditable: isFormEditable
                });
            }).catch((error) => {
                console.log('Server Error Failed', error);

                NotificationManager({
                    message: error.message || "Unexpected error, Data didn't load",
                    type: 4
                });
            });
        } else {
            UserServices.getUserData(req).then((response) => {
                const data = response;
                let isFormEditable = false;

                for (var role in data.user.userAuthority) {
                    if (data.user.userAuthority[role] === "W") {
                        isFormEditable = true;

                        break;
                    }
                }

                let validationData = _this.state.validation;
                let arrMandatoryFields = data.user.userMandatoryFields;

                arrMandatoryFields.map(function (o, i) {
                    validationData[o.userFields] = o.mandatoryFields;
                });

                this.setState({
                    userData: data,
                    user: data.user,
                    email: data.user.email,
                    name: data.user.firstName + ' ' + data.user.lastName,
                    nickname: data.user.nickName,
                    company: data.user.company,
                    department: data.user.deptName,
                    designation: data.user.designation,
                    validation: validationData,
                    isFormEditable: isFormEditable
                });
            }).catch((error) => {
                console.log('Server Error Failed', error);

                NotificationManager({
                    message: error.message || "Unexpected error, Data didn't load",
                    type: 4
                });
            });
        }
    }

    getSpecialityData() {
        //  to get speciality data using user ID in profile modal
        let userId = this.props.user.userId;

        let postdata = {};
        postdata.id = parseInt(userId);

        let listUrl = require('../common/configurations.js').category.productcategories;
        let Method = "post";

        let headers1 = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        CategoryServices.productcategories().then( (response) => {
            let data = response;

            this.setState({ specialityData: data });
        })
            .catch( error => {
                console.log(`failed to get speciality fields`);
            });
    }

    generateSpecialityData(newData) {
        let _this = this;
        let oldData = _this.state.specialityData;

        let initData = oldData.map(function (val, ii) {
            val.status = false;

            return val;
        });

        newData.map(function (nData, nind) {
            initData.some(function (oData) {
                if (nData == oData.catName) {
                    oData.status = true;
                }
            });
        });

        return oldData;
    }

    openModalCancel() {
        if (this.state.isModified) {
            this.setState({ isModalOpen: true });
        } else {
            this.props.modalClose();
        }

        $('.jqx-widget-header').show();
        $('.footer').show();
        $('.jqx-grid-column-filterbutton').css('display', 'none');
    }

    editModalCancel() {
        $('.jqx-widget-header').show();
        $('.footer').show();
        $('.jqx-grid-column-filterbutton').css('display', 'none');

        this.props.modalClose();
    }

    modalClose(key) {
        this.setState({ isModalOpen: false });

        switch (key) {
            case 'updateUser':
                this.handleSubmit();
                break;

            case 'changePassword':
                this.submitChangePassword();
                break;

            case 'no':
                this.props.modalClose();
                break;
        }
    }

    validateEmail(email) {
        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        return regex.test(email);
    }

    handleSubmit(event) {
        var _this = this;
        var finalData = this.state.user;

        let q = {};
        let trimmingFields = [
            'company',
            'designation',
            'email',
            'firstName',
            'lastName',
            'nickName',
            'phone'
        ];

        for (var key in finalData) {
            if (trimmingFields.indexOf(key) >= 0) {
                q[key] = finalData[key].trim();
            } else {
                q[key] = finalData[key];
            }
        }

        var flag = 0;

        if (this.state.formEdit) {
            $("#user-profile").find('input[type="text"],input[type="email"]').each(function () {
                var _me = $(this);

                if (_me.val().trim().length <= 0) {
                    if (_this.state.validation[_me[0].name]) {
                        _me.addClass('invalid');
                        flag++;

                        _this.setState({
                            errorMessage: '*Mandatory fields marked with an asterisk is missing.'
                        });
                    }
                } else {
                    if (_me[0].type === "email") {
                        if (_this.validateEmail(_me.val())) {
                            _me.removeClass('invalid');
                        } else {
                            _me.addClass('invalid');

                            _this.setState({
                                errorMsg: 'Invalid Email address.',
                                invalid: true
                            });

                            flag++;

                            _this.setState({
                                errorMessage: 'Please enter valid email address'
                            });
                        }
                    } else {
                        _me.removeClass('invalid');
                    }
                }
            });
        }

        if (flag <= 0) {
            _this.setState({ invalid: false });

            let req = q;
            let status = 0;

            req.specialityListDTOs = null;

            var URL = '';

            if (this.props.user.admin) {
                AdminServices.insertUsersData(req).then((data) => {
                    if (data.status) {
                        // when user group is changed, make GET call and upate the local storage and reload the app.
                        // so that, all functionality will coninue without re-login
                        // TODO - Going forward, add new services to this module and use this for all user relate activities.
                        // Date - 9/26/19 | JIRA - yet to be created | Dev - Guna
                        UserServices.getUser().then( (userResponse) => {
                            localStorage.setItem('user', JSON.stringify(userResponse.data.responseData));
                            window.location.href = '/dashboard'; //changed to redirect users to the Dashbaord after login.
                        });
                    } else {
                        _this.props.modalClose();

                        NotificationManager({
                            message: data.data.responseMessage,
                            type: 4
                        });
                    }
                }).catch((error) => {
                    _this.props.modalClose();
                    NotificationManager({
                        message: 'Failed ' + data.data.responseMessage,
                        type: 4
                    });
                });
            } else {
                UserServices.insertUsersData(req).then((data) => {
                    if (data.status) {
                        // when user group is changed, make GET call and upate the local storage and reload the app.
                        // so that, all functionality will coninue without re-login
                        // TODO - Going forward, add new services to this module and use this for all user relate activities.
                        // Date - 9/26/19 | JIRA - yet to be created | Dev - Guna
                        UserServices.getUser().then( (userResponse) => {
                            localStorage.setItem('user', JSON.stringify(userResponse.data.responseData));
                            window.location.href = '/dashboard'; //changed to redirect users to the Dashbaord after login.
                        });
                    } else {
                        _this.props.modalClose();

                        NotificationManager({
                            message: data.data.responseMessage,
                            type: 4
                        });
                    }
                }).catch((error) => {
                    _this.props.modalClose();

                    NotificationManager({
                        message: 'Failed ' + data.data.responseMessage,
                        type: 4
                    });
                });
            }
        }

        $('.jqx-widget-header').css('display', 'block');
        $('.footer').css('display', 'block');
        $('.jqx-grid-column-filterbutton').css('display', 'none');
    }

    isValidPassword() {
        var passwordLength = 8,
            empty = 'Mandatory field(s) is/are missing',
            length = `password must be at least ${passwordLength} characters`,
            notMatching = 'Password did not match. Please provide the same password.';

        var msg_str = "Your password must have min 8 to max 20 characters. It must contain atleast one numeric value (0-9), Uppercase letter (A-Z), Lowercase letter (a-z) and Special character (?=.[!@#\$%\^&])";
        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,20})");

        if (this.state.changePswd.oldPassword &&
           this.state.changePswd.newPassword &&
           this.state.changePswd.confNewPassword) {
            if (strongRegex.test(this.state.changePswd.newPassword)) {
                if (this.state.changePswd.newPassword != this.state.changePswd.confNewPassword) {
                    return {
                        isValid: false,
                        msg: notMatching
                    };
                } else {
                    return {
                        isValid: true
                    };
                }
            } else {
                return {
                    isValid: false,
                    msg: msg_str
                };
            }
        } else {
            return {
                isValid: false,
                msg: empty
            };
        }
    }

    submitChangePassword(event) {
        var _this = this;
        var q = this.state.changePswd;

        var flag = 0;

        _this.setState({ errorMsg: '' });

        var validRes = this.isValidPassword();

        if (!validRes.isValid) {
            _this.setState({
                invalidPassword: true,
                errorMsg: validRes.msg
            });

            return;
        }

        if (flag <= 0) {
            q.userId = this.state.user.userName;

            var req = JSON.stringify(q), status = 0;

            let headers1 = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json'
            };

            CommonServices.changePassword(req)
                .then( (data) => {
                    if (data.status) {
                        var changePswd = {
                            oldPassword: '',
                            newPassword: '',
                            confNewPassword: ''
                        };

                        _this.setState({
                            changePassword: false,
                            profileView: true,
                            changePswd: changePswd
                        });

                        NotificationManager({
                            message: 'Password reset successfully',
                            type: 1
                        });

                        _this.props.logout();

                        window.location.href = '/login';
                    } else {
                        NotificationManager({
                            message: data.responseMessage,
                            type: 1
                        });

                        _this.setState({
                            invalidPassword: true,
                            errorMsg: data.responseMessage
                        });
                    }
                })
                .catch( error => {
                    console.log(`failed`);
                });
        }
    }

    handleChange(event) {
        let _this = this;
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        // Phoneno - allows only numeric value
        if ((name === 'phone' && isNaN(value))) {
            return false;
        }

        if (value.trim() === "" || value.trim() === undefined) {
            if (this.state.validation[name]) {
                target.className += ' invalid';
            }
        } else {
            _this.setState({ invalid: false });

            target.classList.remove('invalid');
        }

        let { user } = this.state;
        let newUser = user;
        newUser[name] = value;

        if (name !== 'email') {
            newUser[name] = value.replace(/  +/g, ' ');
        }

        this.setState({
            user: newUser,
            isModified: true
        });
    }

    changeUserGroup(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let { user } = this.state;
        let newUser = user;

        newUser[name] = value;

        this.setState({
            isFormEditable: true,
            isUpdateUser: true,
            isModified: true
        });
    }

    handleChangePassword(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (value.trim() === "" || value.trim() === undefined) {
            target.className += ' invalid';
        } else {
            target.classList.remove('invalid');
        }

        let { changePswd } = this.state;
        let newChangePassword = changePswd;

        newChangePassword[name] = value;
        this.setState({ changePswd: newChangePassword, isModified: true });
    }

    formEditable(event) {
        event.preventDefault();

        this.setState({ formEdit: true });
    }

    formViewable() {
        var flagForEditable = false;

        if (this.state.isFormEditable) {
            flagForEditable = true;
        }

        this.setState({
            formEdit: false,
            isUpdateUser: false,
            isFormEditable: flagForEditable
        });
    }

    showChangePassword() {
        this.setState({
            profileView: false,
            changePassword: true,
            modalTitle: 'Change Password'
        });
    }

    showProfileView() {
        this.setState({
            modalTitle: 'Profile',
            profileView: true,
            changePassword: false
        });
    }

    OptionItem(props) {
        return <option>{props.value}</option>;
    }

    render() {
        console.log('profileContainer render: state', this.state);
        console.log('profileContainer render: props', this.props);

        let cancelBtn = '', saveBtn = '';

        if (this.state.formEdit || this.state.isUpdateUser) {
            cancelBtn = (<input type="button" value="Cancel" onClick={this.openModalCancel}></input>);
            saveBtn = (<input type="button" value="Save" onClick={this.handleSubmit}></input>);
        } else {
            cancelBtn = (<input type="button" value="Cancel" onClick={this.editModalCancel}></input>);
            saveBtn = (<input type="button" value="Edit" onClick={this.formEditable}></input>);
        }

        let userData = this.state.userData;
        let optionItem = [];

        if (userData.hasOwnProperty('user')) {
            optionItem = userData.user.roles.map(function (role, index) {
                if (role.status === 'True') {
                    if (role.role === userData.user.defaultRole) {
                        return <option value={role.role} key={index} selected >{role.role}</option>;
                    } else {
                        return <option value={role.role} key={index}>{role.role}</option>;
                    }
                }
            });
        }

        let currentUser = this.props.user.company.toLowerCase() == 'sweft';

        return (
            <ProfileComponent
                state = {this.state}
                formEditable = {this.formEditable}
                formViewable = {this.formViewable}
                showProfileView = {this.showProfileView}
                showChangePassword = {this.showChangePassword}
                handleSubmit = {this.handleSubmit}
                handleChange = {this.handleChange}
                changeUserGroup = {this.changeUserGroup}
                handleChangePassword = {this.handleChangePassword}
                submitChangePassword = {this.submitChangePassword}
                openModalCancel = {this.openModalCancel}
                modalClose = {this.modalClose}
                getSpecialityData = {this.getSpecialityData}
                generateSpecialityData = {this.generateSpecialityData}
                optionItem = {optionItem}
                cancelBtn = {cancelBtn}
                saveBtn = {saveBtn}
                currentUser = {currentUser}
            />
        );
    }
}

export default Profile;
