import React, { Component } from 'react';
import axios from 'axios';
import CategoryServices from '../../product2/services/category-services';

var GridDataBuilder = require('../../common/gridDataBuilder');
// var NotificationManager = require('../../common/notification');
import NotificationManager from '../../common/components/notification';

class CategoryTree extends Component{
    constructor(props){
        super(props);
        this.state = {
            category:null,
            selectedCategoryId:null
        };
        this.selectedCatHierarchy = {division:null, department:null, classColumn:null, subClassColumn:null};
        this.toggleView = this.toggleView.bind(this);
        this.refereshView = this.refereshView.bind(this);
        this.findCategoryObj = this.findCategoryObj.bind(this);
        this.setCategoryId = this.setCategoryId.bind(this);
    }

    componentDidMount(){
        var _this = this;

	    let headers = {
	          'Authorization': this.props.user.token,
	          'content-type': 'application/json'
	    };

        CategoryServices.productcategories().then( (response) => {
            // let res = response.data.respPayload

            let res = response;

            var category = [];
            for(var i = 0;i < res.length;i++){
                if(res[i].catName.toLowerCase() === "department"){
                    category.push(res[i]);
                    break;
                }
            }
            _this.setState({category:category});
            setTimeout(function(){
                $('.product-treegrid .tree-node.level1').find('span:eq(0)').trigger('click');
            }, 300);
	    })
	    .catch( error => {
	        console.log('productContainer helperComponents categoryTree API call failed', error);
	    });
    }

    setCategoryId(){
        if(this.state.selectedCategoryId){
            this.selectedCatHierarchy = {division:null, department:null, classColumn:null, subClassColumn:null};
            var data = this.state.category;
            var currentSelectedLevel =  $('#SelectCategoryPopup .tree-node-list.selected').closest('.tree-node').attr('data-level');
            var length = parseInt(currentSelectedLevel);
            for(var i = length;i > 0;i--){
                this.pushValueIntoObj(i);
            }
            // this.props.setCellValueAndSaveProduct(this.props.productId,'catId',this.state.selectedCategoryId,this.selectedCatHierarchy);  // commented for new ag-grid

            // below added for ag-grid
            console.log('setting department category', this.state.selectedCategoryId, this.selectedCatHierarchy);
            let catId = this.state.selectedCategoryId;
            let catHierarchy = this.selectedCatHierarchy;
            this.props.setColumnValues(catId, catHierarchy);
            // above added for ag-grid

            // this.props.modalClose();
        }else{
            NotificationManager({message:"Please select any one category", type:4});
        }
    }

    pushValueIntoObj(level){
        var catId = $('#SelectCategoryPopup .tree-node-list.selected').closest('.tree-node.level' + level).attr('data-catid');
        switch(level){
            case 2:
                this.selectedCatHierarchy.division = catId;
                break;
            case 3:
                this.selectedCatHierarchy.department = catId;
                break;
            case 4:
                this.selectedCatHierarchy.classColumn = catId;
                break;
            case 5:
                this.selectedCatHierarchy.subClassColumn = catId;
                break;
            default:
			  break;
        }
    }

    findCategoryObj(data, catId, level, doSelect){
        for(var i = 0;i < data.length;i++){
            var o = data[i];
            o.isTabActive = o.isTabActive == undefined ? false : o.isTabActive;
            o.isOpen = o.isOpen == undefined ? false : o.isOpen;
            o.isSelected = o.isSelected == undefined ? false : o.isSelected;
            if(doSelect && level > 4){
                o.isSelected = false;
            }
            if(o.catId === catId){
                o.isOpen = !o.isOpen;
                o.isTabActive = !o.isTabActive;
                if(doSelect && level > 4){
                    o.isSelected = true;
                    this.state.selectedCategoryId = o.catId;
                }
            }
            if(o.productCategory.length){
                this.findCategoryObj(o.productCategory, catId, level, doSelect);
            }
        }
    }

    toggleView(catId, level, doSelect){
        //this.selectedCatHierarchy=[];
        var data = this.state.category;
        this.findCategoryObj(data, catId, level, doSelect);
        if(this.state.selectedCategoryId){
            this.props.enableOkayButton(true);
        }else{
            this.props.enableOkayButton(false);
        }
        this.setState({category:data});
    }

    refereshView(nextProps){
        var data = nextProps.alerts;
        this.setState({alerts:data});
    }

    categoryTreeView(catlist, level){
        let items = catlist.map((category, index) => {
            return (
                <div key={index} className={`tree-node level` + level} data-level={level} data-catId={category.catId}>
                    <div className={`tree-node-list ${(category.isSelected) ? 'selected' : ''}`}>
                        { category.productCategory.length > 0 ?
                            <span className={`${(category.isOpen) ? 'icon-minus-blue' : 'icon-plus'}`} onClick={() =>this.toggleView(category.catId, level)}></span>
                            : <span className={`no-expand`}>&nbsp;</span>}
                        <span id={"id-" + category.catId} className="category" data-node="0" data-catid={category.catId}
                            onClick={() =>this.toggleView(category.catId, level, 1)}>{category.catName}</span>
                    </div>
                    { category.productCategory.length > 0 &&
				<div className={`tree-node-item ${(category.isOpen) ? 'is-open' : ''}`}>
					 {this.categoryTreeView(category.productCategory, level + 1)}
				</div>
                    }
                </div>
            );
        });
        return (<div>{items}</div>);
    }

    render(){
	  let level = 1;
	  	return (
			  <div id="SelectCategoryPopup">
			  { this.state.category &&
				this.categoryTreeView(this.state.category, level)
			   }
			  </div>
		  );
    }
}
export default CategoryTree;
