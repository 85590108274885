import React from 'react';

class ProductInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRow: [],
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }


    componentWillMount() {
        const _this = this;
        console.log('ProductInfo componentWillMount', this.props, this.state);
        if (_this.state.selectedRow.length < 1 && _this.props.selectedRowData) {
            _this.setState({ selectedRow: _this.props.selectedRowData });
        }
    }

    componentWillReceiveProps(nextProps) {
        const _this = this;
        console.log('ProductInfo componentWillReceiveProps', this.props, nextProps);

        if (nextProps.selectedRowData && _this.props.selectedRowData && nextProps.selectedRowData.productId != _this.props.selectedRowData.productId) {
            _this.setState({ selectedRow: nextProps.selectedRowData });
        }
    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        console.log('checking checkbox', value);
    }


    render() {
        console.log('ProductInfo render', this.props, this.state);
        const data = this.state.selectedRow;

        return(
            <div className='tabItems'>
                <div className="row">
                    <label className="cw-copyStatus">PI Status: </label>
                    <div className="cw-copyStatus">{data.isPiApproved ? 'Approved' : 'Incomplete'}</div>
                </div>


                <div className="row">
                    <label className="cw-pi-section-heading">General</label>
                    <div className="input-sections">
                        <label className="cw-pi-label">Country of Origin</label>
                        <input type="text" className="form-control cw-pi-input" name="webPrice" defaultValue="" />
                    </div>
                </div>

                <div className="row">
                    <div className="input-checkbox-sections">
                        <label className="cw-pi-label">Web/Brand Exclusive</label>
                        <input type="checkbox" className="cw-pi-input" name="webPrice" defaultValue="" onClick={this.handleInputChange} />

                        <label className="cw-pi-label">Use Label in Copy</label>
                        <input type="checkbox" className="cw-pi-input" name="webPrice" defaultValue="" />
                    </div>
                </div>

                <div className="row">
                    <label className="cw-pi-section-heading">Materials</label>
                    <div className="input-sections">
                        <label className="cw-pi-label">Outer Material</label>
                        <input type="text" className="form-control cw-pi-input" name="piOuterMaterial" defaultValue={data.piOuterMaterial ? data.piOuterMaterial : ''} />
                    </div>
                    <div className="input-sections">
                        <label className="cw-pi-label">Inner Material</label>
                        <input type="text" className="form-control cw-pi-input" name="piInnerMaterial" defaultValue={data.piInnerMaterial ? data.piInnerMaterial : ''} />
                    </div>
                    <div className="input-sections">
                        <label className="cw-pi-label">Sole Material (shoes)</label>
                        <input type="text" className="form-control cw-pi-input" name="piSoleMaterial" defaultValue={data.piSoleMaterial ? data.piSoleMaterial : ''} />
                    </div>
                    <div className="input-sections large-input">
                        <label className="cw-pi-label">Product Care</label>
                        <textarea type="text" className="form-control cw-pi-input" name="piProductCare" defaultValue={data.piProductCare ? data.piProductCare : ''} />
                    </div>
                </div>

                <div className="row">
                    <label className="cw-pi-section-heading">Dimensions</label>
                    <div className="input-sections">
                        <label className="cw-pi-label">Height</label>
                        <input type="text" className="form-control cw-pi-input" name="piHeight" defaultValue={data.piHeight ? data.piHeight : ''} />
                    </div>
                    <div className="input-sections">
                        <label className="cw-pi-label">Width</label>
                        <input type="text" className="form-control cw-pi-input" name="piWidth" defaultValue={data.piWidth ? data.piWidth : ''} />
                    </div>
                    <div className="input-sections">
                        <label className="cw-pi-label">Depth</label>
                        <input type="text" className="form-control cw-pi-input" name="piInternalDepth" defaultValue={data.piInternalDepth ? data.piInternalDepth : ''} />
                    </div>
                    <div className="input-sections">
                        <label className="cw-pi-label">Length</label>
                        <input type="text" className="form-control cw-pi-input" name="piLength" defaultValue={data.piLength ? data.piLength : ''} />
                    </div>
                </div>


                <div className="row">
                    <label className="cw-pi-section-heading">Measurements</label>
                    <div className="input-sections">
                        <label className="cw-pi-label">PI Collar Size</label>
                        <input type="text" className="form-control cw-pi-input" name="piCollarSize" defaultValue={data.piCollarSize ? data.piCollarSize : ''} />
                    </div>
                    <div className="input-sections">
                        <label className="cw-pi-label">PI Cuff Width</label>
                        <input type="text" className="form-control cw-pi-input" name="piCuffWidth" defaultValue={data.piCuffWidth ? data.piCuffWidth : ''} />
                    </div>
                    <div className="input-sections">
                        <label className="cw-pi-label">PI Sleeve Length</label>
                        <input type="text" className="form-control cw-pi-input" name="piSleeveLength" defaultValue={data.piSleeveLength ? data.piSleeveLength : ''} />
                    </div>
                    <div className="input-sections">
                        <label className="cw-pi-label">PI Width at Hem</label>
                        <input type="text" className="form-control cw-pi-input" name="piWidthHem" defaultValue={data.piWidthHem ? data.piWidthHem : ''} />
                    </div>
                    <div className="input-sections">
                        <label className="cw-pi-label">PI Bust Size</label>
                        <input type="text" className="form-control cw-pi-input" name="piBustSize" defaultValue={data.piBustSize ? data.piBustSize : ''} />
                    </div>
                </div>


                <div className="row">
                    <label className="cw-pi-section-heading">Other Details</label>
                    <div className="input-sections">
                        <label className="cw-pi-label">PI Instructions</label>
                        <input type="text" className="form-control cw-pi-input" name="piInstruction" defaultValue={data.piInstruction ? data.piInstruction : ''} />
                    </div>
                    <div className="input-sections">
                        <label className="cw-pi-label">PI Disclaimer</label>
                        <input type="text" className="form-control cw-pi-input" name="piDisclaimer" defaultValue={data.piDisclaimer ? data.piDisclaimer : ''} />
                    </div>
                    <div className="input-sections">
                        <label className="cw-pi-label">PI Approved Date</label>
                        <input type="text" className="form-control cw-pi-input" name="piApprovedDate" defaultValue={data.piApprovedDate ? data.piApprovedDate : ''} />
                    </div>
                    <div className="input-sections">
                        <label className="cw-pi-label">PI Package Weight</label>
                        <input type="text" className="form-control cw-pi-input" name="piWeightPacked" defaultValue={data.piWeightPacked ? data.piWeightPacked : ''} />
                    </div>
                    <div className="input-sections">
                        <label className="cw-pi-label">PI Internal Depth</label>
                        <input type="text" className="form-control cw-pi-input" name="piInternalDepth" defaultValue={data.piInternalDepth ? data.piInternalDepth : ''} />
                    </div>
                    <div className="input-sections">
                        <label className="cw-pi-label">PI Color Group</label>
                        <input type="text" className="form-control cw-pi-input" name="piColor" defaultValue={data.piColor ? data.piColor : ''} />
                    </div>
                </div>


            </div>
        );
    }
}

export default ProductInfo;
