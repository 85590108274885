/**
 * column width declaration
 */
const columnWidth = {
    productId: 120,
    prodName: 300,
    activeNotes: 400,
    //required fields column width
    fields: '25%',
    fieldsToClone: '12%',
    readyToOrder: '12%',
    reqdToAdd: '12%',
    mpr2Approved: '12%',
    //rules fields column width
    description: '30%',
    details: '30%',
    ruleName: '25%',
    status: '15%',
    value: 400,
};

/**
 * Required fields
 */
const requiredFieldsList = [
    'fieldsToClone',
    'readyToOrder',
    'reqdToAdd',
    'mpr2Approved'
];

/**
 * Renders the checkbox
 * @param {*} row
 * @param {*} columnfield
 * @param {*} value
 * @param {*} rowdata
 * @return {Object}
 */
const productCheckbox = function (row, columnfield, value, rowdata) {
    let disabled = '';

    if (window.currentValSampleSwatchOption && window.currentValSampleSwatchOption == 'Swatch Only') {
        disabled = 'disabled';
    }

    if (window.readableFields && columnfield === 'sampleSwatch' && window.readableFields.indexOf('swatchOnly') > -1) {
        disabled = 'disabled';
    }

    if (columnfield == 'mandatoryFields') {
        rowdata = this.owner.getrowdata(row);

        if (['userName', 'email', 'userGroups'].indexOf(rowdata.userFields) > -1) {
            disabled = 'disabled';
        }
    }

    if (value) {
        return '<span class="blue-checkbox "><input class="sample-boolean" type="checkbox" data-fieldname="' +
            columnfield + '" checked data-row="' + row + '" ' + disabled + '><label></label></span>';
    } else {
        return '<span class="blue-checkbox "><input class="sample-boolean" type="checkbox" data-fieldname="' +
            columnfield + '" data-row="' + row + '" ' + disabled + '><label></label></span>';
    }
};

/**
 * Creates the date editor
 * @param {*} row
 * @param {*} cellvalue
 * @param {*} editor
 * @param {*} cellText
 * @param {*} width
 * @param {*} height
 */
const createDateEditor = function (row, cellvalue, editor, cellText, width, height) {
    // construct the editor.
    cellvalue = cellvalue ? cellvalue : new Date();

    editor.jqxDateTimeInput({
        width: width,
        height: height,
        value: cellvalue,
        culture: 'en-US',
    });
};

/**
 * Initialize the date editor
 * @param {*} row
 * @param {*} cellvalue
 * @param {*} editor
 * @param {*} celltext
 * @param {*} width
 * @param {*} height
 */
const initDateEditor = function (row, cellvalue, editor, celltext, width, height) {
    // set the editor's current value. The callback is called each time the editor is displayed.
    cellvalue = cellvalue ? cellvalue : new Date();
    editor.jqxDateTimeInput('setDate', cellvalue);
};

/**
 * Gets current date
 * @param {*} row
 * @param {*} cellvalue
 * @param {*} editor
 * @return {Date}
 */
const getDateEditor = function (row, cellvalue, editor) {
    // return the editor's value.
    return $(editor).jqxDateTimeInput('getDate');
};

/**
 * Renders the image in cell
 * @param {*} row
 * @param {*} datafield
 * @param {*} value
 * @return {object}
 */
const imageRenderer = function (row, datafield, value) {
    let image;

    if (value.length && value[0].imageName) {
        image = '<div class="product-image"><img src="'
            + value[0].imageName + '" /></div>';
    } else {
        image = '';
    }

    return image;
};

/**
 * renders swatch only cell
 * @param {*} row
 * @param {*} columnfield
 * @param {*} value
 * @param {*} rowdata
 * @return {object}
 */
const swatchOnlyCellRender = function (row, columnfield, value, rowdata) {
    if (value && value > 0) {
        return '<span class="blue-checkbox"><input class="swatch-only" type="checkbox" checked data-row="' +
            row + '" ><label></label></span>';
    } else {
        return '<span class="blue-checkbox"><input class="swatch-only" type="checkbox"  data-row="' +
            row + '" ><label></label></span>';
    }
};

/**
 * renders checkbox
 * @param {*} row
 * @param {*} columnfield
 * @param {*} value
 * @param {*} html
 * @param {*} details
 * @param {*} rowdata
 * @return {object}
 */
const requiredFieldcheckboxes = function (row, columnfield, value, html, details, rowdata) {
    const defaultFields = [
        'productId',
        'prodName',
        'catId',
        'vendorId',
        'copyLevel'
    ];

    let disabled = '';
    let disabledTwo = '';

    if (columnfield === 'reqdToAdd' && defaultFields.indexOf(rowdata.actualFieldName) > -1) {
        if (rowdata.actualFieldName != 'copyLevel') {
            value = true;
        }

        disabled = 'disabled';
    }

    // disabled specific reqToAdd field checkbox
    if (columnfield === 'reqdToAdd' && !rowdata.editable) {
        disabledTwo = 'disabled';
    }

    if (value) {
        return '<div class="blue-checkbox custom-grid-checkbox"><input class="required-checkbox" type="checkbox" data-fieldname="' +
            columnfield + '" checked ' + disabled + ' data-row="' + row + '" ' + disabledTwo + '><label></label></div>';
    } else {
        return '<div class="blue-checkbox custom-grid-checkbox"><input class="required-checkbox" type="checkbox" data-fieldname="' +
            columnfield + '" data-row="' + row + '" ' + disabledTwo + '><label></label></div>';
    }
};

// Rules status click icon reder
const CellDoubleClickEvent = function (row, columnfield, value, rowdata) {
    const status = value === 'Active' ? 'active' : '';
    const className = columnfield === 'status' ? 'rule-status' : '';

    return '<div class="grid-text ' + className + ' ' + status + '" data-row="' + row + '">' + value + '<span class="icon-expand-grid right"></span></div>';
};

const cellbeginedit = function (row, datafield, columntype, value) {
    const rowData = this.owner.getrowdata(row);

    if (rowData.id) {
        return false;
    }
};

/* Grid data builder is used to create the object format required for
 * Jqwidgets.
 */
function GridDataBuilder(res, pageName) {
    // dataField is used for providing type of data and its name.
    // columnList is used to define column name, type, and extra fields
    //  to define additional plugins inside the cell eg: drop down, calender ...,
    const dataField = [];
    const columnList = [];

    let primayKey = (pageName === 'rules') ? 'ruleId' : res.columnDetails[0].fieldName;
    // check for the type of fieldname future

    //sort columndetails in product pageName
    res.columnDetails.sort(function (a, b) {
        return a.order - b.order;
    });

    for (let i = 0; i < res.columnDetails.length; i++) {
        dataField.push({
            'name': res.columnDetails[i].fieldName,
            'type': res.columnDetails[i].type ? res.columnDetails[i].type.toLowerCase() : 'String'
        });

        /** Enable the below condition after pk(primary key) availabel from backend
         *  in JSON response.
         */

        if (res.columnDetails[i].hasOwnProperty('pk') && res.columnDetails[i].pk) {
            primayKey = res.columnDetails[i].fieldName;
        }

        columnList.push(columnObj(res.columnDetails[i]));
    }

    /** columnobj function will  return the object fields
     *  based on the field type in list.
     * e.g. for date type and other types need to build the cellrender function.
     * @param {*} list
     * @return {object}
     */
    function columnObj(list) {
        const obj = {};

        if (pageName === 'rules') {
            obj.text = '<span class="pd-drop" data-field="' + list.fieldName + '" title="' + list.columnTitle + '">' + list.columnTitle + '</span>';
        } else {
            obj.text = '<span title="' + list.columnTitle + '">' + list.columnTitle + '</span>';
        }

        obj.dataField = list.fieldName;
        obj.width = (columnWidth[list.fieldName]) ? columnWidth[list.fieldName] : 120;
        obj.hidden = !list.visibility;

        /* if list type is date, pass the methods related to date to intialize
         *  date picker
         */
        if (list.type === 'Date') {
            obj.columnType = 'template';
            obj.cellsFormat = 'd';
            obj.createEditor = createDateEditor;
            obj.initEditor = initDateEditor;
            obj.getEditorValue = getDateEditor;
        } else if (list.type === 'image') {
            obj.editable = false;
            obj.cellsrenderer = imageRenderer;
        } else if (list.fieldName == 'swatchOnly') {
            obj.cellsrenderer = swatchOnlyCellRender;
            obj.editable = false;
            obj.cellsAlign = 'center';
        } else if (pageName === 'requiredFields' && requiredFieldsList.indexOf(list.fieldName) >= 0) {
            obj.cellsrenderer = requiredFieldcheckboxes;
            obj.editable = false;
            obj.cellsAlign = 'center';
        } else if (pageName === 'rules') {
            if (list.fieldName === 'status') {
                obj.text = '<span class="pd-drop" data-field="' + list.fieldName + '">Status</span>';
                obj.cellsrenderer = CellDoubleClickEvent;
                obj.editable = false;
                obj.pinned = true; // Freeze column in Admin section Rules Page
            } else if (list.fieldName === 'ruleName') {
                obj.cellsrenderer = CellDoubleClickEvent;
                obj.editable = false;
                obj.sortable = true;
                obj.pinned = true; // Freeze column in Admin section Rules Page
            } else {
                obj.sortable = false;
            }
        } else if (pageName === 'Sample') {
            if (list.fieldName === 'sampleSwatch') {
                obj.cellsrenderer = productCheckbox;
                obj.editable = false;
            } else if (list.fieldName === 'sampleComments') {
                obj.editable = true;
                if (window.readableFields && window.readableFields.indexOf('swatchOnly') > -1) {
                    obj.editable = false;
                }
            } else {
                obj.editable = false;
            }
        } else if (pageName == 'category') {
            if (list.fieldName === 'categoryId') {
                obj.editable = false;
            } else if (list.fieldName === 'name') {
                obj.pinned = true;
                obj.initeditor = function (row, column, editor) {
                    editor.attr('maxlength', 100);
                };
            } else if (list.fieldName == 'displayOrder') {
                obj.initeditor = function (row, column, editor) {
                    editor.attr('maxlength', 5);
                };
            } else if (list.type == 'DropDown') {
                obj.columntype = 'dropdownlist';
                obj.createeditor = function (row, value, editor) {
                    editor.jqxDropDownList({ source: res.dropDowns, width: this.width });
                };
            } else if (list.type === 'bool') {
                obj.columntype = 'checkbox';
            }
        } else if (pageName == 'userfields') {
            if (list.fieldName == 'mandatoryFields') {
                obj.cellsrenderer = productCheckbox;
                obj.editable = false;
                obj.cellsAlign = 'center';
            }
        } else if (list.type == 'boolean') {
            obj.cellsrenderer = productCheckbox;
            obj.editable = false;
            obj.cellsAlign = 'center';
        } else if (pageName == 'ShotAngles') {
            if (list.fieldName == 'id' || list.fieldName == 'angle') {
                obj.editable = false;
            }
        } else if (pageName == 'email') {
            if (list.fieldName == 'id') {
                obj.cellsrenderer = CellDoubleClickEvent;
                obj.editable = false;
                obj.sortable = true;
            }
        }

        return obj;
    }

    if (pageName == 'product') {
        const obj = {};
        obj.text = '<span class=\'header-checkbox\' data-checked=\'false\'></span>';
        obj.dataField = null;
        obj.width = 50;
        obj.editable = false;
        obj.sortable = false;

        // place the checkboxs in first columnList
        columnList.unshift(obj);
    }

    const source = {
        dataType: 'json',
        dataFields: dataField,
        id: primayKey,
        localdata: res.data,
    };

    const dataAdapter = new $.jqx.dataAdapter(source);

    return {
        dataAdapter: dataAdapter,
        columns: columnList,
    };
}

export default GridDataBuilder;
