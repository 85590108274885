import React, { Component } from 'react';
import UserGroupComponent from './userGroupComponent';
import GridDataBuilder from './helperComponents/userGroupDataBuilder';
import NotificationManager from '../common/components/notification';
import axios from 'axios';
import AdminServices from '../admin/admin-services/admin-services';

class UserGroup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            userGroupList: [],
            updateGroupData: [],
            groupEditPopupData: {},
            isEditModalOpen: false,
            isCancelModalOpen: false,
            roleid: '',
            groupStatus: '',
            rowId: '',
            isModified: false,
        };

        this.constants = {
            backendViewId: 'view id',
            status: 'status',
            viewId: 'viewId',
            views: 'views',
            isViewObject: 'isViewObject',
        };

        this.applyChanges = this.applyChanges.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.addGroupRow = this.addGroupRow.bind(this);
        this.addNewGroup = this.addNewGroup.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.parseForBackend = this.parseForBackend.bind(this);
        this.updateGridRow = this.updateGridRow.bind(this);
    }

    componentDidMount () {
        console.log('userGroupContainer componentDidMount props: ', this.props);

        const base = this;

        AdminServices.getUserGroupsList().then((response) => {
            const res = response;

            if (res.responseCode === 0) {
                let currentUser = base.props.user;
                let colDetails = res.columnDetails;
                let dataMap = res.data;

                // Added here to hide the columns
                // Rockler users and only visible to users with
                // company = sweft

                colDetails && colDetails.map((i) => {
                    if (['creative', 'marketing', 'publication', 'product', 'samples', 'copywriting', 'user'].includes(i.fieldName)) {
                        i.visibility = false;
                    }
                });

                if (colDetails && currentUser.company.toLowerCase() != 'sweft') {
                    // This removes the admin user from the list
                    dataMap.map((i, x) => {
                        if (i.title == 'ADMIN') {
                            dataMap.splice(x, 1);
                        }
                    });
                }

                // the data will be parsed as required for jqwidgets
                base.parseForJqwidget(res.data);
                base.gridData = GridDataBuilder(res, 'userGroup');

                base.setState({
                    isLoaded: true,
                    userGroupList: res.data,
                    preUserGroupDataList: res.data,
                    userTotalCount: res.data.length
                });

                base.keysToConvert = base.getKeysToConvert(res.data[0][base.constants.views]);
            } else {
                NotificationManager({
                    message: 'Error:Response failed, Please try again',
                    type: 4
                });
            }
        }).catch((error) => {
            console.log('UserGroup componentDidMount API call failed', error);

            NotificationManager({
                message: 'Error:Response failed, Please try again',
                type: 4
            });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.isLoaded !== this.state.isLoaded) {
            const _this = this;

            // Single rowclick to edit user in grid
            const userGroupView = $('#userGroupGrid');
            userGroupView.off();

            // User Group title double event to open pop-up
            userGroupView.on('dblclick', '.gridGroupTitle', function(event) {
                const _me = $(this);
                const row = _me.data('row');
                const rowData = _this.userGroupGrid.getrowdata(row);
                const groupObj = Object.assign({}, rowData);

                if (rowData.enabled == false) {
                    NotificationManager({
                        message: 'User is disabled, please <b>enable</b> the user before taking any action',
                        type: 4,
                    });
                } else {
                    _this.groupEditPopupData = groupObj;
                    _this.setState({ groupEditPopupData: groupObj });
                    _this.modalOpen('edit');
                }
            });

            userGroupView.on('click', '.usergroup-view', function(e) {
                const rowIndex = $(this).data('row');
                const fieldname = $(this).data('fieldname');
                const value = ($(this).prop('checked')) ? 'V' : 'NA';
                const rowData = _this.userGroupGrid.getrowdata(rowIndex);

                if (rowData.enabled == false) {
                    e.stopPropagation;

                    NotificationManager({
                        message: 'User is disabled, please <b>enable</b> the user before taking any action',
                        type: 4,
                    });

                    if ($(this).is(":checked")) {
                        e.stopPropagation();

                        return false;
                    } else {
                        e.stopPropagation();

                        return false;
                    }
                } else {
                    const cellVal = rowData[fieldname];

                    cellVal.status = value;
                    _this.userGroupGrid.setcellvalue(rowIndex, fieldname, cellVal);
                    _this.userGroupGrid.refresh();

                    rowData[fieldname] = cellVal;

                    const { updateGroupData } = _this.state;
                    const newUpdateGroupData = updateGroupData;

                    let updatedObject = false;

                    if (newUpdateGroupData.length > 0) {
                        newUpdateGroupData.forEach((e, key) => {
                            if (newUpdateGroupData[key].roleId === rowData.roleId) {
                                newUpdateGroupData[key] = rowData;
                                updatedObject = true;
                            }
                        });

                        if (!updatedObject) {
                            newUpdateGroupData.push(rowData);
                        }
                    } else {
                        newUpdateGroupData.push(rowData);
                    }

                    _this.setState({
                        updateGroupData: newUpdateGroupData,
                        isModified: true
                    });
                }
            });

            // Start: Update user status
            userGroupView.on('change', 'select', function(e) {
                const _me = $(this);
                const value = (_me.val() === 'true');
                const rowId = _me.data('row');
                const rowData = _this.userGroupGrid.getrowdata(rowId);

                _this.userGroupGrid.setcellvalue(rowId, 'enabled', value);

                console.log('Onchange rowdata', _this.userGroupGrid.getrowdata(rowId));
                _this.setState({
                    rowId: rowId,
                    roleid: rowData.roleId,
                    groupStatus: value
                });

                if (value) { // If group status is Enable
                    _this.updateUserGroupStatus();
                } else { // If group status is Disable
                    const req = { id: rowData.roleId };

                    AdminServices.isUserMapped(req).then((response) => {
                        const res = response.data;

                        if (res) {
                            _this.setState({ isCancelModalOpen: true });
                        } else {
                            _this.updateUserGroupStatus();
                        }
                    }).catch((error) => {
                        console.log('UserGroup updateUserGroupStatus API call failed', error);

                        NotificationManager({
                            message: 'Failed to update usergroup status',
                            type: 4,
                        });
                    });
                }
            });

            //End: Update user status
        }

        if (this.gridData) {
            if (!this.state.isLoaded && this.gridData.columns.length > 0) {
                this.updateCancelChanges();
            }
        }
    }

    /** adds the passed object to jqwidget
     * @param {object} obj
     */
    addNewGroup(obj) {
        this.userGroupGrid.addrow(obj.roleId, obj);
    }

    /** converting response object to required format for jqWidgets
     * @param {Array} data array of objects
     */
    parseForJqwidget(data) {
        const _this = this;

        // first level loop
        data.forEach(function(item, index) {
            const temp = item.views;

            // getting the child objects inside views array to parent
            temp.forEach(function(list, index) {
                for (const i in list) {
                    if (list.hasOwnProperty(i) && i !== _this.constants.backendViewId) {
                        item[i] = {};
                        item[i][_this.constants.status] = list[i];
                        item[i][_this.constants.viewId] = list[_this.constants.backendViewId];
                        item[i][_this.constants.isViewObject] = list[_this.constants.isViewObject];
                    }
                }
            });
        });
    }

    /** convert objects cloned from jqwidget to backend
     * @param {Array} data array of objects
     * @param {Array} keysToConvert array of strings
     * @return {object}
     */
    parseForBackend(data, keysToConvert) {
        const _this = this;
        const dataForBackend = [];

        data.forEach(function(item) {
            const newItem = Object.assign({}, item);

            // creating an array views and pushing back the objects with keys in array keysToConvert
            const temp = newItem[_this.constants.views] = [];

            _this.keysToConvert.forEach(function(stg) {
                if (newItem[stg] && newItem[stg][_this.constants.status]) {
                    const obj = {};
                    obj[stg] = newItem[stg][_this.constants.status];
                    obj[_this.constants.backendViewId] = newItem[stg][_this.constants.viewId];
                    obj[_this.constants.isViewObject] = newItem[stg][_this.constants.isViewObject];

                    temp.push(obj);
                }
            });

            dataForBackend.push(newItem);
        });

        return dataForBackend;
    }

    /** generates the array of strings, which contains the keys from view object
     * @param {object} data expects the first object views array
     * @return {object}
     *
     */
    getKeysToConvert(data) {
        const _this = this;
        const keysToConvert = [];

        data.forEach(function(item, index) {
            for (const i in item) {
                if (item.hasOwnProperty(i) && i !== _this.constants.backendViewId && i !== _this.constants.isViewObject) {
                    keysToConvert.push(i);
                }
            }
        });

        return keysToConvert;
    }

    /**
     * Opens the modal
     * @param {*} key
     */
    modalOpen(key) {
        if (key === 'edit') {
            this.setState({ isEditModalOpen: true });
        } else {
            this.setState({ isCancelModalOpen: true });
        }
    }

    /**
     * Closes the modal
     * @param {*} key
     */
    modalClose(key) {
        this.setState({ isEditModalOpen: false });
        this.setState({ isCancelModalOpen: false });

        switch (key) {
            case 'save':
                this.updateUserGroupStatus();
                break;

            case 'no':
                this.userGroupGrid.setcellvalue(this.state.rowId, 'enabled', !this.state.groupStatus);
                break;
        }
    }

    /**
     * Append new row once save user gets success
     * @param {*} req
     */
    updateGridRow(req) {
        const data = req;

        this.userGroupGrid.updaterow(data.uid, data);
    }

    /**
     * Update UserGroup Status
     */
    updateUserGroupStatus() {
        const req = {
            userId: this.state.roleid,
            status: this.state.groupStatus
        };

        AdminServices.updateUserGroupStatus(req).then((response) => {
            const res = response;

            if (res.responseStatus) {
                NotificationManager({
                    message: 'User Group status has been changed successfully',
                    type: 1,
                });
            }
        }).catch((error) => {
            console.log('UserGroup updateUserGroupStatus API call failed', error);
            NotificationManager({
                message: 'Failed to update status',
                type: 4,
            });
        });
    }

    /**
     * Cancels the changes
     */
    cancelChanges() {
        this.setState({
            isLoaded: false,
            isModified: false
        });
    }

    /**
     * rerender the grid data after clicks on Cancel Changes
     */
    updateCancelChanges() {
        const base = this;

        AdminServices.getUserGroupsList().then((response) => {
            const res = response;

            if (res.responseCode === 0) {
                base.parseForJqwidget(res.data);
                base.gridData = GridDataBuilder(res, 'userGroup');

                base.setState({
                    isLoaded: true,
                    userGroupList: res.data,
                    preUserGroupDataList: res.data,
                    userTotalCount: res.data.length
                });

                base.keysToConvert = base.getKeysToConvert(res.data[0][base.constants.views]);
            }
        }).catch((error) => {
            console.log('UserGroup updateCancelChanges API call failed', error);

            NotificationManager({
                message: 'Failed to update status',
                type: 4,
            });
        });
    }

    /**
     * Calls backend to save changes
     */
    applyChanges() {
        const _this = this;
        const groupData = this.state.updateGroupData;

        if (groupData.length > 0) {
            const req = this.parseForBackend(groupData);

            AdminServices.updateGroupPermissionList(req).then((response) => {
                const data = response;

                if (data.status) {
                    NotificationManager({
                        message: 'User Group has been updated successfully!!!',
                        type: 1,
                    });

                    _this.setState({ isModified: false });
                } else {
                    NotificationManager({
                        message: data.responseMessage,
                        type: 4,
                    });

                    _this.setState({
                        isDirty: true,
                        errorMessage: data.responseMessage
                    });
                }
            }).catch((error) => {
                console.log('UserGroup applyChanges API call failed', error);

                NotificationManager({
                    message: 'Failed to update status',
                    type: 4,
                });
            });
        } else {
            NotificationManager({
                message: 'Changes has not been affected',
                type: 4,
            });
        }
    }

    /**
     * Append new row once save user gets success
     * @param {*} req
     */
    addGroupRow(req) {
        const data = req;
        data.status = true;
        data.fullName = data.firstName + ' ' + data.lastName;
        data.userId = '';

        this.userGrid.addrow(null, data);
    }

    render () {
        const footerHeight = 50;
        const headerHeight = 64;

        $('.page-title a').text("ADMIN: User Views");

        const gridHeight = window.innerHeight - footerHeight - headerHeight;

        const editSettings = {
            saveOnPageChange: true,
            saveOnBlur: true,
            saveOnSelectionChange: true,
            cancelOnEsc: true,
            saveOnEnter: true,
            editSingleCell: true,
            editOnDoubleClick: true,
            editOnF2: true,
        };

        const groupEditPopupData = JSON.stringify(this.groupEditPopupData);

        return (
            <UserGroupComponent
                state= { this.state }
                props= { this.props }

                userGroupGridReference={(grid) => {
                    this.userGroupGrid = grid;
                }}
                gridData= {this.gridData}
                gridHeight= {gridHeight}
                editSettings= {editSettings}

                modalClose= {this.modalClose}
                groupEditPopupData= {groupEditPopupData}
                keysToConvert= { this.keysToConvert }
                parseForBackend= { this.parseForBackend }
                updateGridRow= { this.updateGridRow }

                applyChanges= {this.applyChanges}
                cancelChanges= {this.cancelChanges}
                addGroupRow= {this.addGroupRow}
                addNewGroup= {this.addNewGroup}
            />
        );
    }
}

export default UserGroup;
