import React, { Component } from 'react';
import RecentMessage from './helperComponents/recentMessageDropDown';
import RolesDropDown from './helperComponents/rolesDropDown';
import Notifier from './notifierComponent';
import PropTypes from 'prop-types';
import HeaderFilters from './headerFilter/headerFilter';
import HelpBugs from '../common/help/helpBugs';

const PageHeaderComponent = ({
    state,
    props,
    toggleRecentMessage,
    toggleRolesDropDown,
    enableFullScreen,
    currentPage,
    currentPageTitle,
}) => {
    return (
        <div className="content-wrapper">
            <header>
                <div className="page-header-logo">
                    <img src={require("../common/icons/SweftLogo-horizontal.svg")} alt="" />
                </div>
                <div className="page-title">
                    <h2><a>{currentPageTitle}</a></h2>
                </div>

                <nav className="navbar top-navbar">
                </nav>

                <div className="collapse navbar-collapse">
                    { ($.inArray(currentPage, ['product']) != -1) &&
                    <HeaderFilters user={props.user} currentPage='product' />
                    }

                    { ($.inArray(currentPage, ['product2']) != -1) &&
                    <div>
                        <div className='help-bugs-page-header'>
                            <HelpBugs section={'views'} currentPage='product2' />
                        </div>
                        <HeaderFilters user={props.user} currentPage='product2' />
                    </div>
                    }


                    { ($.inArray(currentPage, ['marketing']) != -1) &&
                    <HeaderFilters user={props.user} currentPage='marketing' />
                    }

                    { ($.inArray(currentPage, ['publication']) != -1) &&
                    <HeaderFilters user={props.user} currentPage='publication' />
                    }

                    <ul>
                        { (($.inArray(currentPage, ['dashboard', 'layout']) != -1) && state.allRolesList) &&
                        <li className="based-on-me" >
                        </li>
                        }
                        <li>
                            <Notifier
                                user={props.user}
                                setCurrentPage={props.setCurrentPage}
                                leftDrawerPulled={props.leftDrawerPulled}
                                toggleLeftDrawer={props.toggleLeftDrawer}
                                {...props}
                            />
                        </li>
                        <li className="expand-screen-parent">
                            <a href="#" alt="" className='expand-screen' onClick={enableFullScreen}>
                                <i className={state.fullScreen ? 'icon-collapse' : 'icon-expand'}></i>
                            </a>
                        </li>
                    </ul>

                </div>
            </header>
        </div>
    );
};

PageHeaderComponent.propTypes = {
    state: PropTypes.object,
    props: PropTypes.object,
    toggleRecentMessage: PropTypes.func,
    enableFullScreen: PropTypes.func,
    currentPage: PropTypes.string,
};

export default PageHeaderComponent;
