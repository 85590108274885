/*column width declaration
*/
var columnWidth = {
    alertName: 200
};

var alertNameCellrender = function (row, columnfield, value, rowdata) {
    var val = (value) ? value : '';
    return ('<div class="jqx-grid-cell-left-align edit-alertName" data-rowid ="' +
    row + '">' + value + '</div>');
};

var FieldorderRenderer = function (row, columnfield, value, rowdata) {
    var val = (value) ? value : '';
    return ('<div class="jqx-grid-cell-left-align edit-fieldOrder" data-rowid ="' +
    row + '">' + value + '</div>');
};

/* DropDown list creator functions
*/

var DropDownEditor = function (row, value, editor) {
    // assign a new data source to the dropdownlist.
    var list = ["Read", "Write", "Hide"];
    editor.jqxDropDownList({
        autoDropDownHeight: true,
        source: list,
        width: '120px'
    });
};

var DropDownUpdater = function (row, column, columntype, oldvalue, newvalue) {
    // return the old value, if the new value is empty.
    if (newvalue == "") return oldvalue;
    return newvalue;
};

var DropDownInitRenderer = function (row, datafield, value, rowData) {
    if (value === "R") {
        value = "Read";
    } else if (value === "W") {
        value = "Write";
    } else if (value === "H") {
        value = "Hide";
    }
    return "<div class='jqx-grid-cell-left-align' style='margin-top:6px'>" +
    value + "</div>";
};

var orderingRenderer = function (row, datafield, value, rowData) {
    return "<div class='ordering'>" + value + "</div>";
};
/* Grid data builder is used to create the object format required for
 * Jqwidgets.
*/
function AlertsDataBuilder(res, pageName) {
    // dataField is used for providing type of data and its name.
    // columnList is used to define column name, type, and extra fields
    //  to define additional plugins inside the cell eg: drop down, calender ...,
    var dataField = [],
        columnList = [];
    var primayKey = res.columnDetails[0].fieldName;

    //sort columndetails in product pageName
    res.columnDetails.sort(function (a, b) {
        return a.order - b.order;
    });

    // check for the type of fieldname future
    for (var i = 0; i < res.columnDetails.length; i++) {
        dataField.push(dataFieldObj(res.columnDetails[i]));

        /* Enable the below condition after pk(primary key) availabel from backend
    *  in JSON response.
    */

        if (res.columnDetails[i].hasOwnProperty("pk") && res.columnDetails[i].pk) {
            primayKey = res.columnDetails[i].fieldName;
        }

        columnList.push(columnObj(res.columnDetails[i]));
    }

    function dataFieldObj(list) {
        var obj = {};

        obj.name = list.fieldName;
        obj.type = list.type.toLowerCase();

        if (list.hasOwnProperty('map')) {
            obj.map = list.map;
        }

        return obj;
    }
    /* columnobj function will  return the object fields
  *  based on the field type in list.
  * e.g. for date type and other types need to build the cellrender function.
  */
    function columnObj(list) {
        var obj = {};

        //obj.text = list.columnTitle;
        obj.text = '<span class="pd-drop" data-field="' + list.fieldName + '" title="' + list.columnTitle + '" >' + list.columnTitle + '</span>';
        obj.dataField = list.fieldName;
        obj.width = (columnWidth[list.fieldName]) ? columnWidth[list.fieldName] : 150;
        obj.hidden = !list.visibility;

        if (list.fieldName === 'alertName') {
            obj.cellsrenderer = alertNameCellrender;
            obj.editable = false;
            obj.pinned = true;
            obj.sortable = true;
        }
        else if (list.type === "dropdown") {
            obj.columntype = 'dropdownlist';
            obj.createeditor = DropDownEditor;
            obj.cellvaluechanging = DropDownUpdater;
            obj.cellsrenderer = DropDownInitRenderer;
        }
        else if (pageName === "fieldpermission") {
            if (list.fieldName == "displayName" || list.fieldName == "order") {
                obj.pinned = true;
            }
        }
        else if (list.fieldName === "order") {
            // obj.cellsrenderer = orderingRenderer;
            obj.editable = true;
            obj.cellsrenderer = FieldorderRenderer;
        }

        return obj;
    }

    function parsedData() {
        var data = [].concat(res.data),
            parseableFieldName = res.parseField || 'roleName';

        var newData = data.map((item, index) => {
            item[parseableFieldName] = JSON.parse(item[parseableFieldName]);
            return item;
        });

        return newData;
    }
    var finalData = parsedData();


    function customsortfunc(column, direction) {
        var records = Object.assign([], finalData);

        if ((column === 'alertName')) {
            if (direction == 'asc') direction = true;
            if (direction == 'desc') direction = false;
            if (direction != null) {
                if (!direction) {
                    records.sort(function (a, b) {
                        return (a.alertName === undefined) - (b.alertName === undefined) || +(a.alertName < b.alertName) || -(a.alertName > b.alertName);
                    });
                } else {
                    records.sort(function (a, b) {
                        return (a.alertName === undefined) - (b.alertName === undefined) || +(a.alertName > b.alertName) || -(a.alertName < b.alertName);
                    });
                }
            }
            this._source.localdata = records;
            $(".jqx-grid").jqxGrid('updatebounddata', 'sort');
        } else {
            if (direction == 'asc') direction = true;
            if (direction == 'desc') direction = false;
            if (direction != null) {
                if (!direction) {
                    records.sort(function (a, b) {
                        return (a.roleName[column] === undefined) - (b.roleName[column] === undefined) || +(a.roleName[column] < b.roleName[column]) || -(a.roleName[column] > b.roleName[column]);
                    });
                } else {
                    records.sort(function (a, b) {
                        return (a.roleName[column] === undefined) - (b.roleName[column] === undefined) || +(a.roleName[column] > b.roleName[column]) || -(a.roleName[column] < b.roleName[column]);
                    });
                }
            }
            this._source.localdata = records;
            $(".jqx-grid").jqxGrid('updatebounddata', 'sort');
        }
    }

    var source = {
        dataType: "json",
        dataFields: dataField,
        id: primayKey,
        loadonce: true,
        // localdata: finalData,
        localdata: finalData
    };

    if (pageName !== 'fieldpermission') {
        source.sort = customsortfunc;
    }

    var dataAdapter = new $.jqx.dataAdapter(source);

    return {
        dataAdapter: dataAdapter,
        columns: columnList
    };
}


//   function customsortfunc(column,direction){
//      var records = Object.assign([],finalData);
//      if (direction == 'asc') direction = true;
//      if (direction == 'desc') direction = false;
//      if (direction != null) {
//         if (!direction) {
//           records.sort(function(a, b) {
//             return (a.roleName[column]===undefined)-(b.roleName[column]===undefined) || +(a.roleName[column]<b.roleName[column])||-(a.roleName[column]>b.roleName[column]);
//            });
//         }else{
//           records.sort(function(a, b) {
//             return (a.roleName[column]===undefined)-(b.roleName[column]===undefined) || +(a.roleName[column]>b.roleName[column])||-(a.roleName[column]<b.roleName[column]);
//            });
//         }
//     }
//     this._source.localdata = records;
//     $(".jqx-grid").jqxGrid('updatebounddata', 'sort');
//   }
//   var source = {
//     dataType:"json",
//     dataFields:dataField,
//     id:primayKey,
//     localdata: finalData
//   };

//   if (pageName !== 'fieldpermission') {
//     source.sort = customsortfunc;
//   }

//   var dataAdapter = new $.jqx.dataAdapter(source);

//   return {
//     dataAdapter:dataAdapter,
//     columns:columnList
//   };
// }

export default AlertsDataBuilder;
