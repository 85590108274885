import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Modal from '../../modals/common-modal.js';
import * as utils from '../../common/utils.js';
import axios from 'axios';

var constants = require('../../common/constants.js');
// var NotificationManager = require('../../common/notification');
import NotificationManager from '../../common/components/notification';


class ImageApprovalModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            mainImageSrc:"",
            imageId: "",
            imageData: {},
            notes: "",
            isLoaded: false,
            isImageLoaded: false,
            isRejectOrApprove: '',
            isRejectedData: false,
            isSubmitDisabled: true,
            accessFlag: false,
            errorMessage: '',
            imageCheckedCount: 1
        };

        this.parentId = [];
        this.updateMainViewer = this.updateMainViewer.bind(this);
        this.changeImageStatus = this.changeImageStatus.bind(this);
        this.changeImageCheckbox = this.changeImageCheckbox.bind(this);
        this.handleNotes = this.handleNotes.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        let _this = this;
        let req = _this.props.parentId;

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };
        axios({
            method: 'post',
            url: require('../../common/configurations.js').creative.getAcceptedImagesByProductId,
            headers: headers,
            data: req
        })
            .then( (response) => {
                console.log('productContainer helperComponents image-approval API call success data: ', response.data);
                let res = response.data;

                if(res.status){
                    _this.parentId = res.parentId;
                    if (res.imgData.length > 0) {
                        let imageList = Object.assign([], res.imgData);
                        imageList.map((val, key) => { // For ChildData
                            imageList[key].checked = true;
                            imageList[key].comments = '';
                        });

                        _this.setState({imageId: 0,
                            isImageLoaded: true,
                            imageData: imageList,
                            mainImageChecked: res.imgData[0].checked,
                            mainImageSrc: res.imgData[0].fnalImageUrl + (res.imgData[0].isBuyer ? "" : "?w=" + constants.IMGMAIN.width + "&h=" + constants.IMGMAIN.height),
                            isLoaded: true,
                            accessFlag: res.accessFlag,
                            isBuyerImg: res.imgData[0].isBuyer
                        });
                    } else {
                        _this.setState({errorMessage: "No image"});
                    }
                } else {
                    _this.setState({errorMessage: res.responseMessage});
                }
            })
            .catch( error => {
                console.log('productContainer helperComponents image-approval API call failed', error);
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isRejectedData) {
            $('#rejectNotesField').focus();
        }
    }

    updateMainViewer(val) {
        var currentSrc = this.state.imageData[val].fnalImageUrl + (this.state.imageData[val].isBuyer ? "" : "?w=" + constants.IMGMAIN.width + "&h=" + constants.IMGMAIN.height);
        this.setState({
            imageId: val,
            mainImageSrc: currentSrc,
            mainImageChecked: this.state.imageData[val].checked,
            isBuyerImg: this.state.imageData[val].isBuyer
        });
    }

    changeImageStatus(event) {
        const target = event.target;
	  	const value = target.type === 'checkbox' ? target.checked : target.value;
	  	const data = this.state.imageId;

        let {imageData} = this.state;
        let newImageData = Object.assign([], imageData);

        newImageData[data].checked = value;

        this.setState({imageData: newImageData, mainImageChecked: value});

        let imageUncheckedCount = 0;
        imageData.map((val, key) => {
            if (!val.checked) {
                imageUncheckedCount++;
            }
        });
        if (imageUncheckedCount == 0) {
            this.setState({isRejectedData: false});
        }
        this.setState({imageCheckedCount: (newImageData.length === imageUncheckedCount) ? 0 : 1});
    }

    changeImageCheckbox(event) {
        const target = event.target;
	  	const value = target.type === 'checkbox' ? target.checked : target.value;
	  	const data = $(target).data('value');

        let {imageData} = this.state;
        let newImageData = Object.assign([], imageData);

        newImageData[data].checked = value;

        this.setState({imageData: newImageData, mainImageChecked: value});

        // If all images are checked -
        let imageUncheckedCount = 0;
        imageData.map((val, key) => {
            if (!val.checked) {
                imageUncheckedCount++;
            }
        });

        if (imageUncheckedCount == 0) {
            this.setState({isRejectedData: false});
        }

        this.setState({imageCheckedCount: (newImageData.length === imageUncheckedCount) ? 0 : 1});
    }


    rejectOrApprove(key) {
        let _this = this;
        let {imageData} = this.state;

        let imageUncheckedCount = 0;
        imageData.map((val, key) => {
            if (!val.checked) {
                imageUncheckedCount++;
            }
        });


        if (key === 'approve') { // Approve
            if (imageUncheckedCount > 0) {
                _this.setState({isRejectedData: true, isRejectOrApprove: key});
                let errormsg = "Some images have been rejected. Please provide the notes in the below section for studio reference and submit.";
                NotificationManager({
                    message:errormsg,
                    type:4
                });
            } else {
                _this.setState({isRejectedData: false});
                _this.updateService(key);
            }
        }

        if (key === 'reject') { // Reject
            _this.setState({isRejectedData: true, isRejectOrApprove: key});
        }
    }

    handleSubmit(event) {
        this.updateService();
    }

    updateService(key) {
        let _this = this;
        let req = {
            parentId: _this.parentId,
            chldId: this.props.ppidLst,
            approveORreject: (key != undefined) ? key : _this.state.isRejectOrApprove,
            data: this.state.imageData,
            userId: this.props.user.userId
        };


        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };
        axios({
            method: 'post',
            url: require('../../common/configurations.js').creative.acceptBuyerResult,
            headers: headers,
            data: req
        })
            .then( (response) => {
                console.log('productContainer helperComponents image-approval API call success data: ', response.data);
                let res = response.data;

                if(res.status){
                    _this.props.modalClose();
                    NotificationManager({
                        message:'Approve status has been successfully updated.',
                        type:1
                    });
                } else {
                    NotificationManager({
                        message:'Failed to update status.',
                        type:4
                    });
                }
            })
            .catch( error => {
                console.log('productContainer helperComponents image-approval API call failed', error);
                NotificationManager({
                    message:'Service error ' + error,
                    type:4
                });
            });
    }

    handleNotes(event) {
        this.setState({notes: event.target.value});

        if (event.target.value.length > 0) {
            this.setState({isSubmitDisabled: false});
        } else {
            this.setState({isSubmitDisabled: true});
        }

        let {imageData} = this.state;
        let newImageData = Object.assign([], imageData);
        newImageData.map((val, key) => {
            newImageData[key].comments = event.target.value;
        });

        this.setState({imageData: newImageData});
    }

    render(){
        var disableEditing = false;
        if(window.readableFields && window.readableFields.indexOf('swatchOnly') > -1){
            disableEditing = true;
        }

        return (
            <Modal isOpen={this.props.isModalOpen}>
                <div className="modal-header">
            Pictures
                    <span className="close" onClick={this.props.modalClose}>x</span>
                </div>
                <div className="modal-content product-pictures">
                    { this.state.isLoaded &&
                <div className="modal-form">
                    <div className="vertical-image-holder form-container scroll">
                        <div className="thumbnail-holder scroll">
                            <ul>
                                {this.state.imageData.map((item, index) =>(
                                    <li className="thumbnails" key={index}>
                                        <div className={"thumbnail-image " + (item.isBuyer ? "" : "dam")}>
                                            <img src={item.imageUrl + (item.isBuyer ? "" : "?w=" + constants.IMGTHUMBNAIL.width + "&h=" + constants.IMGTHUMBNAIL.height)} onClick={() =>(this.updateMainViewer(index))}/>
                                            {(!item.isBuyer) ? <input type="checkbox" checked={item.checked} className="image-checkbox" data-value={index} onChange={this.changeImageCheckbox}/> : ''}
                                        </div>
                                    </li>
                                ))
                                }
                            </ul>
                        </div>
                        <div className="main-image">
                            <div className={"image-container " + (this.state.isBuyerImg ? "" : "dam")}>
						    <img src={this.state.mainImageSrc} />
						    {(!this.state.isBuyerImg) ? <input type="checkbox" checked={this.state.mainImageChecked} data-value={this.state.imageId} className="image-checkbox" onChange={this.changeImageStatus}/> : ''}
                            </div>
                            {!this.state.isBuyerImg &&
                            <div className={"modal-footer clearfix"}>
                                {this.state.accessFlag && !disableEditing &&
                                <div className="images-action mb-10">
                                    <input type="button" className="gray-button" value="Reject" disabled={(this.state.imageCheckedCount == 0) ? true : false} onClick={() =>this.rejectOrApprove('reject')}/>
                                    <input type="button" className="gray-button" value="Approve" disabled={(this.state.imageCheckedCount == 0) ? true : false} onClick={() =>this.rejectOrApprove('approve')}/>
                                </div>
                                }
                                {this.state.isRejectedData && !disableEditing &&
                                <div className="comment-section modal-form col-12">
                                    <div className="form-group">
                                        <label className="form-label">Review Notes</label>
                                        <textarea className="form-control texrarea-fld" id="rejectNotesField" value={this.state.notes} onChange={this.handleNotes}></textarea>
                                    </div>
                                    <div className="form-group text-center">
                                        <input type="button" className="gray-button" disabled={this.state.isSubmitDisabled} value="Submit" onClick={this.handleSubmit}/>
                                    </div>
                                </div>
                                }
                            </div>
                            }
                        </div>

                    </div>

                </div>
                    }

                    {!this.state.isLoaded &&
                    <span className="error-message">{this.state.errorMessage}</span>
                    }
                </div>
            </Modal>
        );
    }
}

export default ImageApprovalModal;
