import React from 'react';
import ConfirmModal from '../../common/modals/confirmModal';

class ConfirmDeleteModal extends React.Component {
    constructor(props) {
        super(props);

        this.closeConfirmModal = this.closeConfirmModal.bind(this);
    }

    closeConfirmModal(key) {
        this.props.modalClose(key);
    }

    render() {
        return (<ConfirmModal isOpen={this.props.isOpen}>
            <div className="modal-content">
                <h3 className="confirm-warning-text">Are you sure you want to delete the selected field(s)?</h3>
                <div className="modal-footer">
                    <input type="button" className="gray-button" value="No" onClick={() => this.props.modalClose('no')}></input>
                    <input type="button" className="gray-button" value="Yes" onClick={() => this.closeConfirmModal('delete')}></input>
                </div>
            </div>
        </ConfirmModal>);
    }
}

export default ConfirmDeleteModal;
