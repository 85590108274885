import React, { Component } from 'react';

class DeleteConfirm extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let modalHead = '';
        let content = null;

        if (this.props.modalName === 'ConfirmDelete') {
            modalHead = 'Confirm Delete';
            content = <ConfirmDelete {...this.props}/>;
        } else {
            modalHead = 'Save Changes';
            content = <SaveChanges {...this.props}/>;
        }

        return (
            <div>
                <div className="modal-content text-center">
                    {content}
                </div>
            </div>
        );
    }
}

/**
 * Renders the save changes popup
 * @param {*} props
 * @return {object}
 */
function SaveChanges(props) {
    return (
        <div className="add-copy modal-form">
            <form className="confirmdelete" name="confirmdelete">
                <div className="form-container scroll">
                    <p>Do you want to Save Changes?</p>
                </div>
                <div className="modal-footer text-center">
                    <input
                        type="button"
                        className="gray-button"
                        name="no"
                        value="No"
                        onClick={props.noSaveChanges} />
                    <input
                        type="button"
                        className="gray-button"
                        name="yes"
                        value="Yes"
                        onClick={props.yesSaveChanges} />
                </div>
            </form>
        </div>
    );
}

/**
 * Renders the ConfirmDelete popup
 * @param {*} props
 * @return {object}
 */
function ConfirmDelete(props) {
    return (
        <div className="add-copy modal-form">
            <form className="confirmdelete" name="confirmdelete">
                <div className="form-container scroll">
                    <p>Are you sure on deleting the rule?</p>
                </div>
                <div className="modal-footer text-right">
                    <input
                        type="button"
                        className="gray-button"
                        name="noDelete"
                        value="No"
                        onClick={props.modalClose} />
                    <input
                        type="button"
                        className="gray-button"
                        name="yesDelete"
                        value="Yes"
                        onClick={props.deleteRules} />
                </div>
            </form>
        </div>
    );
}

export default DeleteConfirm;
