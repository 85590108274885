import React from 'react';
import axios from 'axios';
import { ProductServices } from '../services/product-services';
import NotificationManager from '../../common/components/notification';
import { checkHeight } from './utils';

export class GeneralFileUploadEditor extends React.Component {
    constructor() {
        super();

        this.state = {
            selectedFile: null,
            currentFile: null,
        };
    }

    componentDidMount() {
        if (this.props.value) {
            this.setState({ currentFile: this.props.value ? this.props.value : null });
        }

        checkHeight(this.props);
    }

    closePopup() {
        this.props.api.stopEditing();
    }

    onFileChange(e) {
        console.log(e.target.files[0], e.target.files);
        this.setState({ selectedFile: e.target.files[0] });
    }

    uploadFile() {
        let obj = {};

        obj['fileName'] = this.state.selectedFile.name;
        obj['productId'] = this.props.node.data.productId;
        obj['fieldName'] = this.props.column.colDef.field;

        let formData = new FormData();

        // Update the formData object
        formData.append('file', this.state.selectedFile);
        formData.append('jsonString', JSON.stringify(obj));

        ProductServices.uploadFiles(formData).then((res) => {
            console.log('upload success data', res);

            const resp = res.data;

            if (res.data) {
                NotificationManager({ message: resp.message, type: 1 });

                this.setState({
                    currentFile: resp.responseData.fileName,
                    selectedFile: null,
                });
            }
        }).catch((err) => {
            console.log('upload fail data', err);
        });
    }

    downloadFile(e) {
        e.preventDefault();
        let productId = this.props.node.data.productId;
        let fieldName = this.props.column.colDef.field;

        let url = require('../../common/configurations').product2.downloadFile;

        let endUrl = url + "productId=" + productId + "&fieldName=" + fieldName;
        window.open(endUrl);
    }

    getValue() {
        return this.state.currentFile;
    }

    isPopup() {
        return true;
    }

    render() {
        console.log('InstructionsFileUploadEditor render', this.state);
        const colName = this.props.column.colId;

        return(
            <div className="message-editor-container general-file-upload">
                <div className="message-editor-header">
                    <div className="modal-header">{colName.toUpperCase()}</div>
                    <span className="close" onClick={this.closePopup.bind(this)}>x</span>
                </div>
                <div className="message-editor-body">
                    <div className='upload-section'>
                        <div className='top-sec'>
                            <form id="general-file-upload" className='file-upload' encType="multipart/form-data">
                                <label htmlFor="file-upload" className="custom-file-upload">
                                    {this.state.currentFile ? 'Replace File' : 'Choose File'}
                                </label>
                                <div htmlFor="file-upload" className="selected-file">
                                Selected File:
                                </div>
                                {
                                    this.state.selectedFile &&
                                <div className='uploaded-file'>{this.state.selectedFile.name}</div>
                                }
                                <input id="file-upload" name='file-upld' type="file" style={{display: 'none'}} onChange={this.onFileChange.bind(this)} />
                            </form>
                            <button htmlFor="file-upload"
                                disabled={!this.state.selectedFile ? true : false}
                                onClick = {this.uploadFile.bind(this)}
                                className={`custom-file-upload ${(!this.state.selectedFile ? 'grey' : '')}`}>
                            Upload File
                            </button>
                        </div>
                        <div className='bottom-sec'>
                            <form id="general-file-upload" className='file-upload' encType="multipart/form-data">
                                <button htmlFor="file-upload"
                                    disabled={!this.state.currentFile ? true : false}
                                    onClick = {this.downloadFile.bind(this)}
                                    className={`custom-file-upload ${(!this.state.currentFile ? 'grey' : '')}`}>
                                Download File
                                </button>
                                <div htmlFor="file-upload" className="selected-file">
                                Current File:
                                </div>
                                <div className='uploaded-file'>{this.state.currentFile}</div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export class GeneralFileUploadRenderer extends React.Component {
    render() {
        const IconDisplay = <div className="ag-popupIndicator"><img src={require('../../common/icons/Expand Grid.svg')} /></div>;

        return (
            <React.Fragment>
                {
                    this.props.value ?
                        this.props.value
                        :
                        IconDisplay
                }
            </React.Fragment>
        );
    }
}
