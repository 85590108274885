import React from 'react';
import moment from 'moment';
import NotificationManager from '../../common/components/notification';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate} from 'react-day-picker/moment';
import DayPicker from 'react-day-picker';

export class DateRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const rowData = this.props.node.data;
        let dateValue;

        if (this.props.column.colDef.field == 'dateAdded') {
            dateValue = rowData && rowData[this.props.column.colDef.field] ? moment(new Date(rowData[this.props.column.colDef.field])).format('MM/DD/YYYY') : moment(new Date()).format('MM/DD/YYYY');
        } else if (this.props.column.colDef.headerName == 'Ecom Po Due Date') { // added for invaild date issue coming from the add po popup
            if (this.props.node.data.ecomPoDueDate) { // added because backend sends back ecomPoDueDate and retailPoDueDate as poDueDate!!
                dateValue = moment(new Date(this.props.node.data.ecomPoDueDate)).format('MM/DD/YYYY');
            } else if (this.props.node.data.poDueDate) {
                dateValue = moment(new Date(this.props.node.data.poDueDate)).format('MM/DD/YYYY');
            } else {
                dateValue = '';
            }
        } else if (this.props.column.colDef.headerName == 'Retail Po Due Date') { // added for invaild date issue coming from the add po popup
            if (this.props.node.data.retailPoDueDate) { // added because backend sends back ecomPoDueDate and retailPoDueDate as poDueDate!!
                dateValue = moment(new Date(this.props.node.data.retailPoDueDate)).format('MM/DD/YYYY');
            } else if (this.props.node.data.poDueDate) {
                dateValue = moment(new Date(this.props.node.data.poDueDate)).format('MM/DD/YYYY');
            } else {
                dateValue = '';
            }
        } else if (typeof (this.props.value) == 'string') {
            dateValue = this.props.value;
        } else {
            dateValue = rowData && rowData[this.props.column.colDef.field] ? moment(new Date(rowData[this.props.column.colDef.field])).format('MM/DD/YYYY') : null;
        }

        return (
            <div>
                {dateValue}
            </div>
        );
    }
}

export class DateEditor extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            orgDate: props.value ? new Date(props.value) : null,
            newDate: props.value ? new Date(props.value) : null,
        };

        this.clearDate = this.clearDate.bind(this);
    }

    isPopup() {
        return true;
    }

    isCancelBeforeStart() { }

    isCancelAfterEnd() { }

    afterGuiAttached() { }

    componentWillMount() {
        if (['readyToOrderDate',
            'ecomPoDueDate',
            'retailPoDueDate',
            'sampleApprovedDate',
            'piRequestedDate',
            'piReceivedDate',
            'piApprovedDate',
            'dateAdded',
            'poDueDate',
            'sampleMovedDate',
            'sampleRequestedDate',
            'sampleReceivedDate'].includes(this.props.column.colDef.field)) {
            NotificationManager({ message: 'Sorry this field is not editable', type: 4 });

            this.props.api.stopEditing();
        }
    }

    componentWillUnmount() {
        const rowData = this.props.node.data;
        const d1 = new Date(this.state.orgDate);
        const d2 = new Date(this.state.newDate);
        const getCol = this.props.column.colId;

        if (this.state.newDate) {
            rowData[this.props.column.colDef.field] = d2;
        }

        if ((d1.getTime() !== d2.getTime())) {
            this.props.rowUpdate(rowData, this.props.node.rowIndex);
        }
    }

    getValue() {
        return this.state.newDate ? new Date(this.state.newDate) : "";
    }

    handleOnChange(e) {
        const d = new Date(e.target.value);

        if (e.target.value) {
            this.setState({ newDate: d });
        }
    }

    handleDayClick(day) {
        console.log(day);

        this.setState({ newDate: day }, () => {
            this.props.api.stopEditing();
        });
    }

    clearDate(e) {
        console.log(e);

        this.setState({ newDate: "" }, () => {
            this.props.api.stopEditing();
        });
    }

    render() {
        const rowData = this.props.node.data;
        let dateValue;

        if (this.props.column.colDef.field == 'dateAdded') {
            dateValue = rowData && rowData[this.props.column.colDef.field] ? moment(new Date(rowData[this.props.column.colDef.field])).format('YYYY/MM/DD') : moment(new Date()).format('YYYY/MM/DD');
        } else {
            dateValue = rowData && rowData[this.props.column.colDef.field] ? moment(new Date(this.props.column.colDef.field)).format('YYYY/MM/DD') : '';
        }

        return (
            <React.Fragment>
                <DayPicker
                    className='date'
                    formatDate={formatDate}
                    parseDate={parseDate}
                    placeholder={'MM-DD-YYYY'}
                    selectedDays={this.state.newDate ? this.state.newDate : this.state.orgDate}
                    onDayClick={this.handleDayClick.bind(this)}
                    onTodayButtonClick = {this.clearDate}
                    readOnly={true}
                    todayButton = 'Clear'
                />
            </React.Fragment>
        );
    }
}
