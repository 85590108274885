import React, { Component } from 'react';
import Modal from '../../modals/common-modal.js';
import ConfirmModal from '../../modals/confirm-modal.js';
import { connect } from 'react-redux';
import * as actions from '../redux/productActions';
// import JqxTabs from '../../common/vendor/jqwidgets-react/react_jqxtabs.js';
import axios from 'axios';

var constants = require('../../common/constants.js');
var utils = require('../../common/utils.js');
// var NotificationManager = require('../../common/notification');
import NotificationManager from '../../common/components/notification';

import { ProductServices } from '../../product2/services/product-services';


class ProductOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            isLoaded: false,
            options: [],
            selectedOptionsCount: 0,
            selectedOptionsIndex: [],
            isLastTab: false,
            currentTabindex: 0
        };

        this.createParentChild = this.createParentChild.bind(this);
        this.saveParentChild = this.saveParentChild.bind(this);
        this.modalClose = this.modalClose.bind(this);
    }

    componentDidMount() {
        let _this = this;
        console.log('product-options props: ', this.props);
        // let dropdowns = Object.assign([], this.props.dropdowns);
        let dropdowns = Object.assign([], this.props.dropdowns2); // added for new products grid

        let variants = dropdowns.variants;

        console.log('variants', variants);
        let options = {};

        if (this.props.queryForOptions) {
            let headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json'
            };
            axios({
                method: 'get',
                url: require('../../common/configurations.js').product.getAttributeDropDown + this.props.queryForOptions,
                headers: headers,
                //   data: postdata
            })
                .then((response) => {
                    console.log('productContainer helperComponents product-options API call success data: ', response.data);
                    let res = response.data;

                    if (res.status == true) {
                        for (var keyindex in res.data) {
                            for (var keyindex2 in res.data[keyindex]) {
                                res.data[keyindex][keyindex2].order = keyindex2;
                            }
                        }
                        _this.setState({ options: res.data, isLoaded: true });
                    }
                })
                .catch(error => {
                    console.log('productContainer helperComponents product-options API call failed', error);
                });
        } else {
            variants.map((val, index) => {
                // options[val.toLowerCase()] =  {value: (dropdowns[val.toLowerCase()] != undefined)?dropdowns[val.toLowerCase()]: []}
                options[val.toLowerCase()] = [];
                if (dropdowns[val.toLowerCase()] != undefined) {
                    dropdowns[val.toLowerCase()].map((opt_val, index) => {
                        options[val.toLowerCase()].push({ value: opt_val, status: false, visiblity: true, order: index });
                    });
                }
            });

            this.setState({ options: options, isLoaded: true });
        }
    }

    optionSearch(q, type) {
        let options = this.state.options;

        options[type].map((val, idx) => {
            let nameLowercase = val.value.toLowerCase();
            if (nameLowercase.indexOf(q) > -1) {
                options[type][idx].visiblity = true;
            } else {
                options[type][idx].visiblity = false;
            }
        });

        return options;
    }

    componentDidUpdate(prevprops, prevstate) {
        var _this = this;
        if (prevstate.isLoaded != this.state.isLoaded) {
            let options_tab = $('#options-tab');

            // on change options values - checkbox
            options_tab.off('change');
            options_tab.on('change', '.product-options', function (e) {
                let elem = $(this);
                let option_name = elem.data('option-name');
                let key = elem.data('option-key');
                let tab_index = elem.data('tabindex');
                let checked = (elem.is(':checked')) ? true : false;

                $(".options-list." + option_name + " .row-index-" + key).attr("data-ischecked", checked ? "1" : "0");

                let { options, selectedOptionsCount } = _this.state;
                let newOptions = options;

                newOptions[option_name][key].status = checked;
                // updating selected options to state for cancel modal
                (checked) ? selectedOptionsCount++ : selectedOptionsCount--;

                var list_unchecked = [];
                var list_checked = [];
                newOptions[option_name].map((obj) => {
                    if (obj.status) {
                        list_checked.push(obj);
                    } else {
                        list_unchecked.push(obj);
                    }
                });

                var sortedChecked = list_checked.sort((a, b) => a.order - b.order);
                var sortedUnchecked = list_unchecked.sort((a, b) => a.order - b.order);
                newOptions[option_name] = sortedChecked.concat(sortedUnchecked);

                //let sizeordersort =  newOptions.size.sort(function(a,b){ return a.order-b.order});

                _this.setState({ options: newOptions, selectedOptionsCount: selectedOptionsCount, isLoaded: false });
            });


            //Search
            options_tab.off('keyup');
            options_tab.on('keyup', '.option-search', function (e) {
                let query = $(this).val().toLowerCase();
                let option_type = $(this).data('optionname');
                let tab_index = $(this).data('tabindex');
                var matchedLength = 0;
                setTimeout(function () {
                    $('.options-list.' + option_type + ' .row').hide();
                    // $('.options-list.'+option_type+' .row[data-ischecked="false"]').hide();
                    $('.options-list.' + option_type + ' .row').filter(function (el) {
                        if (query.length > 0) {
                            var pName = isNaN($(this).data('option-value')) ? $(this).data('option-value').toLowerCase() : $(this).data('option-value');
                            // var pName = $(this).data('option-value');
                            if (pName.indexOf(query) > -1) {
                                matchedLength++;
                                console.log(pName);
                                $(this).show();
                            }
                            if (matchedLength <= 0) {
                                $("#option-no-msg-" + tab_index).show();
                            } else {
                                $("#option-no-msg-" + tab_index).hide();
                            }
                        } else {
                            $('.options-list.' + option_type + ' .row').show();
                            $("#option-no-msg-" + tab_index).hide();
                        }
                    });

                    if (_this.state.selectedOptionsCount > 0) {
                        let $wrapper = $('.options-container.tabIndex-' + tab_index + ' .options-list');

                        $wrapper.find('.row').sort(function (a, b) {
                            return +b.dataset.ischecked - +a.dataset.ischecked;
                        }).appendTo($wrapper);
                    }
                    // _this.setState({options: _this.optionSearch(query, option_type), isLoaded: false});
                }, 300);
            });


            if (this.state.isLoaded && !this.state.isProductConfirm) {
                // On click on tabs
                _this.optionTabs.on('tabclick', function (event) {
                    let tab_index = event.args.item;
                    if ((tab_index + 1) == _this.getTabCount()) {
                        _this.setState({ isLastTab: true, currentTabindex: tab_index });
                    } else {
                        _this.setState({ isLastTab: false, currentTabindex: tab_index });
                    }
                });
            }

            if (prevstate.isLoaded && !this.state.isLoaded && !this.state.isProductConfirm) {
                _this.setState({ isLoaded: true });
            }
        }

        //
        // if (prevstate.isProductConfirm != this.state.isProductConfirm) {
        //     let options_tab = $('#options-tab');
        //     options_tab.on('change', '.combined-product-options', function(e) {
        //         let elem = $(this);
        //         let key = elem.data('option-key');
        //         let checked = (elem.is(':checked'))?true:false;

        //         _this.constructingCombinedOptions(key, checked);
        //     });
        // }
    }

    /**
     * Get JqxTabs count - it returns the total count of tabs avail
     */
    getTabCount() {
        return this.optionTabs.length();
    }

    confirmModal() {
        // count of selected index both individual & combined options
        let count = (this.state.isProductConfirm) ? this.state.selectedOptionsIndex.length : this.state.selectedOptionsCount;
        if (count > 0) {
            this.setState({ isModalOpen: true });
        } else {
            this.props.modalClose();
        }
    }

    modalClose(key) {
        this.setState({ isModalOpen: false });
        switch (key) {
            case 'yes':
                this.props.modalClose();
                break;
            case 'cancel':
                alert('asd');
                break;
        }
    }

    /**
     * constructing permutation & combination values to create child products
     * @param {integer} key - Array index of state "combinedOptions"
     * @param {Boolean} checked
     */
    constructingCombinedOptions(key, checked) {
        // let {combinedOptions} = _this.state;
        // let newCombinedOptions = combinedOptions;

        // newCombinedOptions[option_name][key].status = checked;

        // _this.setState({combinedOptions: newCombinedOptions});

        let { selectedOptionsIndex } = this.state;

        if (checked) {
            selectedOptionsIndex.push(key);
        } else {
            if (selectedOptionsIndex.indexOf(key) > -1) {
                selectedOptionsIndex.splice(selectedOptionsIndex.indexOf(key));
            }
        }

        this.setState({ selectedOptionsIndex: selectedOptionsIndex });
    }

    permutationCombination() {
        let options = Object.keys(this.state.options);
        let combinations = {};

        options.map((value) => {
            combinations[value[0].toUpperCase() + value.slice(1)] = [];

            this.state.options[value].map((val, index) => {
                if (val.status) {
                    combinations[value[0].toUpperCase() + value.slice(1)].push(val.value);
                }
            });
        });

        return combinations;
    }

    toggleCombinedOptions(index) {
        let { combinedProducts, selectedCombinedCount } = this.state;
        let newCombinedProducts = combinedProducts;
        let newCount = selectedCombinedCount;

        newCombinedProducts[index].isChecked = !newCombinedProducts[index].isChecked;

        newCount = (newCombinedProducts[index].isChecked) ? newCount + 1 : newCount - 1;

        // this.constructingCombinedOptions(index, newCombinedProducts[index].isChecked);

        this.setState({ combinedProducts: newCombinedProducts, selectedCombinedCount: newCount });
    }


    createParentChild() {
        function cartesian(arg) {
            var r = [],
                // arg = arguments,
                max = arg.length - 1;

            function helper(arr, i) {
                for (var j = 0, l = arg[i].length; j < l; j++) {
                    var a = arr.slice(0); // clone arr
                    a.push(arg[i][j]);
                    if (i == max)
                        r.push(a);
                    else
                        helper(a, i + 1);
                }
            }
            helper([], 0);
            return r;
        }

        let _this = this;
        let combinations = this.permutationCombination();

        // tried multiple combinations
        // combinations = {
        //     Color:["Blue", "Green"],
        //     Size:["XS", "S"],
        //     Fit:["Regular", "Slimfit"]
        // }


        let data = [];

        for (var key in combinations) {
            data.push(combinations[key]);
        }

        let permutation = cartesian(data);

        let allKeys = Object.keys(combinations);
        let data2 = [];

        for (let i = 0; i < permutation.length; i++) {
            let obj = {};
            for (let k = 0, v = 0; k < allKeys.length, v < permutation[i].length; k++, v++) {
                obj[allKeys[k]] = permutation[i][v];
            }
            data2.push(obj);
        }

        console.log('create parent child ', data2);



        let resData = data2;
        let selectedIndex = [];
        resData.map((val, idx) => {
            resData[idx].isChecked = true;
            selectedIndex.push(idx);
        });
        _this.setState({ isProductConfirm: true, combinedProducts: resData, selectedCombinedCount: resData.length, selectedOptionsIndex: selectedIndex, isLoaded: false });

        // let headers = {
        //       'Authorization': this.props.user.token,
        //       'content-type': 'application/json'
        // }
        // axios({
        //   method: 'post',
        //   url: require('../../common/configurations.js').product.createParentChld,
        //   headers: headers,
        //   data: combinations
        // })
        // .then( (response) => {
        //     console.log('productContainer helperComponents footer API call success data: ', response.data)
        //     let res = response.data

        //     if (res.status) {
        //         let resData = res.data;
        //         let selectedIndex = []
        //         resData.map((val, idx) => {
        //             resData[idx].isChecked = true;
        //             selectedIndex.push(idx);
        //         });
        //         _this.setState({isProductConfirm: true, combinedProducts: resData, selectedCombinedCount: resData.length, selectedOptionsIndex: selectedIndex, isLoaded: false});
        //     }

        // })
        // .catch( error => {
        //     console.log('productContainer helperComponents footer API call failed', error)
        //     NotificationManager({message:'Failed to create parent-child',type:4});
        // });
    }


    saveParentChild() {
        let _this = this;
        let combinations = [];

        _this.state.combinedProducts.map((value) => {
            if (value.isChecked) {
                combinations.push(value);
            }
        });

        let productData = this.props.currentRowData;
        // delete productData.data;
        let req = {
            productId: productData,
            data: combinations,
            userRole: this.props.user.defaultRole
        };

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.saveParentChld(req)
            .then((response) => {
                console.log('productContainer helperComponents footer API call success data: ', response.data);
                let res = response.data;

                if (res.status) {
                    // _this.setState({isProductConfirm: true, combinedProducts: res.data});
                    if (_this.props.queryForOptions) {
                        let data = res.childData.concat(res.parentData);
                        _this.props.updateOrReplaceData(data);
                        _this.props.removeClonedList();
                    } else {
                        _this.props.updateParentRow(_this.props.currentRowData.productId, res);
                    }
                    _this.props.modalClose('success');
                } else {
                    NotificationManager({ message: 'Failed to create parent-child', type: 4 });
                }
            })
            .catch(error => {
                console.log('productContainer helperComponents footer API call failed', error);
                NotificationManager({ message: 'Failed to create parent-child', type: 4 });
            });
    }


    editSelection() {
        confirm('Edit Selection');
        this.setState({ isProductConfirm: false, isLoaded: true, isLastTab: false, currentTabindex: 0 });
    }

    tabNext() {
        if ((this.state.currentTabindex + 1) <= this.getTabCount()) {
            this.optionTabs.select(this.state.currentTabindex + 1);

            if ((this.state.currentTabindex + 1) === (this.getTabCount() - 1)) {
                this.setState({ isLastTab: true, currentTabindex: this.state.currentTabindex + 1 });
            }
        }
    }

    tabBack() {
        this.optionTabs.select(this.state.currentTabindex - 1);
        this.setState({ isLastTab: false, currentTabindex: this.state.currentTabindex - 1 });
    }

    render() {
        // Product options constructions
        var options = Object.keys(this.state.options);
        var options_tab = '', tab_content = '';
        options.map((value, idx) => {
            options_tab += '<li>' + value + '</li>';

            let tab_content_list = '';
            this.state.options[value].map((val, index) => {
                let checked = (val.status) ? 'checked' : '';
                let isChecked = (val.status) ? "1" : "0";
                if (val.visiblity) {
                    tab_content_list += '<div class="row row-index-' + index + '" data-option-value="' + val.value + '" data-isChecked="' + isChecked + '" data-sortorder="' + index + '"><label class="form-check-label"><input type="checkbox" id="' + value + '-' + index + '" class="product-options form-control-check" data-option-name="' + value + '" data-option-key="' + index + '" data-tabindex="' + idx + '" ' + checked + '/><span for="' + value + '-' + index + '" class="from-checkbox margin-top3"></span>' + val.value + '</label></div>';
                } else {
                    tab_content_list += "";
                }
            });
            tab_content += '<div class="options-container scroll tabIndex-' + idx + '"><div class="options-search"><label class="form-label">Search ' + value + '</label><input type="text" class="option-search form-control" data-optionname="' + value + '" data-tabindex="' + idx + '"/></div><div class="options-list form-group ' + value + '">' + tab_content_list + '</div><div id="option-no-msg-' + idx + '" style="display: none">No options found</div></div>';
        });
        let tabsHTML = `<ul>` + options_tab + `</ul>` + tab_content;

        // Combined Products
        // var values = this.props.dropdowns.variants;
        var values = this.props.dropdowns2.variants;  // added for new products grid

        console.log('values', values);
        console.log('state.isProductConfirm', this.state.isProductConfirm);
        console.log('combinedProducts', this.state.combinedProducts);
        if (this.state.isProductConfirm) {
            var combinedOptions = this.state.combinedProducts.map((value, index) => {
                let label = values.map((val, idx) => {
                    return (<span key={idx}>{value[val]}{(idx < (values.length - 1)) ? '-' : ''}</span>);
                });
                return (<div className="row" key={index}>
                    <label className="form-check-label">
                        <input type="checkbox" id={'option_' + index} className="combined-product-options form-control-check" checked={value.isChecked} data-option-key={index} onChange={() => this.toggleCombinedOptions(index)} />
                        <span htmlFor={'option_' + index} className="from-checkbox margin-top3"></span>
                        {label}</label>
                </div>);
            });
        }

        return (
            <div>
                <div className="modal-header">
                    Multi Product Creation
                    <span className="close" onClick={this.confirmModal.bind(this)}>x</span>
                </div>
                <div className="modal-content">
                    <div className="product-option modal-form">
                        <div>
                            <h4>Attribute Selection</h4>
                            {this.state.isProductConfirm &&
                                <span><button type="button" className="button-link" onClick={this.editSelection.bind(this)}>Edit</button></span>
                            }
                        </div>
                        <hr />

                        {!this.state.isProductConfirm &&
                            <div>
                                {/* <div id="options-tab">
                                    {this.state.isLoaded &&
                                    <JqxTabs ref={(tabs) => {this.optionTabs = tabs}}
                                        template={tabsHTML}
                                        width={'100%'} height={200}
                                        position={'top'}
                                        seJqxTabslectedItem={this.state.currentTabindex}/>
                                    }
                                </div> */}

                                <div className="modal-footer">
                                    <input type="button" className="gray-button" value="Back" disabled={(this.state.currentTabindex === 0) ? true : false} onClick={this.tabBack.bind(this)} />
                                    {
                                        (this.state.isLastTab) ?
                                            <input type="button" className="gray-button" value="Generate" disabled={(this.state.selectedOptionsCount > 0) ? false : true} onClick={this.createParentChild} /> :
                                            <input type="button" className="gray-button" value="Next" onClick={this.tabNext.bind(this)} />
                                    }
                                    <input type="button" className="gray-button" value="Cancel" onClick={this.confirmModal.bind(this)} />
                                </div>
                            </div>
                        }

                        {this.state.isProductConfirm &&
                            <div>
                                <h4 className="no-margin">Confirm Product Options</h4>
                                <div id="options-tab">
                                    <div className="options-container scroll">
                                        <div className="options-list form-group">
                                            {combinedOptions}
                                        </div>
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <input type="button" className="gray-button" value="Create Products" disabled={(this.state.selectedCombinedCount > 0) ? false : true} onClick={this.saveParentChild} />
                                    <input type="button" className="gray-button" value="Cancel" onClick={this.confirmModal.bind(this)} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <CancelModal isOpen={this.state.isModalOpen} modalClose={this.modalClose} />
            </div>
        );
    }
}

class CancelModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false
        };

        this.closeConfirmModal = this.closeConfirmModal.bind(this);
    }

    closeConfirmModal(key) {
        this.props.modalClose(key);
    }

    render() {
        return (<ConfirmModal isOpen={this.props.isOpen}>
            <div className="modal-content">
                <h3 className="confirm-warning-text">Are you sure you want to cancel the child products creation process?</h3>
                <div className="modal-footer">
                    <input type="button" className="gray-button" value="Yes" onClick={() => this.closeConfirmModal('yes')}></input>
                    <input type="button" className="gray-button" value="No" onClick={() => this.closeConfirmModal('no')}></input>
                </div>
            </div>
        </ConfirmModal>);
    }
}
/**
 * Redux Props:
 * - this.props.dropdowns
 */
const mapStateToProps = (state) => {
    return {
        dropdowns: state.gridDropDowns
    };
};

const mapDispatchToprops = (dispatch) => {
    return {
        updateOrReplaceData: (data) => {
            dispatch(actions.updateOrReplaceData(data));
        }
    };
};

const productOption = connect(mapStateToProps, mapDispatchToprops)(ProductOptions);
export default productOption;
