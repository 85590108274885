import React from 'react';
import ConfirmModal from '../../common/modals/confirmModal';

class CancelModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
        };

        this.closeConfirmModal = this.closeConfirmModal.bind(this);
    }

    closeConfirmModal(key) {
        this.props.modalClose(key);
    }

    render() {
        return (<ConfirmModal isOpen={this.props.isOpen}>
            <div className="modal-content">
                <h3 className="confirm-warning-text">Do you want to Save Changes?</h3>
                <div className="modal-footer">
                    <input type="button" className="gray-button" value="Yes" onClick={() => this.closeConfirmModal('save')}></input>
                    <input type="button" className="gray-button" value="No" onClick={() => this.props.modalClose('no')}></input>
                    <input type="button" className="gray-button" value="Cancel" onClick={() => this.closeConfirmModal('cancel')}></input>
                </div>
            </div>
        </ConfirmModal>);
    }
}

export default CancelModal;
