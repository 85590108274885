export const addProduct2GridData = (data) => {
    return {
        type: "ADD_PRODUCT2_GRID_DATA",
        data
    };
};

export const addSelectedRows = (data) => {
    return {
        type: "ADD_SELECTED_ROWS",
        data
    };
};

export const filterDataChanged = () => {
    return {
        type: "FILTER_DATA_CHANGED"
    };
};

export const isColumnTouched = (data) => {
    return {
        type: "IS_COLUMN_TOUCHED",
        data,
    };
};

export const filterForSortingProductsGrid = (data) => {
    return {
        type: "FILTER_FOR_SORTING_FROM_DASHBOARD",
        data,
    };
};

export const actionPerformedFrom = (data) => {
    return {
        type: "ACTION_PERFORMED_FROM",
        data,
    };
};
