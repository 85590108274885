import React, { Component } from 'react';

class RequiredFieldsFooter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isApplyChangesDisabled: true,
        };
    }

    enableFooter() {
        this.setState({ isApplyChangesDisabled: false });
    }

    disableFooter() {
        this.setState({ isApplyChangesDisabled: true });
    }

    render() {
        return (
            <div className="footer alerts-footer">
                <div className="footer-content right">
                    <button
                        className="gray-button"
                        onClick={this.props.cancelChanges}
                        disabled={this.state.isApplyChangesDisabled ? true : false}>
                        Cancel Changes
                    </button>
                    <button className="gray-button"
                        onClick={this.props.applyChanges}
                        disabled={this.state.isApplyChangesDisabled ? true : false}>
                        Apply Changes
                    </button>
                </div>
            </div>
        );
    }
}

export default RequiredFieldsFooter;
