
import React from 'react';
import SelectCategory from './department/select-category';

export class SubClassColumnPopUpRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            categoriesList: props.getCategoriesList(),
        };
    }

    render() {
        let subClassColumn = '';
        if (this.props.node.data && this.props.node.data.subClassColumnName) {
            subClassColumn = this.props.node.data.subClassColumnName;
        }
        if (this.state.categoriesList && this.props.data && this.props.data.subClassColumn) {
            this.state.categoriesList.forEach((row) => {
                if (row.fieldId === this.props.data.subClassColumn) {
                    subClassColumn = row.fieldName;
                }
            });
            if (this.props.data && !this.props.data.subClassColumnName) {
                this.props.node.setDataValue(this.props.colDef.colId, subClassColumn);
            }
        }

        return (
            <div>
                {subClassColumn}
            </div>
        );
    }
}

// //////////////////////////////////////////////////////////////////////////

/**
 * this class is used to when the subclass column is double clicked and enters in edit mode
 */
export class SubClassColumnPopUpEditor extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            showPopup: false,
            department: null,
            class: null,
            subClass: null,
            categoriesList: props.getCategoriesList(),
            selectedHierarchy: {
                categoryId: null,
                division: null,
                department: null,
                classColumn: null,
                subClassColumn: null,
            },
        };
    }

    componentWillMount() {
        this.setState({ showPopup: true });
    }

    componentWillUnmount() {
        this.props.api.tabToNextCell();
    }

    isPopup() {
        return true;
    }

    getValue() {
        const subClassColumn = this.state.selectedHierarchy.subClassColumn;
        let subClassColumnName = this.props.value || '';
        this.state.categoriesList.forEach(function (row) {
            if (row.fieldId === subClassColumn) {
                subClassColumnName = row.fieldName;
            }
        });
        return subClassColumnName;
    }

    getMappedName(id) {
        let name = '';
        this.state.categoriesList.forEach(function (row) {
            if (row.fieldId === id) {
                name = row.fieldName;
            }
        });
        return name;
    }

    closeDepartmentPopup() {
        this.setState({ showPopup: false });
    }

    /**
     * this column is used to set column values of class, division and department and call the
     * function in product2container.js which actually updates the values in the database
     * @param {string} catId
     * @param {object} catHierarchy
     */
    setColumnValues(catId, catHierarchy) {
        // capture the values in the state to be accessible to the getValue function.
        const selectedHierarchy = this.state.selectedHierarchy;
        selectedHierarchy.categoryId = catId;
        selectedHierarchy.division = catHierarchy.division;
        selectedHierarchy.department = catHierarchy.department;
        selectedHierarchy.classColumn = catHierarchy.classColumn;
        selectedHierarchy.subClassColumn = catHierarchy.subClassColumn;
        this.setState({ selectedHierarchy, showPopup: false });

        const rowData = this.props.node.data;

        let isDirty = false;
        if (
            rowData['catId'] != catId
        ) {
            isDirty = true;
        }

        if (isDirty) {
            rowData['catId'] = catId;
            rowData['division'] = catHierarchy.division;
            rowData['divisionName'] = this.getMappedName(catHierarchy.division);
            rowData['department'] = catHierarchy.department;
            rowData['departmentName'] = this.getMappedName(catHierarchy.department);
            rowData['classColumn'] = catHierarchy.classColumn;
            rowData['classColumnName'] = this.getMappedName(catHierarchy.classColumn);
            rowData['subClassColumn'] = catHierarchy.subClassColumn;
            rowData['subClassColumnName'] = this.getMappedName(catHierarchy.subClassColumn);
            this.props.rowUpdate(rowData, this.props.node.rowIndex);
        }
        this.props.api.stopEditing(); // this command is required to set the value into the column.
    }

    render() {
        return (
            <div>
                {
                    this.state.showPopup &&
                    <div className="ag-modal-container">
                        <div className="ag-modal-holder">

                            <SelectCategory
                                modalClose={this.closeDepartmentPopup.bind(this)}
                                user={this.props.user}
                                // setSelectedRow = {this.props.setSelectedRow}
                                setColumnValues={this.setColumnValues.bind(this)}
                                productId={this.props.node.data.parentProdId}
                            />;
                        </div>
                    </div>
                }
            </div>
        );
    }
}


