import React, { Component } from 'react';
import { connect } from 'react-redux';
import { rulesForAlerts } from './helperComponents/actions';

import AlertsFooter from './helperComponents/alerts-footer';

import Modal from '../modals/common-modal';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import JqxGrid from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';

import NotificationManager from '../common/components/notification';
import AlertsDataBuilder from './helperComponents/alerts-data-builder';

import EditAlert from './Edit-alerts-gridContainer';

import axios from 'axios';

import PropTypes from 'prop-types';


const AlertGridComponent = ({
    state,
    props,
    alertGrid,
    gridData,
    addRow,
    sendData,
    cancelChanges,
    isCancelEnable,
    modalClose,
    selectedRow,
    updateRow,
    gridHeight,
    AlertGridRef,
    selectedRowsCount,
    checkedRows,
    checkedAlertData,
    removeGridRows,
    onRowselect,
    onRowunselect,
    onCellvaluechanged,
    alertFooterRef,
}) => {
    return (
        <div>
            <div className="alertGrid">
                {state.isLoaded &&
          <JqxGrid ref={AlertGridRef}
              width={'100%'} source={gridData.dataAdapter} pageable={false}
              checkboxes={true} editable={true} hierarchicalCheckboxes={true}
              columnsResize={true} columns={gridData.columns} sortable={true}
              autoRowHeight={false} autoWidth={true} height={gridHeight}
              theme={'light'} selectionmode={'checkbox'} columnsreorder={true} columnsmenu={false}
              onRowselect={onRowselect}
              onRowunselect={onRowunselect}
              onCellvaluechanged = {onCellvaluechanged}
          />
                }
            </div>
            {state.isLoaded &&
        <AlertsFooter
            user={props.user}
            alertGridRowAdd={addRow}
            applyChanges={sendData}
            cancelChanges={cancelChanges}
            selectedRowsCount={selectedRowsCount}
            checkedRows={checkedRows}
            checkedAlertData={checkedAlertData}
            removeRows={removeGridRows}
            ref= {alertFooterRef}
            cancelDisable={state.isCancelEnable} />
            }
            <Modal isOpen={state.isModalOpen}>
                <EditAlert modalClose={modalClose} {...props}
                    selectedRow={selectedRow} updateRow={updateRow} />
            </Modal>


        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        addNewAlert: state.addNewAlert,
        alertRules: state.rulesForAlerts,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addRulesForAlerts: (data) => {
            dispatch(rulesForAlerts(data));
        },
    };
};

AlertGridComponent.PropTypes = {
    state: PropTypes.object,
    props: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertGridComponent);
