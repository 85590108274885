import React from 'react';
import Proptype from 'prop-types';

class RejectEventComponent extends React.PureComponent {
    render() {
        return (
            <div className="add-copy modal-form">
                <form className="confirmdelete" name="confirmdelete">
                    <div className="form-container scroll">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-label">Please specify the result for rejection</label>
                                    <textarea name="reason" onChange={this.props.handleChange} className="rejectReason" value={this.props.reason}></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer text-right">
                        <input type="button" className="gray-button" name="yesReject" value="Submit" onClick={this.props.submitReject} />
                        <input type="button" className="gray-button" name="noCancel" value="Cancel" onClick={this.props.modalClose} />
                    </div>
                </form>
            </div>
        );
    }
}

export default RejectEventComponent;
