/*column width declaration
*/
const columnWidth = {
    alertName: 200,
};

const alertNameCellrender = function (row, columnfield, value, rowdata) {
    const val = (value) ? value : '';

    return ('<div class="jqx-grid-cell-left-align edit-alertName" data-rowid ="' +
    row + '">' + value + '</div>');
};

const FieldorderRenderer = function (row, columnfield, value, rowdata) {
    const val = (value) ? value : '';

    return ('<div class="jqx-grid-cell-left-align edit-fieldOrder" data-rowid ="' +
    row + '">' + value + '</div>');
};

/* DropDown list creator functions
*/

const DropDownEditor = function (row, value, editor) {
    // assign a new data source to the dropdownlist.
    const list = ['Read', 'Write', 'Hide'];
    editor.jqxDropDownList({
        autoDropDownHeight: true,
        source: list,
        width: this.width,
        placeHolder: 'Please Select',
    });
};

const DropDownUpdater = function (row, column, columntype, oldvalue, newvalue) {
    // return the old value, if the new value is empty.
    if (newvalue == '') {
        return oldvalue;
    }
    return newvalue;
};

const DropDownInitRenderer = function (row, datafield, value, rowData) {
    if (value === 'R') {
        value = 'Read';
    } else if (value === 'W') {
        value = 'Write';
    } else if (value === 'H') {
        value = 'Hide';
    }
    return '<div class=\'jqx-grid-cell-left-align\' style=\'margin-top:6px\'>' +
    value + '</div>';
};

const DropDownInit = function (row, cellvalue, editor, celltext, width, height) {
    // set the editor's current value. The callback is called each time the editor is displayed.
    cellvalue = cellvalue ? cellvalue : '';

    editor.jqxDropDownList('selectItem', cellvalue);
    //editor.jqxDropDownList('selectIndex', 0);
    editor.jqxDropDownList('clearFilter');
    //  if(cellvalue == 0){
    //     editor.jqxDropDownList('clearSelection');
    //  }
};

const orderingRenderer = function (row, datafield, value, rowData) {
    return '<div class=\'ordering\'>' + value + '</div>';
};
/* Grid data builder is used to create the object format required for
 * Jqwidgets.
*/
function AlertsDataBuilder(res, pageName) {
    // dataField is used for providing type of data and its name.
    // columnList is used to define column name, type, and extra fields
    //  to define additional plugins inside the cell eg: drop down, calender ...,
    const dataField = [];
    const columnList = [];
    let primayKey = res.columnDetails[0].fieldName;
    const _this = this;

    //sort columndetails in product pageName
    res.columnDetails.sort(function (a, b) {
        return a.order - b.order;
    });

    // check for the type of fieldname future
    for (let i = 0; i < res.columnDetails.length; i++) {
        dataField.push(dataFieldObj(res.columnDetails[i]));

        /* Enable the below condition after pk(primary key) availabel from backend
    *  in JSON response.
    */

        if (res.columnDetails[i].hasOwnProperty('pk') && res.columnDetails[i].pk) {
            primayKey = res.columnDetails[i].fieldName;
        }

        columnList.push(columnObj(res.columnDetails[i]));
    }

    function dataFieldObj(list) {
        const obj = {};

        obj.name = list.fieldName;
        obj.type = list.type.toLowerCase();

        if (list.hasOwnProperty('map')) {
            obj.map = list.map;
        }

        return obj;
    }
    /* columnobj function will  return the object fields
  *  based on the field type in list.
  * e.g. for date type and other types need to build the cellrender function.
  */
    function columnObj(list) {
        const obj = {};
        //obj.text = list.columnTitle;
        obj.text = '<span class="pd-drop" data-field="' + list.fieldName + '" title="' + list.columnTitle + '" >' + list.columnTitle + '</span>';
        obj.dataField = list.fieldName;
        obj.width = (columnWidth[list.fieldName]) ? columnWidth[list.fieldName] : 150;
        obj.hidden = !list.visibility;

        if (list.fieldName === 'alertName') {
            obj.cellsrenderer = alertNameCellrender;
            obj.editable = false;
            obj.pinned = true;
        } else if (list.type === 'dropdown') {
            obj.columntype = 'dropdownlist';
            obj.createeditor = DropDownEditor;
            obj.cellvaluechanging = DropDownUpdater;
            obj.cellsrenderer = DropDownInitRenderer;
        // obj.columntype = 'template';
        // obj.createEditor = DropDownEditor;
        // obj.initEditor = DropDownInit;
        // obj.getEditorValue = DropDownUpdater;
        // obj.cellsrenderer = DropDownInitRenderer;
        } else if (pageName === 'fieldpermission') {
            if (list.fieldName == 'displayName' || list.fieldName == 'order') {
                obj.pinned = true;
            } else if (list.fieldName == 'displayName') {
                obj.editable = false;
            } else if (list.fieldName === 'order') {
                // obj.cellsrenderer = orderingRenderer;
                obj.editable = true;
                obj.cellsrenderer = FieldorderRenderer;
            }
        } else if (list.fieldName === 'order') {
            // obj.cellsrenderer = orderingRenderer;
            obj.editable = true;
            obj.cellsrenderer = FieldorderRenderer;
        }

        return obj;
    }

    function parsedData() {
        const data = [].concat(res.data);
        const parseableFieldName = res.parseField || 'roleName';

        const newData = data.map((item, index) => {
            item[parseableFieldName] = JSON.parse(item[parseableFieldName]);
            return item;
        });

        return newData;
    }
    const finalData = parsedData();
    function customsortfunc(column, direction) {
        const records = Object.assign([], finalData);
        if (direction == 'asc') {
            direction = true;
        }
        if (direction == 'desc') {
            direction = false;
        }
        if (direction != null) {
            if (!direction) {
                records.sort(function (a, b) {
                    return (a.roleName[column] === undefined) - (b.roleName[column] === undefined) || +(a.roleName[column] < b.roleName[column]) || -(a.roleName[column] > b.roleName[column]);
                });
            } else {
                records.sort(function (a, b) {
                    return (a.roleName[column] === undefined) - (b.roleName[column] === undefined) || +(a.roleName[column] > b.roleName[column]) || -(a.roleName[column] < b.roleName[column]);
                });
            }
        }
        _this._source.localdata = records;
        $('.jqx-grid').jqxGrid('updatebounddata', 'sort');
    }
    const source = {
        dataType: 'json',
        dataFields: dataField,
        id: primayKey,
        localdata: finalData,
    };

    if (pageName !== 'fieldpermission') {
        source.sort = customsortfunc;
    }

    const dataAdapter = new $.jqx.dataAdapter(source);

    return {
        dataAdapter: dataAdapter,
        columns: columnList,
    };
}

export default AlertsDataBuilder;
