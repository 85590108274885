import React from 'react';
import NotificationManager from '../../../common/components/notification';
import AddCopyModel from '../../../product/helperComponents/add-copy-modal';
import axios from 'axios';

import { ProductServices } from '../../services/product-services';

export class CopyWriteRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const copyLevel = (this.props.data && this.props.data.copyLevel) ? this.props.data.copyLevel : null; // Checks the displayLevel of the record validate the render based on displayLevel
        const IconDisplay = <div className="ag-popupIndicator"><img src={require('../../../common/icons/Expand Grid.svg')} /></div>; // Used to display the icon

        if (this.props.node.data.dataLevel == 'SINGLE') {
            return (
                <div>
                    {IconDisplay}
                </div>
            );
        }

        if (copyLevel) {
            switch (copyLevel) {
                case 'Style':
                    if (this.props.node.level == 0) {
                        return (
                            <div>
                                {IconDisplay}
                            </div>
                        );
                    } else {
                        return null;
                    }
                case 'Color':
                    if (this.props.node.level == 1) {
                        return (
                            <div>
                                {IconDisplay}
                            </div>
                        );
                    } else {
                        return null;
                    }
                case 'Size':
                    if (this.props.node.level == 2) {
                        return (
                            <div>
                                {IconDisplay}
                            </div>
                        );
                    } else {
                        return null;
                    }
                default: return null;
            }
        } else {
            return null;
        }
    }
}

// ////////////////////////////////////////////////////////////////////////////////////////

export class CopyWriteEditor extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    componentWillMount() {
        // this.props.openCopyWritePopup();

        let flag = false; // Using a flag to set the edit status of the cell

        // Set of checks for setting flag
        if (this.props.node.data.copyLevel == 'Color' && this.props.node.level == 1) {
            flag = true;
        }

        if (this.props.node.data.copyLevel == 'Style' && this.props.node.level == 0) {
            flag = true;
        }

        if (this.props.node.data.copyLevel == 'Size' && this.props.node.level == 2) {
            flag = true;
        }

        if (this.props.node.data.dataLevel == 'SINGLE') {
            flag = true;
        }

        if (!flag) {
            this.props.api.stopEditing();
        } else {
            // this.props.openCopyWritePopup();
            this.props.setSpinner(true);
            let sendId = null; // Value to be sent to the backend

            if (this.props.node.data.dataLevel == 'SINGLE') {
                sendId = this.props.node.data.chldProdId;
            } else if (this.props.node.data.dataLevel == 'PARENT') {
                sendId = this.props.node.data.parentProductId;
            } else if (this.props.node.data.dataLevel == 'INTERMEDIATE') {
                sendId = this.props.node.data.intProdId;
            } else if (this.props.node.data.dataLevel == 'CHILD') {
                sendId = this.props.node.data.chldProdId;
            }

            const headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json',
            };
            ProductServices.getCopyWritter({ id: sendId })
                .then((response) => {
                    this.props.setSpinner(false);

                    console.log('productContainer componentDidUpdate Image Approval subcall API call success data: ', response.data);
                    const res = response.data;
                    if (res.status) {
                        // _this.copyData = res.data;
                        this.props.openCopyWritePopup(res.data, this.props.node.data, this.props.node);
                        // _this.setState({ modalName: 'addCopyModel' });
                        // _this.modalOpen();
                    } else {
                        console.log('Product data doesnot contains descriptions');
                    }
                })
                .catch((error) => {
                    this.props.setSpinner(false);
                    console.log('productContainer componentDidUpdate Image Approval subcall  API call failed', error);
                });
        }
    }

    componentWillUnmount() {
        // this.props.api.tabToNextCell();
    }


    // Comes with AG grid used to set a cell to be a popup or not
    isPopup() {
        return true;
    }

    // Gets value when the popup has been closed
    getValue() {
        return this.props.status;
    }

    render() {
        const currentRowData = this.props.node.data;
        return (
            <div />
        );
    }
}


// import React from 'react';
// import NotificationManager from '../../common/components/notification';


// export class CopyWriteRenderer extends React.Component{

//     constructor(props, context) {
// 		super(props, context);
// 	}

//     render(){
//         return(
//             <div>
//             </div>
//         );
//     }
// }

// //////////////////////////////////////////////////////////////////////////////////////////

// export class CopyWriteEditor extends React.Component {

//     constructor(props, context) {
//         super(props, context);
// 	}

//     componentWillMount() {
//         if(!this.props.node.data.parentProdId) {
//             this.props.api.stopEditing();
//             NotificationManager({
//                 message: 'No copyWrite at Parent level',
//                 type: 4
//             });
//         } else {
//             this.props.openCopyWritePopup();
//         }
//     }

//     componentWillUnmount() {
//         this.props.api.tabToNextCell();
//     }


//     isPopup(){
//         return true;
//     }


//     getValue() {
//         return this.props.value;
//     }

//     render(){

//         let currentRowData = this.props.node.data;
//         return(
//             <div>
//             </div>
//         );
//     }
// }
