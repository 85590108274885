/*column width declaration
*/
var columnWidth = {
    productId: 120,
    prodName: 300,
    activeNotes: 400,
    //required fields column width
    fields:'40%',
    fieldsToClone:'20%',
    readyToOrder:'20%',
    reqdToAdd:'20%',
    //rules fields column width
    description:'30%',
    details:'30%',
    ruleName:'25%',
    status:'15%',
    value:400,
    name: 240,
    displayName: 240,
};

var requiredFieldsList = ['fieldsToClone', 'readyToOrder', 'reqdToAdd'];
/*Date Editor funtion for Jqwidgets
*/

var productCheckbox = function(row, columnfield, value, rowdata) {
    var disabled = '';

    if (window.currentValSampleSwatchOption && window.currentValSampleSwatchOption == "Swatch Only") {
        disabled = 'disabled';
    }

    if (window.readableFields && columnfield === "sampleSwatch" && window.readableFields.indexOf('swatchOnly') > - 1) {
        disabled = 'disabled';
    }

    if (columnfield == "mandatoryFields") {
        rowdata = this.owner.getrowdata(row);

        if (["userName", "email", "userGroups"].indexOf(rowdata.userFields) > -1){
            disabled = 'disabled';
        }
    }

    if (value) {
        return '<span class="blue-checkbox "><input class="sample-boolean" type="checkbox" data-fieldname="' +
            columnfield + '" checked data-row="' + row + '" ' + disabled + '><label></label></span>';
    } else {
        return '<span class="blue-checkbox "><input class="sample-boolean" type="checkbox" data-fieldname="' +
            columnfield + '" data-row="' + row + '" ' + disabled + '><label></label></span>';
    }
};

var createDateEditor = function (row, cellvalue, editor, cellText, width, height) {
    // construct the editor.
    cellvalue = cellvalue ? cellvalue : new Date();
    editor.jqxDateTimeInput({
        width: width,
        height:height,
        value:cellvalue,
        culture: 'en-US'
    });
};

var initDateEditor = function (row, cellvalue, editor, celltext, width, height) {
    // set the editor's current value. The callback is called each time the editor is displayed.
    cellvalue = cellvalue ? cellvalue : new Date();
    editor.jqxDateTimeInput('setDate', cellvalue);
};

var getDateEditor =  function (row, cellvalue, editor) {
    // return the editor's value.
    return $(editor).jqxDateTimeInput('getDate');
};

// Including image in a column
var imageRenderer = function (row, datafield, value) {
    var image ;

    if (value.length && value[0].imageName) {
        image = '<div class="product-image"><img src="'
            + value[0].imageName + '" /></div>';
    } else {
        image = '';
    }

    return image;
};

// swatchOnly checkbox renderer
var swatchOnlyCellRender = function(row, columnfield, value, rowdata) {
    if (value && value > 0) {
        return '<span class="blue-checkbox"><input class="swatch-only" type="checkbox" checked data-row="' +
            row + '" ><label></label></span>';
    } else {
        return '<span class="blue-checkbox"><input class="swatch-only" type="checkbox"  data-row="' +
            row + '" ><label></label></span>';
    }
};

// checkbox renderer
var requiredFieldcheckboxes = function(row, columnfield, value, html, details, rowdata) {
    var defaultFields = ["productId", "prodName", "catId", "vendorId", "copyLevel"],
        disabled = '', disabled_1 = '';

    if (columnfield === "reqdToAdd" && defaultFields.indexOf(rowdata.actualFieldName) > -1) {
        if  (rowdata.actualFieldName != "copyLevel") {
            value = true;
        }

        disabled = "disabled";
    }

    // disabled specific reqToAdd field checkbox
    if (columnfield === "reqdToAdd" && !rowdata.editable) {
        disabled_1 = "disabled";
    }

    if (value) {
        return '<div class="blue-checkbox custom-grid-checkbox"><input class="required-checkbox" type="checkbox" data-fieldname="' +
            columnfield + '" checked ' + disabled + ' data-row="' + row + '" ' + disabled_1 + '><label></label></div>';
    } else {
        return '<div class="blue-checkbox custom-grid-checkbox"><input class="required-checkbox" type="checkbox" data-fieldname="' +
            columnfield + '" data-row="' + row + '" ' + disabled_1 + '><label></label></div>';
    }
};

// Rules status click icon reder
var CellDoubleClickEvent = function(row, columnfield, value, rowdata) {
    var status = value === "Active" ? "active" : "";
    var className = columnfield === "status" ? "rule-status" : "";

    return '<div class="grid-text ' + className + ' ' + status + '" data-row="' + row + '">' + value + '<span class="icon-expand-grid right"></span></div>';
};

var cellbeginedit = function (row, datafield, columntype, value) {
    var rowData = this.owner.getrowdata(row);

    if (rowData.id) {
        return false;
    }
};

/* Grid data builder is used to create the object format required for
 * Jqwidgets.
 */
function GridDataBuilder(res, pageName) {
    // dataField is used for providing type of data and its name.
    // columnList is used to define column name, type, and extra fields
    //  to define additional plugins inside the cell eg: drop down, calender ...,
    var dataField = [],
        columnList = [],
        primayKey = (pageName === "rules") ? "ruleId" : res.columnDetails[0].fieldName;
    // check for the type of fieldname future

    //sort columndetails in product pageName
    res.columnDetails.sort(function (a, b) {
        return a.order - b.order;
    });

    for (var i = 0; i < res.columnDetails.length; i++) {
        dataField.push({
            "name": res.columnDetails[i].fieldName,
            "type": res.columnDetails[i].type ? res.columnDetails[i].type.toLowerCase() : "String"
        });

        /* Enable the below condition after pk(primary key) availabel from backend
         *  in JSON response.
         */

        if (res.columnDetails[i].hasOwnProperty("pk") && res.columnDetails[i].pk) {
            primayKey = res.columnDetails[i].fieldName;
        }

        columnList.push(columnObj(res.columnDetails[i]));
    }

    /* columnobj function will  return the object fields
     *  based on the field type in list.
     * e.g. for date type and other types need to build the cellrender function.
     */
    function columnObj(list) {
        var obj = {};

        if (pageName === "rules") {
            obj.text = '<span class="pd-drop" data-field="' + list.fieldName + '" title="' + list.columnTitle + '">' + list.columnTitle + '</span>';
        } else {
            obj.text = '<span title="' + list.columnTitle + '">' + list.columnTitle + '</span>';
        }

        obj.dataField = list.fieldName;
        obj.width = (columnWidth[list.fieldName]) ? columnWidth[list.fieldName] : 120;
        obj.hidden = !list.visibility;

        /* if list type is date, pass the methods related to date to intialize
         *  date picker
         */
        if (list.type === "Date") {
            if (list.fieldName == "sampleMovedDate" || list.fieldName == "sampleReceivedDate") {
                obj.cellsFormat = 'd';
                obj.editable = false;
            } else {
                obj.columnType = "template";
                obj.cellsFormat = 'd';
                obj.createEditor = createDateEditor ;
                obj.initEditor = initDateEditor ;
                obj.getEditorValue = getDateEditor;
            }
        } else if (list.type === 'image') {
            obj.editable = false;
            obj.cellsrenderer = imageRenderer;
        } else if (list.fieldName == 'swatchOnly') {
            obj.cellsrenderer = swatchOnlyCellRender;
            obj.editable = false;
            obj.cellsAlign = 'center';
        } else if (pageName === "requiredFields" &&
                  requiredFieldsList.indexOf(list.fieldName) >= 0) {
            obj.cellsrenderer = requiredFieldcheckboxes;
            obj.editable = false;
            obj.cellsAlign = 'center';
        } else if (pageName === "rules") {
            if (list.fieldName === "status") {
                obj.text = '<span class="pd-drop" data-field="' + list.fieldName + '">Status</span>';
                obj.cellsrenderer = CellDoubleClickEvent;
                obj.editable = false;
                obj.pinned = true; /* Added to Freeze column in Admin section Rules Page */
            } else if (list.fieldName === "ruleName") {
                obj.cellsrenderer = CellDoubleClickEvent;
                obj.editable = false;
                obj.sortable = true;
                obj.pinned = true; /* Added to Freeze column in Admin section Rules Page */
            } else {
                obj.sortable = false;
            }
        } else if (pageName === "Sample") {
            if (list.fieldName === "sampleSwatch") {
                obj.cellsrenderer = productCheckbox;
                obj.editable = false;
            } else if (list.fieldName === "sampleComments") {
                obj.editable = true;

                if (window.readableFields && window.readableFields.indexOf('swatchOnly') > -1) {
                    obj.editable = false;
                }
            } else {
                obj.editable = false;
            }
        } else if (pageName == "category") {
            if (list.fieldName === "categoryId") {
                obj.editable = false;
            } else if (list.fieldName === 'name') {
                obj.pinned = true;
                obj.initeditor = function (row, column, editor) {
                    editor.attr('maxlength', 100);
                };
            } else if (list.fieldName == "displayOrder") {
                obj.initeditor = function (row, column, editor) {
                    editor.attr('maxlength', 5);
                };
            } else if (list.type == "DropDown") {
                obj.columntype = 'dropdownlist';
                obj.createeditor = function (row, value, editor) {
                    editor.jqxDropDownList({
                        source: res.dropDowns,
                        width: this.width
                    });
                };
            } else if (list.type === "bool") {
                obj.columntype = "checkbox";
            }
        } else if (pageName == "userfields") {
            if (list.fieldName == "mandatoryFields") {
                obj.cellsrenderer = productCheckbox;
                obj.editable = false;
                obj.cellsAlign = 'center';
            }
        } else if (list.type == "boolean") {
            obj.cellsrenderer = productCheckbox;
            obj.editable = false;
            obj.cellsAlign = 'center';
        } else if (pageName == "ShotAngles") {
            if (list.fieldName == "id" || list.fieldName == "angle") {
                obj.editable = false;
            }
        } else if (pageName == "email") {
            if (list.fieldName == "id") {
                obj.cellsrenderer = CellDoubleClickEvent;
                obj.editable = false;
                obj.sortable = true;
            }
        }

        return obj;
    }

    if (pageName == 'product') {
        var obj = {};
        obj.text = "<span class='header-checkbox' data-checked='false'></span>";
        obj.dataField = null;
        obj.width = 50;
        obj.editable = false;
        obj.sortable = false;

        // place the checkboxs in first columnList
        columnList.unshift(obj);
    }

    var source = {
        dataType:"json",
        dataFields:dataField,
        id:primayKey,
        localdata: res.data
    };

    var dataAdapter = new $.jqx.dataAdapter(source);

    return {
        dataAdapter: dataAdapter,
        columns: columnList
    };
}

module.exports = GridDataBuilder;
