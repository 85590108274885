import React, { Component } from 'react';
import CancelModalAdminFooter from './cancelModalAdminFooter';
import VendorModal from './vendorModal';
import axios from 'axios';
import { connect } from 'react-redux';
import NotificationManager from '../../common/components/notification';
import {CommonServices} from '../../common/services/common-services';


class AdminFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addVendorLabel: 'Add New Vendor',
            delete: 'Delete Vendor',
            undoChanges: 'Undo Last Changes',
            isModalOpen: false,
            isConfirmModalOpen: false,
            vendorIds: [],
            modalName: '',
        };

        this.modalOpen = this.modalOpen.bind(this);
        this.modalClose = this.modalClose.bind(this);

        this.vendorDeleteModal = this.vendorDeleteModal.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.deleteVendors = this.deleteVendors.bind(this);
    }

    /**
	 * Opens the modal
	 * @param {*} target
	 */
    modalOpen(target) {
        if (target === 'add_vendor') {
            this.setState({ isModalOpen: true });
        } else if (target === 'delete') {
            this.setState({ isConfirmModalOpen: true });
        }
    }

    /**
	 * Closes the modal
	 */
    modalClose() {
        this.setState({ isModalOpen: false, isConfirmModalOpen: false });
    }


    /**
	 * Trigger open modal
	 * @param {*} target
	 */
    triggerModalOpen(target) {
        this.setState({ modalName: target });
        this.modalOpen(target);
    }

    /**
	 * Opens the confirm delete popup
	 */
    confirmDelete() {
        this.deleteVendors();
        this.modalClose();
    }

    /**
	 * Opens the vendor delete modal
	 */
    vendorDeleteModal() {
        this.triggerModalOpen('delete');
    }

    /**
	 * Calls backend to delete selected vendors
	 */
    deleteVendors() {
        const _this = this;
        const vendorIds = _this.props.checkedVendorData;
        if (vendorIds.length > 0) {
            const req = JSON.stringify(vendorIds);

            const headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json',
            };

            CommonServices.deletevendor(req)
                .then((response) => {
                    // console.log('admin vendor adminFooter deleteVendors API call success data: ', response.data);
                    const data = response;

                    if (data.status) {
                        _this.props.removeRows(vendorIds);
                        NotificationManager({
                            message: 'Vendor Deleted Successfully.',
                            type: 1,
                        });
                    } else {
                        NotificationManager({
                            message: 'Failed to delete user.',
                            type: 4,
                        });
                    }
                })
                .catch((error) => {
                    console.log('admin vendor adminFooter deleteVendors call failed', error);
                });
        }
    }


    render() {
        let isDisableDelete = true;
        if (this.props.selectedRows > 0) {
            isDisableDelete = false;
        }
        const footerContent = (
            <div>
                <div className="footer-content">
                    <button className="admin-addrecord gray-button" onClick={() => this.triggerModalOpen('add_vendor')}>{this.state.addVendorLabel}</button>
                    <VendorModal isOpen={this.state.isModalOpen} modalClose={this.modalClose}
                        modalName={this.state.modalName} userData={this.props.user}
                        addVendorRow={this.props.addVendorRow} user={this.props.user}
                        reloadGrid = {this.props.reloadGrid}
                    />
                </div>
                <div className="footer-row-details">
                    <span className="row-count">{this.props.vendorCount} Total Rows</span>
                    <span className="row-selected"> | Selected: {this.props.selectedRows}</span>
                </div>
                <div className="footer-content right">
                    <button className="cancel_changes gray-button" disabled={isDisableDelete} onClick={this.vendorDeleteModal}>{this.state.delete}</button>
                    <button className="apply_changes gray-button" disabled>{this.state.undoChanges}</button>
                </div>
                <CancelModalAdminFooter isOpen={this.state.isConfirmModalOpen} modalClose={this.modalClose} confirmDelete={this.confirmDelete} />
            </div>
        );

        return (
            <div className="footer admin-footer">
                {footerContent}
            </div>
        );
    }
}


const mapSateToProps = (state) => {
    return {
        userView: state.userView,
    };
};

export default connect(mapSateToProps)(AdminFooter);
