/*column width declaration
*/
var columnWidth = {
    productId: 120,
    ProdName: 300,
    activeNotes: 400
};

var cdRenderer = function(productId, datafield, value, columnproperties, defaultHtml) {
    console.log('currency renderer productId: ', productId);
    console.log('currency renderer datafield: ', datafield);
    console.log('currency renderer value: ', value);
    console.log('currency renderer columnproperties: ', columnproperties);
    console.log('currency renderer defaultHtml: ', defaultHtml);

    if (columnproperties.dataLevel == 'SINGLE') {
        return '';
    } else {
        return value;
    }
};

var currencyRenderer = function(row, datafield, value) {
    return '$' + parseFloat(value).toFixed(2);
};

// Including image in a column
var imageRenderer = function (row, datafield, value) {
    var image ;

    if (value.length && value[0].imageName) {
        var imageLink = "";

        if (value[0].tinyImageUrl) {
            imageLink = value[0].tinyImageUrl;
        } else {
            imageLink = value[0].imageUrl;
        }

        image = '<div class="product-image" data-row="' + row + '"><img src="'
            + imageLink + '" /></div>';
    } else {
        image = '<div class="product-image empty" data-row="' + row + '"></div>'; // remove if required
    }

    if (datafield === 'imageApproval') {
        image = '';
    }

    return image;
};

var convertDataType = function (value) {
    if (value && typeof (value) === "string") {
        if (value.toLowerCase() === "none") {
            value = false;
        } else if (value === "true") {
            value = true;
        } else if (value === "false") {
            value = false;
        }
    }

    return value;
};

var productCheckbox = function (columnfield, value, row, defaulthtml, columnproperties, rowdata) {
    var rowdata = row;
    var key = rowdata.productId;

    let val = convertDataType(row);

    var oneTimeCheck = [
            "isPiReceived",
            "isPiApproved",
            "isSampleapproved",
            "isSamplerecieved",
            "attrApproved",
            "isPiRequestSent",
            "readyToActivate",
            "attrCompleted",
            "earlyReqSampleSwatch",
            "sampleMoved",
            "isPiRequestEarly"
        ],
        defaultDisabledList = [
            "isCopywriterApproved",
            "isWebproducerApproved",
            "isRetoucherApproved"
        ],
        readyToActivateList = defaultDisabledList.concat(["attrApproved"]),
        disabled = '',
        defaultDisabled = '',
        sampledisabled = '';

    disabled = (oneTimeCheck.indexOf(columnfield) > -1) ? ' disabled ' : '';
    defaultDisabled = (defaultDisabledList.indexOf(columnfield) > -1) ? ' disabled ' : '';

    if (columnfield === oneTimeCheck[2]) {
        sampledisabled = convertDataType(rowdata[oneTimeCheck[3]]) != true ? 'disabled' : '';
    } else if (columnfield === "isSamplerecieved" && rowdata.swatchOnly && rowdata.swatchOnly.toLowerCase() == "none") {
        sampledisabled = 'disabled';
    } else if (columnfield === "readyToActivate") {
        // to enable checkbox for item ready to order disablist list(3) plus attrApproved should be checked
        readyToActivateList.forEach(function (item, index) {
            if (rowdata.swatchOnly && rowdata.swatchOnly.toLowerCase() == "none" && item === "isRetoucherApproved") {
                // Skip Retoucher Approved check
            } else {
                if (!rowdata[item]) {
                    defaultDisabled = ' disabled ';
                }
            }
        });
    } else if (columnfield === "isPiReceived") {
        if (convertDataType(rowdata["isPiRequestSent"]) != true) {
            defaultDisabled = ' disabled ';
        }
    } else if (columnfield === "isPiApproved") {
        if (convertDataType(rowdata["isPiRequestSent"]) != true || convertDataType(rowdata["isPiReceived"]) != true) {
            defaultDisabled = ' disabled ';
        }
    } else if (columnfield === "attrApproved") {
        if (convertDataType(rowdata['attrCompleted']) != true) {
            defaultDisabled = ' disabled ';
        }
    } else if (columnfield === "isPiRequestSent" || columnfield === "isSamplerecieved") {
        defaultDisabled = '';
    } else if (columnfield === "isActiveLive") {
        if (convertDataType(rowdata["readyToActivate"]) != true) {
            defaultDisabled = ' disabled ';
        }
    }

    var className = "product-boolean";

    if (columnfield === "attrCompleted") {
        className = "product-attr-completed";

        var attributeFieds = ['attr1', 'attr2', 'attr3', 'attr4', 'attr5', 'attr6', 'attr7', 'attr8', 'attr9', 'attr10',
            'attr11', 'attr12', 'attr13', 'attr14', 'attr15', 'attr16', 'attr17', 'attr18', 'attr19', 'attr20'];
        var maxlength = window.settings ? window.settings.maxAttributeLength : 0;
        var notEmptyCount = 0;

        if (maxlength > 0) {
            for (i = 0; i < attributeFieds.length; i++) {
                if (rowdata[attributeFieds[i]]) {
                    notEmptyCount++;
                }
            }

            if (notEmptyCount < maxlength) {
                defaultDisabled = "disabled";
            }
        }
    }

    var gridcontainer = '';

    if (rowdata.dataLevel == "INTERMEDIATE" && rowdata.level == 2) {
        gridcontainer = '#level2grid' + rowdata.level1;
    } else if (rowdata.dataLevel == "PARENT" && rowdata.parentRef) {
        if (rowdata.level == 2) {
            gridcontainer = '#level2grid' + rowdata.level1;
        } else if (rowdata.level == 3) {
            gridcontainer = '#level3grid' + rowdata.level1 + rowdata.level2;
        } else {
            gridcontainer = '';
        }
    }

    if (rowdata.rowLocking) {
        defaultDisabled = "disabled";
    }

    var marginTop = 'margin-top:6px;';

    if (this.owner.rowsheight == 50) {
        marginTop = 'margin-top:17px;';
    }

    if (value) {
        return '<div class="jqx-grid-cell-middle-align" style="' + marginTop + '"><span class="blue-checkbox "><input class="' + className + '" type="checkbox" data-fieldname="' +
            columnfield + '" checked data-row="' + key + '"' + disabled + defaultDisabled + ' data-row-type="' + rowdata.dataLevel + '" data-parent="' +
            rowdata.referrence + '" grid-container="' + gridcontainer + '"><label></label></span></div>';
    } else {
        return '<div class="jqx-grid-cell-middle-align" style="' + marginTop + '"><span class="blue-checkbox "><input class="' + className + '" type="checkbox" data-fieldname="' +
            columnfield + '" data-row="' + key + '"' + defaultDisabled + ' ' + sampledisabled + ' data-row-type="' + rowdata.dataLevel + '" data-parent="' +
            rowdata.referrence + '" grid-container="' + gridcontainer + '"><label></label></span></div>';
    }
};

/**Grid data builder is used to create the object format required for
 * Jqwidgets.
 */
function GridDataBuilder(res, pageName) {
    console.log('Publication helperComponents GridDataBuilder: ', res);

    // if there is no data in res returning and not populating grid
    if (!res.columnDetails[0]) {
        return;
    }

    var DropDownEditor = function (row, fieldName, editor, cellText, width, height) {
        // assign a new data source to the dropdownlist.
        var fieldName = this.datafield;
        var list = res.dropdowns[fieldName] ? res.dropdowns[fieldName] : [],
            enableAutoHieght = list.length < 7 ? true : false;
        var placeHolder = {
            featureColor: 'Feature Color',
        };

        editor.jqxDropDownList({
            autoDropDownHeight: enableAutoHieght,
            source: list,
            dropDownHeight: 205,
            width: '100%',
            height: height,
            displayMember: "fieldName",
            valueMember: "fieldId",
            theme: 'light',
            autoOpen: true,
            enableBrowserBoundsDetection: true,
            placeHolder: 'Select ' + placeHolder[fieldName]
        });
    };

    var DropDownInit = function (row, cellvalue, editor, celltext, width, height) {
        // set the editor's current value. The callback is called each time the editor is displayed.
        if (window.newVendorList && window.newVendorList.length > 0) {
            window.newVendorList.map(function (o, i) {
                var item = editor.jqxDropDownList('getItemByValue', o.fieldId);

                if (item === undefined) {
                    editor.jqxDropDownList('addItem', { label: o.fieldName, value: o.fieldId });
                }
            });
        }

        cellvalue = cellvalue ? cellvalue : 0;
        editor.jqxDropDownList('selectItem', cellvalue);
    };

    var DropDownUpdater = function (row, cellvalue, editor) {
        // return the old value, if the new value is empty.
        return editor.val();
    };

    // dataField is used for providing type of data and its name.
    // columnList is used to define column name, type, and extra fields
    // to define additional plugins inside the cell eg: drop down, calender ...,
    // Primary key is product_pub_id
    let dataField = [],
        columnList = [],
        primayKey = res.columnDetails[0].fieldName,
        pinnedList = [],
        nonEditable = [
            'classColumn_name',
            'subClassColumn_name',
            'department_name',
            'catId_name',
            'vendorId_name'
        ];
    let nonEditable2 = [
        'classColumn', 'subClassColumn', 'department', 'catId', 'vendorId'
    ];

    // check for the type of fieldname future

    //sort columndetails in product pageName
    res.columnDetails.sort(function (a, b) {
        return a.order - b.order;
    });

    for (var i = 0; i < res.columnDetails.length; i++) {
        if (nonEditable.indexOf(res.columnDetails[i].fieldName) != -1) {
            dataField.push({
                "name": res.columnDetails[i].fieldName + '_name',
                "type": res.columnDetails[i].type.toLowerCase()
            });
        } else {
            dataField.push({
                "name": res.columnDetails[i].fieldName,
                "type": res.columnDetails[i].type.toLowerCase()
            });
        }

        /* Enable the below condition after pk(primary key) availabel from backend
         *  in JSON response.
         */

        if (res.columnDetails[i].hasOwnProperty("pk") && res.columnDetails[i].pk) {
            primayKey = res.columnDetails[i].fieldName;
        }

        if (nonEditable.indexOf(res.columnDetails[i].fieldName) != -1) {
            let obj = {};
            obj = res.columnDetails[i];
            obj.fieldName = res.columnDetails[i].fieldName + '_name';

            columnList.push(columnObj(obj));
        } else {
            columnList.push(columnObj(res.columnDetails[i]));
        }
    }

    console.log('Publication helperComponents GridDataBuilder: colList', columnList);

    /** columnobj function will  return the object fields
     *  based on the field type in list.
     * e.g. for Date type and other types need to build the cellrender function.
     */
    function columnObj(list) {
        let obj = {};

        console.log('GridDataBuilder publication: columnObj: ', list);

        obj.text = '<span title="' + list.columnTitle + '">' + list.columnTitle + '</span>';
        obj.dataField = list.fieldName;
        obj.width = (columnWidth[list.fieldName]) ? columnWidth[list.fieldName] : 120;
        obj.hidden = !list.visibility;

        //enable pinned/frozen column
        if (pinnedList.indexOf(list.fieldName) > -1) {
            obj.pinned = true;
        }

        if (nonEditable2.indexOf(list.fieldName) > -1) {
            obj.editable = false;
        } else {
            obj.editable = true;
        }

        if (list.fieldName === 'ProdName' || list.fieldName === 'productId') {
        } else if (list.type === 'image') {
            obj.cellsrenderer = imageRenderer;
        } else if (list.type === 'dropdown' && list.fieldName !== "featureColor") {
            obj.columntype = 'template';
            obj.createEditor = DropDownEditor;
            obj.initEditor = DropDownInit;
            obj.getEditorValue = DropDownUpdater;
            obj.editable = true;
        } else if (list.type === 'boolean') {
            obj.editable = false;
            obj.cellsrenderer = productCheckbox;
            obj.cellsalign = 'center';
        }

        if (["promoPrice", "retailPrice", "salesPrice"].indexOf(list.fieldName) > -1) {
            obj.cellsrenderer = currencyRenderer;
            obj.cellsformat = 'c2';
            obj.cellsalign = 'right';
        }

        if (["copyLevel", "displayLevel"].indexOf(list.fieldName) > -1) {
            obj.cellsrenderer = cdRenderer;
        }

        if (["comment1", "comment2"].indexOf(list.fieldName) > -1) {
            obj.validation = function (cell, value) {
                if (value.toString().trim().length > 100) {
                    return {
                        message: "Please enter value upto 100 characters in length",
                        result: false
                    };
                }

                return true;
            };
        }

        if (list.fieldName === "featureColor") {
            obj.editable = true;
        }

        return obj;
    }

    if (pageName == 'publication') {
        var obj = {};
        obj.text = "<span class='header-checkbox' data-checked='false'></span>";
        obj.dataField = null;
        obj.width = 50;
        obj.editable = false;
        obj.sortable = false;
        obj.resizeable = false;
        obj.draggable = false;

        // place the checkboxs in first columnList
        columnList.unshift(obj);
    }

    let gridData = res.data;
    let gridDataLength = gridData.length;
    let categoriesList = res.categoriesList;
    let categoriesListLength = categoriesList.length;
    let vendorsList = res.vendorsList;
    let vendorsListLength = vendorsList.length;

    let department_name, classColumn_name, subClassColumn_name, vendorId_name;

    console.log('GridDataBuilder: categoriesList: ', categoriesList, vendorsList);

    for (let g = 0; g < gridDataLength; g++) {
        let vendorId = gridData[g]['vendorId'];

        for (let v = 0; v < vendorsListLength; v++) {
            if (vendorsList[v].fieldId === vendorId) {
                vendorId_name = vendorsList[v].fieldName;
            }
        }

        gridData[g]['vendorId'] = vendorId_name;
    }

    console.log('GridDataBuilder modified: ', gridData);

    var source = {
        dataType: "json",
        dataFields: dataField,
        id: primayKey,
        localdata: gridData
    };

    console.log('GridDataBuilder', source, columnList);

    var dataAdapter = new $.jqx.dataAdapter(source);

    return {
        dataAdapter: dataAdapter,
        columns: columnList
    };
}

export default GridDataBuilder;
