import React, {Component} from 'react';
import { connect } from 'react-redux';

import * as actions from '../../publication/redux/publicationActions';

import Modal from '../../modals/common-modal';
import SavedPresentationDeleteConfirm from './deleteSavedListConfirm';

import axios from 'axios';

import NotificationManager from '../../common/components/notification';
import PublicationServices from '../../publication/services/publication-services';

var GridDataBuilder = require('../../publication/helperComponents/gridDataBuilder');

class SavedListDropDown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isListDropdownOpen: false,
            availableList: [],
            availableListWithId: [],
            presentationName: "Select List",
            deleteData: {},
            isModalOpen: false,
            modalName: '',
            arrPresentations: [],
            isClear: false,
            isListItemsDirty: false,
            isCacheAvailable: false
        };

        this.cacheListName = "";
        this.cacheListId = "";
        this.listNamesForCache = [];
        this.modalOpen = this.modalOpen.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.deletePresentationConfirmed = this.deletePresentationConfirmed.bind(this);
        this.toggleClickList = this.toggleClickList.bind(this);
        this.handleClickList = this.handleClickList.bind(this);
        this.loadListData = this.loadListData.bind(this);
        this.getSavedListNames = this.getSavedListNames.bind(this);
        this.resetDropdown = this.resetDropdown.bind(this);
        this.reOrderList = this.reOrderList.bind(this);
    }

    componentDidMount() {
        var _this = this;

        // to hide dropdowns when user clicks outside the field area
        $(document).on('click.toggle', function (e) {
            e.stopPropagation();

            var viewDropDown = '.selected-presentation';

            if ($(viewDropDown).hasClass('open') && $(e.target).closest('.grid-view-dropdown').length === 0 ) {
                _this.toggleClickList();
            }
        });
    }

    componentWillUnmount() {
        $(document).off('click.toggle');
    }

    componentWillReceiveProps(prevProps) {
        if (prevProps.isListDirty != this.props.isListDirty) {
            //updates the prop addSelectedPresentationName with the right id of the list name
            if (this.props.currentListName) {
                this.setState({presentationName: this.props.currentListName});
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let _this = this;

        if (prevProps.clearflag != _this.props.clearflag) {
            _this.setState({ isClear: true });
        }

        if (_this.state.isClear) {
            _this.resetDropdown();
        }

        if (prevProps.pageType != _this.props.pageType) {
            _this.loadListData(_this.state.presentationName);
        }
    }

    toggleClickList() {
        console.log('toggleClicked');

        if (this.props.pageType === "product" || this.props.pageType === "publication" || this.props.pageType === "product2") {
            this.getSavedListNames();
            this.setState({isListDropdownOpen: !this.state.isListDropdownOpen});
        }
    }

    handleClickList(e) {
        console.log('handleClickList: ', e.target.getAttribute('name'));

        let name = e.target.getAttribute('name') || this.props.currentListName;
        let id;
        let listWithId = this.state.availableListWithId;
        let listWithIdLength = listWithId.length;

        for (let i = 0; i < listWithIdLength; i++) {
            if (listWithId[i].name.localeCompare(name) == 0) {
                id = listWithId[i].id;

                break;
            }
        }

        let _this = this;

        this.loadListData(name);

        let selId;

        if (id) {
            selId = id;
        } else {
            _this.state.arrPresentations.map(function (objj, i) {
                if (objj.hasOwnProperty(name)) {
                    selId = objj[name];
                }
            });
        }

        console.log('adding to props selected presentation name: ', name, selId);

        this.props.addSelectedPresentationName({ name: name, id: selId });
    }

    reOrderList(name) {
        let availableListData = this.state.availableList;
        let orderedData = [];
        orderedData.push(name);

        availableListData.map(function(nObj) {
            if (nObj != name) {
                orderedData.push(nObj);
            }
        });

        this.setState({availableList:orderedData});
    }

    loadListData(name) {
        let postData = {};
        let _this = this;

        postData.listName = name;
        postData.userId = this.props.user.userId;

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        PublicationServices.getSingleListName(postData).then( (response) => {
            console.log('rightDrawerContainer SavedListDropDown loadListData API call  success data: ', response);
            let data = response;

            if (data.status) {
                _this.props.addPublicationColumnNames(data.columnDetails);
                _this.props.addPublicationGridData(data.data);
                _this.props.publicationCounter(); // added vinay

                let dropList = _this.props.gridDropDowns;

                dropList.featureColor = data.dropDown.featureColor;
                console.log('rightDrawerContainer SavedListDropDown loadListData: droplist ', _this.props, dropList);

                _this.props.addPublicationGridDropDowns(dropList);

                // close the drodown
                this.setState({isListDropdownOpen: false, presentationName: name});

                if (data.data) {
                    var oData = data.data;

                    var arrListNames = [];
                    arrListNames = oData.map(function (obj, i) {
                        let pObj = {};
                        pObj.name = obj.prodName ? obj.prodName : "";
                        pObj.id = obj.productId ? obj.productId : "";

                        return pObj;
                    });

                    _this.props.addListItemsNamesData(arrListNames);
                }
            } else {
                _this.resetDropdown();
            }
        })
            .catch( error => {
                console.log('right Drawer SavedListDropDown loadListData  API call failed', error);

                NotificationManager({ message:"Exception in getting List name", type:4 });
                _this.resetDropdown();
            });
    }

    resetDropdown() {
        this.setState({ presentationName: "Select List", isClear: false });
        this.props.addSelectedPresentationName({ name: "", id: "" });
        this.props.addListItemsNamesData([]);
        this.getSavedListNames();
    }

    getSavedListNames() {
        let _this = this;
        let postData = { 'id': 'product' };

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        PublicationServices.getPublicationList(postData).then( (response) => {
            console.log('right Drawer SavedListDropDown getSavedListNames API call  success data: ', response.data);
            let data = response;

            if (data.status) {
                if (data.data) {
                    _this.props.addPubListDropdownData(data.data);

                    var oData = data.data;
                    var arrListNames = [];
                    var bIsCache = false;

                    arrListNames = oData.map(function (obj, i) {
                        if (obj.listName) {
                            if (obj.tempList) {
                                _this.cacheListName = obj.listName;
                                _this.cacheListId = obj.publishListId;
                                bIsCache = true;
                            }

                            return obj.listName;
                        } else {
                            return "";
                        }
                    });

                    let arrListNamesWithId = oData.map(function (obj, i) {
                        if (obj.listName) {
                            return {'name': obj.listName, 'id': obj.publishListId};
                        } else {
                            return "";
                        }
                    });

                    let arrPNames = [];
                    arrPNames = oData.map(function (obj, i) {
                        if (obj.publishListId && obj.listName) {
                            var oRes = {};
                            var okey = obj.listName;
                            oRes[okey] = obj.publishListId;
                            return oRes;
                        } else {
                            return "";
                        }
                    });

                    _this.setState({ availableList: arrListNames, arrPresentations: arrPNames, availableListWithId: arrListNamesWithId });
                }
            }
        })
            .catch( error => {
                console.log('right Drawer SavedListDropDown getSavedListNames  API call failed', error);

                NotificationManager({message:"Exception in getting List name", type:4});

                _this.resetDropdown();
            });
    }

    modalClose() {
        this.setState({isModalOpen: false});
    }

    modalOpen() {
        this.setState({isModalOpen: true});
    }

    deletePresentation(e) {
        console.log('delete presentation: ', e.target.getAttribute('name'));

        var postData = {};
        postData.listName = e.target.getAttribute('name');
        postData.userId = this.props.user.userId;

        this.setState({deleteData: postData, modalName: 'SavedPresentationDeleteConfirm'});
        this.modalOpen();
    }

    deletePresentationConfirmed() {
        let _this = this;
        let deletingListName = _this.state.deleteData.listName;

        console.log('delete data: ', _this.state.deleteData);

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        PublicationServices.deleteListName(_this.state.deleteData).then( (response) => {
            let res = response;

            NotificationManager({message:res.responseMessage, type:1});

            let listNames = _this.state.availableList;
            let deleteIndex = listNames.indexOf(deletingListName);

            if (deleteIndex >= 0) {
                listNames.splice(deleteIndex, 1);
                _this.setState({availableList: listNames});
            }
        })
            .catch( error => {
                console.log('right Drawer SavedListDropDown getSavedListNames  API call failed', error);

                NotificationManager({message:error, type:4});
            });

        _this.setState({ deleteData: {} });
    }

    render() {
        let availableList = this.state.availableList;

        return (
            <div>
                <div className="grid-view-dropdown">
                    <div
                        className={`selected-presentation ${(this.state.isListDropdownOpen) ? 'open' : ''}`}
                        onClick={this.toggleClickList}>
                        {this.state.presentationName}
                    </div>
                    <div className="availabel-list">
                        <ul>
                            {availableList.length == 0 ?
                                <li className="li-list selected text-center">List not available</li> :
                                (availableList.map((list, index) => {
                                    {
                                        if (list === this.state.presentationName) {
                                            return <li key={index} className="selected">{list}</li>;
                                        }

                                        return (
                                            <li key={index}
                                                className="li-list"
                                                name={list}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.handleClickList(e);
                                                }}
                                            >
                                                {list}
                                                <span
                                                    className="icon-close"
                                                    name={list}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.deletePresentation(e);
                                                    }}
                                                >
                                                </span>
                                            </li>
                                        );
                                    }
                                }, this))
                            }
                        </ul>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.isModalOpen}
                    addClass={`${(this.state.modalName == "SavedPresentationDeleteConfirm") ? 'delete-presentation-popup' : ''} `}
                >
                    {this.state.modalName === "SavedPresentationDeleteConfirm" &&
                    <SavedPresentationDeleteConfirm
                        viewDetails={this.viewDetails}
                        confirmDelete={this.deletePresentationConfirmed}
                        modalClose={this.modalClose}
                        {...this.props}
                    />
                    }
                </Modal>
            </div>
        );
    }
}

//mapping redux dispatch
const mapStateToProps = (state) => {
    return {
        isListDirty: state.isListDirty,
        gridDropDowns: state.gridDropDowns,
        currentListName: state.selectedPresentationName.name,
        currentListId: state.selectedPresentationName.id
    };
};

const mapDispatchToprops = (dispatch) =>{
    return {
        addPublicationGridData: (data) => {
            dispatch(actions.addPublicationGridData(data));
        },
        addPublicationColumnNames: (data) => {
            dispatch(actions.addPublicationColumnNames(data));
        },
        addListItemsNamesData: (data) => {
            dispatch(actions.addListItemsNamesData(data));
        },
        publicationCounter: (data) => {
            dispatch(actions.publicationCounter());
        },
        addPublicationGridDropDowns: (data) => {
            dispatch(actions.addGridDropDowns(data));
        },
        addSelectedPresentationName: (data) => {
            dispatch(actions.addSelectedPresentationName(data));
        },
        addPubListDropdownData: (data) => {
            dispatch(actions.addPubListDropdownData(data));
        },
        listDirty: (data) => {
            dispatch(actions.updateListDirty(data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToprops)(SavedListDropDown);
