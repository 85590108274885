import React, { Component } from 'react';
import Modal from '../../../modals/common-modal.js';
import * as actions from '../../../product/redux/productActions';
import * as p2Actions from '../../../product2/redux/product2Actions';
import { connect } from 'react-redux';
import OverWritePopup from './overWritePopup';

import axios from 'axios';

import NotificationManager from '../../../common/components/notification';

import { ProductServices } from '../../../product2/services/product-services';

class AddNewViewPopup extends Component {
    constructor(props) {
        super(props);

        console.log("ADD NEW VIEW STATE", props);

        this.state = {
            viewSelection:'personal',
            viewName:props.viewName,
            isAdmin:props.user.admin,
            isValid:true,
            Overwrite:false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.saveView = this.saveView.bind(this);
        this.checkForViewName = this.checkForViewName.bind(this);
        this.cancelOverWrite = this.cancelOverWrite.bind(this);

        this.CONSTATNS = {
            'personal': "PersonalView",
            'global': "SystemView"
        };
    }

    handleInputChange(e) {
        var target = e.target,
            name = target.name,
            value = target.value;

        this.setState({ [name]: value });
    }

    /**
     * checks for required fields and sends the data to backend
     */
    saveView() {
        var state = this.state;
        var _this = this;
        var postData = {};

        if (!state.viewName) {
            this.setState({ isValid: false });

            return;
        }

        // constants for backend
        var views = this.CONSTATNS;

        postData.viewName = state.viewName;
        postData.type = (state.isAdmin) ? views[state.viewSelection] : views['personal'];
        postData.userId = this.props.user.userId;
        postData.columnDetails = this.props.columnDetails;

        console.log('post data for save view: ', postData);

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.saveNewView(postData)
            .then( (response) => {
                let res = response.data;

                console.log('pageHeader: productheader addNewViewPopup api call success', res);

                if (res.status && res.data) {
                    _this.props._REDUX_DISPATCH_COLUMN_RESEQUENCE({
                        isSaveNewView: false,
                        afterSaveView: true,
                        where: 'add-view-popup'
                    });

                    _this.props.isColumnTouched(null); // reset the cols after save is done - Suraj

                    NotificationManager({
                        message: res.responseMessage,
                        type:1
                    });

                    var obj = {
                        type: (postData.type === 'PersonalView') ? 'personalView' : 'systemView',
                        viewId: res.data.id,
                        viewName: postData.viewName
                    };

                    let getColReq = {
                        id: parseInt(res.data.id),
                        type: (state.isAdmin) ? views[state.viewSelection] : views['personal'], userId:this.props.user.userId
                    };

                    let headers = {
                        'Authorization': this.props.user.token,
                        'content-type': 'application/json'
                    };

                    ProductServices.viewColumndetails(getColReq)
                        .then( (response) => {
                            let res = response.data;

                            _this.props.addColumnDetails(res.columnDetailsDTOs); // Redux update
                        })
                        .catch( error => {
                            console.log('failed at loadAllRole');
                        });

                    _this.props.updateNewView(obj);
                } else {
                    NotificationManager({
                        message:res.responseMessage,
                        type:4
                    });
                }

                _this.props.modalClose(false);
            })
            .catch( error => {
                console.log('pageHeader: productheader addNewViewPopup api call failure', error);
                NotificationManager({
                    message: error,
                    type: 4
                });
            });
    }

    /**
     * checks for user provided view name exists
     */
    checkForViewName() {
        var _this = this;
        var state = this.state;
        var postData = {};

        if (!state.viewName) {
            this.setState({ isValid: false });

            return;
        }

        // constants for backend
        var views = this.CONSTATNS;

        postData.viewName = state.viewName;
        postData.type = (state.isAdmin) ? views[state.viewSelection] : views['personal'];
        postData.userId = this.props.user.userId;

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.isViewExists(postData)
            .then( (response) => {
                let data = response.data;

                if (data.isExists) {
                    _this.setState({ Overwrite: true });
                } else {
                    _this.setState({ Overwrite: false });
                    _this.saveView();
                }
            })
            .catch( error => {
                console.log('pageHeader: productHeader addNewViewPopup checkForViewName api call failure', error);
            });
    }

    cancelOverWrite() {
        this.setState({ Overwrite: false });
    }

    render() {
        let props = this.props;
        let personal = (this.state.viewSelection === 'personal') ? true : false;

        return (
            <div>
                {!this.state.Overwrite &&
                <div>
                    <div className="modal-header">
                        Save View
                        <span className="close" onClick={() => {
                            props.modalClose(false);
                        }}>x</span>
                    </div>
                    <div className="modal-content">
                        <div className="modal-fields">
                            <label>view name</label>
                            <input
                                type="text"
                                onChange={this.handleInputChange}
                                value={this.state.viewName}
                                name='viewName'
                                className={`${(this.state.isValid) ? '' : 'border-error'}`}
                                maxLength={35}
                            />
                        </div>
                        <div>
                            <p className={`font-small ${(this.state.isValid) ? 'hide' : 'error-msg'}`}>Please Fill The Mandatory Field.</p>
                        </div>
                        {this.state.isAdmin &&
                        <div className="view-selection text-center">
                            <label className="blue-radio wt">
                                <input
                                    type="radio"
                                    name="viewSelection"
                                    value='personal'
                                    onChange={this.handleInputChange}
                                    checked={personal}
                                />
                                <label></label>
                                Personal View
                            </label>
                            <label className="blue-radio wt">
                                <input
                                    type="radio"
                                    name="viewSelection"
                                    value='global'
                                    onChange={this.handleInputChange}
                                    checked={!personal}
                                />
                                <label></label>
                                Global View
                            </label>
                        </div>
                        }
                        <div className="text-center modal-footer">
                            <button type="button" className="gray-button" onClick={() =>props.modalClose(false)}>Cancel</button>
                            <button type="button" className="gray-button" onClick={this.checkForViewName}>Save View</button>
                        </div>
                    </div>
                </div>
                }
                {this.state.Overwrite &&
                <OverWritePopup
                    {...props}
                    saveView={this.saveView}
                    cancel={this.cancelOverWrite}
                />
                }
            </div>
        );
    }
}

const mapDispatchToprops = (dispatch) => {
    return {
        _REDUX_DISPATCH_COLUMN_RESEQUENCE: (data) => {
            dispatch(actions.columnResequence(data));
        },
        addColumnDetails: (data) => {
            dispatch(actions.addColumnDetails(data));
        },
        isColumnTouched: (data) => {
            dispatch(p2Actions.isColumnTouched(data));
        }
    };
};

const addNewViewPopup = connect(null, mapDispatchToprops)(AddNewViewPopup);

export default addNewViewPopup;
