/**
 * column width declaration
 */
const columnWidth = {
    Category: 150,
    Region: 150,
};

/**
 * Grid data builder is used to create the object format required for
 * Jqwidgets.
 * @param {*} res
 * @param {*} pageName
 * @return {object}
 */
function GridDataBuilder(res, pageName) {
    // dataField is used for providing type of data and its name.
    // columnList is used to define column name, type, and extra fields
    //  to define additional plugins inside the cell eg: drop down, calender ...,
    const dataField = [];
    const columnList = [];
    let primayKey = res.columnDetails[0].fieldName;

    //sort columndetails in Workflow pageName
    res.columnDetails.sort(function (a, b) {
        return a.order - b.order;
    });

    const daysRenderer = function(row, columnfield, value, defaulthtml, columnproperties, rowdata) {
        if (rowdata.Region === 'Domestic') {
            let html = '<div class="grid-dropdown margin-handle-dropdown"><select  class="" data-row="' + row + '" data-userid="" data-columnfield="' + columnfield + '">';
            for (i = 1; i <= res.maxDays[0].Domestic; i++) {
                const val = i;
                let days = 'days';
                if (i == 1) {
                    days = 'day';
                }
                value = value.replace(' days', '');
                html += '<option value="';
                html += val;
                html += '"';
                if (val === parseInt(value)) {
                    html += 'selected ';
                }

                html += '>' + val + ' ' + days + '</option>';
            }
            html += '</select>';
            return html;
        } else {
            let html = '<div class="grid-dropdown margin-handle-dropdown"><select class="" data-row="' + row + '" data-userid="" data-columnfield="' + columnfield + '">';
            for (i = 1; i <= res.maxDays[0].Overseas; i++) {
                const val = i;
                let days = 'days';
                if (i == 1) {
                    days = 'day';
                }
                value = value.replace(' days', '');
                html += '<option value="';
                html += val;
                html += '"';
                if (val === parseInt(value)) {
                    html += 'selected ';
                }

                html += '>' + val + ' ' + days + '</option>';
            }
            html += '</select>';
            return html;
        }
    };


    for (let i = 0; i < res.columnDetails.length; i++) {
        dataField.push({'name': res.columnDetails[i].fieldName,
            'type': res.columnDetails[i].type.toLowerCase()});

        /** Enable the below condition after pk(primary key) availabel from backend
    *  in JSON response.
    */

        if (res.columnDetails[i].hasOwnProperty('pk') && res.columnDetails[i].pk) {
            primayKey = res.columnDetails[i].fieldName;
        }

        columnList.push(columnObj(res.columnDetails[i], res.maxDays));
    }


    /**
   * columnobj function will  return the object fields
  *  based on the field type in list.
  * e.g. for date type and other types need to build the cellrender function.
   * @param {*} list
   * @param {*} maxDays
   * @return {object}
   */
    function columnObj(list, maxDays) {
        const obj = {};

        obj.text = '<span title="' + list.columnTitle + '">' + list.columnTitle + '</span>';
        obj.dataField = list.fieldName;
        obj.width = (columnWidth[list.fieldName]) ? columnWidth[list.fieldName] : 150;
        obj.hidden = !list.visibility;

        if (list.type === 'dropdown') {
            obj.editable = false;
            obj.cellsrenderer = daysRenderer;
            obj.enabletooltips = false;
        } else if (list.type === 'String') {
            //If type is String make it not editable
            obj.editable = false;
            obj.pinned = true;
        }
        return obj;
    }

    const source = {
        dataType: 'json',
        dataFields: dataField,
        id: primayKey,
        localdata: res.data,
    };

    const dataAdapter = new $.jqx.dataAdapter(source);

    return {
        dataAdapter: dataAdapter,
        columns: columnList,
    };
}

export default GridDataBuilder;
