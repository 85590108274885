import React, { Component } from 'react';
import CancelModalDelete from '../../../common/modals/image-cancel.js';
import ImageUpload from './imageUpload';
import axios from 'axios';

import NotificationManager from '../../../common/components/notification';

import { ProductServices } from '../../../product2/services/product-services';

class VerticalImageViewer extends Component {
    constructor(props) {
        super(props);

        props.imageInfo.images = (props.imageInfo.images ? props.imageInfo.images : []);

        this.state = {
            imageArr: (props.imageInfo.images) ? props.imageInfo.images : [],
            mainImageSrc: (props.imageInfo.images.length > 0) ? props.imageInfo.images[0].imageUrl : '',
            imageName: (props.imageInfo.images.length > 0) ? props.imageInfo.images[0].imageName : '',
            imageId: (props.imageInfo.images.length > 0) ? props.imageInfo.images[0].imageId : '',
            isImageDeletable: (props.imageInfo.images.length > 0) ? props.imageInfo.images[0].deleteFlag : false,
            isCancelDelete: false,
        };

        this.updateMainViewer = this.updateMainViewer.bind(this);
        this.updateImageState = this.updateImageState.bind(this);
        this.getProductId = this.getProductId.bind(this);
        this.getDataLevel = this.getDataLevel.bind(this);
        this.getImageData = this.getImageData.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
        this.CancelModalDelete = this.CancelModalDelete.bind(this);
        this.deleteImageConfirm = this.deleteImageConfirm.bind(this);
    }

    componentDidMount() {
        const pIds = this.getProductId();

        const dataLevel = this.getDataLevel();
        const req = { 'chldProuctId': pIds, 'dataLevel': dataLevel };

        this.getImageData(req);
    }

    /**
     * Updates the image values
     * @param {number} val // value of index
     */
    updateMainViewer(val) {
        const currentSrc = this.state.imageArr[val].imageUrl;
        const imageName = this.state.imageArr[val].imageName;
        const imageId = this.state.imageArr[val].imageId;
        const isImageDeletable = this.state.imageArr[val].deleteFlag;

        this.setState({
            mainImageSrc: currentSrc,
            imageName: imageName,
            imageId: imageId,
            isImageDeletable:
            isImageDeletable
        });
    }

    /**
     * Updates image to state
     */
    updateImageState() {
        const imageState = this.state.imageArr;
        const imageTemp = imageState;

        this.setState({ imageArr: imageTemp });

        if (imageTemp.length > 0) {
            this.updateMainViewer(0);
        }
    }

    /**
     * Getter for the productID
     * @return {number} product id's are returned
     */
    getProductId() {
        const base = this.props.imageInfo;
        const pIds = [];

        pIds.push(base.productId);

        return pIds;
    }

    /**
     * Getter for the data level
     * @return {string} data level is returned
     */
    getDataLevel() {
        const base = this.props.imageInfo;

        return base.dataLevel;
    }

    /**
     * Getter for the image data calling the backend when the popup is opened
     * @param {object} req // Image data object
     */
    getImageData(req) {
        const _this = this;

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        ProductServices.getImageLst(req)
            .then( (response) => {
                const imageData = response.data;

                _this.setState({
                    imageArr: (imageData.data[0].images) ? imageData.data[0].images : [],
                    mainImageSrc: (imageData.data[0].images.length > 0) ? imageData.data[0].images[0].imageUrl : '',
                    imageName: (imageData.data[0].images.length > 0) ? imageData.data[0].images[0].imageName : '',
                    imageId: (imageData.data[0].images.length > 0) ? imageData.data[0].images[0].imageId : '',
                    isImageDeletable: (imageData.data[0].images.length > 0) ? imageData.data[0].images[0].deleteFlag : false,
                });
            })
            .catch( (error) => {
                console.log('productContainer helperComponents vertical-image-viewer API call failed', error);
                NotificationManager({
                    message: 'Request failed due to technical reasons, please try again',
                    type: 4,
                });
            });
    }

    /**
     * Deletes the Image and sets state
     */
    deleteImage() {
        this.setState({ isCancelDelete: true });
    }

    /**
     * Image confirmation popup
     */
    deleteImageConfirm() {
        const pIds = this.getProductId();
        const dataLevel = this.getDataLevel();
        const imageName = this.state.imageName;

        const req = {
            'imageName': imageName,
            'childrenIds': pIds,
            'dataLevel': dataLevel
        };

        const _this = this;

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        axios({
            method: 'post',
            url: require('../../../common/configurations.js').product2.deleteImageUploadDataV2,
            headers: headers,
            data: req,
        })
            .then( (response) => {
                const res = response.data;

                if (res.status) {
                    const imageObj = _this.state.imageArr;
                    const currentImageId = _this.state.imageId;

                    const data = $.grep(imageObj, function(e) {
                        return e.imageId != currentImageId;
                    });

                    _this.setState({ imageArr: data, isCancelDelete: false });

                    if (data.length > 0) {
                        _this.setState({
                            mainImageSrc: data[0].imageUrl,
                            imageName: data[0].imageName,
                            imageId: data[0].imageId,
                            isImageDeletable: data[0].deleteFlag,
                        });
                    } else {
                        _this.setState({
                            mainImageSrc: '',
                            imageName: '',
                            imageId: '',
                            isImageDeletable: false,
                        });
                    }

                    const obj = {};
                    obj.rowId = _this.props.imageInfo.uid;
                    obj.fieldName = 'images';
                    obj.data = data;

                    _this.props.updateImage(obj);

                    NotificationManager({
                        message: res.message,
                        type: 1,
                    });
                } else {
                    NotificationManager({
                        message: res.message,
                        type: 4,
                    });

                    _this.setState({ isCancelDelete: false });
                }
            })
            .catch( (error) => {
                console.log('productContainer helperComponents vertical-image-viewer API call failed', error);
                NotificationManager({
                    message: 'Something went wrong.Please try again after some time.',
                    type: 4,
                });

                _this.setState({ isCancelDelete: false });
            });
    }

    /**
     * Cancels the Delete or Approves it
     * @param {string} action // Yes/No to approve/cancel the delete
     */
    CancelModalDelete(action) {
        const _this = this;

        switch (action) {
            case 'yes':
                _this.deleteImageConfirm();
                break;

            case 'no':
                _this.setState({ isCancelDelete: false });
                break;
        }
    }

    render() {
        const noImgFix = this.state.mainImageSrc ? '' : 'noImgFix';
        const props = this.props;

        return (
            <div className="vertical-image-holder-for-product">
                <div className="rowHolder">
                    <div className="thumbnail-holder scroll">
                        <ul>
                            {
                                this.state.imageArr.map((item, index) => (
                                    <li
                                        className="thumbnails"
                                        key={index}
                                        onClick={() => (this.updateMainViewer(index))}
                                    >
                                        <img
                                            src={item.thumbNailUrl ? item.thumbNailUrl : item.imageUrl}
                                        />
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className={'main-image ' + noImgFix}>
                        {this.state.mainImageSrc &&
                        <div className="productImageContainer">
                            {this.props.modalName != 'publish_image' && this.state.isImageDeletable &&
                            <span className="closeIcon" onClick={this.deleteImage}>X</span>
                            }
                            <img src={this.state.mainImageSrc} />
                        </div>
                        }
                        {!this.state.mainImageSrc &&
                        <p>No Image</p>
                        }
                    </div>
                </div>

                <ImageUpload {...props} imageArray={this.state.imageArr} imageData={this.updateImageState} />

                <CancelModalDelete isOpen={this.state.isCancelDelete} modalClose={this.CancelModalDelete} />

            </div>
        );
    }
}

export default VerticalImageViewer;
