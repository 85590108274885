import React, { Component } from 'react';

var utils = require('../../common/utils');

class FilterBy extends Component{
    constructor(props){
        super(props);
        this.state = {
            FieldValuesList:[],
            allChecked:true,
            filterby:"",
            disableApplyBtn:false
        };
        this.fieldNamesValues = {};
        this.filterAppliedFields = [];
        this.fieldAllList = [];
        this.allrows = null;
        this.searchTimeout = null;
        this.applyFilter = this.applyFilter.bind(this);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
    }
    clearFilter(fieldName){
        var _this = this;
        if(fieldName){
            delete _this.fieldNamesValues[fieldName];
            _this.filterAppliedFields = _this.filterAppliedFields.filter(item => item !== fieldName);
        }else{
            _this.fieldNamesValues = {};
            _this.filterAppliedFields = [];
        }
    }
    handleSearchChange(e){
        var _this = this;
        var target = e.target;
        var name = target.name;
        var value = target.value;
        var fieldName = this.props.fieldName;
        _this.setState({[name]:value});
        if(_this.searchTimeout){
            clearTimeout(_this.searchTimeout);
        }
        _this.searchTimeout = setTimeout(function(){
            var valuesList = _this.allrows;
            var searchlist = [];
            for(var i = 0;i < valuesList.length;i++){
                var o =  valuesList[i];
                var newval = o.value;
                if(["catId", "vendorId", "division", "department", "classColumn", "subClassColumn"].indexOf(fieldName) > -1){
                    var dropDownField = fieldName;
                    if(["catId", "division", "department", "classColumn", "subClassColumn"].indexOf(fieldName) > -1){
                        dropDownField = 'allCatId';
                    }
                    if(newval){
                        newval = utils.findObjByKeyVal(window.ProductGridDropDowns[dropDownField], newval);
                    }
                }

                newval = newval.toString().toLowerCase();
                if(value === "" || newval.indexOf(value.toLowerCase()) > -1){
                    searchlist.push(o);
                }
            }
            console.log(searchlist);
            _this.setState({FieldValuesList:searchlist});
        }, 300);
    }
    filterBy(fieldName){
        var _this = this;
        var result = [], rows = null;
        _this.fieldAllList = [];
        if(_this.filterAppliedFields.indexOf(fieldName) > -1){
            result = _this.fieldNamesValues[fieldName];
        }else{
            if(_this.filterAppliedFields.length > 0){
                rows = _this.props.productGrid.getView();
            }else{
                rows = _this.props.productGrid.getRows();
            }
            _this.getAllRowsList(rows, result, fieldName);
        }
        _this.allrows = result;
        var checkedCount = 0;
        var valuesList = result;
        valuesList.map(function(o, i){
            if(o.checked){
                checkedCount++;
            }
        });
        if(checkedCount != valuesList.length){
            if(checkedCount == 0){
                this.state.disableApplyBtn = true;
            }
            else{
                this.state.disableApplyBtn = false;
            }
            this.state.allChecked = false;
        }else{
            this.state.disableApplyBtn = false;
            this.state.allChecked = true;
        }
        this.state.filterby = "";

        _this.setState({FieldValuesList:result});
    }
    getAllRowsList(obj, result, fieldName){
        var _this = this;
        if(obj.length > 0){
            for(var i = 0;i < obj.length;i++){
                var newObj = Object.assign({}, obj[i]);
                //result.push(newObj);
                if(newObj[fieldName] != undefined && _this.fieldAllList.indexOf(newObj[fieldName]) === -1){
                    _this.fieldAllList.push(newObj[fieldName]);
                    var val = newObj[fieldName];
                    val = val == true ? 1 : val;
                    val = val == false ? 0 : val;
                    var valObj = {value:val, checked:true};
                    result.push(valObj);
                }
                if(obj[i].records && obj[i].records.length > 0){
                    _this.getAllRowsList(obj[i].records, result);
                }
            }
        }
    }
    applyFilter(){
        var _this = this;
        var checkedList = $('.filter-list input.filterVal:checked');
        var filtergroup = new $.jqx.filter();
        var fieldName = this.props.fieldName;
        checkedList.each(function(e){
            var filtertype = 'stringfilter';
            var inputVal = $(this).val();
            inputVal = inputVal == "1" ? "true" : inputVal;
            inputVal = inputVal == "0" ? false : inputVal;
            // create a new group of filters.
            var filter_or_operator = 1;
            var filtervalue = inputVal;
            var filtercondition = 'equal';
            var filter = filtergroup.createfilter(filtertype, filtervalue, filtercondition);
            filtergroup.addfilter(filter_or_operator, filter);
        });
        _this.props.productGrid.addFilter(fieldName, filtergroup);
        _this.props.productGrid.applyFilters();
        _this.props.closefilterBy();
        _this.fieldNamesValues[fieldName] = this.state.FieldValuesList;
        _this.filterAppliedFields.push(fieldName);
    }
    componentDidMount(){
        var _this = this;
        if(this.props.fieldName){
            this.filterBy(this.props.fieldName);
        }
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.fieldName !== this.props.fieldName){
            if(nextProps.fieldName){
                this.filterBy(nextProps.fieldName);
            }
        }
    }
    toggleCheckbox(e){
        var _this = this;
        var target = e.target;
        var value = target.value;
        var isChecked = target.checked;
        if(value == "all"){
            this.state.allChecked = isChecked;
        }
        var checkedCount = 0;
        var valuesList = this.state.FieldValuesList;
        valuesList.map(function(o, i){
            if(o.value == value)
            {
                o.checked = isChecked;
            }
            if(value === 'all'){
                o.checked = isChecked;
            }
            if(o.checked){
                checkedCount++;
            }
        });
        if(checkedCount != valuesList.length){
            if(checkedCount == 0){
                this.state.disableApplyBtn = true;
            }else{
                this.state.disableApplyBtn = false;
            }

            this.state.allChecked = false;
        }else{
            this.state.allChecked = true;
            this.state.disableApplyBtn = false;
        }
        _this.setState({FieldValuesList:valuesList});
    }
    isInViewPort(){
        var el = $('#drop-down');
        var win = $(window);
        // Storing top and left scroll position of window
        var viewport = {
            top : win.scrollTop(),
            left : win.scrollLeft()
        };
        // Getting the right and bottom position of the view port
        viewport.right = viewport.left + win.width();
        viewport.bottom = viewport.top + win.height();
        // Geting element boundary informations
        var bounds = $(el).offset();
        // calculating the bottom and right position of the element
        bounds.right = bounds.left + $(el).outerWidth() + 150;
        bounds.bottom = bounds.top + $(el).outerHeight();
        if(bounds.right < viewport.right){
            return true;
        }
        return false;
    }
    render(){
        var _this = this;
        var fieldName = this.props.fieldName;
        let MakeItem = function(x, i) {
            var text = x.value;
            if(["catId", "vendorId", "division", "department", "classColumn", "subClassColumn"].indexOf(fieldName) > -1){
                var dropDownField = fieldName;
                if(["catId", "division", "department", "classColumn", "subClassColumn"].indexOf(fieldName) > -1){
                    dropDownField = 'allCatId';
                }
                if(x.value){
                    text = utils.findObjByKeyVal(window.ProductGridDropDowns[dropDownField], x.value);
                }
            }else{
                text += "";
                if(["color_attribute", "size_attribute"].indexOf(fieldName) > -1){
                    if(text == "0" || text == ""){ text = "No " + fieldName.split('_')[0] + " selected";}
                }else{
                    if(text == "1"){text = "Checked";}
                    if(text == "0"){text = "Not checked";}
                    if(text == ""){text = "Empty";}
                }
            }
            text += "";

            return <li key={i}><label><input type="checkbox" className="filterVal" name="filterVal[]" onChange={_this.toggleCheckbox} checked={x.checked} value={x.value}/>{text}</label></li>;
        };

        var css = null;
        if(this.props.open){
            var elOffset = $('#drop-down').position();
            var wd = $('.sort-holder').width();
            if(!this.isInViewPort()){
                wd = -150;
            }
            css = {
                top:elOffset.top + 116,
                left:elOffset.left + wd + 116,
                display:'block'
            };
        }else{
            css = {
                display:'none'
            };
        }

        // var css= null;
        // if(this.props.open){
        //     var elOffset = $('#drop-down').position();
        //     var wd =$('.sort-holder').width();
        //     if(!this.isInViewPort()){
        //         wd=-150;
        //     }
        //     css={
        //     top:elOffset.top+35,
        //     left:elOffset.left+wd,
        //     display:'block'
        //      }
        // }else{
        //     css={
        //         display:'none'
        //     }
        // }
        return(
            <div className="filter-by-container" style={css}>
                <input type="text" name="filterby" className="searchFilter form-control" onChange={this.handleSearchChange} placeholder="Search" value={this.state.filterby}/>
                <ul className="filter-list scroll">
                    { this.state.FieldValuesList && this.state.FieldValuesList.length > 0 &&
                        <li><label><input type="checkbox" value="all" className="select-all" name="filterValAll" onChange={this.toggleCheckbox} checked={this.state.allChecked}/><b>Select All</b></label></li>
                    }
                    { this.state.FieldValuesList && this.state.FieldValuesList.length > 0 ?
                        this.state.FieldValuesList.map(MakeItem) : <li>No options found</li>}
                </ul>
                <button className="gray-button" disabled={this.state.disableApplyBtn} onClick={this.applyFilter}>Apply</button>
            </div>
        );
    }
}
export default FilterBy;
