import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import * as product2_actions from '../../product2/redux/product2Actions';

class CategoryTreeView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alerts: props.alerts
        };
        this.toggleView = this.toggleView.bind(this);
        this.refereshView = this.refereshView.bind(this);
        this.findCategoryObj = this.findCategoryObj.bind(this);
        this.openCatLink = this.openCatLink.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.isTotalCountZero = this.isTotalCountZero.bind(this);
        this.timer = 0;
        this.delay = 2000;
        this.prevent = false;
        this.isTotalCZero = null;
    }


    componentWillReceiveProps(nextProps) {
        console.log(' componentWillReceiveProps CategoryTreeView', nextProps, this.props, this.state);
        this.refereshView(nextProps);
    }


    handleClick(o) {
        this.timer = setTimeout(function () {
            if (!this.prevent) {
                this.openCatLink(o);
            }
            this.prevent = false;
        }, this.delay);
    }

    findCategoryObj(data, catId) {
        for (var i = 0; i < data.length; i++) {
            var o = data[i];
            o.isTabActive = o.isTabActive == undefined ? false : o.isTabActive;
            o.isOpen = o.isOpen == undefined ? false : o.isOpen;
            if (o.catId === catId) {
                o.isOpen = !o.isOpen;
                o.isTabActive = !o.isTabActive;
            }
            if (o.productCategory.length) {
                this.findCategoryObj(o.productCategory, catId);
            }
        }
    }

    toggleView(catId) {
        var data = this.props.alerts;
        this.findCategoryObj(data.category, catId);
        this.setState({ alerts: data });
    }

    refereshView(nextProps) {
        console.log("refreshing view....", nextProps.alerts);
        var data = nextProps.alerts;
        this.setState({ alerts: data });
    }

    openCatLink(catId, level) {
        if (level == 1) {
            // NotificationManager({message:"Double click sub categories to redirect details page",type:4});
            return;
        }
        var alert = this.state.alerts;
        var redirecturl = alert.url + '&&' + catId;
        var allow = true;
        if (allow) {
            if (alert.page == "product") {
                require('../../common/utils').createCookie('alertProductClickUrl', redirecturl);
                location.href = "/product";
            }
            if (alert.page == "creative") {
                require('../../common/utils').createCookie('creativeAlert', redirecturl);
                location.href = "/creative";
            }
            if (alert.page == "Marketing") {
                require('../../common/utils').createCookie('marketingAlert', redirecturl);
                location.href = "/marketing";
            }
        }
    }


    isTotalCountZero(catId, categoryList) {
        for (let i = 0; i < categoryList.length; i++) {
            if (catId === categoryList[i].catId) {
                if (categoryList[i].totalCount === 0 || categoryList[i].catName == "Seasons" || categoryList[i].catName == "Channels") { // added to disable click for Seasons and Channel - Urban Release - Suraj
                // if (categoryList[i].totalCount === 0) {
                    this.isTotalCZero = true;
                    break;
                }
                this.isTotalCZero = false;
                break;
            }
            if (categoryList[i].productCategory && categoryList[i].productCategory.length > 0) {
                this.isTotalCountZero(catId, categoryList[i].productCategory);
            }
        }
        if (this.isTotalCZero === null) {
            this.isTotalCZero = true;
        }
    }

    gotoGrid(catId, url) {
        console.log('going to grid, ', catId, this.state.alerts, this.props, url);
        const obj = this.state.alerts;
        const categoryList = obj.category;
        this.isTotalCountZero(catId, categoryList);
        console.log('is Total Cont Zero: ', this.isTotalCZero);
        if (this.isTotalCZero === true) {
            return;
        }
        let pagename = obj.page.toLowerCase() == 'product' ? 'product2' : obj.page.toLowerCase();

        this.props.setCurrentPage(pagename);

        let _this = this;
        _this.props.toggleSpinner(true);

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        let baseUrl =  require('../../common/configurations').apiURL + url;

        axios({
            method: 'get',
            url: baseUrl,
            headers: headers
        })
            .then((response) => {
                console.log('notifierComponent alertProductClick API call success data: ', response.data);
                let res = response.data;

                if (res.code == 200) {
                    console.log('pagenames: ', pagename);
                    if (pagename == 'product2') {
                        console.log('moving to products 2 grid');
                        let product2GridData = {};
                        product2GridData['data'] = res.responseData.data;
                        product2GridData['columnDetails'] = res.responseData.columnDetails;
                        product2GridData['recordCount'] = res.responseData.data.length;
                        product2GridData['catIds'] = [];
                        product2GridData['customFilters'] = [];
                        product2GridData['fromAlerts'] = true;
                        product2GridData['priorityAlertsForSort'] = {
                            'filters': [],
                            'url': baseUrl,
                        };
                        _this.props.addProduct2GridData(product2GridData);
                        _this.props.actionPerformedFrom({fromAlerts: true});
                        _this.props.filterDataChanged();
                        _this.props.toggleSpinner(false);
                        return;
                    }
                }
            })
            .catch((error) => {
                console.log('notifierComponent alertProductClick API call failed', error);
                _this.props.toggleSpinner(false);
            });

        _this.props.toggleSpinner(false);
    }

    categoryTreeView(catlist, level) {
        let items = catlist.map((category, index) => {
            return (
                <div key={index} className={`tree-node level` + level} data-level={level}>
                    <div className={`tree-node-list ${(category.isTabActive) ? 'active' : ''}`}>
                        {category.productCategory.length > 0 ?
                            <span className={`${(category.isOpen) ? 'icon-minus-blue' : 'icon-plus'}`} onClick={() => this.toggleView(category.catId)}></span>
                            : <span className="no-expand"></span>}
                        <span id={"id-" + category.catId} className="category " data-node="0" data-catid={category.catId}
                            onClick={() => this.toggleView(category.catId)}
                            onDoubleClick={() => {
                                this.openCatLink(category.catId, level);
                            }}>{category.catName}</span>
                        <span className="alert-count" onClick={() => this.gotoGrid(category.catId, category.url)} >{category.totalCount}</span>
                    </div>
                    {category.productCategory.length > 0 &&
                        <div className={`tree-node-item ${(category.isOpen) ? 'is-open' : ''}`}>
                            {this.categoryTreeView(category.productCategory, level + 1)}
                        </div>
                    }
                </div>
            );
        });
        return (<div>{items}</div>);
    }

    render() {
        console.log('CategoryTreeView', this.props, this.state, this.state.alerts.category);

        let level = 1;
        return this.categoryTreeView(this.state.alerts.category, level);
    }
}



const mapDispatchToprops = (dispatch) => {
    return {

        filterDataChanged: () => {
            dispatch(product2_actions.filterDataChanged());
        },
        addProduct2GridData: (data) => {
            dispatch(product2_actions.addProduct2GridData(data));
        },
        actionPerformedFrom: (data) => {
            dispatch(product2_actions.actionPerformedFrom(data));
        },
    };
};


export default connect(null, mapDispatchToprops)(CategoryTreeView);

// export default CategoryTreeView;
