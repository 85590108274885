import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import JqxGrid from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';


const AuditLogComponent = ({
    state,
    props,
    auditLogGrid,
    gridData,
    gridHeight,
    editSettings,
}) => {
    return (
        <div id="workFlowContainer">
            {state.isLoaded &&
        <div>
            <div id="auditLogid">
                <JqxGrid
                    ref={auditLogGrid}
                    width={'100%'}
                    source={gridData.dataAdapter}
                    pageable={false}
                    editable={false}
                    hierarchicalCheckboxes={true}
                    pagermode={'simple'}
                    columnsResize={true}
                    columns={gridData.columns}
                    sortable={true}
                    autoRowHeight={false}
                    autoWidth={true}
                    height={gridHeight}
                    theme={'light'}
                    editSettings={editSettings}
                    columnsreorder={true}
                    enabletooltips={true}
                    enableellipsis={true}
                    columnsmenu={false}
                />
            </div>

        </div>
            }
        </div>

    );
};

AuditLogComponent.propTypes = {
    state: PropTypes.object,
    props: PropTypes.object,
};

export default withRouter(AuditLogComponent);
