import React, {Component} from 'react';

export default class CustomHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ascSort: false,
            descSort: false,
            noSort: false
        };

        props.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
    }

    componentDidMount() {
        this.onSortChanged();
    }

    render() {
        let menu = null;
        if (this.props.enableMenu) {
            menu =
                <div ref={(menuButton) => { this.menuButton = menuButton; }}
                    className="customHeaderMenuButton custom-header-item"
                    onClick={this.onMenuClick.bind(this)}>
                    <i className={`ag-icon ag-icon-menu`}></i>
                </div>;
        }

        let sort = null;
        sort =  <div className="custom-header-item custom-header-icon">
            {this.state.ascSort &&
				<div onClick={this.onSortRequested.bind(this, '')} onTouchEnd={this.onSortRequested.bind(this, '')} className={`customSortDownLabel ag-icon ag-icon-asc ${this.state.ascSort}`}>
				    {/* <i class="fa fa-long-arrow-alt-down"></i> */}
				</div> }
            {this.state.descSort &&
				<div onClick={this.onSortRequested.bind(this, '')} onTouchEnd={this.onSortRequested.bind(this, '')} className={`customSortUpLabel ag-icon ag-icon-desc ${this.state.descSort}`}>
				    {/* <i class="fa fa-long-arrow-alt-up"></i> */}
				</div>
            }
        </div>;

        return (
            <div className="customHeader-wrap">
                <div className="customHeaderLabel custom-header-item">{this.props.displayName}</div>
                {sort}
                {menu}
            </div>
        );
    }
    onSortChanged() {
        this.setState({
            ascSort: this.props.column.isSortAscending(),
            descSort: this.props.column.isSortDescending(),
            noSort: !this.props.column.isSortAscending() && !this.props.column.isSortDescending()
        });
    }

    onMenuClick() {
        this.props.showColumnMenu(this.menuButton);
    }

    onSortRequested(order, event) {
        this.props.setSort(order, event.shiftKey);
    }
}
