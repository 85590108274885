import React from 'react';

export class Checkbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: props.data.value ? props.data.value : false,
            disabled: props.data.value ? props.data.value : false,
            error: false,
            errorMsg: '',
        };

        this.validateItemReadyToOrder = this.validateItemReadyToOrder.bind(this);
    }


    componentWillMount() {
        const data = this.props.props.node.data;
        const colId = this.props.data.data.field;
        const _this = this;
        let DisplayFlag = false;
        let CopyFlag = false;

        if (data) {
            if (this.props.props.node.data.displayLevel == 'Style' && this.props.props.node.level == 0) {
                DisplayFlag = true;
            } else if (this.props.props.node.data.displayLevel == 'Color' && this.props.props.node.level == 1) {
                DisplayFlag = true;
            } else if (this.props.props.node.data.displayLevel == 'Size' && this.props.props.node.level == 2) {
                DisplayFlag = true;
            } else if (this.props.props.node.data.dataLevel == 'SINGLE') {
                DisplayFlag = true;
            }
            if (this.props.props.node.data.copyLevel == 'Style' && this.props.props.node.level == 0) {
                CopyFlag = true;
            } else if (this.props.props.node.data.copyLevel == 'Color' && this.props.props.node.level == 1) {
                CopyFlag = true;
            } else if (this.props.props.node.data.copyLevel == 'Size' && this.props.props.node.level == 2) {
                CopyFlag = true;
            } else if (this.props.props.node.data.dataLevel == 'SINGLE') {
                CopyFlag = true;
            }
        }

        if (!DisplayFlag && ['itemReadyToOrder', 'earlyReqSampleSwatch', 'isSampleRequested', 'isSampleReceived', 'isSampleApproved', 'sampleMoved'].includes(colId)) {
            _this.setState({
                disabled: true,
            });
        }

        if (!CopyFlag && ['isPiRequestSent', 'isPiReceived', 'isPiApproved', 'isResendPiRequest', 'piHandoff'].includes(colId)) {
            _this.setState({
                disabled: true,
            });
        }

        if (['isCopywritterApproved', 'isWebproducerapproved'].includes(colId)) {
            _this.setState({
                disabled: true,
            });
        }
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const colId = this.props.data.data.field;
        const _this = this;
        let flag = false;
        const data = this.props.props.node.data;

        if (colId == 'itemReadyToOrder') {
            if (!this.validateItemReadyToOrder()) {
                flag = true;
                data['itemReadyToOrder'] = value;
                this.setState({
                    data: data,
                    error: false,
                    errorMsg: '',
                });
            }
        }


        if (['isPiRequestSent', 'isPiReceived', 'isPiApproved'].includes(colId)) {
            if (!this.validatePiWorkflow(colId)) {
                flag = true;
                data[colId] = value;
                this.setState({
                    data: data,
                    error: false,
                    errorMsg: '',
                });
            }
        }

        if (['isSampleRequested', 'isSampleReceived', 'isSampleApproved'].includes(colId)) {
            if (!this.validateSampleWorkflow(colId)) {
                flag = true;
                data[colId] = value;
                this.setState({
                    data: data,
                    error: false,
                    errorMsg: '',
                });
            }
        }

        if (flag) {
            let obj = {};

            obj['isBool'] = value;
            obj['col'] = colId;

            this.setState({
                checked: value,
                error: false,
                errorMsg: '',
            });

            this.props.saveValues(obj);
        }
    }

    validateSampleWorkflow(id) {
        const data = this.props.props.node.data;

        if (id == 'isSampleRequested') {
            if (!data.itemReadyToOrder) {
                this.setState({
                    errorMsg: 'Item Ready To Order flag has not been checked',
                    error: true,
                    checked: false,
                });
                return true;
            }
        }

        if (id == 'isSampleReceived') {
            if (!data.isSampleRequested) {
                this.setState({
                    errorMsg: 'Sample Requested has to be checked before Samples can be received',
                    error: true,
                    checked: false,
                });
                return true;
            }
        }

        if (id == 'isSampleApproved') {
            if (!data.isSampleReceived) {
                this.setState({
                    errorMsg: 'Sample Requested and Sample Received has to be checked before Samples can be approved',
                    error: true,
                    checked: false,
                });
                return true;
            }
        }
    }

    validatePiWorkflow(id) {
        const data = this.props.props.node.data;

        if (id == 'isPiRequestSent') {
            if (!data.itemReadyToOrder) {
                this.setState({
                    errorMsg: 'Item Ready To Order flag has not been checked',
                    error: true,
                    checked: false,
                });
                return true;
            }
        }

        if (id == 'isPiReceived') {
            if (!data.isPiRequestSent) {
                this.setState({
                    errorMsg: 'PI Request Sent has to be checked for PI to be Received',
                    error: true,
                    checked: false,
                });
                return true;
            }
        }

        if (id == 'isPiApproved') {
            if (!data.isPiReceived) {
                this.setState({
                    errorMsg: 'PI Request Sent & PI Received has to be checked for PI to be Approved',
                    error: true,
                    checked: false,
                });
                return true;
            }
        }
    }


    validateItemReadyToOrder () {
        const data = this.props.props.node.data;
        const mandatoryFields = this.props.itemReadyToOrderFields.map(function (obj) {
            return { [obj.actualFieldName]: obj.fields };
        });

        const stringofMandatoryFields = [];

        mandatoryFields.forEach((obj) => {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    stringofMandatoryFields.push(key);
                }
            }
        });

        const mandatoryFieldsNotPopulated = [];

        stringofMandatoryFields.map((i) => { // keys have been changed to match the data sent by the API
            if (i == 'channel') {
                i = 'channelIds';
            }
            if (i == 'division') {
                i = 'divisionName';
            }
            if (!data[i]) {
                mandatoryFieldsNotPopulated.push(i);
            }
        });

        if (mandatoryFieldsNotPopulated.length > 0) {
            for (let i = 0; i < mandatoryFieldsNotPopulated.length; i++) {  //changing the names back to display to the user
                if (mandatoryFieldsNotPopulated[i] == 'channelIds') {
                    mandatoryFieldsNotPopulated[i] = 'channel';
                }
                if (mandatoryFieldsNotPopulated[i] == 'divisionName') {
                    mandatoryFieldsNotPopulated[i] = 'division';
                }
            }

            this.setState({
                errorMsg: 'Please provide the following mandatory fields in order '
                + 'for ‘Ready to order’ checkbox to be active: ' + mandatoryFieldsNotPopulated.toString(),
                error: true,
            });
            return true;
        }
    }

    render() {
        return(
            <div className='single-view-checkbox'>
                <div className="blue-checkbox">
                    <input type="checkbox"
                        checked = {this.state.checked ? true : false}
                        disabled = {this.state.disabled ? true : false}
                        onChange = {this.handleInputChange.bind(this)}
                    />
                    <label></label>
                </div>
                {
                    this.state.error &&
                    <div className='checkbox-error-msg'>{this.state.errorMsg}</div>
                }
            </div>
        );
    }
}

