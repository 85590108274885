// var NotificationManager = require('../../common/notification');
import NotificationManager from '../../common/components/notification';

/*column width declaration
*/
var columnWidth = {
    productId: 120,
    prodName: 300,
    activeNotes: 400
};

var readableFields = [];
var writableFields = [];
window.readableFields = [];
window.writableFields = [];
window.gridEditedRows = [];

var checboxFields = ["isPiReceived",
    "isPiApproved",
    "isPiRequestSent",
    "isSampleapproved",
    "isSamplerecieved",
    "deleted",
    "attrApproved",
    "readyToActivate",
    "isPiRequestEarly",
    "isResendPiRequest",
    "itemReadyToOrder",
    "accessToItemReady",
    "earlyReqSampleSwatch",
    "backOrder",
    "sampleMoved",
    "attrCompleted",
    "isRetoucherApproved",
    "isCopywriterApproved",
    "isWebproducerApproved",
    'isActiveLive'];



var requiredFieldsList = ['fieldsToClone', 'readyToOrder', 'reqdToAdd'];
/*Date Editor funtion for Jqwidgets
*/

var copyPopupFields = ["itemReadyToOrder", "isPiRequestSent", "isPiReceived", "piUpload", "isPiApproved", 'copywrite', 'keywords',
    'attr1', 'attr2', 'attr3', 'attr4', 'attr5', 'attr6', 'attr7', 'attr8', 'attr9', 'attr10', 'attr11', 'attr12', 'attr13', 'attr14', 'attr15',
    'attr16', 'attr17', 'attr18', 'attr19', 'attr20', 'attr', 'attrCompleted', 'attrApproved', 'addPO', 'channel'];
var displayPopupFields = ["earlyReqSampleSwatch", "isSamplerecieved", "isSampleapproved", "swatchOnly", 'swatchReference',
    'featureColor', 'launchDate', 'onlineActivatedDate', 'activeNotes', 'quantityInventory', 'shotAngle', 'isActiveLive',
    'campaign', 'campaignDate', 'season'];

var createDateEditor = function (row, cellvalue, editor, celltext, width, height) {
    var todayDate = new Date();
    // construct the editor.
    cellvalue = cellvalue ? new Date(cellvalue) : null;
    editor.jqxDateTimeInput({
        width: width,
        height: height,
        value: cellvalue,
        culture: 'en-US',
        formatString: "MM/dd/yyyy",
        enableBrowserBoundsDetection: true,
        showFooter: true,
        clearString: 'Clear',
        min: new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate())
    });
};
var createDateTimeEditor = function (row, cellvalue, editor, celltext, width, height) {
    var todayDate = new Date();
    // construct the editor.
    cellvalue = cellvalue ? new Date(cellvalue) : null;
    editor.jqxDateTimeInput({
        width: width,
        height: height,
        value: cellvalue,
        culture: 'en-US',
        formatString: "MM/dd/yyyy HH:mm",
        enableBrowserBoundsDetection: true,
        showFooter: true,
        clearString: 'Clear'
    });
};

var initDateEditor = function (row, cellvalue, editor, celltext, pressedChar) {
    // set the editor's current value. The callback is called each time the editor is displayed.
    cellvalue = cellvalue ? new Date(cellvalue) : null;
    editor.jqxDateTimeInput('val', cellvalue);
    //editor.jqxDateTimeInput('open');
    //editor.jqxDateTimeInput({  height: height});
};
var getDateEditor = function (row, cellvalue, editor) {
    var rowData = this.owner.getrowdata(row);
    var cdate = $(editor).jqxDateTimeInput('val');
    var fieldName = this.datafield;
    if (fieldName === "launchDate" && rowData.campaignDate) {
        var campaignDate = new Date(rowData.campaignDate);
        var launchDate = new Date(cdate);
        var formatCDateStr = campaignDate.getFullYear() + "-" + (campaignDate.getMonth() + 1) + "-" + campaignDate.getDate();
        var formatLDateStr = launchDate.getFullYear() + "-" + (launchDate.getMonth() + 1) + "-" + launchDate.getDate();
        var formatCDate = new Date(formatCDateStr);
        var formatLDate = new Date(formatLDateStr);
        if (formatLDate > formatCDate) {
            NotificationManager({ message: "Launch date should not be greater than Campain date", type: 4 });
            return cellvalue;
        }
    }
    if (fieldName === "launchDate" && ["SP", "Intm"].indexOf(rowData.dataLevel) > -1) {
        return "";
    }
    // return the editor's value.
    return cdate;
};

// Including image in a column
var imageRenderer = function (row, columnfield, value, defaulthtml, columnproperties) {
    var rowdata = this.owner.getrowdata(row);
    var key = rowdata.productId;
    var image;
    var gridcontainer = '';
    var rowsheight = 0;
    if (rowdata.dataLevel == "Intm" && rowdata.level == 2) {
        gridcontainer = '#level2grid' + rowdata.level1;
    }
    else if (rowdata.dataLevel == "Sku" && rowdata.parentRef) {
        if (rowdata.level == 2) {
            gridcontainer = '#level2grid' + rowdata.level1;
        } else if (rowdata.level == 3) {
            gridcontainer = '#level3grid' + rowdata.level1 + rowdata.level2;
        } else {
            gridcontainer = '';
        }
    }
    if (gridcontainer) {
        rowsheight = $(gridcontainer).jqxGrid('rowsheight');
    } else {
        rowsheight = $('#jqxgrid').jqxGrid('rowsheight');
    }

    if (value.length && value[0].imageName) {
        var imageLink = "";
        if (value[0].tinyImageUrl) {
            imageLink = value[0].tinyImageUrl;
            if (rowsheight == 50) {
                imageLink = value[0].thumbNailUrl;
            }
        } else {
            imageLink = value[0].imageUrl;
        }
        // image = '<div class="product-image" data-row="' + key + '" grid-container="' + gridcontainer + '" style="max-height:' + rowsheight + 'px"><img src="'
        // 	+ imageLink + '" /></div>';

        image = '<div class="product-image" data-row="' + key + '" grid-container="' + gridcontainer + '"><img src="'
			+ imageLink + '" /></div>';
    } else {
        image = '<div class="product-image empty" data-row="' + key + '" grid-container="' + gridcontainer + '"  style="height:' + rowsheight + 'px"></div>'; // remove if required
        // image = '';
    }

    if (columnfield === 'imageApproval') {
        image = '';
    }

    return image;
};

// swatchOnly checkbox renderer
var swatchOnlyCellRender = function (row, columnfield, value, rowdata) {
    if (value && value > 0) {
        return '<span class="blue-checkbox"><input class="swatch-only" type="checkbox" checked data-row="' +
			row + '" ><label></label></span>';
    } else {
        return '<span class="blue-checkbox"><input class="swatch-only" type="checkbox"  data-row="' +
			row + '" ><label></label></span>';
    }
};
var convertDataType = function (value) {
    if (value && typeof (value) === "string") {
        if (value.toLowerCase() === "none") {
            value = false;
        }
        else if (value === "true") {
            value = true;
        }
        else if (value === "false") {
            value = false;
        }
    }
    return value;
};

var productCheckbox = function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
    //var rowdata = this.owner.getrowdata(row);
    var key = rowdata.productId;
    if (value && typeof (value) === "string") {
        if (value.toLowerCase() === "none") {
            value = false;
        }
        else if (value === "true") {
            value = true;
        }
        else if (value === "false") {
            value = false;
        }
    }
    value = convertDataType(value);
    // Removed "isPiRequestSent" , Added backed @01/06/2018 @5thplace
    var oneTimeCheck = ["isPiReceived", "isPiApproved", "isSampleapproved", "isSamplerecieved", "attrApproved",
            "isPiRequestSent", "readyToActivate", "attrCompleted", "earlyReqSampleSwatch", "sampleMoved", "isPiRequestEarly"],
        defaultDisabledList = ["isCopywriterApproved", "isWebproducerApproved", "isRetoucherApproved"],
        readyToActivateList = defaultDisabledList.concat(["attrApproved"]),
        disabled = '',
        defaultDisabled = '',
        sampledisabled = '';

    if (readableFields.indexOf(columnfield) > -1) {
        defaultDisabled = 'disabled';
    }

    if (writableFields.indexOf(columnfield) > -1) {
        defaultDisabled = '';
    }

    disabled = (oneTimeCheck.indexOf(columnfield) > -1) ? ' disabled ' : '';
    defaultDisabled = (defaultDisabledList.indexOf(columnfield) > -1) ? ' disabled ' : '';

    if (columnfield === oneTimeCheck[2]) {
        sampledisabled = convertDataType(rowdata[oneTimeCheck[3]]) != true ? 'disabled' : '';
    } else if (columnfield === "isSamplerecieved" && rowdata.swatchOnly && rowdata.swatchOnly.toLowerCase() == "none") {
        sampledisabled = 'disabled';
    } else if (columnfield === "readyToActivate") {
        // to enable checkbox for item ready to order disablist list(3) plus attrApproved should be checked
        readyToActivateList.forEach(function (item, index) {
            if (rowdata.swatchOnly && rowdata.swatchOnly.toLowerCase() == "none" && item === "isRetoucherApproved") {
                // Skip Retoucher Approved check
            } else {
                if (!rowdata[item]) {
                    defaultDisabled = ' disabled ';
                }
            }
        });
    } else if (columnfield === "isPiReceived") {
        if (convertDataType(rowdata["isPiRequestSent"]) != true) {
            defaultDisabled = ' disabled ';
        }
    } else if (columnfield === "isPiApproved") {
        // if (convertDataType(rowdata["isPiRequestSent"]) != true || convertDataType(rowdata["isPiReceived"]) != true) {
        // 	defaultDisabled = ' disabled ';
        // }
        if (convertDataType(rowdata["isPiReceived"]) != true) {
            defaultDisabled = ' disabled ';
        }
    } else if (columnfield === "attrApproved") {
        if (convertDataType(rowdata['attrCompleted']) != true) {
            defaultDisabled = ' disabled ';
        }
    } else if (columnfield === "isPiRequestSent" || columnfield === "isSamplerecieved") {
        // if(convertDataType(rowdata["itemReadyToOrder"])!=true){
        // 	defaultDisabled = ' disabled ';
        // }
        defaultDisabled = '';
    }
    else if (columnfield === "isActiveLive") {
        if (convertDataType(rowdata["readyToActivate"]) != true) {
            defaultDisabled = ' disabled ';
        }
    }


    if (rowdata.copyEditable === false && copyPopupFields.indexOf(columnfield) > -1) {
        defaultDisabled = 'disabled';
    }
    if (rowdata.displayEditable === false && displayPopupFields.indexOf(columnfield) > -1) {
        defaultDisabled = 'disabled';
    }

    var className = "product-boolean";
    if (columnfield === "attrCompleted") {
        className = "product-attr-completed";
        var attributeFieds = ['attr1', 'attr2', 'attr3', 'attr4', 'attr5', 'attr6', 'attr7', 'attr8', 'attr9', 'attr10',
            'attr11', 'attr12', 'attr13', 'attr14', 'attr15', 'attr16', 'attr17', 'attr18', 'attr19', 'attr20'];
        var maxlength = window.settings ? window.settings.maxAttributeLength : 0;
        var notEmptyCount = 0;
        if (maxlength > 0) {
            for (i = 0; i < attributeFieds.length; i++) {
                if (rowdata[attributeFieds[i]]) {
                    notEmptyCount++;
                }
            }
            if (notEmptyCount < maxlength) {
                defaultDisabled = "disabled";
            }
        }
    }

    // if (columnfield === 'isActiveLive' && rowdata.dataLevel != 'Sku') {
    // 	return '';
    // }
    var gridcontainer = '';
    if (rowdata.dataLevel == "Intm" && rowdata.level == 2) {
        gridcontainer = '#level2grid' + rowdata.level1;
    }
    else if (rowdata.dataLevel == "Sku" && rowdata.parentRef) {
        if (rowdata.level == 2) {
            gridcontainer = '#level2grid' + rowdata.level1;
        } else if (rowdata.level == 3) {
            gridcontainer = '#level3grid' + rowdata.level1 + rowdata.level2;
        } else {
            gridcontainer = '';
        }
    }
    if (rowdata.rowLocking) {
        defaultDisabled = "disabled";
    }
    var marginTop = 'margin-top:6px;';
    if (this.owner.rowsheight == 50) {
        marginTop = 'margin-top:17px;';
    }

    // Read or Write condition
    if (readableFields.indexOf(columnfield) > -1) {
        defaultDisabled = 'disabled';
    }

    // if (writableFields.indexOf(columnfield) > -1) {
    // 	defaultDisabled = '';
    // }
    //

    if (value) {
        return '<div class="jqx-grid-cell-middle-align" style="' + marginTop + '"><span class="blue-checkbox "><input class="' + className + '" type="checkbox" data-fieldname="' +
			columnfield + '" checked data-row="' + key + '"' + disabled + defaultDisabled + ' data-row-type="' + rowdata.dataLevel + '" data-parent="' +
			rowdata.referrence + '" grid-container="' + gridcontainer + '"><label></label></span></div>';
    } else {
        return '<div class="jqx-grid-cell-middle-align" style="' + marginTop + '"><span class="blue-checkbox "><input class="' + className + '" type="checkbox" data-fieldname="' +
			columnfield + '" data-row="' + key + '"' + defaultDisabled + ' ' + sampledisabled + ' data-row-type="' + rowdata.dataLevel + '" data-parent="' +
			rowdata.referrence + '" grid-container="' + gridcontainer + '"><label></label></span></div>';
    }
};
// Product Copywrite and Keyword Click
var CellDoubleClickEvent = function (row, columnfield, value, defaulthtml, columnproperties) {
    var rowdata = this.owner.getrowdata(row);
    var key = rowdata.productId;
    var gridcontainer = '';
    if (rowdata.dataLevel == "Intm" && rowdata.level == 2) {
        gridcontainer = '#level2grid' + rowdata.level1;
    }
    else if (rowdata.dataLevel == "Sku" && rowdata.parentRef) {
        if (rowdata.level == 2) {
            gridcontainer = '#level2grid' + rowdata.level1;
        } else if (rowdata.level == 3) {
            gridcontainer = '#level3grid' + rowdata.level1 + rowdata.level2;
        } else {
            gridcontainer = '';
        }
    }
    if (columnfield == "copywrite" || columnfield == "keywords") {
        value = "";
    }
    var marginTop = 'margin-top:6px;';
    if (this.owner.rowsheight == 50) {
        marginTop = 'margin-top:17px;';
    }
    var disableColor = "color:#999";
    if (rowdata.copyEditable === false && copyPopupFields.indexOf(columnfield) > -1) {
        return '<div class="jqx-grid-cell-left-align" style="' + marginTop + disableColor + '">' + value + '</div>';
    }
    else if (rowdata.displayEditable === false && displayPopupFields.indexOf(columnfield) > -1) {
        return '<div class="jqx-grid-cell-left-align" style="' + marginTop + disableColor + '">' + value + '</div>';
    } else {
        return '<div class="jqx-grid-cell-left-align" style="' + marginTop + '"><div class="grid-text ' + columnfield + '" data-row="' + key + '" grid-container="' + gridcontainer + '" dataField="' + columnfield + '">' + value + '<span class="icon-expand-grid right"></span></div></div>';
    }
};

// Product Copywrite and Keyword Click
var ClickAddPOLink = function (row, columnfield, value, defaulthtml, columnproperties, rowdata) {
    var key = rowdata.productId;
    var gridcontainer = '';
    if (rowdata.dataLevel == "Intm" && rowdata.level == 2) {
        gridcontainer = '#level2grid' + rowdata.level1;
    }
    else if (rowdata.dataLevel == "Sku" && rowdata.parentRef) {
        if (rowdata.level == 2) {
            gridcontainer = '#level2grid' + rowdata.level1;
        } else if (rowdata.level == 3) {
            gridcontainer = '#level3grid' + rowdata.level1 + rowdata.level2;
        } else {
            gridcontainer = '';
        }
    }
    if (window.readableFields.indexOf(columnfield) > -1) {
        return '';
    }
    else if (rowdata.copyEditable === false && copyPopupFields.indexOf(columnfield) > -1) {
        return '';
    }
    var marginTop = 'margin-top:6px;';
    if (this.owner.rowsheight == 50) {
        marginTop = 'margin-top:17px;';
    }
    return '<div class="jqx-grid-cell-middle-align" style="' + marginTop + '"><div class="' + columnfield + '" data-row="' + key + '" dataField="' + columnfield + '" data-vendorId="' + rowdata.vendorId + '" data-row-type="' + rowdata.dataLevel + '" data-parent="' + rowdata.referrence + '" grid-container="' + gridcontainer + '">Add PO</div></div>';
};

var cellClass = function (row, columnfield, value, data) {
    var rowData = this.owner.getrowdata(row);
    if(rowData != undefined) {
        var cellValue = rowData[columnfield];
        var colorindicator = '';
        if (["copywrite", "keywords"].indexOf(columnfield) > -1 && cellValue && cellValue != "") {
            colorindicator = "notempty";
        }
        if (columnfield == "copywrite" && rowData.copyStatus) {
            colorindicator = rowData.copyStatus.toLowerCase();
        }
        if (columnfield == "keywords" && rowData.keywordsStatus) {
            colorindicator = rowData.keywordsStatus.toLowerCase();
        }
        if (data.checked) {
            return 'grid-cell-selected';
        }
        return colorindicator;
    }
};

var campaignPopup = function (row, columnfield, value, rowdata) {
    var rowdata = this.owner.getrowdata(row);
    var key = rowdata.productId;
    var gridcontainer = '';
    if (rowdata.dataLevel == "Intm" && rowdata.level == 2) {
        gridcontainer = '#level2grid' + rowdata.level1;
    }
    else if (rowdata.dataLevel == "Sku" && rowdata.parentRef) {
        if (rowdata.level == 2) {
            gridcontainer = '#level2grid' + rowdata.level1;
        } else if (rowdata.level == 3) {
            gridcontainer = '#level3grid' + rowdata.level1 + rowdata.level2;
        } else {
            gridcontainer = '';
        }
    }
    if (rowdata.displayEditable === false && displayPopupFields.indexOf(columnfield) > -1) {
        return '';
    }
    var marginTop = 'margin-top:6px;';
    if (this.owner.rowsheight == 50) {
        marginTop = 'margin-top:17px;';
    }
    return '<div class="jqx-grid-cell-middle-left" style="' + marginTop + 'padding-right: 2px;"><div class="grid-text ' + columnfield + '" data-row="' + row + '" data-key="' + key + '" dataField="' + columnfield + '" grid-container="' + gridcontainer + '">' + value + '<span class="icon-expand-grid right campaign"></span></div></div>';
};

function findObjByKeyValue(arr, key, value) {
    var obj = null;
    for (var i = 0; i < arr.length; i++) {
        if (arr[i][key] == value) {
            obj = arr[i];
        }
    }
    return obj;
}

/**Grid data builder is used to create the object format required for
 * Jqwidgets.
 */
function GridDataBuilder(res, pageName, build) {
    window.dropdownslist = res.dropdowns;

    // if there is no data in res returning and not populating grid
    if(!res.columnDetails[0]) {
        return;
    }


    /** this will generate drop down template for the field name with type as DropDown,
	 *  the source for the dropdown should be available in the list response under dropdowns with
	 *  the same name as fieldName.
	 */
    var groupsrenderer = function (text, group, expanded, data) {
        if (["catId", "division", "department", "classColumn", "subClassColumn"].indexOf(data.groupcolumn.datafield) > -1) {
            var list = res.dropdowns[data.groupcolumn.datafield] ? res.dropdowns[data.groupcolumn.datafield] : [];
            var obj = findObjByKeyValue(list, 'fieldId', group);
            if (obj) {
                return '<div class="jqx-grid-groups-row " style="position: absolute;">' + data.groupcolumn.text + ':<span class="jqx-grid-groups-row-details">' + obj.fieldName + '</span></div>';
            }
            return '<div class="jqx-grid-groups-row " style="position: absolute;">' + data.groupcolumn.text + ':<span class="jqx-grid-groups-row-details">' + group + '</span></div>';
        }
        return '<div class="jqx-grid-groups-row " style="position: absolute;">' + data.groupcolumn.text + ':<span class="jqx-grid-groups-row-details">' + group + '</span></div>';
    };

    var channelNameRenderer = function (row, columnfield, value, defaulthtml, columnproperties) {
        var rowdata = this.owner.getrowdata(row);

        if (columnfield === 'channel') {
            if (rowdata.copyEditable === false && copyPopupFields.indexOf(columnfield) > -1) {
                return '';
            }
        }
        else {
            if (rowdata.displayEditable === false && displayPopupFields.indexOf(columnfield) > -1) {
                return '';
            }
        }


        var key = rowdata.productId;

        var dropdownKey = columnfield;
        var list = res.dropdowns[dropdownKey] ? res.dropdowns[dropdownKey] : [];
        //var obj = findObjByKeyValue(list, 'fieldId', value);
        var marginTop = 'margin-top:6px;';

        var placeHolder = {
            vendorId: 'Vendor',
            catId: 'Category',
            swatchOnly: 'Swatch',
            color_attribute: "Color",
            size_attribute: "Size",
            channel: "Channel",
            season: "Season"
        };

        var disableColor = "";
        if (rowdata.rowLocking) {
            disableColor = "color:#999";
        }
        else if (window.readableFields.indexOf(columnfield) > -1) {
            disableColor = "color:#999";
        }

        var valarray = value.split(',');
        var obj = null;
        let j = 0;
        for (var i = 0; i < list.length; i++) {
            //if (list[i]['fieldId'] == valarray) {
            if (valarray.includes(list[i]['fieldId'])) {
                j++;
                if (j <= 1) {
                    obj = list[i]['fieldName'];
                }
                else {
                    obj += ',' + list[i]['fieldName'];
                }
            }
        }
        if (obj) {
            return '<div class="jqx-grid-cell-left-align" style="' + marginTop + disableColor + '">' + obj + '</div>';
        } else {
            return '<div class="jqx-grid-cell-left-align" style="' + marginTop + disableColor + '">Select ' + placeHolder[dropdownKey] + '</div>';
        }
    };

    var catNameAndVendorNameRender = function (row, columnfield, value, defaulthtml, columnproperties) {
        var rowdata = this.owner.getrowdata(row);
        var key = rowdata.productId;
        var dropdownKey = columnfield;
        if (columnfield == "catId") {
            dropdownKey = "allCatId";
        }
        var disableColor = "";
        if (rowdata.rowLocking) {
            disableColor = "color:#999";
        }
        else if (window.readableFields.indexOf(columnfield) > -1) {
            disableColor = "color:#999";
        }

        var list = res.dropdowns[dropdownKey] ? res.dropdowns[dropdownKey] : [];
        if (columnfield == "vendorId" && window.newVendorListRes && window.newVendorListRes.length > 0) {
            window.newVendorListRes.map(function (o, i) {
                list.push(o);
            });
            window.newVendorListRes = null;
        }
        var obj = findObjByKeyValue(list, 'fieldId', value);
        var addVendorBtn = '';
        if (columnfield === "vendorId" && window.writableFields.indexOf(columnfield) > -1) {
            addVendorBtn = '<span title="Add New Vendor" class="icon-expand-grid right addVendorFromProductGrid" data-row="' + key + '"></span>';
        }
        if (columnfield === "catId" && window.writableFields.indexOf(columnfield) > -1) {
            addVendorBtn = '<span title="Select Category" class="icon-expand-grid right selectcategory"></span>';
        }
        if ((value == "" || value == undefined || value == null) && columnfield === "vendorId") {
            value = "Select Vendor";
        }
        if ((value == "" || value == undefined || value == null) && columnfield === "catId") {
            value = "Select Category";
        }
        var marginTop = 'margin-top:6px;';
        if (this.owner.rowsheight == 50) {
            marginTop = 'margin-top:17px;';
        }

        if (obj) {
            if ((rowdata.itemReadyToOrder == true && columnfield === "vendorId")
				|| (rowdata.isSampleapproved == true && columnfield === "catId")
				|| (rowdata.dataLevel !== "SP" && rowdata.parentRef != null)) {
                disableColor = "color:#999";
                return '<div class="jqx-grid-cell-left-align" style="' + marginTop + disableColor + '">' + obj.fieldName + '</div>';
            }
            return '<div class="jqx-grid-cell-left-align" style="' + marginTop + disableColor + '"><span>' + obj.fieldName + addVendorBtn + '</span></div>';
        } else {
            return '<div class="jqx-grid-cell-left-align" style="' + marginTop + disableColor + '"><span>' + value + addVendorBtn + '</span></div>';
        }
    };

    var PiUploadField = function (row, columnfield, value, rowdata) {
        if (rowdata.copyEditable === false && copyPopupFields.indexOf(columnfield) > -1) {
            return '';
        }

        let btn = '<span title="Upload PI" class="icon-expand-grid right upload-pi"></span>';
        return '<span>' + btn + '</span>';
    };


    var catFieldsRenderer = function (row, columnfield, value, defaulthtml, columnproperties) {
        var list = res.dropdowns.allCatId ? res.dropdowns.allCatId : [];
        var obj = findObjByKeyValue(list, 'fieldId', value);
        var disableColor = "";
        var rowdata = this.owner.getrowdata(row);
        var addVendorBtn = '';
        if (rowdata.rowLocking) {
            disableColor = "color:#999";
            addVendorBtn = '';
        }
        else if (window.readableFields.indexOf(columnfield) > -1) {
            disableColor = "color:#999";
            addVendorBtn = '';
        } else if (rowdata.isSampleapproved == true || (rowdata.dataLevel !== "SP" && rowdata.parentRef != null)) {
            addVendorBtn = '';
        } else {
            addVendorBtn = '<span title="Select Category" class="icon-expand-grid right selectcategory"></span>';
        }

        if ((value == "" || value == undefined || value == null) && columnfield === "catId") {
            value = "Select Category";
        }
        var marginTop = 'margin-top:6px;';
        if (this.owner.rowsheight == 50) {
            marginTop = 'margin-top:17px;';
        }

        if (obj) {
            return '<div class="jqx-grid-cell-left-align" style="' + marginTop + disableColor + '"><span>' + obj.fieldName + addVendorBtn + '</span></div>';
        } else {
            return '<div class="jqx-grid-cell-left-align" style="' + marginTop + disableColor + '"><span>' + value + addVendorBtn + '</span></div>';
        }
    };

    var VariantDropDownEditorInit = function (row, cellvalue, editor, celltext, pressedChar) {
        // set the editor's current value. The callback is called each time the editor is displayed.
        editor.jqxDropDownList('selectItem', cellvalue);
    };

    var VariantDropDownEditorUpdater = function (row, cellvalue, editor) {
        // return the old value, if the new value is empty.
        if (cellvalue && cellvalue.indexOf(',') > -1) {
            return cellvalue;
        } else {
            return editor.val();
        }
    };
    // Dropdown editor for product variants ex: color, size, model, etc
    var VariantDropDownEditor = function (row, cellvalue, editor, celltext, width, height) {
        // assign a new data source to the dropdownlist.
        var fieldName = this.datafield.split('_')[0];
        var list = Object.assign([], res.dropdowns[fieldName] ? res.dropdowns[fieldName] : []),
            enableAutoHieght = list.length < 7 ? true : false;

        // Pushing static value for variants
        if (list.indexOf('Create Multi Product') < 0) {
            list.push('Create Multi Product');
        }
        if (this.datafield === "color_attribute") {
            list.unshift({ fieldId: "", fieldName: "Select Color" });
        }
        if (this.datafield === "size_attribute") {
            list.unshift({ fieldId: "", fieldName: "Select Size" });
        }

        editor.jqxDropDownList({
            autoDropDownHeight: enableAutoHieght,
            source: list,
            dropDownHeight: 205,
            width: '125px',
            height: height,
            displayMember: "fieldName",
            valueMember: "fieldId",
            theme: 'light',
            autoOpen: true,
            enableBrowserBoundsDetection: true,
            filterable: true,
            filterDelay: 300,
            filterNoItemText: 'No options found',
            // selectedIndex: 0,
            searchMode: 'containsignorecase',
            placeHolder: 'Select ' + fieldName
        });
    };

    var DropDownEditor = function (row, cellvalue, editor, celltext, width, height) {
        // assign a new data source to the dropdownlist.
        var fieldName = this.datafield;
        var list = res.dropdowns[fieldName] ? Object.assign([], res.dropdowns[fieldName]) : [],
            enableAutoHieght = list.length < 7 ? true : false;
        var placeHolder = {
            vendorId: 'Vendor',
            catId: 'Category',
            swatchOnly: 'Swatch',
            color_attribute: "Color",
            size_attribute: "Size",
            channel: "Channel",
            season: "Season"
        };

        if (fieldName === "vendorId") {
            list.unshift({ fieldId: "", fieldName: "Select Vendor" });
        }
        if (fieldName === "color_attribute") {
            list.unshift({ fieldId: "", fieldName: "Select Color" });
        }
        if (fieldName === "size_attribute") {
            list.unshift({ fieldId: "", fieldName: "Select Size" });
        }

        var finalPlaceholder = (placeHolder[fieldName]) ? placeHolder[fieldName] : fieldName;
        if (fieldName === "channel" || fieldName === "season") {
            editor.jqxDropDownList({
                autoDropDownHeight: enableAutoHieght,
                source: list,
                dropDownHeight: 205,
                // width: '100%',
                width: '125px',

                height: height,
                displayMember: "fieldName",
                valueMember: "fieldId",
                theme: 'light',
                autoOpen: true,
                enableBrowserBoundsDetection: true,
                filterable: true,
                // selectedIndex: 0,
                searchMode: 'containsignorecase',
                placeHolder: 'Select ' + finalPlaceholder,
                checkboxes: true
            });
        } else {
            editor.jqxDropDownList({
                autoDropDownHeight: enableAutoHieght,
                source: list,
                dropDownHeight: 205,
                // width: '100%',
                width: '125px',

                height: height,
                displayMember: "fieldName",
                valueMember: "fieldId",
                theme: 'light',
                autoOpen: true,
                enableBrowserBoundsDetection: true,
                filterable: true,
                // selectedIndex: 0,
                searchMode: 'containsignorecase',
                placeHolder: 'Select ' + finalPlaceholder
            });
        }
    };

    var DropDownInit = function (row, cellvalue, editor, celltext, width, height, pressedChar) {
        // set the editor's current value. The callback is called each time the editor is displayed.
        var fieldName = this.datafield;
        var list = res.dropdowns[fieldName] ? res.dropdowns[fieldName] : [],
            enableAutoHieght = list.length < 7 ? true : false;
        var placeHolder = {
            vendorId: 'Vendor',
            catId: 'Category',
            swatchOnly: 'Swatch',
            color_attribute: "Color",
            size_attribute: "Size",
            channel: "Channel",
            season: "season"
        };
        var sourcelist = editor.jqxDropDownList('source');

        /*if (fieldName === "vendorId") {
			list.unshift({ fieldId: "", fieldName: "Select Vendor" });
		}
		if (fieldName === "color_attribute") {
			list.unshift({ fieldId: "", fieldName: "Select Color" });
		}
		if (fieldName === "size_attribute") {
			list.unshift({ fieldId: "", fieldName: "Select Size" });
		}*/

        var finalPlaceholder = (placeHolder[fieldName]) ? placeHolder[fieldName] : fieldName;
        var options = {
            //autoDropDownHeight: enableAutoHieght,
            //source: sourcelist,
            //dropDownHeight: 205,
            width: '125px',

            // width: width,
            height: height,
            //displayMember: "fieldName",
            //valueMember: "fieldId",
            //theme: 'light',
            //autoOpen: true,
            //enableBrowserBoundsDetection: true,
            //filterable: true,
            // selectedIndex: 0,
            //searchMode: 'containsignorecase',
            //placeHolder: 'Select ' + finalPlaceholder
        };
        editor.jqxDropDownList(options);
        if (fieldName === "channel" || fieldName === "season") {
            // if (window.newVendorList && window.newVendorList.length > 0) {
            // 	window.newVendorList.map(function (o, i) {
            // 		var item = editor.jqxDropDownList('getItemByValue', o.fieldId);
            // 		if (item === undefined) {
            // 			editor.jqxDropDownList('addItem', { label: o.fieldName, value: o.fieldId });
            // 		}
            // 	});
            // }
            editor.jqxDropDownList('uncheckAll');
            editor.jqxDropDownList('clearFilter');

            cellvalue = cellvalue ? cellvalue : "";
            //var PosEmailId = "4,5";
            var valarray = cellvalue.split(',');
            var obj = [];
            for (var i = 0; i < sourcelist.length; i++) {
                //if (list[i]['fieldId'] == valarray) {
                if (valarray.includes(sourcelist[i]['fieldId'])) {
                    obj.push(sourcelist[i]['fieldId']);
                }
            }

            $.each(obj, function (key, value) {
                editor.jqxDropDownList('checkItem', value);
            });
        }
        else {
            if (window.newVendorList && window.newVendorList.length > 0) {
                window.newVendorList.map(function (o, i) {
                    var item = editor.jqxDropDownList('getItemByValue', o.fieldId);
                    if (item === undefined) {
                        editor.jqxDropDownList('addItem', { label: o.fieldName, value: o.fieldId });
                    }
                });
            }
            cellvalue = cellvalue ? cellvalue : "";
            editor.jqxDropDownList('selectItem', cellvalue);
        }
    };

    var DropDownUpdater = function (row, cellvalue, editor) {
        // return the old value, if the new value is empty.

        var fieldName = this.datafield;
        if (fieldName === "channel" || fieldName === "season") {
            return editor.val();
        }


        if (cellvalue && cellvalue.indexOf(',') > -1) {
            return cellvalue;
        } else {
            return editor.val();
        }
    };

    // checkbox renderer
    var itemReadyCellRender = function (row, columnfield, value, defaulthtml, columnproperties) {
        var rowdata = this.owner.getrowdata(row);
        var key = rowdata.productId;
        var disabled = "", checked = "", defaultDisabled = "",
            disabledClass = '';

        if (readableFields.indexOf(columnfield) > -1) {
            defaultDisabled = ' disabled';
        }

        if (writableFields.indexOf(columnfield) > -1) {
            defaultDisabled = '';
        }

        if (window.readyToOrderFields && window.readyToOrderFields.length > 0) {
            // checking if all the fields required for ready to order is filled.
            window.readyToOrderFields.forEach(function (item, index) {
                if (!rowdata[item]) {
                    //disabled = ' disabled ';
                    disabledClass = 'disabledReadyToOrder';
                    checked = '';
                }
            });
        }

        // disable the check box until all the users provide required fields for item ready to order

        if (rowdata.addPoStatus && value) {
            disabled = " disabled ";
        }
        else if (rowdata.copyEditable == false) {
            if (copyPopupFields.indexOf(columnfield) > -1) {
                disabled = 'disabled';
            }
        }
        else if (rowdata.displayEditable == false) {
            if (displayPopupFields.indexOf(columnfield) > -1) {
                disabled = 'disabled';
            }
        }

        if (value) {
            checked = " checked ";
            disabled = 'disabled';
        }

        var gridcontainer = '';
        if (rowdata.dataLevel == "Intm" && rowdata.level == 2) {
            gridcontainer = '#level2grid' + rowdata.level1;
        }
        else if (rowdata.dataLevel == "Sku" && rowdata.parentRef) {
            if (rowdata.level == 2) {
                gridcontainer = '#level2grid' + rowdata.level1;
            } else if (rowdata.level == 3) {
                gridcontainer = '#level3grid' + rowdata.level1 + rowdata.level2;
            } else {
                gridcontainer = '';
            }
        }
        if (rowdata.rowLocking) {
            defaultDisabled = "disabled";
        }
        var marginTop = 'margin-top:6px;';
        if (this.owner.rowsheight == 50) {
            marginTop = 'margin-top:17px;';
        }
        return '<div class="jqx-grid-cell-middle-align" style="' + marginTop + '"><span class="blue-checkbox "><input class="product-boolean ' + disabledClass + '" type="checkbox" data-fieldname="' +
			columnfield + '" ' + disabled + ' ' + defaultDisabled + checked + ' data-row="' + key + '" data-row-type="' +
			rowdata.dataLevel + '" data-parent="' + rowdata.referrence + '" grid-container="' + gridcontainer + '"/><label></label></span>';
    };


    // var cellbegineditrowlocking = function (row, datafield, columntype, value) {
    // 	var rowdata = this.owner.getrowdata(row);
    // 	let doCallEndCellEdit = false;
    // 	if (rowdata) {
    // 		if (rowdata.rowLocking) {
    // 			doCallEndCellEdit = true;
    // 		}
    // 		else if (datafield !== "launchDate" &&
    // 			((rowdata.copyEditable === false && copyPopupFields.indexOf(datafield) > -1) ||
    // 				(rowdata.displayEditable === false && displayPopupFields.indexOf(datafield) > -1))) {
    // 			doCallEndCellEdit = true;
    // 		}
    // 		else if ((datafield === "color_attribute" || datafield === "size_attribute") && rowdata.itemReadyToOrder) {
    // 			doCallEndCellEdit = true;
    // 		}
    // 		else if (["color_attribute", "size_attribute"].indexOf(datafield) > -1 && rowdata.parentRef && rowdata.dataLevel == "SP") {
    // 			doCallEndCellEdit = true;
    // 		}
    // 		else if (datafield == "swatchOnly" && rowdata.isSamplerecieved === true && rowdata.isSampleapproved === true) {
    // 			doCallEndCellEdit = true;
    // 		}
    // 		else if (datafield == "vendorId" && (rowdata.itemReadyToOrder === true || (rowdata.dataLevel != undefined && rowdata.dataLevel != "SP" && rowdata.parentRef))) {
    // 			doCallEndCellEdit = true;
    // 		}
    // 		else if (datafield === "launchDate" && rowdata.dataLevel != undefined && (rowdata.dataLevel == "SP" || rowdata.dataLevel == "Intm")) {
    // 			doCallEndCellEdit = true;
    // 		}
    // 		else if (rowdata.cronJobField === true && ["promoPrice", "retailPrice", "salesPrice"].indexOf(datafield) > -1) {
    // 			doCallEndCellEdit = true;
    // 		} else if (["color_attribute", "size_attribute"].indexOf(datafield) != -1 && rowdata.level != undefined && rowdata.level != 1) {
    // 			doCallEndCellEdit = true;
    // 		}
    // 	}

    // 	if (doCallEndCellEdit) {
    // 		return false;
    // 	}
    // }


    // var cellsrendererrowlocking = function (row, datafield, value, defaultHtml) {
    // 	var rowdata = this.owner.getrowdata(row);
    // 	let doCallEndCellEdit = false;
    // 	if (rowdata) {
    // 		if (rowdata.rowLocking) {
    // 			doCallEndCellEdit = true;
    // 		}
    // 		else if (window.readableFields.indexOf(datafield) > -1) {
    // 			doCallEndCellEdit = true;
    // 		}
    // 		else if (datafield !== "launchDate" &&
    // 			((rowdata.copyEditable === false && copyPopupFields.indexOf(datafield) > -1) ||
    // 				(rowdata.displayEditable === false && displayPopupFields.indexOf(datafield) > -1))) {
    // 			doCallEndCellEdit = true;
    // 		}
    // 		else if ((datafield === "color_attribute" || datafield === "size_attribute") && rowdata.itemReadyToOrder) {
    // 			doCallEndCellEdit = true;
    // 		}
    // 		else if (["color_attribute", "size_attribute"].indexOf(datafield) > -1 && rowdata.parentRef && rowdata.dataLevel == "SP") {
    // 			doCallEndCellEdit = true;
    // 		}
    // 		else if (datafield == "swatchOnly" && rowdata.isSamplerecieved === true && rowdata.isSampleapproved === true) {
    // 			doCallEndCellEdit = true;
    // 		}
    // 		else if (datafield == "vendorId" && (rowdata.itemReadyToOrder === true || (rowdata.dataLevel != undefined && rowdata.dataLevel != "SP" && rowdata.parentRef))) {
    // 			doCallEndCellEdit = true;
    // 		}
    // 		else if (datafield === "launchDate" && rowdata.dataLevel != undefined && (rowdata.dataLevel == "SP" || rowdata.dataLevel == "Intm")) {
    // 			doCallEndCellEdit = true;
    // 		}
    // 		else if (rowdata.cronJobField === true && ["promoPrice", "retailPrice", "salesPrice"].indexOf(datafield) > -1) {
    // 			doCallEndCellEdit = true;
    // 		} else if (["color_attribute", "size_attribute"].indexOf(datafield) != -1 && rowdata.level != undefined && rowdata.level != 1 && !rowdata.cloned) {
    // 			doCallEndCellEdit = true;
    // 		}
    // 	}

    // 	if (doCallEndCellEdit) {
    // 		var element = $(defaultHtml);
    // 		element.css('color', '#999');
    // 		return element[0].outerHTML;
    // 	}
    // 	return defaultHtml;
    // }

    // dataField is used for providing type of data and its name.
    // columnList is used to define column name, type, and extra fields
    //  to define additional plugins inside the cell eg: drop down, calender ...,

    var dataField = [],
        columnList = [],
        primayKey = res.columnDetails[0].fieldName,
        pinnedList = [];
    // check for the type of fieldname future

    // Forcing product and Image order
    /*for(var i = 0; i < res.columnDetails.length; i++){
		if(res.columnDetails[i].fieldName==="productId"){
			res.columnDetails[i].order=1;
		}
		if(res.columnDetails[i].fieldName==="image"){
			res.columnDetails[i].order=0;
		}
	}*/
    //sort columndetails in product pageName
    /*res.columnDetails.sort(function (a,b) {
		return a.order - b.order;
	});*/

    for (var i = 0; i < res.columnDetails.length; i++) {
        dataField.push({
            "name": res.columnDetails[i].fieldName,
            "type": res.columnDetails[i].type.toLowerCase()
        });

        /* Enable the below condition after pk(primary key) availabel from backend
		*  in JSON response.
		*/

        if (res.columnDetails[i].hasOwnProperty("pk") && res.columnDetails[i].pk) {
            primayKey = res.columnDetails[i].fieldName;
        }
        // Forcing product and Image order
        /*if(res.columnDetails[i].fieldName==="productId"){
		res.columnDetails[i].order=1;
		}
		if(res.columnDetails[i].fieldName==="image"){
		res.columnDetails[i].order=0;
		}*/

        columnList.push(columnObj(res.columnDetails[i]));
    }

    //sort columndetails in product pageName
    /*res.columnDetails.sort(function (a,b) {
		return a.order - b.order;
	});*/

    function titleCase(str) {
        return str.toLowerCase().split(' ').map(function (word) {
            return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
    }

    /** columnobj function will  return the object fields
	 *  based on the field type in list.
	 * e.g. for Date type and other types need to build the cellrender function.
	 */
    function columnObj(list) {
        var obj = {};
        // obj.initeditor = function (row, column, editor) {
        // 	editor.attr('maxlength', (list.maxLength > 0) ? list.maxLength : 50);
        // }
        // var colTitle = list.columnTitle != null ? titleCase(list.columnTitle) : "";

        var colTitle = list.columnTitle ;
        var isShaded = (list.feildLevel === 'W') ? '' : 'col-header-grey';
        obj.text = '<span class="pd-drop ' + isShaded + '" title="' + colTitle + '" data-field="' + list.fieldName + '">' + colTitle + '</span>';

        obj.datafield = list.fieldName;	// required for values to show up
        obj.width = list.width;	// required

        obj.hidden = !list.visibility;

        // // obj.width = (columnWidth[list.fieldName]) ? columnWidth[list.fieldName] : 120;
        // //obj.cellsalign="center";

        obj.cellclassname = function (row, column, value, data) {
            if (data.checked) {
                return 'grid-cell-selected';
            }
        };

        // obj.cellclassname = function (row, column, value, data) {
        // 	if (data.deleted) {
        // 		return "strike-through deleted";
        // 	}
        // 	if (data.checked) {
        // 		return 'grid-cell-selected'
        // 	}
        // 	if (window.gridEditedRows.indexOf(data.productId) > -1) {
        // 		return "editedRow";
        // 	}
        // }
        // obj.cellbeginedit = cellbegineditrowlocking;
        // if (["deleted"].indexOf(list.fieldName) === -1) {
        // 	obj.aggregates = ["count"];
        // }
        // if (["salesPrice", "retailPrice", "promoPrice", "piQuantity",
        // 	"retailPoDueOrder", "ecomPoOnOrder", "quantityInventory"].indexOf(list.fieldName) > -1) {
        // 	obj.aggregates = ["sum"];
        // }


        //enable pinned/frozen column
        // if (pinnedList.indexOf(list.fieldName) > -1) {
        // 	obj.pinned = true;
        // }
        /* if list type is date, pass the methods related to date to intialize
		*  date picker
		*/

        /**
		 * Read Write Field Permission
		 */
        if (list.feildLevel === "R") {
            obj.editable = false;
            readableFields.push(list.fieldName);
            window.readableFields.push(list.fieldName);
        } else if (list.feildLevel === "W") {
            obj.editable = true;
            writableFields.push(list.fieldName);
            window.writableFields.push(list.fieldName);
        } else {
            obj.hidden = true;
        }

        if (["division", "department", "classColumn", "subClassColumn"].indexOf(list.fieldName) > -1) {
            obj.editable = false;
            obj.cellsrenderer = catFieldsRenderer;
        }
        else if (["shotAngle", "copywrite", "keywords"].indexOf(list.fieldName) > -1) {
            obj.cellsrenderer = CellDoubleClickEvent;
            obj.editable = false;
            obj.cellclassname = cellClass;
        } else if (list.fieldName === 'productId') {
            obj.editable = false;
            //obj.draggable = false;
            //obj.pinned=true;
            obj.cellsrenderer = function (row, column, value, defaultHtml, columnproperties) {
                var rowdata = this.owner.getrowdata(row);
                var disableColor = "color:#999";
                /*if (rowdata.rowLocking) {
					disableColor = "color:#999";
				}else if(window.readableFields.indexOf(column)>-1){
					disableColor = "color:#999";
				}*/
                var marginTop = 'margin-top:6px;padding-left:2px;';
                if (this.owner.rowsheight == 50) {
                    marginTop = 'margin-top:17px;pading-left:2px;';
                }

                return '<div class="jqx-grid-cell-left-align" style="' + marginTop + disableColor + '">' + value + '</div>';
            };
        }
        else if (list.type === "Date") {
            obj.columnType = "template";
            if (list.format === "Date & Time") {
                obj.cellsFormat = 'MM/dd/yyyy HH:mm';
                obj.createEditor = createDateTimeEditor;
            }else{
                obj.cellsFormat = 'MM/dd/yyyy';
                obj.createEditor = createDateEditor;
            }
            obj.initEditor = initDateEditor;
            obj.getEditorValue = getDateEditor;



            obj.initEditor = initDateEditor;
            obj.getEditorValue = getDateEditor;
            if (["sampleRecievedDate", "sampleApprovedDate", "piRequestedDate", "piReceivedDate", "copyWrittenDate",
                "isCopywritterApprovedDate", "photographedDate", "retouchercompletedDate", "piApprovedDate",
                "imgRetouchedDate", "campaignDate", "targetDate"].indexOf(list.fieldName) > -1) {
                obj.editable = false;
            }
        }
        else if (["attentionTo", "copyLevel", "displayLevel"].indexOf(list.fieldName) > -1) {
            obj.editable = false;
        } else if (list.fieldName === 'images') {
            obj.editable = false;
            obj.cellsrenderer = imageRenderer;
            obj.enabletooltips = false;
            //obj.draggable = false;
            // if (list.fieldName == "images") {
            // 	//obj.pinned=true;
            // }
        }
        else if (list.fieldName === "addPO") {
            obj.cellsrenderer = ClickAddPOLink;
            obj.editable = false;
        } else if (list.fieldName == 'swatchOnly') {
            obj.cellsrenderer = CellDoubleClickEvent;
            obj.columntype = 'template';
            obj.createeditor = DropDownEditor;
            obj.initeditor = DropDownInit;
            obj.geteditorvalue = DropDownUpdater;
        } else if (list.fieldName == 'campaign') {
            obj.cellsrenderer = campaignPopup;
            obj.editable = false;
        } else if (list.fieldName == 'swatchReference') {
            obj.editable = false;
        }
        else if (list.fieldName === 'vendorId') {
            obj.columntype = 'template';
            obj.cellsrenderer = catNameAndVendorNameRender;
            /*obj.createeditor = ComboBoxEditor;
			obj.initeditor =ComboBoxInit;
			obj.geteditorvalue = ComboBoxUpdater;*/
            obj.createeditor = DropDownEditor;
            obj.initeditor = DropDownInit;
            obj.geteditorvalue = DropDownUpdater;
            //obj.editable=true;
        }
        else if (list.fieldName === 'channel' || list.fieldName === 'season') {
            obj.columntype = 'template';
            obj.cellsrenderer = channelNameRenderer;
            obj.createeditor = DropDownEditor;
            obj.initeditor = DropDownInit;
            obj.geteditorvalue = DropDownUpdater;
            //obj.editable=true;
        }
        else if (list.fieldName === "catId") {
            obj.cellsrenderer = catNameAndVendorNameRender;
            obj.editable = false;
        }


        else if (list.fieldName === "featureColor") {
            obj.editable = false;
            obj.cellsrenderer = (row, datafield, value, defaultHtml) => {
                var expandIcon = '<span class="icon-expand-grid right"></span>';
                var marginTop = 'margin-top:6px;';
                return '<div class="jqx-grid-cell-left-align" style="' + marginTop + '">' + value + expandIcon + '</div>';
            };
        }



        else if (list.type === "DropDown") {
            obj.columntype = 'template';
            obj.createeditor = DropDownEditor;
            obj.initeditor = DropDownInit;
            obj.geteditorvalue = DropDownUpdater;
            obj.editable = true;
        } else if (list.fieldName === "itemReadyToOrder" ||
			list.fieldName === "accessToItemReady") {
            obj.editable = false;
            obj.cellsrenderer = itemReadyCellRender;
            obj.cellsalign = 'center';
        } else if (list.type === "boolean") {
            obj.editable = false;
            obj.cellsrenderer = productCheckbox;
            obj.cellsalign = 'center';
        } else if (list.type === 'list') {
            obj.columntype = 'template';
            obj.createeditor = VariantDropDownEditor;
            obj.initeditor = VariantDropDownEditorInit;
            obj.geteditorvalue = VariantDropDownEditorUpdater;
            //obj.initeditor = DropDownInit;
            //obj.geteditorvalue = DropDownUpdater;
            obj.type = "list";
            obj.editable = true;
            if (["color_attribute", "size_attribute"].indexOf(list.fieldName) > -1) {
                obj.cellsrenderer = function (row, columnfield, value, defaultHtml, columnproperties, rowdata) {
                    var label = {
                        color_attribute: "Select Color",
                        size_attribute: "Select Size"
                    };

                    if (value == "" || value == undefined || value == null) {
                        value = label[columnfield];
                    }
                    var marginTop = 'margin-top:6px;';
                    if (this.owner.rowsheight == 50) {
                        marginTop = 'margin-top:17px;';
                    }

                    let doCallEndCellEdit = false;
                    if (rowdata.rowLocking) {
                        doCallEndCellEdit = true;
                    }
                    else if (window.readableFields.indexOf(columnfield) > -1) {
                        doCallEndCellEdit = true;
                    }
                    else if ((columnfield === "color_attribute" || columnfield === "size_attribute") && rowdata.itemReadyToOrder) {
                        doCallEndCellEdit = true;
                    }
                    else if (["color_attribute", "size_attribute"].indexOf(columnfield) > -1 && rowdata.parentRef && rowdata.dataLevel == "SP") {
                        doCallEndCellEdit = true;
                    }
                    else if (["color_attribute", "size_attribute"].indexOf(columnfield) != -1 && rowdata.level != undefined && rowdata.level != 1 && !rowdata.cloned) {
                        doCallEndCellEdit = true;
                    }
                    var expandIcon = '<span class="icon-expand-grid right"></span>';
                    var disableColor = "";
                    if (doCallEndCellEdit) {
                        expandIcon = '';
                        disableColor = 'color:#999';
                    }

                    return '<div class="jqx-grid-cell-left-align" style="' + marginTop + disableColor + '">' + value + expandIcon + '</div>';
                };
            }
        } else if (list.fieldName === 'piUpload') {
            obj.cellsrenderer = PiUploadField;
            obj.editable = false;
        }
        else if (["promoPrice", "retailPrice", "salesPrice"].indexOf(list.fieldName) > -1) {
            // 	// obj.cellsrenderer = function(row, columnfield, value, rowdata){
            // 	// 	if(rowdata.cronJobField===true){
            // 	// 		return '<span class="" style="background-color:#f15a29">'+value+'</span>';
            // 	// 	}
            // 	// 	return value;
            // 	// };


            obj.cellClassName = function (row, dataField, cellText, rowData) {
                if (rowData.cronJobField === true) {
                    return "nonedit";
                }
                return "";
            };
        }

        // else if (["promoPrice", "retailPrice", "salesPrice"].indexOf(list.fieldName) > -1) {


        // 	// obj.cellsalign = 'right';
        // 	// obj.cellsformat = 'c2';
        // 	// list.type = 'float'
        // 	obj.cellsrenderer = function (row, columnfield, value, defaultHtml, columnproperties, rowdata) {
        // 	}

        // }
        // else if (list.fieldName === "featureColor") {
        // 	obj.editable = false;

        // }
        // Column format
        if (list.type.toLowerCase() == "int" || list.type.toLowerCase() == "float" || list.type.toLowerCase() == "double" || list.type.toLowerCase() == "number") {
            if (list.fieldName === "retailPrice") {
                list.type = 'float';
            }
            // cellsformat property
            // var cellsFormat = {
            // 	Standard: 2,
            // 	Integers: 0,
            // 	Currency: 2,
            // }
            var symbol = (list.format === "Currency") ? '$' : '';
            var symbol = '';

            // obj.type = list.type.toLowerCase();
            obj.cellsalign = 'right';

            obj.columntype = 'numberinput';

            if (list.format === "Currency") {
                obj.cellsformat = 'c2';

                // obj.cellsrenderer = (row, datafield, value, defaultHtml) => {
                // 	return '<div class="jqx-grid-cell-right-align" style="margin-top: 7px;"> $' + value + '</div>';
                // }
            }
            obj.createeditor = function (row, cellvalue, editor) {
                // editor.jqxNumberInput({ decimalDigits: cellsFormat[list.format], symbol: symbol, spinButtons: false });
                editor.jqxNumberInput({symbol: symbol, spinButtons: false });
            };
        }

        // if (!obj.cellsrenderer) {
        // 	obj.cellsrenderer = cellsrendererrowlocking;
        // }

        // cellsformat property
        // var cellsFormat = {
        // 	Standard: "f",
        // 	Integers: "n",
        // 	Currency: "c",
        // }

        // if (cellsFormat[list.format]) {
        // 	obj.cellsFormat = cellsFormat[list.format];
        // }
        return obj;
    }




    if (pageName == 'product') {
        var obj = {};
        obj.text = "<span class='header-checkbox pd-drop' data-checked='false' data-field='checkboxId'></span>";
        obj.dataField = 'checkboxId';
        obj.width = 100;
        // obj.columntype = 'checkbox';
        obj.editable = false;
        obj.sortable = true;
        // obj.pinned = true;
        // place the checkboxs in first columnList
        //columnList.unshift(obj);
        dataField.push({
            name: 'rowLocking',
            type: 'boolean'
        });
        dataField.push({
            name: 'cronJobField',
            type: 'boolean'
        });
        dataField.push({
            name: "dynaData",
            type: "json"
        });
    }
    /*dataField.push({
		name:'id',
		type:'number'
	});*/
    dataField.push({
        name: 'children',
        type: 'array'
    });
    var catListArray = ["vendorId", "catId", "division", "department", "classColumn", "subClassColumn"];
    for (var i = 0; i < catListArray.length; i++) {
        dataField.push({
            name: catListArray[i] + "_name",
            type: 'string'
        });
    }
    var resData = build ? res.data : treeify(res.data, "productId", "referrence", "children");

    return {
        results: resData,
        columns: columnList,
        datafields: dataField,
        gridIdType: "productId",
        groupsrenderer: groupsrenderer
    };
}

function generateData(data) {
    for (var i = 0; i < data.length; i++) {
        data[i].id = i + 1;
    }
    var list = treeify(data, "productId", "referrence", "children");
    return list;
}

function treeify(list, idAttr, parentAttr, childrenAttr) {
    console.log('productContainer gridDataBuilderTree before treeifying: ', list);
    if (!idAttr) idAttr = 'id';
    if (!parentAttr) parentAttr = 'parent';
    if (!childrenAttr) childrenAttr = 'children';

    list.sort(function(a, b){
        // Using the date to sort the grid. Most recent record comes first - Suraj
        return new Date(b.dateAdded) - new Date(a.dateAdded);
    });

    var treeList = [];
    var lookup = {};
    list.forEach(function (obj) {
        var catListArray = ["vendorId", "catId", "division", "department", "classColumn", "subClassColumn"];
        for (var i = 0; i < catListArray.length; i++) {
            var list = [];
            if (catListArray[i] == "vendorId") {
                list = window.dropdownslist.vendorId ? window.dropdownslist.vendorId : [];
            } else {
                list = window.dropdownslist.allCatId ? window.dropdownslist.allCatId : [];
            }
            var matchobj = findObjByKeyValue(list, 'fieldId', obj[catListArray[i]]);
            if (matchobj) {
                obj[catListArray[i] + "_name"] = matchobj.fieldName;
            }
        }
        for (var key in obj.dynaData) {
            if (obj.dynaData.hasOwnProperty(key)) {
                obj[key] = obj.dynaData[key];
            }
        }

        lookup[obj[idAttr]] = obj;
        obj[childrenAttr] = [];
    });
    list.forEach(function (obj) {
        for (var i = 0; i < checboxFields.length; i++) {
            if (obj[checboxFields[i]]) {
                obj[checboxFields[i]] = obj[checboxFields[i]].toString() != "true" ? false : true;
            }
            else {
                obj[checboxFields[i]] = false;
            }
        }
        if (obj[parentAttr] != null && lookup[obj[parentAttr]]) {
            lookup[obj[parentAttr]][childrenAttr].push(obj);
        } else {
            treeList.push(obj);
        }
    });
    console.log('productContainer gridDataBuilderTree after treeifying: ', treeList);

    return treeList;
}

export default GridDataBuilder;
