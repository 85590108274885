import React from 'react';

class DeleteConfirm extends React.Component {
    render() {
        let props = this.props;

        return (
            <div >
                <div className="modal-content">
                    <div className="text-center confirm-delete">
                        <h4>Are you sure on deleting the records?</h4>

                    </div>
                    <div className="modal-footer text-center">
                        <button className="gray-button" onClick={props.modalClose}>No</button>
                        <button className="gray-button" onClick= { () =>
                        {
                            props.deleteSelectedRecord();
                            props.modalClose();
                        }}>Yes
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default DeleteConfirm;
