import React from 'react';

/**
 * used to render the currency values.
 */
export class CurrencyRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const usdFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        });

        const currencyFormatted = usdFormatter.format(this.props.node.data && this.props.column.colId &&
                                                      this.props.node.data[this.props.column.colId] ? this.props.node.data[this.props.column.colId] : 0);
        return (
            <div style={{ textAlign: 'right' }}>
                {currencyFormatted}
            </div>
        );
    }
}

/**
 * used to edit the currency values.
 */
export class CurrencyEditor extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            selectedVal: props.node.data[props.column.colId] || '',
        };
    }

    isPopup() {
        return false;
    }

    isCancelBeforeStart() { }

    isCancelAfterEnd() { }

    afterGuiAttached() {
        const eInput = this.refs.input;

        eInput.focus();

        this.props.api.startEditingCell({
            'rowIndex': this.props.rowIndex,
            'colKey': this.props.column.colId,
        });
    }

    getValue() {
        return this.state.selectedVal;
    }

    handleOnChange(e) {
        if (e.target.value) {
            this.setState({ selectedVal: e.target.value });
        }
    }

    render() {
        return (
            <div>
                <input
                    ref="input"
                    style={{ width: '100%', height: '100%' }}
                    type={'number'}
                    onChange={this.handleOnChange.bind(this)}
                    defaultValue={this.state.selectedVal}
                />
            </div>
        );
    }
}
