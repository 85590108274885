import React from 'react';
import axios from 'axios';
import NotificaitonManager from '../../common/components/notification';

import { ProductServices } from '../../product2/services/product-services';

class ImageUpload extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inputFile: [],
            inputFileName: ""
        };

        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    //console.log(this.props.imageArray);
    }

    handleSubmit(e) {
        e.preventDefault();
        let _this = this;
        let obj = {};
        obj.rowId = this.props.imageInfo.uid;
        obj.fieldName = 'images';
        var form = document.getElementById('ProductImagesUpload');
        var formData = new FormData(form);
        formData.append('dataLevel', _this.props.imageInfo.dataLevel);


        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.imageUpload(formData)
            .then( (response) => {
                console.log('productContainer helperComponents footer API call success data: ', response.data);
                let data = response.data;

                if (data.status) {
                    obj.data = _this.props.imageArray;
                    if (data.data) {
                        obj.data.push(data.data);
                    }
                    _this.props.updateImage(obj);
                    _this.props.imageData(obj);

                    NotificaitonManager({ message: "Image uploaded successfully", type: 1 });
                }
                else {
                    NotificaitonManager({ message: data.responseMessage, type: 4 });
                }
            })
            .catch( error => {
                console.log('productContainer helperComponents footer API call failed', error);
                NotificaitonManager({
                    message: "failed to upload image due to technical reasons, please try again",
                    type: 4
                });
            });

        _this.setState({ inputFile: [], inputFileName: "" });
    }

    handleFileChange(e) {
        e.preventDefault();
        let _this = this;
        var target = e.target,
            list = target.files;

        var fileArray = [];
        for (var i = 0; i < list.length; i++) {
            var fileList = {};
            // var allowedImageTypes = ["image/jpeg", "image/png", "image/gif", "image/jpg", "image/tff", "image/tf"];
            var allowedImageTypes = ["image/jpeg", "image/png", "image/gif", "image/jpg", "image/tiff", "image/tif"];

            if (allowedImageTypes.indexOf(list[i].type) < 0) {
                NotificaitonManager({
                    message: "Only PNG, JPG, GIF and TIF are allowed",
                    type: 4
                });
                return false;
            }
            //if(list[i].size < 1048576) {
            for (var l in list[i]) {
                fileList[l] = list[i][l];
            }
            fileArray.push(fileList);
        }

        if (list.length > 0 && fileArray.length > 0) {
            this.setState({ inputFile: fileArray, inputFileName: fileArray[0].name });
        }
    }

    render() {
        let props = this.props;

        let imgData = props.imageInfo;
        let pIds = [];
        if (imgData.dataLevel == "Intm") {
            let arrIntm = imgData.chldProuctId;
            arrIntm.map(function (id, ind) {
                pIds.push(id);
            });
        } else if (imgData.dataLevel == "SP") {
            let arrIntm = imgData.chldProuctId;
            arrIntm.map(function (id, ind) {
                pIds.push(id);
            });
        } else {
            pIds.push(imgData.productId);
        }

        let editable = false;
        if (window.writableFields.indexOf('images') > -1) {
            editable = true;
        }

        return (
            <div className="upload-holder">
                {editable &&
          <form id="ProductImagesUpload" name="product-images-upload" className="images-upload" onSubmit={this.handleSubmit} encType="multipart/form-data">
              <input type='hidden' name="pid" defaultValue={pIds} />
              <div className="upload-input">
                  <div className="icon-holder">
                      <span className="plus-icon"></span>
                  </div>
                  <input type="file" name="files" onChange={this.handleFileChange} />
              </div>

              <label className="block text-center"> Add Photo(s)
                  <span className={"image-name " + (this.state.inputFile.length > 0 ? "" : "hide")}>{" : " + this.state.inputFileName}</span>
              </label>
              <fieldset className="text-center upload-footer">
                  <button type="submit" className="orange-button"
                      disabled={this.state.inputFile.length > 0 ? false : true}>
                Save
                  </button>
              </fieldset>
              <input type="hidden" name="extraField" value="_dontcare" />
          </form>
                }
            </div>
        );
    }
}

export default ImageUpload;
