import React from 'react';

import CampaignOverviewComponent from './campaignOverviewComponent.js';

class CampaignOverview extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            photoholder: '',
            layoutHolder: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.updateMainViewerOverview = this.updateMainViewerOverview.bind(this);
        this.OverviewData = '';
    }

    updateMainViewerOverview(event) {
        let img = $(event.target).attr('data-img');

        $(event.target).parents('.vertical-image-holder').find('.main-image img').attr('src', img);
    }

    handleChange() {
        $('.jqte-test-overview').jqte(
            {
                'contenteditable': false
            }
        );

        $(".jqte-test-overview").jqteVal(this.OverviewData[1].data[0].taskData);
        $(".float-none").find(".jqte_editor").attr("contenteditable", "false");
    }

    render() {
        console.log('campaign Overview Container render: ', this.props, this.OverviewData);

        this.OverviewData = this.props.campaignOverviewData.data;

        return (
            <CampaignOverviewComponent
                state = {this.state}
                props = {this.props}
                OverviewData={this.OverviewData}
                handleChange={this.handleChange}
                updateMainViewerOverview={this.updateMainViewerOverview}
            />
        );
    }
}

export default CampaignOverview;
