
import React from 'react';
import { CustomDropdownEditor } from './customDropdownEditor';

export class BuyerDropdownEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedVal: '',
            buyersList: props.getBuyersList(),
            width: null,
        };
        // this.handleChange = this.handleChange.bind(this);
    }


    componentWillMount() {
        const cellWidth = this.props.eGridCell.style.width;

        this.setState({ selectedVal: this.props.value, width: cellWidth });
    }

    componentWillUnmount() {
        this.props.api.stopEditing();
        this.props.api.tabToNextCell();
    }


    isPopup() {
        return true;
    }

    getValue() {
        return this.state.selectedVal;
    }

    changeSelectedValue(value) {
        this.setState({
            selectedVal: value,
        }, () => this.stopEditing());
    }

    stopEditing() {
        this.props.api.stopEditing();
    }

    render() {
        return (
            <div>
                <CustomDropdownEditor
                    width={this.state.width}
                    selectedVal={this.state.selectedVal}
                    buyersList={this.state.buyersList}
                    changeSelectedValue={this.changeSelectedValue.bind(this)}
                    {...this.props}
                />
            </div>
        );
    }
}

//////////////////////////////////////////////////////////////////////////////////////


export class BuyerDropdownRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (
            <div>
                {this.props.node.data ?
                    <div className='ag-dropdown-custom'> {this.props.value}<div className='ag-icon-expand-grid'></div></div>
                    :
                    <div className='ag-dropdown-custom'><div className='ag-icon-expand-grid'></div></div>}
            </div>

        );
    }
}


