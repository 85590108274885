import React from 'react';
import moment from 'moment';

export class TextRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);
    }


    render(){
        console.log('text value: ', this.props.value);
        console.log('text value: ', this.props.node.data[this.props.column.colId]);
        return(
            <div>
                <input
                    ref="input"
                    style={{width:'100%', height:'100%', padding:'2px'}}
                    type='text'
                    value={this.props.node.data[this.props.column.colId]}
                />

                {/* {this.props.node.data[this.props.column.colId]} */}
            </div>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////

export class TextEditor extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            selectedVal: props.node.data[props.column.colId] || '',
            numberInput: false //added for number type input
        };
    }


    isPopup(){
        return false;
    }


    //     isCancelBeforeStart(){
    //   // Gets called once before editing starts, to give editor a chance to
    //     // cancel the editing before it even starts.
    //     console.log('is cancel before start')
    //     }

    //     isCancelAfterEnd(){
    //     // Gets called once when editing is finished (eg if enter is pressed).
    //     // If you return true, then the result of the edit will be ignored.
    //     console.log('is cancel after end')
    //     }



    afterGuiAttached() {
        // Gets called once after GUI is attached to DOM.
        // Useful if you want to focus or highlight a component
        // (this is not possible when the element is not attached)
        const eInput = this.refs.input;
        eInput.focus();

        let self = this;
        eInput.addEventListener('keydown', function (event) {
            self.myOnKeyDown(event);
        });

        if(this.props.api) {
            this.props.api.startEditingCell({
                'rowIndex':this.props.rowIndex,
                'colKey':this.props.column.colId
            });
        }
    }

    myOnKeyDown(event) {
        console.log('on key down: ', event);
        if(event.key == "ArrowDown" || event.key == "ArrowUp" || event.key == "ArrowRight" || event.key == "ArrowLeft" || event.key == "Home" || event.key == "End"){
            event.stopPropagation();
        }
        // var cellDefs = this.props.api.getEditingCells();
        // console.log('cellDefs: length: ', cellDefs);
        // if(cellDefs.length < 1){
        //     this.props.api.startEditingCell({
        //         'rowIndex':this.props.rowIndex,
        //         'colKey':this.props.column.colId
        //     });
        // }
    }

    componentWillMount() {
        let _this = this;
        if (_this.props.column.colDef.field == "grossMargin") {
            this.setState({numberInput: true});
        }
    }

    componentWillUnmount() {
        console.log('unmounting: ', this.props.node);
        let rowData = this.props.node.data;
        rowData[this.props.column.colId] = this.state.selectedVal;
        this.props.rowUpdate(rowData, this.props.node.rowIndex);
        this.props.api.stopEditing();
    }

    getValue() {
        return this.state.selectedVal;
    }

    handleOnChange(e){
        if(e.target.value) {
            this.setState({selectedVal: e.target.value});
        }
    }

    render(){
        return(
            <div>
                <input
                    ref="input"
                    style={{width:'100%', height:'100%', padding:'2px'}}
                    type={this.state.numberInput == true ? 'number' : 'text'}
                    onChange={this.handleOnChange.bind(this)}
                    defaultValue={this.state.selectedVal}
                />
            </div>
        );
    }
}

