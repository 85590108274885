import {endpoint} from '../../common/REST/endpoints';
import {headers} from '../../common/REST/headers';
import request from '../../common/REST/request';

let PresentationServices = {
    getFolioFormats: function(payload) {
        return request({
            method: endpoint.presentation.getFolioFormats.method,
            url: endpoint.presentation.getFolioFormats.url,
            headers: headers, data: payload || ""
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    getUserFolio: function(payload) {
        return request({
            method: endpoint.presentation.getUserFolio.method,
            url: endpoint.presentation.getUserFolio.url,
            headers: headers, data: payload || ""
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    saveOrUpdate: function(payload) {
        return request({
            method: endpoint.presentation.saveOrUpdate.method,
            url: endpoint.presentation.saveOrUpdate.url,
            headers: headers, data: payload || ""
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    getPageByPageNo: function(payload) {
        return request({
            method: endpoint.presentation.getPageByPageNo.method,
            url: endpoint.presentation.getPageByPageNo.url,
            headers: headers, data: payload || ""
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    listReports: function(query) {
        return request({
            method: endpoint.presentation.listReports.method,
            url: endpoint.presentation.listReports.url + query,
            headers: headers
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    }
};

export default PresentationServices;
