
import React from 'react';
import NotificationManager from '../../common/components/notification';


export class DisplayLevelDropdownRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const recordType = this.props.data && this.props.data.dataLevel ? this.props.data.dataLevel : null;
        const displaylevel = this.props.value ? this.props.value : 'Color';
        if (this.props.data.childCount) {
            return (
                <div>
                    {this.props.value}
                </div>
            );
        } else {
            if (recordType == 'SINGLE' || recordType == null) {
                return null;
            } else {
                return (
                    <div>
                        {displaylevel}
                    </div>
                );
            }
        }
    }
}

// /////////////////////////////////////////////////////////////////////////////////////////////

export class DisplayLevelDropdownEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedVal: '',
            displayLevelList: ['Style', 'Color', 'Size'],
        };
    }

    componentWillMount() {
        this.setState({
            selectedVal: this.props.node.data.displayLevel || 'Please Select',
        });
    }

    componentWillUnmount() {
        this.props.api.stopEditing();
    }

    isPopup() {
        return false;
    }

    getValue() {
        return this.state.selectedVal;
    }

    handleChange(event) {
        const rowData = this.props.node.data;
        rowData['displayLevel'] = event.target.value;
        const _this = this;
        const childrenIds = this.props.node.data.childrenIds;

        this.props.api.forEachNode(function (row, index) {
            if (index == _this.props.rowIndex) {
                if (event.target.value == 'Style') {
                    row.data.addPO = 'Add PO';
                    row.data.swatchOnly = 'AddSampleOption'; // swatchOnly field takes just 15 characters, this value is referenced in sampleOption.js
                } else {
                    row.data.addPO = '';
                    row.data.swatchOnly = '';
                }
            }

            // updating the intermediates of the parent
            if (childrenIds.includes(row.data.intProdId)) {
                row.data.displayLevel = event.target.value;
                if (event.target.value == 'Color') {
                    row.data.addPO = 'Add PO';
                    row.data.swatchOnly = 'AddSampleOption';
                } else {
                    row.data.addPO = '';
                    row.data.swatchOnly = '';
                }

                // getting children of the intermediates
                const intChildrenIds = row.data.childrenIds;
                _this.props.api.forEachNode(function (introw) {
                    if (intChildrenIds.includes(introw.data.chldProdId)) {
                        introw.data.displayLevel = event.target.value;

                        if (event.target.value == 'Size') {
                            introw.data.addPO = 'Add PO';
                            introw.data.swatchOnly = 'AddSampleOption';
                        } else {
                            introw.data.addPO = '';
                            introw.data.swatchOnly = '';
                        }
                    }
                });
            }
        });
        this.setState({ selectedVal: event.target.value });
        this.props.rowUpdate(rowData, this.props.node.rowIndex);

        // adding refreshCells here to rerender the displayLevel cells to see the updated value
        this.props.api.refreshCells();
    }

    render() {
        const recordType = this.props.node.data && this.props.node.data.dataLevel ? this.props.node.data.dataLevel : null;

        const displayLevelList = this.state.displayLevelList.map((val, index) => {
            return <option key={index} value={val}>{val}</option>;
        });

        if (recordType == 'SINGLE' || recordType == null) {
            // you cannot set displaylevel at Single record level
            return null;
        } else if (recordType == 'INTERMEDIATE' || recordType == 'CHILD') {
            // you cannot set displaylevel at intermediate or child but you need to display the value set on the parent
            return (
                <div>
                    {
                        this.props.node.data && this.props.node.data.displayLevel &&
                        <div>
                            {this.props.node.data.displayLevel}
                        </div>
                    }
                </div>
            );
        } else {
            if (this.props.node.data && (this.props.node.data.itemReadyToOrder == true)) {
                NotificationManager({ message: 'Item Ready To Order is already set for this record. Display Level cannot be changed now', type: 4 });
                return (
                    <div>
                        {
                            this.props.node.data && this.props.node.data.displayLevel &&
                            <div>
                                {this.props.node.data.displayLevel}
                            </div>
                        }
                    </div>
                );
            } else {
                return (
                    <div>
                        {
                            this.props.node.data && this.props.node.data.parentProductId &&
                            <select style={{ 'width': '100%' }} onChange={this.handleChange.bind(this)} value={this.state.selectedVal}>
                                <option disabled={true}>Please Select</option>
                                {displayLevelList}
                            </select>
                        }
                    </div>
                );
            }
        }
    }
}

