import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addNewAlert } from './actions';
import Modal from '../../modals/common-modal';

// var api = require('../../../../../configurations.js');
// var ajax = require('../../../common/ajax-methods.js');

import NotificationManager from '../../common/components/notification';

import axios from 'axios';
import AdminServices from '../../admin/admin-services/admin-services';

// var api = require('../../../../../configurations.js');

class AlertsFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            modalName: '',
            isConfirmOpen: false,
            enableButtons: false,
        };

        this.modalClose = this.modalClose.bind(this);
        this.modalOpen = this.modalOpen.bind(this);
        this.callAddNewRule = this.callAddNewRule.bind(this);
        this.callEditNewRule = this.callEditNewRule.bind(this);
        this.alertGridRowAdd = this.alertGridRowAdd.bind(this);
        this.deleteAlert = this.deleteAlert.bind(this);
        this.confirmOpen = this.confirmOpen.bind(this);
        this.confirmClose = this.confirmClose.bind(this);
        this.enableFooter = this.enableFooter.bind(this);
        this.disableFooter = this.disableFooter.bind(this);
    }

    modalClose() {
        this.setState({ isModalOpen: false });
    }

    modalOpen() {
        this.setState({ isModalOpen: true });
    }

    confirmClose() {
        this.setState({ isConfirmOpen: false });
    }

    confirmOpen() {
        this.setState({ isConfirmOpen: true });
    }

    enableFooter() {
        this.setState({ enableButtons: true });
    }

    disableFooter() {
        this.setState({ enableButtons: false });
    }

    callAddNewRule() {
        this.modalOpen();
        this.setState({ modalName: 'add' });
    }

    callEditNewRule() {
        this.modalOpen();
        this.setState({ modalName: 'edit' });
    }
    alertGridRowAdd(data, newrow) {
        this.props.alertGridRowAdd(data, newrow);
    }

    deleteAlert () {
        const _this = this;
        const userIds = _this.props.checkedAlertData;
        const checkedRows = _this.props.checkedRows;
        if (userIds.length > 0) {
            const req = { id: userIds };

            AdminServices.deleteAlert(req).then((response) => {
                const data = response;
                if (data.responseCode) {
                    _this.props.removeRows(userIds);
                    _this.confirmClose();
                    NotificationManager({ message: data.responseMessage, type: 1 });
                } else {
                    NotificationManager({
                        message: 'Failed to delete Alert.',
                        type: 4,
                    });
                }
            }).catch((error) => {
                console.log('User adminFooter deleteUsers API call failed', error);
                NotificationManager({
                    message: 'Failed to delete Alert.',
                    type: 4,
                });
            });
        }
    }


    render() {
        let modalContent;
        switch (this.state.modalName) {
            case "add":
                modalContent = <AddNewAlertConnect user={this.props.user} alertGridRowAdd={this.alertGridRowAdd} modalClose={this.modalClose} />;
                break;
            case "edit":
                modalContent = <EditNewAlert modalClose={this.modalClose} />;
                break;
            default:
        }

        return (
            <div className="footer alerts-footer">
                <div className="footer-content left">
                    <button type="button" className="gray-button"
                        onClick={this.callAddNewRule} >Add New Alert
                    </button>
                    <button type="button" className="gray-button" onClick={this.confirmOpen} disabled={this.props.selectedRowsCount > 0 ? false : true}>Delete Alert</button>
                </div>
                <div className="footer-content right">
                    <button type="button" className="gray-button" onClick={this.props.cancelChanges} disabled={this.state.enableButtons ? false : true}>Cancel Changes</button>
                    <button type="button" className="gray-button"
                        onClick={this.props.applyChanges} disabled={this.state.enableButtons ? false : true}>Apply Changes</button>
                </div>
                <Modal isOpen={this.state.isModalOpen}>
                    {modalContent}
                </Modal>
                <Modal isOpen={this.state.isConfirmOpen}>
                    <div className="modal-content text-center">
                        <h4>Are you sure you want to Delete Alert(s)?</h4>
                        <div>
                            <button type="button" onClick={this.deleteAlert} className="gray-button">Yes</button>
                            <button type="button" onClick={this.confirmClose} className="gray-button">No</button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

class AddNewAlert extends Component {
    constructor(props) {
        super(props);
        let defaultRule = '--Select Rule--' || '';
        this.state = {
            formData: {
                alertName: '',
                appliedRuleId: defaultRule,
            },
            isFormValid: true,
            isSelectValid: true,
            isConfirmOpen: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.confirmOpen = this.confirmOpen.bind(this);
        this.confirmClose = this.confirmClose.bind(this);
        this.cancelPopup = this.cancelPopup.bind(this);
    }

    componentDidMount() {
        let base = this;
    }

    confirmClose() {
        this.setState({ isConfirmOpen: false });
    }

    confirmOpen() {
        this.setState({ isConfirmOpen: true });
    }

    cancelPopup() {
        if (this.state.formData.alertName) {
            this.confirmOpen();
        } else {
            this.props.modalClose();
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        let data = this.state.formData;
        let regex = /^[A-Za-z0-9']+(\s[A-Za-z0-9']+)*$/i;

        if (data.alertName.trim() === '') {
            NotificationManager({ message: "There is an error occurred during the validation, please provide the valid data.", type: 4 });
            this.setState({ isFormValid: false, isSelectValid: true });
        }

        //  else if(data.alertName.length >= 200){
        //   NotificationManage r({message:"Please shorten your Alert name to less than or equal to 200 characters",type:4});
        //   this.setState({isFormValid:false,isSelectValid:true});
        //  }

        else if (data.appliedRuleId === '--Select Rule--') {
            NotificationManager({ message: "Please select a Rule to apply.", type: 4 });
            this.setState({ isSelectValid: false, isFormValid: true });
        } else {
            this.setState({ isFormValid: true, isSelectValid: true });
            if (!data.alertName.match(regex)) {
                data.alertName = data.alertName.trim();
                data.alertName = data.alertName.replace(/\s\s+/g, ' ');
                $('#addAlert').val(data.alertName);
            }
            //this.props.modalClose();
            this.props.callAddNewAlert(this.state.formData);
            var _this = this;
            var requiredObj = {
                "alertId": null,
                "alertName": data.alertName,
                "alertType": null,
                "roleid": 1,
                "ruleid": data.appliedRuleId
            };

            AdminServices.addAlert2(requiredObj).then((response) => {
                let res = response;
                if (res.status == "success") {
                    NotificationManager({ message: res.message, type: 1 });
                    _this.props.modalClose();

                    _this.props.alertGridRowAdd(null, data);
                } else {
                    NotificationManager({ message: res.message, type: 4 });
                }
            }).catch((error) => {
                console.log('AdminRules alert-grid sendData API call failed', error);
                NotificationManager({ message: "Error:Response failed, Please try again", type: 4 });
            });
        }

        //appliedRuleId
        // if(data.alertName.trim() !== ''){
        //   this.setState({isFormValid:true});
        //   this.props.modalClose();
        //   this.props.callAddNewAlert(this.state.formData);
        // }else {
        //   NotificationManager({message:"There is an error occurred during the validation, please provide the valid data.",type:4});
        //   this.setState({isFormValid:false});
        // }
    }

    handleChange(e) {
        var target = e.target,
            name = target.name,
            value = target.value;

        let { formData } = this.state;
        let newFormData = Object.assign({}, formData);

        newFormData[name] = value;
        this.setState({ formData: newFormData });
    }

    render() {
        return (
            <div>
                <div className="modal-header">
          Add New Alert
                    <span className="close" onClick={this.cancelPopup}>x</span>
                </div>
                <div className="modal-content add-new-alert">
                    <form name="add-new-alert" onSubmit={this.handleSubmit} className="modal-form">
                        <div className="form-body form-group">
                            <fieldset className="form-group">
                                <label className="form-label">Name</label>
                                <input id="addAlert" type="text" defaultValue={this.state.alertName} name="alertName" maxLength={200}
                                    className={`form-control ${(this.state.isFormValid) ? '' : 'error'} `} onChange={this.handleChange} />
                            </fieldset>
                            <fieldset className="form-group">
                                <label className="form-label">Rule to be applied</label>
                                <select defaultValue={this.state.formData.appliedRuleId} name="appliedRuleId"
                                    onChange={this.handleChange} className={`form-fluid-select form-control ${(this.state.isSelectValid) ? '' : 'error'}`}>
                                    {this.props.rules.length > 0 &&
                    <option value='--Select Rule--'>--Select Rule--</option>
                                    }
                                    {this.props.rules.length > 0 &&
                    this.props.rules.map((list, index) => (
                        <option key={index} value={list.ruleId}>
                            {list.ruleName}
                        </option>
                    ))
                                    }
                                </select>
                            </fieldset>
                        </div>
                        <div className="form-footer">
                            <div className="text-center">
                                <button type="button" onClick={this.cancelPopup} className="gray-button">Cancel</button>
                                <button type="submit" className="gray-button">Save</button>
                            </div>
                        </div>
                    </form>
                </div>

                <Modal isOpen={this.state.isConfirmOpen}>
                    <div className="modal-content text-center">
                        <h4>Do you want to Save Changes?</h4>
                        <div>
                            <button type="button" onClick={this.handleSubmit} className="gray-button">Yes</button>
                            <button type="button" onClick={this.props.modalClose} className="gray-button">No</button>
                            <button type="button" onClick={this.confirmClose} className="gray-button">cancel</button>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        rules: state.rulesForAlerts
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        callAddNewAlert: (data) => {
            dispatch(addNewAlert(data));
        }
    };
};

const AddNewAlertConnect = connect(mapStateToProps, mapDispatchToProps)(AddNewAlert);


export default AlertsFooter;
