import React, { useState, useEffect } from 'react';
import moment from 'moment';

export const PhotoDirectionRender = (props) => {
    const [data, setData] = useState('');

    useEffect(() => {
        console.log('PhotoDirectionRender useEffect', props);

        if (props.node.group && props.value && props.value != '[]') {
            const vals = props.value.includes('"comment\":') ? JSON.parse(props.value) : '';
            if (vals.length > 0){
                setData(vals[0].comment);
            } else {
                setData('Bad Data');
            }
        }

        // cleanup - remove the data when unmount
        return () => {
            setData();
        };
    }, [props]);

    return (
        <div className="photo-direction-render">{data}</div>
    );
};

export class PhotoDirectionEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            addNewMessage: false,
            noMessages: true,
            data: [],
            enableSave: false,
        };

        this.cancelEditing = this.cancelEditing.bind(this);
        this.addNewMessage = this.addNewMessage.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.inputMessage = this.inputMessage.bind(this);
    }

    componentDidMount() {
        let checkVals = this.props.value ? this.props.value : '';
        let vals = checkVals.includes('"comment\":') ? JSON.parse(checkVals) : '';
        if (vals.length == 0){
            vals = '';
        }
        if (vals && vals.length > 0) {
            this.setState({
                data: vals,
                noMessages: false,
            });
        }
    }

    isPopup() {
        return true;
    }

    getValue() {
        const data = this.state.data;

        return JSON.stringify(data);
    }

    addNewMessage() {
        this.setState({
            addNewMessage: true,
            noMessages: false,
        }, () => {
            this.nameInput.focus();
        });
    }

    sendMessage() {
        const parentId = this.props.node.data.id;
        const colId = this.props.column.colId;
        const data = this.state.inputMessage;

        let mainObj = this.state.data;
        const objN = {};

        objN['name'] = this.props.user.userName;
        objN['date'] = new Date();
        objN['comment'] = data;

        mainObj.unshift(objN);

        let parsed = JSON.stringify(mainObj);

        this.setState({
            data: mainObj,
        }, () => {
            this.cancelEditing();
        });

        console.log('checking paresed sendMessage', parsed);

        this.props.updateData(parsed, parentId, colId);
    }

    cancelEditing() {
        this.setState({
            addNewMessage: false,
        });
    }

    inputMessage(e) {
        const value = e.currentTarget.value;

        if (value.length == 0) {
            this.setState({
                enableSave: false,
            });
        } else {
            this.setState({
                enableSave: true,
            });
        }

        this.setState({
            inputMessage: value,
            messageError: false,
        });
    }

    render() {
        console.log('Photodirection editor render', this.props, this.state);

        const mesgData = this.state.data;

        let messages = mesgData && mesgData.map((i, x) => {
            return (
                <div className="message-section" key={x}>
                    <div className='body-text'>{i.comment}</div>
                    <div className='date-created'>{moment(new Date(i.date)).format("MM/DD/YYYY, h:mm a")}</div>
                    <div className='from-user'>{i.name}</div>
                </div>
            );
        });

        return (
            <div className="message-editor-container photo-direction-col">
                <div className="message-editor-header">
                    <div className="title">PHOTO DIRECTION ({this.state.data.length})</div>
                    <div className='details'>
                        <div className="style"><span>Style#: </span>34DEF</div>
                        <div className="prodname">{this.props.node.data.description}</div>
                    </div>
                </div>

                <div className="message-editor-body">

                    {messages}

                    <div className="section-comment">
                        {
                            this.state.addNewMessage &&
                        <textarea
                            ref={(input) => { this.nameInput = input; }}
                            maxLength={200} onChange={this.inputMessage}
                            className="input-message">
                        </textarea>
                        }

                        {
                            this.state.noMessages &&
                        <h3>No comments, please click 'New' to add a new comment.</h3>
                        }
                    </div>

                </div>

                <div className="message-editor-footer">
                    <button className="message-button new" disabled={this.state.addNewMessage ? true : false} onClick={this.addNewMessage}>New</button>
                    <button className="message-button send" disabled={this.state.enableSave ? false : true} onClick={this.sendMessage}>Save</button>
                    <button className="message-button cancel" onClick={this.cancelEditing}>Cancel</button>
                </div>

            </div>
        );
    }
}
