import React from 'react';
import ProductComponent from './productComponent';
import './product.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import * as actions from './redux/productActions';
import * as utils from '../common/utils';

var GridDataBuilder = require('./helperComponents/grid-data-builder-tree.js');
// var NotificationManager = require('../common/notification.js');
import NotificationManager from '../common/components/notification';

import {ProductServices} from '../product2/services/product-services';

// var userData = require('../../common/data-fetcher/userData.js');

class Product extends React.Component {
  	constructor(props) {
  		super(props);
      	this.state = {
            rightDrawerPulled:false,
            isLoaded: false,
            isModalOpen: false,
            modalName: '',
            isRequiredFieldsLoaded: true,
            isUndoEnable: false,
            enableFilterBy: false,
            piPdfUrl: '',
            isImageModalOpen: false,
            isfilterApplied: false,
            totalRows: 0,
            treegridcurrentPos: null,
            queryForOptions: '',
        };

        this.sourceFrom = { type: "", url: "" };
        this.allowUnlock = false;
        this.popupfields = ['images'];

        this.totalPage = 0;
        this.pageSize = 6;
        this.pageSizeMode = "root";
        this.rowsheight = 35;
        this.jqWidgetReservedMembers = ['checked', 'expanded', 'icon', 'leaf', 'level',
            'parent', 'records', 'selected', 'uid'];
        this.clonedList = [];
        this.swatchArrayRef = ["swatchReference", "swatchOnly"];
        this.pinnedColumnsList = [];
        this.sortcolumn = null;
        this.sortdirection = null;


        this.AllowUserOnlyTrue = ["itemReadyToOrder", "isPiRequestSent", "isPiReceived", "piUpload", "isPiApproved", "isSamplerecieved", "isSampleapproved"];
        this.copyPopupFields = ["itemReadyToOrder", "isPiRequestSent", "isPiReceived", "piUpload", "isPiApproved", 'copywrite', 'keywords',
            'attr1', 'attr2', 'attr3', 'attr4', 'attr5', 'attr6', 'attr7', 'attr8',
            'attr9', 'attr10', 'attr11', 'attr12', 'attr13', 'attr14', 'attr15',
            'attr16', 'attr17', 'attr18', 'attr19', 'attr20', 'attr', 'attrCompleted', 'attrApproved', 'addPO', 'channel'];
        this.displayPopupFields = ["earlyReqSampleSwatch", "isSamplerecieved", "isSampleapproved", "swatchOnly", 'swatchReference', 'featureColor',
            'onlineActivatedDate', 'activeNotes', 'quantityInventory', 'sampleMoved', 'imageApproval', 'shotAngle', 'isActiveLive', 'campaign', 'campaignDate', 'season'];

        this.swatchReferenceList = [];
        this.vendorNameList = null;
        this.undoListData = [];
        this.undoCounter = 0;
        this.undoEditFields = [];
        this.enabledGroupBy = false;
        this.productGridReady = null;
        this.attentionToFieldName = "";
        this.targetDatefieldName = "";
        this.categorySampleRecipient = "";
        this.appliedGridFiltersList = [];

        this.rowLocked = false;
        this.rowLockedKey = '';        // this is the dataField
        this.rowLockedDataField = '';
        this.rowLockedOrgValue = '';
        this.lastUserIdTime = '';
        this.productGridLoadTime = '';
        this.prodRowlockList = '';

        // this.toggleRightDrawer = this.toggleRightDrawer.bind(this)
        this.modalOpen = this.modalOpen.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.nextRequiredField = this.nextRequiredField.bind(this);
        this.deleteRequireConfirmed = this.deleteRequireConfirmed.bind(this);
        this.addBackTheField = this.addBackTheField.bind(this);
        this.updateUploadedImage = this.updateUploadedImage.bind(this);
        this.openSamplePopup = this.openSamplePopup.bind(this);
        this.deleteSelectedRecord = this.deleteSelectedRecord.bind(this);
        //Set sample value if there is no swatch reference
        this.setValueSampleOption = this.setValueSampleOption.bind(this);
        this.setValueSwatchReference = this.setValueSwatchReference.bind(this);
        this.updatePIRecived = this.updatePIRecived.bind(this);
        this.piReceivedModalClose = this.piReceivedModalClose.bind(this);
        this.updateProductForPO = this.updateProductForPO.bind(this);
        this.updateAttrCompleted = this.updateAttrCompleted.bind(this);
        this.setValueSwatchonly = this.setValueSwatchonly.bind(this);
        this.updateSampleSwatchOption = this.updateSampleSwatchOption.bind(this);
        this.undoLastChanges = this.undoLastChanges.bind(this);
        this.storeForUndoChanges = this.storeForUndoChanges.bind(this);
        this.updateParentRow = this.updateParentRow.bind(this);
        this.copyToChildRows = this.copyToChildRows.bind(this);
        this.getResChildRows = this.getResChildRows.bind(this);
        this.openPIUploadPopup = this.openPIUploadPopup.bind(this);
        this.setCellValueAndSaveProduct = this.setCellValueAndSaveProduct.bind(this);
        this.openCampaignPopup = this.openCampaignPopup.bind(this);
        this.loadCampaignEvents = this.loadCampaignEvents.bind(this);
        this.closefilterBy = this.closefilterBy.bind(this);
        this.imgApprovalModalClose = this.imgApprovalModalClose.bind(this);
        this.getPiForProduct = this.getPiForProduct.bind(this);
        this.reloadGrid = this.reloadGrid.bind(this);
        this.setCellValueAndCopyToChild = this.setCellValueAndCopyToChild.bind(this);
        this.removeClonedList = this.removeClonedList.bind(this);
        this.onUnload = this.onUnload.bind(this);
        this.setCampaignCell = this.setCampaignCell.bind(this);
        this.makeCallToRequiredFields = this.makeCallToRequiredFields.bind(this);
        this.rebuildTheGrid = this.rebuildTheGrid.bind(this);
  	}


    componentWillMount() {
        if(!this.props.leftDrawerPulled) {
            this.props.toggleLeftDrawer();
        } else {
            this.props.toggleLeftDrawer();
            this.props.toggleLeftDrawer();
        }

        //only changes url and does not reload or redirect the page
        //window.history.pushState("", "", '/product');
    }


    componentDidMount() {
      	let _this = this;

        let user = JSON.parse(localStorage.getItem('user')) || _this.props.user;

        _this.getAdminSettings();
        _this.makeCallToRequiredFields(user.token);


        $(document).keyup((e) => {
            if (e.keyCode == 27) { // escape key maps to keycode `27`
                if(_this.rowLocked) {
                    let headers = {
                        'Authorization': _this.props.user.token,
                        'content-type': 'application/json'
                    };

			  ProductServices.sessionLock('?productIdLst=' + _this.prodRowlockList + '&lastUserIdTime=' + _this.lastUserIdTime +  '&prodGridLoadTime=' + _this.productGridLoadTime + '&unlock=true' + '&escapePressed=true')
			  .then( (response) => {
                            _this.rowLocked = false;
                        });
                }
            }
        });



        $(window).resize(function(event) {
            const resizedComponent = event.target.toString();

            if ( resizedComponent.indexOf('Window') != -1 ) {
                if (_this.productGrid) {
                    _this.productGrid.refreshGrid();
                }
            }
        });


        //var productTreeGrid = $('.product-treegrid');
        var productTreeGrid = $('.productJqxTreeGrid');
        productTreeGrid.off();

        productTreeGrid.on('click', '.product-image', function () {
            var rowId = $(this).attr('data-row'),
                gridContainer = $(this).attr('grid-container');
            var data = _this.productGrid.getRow(rowId, gridContainer);

            console.log('productGrid clicking image cell: ', data);

            _this.imageProductInfo = data;
            _this.setState({ modalName: 'image' });
            _this.modalOpen();
        });


        productTreeGrid.on('scrollfeedback', '.product-image', function () {

        });



        productTreeGrid.on('click', '.swatch-only', function (e) {
            var ProductId = $(this).attr('data-row'),
                checked = $(this).prop('checked');
            _this.swatchUpdate(ProductId, checked);
        });

        /** Add Vendor */
        productTreeGrid.on('click', '.addVendorFromProductGrid', function (e) {
            _this.productId = $(this).attr('data-row');
            _this.setState({ modalName: "addVendorModal" });
            _this.modalOpen();
        });

        /** Update Sample approved  commented by asaithambi */
        productTreeGrid.on('click', '.product-attr-completed', function (e) {
            e.preventDefault();
            var ProductId = $(this).attr('data-row'),
                //checked = $(this).prop('checked'),
                gridContainer = $(this).attr('grid-container'),
                fieldName = $(this).attr('data-fieldname');
            _this.productId = ProductId;
            _this.currentGridContainer = gridContainer;
            var requiredFields = ['attr1', 'attr2', 'attr3', 'attr4', 'attr5', 'attr6', 'attr7', 'attr8', 'attr9', 'attr10',
                'attr11', 'attr12', 'attr13', 'attr14', 'attr15', 'attr16', 'attr17', 'attr18', 'attr19', 'attr20'];
            var needcount = window.settings.maxAttributeLength;
            var validationData = { valid: false, validcount: 0, needcount: needcount, force: false };
            var rowData = _this.productGrid.getRow(ProductId, gridContainer);
            for (var i = 0; i < needcount; i++) {
                var val = $.trim(rowData[requiredFields[i]]);
                if (val != null && val != undefined && val != "") {
                    validationData.valid = true;
                    validationData.validcount++;
                }
            }
            if (validationData.validcount !== needcount) {
                validationData.force = true;
            }

            if (validationData.force) {
                _this.setState({ modalName: 'submitConfirm', dialog: "SubmitConfirm", validationData: validationData });
                _this.modalOpen();
            } else {
                _this.updateAttrCompleted(gridContainer);
            }
        });


        productTreeGrid.on('click', '.checkboxcolumn', function (e) {
            var rowId = $(this).attr('data-row'),
                gridContainer = $(this).attr('grid-container'),
                isChecked = $(this).prop('checked');

            var rowdata = _this.productGrid.getRow(rowId, gridContainer);
            _this.currentRowData = rowdata;
            _this.setCellValueAndCopyToChild('checked', isChecked, gridContainer);
            //_this.productGrid.setCellValue(rowId,'checked',isChecked, gridContainer);
            _this.updateSelectedRowstoStore();
        });


        // for field type is boolean checkbox is provided, on click the result will be updated
        productTreeGrid.on('click', '.product-boolean,.product-bool', function (e) {
            if (_this.newRecordId) {
                NotificationManager({
                    message: 'Save product with basic details before update copy and display information',
                    type: 4
                });
                return false;
            }
            var rowId = $(this).attr('data-row'),
                fieldName = $(this).attr('data-fieldname'),
                isChecked = $(this).prop('checked'),
                gridContainer = $(this).attr('grid-container'),
                dataField = fieldName,
                // include the same value in grid-data-builder.js as well
                oneTimeCheckbox = ["isPiReceived",
                    "isPiApproved",
                    "isPiRequestSent",
                    "isSampleapproved",
                    "isSamplerecieved",
                    "deleted",
                    "attrApproved",
                    "readyToActivate",
                    "isPiRequestEarly",
                    "isResendPiRequest",
                    "itemReadyToOrder",
                    "earlyReqSampleSwatch",
                    "backOrder",
                    "sampleMoved",
                    "sampleRequested",
                    "isActiveLive"];

            if (oneTimeCheckbox.indexOf(fieldName) > -1) {
                let urlObj = {
                    [oneTimeCheckbox[0]]: require('../common/configurations.js').product.piRecieved,
                    [oneTimeCheckbox[1]]: require('../common/configurations.js').product.piApproved,
                    [oneTimeCheckbox[2]]: require('../common/configurations.js').product.piRequestSent,
                    [oneTimeCheckbox[3]]: require('../common/configurations.js').product.sampleApproved,
                    [oneTimeCheckbox[4]]: require('../common/configurations.js').product.isSampleRecevied,
                    [oneTimeCheckbox[5]]: require('../common/configurations.js').product.userDelete,
                    [oneTimeCheckbox[6]]: require('../common/configurations.js').product.attrAproved,
                    [oneTimeCheckbox[7]]: require('../common/configurations.js').product.readyToActivate,
                    [oneTimeCheckbox[8]]: require('../common/configurations.js').product.piRequestEarly,
                    [oneTimeCheckbox[9]]: require('../common/configurations.js').product.resendPiRequest,
                    [oneTimeCheckbox[10]]: require('../common/configurations.js').product.itemReadyToOrder,
                    [oneTimeCheckbox[11]]: require('../common/configurations.js').product.earlyRequestSampleSwatch,
                    [oneTimeCheckbox[12]]: require('../common/configurations.js').product.backOrder,
                    [oneTimeCheckbox[13]]: require('../common/configurations.js').product.samleMoveCompleted,
                    [oneTimeCheckbox[14]]: require('../common/configurations.js').product.sampleRequestSent,
                    [oneTimeCheckbox[15]]: require('../common/configurations.js').product.activeliveeupdate
                };
                let value = true;
                if (oneTimeCheckbox.indexOf(fieldName) === 5 || oneTimeCheckbox.indexOf(fieldName) === 8 || oneTimeCheckbox.indexOf(fieldName) === 9) {
                    value = isChecked;
                }

                // for "piRequestEarly" and "resendPiRequest"
                if (oneTimeCheckbox.indexOf(fieldName) === 8 || oneTimeCheckbox.indexOf(fieldName) === 9) {
                    value = value.toString();
                }
                /**
			 * Copy content parent child product -- Block Start
			 */
                var productId = rowId,
                    rowData = null, list, postdata = null, parentRef = null;
                rowData = _this.productGrid.getRow(productId, gridContainer);
                _this.currentRowData = rowData;// Used to pass current row data to modal component

                if (fieldName === "itemReadyToOrder" && rowData[fieldName] != true && (rowData.copyEditable === true || rowData.copyEditable === undefined)) {
                    var validate = _this.ValidateRequiredFields(_this.requiredReadyFields, [rowData]);
                    var unfilledField = _this.getUnfilledColumns(_this.requiredReadyFields, [rowData]);
                    if (!validate.isValid) {
                        var msg = 'Please provide the following mandatory fields to in order to mark "Ready to order" checkbox for this product<br/><br/>'
					+ unfilledField.join(',');
                        NotificationManager({ message: msg, type: 4 });
                        return false;
                    }
                }

                _this.currentFieldText = _this.productGrid.getColumnProperty(dataField, 'text');
                if (rowData && rowData.copyPop && _this.copyPopupFields.indexOf(dataField) > -1) {
                    var root = _this.productGrid.getRow(rowData.referrence);
                    var childlist = _this.getResChildRows(root);
                    var chlist = childlist.map((p, i) => {
                        return p.productId;
                    });
                    _this.childProdList = chlist;
                    _this.productGridPopupType = 'copy';
                    _this.setState({ modalName: "ProductGridPopup" });
                    _this.modalOpen();
                    return false;
                }

                if (rowData && rowData.displayPop && _this.displayPopupFields.indexOf(dataField) > -1) {
                    var root = _this.productGrid.getRow(rowData.referrence);
                    var childlist = _this.getResChildRows(root);
                    var chlist = childlist.map((p, i) => {
                        return p.productId;
                    });
                    _this.childProdList = chlist;
                    _this.productGridPopupType = 'display';
                    _this.setState({ modalName: "ProductGridPopup" });
                    _this.modalOpen();
                    return false;
                }


                if (rowData.dataLevel != "SP" && rowData.dataLevel != "Intm") {
                    _this.parentRef = null;
                } else {
                    _this.parentRef = rowData.parentRef ? rowData.parentRef : null;
                }
                list = _this.getResChildRows(rowData);
                postdata = list.map((o, i) => {
                    return o.productId;
                });

                if (oneTimeCheckbox.indexOf(fieldName) === 4) {
                    if (rowData.swatchOnly && rowData.swatchOnly.toLowerCase() !== "none") {
                        _this.productData = postdata;
                        _this.childProdList = list;
                        _this.openSamplePopup();
                    } else {
                        NotificationManager({ message: "This action is not applicable for sample option none", type: 4 });
                    }
                    return false;
                }

                if (fieldName === 'isPiReceived') {
                    _this.productId = postdata;
                    _this.setState({ modalName: 'PiUploadModal' });
                    _this.modalOpen();
                }

                if (fieldName === "itemReadyToOrder" || fieldName === "earlyReqSampleSwatch") {
                    postdata = list.map((o, i) => {
                        return { "id": o.productId, "value": true };
                    });
                }
                if (fieldName === "isSampleapproved" || fieldName === "samleMoveCompleted") {
                    postdata = list.map((o, i) => {
                        return { "id": o.productId };
                    });
                }

                if (["deleted", "isPiRequestSent", "isResendPiRequest", "isPiRequestEarly", "sampleRequested", "isActiveLive"].indexOf(fieldName) > -1) {
                    postdata = list.map((o, i) => {
                        return { "id": o.productId, "value": isChecked };
                    });
                }
                if (fieldName === "backOrder") {
                    postdata = list.map((o, i) => {
                        let userId = this.props.user.userId;
                        return { "id": o.productId, "value": userId, status: isChecked };
                    });
                }
                /** update parent child rows */


                _this.setCellValueAndCopyToChild(fieldName, isChecked, gridContainer);

                /**
			 * Copy content parent child product -- Block End
				return;
			*/

                let headers = {
                    'Authorization': _this.props.user.token,
                    'content-type': 'application/json'
                };

                axios({
                    method: 'post',
                    url: urlObj[fieldName],
                    headers: headers,
                    data: postdata
                })
                    .then( (response) => {
                        let res = response.data;

                        if (res.status) {
                            NotificationManager({ message: res.responseMessage + "<br/><b class='text-red'>This action can't be undone.</b>", type: 1 });
                        } else {
                            NotificationManager({ message: res.responseMessage, type: 4 });
                            _this.setCellValueAndCopyToChild(fieldName, false, gridContainer);
                        }
                    })
                    .catch( error => {
                        console.log('productContainer componentDidMount  productTreeGrid.on(click product-boolean,.product-bool API call failed', error);
                    });
            } else {
                var rowData = _this.productGrid.getRow(rowId);
                _this.currentRowData = rowData;
                let dynaFields = Object.keys(rowData.dynaData);
                if (dynaFields.indexOf(fieldName) > -1) { // For newly created fields
                    _this.setCellValueAndCopyToChild(fieldName, isChecked);
                    let childRowsData = _this.getResChildRows(_this.productGrid.getRow(rowId));

                    _this.sendUpdatedOrAddedRecord(childRowsData);
                } else {
                    if (fieldName === "isActiveLive") { // update Active Live (product deactivation)
                        _this.productDeactivate(deactiveReq, isChecked);
                    } else {
                        _this.sendUpdatedOrAddedRecord(rowData);
                    }
                }
            }
        });


        productTreeGrid.on('click', '.header-checkbox', function (event) {
            var isChecked = $(this).attr('data-checked');
            if (isChecked === 'false') {
                $(this).attr('data-checked', 'true');
                $(this).addClass('checked');
                _this.isCheckedAllRows = true;
                _this.checkAllRows();
            } else if (isChecked === 'true') {
                $(this).attr('data-checked', 'false');
                $(this).removeClass('checked');
                _this.isCheckedAllRows = false;
                _this.unCheckAllRows();
            }
        });


        productTreeGrid.on('click', '.jqx-grid-column-header', function (e) {
            var fieldName = $(this).find('.pd-drop').attr('data-field');
            var ignoreFields = ['checked'];
            if (_this.filterFieldName !== fieldName && ignoreFields.indexOf(fieldName) === -1) {
                $('#drop-down').hide();
                $('#drop-down .sort-holder-image').remove();
                $('#drop-down .sort-holder').show();
                _this.setState({ enableFilterBy: false });
                $('.product-sort-options[data-val="filter"]').removeClass('active');
            }
            if (fieldName !== undefined && ignoreFields.indexOf(fieldName) === -1) {
                if (fieldName == "images") {
                    var imhtml = '<ul class="sort-holder-image">' +
				'<li class="product-sort-options" data-val="50-50">Show thumbnail image</li>' +
				'<li class="product-sort-options" data-val="30-30">Show tiny image(default)</li>' +
				'<li class="product-sort-options" data-val="asc">Sort 0-9, A -> Z</li>' +
				'<li class="product-sort-options" data-val="desc">Sort 9-0, Z -> A</li>' +
				'<li class="product-sort-options" data-val="hide">Hide Field</li>' +
				'<li class="product-sort-options" data-val="pinned">Freeze/UnFreeze field</li>' +
				'<li class="product-sort-options" data-val="clear">clear</li>' +
				'</ul>';
                    $('#drop-down .sort-holder-image').remove();

                    $('#drop-down').append(imhtml);
                    $('#drop-down .sort-holder').hide();
                }
                if (fieldName == "checkboxId") {
                    if ($(e.target).hasClass('header-checkbox')) {
                        return;
                    }
                    var imhtml = '<ul class="sort-holder-image">' +
				'<li class="product-sort-options" data-val="asc">Sort 0-9, A -> Z</li>' +
				'<li class="product-sort-options" data-val="desc">Sort 9-0, Z -> A</li>' +
				'<li class="product-sort-options" data-val="groupby">Group By this field</li>' +
				'<li class="product-sort-options" data-val="clear">clear</li>' +
				'</ul>';
                    $('#drop-down .sort-holder-image').remove();

                    $('#drop-down').append(imhtml);
                    $('#drop-down .sort-holder').hide();
                }
                var wh = $(this).css(['width', 'height']);
                var offset = $(this).offset();
                var OffsetTop = offset.top - 28;
                var srwd = $('.search-refine-holder').width();
                var offsetLeft = offset.left - 100 - srwd;

                if (_this.productGrid) {
                    var appliedgroups = _this.productGrid.groups();
                    if (appliedgroups.indexOf(fieldName) > -1) {
                        $('.product-sort-options[data-val="groupby"]').addClass('disabled');
                    } else {
                        $('.product-sort-options[data-val="groupby"]').removeClass('disabled');
                    }
                }
                $('#drop-down').css({ left: offsetLeft, top: OffsetTop, display: 'block' })
                    .attr('data-field', fieldName);
            }
        });


        productTreeGrid.on('click', '.swatchOnly span', function (event) {
            var $target = $(event.currentTarget).parent();
            var dataField = $target.attr('datafield');
            var key = $target.data('row');
            var fieldValue = $.trim($target.text());
            var gridContainer = $target.attr('grid-container');
            var rowData = _this.productGrid.getRow(key, gridContainer);
            _this.currentRowData = rowData; // used for passing to modal componenet
            var list = _this.getResChildRows(rowData);
            var postData = list.map(function (p, i) {
                return p.productId;
            });
            if (dataField === "swatchOnly" && ["sample", "swatch only"].indexOf(fieldValue.toLowerCase()) !== -1) {
                _this.productData = postData;
                _this.childProdList = list;
                if (rowData.dataLevel != "SP" && rowData.dataLevel != "Intm") {
                    _this.parentRef = null;
                } else {
                    _this.parentRef = rowData.parentRef ? rowData.parentRef : null;
                }
                _this.openSamplePopup();
            }
        });


        productTreeGrid.on('click', '.icon-expand-grid.campaign', function (event) {
            let $target = $(event.currentTarget).parent();
            let dataField = $target.attr('datafield');
            let key = $target.data('key');
            let fieldValue = $.trim($target.text());
            var gridContainer = $target.attr('grid-container');
            let rowData = _this.productGrid.getRow(key, gridContainer);

            _this.currentFieldText = _this.productGrid.getColumnProperty(dataField, 'text');
            if (rowData && rowData.displayPop && _this.displayPopupFields.indexOf(dataField) > -1) {
                var root = _this.productGrid.getRow(rowData.referrence);
                var childlist = _this.getResChildRows(root);
                var chlist = childlist.map((p, i) => {
                    return p.productId;
                });
                _this.childProdList = chlist;
                _this.productGridPopupType = 'display';
                _this.setState({ modalName: "ProductGridPopup" });
                _this.modalOpen();
                return;
            }


            _this.campaignProduct = rowData;
            _this.openCampaignPopup();
        });


        /** Add Po Link click */
        productTreeGrid.on('click', '.addPO', function (event) {
            var $target = $(event.currentTarget),
                dataField = $target.attr('datafield'),
                key = $target.data('row'),
                gridContainer = $target.attr('grid-container'),
                //rowData = _this.productGrid.getRow(key);
                productId = key,
                rowData = null, list, postdata = [];
            rowData = _this.productGrid.getRow(productId, gridContainer);
            list = _this.getResChildRows(rowData);
            postdata = list.map(function (o, i) {
                return o.productId;
            });

            _this.currentFieldText = _this.productGrid.getColumnProperty(dataField, 'text');
            // if (rowData && rowData.copyPop && _this.copyPopupFields.indexOf(dataField) > -1) {
            // 	var root = _this.productGrid.getRow(rowData.referrence);
            // 	var childlist = _this.getResChildRows(root);
            // 	var chlist = childlist.map((p, i) => {
            // 		return p.productId;
            // 	});
            // 	_this.childProdList = chlist;
            // 	_this.productGridPopupType = 'copy';
            // 	_this.setState({ modalName: "ProductGridPopup" });
            // 	_this.modalOpen();
            // 	return false;
            // }
            // if (!rowData['itemReadyToOrder']) {
            // 	NotificationManager({ message: "Required product details for Item ready to order are missing", type: 2 });
            // 	//return;
            // };

            _this.lastUserIdTime = rowData.lastUserIdTime;
            _this.prodRowlockList = (rowData.dataLevel === "SP" || rowData.dataLevel === "Intm") ? rowData.chldProuctId : rowData.productId;


            let headers = {
                'Authorization': _this.props.user.token,
                'content-type': 'application/json'
            };

			 ProductServices.sessionLock('?productIdLst=' + _this.prodRowlockList + '&lastUserIdTime=' + _this.lastUserIdTime +  '&prodGridLoadTime=' + _this.productGridLoadTime + '&unlock=false' + '&escapePressed=false')
                .then( (response) => {
                    let res = response.data;

                    // 122 = row is already locked by someone else
                    if (res.responseCode === 122) {
                        let rowLockedBy = JSON.parse(res.responseMessage).rowLockedBy;
                        let respMessage = JSON.parse(res.responseMessage).message;
                        let rowLockedByCurrentUser = rowLockedBy.localeCompare(_this.props.user.userName) === 0 ? true : false;

                        if(!rowLockedByCurrentUser) {
                            NotificationManager({ message: respMessage + " , " + rowLockedBy + ". Kindly try after sometime", type: 4 });
                            $(gridContainer).jqxGrid('endcelledit', row.productId);
                            setTimeout(function(){
                                _this.productGrid.setCellValue(_this.rowLockedKey, _this.rowLockedDataField, _this.rowLockedOrgValue);
                            }, 200);
                            return ;
                        }
                        // 123 is when grid needs to be refreshed as grid data is old
                    } else if (res.responseCode === 123) {
                        NotificationManager({ message: res.responseMessage, type: 4 });
                        $(gridContainer).jqxGrid('endcelledit', row.productId);
                        setTimeout(function(){
                            _this.productGrid.setCellValue(_this.rowLockedKey, _this.rowLockedDataField, _this.rowLockedOrgValue);
                        }, 200);
                        return ;
                    } else {
                        _this.rowLocked = true;

                        _this.productId = postdata;
                        _this.vendorId = rowData.vendorId; //
                        _this.channel = rowData.channel;
                        _this.setState({ modalName: "AddPOModal" });
                        _this.modalOpen();


                        setTimeout(() =>{
                            if(_this.rowLocked) {
                                ProductServices.sessionLock('?productIdLst=' + _this.prodRowlockList + '&lastUserIdTime=' + _this.lastUserIdTime +  '&prodGridLoadTime=' + _this.productGridLoadTime + '&unlock=true' + '&escapePressed=false')
                                    .then( (response) => {
                                        _this.modalClose();
                                        setTimeout(function(){
                                            _this.rowLocked = false;
                                        }, 200);
                                    });
                            }
                        }, require('../common/configurations').gridRowLockTime);  // currently set to 30000
                    }
                })
                .catch( error => {
                    console.log('productContainer componentDidMount  _this.allowUnlock = false; API call failed', error);
                });
        });


        //drop down close event
        $(document).on('click.proddropdown', function (e) {
            e.stopPropagation();

            if ($('#drop-down').is(':visible') && $(e.target).closest('.jqx-grid-column-header').length === 0 && $(e.target).attr('data-val') !== "filter" && $(e.target).closest('.filter-by-container').length === 0) {
                $('#drop-down').hide();
                _this.closefilterBy();
            }
        });


        this.sortDropDown();


        $('#drop-down').on('click', 'li', function () {
            var fieldName = $(this).closest('#drop-down').attr('data-field');
            var catListArray = ["vendorId", "catId", "division", "department", "classColumn", "subClassColumn"];
            var val = $(this).attr('data-val');
            if (fieldName == 'checkboxId') {
                fieldName = "checked";
            }

            if (val == "hide") {
                //_this.productGrid.hideColumn(fieldName);
                $(".jqx-grid").jqxGrid("hidecolumn", fieldName);
                $('.list-viewer-holder input[name="' + fieldName + '"]').trigger('click');
                _this.productGrid.sortBy(fieldName, null);
                _this.productGrid.clearFilters();
                _this.filterFieldName = null;
                _this.FilterByChild.clearFilter();
                _this.setState({ isfilterApplied: !_this.state.isfilterApplied });
                _this.closefilterBy();
                return;
            }
            else if (val == "pinned") {
                if (_this.productGrid.iscolumnpinned(fieldName)) {
                    $(".jqx-grid").jqxGrid('setcolumnproperty', fieldName, 'pinned', false);
                    const index = _this.pinnedColumnsList.indexOf(fieldName);
                    _this.pinnedColumnsList.splice(index, 1);
                } else {
                    $(".jqx-grid").jqxGrid('setcolumnproperty', fieldName, 'pinned', true);
                    _this.pinnedColumnsList.push(fieldName);
                }
            }
            else if (val == "filter") {
                $('.product-sort-options[data-val="filter"]').addClass('active');
                _this.showfilterBy(fieldName);
            } else if (val == "clear") {
                // ajax.loader(true);

                _this.productGrid.sortBy(fieldName, null);
                _this.productGrid.removeFilter(fieldName);
                _this.FilterByChild.clearFilter(fieldName);
                _this.productGrid.removegroup(fieldName);
                if (catListArray.indexOf(fieldName) > -1) {
                    fieldName = fieldName + "_name";
                }
                _this.productGrid.removeFilter(fieldName);
                _this.FilterByChild.clearFilter(fieldName);
                _this.productGrid.removegroup(fieldName);
                _this.productGrid.applyFilters();
                // _this.setState({ isfilterApplied: !_this.state.isfilterApplied });
                // _this.setState({ isfilterApplied: false });
                _this.filterFieldName = null;
                _this.appliedGridFiltersList = [];
                _this.closefilterBy();
                setTimeout(function () {
                    _this.sortcolumn = null;
                    _this.sortdirection = null;
                    // ajax.loader(false);
                }, 500);
            }
            else if (val === "groupby") {
                var appliedgroups = _this.productGrid.groups();
                if (appliedgroups.indexOf(fieldName) === -1) {
                    _this.productGrid.addgroup(fieldName);
                }
            } else if (val == "60-60") {
                _this.rowsheight = 60;
                //_this.props.gridDataChanged();
                _this.productGrid.refreshGrid();
            }
            else if (val == "50-50") {
                _this.rowsheight = 50;
                //_this.props.gridDataChanged();
                _this.productGrid.refreshGrid();
            }
            else if (val == "30-30") {
                _this.rowsheight = 30;
                //_this.props.gridDataChanged();
                _this.productGrid.refreshGrid();
            }
            else {
                if (catListArray.indexOf(fieldName) > -1) {
                    fieldName = fieldName + "_name";
                }
                val = (val === "null") ? null : val;
                _this.productGrid.sortBy(fieldName, val);
                _this.sortcolumn = fieldName;
                _this.sortdirection = val;
                _this.closefilterBy();
            }
        });



     	// $('body').on('click', ':not(.some-div-class, .another-div-class, .parent-div-class *)', goto);



        $(document).on('click', '.filter-strip-holder', function (e) {
            console.log('filter strip or page header');

            let headers = {
                'Authorization': _this.props.user.token,
                'content-type': 'application/json'
            };


            if(_this.rowLocked) {
                ProductServices.sessionLock('?productIdLst=' + _this.prodRowlockList + '&lastUserIdTime=' + _this.lastUserIdTime +  '&prodGridLoadTime=' + _this.productGridLoadTime + '&unlock=true' + '&escapePressed=false')
                    .then( (response) => {
                        _this.modalClose();
                        setTimeout(function(){
                            _this.rowLocked = false;
                        }, 200);
                    });
            }
        });

        $(document).on('click', '.pageHeader', function (e) {
            console.log('filter strip or page header');

            let headers = {
                'Authorization': _this.props.user.token,
                'content-type': 'application/json'
            };


            if(_this.rowLocked) {
                ProductServices.sessionLock('?productIdLst=' + _this.prodRowlockList + '&lastUserIdTime=' + _this.lastUserIdTime +  '&prodGridLoadTime=' + _this.productGridLoadTime + '&unlock=true' + '&escapePressed=false')
                    .then( (response) => {
                        _this.modalClose();
                        setTimeout(function(){
                            _this.rowLocked = false;
                        }, 200);
                    });
            }
        });
    }


    componentWillUnmount() {
        // window.removeEventListener("beforeunload", this.onUnload)
        let _this = this;
        if(this.props.leftDrawerPulled) {
            this.props.toggleLeftDrawer();
        }

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };


        if(_this.rowLocked) {
            ProductServices.sessionLock('?productIdLst=' + _this.prodRowlockList + '&lastUserIdTime=' + _this.lastUserIdTime +  '&prodGridLoadTime=' + _this.productGridLoadTime + '&unlock=true' + '&escapePressed=false')
                .then( (response) => {
                    _this.modalClose();
                    setTimeout(function(){
                        _this.rowLocked = false;
                    }, 200);
                });
        }
    }


    componentWillReceiveProps(props) {
        this.setState({ pdfUrl: props.piPdfUrl });
    }


    componentDidUpdate(prevProps, prevState) {
        let _this = this;

        // making the isloaded flase will destroy the product grid
        if (prevProps.gridDataCount.gridDataChange !== this.props.gridDataCount.gridDataChange
			|| prevProps.gridDataCount.gridColumnChange !== this.props.gridDataCount.gridColumnChange) {
            // this.enabledGroupBy = false;
            // // _this.appliedGridFiltersList = [];
            // if (this.state.isLoaded) {
            // this.setState({ isLoaded: false });
            // }
            // else {
            this.setState({isLoaded:false});
            this.rebuildTheGrid();
            // }
        }

        // if (prevProps.appliedFilters.catIds.length !== this.props.appliedFilters.catIds.length) {
        //   this.rebuildTheGrid()
        // }

        // if (prevProps.columnResequence.isSaveNewView !== this.props.columnResequence.isSaveNewView) {
        // 	if (this.props.columnResequence.isSaveNewView) {
        // 	this.rebuildTheGrid()
        // 	}
        // }



        // if (
        //     (prevState.isLoaded !== this.state.isLoaded && this.state.isLoaded === false && !this.enabledGroupBy) ||
        //     (prevProps.count !== this.props.count) ||
        //   ) {
        //     this.rebuildTheGrid()
        //   }


        if (prevState.isLoaded !== this.state.isLoaded && this.state.isLoaded === false && !this.enabledGroupBy) {
            this.rebuildTheGrid();
        }

        // adding a new product
        if (prevProps.count !== this.props.count) {
            this.addSingleRecord();
        }

        //deleting the selected products
        if (prevProps.deleteCount !== this.props.deleteCount) {
            _this.setState({ modalName: 'deleteConfirm' });
            _this.modalOpen();
        }

        if (prevState.isLoaded !== this.state.isLoaded && this.state.isLoaded === true) {
            if (!_this.props.columnResequence.afterSaveView) {
                _this.updateSelectedRowstoStore();
            }


            _this.productGrid.off('cellendedit');
            _this.productGrid.on('cellendedit', function (event) {
                let args = event.args;
                args.dataField = args.datafield;

                if (args.row && !window.escKeyPressed) {
                    args.row[args.dataField] = event.args.value;
                } else {
                    window.escKeyPressed = null;
                    return;
                }
                args.key = args.row.productId;

                if (args.dataField === "swatchOnly" && args.value.toLowerCase() === "none") {
                    $('.productJqxTreeGrid tr[data-row="' + args.key + '"]').find('input[data-fieldname="isSamplerecieved"]').attr({ disabled: true });
                } else {
                    $('.productJqxTreeGrid tr[data-row="' + args.key + '"]').find('input[data-fieldname="isSamplerecieved"]').removeAttr('disabled');
                }


                // Sample Recipient, Buyer and Attention To
                let attentionToFieldList = ["sampleRecipient", "buyer"];
                if (attentionToFieldList.indexOf(args.dataField) > -1) {
                    _this.attentionToFieldName = args.dataField;
                }
                // "campaignDate", "distCenterDate", "launchDate"
                let targetDateFieldList = ["campaignDate", "distCenterDate", "launchDate"];
                if (targetDateFieldList.indexOf(args.dataField) > -1) {
                    _this.targetDatefieldName = args.dataField;
                }


                // Product Variants - if variants value equal to "create multi product" and itemReadyToOrder is false
                if ((args.dataField.split('_').length > 1 && args.dataField.split('_')[1] === 'attribute') && !args.row.itemReadyToOrder) {
                    if (args.value && args.value.toLowerCase() === 'create multi product') {
                        _this.setState({ modalName: 'productOption' });
                        //let attr_label = "Select " + args.dataField.split('_')[0];
                        setTimeout(function () {
                            _this.productGrid.setCellValue(args.key, args.dataField, args.oldvalue);
                        }, 200);
                        _this.modalOpen();
                    } else {
                        if (args.value != "") {
                            _this.checkForCellValChange(args);
                        }
                    }
                } else { // except options datafields
                    _this.checkForCellValChange(args);
                }
            });



            /////////////////////////////////////////////////////////////////////////////////////////
            //////////////////////Row Locking on begin edit//////////////////////////////////////////
            /////////////////////////////////////////////////////////////////////////////////////////
            _this.productGrid.off('cellbeginedit');
            _this.productGrid.on('cellbeginedit', function (event) {
                let headers = {
                    'Authorization': prevProps.user.token,
                    'content-type': 'application/json'
                };

                var args = event.args;
                var dataField = args.datafield;
                var row = args.row;
                args.key = row.productId;

                // FOR UNDO LAST CHANGES - Start

                _this.fieldNameEndCellEdit = args.datafield;
                let prodData = args.row;
                _this.oldRowData = Object.assign({}, prodData);
                if(prodData){
                    const gridContainer = (event.args.row.level == 1) ? 'jqxgrid' : (event.args.row.level == 2) ? 'level2grid' + event.args.row.level1 : 'level3grid' + event.args.row.level1 + '' + event.args.row.level1;
                    window.dropdownField = { fieldName: event.args.datafield, rowBounIndex: event.args.row.boundindex, gridContainer };
                }

                // FOR UNDO LAST CHANGES - End


                console.log('DataField is ' + dataField);
                console.log('Row is ' + row);

                // added for row locking
                if (_this.rowLocked) return;

                if(row.productId)
                    _this.rowLockedKey = row.productId;

                _this.rowLockedOrgValue = args.value;
                _this.rowLockedDataField = args.datafield;
                _this.lastUserIdTime = args.row.lastUserIdTime;

                let gridContainer = "#jqxgrid";
                if (args.row.dataLevel == "Intm") {
                    gridContainer = '#level2grid' + args.row.level1;
                }
                else if (args.row.dataLevel == "Sku" && args.row.parentRef) {
                    if (args.row.level == 2) {
                        gridContainer = '#level2grid' + args.row.level1;
                    } else if (args.row.level == 3) {
                        gridContainer = '#level3grid' + args.row.level1 + args.row.level2;
                    } else {
                        gridContainer = '';
                    }
                }

                ////////////////////////////////////////////////////////////////////////////////
                ///////////////////////////Row Locking for following fields/////////////////////
                ////////////////////////////////////////////////////////////////////////////////
                // "prodName", "catId", "featureColor", "swatchOnly", "shotAngle", "copywrite",
                // "keywords", "imageApproval", "color_attribute", "size_attribute"


                _this.prodRowlockList = (row.dataLevel === "SP" || row.dataLevel === "Intm") ? row.chldProuctId : row.productId;

                let validRorlockFields = ["prodName", "catId", "swatchOnly", "imageApproval"];

                if (validRorlockFields.indexOf(dataField) != -1 && row.prodName) {
                    ProductServices.sessionLock('?productIdLst=' + _this.prodRowlockList +  '&lastUserIdTime=' + _this.lastUserIdTime +  '&prodGridLoadTime=' + _this.productGridLoadTime + '&unlock=false' + '&escapePressed=false')
                        .then( (response) => {
                            let res = response.data;

                            console.log('Response: ', res.responseCode);
                            console.log('Response Message: ', res.responseMessage);
                            console.log('Response User Name: ', prevProps.user.userName);

                            // 122 = row is already locked by someone else
                            if (res.responseCode === 122) {
                                let rowLockedBy = JSON.parse(res.responseMessage).rowLockedBy;
                                let respMessage = JSON.parse(res.responseMessage).message;
                                let rowLockedByCurrentUser = rowLockedBy.localeCompare(prevProps.user.userName) === 0 ? true : false;

                                if(!rowLockedByCurrentUser) {
                                    NotificationManager({ message: respMessage + " , " + rowLockedBy + ". Kindly try after sometime", type: 4 });
                                    $(gridContainer).jqxGrid('endcelledit', row.productId);
                                    setTimeout(function(){
                                        _this.productGrid.setCellValue(_this.rowLockedKey, _this.rowLockedDataField, _this.rowLockedOrgValue);
                                    }, 200);
                                    return ;
                                }
                                // 123 is when grid needs to be refreshed as grid data is old
                            } else if (res.responseCode === 123) {
                                NotificationManager({ message: res.responseMessage, type: 4 });
                                $(gridContainer).jqxGrid('endcelledit', row.productId);
                                setTimeout(function(){
                                    _this.productGrid.setCellValue(_this.rowLockedKey, _this.rowLockedDataField, _this.rowLockedOrgValue);
                                }, 200);
                                return ;
                            } else {
                                _this.rowLocked = true;

                                setTimeout(() =>{
                                    if(_this.rowLocked) {
                                        ProductServices.sessionLock('?productIdLst=' + _this.prodRowlockList + '&lastUserIdTime=' + _this.lastUserIdTime +  '&prodGridLoadTime=' + _this.productGridLoadTime + '&unlock=true' + '&escapePressed=false')
                                            .then( (response) => {
                                                $(gridContainer).jqxGrid('endcelledit', row.productId);
                                                setTimeout(function(){
                                                    _this.productGrid.setCellValue(_this.rowLockedKey, _this.rowLockedDataField, _this.rowLockedOrgValue);
                                                    _this.rowLocked = false;
                                                }, 200);
                                            });
                                    }
                                }, require('../common/configurations').gridRowLockTime);  // currently set to 30000
                            }
                        })
                        .catch( error => {
                            console.log('productContainer componentDidUpdate cellbeginedit locking Row API call failed', error);
                        });
                } // ends validRorlockFields
            });



            // /////////////////////////////////////////////////////////////////////////////////////////
            // //////////////////////Row Locking on begin edit//////////////////////////////////////////
            // /////////////////////////////////////////////////////////////////////////////////////////
            // 		_this.productGrid.off('cellbeginedit');
            // 		_this.productGrid.on('cellbeginedit', function (event) {

            // 			let headers = {
            // 				'Authorization': prevProps.user.token,
            // 				'content-type': 'application/json'
            // 			}

            // 			var args = event.args;
            // 			var dataField = args.datafield;
            // 			var row = args.row;
            // 			args.key = row.productId;

            // 			// added for row locking
            // 			if (_this.rowLocked) return;

            // 			_this.rowLockedKey = row.productId;
            // 			_this.rowLockedOrgValue = args.value
            // 			_this.rowLockedDataField = args.datafield;
            // 			_this.lastUserIdTime = args.row.lastUserIdTime

            // 			let gridContainer = "#jqxgrid";
            // 			if (args.row.dataLevel == "Intm") {
            // 				gridContainer = '#level2grid' + args.row.level1;
            // 			}
            // 			else if (args.row.dataLevel == "Sku" && args.row.parentRef) {
            // 				if (args.row.level == 2) {
            // 				gridContainer = '#level2grid' + args.row.level1;
            // 				} else if (args.row.level == 3) {
            // 				gridContainer = '#level3grid' + args.row.level1 + args.row.level2;
            // 				} else {
            // 				gridContainer = '';
            // 				}
            // 			}


            // 	////////////////////////////////////////////////////////////////////////////////
            // 	///////////////////////////Row Locking for following fields/////////////////////
            // 	////////////////////////////////////////////////////////////////////////////////
            // 	// "prodName", "catId", "featureColor", "swatchOnly", "shotAngle", "copywrite",
            // 	// "keywords", "imageApproval", "color_attribute", "size_attribute"


            // 	_this.prodRowlockList = (row.dataLevel === "SP" || row.dataLevel === "Intm") ? row.chldProuctId : row.productId;

            // 	let validRorlockFields = ["prodName", "catId", "swatchOnly", "copywrite", "keywords", "imageApproval"];

            // 	if (validRorlockFields.indexOf(dataField) != -1 && row.prodName) {



            // 	axios({
            // 		method: 'get',
            // 		url: require('../common/configurations.js').product.sessionLock + '?productIdLst=' + _this.prodRowlockList +  '&lastUserIdTime=' + _this.lastUserIdTime +  '&prodGridLoadTime=' + _this.productGridLoadTime + '&unlock=false' + '&escapePressed=false',
            // 		headers: headers,
            // 	})
            // 	.then( (response) => {
            // 		let res = response.data

            // 		// 122 = row is already locked by someone else
            // 		if (res.responseCode === 122 && res.responseMessage.localeCompare(prevProps.user.userName)  ) {
            // 			NotificationManager({ message: res.responseMessage, type: 4 });
            // 			$(gridContainer).jqxGrid('endcelledit', row.productId);
            // 			setTimeout(function(){
            // 				_this.productGrid.setCellValue(_this.rowLockedKey, _this.rowLockedDataField, _this.rowLockedOrgValue);
            // 			},200)
            // 			return ;
            // 		} else {

            // 			_this.rowLocked = true;

            // 			setTimeout(() =>{
            // 				if(_this.rowLocked) {
            // 					axios({
            // 						method: 'get',
            // 						url: require('../common/configurations.js').product.sessionLock + '?productIdLst=' + _this.prodRowlockList + '&lastUserIdTime=' + _this.lastUserIdTime +  '&prodGridLoadTime=' + _this.productGridLoadTime + '&unlock=true' + '&escapePressed=false',
            // 						headers: headers,
            // 					})
            // 					.then( (response) => {
            // 						$(gridContainer).jqxGrid('endcelledit', row.productId);
            // 						setTimeout(function(){
            // 							_this.productGrid.setCellValue(_this.rowLockedKey, _this.rowLockedDataField, _this.rowLockedOrgValue);
            // 							_this.rowLocked = false;
            // 						},200)
            // 					})
            // 				}
            // 			},require('../common/configurations').gridRowLockTime)  // currently set to 30000
            // 		}
            // 	})
            // 	.catch( error => {
            // 	});

            // 	} // ends validRorlockFields

            // });


            /////////////////////////////////////////////////////////////////////////////////////////
            //////////////////////Row Locking on double click////////////////////////////////////////
            /////////////////////////////////////////////////////////////////////////////////////////

            _this.productGrid.off('celldoubleclick');
            _this.productGrid.on('celldoubleclick', function (event) {
                var args = event.args;
                var dataField = args.datafield;
                var row = args.row.bounddata;

                _this.prodRowlockList = (row.dataLevel === "SP" || row.dataLevel === "Intm") ? row.chldProuctId : row.productId;

                _this.currentRowData = row;


                var list = _this.getResChildRows(row);

                // getting first child's product id
                if (list.length > 0) {
                    var keyProductId = list[0].productId;
                }

                var productIds = list.map((e, i) => {
                    return e.productId;
                });

                var clonedProductOptions = (row && ["color_attribute", "size_attribute"].indexOf(dataField) != -1 && [2, 3].indexOf(row.level) != -1);
                _this.productId = productIds;
                _this.currentFieldText = _this.productGrid.getColumnProperty(dataField, 'text');


                // Row locking for following fields inside //// copyPopupFields////
                // ["itemReadyToOrder", "isPiRequestSent", "isPiReceived", "piUpload", "isPiApproved", 'copywrite', 'keywords',
                // 'attr1', 'attr2', 'attr3', 'attr4', 'attr5', 'attr6', 'attr7', 'attr8',
                // 'attr9', 'attr10', 'attr11', 'attr12', 'attr13', 'attr14', 'attr15',
                // 'attr16', 'attr17', 'attr18', 'attr19', 'attr20', 'attr', 'attrCompleted', 'attrApproved', 'addPO', 'channel'];


                // if (row && row.copyPop && [_this.copyPopupFields].indexOf(dataField) != -1) {

                ///////////////////////////////////////////////////
                // row locking for drop down fields
                ////////////////////////////////////////////////////

                console.log('Row Locking for ' + dataField);

                if (['vendorId', 'buyer', 'channel', 'season', 'size_attribute', 'color_attribute'].indexOf(dataField) != -1) {
                    let headers = {
                        'Authorization': prevProps.user.token,
                        'content-type': 'application/json'
                    };

                    ProductServices.sessionLock('?productIdLst=' + _this.prodRowlockList + '&lastUserIdTime=' + _this.lastUserIdTime +  '&prodGridLoadTime=' + _this.productGridLoadTime + '&unlock=false' + '&escapePressed=false')
                        .then( (response) => {
                            let res = response.data;

                            // 122 = row is already locked by someone else
                            if (res.responseCode === 122) {
                                let rowLockedBy = JSON.parse(res.responseMessage).rowLockedBy;
                                let respMessage = JSON.parse(res.responseMessage).message;
                                let rowLockedByCurrentUser = rowLockedBy.localeCompare(prevProps.user.userName) === 0 ? true : false;

                                if(!rowLockedByCurrentUser) {
                                    NotificationManager({ message: respMessage + " , " + rowLockedBy + ". Kindly try after sometime", type: 4 });
                                    return ;
                                }
                                // 123 is when grid needs to be refreshed as grid data is old
                            } else if (res.responseCode === 123) {
                                NotificationManager({ message: res.responseMessage, type: 4 });
                                return ;
                            } else {
                                _this.rowLocked = true;

                                setTimeout(() =>{
                                    if(_this.rowLocked) {
                                        ProductServices.sessionLock('?productIdLst=' + _this.prodRowlockList + '&lastUserIdTime=' + _this.lastUserIdTime +  '&prodGridLoadTime=' + _this.productGridLoadTime + '&unlock=true' + '&escapePressed=false')
                                            .then( (response) => {
                                                _this.modalClose();
                                                setTimeout(function(){
                                                    _this.rowLocked = false;
                                                }, 200);
                                            });
                                    }
                                }, require('../common/configurations').gridRowLockTime);  // currently set to 30000
                            }
                        })
                        .catch( error => {
                            console.log('productContainer componentDidMount  _this.allowUnlock = false; API call failed', error);
                        });
                }

                // row locking for modal popups

                if(['department', 'classColumn', 'subClassColumn',
                    'copyLevel', 'displayLevel',
                    'shotAngle'].indexOf(dataField) != -1) {
                    console.log('Row Locking for ' + dataField);

                    let headers = {
                        'Authorization': prevProps.user.token,
                        'content-type': 'application/json'
                    };

                    ProductServices.sessionLock('?productIdLst=' + _this.prodRowlockList + '&lastUserIdTime=' + _this.lastUserIdTime +  '&prodGridLoadTime=' + _this.productGridLoadTime + '&unlock=false' + '&escapePressed=false')
                        .then( (response) => {
                            let res = response.data;
                            _this.currentRowData = row;

                            // 122 = row is already locked by someone else
                            if (res.responseCode === 122) {
                                let rowLockedBy = JSON.parse(res.responseMessage).rowLockedBy;
                                let respMessage = JSON.parse(res.responseMessage).message;
                                let rowLockedByCurrentUser = rowLockedBy.localeCompare(prevProps.user.userName) === 0 ? true : false;

                                if(!rowLockedByCurrentUser) {
                                    NotificationManager({ message: respMessage + " , " + rowLockedBy + ". Kindly try after sometime", type: 4 });
                                    return ;
                                }
                                // 123 is when grid needs to be refreshed as grid data is old
                            } else if (res.responseCode === 123) {
                                NotificationManager({ message: res.responseMessage, type: 4 });
                                return ;
                            } else {
                                _this.rowLocked = true;

                                if (['copyLevel', 'displayLevel'].indexOf(dataField) != -1 && row.dataLevel === 'SP' && row.itemReadyToOrder != true) {
                                    _this.getAllChildProducts(row.children);
                                    _this.currentRowData = row;
                                    _this.levelType = dataField;
                                    _this.setState({ modalName: 'copyordisplayLevel' });
                                    _this.modalOpen();
                                }

                                if (dataField === "shotAngle" && row.displayEditable === true) {
                                    _this.currentProductCatID = row.catId;
                                    _this.productId = productIds;
                                    _this.setState({ modalName: 'ShotAngles' });
                                    _this.modalOpen();
                                }

                                if (["department", "classColumn", "subClassColumn"].indexOf(dataField) != -1 && row.isSampleapproved != true && window.writableFields.indexOf(dataField) > -1 &&
						(row.dataLevel === undefined || row.dataLevel == "SP" || (row.dataLevel == "Sku" && row.parentRef == null))) {
                                    _this.selectCategoryRow = row;
                                    _this.setState({ modalName: 'SelectCategory' });
                                    _this.modalOpen();
                                }

                                setTimeout(() =>{
                                    if(_this.rowLocked) {
                                        ProductServices.sessionLock('?productIdLst=' + _this.prodRowlockList + '&lastUserIdTime=' + _this.lastUserIdTime +  '&prodGridLoadTime=' + _this.productGridLoadTime + '&unlock=true' + '&escapePressed=false')
                                            .then( (response) => {
                                                _this.modalClose();
                                                setTimeout(function(){
                                                    _this.rowLocked = false;
                                                }, 200);
                                            });
                                    }
                                }, require('../common/configurations').gridRowLockTime);  // currently set to 30000
                            }
                        })
                        .catch( error => {
                            console.log('productContainer componentDidMount  _this.allowUnlock = false; API call failed', error);
                        });
                }


                ////////////////////////////////////////////////////////////////////////////////
                ///////////////////////////Row Locking for following fields/////////////////////
                ////////////////////////////////////////////////////////////////////////////////
                ///"earlyReqSampleSwatch", "isSamplerecieved", "isSampleapproved", "swatchOnly",
                ///'swatchReference', 'featureColor','onlineActivatedDate', 'activeNotes', 'quantityInventory',
                ///'sampleMoved', 'imageApproval', 'shotAngle', 'isActiveLive', 'campaign', 'campaignDate', 'season'


                // if (row && row.displayPop && _this.displayPopupFields.indexOf(dataField) > -1) {
                //       var root = _this.productGrid.getRow(row.referrence);
                //       var childlist = _this.getResChildRows(root);
                //       var chlist = childlist.map((p, i) => {
                //         return p.productId;
                //       });

                //       //Row Locking



                //       let headers = {
                //             'Authorization': prevProps.user.token,
                //             'content-type': 'application/json'
                //       }

                //       axios({
                //         method: 'get',
                //         url: require('../common/configurations.js').product.sessionLock + '?productIdLst=' + _this.prodRowlockList + '&lastUserIdTime=' + args.row.bounddata.lastUserIdTime +  '&prodGridLoadTime=' + _this.productGridLoadTime + '&unlock=false' + '&escapePressed=false',
                //         headers: headers
                //       })
                //       .then( (response) => {
                //           let res = response.data

                //           _this.allowUnlock = true;
                //           if (!res.status && res.responseCode == 122) {
                //             _this.allowUnlock = false;
                //             _this.productGrid.lockRow(row.productId);
                //             NotificationManager({ message: res.responseMessage, type: 4 });
                //             return false;
                //           } else if (!res.status && res.responseCode == 123) {
                //             NotificationManager({ message: res.responseMessage, type: 4 }); // ask user to refresh the grid
                //             return false;
                //           } else {
                //             _this.childProdList = chlist;
                //             _this.productGridPopupType = 'display';
                //             _this.setState({ modalName: "ProductGridPopup" });
                //             _this.modalOpen();
                //             return;
                //           }

                //       })
                //       .catch( error => {
                //       });


                // }



                // Image Approval
                // for image no row locking added
                if (row.displayEditable === true && args.dataField === 'imageApproval') {
                    _this.chldProuctIds = productIds;
                    _this.setState({ isImageModalOpen: true });
                }




                ////////////////////////////////////////////////////////////////////////////////
                ///////////////////////////Row Locking for copywrite///////////////////////////
                ////////////////////////////////////////////////////////////////////////////////

                // var copyAllowFlag = false;
                // if (row.isPiApproved === true) {
                // 	if (row.parentRef) {
                // 		if (row.copyEditable === true) {
                // 		copyAllowFlag = true;
                // 		}
                // 	} else {
                // 		copyAllowFlag = true;
                // 	}
                // }

                // if (copyAllowFlag && dataField === "copywrite") {

                //   let headers = {
                //         'Authorization': prevProps.user.token,
                //         'content-type': 'application/json'
                //   }

                //   axios({
                //     method: 'get',
                //     url: require('../common/configurations.js').product.sessionLock + '?productIdLst=' + _this.prodRowlockList + '&lastUserIdTime=' + args.row.bounddata.lastUserIdTime +  '&prodGridLoadTime=' + _this.productGridLoadTime + '&unlock=false' + '&escapePressed=false',
                //     headers: headers
                //   })
                //   .then( (response) => {
                //       let res = response.data

                //       if (!res.status && res.responseCode == 122) {
                //         _this.allowUnlock = false;
                //         _this.productGrid.lockRow(args.row.productId);
                //         NotificationManager({ message: res.responseMessage, type: 4 });
                //         return false;
                //       } else if (!res.status && res.responseCode == 123) {
                //         NotificationManager({ message: res.responseMessage, type: 4 }); // ask user to refresh the grid
                //         return false;
                //       } else {

                //           let headers = {
                //                 'Authorization': prevProps.user.token,
                //                 'content-type': 'application/json'
                //           }

                //           axios({
                //             method: 'post',
                //             url: require('../common/configurations.js').product.getCopyWritter,
                //             headers: headers,
                //             data: { id: keyProductId }
                //           })
                //           .then( (response) => {
                //               let res = response.data
                //               if (res.status) {
                //                 _this.copyData = res.data;

                //                 _this.setState({ modalName: 'addCopyModel' });
                //                 _this.modalOpen();
                //               } else {
                //                 alert("Product data doesnot contains descriptions");
                //               }
                //             })
                //           .catch( error => {
                //           });

                //       }

                //   })
                //   .catch( error => {
                //   });

                // }



                // ////////////////////////////////////////////////////////////////////////////////
                // ///////////////////////////Row Locking for keywords///////////////////////////
                // ////////////////////////////////////////////////////////////////////////////////


                // if (copyAllowFlag && dataField === "keywords") {

                //       let headers = {
                //             'Authorization': prevProps.user.token,
                //             'content-type': 'application/json'
                //       }

                //       axios({
                //         method: 'get',
                //         url: require('../common/configurations.js').product.sessionLock + '?productIdLst=' + _this.prodRowlockList + '&lastUserIdTime=' + row.lastUserIdTime +  '&prodGridLoadTime=' + _this.productGridLoadTime + '&unlock=false' + '&escapePressed=false',
                //         headers: headers
                //       })
                //       .then( (response) => {
                //           let res = response.data

                //           _this.allowUnlock = true;
                //           if (!res.status && res.responseCode == 122) {
                //             _this.allowUnlock = false;
                //             _this.productGrid.lockRow(args.row.productId);
                //             NotificationManager({ message: res.responseMessage, type: 4 });
                //             return false;
                //           } else if (!res.status && res.responseCode == 123) {
                //             NotificationManager({ message: res.responseMessage, type: 4 }); // ask user to refresh the grid
                //             return false;
                //           } else {

                //               let headers = {
                //                     'Authorization': prevProps.user.token,
                //                     'content-type': 'application/json'
                //               }

                //               axios({
                //                 method: 'post',
                //                 url: require('../common/configurations.js').product.getKeywords,
                //                 headers: headers,
                //                 data: { id: keyProductId }
                //               })
                //               .then( (response) => {
                //                   let res = response.data

                //                   if (res.status) {
                //                     _this.keywordData = res.data;
                //                     _this.setState({ modalName: 'addKeywordModal' });
                //                     _this.modalOpen();
                //                   } else {
                //                     NotificationManager({ message: "Product Data doesnt contains related data", type: 4 });
                //                   }

                //                 })
                //               .catch( error => {
                //               });

                //           }

                //       })
                //       .catch( error => {
                //       });

                // }





                var copyAllowFlag = false;
                if (row.isPiApproved === true) {
                    if (row.parentRef) {
                        if (row.copyEditable === true) {
                            copyAllowFlag = true;
                        }
                    } else {
                        copyAllowFlag = true;
                    }
                }

                console.log('Copy Allow Flag: ', copyAllowFlag);

                if (copyAllowFlag && dataField === "copywrite") {
				  console.log('productContainer componentDidUpdate copyAllowFlag and dataField is copywrite: ', copyAllowFlag, dataField);
				  console.log('productContainer componentDidUpdate copyAllowFlag and row: ', row);

                    //   let headers = {
                    // 		'Authorization': prevProps.user.token,
                    // 		'content-type': 'application/json'
                    //   }

                    //   axios({
                    // 	method: 'get',
                    // 	url: require('../common/configurations.js').product.sessionLock + '?productIdLst=' + _this.prodRowlockList + '&lastUserIdTime=' + args.row.bounddata.lastUserIdTime +  '&prodGridLoadTime=' + _this.productGridLoadTime + '&unlock=false' + '&escapePressed=false',
                    // 	headers: headers
                    //   })
                    //   .then( (response) => {
                    // 	  let res = response.data

                    // 	  if (!res.status && res.responseCode == 122) {
                    // 		_this.allowUnlock = false;
                    // 		_this.productGrid.lockRow(args.row.productId);
                    // 		NotificationManager({ message: res.responseMessage, type: 4 });
                    // 		return false;
                    // 	  } else if (!res.status && res.responseCode == 123) {
                    // 		NotificationManager({ message: res.responseMessage, type: 4 }); // ask user to refresh the grid
                    // 		return false;
                    // 	  } else {

						  let headers = {
                        'Authorization': prevProps.user.token,
                        'content-type': 'application/json'
						  };
                    ProductServices.getCopyWritter({ id: keyProductId })
                        .then( (response) => {
							  console.log('productContainer componentDidUpdate Image Approval subcall API call success data: ', response.data);
							  let res = response.data;
							  if (res.status) {
                                _this.copyData = res.data;

                                _this.setState({ modalName: 'addCopyModel' });
                                _this.modalOpen();
							  } else {
                                alert("Product data doesnot contains descriptions");
							  }
                        })
						  .catch( error => {
							  console.log('productContainer componentDidUpdate Image Approval subcall  API call failed', error);
						  });

                    // 	  }

                    //   })
                    //   .catch( error => {
                    //   });
                }



                // ////////////////////////////////////////////////////////////////////////////////
                // ///////////////////////////Row Locking for keywords///////////////////////////
                // ////////////////////////////////////////////////////////////////////////////////


                if (copyAllowFlag && dataField === "keywords") {
                    //   let headers = {
                    // 		'Authorization': prevProps.user.token,
                    // 		'content-type': 'application/json'
                    //   }

                    //   axios({
                    // 	method: 'get',
                    // 	url: require('../common/configurations.js').product.sessionLock + '?productIdLst=' + _this.prodRowlockList + '&lastUserIdTime=' + row.lastUserIdTime +  '&prodGridLoadTime=' + _this.productGridLoadTime + '&unlock=false' + '&escapePressed=false',
                    // 	headers: headers
                    //   })
                    //   .then( (response) => {
                    // 	  let res = response.data

                    // 	  _this.allowUnlock = true;
                    // 	  if (!res.status && res.responseCode == 122) {
                    // 		_this.allowUnlock = false;
                    // 		_this.productGrid.lockRow(args.row.productId);
                    // 		NotificationManager({ message: res.responseMessage, type: 4 });
                    // 		return false;
                    // 	  } else if (!res.status && res.responseCode == 123) {
                    // 		NotificationManager({ message: res.responseMessage, type: 4 }); // ask user to refresh the grid
                    // 		return false;
                    // 	  } else {
	 						ProductServices.getKeywords({ id: keyProductId })
                        .then( (response) => {
								  console.log('productContainer componentDidUpdate Image Approval elsesubcall  API call success data: ', response.data);
								  let res = response.data;

								  if (res.status) {
                                _this.keywordData = res.data;
                                _this.setState({ modalName: 'addKeywordModal' });
                                _this.modalOpen();
								  } else {
                                NotificationManager({ message: "Product Data doesnt contains related data", type: 4 });
								  }
                        })
							  .catch( error => {
								  console.log('productContainer componentDidUpdate Image Approval else subcall  API call failed', error);
							  });

                    // 	  }

                    //   })
                    //   .catch( error => {
                    //   });
                }


                if (dataField === "copywrite" || dataField === "keywords") {
                    var msg = "";

                    if (!row.copyEditable) {
                        if (dataField === "keywords") {
                            msg = "Keywords are not available at this product level";
                            NotificationManager({ message: msg, type: 4 });
                        } else {
                            msg = "Copywrite is not available at this product level";
                            NotificationManager({ message: msg, type: 4 });
                        }
                    } else if (!row.isPiApproved && dataField === "keywords") {
                        // msg = "Keywords not yet added";
                        msg = "Product doesn't have required information to add keywords";
                        NotificationManager({ message: msg, type: 4 });
                    } else if (!row.isPiApproved) {
                        msg = "Product doesn't have required information to add copywrite";
                        NotificationManager({ message: msg, type: 4 });
                    }
                }

                // if (dataField === "copywrite" || dataField === "keywords") {
                // 	var msg = "";

                // 	if (!row.copyEditable) {
                // 	if (dataField === "keywords") {
                // 		msg = "Keywords are not available at this product level";
                // 	} else {
                // 		msg = "Copywrite is not available at this product level";
                // 	}
                // 	} else if (!row.piApproved && dataField === "keywords") {
                // 		msg = "Keywords not yet added";
                // 	} else if (!row.piApproved) {
                // 		msg = "Product doesnt have required information";
                // 	}
                // 	NotificationManager({ message: msg, type: 4 });
                // }



                if (dataField === "swatchReference" && row.swatchOnly == "Swatch Only" && row.displayEditable === true) {
                    let headers = {
                        'Authorization': prevProps.user.token,
                        'content-type': 'application/json'
                    };

                    ProductServices.getSampleIds({ id: productIds, styleId: row.styleNumber })
                        .then( (response) => {
                            let res = response.data;

                            if (res.responseStatus && res.data.length > 0) {
                                _this.swatchReferenceList = res.data;
                                _this.setState({ modalName: 'sampleSwatchDropDown' });
                                _this.modalOpen();
                                _this.addBackDetails = args;
                            }
                            else {
                                NotificationManager({ message: "There is no swatch reference for this product", type: 4 });
                            }
                        })
                        .catch( error => {
                            console.log('productContainer componentDidMount  product.getSampleIds API call failed', error);
                        });
                }


                // if (["department", "classColumn", "subClassColumn"].indexOf(dataField) > -1 && row.isSampleapproved !== true && window.writableFields.indexOf(dataField) > -1 &&
                // 	(row.dataLevel === undefined || row.dataLevel == "SP" || (row.dataLevel == "Sku" && row.parentRef == null))) {

                // 	let headers = {
                // 		'Authorization': prevProps.user.token,
                // 		'content-type': 'application/json'
                // 	}

                // 	axios({
                // 		method: 'get',
                // 		url: require('../common/configurations.js').product.sessionLock + '?productIdLst=' + _this.prodRowlockList + '&lastUserIdTime=' + _this.lastUserIdTime +  '&prodGridLoadTime=' + _this.productGridLoadTime + '&unlock=false'  + '&escapePressed=false',
                // 		headers: headers
                // 	})
                // 	.then( (response) => {
                // 		let res = response.data;

                // 			// 122 = row is already locked by someone else
                // 			if (res.responseCode === 122) {
                // 			NotificationManager({ message: res.responseMessage, type: 4 });
                // 			_this.modalClose();
                // 			return ;
                // 			} else {
                // 				_this.rowLocked = true;

                // 				_this.selectCategoryRow = row;
                // 				_this.setState({ modalName: 'SelectCategory' });
                // 				_this.modalOpen();

                // 				setTimeout(() =>{
                // 					if(_this.rowLocked) {

                // 						axios({
                // 							method: 'get',
                // 							url: require('../common/configurations.js').product.sessionLock + '?productIdLst=' + _this.prodRowlockList + '&lastUserIdTime=' + _this.lastUserIdTime +  '&prodGridLoadTime=' + _this.productGridLoadTime + '&unlock=true' + '&escapePressed=false',
                // 							headers: headers,
                // 						})
                // 						.then( (response) => {
                // 							_this.modalClose();
                // 							setTimeout(function(){
                // 							_this.rowLocked = false;
                // 							},200)

                // 						})
                // 					}
                // 				},require('../common/configurations').gridRowLockTime)  // currently set to 30000
                // 			}
                // 	})
                // 	.catch( error => {
                // 	});
                // }

                //window.writableFields.indexOf(dataField)>-1
                // if (dataField === "shotAngle" && row.displayEditable === true) {

                // 	let headers = {
                // 		'Authorization': prevProps.user.token,
                // 		'content-type': 'application/json'
                // 	}

                // 	axios({
                // 		method: 'get',
                // 		url: require('../common/configurations.js').product.sessionLock + '?productIdLst=' + _this.prodRowlockList + '&lastUserIdTime=' + _this.lastUserIdTime +  '&prodGridLoadTime=' + _this.productGridLoadTime + '&unlock=false'  + '&escapePressed=false',
                // 		headers: headers
                // 	})
                // 	.then( (response) => {
                // 		let res = response.data;

                // 			// 122 = row is already locked by someone else
                // 			if (res.responseCode === 122) {
                // 			NotificationManager({ message: res.responseMessage, type: 4 });
                // 			_this.modalClose();
                // 			return ;
                // 			} else {
                // 			_this.rowLocked = true;

                // 			_this.currentProductCatID = row.catId;
                // 			_this.productId = productIds;
                // 			_this.setState({ modalName: 'ShotAngles' });
                // 			_this.modalOpen();

                // 			setTimeout(() =>{

                // 				if(_this.rowLocked) {

                // 					axios({
                // 						method: 'get',
                // 						url: require('../common/configurations.js').product.sessionLock + '?productIdLst=' + _this.prodRowlockList + '&lastUserIdTime=' + _this.lastUserIdTime +  '&prodGridLoadTime=' + _this.productGridLoadTime + '&unlock=true' + '&escapePressed=false',
                // 						headers: headers,
                // 					})
                // 					.then( (response) => {
                // 						_this.modalClose();
                // 						setTimeout(function(){
                // 						_this.rowLocked = false;
                // 						},200)

                // 					})
                // 				}

                // 			},require('../common/configurations').gridRowLockTime)  // currently set to 30000


                // 			}


                // 	})
                // 	.catch( error => {
                // 	});

                // }


                if (dataField === "piUpload") {
                    _this.selectCategoryRow = row;
                    if (_this.selectCategoryRow['isPiReceived'] === true && row.copyEditable === true) {
                        _this.getPiForProduct(_this.productId);
                        _this.setState({ modalName: 'PiUploadModal' });
                        _this.modalOpen();
                    }
                }

                if (dataField === "featureColor" && row.dataLevel == "SP") {
                    _this.currentRowData = row;
                    _this.productId = row.productId;

                    let headers = {
                        'Authorization': prevProps.user.token,
                        'content-type': 'application/json'
                    };

                    axios({
                        method: 'get',
                        url: require('../common/configurations.js').product.sessionLock + '?productIdLst=' + _this.prodRowlockList + '&lastUserIdTime=' + _this.lastUserIdTime +  '&prodGridLoadTime=' + _this.productGridLoadTime + '&unlock=false'  + '&escapePressed=false',
                        headers: headers
                    })
                        .then( (response) => {
                            let res = response.data;


                            // 122 = row is already locked by someone else
                            if (res.responseCode === 122) {
                                let rowLockedBy = JSON.parse(res.responseMessage).rowLockedBy;
                                let respMessage = JSON.parse(res.responseMessage).message;
                                let rowLockedByCurrentUser = rowLockedBy.localeCompare(prevProps.user.userName) === 0 ? true : false;

                                if(!rowLockedByCurrentUser) {
                                    NotificationManager({ message: respMessage + " , " + rowLockedBy + ". Kindly try after sometime", type: 4 });
                                    $(gridContainer).jqxGrid('endcelledit', row.productId);
                                    setTimeout(function(){
                                        _this.productGrid.setCellValue(_this.rowLockedKey, _this.rowLockedDataField, _this.rowLockedOrgValue);
                                    }, 200);
                                    return ;
                                }
                                // 123 is when grid needs to be refreshed as grid data is old
                            } else if (res.responseCode === 123) {
                                NotificationManager({ message: res.responseMessage, type: 4 });
                                $(gridContainer).jqxGrid('endcelledit', row.productId);
                                setTimeout(function(){
                                    _this.productGrid.setCellValue(_this.rowLockedKey, _this.rowLockedDataField, _this.rowLockedOrgValue);
                                }, 200);
                                return ;
                            } else {
                                _this.rowLocked = true;


                                ProductServices.getFeatureColor({ chldProuctId: productIds })
                                    .then( (response) => {
                                        let res = response.data;

                                        if (res.data) {
                                            _this.colorList = res.data;
                                            _this.setState({ modalName: 'featureColorList' });
                                            _this.modalOpen();
                                        } else {
                                            NotificationManager({ message: "No colors available for this product", type: 4 });
                                        }
                                    })
                                    .catch( error => {
                                        console.log('productContainer componentDidMount  product.getFeatureColor API call failed', error);
                                    });


                                setTimeout(() =>{
                                    if(_this.rowLocked) {
                                        axios({
                                            method: 'get',
                                            url: require('../common/configurations.js').product.sessionLock + '?productIdLst=' + _this.prodRowlockList + '&lastUserIdTime=' + _this.lastUserIdTime +  '&prodGridLoadTime=' + _this.productGridLoadTime + '&unlock=true' + '&escapePressed=false',
                                            headers: headers,
                                        })
                                            .then( (response) => {
                                                _this.modalClose();
                                                setTimeout(function(){
                                                    _this.rowLocked = false;
                                                }, 200);
                                            });
                                    }
                                }, require('../common/configurations').gridRowLockTime);  // currently set to 30000
                            }
                        })
                        .catch( error => {
                            console.log('error getting lock for featureColor', error);
                        });
                }

                if (clonedProductOptions && _this.childlevelClonedList) {
                    if (_this.childlevelClonedList.indexOf(row.productId) != -1) {
                        let parentRow;
                        if (row.dataLevel === "Sku") {
                            parentRow = $('#level2grid' + row.level1).jqxGrid('getrowdatabyid', row.referrence);
                        } else {
                            parentRow = _this.productGrid.getrowdatabyid(row.referrence);
                        }
                        let query = '?parentRef=' + row.parentRef + '&dataLevel=' + row.dataLevel +
					'&color=' + parentRow.color_attribute + '&size=' + parentRow.size_attribute;

                        _this.setState({ queryForOptions: query, modalName: 'productOption' });
                        _this.modalOpen();
                    }
                } else {
                    // make queryForOptions empty when there is no cloned list
                    _this.setState({ queryForOptions: '' });
                }
            });



            /**
			 * RowClick event: Opening a copy-level and display-level if the column is "copyLevel and displaylevel"
			 */
            _this.productGrid.off('cellclick');
            _this.productGrid.on('cellclick', function (event) {
                var args = event.args;
                args.dataField = args.datafield;
                var dataField = args.dataField;

                var row = args.row.bounddata;

                let gridContainer = "#jqxgrid";
                if (args.row.dataLevel == "Intm") {
                    gridContainer = '#level2grid' + args.row.level1;
                }
                else if (args.row.dataLevel == "Sku" && args.row.parentRef) {
                    if (args.row.level == 2) {
                        gridContainer = '#level2grid' + args.row.level1;
                    } else if (args.row.level == 3) {
                        gridContainer = '#level3grid' + args.row.level1 + args.row.level2;
                    } else {
                        gridContainer = '';
                    }
                }

                //Row Locking
                //let validRorlockFields = ["copywrite","keywords","imageApproval","color_attribute","size_attribute"];
                // let validRorlockFields = ["catId", "featureColor", "swatchOnly", "shotAngle", "copywrite", "keywords", "imageApproval", "color_attribute", "size_attribute"];

                // let prodRowlockList = (row.dataLevel === "SP" || row.dataLevel === "Intm") ? row.chldProuctId : row.productId;
                // if (validRorlockFields.indexOf(dataField) < 0) {
                //   if (row.rowLocking) {
                //     return;
                //   }

                // _this.allowUnlock = false;

                // if(_this.rowUniqueId === '') {
                //   _this.rowUniqueId = args.row.bounddata.uniqueid
                // }
                // else {
                //   if(_this.rowUniqueId != args.row.bounddata.uniqueid) {


                //       let headers = {
                //             'Authorization': prevProps.user.token,
                //             'content-type': 'application/json'
                //       }

                //       axios({
                //         method: 'get',
                //         url: require('../common/configurations.js').product.sessionLock + '?productIdLst=' + prodRowlockList + '&lastUserIdTime=' + args.row.bounddata.lastUserIdTime + '&unlock=false',
                //         headers: headers
                //       })
                //       .then( (response) => {
                //           let res = response.data

                //           // _this.allowUnlock = true;
                //           if (!res.status && res.responseCode == 122) {
                //           //   // _this.allowUnlock = false;
                //           //   // _this.productGrid.lockRow(row.productId);
                //           //   // _this.productGrid.endcelledit(row.productId);
                //             $(gridContainer).jqxGrid('endcelledit', row.productId);
                //             NotificationManager({ message: res.responseMessage, type: 4 });
                //             return false;
                //           }
                //           //   else if (!res.status && res.responseCode == 123) {
                //           //   NotificationManager({ message: res.responseMessage, type: 4 }); // ask user to refresh the grid
                //           //   return false;
                //           // }

                //       })
                //       .catch( error => {
                //       });



                //   }
                // }

                // let headers = {
                //       'Authorization': prevProps.user.token,
                //       'content-type': 'application/json'
                // }

                // axios({
                //   method: 'get',
                //   url: require('../common/configurations.js').product.sessionLock + '?productIdLst=' + prodRowlockList + '&lastUserIdTime=' + args.row.bounddata.lastUserIdTime + '&unlock=false',
                //   headers: headers
                // })
                // .then( (response) => {
                //     let res = response.data

                //     // _this.allowUnlock = true;
                //     if (!res.status && res.responseCode == 122) {
                //     //   // _this.allowUnlock = false;
                //     //   // _this.productGrid.lockRow(row.productId);
                //     //   // _this.productGrid.endcelledit(row.productId);
                //       $(gridContainer).jqxGrid('endcelledit', row.productId);
                //       NotificationManager({ message: res.responseMessage, type: 4 });
                //       return false;
                //     }
                //     //   else if (!res.status && res.responseCode == 123) {
                //     //   NotificationManager({ message: res.responseMessage, type: 4 }); // ask user to refresh the grid
                //     //   return false;
                //     // }

                // })
                // .catch( error => {
                // });


                // }

                //


                // if (row.dataLevel === 'SP') {
                // 	if (row.itemReadyToOrder !== true && (dataField === 'copyLevel' || dataField === 'displayLevel')) {

                // 	var childProds = [];

                // 	_this.getAllChildProducts(row.children);

                // 	_this.currentRowData = row;
                // 	_this.levelType = dataField;
                // 	_this.modalOpen();
                // 	_this.setState({ modalName: 'copyordisplayLevel' });
                // 	}
                // }

                var rowData = row;
                _this.currentFieldText = _this.productGrid.getColumnProperty(dataField, 'text');
                if (rowData && rowData.copyPop && _this.copyPopupFields.indexOf(dataField) > -1) {
                    var root = _this.productGrid.getRow(rowData.referrence);
                    var childlist = _this.getResChildRows(root);
                    var chlist = childlist.map((p, i) => {
                        return p.productId;
                    });
                    _this.childProdList = chlist;
                    _this.productGridPopupType = 'copy';
                    _this.setState({ modalName: "ProductGridPopup" });
                    _this.modalOpen();
                    return;
                }
                if (rowData && rowData.displayPop && _this.displayPopupFields.indexOf(dataField) > -1) {
                    var root = _this.productGrid.getRow(rowData.referrence);
                    var childlist = _this.getResChildRows(root);
                    var chlist = childlist.map((p, i) => {
                        return p.productId;
                    });
                    _this.childProdList = chlist;
                    _this.productGridPopupType = 'display';
                    _this.setState({ modalName: "ProductGridPopup" });
                    _this.modalOpen();
                    return;
                }
                if (rowData.copyEditable === false && _this.copyPopupFields.indexOf(dataField) > -1) {
                    return false;
                }
                if (rowData.displayEditable === false && _this.displayPopupFields.indexOf(dataField) > -1) {
                    return false;
                }
                if (["swatchOnly", "color_attribute", "size_attribute"].indexOf(args.dataField) > -1
				&& args.row.parentRef && args.row.dataLevel == "SP") {
                    return false;
                }
            });

            _this.getAllChildProducts = (records) => {
                _this.childRowData = [];
                records.map((value) => {
                    if (value.dataLevel === 'Intm') {
                        value.children.map((recs) => {
                            delete recs.data;  //remove extra data object;
                            _this.childRowData.push(recs);
                        });
                    }
                });
            };

            // on change of the row selection, checking if new row is added, then checking if user
            // moves away from it without adding required fields
            _this.productGrid.off('rowclick');
            _this.productGrid.on('rowclick', function (event) {
                var rowData = args.row.bounddata;
                if (_this.newRecordId) {
                    _this.productEditing = false;
                    _this.productGrid.selectRow(_this.newRecordId);
                }
                if (_this.clonedList.length) {
                    _this.productEditing = false;
                    _this.clonedList.map(function (e, i) {
                        _this.productGrid.selectRow(e);
                    });
                }
                var currentSelected = _this.productGrid.getSelection();

                //&& _this.newRecordId !== currentSelected[0].uid
                if (_this.newRecordId && currentSelected.length > 0 && rowData.productId != _this.newRecordId) {
                    if (_this.fieldNameEndCellEdit) {
                        _this.productGrid.endCellEdit(_this.newRecordId, _this.fieldNameEndCellEdit);
                    }
                    console.log('New Record Id is: ', _this.newRecordId);
                    var currentRecord = _this.productGrid.getRow(_this.newRecordId);
                    console.log('Row Click...Current Record is: ', currentRecord);
                    currentRecord.color_attribute = "";
                    currentRecord.size_attribute = "";
                    var list = [currentRecord];
                    var validate = _this.ValidateRequiredFields(_this.requiredAddFields, list);
                    var unfilledField = _this.getUnfilledColumns(_this.requiredAddFields, list);

                    if (validate.isValid) {
                        // if valid send the row details to backend
                        _this.sendUpdatedOrAddedRecord(currentRecord);
                    } else {
                        _this.productGrid.unSelectRow(rowData.productId);
                        _this.productGrid.selectRow(_this.newRecordId);
                        // _this.alertRequiredFields(validate);
                        _this.alertWithNotFilledFields(unfilledField);
                        _this.requiredFieldsAlert = validate;
                    }
                } else if (_this.clonedList.length > 0 && currentSelected.length > 0
				&& _this.clonedList.indexOf(rowData.productId) === -1 && _this.clonedList[_this.clonedList.length - 1] !== rowData.productId) {
                    if (_this.fieldNameEndCellEdit) {
                        _this.productGrid.endCellEdit(_this.clonedList[_this.clonedList.length - 1], _this.fieldNameEndCellEdit);
                    }

                    _this.validateClonedList();
                }
            });

            // column reorder execution
            // implement this in Batch 3
            _this.productGrid.off('columnreordered');
            _this.productGrid.on('columnreordered', function (event) {
                _this.props._REDUX_DISPATCH_COLUMN_RESEQUENCE({ isSaveNewView: true, where: 'product-grid' }); // Updating into Redux
                var old = event.args.oldindex,
                    newIndex = event.args.newindex,
                    newColumndetails = [];

                _this.props.columnDetails.forEach((item, index) => {
                    // newColumndetails.push(Object.assign([],item));
                    newColumndetails.push(item);
                });
                var moved = newColumndetails.splice(old - 1, 1);
                newColumndetails.splice(newIndex - 1, 0, moved[0]);
                _this.props.addColumnDetails(newColumndetails); // Updating into Redux
                // _this.updateColumnResequence();

                //_this.rebuildTheGrid();
                // _this.productGrid.refreshGrid();
            });

            _this.productGrid.off('columnresized');

            _this.productGrid.on('columnresized', function (event) {
                var oldWidth = event.args.oldwidth,
                    newWidth = event.args.newwidth,
                    newColumndetails = Object.assign([], _this.props.columnDetails),
                    fieldIndex = _this.getColumnIndex(event.args.datafield);
                newColumndetails[fieldIndex].width = newWidth;
                _this.props.addColumnDetails(newColumndetails); // Updating into Redux
                // _this.updateColumnResequence();
                _this.props._REDUX_DISPATCH_COLUMN_RESEQUENCE({ isSaveNewView: true, where: 'product-grid' }); // Updating into Redux
                // _this.productGrid.refreshGrid();
            });


            //
            _this.productGrid.off('filter');
            _this.productGrid.on('filter', function (event) {
                // _this.setState({ isfilterApplied: !_this.state.isfilterApplied });
                let arrFilterInfo = _this.productGrid.getfilterinformation();
                if (arrFilterInfo.length > 0) {
                    _this.appliedGridFiltersList = arrFilterInfo;
                    _this.setState({ isfilterApplied: true, filterchanged: !_this.state.filterchanged });
                } else {
                    _this.setState({ isfilterApplied: false, filterchanged: !_this.state.filterchanged });
                }
            });
            _this.productGrid.off('groupschanged');
            _this.productGrid.on('groupschanged', function (event) {
                // event arguments.
                var args = event.args;
                // groups array.
                var groups = args.groups;
                _this.groupsList = groups;
            });
        }

        // cloning slected products

        if (prevProps.cloneCount !== this.props.cloneCount) {
            this.cloneSelectedRecords();
        }
    }

    // loading after product list is fetched successfully
    makeCallToRequiredFields(userToken) {
        let headers = {
            'Authorization': userToken,
            'content-type': 'application/json'
        };

        axios({
            method: 'get',
            url: require('../common/configurations.js').product.requiredFields,
            headers: headers
        })
            .then( (response) => {
                let res = response.data;

                if (res.data.length > 0) {
                    this.parseTheRequiredFields(res.data);
                }
            })
            .catch( error => {
                console.log('productContainer componentDidMount  makeCallToRequiredFields API call failed', error);
                this.setState({ isRequiredFieldsLoaded: false });
            });
    }


    /**
     * get columnindex
     * @param {object} fieldName - column fieldname
     */
    getColumnIndex(fieldName) {
        let index;
        this.props.columnDetails.map((e, i) => {
            if (e.fieldName === fieldName) {
                index = i;
            }
        });
        return index;
    }


    /**
     * Item Deactivate
     * @param {array} req - Id(productId), value(true or false)
     */
    productDeactivate(req, isChecked) {
        let _this = this;

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.activeliveeupdate(req)
            .then( (response) => {
                let res = response.data;

                if (res.status) {
                    NotificationManager({ message: res.responseMessage, type: 1 });
                    _this.productGrid.setCellValue(req[0].id, 'isActiveLive', isChecked);
                } else {
                    NotificationManager({ message: res.responseMessage, type: 4 });
                    _this.productGrid.setCellValue(req[0].id, 'isActiveLive', !isChecked);
                }
            })
            .catch( error => {
                console.log('productContainer productDeactivate  API call failed', error);
                NotificationManager({ message: 'Service Failed', type: 4 });
                _this.productGrid.setCellValue(req[0].id, 'isActiveLive', !isChecked);
            });
    }

    /**
     * update PI received checkbox after PI upload is done
     * @param {object} data - rowdata
     */
    updatePIRecived(status, rowId) {
        let _this = this;
        if (!status) {
            _this.productGrid.setCellValue(rowId, 'isPiReceived', false);
        } else {
            let headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json'
            };

            axios({
                method: 'post',
                url: require('../common/configurations.js').product.piRecieved,
                headers: headers,
                data: { "id": rowId, value: true }
            })
                .then( (response) => {
                    console.log('productContainer updatePIRecived API call success data: ', response.data);
                    let res = response.data;

                    if (res.status) {
                        NotificationManager({ message: res.responseMessage, type: 1 });
                    } else {
                        NotificationManager({ message: res.responseMessage, type: 4 });
                    }
                })
                .catch( error => {
                    console.log('productContainer updatePIRecived  API call failed', error);
                });
      	}
    }


    /** parseTheRequiredFields : Converts the array of objects to object with
     * fieldName and status as key pair required for clone,add & ready to order
    */
    parseTheRequiredFields(data) {
        var requiredFields = {
            add: {},
            clone: {},
            readyToOrder: {}
        };
        /* actualFieldName,reqdToAdd,fieldsToClone,readyToOrder are constant values
      *  based on the response from backend.
      *  CHECK THESE VALUES IF ANY ERROR COMES
      */
        data.forEach((item, index) => {
            requiredFields.add[item.actualFieldName] = item.reqdToAdd;
            requiredFields.clone[item.actualFieldName] = item.fieldsToClone;
            requiredFields.readyToOrder[item.actualFieldName] = item.readyToOrder;
            if (!item.actualFieldName || item.actualFieldName === '') {
                console.warn("actualFieldName is coming empty, With out this addRecord and clone will not work.");
            }
        });

        this.setRequiredFields(requiredFields);
    }

    /*setRequiredFields will generate an array of required fields and set to
    * the appropriate variable
    */
    setRequiredFields(obj) {
        this.requiredCloneFields = getTrueFields(obj.clone);
        this.requiredAddFields = getTrueFields(obj.add);
        this.requiredReadyFields = getTrueFields(obj.readyToOrder);
        window.readyToOrderFields = this.requiredReadyFields;

        function getTrueFields(obj, buildArray) {
            var builtArray = [];
            for (var item in obj) {
                if (obj[item]) {
                    builtArray.push(item);
                }
            }

            return builtArray;
        }

        // function getFalseFields(obj, buildArray) {
        //   for (var item in obj) {
        //     if (!obj[item]) {
        //       buildArray.push(item);
        //     }
        //   }
        // }
    }

    checkAllRows() {
        var _this = this;
        var allRows = this.productGrid.getView();
        var listData = [];
        function checkAllRows(rows) {
            rows.forEach((item) => {
                //_this.productGrid.setCellValue(item.productId,'checked',true);
                item.checked = true;
                listData.push(item);
                if (item.children && item.children.length > 0) {
                    checkAllRows(item.children);
                }
            });
        }
        checkAllRows(allRows);
        _this.props.addGridData(listData);
        _this.props.gridDataChanged();
    }

    unCheckAllRows() {
        var _this = this;
        var listData = [];
        var allRows = this.productGrid.getView();
        function uncheckedallrows(rows) {
            rows.forEach((item) => {
                item.checked = false;
                listData.push(item);
                if (item.children && item.children.length > 0) {
                    uncheckedallrows(item.children);
                }
            });
        }
        uncheckedallrows(allRows);
        _this.props.addGridData(listData);
        _this.props.gridDataChanged();
    }

    piReceivedModalClose(status, rowId) {
     	this.setState({ isModalOpen: false, piPdfUrl: '' });
    }


    rowUnlock() {
        // row unlock
        let rowdata = this.currentRowData;
        let prodRowlockList = (rowdata.dataLevel === "SP" || rowdata.dataLevel === "Intm") ? rowdata.chldProuctId : rowdata.productId;

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        product.sessionLock('?productIdLst=' + prodRowlockList + '&lastUserIdTime=' + rowdata.lastUserIdTime + '&prodGridLoadTime=' + this.productGridLoadTime + '&unlock=true' + '&escapePressed=false')
            .then( (response) => {
                let res = response.data;
                if (!res.status) {
                    NotificationManager({ message: res.responseMessage, type: 4 });
                }
            })
            .catch( error => {
                console.log('productContainer rowUnlock  API call failed', error);
                NotificationManager({ message: xhr.message, type: 4 });
            });
    }

    getPiForProduct(productArray) {
        let _this = this;
        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        axios({
            method: 'post',
            url: require('../common/configurations.js').product.getPiForProduct,
            headers: headers,
            data: productArray
        })
            .then( (response) => {
                let res = response.data;

                if (res.status) {
                    _this.setState({ piPdfUrl: res.data.pdfUrl });
                } else {
                    _this.setState({ piPdfUrl: '' });
                }
            })
            .catch( error => {
                console.log('productContainer getPiForProduct  API call failed', error);
            });
    }


    modalOpen() {
      	this.setState({ isModalOpen: true });
    }

    modalClose() {
        this.setState({ isModalOpen: false });
        if (this.state.modalName === "addCopyModel" || this.state.modalName === "addKeywordModal") {
            this.rowUnlock();
        }
    }

    imgApprovalModalClose() {
      	this.setState({ isModalOpen: false, isImageModalOpen: false });
    }



    // toggleRightDrawer(){
    //   this.setState({rightDrawerPulled:!this.state.rightDrawerPulled});
    // }


    getAdminSettings() {
        var _this = this;

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.getAdminSettings()
            .then( (response) => {
                let res = response.data;
                if (res.settings) {
                    window.settings = res.settings;
                }
            })
            .catch( error => {
                console.log('productContainer getAdminSettings  API call failed', error);
            });
    }


    onUnload(e) {
        e.preventDefault();

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.releaseLock()
            .then( (response) => {
                console.log('productContainer onUnload API call success data: ');
                // let res = response.data
            })
            .catch( error => {
                console.log('productContainer onUnload  API call failed', error);
            });
    }


    //Reload grid
    reloadGrid() {
        this.setState({ isLoaded: false});

        // this.props.toggleSpinner(true)

        let _this = this, req = "", method = "", url = "";

        let source = _this.sourceFrom;
        if (source.type != "") {
            method = source.serviceType;
            req = source.navObj;
            url = source.url;
        } else {
            // $("#apply-filter").click();
            method = 'post';
            req = _this.props.appliedFilters;
            url = (_this.props.appliedFilters.type === "search") ? require('../common/configurations.js').product.productsearch : require('../common/configurations.js').product.filters;
        }

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        if(this.gridData === undefined) {
            NotificationManager({ message: 'There is Nothing to Reload', type: 4 });
            this.props.toggleSpinner(false);
            return;
        }

        // req.department = req.catIds ;

        axios({
            method: 'post',
            url: url,
            headers: headers,
            data: req
        })
            .then( (response) => {
                let res = response.data;

                this.props.toggleSpinner(false);


                if (res.responseCode === 200) {
                    // _this.gridData = GridDataBuilder(res, 'product');
                    // _this.setState({ isLoaded: true, treegridcurrentPos: 0 });
                    // _this.props.addGridData(res.data);
                    // _this.props.gridDataChanged();


                    var d = new Date();
                    _this.productGridLoadTime = [
                        d.getFullYear(),
                        d.getMonth() + 1,
                        d.getDate(),
                    ].join('-') + ' ' +
					[d.getHours(),
					    d.getMinutes(),
					    d.getSeconds()].join(':');

                    // localStorage.setItem('productGridLoadTime', productGridLoadTime)




                    let data = {};
                    data.columnDetails = this.props.columnDetails;
                    data.data = this.props.storeGridData;
                    data.dropdowns = this.props.storeGridDropDowns;
                    this.gridData = GridDataBuilder(data, 'product');

                    this.setState({ isLoaded: true, treegridcurrentPos: 0 });
                    this.props.toggleSpinner(false);
                } else {
                    NotificationManager({ message: res.responseMessage, type: 4 });
                    // _this.setState({ isLoaded: true, treegridcurrentPos: 0 });
                    _this.props.addGridData([]);
                    _this.props.gridDataChanged();
                }
            })
            .catch( error => {
                console.log('productContainer reloadGrid  API call failed', error);
                this.props.toggleSpinner(false);
            });
    }

    /**
      * update attr completed true
      */
    updateAttrCompleted() {
        var _this = this;
        var productId = _this.productId;
        var gridContainer = _this.currentGridContainer;
        var rowData = this.productGrid.getRow(productId, gridContainer);
        var list = this.copyToChildRows(rowData, 'attrCompleted');
        var postdata = list.map(function (p, i) {
            return { id: p.productId, value: true };
        });
        _this.currentRowData = rowData;


        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.updateAttrCompleted(postdata)
            .then( (response) => {
                let res = response.data;

                if (res.status) {
                    _this.productGrid.setCellValue(productId, 'attrCompleted', true, gridContainer);
                    _this.setCellValueAndCopyToChild('attrCompleted', true, gridContainer);
                    NotificationManager({ message: res.responseMessage + "<br/><b class='text-red'>This action can't be undone.</b>", type: 1 });
                    _this.modalClose();
                } else {
                    _this.modalClose();
                    NotificationManager({ message: res.responseMessage, type: 4 });
                }
            })
            .catch( error => {
                console.log('productContainer updateAttrCompleted  API call failed', error);
            });
    }


    /** function to open Sample popup */
    openSamplePopup() {
        var _this = this;
        _this.setState({ modalName: "SampleModal" });
        _this.modalOpen();
    }

    openPIUploadPopup(ids) {
        var _this = this;
        _this.productId = ids;
        _this.setState({ modalName: 'PiUploadModal' });
        _this.modalOpen();
    }

    openCampaignPopup() {
        let _this = this;
        _this.setState({ modalName: "CampaignModal" });
        _this.modalOpen();
    }

    loadCampaignEvents() {
        let _this = this;
        let pIds = [];
        let base = _this.campaignProduct;
        if (base) {
            if (base.dataLevel == "Sku") {
                pIds.push(base.productId);
            } else if (base.dataLevel == "Intm") {
                let arrIntm = base.chldProuctId;
                arrIntm.map(function (id, ind) {
                    pIds.push(id);
                });
            } else if (base.dataLevel == "SP") {
                let arrIntm = base.chldProuctId;
                arrIntm.map(function (id, ind) {
                    pIds.push(id);
                });
            }

            return pIds;
        }
    }

    /**
     * update row with Po information
     */
    updateProductForPO(data) {
        this.productGrid.updateRow(data.productId, data);
    }

    /**
     * store for undo changes
     */
    storeForUndoChanges() {
        let rowdata = this.oldRowData;

        if (rowdata) {
            this.undoEditFields.push(args.dataField);
            this.undoListData.unshift(rowdata);
            if (this.undoListData.length > 0) {
                this.setState({ isUndoEnable: true });
            }
        }
    }



    /**
     * undo last 5 changes
     */
    undoLastChanges() {
        if (this.undoCounter < 5) {
            var rowdata = this.undoListData[this.undoCounter];
            //this.productGrid.updaterow(rowdata.boundindex,rowdata);
            var gridContainer = "";
            if (rowdata.dataLevel == "Intm") {
                gridContainer = '#level2grid' + rowdata.level1;
            }
            else if (rowdata.dataLevel == "Sku" && rowdata.parentRef) {
                if (rowdata.level == 2) {
                    gridContainer = '#level2grid' + rowdata.level1;
                } else if (rowdata.level == 3) {
                    gridContainer = '#level3grid' + rowdata.level1 + rowdata.level2;
                } else {
                    gridContainer = '';
                }
            }
            for (var i = 0; i < this.undoEditFields.length; i++) {
                this.productGrid.setCellValue(rowdata.productId, this.undoEditFields[i], rowdata[this.undoEditFields[i]], gridContainer);
            }
            var list = this.copyToChildRows(rowdata, this.undoEditFields);
            this.sendUpdatedOrAddedRecord(list, true);
            this.undoCounter++;
            if (this.undoCounter === 5 || this.undoCounter === this.undoListData.length) {
                this.undoCounter = 0;
                this.undoListData = [];
                this.undoEditFields = [];
                this.oldRowData = null;
                this.setState({ isUndoEnable: false });
            }
        }
    }



    updateColumnResequence() {
        let _this = this;
        let req = { id: this.props.currentViewId.viewId, userId: this.props.user.userId, type: this.props.currentViewId.viewType, columnDetails: this.props.columnDetails };

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.resizeColOrderUpdate(req)
            .then( (response) => {
                let res = response.data;
                if (res.status) {
                    delete req.columnDetails;

                    ProductServices.viewColumndetails(req)
                        .then( (response) => {
                            _this.props.addColumnDetails(res.columnDetailsDTOs); // Updating into Redux
                        })
                        .catch( error => {
                            console.log('productContainer viewColumndetails  API call failed', error);
                        });
                }
            })
            .catch( error => {
                console.log('productContainer updateColumnResequence  API call failed', error);
            });
    }



    /** opens the required field confirm modal
      * @param {object} obj - object contains not filled rowId and fieldName
      */
    alertRequiredFields(obj) {
        this.setState({
            modalName: "requiredFieldConfirm"
        });
        this.requiredFieldsAlert = obj;
        this.getMandatoryColumnNames();
        this.modalOpen();
    }

    /** opens the required field confirm modal
     * @param {object} obj - object contains not filled rowId and fieldName
     */
    alertWithNotFilledFields(obj) {
        this.setState({
            modalName: "requiredFieldConfirm"
        });
        this.requiredFieldsAlertTemp = obj;

        // this.getMandatoryColumnNames();
        this.getMandatoryUnfilledColumnNames();
        this.modalOpen();
    }

    getMandatoryColumnNames() {
        this.mandatoryAddColumns = [];

        if (this.mandatoryAddColumns.length === 0) {
            this.props.columnDetails.map((item, index) => {
                if (this.requiredAddFields.indexOf(item.fieldName) > -1) {
                    return this.mandatoryAddColumns.push(item.columnTitle);
                }
            });
        }
    }

    getMandatoryUnfilledColumnNames() {
        this.mandatoryAddColumns = [];
        if (this.mandatoryAddColumns.length === 0) {
            this.props.columnDetails.map((item, index) => {
                if (this.requiredFieldsAlertTemp.indexOf(item.fieldName) > -1) {
                    return this.mandatoryAddColumns.push(item.columnTitle);
                }
            });
        }
    }

    updateSampleSwatchOption(productId, checked) {
        var base = this;
        var postdata = productId.map((o, i) => {
            return o.productId;
        });

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.updateProductSample({ checkedAll: checked, productId: postdata, swatchRef: "" })
            .then( (response) => {
            })
            .catch( error => {
                console.log('productContainer updateSampleSwatchOption  API call failed', error);
                NotificationManager({ message: xhr.message, type: 4 });
            });
    }



    checkForCellValChange(args) {
        // do not execute if new record or cloned list is editing, code changed by asaithambi
        var _this = this;
        /*if (this.swatchArrayRef.indexOf(args.dataField) === -1 && (this.newRecordId || this.clonedList.length > 0)) {
			return;
		}*/
        var cellAfterEdit,
            isChanged = false;
        if (args.displayValue instanceof Date) {
            cellAfterEdit = +args.displayValue;
        } else {
            cellAfterEdit = args.displayValue;
        }
        var gridContainer = "";
        if (args.row.dataLevel == "Intm") {
            gridContainer = '#level2grid' + args.row.level1;
        }
        else if (args.row.dataLevel == "Sku" && args.row.parentRef) {
            if (args.row.level == 2) {
                gridContainer = '#level2grid' + args.row.level1;
            } else if (args.row.level == 3) {
                gridContainer = '#level3grid' + args.row.level1 + args.row.level2;
            } else {
                gridContainer = '';
            }
      	}

        // checking for the field is mandatory and if it is made empty trigger a popup
        if (this.requiredAddFields && this.requiredAddFields.indexOf(args.displayfield) > -1 && cellAfterEdit == '') {
            var unfilledField = _this.getUnfilledColumns(_this.requiredAddFields, [args.row]);
            this.requiredFieldsAlertTemp = unfilledField;
            this.getMandatoryUnfilledColumnNames();
            this.setState({ modalName: 'emptyMandatoryfield' });
            this.modalOpen();
            this.addBackDetails = args;
            return;
        }
        if (this.swatchArrayRef.indexOf(args.dataField) > -1 && (args.row[this.swatchArrayRef[1]] === "Sample" || args.row[this.swatchArrayRef[1]].toLowerCase() === "none")) {
            this.productGrid.setCellValue(args.key, this.swatchArrayRef[0], "", gridContainer);
            var rowData = this.productGrid.getRow(args.key, gridContainer);
            var list = this.copyToChildRows(rowData, [this.swatchArrayRef[0]]);
            this.updateSampleSwatchOption(list, false);
        }
        if (this.swatchArrayRef.indexOf(args.dataField) > -1 && args.row[this.swatchArrayRef[1]] === "Swatch Only") {
            if (args.row[this.swatchArrayRef[0]] === undefined || args.row[this.swatchArrayRef[0]] === null || args.row[this.swatchArrayRef[0]] === "") {
                var styleID = args.row.styleNumber;
                var rowData = this.productGrid.getRow(args.key, gridContainer);
                var list = this.getResChildRows(rowData);
                var producIds = list.map((o, i) => {
                    return o.productId;
                });
                if (styleID) {
                    let headers = {
                        'Authorization': this.props.user.token,
                        'content-type': 'application/json'
                    };

                    ProductServices.getSampleIds({ id: producIds, styleId: styleID })
                        .then( (response) => {
                            let res = response.data;
                            if (res.responseStatus && res.data.length > 0) {
                                _this.swatchReferenceList = res.data;
                                _this.setState({ modalName: 'sampleSwatchDropDown' });
                                _this.modalOpen();
                                _this.addBackDetails = args;
                                var rowData = _this.productGrid.getRow(args.key, gridContainer);
                                var list = _this.copyToChildRows(rowData, [_this.swatchArrayRef[0]]);
                                _this.updateSampleSwatchOption(list, true);
                            }
                            else {
                                _this.setState({ modalName: 'sampleMessagePopup' });
                                _this.modalOpen();
                                _this.addBackDetails = args;
                            }
                        })
                        .catch( error => {
                            console.log('productContainer checkForCellValChange  API call failed', error);
                        });
                } else {
                    _this.setState({ modalName: 'sampleMessagePopup' });
                    _this.modalOpen();
                    _this.addBackDetails = args;
                }
                return;
            }
        }
        if (args.dataField === 'styleNumber') {
            var regex = /^[A-Za-z0-9-.]+$/i;
            if (cellAfterEdit && !cellAfterEdit.match(regex)) {
                NotificationManager({ message: "Alphanumeric, - and . are allowed.", type: 4 });
                _this.productGrid.setCellValue(args.key, args.dataField, args.oldvalue, gridContainer);
                _this.productGrid.beginCellEdit(args.key, args.dataField, gridContainer);
                return false;
            }
            // else if(args.value.length > 50){
            //  NotificationManager({ message: "Please enter less than 50 characters", type: 4 });
            //    _this.productGrid.beginCellEdit(args.key, args.dataField, gridContainer);
            //  return false;
            // }
        }
        if (["swatchOnly", "color_attribute", "size_attribute"].indexOf(args.dataField) > -1 && args.row.parentRef && args.row.dataLevel == "SP") {
            return;
        }
        //this.cellBeforeEdit = this.cellBeforeEdit?this.cellBeforeEdit:"";
        if (this.newRecordId || this.clonedList.length > 0) {
            return;
        }
        if (!args.oldvalue) {
            args.oldvalue = "";
        }
        var list = [args.row];
        var validate = _this.ValidateRequiredFields(_this.requiredAddFields, list);
        var unfilledField = _this.getUnfilledColumns(_this.requiredAddFields, list);

        if (!validate.isValid) {
            // _this.alertRequiredFields(validate);
            this.productEditing = true;
            _this.alertWithNotFilledFields(unfilledField);
            this.requiredFieldsAlert = validate;
            return;
        }

        if (args.oldvalue != args.value) {
            // checking for the field is mandatory and if it is made empty trigger a popup
            this.storeForUndoChanges(args.dataField);
            window.gridEditedRows.push(args.row.productId);
            this.productGrid.updateRow(args.row.productId, args.row);
            var list = this.copyToChildRows(args.row, [args.dataField]);
            this.sendUpdatedOrAddedRecord(list);
        } else {
            // unlocking row
            //Locking
            if (!this.allowUnlock) {
                let prodRowlockList = args.row.productId ? args.row.productId : args.row.chldProuctId;
            } else {
                let prodRowlockList = (args.row.dataLevel === "SP" || args.row.dataLevel === "Intm") ? args.row.chldProuctId : args.row.productId;


                let headers = {
                    'Authorization': this.props.user.token,
                    'content-type': 'application/json'
                };

                product.sessionLock('?productIdLst=' + prodRowlockList + '&lastUserIdTime=' + args.row.lastUserIdTime +  '&prodGridLoadTime=' + _this.productGridLoadTime + '&unlock=true' + '&escapePressed=false').then( (response) => {
                })
                    .catch( error => {
                        console.log('productContainer checkForCellValChange  args.oldvalue else API call failed', error);
                    });
            }
        }
    }


    getResChildRows(row) {
        var _this = this;
        var list = [], rowdata;
        function getChild(c) {
            c.map((p, i) => {
                if (p.dataLevel !== "Intm") {
                    delete p.data;
                    delete p.parent;
                    list.push(p);
                }
                if (p.children && p.children.length > 0) {
                    getChild(p.children);
                }
            });
        }
        if (row.dataLevel == "SP") {
            // rowdata = _this.productGrid.getRow(row.parentRef);

            getChild(row.children);
        }
        else if (row.dataLevel == "Intm") {
            rowdata = _this.productGrid.getRow(row.referrence);
            var itmchild = null;
            if (rowdata) {
                rowdata.children.map((o, i) => {
                    if (o.productId === row.productId) {
                        itmchild = o;
                    }
                });
            } else {
                itmchild = row;
            }
            getChild(itmchild.children);
        } else {
            list.push(row);
        }
        return list;
    }

    copyToChildRows(row, copyfields) {
        var _this = this;
        var list = [], rowdata;
        copyfields = copyfields ? copyfields : [];
        function updateChild(g, c) {
            c.map((p, i) => {
                for (var l = 0; l < copyfields.length; l++) {
                    if (copyfields[l] == "styleNumber") {
                        if (!p[copyfields[l]]) {
                            p[copyfields[l]] = g[copyfields[l]];
                        }
                    } else {
                        p[copyfields[l]] = g[copyfields[l]];
                    }
                }
                _this.productGrid.updateRow(p.productId, p);
                if (p.dataLevel !== "Intm") {
                    delete p.data;
                    delete p.parent;
                    list.push(p);
                }
                if (p.children && p.children.length > 0) {
                    updateChild(g, p.children);
                }
            });
        }
        if (row.dataLevel == "SP") {
            rowdata = _this.productGrid.getRow(row.productId);
            rowdata = (rowdata === undefined) ? row : rowdata;
            updateChild(rowdata, rowdata.children);
        }
        else if (row.dataLevel == "Intm") {
            /*rowdata = _this.productGrid.getRow(row.referrence);
			rowdata = (rowdata === undefined)? row.parent: rowdata
			var itmchild =null;
			if(rowdata){
			rowdata.children.map((o,i)=>{
				if(o.productId===row.productId){
				itmchild=o;
				}
			});
			}else{
			itmchild=row;
			}*/
            updateChild(row, row.children);
        } else {
            list.push(row);
        }
        return list;
    }

    /**
     * When they make sampe to swartch only from sample model
     */
    setValueSwatchonly(swatchref) {
        if (this.currentRowData) {
            var value = 'Swatch Only';

            var rowdata = this.currentRowData;
            var gridContainer = "";
            if (rowdata.dataLevel == "Intm") {
                gridContainer = '#level2grid' + rowdata.level1;
            }
            else if (rowdata.dataLevel == "Sku" && rowdata.parentRef) {
                if (rowdata.level == 2) {
                    gridContainer = '#level2grid' + rowdata.level1;
                } else if (rowdata.level == 3) {
                    gridContainer = '#level3grid' + rowdata.level1 + rowdata.level2;
                } else {
                    gridContainer = '';
                }
            }
            //Setting window object reference for grid check disable/enable;
            this.productGrid.setCellValue(rowdata.productId, 'swatchOnly', value, gridContainer);
            this.productGrid.setCellValue(rowdata.productId, 'swatchReference', swatchref, gridContainer);

            //var rowData = this.productGrid.getRow(args.key);
            this.copyToChildRows(rowdata, ["swatchReference", "swatchOnly"]);
        }
    }

    /**
     * Set Value as Sample when there is no swatch reference
     */
    setValueSampleOption() {
        let args = this.addBackDetails, list;
        if (args.dataField === "swatchOnly") {
            var rowdata = args.row;
            var gridContainer = "";
            if (rowdata.dataLevel == "Intm") {
                gridContainer = '#level2grid' + rowdata.level1;
            }
            else if (rowdata.dataLevel == "Sku" && rowdata.parentRef) {
                if (rowdata.level == 2) {
                    gridContainer = '#level2grid' + rowdata.level1;
                } else if (rowdata.level == 3) {
                    gridContainer = '#level3grid' + rowdata.level1 + rowdata.level2;
                } else {
                    gridContainer = '';
                }
            }
            //this.productGrid.getRow(args.key);
            this.productGrid.setCellValue(args.key, args.dataField, "Sample", gridContainer);
            list = this.copyToChildRows(rowdata, [args.dataField], gridContainer);
        }
        if (this.newRecordId) {
            return;
        }
        this.sendUpdatedOrAddedRecord(list);
    }

    /**
     * Set value or swatch reference colum when choose swatchOnly option
    */
    setValueSwatchReference() {
        let args = this.addBackDetails, list;
        var rowdata = args.row.bounddata ? args.row.bounddata : args.row;
        var gridContainer = "";
        if (rowdata.dataLevel == "Intm") {
            gridContainer = '#level2grid' + rowdata.level1;
        }
        else if (rowdata.dataLevel == "Sku" && rowdata.parentRef) {
            if (rowdata.level == 2) {
                gridContainer = '#level2grid' + rowdata.level1;
            } else if (rowdata.level == 3) {
                gridContainer = '#level3grid' + rowdata.level1 + rowdata.level2;
            } else {
                gridContainer = '';
            }
        }
        this.productGrid.setCellValue(args.key, "swatchReference", $(".grid-swatch-reference").val(), gridContainer);
        //var rowData = this.productGrid.getRow(args.key);
        list = this.copyToChildRows(rowdata, ["swatchReference", "swatchOnly"]);
        if (this.newRecordId) {
            return;
        }

        this.sendUpdatedOrAddedRecord(list);
    }

    setCellValueAndSaveProduct(productId, dataField, value, selectedCatHierarchy) {
        var list = [];
        var _this = this;
        var copyFields = [];
        var rowdata = null;
        if (dataField === 'catId') {
            _this.productGrid.setCellValue(_this.selectCategoryRow.productId, dataField, value);
            for (var key in selectedCatHierarchy) {
                if (selectedCatHierarchy.hasOwnProperty(key)) {
                    if (selectedCatHierarchy[key]) {
                        _this.productGrid.setCellValue(_this.selectCategoryRow.productId, key, selectedCatHierarchy[key]);
                    } else {
                        _this.productGrid.setCellValue(_this.selectCategoryRow.productId, key, "");
                    }
                }
            }
            copyFields = ['catId', 'division', 'department', 'classColumn', 'subClassColumn'];
            rowdata = _this.selectCategoryRow;
            _this.categorySampleRecipient = 'catId';
        } else {
            copyFields = [dataField];
            _this.productGrid.setCellValue(productId, dataField, value);
            rowdata = _this.productGrid.getRow(productId);
        }
        if (this.newRecordId || this.clonedList.length > 0) {
            return;
        }
        list = this.copyToChildRows(rowdata, copyFields);
        if (list.length > 0) {
            this.sendUpdatedOrAddedRecord(list);
        }
        else {
            NotificationManager({ message: "There is issue with product data, try with newly created products", type: 4 });
        }
    }

    /**
     *
     * @param {*} fieldName
     * @param {*} value
     */
    setCellValueAndCopyToChild(fieldName, value, gridContainer) {
        var _this = this;
        var productId = _this.currentRowData.productId;
        var rowdata = _this.currentRowData;
        if (!gridContainer) {
            if (rowdata.dataLevel == "Intm") {
                gridContainer = '#level2grid' + rowdata.level1;
            }
            else if (rowdata.dataLevel == "Sku" && rowdata.parentRef) {
                if (rowdata.level == 2) {
                    gridContainer = '#level2grid' + rowdata.level1;
                } else if (rowdata.level == 3) {
                    gridContainer = '#level3grid' + rowdata.level1 + rowdata.level2;
                } else {
                    gridContainer = '';
                }
            }
        }

        _this.productGrid.setCellValue(productId, fieldName, value);

        this.copyToChildRows(rowdata, [fieldName]);
        var parentRow = null;
        if (rowdata.parentRef && rowdata.dataLevel == "Intm" && rowdata.referrence) {
            parentRow = _this.productGrid.getRow(rowdata.referrence);
        }
        if (rowdata.parentRef && rowdata.dataLevel == "Sku" && rowdata.referrence) {
            if (rowdata.level == 2 || rowdata.level == 1) {
                gridContainer = '';
            } else {
                gridContainer = '#level2grid' + rowdata.level1;
            }
            var intmParentRow = _this.productGrid.getRow(rowdata.referrence, gridContainer);
            if (intmParentRow && intmParentRow.dataLevel == "Intm") {
                _this.updateParentRowStatus(intmParentRow, fieldName, value, gridContainer);
                parentRow = _this.productGrid.getRow(intmParentRow.referrence);
            } else {
                parentRow = intmParentRow;
            }
        }
        if (parentRow) {
            _this.updateParentRowStatus(parentRow, fieldName, value);
        }
    }

    updateParentRowStatus(parentRow, fieldName, value, gridContainer) {
        var _this = this;
        var list = _this.getResChildRows(parentRow);
        var matchCount = 0;
        var valueStr = list[0][fieldName];
        for (var i = 0; i < list.length; i++) {
            if (["copywrite", "keywords", "images"].indexOf(fieldName) > -1) {
                if (list[i][fieldName]) {
                    matchCount++;
                }
            } else {
                if (valueStr === list[i][fieldName]) {
                    matchCount++;
                }
            }
        }
        if (matchCount == list.length) {
            _this.productGrid.setCellValue(parentRow.productId, fieldName, value, gridContainer);
        } else {
            _this.productGrid.setCellValue(parentRow.productId, fieldName, false, gridContainer);
        }
    }

    /**
     * rebuilds the grid with columndetails and data available from redux store
     */
    rebuildTheGrid() {
        this.props.toggleSpinner(true);

        if(this.props.storeGridData.length != 0) {
            // this.props.toggleSpinner(true)
            // this.setState({ isLoaded: false });
            let res = {};
            res.columnDetails = this.props.columnDetails;
            res.data = this.props.storeGridData;
            res.dropdowns = this.props.storeGridDropDowns;
            res.readyToOrderFields = this.requiredReadyFields;

            this.overwriteProductResData(res);  // this function populates the swatch column
            // this.productGridReady = function () { }
            // res.columnDetails = res.columnDetails.slice(0, 50)
            console.time('gridloadtime');

            console.log('Data is : ', res);

            // return;
            this.gridData = GridDataBuilder(res, 'product');


            if(this.gridData) {
                console.timeEnd('gridloadtime');

                // $('.gray-button').attr('disabled',false);
                $('.record-options').css('pointer-events', 'auto');
            }


            setTimeout(() =>{
                this.props.toggleSpinner(false);
                this.setState({isLoaded:true});

                var d = new Date();
                this.productGridLoadTime = [
                    d.getFullYear(),
                    d.getMonth() + 1,
                    d.getDate(),
                ].join('-') + ' ' +
					[d.getHours(),
					    d.getMinutes(),
					    d.getSeconds()].join(':');

                // localStorage.setItem('productGridLoadTime', productGridLoadTime)
            }, 500);
        }
        else {
            NotificationManager({ message: 'Please populate the Product Grid and Try again.', type: 4 });
        }
    }

    overwriteProductResData(res) {
        window.ProductGridDropDowns = res.dropdowns;
        var _this = this;
        /** Code written By Asaithambi for samples and swatchs */
        //Hardcode values for sample dropdowns, currently using description field instead of sample column for the development
        if (!res.dropdowns) {
            res.dropdowns = {};
        }
        res.dropdowns['swatchOnly'] = [{ fieldId: 'Sample', fieldName: "Sample" }, { fieldId: 'Swatch Only', fieldName: "Swatch Only" }, { fieldId: 'None', fieldName: "None" }];
    }

    //it will add back the emptied field
    addBackTheField() {
        let args = this.addBackDetails;
        let _this = this;
        if (args && this.swatchArrayRef.indexOf(args.dataField) === -1) {
            this.productGrid.setCellValue(args.key, args.dataField, this.cellBeforeEdit);
        }
        else if (this.swatchArrayRef.indexOf(args.dataField) > -1) {
            _this.selectSingleRow(args.key);
            _this.productGrid.beginCellEdit(args.key, _this.swatchArrayRef[0]);
        }
    }

    parseForBackend(data) {
        var newObj,
            _this = this;
        if (typeof (data) === "object" && data.length) {
            // check for object reference when array is passed
            data.forEach((item, index) => (
                parseValues(item)
            ));
        } else {
            newObj = Object.assign({}, data);
            delete newObj.data;
            parseValues(newObj);
        }

        function parseValues(obj) {
            for (var item in obj) {
                if (obj[item] instanceof Date) {
                    obj[item] = obj[item].toISOString().replace(/(T|Z)/ig, ' ');
                } else if (item === "images" ||
				_this.jqWidgetReservedMembers.indexOf(item) > -1) {
                    delete obj[item];
                }
            }
        }

        return newObj;
    }


    /**common method to updates and adds new record
      * @param {object} obj - expects the record object
      */

    //update or add

    sendUpdatedOrAddedRecord(obj, isundo) {
        let _this = this;
        var requestData = [], newObj = null;
        let isCloned = false;

        console.log('before first modify', obj);


        if (Array.isArray(obj)) {
            if (obj[0].productId == this.clonedList[0]) {
                isCloned = true;
            }
            obj.map((o, i) => {
                newObj = this.parseForBackend(o);
                requestData.push(newObj);
            });
        } else {
            if (obj.productId == this.clonedList[0]) {
                isCloned = true;
            }
            newObj = this.parseForBackend(obj);
            requestData.push(newObj);
        }
        for (var i = 0; i < requestData.length; i++) {
            for (var key in requestData[i].dynaData) {
                if (requestData[i].dynaData.hasOwnProperty(key)) {
                    requestData[i].dynaData[key] = requestData[i][key];
                }
            }
        }


        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ////////////////////////////////////////////////////////////////
        ////for new refactored backend code to create a new postData object in a particular format

        // let newRequestData=[];
        // for(let i=0; i < requestData.length; i++) {
        // 	let obj = {};
        // 	for (var key in requestData[i]) {
        // 		if (requestData[i].hasOwnProperty(key)) {
        // 			if(requestData[i][key]) {

        // 				if(key ==='productId' && requestData[i][key] === _this.newRecordId) {
        // 					obj[key]="";

        // 				}
        // 				else
        // 					obj[key] = requestData[i][key];
        // 			}
        // 		}
        // 	}
        // 	newRequestData.push(obj);
        // }
        // let postData = {};
        // postData.productDetails = newRequestData;
        // postData.userRole = this.props.user.defaultRole;

        ////for new refactored backend code to create a new postData object in a particular format
        //////////////////////////////////////////////////////////////////


        ProductServices.updateOrAddProduct(requestData)
            .then((res) => {
                if(res.responseCode == 155) {
                    NotificationManager({ message: res.responseMessage, type: 4 });

                    axios({
                        method: 'get',
                        url: require('../common/configurations.js').product.sessionLock + '?productIdLst=' + _this.prodRowlockList + '&lastUserIdTime=' + _this.lastUserIdTime +  '&prodGridLoadTime=' + _this.productGridLoadTime + '&unlock=true' + '&escapePressed=false',
                        headers: headers,
                    })
                        .then( (response) => {

                        });

                    _this.rowLocked = false;
                    setTimeout(function(){
                        _this.productGrid.setCellValue(_this.rowLockedKey, _this.rowLockedDataField, _this.rowLockedOrgValue);
                    }, 1000);
                    return;
                }

                // if (res.responseCode == 201) {   // for new code
                if (res.responseCode == 200) {		// for old code
                    // setting the cell value to the newly assigned product id.
                    // _this.productGrid.setCellValue(_this.rowLockedKey, _this.rowLockedDataField, _this.rowLockedOrgValue);


                    if (isundo) {
                        NotificationManager({ message: "Undo Success", type: 1 });
                        // empty the newRecordId after submission
                        _this.newRecordId = '';
                        window.newProductId = null;
                    } else {
                        NotificationManager({ message: res.responseMessage, type: 1 });


                        _this.rowLocked = false;

                        // let newProductId = respPayload.data[0].productId + '';

                        _this.productGrid.setCellValue(_this.newRecordId, 'productId', res.data[0].productId );
                        window.newProductId = _this.newRecordId = res.data[0].productId;

                        // to update buyer name
                        if(_this.newRecordId){
                            _this.productGrid.setCellValue(res.data[0].productId, 'buyer', res.data[0].buyer);
                        }
                        // empty the newRecordId after submission
                        _this.newRecordId = '';
                        window.newProductId = null;

                        // Sample Recipient, Buyer and Attention To
                        let attentionToFieldList = ["sampleRecipient", "buyer"];
                        if (attentionToFieldList.indexOf(_this.attentionToFieldName) > -1) {
                            res.data.map((val, idx) => {
                                _this.productGrid.setCellValue(val.productId, 'attentionTo', val.attentionTo);
                            });
                        }
                        // "campaignDate", "distCenterDate", "launchDate"
                        let targetDateFieldList = ["campaignDate", "distCenterDate", "launchDate"];
                        if (targetDateFieldList.indexOf(_this.targetDatefieldName) > -1) {
                            res.data.map((val, idx) => {
                                _this.productGrid.setCellValue(val.productId, 'targetDate', val.targetDate);
                            });
                        }

                        // "category"
                        if (["catId"].indexOf(_this.categorySampleRecipient) > -1) {
                            res.data.map((val, idx) => {
                                _this.productGrid.setCellValue(val.productId, 'sampleRecipient', val.sampleRecipient);
                            });
                        }
                    }

                    if (isCloned) {
                        _this.clonedList = [];
                    }
                } else {
                    NotificationManager({ message: res.responseMessage, type: 4 });
                    // empty the newRecordId after submission
                    _this.newRecordId = '';
                    window.newProductId = null;
                }
            })
            .catch( error => {
                console.log('productContainer sendUpdatedOrAddedRecord  API call failed', error);
            });
    }

    swatchUpdate(rowId, checked) {
        ProductServices.swatchUpdate([{ "id": rowId, "value": checked }])
            .then( (response) => {
                let res = response.data;
            })
            .catch( (error) => {
                console.log('productContainer swatchUpdate API call success data: ', response.data);
            });
        // ajax.post(api.product.swatchUpdate, [{ "id": rowId, "value": checked }]);
    }


    cloneSelectedRecords() {
        let _this = this;
        let selectedRows = this.props.selectedRows;

        /**
		 * this method filter the three level product items and returns an object with level1,level2,level3
		 * properties as array. the array's will containe the list to clone in respective level(parent heirarchy)
		 */
        function filterRequiredListToClone() {
            var obj = {
                level1: [],
                level2: [],
                level3: []
            };

            selectedRows.forEach(function (item, value) {
                if (item.level == 1) {
                    obj.level1.push(item);
                }
            });


            /** this check for the given level item present in the parent, if not present then pushes to particular
			 *  level array
			 * @param {array} data - array of object to filter cloned list
			 * @param {number} compareLevel - level number of comparision
			 * @param {string} parent - parent property in obj
			 * @param {string} child - child property in obj
			 */
            function filterLevel(data, compareLevel, parent, child) {
                data.forEach(function (item, index) {
                    if (item.level == compareLevel) {
                        if (obj[parent].length) {
                            let numberOfPass = 0;
                            for (var k = 0; k < obj[parent].length; k++) {
                                if (obj[parent][k].parentRef != item.parentRef) {
                                    numberOfPass += 1;
                                }
                            }

                            if (numberOfPass == obj[parent].length) {
                                obj[child].push(item);
                            }
                        } else {
                            obj[child].push(item);
                        }
                    }
                });
            }
            function filterLevel3(data, compareLevel, parent, child) {
                data.forEach(function (item, index) {
                    if (item.level == compareLevel) {
                        if (obj[parent].length || obj['level1'].length) {
                            let numberOfPass = 0;
                            for (var k = 0; k < obj[parent].length; k++) {
                                if (obj[parent][k].parentRef != item.parentRef || obj[parent][k].productId != item.referrence) {
                                    numberOfPass += 1;
                                }
                            }

                            for (var z = 0; z < obj['level1'].length; z++) {
                                if (obj['level1'][z].parentRef != item.parentRef) {
                                    numberOfPass += 1;
                                }
                            }

                            if (numberOfPass == (obj[parent].length) + obj['level1'].length) {
                                obj[child].push(item);
                            }
                        } else {
                            obj[child].push(item);
                        }
                    }
                });
            }

            filterLevel(selectedRows, 2, 'level1', 'level2');
            filterLevel3(selectedRows, 3, 'level2', 'level3');

            console.log('filterRequiredListToClone');
            console.log(obj);
            return obj;
        }

      	var finalizedCloneList = filterRequiredListToClone();

        console.log('finalizedCloneList');
        console.log(finalizedCloneList);

        if (this.newRecordId) {
            NotificationManager({
                message: 'Please fill the mandatory fields for previously added record before proceeding to next',
                type: 4
            });

            return;
        }
        if (_this.clonedList.length > 0) {
            NotificationManager({
                message: 'Please fill the mandatory fields for previously cloned record before proceeding to next',
                type: 4
            });
            return;
        }

        // this.unCheckAllRows(); //commented this on 1 july 2018 to check cloning

        /**updated the new object with the uniquekey and clones
		 * the required fields checked in admin panel
		 *
		 * @param {array} list - array of objects
		 * @param {string} uniqueKey - unique key field name
		 */
        const updateUniqueKey = (list, uniqueKey, autoGenerateUids) => {
            let updatedList = [];
            let jqWidgetReservedMembers = ['checked', 'expanded', 'icon', 'leaf', 'level',
                'parent', 'records', 'selected', 'uid'];
            if (_this.requiredCloneFields && _this.requiredCloneFields.length > 0) {
                jqWidgetReservedMembers = jqWidgetReservedMembers.concat(_this.requiredCloneFields);
            }

            //@param {object} obj
            //@param {integer} index
            let additionalFields = [];
            if (list[0].dataLevel != "SP") {
                additionalFields = ['referrence', 'parentRef', 'dataLevel'];
            }

            let cloneFields = _this.requiredCloneFields.concat(additionalFields);

            list.forEach((obj, index) => {
                //obj[uniqueKey] = obj[uniqueKey] +'-c';
                // _this.productGrid.uncheckRow(obj);

                var clonedObj = utils.cloneTheKeys(cloneFields, obj);
                // the string -c is used to represent cloned product id
                //clonedObj[uniqueKey] = obj[uniqueKey] +'-c';
                clonedObj[uniqueKey] = autoGenerateUids[index];
                updatedList.push(clonedObj);
            });
            return updatedList;
        };

        if (selectedRows && selectedRows.length > 0) {
            var finalizedRows = [...finalizedCloneList.level1, ...finalizedCloneList.level2, ...finalizedCloneList.level3];
            var finalizedLength = finalizedRows.length;

            let headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json'
            };

            ProductServices.getcloneProductIds('?count=' + finalizedLength)
                .then( (response) => {
                    console.log('productContainer  cloneSelectedRecords API call success data: ', response.data);
                    let res = response.data;

                    if (res.length > 0) {
                        var autoGenerateUids = res;
                        let ProductUinqueKey = 'productId';
                        let clonedList = updateUniqueKey(finalizedRows, ProductUinqueKey, autoGenerateUids);
                        let clonedListUniqueKeys = [];
                        _this.childlevelClonedList = [];
                        clonedList.forEach((item, index) => {
                            // _this.productGrid.addRow(item[ProductUinqueKey],item);
                            if (item.dataLevel == "SP" || (item.dataLevel == "Sku" && !item.parentRef)) {
                                clonedListUniqueKeys.push(item[ProductUinqueKey]);
                            }else{
                                _this.childlevelClonedList.push(item[ProductUinqueKey]);
                            }
                        });
                        console.log('cloned list isloaded false');
                        console.log(clonedList);

                        _this.props.updateGridData(clonedList);
                        _this.clonedList = clonedListUniqueKeys;
                        _this.setState({ isLoaded: false });
                    }
                })
                .catch( error => {
                    console.log('productContainer  cloneSelectedRecords API call failed', error);
                });
        }
    }


    removeClonedList() {
        let cloned = '';
        if (this.clonedList.length) {
            cloned = this.clonedList[0];
        }

        var selectedRegex = this.clonedList.join('|');
        selectedRegex = new RegExp('(' + selectedRegex + ')');
        var newData = this.props.storeGridData.filter((list, index) => {
            if (!selectedRegex.test(list.productId)) {
                return list;
            }
        });
        this.props.addGridData(newData);

        this.setState({ isLoaded: false });
        // TO-DO
        // if product is updated in the backend, remove it.
    }

    triggerFocusToclonedList() {
        var _this = this;
        this.selectSingleRow(this.clonedList[this.clonedList.length - 1]);
        this.productGrid.beginCellEdit(this.clonedList[this.clonedList.length - 1], this.requiredAddFields[1]);
    }

    /** Validate the cloned list for required fields to add
      *  the record to backend
      */
    validateClonedList() {
        var currentRecord = this.productGrid.getRow(this.clonedList[this.clonedList.length - 1]);
        var list = [currentRecord];
        var validate = this.ValidateRequiredFields(this.requiredAddFields, list);
        var unfilledField = this.getUnfilledColumns(this.requiredAddFields, list);

        if (validate.isValid) {
            // if valid send the row details to backend
            this.sendUpdatedOrAddedRecord(currentRecord);
            this.props.updateGridData([currentRecord]);
            // empty the clonedList id after submission
            this.clonedList.splice(this.clonedList.length - 1, 1);
            if (this.clonedList.length > 0) {
                this.triggerFocusToclonedList();
            }
        } else {
            this.alertWithNotFilledFields(unfilledField);
            this.requiredFieldsAlert = validate;
        }
    }



    // addSingleRecord() {
    // 	let _this = this;
    // 	/** Added by asaithamib to fix one issue */
    // 	if (this.productGrid === undefined) {
    // 		NotificationManager({ message: "Please Apply filter to proceed further", type: 4 });
    // 		return;
    // 	}
    // 	if (this.newRecordId) {
    // 		NotificationManager({
    // 		message: 'Please fill the mandatory fields for previously added record before proceeding to next',
    // 		type: 4
    // 		});

    // 		return;
    // 	}
    // 	if (_this.clonedList.length > 0) {
    // 		NotificationManager({
    // 		message: 'Please fill the mandatory fields for previously cloned record before proceeding to next',
    // 		type: 3
    // 		});
    // 		return;
    // 	}

    // 	_this.newRecordId = Math.floor((Math.random() * 1000) + 1).toString(); //'999999';
    // 	var newObj = _this.getColumnNames();
    // 	newObj.swatchOnly = "Sample";
    // 	window.newProductId = _this.newRecordId ; //Setting window object for sample/swatch option selection
    // 	_this.productGrid.addRow(_this.newRecordId, newObj);
    // 	// set focus to new row cell

    // 	let t = setTimeout(function () {
    // 	_this.selectSingleRow(_this.newRecordId);
    // 	_this.productGrid.beginCellEdit(_this.newRecordId, _this.requiredAddFields[1]);
    // 	}, 500)
    // }



    addSingleRecord() {
        let _this = this;
        /** Added by asaithamib to fix one issue */
        if (this.productGrid === undefined) {
            NotificationManager({ message: "Please Apply filter to proceed further", type: 4 });
            return;
        }
        if (this.newRecordId) {
            NotificationManager({
                message: 'Please fill the mandatory fields for previously added record before proceeding to next',
                type: 4
            });

            return;
        }
        if (_this.clonedList.length > 0) {
            NotificationManager({
                message: 'Please fill the mandatory fields for previously cloned record before proceeding to next',
                type: 3
            });
            return;
        }


        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };
        ProductServices.getProductId()
            .then( (response) => {
                let res = response.data;

                _this.newRecordId = res.id;
                callOnResponse();
            })
            .catch( error => {
                console.log('productContainer addSingleRecord API call failed', error);
                _this.newRecordId = null;
                callOnResponse();
            });


        function callOnResponse() {
            console.log('call on Response add single record');
            var newObj = _this.getColumnNames();
            newObj.swatchOnly = "Sample";
            window.newProductId = _this.newRecordId; //Setting window object for sample/swatch option selection
            _this.productGrid.addRow(_this.newRecordId, newObj);
            // set focus to new row cell

            let t = setTimeout(function () {
                _this.selectSingleRow(_this.newRecordId);
                // _this.productGrid.ensureRowVisible(_this.newRecordId);
                _this.productGrid.beginCellEdit(_this.newRecordId, _this.requiredAddFields[1]);
            }, 500);
        }
    }

    /** ValidateRequiredFields checks all conditions are satisfied for new record,
     *  when it turns true the record will be sent to backend to store in DB.
     *  @param {array} requiredList - array of strings
     *  @param {array} objectList - array of objects
     *  @returns an object
     */
    ValidateRequiredFields(requiredList, objectList) {
        let stopLoop = false;
        let notFilled = {};

        for (var i = 0; i < objectList.length; i++) {
            var item = objectList[i];
            if (stopLoop) break;
            for (var j = 0; j < requiredList.length; j++) {
                var fieldName = requiredList[j];
                if (!item[fieldName] || typeof (item[fieldName]) === "object" || item[fieldName] === "Select Vendor" || item[fieldName] === "Select Category") {
                    //if the required field is empty or undefined
                    stopLoop = true;
                    notFilled.uid = item.uid;
                    notFilled.fieldName = fieldName;
                    break;
                }
            }
        }

        return { isValid: !stopLoop, notFilled };
    }

    getUnfilledColumns(requiredList, objectList) {
        let stopLoop = false;
        let notFilled = [];
        for (var i = 0; i < objectList.length; i++) {
            var item = objectList[i];
            // if(stopLoop) break;
            for (var j = 0; j < requiredList.length; j++) {
                var fieldName = requiredList[j];
                if (!item[fieldName] || typeof (item[fieldName]) === "object" || item[fieldName] === "Select Vendor" || item[fieldName] === "Select Category") {
                    //if the required field is empty or undefined
                    stopLoop = true;
                    //  notFilled.uid = item.uid;
                    notFilled.push(fieldName);
                    // break;
                }
            }
        }

        return notFilled;
    }

    /** getColumnNames returns an object of undefined field Names
     */
    getColumnNames() {
        //var rowDetails = this.productGrid.getRows()[0];
        var rowDetails = this.props.columnDetails;
        var obj = {};

        for (var key in rowDetails) {
            if (rowDetails.hasOwnProperty(key)) {
                var k = rowDetails[key].fieldName;
                var type = rowDetails[key].type;
                if (k == 'vendorId') {
                    obj[k] = "";
                    // obj[k] = undefined;
                } else if (["catId", "division", "department", "classColumn", "subClassColumn"].indexOf(k) > -1) {
                    obj[k] = "Select Category";
                }
                else if (k == "color_attribute") {
                    obj[k] = "";
                }
                else if (k == "size_attribute") {
                    obj[k] = "";
                }

                else if (k == "images") {
                    obj[k] = [];
                }
                else if (k == 'dataLevel') {
                    obj[k] = 'Sku';
                }
                else {
                    obj[k] = undefined;
                }
            }
        }

        return obj;
    }

    /** it selects the passed row and unselects other selected rows
      * @param {string} rowId - row unique ID
      */
    selectSingleRow(rowId) {
        var _this = this,
            selectedRows = this.productGrid.getSelection(),
            isRowIdPresent = false;

        selectedRows.forEach((item, index) => {
            if (item.uid !== rowId) {
                _this.productGrid.unSelectRow(item.uid);
            } else {
                isRowIdPresent = true;
            }
        });
        // if row id is not present in the selected list then select the rowid.
        if (!isRowIdPresent) {
            _this.productGrid.selectRow(rowId);
        }
    }
    // this will get focus to next mandatory field cell
    nextRequiredField() {
        let obj = this.requiredFieldsAlert;
        let fieldName = obj.notFilled.fieldName;
        if (fieldName == "catId") {
            fieldName = 'department';
        }
        if (obj.notFilled && obj.notFilled.uid && fieldName) {
            //this.productGrid.selectRow(obj.notFilled.uid);
            this.selectSingleRow(obj.notFilled.uid);
            $('.jqx-grid').jqxGrid('clearselection');
            $('.jqx-grid').jqxGrid('showcolumn', fieldName);
            this.productGrid.selectCell(obj.notFilled.uid, fieldName);
            this.fieldNameEndCellEdit = fieldName;
            this.productGrid.beginCellEdit(obj.notFilled.uid, fieldName);
        }
    }

    // when mandatory fields are not filled and on user confirms to delete,
    // it will delete the row
    deleteRequireConfirmed() {
        if (this.newRecordId) {
            this.productGrid.deleteRow(this.newRecordId);
            this.newRecordId = '';
        } else if (this.clonedList.length > 0) {
            this.productGrid.deleteRow(this.clonedList[0]);
            this.clonedList.splice(0, 1);
            if (this.clonedList.length > 0) this.nextRequiredField();
        }
        this.setState({ totalRows: this.productGrid.getView() });
    }


    deleteSelectedRecord() {
        let _this = this;
        let selectedRows = this.props.selectedRows;

        if (selectedRows && selectedRows.length > 0) {
            let postData = [];
            let removeList = [];
            let error = [];
            selectedRows.forEach((item, index) => {
                removeList.push(item.uid);
                if (item.deleted) {
                    error.push(item.productId);
                }
                if (!item.deleted) {
                    if (item.dataLevel === undefined || (item.dataLevel && item.dataLevel.toLowerCase() === "sku")) {
                        postData.push({ id: item.productId });
                    }
                }
            });
            if (error.length > 0) {
                var msg = "Product got deleted already";
                NotificationManager({ message: msg, type: 4 });
            }
            if (postData.length === 0) {
                return;
            }

            ProductServices.deleteRecord(postData)
                .then((response) => {
                    let data = response;

                    NotificationManager({ message: data.responseMessage, type: 1 });
                    selectedRows.forEach((item, index) => {
                        var isReload = false;
                        if (selectedRows.length == index + 1) {
                            isReload = true;
                        }
                        _this.productGrid.deleteRow(item.uid, item, isReload);
                    });

                    //removing the deleted rows from redux store
                    var selectedRegex = removeList.join('|');
                    selectedRegex = new RegExp('(' + selectedRegex + ')');
                    var newData = _this.props.storeGridData.filter((list, index) => {
                        if (!selectedRegex.test(list.productId)) {
                            return list;
                        }
                    });
                    _this.props.addGridData(newData);
                    _this.updateSelectedRowstoStore();
                })
                .catch( error => {
                    console.log('productContainer deleteSelectedRecord API call failed', error);
                });
     	}
    }

    updateSelectedRowstoStore() {
        var checkedList = this.productGrid.getCheckedRows();
        this.props.updateSelectedRows(checkedList.result);

        if (checkedList.totalchecked == checkedList.totalcount) {
            $('.header-checkbox').addClass('checked');
            $('.header-checkbox').attr('data-checked', 'true');
        } else {
            $('.header-checkbox').removeClass('checked');
            $('.header-checkbox').attr('data-checked', 'false');
        }
    }


    sortDropDown() {
        var container = '<div id="drop-down" class="pd-hl"></div>';
        $('.sortbyDropDown').append(container);
        var list = this.generateDropDowns();

        $('#drop-down').append(list);
    }

    generateDropDowns() {
        var _this = this;
        var list = [{
            name: 'Sort 0-9, A -> Z',
            val: 'asc'
        },
        {
            name: 'Sort 9-0, Z -> A',
            val: 'desc'
        },
        {
            name: 'Filter By',
            val: 'filter'
        },
        {
            name: 'Group by This Field',
            val: 'groupby'
        },
        {
            name: 'Hide Field',
            val: 'hide'
        },
        {
            name: 'Freeze/UnFreeze Field',
            val: 'pinned'
        },
        {
            name: 'Clear',
            val: 'clear'
        }
        ];

        var li = '';
        list.forEach((item, index) => {
            li += '<li class="product-sort-options" data-val="' + item.val + '">' + item.name + '</li>';
        });

        var ul = "<ul class='sort-holder'>" + li + "</ul>";

        return ul;
    }

    showfilterBy(fieldName) {
        var _this = this;
        _this.filterFieldName = fieldName;
        _this.setState({ enableFilterBy: true });
    }

    closefilterBy(fieldName) {
        var _this = this;
        $('#drop-down').hide();
        $('.product-sort-options[data-val="filter"]').removeClass('active');
        _this.setState({ enableFilterBy: false });
    }

    updateUploadedImage(obj) {
        let rowdata = this.imageProductInfo;
        let gridContainer = '';
        //this.setCellValueAndCopyToChild(obj.fieldName,obj.data);
        if (rowdata.dataLevel == "Intm") {
            gridContainer = '#level2grid' + rowdata.level1;
        }
        else if (rowdata.dataLevel == "Sku" && rowdata.parentRef) {
            if (rowdata.level == 2) {
                gridContainer = '#level2grid' + rowdata.level1;
            } else if (rowdata.level == 3) {
                gridContainer = '#level3grid' + rowdata.level1 + rowdata.level2;
            } else {
                gridContainer = '';
            }
        }
        this.productGrid.setCellValue(obj.rowId, obj.fieldName, obj.data, gridContainer);
        var rowData = this.productGrid.getRow(obj.rowId, gridContainer);
        this.copyToChildRows(rowData, [obj.fieldName]);
    }


    //Product Options - updat parent and append new child rows
    updateParentRow(rowid, data) {
        // Updating Parent row
        var _this = this;
        _this.clonedList = [];

        if (data.childData.length <= 0) {
            _this.productGrid.updateRow(rowid, data.parentData[0]);
        } else {
            _this.productGrid.deleteRow(rowid);

            var chldProuctIds = [];
            if (data.childData.length > 0) {
                data.childData.map((value) => {
                    if (value.dataLevel === 'Sku') {
                        chldProuctIds.push(value.productId);
                    }
                });
            }


            let headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json'
            };

            ProductServices.groupByPopUp({ chldProuctId: chldProuctIds, userRole: this.props.user.defaultRole, level: 'default' })
                .then( (response) => {
                    let res = response.data;

                    if (res.data) {
                        if (res.data.length > 0) {
                            var list = require('../common/utils').treeify(res.data, "productId", "referrence", "children");
                            if (list.length > 0) {
                                _this.productGrid.addRow(list[0].productId, list[0]);
                            }
                        }
                    }
                })
                .catch( error => {
                    console.log('productContainer updateParentRow API call failed', error);
                });
        }
    }

    setCampaignCell(productId, fieldName, value) {
        var _this = this;
        var gridcontainer = '';
        var rowdata = _this.campaignProduct;
        if (rowdata.dataLevel == 'Intm' || rowdata.dataLevel == "SP") {
            var childlist = rowdata.children;
            childlist.map(function (ele, i) {
                if (productId == ele.productId) {
                    rowdata = ele;
                }
            });
        }
        if (rowdata.dataLevel == "Sku" && rowdata.parentRef) {
            if (rowdata.level == 2) {
                gridcontainer = "#level2grid" + rowdata.level1;
            } else if (rowdata.level == 3) {
                gridcontainer = "#level3grid" + rowdata.level1 + rowdata.level2;
            }
        }
        _this.productGrid.setCellValue(productId, fieldName, value, gridcontainer);
    }



    render() {
        let currentPage = localStorage.getItem('currentPage');

        let footerHeight = 50,
            headerHeight = 64,
            filterStrip = 34;
        let gridHeight = window.innerHeight - footerHeight - headerHeight - filterStrip ;
        let gridWidth = $('.productJqxTreeGrid').width() ;

        let modalClass = (this.state.modalName === 'deleteConfirm') ?
          	'confirm' : (this.state.modalName === 'addCopyModel') ?
                'addCopyModel' : (this.state.modalName === 'addKeywordModal')
                    ? 'addKeywordModal' : (this.state.modalName === "SampleModal") ? "SampleModalPopup" : "";

        if (this.state.modalName === "ProductGridPopup") {
          	modalClass = "ProductGridPopup";
        }

        return (
            <ProductComponent
                state = { this.state }
                props = { this.props }

                productGridRef= { grid => { this.productGrid = grid; } }
                gridData = { this.gridData }
                gridHeight = { gridHeight }
                gridWidth = { gridWidth }
                productThis = { this }
                // toggleRightDrawer = { this.toggleRightDrawer }
                isCheckedAllRows = { this.isCheckedAllRows }
                rowsheight = { this.rowsheight }
                groupsList = { this.groupsList }
                pinnedColumnsList = { this.pinnedColumnsList }
                sortcolumn = { this.sortcolumn }
                sortdirection = { this.sortdirection }
                appliedGridFiltersList = {this.appliedGridFiltersList}


                modalClass = { modalClass }

                modalClose = {this.modalClose}
                currentRowData= { this.currentRowData }
                imageProductInfo= { this.imageProductInfo }
                setCellValueAndSaveProduct= { this.setCellValueAndSaveProduct }
                deleteSelectedRecord= { this.deleteSelectedRecord }
                openPIUploadPopup= { this.openPIUploadPopup }
                deleteRequireConfirmed= { this.deleteRequireConfirmed }
                nextRequiredField= { this.nextRequiredField }
                parentRef= { this.parentRef }
                currentProductCatID= { this.currentProductCatID }
                addBackTheField= { this.addBackTheField }
                productId= { this.productId }
                productData= { this.productData }
                keywordData= { this.keywordData }
                copyData= { this.copyData }
                childProdList= { this.childProdList }
                productGridPopupType= { this.productGridPopupType }
                childRowData= { this.childRowData }
                levelType= { this.levelType }
                setValueSampleOption= { this.setValueSampleOption }
                swatchReferenceList= { this.swatchReferenceList }
                setValueSwatchReference= { this.setValueSwatchReference }
                vendorNameList= { this.vendorNameList }
                updateProductForPO= { this.updateProductForPO }
                productGrid={this.productGrid}
                setValueSwatchonly={this.setValueSwatchonly}
                confirmApproveSubmit={this.updateAttrCompleted}
                updateUploadedImage={this.updateUploadedImage}
                mandatoryAddColumns={this.mandatoryAddColumns}
                vendorId={this.vendorId}
                updatePIRecived={this.updatePIRecived}
                piReceivedModalClose={this.piReceivedModalClose}
                updateParentRow={this.updateParentRow}
                campaignProduct={this.campaignProduct}
                setCellValueAndCopyToChild={this.setCellValueAndCopyToChild}
                loadCampaignEvents={this.loadCampaignEvents}
                currentFieldText={this.currentFieldText}
                removeClonedList={this.removeClonedList}
                currentFieldText={this.currentFieldText}
                colorList={this.colorList}
                setCampaignCell={this.setCampaignCell}
                productEditing={this.productEditing}
                channel={this.channel}

                imgApprovalModalClose = { this.imgApprovalModalClose }
                ppidLst = { this.ppidLst }
                chldProuctIds = { this.chldProuctIds }

                undoLastChanges={this.undoLastChanges}
                sourceFrom={this.sourceFrom}
                isUndoEnable={this.state.isUndoEnable}
                reloadGrid={this.reloadGrid}
                isfilterApplied={this.state.isfilterApplied}
                filterchanged={this.state.filterchanged}
                totalRows={this.state.totalRows}

                FindRef = { ins => { this.FindChild = ins; } }

                FilterByRef ={ o => { this.FilterByChild = o; }}
                closefilterBy = { this.closefilterBy }
                filterFieldName = { this.filterFieldName }
                open = { this.enableFilterBy }

                currentPage = { currentPage }
                rebuildTheGrid = {this.rebuildTheGrid}

            />
        );
    }
}


const mapStateToProps = (state) => {
    return {
        count: state.addProductRow,
        selectedRows: state.selectedRows,
        deleteCount: state.deleteSeletedProducts,
        storeGridData: state.gridData,
        cloneCount: state.cloneSelectedProducts,
        columnDetails: state.columnDetails,
        gridDataCount: state.gridDataUpdate,
        storeGridDropDowns: state.gridDropDowns,
        appliedFilters: state.appliedFilters,
        currentViewId: state.updateCurrentView,
        columnResequence: state.columnResequence
    };
};

const mapDispatchToprops = (dispatch) => {
    return {
        addGridData: (data) => {
            dispatch(actions.addGridData(data));
        },
        updateSelectedRows: (data) => {
            dispatch(actions.updateSelectedRows(data));
        },
        updateGridData: (updateData) => {
            dispatch(actions.updateGridData(updateData));
        },
        addColumnDetails: (data) => {
            dispatch(actions.addColumnDetails(data));
        },
        addDropDowns: (data) => {
            dispatch(actions.addGridDropDowns(data));
        },
        removeFilter: (data) => {
            dispatch(actions.removeFilter(data));
        },
        gridDataChanged: () => {
            dispatch(actions.gridDataChanged());
        },
        appliedFiltersAction: (data) => {
            dispatch(actions.appliedFilters(data));
        },
        filterDataChanged: ()=>{
            dispatch(actions.filterDataChanged());
        },
        _REDUX_DISPATCH_COLUMN_RESEQUENCE: (data) => {
            dispatch(actions.columnResequence(data));
        }
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToprops)(Product)
);









// let data = [
// 	{
// 		ProductID:1,
// 		ProductName:"Customer1",
// 		QuantityPerUnit:"1",
// 		UnitPrice:"4.56",
// 		UnitsInStock:"2",
// 		Discontinued:false
// 	},
// 	{
// 		ProductID:2,
// 		ProductName:"Customer1",
// 		QuantityPerUnit:"1",
// 		UnitPrice:"4.56",
// 		UnitsInStock:"2",
// 		Discontinued:false
// 	}
// ];



// let source =
//     {
//         datatype: 'xml',
//         datafields: [
//             { name: 'ProductName', type: 'string' },
//             { name: 'QuantityPerUnit', type: 'int' },
//             { name: 'UnitPrice', type: 'float' },
//             { name: 'UnitsInStock', type: 'float' },
//             { name: 'Discontinued', type: 'bool' }
//         ],
// 		localdata: data
// 	};

// let cellsrenderer = (row, columnfield, value, defaulthtml, columnproperties, rowdata) => {
//     if (value < 20) {
//         return '<span style="margin: 4px; float: ' + columnproperties.cellsalign + '; color: #ff0000;">' + value + '</span>';
//     }
//     else {
//         return '<span style="margin: 4px; float: ' + columnproperties.cellsalign + '; color: #008000;">' + value + '</span>';
//     }
// }
// let dataAdapter = new $.jqx.dataAdapter(source);


// let columns =
//     [
//         { text: 'Product Name', columngroup: 'ProductDetails', datafield: 'ProductName', width: 250 },
//         { text: 'Quantity per Unit', columngroup: 'ProductDetails', datafield: 'QuantityPerUnit', cellsalign: 'right', align: 'right', width: 200 },
//         { text: 'Unit Price', columngroup: 'ProductDetails', datafield: 'UnitPrice', align: 'right', cellsalign: 'right', cellsformat: 'c2', width: 200 },
//         { text: 'Units In Stock', datafield: 'UnitsInStock', cellsalign: 'right', cellsrenderer: cellsrenderer, width: 100 },
//         { text: 'Discontinued', columntype: 'checkbox', datafield: 'Discontinued' }
//     ];

// let columngroups =
//     [
//         { text: 'Product Details', align: 'center', name: 'ProductDetails' }
//     ];



// let data = [
// 	{
// 		EmployeeKey:"C1",
// 		ParentEmployeeKey:"C1",
// 		FirstName:"Vinay",
// 		LastName:"Prabhakaran",
// 		Title:"Mr.",
// 		HireDate:'09/09/2017',
// 		BirthDate:'09/09/2017',
// 		Phone:'9777777777',
// 		Gender:'Male',
// 		DepartmentName:'Chemist'

// 	},
// 	{
// 		EmployeeKey:"C2",
// 		ParentEmployeeKey:"C1",
// 		FirstName:"V2",
// 		LastName:"Pr2",
// 		Title:"Mr.",
// 		HireDate:'09/09/2018',
// 		BirthDate:'09/09/2018',
// 		Phone:'9777777778',
// 		Gender:'Male',
// 		DepartmentName:'Chemist'

// 	},
// 	{
// 		EmployeeKey:"C3",
// 		ParentEmployeeKey:"C1",
// 		FirstName:"V3",
// 		LastName:"Pr3",
// 		Title:"Mr.",
// 		HireDate:'09/09/2018',
// 		BirthDate:'09/09/2018',
// 		Phone:'9777777778',
// 		Gender:'Male',
// 		DepartmentName:'Chemist'

// 	},

// ];


// let source =
// {
// 	dataFields: [
// 		{ name: 'EmployeeKey', type: 'number' },
// 		{ name: 'ParentEmployeeKey', type: 'number' },
// 		{ name: 'FirstName', type: 'string' },
// 		{ name: 'LastName', type: 'string' },
// 		{ name: 'Title', type: 'string' },
// 		{ name: 'HireDate', type: 'date' },
// 		{ name: 'BirthDate', type: 'date' },
// 		{ name: 'Phone', type: 'string' },
// 		{ name: 'Gender', type: 'string' },
// 		{ name: 'DepartmentName', type: 'string' }
// 	],

// 	hierarchy:
// 	{
// 		keyDataField: { name: 'EmployeeKey' },
// 		parentDataField: { name: 'ParentEmployeeKey' }
// 	},

// 	id: 'EmployeeKey',
// 	localdata: data

// };
// let dataAdapter = new $.jqx.dataAdapter(source);
// let columns=
// [
// 	{ text: 'FirstName', dataField: 'FirstName', minWidth: 100, width: 200 },
// 	{ text: 'LastName',  dataField: 'LastName', width: 200 },
// 	{ text: 'Department Name', dataField: 'DepartmentName', width: 150 },
// 	{ text: 'Title', dataField: 'Title', width: 300 },
// 	{ text: 'Birth Date', dataField: 'BirthDate', cellsFormat: 'd', width: 120 },
// 	{ text: 'Hire Date', dataField: 'HireDate', cellsFormat: 'd', width: 120 },
// 	{ text: 'Phone', dataField: 'Phone', cellsFormat: 'd', width: 120 }
// ];





// let isNavigatedFromAlert = require('../common/utils').getCookie('alertProductClickUrl');

// let isNavigatedFromDasboard = require('../common/utils').getCookie('productSearcClickUrl');

// let listUrl = require('../common/configurations.js').product.listProducts;

// if (_this.props.urlFromAlert) {

// }
//   let listUrl = require('../common/configurations.js').apiURL + isNavigatedFromAlert;
//   require('../common/utils').deleteCookie('alertProductClickUrl');
//   this.sourceFrom = { type: 'alert', url: listUrl, serviceType: Method, navObj: "" };

//     this.props.toggleSpinner();

//     let headers = {
//           'Authorization': user.token,
//           'content-type': 'application/json'
//     }

//     axios({
//       method: 'get',
//       url: listUrl,
//       headers: headers
//     })
//     .then( (response) => {
//         let res = response.data

//         this.props.toggleSpinner();


//         if (res.responseCode === 200) {
//           _this.overwriteProductResData(res);
//           _this.props.addColumnDetails(res.columnDetails);
//           _this.props.addDropDowns(res.dropdowns);
//           res.readyToOrderFields = this.requiredReadyFields;
//           // if (isNavigatedFromAlert || isNavigatedFromDasboard) {
//           //   if (!res.data) {
//           //     res.data = [];
//           //   }
//             _this.gridData = GridDataBuilder(res, 'product');
//             _this.appliedGridFiltersList = [];
//             _this.setState({ isLoaded: true, treegridcurrentPos: 0 });
//             _this.props.addGridData(res.data);

//             // $('.search-drawer .puller').trigger('click');
//           // }
//         }

//     })
//     .catch( error => {
//     });


// }


// if (isNavigatedFromDasboard) {
//   var navobj = JSON.parse(isNavigatedFromDasboard);
//   listUrl = require('../common/configurations.js').apiURL + '/product/productsearch';
//   require('../common/utils').deleteCookie('productSearcClickUrl');
//   postData = navobj;
//   this.sourceFrom = { type: 'dashboard', url: listUrl, serviceType: Method, navObj: navobj };
// }

// // method will change if the product list is navigated from alerts (GET),
// //while loading directly or from filters it will be POST
// // if (isNavigatedFromDasboard) {

// let headers = {
//       'Authorization': user.token,
//       'content-type': 'application/json'
// }

// axios({
//   method: 'post',
//   url: require('../common/configurations.js').product.listProducts,
//   headers: headers,
//   data: postData
// })
// .then( (response) => {
//     let res = response.data

//     if (res.responseCode === 200) {
//       _this.overwriteProductResData(res);
//       _this.props.addColumnDetails(res.columnDetails);
//       _this.props.addDropDowns(res.dropdowns);
//       res.readyToOrderFields = this.requiredReadyFields;
//       // if (isNavigatedFromAlert || isNavigatedFromDasboard) {
//       //   if (!res.data) {
//       //     res.data = [];
//       //   }
//         _this.gridData = GridDataBuilder(res, 'product');
//         _this.appliedGridFiltersList = [];
//         _this.setState({ isLoaded: true, treegridcurrentPos: 0 });
//         _this.props.addGridData(res.data);

//         // $('.search-drawer .puller').trigger('click');
//       // }
//     }

// })
// .catch( error => {
// });

// } else {

// let headers = {
//       'Authorization': user.token,
//       'content-type': 'application/json'
// }

// axios({
//   method: 'get',
//   url: listUrl,
//   headers: headers
// })
// .then( (response) => {
//     let res = response.data

//     if (res.responseCode === 200) {
//       _this.overwriteProductResData(res);
//       _this.props.addColumnDetails(res.columnDetails);
//       _this.props.addDropDowns(res.dropdowns);
//       res.readyToOrderFields = this.requiredReadyFields;
//       // if (isNavigatedFromAlert || isNavigatedFromDasboard) {
//       //   if (!res.data) {
//       //     res.data = [];
//       //   }
//         _this.gridData = GridDataBuilder(res, 'product');
//         _this.appliedGridFiltersList = [];
//         _this.setState({ isLoaded: true, treegridcurrentPos: 0 });
//         _this.props.addGridData(res.data);

//         // $('.search-drawer .puller').trigger('click');
//       // }
//     }

// })
// .catch( error => {
// });




// }



// var resizeTimer;
// $(window).resize(function (event) {
// //   // condition to prevent resize from other event
//   if (event.target === 'Window') {
// //     // let footerHeight = 50,
// //     //   headerHeight = 64,
// //     //   filterStrip = 34;
// //     // let gridHeight = window.innerHeight - footerHeight - headerHeight - filterStrip -20;
// //     // var gridWidh = $('.productJqxTreeGrid').width();
//     if (_this.productGrid) {
//       //Reload the grid
//       _this.productGrid.refreshGrid();
//     }
// //     // clearTimeout(resizeTimer);
// //     // resizeTimer = setTimeout(function () {
// //     //   _this.FindChild.wrappedInstance.highlightResize();
// //     //   // Run code here, resizing has "stopped"

// //     // }, 250);
//   }
// });




// $(document).click((e) => {

// 	var gridContainer = $(this).attr('grid-container');
// 	var currentRecord = _this.productGrid.getRow(_this.prodRowlockList,gridContainer);
// 	var list = [currentRecord];
// 	var validate = _this.ValidateRequiredFields(_this.requiredAddFields, list);
// 	var unfilledField = _this.getUnfilledColumns(_this.requiredAddFields, list);

// 	if(_this.rowLocked) {

// 		var validate = _this.ValidateRequiredFields(_this.requiredAddFields, list);
// 		var unfilledField = _this.getUnfilledColumns(_this.requiredAddFields, list);

// 		if (!validate.isValid) {
// 			_this.setState({
// 				modalName: "requiredFieldConfirm"
// 			});
// 			this.requiredFieldsAlertTemp = unfilledField
// 			this.getMandatoryUnfilledColumnNames();
// 			this.modalOpen();
// 		}

// 	  }

// });



//////////////////////////////////////////////////////


// dataAdapter = {dataAdapter}
// columns = {columns}
// // columngroups = {columngroups}
// myTreeGridRef = {mygrid => {this.myTreeGrid = mygrid}}


// FilterByRef = { o => { this.FilterByChild = o }}
// closefilterBy = { this.closefilterBy }
// filterFieldName = { this.filterFieldName }
// enableFilterBy = { this.enableFilterBy }

