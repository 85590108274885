import { loginConstants } from './loginConstants';
import axios from 'axios';
import NotificationManager from '../../common/components/notification';
import {UserServices} from '../../common/services/user-services';
import {CommonServices} from '../../common/services/common-services';

const {login} = require('../../common/configurations.js');
const {userData} = require('../../common/configurations.js');



function sweftLogin(username, password) {
    function request(user) { return { type: loginConstants.LOGIN_REQUEST, user }; }

    function success(user) {
        return { type: loginConstants.LOGIN_SUCCESS, user };
    }

    function failure(error) {
        const loginForm = $('#login-form');
        $(loginForm).find('.error').show();
        $(loginForm).find('.error').text(error);
        // NotificationManager({message:"Login Failed. Please contact system administrator.", type:4})
        return { type: loginConstants.LOGIN_FAILURE, error };
    }

    const data = {userId: username, password: password};
    return dispatch => {
        dispatch(request(username));

        return CommonServices.login(data).then((response) => {
            if(response.data.responseData.token) {
                NotificationManager({ message: response.data.message, type:1 })

                localStorage.setItem('token', response.data.responseData.token);

                //				User data is needed, to load the app. So make a get user call and store it in Local storage
                //				TODO - Going forward, add new services to this module and use this for all user relate activities.
                //				Date - 9/26/19 | JIRA - SP-2087| Dev - Guna
                return UserServices.getUser().then((userResponse) => {
                    localStorage.setItem('user', JSON.stringify(userResponse.data.responseData));
                    return dispatch(success(userResponse.data.responseData));
                });
            } else {
                dispatch(failure(response.data.responseMessage));
            }
            return response;
        }).catch(error => {
            dispatch(failure(error.response.data.message));
        });
    }; // ends return dispatch
}

function sweftLogout() {
    function request() { return { type: loginConstants.LOGOUT }; }

    return dispatch => {
        dispatch(request());
        localStorage.clear();
    }; // ends return dispatch
}


export const loginActions = {
    sweftLogin,
    sweftLogout
};


// *******************************************
// not working
//  let requestOptions = {
//    body: JSON.stringify(data), // must match 'Content-Type' header
//    headers: {
//      'Accept':'application/json',
//      'content-type': 'application/json'
//    },
//    method: 'POST', // *GET, PUT, DELETE, etc.
//  }


// fetch(login, requestOptions)
//  .then( response => {
//    // return response.json()
//  })
//  .then( response => {
//          // return response;
//        }
//  )
//  .catch(error => {
//  })
// };

// *******************************************
// network request failed

//   (async () => {
//     try {
//       const res = await fetch(login, {
//       method: "POST",
//       body: JSON.stringify(data),
//       headers: {
//         "Content-Type": "application/json"
//       },
//     });
//       if (res.status >= 400) {
//         throw new Error("Bad response from server");
//       }

//       const user = await res.json();

//     } catch (err) {
//       console.error(err);
//     }
//   })();


// };

// ******************************************

// }; //ends return dispatch

// ************************working***********
// loginService.login(username, password)
//  .then( ([response, json]) => {
//         if(response.status === 200){
//           dispatch(success(json))
//         }
//         else{
//           dispatch(failure(error))
//         }
//       },
//       error => {
//         dispatch(failure(error));
//       }
//   );
// *************************************

// data does return to browser but not to console


//     let authOptions = {
//         // method: 'POST',
//         url: login,
//         data: data,
//         headers: {
//             'Content-Type': 'application/json'
//         },
//         // json:true
//     };
