import React from 'react';
import AuditLogComponent from './auditLogComponent';
import axios from 'axios';
import NotificationManager from '../common/components/notification';
import GridDataBuilder from './helperComponents/dataBuilder';
import AdminServices from '../admin/admin-services/admin-services';

class AuditLog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
        };
    }

    componentDidMount() {
        const base = this;

        AdminServices.getAuditList().then((response) => {
            const res = response;
            if (res.responseCode === 200) {
                base.gridData = GridDataBuilder(res, 'auditlog');
                base.setState({ isLoaded: true });
            }
        }).catch((error) => {
            console.log('auditLog componentDidMount API call failed', error);
        });
    }

    render() {
        console.log('AuditLog Container render: ', this.props);
        const footerHeight = 0;
        const headerHeight = 64;
        $('.page-title a').text("ADMIN: Audit Logs");

        const gridHeight = window.innerHeight - footerHeight - headerHeight - 20;
        const editSettings = {
            saveOnPageChange: true,
            saveOnBlur: true,
            saveOnSelectionChange: true,
            cancelOnEsc: true,
            saveOnEnter: true,
            editSingleCell: true,
            editOnDoubleClick: true,
            editOnF2: true,
        };

        return (
            <AuditLogComponent
                state={this.state}
                props={this.props}
                gridData={this.gridData}
                auditLogGrid={(grid) => {
                    this.auditLogid = grid;
                }}
                gridHeight={gridHeight}
                editSettings={editSettings}
            />
        );
    }
}


export default AuditLog;

