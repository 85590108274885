import React, { Component } from 'react';
import GridViewDropDown from './helperComponents/gridViewDropDown.js';
import ListViewer from './helperComponents/listViewer.js';
import * as actions from '../../product/redux/productActions';
import { connect } from 'react-redux';

import NotificationManager from '../../common/components/notification';

class HeaderFilters extends Component {
    constructor(props){
        super(props);

        this.state = {
            isViewFields: false,
            isViewsOpen:false,
            addNewViews:false,
            openFind: false
        };

        this.toggleViewFields = this.toggleViewFields.bind(this);
        this.handleViewFields = this.handleViewFields.bind(this);
        this.toggleOptions = this.toggleOptions.bind(this);
        this.setViewFields = this.setViewFields.bind(this);
        this.toggleFind = this.toggleFind.bind(this);
        this.checkboxChangeObserver = this.checkboxChangeObserver.bind(this);

        this.updatedViewFields = null;
        this.checkboxChanged = false;
    }

    componentDidMount() {
        let _this = this;

        // to hide dropdowns when user clicks outside the field area
        $(document).on('click.toggle', function(e) {
            e.stopPropagation();
            var viewFields = '.view-fields',
                viewDropDown = '.selected-view';

            if ($(viewFields).hasClass('open') && $(e.target).closest('.view-fields-dropdown').length === 0) {
                _this.toggleViewFields();
            }

            if ($(viewDropDown).hasClass('open') && $(e.target).closest('.grid-view-dropdown').length === 0) {
                _this.toggleOptions();
            }
        });

        $(document).on('click', '.close-find', function(e) {
            _this.toggleFind();
        });
    }

    componentDidUpdate(prevprops, prevState) {
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.toggleFind !== this.props.toggleFind) {
            this.setState({ openFind: nextProps.toggleFind });
        }
    }

    componentWillUnmount() {
        $(document).off('click.toggle');
    }

    toggleViewFields() {
        console.log('toggling View Fields');

        this.setState({ isViewFields: !this.state.isViewFields });
        $('.pageHeaderProductViewFields').toggleClass("open");
    }

    viewFieldsHide() {
        if (this.state.viewFields == true) {
            this.setState({ isViewFields: false });
        }
    }

    hide(e) {
        if (e && e.relatedTarget) {
            e.relatedTarget.click();
        }

        this.setState({ isViewFields: false });
    }

    toggleOptions() {
        this.setState((prevState) => ({
            isViewsOpen: !prevState.isViewsOpen
        }));
    }

    toggleFind() {
        var toggle = !this.state.openFind;
        this.setState({ openFind: toggle });
        this.props.toggleFind(toggle);
    }

    setViewFields(newList) {
        if (newList) {
            console.log('setViewFields: ', newList);
            this.updatedViewFields = newList;
            this.setState({ addNewViews: true });
        } else {
            this.setState({ addNewViews: false });
        }
    }

    /**this method is used to enable 'add new view' if any  changes in the column visiblity
     *
     * @param {string} fieldName
     * @param {boolean} checked
     */
    handleViewFields(fieldName, checked) {
    }

    checkboxChangeObserver() {
        let cValue = this.checkboxChanged;
        this.checkboxChanged = !cValue;
    }

    render() {
        let currentPage = this.props.currentPage || localStorage.getItem('currentPage');

        let filter;

        if (currentPage === 'product' || currentPage === 'product2') {
            filter = (
                <div className="header-filters">
                    <div className="grid-view">
                        <GridViewDropDown
                            toggleOptions={this.toggleOptions}
                            isOpen={this.state.isViewsOpen}
                            addNewViews={this.state.addNewViews}
                            viewPopup={this.props._REDUX_DISPATCH_addNewViewPopup}
                            updateColumns={this.props.addColumnDetails}
                            columnsUpdated={this.props.columnDetailsUpdated}
                            columnDetails={this.props.columnDetails}
                            checkboxChanged={this.checkboxChanged}
                            user={this.props.user}
                        />
                    </div>
                    <div className="view-fields-dropdown">
                        <div className="inline-block pageHeaderProductViewFields view-fields" onClick={this.toggleViewFields}>View fields</div>
                        { this.state.isViewFields &&
                        <ListViewer
                            inputChange={this.handleViewFields}
                            setViewFields={this.setViewFields}
                            columnDetails={this.props.columnDetails}
                            columnListCount={this.props.columnListCount}
                            checkboxChangeObserver={this.checkboxChangeObserver}
                        />
                        }
                    </div>
                </div>
            );
        }

        if (currentPage === 'marketing') {
            filter = (
                <div className="header-filters">
                    <div className="find">
                        <span onClick={this.toggleFind}>find</span>
                    </div>
                </div>
            );
        }

        if (currentPage === 'copywriting') {
            filter = (
                <div className="header-filters">
                    <div>US English</div>
                </div>
            );
        }

        return (
            <div>{filter}</div>
        );
    }
}

// redux dispatch events mapping
const mapDispatchToprops = (dispatch) => {
    return {
        addColumnDetails: (data) => {
            dispatch(actions.addColumnDetails(data));
        },
        columnDetailsUpdated: () => {
            dispatch(actions.gridColumnChanged());
        },
        toggleFind:(data) => {
            dispatch(actions.toggleFind(data));
        }
    };
};

const mapStateToProps = (state) => {
    return {
        columnDetails: state.columnDetails,
        columnListCount: state.gridDataUpdate.gridColumnChange
    };
};

export default connect(mapStateToProps, mapDispatchToprops)(HeaderFilters);
