import React, { Component } from 'react';
import { connect } from 'react-redux';

import GridDataBuilder from './helperComponents/userDataBuilder';
import NotificationManager from '../common/components/notification';
import UserComponent from './userComponent';
import axios from 'axios';
import { UserServices } from '../common/services/user-services';
import AdminServices from '../admin/admin-services/admin-services';

class UserContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            isModalOpen: false,
            userUpdateData: {},
            userTotalCount: 0,
            selectedRowsCount: 0,
            checkedRows: [],
            checkedUserData: [],
            isUndoEnable: false,
            disabledUsers: [],
        };

        this.undoListData = [];
        this.undoCounter = 0;
        this.undoEditFields = [];
        this.modalOpen = this.modalOpen.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.removeGridRows = this.removeGridRows.bind(this);
        this.addUserRow = this.addUserRow.bind(this);
        this.updateGridRow = this.updateGridRow.bind(this);
        this.undoLastChanges = this.undoLastChanges.bind(this);
        this.resetUndo = this.resetUndo.bind(this);
        this.reloadGrid = this.reloadGrid.bind(this);
    }

    componentWillMount() {
    }

    componentDidMount() {
        const postData = {
            'id': this.props.user.groupId,
        };

        AdminServices.userList().then((response) => {
            let res = response;
            let currentUser = this.props.user;

            if (currentUser.company.toLowerCase() != 'sweft') {
                res.data = res.data.filter(
                    i => i.company.toLowerCase() != 'sweft'
                );
            }

            if (res.responseCode === 0) {
                this.gridData = GridDataBuilder(res);
                this.setState({
                    isLoaded: true,
                    userTotalCount: res.data.length
                });
            } else {
                NotificationManager({
                    message: 'Error:Response failed, Please try again',
                    type: 4
                });
            }
        }).catch((error) => {
            console.log('User componentDidMount userList API call failed', error);
            NotificationManager({
                message: 'Error:Response failed, Please try again',
                type: 4
            });
        });

        $(window).resize(function () {
            $('.jqx-grid').jqxGrid({ height: window.innerHeight - 114 });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.isLoaded !== this.state.isLoaded) {
            const _this = this;
            const userView = $('#userGrid');

            userView.off();

            let isEmailCounter = 0;

            // Start: Update user status
            userView.on('change', 'select:not(".multi-select")', function (e) {
                const _me = $(this);
                const value = _me.val();
                const rowId = _me.data('row');
                const rowData = _this.userGrid.getrowdata(rowId);

                _this.userGrid.setcellvalue(rowId, 'status', (value === '1') ? true : false);

                console.log('Onchange rowdata', _this.userGrid.getrowdata(rowId));

                _this.updateUserStatus(rowData.userId, (value === '1') ? true : false);
            });
        }
    }

    /**
     * undo last 5 changes
     */
    undoLastChanges() {
        //user grid undo last change
        const _this = this;

        if (_this.undoCounter < 5) {
            const rowdata = _this.undoListData[_this.undoCounter];
            this.userGrid.updaterow(rowdata.uid, rowdata);

            _this.inlineUserUpdate(rowdata, 'undo');
            _this.undoCounter++;

            if (_this.undoCounter === 5 || _this.undoCounter === _this.undoListData.length) {
                _this.undoCounter = 0;
                _this.undoListData = [];
                _this.undoEditFields = [];
                _this.oldRowData = null;
                _this.setState({ isUndoEnable: false });
            }
        }
    }

    /**
     * reloads grid after changes are made
     */
    reloadGrid() {
        this.setState({ isLoaded: false }, () => {
            AdminServices.userList().then((response) => {
                const res = response;

                if (res.responseCode === 0) {
                    this.gridData = GridDataBuilder(res);
                    this.setState({
                        isLoaded: true,
                        userTotalCount: res.data.length
                    });
                } else {
                    NotificationManager({
                        message: 'Error:Response failed, Please try again',
                        type: 4
                    });
                }
            }).catch((error) => {
                console.log('User componentDidMount userList API call failed', error);

                NotificationManager({
                    message: 'Error:Response failed, Please try again',
                    type: 4
                });
            });
        });
    }

    /**
     * store for undo changes
     */
    storeForUndoChanges() {
        const rowdata = this.oldRowData;

        if (rowdata) {
            this.undoEditFields.push(args.dataField);
            this.undoListData.unshift(rowdata);

            if (this.undoListData.length > 0) {
                this.setState({ isUndoEnable: true });
            }
        }
    }

    /**
     * Resets the undo
     */
    resetUndo() {
        const _this = this;

        _this.oldRowData = null;
        _this.setState({ isUndoEnable: false });
        _this.undoEditFields = [];
        _this.undoListData = [];
        _this.undoCounter = 0;
    }

    /**
     * Calls backend to update user status
     * @param {*} id
     * @param {*} status
     */
    updateUserStatus(id, status) {
        const req = {
            userId: id,
            status: status
        };

        UserServices.updateUserStatus(req).then((response) => {
            const res = response;

            if (res.responseStatus) {
                NotificationManager({
                    message: 'User status has been changed successfully',
                    type: 1,
                });
            }
        }).catch((error) => {
            NotificationManager({
                message: 'Failed to update user status',
                type: 4,
            });
        });
    }

    /**
     * Calls backend for inline update
     * @param {*} rowdata
     * @param {*} prevData
     */
    inlineUserUpdate(rowdata, prevData) {
        const req = rowdata;
        const _this = this;

        AdminServices.insertUsersData(req).then((response) => {
            const res = response;

            if (!res.status) {
                const msg = (prevData == 'undo') ? 'Failed to Undo data' : res.responseMessage;

                NotificationManager({
                    message: msg,
                    type: 4,
                });

                return false;
            } else {
                const msg = (prevData == 'undo') ? 'Data Undo Success' : res.responseMessage;

                NotificationManager({
                    message: msg,
                    type: 1,
                });
            }
        }).catch((error) => {
            const msg = (prevData == 'undo') ? 'Failed to Undo data' : res.responseMessage;

            NotificationManager({
                message: msg,
                type: 4,
            });
        });
    }

    /**
     * Removes rows after user has been deleted
     * @param {*} rows
     */
    removeGridRows(rows) {
        const _this = this;

        rows.forEach((e, index) => {
            this.userGrid.deleterow(e);
        });

        console.log(this.userGrid.getrows().length);

        _this.setState({
            userTotalCount: this.userGrid.getrows().length,
            selectedRowsCount: 0,
            checkedRows: [],
            checkedUserData: []
        });
    }

    /**
     * Opens the modal
     */
    modalOpen() {
        this.setState({ isModalOpen: true });
    }

    /**
     * Closes the modal
     */
    modalClose() {
        this.setState({ isModalOpen: false });
    }

    /**
     * Appends new row
     * @param {*} req
     */
    addUserRow(req) {
        const data = req;
        data.status = true;
        data.userId = data.userId;

        // constructing date format
        const date = new Date(data.registrationDate);
        const month = (date.getMonth() > 10) ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
        const day = (date.getDate() > 9) ? date.getDate() : '0' + date.getDate();
        const year = date.getFullYear();

        data.registrationDate = month + '/' + day + '/' + year;
        this.userGrid.addrow(null, data);
        this.reloadGrid();
    }

    /**
     * Updates rows after data is saved
     * @param {*} req
     */
    updateGridRow(req) {
        const data = req;

        this.userGrid.updaterow(data.uid, data);
    }

    onCellbeginedit(event) {
        const _this = this;
        const data = event.args.row;
        const args = event.args;
        const datafield = args.datafield;
        const newvalue = args.newvalue;
        const rowindex = args.rowindex;
        const rowdata = _this.userGrid.getrowdata(rowindex);

        if (!event.args.row.status) {
            _this.userGrid.endcelledit(rowindex, datafield, true);

            NotificationManager({
                message: 'User is disabled, please <b>enable</b> the user before taking any action',
                type: 4,
            });
        }

        _this.oldRowData = Object.assign({}, data);
    }

    onRowselect(event) {
        const _this = this;
        const rowIndexs = _this.userGrid.getselectedrowindexes();
        const newCheckedUserData = [];

        rowIndexs.map((val, key) => {
            const userId = _this.userGrid.getrowdata(val).userId;

            if (newCheckedUserData.indexOf(userId) < 0) {
                newCheckedUserData.push(userId);
            }
        });

        _this.setState({
            selectedRowsCount: rowIndexs.length,
            checkedRows: rowIndexs,
            checkedUserData: newCheckedUserData
        });
    }

    onRowunselect(event) {
        const _this = this;
        const rowIndexs = _this.userGrid.getselectedrowindexes();
        const newCheckedUserData = [];

        rowIndexs.map((val, key) => {
            const userId = _this.userGrid.getrowdata(val).userId;

            if (newCheckedUserData.indexOf(userId) < 0) {
                newCheckedUserData.push(userId);
            }
        });

        _this.setState({ selectedRowsCount: rowIndexs.length, checkedRows: rowIndexs, checkedUserData: newCheckedUserData });
    }

    onCellvaluechanged(event) {
        let isEmailCounter = 0;
        const _this = this;

        if (isEmailCounter) {
            isEmailCounter = 0;

            return false;
        }

        const args = event.args;
        const datafield = args.datafield;
        const newvalue = args.newvalue;
        const rowindex = args.rowindex;
        const rowdata = _this.userGrid.getrowdata(rowindex);
        const strreg = /^[A-Za-z0-9]+$/i;
        const telregex = /^[0-9+-]+$/i;

        if (datafield === 'nickName' && newvalue.length > 10) {
            isEmailCounter = 1;

            NotificationManager({
                message: 'nick name length should not exceed 10 characters',
                type: 4,
            });

            _this.userGrid.setcellvalue(rowindex, datafield, args.oldvalue.trim());
            _this.userGrid.begincelledit(rowindex, datafield);

            return;
        } else if (datafield === 'email') {
            const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (!regex.test(newvalue.trim())) {
                isEmailCounter = 1;

                NotificationManager({
                    message: 'Invalid Email address.',
                    type: 4,
                });

                _this.userGrid.setcellvalue(rowindex, datafield, args.oldvalue.trim());
                _this.userGrid.begincelledit(rowindex, datafield);

                return;
            }
        } else if (datafield === 'nickName' && (newvalue.trim() === '' || !strreg.test(newvalue))) {
            isEmailCounter = 1;

            NotificationManager({
                message: 'Invalid Nickname.',
                type: 4,
            });

            _this.userGrid.setcellvalue(rowindex, datafield, args.oldvalue.trim());
            _this.userGrid.begincelledit(rowindex, datafield);

            return;
        } else if (datafield === 'firstName' && (newvalue.trim() === '' || !strreg.test(newvalue))) {
            isEmailCounter = 1;

            NotificationManager({
                message: 'Invalid Firstname.',
                type: 4,
            });

            _this.userGrid.setcellvalue(rowindex, datafield, args.oldvalue.trim());
            _this.userGrid.begincelledit(rowindex, datafield);

            return;
        } else if (datafield === 'lastName' && (newvalue.trim() === '' || !strreg.test(newvalue))) {
            isEmailCounter = 1;

            NotificationManager({
                message: 'Invalid Lastname.',
                type: 4,
            });

            _this.userGrid.setcellvalue(rowindex, datafield, args.oldvalue.trim());
            _this.userGrid.begincelledit(rowindex, datafield);

            return;
        } else if (datafield === 'phone' && (newvalue.trim() === '' || !telregex.test(newvalue))) {
            isEmailCounter = 1;

            NotificationManager({
                message: 'Invalid Phone number.',
                type: 4,
            });

            _this.userGrid.setcellvalue(rowindex, datafield, args.oldvalue.trim());
            _this.userGrid.begincelledit(rowindex, datafield);

            return;
        }

        const ignoreList = ['status', 'defaultRole'];

        if (ignoreList.indexOf(datafield) === -1) {
            const roles = Object.assign([], rowdata.roles);
            const newRoles = [];

            roles.map((val, key) => {
                if (roles[key].status === 'True') {
                    newRoles.push(roles[key]);
                }
            });

            const newRowData = Object.assign({}, rowdata);
            newRowData.roles = newRoles;

            if (args.oldvalue != args.value && isEmailCounter == 0) {
                _this.inlineUserUpdate( // Updating user
                    newRowData,
                    {
                        oldValue: event.args.oldvalue,
                        dataField: datafield,
                        rowindex: rowindex
                    }
                );

                _this.storeForUndoChanges(args.datafield);
            }

            isEmailCounter = 0;
        }
    }

    onCelldoubleclick(event) {
        const _this = this;
        const value = event.args.value;
        const rowId = event.args.rowindex;
        const rowData = _this.userGrid.getrowdata(rowId);

        if (rowData.status) {
            const userObj = Object.assign({}, rowData);

            _this.userUpdateData = userObj;
            _this.setState({ userUpdateData: userObj });
            _this.modalOpen();
        } else {
            NotificationManager({
                message: 'User is disabled, please <b>enable</b> the user before taking any action',
                type: 4,
            });
        }
    }

    render() {
        const footerHeight = 50;
        const headerHeight = 64;

        $('.page-title a').text("ADMIN: Users");

        const gridHeight = window.innerHeight - footerHeight - headerHeight - 20;
        const editSettings = {
            saveOnPageChange: true,
            saveOnBlur: true,
            saveOnSelectionChange: true,
            cancelOnEsc: true,
            saveOnEnter: true,
            editSingleCell: true,
            editOnF2: true,
        };

        return (
            <UserComponent
                state={this.state}
                props={this.props}

                userGridReference={(grid) => {
                    this.userGrid = grid;
                }}

                gridData={this.gridData}
                gridHeight={gridHeight}
                editSettings={editSettings}

                onCellbeginedit={this.onCellbeginedit.bind(this)}
                onRowselect={this.onRowselect.bind(this)}
                onRowunselect={this.onRowunselect.bind(this)}
                onCellvaluechanged={this.onCellvaluechanged.bind(this)}
                onCelldoubleclick={this.onCelldoubleclick.bind(this)}

                modalClose={this.modalClose}
                userUpdateData={this.userUpdateData}
                updateGridRow={this.updateGridRow}

                removeGridRows={this.removeGridRows}
                addUserRow={this.addUserRow}
                undoLastChanges={this.undoLastChanges}

                reloadGrid={this.reloadGrid}
            />
        );
    }
}

export default UserContainer;
