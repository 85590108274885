import React, { Component } from 'react';
import ConfirmModal from '../../../modals/confirm-modal.js';
import { connect } from 'react-redux';

import * as actions from '../../../product/redux/productActions';

import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import JqxTabs from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxtabs';

// import JqxTabs from '../../../common/vendor/jqwidgets-react/react_jqxtabs.js';
import axios from 'axios';

const API = require('../../../common/configurations.js');

import '../../product2Container.less';

import NotificationManager from '../../../common/components/notification';

// import moment from 'moment';

class ProductOptions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            isLoaded: false,
            options: [],
            selectedOptionsCount: 0,
            selectedOptionsIndex: [],
            isLastTab: false,
            currentTabindex: 0,
            combinations: {},
        };

        this.createParentChild = this.createParentChild.bind(this);
        this.saveParentChild = this.saveParentChild.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.checkboxChanged = this.checkboxChanged.bind(this);
        this.onTabclick = this.onTabclick.bind(this);
    }

    /**
     * Setter for values when the popups opens
     */
    componentDidMount() {
        const _this = this;

        const dropdowns = Object.assign([], this.props.dropdowns2);
        const variants = dropdowns.variants;
        const options = {};

        if (this.props.queryForOptions) {
            const headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json',
            };
            axios({
                method: 'get',
                url: API.product.getAttributeDropDown + this.props.queryForOptions,
                headers: headers,
                //   data: postdata
            })
                .then((response) => {
                    console.log('productContainer helperComponents product-options API call success data: ', response.data);
                    const res = response.data;


                    if (res.status == true) {
                        for (const keyindex in res.data) {
                            for (const keyindex2 in res.data[keyindex]) {
                                res.data[keyindex][keyindex2].order = keyindex2;
                            }
                        }
                        _this.setState({ options: res.data, isLoaded: true });
                    }
                })
                .catch((error) => {
                    console.log('productContainer helperComponents product-options API call failed', error);
                });
        } else {
            variants && variants.map((val, index) => {
                // options[val.toLowerCase()] =  {value: (dropdowns[val.toLowerCase()] != undefined)?dropdowns[val.toLowerCase()]: []}
                options[val.toLowerCase()] = [];
                if (dropdowns[val.toLowerCase()] != undefined) {
                    dropdowns[val.toLowerCase()].map((optVal, index) => {
                        options[val.toLowerCase()].push({ value: optVal, status: false, visiblity: true, order: index });
                    });
                }
            });

            const oldColor = options['color'];
            const color = oldColor.filter((color) => color.value != 'Create Variant');
            options['color'] = color;
            const oldSize = options['size'];
            const size = oldSize.filter((size) => size.value != 'Create Variant');
            options['size'] = size;
            this.setState({ options: options, isLoaded: true });
        }
    }

    // optionSearch(q, type) {
    //     let options = this.state.options;

    //     options[type].map((val, idx) => {
    //         let nameLowercase = val.value.toLowerCase();
    //         if (nameLowercase.indexOf(q)>-1) {
    //             options[type][idx].visiblity = true;
    //         } else {
    //             options[type][idx].visiblity = false;
    //         }
    //     });

    //     return options;

    // }

    /**
     * Checks for changes in the popup and updates
     * @param {*} prevprops
     * @param {*} prevstate
     */
    componentDidUpdate(prevprops, prevstate) {
        const _this = this;
        if (prevstate.isLoaded != this.state.isLoaded) {
            const optionsTab = $('#options-tab');

            // on change options values - checkbox
            // optionsTab.off('change');
            // optionsTab.on('change', '.product-options', function (e) {
            //     const elem = $(this);
            //     const optionName = elem.data('option-name');
            //     const key = elem.data('option-key');
            //     const tabIndex = elem.data('tabindex');
            //     const checked = (elem.is(':checked')) ? true : false;

            //     $('.options-list.' + optionName + ' .row-index-' + key).attr('data-ischecked', checked ? '1' : '0');

            //     let { options, selectedOptionsCount } = _this.state;
            //     const newOptions = options;

            //     newOptions[optionName][key].status = checked;
            //     // updating selected options to state for cancel modal
            //     (checked) ? selectedOptionsCount++ : selectedOptionsCount--;

            //     const listUnchecked = [];
            //     const listChecked = [];
            //     newOptions[optionName].map((obj) => {
            //         if (obj.status) {
            //             listChecked.push(obj);
            //         } else {
            //             listUnchecked.push(obj);
            //         }
            //     });

            //     const sortedChecked = listChecked.sort((a, b) => a.order - b.order);
            //     const sortedUnchecked = listUnchecked.sort((a, b) => a.order - b.order);
            //     newOptions[optionName] = sortedChecked.concat(sortedUnchecked);

            //     // let sizeordersort =  newOptions.size.sort(function(a,b){ return a.order-b.order});

            //     _this.setState({ options: newOptions, selectedOptionsCount: selectedOptionsCount, isLoaded: false });
            // });


            // Search
            optionsTab.off('keyup');
            optionsTab.on('keyup', '.option-search', function (e) {
                const query = $(this).val().toLowerCase();
                const optionType = $(this).data('optionname');
                const tabIndex = $(this).data('tabindex');
                let matchedLength = 0;
                setTimeout(function () {
                    $('.options-list.' + optionType + ' .row').hide();
                    // $('.options-list.'+optionType+' .row[data-ischecked="false"]').hide();
                    $('.options-list.' + optionType + ' .row').filter(function (el) {
                        if (query.length > 0) {
                            const pName = isNaN($(this).data('option-value')) ? $(this).data('option-value').toLowerCase() : $(this).data('option-value');
                            // var pName = $(this).data('option-value');
                            if (pName.indexOf(query) > -1) {
                                matchedLength++;
                                $(this).show();
                            }
                            if (matchedLength <= 0) {
                                $('#option-no-msg-' + tabIndex).show();
                            } else {
                                $('#option-no-msg-' + tabIndex).hide();
                            }
                        } else {
                            $('.options-list.' + optionType + ' .row').show();
                            $('#option-no-msg-' + tabIndex).hide();
                        }
                    });

                    if (_this.state.selectedOptionsCount > 0) {
                        const $wrapper = $('.options-container.tabIndex-' + tabIndex + ' .options-list');

                        $wrapper.find('.row').sort(function (a, b) {
                            return +b.dataset.ischecked - +a.dataset.ischecked;
                        }).appendTo($wrapper);
                    }
                    // _this.setState({options: _this.optionSearch(query, optionType), isLoaded: false});
                }, 300);
            });


            // if (this.state.isLoaded && !this.state.isProductConfirm) {
            //     // On click on tabs
            //     _this.optionTabs.on('tabclick', function (event) {
            //         const tabIndex = event.args.item;
            //         if ((tabIndex + 1) == _this.getTabCount()) {
            //             _this.setState({ isLastTab: true, currentTabindex: tabIndex });
            //         } else {
            //             _this.setState({ isLastTab: false, currentTabindex: tabIndex });
            //         }
            //     });
            // }

            if (prevstate.isLoaded && !this.state.isLoaded && !this.state.isProductConfirm) {
                _this.setState({ isLoaded: true });
            }
        }

        //
        // if (prevstate.isProductConfirm != this.state.isProductConfirm) {
        //     let optionsTab = $('#options-tab');
        //     optionsTab.on('change', '.combined-product-options', function(e) {
        //         let elem = $(this);
        //         let key = elem.data('option-key');
        //         let checked = (elem.is(':checked'))?true:false;

        //         _this.constructingCombinedOptions(key, checked);
        //     });
        // }
    }

    /**
     * Get JqxTabs count - it returns the total count of tabs avail
     * @return {number} gets tab count
     */
    getTabCount() {
        return this.optionTabs.length();
    }

    /**
     * Opens the confirm Modal
     */
    confirmModal() {
        // count of selected index both individual & combined options
        const count = (this.state.isProductConfirm) ? this.state.selectedOptionsIndex.length : this.state.selectedOptionsCount;
        if (count > 0) {
            this.setState({ isModalOpen: true });
        } else {
            this.props.modalClose();
        }
    }

    /**
     * Cloes the modal
     * @param {*} key
     */
    modalClose(key) {
        this.setState({ isModalOpen: false });
        switch (key) {
            case 'yes':
                this.props.modalClose();
                break;
            case 'cancel':
                break;
        }
    }

    /**
     * constructing permutation & combination values to create child products
     * @param {integer} key Array index of state "combinedOptions"
     * @param {Boolean} checked
     */
    constructingCombinedOptions(key, checked) {
        // let {combinedOptions} = _this.state;
        // let newCombinedOptions = combinedOptions;

        // newCombinedOptions[optionName][key].status = checked;

        // _this.setState({combinedOptions: newCombinedOptions});

        const { selectedOptionsIndex } = this.state;

        if (checked) {
            selectedOptionsIndex.push(key);
        } else {
            if (selectedOptionsIndex.indexOf(key) > -1) {
                selectedOptionsIndex.splice(selectedOptionsIndex.indexOf(key));
            }
        }

        this.setState({ selectedOptionsIndex: selectedOptionsIndex });
    }

    /**
     * Setter for parent child combination
     * @return {object} product combination
     */
    permutationCombination() {
        const options = Object.keys(this.state.options);
        const combinations = {};

        options.map((value) => {
            combinations[value[0].toUpperCase() + value.slice(1)] = [];

            this.state.options[value].map((val, index) => {
                if (val.status) {
                    combinations[value[0].toUpperCase() + value.slice(1)].push(val.value);
                }
            });
        });

        return combinations;
    }

    /**
     * Toggles options of parent child
     * @param {*} index
     */
    toggleCombinedOptions(index) {
        const { combinedProducts, selectedCombinedCount } = this.state;
        const newCombinedProducts = combinedProducts;
        let newCount = selectedCombinedCount;

        newCombinedProducts[index].isChecked = !newCombinedProducts[index].isChecked;

        newCount = (newCombinedProducts[index].isChecked) ? newCount + 1 : newCount - 1;

        // this.constructingCombinedOptions(index, newCombinedProducts[index].isChecked);

        this.setState({ combinedProducts: newCombinedProducts, selectedCombinedCount: newCount });
    }

    /**
     * Caretes parent and child structure for backend
     */
    createParentChild() {
        function cartesian(arg) {
            const r = [];
            // arg = arguments,
            const max = arg.length - 1;

            function helper(arr, i) {
                for (let j = 0, l = arg[i].length; j < l; j++) {
                    const a = arr.slice(0); // clone arr
                    a.push(arg[i][j]);
                    if (i == max) {
                        r.push(a);
                    } else {
                        helper(a, i + 1);
                    }
                }
            }
            helper([], 0);
            return r;
        }

        const _this = this;
        const combinations = this.permutationCombination();

        // tried multiple combinations
        // combinations = {
        //     Color:["Blue", "Green"],
        //     Size:["XS", "S"],
        //     Fit:["Regular", "Slimfit"]
        // }

        const data = [];

        for (const key in combinations) {
            // if (combinations.hasOwnProperty.call(key, combinations)) {
            data.push(combinations[key]);
            // }
        }

        const permutation = cartesian(data);

        const allKeys = Object.keys(combinations);
        const data2 = [];

        for (let i = 0; i < permutation.length; i++) {
            const obj = {};
            for (let k = 0, v = 0; k < allKeys.length, v < permutation[i].length; k++, v++) {
                obj[allKeys[k]] = permutation[i][v];
            }
            data2.push(obj);
        }

        const resData = data2;
        const selectedIndex = [];
        resData.map((val, idx) => {
            resData[idx].isChecked = true;
            selectedIndex.push(idx);
        });

        _this.setState({
            isProductConfirm: true,
            combinedProducts: resData,
            selectedCombinedCount: resData.length,
            selectedOptionsIndex: selectedIndex,
            isLoaded: false,
            combinations,
        });

        // let headers = {
        //       'Authorization': this.props.user.token,
        //       'content-type': 'application/json'
        // }
        // axios({
        //   method: 'post',
        //   url: require('../../common/configurations.js').product.createParentChld,
        //   headers: headers,
        //   data: combinations
        // })
        // .then( (response) => {
        //     let res = response.data

        //     if (res.status) {
        //         let resData = res.data;
        //         let selectedIndex = []
        //         resData.map((val, idx) => {
        //             resData[idx].isChecked = true;
        //             selectedIndex.push(idx);
        //         });
        //         _this.setState({isProductConfirm: true, combinedProducts: resData, selectedCombinedCount: resData.length, selectedOptionsIndex: selectedIndex, isLoaded: false});
        //     }

        // })
        // .catch( error => {
        //     NotificationManager({message:'Failed to create parent-child',type:4});
        // });
    }


    /**
     * Saves parent child structure
     */
    saveParentChild() {
        const _this = this;
        const combinations = [];
        this.setState({ selectedCombinedCount: 0 }); // added to disable multiclick of button

        _this.state.combinedProducts.map((value) => {
            if (value.isChecked) {
                combinations.push(value);
            }
        });

        const productData = this.props.currentRowData;
        // delete productData.data;
        const req = {
            productId: productData,
            data: combinations,
            userRole: this.props.user.defaultRole,
        };


        const data = {};
        const productDetails = [];
        const selectedRow = this.props.rowData;

        // updating fields with some default values. format has to be yyyy-mm-dd else it does not save
        selectedRow['dateAdded'] = selectedRow['dateAdded'] ? new Date(selectedRow['dateAdded']) : new Date();

        const buyer = selectedRow['buyer'];
        selectedRow['buyer'] = buyer ? buyer : this.props.user.userName;


        // ////////////////////mandatory fields check////////////////////////////
        const fieldsNotPopulated = this.props.mandatoryFieldsCheck(selectedRow);

        if (fieldsNotPopulated.length) {
            NotificationManager({ message: 'The following fields are mandatory. ' + fieldsNotPopulated.toString(), type: 4 });
            return;
        }
        // ////////////////////mandatory fields check////////////////////////////
        // below works only on update not on add new


        if (selectedRow['dataLevel'] && selectedRow['dataLevel'].localeCompare('SINGLE') == 0) {
            if (selectedRow['chldProdId']) {
                selectedRow['productId'] = selectedRow['chldProdId'];
            } else if (this.state.data && this.state.data[index]['chldProdId']) {
                selectedRow['productId'] = this.state.data[index]['chldProdId'];
            } else {
                NotificationManager({ message: 'Unable to retrieve child product id', type: 4 });
                return;
            }
        }

        if (selectedRow['dataLevel'] && selectedRow['dataLevel'].localeCompare('PARENT') == 0) {
            if (selectedRow['parentProductId']) {
                selectedRow['productId'] = selectedRow['parentProductId'];
            } else if (this.state.data && this.state.data[index]['parentProductId']) {
                selectedRow['productId'] = this.state.data[index]['parentProductId'];
            } else {
                NotificationManager({ message: 'Unable to retrieve parent product id', type: 4 });
                return;
            }
        }

        if (selectedRow['dataLevel'] && selectedRow['dataLevel'].localeCompare('INTERMEDIATE') == 0) {
            if (selectedRow['intProdId']) {
                selectedRow['productId'] = selectedRow['intProdId'];
            } else if (this.state.data && this.state.data[index]['intProdId']) {
                selectedRow['productId'] = this.state.data[index]['intProdId'];
            } else {
                NotificationManager({ message: 'Unable to retrieve int product id', type: 4 });
                return;
            }
        }

        if (!selectedRow['copyLevel']) {
            selectedRow['copyLevel'] = 'Color';
        }
        if (!selectedRow['displayLevel']) {
            selectedRow['displayLevel'] = 'Color';
        }

        // filter out the null values

        const filteredSelectedRow = {};
        for (const key in selectedRow) {
            if (selectedRow.hasOwnProperty(key)) {
                if (selectedRow[key]) {
                    filteredSelectedRow[key] = selectedRow[key];
                }
            }
        }

        // ////////////////////////////////////////////////////////////////////////////////////////////////////////

        productDetails.push(filteredSelectedRow);
        data['productDetails'] = productDetails; // because productDetails is an array
        // data['productCombination'] = combinations;
        data['productCombination'] = this.state.combinations;
        data['userRole'] = this.props.user.defaultRole;

        data['combinedProducts'] = this.state.combinedProducts;
        const excludedCombinations = this.state.combinedProducts.filter(function (row) {
            if (!row.isChecked) {
                return row;
            }
        });

        data['excludedCombinations'] = excludedCombinations;

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };
        axios({
            method: 'post',
            //   url: 'http://localhost:1234/api/products/addupdate',
            url: require('../../../common/configurations.js').product2.addupdate,
            headers: headers,
            data: data,
        })
            .then((response) => {
                const res = response.data;

                if (res.status === 'success') {
                    _this.props.modalClose();
                    _this.props.reloadGrid();
                } else {
                    NotificationManager({ message: 'Failed to create parent-child', type: 4 });
                }
            })
            .catch((error) => {
                console.log('productContainer helperComponents footer API call failed', error);
                NotificationManager({ message: 'Failed to create parent-child', type: 4 });
            });
    }


    /**
     * Allows edit of selected values
     */
    editSelection() {
        confirm('Edit Selection');
        this.setState({ isProductConfirm: false, isLoaded: true, isLastTab: false, currentTabindex: 0 });
    }

    /**
     * Moves to the next tab
     */
    tabNext() {
        if ((this.state.currentTabindex + 1) <= this.getTabCount()) {
            this.optionTabs.select(this.state.currentTabindex + 1);

            if ((this.state.currentTabindex + 1) === (this.getTabCount() - 1)) {
                this.setState({ isLastTab: true, currentTabindex: this.state.currentTabindex + 1 });
            }
        }
    }

    /**
     * Moves back to the previous tab
     */
    tabBack() {
        this.optionTabs.select(this.state.currentTabindex - 1);
        this.setState({ isLastTab: false, currentTabindex: this.state.currentTabindex - 1 });
    }

    onTabclick(event) {
        const _this = this;
        console.log('tab clicked', event);
        const tabIndex = event.args.item;
        if ((tabIndex + 1) == _this.getTabCount()) {
            _this.setState({ isLastTab: true, currentTabindex: tabIndex });
        } else {
            _this.setState({ isLastTab: false, currentTabindex: tabIndex });
        }
    }

    checkboxChanged(event) {
        let _this = this;
        const target = event.target;
        const checked = target.type === 'checkbox' ? target.checked : target.value;

        const optionName = event.target.dataset.optionName;
        const optionValue = event.target.dataset.optionValue;
        const key = event.target.dataset.optionKey;
        const tabIndex = event.target.dataset.tabindex;

        $('.options-list.' + optionName + ' .row-index-' + key).attr('data-ischecked', checked ? '1' : '0');

        let { options, selectedOptionsCount } = _this.state;
        const newOptions = options;

        newOptions[optionName][key].status = checked;
        // updating selected options to state for cancel modal
        (checked) ? selectedOptionsCount++ : selectedOptionsCount--;

        const listUnchecked = [];
        const listChecked = [];
        newOptions[optionName].map((obj) => {
            if (obj.status) {
                listChecked.push(obj);
            } else {
                listUnchecked.push(obj);
            }
        });

        const sortedChecked = listChecked.sort((a, b) => a.order - b.order);
        const sortedUnchecked = listUnchecked.sort((a, b) => a.order - b.order);
        newOptions[optionName] = sortedChecked.concat(sortedUnchecked);

        // let sizeordersort =  newOptions.size.sort(function(a,b){ return a.order-b.order});

        _this.setState({ options: newOptions, selectedOptionsCount: selectedOptionsCount, isLoaded: false });
    }

    render() {
        // Product options constructions

        const options = Object.keys(this.state.options);

        let tabContentColor;
        let mainRender = options.map((value, idx) => {
            let tabContentListColor = this.state.options[value].map((val, index) => {
                const checked = (val.status == true) ? true : false;
                const isChecked = (val.status == true) ? '1' : '0';
                if (val.visiblity == true) {
                    return <div key={val.value} className={'row row-index-' + index} data-option-value={val.value} data-ischecked={isChecked} data-sortorder={index}><label className={'form-check-label'}><input type='checkbox' onChange={this.checkboxChanged} id={value + '-' + index} className='product-options form-control-check' data-option-name={value} data-option-value={val.value} data-option-key={index} data-tabindex={idx} checked={checked}/><span htmlFor={value + '-' + index} className='from-checkbox margin-top3'></span>{val.value}</label></div>;
                } else {
                    return '';
                }
            });
            return <div key={idx} className={'options-container scroll tabIndex-' + idx}><div className='options-search'><label className='form-label'>Search {value}</label><input type='text' className='option-search form-control' data-optionname={value} data-tabindex={idx}></input></div><div className={'options-list form-group ' + value}>{tabContentListColor}</div><div id={'option-no-msg-' + idx} style={{display: 'none'}}>No options found</div></div>;
        });

        // let optionsTab = '';
        // let tabContent = '';
        // options.map((value, idx) => {
        //     optionsTab += '<li>' + value + '</li>';

        //     let tabContentList = '';
        //     this.state.options[value].map((val, index) => {
        //         const checked = (val.status) ? 'checked' : '';
        //         const isChecked = (val.status) ? '1' : '0';
        //         if (val.visiblity) {
        //             tabContentList += '<div class="row row-index-' + index + '" data-option-value="' + val.value + '" data-isChecked="' + isChecked + '" data-sortorder="' + index + '"><label class="form-check-label"><input type="checkbox" id="' + value + '-' + index + '" class="product-options form-control-check" data-option-name="' + value + '" data-option-key="' + index + '" data-tabindex="' + idx + '" ' + checked + '/><span for="' + value + '-' + index + '" class="from-checkbox margin-top3"></span>' + val.value + '</label></div>';
        //         } else {
        //             tabContentList += '';
        //         }
        //     });
        //     tabContent += '<div class="options-container scroll tabIndex-' + idx + '"><div class="options-search"><label class="form-label">Search ' + value + '</label><input type="text" class="option-search form-control" data-optionname="' + value + '" data-tabindex="' + idx + '"/></div><div class="options-list form-group ' + value + '">' + tabContentList + '</div><div id="option-no-msg-' + idx + '" style="display: none">No options found</div></div>';
        // });
        // const tabsHTML = '<ul>' + optionsTab + '</ul>' + tabContent;

        // Combined Products
        const values = this.props.dropdowns2.variants;

        let combinedOptions;
        if (this.state.isProductConfirm) {
            combinedOptions = this.state.combinedProducts.map((value, index) => {
                const label = values.map((val, idx) => {
                    return (<span key={idx}>{value[val]}{(idx < (values.length - 1)) ? '-' : ''}</span>);
                });
                return (<div className="row" key={index}>
                    <label className="form-check-label">
                        <input type="checkbox" id={'option_' + index} className="combined-product-options form-control-check"
                            checked={value.isChecked} data-option-key={index} onChange={() => this.toggleCombinedOptions(index)} />
                        <span htmlFor={'option_' + index} className="from-checkbox margin-top3" />
                        {label}</label>
                </div>);
            });
        }

        return (
            <div>
                <div className="modal-header">
                    Create Variant
                    <span className="close" onClick={this.confirmModal.bind(this)}>x</span>
                </div>
                <div className="modal-content">
                    <div className="product-option modal-form">
                        <div>
                            <h4>Attribute Selection</h4>
                            {this.state.isProductConfirm &&
                                <span><button type="button" className="button-link" onClick={this.editSelection.bind(this)}>Edit</button></span>
                            }
                        </div>
                        <hr />

                        {!this.state.isProductConfirm &&
                            <div>
                                <div id="options-tab">
                                    {this.state.isLoaded &&
                                        <JqxTabs ref={(tabs) => {
                                            this.optionTabs = tabs;
                                        }}
                                        // template={tabsHTML}
                                        width={'100%'} height={200}
                                        position={'top'}
                                        onTabclick={this.onTabclick}
                                        selectedItem={this.state.currentTabindex}>
                                            <ul>
                                                {options.map(i => <li key={i}>{i}</li>)}
                                            </ul>
                                            {mainRender}
                                        </JqxTabs>
                                    }
                                </div>

                                <div className="modal-footer">
                                    <input type="button" className="gray-button" value="Back" disabled={(this.state.currentTabindex === 0) ? true : false} onClick={this.tabBack.bind(this)} />
                                    {
                                        (this.state.isLastTab) ?
                                            <input type="button" className="gray-button" value="Generate" disabled={(this.state.selectedOptionsCount > 0) ? false : true} onClick={this.createParentChild} /> :
                                            <input type="button" className="gray-button" value="Next" onClick={this.tabNext.bind(this)} />
                                    }
                                    <input type="button" className="gray-button" value="Cancel" onClick={this.confirmModal.bind(this)} />
                                </div>
                            </div>
                        }

                        {this.state.isProductConfirm &&
                            <div>
                                <h4 className="no-margin">Confirm Product Options</h4>
                                <div id="options-tab">
                                    <div className="options-container scroll">
                                        <div className="options-list form-group">
                                            {combinedOptions}
                                        </div>
                                    </div>
                                </div>

                                <div className="modal-footer">
                                    <input type="button" className="gray-button" value="Create Products" disabled={(this.state.selectedCombinedCount > 0) ? false : true} onClick={this.saveParentChild} />
                                    <input type="button" className="gray-button" value="Cancel" onClick={this.confirmModal.bind(this)} />
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <CancelModal isOpen={this.state.isModalOpen} modalClose={this.modalClose} />
            </div>
        );
    }
}

class CancelModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
        };

        this.closeConfirmModal = this.closeConfirmModal.bind(this);
    }

    closeConfirmModal(key) {
        this.props.modalClose(key);
    }

    render() {
        return (<ConfirmModal isOpen={this.props.isOpen}>
            <div className="modal-content">
                <h3 className="confirm-warning-text">Are you sure you want to cancel the child products creation process?</h3>
                <div className="modal-footer">
                    <input type="button" className="gray-button" value="Yes" onClick={() => this.closeConfirmModal('yes')} />
                    <input type="button" className="gray-button" value="No" onClick={() => this.closeConfirmModal('no')} />
                </div>
            </div>
        </ConfirmModal>);
    }
}
/**
 * Redux
 * @param {*} state
 * @return {data}
 */
const mapStateToProps = (state) => {
    return {
        dropdowns: state.gridDropDowns,
    };
};

const mapDispatchToprops = (dispatch) => {
    return {
        updateOrReplaceData: (data) => {
            dispatch(actions.updateOrReplaceData(data));
        },
    };
};

const productOption = connect(mapStateToProps, mapDispatchToprops)(ProductOptions);
export default productOption;
