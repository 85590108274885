import React from 'react';
import axios from 'axios';
import * as actions from '../product/redux/productActions';
import * as product2_actions from '../product2/redux/product2Actions';

import { addAlertsData } from './redux/notificationsActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {CommonServices} from '../common/services/common-services';
import NotificationManager from '../common/components/notification';

class Notifier extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            tabActive: 'general',
            isAlertsLoaded: false,
            response: {},
            product2GridDataState: {},
            currentFilters: null,
        };

        this.switchTab = this.switchTab.bind(this);
        this.toggleNotification = this.toggleNotification.bind(this);
        this.alertProductClick = this.alertProductClick.bind(this);
        this.getParsedResponse = this.getParsedResponse.bind(this);
    }

    componentDidMount() {
        console.log('Page Header Notifier Component did mount', this.props);

        //fetch the list of alerts available
        this.getRecentData();

        $(document).on('click.alertclose', (e) => {
            e.stopPropagation();

            var notifier = '.notification-body';

            if ($(notifier).hasClass('open') && $(e.target).closest('.notification-head').length === 0) {
                this.toggleNotification();
            }
        });

        this.getRecentData();
    }

    componentDidUpdate(prevProps, prevState) {
        console.log('Page Header Notifier componentDidUpdate: ', prevProps, prevState);

        if (prevProps.isOpenedBase !== this.props.isOpenedBase) {
            if ($('.based-on-me .dropdown-el').hasClass('active') && this.state.isOpen) {
                this.setState({ isOpen: false });
            }
        }

        if (prevState.isOpen != this.state.isOpen && this.state.isOpen) {
            this.getRecentData();
        }

        if (prevProps.appliedFilters != this.props.appliedFilters && this.props.appliedFilters.length > 0) {
            this.setState({ currentFilters: this.props.appliedFilters });
        }
    }

    componentWillUnmount() {
        $(document).off('click.alertclose');
    }

    getRecentData() {
        let postData = {
            "defaultRole": this.props.user?.defaultRole,
            "userID": this.props.user?.userId
        };

        let headers = {
            'Authorization': this.props.user?.token,
            'content-type': 'application/json'
        };

        CommonServices.personalAlerts(postData)
            .then((response) => {
                this.setState({
                    isAlertsLoaded: true,
                    response: response.responseData
                });

                this.props.addAlertsData(response.responseData);
            })
            .catch(error => {
                console.log('failed to fetch the data at personal alerts', error);
            });
    }

    getParsedResponse(response) {
        let obj = {
            count: 0,
            personal: [],
            general: [],
            generalCount: 0,
            personalCount: 0
        };

        if (!response) {
            return obj;
        }

        if (response) {
            obj.personal = response.personal ? response.personal : [];
            obj.general = response.general ? response.general : [];
            obj.generalCount = response.general ? response.total : 0;
            obj.personalCount = response.personal ? response.total : 0;
            obj.count = obj.generalCount + obj.personalCount;
        }

        return obj;
    }

    switchTab(activeTab) {
        this.setState({ tabActive: activeTab });
    }

    alertProductClick(obj) {
        let pagenames = obj.page.toLowerCase();
        pagenames = pagenames == 'product' ? 'product2' : pagenames;

        let _this = this;

        console.log('obj value: ', obj.value);

        if (obj.value === 0) {
            return;
        }

        _this.props.toggleSpinner(true);

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        let filters = this.state.currentFilters;
        let data = filters ? {catIds: filters.catIds, seasonIds: filters.seasons, channelIds: filters.channels} : {catIds: [], seasonIds: null, channelIds: null};
        const url = require('../common/configurations.js').apiURL + obj.url;

        axios({
            method: 'post',
            url: url,
            headers: headers,
            data: data,
        })
            .then((response) => {
                console.log('notifierComponent alertProductClick API call success data: ', response.data);
                let res = response.data;

                if (res.code === 200) {
                    if (response.data.responseData.data.length == 0) {
                        NotificationManager({ message: 'No records found.', type: 4, });
                        _this.props.toggleSpinner(false);
                        return;
                    }

                    let product2GridData = _this.state.product2GridDataState;
                    product2GridData['data'] = response.data.responseData.data;
                    product2GridData['columnDetails'] = response.data.responseData.columnDetails;
                    product2GridData['recordCount'] = response.data.responseData.data.length;
                    product2GridData['fromAlerts'] = true;
                    product2GridData['priorityAlertsForSort'] = {
                        'filters': data,
                        'url': url,
                    };

                    this.props.setCurrentPage(pagenames);

                    _this.props.addProduct2GridData(product2GridData);
                    _this.props.actionPerformedFrom({fromAlerts: true});
                    _this.props.toggleSpinner(false);

                    return;
                }
            })
            .catch(error => {
                console.log('notifierComponent alertProductClick API call failed', error);
            });
    }

    toggleNotification() {
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen
        }));
    }

    render() {
        let list = {}, listCount;

        if (this.state.isAlertsLoaded) {
            list = this.getParsedResponse(this.state.response);
        }

        return (
            <div className="notification-holder">
                <div className="notification-head">
                    <a>
                        <span className="notification-count" onClick={this.toggleNotification}>
                            {list.count}
                        </span>
                        <i className="icon-notification" onClick={this.toggleNotification}></i>
                    </a>
                </div>

                { this.state.isAlertsLoaded &&
                <div className={`notification-body ${(this.state.isOpen) ? 'open' : ''} `}>
                    <ul className="tab-selector">
                        { list.personal.length > 0 &&
                        <li
                            className={`personal-tab ${(this.state.tabActive === "personal") ? 'active' : ''}`}
                            onClick={() => this.switchTab('personal')}
                        >
                            Personal Alerts ({list.personalCount})
                        </li>
                        }
                        { list.general.length > 0 &&
                        <li
                            className={`general-tab ${(this.state.tabActive === "general") ? 'active' : ''} `}
                            onClick={() => this.switchTab('general')}
                        >
                            Alerts ({list.generalCount})
                        </li>
                        }
                    </ul>
                    <div className="tab-holder scroll">
                        { list.personal.length > 0 &&
                        <div className={`p-tab ${(this.state.tabActive === "personal") ? 'active' : ''} `}>
                            <AlertLister list={list.personal} onClick={this.alertProductClick} />
                        </div>
                        }
                        { list.general.length > 0 &&
                        <div className={`g-tab ${(this.state.tabActive === "general") ? 'active' : ''} `}>
                            <AlertLister list={list.general} onClick={this.alertProductClick} />
                        </div>
                        }
                    </div>
                </div>
                }

            </div>
        );
    }
}

const mapDispatchToprops = (dispatch) => {
    return {
        addGridData: (data) => {
            dispatch(actions.addGridData(data));
        },
        addColumnDetails: (data) => {
            dispatch(actions.addColumnDetails(data));
        },
        addGridDropDowns: (data) => {
            dispatch(actions.addGridDropDowns(data));
        },
        gridDataChanged: () => {
            dispatch(actions.gridDataChanged());
        },
        addAlertsData: (data) => {
            dispatch(addAlertsData(data));
        },

        filterDataChanged: () => {
            dispatch(actions.filterDataChanged());
        },

        addProduct2GridData: (data) => {
            dispatch(product2_actions.addProduct2GridData(data));
        },

        actionPerformedFrom: (data) => {
            dispatch(product2_actions.actionPerformedFrom(data));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        appliedFilters: state.appliedFilters,
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToprops)(Notifier)
);

function AlertLister(props) {
    let sortList = props.list;

    console.log("AlertLister Check:", props);

    sortList.sort(function (a, b) {
        return a.priority - b.priority;
    });

    return (
        <ul>
            { sortList.map((item, index) => (
                <li key={index} onClick={() => { props.onClick(item); }} >
                    {item.title}
                    <span className="alertItemVal right" >{item.value}</span>
                </li>
            ))}
        </ul>
    );
}
