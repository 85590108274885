import React from 'react';

import FieldPermissionsComponent from './fieldPermissionsComponent';
import NotificationManager from '../common/components/notification';
import axios from 'axios';

import AlertsDataBuilder from './helperComponents/dataBuilder';
import AdminServices from '../admin/admin-services/admin-services';

class FieldPermissionsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isModalOpen: false,
            currentModal: '',
            checkedFieldData: [],
            isDeletable: false,
            deleteFieldsList: [],
            isGridModified: false,
            isDeleteModalOpen: false,
            activeRow: 0,
            rowData: {},
            oldData: [],
            currentSort: null,
            gridData: null,
        };

        this.gridHeight = '';
        this.modalOpen = this.modalOpen.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.deleteModalClose = this.deleteModalClose.bind(this);
        this.addNewField = this.addNewField.bind(this);
        this.sendData = this.sendData.bind(this);
        this.cloneTheKeys = this.cloneTheKeys.bind(this);
        this.deleteFields = this.deleteFields.bind(this);
        this.addFieldRow = this.addFieldRow.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.deleteConfirmed = this.deleteConfirmed.bind(this);
        this.getFieldPermission = this.getFieldPermission.bind(this);
    }


    componentDidMount() {
        this.getFieldPermission();
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.state.currentSort && prevState.isModalOpen !== this.state.currentModal) {
            let sortMode = this.state.currentSort;
            this.fieldPermissionsGrid.sortby(sortMode.column, sortMode.order);
        }

        if (prevState.isLoaded !== this.state.isLoaded) {
            const _this = this;
            if (this.state.isLoaded) {
                // Row select - Checkbox
                // this.fieldPermissionsGrid.on('rowselect', function (event) {
                //   const rowIndexs = _this.fieldPermissionsGrid.getselectedrowindexes();
                //   const newCheckedFieldData = [];
                //   const checkedRowData = [];
                //   let noDeletableCount = 0;
                //   let isdeleted = 0;

                //   rowIndexs.map((val, key) => {
                //     if (!_this.fieldPermissionsGrid.getrowdata(val).dynamic) {
                //       noDeletableCount++;
                //     }
                //     const fpId = _this.fieldPermissionsGrid.getrowdata(val).fpId;
                //     if (_this.fieldPermissionsGrid.getrowdata(val).dynamic && newCheckedFieldData.indexOf(fpId) < 0) {
                //       newCheckedFieldData.push(fpId);
                //       checkedRowData.push(_this.fieldPermissionsGrid.getrowdata(val));
                //     }

                //     if (_this.fieldPermissionsGrid.getrowdata(val).deletedFlag) {
                //       isdeleted++;
                //     }
                //   });

                //   _this.setState({
                //     checkedFieldData: newCheckedFieldData,
                //     checkedRowData: checkedRowData,
                //     isDeletable: ((newCheckedFieldData.length > 0 && noDeletableCount <= 0) && isdeleted <= 0) ? true : false,
                //   });
                // });

                // Row Unselect - Checkbox
                // this.fieldPermissionsGrid.on('rowunselect', function (event) {
                //   const rowIndexs = _this.fieldPermissionsGrid.getselectedrowindexes();
                //   const newCheckedFieldData = [];
                //   let noDeletableCount = 0;
                //   let isdeleted = 0;
                //   rowIndexs.map((val, key) => {
                //     if (!_this.fieldPermissionsGrid.getrowdata(val).fieldName) {
                //       noDeletableCount++;
                //     }
                //     const fpId = _this.fieldPermissionsGrid.getrowdata(val).fpId;
                //     if (_this.fieldPermissionsGrid.getrowdata(val).dynamic && newCheckedFieldData.indexOf(fpId) < 0) {
                //       newCheckedFieldData.push(fpId);
                //       // newCheckedFieldData.splice(newCheckedFieldData.indexOf(uid), 1);
                //     }
                //     if (_this.fieldPermissionsGrid.getrowdata(val).deletedFlag) {
                //       isdeleted++;
                //     }
                //   });

                //   _this.setState({ checkedFieldData: newCheckedFieldData, isDeletable: ((newCheckedFieldData.length > 0 && noDeletableCount <= 0) && isdeleted <= 0) ? true : false });

                // });

                // row click
                // this.fieldPermissionsGrid.on('cellclick', function (event) {
                //   console.log('fieldPermissionsContainer cellClick: ', _this.fieldPermissionsGrid.getrowdata(event.args.rowindex));
                //   const args = event.args;
                //   const index = args.rowindex;
                //   _this.setState({ activeRow: index });
                //   if (args.datafield === 'displayName' && !args.row.bounddata.deletedFlag) {
                //     const rowindex = args.rowindex;
                //     // let rowdata = Object.assign({}, _this.fieldPermissionsGrid.getrowdata(rowindex));
                //     _this.rowdata = _this.fieldPermissionsGrid.getrowdata(rowindex);
                //     _this.setState({ currentModal: 'edit', rowData: _this.rowdata });
                //     _this.modalOpen();
                //   }
                // });

                // this.fieldPermissionsGrid.on('cellbeginedit', function (event) {
                //   const args = event.args;
                //   if (args.row.deletedFlag) {
                //     const rowindex = args.rowindex;
                //     _this.fieldPermissionsGrid.endcelledit(rowindex, args.datafield, true);
                //   }
                // });


                // this.fieldPermissionsGrid.on('cellvaluechanged', function (event) {
                //   if (event.args.datafield === 'displayName' || event.args.datafield === 'width') {
                //     return false;
                //   }
                //   _this.setState({ isGridModified: true });
                // });
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        console.log('shouldComponentUpdate test', nextProps, nextState, this.state);
        if (nextState.gridData != this.state.gridData) {
            return true;
        } else if(nextState.isModalOpen != this.state.isModalOpen) {
            return true;
        } else {
            return false;
        }
    }

    onRowselect(event) {
        const _this = this;
        const rowIndexs = _this.fieldPermissionsGrid.getselectedrowindexes();
        const newCheckedFieldData = [];
        const checkedRowData = [];
        let noDeletableCount = 0;
        let isdeleted = 0;

        rowIndexs.map((val, key) => {
            if (!_this.fieldPermissionsGrid.getrowdata(val).dynamic) {
                noDeletableCount++;
            }
            const fpId = _this.fieldPermissionsGrid.getrowdata(val).fpId;
            if (_this.fieldPermissionsGrid.getrowdata(val).dynamic && newCheckedFieldData.indexOf(fpId) < 0) {
                newCheckedFieldData.push(fpId);
                checkedRowData.push(_this.fieldPermissionsGrid.getrowdata(val));
            }

            if (_this.fieldPermissionsGrid.getrowdata(val).deletedFlag) {
                isdeleted++;
            }
        });

        _this.setState({
            checkedFieldData: newCheckedFieldData,
            checkedRowData: checkedRowData,
            isDeletable: ((newCheckedFieldData.length > 0 && noDeletableCount <= 0) && isdeleted <= 0) ? true : false,
        });
    }

    onRowunselect(event) {
        const _this = this;
        const rowIndexs = _this.fieldPermissionsGrid.getselectedrowindexes();
        const newCheckedFieldData = [];
        let noDeletableCount = 0;
        let isdeleted = 0;
        rowIndexs.map((val, key) => {
            if (!_this.fieldPermissionsGrid.getrowdata(val).fieldName) {
                noDeletableCount++;
            }
            const fpId = _this.fieldPermissionsGrid.getrowdata(val).fpId;
            if (_this.fieldPermissionsGrid.getrowdata(val).dynamic && newCheckedFieldData.indexOf(fpId) < 0) {
                newCheckedFieldData.push(fpId);
                // newCheckedFieldData.splice(newCheckedFieldData.indexOf(uid), 1);
            }
            if (_this.fieldPermissionsGrid.getrowdata(val).deletedFlag) {
                isdeleted++;
            }
        });

        _this.setState({ checkedFieldData: newCheckedFieldData, isDeletable: ((newCheckedFieldData.length > 0 && noDeletableCount <= 0) && isdeleted <= 0) ? true : false });
    }

    onCelldoubleclick(event) {
        const _this = this;
        console.log('fieldPermissionsContainer onCelldoubleclick: ', event);

        let sortInfo = _this.fieldPermissionsGrid.getsortinformation();
        let getSort = null;
        if (sortInfo.sortdirection && sortInfo.sortdirection.ascending) {
            getSort = 'asc';
        }
        if (sortInfo.sortdirection && sortInfo.sortdirection.descending) {
            getSort = 'desc';
        }

        let currentSortModel = [];
        currentSortModel['order'] = getSort;
        currentSortModel['column'] = sortInfo.sortcolumn;

        const args = event.args;
        const index = args.rowindex;
        const data = event.args.row.bounddata;
        // _this.setState({ activeRow: index, currentSort: currentSortModel});
        if (args.datafield === 'displayName') {
            _this.setState({ currentModal: 'edit', rowData: data, activeRow: index, currentSort: currentSortModel });
            _this.modalOpen();
        }
    }

    onCellbeginedit(event) {
        const _this = this;
        console.log('fieldPermissionsContainer onCellbeginedit:', event);
        const args = event.args;
        if (args.datafield == 'displayName') {
            _this.fieldPermissionsGrid.endcelledit(args.rowindex, args.datafield, true);
        }
    // _this.setState({ isGridModified: true });
    }

    onCellvaluechanged(event) {
        console.log('fieldPermissionsContainer onCellvaluechanged:', event);
        const _this = this;
        const args = event.args;
        const datafield = args.datafield;
        const newvalue = args.newvalue;
        const oldvalue = args.oldvalue;
        const rowindex = args.rowindex;
        const rowdata = _this.fieldPermissionsGrid.getrowdata(rowindex);

        if (newvalue != oldvalue) {
            _this.fieldPermissionsGrid2.enableFooter();
            _this.fieldPermissionsGrid.setcellvalue(rowindex, datafield, newvalue);
            let oldData = this.state.oldData;
            if (datafield == 'order') {
                oldData.data.forEach((item, index) => {
                    if (rowdata.fieldName == item.fieldName) {
                        item.order = rowdata.order;
                    }
                });
            } else {
                oldData.data.forEach((item, index) => {
                    if (rowdata.fieldName == item.fieldName) {
                        item.permission[datafield] = newvalue[0];
                    }
                });
            }
            // _this.fieldPermissionsGrid.refresh();
        }
    }

    getFieldPermission() {
        const base = this;
        //fetching the field Permissions  list
        AdminServices.getFieldPermissions().then((response) => {
            let res = response;
            let currentUser = this.props.user;
            console.log('get field permission', res);

            if (currentUser.company.toLowerCase() != 'sweft') {
                res.data = res.data.filter(i => i.order <= 1000);
            }

            base.gridData = AlertsDataBuilder(res, 'fieldpermission');
            base.gridData.columns.map(function (data, i) {
                if ((data['text'] == 'Fields') || (data['text'] == 'Ordering')) {
                    data['pinned'] = true;
                }
                return data;
            });
            base.parseKeys = {
                normalKeys: res.normalKeys,
                stringKeys: res.stringKeys,
                parseField: res.parseField,
            };

            let data = base.gridData;
            base.setState({ isLoaded: true, oldData: res, gridData: data });
        }).catch((error) => {
            console.log('FieldPermissions getFieldPermission API call failed', error);
        });
    }

    modalOpen() {
        this.setState({ isModalOpen: true });
    }

    modalClose() {
        this.setState({ isModalOpen: false });
    }

    addNewField() {
        this.setState({ currentModal: 'add' });
        this.modalOpen();
    }

    /**
   * returns object with cloned properties.
   * @param {array} keys - array of strings (object keys)
   * @param {object} cloneFrom - object to clone from
   * @param {boolean} modify - true or flase modify is used to replace the
   * @return {*} returns keys
   */
    cloneTheKeys(keys, cloneFrom, modify) {
        const obj = {};
        keys.forEach((item, index) => {
            if (cloneFrom.hasOwnProperty(item)) {
                if (modify) {
                    obj[item] = abbreviator(cloneFrom[item]);
                } else {
                    obj[item] = cloneFrom[item];
                }
            }
        });

        function abbreviator(val) {
            if (val === 'Read') {
                return 'R';
            } else if (val === 'Write') {
                return 'W';
            } else if (val === 'Hide') {
                return 'H';
            } else {
                return val;
            }
        }

        return obj;
    }

    sendData(data) {
        const _this = this;
        const editedData = this.fieldPermissionsGrid.getrows();

        // parsedData has array of objects in backend required format
        const parsedData = [];

        editedData.forEach((item, index) => {
            const normalProps = _this.cloneTheKeys(this.parseKeys.normalKeys, item);
            const stringProps = _this.cloneTheKeys(this.parseKeys.stringKeys, item, true);
            const obj = normalProps;
            //make the roleName dynamic, get it in backend response
            obj[this.parseKeys.parseField] = JSON.stringify(stringProps);

            parsedData.push(obj);
        });

        AdminServices.updateFieldPermissions(parsedData).then((response) => {
            const res = response;
            if (res.status) {
                NotificationManager({
                    message: res.responseMessage,
                    type: 1,
                });
                _this.modalClose();
                _this.fieldPermissionsGrid2.disableFooter();
            } else {
                NotificationManager({
                    message: res.responseMessage,
                    type: 4,
                });
            }
        }).catch((error) => {
            console.log('FieldPermissions sendData API call failed', error);
            NotificationManager({
                message: 'failed to update',
                type: 2,
            });
        });
    }


    addFieldRow(data) {
        const roleObj = JSON.parse(data.permission);
        const objectData = {
            type: data.type, width: data.width,
            format: data.format, fpId: data.fpId,
            fieldName: data.fieldName,
            fieldvalues: data.fieldvalues,
            dynamic: data.dynamic,
            deletedFlag: data.deletedFlag,
            order: data.order,
            displayName: data.displayName,
        };
        Object.keys(roleObj).forEach(function (k) {
            objectData[k] = roleObj[k];
        });
        this.fieldPermissionsGrid.addrow(null, objectData);
    }

    parsedDataToJSON(obj) {
        const data = [].concat(obj);
        const parseableFieldName = 'permission';

        const newData = data.map((item, index) => {
            item[parseableFieldName] = JSON.parse(item[parseableFieldName]);
            return item;
        });

        newData.map((val, idx) => {
            Object.keys(val.permission).map((role) => {
                newData[idx][role] = newData[idx].permission[role];
            });
        });

        return newData;
    }

    deleteFields() {
        this.setState({ isDeleteModalOpen: true });
    }

    deleteModalClose(key) {
        this.setState({ isDeleteModalOpen: false });
        const _this = this;
        switch (key) {
            case 'delete':
                _this.deleteConfirmed();
                break;
            default: break;
        }
    }

    deleteConfirmed() {
        const _this = this;

        // parsedData has array of objects in backend required format
        const parsedData = [];

        this.state.checkedRowData.forEach((item, index) => {
            const normalProps = _this.cloneTheKeys(this.parseKeys.normalKeys, item);
            const stringProps = _this.cloneTheKeys(this.parseKeys.stringKeys, item, true);
            const obj = normalProps;
            //make the roleName dynamic, get it in backend response
            obj[this.parseKeys.parseField] = JSON.stringify(stringProps);

            parsedData.push(obj);
        });


        AdminServices.deleteFieldRoles(parsedData).then((response) => {
            const res = response.data;
            if (res.status) {
                NotificationManager({ message: res.responseMessage, type: 1 });
                const parsedData = _this.parsedDataToJSON(res.data);
                parsedData.map((val) => {
                    // _this.fieldPermissionsGrid.deleterow(val);
                    //_this.fieldPermissionsGrid.updaterow(val.fpId, val);
                    const getrowboundindex = _this.fieldPermissionsGrid.getrowboundindex(val.fpId);
                    _this.fieldPermissionsGrid.deleterow(val.fpId);
                });
                _this.fieldPermissionsGrid.clearselection();
                _this.fieldPermissionsGrid.removesort();
                _this.setState({ isDeletable: false, checkedFieldData: [], checkedRowData: [] });
            }
        }).catch((error) => {
            console.log('FieldPermissions sendData API call failed', error);
            NotificationManager({ message: error.responseMessage, type: 4 });
        });
    }

    cancelChanges() {
        this.setState({ isLoaded: false, isGridModified: false });
        this.getFieldPermission();
    }

    render() {
        const footerHeight = 50;
        const headerHeight = 64;
        const filterStrip = 34;
        let gridHeightSet = window.innerHeight - (50 + footerHeight + headerHeight) ;
        $('.page-title a').text("ADMIN: Field Permission");

        return (
            <FieldPermissionsComponent
                state={this.state}
                props={this.props}

                fieldPermissionsGridRef={(grid) => {
                    this.fieldPermissionsGrid = grid;
                }}

                fieldPermissionsGridRef2={(grid2) => {
                    this.fieldPermissionsGrid2 = grid2;
                }}

                gridData={this.gridData}
                gridHeight={gridHeightSet}
                modalClose={this.modalClose}
                addFieldRow={this.addFieldRow}
                cloneTheKeys={this.cloneTheKeys}
                parseKeys={this.parseKeys}
                fieldPermissionsGrid={this.fieldPermissionsGrid}

                onCellbeginedit={this.onCellbeginedit.bind(this)}
                onRowselect={this.onRowselect.bind(this)}
                onRowunselect={this.onRowunselect.bind(this)}
                onCellvaluechanged={this.onCellvaluechanged.bind(this)}
                onCelldoubleclick={this.onCelldoubleclick.bind(this)}

                addNewField={this.addNewField}
                sendData={this.sendData}
                cancelChanges={this.cancelChanges}
                deleteFields={this.deleteFields}

                deleteModalClose={this.deleteModalClose}

            />
        );
    }
}


export default FieldPermissionsContainer;

