
import React from 'react';
import { CustomDropdownEditor } from './customDropdownEditor';

export class AttrDropdownEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedVal: '',
            attrDropdownValues: null,
            width: null,
        };
        // this.handleChange = this.handleChange.bind(this);
    }


    componentWillMount() {
        const _this = this;
        const cellWidth = this.props.eGridCell.style.width;
        const attrDropdownValues = this.props.getAttrDropdowns();
        let setDropdownValues;

        if (_this.props.column.colId == 'attr1') {
            setDropdownValues = attrDropdownValues.attr1;
        }
        if (_this.props.column.colId == 'attr2') {
            setDropdownValues = attrDropdownValues.attr2;
        }
        if (_this.props.column.colId == 'attr3') {
            setDropdownValues = attrDropdownValues.attr3;
        }

        // Object.keys(attrDropdownValues).forEach(function(key) {
        //     // console.log(key, attrDropdownValues[key]);
        //     if (_this.props.column.colId == 'attr1' && key == 'attr1') {
        //         setDropdownValues = attrDropdownValues[key];
        //         console.log('AttrDropdownEditor Dropdown component 1', attrDropdownValues[key]);
        //     }
        // });

        console.log('AttrDropdownEditor Dropdown component', this.props, this.state, attrDropdownValues);
        this.setState({ selectedVal: this.props.value, width: cellWidth, attrDropdownValues: setDropdownValues});
    }

    componentWillUnmount() {
        this.props.api.stopEditing();
        this.props.api.tabToNextCell();
    }

    isPopup() {
        return true;
    }

    getValue() {
        // console.log('getValue', this.state.selectedVal);
        return this.state.selectedVal;
    }

    changeSelectedValue(value) {
        this.setState({
            selectedVal: value,
        }, () => this.stopEditing());
    }

    stopEditing() {
        this.props.api.stopEditing();
    }

    render() {
        return (
            <div>
                <CustomDropdownEditor
                    width={this.state.width}
                    selectedVal={this.state.selectedVal}
                    attrDropdownValues={this.state.attrDropdownValues}
                    changeSelectedValue={this.changeSelectedValue.bind(this)}
                    {...this.props}
                />
            </div>
        );
    }
}

//////////////////////////////////////////////////////////////////////////////////////


export class AttrDropdownRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        console.log('AttrDropdownRenderer render check', this.props);

        return (
            <div>
                {this.props.node.data ?
                    <div className='ag-dropdown-custom'> {this.props.value}<div className='ag-icon-expand-grid'></div></div>
                    :
                    <div className='ag-dropdown-custom'><div className='ag-icon-expand-grid'></div></div>}
            </div>

        );
    }
}


