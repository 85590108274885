import React from 'react';

/**
 * used to render the uppercase values.
 */
export class UpperCaseRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (
            <div>
                {this.props.value}
            </div>
        );
    }
}

/**
 * used to edit the uppercase values.
 */
export class UpperCaseEditor extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            selectedVal: props.node.data[props.column.colId] || '',
        };
    }

    isPopup() {
        return false;
    }

    isCancelBeforeStart() { }

    isCancelAfterEnd() { }

    afterGuiAttached() {
        const eInput = this.refs.input;
        eInput.focus();

        this.props.api.startEditingCell({
            'rowIndex': this.props.rowIndex,
            'colKey': this.props.column.colId,
        });
    }

    getValue() {
        return this.state.selectedVal;
    }

    handleOnChange(e) {
        let upperCase = e.target.value.toUpperCase();

        if (e.target.value) {
            this.setState({ selectedVal: upperCase });
        } else {
            this.setState({ selectedVal: '' });
        }
    }

    render() {
        const maxLength = this.props.column.colDef.maxLength;

        return (
            <div>
                <input
                    ref="input"
                    style={{ width: '100%', height: '100%', textTransform: 'uppercase'}}
                    type={'text'}
                    onChange={this.handleOnChange.bind(this)}
                    defaultValue={this.state.selectedVal}
                    maxLength={maxLength}
                />
            </div>
        );
    }
}
