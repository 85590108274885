import React from 'react';
import Modal from '../../common/modals/commonModal';
import CancelEditModal from './cancelEditModal';
import axios from 'axios';
import NotificationManager from '../../common/components/notification';
import AdminServices from '../../admin/admin-services/admin-services';

class UserGroupEditModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            group: JSON.parse(props.groupEditPopupData) || {},
            isModified: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.updateUserGroup = this.updateUserGroup.bind(this);
        this.modalClose = this.modalClose.bind(this);
    }

    /**
     * Method - To open confirm modal when user clicks cancel and close after the changes has been made
     */
    openModalCancel() {
        if (this.state.isModified) {
            this.setState({ isModalOpen: true });
        } else {
            this.props.modalClose();
        }
    }

    /**
     * To close the confirmation modal
     * @param {string} key - save-will update user group and close the modals, no-will close the modals without update group
     */
    modalClose(key) {
        this.setState({ isModalOpen: false });

        switch (key) {
            case 'save':
                this.updateUserGroup();
                break;

            case 'no':
                this.props.modalClose();
                break;
        }
    }

    /**
     * Checks input values
     * @param {*} event
     */
    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (value.trim() === '' || value.trim() === undefined) {
            target.className += ' invalid';
        } else {
            target.classList.remove('invalid');
        }

        const {group} = this.state;
        const newGroup = group;

        const regex = /^[a-z0-9 ]+$/i;

        if (regex.test(value) || value === '') {
            newGroup.title = value;
        }

        this.setState({
            group: newGroup,
            isModified: true
        });
    }

    /**
     * Toggles checkboxes
     * @param {*} key
     */
    toggleCheckbox(key) {
        const { group } = this.state;
        const newGroup = group;

        if (newGroup[key].status === 'V') {
            newGroup[key].status = 'NA';
        } else {
            newGroup[key].status = 'V';
        }

        this.setState({
            group: newGroup,
            isModified: true
        });
    }

    /**
     * Toggles radio buttons
     * @param {*} key
     */
    toggleRadio(key) {
        const { group } = this.state;
        const newGroup = group;

        newGroup['Default view'] = key;

        this.setState({
            group: newGroup,
            isModified: true
        });
    }

    /**
     * Calls backend to save user group
     * @param {*} event
     */
    updateUserGroup(event) {
        const _this = this;
        const {group} = this.state;

        const newGroup = group;

        const groupEditPopupData = _this.props.parseForBackend([newGroup], _this.props.keysToConvert);

        // Validation
        let flag = 0;
        const regex = /^[A-Za-z0-9']+(\s[A-Za-z0-9']+)*$/i;

        if (newGroup.title === '' || newGroup.title.trim() === '') {
            flag++;

            this.setState({
                isDirty: true,
                errorMessage: 'Group title should not be empty.'
            });
        } else {
            if (newGroup.defaultView === '') {
                flag++;

                this.setState({
                    isDirty: true,
                    errorMessage: 'Atleast one ‘View’ needs to be selected for the user group.'
                });
            } else {
                this.setState({ isDirty: false });

                if (!newGroup.title.match(regex)) {
                    newGroup.title = newGroup.title.trim();
                    newGroup.title = newGroup.title.replace(/\s\s+/g, ' ');
                }
            }
        }

        if (flag === 0) {
            const req = groupEditPopupData;

            AdminServices.updateGroupPermissionList(req).then((response) => {
                const data = response;

                if (data.status) {
                    _this.props.modalClose();
                    _this.props.updateGridRow(newGroup); // Update grid row

                    NotificationManager({
                        message: 'User Group has been updated successfully!!!',
                        type: 1,
                    });
                } else {
                    _this.setState({
                        isDirty: true,
                        errorMessage: data.responseMessage
                    });
                }
            }).catch((error) => {
                console.log('UserGroup updateUserGroup API call failed', error);

                NotificationManager({
                    message: 'Error, Service error ', error,
                    type: 4,
                });
            });
        }
    }

    render() {
        console.log('Admin userGroupEditModal render: ', this.state, this.props.keysToConvert);
        const {group} = this.state;

        let filtered = this.props.keysToConvert.filter(
            (i) => ['creative', 'marketing', 'publication', 'product', 'samples', 'copywriting', 'user'].includes(i)
        );

        const views = filtered.map((val, index) => {
            return (
                <div className="row" key={index}>
                    <div className="col-4">
                        <label className="form-label" title={val}>{val}:</label>
                    </div>
                    <div className="col-4">
                        <label className="form-check-label">
                            {
                                (group[val] && group[val].status === 'V') ?
                                    <input type="checkbox" className="form-control-check"
                                        disabled={(val === group['Default view']) ? true : false} id={val} name={val} checked
                                        onChange={() => this.toggleCheckbox(val)}></input>
                                    : <input type="checkbox" className="form-control-check" id={val} name={val} onChange={() => this.toggleCheckbox(val)}></input>
                            }
                            <span htmlFor={val} className="from-checkbox"></span>
                        </label>
                    </div>

                    <div className="col-4">
                        <label className="form-radio-label">
                            {
                                (val === group['Default view']) ?
                                    <input type="radio" name="default" className="form-control-radio"
                                        checked={(val === group['Default view']) ? true : false} onChange={() => this.toggleRadio(val)}></input>
                                    : <input type="radio" name="default" disabled={(group[val] && group[val].status === 'V') ? false : true }
                                        checked={(val === group['Default view']) ? true : false} className="form-control-radio" onChange={() => this.toggleRadio(val)}></input>
                            }
                        </label>
                    </div>

                </div>
            );
        });

        return (
            <Modal isOpen={this.props.isOpen}>
                <div className="modal-header">
                    Edit Group
                    <span className="close" onClick={() => this.openModalCancel()}>x</span>
                </div>
                <div className="modal-content">
                    <div className="add-user modal-form">
                        <form className="addNewUser" name="addNewUser">
                            <div className="form-container scroll">
                                <h4>Account Details</h4>
                                <hr/>
                                <div className="form-group">
                                    <label className="form-label">Group Title</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="title"
                                        value={this.state.group.title}
                                        onChange={this.handleChange}
                                        maxLength="50" />
                                </div>

                                <h4>User Group Permissions</h4>
                                <hr/>
                                <div className="row">
                                    <div className="col-4"><span className="form-subtitle">Permissions</span></div>
                                    <div className="col-4"><span className="form-subtitle">Visibility*</span></div>
                                    <div className="col-4"><span className="form-subtitle">Default*</span></div>
                                </div>
                                <div className="form-group">
                                    {views}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <input
                                    type="button"
                                    className="gray-button"
                                    value="Cancel"
                                    onClick={() => this.openModalCancel()} />
                                <input
                                    type="button"
                                    className="gray-button"
                                    value="Save"
                                    onClick={this.updateUserGroup} />
                            </div>
                            {(this.state.isDirty) &&
                            <span className="error-message">{this.state.errorMessage}</span>
                            }
                        </form>
                    </div>
                </div>
                <CancelEditModal
                    isOpen={this.state.isModalOpen}
                    modalClose={this.modalClose}
                />
            </Modal>
        );
    }
}

export default UserGroupEditModal;
