
import React from 'react';
import { CustomDropdownEditor } from './customDropdownEditor';

/**
 * renders the colors dropdown
 */
export class ColorDropdownPopupRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        if (this.props.node.data && this.props.node.data.color_attribute) {
            return (
                <div>
                    {this.props.node.data.color_attribute}
                </div>
            );
        } else {
            return (
                <div className="arrow downPos">
                </div>
            );
        }
    }
}

// //////////////////////////////////////////////////////////////////////////////////////////////////////


export class ColorDropdownPopupEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedVal: '',
            colorsList: props.getColorsList(),
            width: null,
        };
    }

    componentWillMount() {
        const colorsList = this.state.colorsList;
        const cellWidth = this.props.eGridCell.style.width;
        if (colorsList.indexOf('Create Variant') == -1) {
            colorsList.push('Create Variant');
        }
        this.setState({
            colorsList,
            selectedVal: this.props.node.data.color_attribute,
            width: cellWidth,
        });
        if (this.props.node.data.itemReadyToOrder) {
            this.props.api.stopEditing();
        } else if (this.props.node.data.dataLevel == 'PARENT') {
            this.props.api.stopEditing();
        }
    }

    componentWillUnmount() {
        this.props.api.stopEditing();
    }

    isPopup() {
        return true;
    }

    getValue() {
        return this.state.selectedVal;
    }

    changeSelectedValue(value) {
        this.setState({
            selectedVal: value,
        }, () => this.stopEditing());
    }

    stopEditing() {
        this.props.api.stopEditing();
    }

    render() {
        return (
            <div>
                <CustomDropdownEditor
                    width={this.state.width}
                    selectedVal={this.state.selectedVal}
                    colorsList={this.state.colorsList}
                    changeSelectedValue={this.changeSelectedValue.bind(this)}
                    {...this.props}
                />
            </div>
        );
    }
}
