import React, { Component } from 'react';
import CancelModal from '../../../modals/cancelModal.js';
import axios from 'axios';

import NotificationManager from '../../../common/components/notification';
import {CommonServices} from '../../../common/services/common-services';

class AddVendorForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vendor: {
                contactPerson: '',
                email: '',
                name: '',
                orgVendorId: '',
                phone: '',
                status: true,
                type: '',
            },
            validation: {
                contactPerson: false,
                email: true,
                name: true,
                orgVendorId: false,
                phone: false,
                status: false,
                type: false,
            },
            isDirty: false,
            errorMessage: '',
            isModalOpen: false,
            isModified: false,
        };

        this.openModalCancel = this.openModalCancel.bind(this);
        this.modalClose = this.modalClose.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.saveVendor = this.saveVendor.bind(this);
    }

    // modalOpen() {
    // 	this.setState({isModalOpen: true});
    // }

    /**
		 * Closes the popup
		 * @param {string} key
		 */
    modalClose(key) {
        this.setState({isModalOpen: false});
        switch (key) {
            case 'updateUser':
                this.saveVendor();
                break;
            case 'no':
                this.props.modalClose();
                break;
        }
    }

    /**
		 * Opens the canncel modal
		 */
    openModalCancel() {
        if (this.state.isModified) {
            this.setState({isModalOpen: true});
        } else {
            this.props.modalClose();
        }
    }

    /**
		 * Input field event handler
		 * @param {*} event
		 */
    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (value.trim() === '' || value.trim() === undefined) {
            if (this.state.validation[name]) {
                target.className += ' invalid';
            }
        } else {
            target.classList.remove('invalid');
        }

        const {vendor} = this.state;
        const newVendor = vendor;

        if (name === 'phone' && isNaN(value)) {
        } else {
            newVendor[name] = value;
        }
        this.setState({vendor: newVendor, isModified: true});
    }

    /**
		 * Checks and validates email
		 * @param {string} email
         * @return {string} validated email
		 */
    validateEmail(email) {
        const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(email);
    }

    /**
		 * Calls backend to save the vendor
		 * @param {*} event
		 */
    saveVendor(event) {
        // event.preventDefault();
        const _this = this;
        const {vendor} = this.state;
        const newVendor = vendor;
        // validation
        let flag = 0;
        let errorMessage = '*Mandatory fields marked with an asterisk are missing.';

        if (this.state.validation.name && newVendor.name === '') {
            flag++;
        } else {
            this.setState({isDirty: false});
        }

        if (this.state.validation.email && newVendor.email === '') {
            flag++;
        } else {
            if (this.validateEmail(this.state.vendor.email)) {
                this.setState({isDirty: false});
            } else {
                flag++;
                errorMessage = 'Invalid Email address.';
            }
        }

        if (this.state.validation.phone && newVendor.phone === '') {
            flag++;
        } else {
            this.setState({isDirty: false});
        }

        //
        if (flag === 0) {
            const req = JSON.stringify(newVendor);

            const headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json',
            };

            CommonServices.addVendor(req)
                .then( (response) => {
                    const res = response;

                    if (res.data) {
                        // window.newVendorList=window.newVendorList?window.newVendorList:[];
                        // window.newVendorListRes=window.newVendorListRes?window.newVendorListRes:[];
                        const newData = {
                            fieldId: res.data.id,
                            fieldName: newVendor.name,
                        };

                        const rowData = _this.props.rowData.data;
                        rowData['vendorId'] = newData.fieldId;
                        rowData['vendorName'] = newData.fieldName;

                        // let obj = _this.props.rowData;

                        // _this.props.gridApi.forEachNode(function(rowNode) {
                        // 	if(obj.rowIndex === rowNode.rowIndex) {
                        // 		var updated = Object.assign({}, rowNode.data);
                        // 		for (var key in updated) {
                        // 			if (updated.hasOwnProperty(key)) {
                        // 				updated[key] = obj[key] ? obj [key] : updated[key]
                        // 			}
                        // 		}

                        // 		rowNode.setData(updated);
                        // 	}
                        // });

                        // window.newVendorList.push(newData);
                        // window.newVendorListRes.push(newData);

                        // var rowNode = this.props.gridApi.getRowNode(this.props.clickedCell.rowIndex);

                        // rowNode.setDataValue(this.props.clickedCell.column.colId, newData.fieldName);

                        // _this.props.setCellValueAndSaveProduct(_this.props.productId,'vendorId',newData.fieldId,null);
                        _this.props.modalClose();
                        _this.props.rowUpdate(rowData, _this.props.rowData.rowIndex);
                        _this.props.rowData.setDataValue('vendorId', newData.fieldName);

                        NotificationManager({
                            message: res.responseMessage,
                            type: 1,
                        });
                    } else {
                        _this.setState({isDirty: true, errorMessage: res.responseMessage});
                        NotificationManager({
                            message: res.responseMessage,
                            type: 4,
                        });
                    }
                })
                .catch( (error) => {
                    console.log('productContainer helperComponents add-vendor API call failed', error.response);
                    _this.setState({isDirty: true});
                    // NotificationManager({
                    // 	message: 'Failed to add vendor.',
                    // 	type:5
                    // });
                });
        } else {
            this.setState({isDirty: true});
            NotificationManager({
                message: errorMessage,
                type: 4,
            });
        }

        // _this.props.gridApi.purgeServerSideCache();
    }

    render() {
        return (
            <div>
                <div className="modal-header">
					Add New Vendor
                    <span className="close" onClick={this.openModalCancel}>x</span>
                </div>
                <div className="modal-content">
                    <div className="add-vendor modal-form">
                        <form className="addNewVendor" name="addNewVendor">
                            <div className="form-container scroll">
                                <h4>Vendor Details</h4>
                                <hr/>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Org Vendor ID</label>
                                            <input type="text" className="form-control" name="orgVendorId" value={this.state.vendor.orgVendorId} onChange={this.handleChange} maxLength={30}></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Name*</label>
                                            <input type="text" className="form-control" name="name" value={this.state.vendor.name} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Email*</label>
                                            <input type="text" className="form-control" name="email" value={this.state.vendor.email} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Contact Person</label>
                                            <input type="text" className="form-control" name="contactPerson" value={this.state.vendor.contactPerson} onChange={this.handleChange} maxLength={30} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Phone</label>
                                            <input type="text" className="form-control" name="phone" value={this.state.vendor.phone} onChange={this.handleChange} maxLength="10" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <input type="button" className="gray-button" value="Cancel" onClick={this.openModalCancel} />
                                <input type="button" className="gray-button" value="Add Vendor" onClick={this.saveVendor} />
                            </div>
                            {(this.state.isDirty) ? <span className="error-message">{this.state.errorMessage}</span> : ''}
                        </form>
                    </div>
                </div>

                <CancelModal data={this.state.user} isOpen={this.state.isModalOpen} modalClose={this.modalClose}/>
            </div>
        );
    }
}


export default AddVendorForm;
