import React, { Component } from 'react';
import UserGroupModal from './userGroupModal';
import {connect} from 'react-redux';


class AdminFooter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cancelChanges: 'Cancel Changes',
            applyChanges: 'Apply Changes',
            addUserGroup: 'Add New Group',
            isModalOpen: false,
            modalName: '',
        };

        this.modalOpen = this.modalOpen.bind(this);
        this.modalClose = this.modalClose.bind(this);
    }

    /**
     * Opens the modal
     */
    modalOpen() {
        this.setState({ isModalOpen: true });
    }

    /**
     * Closes the modal
     */
    modalClose() {
        this.setState({ isModalOpen: false });
    }

    /**
     * Opens the modal
     * @param {*} target
     */
    triggerModalOpen(target) {
        this.setState({ modalName: target });
        this.modalOpen();
    }

    render() {
        const footerContent = (
            <div>
                <div className="footer-content">
                    <button className="add_group_btn gray-button" onClick={() => this.triggerModalOpen('add_new_group')}>{this.state.addUserGroup}</button>
                    <UserGroupModal
                        isOpen={this.state.isModalOpen}
                        modalClose={this.modalClose}
                        modalName={this.state.modalName}
                        addNewGroup={this.props.addNewGroup}
                        user={this.props.user}
                    />
                </div>
                <div className="footer-row-details">
                    <span className="row-count">{this.props.userCount} Total Rows</span>
                    <span className="row-selected"> | Selected: 0</span>
                </div>
                <div className="footer-content right">
                    <button className="cancel_changes gray-button" onClick={this.props.cancelChanges} disabled={!this.props.isModified}>{this.state.cancelChanges}</button>
                    <button className="apply_changes gray-button" onClick={this.props.applyChanges} disabled={!this.props.isModified}>{this.state.applyChanges}</button>
                </div>
            </div>
        );

        return (
            <div className="footer admin-footer">
                {footerContent}
            </div>
        );
    }
}

const mapSateToProps = (state) => {
    return {
        userView: state.userView,
    };
};

export default connect(mapSateToProps)(AdminFooter);
