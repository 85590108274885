import React, { Component } from 'react';
import CategoryGrid from './helperComponent/category-grid';

import ProductCategoryComponent from './productCategoryComponent';

class CategoryTab extends Component {
    constructor(props) {
        super(props);

        this.state = {
            catId: this.props.catId,
        };
    }

    /**
     * sets state for catID's
     * @param {*} nextProps
     */
    componentWillReceiveProps(nextProps) {
        if (nextProps.catId != this.props.catId) {
            this.setState({ catId: nextProps.catId });
        }
    }

    render() {
        $('.page-title a').text("ADMIN: Product Categories");

        return (
            <div>
                <ProductCategoryComponent
                    state={this.state}
                    props={this.props}
                />
            </div>
        );
    }
}

export default CategoryTab;
