import React from 'react';

export class DropdownRenderer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            list: props.getList() || [],
        };
    }

    componentDidMount() {
        let val = this.props.value;
        const list = this.state.list;

        for (let i = 0; i < list.lenth; i++) {
            if (list[i].id === val) {
                val = list[i].name;
                break;
            }
        }

        this.setState({ val });
    }

    render() {
        return (
            <div>
                {this.state.val}
            </div>
        );
    }
}

export class DropdownEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedVal: '',
            list: props.getList() || [],
        };
    }

    componentWillMount() {
        const cellWidth = this.props.eGridCell.style.width;
        this.setState({ selectedVal: this.props.value, width: cellWidth });
    }

    componentWillUnmount() {
        this.props.api.stopEditing();
        this.props.api.tabToNextCell();
    }

    isPopup() {
        return true;
    }

    getValue() {
        return this.state.selectedVal;
    }

    handleChange(event) {
        console.log('event target: ', event.target.value, event.target.innerHTML);

        if (this.props.column.colId === 'season') {
            this.setState({
                selectedVal: event.target.innerHTML,
            }, () => this.props.api.stopEditing());
        } else {
            this.setState({
                selectedVal: event.target.innerHTML,
            }, () => this.props.api.stopEditing());
        }
    }

    stopEditing() {
        this.props.api.stopEditing();
    }

    render() {
        console.log('dropdown: ', this.state, this.props);

        let dropdownListValues;

        if (this.props.column.colId === 'season') {
            dropdownListValues = this.state.list.map((obj, index) => {
                return <li className="agCustomDropdownItems" onClick={this.handleChange.bind(this)} key={index} value={obj.id}>{obj.name}</li>;
            });
        } else {
            dropdownListValues = this.state.list.map((val, index) => {
                return <li className="agCustomDropdownItems" onClick={this.handleChange.bind(this)} key={index} value={val}>{val}</li>;
            });
        }

        return (
            <div
                className="ag-DropdownSearchContainer"
                style={{ width: this.props.width }}
            >
                <ul style={{ width: '100%' }} className="agCustomDropdown">
                    {dropdownListValues}
                </ul>
            </div>
        );
    }
}
