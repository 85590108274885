import React from 'react';
import './admin.css';
import { connect } from 'react-redux';

import axios from 'axios';

import AdminComponent from './adminComponent';

class Admin extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;
    }

    componentWillMount() {
        localStorage.setItem('currentPage', 'admin');

        if (!this.props.leftDrawerPulled) {
            this.props.toggleLeftDrawer();
        }
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;

        if (this.props.leftDrawerPulled) {
            this.props.toggleLeftDrawer();
        }
    }

    toggleRightDrawer() {
        this.setState({ rightDrawerPulled: !this.state.rightDrawerPulled });
    }

    render() {
        console.log('Admin Container render: ', this.props);

        return (
            <AdminComponent
                state={this.state}
                props={this.props}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userView: state.userView,
    };
};

export default connect(mapStateToProps)(Admin);
