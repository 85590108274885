import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
// import Modal from '../common/modals/commonModal';
import VendorEditModal from './helperComponents/editVendorModal';
import AdminFooter from './helperComponents/adminFooter';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import JqxGrid from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';

const VendorComponent = ({
    state,
    props,

    vendorGridRef,
    gridData,
    gridHeight,
    editSettings,

    modalClose,
    vendorUpdateData,
    updateGridRow,

    removeGridRows,
    addVendorRow,
    onCelldoubleclick,
    reloadGrid,
    onRowselect,
    onRowunselect,
}) => {
    return (

        <div>
            <div className="pos-relative">
                {state.isLoaded &&
                    <div id="vendorGrid">
                        <JqxGrid
                            ref={vendorGridRef}
                            width={'99%'}
                            source={gridData.dataAdapter}
                            pageable={false}
                            checkboxes={true}
                            editable={'click'}
                            hierarchicalCheckboxes={true}
                            columnsResize={true}
                            columns={gridData.columns}
                            sortable={true}
                            autoRowHeight={false}
                            autoWidth={true}
                            height={gridHeight}
                            theme={'light'}
                            selectionmode={'checkbox'}
                            editSettings={editSettings}
                            columnsreorder={true}
                            columnsmenu={false}
                            onCelldoubleclick={onCelldoubleclick}
                            onRowselect = {onRowselect}
                            onRowunselect = {onRowunselect}

                        />
                        {(state.isModalOpen) &&
                            <VendorEditModal
                                user={props.user}
                                isOpen={state.isModalOpen}
                                modalClose={modalClose}
                                vendorUpdateData={vendorUpdateData}
                                updateGridRow={updateGridRow}
                                reloadGrid = {reloadGrid}
                            />
                        }
                    </div>
                }
                {state.isLoaded &&
                    <AdminFooter
                        vendorCount={state.vendorTotalCount}
                        selectedRows={state.selectedRowsCount}
                        checkedRows={state.checkedRows}
                        checkedVendorData={state.checkedVendorData}
                        removeRows={removeGridRows}
                        addVendorRow={addVendorRow}
                        user={props.user}
                        reloadGrid = {reloadGrid}
                    />
                }
            </div>
        </div>
    );
};

VendorComponent.propTypes = {
    state: PropTypes.object,
    props: PropTypes.object,

};

export default withRouter(VendorComponent);
