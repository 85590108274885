import React from 'react';
import Modal from '../../modals/common-modal.js';
import axios from 'axios';
import CreativeServices from '../services/creative-services.js';
import NotificationManager from '../../common/components/notification';

class ImageListerModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mainImageSrc:"",
            imageId: "",
            imageData: {},
            notes: "",
            isLoaded: false,
            isImageLoaded: false,
            isRejectOrApprove: '',
            isRejectedData: false,
            isSubmitDisabled: true,
            accessFlag: false,
            errorMessage: '',
            imageCheckedCount: 1, // total no. of checked image count
            userRole: null,
            approveRejectData: null,
        };

        this.updateMainViewer = this.updateMainViewer.bind(this);
        this.changeImageStatus = this.changeImageStatus.bind(this);
        this.changeImageCheckbox = this.changeImageCheckbox.bind(this);
        this.handleNotes = this.handleNotes.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        let _this = this;

        // Get image list
        let req = _this.props.parentId;
        let newObj = {};

        newObj["sweftId"] = req;

        const user = this.props.user.defaultRole.toLowerCase();

        CreativeServices.loadImages(newObj).then((response) => {
            console.log('creative image lister modal componentDidMount API call', response);

            let res = response;

            if (res.code == 200) {
                if (res.responseData.images.length > 0) {
                    let imageList = Object.assign([], res.responseData.images);

                    imageList.map((val, key) => { // For ChildData
                    });

                    _this.setState({
                        imageData: imageList,
                        mainImageSrc: imageList[0].finalImageURL,
                        isLoaded: true,
                        userRole: user,
                    });
                } else {
                    _this.setState({ errorMessage: 'No images found.' });
                }
            } else {
                _this.setState({ errorMessage: res.responseMessage });
            }
        }).catch((error) => {
            console.log('creative image lister modal componentDidMount API call failed', error);
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isRejectedData) {
            $('#rejectNotesField').focus();
        }
    }

    updateMainViewer(val) {
        let currentSrc = this.state.imageData[val].finalImageURL;

        this.setState({
            imageId: val,
            mainImageSrc: currentSrc,
            mainImageChecked: this.state.imageData[val].checked,
            isBuyerImg: this.state.imageData[val].isBuyer
        });
    }

    /**
	 * To update image status upon change the checkbox
	 */
    changeImageStatus(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const data = this.state.imageId;

        let {imageData} = this.state;
        let newImageData = Object.assign([], imageData);

        newImageData[data].checked = value;

        this.setState({ imageData: newImageData, mainImageChecked: value });

        // If all images are checked -
        let imageUncheckedCount = 0;

        imageData.map((val, key) => {
            if (!val.checked) {
                imageUncheckedCount++;
            }
        });

        if (imageUncheckedCount == 0) {
            this.setState({ isRejectedData: false });
        }

        this.setState({ imageCheckedCount: (newImageData.length === imageUncheckedCount) ? 0 : 1 });
    }

    changeImageCheckbox(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const data = $(target).data('value');

        let {imageData} = this.state;
        let newImageData = Object.assign([], imageData);

        newImageData[data].checked = value;

        this.setState({ imageData: newImageData, mainImageChecked: value });

        // If all images are checked -
        let imageUncheckedCount = 0;

        imageData.map((val, key) => {
            if (!val.checked) {
                imageUncheckedCount++;
            }
        });

        if (imageUncheckedCount == 0) {
            this.setState({ isRejectedData: false });
        }

        this.setState({ imageCheckedCount: (newImageData.length === imageUncheckedCount) ? 0 : 1 });
    }

    rejectOrApprove(key) {
        let _this = this;
        let data = {};

        data['sweftId'] = _this.props.parentId;

        if (key === 'reject') { // Reject
            data['isApproved'] = false;
            _this.setState({
                isRejectedData: true,
            });
        }

        if (key === 'approve') { // Approve
            data['isApproved'] = true;
        }

        _this.setState({
            approveRejectData: data,
        }, () => {
            if (key === 'approve') { // Approve
                this.updateService();
            }
        });
    }

    handleSubmit(event) {
        this.updateService();
    }

    updateService() {
        const _this = this;

        const req = this.state.approveRejectData;
        req['buyerNotes'] = this.state.notes;

        CreativeServices.buyerReview(req).then((response) => {
            console.log('Approve/Reject Image API call', response);

            const res = response;

            if (res.code == 200) {
                this.props.setRejectedDataOnGrid(res);

                NotificationManager({
                    message: res.message,
                    type: 1
                });
            } else {
                NotificationManager({
                    message: res.data.message,
                    type: 4
                });
            }
        }).catch((error) => {
            console.log('Approve/Reject Image API call Error', error);
        });

        this.props.modalClose();
    }

    handleNotes(event) {
        this.setState({notes: event.target.value});

        if (event.target.value.length > 0) {
            this.setState({ isSubmitDisabled: false });
        } else {
            this.setState({ isSubmitDisabled: true });
        }

        let {imageData} = this.state;
        let newImageData = Object.assign([], imageData);
        newImageData.map((val, key) => {
            newImageData[key].comments = event.target.value;
        });

        this.setState({imageData: newImageData});
    }

    render() {
        let imgGetter;

        const shotAngles = this.props.shotAngles;

        if (this.state.isLoaded && this.state.imageData) {
            imgGetter = this.state.imageData.map((item, index) => {
                let iName = item.finalImageURL.split('-');
                let showName;

                if (iName.length > 1) {
                    iName.map((j, k) => {
                        if (iName.length - 1 == k) {
                            if (shotAngles.includes(j)) {
                                showName = j;
                            } else {
                                showName = 'Uploaded';
                            }
                        }
                    });
                } else {
                    showName = 'Uploaded';
                }

                return (
                    <li className="thumbnails" key={index}>
                        <div className="angle-name">{showName}</div>
                        <div className={"thumbnail-image "}>
                            <img src={item.finalImageURL} alt='Image Missing' onClick={()=>(this.updateMainViewer(index))} />
                        </div>
                    </li>
                );
            });
        }

        let checkData = this.props.data && this.props.data.data && this.props.data.data.photographyDirection ? this.props.data.data.photographyDirection : '';
        let rejectParse = checkData.includes('"comment\":') ? JSON.parse(checkData) : '';
        let rejectedComments = rejectParse && rejectParse.length > 0 && rejectParse.filter(i => i.rejected);

        return (
            <Modal isOpen={this.props.isModalOpen}>
                <div className="modal-header">
                    Pictures
                    <span className="close" onClick={this.props.modalClose}>x</span>
                </div>
                <div className="modal-content product-pictures creative-image-popup">
                    { this.state.isLoaded &&
                    <div className="modal-form">
                        <div className="vertical-image-holder-for-creative">
                            <div className="rowHolder">
                                <div className="thumbnail-holder scroll">
                                    <ul>
                                        {imgGetter}
                                    </ul>
                                </div>
                                <div className="main-image">
                                    <div className={"image-container " + (this.state.isBuyerImg ? "" : "dam")}>
                                        <img src={this.state.mainImageSrc} alt='Image Missing' />
                                    </div>
                                    { this.props.data.data.stauts == 'APPROVED' &&
                                    <div className='image-approved'>Approved</div>
                                    }
                                    { this.props.data.data.stauts == 'REJECTED' &&
                                    <div>
                                        <div className='image-rejected'>Rejected</div>
                                        <div className='rejected-container'>
                                            { rejectedComments.length > 0 &&
                                            rejectedComments[0].comment
                                            }
                                        </div>
                                    </div>
                                    }
                                    { this.state.userRole == 'buyer' &&
                                    <div>
                                        <div className="images-action">
                                            <input type="button" className="gray-button" value="Reject" disabled={(this.state.imageCheckedCount == 0) ? true : false} onClick={()=>this.rejectOrApprove('reject')} />
                                            <input type="button" className="gray-button" value="Approve" disabled={this.props.data.data.approvedFlag ? true : false} onClick={()=>this.rejectOrApprove('approve')} />
                                        </div>

                                        { this.state.isRejectedData &&
                                        <div className="comment-section modal-form col-12">
                                            <div className="form-group">
                                                <label className="form-label">Review Notes</label>
                                                <textarea className="form-control texrarea-fld" id="rejectNotesField" value={this.state.notes} onChange={this.handleNotes}></textarea>
                                            </div>
                                            <div className="form-group text-center">
                                                <input type="button" className="gray-button" disabled={this.state.isSubmitDisabled} value="Submit" onClick={this.handleSubmit} />
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    { !this.state.isLoaded &&
                    <span className="error-message">{this.state.errorMessage}</span>
                    }
                </div>
            </Modal>
        );
    }
}

export default ImageListerModal;
