
import React, { Component } from 'react';

/**
 * renders the confirm dialog box with options to confirm or cancel
 */

class ConfirmDialog extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let dialog = <SaveChangesCancel {...this.props} />;
        let popuptitle = 'Save Changes';
        if (this.props.dialog === 'SubmitConfirm') {
            dialog = <SubmitConfirm {...this.props} />;
            popuptitle = 'Confirm Submit';
        }
        if (this.props.dialog === 'SampleConfirm') {
            dialog = <SampleConfirm {...this.props} />;
            popuptitle = 'Confirm Submit';
        }
        return (
            <div>
                <div className="modal-header">
                    {popuptitle}
                    <span className="close" onClick={this.props.modalClose}>x</span>
                </div>
                <div className="modal-content">
                    {dialog}
                </div>
            </div>
        );
    }
}

function SubmitConfirm(props) {
    return (
        <div>
            <div className="add-copy modal-form">
                <form className="confirmdelete" name="confirmdelete">
                    <div className="form-container scroll">
                        <p>Only {props.validationData.validcount} of {props.validationData.needcount} required fields were completed. Do you still want to submit for approval?</p>
                    </div>
                    <div className="modal-footer text-center">
                        <input type="button" className="gray-button" name="yesDelete" value="Yes" onClick={props.confirmApproveSubmit} />
                        <input type="button" className="gray-button" name="noDelete" value="No" onClick={props.modalClose} />
                    </div>
                </form>
            </div>
        </div>
    );
}
function SaveChangesCancel(props) {
    return (
        <div>
            <div className="add-copy modal-form">
                <form className="confirmdelete" name="confirmdelete">
                    <div className="form-container scroll">
                        <p>Do you want to save changes?</p>
                    </div>
                    <div className="modal-footer text-center">
                        <input type="button" className="gray-button" name="yesSaveChange" value="Yes" onClick={props.yesSaveChange} />
                        <input type="button" className="gray-button" name="noSaveChange" value="No" onClick={props.noSaveChange} />
                        <input type="button" className="gray-button" name="cancelSaveChange" value="Cancel" onClick={props.modalClose} />
                    </div>
                </form>
            </div>
        </div>
    );
}
function SampleConfirm(props) {
    return (
        <div>
            <div className="add-copy modal-form">
                <form className="confirmdelete" name="confirmdelete">
                    <div className="form-container scroll">
                        <p>Is Product "Swatch Only" type? </p>
                    </div>
                    <div className="modal-footer text-center">
                        <input type="button" className="gray-button" name="yes" value="Yes" onClick={props.yes} />
                        <input type="button" className="gray-button" name="no" value="No" onClick={props.modalClose} />
                    </div>
                </form>
            </div>
        </div>
    );
}
export default ConfirmDialog;
