export const addCreativeGridData = (data) => {
    return {
        type:"ADD_GRID_DETAILS",
        data
    };
};

export const addColumnDetails = (data) => {
    return {
        type:"ADD_COLUMN_DETAILS",
        data
    };
};

export const addGridDropDowns = (data) => {
    return {
        type:"ADD_GRID_DROP_DOWNS",
        data
    };
};

export const gridDataChanged = () => {
    return {
        type:"GRID_DATA_CHANGED"
    };
};

export const updateSelectedRows = (data) => {
    return {
        type:"UPDATE_SELECTED_ROWS",
        data
    };
};
