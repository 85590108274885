import React from 'react';

export class ImageRender extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            imageArray: props.value,
        };
    }

    render() {
        console.log('image renderer for creative', this.state);

        const imageObj = this.state.imageArray && this.state.imageArray[0] ? this.state.imageArray[0] : {};
        const imageUrl = imageObj.imageUrl ? imageObj.imageUrl : '';

        return (
            <div style={{ textAlign: 'center', height: '30px' }}>
                <img src={imageUrl} />
            </div>
        );
    }
}
