import React, { useState, useEffect } from 'react';
import LeftNav from '../leftnav/leftNavContainer';

import PageHeader from '../pageheader/pageHeaderContainer';
import LeftDrawer from '../leftDrawer/leftDrawerContainer';
import Dashboard from '../dashboard/dashboardContainer';
import Product from '../product/productContainer';
import Marketing from '../marketing/marketingContainer';
import Creative from '../creative/creativeContainer';
import Publication from '../publication/publicationContainer';
import Samples from '../samples/samplesContainer';
import Product2 from '../product2/product2Container';
import LoginPage from '../login/loginContainer';
import Admin from '../admin/adminContainer';
import Copywriting from '../copywriting/copywritingContainer';
import MobileScanAction from './mobileScanActions';

import RightDrawer from '../rightDrawer/rightDrawerContainer';

import { RingLoader } from 'react-spinners';

import './layout.css';

import { Route, Switch, Redirect } from 'react-router-dom';

import ErrorBoundryComponent from '../common/error-boundry-component';
import MobileMenu from './mobileMenu';

const Layout = function (props) {
    // Creating a new state Object to simulate how state was being handled before, and simplify refactor
    const [state, setActualState] = useState({
        leftDrawerPulled: false,
        rightDrawerPulled: false,
        spinner: false,
        notification: false,
        currentPage: props.location?.pathname ? props.location.pathname.split("/")[1] : 'dashboard',
        pageChange: false,
    });
    const currentUser = JSON.parse(localStorage.getItem('user'));
    const [user, setUser] = useState(currentUser);
    const [pageName, setPageName] = useState(false);
    const [defaultRedirect, setDefaultRedirect] = useState(null);

    // This wrapper helps change the state, keeping the same name to rewrite less, and so is easy to understand its use
    // Using the wrapper to simulate being able to intercept state changes
    const setState = (attrs) => {
        if (state.currentPage != attrs.currentPage && state.currentPage === 'publication' && !state.rightDrawerPulled) {
            attrs.rightDrawerPulled = true;
        }

        setActualState(prevState => {
            return ({
                ...prevState,
                ...attrs,
            });
        });
    };

    // Run only once the first time it renders
    useEffect(() => {
        props.history?.listen((data) => {
            setState({ currentPage: data.pathname.replace("/", "") });
        });
    }, []);

    // Run every time the search query changes
    useEffect(() => {
        if (props.location.search.indexOf('publication') != -1) {
            setState({ currentPage: 'publication' });
        }
        if (props.location?.pathname === "/" && props.location?.search) {
            let queryParam = decodeURIComponent(props.location.search);
            setPageName(queryParam.split("/")[1]);
            setDefaultRedirect(<Redirect to={"/" + pageName} />);
        } else {
            setDefaultRedirect(<Redirect to="/dashboard" />);
        }
    }, [props.location.pathname, props.location.search]);

    // run every time that spinner or current page changes to update the UI to match the state
    useEffect(() => {
        if (state.spinner === true) {
            $('.css-jxiqlq').children().css({
                'opacity': '0.6',
            });
        }

        $('.user-details').hover(function () {
            $('.sidenav').addClass('sidenavFix');
        }, function () {
            $('.sidenav').removeClass('sidenavFix');
        });

        const leftDrawCheck = state.leftDrawerPulled;

        if (state.currentPage == 'product2') {
            if (leftDrawCheck == true) {
                // @TODO toggle classes with react instead
                $('.notification').removeClass('notificationPosFixProd');
                $('.notification').addClass('notificationPosFixProd2');
            } else {
                $('.notification').removeClass('notificationPosFixProd2');
                $('.notification').addClass('notificationPosFixProd');
            }
        }

        if (state.currentPage == 'admin') {
            if (leftDrawCheck == false) {
                $('.notification').removeClass('notificationPosFix');
                $('.notification').addClass('notificationPosFix2');
            } else {
                $('.notification').removeClass('notificationPosFix2');
                $('.notification').addClass('notificationPosFix');
            }
        }
    }, [state.currentPage, state.spinner]);


    const toggleSpinner = (flag) => {
        setState({ spinner: flag });
    };

    const toggleNotification = () => {
        setState({ notification: !state.notification });
    };

    const toggleLeftDrawer = () => {
        setState({ leftDrawerPulled: !state.leftDrawerPulled });
        setState({ rightDrawerPulled: false });

        // added to resize the grid everytime there is a toggle
        setTimeout(() => {
            $('.jqx-grid').trigger('resize');
        }, 300);
    };

    const toggleRightDrawer = () => {
        console.info('Toggling right drawer');
        setState({ rightDrawerPulled: !state.rightDrawerPulled });
        setState({ leftDrawerPulled: false });

        // added to resize the grid everytime there is a toggle
        setTimeout(function () {
            $('.jqx-grid').trigger('resize');
        }, 300);
    };

    const setCurrentPage = (page, sourceLink) => {
        let pageObj = {
            currentPage: page
        };

        if (sourceLink) {
            pageObj.sourceLink = sourceLink;
        }

        setState(pageObj);

        props.history.push({
            pathname: '/' + page,
            search: ""
        });
    };

    const pageChange = (bool) => {
        console.log('pagechange: ', bool);
        setState({ pageChange: bool });
    };

    const logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        window.location.reload();
    };

    // if there's no user, and they're trying to access another page, display login and stop execution
    if (!user) {
        return (
            <ErrorBoundryComponent>
                <LoginPage location={props.location} setUser={setUser} history={props.history} user={props.user} />
            </ErrorBoundryComponent>
        );
    }

    return (
        <div>
            <div className="sidenav">
                <ErrorBoundryComponent>
                    <LeftNav
                        history={props.history}
                        currentPage={state.currentPage}
                        setCurrentPage={setCurrentPage}
                        user={user || {}}
                        pageChange={state.pageChange}
                        logout={logout}
                        leftDrawerPulled={state.leftDrawerPulled}
                        toggleLeftDrawer={toggleLeftDrawer}
                    />
                </ErrorBoundryComponent>
            </div>

            <div className="right-content">
                <div id="rightContent">
                    <div className={`right-content-container ${(state.leftDrawerPulled) ? 'drawer-open' : ''}`}>
                        <div className="pageHeader">
                            <ErrorBoundryComponent>
                                <PageHeader
                                    setCurrentPage={setCurrentPage}
                                    currentPage={state.currentPage}
                                    user={user}
                                    leftDrawerPulled={state.leftDrawerPulled}
                                    toggleLeftDrawer={toggleLeftDrawer}
                                    rightDrawerPulled={state.rightDrawerPulled}
                                    toggleRightDrawer={toggleRightDrawer}
                                    toggleSpinner={toggleSpinner}
                                />
                            </ErrorBoundryComponent>
                        </div>

                        <div>
                            <ErrorBoundryComponent>
                                <LeftDrawer
                                    toggleSpinner={toggleSpinner}
                                    leftDrawerPulled={state.leftDrawerPulled}
                                    toggleLeftDrawer={toggleLeftDrawer}
                                    rightDrawerPulled={state.rightDrawerPulled}
                                    toggleRightDrawer={toggleRightDrawer}
                                    user={user}
                                    currentPage={state.currentPage}
                                    setCurrentPage={setCurrentPage}
                                />
                            </ErrorBoundryComponent>
                        </div>

                        <div className={`content-wrapper ${(state.leftDrawerPulled) ? 'drawer-open' : ''}`}>
                            <Switch>
                                <Route path="/login" component={LoginPage} />
                                <Route exact path="/">
                                    {defaultRedirect}
                                </Route>
                                <Route exact path="/layout">
                                    <Redirect to="/dashboard" />
                                </Route>
                                <Route path="/dashboard">
                                    <ErrorBoundryComponent>
                                        <Dashboard
                                            toggleLeftDrawer={toggleLeftDrawer}
                                            toggleRightDrawer={toggleRightDrawer}
                                            user={user}
                                            leftDrawerPulled={state.leftDrawerPulled}
                                            rightDrawerPulled={state.rightDrawerPulled}
                                            toggleNotification={toggleNotification}
                                            notification={state.notification}
                                            currentPage={state.currentPage}
                                            setCurrentPage={setCurrentPage}
                                            toggleSpinner={toggleSpinner}
                                        />
                                    </ErrorBoundryComponent>
                                </Route>
                                <Route path="/product">
                                    <ErrorBoundryComponent>
                                        <Product
                                            toggleLeftDrawer={toggleLeftDrawer}
                                            toggleRightDrawer={toggleRightDrawer}
                                            rightDrawerPulled={state.rightDrawerPulled}
                                            user={user}
                                            leftDrawerPulled={state.leftDrawerPulled}
                                            toggleNotification={toggleNotification}
                                            notification={state.notification}
                                            spinner={state.spinner}
                                            toggleSpinner={toggleSpinner}
                                            currentPage={state.currentPage}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    </ErrorBoundryComponent>
                                </Route>
                                <Route path="/product2">
                                    <ErrorBoundryComponent>
                                        <Product2
                                            toggleLeftDrawer={toggleLeftDrawer}
                                            toggleRightDrawer={toggleRightDrawer}
                                            rightDrawerPulled={state.rightDrawerPulled}
                                            user={user}
                                            leftDrawerPulled={state.leftDrawerPulled}
                                            toggleNotification={toggleNotification}
                                            notification={state.notification}
                                            spinner={state.spinner}
                                            toggleSpinner={toggleSpinner}
                                            currentPage={state.currentPage}
                                            sourceLink={state.sourceLink}
                                            pageChange={pageChange}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    </ErrorBoundryComponent>
                                </Route>
                                <Route path="/marketing">
                                    <ErrorBoundryComponent>
                                        <Marketing
                                            toggleLeftDrawer={toggleLeftDrawer}
                                            toggleRightDrawer={toggleRightDrawer}
                                            rightDrawerPulled={state.rightDrawerPulled}
                                            user={user}
                                            leftDrawerPulled={state.leftDrawerPulled}
                                            toggleNotification={toggleNotification}
                                            notification={state.notification}
                                            currentPage={state.currentPage}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    </ErrorBoundryComponent>
                                </Route>
                                <Route path="/creative">
                                    <ErrorBoundryComponent>
                                        <Creative
                                            toggleLeftDrawer={toggleLeftDrawer}
                                            toggleRightDrawer={toggleRightDrawer}
                                            rightDrawerPulled={state.rightDrawerPulled}
                                            user={user}
                                            leftDrawerPulled={state.leftDrawerPulled}
                                            toggleNotification={toggleNotification}
                                            notification={state.notification}
                                            currentPage={state.currentPage}
                                            toggleSpinner={toggleSpinner}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    </ErrorBoundryComponent>
                                </Route>
                                <Route path="/publication">
                                    <ErrorBoundryComponent>
                                        <Publication
                                            toggleLeftDrawer={toggleLeftDrawer}
                                            toggleRightDrawer={toggleRightDrawer}
                                            rightDrawerPulled={state.rightDrawerPulled}
                                            user={user}
                                            leftDrawerPulled={state.leftDrawerPulled}
                                            toggleNotification={toggleNotification}
                                            notification={state.notification}
                                            currentPage={state.currentPage}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    </ErrorBoundryComponent>
                                </Route>
                                <Route path="/samples">
                                    <ErrorBoundryComponent>
                                        <Samples user={user} />
                                    </ErrorBoundryComponent>
                                </Route>
                                <Route path="/scanning">
                                    <ErrorBoundryComponent>
                                        <MobileScanAction user={user} />
                                    </ErrorBoundryComponent>
                                </Route>
                                <Route path="/admin">
                                    <ErrorBoundryComponent>
                                        <Admin
                                            toggleLeftDrawer={toggleLeftDrawer}
                                            toggleRightDrawer={toggleRightDrawer}
                                            rightDrawerPulled={state.rightDrawerPulled}
                                            user={user}
                                            leftDrawerPulled={state.leftDrawerPulled}
                                            toggleNotification={toggleNotification}
                                            notification={state.notification}
                                            currentPage={state.currentPage}
                                            logout={props.logout}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    </ErrorBoundryComponent>
                                </Route>
                                <Route path="/copywriting">
                                    <ErrorBoundryComponent>
                                        <Copywriting
                                            toggleLeftDrawer={toggleLeftDrawer}
                                            toggleRightDrawer={toggleRightDrawer}
                                            rightDrawerPulled={state.rightDrawerPulled}
                                            user={user}
                                            leftDrawerPulled={state.leftDrawerPulled}
                                            toggleNotification={toggleNotification}
                                            notification={state.notification}
                                            currentPage={state.currentPage}
                                            setCurrentPage={setCurrentPage}
                                        />
                                    </ErrorBoundryComponent>
                                </Route>
                            </Switch>
                        </div>
                        <div>
                            {(state.currentPage != 'dashboard' && state.currentPage != 'admin' && state.currentPage != 'scanning') && (
                                <ErrorBoundryComponent>
                                    <RightDrawer
                                        user={user}
                                        pageType={state.currentPage}
                                        toggleRightDrawer={toggleRightDrawer}
                                        rightDrawerPulled={state.rightDrawerPulled}
                                        toggleLeftDrawer={toggleLeftDrawer}
                                        leftDrawerPulled={state.leftDrawerPulled}
                                        toggleNotification={toggleNotification}
                                        notification={state.notification}
                                        setCurrentPage={setCurrentPage}
                                        {...props}
                                    />
                                </ErrorBoundryComponent>
                            )}
                        </div>
                        <ErrorBoundryComponent>
                            <MobileMenu
                                history={props.history}
                                currentPage={state.currentPage}
                                setCurrentPage={setCurrentPage}
                                user={user}
                                pageChange={state.pageChange}
                                logout={logout}
                            />
                        </ErrorBoundryComponent>
                    </div>
                </div>
            </div>

            {state.spinner && (
                <div className='sweet-loading'>
                    <RingLoader
                        color={'#36D7B7'}
                        loading={state.spinner}
                    />
                </div>
            )}

            <div className='notifier-container' id='notifier-container' />

        </div>
    );
};

export default Layout;
