import React, { Component } from 'react';

// import JqxTreeGrid from '../../common/vendor/jqwidgets-react/react_jqxtreegrid.js';
import Modal from '../../modals/common-modal.js';
import VerticalImageViewer from './vertical-image-viewer.js';
import * as utils from '../../common/utils.js';

import AddCopyModel from './add-copy-modal.js';
import AddKeywordModal from './add-keyword-modal.js';
import SampleModal from './sample-modal.js';
import AddPOModal from './add-po-modal.js';
import SubmitConfirm from './confirmsubmit.js';
import AddVendorModal from './add-vendor.js';
import ProductOptionModal from './product-options.js';
import CopyDisplayModal from './copy-display-modal.js';
import ShotAngles from './add-shot-angles';
import CampaignModal from './campaign-modal';

var GridDataBuilder = require('./grid-data-builder');
var constants = require('../../common/constants.js');
// var NotificationManager = require('../../common/notification');
import NotificationManager from '../../common/components/notification';

import { ProductServices } from '../../product2/services/product-services';


class ProductGridPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isModalOpen: false,
            modalName: '',
            isRequiredFieldsLoaded: true,
            sliderRange: 1,
            isUndoEnable: false,
            piPdfUrl: ''
        };
        this.jqWidgetReservedMembers = ['checked', 'expanded', 'icon', 'leaf', 'level',
            'parent', 'records', 'selected', 'uid'];
        this.clonedList = [];
        this.swatchArrayRef = ["swatchReference", "swatchOnly"];
        this.AllowUserOnlyTrue = ["itemReadyToOrder", "isPiRequestSent", "isPiReceived", "piUpload", "isPiApproved", "isSamplerecieved", "isSampleapproved"];
        this.copyPopupFields = ["productId", "prodName", "itemReadyToOrder", "isPiRequestSent", "isPiReceived", "piUpload", "isPiApproved", 'copywrite', 'keywords',
            'attr1', 'attr2', 'attr3', 'attr4', 'attr5', 'attr6', 'attr7', 'attr8', 'attr9', 'attr10', 'attr11', 'attr12', 'attr13', 'attr14', 'attr15', 'attr16',
            'attr17', 'attr18', 'attr19', 'attr20', 'attr', 'attrCompleted', 'attrApproved', 'addPO', 'channel'];
        this.displayPopupFields = ["earlyReqSampleSwatch", "isSamplerecieved", "isSampleapproved", "swatchOnly",
            'swatchReference', 'featureColor', 'onlineActivatedDate', 'activeNotes', 'quantityInventory', 'sampleMoved',
            'imageApproval', 'shotAngle', 'isActiveLive', 'campaign', 'campaignDate', 'season'];

        this.swatchReferenceList = [];
        this.vendorNameList = null;
        this.undoListData = [];
        this.undoCounter = 0;
        this.modalOpen = this.modalOpen.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.nextRequiredField = this.nextRequiredField.bind(this);
        this.addBackTheField = this.addBackTheField.bind(this);
        this.updateUploadedImage = this.updateUploadedImage.bind(this);
        this.openSamplePopup = this.openSamplePopup.bind(this);
        this.setValueSampleOption = this.setValueSampleOption.bind(this);//Set sample value if there is no swatch reference
        this.setValueSwatchReference = this.setValueSwatchReference.bind(this);
        this.attibuteChangeEvent = this.attibuteChangeEvent.bind(this);
        this.sliderOnChange = this.sliderOnChange.bind(this);
        this.saveNewRecord = this.saveNewRecord.bind(this);

        this.updatePIRecived = this.updatePIRecived.bind(this);
        this.piReceivedModalClose = this.piReceivedModalClose.bind(this);
        this.updateProductForPO = this.updateProductForPO.bind(this);
        this.updateAttrCompleted = this.updateAttrCompleted.bind(this);
        this.setValueSwatchonly = this.setValueSwatchonly.bind(this);
        this.updateSampleSwatchOption = this.updateSampleSwatchOption.bind(this);
        this.storeForUndoChanges = this.storeForUndoChanges.bind(this);
        this.updateParentRow = this.updateParentRow.bind(this);
        this.copyToChildRows = this.copyToChildRows.bind(this);
        this.getResChildRows = this.getResChildRows.bind(this);
        this.openPIUploadPopup = this.openPIUploadPopup.bind(this);
        this.getPiForProduct = this.getPiForProduct.bind(this);
        this.setCellValueAndCopyToChild = this.setCellValueAndCopyToChild.bind(this);
        this.openCampaignPopup = this.openCampaignPopup.bind(this);
        this.loadCampaignEvents = this.loadCampaignEvents.bind(this);
        this.setCampaignCell = this.setCampaignCell.bind(this);
    }
    /**
	 * store for undo changes
	 */
    storeForUndoChanges() {
        console.log("Store Old Data");
        let rowdata = this.oldRowData;
        console.log(rowdata);
        if (rowdata) {
            this.undoListData.unshift(rowdata);
            if (this.undoListData.length > 0) {
                this.setState({ isUndoEnable: true });
            }
        }
    }
    /** function to open Sample popup */
    openSamplePopup() {
        var _this = this;
        _this.setState({ modalName: "SampleModal" });
        _this.modalOpen();
    }
    openPIUploadPopup(ids) {
        var _this = this;
        _this.productId = ids;
        _this.setState({ modalName: 'PiUploadModal' });
        _this.modalOpen();
    }
    openCampaignPopup() {
        let _this = this;
        _this.setState({ modalName: "CampaignModal" });
        _this.modalOpen();
    }
    loadCampaignEvents() {
        let _this = this;
        let pIds = [];
        let base = _this.campaignProduct;
        if (base) {
            if (base.dataLevel == "Sku") {
                pIds.push(base.productId);
            } else if (base.dataLevel == "Intm") {
                let arrIntm = base.chldProuctId;
                arrIntm.map(function (id, ind) {
                    pIds.push(id);
                });
            } else if (base.dataLevel == "SP") {
                let arrIntm = base.chldProuctId;
                arrIntm.map(function (id, ind) {
                    pIds.push(id);
                });
            }

            return pIds;
        }
    }
    attibuteChangeEvent(dom) {
        var _this = this;
        var MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;

        var element = document.querySelector(dom);
        /*setTimeout(function() {
		  element.setAttribute('data-text', 'whatever');
		}, 5000)*/
        var observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                if (mutation.type == "attributes") {
                    console.log("attributes changed");
                    var top = $(dom).position().top;
                    var height = $(dom).height();
                    var upPos = $(dom).parent().find('.jqx-rc-t-light').position();
                    var downPos = $(dom).parent().find('.jqx-rc-b-light').position();

                    if ((top + height + 2) === downPos.top) {
                        console.log("Load Next page....");
                        _this.productGrid.goToNextPage();
                    }
                    /*if(upPos.top===(top-15)){
					  _this.productGrid.goToPrevPage();
					}*/
                }
            });
        });

        observer.observe(element, {
            attributes: true, //configure it to listen to attribute changes
            attributeFilter: ["style"] //Only Style Changes
        });
    }
    sliderOnChange(e) {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        this.setState({ [name]: value });
        this.productGrid.goToPage(parseInt(value, 10) - 1);
    }
    /**
	 * update row with Po information
	 */
    updateProductForPO(data) {
        console.log("updated row successfully");
        this.productGrid.updateRow(data.productId, data);
    }
    /**{
	 * update attr completed true
	 */
    updateAttrCompleted() {
        var _this = this;
        var productId = _this.productId;
        var rowData = this.productGrid.getRow(productId);
        var list = this.copyToChildRows(rowData, 'attrCompleted');
        var postdata = list.map(function (p, i) {
            return { id: p.productId, value: true };
        });
        /** update parent child rows */
        _this.currentRowData = rowData;

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.updateAttrCompleted(postdata)
            .then((response) => {
                console.log('productContainer product-grid-popup updateAttrCompleted API call success data: ', response.data);
                let res = response.data;

                if (res.status) {
                    _this.productGrid.setCellValue(productId, 'attrCompleted', true);
                    _this.setCellValueAndCopyToChild('attrCompleted', true);
                    NotificationManager({ message: res.responseMessage, type: 1 });
                    _this.modalClose();
                } else {
                    _this.modalClose();
                    NotificationManager({ message: res.responseMessage, type: 4 });
                }
            })
            .catch(error => {
                console.log('productContainer product-grid-popup updateAttrCompleted  API call failed', error);
            });
    }

    componentDidMount() {
        let _this = this;

        makeCallToRequiredFields();
        // method will change if the product list is navigated from alerts (GET),
        //while loading directly or from filters it will be POST
        if (_this.props.productresult) {
            let res = _this.props.productresult;
            _this.overwriteProductResData(res);
            res.readyToOrderFields = _this.requiredReadyFields;
            _this.gridData = GridDataBuilder(res, 'product');
            _this.setState({ isLoaded: true });
        }
        // loading after product list is fetched successfully
        function makeCallToRequiredFields() {
            let headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json'
            };
            axios({
                method: 'get',
                url: require('../../common/configurations.js').product.requiredFields,
                headers: headers
            })
                .then((response) => {
                    console.log('productContainer product-grid-popup updateAttrCompleted API call success data: ', response.data);
                    let res = response.data;

                    if (res.data.length > 0) {
                        _this.parseTheRequiredFields(res.data);
                    }
                })
                .catch(error => {
                    console.log('productContainer product-grid-popup updateAttrCompleted  API call failed', error);
                    _this.setState({ isRequiredFieldsLoaded: false });
                });
        }


        $(window).resize(function () {
            console.log('Window Resizng');
            let footerHeight = 50,
                headerHeight = 64,
                filterStrip = 34;
            let gridHeight = window.innerHeight - footerHeight - headerHeight - filterStrip + 28;
        });

        //var productTreeGrid = $('.product-treegrid');
        var productTreeGrid = $('.productTreeGridPopup');
        productTreeGrid.off();

        productTreeGrid.on('click', '.product-image', function () {
            var rowId = $(this).attr('data-row');
            var data = _this.productGrid.getRow(rowId);
            // imageProductInfo is used for image pop information
            _this.imageProductInfo = data;
            _this.setState({ modalName: 'image' });
            _this.modalOpen();
        });

        productTreeGrid.on('click', '.swatch-only', function (e) {
            var ProductId = $(this).attr('data-row'),
                checked = $(this).prop('checked');
            _this.swatchUpdate(ProductId, checked);
        });
        /** Add Vendor */
        productTreeGrid.on('click', '.addVendorFromProductGrid', function (e) {
            _this.setState({ modalName: "addVendorModal" });
            _this.modalOpen();
        });
        /** Update Sample approved  commented by asaithambi */
        productTreeGrid.on('click', '.product-attr-completed', function (e) {
            e.preventDefault();
            var ProductId = $(this).attr('data-row'),
                //checked = $(this).prop('checked'),
                fieldName = $(this).attr('data-fieldname');
            _this.productId = ProductId;
            var requiredFields = ['attr1', 'attr2', 'attr3', 'attr4', 'attr5', 'attr6', 'attr7', 'attr8', 'attr9', 'attr10',
                'attr11', 'attr12', 'attr13', 'attr14', 'attr15', 'attr16', 'attr17', 'attr18', 'attr19', 'attr20'];
            var needcount = 20;
            var validationData = { valid: false, validcount: 0, needcount: needcount, force: false };
            var rowData = _this.productGrid.getRow(ProductId);
            for (var i = 0; i < needcount; i++) {
                var val = $.trim(rowData[requiredFields[i]]);
                if (val != null && val != undefined && val != "") {
                    validationData.valid = true;
                    validationData.validcount++;
                }
            }
            if (validationData.validcount !== needcount) {
                validationData.force = true;
            }

            if (validationData.force) {
                _this.setState({ modalName: 'submitConfirm', dialog: "SubmitConfirm", validationData: validationData });
                _this.modalOpen();
            } else {
                _this.updateAttrCompleted();
            }
        });

        // for field type is boolean checkbox is provided, on click the result will be updated
        productTreeGrid.on('click', '.product-boolean,.product-bool', function (e) {
            var rowId = $(this).attr('data-row'),
                fieldName = $(this).attr('data-fieldname'),
                isChecked = $(this).prop('checked'),
                // include the same value in grid-data-builder.js as well
                oneTimeCheckbox = ["isPiReceived",
                    "isPiApproved",
                    "isPiRequestSent",
                    "isSampleapproved",
                    "isSamplerecieved",
                    "deleted",
                    "attrApproved",
                    "readyToActivate",
                    "isPiRequestEarly",
                    "isResendPiRequest",
                    "itemReadyToOrder",
                    'earlyReqSampleSwatch',
                    "sampleRequested",
                    "isActiveLive"
                ];

            if (oneTimeCheckbox.indexOf(fieldName) > -1) {
                let urlObj = {
                    [oneTimeCheckbox[0]]: api.product.piRecieved,
                    [oneTimeCheckbox[1]]: api.product.piApproved,
                    [oneTimeCheckbox[2]]: api.product.piRequestSent,
                    [oneTimeCheckbox[3]]: api.product.sampleApproved,
                    [oneTimeCheckbox[4]]: api.product.isSampleRecevied,
                    [oneTimeCheckbox[5]]: api.product.userDelete,
                    [oneTimeCheckbox[6]]: api.product.attrAproved,
                    [oneTimeCheckbox[7]]: api.product.readyToActivate,
                    [oneTimeCheckbox[8]]: api.product.piRequestEarly,
                    [oneTimeCheckbox[9]]: api.product.resendPiRequest,
                    [oneTimeCheckbox[10]]: api.product.itemReadyToOrder,
                    [oneTimeCheckbox[11]]: api.product.earlyRequestSampleSwatch,
                    [oneTimeCheckbox[12]]: api.product.sampleRequestSent,
                    [oneTimeCheckbox[13]]: api.product.activeliveeupdate
                };
                let value = true;
                if (oneTimeCheckbox.indexOf(fieldName) === 5 || oneTimeCheckbox.indexOf(fieldName) === 8 || oneTimeCheckbox.indexOf(fieldName) === 9) {
                    value = isChecked;
                }

                // for "piRequestEarly" and "resendPiRequest"
                if (oneTimeCheckbox.indexOf(fieldName) === 8 || oneTimeCheckbox.indexOf(fieldName) === 9) {
                    value = value.toString();
                }
                /**
				 * Copy content parent child product -- Block Start
				 */
                var productId = rowId,
                    rowData = null, list, postdata = null, parentRef = null;
                rowData = _this.productGrid.getRow(productId);
                _this.currentRowData = rowData;// Used to pass current row data to modal component

                if (fieldName === "itemReadyToOrder" && rowData[fieldName] !== true && rowData.copyEditable === true) {
                    var validate = _this.ValidateRequiredFields(_this.requiredReadyFields, [rowData]);
                    var unfilledField = _this.getUnfilledColumns(_this.requiredReadyFields, [rowData]);
                    if (!validate.isValid) {
                        var msg = 'Please provide the following mandatory fields to in order to mark "Ready to order" checkbox for this product<br/><br/>'
							+ unfilledField.join(',');
                        NotificationManager({ message: msg, type: 4 });
                        return false;
                    }
                }

                if (rowData.dataLevel != "SP" && rowData.dataLevel != "Intm") {
                    _this.parentRef = null;
                } else {
                    _this.parentRef = rowData.parentRef ? rowData.parentRef : null;
                }


                list = _this.getResChildRows(rowData);
                postdata = list.map((o, i) => {
                    return o.productId;
                });
                if (fieldName === 'isPiReceived') {
                    _this.productId = postdata;
                    _this.setState({ modalName: 'PiUploadModal' });
                    _this.modalOpen();
                }
                if (oneTimeCheckbox.indexOf(fieldName) === 4) {
                    _this.productData = postdata;
                    _this.childProdList = list;
                    _this.openSamplePopup();
                    return false;
                }

                if (fieldName === "itemReadyToOrder" || fieldName === "earlyReqSampleSwatch") {
                    postdata = list.map((o, i) => {
                        return { "id": o.productId, "value": true };
                    });
                }
                if (fieldName === "isSampleapproved") {
                    postdata = list.map((o, i) => {
                        return { "id": o.productId };
                    });
                }
                if (["deleted", "isPiRequestSent", "isResendPiRequest", "isPiRequestEarly"].indexOf(fieldName) > -1) {
                    postdata = list.map((o, i) => {
                        return { "id": o.productId, "value": isChecked };
                    });
                }
                /** update parent child rows */
                _this.setCellValueAndCopyToChild(fieldName, isChecked);

                /**
				 * Copy content parent child product -- Block End
				 */

                let headers = {
                    'Authorization': this.props.user.token,
                    'content-type': 'application/json'
                };
                axios({
                    method: 'post',
                    url: urlObj[fieldName],
                    headers: headers,
                    data: postdata
                })
                    .then((response) => {
                        console.log('productContainer product-grid-popup componentDidMount API call success data: ', response.data);
                        let data = response.data;

                        if (data.status) {
                            NotificationManager({ message: data.responseMessage, type: 1 });
                            // _this.productGrid.setCellValue(rowId,fieldName,isChecked);
                        } else {
                            NotificationManager({ message: data.responseMessage, type: 4 });
                            _this.setCellValueAndCopyToChild(fieldName, false);
                        }
                    })
                    .catch(error => {
                        console.log('productContainer product-grid-popup componentDidMount  API call failed', error);
                        _this.setState({ isRequiredFieldsLoaded: false });
                    });
            } else {
                var rowData = _this.productGrid.getRow(rowId);
                _this.currentRowData = rowData;
                let dynaFields = Object.keys(rowData.dynaData);
                if (dynaFields.indexOf(fieldName) > -1) { // For newly created fields
                    _this.setCellValueAndCopyToChild(fieldName, isChecked);
                    let childRowsData = _this.getResChildRows(_this.productGrid.getRow(rowId));

                    _this.sendUpdatedOrAddedRecord(childRowsData);
                } else {
                    if (fieldName === "isActiveLive") {
                        _this.productDeactivate(deactiveReq, isChecked);
                    } else {
                        _this.sendUpdatedOrAddedRecord(rowData);
                    }
                }
            }
        });

        productTreeGrid.on('click', '.header-checkbox', function () {
            var isChecked = $(this).attr('data-checked');
            if (isChecked === 'false') {
                $(this).attr('data-checked', 'true');
                $(this).addClass('checked');
                _this.checkAllRows();
            } else if (isChecked === 'true') {
                $(this).attr('data-checked', 'false');
                $(this).removeClass('checked');
                _this.unCheckAllRows();
            }
        });

        productTreeGrid.on('click', '.jqx-grid-column-header', function () {
            var fieldName = $(this).find('.pd-drop').attr('data-field');
            if (fieldName !== undefined) {
                var wh = $(this).css(['width', 'height']);
                var offset = $(this).offset();
                var OffsetTop = offset.top + parseInt(wh.height);
                var offsetLeft = offset.left + parseInt(wh.width) - 150;
                $('#drop-down').css({ left: offsetLeft, top: OffsetTop, display: 'block' })
                    .attr('data-field', fieldName);
            }
        });

        productTreeGrid.on('click', '.swatchOnly span', function (event) {
            console.log("swatch only click------------------------------------");
            var $target = $(event.currentTarget).parent();
            var dataField = $target.attr('datafield');
            var key = $target.data('row');
            var fieldValue = $.trim($target.text());
            var rowData = _this.productGrid.getRow(key);
            _this.currentRowData = rowData; // used for passing to modal componenet
            var list = _this.getResChildRows(rowData);
            var postData = list.map(function (p, i) {
                return p.productId;
            });
            if (dataField === "swatchOnly" && ["sample", "swatch only"].indexOf(fieldValue.toLowerCase()) !== -1) {
                _this.productData = postData;
                _this.childProdList = list;
                if (rowData.dataLevel != "SP" && rowData.dataLevel != "Intm") {
                    _this.parentRef = null;
                } else {
                    _this.parentRef = rowData.parentRef ? rowData.parentRef : null;
                }
                _this.openSamplePopup();
            }
        });

        productTreeGrid.on('click', '.icon-expand-grid.campaign', function (event) {
            let $target = $(event.currentTarget).parent();
            let dataField = $target.attr('datafield');
            let key = $target.data('key');
            let fieldValue = $.trim($target.text());
            let rowData = _this.productGrid.getRow(key);
            _this.campaignProduct = rowData;
            _this.openCampaignPopup();
        });

        productTreeGrid.on('click', '[id*="contentjqxTreeGrid"]', function (e) {
            let parentObj = $(e.target).parents('.jqx-grid-content');
            if (parentObj.length == 0 && (_this.newRecordId || _this.clonedList[0])) {
                var selectrowID = null;
                if (_this.newRecordId) {
                    selectrowID = _this.newRecordId;
                }
                if (_this.clonedList[0]) {
                    selectrowID = _this.clonedList[0];
                }
                _this.productGrid.endRowEdit(selectrowID);
                _this.saveNewRecord();
            }
        });
        var columndetails = _this.props.productresult.customcolumdetails;
        //var listSource = [{ label: 'Budget', value: 'budget', checked: true }, { label: 'Location', value: 'location', checked: false }];
        var listSource = columndetails.map((e, i) => {
            return { label: e.columnTitle, value: e.fieldName, checked: true };
        });
        $("#jqxlistbox").jqxListBox({ source: listSource, width: 120, height: 200, checkboxes: true });
        $("#jqxlistbox").on('checkChange', function (event) {
            _this.productGrid.beginUpdate();
            if (event.args.checked) {
                _this.productGrid.showColumn(event.args.value);
            }
            else {
                _this.productGrid.hideColumn(event.args.value);
            }
            _this.productGrid.endUpdate();
        });

        /** Add Po Link click */
        productTreeGrid.on('click', '.addPO', function (event) {
            console.log("add PO click------------------------------------");
            var $target = $(event.currentTarget);
            var dataField = $target.attr('datafield');
            var key = $target.data('row'),
                //rowData = _this.productGrid.getRow(key);
                productId = key,
                rowData = null, list, postdata = [];
            rowData = _this.productGrid.getRow(productId);
            list = _this.getResChildRows(rowData);
            postdata = list.map(function (o, i) {
                return o.productId;
            });
            if (!rowData['itemReadyToOrder']) {
                NotificationManager({ message: "Required product details for Item ready to order are missing", type: 2 });
                //return;
            }

            if (dataField === "addPO") {
                _this.productId = postdata;
                _this.vendorId = rowData.vendorId; //
                _this.setState({ modalName: "AddPOModal" });
                _this.modalOpen();
            }
        });
        //drop down close event
        $(document).on('click.proddropdown', function (e) {
            e.stopPropagation();

            if ($('#drop-down').is(':visible') && $(e.target).closest('.jqx-grid-column-header').length === 0) {
                $('#drop-down').hide();
            }
        });

        this.sortDropDown();

        $('#drop-down').on('click', 'li', function () {
            var fieldName = $(this).closest('#drop-down').attr('data-field');
            var val = $(this).attr('data-val');

            val = (val === "null") ? null : val;
            _this.productGrid.sortBy(fieldName, val);
        });
    }
    /**
	 * update PI received checkbox after PI upload is done
	 * @param {object} data - rowdata
	 */
    updatePIRecived(status, rowId) {
        let _this = this;
        if (!status) {
            _this.productGrid.setCellValue(rowId, 'isPiReceived', false);
        } else {
            let headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json'
            };
            axios({
                method: 'post',
                url: require('../../common/configurations.js').product.piRecieved,
                headers: headers,
                data: { "id": rowId, value: true }
            })
                .then((response) => {
                    console.log('productContainer helperComponents product-grid-popup updatePIRecived API call success data: ', response.data);
                    let data = response.data;

                    if (data.status) {
                        NotificationManager({ message: data.responseMessage, type: 1 });
                    } else {
                        NotificationManager({ message: data.responseMessage, type: 4 });
                    }
                })
                .catch(error => {
                    console.log('productContainer helperComponents product-grid-popup updatePIRecived API call failed', error);
                });
        }
    }

    /** parseTheRequiredFields : Converts the array of objects to object with
	 * fieldName and status as key pair required for clone,add & ready to order
	*/
    parseTheRequiredFields(data) {
        var requiredFields = {
            add: {},
            clone: {},
            readyToOrder: {}
        };
        /* actualFieldName,reqdToAdd,fieldsToClone,readyToOrder are constant values
		*  based on the response from backend.
		*  CHECK THESE VALUES IF ANY ERROR COMES
		*/
        data.forEach((item, index) => {
            requiredFields.add[item.actualFieldName] = item.reqdToAdd;
            requiredFields.clone[item.actualFieldName] = item.fieldsToClone;
            requiredFields.readyToOrder[item.actualFieldName] = item.readyToOrder;
            if (!item.actualFieldName || item.actualFieldName === '') {
                console.warn("actualFieldName is coming empty, With out this addRecord and clone will not work.");
            }
        });

        this.setRequiredFields(requiredFields);
    }

    /*setRequiredFields will generate an array of required fields and set to
	* the appropriate variable
	*/
    setRequiredFields(obj) {
        this.requiredCloneFields = getTrueFields(obj.clone);
        this.requiredAddFields = getTrueFields(obj.add);
        this.requiredReadyFields = getTrueFields(obj.readyToOrder);

        function getTrueFields(obj, buildArray) {
            var builtArray = [];
            for (var item in obj) {
                if (obj[item]) {
                    builtArray.push(item);
                }
            }

            return builtArray;
        }

        function getFalseFields(obj, buildArray) {
            for (var item in obj) {
                if (!obj[item]) {
                    buildArray.push(item);
                }
            }
        }
    }


    checkAllRows() {
        var allRows = this.productGrid.getRows();
        allRows.forEach((item) => {
            this.productGrid.checkRow(item.uid);
        });
    }
    unCheckAllRows() {
        var allRows = this.productGrid.getRows();
        allRows.forEach((item) => {
            this.productGrid.uncheckRow(item.uid);
        });
    }

    piReceivedModalClose(status, rowId) {
        // if (!status) {
        // 	this.productGrid.setCellValue(rowId, 'isPiReceived', false);
        // }
        this.setState({ isModalOpen: false });
    }

    modalOpen() {
        this.setState({ isModalOpen: true });
    }

    modalClose() {
        this.setState({ isModalOpen: false });
    }

    getPiForProduct(productArray) {
        let _this = this;

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };
        axios({
            method: 'post',
            url: require('../../common/configurations.js').product.getPiForProduct,
            headers: headers,
            data: productArray
        })
            .then((response) => {
                console.log('productContainer helperComponents footer API call success data: ', response.data);
                let res = response.data;

                if (res.status) {
                    _this.setState({ piPdfUrl: res.data.pdfUrl });
                } else {
                    _this.setState({ piPdfUrl: '' });
                }
            })
            .catch(error => {
                console.log('productContainer helperComponents footer API call failed', error);
            });
    }

    // this method is invoked when props or state is updated
    componentDidUpdate(prevProps, prevState) {
        let _this = this;

        if (prevState.isLoaded !== this.state.isLoaded && this.state.isLoaded === true) {
            _this.productGrid.on('cellEndEdit', function (event) {
                if (event.args.dataField === "swatchOnly" && event.args.value.toLowerCase() === "none") {
                    $('.productTreeGridPopup tr[data-row="' + event.args.key + '"]').find('input[data-fieldname="isSamplerecieved"]').attr({ disabled: true });
                } else {
                    $('.productTreeGridPopup tr[data-row="' + event.args.key + '"]').find('input[data-fieldname="isSamplerecieved"]').removeAttr('disabled');
                }

                let args = event.args;
                // Product Variants - if variants value equal to "create multi product"
                if (args.dataField.split('_').length > 1 && args.dataField.split('_')[1] === 'attribute') {
                    if (args.value && args.value.toLowerCase() === 'create multi product') {
                        _this.setState({ modalName: 'productOption' });
                        _this.modalOpen();
                    } else {
                        if (args.value != "") {
                            _this.checkForCellValChange(event.args);
                        }
                    }
                } else { // except options datafields
                    _this.checkForCellValChange(event.args);
                }
            });

            _this.productGrid.on('cellBeginEdit', function (event) {
                let args = event.args;
                let prodData = args.row;
                var elePos = _this.productGrid.getScrollOffset();
                _this.oldRowData = Object.assign({}, prodData);
                if (args.displayValue instanceof Date) {
                    //converting to milliseconds
                    _this.cellBeforeEdit = +args.displayValue;
                } else {
                    _this.cellBeforeEdit = args.displayValue;
                }

                if ((args.row.copyEditable === false && _this.copyPopupFields.indexOf(args.dataField) > -1) ||
					(args.row.displayEditable === false && _this.displayPopupFields.indexOf(args.dataField) > -1)) {
                    _this.productGrid.endCellEdit(args.key, args.dataField);
                }
                if (["color_attribute", "size_attribute", "swatchOnly"].indexOf(args.dataField) > -1 && args.row.parentRef && args.row.dataLevel == "SP") {
                    _this.productGrid.endCellEdit(args.key, args.dataField);
                }
                if (args.dataField == "swatchOnly" && args.row.isSamplerecieved && args.row.isSampleapproved) {
                    _this.productGrid.endCellEdit(args.key, args.dataField);
                }
                setTimeout(function () {
                    _this.productGrid.scrollOffset(elePos.top, elePos.left);
                }, 100);
            });
            /**
			 * RowDoubleClick-ProductGrid Popup event: Opening a CopyModel if the column is "isCopywriterApproved"
			 */
            _this.productGrid.on('rowDoubleClick', function (event) {
                var args = event.args;
                var dataField = args.dataField;
                var row = args.row;
                //_this.productId=args.key;
                _this.currentRowData = row;
                var copyAllowFlag = false, keywordAllowFlag = false;
                if (row.isPiApproved && row.copyEditable === true) {
                    copyAllowFlag = true;
                }

                if (row.isPiApproved && row.copyEditable === true) {
                    keywordAllowFlag = true;
                }
                var list = _this.getResChildRows(row);
                var keyProductId = list[0].productId;
                var productIds = list.map((e, i) => {
                    return e.productId;
                });
                _this.productId = productIds;

                // Image Approval
                if (row.displayEditable == true && event.args.dataField === 'imageApproval') {
                    _this.chldProuctIds = productIds;

                    _this.setState({ isImageModalOpen: true });
                }

                if (copyAllowFlag && row.copyEditable === true && dataField === "copywrite" && [constants.ROLES.COPYWRITER, constants.ROLES.BUYER, constants.ROLES.ADMIN].indexOf(this.props.user.defaultRole) !== -1) {
                    let headers = {
                        'Authorization': this.props.user.token,
                        'content-type': 'application/json'
                    };
                    ProductServices.getCopyWritter({ id: keyProductId })
                        .then((response) => {
                            console.log('productContainer helperComponents product grid popup _this.productGrid.on(rowDoubleClick footer API call success data: ', response.data);
                            let res = response.data;

                            if (res.status) {
                                _this.copyData = res.data;

                                _this.setState({ modalName: 'addCopyModel' });
                                _this.modalOpen();
                            } else {
                                alert("Product data doesnot contains descriptions");
                            }
                        })
                        .catch(error => {
                            console.log('productContainer helperComponents product grid popup _this.productGrid.on(rowDoubleClick  API call failed', error);
                        });
                }
                else if (keywordAllowFlag && row.copyEditable === true && dataField === "keywords" && [constants.ROLES.WEBPRODUCER, constants.ROLES.BUYER, constants.ROLES.ADMIN].indexOf(this.props.user.defaultRole) !== -1) {
                    ProductServices.getKeywords({ id: keyProductId })
                        .then((response) => {
                            console.log('productContainer helperComponents product grid popup _this.productGrid.on(rowDoubleClick footer API call success data: ', response.data);
                            let res = response.data;

                            if (res.status) {
                                _this.keywordData = res.data;
                                _this.setState({ modalName: 'addKeywordModal' });
                                _this.modalOpen();
                            } else {
                                NotificationManager({ message: "Product Data doesnt contains related data", type: 4 });
                            }
                        })
                        .catch(error => {
                            console.log('productContainer helperComponents product grid popup _this.productGrid.on(rowDoubleClick  API call failed', error);
                        });
                } else if (dataField === "copywrite" || dataField === "keywords") {
                    var msg = "";

                    if (!row.copyEditable) {
                        if (dataField === "keywords") {
                            msg = "Keywords are not available at this product level";
                        } else {
                            msg = "Copywrite is not available at this product level";
                        }
                    } else if (!row.piApproved && dataField === "keywords") {
                        msg = "Keywords not yet added";
                    } else if (!row.piApproved) {
                        msg = "Product doesnt have required information";
                    }
                    NotificationManager({ message: msg, type: 4 });
                }
                else if (dataField === "swatchReference" && row.swatchOnly == "Swatch Only" && row.displayEditable === true) {
                    let headers = {
                        'Authorization': this.props.user.token,
                        'content-type': 'application/json'
                    };

                    ProductServices.getSampleIds({ id: productIds, styleId: args.row.styleNumber })
                        .then((response) => {
                            console.log('productContainer helperComponents product grid popup _this.productGrid.on(rowDoubleClick footer API call success data: ', response.data);
                            let res = response.data;

                            if (res.responseStatus && res.data.length > 0) {
                                _this.swatchReferenceList = res.data;
                                _this.setState({ modalName: 'sampleSwatchDropDown' });
                                _this.modalOpen();
                                _this.addBackDetails = args;
                            }
                            else {
                                NotificationManager({ message: "There is no swatch reference for this product", type: 4 });
                            }
                        })
                        .catch(error => {
                            console.log('productContainer helperComponents product grid popup _this.productGrid.on(rowDoubleClick  API call failed', error);
                        });
                }
                else if (dataField === "shotAngle" && row.displayEditable === true) {
                    if (row.isSampleapproved === true) {
                        _this.currentProductCatID = row.catId;
                        _this.productId = productIds;
                        _this.setState({ modalName: 'ShotAngles' });
                        _this.modalOpen();
                    } else {
                        NotificationManager({ message: "Sample approved is not done for the selected product", type: 4 });
                    }
                }
                else if (dataField === "piUpload") {
                    _this.selectCategoryRow = row;
                    if (_this.selectCategoryRow['isPiReceived'] === true && row.copyEditable === true) {
                        _this.getPiForProduct(_this.productId);
                        _this.setState({ modalName: 'PiUploadModal' });
                        _this.modalOpen();
                    }
                }
            });


            _this.getAllChildProducts = (records) => {
                _this.childRowData = [];
                records.map((value) => {
                    if (value.dataLevel === 'Intm') {
                        value.records.map((recs) => {
                            delete recs.data;  //remove extra data object;
                            _this.childRowData.push(recs);
                        });
                    }
                });
            };

            // on change of the row selection, checking if new row is added, then checking if user
            // moves away from it without adding required fields
            _this.productGrid.on('rowSelect', function () {
                var currentSelected = _this.productGrid.getSelection();

                if (_this.newRecordId && _this.newRecordId !== currentSelected[0].uid) {
                    var currentRecord = _this.productGrid.getRow(_this.newRecordId);
                    var list = [currentRecord];
                    var validate = _this.ValidateRequiredFields(_this.requiredAddFields, list);
                    var unfilledField = _this.getUnfilledColumns(_this.requiredAddFields, list);

                    if (validate.isValid) {
                        // if valid send the row details to backend
                        _this.sendUpdatedOrAddedRecord(currentRecord);
                        // empty the newRecordId after submission
                        _this.newRecordId = '';
                        window.newProductId = null;
                    } else {
                        // _this.alertRequiredFields(validate);
                        _this.alertWithNotFilledFields(unfilledField);
                        _this.requiredFieldsAlert = validate;
                    }
                }
            });
        }
    }

    /**
	 * get columnindex
	 * @param {object} fieldName - column fieldname
	 */
    getColumnIndex(fieldName) {
        let index;
        this.props.columnDetails.map((e, i) => {
            if (e.fieldName === fieldName) {
                index = i;
            }
        });
        return index;
    }

    updateColumnResequence() {
        let req = { id: this.props.currentViewId.viewId, userId: this.props.user.userId, type: this.props.currentViewId.viewType, columnDetails: this.props.columnDetails };

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.resizeColOrderUpdate(req)
            .then((response) => {
                console.log('productContainer helperComponents product-grid-popup updateColumnResequence API call success data: ', response.data);
                let res = response.data;
            })
            .catch(error => {
                console.log('productContainer helperComponents product-grid-popup updateColumnResequence API call failed', error);
            });
    }

    saveNewRecord() {
        console.log("Saving new record...............");
        var _this = this;
        var currentSelected = _this.productGrid.getSelection();
        console.log(currentSelected);
        if (_this.newRecordId) {
            var currentRecord = _this.productGrid.getRow(_this.newRecordId);
            var list = [currentRecord];
            var validate = _this.ValidateRequiredFields(_this.requiredAddFields, list);
            var unfilledField = _this.getUnfilledColumns(_this.requiredAddFields, list);

            if (validate.isValid) {
                // if valid send the row details to backend
                _this.sendUpdatedOrAddedRecord(currentRecord);
                // empty the newRecordId after submission
                _this.newRecordId = '';
                window.newProductId = null;
            } else {
                // _this.alertRequiredFields(validate);

                _this.alertWithNotFilledFields(unfilledField);
                this.requiredFieldsAlert = validate;
            }
        }
    }

    /** opens the required field confirm modal
	  * @param {object} obj - object contains not filled rowId and fieldName
	  */
    alertRequiredFields(obj) {
        this.setState({
            modalName: "requiredFieldConfirm",
        });
        this.requiredFieldsAlert = obj;
        this.getMandatoryColumnNames();
        this.modalOpen();
    }

    /** opens the required field confirm modal
	 * @param {object} obj - object contains not filled rowId and fieldName
	 */
    alertWithNotFilledFields(obj) {
        this.setState({
            modalName: "requiredFieldConfirm",
        });
        this.requiredFieldsAlertTemp = obj;

        // this.getMandatoryColumnNames();
        this.getMandatoryUnfilledColumnNames();
        this.modalOpen();
    }

    getMandatoryColumnNames() {
        this.mandatoryAddColumns = [];

        if (this.mandatoryAddColumns.length === 0) {
            this.props.columnDetails.map((item, index) => {
                if (this.requiredAddFields.indexOf(item.fieldName) > -1) {
                    return this.mandatoryAddColumns.push(item.columnTitle);
                }
            });
        }
    }

    getMandatoryUnfilledColumnNames() {
        this.mandatoryAddColumns = [];
        if (this.mandatoryAddColumns.length === 0) {
            this.props.columnDetails.map((item, index) => {
                if (this.requiredFieldsAlertTemp.indexOf(item.fieldName) > -1) {
                    return this.mandatoryAddColumns.push(item.columnTitle);
                }
            });
        }
    }

    updateSampleSwatchOption(productId, checked) {
        var base = this;
        var postdata = productId.map((o, i) => {
            return o.productId;
        });

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.updateProductSample({ checkedAll: checked, productId: postdata, swatchRef: "" })
            .then((response) => {
                console.log('productContainer helperComponents footer API call success data: ', response.data);
                let res = response.data;
                if (res.data) {
                    _this.props.addGridData(res.data);
                    _this.props.gridDataChanged();
                }
            })
            .catch(error => {
                console.log('productContainer helperComponents footer API call failed', error);
                NotificationManager({ message: error, type: 4 });
            });
    }

    checkForCellValChange(args) {
        var _this = this;
        if (this.swatchArrayRef.indexOf(args.dataField) === -1 && this.newRecordId || this.clonedList.length > 0) {
            return;
        }
        var cellAfterEdit,
            isChanged = false;
        if (args.displayValue instanceof Date) {
            cellAfterEdit = +args.displayValue;
        } else {
            cellAfterEdit = args.displayValue;
        }

        if (this.requiredAddFields && this.requiredAddFields.indexOf(args.displayField) > -1 && cellAfterEdit == '') {
            this.setState({ modalName: 'emptyMandatoryfield' });
            this.modalOpen();
            this.addBackDetails = args;
            return;
        }
        if (this.swatchArrayRef.indexOf(args.dataField) > -1 && (args.row[this.swatchArrayRef[1]] === "Sample" || args.row[this.swatchArrayRef[1]].toLowerCase() === "none")) {
            this.productGrid.setCellValue(args.key, this.swatchArrayRef[0], "");
            var rowData = this.productGrid.getRow(args.key);
            var list = this.copyToChildRows(rowData, [this.swatchArrayRef[0]]);
            this.updateSampleSwatchOption(list, false);
        }
        if (this.swatchArrayRef.indexOf(args.dataField) > -1 && args.row[this.swatchArrayRef[1]] === "Swatch Only") {
            if (args.row[this.swatchArrayRef[0]] === undefined || args.row[this.swatchArrayRef[0]] === null || args.row[this.swatchArrayRef[0]] === "") {
                var styleID = args.row.styleNumber;

                var rowData = this.productGrid.getRow(args.key);
                var list = this.getResChildRows(rowData);
                var producIds = list.map((o, i) => {
                    return o.productId;
                });
                if (styleID) {
                    let headers = {
                        'Authorization': this.props.user.token,
                        'content-type': 'application/json'
                    };

                    ProductServices.getSampleIds({ id: producIds, styleId: styleID })
                        .then((response) => {
                            console.log('productContainer helperComponents footer API call success data: ', response.data);
                            let res = response.data;

                            if (res.responseStatus && res.data.length > 0) {
                                _this.swatchReferenceList = res.data;
                                _this.setState({ modalName: 'sampleSwatchDropDown' });
                                _this.modalOpen();
                                _this.addBackDetails = args;
                                var rowData = _this.productGrid.getRow(args.key);
                                var list = _this.copyToChildRows(rowData, [_this.swatchArrayRef[0]]);
                                _this.updateSampleSwatchOption(list, true);
                            }
                            else {
                                _this.setState({ modalName: 'sampleMessagePopup' });
                                _this.modalOpen();
                                _this.addBackDetails = args;
                            }
                        })
                        .catch(error => {
                            console.log('productContainer helperComponents footer API call failed', error);
                        });
                } else {
                    _this.setState({ modalName: 'sampleMessagePopup' });
                    _this.modalOpen();
                    _this.addBackDetails = args;
                }
                return;
            }
        }
        if (args.dataField === 'styleNumber') {
            var regex = /^[A-Za-z0-9-.]+$/i;
            if (!cellAfterEdit.match(regex)) {
                NotificationManager({ message: "Alphanumeric, - and . are allowed.", type: 4 });
                _this.productGrid.setCellValue(args.key, args.dataField, this.cellBeforeEdit);
                _this.productGrid.beginCellEdit(args.key, args.dataField);
                return false;
            }
        }
        if (["swatchOnly", "color_attribute", "size_attribute"].indexOf(args.dataField) > -1 && args.row.parentRef && args.row.dataLevel == "SP") {
            return;
        }
        this.cellBeforeEdit = this.cellBeforeEdit ? this.cellBeforeEdit : "";
        if (this.cellBeforeEdit != cellAfterEdit) {
            this.storeForUndoChanges();
            var list = this.copyToChildRows(args.row, [args.dataField]);
            console.log("Saving Object");
            console.log(list);
            this.sendUpdatedOrAddedRecord(list);
        }
    }
    /**
	 *
	 * @param {*ProductRow} row
	 */
    getResChildRows(row) {
        var _this = this;
        var list = [], rowdata;
        function getChild(c) {
            c.map((p, i) => {
                if (p.dataLevel !== "Intm") {
                    delete p.data;
                    delete p.parent;
                    list.push(p);
                }
                if (p.records && p.records.length > 0) {
                    getChild(p.records);
                }
            });
        }
        if (row.dataLevel == "SP") {
            rowdata = _this.productGrid.getRow(row.productId);
            getChild(rowdata.records);
        }
        else if (row.dataLevel == "Intm") {
            rowdata = _this.productGrid.getRow(row.referrence);
            var itmchild = null;
            if (rowdata) {
                rowdata.records.map((o, i) => {
                    if (o.productId === row.productId) {
                        itmchild = o;
                    }
                });
            }
            else {
                itmchild = row;
            }

            getChild(itmchild.records);
        } else {
            list.push(row);
        }
        return list;
    }
    copyToChildRows(row, copyfields) {
        var _this = this;
        var list = [], rowdata;
        copyfields = copyfields ? copyfields : [];
        function updateChild(g, c) {
            c.map((p, i) => {
                for (var l = 0; l < copyfields.length; l++) {
                    p[copyfields[l]] = g[copyfields[l]];
                }
                _this.productGrid.updateRow(p.productId, p);
                if (p.dataLevel !== "Intm") {
                    delete p.data;
                    delete p.parent;
                    list.push(p);
                }
                if (p.records && p.records.length > 0) {
                    updateChild(g, p.records);
                }
            });
        }
        if (row.dataLevel == "SP") {
            rowdata = _this.productGrid.getRow(row.productId);
            updateChild(rowdata, rowdata.records);
        }
        else if (row.dataLevel == "Intm") {
            rowdata = _this.productGrid.getRow(row.referrence);
            var itmchild = null;
            if (rowdata) {
                rowdata.records.map((o, i) => {
                    if (o.productId === row.productId) {
                        itmchild = o;
                    }
                });
            } else {
                itmchild = row;
            }
            updateChild(row, itmchild.records);
        } else {
            list.push(row);
        }
        return list;
    }
    /**
	 * When they make sampe to swartch only from sample model
	 */
    setValueSwatchonly(swatchref) {
        if (this.productData) {
            var value = 'Swatch Only';
            //Setting window object reference for grid check disable/enable;
            this.productGrid.setCellValue(this.productData.productId, 'swatchOnly', value);
            this.productGrid.setCellValue(this.productData.productId, 'swatchReference', swatchref);
        }
    }
    /**
	   *
	   * @param {*} fieldName
	   * @param {*} value
	   */
    setCellValueAndCopyToChild(fieldName, value) {
        var _this = this;
        var productId = _this.currentRowData.productId;
        _this.productGrid.setCellValue(productId, fieldName, value);
        var rowdata = _this.productGrid.getRow(productId);
        this.copyToChildRows(rowdata, [fieldName]);
        var parentRow = null;
        if (rowdata.parentRef && rowdata.dataLevel == "Intm") {
            parentRow = _this.productGrid.getRow(rowdata.referrence);
        }
        if (rowdata.parentRef && rowdata.dataLevel == "Sku") {
            var intmParentRow = _this.productGrid.getRow(rowdata.referrence);
            if (intmParentRow.dataLevel == "Intm") {
                _this.updateParentRowStatus(intmParentRow, fieldName, value);
                parentRow = _this.productGrid.getRow(intmParentRow.referrence);
            } else {
                parentRow = intmParentRow;
            }
        }
        if (parentRow) {
            _this.updateParentRowStatus(parentRow, fieldName, value);
        }
    }
    updateParentRowStatus(parentRow, fieldName, value) {
        var _this = this;
        var list = _this.getResChildRows(parentRow);
        var matchCount = 0;
        var valueStr = list[0][fieldName];
        for (var i = 0; i < list.length; i++) {
            if (["copywrite", "keywords"].indexOf(fieldName) > -1) {
                if (list[i][fieldName]) {
                    matchCount++;
                }
            } else {
                if (valueStr === list[i][fieldName]) {
                    matchCount++;
                }
            }
        }
        if (matchCount == list.length) {
            _this.productGrid.setCellValue(parentRow.productId, fieldName, value);
        }
    }
    /**
	 * Set Value as Sample when there is no swatch reference
	 */
    setValueSampleOption() {
        let args = this.addBackDetails, list;
        if (args.dataField === "swatchOnly") {
            this.productGrid.setCellValue(args.key, args.dataField, "Sample");
            var rowData = this.productGrid.getRow(args.key);
            list = this.copyToChildRows(rowData, [args.dataField]);
        }
        if (this.newRecordId) {
            return;
        }
        this.sendUpdatedOrAddedRecord(list);
    }
    /**
	* Set value or swatch reference colum when choose swatchOnly option
	 */
    setValueSwatchReference() {
        let args = this.addBackDetails, list;
        this.productGrid.setCellValue(args.key, "swatchReference", $(".grid-swatch-reference").val());
        var rowData = this.productGrid.getRow(args.key);
        list = this.copyToChildRows(rowData, ["swatchReference", "swatchOnly"]);
        if (this.newRecordId) {
            return;
        }

        this.sendUpdatedOrAddedRecord(list);
    }

    overwriteProductResData(res) {
        window.ProductGridDropDowns = res.dropdowns;
        var _this = this;
        /** Code written By Asaithambi for samples and swatchs */
        //Hardcode values for sample dropdowns, currently using description field instead of sample column for the development
        if (!res.dropdowns) {
            res.dropdowns = {};
        }
        /*if(res.data){
		  res.data.map(function(o,i){
			if(o.vendorId){
			  o.vendorId = o.vendorId.split('_')[1];
			}
		  });
		}*/
        res.dropdowns['swatchOnly'] = [{ fieldId: 'Sample', fieldName: "Sample" }, { fieldId: 'Swatch Only', fieldName: "Swatch Only" }, { fieldId: 'None', fieldName: "None" }];
        /*var vandorList = res.dropdowns['vendorId'];
		var items = vandorList.map(function(o,i){
			if(typeof(o)==="string"){
			  var val_arr = o.split('_');
			  return {fieldName:val_arr[0],fieldId:val_arr[1]}
			}else{
			  return o;
			}
		});
		res.dropdowns['vendorId']=items;*/
    }

    //it will add back the emptied field
    addBackTheField() {
        let args = this.addBackDetails;
        let _this = this;
        if (args && this.swatchArrayRef.indexOf(args.dataField) === -1) {
            this.productGrid.setCellValue(args.key, args.dataField, this.cellBeforeEdit);
        }
        else if (this.swatchArrayRef.indexOf(args.dataField) > -1) {
            _this.selectSingleRow(args.key);
            _this.productGrid.beginCellEdit(args.key, _this.swatchArrayRef[0]);
        }
    }

    parseForBackend(data) {
        var newObj,
            _this = this;
        if (typeof (data) === "object" && data.length) {
            // check for object reference when array is passed
            data.forEach((item, index) => (
                parseValues(item)
            ));
        } else {
            newObj = Object.assign({}, data);
            delete newObj.data;
            parseValues(newObj);
        }

        function parseValues(obj) {
            for (var item in obj) {
                if (obj[item] instanceof Date) {
                    obj[item] = obj[item].toISOString().replace(/(T|Z)/ig, ' ');
                } else if (item === "images" ||
					_this.jqWidgetReservedMembers.indexOf(item) > -1) {
                    delete obj[item];
                }
            }
        }

        return newObj;
    }

    /**common method to updates and adds new record
	  * @param {object} obj - expects the record object
	  */
    sendUpdatedOrAddedRecord(obj, isundo) {
        var requestData = [], newObj = null;
        if (Array.isArray(obj)) {
            obj.map((o, i) => {
                newObj = this.parseForBackend(o);
                requestData.push(newObj);
            });
        }
        else {
            newObj = this.parseForBackend(obj);
            requestData.push(newObj);
        }


        ProductServices.updateOrAddProduct(requestData)
            .then((res) => {
                if (isundo) {
                    NotificationManager({ message: "Undo Success", type: 1 });
                } else {
                    NotificationManager({ message: res.responseMessage, type: 1 });
                }
            })
            .catch(error => {
                NotificationManager({ message: error.responseMessage, type: 4 });
                console.log('productContainer helperComponents  product-grid-popup sendUpdatedOrAddedRecord API call failed', error);
            });
    }

    swatchUpdate(rowId, checked) {
        ProductServices.swatchUpdate([{ "id": rowId, "value": checked }])
            .then((response) => {
                console.log('productContainer helperComponents product-grid-popup swatchUpdate API call success data: ', response.data);
                let res = response.data;
            })
            .catch(error => {
                console.log('productContainer helperComponents  product-grid-popup swatchUpdate API call failed', error);
            });
    }

    /** ValidateRequiredFields checks all conditions are satisfied for new record,
	 *  when it turns true the record will be sent to backend to store in DB.
	 *  @param {array} requiredList - array of strings
	 *  @param {array} objectList - array of objects
	 *  @returns an object
	 */
    ValidateRequiredFields(requiredList, objectList) {
        let stopLoop = false;
        let notFilled = {};

        for (var i = 0; i < objectList.length; i++) {
            var item = objectList[i];
            if (stopLoop) break;
            for (var j = 0; j < requiredList.length; j++) {
                var fieldName = requiredList[j];
                if (item.hasOwnProperty(fieldName) && (!item[fieldName] || item[fieldName] === "Select Vendor" || item[fieldName] === "Select Category")) {
                    //if the required field is empty or undefined
                    stopLoop = true;
                    notFilled.uid = item.uid;
                    notFilled.fieldName = fieldName;
                    break;
                }
            }
        }

        return { isValid: !stopLoop, notFilled };
    }

    getUnfilledColumns(requiredList, objectList) {
        let stopLoop = false;
        let notFilled = [];
        for (var i = 0; i < objectList.length; i++) {
            var item = objectList[i];
            // if(stopLoop) break;
            for (var j = 0; j < requiredList.length; j++) {
                var fieldName = requiredList[j];
                if (item.hasOwnProperty(fieldName) && (!item[fieldName] || item[fieldName] === "Select Vendor" || item[fieldName] === "Select Category")) {
                    //if the required field is empty or undefined
                    stopLoop = true;
                    //  notFilled.uid = item.uid;
                    notFilled.push(fieldName);
                    // break;
                }
            }
        }

        return notFilled;
    }

    /** getColumnNames returns an object of undefined field Names
	 */
    getColumnNames() {
        var rowDetails = this.productGrid.getRows()[0];
        var obj = {};

        for (var k in rowDetails) {
            if (rowDetails.hasOwnProperty(k)) {
                if (k == 'vendorId') {
                    obj[k] = "Select Vendor";
                    // obj[k] = undefined;
                } else if (k == "catId") {
                    obj[k] = "Select Category";
                }
                else if (k == "images") {
                    obj[k] = [];
                }
                else {
                    obj[k] = undefined;
                }
            }
        }

        return obj;
    }

    /** it selects the passed row and unselects other selected rows
	  * @param {string} rowId - row unique ID
	  */
    selectSingleRow(rowId) {
        var _this = this,
            selectedRows = this.productGrid.getSelection(),
            isRowIdPresent = false;

        selectedRows.forEach((item, index) => {
            if (item.uid !== rowId) {
                _this.productGrid.unselectRow(item.uid);
            } else {
                isRowIdPresent = true;
            }
        });
        // if row id is not present in the selected list then select the rowid.
        if (!isRowIdPresent) {
            _this.productGrid.selectRow(rowId);
        }
    }
    // this will get focus to next mandatory field cell
    nextRequiredField() {
        let obj = this.requiredFieldsAlert;
        if (obj.notFilled && obj.notFilled.uid && obj.notFilled.fieldName) {
            //this.productGrid.selectRow(obj.notFilled.uid);
            this.selectSingleRow(obj.notFilled.uid);
            this.productGrid.beginCellEdit(obj.notFilled.uid, obj.notFilled.fieldName);
        }
    }



    sortDropDown() {
        var container = '<div id="drop-down" class="pd-hl"></div>';
        $('body').append(container);
        var list = this.generateDropDowns();

        $('#drop-down').append(list);
    }

    generateDropDowns() {
        var list = [{
            name: 'Sort 0-9, A -> Z',
            val: 'asc'
        }, {
            name: 'Sort 9-0, Z -> A',
            val: 'desc'
        }, {
            name: 'Clear',
            val: null
        }];

        var li = '';
        list.forEach((item, index) => {
            li += '<li class="product-sort-options" data-val="' + item.val + '">' + item.name + '</li>';
        });

        var ul = "<ul class='sort-holder'>" + li + "</ul>";

        return ul;
    }

    updateUploadedImage(obj) {
        console.log('Image Object');
        console.log(obj.data);
        this.productGrid.setCellValue(obj.rowId, obj.fieldName, obj.data);
        this.productGrid.beginCellEdit(obj.rowId, 'productId');
        this.productGrid.endCellEdit(obj.rowId, 'productId');
        if (obj.data.length == 1) {
            $('.product-image[data-row="' + obj.rowId + '"]').removeClass('empty');
            $('.product-image[data-row="' + obj.rowId + '"]').html('<img src="' + obj.data[0].imageUrl + '"/>');
        }
    }

    //Product Options
    updateParentRow(rowid, data) {
        // Updating Parent row
        if (data.parentData) {
            this.productGrid.updateRow(rowid, data.parentData);
        }

        // adding new children row
        if (data.childData.length > 0) {
            // For intermediate products
            data.childData.map((value) => {
                if (value.dataLevel === 'Intm') {
                    this.productGrid.addRow(value.productId, value, 'last', rowid);
                }
            });

            // For SKU level products
            data.childData.map((value) => {
                if (value.dataLevel === 'Sku') {
                    this.productGrid.addRow(value.productId, value, 'last', value.referrence);
                }
            });
        }
    }

    setCampaignCell(productId, fieldName, value) {
        var _this = this;
        _this.productGrid.setCellValue(productId, fieldName, value);
    }

    render() {
        let props = this.props;
        let gridHeight = 300;
        let editSettings = {
            saveOnPageChange: true,
            saveOnBlur: true,
            saveOnSelectionChange: true,
            cancelOnEsc: true,
            saveOnEnter: true,
            editSingleCell: true,
            editOnDoubleClick: true,
            editOnF2: true
        };
        let modalClass = (this.state.modalName === 'addCopyModel') ?
            'addCopyModel' : (this.state.modalName === 'addKeywordModal')
                ? 'addKeywordModal' : (this.state.modalName === "SampleModal") ? "SampleModalPopup" : "";
        if (this.state.modalName === "ProductGridPopup") {
            modalClass = "ProductGridPopup";
        }
        let slideStyle = {
            height: gridHeight - 15
        };
        let listBxStyle = { "display": "inline-block", "vertical-align": "top" };
        return (

            <div>
                <div className="product-treegrid-popup">
                    {!this.state.isLoaded &&
						<div className="grid-empty-message">No products available</div>
                    }
                    <div>
                        <div style={listBxStyle} id="jqxlistbox"></div>
                        {/* <div className="productTreeGridPopup">
							{this.state.isLoaded &&
							<JqxTreeGrid ref={(grid)=> {this.productGrid = grid}}
								width={'99.5%'} height={gridHeight} source={this.gridData.dataAdapter}
								columns={this.gridData.columns}  editSettings={editSettings}
								checkboxes={true} editable={true} autoRowHeight={false} pageable={false}
							hierarchicalCheckboxes={true} columnsResize={true} sortable={false}
							autowidth={true}  theme={'light'} columnsReorder={true}
							/>
							}
						</div>  */}
                        {this.state.isLoaded && this.state.isModalOpen &&
							<Modal isOpen={this.state.isModalOpen} addClass={modalClass}>
							    <SwithTheModal piPdfUrl={this.state.piPdfUrl} modalClose={this.modalClose} modalName={this.state.modalName}
							        openPIUploadPopup={this.openPIUploadPopup}
							        nextField={this.nextRequiredField}
							        addBackTheField={this.addBackTheField} productId={this.productId} productData={this.productData}
							        keywordData={this.keywordData} copyData={this.copyData} childProdList={this.childProdList} productGridPopupType={this.productGridPopupType}
							        currentRowData={this.currentRowData} childRowData={this.childRowData} levelType={this.levelType} imageInfo={this.imageProductInfo}
							        setValueSampleOption={this.setValueSampleOption} swatchReferenceList={this.swatchReferenceList}
							        setValueSwatchReference={this.setValueSwatchReference} vendorNameList={this.vendorNameList}
							        updateProductForPO={this.updateProductForPO} productGrid={this.productGrid} setValueSwatchonly={this.setValueSwatchonly}
							        dialog={this.state.dialog} validationData={this.state.validationData} confirmApproveSubmit={this.updateAttrCompleted}
							        updateImage={this.updateUploadedImage} mandatoryAddColumns={this.mandatoryAddColumns} vendorId={this.vendorId} updatePIRecived={this.updatePIRecived}
							        piReceivedModalClose={this.piReceivedModalClose} updateParentRow={this.updateParentRow}
							        setCellValueAndCopyToChild={this.setCellValueAndCopyToChild} loadCampaignEvents={this.loadCampaignEvents} campaignProduct={this.campaignProduct} setCampaignCell={this.setCampaignCell}
							    />
							</Modal>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
/* SwithTheModal class will change the modal based on the state.modalName .
*/
class SwithTheModal extends Component {
    render() {
        let props = this.props;
        switch (props.modalName) {
            case "image":
                return <ImageListerModal modalClose={props.modalClose} {...props} />;
            case "requiredFieldConfirm":
                return <RequiredFieldsComfirm {...props} />;
            case "addCopyModel":
                return <AddCopyModel {...props} />;
            case "addKeywordModal":
                return <AddKeywordModal {...props} />;
            case "emptyMandatoryfield":
                return <MandatoryFieldPopup {...props} />;
            case "SampleModal":
                return <SampleModal {...props} />;
            case "AddPOModal":
                return <AddPOModal {...props} />;
            case "sampleMessagePopup":
                return <SampleMessagePopup {...props} />;
            case "sampleSwatchDropDown":
                return <SampleSwatchDropDown {...props} />;
            case "PiUploadModal":
                return <PiUploadModal {...props} />;
            case "submitConfirm":
                return <SubmitConfirm {...props} />;
            case "addVendorModal":
                return <AddVendorModal {...props} />;
            case "productOption":
                return <ProductOptionModal {...props} />;
            case "copyordisplayLevel":
                return <CopyDisplayModal {...props} />;
            case "ShotAngles":
                return <ShotAngles {...props} />;
            case "CampaignModal":
                return <CampaignModal {...props} />;
            default:
                return null;
        }
    }
}

/**
 *
 */
class PiUploadModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            errorMsg: '',
            isValid: false,
            pdfUrl: this.props.piPdfUrl
        };

        this.closePIModal = this.closePIModal.bind(this);
    }

    onFileChange(event) {
        let _this = this;
        _this.setState({ isError: false });
        let file = document.getElementById('pi-file');
        let last = file.files[0].name.substring(file.files[0].name.lastIndexOf(".") + 1, file.files[0].name.length);
        if (last != 'csv' && last != 'pdf') {
            _this.setState({ isError: true, errorMsg: "Invalid file format.  Please upload files in CSV, JSON or PDF format." });
            _this.setState({ isValid: false });
            $(file).val('');
        } else {
            _this.setState({ isValid: true });
        }
    }

    uploadFile(event) {
        let _this = this;
        let file = document.getElementById('pi-file');
        let last = file.files[0].name.substring(file.files[0].name.lastIndexOf(".") + 1, file.files[0].name.length);
        if (last === 'pdf') {
            let formdata = new FormData();
            formdata.append('files', file.files[0]);
            formdata.append('productIdLst', _this.props.productId);


            let headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json'
            };
            axios({
                method: 'post',
                url: require('../../common/configurations.js').product.importFilePIUploadpdf,
                headers: headers,
                data: formdata
            })
                .then((response) => {
                    console.log('productContainer helperComponents footer API call success data: ', response.data);
                    let res = response.data;

                    if (data.status) {
                        NotificationManager({ message: "PI has been updated.", type: 1 });
                        _this.setState({ isValid: false, pdfUrl: data.data.pdfUrl });
                        document.getElementById("piupload-form").reset();
                    } else {
                        NotificationManager({ message: data.responseMessage, type: 4 });
                    }
                })
                .catch(error => {
                    console.log('productContainer helperComponents footer API call failed', error);
                    NotificationManager({ message: "Failed to update PI.", type: 4 });
                });
        } else {
            let formdata = new FormData();
            formdata.append('files', file.files[0]);
            formdata.append('productIdLst', _this.props.productId);



            let headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json'
            };
            axios({
                method: 'post',
                url: require('../../common/configurations.js').product.importFilePIUpload,
                headers: headers,
                data: formdata
            })
                .then((response) => {
                    console.log('productContainer helperComponents footer API call success data: ', response.data);
                    let res = response.data;

                    if (data.status) {
                        NotificationManager({ message: data.responseMessage, type: 1 });
                        _this.setState({ isValid: false, pdfUrl: '' });
                        _this.props.piReceivedModalClose(true);
                    } else {
                        NotificationManager({ message: data.responseMessage, type: 4 });
                    }
                })
                .catch(error => {
                    console.log('productContainer helperComponents footer API call failed', error);
                    NotificationManager({ message: "Failed to update PI.", type: 4 });
                });
        }
    }

    closePIModal(key) {
        this.setState({ pdfUrl: '' });
        this.props.piReceivedModalClose(key, this.props.productId);
    }
    downloadFile() {
        utils.openInNewTab(this.state.pdfUrl);
    }

    deleteFile() {
        let productArray = this.props.productId;
        let _this = this;


        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.deletePiForProduct(productArray)
            .then((response) => {
                console.log('productContainer helperComponents footer API call success data: ', response.data);
                let res = response.data;

                if (res.status) {
                    _this.setState({ pdfUrl: '' });
                    NotificationManager({ message: res.responseMessage, type: 1 });
                } else {
                    NotificationManager({ message: res.responseMessage, type: 4 });
                    //_this.piReceivedModalClose(true,'');
                }
            })
            .catch(error => {
                console.log('productContainer helperComponents footer API call failed', error);
            });
    }

    componentWillReceiveProps(props) {
        this.setState({ pdfUrl: props.piPdfUrl });
    }
    deleteFile() {
        let productArray = this.props.productId;
        let _this = this;

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.deletePiForProduct(productArray)
            .then((response) => {
                console.log('productContainer helperComponents footer API call success data: ', response.data);
                let res = response.data;

                if (res.status) {
                    _this.setState({ pdfUrl: '' });
                    NotificationManager({ message: res.responseMessage, type: 1 });
                } else {
                    NotificationManager({ message: res.responseMessage, type: 4 });
                }
            })
            .catch(error => {
                console.log('productContainer helperComponents footer API call failed', error);
            });
    }

    componentWillReceiveProps(props) {
        this.setState({ pdfUrl: props.piPdfUrl });
    }


    render() {
        return (
            <div>
                <div className="modal-header">
					Import PI from file
                    <span className="close" onClick={() => this.closePIModal(false)}>x</span>
                </div>
                <div className="modal-content">
                    <div className="pi-upload-form">
                        <form id="piupload-form" enctype="multipart/form-data" multiple>
                            <div className="form-container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <input type="file" id="pi-file" onChange={this.onFileChange.bind(this)} />
                                            {this.state.isError &&
												<span className="error-msg">
												    {this.state.errorMsg}
												</span>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <input type="button" disabled={(this.state.isValid) ? false : true} className="gray-button" value="Upload Records" onClick={this.uploadFile.bind(this)} />
                                <input type="button" disabled={(this.state.pdfUrl !== '') ? false : true} className="gray-button" value="Download" onClick={this.downloadFile.bind(this)} />
                                <input type="button" disabled={(this.state.pdfUrl !== '') ? false : true} className="gray-button" value="Delete" onClick={this.deleteFile.bind(this)} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

class RequiredFieldsComfirm extends Component {
    render() {
        let props = this.props;
        return (
            <div >
                <div className="modal-content">
                    <div className="text-center confirm-delete">
                        <h4>Please provide the following mandatory fields to save the product to the grid
                            {/* You have not filled all mandatory fields, Please choose your action */}
                        </h4>
                        <p> "{this.props.mandatoryAddColumns.join(", ")}"</p>
                    </div>
                    <div className="modal-footer text-center">
                        <button className="gray-button" onClick={() => {
                            props.modalClose();
                            props.nextField();
                        }
                        }>Continue Editing</button>
                        <button className="gray-button" onClick={() => {
                            props.modalClose();
                            props.requireDelete();
                        }
                        }>Delete New Record</button>
                    </div>
                </div>
            </div>
        );
    }
}

class MandatoryFieldPopup extends Component {
    render() {
        let props = this.props;

        return (
            <div className="modal-content">
                <div className="text-center confirm-delete">
                    <h4>Mandatory field should not be empty</h4>
                </div>
                <div className="modal-footer text-center">
                    <button className="gray-button"
                        onClick={() => {
                            props.addBackTheField();
                            props.modalClose();
                        }}>
						ok
                    </button>
                </div>
            </div>
        );
    }
}

class SampleMessagePopup extends Component {
    render() {
        let props = this.props;

        return (
            <div className="modal-content">
                <div className="text-center confirm-delete">
                    <h4>Items with samples are required before 'Swatch Only' can be set</h4>
                </div>
                <div className="modal-footer text-center">
                    <button className="gray-button"
                        onClick={() => {
                            props.setValueSampleOption();
                            props.modalClose();
                        }}>
						ok
                    </button>
                </div>
            </div>
        );
    }
}
class SampleSwatchDropDown extends Component {
    render() {
        let props = this.props;
        let swatchReferenceList = props.swatchReferenceList;
        let MakeItem = function (x) {
            return <option value={x}>{x}</option>;
        };
        return (
            <div className="modal-content">
                <div className="text-center confirm-delete">
                    <p>Please select swatch reference</p>
                    <select className="grid-swatch-reference">{swatchReferenceList.map(MakeItem)}</select>
                </div>
                <div className="modal-footer text-center">
                    <button className="gray-button"
                        onClick={() => {
                            props.setValueSwatchReference();
                            props.modalClose();
                        }}>
						ok
                    </button>
                </div>
            </div>
        );
    }
}

class ImageListerModal extends Component {
    render() {
        let props = this.props;
        return (
            <div>
                <div className="modal-header">
					Pictures
                    <span className="close" onClick={this.props.modalClose}>x</span>
                </div>
                <div className="modal-content product-pictures">
                    {/* Large images will come here */}
                    <VerticalImageViewer {...props} />
                </div>
            </div>
        );
    }
}

const ProdGrid1 = ProductGridPopup;

export default ProdGrid1;
