
import React from 'react';
import NotificationManager from '../../common/components/notification';
import axios from 'axios';
/**
 * this is a custom dropdown editor renderer for all the dropdowns on the products grid.
 *
 */

export class CustomDropdownEditor extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            searchValue: null,
            searchedValues: [],
            dropdownList: [],
            dropdownType: null,
        };
    }

    componentWillMount() {
        if (this.props.vendorsList) {
            this.setState({ dropdownList: this.props.vendorsList, dropdownType: 'vendor' });
        } else if (this.props.colorsList) {
            this.setState({ dropdownList: this.props.colorsList, dropdownType: 'color' });
        } else if (this.props.buyersList) {
            this.setState({ dropdownList: this.props.buyersList, dropdownType: 'buyer' });
        } else if (this.props.sizeList) {
            this.setState({ dropdownList: this.props.sizeList, dropdownType: 'size' });
        } else if (this.props.samplesList) {
            this.setState({ dropdownList: this.props.samplesList, dropdownType: 'samples' });
        } else if (this.props.attrDropdownValues) {
            this.setState({ dropdownList: this.props.attrDropdownValues, dropdownType: 'attr' });
        } else if (this.props.SampleTypeDropdownVals) { // added for the sampleType dropdown on the samples grid
            this.setState({ dropdownList: this.props.SampleTypeDropdownVals, dropdownType: 'sampleType' });
        } else if (this.props.userDropdownData) { // added for the sampleType dropdown on the samples grid
            this.setState({ dropdownList: this.props.userDropdownData, dropdownType: 'messageDropdown' });
        }
    }

    searchText(event) {
        const searchArr = [];
        const value = event.target.value.toLowerCase();
        const switchDropdown = this.state.dropdownType;
        switch (switchDropdown) {
            case 'vendor':
                this.state.dropdownList.forEach(function (row) {
                    if ((row.fieldName).toLowerCase().indexOf(value) != -1) {
                        searchArr.push(row);
                    }
                });
                break;

            case 'color':
                this.state.dropdownList.forEach(function (row) {
                    if ((row).toLowerCase().indexOf(value) != -1) {
                        searchArr.push(row);
                    }
                });
                break;

            case 'buyer':
                this.state.dropdownList.forEach(function (row) {
                    if ((row).toLowerCase().indexOf(value) != -1) {
                        searchArr.push(row);
                    }
                });
                break;

            case 'size':
                this.state.dropdownList.forEach(function (row) {
                    if ((row).toLowerCase().indexOf(value) != -1) {
                        searchArr.push(row);
                    }
                });
                break;

            case 'sampleType':
                this.state.dropdownList.forEach(function (row) {
                    if ((row).toLowerCase().indexOf(value) != -1) {
                        searchArr.push(row);
                    }
                });
                break;

            case 'messageDropdown':
                this.state.dropdownList.forEach(function (row) {
                    if ((row).toLowerCase().indexOf(value) != -1) {
                        searchArr.push(row);
                    }
                });
                break;
            default:
        }

        this.setState({ searchValue: event.target.value, searchedValues: searchArr, selectedVal: '' });
    }

    onMouseLeave(event) {
        this.props.api.stopEditing();
    }

    handleChange(event) {
        const rowNode = this.props.node;
        const rowData = this.props.node.data;
        const switchDropdown = this.state.dropdownType;

        // moving this up here so its called before the switch
        // for the dropdowns being and variant popup is opened
        if (event.currentTarget.dataset.id == 'Create Variant') {
            this.props.openMultiProductPopup(this.props.node.data);
            return;
        }

        switch (switchDropdown) {
            case 'vendor':
                if (event.currentTarget.dataset.id == 'Add New Vendor') {
                    this.props.openAddNewVendorPopup(this.props.node);
                    return;
                }
                let vendor = '';
                this.state.dropdownList.forEach(function (obj) {
                    if (obj.fieldName === event.currentTarget.dataset.id) {
                        vendor = obj.fieldId;
                    }
                });
                if (rowData['vendorName'] != event.currentTarget.dataset.id) {
                    rowData['vendorId'] = vendor;
                    rowData['vendorName'] = event.currentTarget.dataset.id;
                    this.props.rowUpdate(rowData, this.props.node.rowIndex);
                    this.props.changeSelectedValue(event.currentTarget.dataset.id);
                }
                break;

            case 'color':
                if (rowData[this.props.column.colId] != event.currentTarget.dataset.id) {
                    rowData[this.props.column.colId] = event.currentTarget.dataset.id;
                    this.props.rowUpdate(rowData, this.props.node.rowIndex);
                    this.props.changeSelectedValue(event.currentTarget.dataset.id);
                }
                break;

            case 'size':
                if (rowData[this.props.column.colId] != event.currentTarget.dataset.id) {
                    rowData[this.props.column.colId] = event.currentTarget.dataset.id;
                    this.props.rowUpdate(rowData, this.props.node.rowIndex);
                    this.props.changeSelectedValue(event.currentTarget.dataset.id);
                }
                break;

            case 'buyer':
                if (rowData[this.props.column.colId] != event.currentTarget.dataset.id) {
                    rowData[this.props.column.colId] = event.currentTarget.dataset.id;
                    this.props.rowUpdate(rowData, this.props.node.rowIndex);
                    this.props.changeSelectedValue(event.currentTarget.dataset.id);
                }
                break;

            case 'attr':
                if (rowData[this.props.column.colId] != event.currentTarget.dataset.id) {
                    rowData[this.props.column.colId] = event.currentTarget.dataset.id;
                    this.props.rowUpdate(rowData, this.props.node.rowIndex);
                    this.props.changeSelectedValue(event.currentTarget.dataset.id);
                }
                break;

            case 'sampleType':
                if (rowData[this.props.column.colId] != event.currentTarget.dataset.id) {
                    rowData[this.props.column.colId] = event.currentTarget.dataset.id;
                    // this.props.rowUpdate(rowData, this.props.node.rowIndex);
                    this.props.changeSelectedValue(event.currentTarget.dataset.id);
                }
                break;

            case 'messageDropdown':
                if (rowData[this.props.column.colId] != event.currentTarget.dataset.id) {
                    rowData[this.props.column.colId] = event.currentTarget.dataset.id;
                    // this.props.rowUpdate(rowData, this.props.node.rowIndex);
                    this.props.changeSelectedValue(event.currentTarget.dataset.id);
                }
                break;

            case 'samples':
                console.log('case samples: ', rowData[this.props.column.colId], event.currentTarget.dataset.id);
                let _this = this;
                let value = event.currentTarget.dataset.id;
                if (rowData[this.props.column.colId] != event.currentTarget.dataset.id) {
                    if (event.currentTarget.dataset.id === 'Sample') {
                        let reqObject = {
                            'productId': this.props.node.data['productId'],
                            'styleNumber': this.props.node.data['styleNumber'],
                            'displayLevel': this.props.node.data['displayLevel'],
                            'sampleOption': 'Sample',
                            'swatchReference': '',
                        };
                        const headers = {
                            'Authorization': this.props.user.token,
                            'content-type': 'application/json',
                        };
                        axios({
                            method: 'post',
                            url: require('../../common/configurations').product2.updateSwatchReference,
                            headers: headers,
                            data: reqObject,
                        })
                            .then((response) => {
                                console.log('swatchReferenceModal.js handleOk : ', response);

                                if (response.responseData.status === 'success') {
                                    _this.props.changeSelectedValue(value);
                                } else {
                                    NotificationManager({ message: response.data.message, type: 4 });
                                }
                            })
                            .catch((error) => {
                                console.log('Server Error Failed', error);
                            });
                        this.props.changeSelectedValue(event.currentTarget.dataset.id);
                        rowNode.setDataValue('swatchReference', '');
                    }

                    if (event.currentTarget.dataset.id === 'Swatch Only') {
                        rowNode.setDataValue('sampleOptions', 'Swatch Only');
                        this.props.openSwatchPopup(rowNode);
                    }

                    this.props.changeSelectedValue(event.currentTarget.dataset.id);
                }

                break;
            default:
        }
    }

    render() {
        const defaultList = this.state.dropdownList;
        const searchedList = this.state.searchedValues;
        const showValues = (searchedList.length > 0) ? searchedList : defaultList;
        let dispVariantCTA = this.props.colDef.productVariant;

        // eslint-disable-next-line consistent-return
        const dropdownListValues = showValues.map((obj, index) => {
            if (this.state.dropdownType == 'vendor') {
                if (obj.fieldName == this.props.selectedVal) {
                    return <li className="agCustomDropdownItems agCustomDropdownSelected" onClick={this.handleChange.bind(this)} key={index} data-id={obj['fieldName']}>{obj['fieldName']}</li>;
                } else {
                    return <li className="agCustomDropdownItems" onClick={this.handleChange.bind(this)} key={index} data-id={obj['fieldName']}>{obj['fieldName']}</li>;
                }
            }

            if (this.state.dropdownType == 'color'
            || this.state.dropdownType == 'buyer'
            || this.state.dropdownType == 'size'
            || this.state.dropdownType == 'samples'
            || this.state.dropdownType == 'attr'
            || this.state.dropdownType == 'sampleType'
            || this.state.dropdownType == 'messageDropdown') {
                if (obj == this.props.selectedVal) {
                    return <li className="agCustomDropdownItems agCustomDropdownSelected" onClick={this.handleChange.bind(this)} key={index} data-id={obj}>{obj}</li>;
                } else {
                    return <li className="agCustomDropdownItems" onClick={this.handleChange.bind(this)} key={index} data-id={obj}>{obj}</li>;
                }
            }
        });
        return (
            <div
                className="ag-DropdownSearchContainer"
                style={{ width: this.props.width }}
                onMouseLeave={this.onMouseLeave.bind(this)}
            >
                {this.state.dropdownList.length > 5 &&
                    <input type="text" className="ag-DropdownSearchInput" placeholder="Search.." onChange={this.searchText.bind(this)}></input>
                }

                <ul style={{ width: '100%' }} className="agCustomDropdown">
                    {dropdownListValues}
                </ul>
                {
                    dispVariantCTA &&
                        <div style={{ width: this.props.width }} className="create-variant" onClick={this.handleChange.bind(this)} data-id="Create Variant">Create Variant</div>
                }
            </div>
        );
    }
}
