import React from 'react';
import PropTypes from 'prop-types';

import FilterStrip from './helperComponents/filter-strip';
// import JqxTreeGrid from '../common/vendor/jqwidgets-react/react_jqxgrid_tree';

import Modal from '../modals/common-modal.js';
import SwitchTheModal from './switchTheModal';
import ImageApprovalModal from './helperComponents/image-approval';
import ProductFooter from './helperComponents/footer';
import Find from './helperComponents/find';
import FilterBy from './helperComponents/filterby';

import RightDrawer from '../rightDrawer/rightDrawerContainer';


const ProductComponent = ({
    state,
    props,

    // for JqxTreeGrid
    productGridRef,
    gridData,
    gridHeight,
    gridWidth,
    productThis,
    isCheckedAllRows,
    rowsheight,
    groupsList,
    pinnedColumnsList,
    sortcolumn,
    sortdirection,
    appliedGridFiltersList,

    // for Modal class
    modalClass,

    // below for SwitchTheModal
    modalClose,
    currentRowData,
    imageProductInfo,
    setCellValueAndSaveProduct,
    deleteSelectedRecord,
    openPIUploadPopup,
    deleteRequireConfirmed,
    nextRequiredField,
    parentRef,
    currentProductCatID,
    addBackTheField,
    productId,
    productData,
    keywordData,
    copyData,
    childProdList,
    productGridPopupType,
    childRowData,
    levelType,
    setValueSampleOption,
    swatchReferenceList,
    setValueSwatchReference,
    vendorNameList,
    updateProductForPO,
    productGrid,
    setValueSwatchonly,
    updateAttrCompleted,
    updateUploadedImage,
    mandatoryAddColumns,
    vendorId,
    updatePIRecived,
    piReceivedModalClose,
    updateParentRow,
    campaignProduct,
    setCellValueAndCopyToChild,
    loadCampaignEvents,
    currentFieldText,
    removeClonedList,
    colorList,
    setCampaignCell,
    productEditing,
    channel,
    // below for ImageApprovalModal
    imgApprovalModalClose,
    ppidLst,
    chldProuctIds,
    // below for ProductFooter
    undoLastChanges,
    sourceFrom,
    isUndoEnable,
    reloadGrid,
    isfilterApplied,
    filterchanged,
    totalRows,
    // for Find
    FindRef,

    FilterByRef,
    closefilterBy,
    open,
    filterFieldName,

    //for FilterBy
    // FilterByRef,
    // closefilterBy,
    // enableFilterBy,
    // filterFieldName,
    //for RightDrawer
    // toggleRightDrawer,
    // currentPage,
    // rebuildTheGrid,

}) => {
    return (

        <div>
            <div>
                <div className="product-treegrid">
                    {!state.isLoaded &&
                        <div className="grid-empty-message">Please Select filter or alert to proceed</div>
                    }
                    {state.isLoaded &&
                        <FilterStrip toggleSpinner={props.toggleSpinner} filters={props.appliedFilters} updateFilters={props.removeFilter} reloadGrid={reloadGrid} />
                    }
                    <div>

                        {/* <div className="productJqxTreeGrid">
                            {state.isLoaded &&
                                <JqxTreeGrid
                                    ref={ productGridRef }
                                    source= { gridData.results }
                                    height= { gridHeight }
                                    width = { gridWidth }
                                    datafields= { gridData.datafields }
                                    gridIdType= { gridData.gridIdType }
                                    columns= { gridData.columns }
                                    product = { productThis }
                                    treegridcurrentPos= { state.treegridcurrentPos }
                                    isCheckedAllRows= { isCheckedAllRows }
                                    rowsheight= { rowsheight }
                                    virtualmode= {true}
                                    groupable= { true }
                                    groupsrenderer= { gridData.groupsrenderer }
                                    groupsList= { groupsList }
                                    pinnedColumnsList= { pinnedColumnsList }
                                    sortcolumn= { sortcolumn }
                                    sortdirection= { sortdirection }
                                    appliedGridFiltersList= { appliedGridFiltersList }
                                    enablehover = {true}
                                    columnsresize = {true}
                                />
                            }
                        </div> */}
                        {state.isLoaded && state.isModalOpen &&
                            <Modal isOpen={state.isModalOpen} addClass={modalClass}>
                                <SwitchTheModal
                                    piPdfUrl={state.piPdfUrl}
                                    modalClose={modalClose}
                                    modalName={state.modalName}
                                    currentRowData={currentRowData}
                                    imageInfo={imageProductInfo}
                                    setCellValueAndSaveProduct={setCellValueAndSaveProduct}
                                    deleteSelectedRecord={deleteSelectedRecord}
                                    openPIUploadPopup={openPIUploadPopup}
                                    requireDelete={deleteRequireConfirmed}
                                    nextField={nextRequiredField}
                                    parentRef={parentRef}
                                    categoryId={currentProductCatID}
                                    addBackTheField={addBackTheField}
                                    productId={productId}
                                    productData={productData}
                                    dropdowns={props.ProductGridDropDowns}
                                    keywordData={keywordData}
                                    copyData={copyData}
                                    childProdList={childProdList}
                                    productGridPopupType={productGridPopupType}
                                    childRowData={childRowData}
                                    levelType={levelType}
                                    setValueSampleOption={setValueSampleOption}
                                    swatchReferenceList={swatchReferenceList}
                                    setValueSwatchReference={setValueSwatchReference}
                                    vendorNameList={vendorNameList}
                                    updateProductForPO={updateProductForPO}
                                    productGrid={productGrid}
                                    setValueSwatchonly={setValueSwatchonly}
                                    dialog={state.dialog}
                                    validationData={state.validationData}
                                    confirmApproveSubmit={updateAttrCompleted}
                                    updateImage={updateUploadedImage}
                                    mandatoryAddColumns={mandatoryAddColumns}
                                    vendorId={vendorId}
                                    updatePIRecived={updatePIRecived}
                                    piReceivedModalClose={piReceivedModalClose}
                                    updateParentRow={updateParentRow}
                                    campaignProduct={campaignProduct}
                                    setCellValueAndCopyToChild={setCellValueAndCopyToChild}
                                    loadCampaignEvents={loadCampaignEvents}
                                    currentFieldText={currentFieldText}
                                    queryForOptions={state.queryForOptions}
                                    removeClonedList={removeClonedList}
                                    colorList={colorList}
                                    setCampaignCell={setCampaignCell}
                                    productEditing={productEditing}
                                    channel={channel}
                                    {...props}
                                />
                            </Modal>
                        }

                        {state.isImageModalOpen &&
                            <ImageApprovalModal
                                isModalOpen={state.isImageModalOpen}
                                modalClose={imgApprovalModalClose}
                                ppidLst={ppidLst}
                                parentId={chldProuctIds}
                            />
                        }
                    </div>
                </div>

                <div className="sortbyDropDown topFilterPos"></div>

                <ProductFooter
                    user={props.user}
                    undoLastChanges={undoLastChanges}
                    sourceFrom={sourceFrom}
                    isUndoEnable={isUndoEnable}
                    reloadGrid={reloadGrid}
                    productGrid={productGrid}
                    isfilterApplied={state.isfilterApplied}
                    filterchanged={state.filterchanged}
                    product={productThis}
                    totalRows={state.totalRows}
                    selectedRows={state.selectedRows}
                    storeGridData={props.storeGridData}
                    toggleSpinner={props.toggleSpinner}
                />

                <Find
                    ref={FindRef}
                    productGrid={productGrid}>
                </Find>

                <FilterBy
                    ref={FilterByRef}
                    productGrid={productGrid}
                    closefilterBy={closefilterBy}
                    fieldName={filterFieldName}
                    open={state.enableFilterBy}>
                </FilterBy>

            </div>




        </div>
    );
};

ProductComponent.propTypes = {

};

export default ProductComponent;



//    <RightDrawer
//        user={props.user}
//        pageType={currentPage}
//        toggleNotification= {props.toggleNotification}
//        notification = {props.notification}
//        {...props}
//    />
//                     open={enableFilterBy}>
