import React, { Component } from 'react';
import * as utils from '../../../common/utils';
import * as actions from '../../../product/redux/productActions';
import { connect } from 'react-redux';
import List from './list';
import * as p2Actions from '../../../product2/redux/product2Actions';

class ListViewer extends Component {
    constructor(props) {
        super(props);

        this.list = props.columnDetails;
        this.list.sort(function (a, b) {
            return a.order - b.order;
        });

        this.state = {
            search: '',
            list: this.list.slice()
        };

        this.preventClick = this.preventClick.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.checkboxChange = this.checkboxChange.bind(this);
    }

    componentDidMount() {
        this.setComparableVariables();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.columnListCount !== this.props.columnListCount) {
            var newList = Object.assign([], this.props.columnDetails);
            newList.sort(function (a, b) {
                return a.order - b.order;
            });

            this.setState({ list: newList });
            this.setComparableVariables();
            this.props.setViewFields(null);
        }
    }

    setComparableVariables() {
        this.list = this.props.columnDetails;
        this.baseList = this.createFlags(this.list);
        this.comparableList =  Object.assign({}, this.baseList);
    }

    /**
     * Create flag will generate an object with fieldname as key and visibility as value,
     * this represents the visibility of the fields in grid.
     */
    createFlags(list) {
        var obj = {};

        list.forEach((item, index) => {
            obj[item.fieldName] = item.visibility;
        });

        return obj;
    }

    handleInputChange(e) {
        e.stopPropagation();

        var target = e.target,
            value = target.value,
            name = target.name;

        this.setState({ [name]: value });
    }

    /** changes the state based on the field name input change
     *
     * @param {string} fieldName - field name
     * @param {boolean} checked
     */
    checkboxChange(fieldName, checked) {
        let newlist = this.state.list.slice();

        for (var i = 0; i < newlist.length; i++) {
            var item = newlist[i];

            if (item.fieldName === fieldName) {
                item.visibility = checked;
                this.comparableList[fieldName] = checked;

                // enable or disable the column based on checked value
                this.props.checkboxChangeObserver();

                if (checked) {
                } else {
                }

                this.checkForCheckboxChange();
                break;
            }
        }

        let updatedColumndetails  = this.state.list.map((val, idx) => {
            if (val.fieldName === fieldName) {
                this.state.list[idx].visibility = checked;
            }

            return this.state.list[idx];
        });

        this.props._REDUX_DISPATCH_addColumnDetails(updatedColumndetails);
        this.props.actionPerformedFrom({ changeFields: fieldName });

        this.setState({ list: newlist });
    }

    /** compares the reference array baseList and state.list, if any changes appears then
     *  enables the SAVE THIS VIEW option in view drop down
     */
    checkForCheckboxChange() {
        var isChanged = false;

        for (var field in this.baseList) {
            if (this.comparableList[field] !== this.baseList[field]) {
                this.props.setViewFields(this.state.list);

                isChanged = true;

                break;
            }
        }

        if (!isChanged) {
            this.props.setViewFields(null);
        }

        this.baseList[field] = this.comparableList[field];
    }

    preventClick(e) {
        e.stopPropagation();
    }

    render() {
        let props = this.props;
        let state = this.state;
        let filteredList = state.list;

        if (state.search.trim().length > 0) {
            let reg = new RegExp(state.search, "i");

            filteredList = state.list.filter((item, index) => {
                if (item.columnTitle != null && item.columnTitle.search(reg) > -1) {
                    return true;
                }
            });
        }

        return (
            <div className="list-viewer-holder ">
                <div className="search-field-holder">
                    <input
                        type="text"
                        value={state.search}
                        name="search"
                        placeholder="Search"
                        onChange={this.handleInputChange}
                        autoComplete="off"
                    />
                </div>
                <div className="list-scrollable scroll">
                    <List list={filteredList} {...props} inputChange={this.checkboxChange} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        _REDUX_PROPS_columnResequence: state.columnResequence
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        _REDUX_DISPATCH_addColumnDetails: (data) => {
            dispatch(actions.addColumnDetails(data));
        },
        actionPerformedFrom: (data) => {
            dispatch(p2Actions.actionPerformedFrom(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListViewer);
