import React, { Component } from 'react';

import CreateEventComponent from './event-creatorComponent';

class CreateEvent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            addEventData: '',
            eventOwner: this.props.user.userName
        };

        this.handleChange = this.handleChange.bind(this);
        this.getTodayDate = this.getTodayDate.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        var dateFormat = "mm/dd/yy";
        var dateToday = this.getTodayDate();

        let _this = this;

        var dueDate = $(".datepicker.duedate").datepicker({
            defaultDate: "+1w",
            changeMonth: true,
            changeYear: true,
            numberOfMonths: 1,
            yearRange: '(new Date).getFullYear():+10',
            dateFormat: dateFormat,
            minDate: dateToday
        });

        var eventDate = $(".datepicker.eventdate").datepicker({
            defaultDate: "+1w",
            changeMonth: true,
            changeYear: true,
            numberOfMonths: 1,
            yearRange: '(new Date).getFullYear():+10',
            dateFormat: dateFormat,
            minDate: dateToday,
        }).on("change", function () {
            eventEndDate.datepicker("option", "minDate", _this.getDate(this));
        });

        var eventEndDate = $(".datepicker.eventEndDate").datepicker({
            defaultDate: "+1w",
            changeMonth: true,
            changeYear: true,
            numberOfMonths: 1,
            yearRange: '(new Date).getFullYear():+10',
            dateFormat: dateFormat,
            minDate: dateToday,
        }).on("change", function () {
            eventDate.datepicker("option", "maxDate", _this.getDate(this));
        });

        if (prevProps.isOpen && !this.props.isOpen) {
            this.setState({ eventOwner: this.props.user.userName });
        }
    }

    getDate(element) {
        var dateFormat = "mm/dd/yy", date;

        try {
            date = $.datepicker.parseDate(dateFormat, element.value);
        } catch (error) {
            date = null;
        }

        return date;
    }

    getTodayDate() {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();

        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }

        today = mm + '/' + dd + '/' + yyyy;

        return today;
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;

        this.setState({ eventOwner: value });
    }

    render() {
        console.log('event-creator Container render: ', this.props);

        if (this.props.addEventData !== '') {
            var types = this.props.addEventData.eventTypes;

            //Generate Event type Select box
            var events = types.map((opt, opt_idx) => {
                return <option value={opt.eventTypes} key={opt_idx}>{opt.eventTypes}</option>;
            });

            var tasks = this.props.addEventData.listDropdowns;

            var taskHtml = tasks.map((val, index) => {
                return  (
                    <div className="task-row-container" key={index}>
                        <div className="task-column form-group label"><label id={"label" + index}>{val.label}</label></div>
                        <div className="task-column form-group">
                            {index == 0 ?
                                <select onChange={this.handleChange} value={this.state.eventOwner} className="owner-dropdwon form-control" id={"owner" + index}>
                                    <option value="0">Please select the owner</option>
                                    {val.values.map((val, i) => {
                                        return <option value={val} key={i}>{val}</option>;
                                    })
                                    }
                                    {val.label !== "Event Brief" &&
                                <option value="NA">N/A</option>
                                    }
                                </select>
                                :
                                <select className="owner-dropdwon form-control" id={"owner" + index}>
                                    <option value="0">Please select the owner</option>
                                    {val.values.map((val, i) => {
                                        return <option value={val} key={i}>{val}</option>;
                                    })
                                    }
                                    <option value="NA">N/A</option>
                                </select>
                            }
                        </div>
                        <div className="task-column form-group">
                            <input
                                id={"duedate" + index}
                                type="text"
                                placeholder="mm/dd/yyyy"
                                className="form-control datepicker duedate"
                                name="dueDate"
                                readOnly="readonly" />
                        </div>
                    </div>
                );
            });
        } else {
            return false;
        }

        return (
            <CreateEventComponent
                props = { this.props }
                events = {events}
                taskHtml = {taskHtml}
            />
        );
    }
}

export default CreateEvent;
