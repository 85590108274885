import {endpoint} from '../../common/REST/endpoints';
import {headers} from '../../common/REST/headers';
import request from '../../common/REST/request';

let CreativeServices = {
    gridData: function() {
        return request({
            method: endpoint.creative.gridData.method,
            url: endpoint.creative.gridData.url + '?viewBy=',
            headers: headers
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    getMaxId: function() {
        return request({
            method: endpoint.creative.getMaxId.method,
            url: endpoint.creative.getMaxId.url,
            headers: headers
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    deletePicturePacket: function(payload) {
        return request({
            method: endpoint.creative.deletePicturePacket.method,
            url: endpoint.creative.deletePicturePacket.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    getAcceptedImages: function(payload) {
        return request({
            method: endpoint.creative.getAcceptedImages.method,
            url: endpoint.creative.getAcceptedImages.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    acceptBuyerResult: function(payload) {
        return request({
            method: endpoint.creative.acceptBuyerResult.method,
            url: endpoint.creative.acceptBuyerResult.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    updatePhotographFlag: function(payload) {
        return request({
            method: endpoint.creative.updatePhotographFlag.method,
            url: endpoint.creative.updatePhotographFlag.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    updateLocation: function(payload) {
        return request({
            method: endpoint.creative.updateLocation.method,
            url: endpoint.creative.updateLocation.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },


    findTaskNameRslt: function(payload) {
        return request({
            method: endpoint.creative.findTaskNameRslt.method,
            url: endpoint.creative.findTaskNameRslt.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    sendAlertCreative: function(payload) {
        return request({
            method: endpoint.creative.sendAlertCreative.method,
            url: endpoint.creative.sendAlertCreative.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error.response;
            });
    },


    addShots: function(payload) {
        return request({
            method: endpoint.creative.addShots.method,
            url: endpoint.creative.addShots.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    deleteShot: function(payload) {
        return request({
            method: endpoint.creative.deleteShot.method,
            url: endpoint.creative.deleteShot.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    photographyComplete: function(payload) {
        return request({
            method: endpoint.creative.photographyComplete.method,
            url: endpoint.creative.photographyComplete.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    loadImages: function(payload) {
        return request({
            method: endpoint.creative.loadImages.method,
            url: endpoint.creative.loadImages.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    buyerReview: function(payload) {
        return request({
            method: endpoint.creative.buyerReview.method,
            url: endpoint.creative.buyerReview.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error.response;
            });
    },
};

export default CreativeServices;
