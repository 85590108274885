export const addGridData = (data) => {
    return {
        type:"ADD_GRID_DETAILS",
        data
    };
};

export const updateGridData = (updateData) =>{
    return {
        type:"UPDATE_GRID_DATA",
        updateData
    };
};

export const updateOrReplaceData = (newData) =>{
    return {
        type:"REPLACE_OR_UPDATE_GRID_DATA",
        newData
    };
};

export const updateSelectedRows = (data) => {
    return {
        type:"UPDATE_SELECTED_ROWS",
        data
    };
};

export const addProductRow = () =>{
    return {
        type:"ADD_PRODUCT_ROW"
    };
};

export const deleteSelectedProducts = () =>{
    return {
        type:"DELETE_SELECTED_PRODUCTS"
    };
};

export const cloneSelectedProducts = () =>{
    return {
        type:"CLONE_SELECTED_PRODUCTS"
    };
};


export const getFilteredProductData = (data) => {
    return {
        type: "GET_PRODUCT_DATA",
        data
    };
};

export const addColumnDetails = (data) => {
    return {
        type:"ADD_COLUMN_DETAILS",
        data
    };
};

export const gridDataChanged = () => {
    return {
        type:"GRID_DATA_CHANGED"
    };
};

export const gridColumnChanged = () => {
    return {
        type:"GRID_COLUMN_CHANGED"
    };
};

export const addGridDropDowns = (data) => {
    return {
        type:"ADD_GRID_DROP_DOWNS",
        data
    };
};

export const appliedFilters = (data) => {
    return {
        type:"APPLIED_FILTERS",
        data
    };
};

export const removeFilter = (data) => {
    return {
        type:"REMOVE_FILTERS",
        data
    };
};

export const updateCurrentView = (data) => {
    return {
        type:"UPDATE_CURRENT_VIEW",
        data
    };
};
export const toggleFind = (data)=>{
    return {
        type:"TOGGLE_FIND",
        data
    };
};

export const columnResequence = (data) => {
    return {
        type: "COLUMN_RESEQUENCE",
        data
    };
};

export const filterDataChanged = () => {
    return {
        type: "FILTER_DATA_CHANGED"
    };
};
