import React, { Component } from 'react';
import Modal from '../../modals/common-modal';
import ConfirmModal from '../../modals/confirm-modal.js';
import PacketDetails from './packetDetails';
import PacketPreview from './packetPreview';
import CancelChanges from './cancelChanges';
import PresentationServices from '../services/presentation-services';

import axios from 'axios';

import NotificationManager from '../../common/components/notification';

var utils = require('../../common/utils.js');

class CreatePicturePacketModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFinaliseOpen: false,
            isConfirmOpen: false,
            errorMessage: '',
            folio: {
                'folioName': '',
                'folioNotes': '',
                'folioFormat': '0',
                'picPacId': '-1'
            },
            constfolio: {
                'folioName': '',
                'folioNotes': '',
                'folioFormat': '0',
                'picPacId': '-1'
            },
            picPacUrl: '',
            totalPages: 0,
        };

        this.checkFolioCounter = true;
        this.toggleFinaliseOptions = this.toggleFinaliseOptions.bind(this);
        this.saveToPdf = this.saveToPdf.bind(this);
        this.saveToFuture = this.saveToFuture.bind(this);
        this.handleChangePic = this.handleChangePic.bind(this);
        this.validatePicPacForm = this.validatePicPacForm.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.closeConfirmModal = this.closeConfirmModal.bind(this);
        this.getUserFolioDetais = this.getUserFolioDetais.bind(this);
        this.saveOrUpdate = this.saveOrUpdate.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.generatePacketPreview = this.generatePacketPreview.bind(this);
        this.PreviewGeneration = this.PreviewGeneration.bind(this);
    }

    handleChangePic(event) {
        const target = event.target;

        let value = target.type === 'checkbox' ? target.checked : target.value;

        const name = target.name;

        if ((value.trim() === "" && name === "folioName") || (value.trim() === undefined && name === "folioName") || (value.trim() === '0' && name === "folioFormat")) {
            target.className += ' invalid';
        } else {
            target.classList.remove('invalid');
        }

        let { folio } = this.state;
        let newFolio = folio;
        newFolio[name] = value;

        this.setState({ folio: newFolio });

        //Update the Preview Based on Select value
        if (name === 'folioFormat') {
            if (value === '0') {
                let picPacUrl = '';
                let html = " <embed src='" + picPacUrl + "#toolbar=0' width='500px' height='400px' />";

                $('#embedHolder embed').remove();
                $('#embedHolder').append(html);

                _this.setState({ picPacUrl: pageUrl, totalPages: totalPages });
            } else {
                //Generate Packet Preview
                this.generatePacketPreview();
            }
        }
    }

    validatePicPacForm() {
        let data = this.state.folio;

        if (data.folioName.trim() === '' || data.folioFormat === '0') {
            this.setState({ errorMessage: '*Mandatory fields marked with an asterisk are missing.' });

            return false;
        } else if (data.folioName.trim().length > 30) {
            NotificationManager({
                message: "Please shorten the folio name to less than 30 characters",
                type: 4
            });

            return false;
        } else if (data.folioNotes.trim().length > 160) {
            NotificationManager({
                message: "Please shorten the folio notes to less than 160 characters",
                type: 4
            });

            return false;
        }

        let { folio } = this.state;
        let newFolio = folio;

        newFolio['folioName'] = newFolio['folioName'].trim();
        newFolio['folioName'] = newFolio['folioName'].replace(/\s\s+/g, ' ');
        this.setState({ folio: newFolio });

        newFolio['folioNotes'] = newFolio['folioNotes'].trim();
        newFolio['folioNotes'] = newFolio['folioNotes'].replace(/\s\s+/g, ' ');

        this.setState({ errorMessage: '' });

        return true;
    }

    toggleFinaliseOptions() {
        this.setState((prevState, props) => ({
            isFinaliseOpen: !prevState.isFinaliseOpen
        }));
    }

    //Function to save as PDF
    saveToPdf() {
        this.toggleFinaliseOptions();
        let validate = this.validatePicPacForm();

        if (validate) {
            this.saveOrUpdate('save');
        } else {
        }
    }

    //Function to Save to DB
    saveToFuture() {
        this.toggleFinaliseOptions();
        let validate = this.validatePicPacForm(event);

        if (validate) {
            this.saveOrUpdate();
        } else {
        }
    }

    modalClose() {
        if (this.didValueChanged(this.state.folio, this.state.constfolio)) {
            this.setState({ isConfirmOpen: true });
        } else {
            this.props.modalClose();
            this.checkFolioCounter = true;
        }
    }

    closeConfirmModal(action) {
        switch (action) {
            case 'save':
                this.setState({ isConfirmOpen: false });
                this.saveToFuture();
                break;

            case 'no':
                this.setState({ isConfirmOpen: false });
                this.props.modalClose();
                this.checkFolioCounter = true;
                break;

            case 'cancel':
                this.setState({ isConfirmOpen: false });
                break;

            default:
                this.setState({ isConfirmOpen: false });
                this.props.modalClose();
                this.checkFolioCounter = true;
                break;
        }
    }

    didValueChanged(intialData, changedData) {
        if (JSON.stringify(intialData) === JSON.stringify(changedData)) {
            return false;
        } else {
            return true;
        }
    }

    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }

        return true;
    }

    getUserFolioDetais() {
        //Service call to get the Picpac details for the selected list if user has already
        console.log('publication createPicturePacketModal: props: ', this.props);

        let postData = { "id": this.props.user };

        this.checkFolioCounter = false;

        let req = { 'userId': postData.id.userId, 'role': postData.id.defaultRole, 'listId': this.props.selectedListId, 'pageCount': 'one' };
        let _this = this;

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        PresentationServices.getUserFolio(req).then( (response) => {
            console.log('publication CreatePicturePacketModal getUserFolioDetais API call after makeCallToRequiredFields success data: ', response);

            let res = response;

            if (res.responseCode === 200) {
                //If user has saved already, Generate state variables with prefiiled value and Generate Preview else No action needed
                if (!_this.isEmpty(res.data)) {
                    let { folio } = _this.state;

                    let newFolio = folio;
                    newFolio['folioName'] = res.data.folio_name;
                    newFolio['folioNotes'] = res.data.folio_notes;
                    newFolio['folioFormat'] = res.data.report_id;
                    newFolio['picPacId'] = res.data.FolioId;

                    let { constfolio } = _this.state;

                    let newConstFolio = constfolio;
                    newConstFolio['folioName'] = res.data.folio_name;
                    newConstFolio['folioNotes'] = res.data.folio_notes;
                    newConstFolio['folioFormat'] = res.data.report_id;
                    newConstFolio['picPacId'] = res.data.FolioId;

                    let picPacUrl = res.data.fileName;
                    let totalPages = parseInt(res.data.NoOfPages);
                    let html = " <embed src='" + picPacUrl + "#toolbar=0' width='500px' height='400px' />";

                    setTimeout(function () {
                        $('#embedHolder embed').remove();
                        $('#embedHolder').append(html);
                    }, 1000);

                    _this.setState({
                        folio: newFolio,
                        constfolio: newConstFolio,
                        errorMessage: '',
                        picPacUrl: picPacUrl,
                        totalPages: totalPages
                    });
                } else {
                    let { folio } = _this.state;
                    let newFolio = folio;
                    newFolio['folioName'] = '';
                    newFolio['folioNotes'] = '';
                    newFolio['folioFormat'] = '0';
                    newFolio['picPacId'] = '-1';

                    let { constfolio } = _this.state;
                    let newConstFolio = constfolio;
                    newConstFolio['folioName'] = '';
                    newConstFolio['folioNotes'] = '';
                    newConstFolio['folioFormat'] = '0';
                    newConstFolio['picPacId'] = '-1';

                    let picPacUrl = '';
                    let totalPages = 0;

                    _this.setState({
                        folio: newFolio,
                        constfolio: newConstFolio,
                        errorMessage: '',
                        picPacUrl: picPacUrl,
                        totalPages: totalPages
                    });
                }
            } else {
                NotificationManager({ message: res.responseMessage, type: 4 });

                _this.modalClose();
            }
        })
            .catch( error => {
                console.log('publication CreatePicturePacketModal getUserFolioDetais API call failed', error);

                NotificationManager({
                    message: 'Some error Occurred. Please try again.',
                    type: 4
                });

                _this.modalClose();
            });
    }

    saveOrUpdate(action = '') {
        //Service call to save OR Update PicPac
        let postData = { "id": this.props.user };
        let folioName = this.state.folio.folioName;
        let folioNotes = this.state.folio.folioNotes;
        let publishListId = this.props.selectedListId;
        let pubReportId = this.state.folio.folioFormat;
        let picPacId = this.state.folio.picPacId;

        let req = {
            "folioName": folioName,
            "folioNotes": folioNotes,
            "publishListId": publishListId,
            "pubReportId": pubReportId,
            "userId": postData.id.userId,
            "userRole": postData.id.defaultRole,
            "picPacId": picPacId
        };

        let _this = this;

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        PresentationServices.saveOrUpdate(req).then( (response) => {
            console.log('publication CreatePicturePacketModal saveOrUpdate API call after makeCallToRequiredFields success data: ', response);

            let res = response;

            if (res.responseCode === 200) {
                if (action === 'save') {
                    let postData = { "id": this.props.user };
                    let req = {
                        'userId': postData.id.userId,
                        'role': postData.id.defaultRole,
                        'listId': _this.props.selectedListId,
                        'pageCount': 'all'
                    };

                    PresentationServices.getUserFolio(req).then( (response) => {
                        console.log('publication CreatePicturePacketModal saveOrUpdate sub call API call after makeCallToRequiredFields success data: ', response.data);

                        let res = response;

                        if (res.responseCode === 200) {
                            //If user has saved already, Generate state variables with prefiiled value and Generate Preview else No action needed
                            if (!_this.isEmpty(res.data)) {
                                utils.openInNewTab(res.data.fileName);
                            } else {
                                NotificationManager({
                                    message: 'Some error Occurred. Please try again.',
                                    type: 4
                                });

                                _this.modalClose();
                            }
                        } else {
                            NotificationManager({
                                message: res.responseMessage,
                                type: 4
                            });

                            _this.modalClose();
                        }
                    })
                        .catch( error => {
                            console.log('publication CreatePicturePacketModal saveOrUpdate sub call API call failed', error);

                            NotificationManager({
                                message: 'Some error Occurred. Please try again.',
                                type: 4
                            });

                            _this.modalClose();
                        });
                } else {
                    NotificationManager({
                        message: res.responseMessage,
                        type: 1
                    });
                }

                _this.props.modalClose();
                _this.checkFolioCounter = true;
            } else {
                NotificationManager({
                    message: res.responseMessage,
                    type: 4
                });
            }
        })
            .catch( error => {
                console.log('publication CreatePicturePacketModal getUserFolioDetais API call failed', error);

                NotificationManager({
                    message: 'Some error Occurred. Please try again.',
                    type: 4
                });

                _this.props.modalClose();
                _this.checkFolioCounter = true;
            });
    }

    handlePageClick(event) {
        let pageNo = event.selected;
        pageNo = pageNo + 1;

        let folioFormat = this.state.folio.folioFormat;
        let selectedListId = this.props.selectedListId;
        let listName = this.props.selectedListName;
        let postData = { "id": this.props.user };
        let req = {
            'userId': postData.id.userId,
            'role': postData.id.defaultRole,
            "pageNo": pageNo,
            'listId': selectedListId,
            'reportId': folioFormat,
            listName: listName
        };

        this.PreviewGeneration(req, 'pageClick');
    }

    generatePacketPreview() {
        let folioFormat = this.state.folio.folioFormat;
        let pageNo = 1;
        let listName = this.props.selectedListName;
        let postData = { "id": this.props.user };

        if (this.props.selectedListId && this.props.selectedListId != '0') {
            let req = {
                'userId': postData.id.userId,
                'role': postData.id.defaultRole,
                "pageNo": pageNo,
                'listId': this.props.selectedListId,
                'reportId': folioFormat,
                listName: listName
            };

            this.PreviewGeneration(req, 'onChangefolio');
        } else {
            NotificationManager({
                message: 'To continue you will need a saved list selected from the publication right hand panel.',
                type: 4
            });

            this.props.modalClose();
        }
    }

    PreviewGeneration(req, action) {
        let _this = this;
        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        PresentationServices.getPageByPageNo(req).then( (response) => {
            console.log('productContainer createPicturePacketModal PreviewGeneration  API call success data: ', response);

            let res = response;

            if (res.responseCode === 200) {
                let pageUrl = res.data.fileName;
                let totalPages = res.data.NoOfPages;
                let html = " <embed src='" + pageUrl + "#toolbar=0' width='500px' height='400px' />";

                $('#embedHolder embed').remove();
                $('#embedHolder').append(html);

                _this.setState({ picPacUrl: pageUrl, totalPages: totalPages });
            } else {
                NotificationManager({
                    message: res.responseMessage,
                    type: 4
                });
            }
        })
            .catch( error => {
                console.log('productContainer createPicturePacketModal PreviewGeneration  API call failed', error);

                NotificationManager({
                    message: 'Some error Occurred. Please try again.',
                    type: 4
                });

                _this.props.modalClose();
                _this.checkFolioCounter = true;
            });
    }

    componentDidMount() {
        let _this = this;

        // to hide dropdowns when user clicks outside the field area
        $(document).on('click.addrecord', function (e) {
            e.stopPropagation();

            var addRecord = '.record-action';

            if ($(addRecord).hasClass('open') && $(e.target).closest('.record-creation-holder').length === 0) {
                _this.toggleFinaliseOptions();
            }
        });
    }

    componentDidUpdate() {
        if (this.props.isOpen && this.checkFolioCounter) {
            this.getUserFolioDetais();
        }
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen}>
                <div className="modal-form">
                    <div className="modal-header">
                        Picture Pack
                        <span className="close" onClick={this.modalClose}>x</span>
                    </div>
                    <div className="modal-content">
                        <div className="packet-details">
                            <PacketDetails
                                isFinaliseOpen={this.state.isFinaliseOpen}
                                toggleFinaliseOptions={this.toggleFinaliseOptions}
                                handleChangePic={this.handleChangePic}
                                saveToPdf={this.saveToPdf}
                                folio={this.state.folio}
                                saveToFuture={this.saveToFuture}
                                errorMessage={this.state.errorMessage}
                                avalibaleFormat={this.props.avalibaleFormat} />
                        </div>
                        <div className="packet-preview">
                            <PacketPreview
                                picPacUrl={this.state.picPacUrl}
                                totalPages={this.state.totalPages}
                                handlePageClick={this.handlePageClick} />
                            <CancelChanges
                                isOpen={this.state.isConfirmOpen}
                                closeConfirmModal={this.closeConfirmModal} />
                        </div>
                    </div>
                    <div className="modal-footer">
                    </div>
                </div>
            </Modal>
        );
    }
}

export default CreatePicturePacketModal;
