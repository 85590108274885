import React, { Component } from 'react';
import { connect } from 'react-redux';
import { rulesForAlerts } from './helperComponents/actions';
import Modal from '../modals/common-modal';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import JqxGrid from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';

// import JqxGrid from '../common/vendor/jqwidgets-react/react_jqxgrid';
// var AlertsDataBuilder = require('./alerts-data-builder.js');
// var NotificationManager = require('../../../common/notifier');
import NotificationManager from '../common/components/notification';
import AlertsDataBuilder from './helperComponents/alerts-data-builder';
import axios from 'axios';
import PropTypes from 'prop-types';

const EditAlertGridComponent = ({
    state,
    props,
    cancelPopup,
    handleSubmit,
    handleChange,
    confirmClose,
    modalClose,
    formData,
}) => {
    return (
        <div>
            <div className="modal-header">
        Edit Alert
                <span className="close" onClick={cancelPopup}>x</span>
            </div>
            <div className="modal-content add-new-alert">
                <form name="add-new-alert" onSubmit={handleSubmit} className="modal-form">
                    <div className="form-body form-group">
                        <fieldset className="form-group">
                            <label className="form-label">Name</label>
                            <input id="editAlert" type="text" value={state.formData.alertName} name="alertName" maxLength={200}
                                className={`form-control ${(state.isFormValid) ? '' : 'error'} `} onChange={handleChange} />
                        </fieldset>
                        <fieldset className="form-group">
                            <label className="form-label">Rule to be applied</label>
                            <select value={state.formData.appliedRuleId} name="appliedRuleId"
                                onChange={handleChange} className="form-fluid-select">
                                {props.alertRules.length > 0 &&
                  props.alertRules.map((list, index) => (
                      <option key={index} value={list.ruleId}>
                          {list.ruleName}
                      </option>
                  ))
                                }
                            </select>
                        </fieldset>

                    </div>
                    <div className="form-footer">
                        <div className="text-center">
                            <button type="button" className="gray-button" onClick={cancelPopup}>Cancel</button>
                            <button type="submit" disabled={state.submitBtnDisabled} className="gray-button">Save</button>
                        </div>
                    </div>
                </form>
            </div>
            <Modal isOpen={state.isConfirmOpen}>
                <div className="modal-content text-center">
                    <h4>Do you want to Save Changes?</h4>
                    <div>
                        <button type="button" onClick={handleSubmit} className="gray-button">Yes</button>
                        <button type="button" onClick={props.modalClose} className="gray-button">No</button>
                        <button type="button" onClick={confirmClose} className="gray-button">cancel</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

EditAlertGridComponent.PropTypes = {
    state: PropTypes.object,
    props: PropTypes.object,
};


export default EditAlertGridComponent;
