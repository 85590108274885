import React, { Component } from 'react';
import RulesFooter from '../adminRules/helperComponents/rules-footer';
import ConfirmDelete from '../adminRules/helperComponents/confirmdelete';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import JqxGrid from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';
import CreateRules from './helperComponents/rules-creator.js';
import Modal from '../modals/common-modal';
import GridDataBuilder from '../adminUserGroup/helperComponents/userGroupDataBuilder';
import PropTypes from 'prop-types';

const RulesGridComponent = ({
    state,
    props,
    rulesGridRef,
    gridData,
    closeRulesModal,
    modalClose,
    ruleData,
    deleteRules,
    modalName,
    addOrUpdateRules,
    closeAllModal,
    openRulesModal,
    cloneRules,
    pauseRules,
    deleteRuleDialog,
    gridHeight,
    modalClass,
    onCelldoubleclick,
}) => {
    return (
        <div>
            <div className="rulesGrid pos-relative">
                {state.isLoaded &&
            <JqxGrid ref={rulesGridRef}
                width={'100%'}
                source={gridData.dataAdapter}
                pageable={false}
                checkboxes={false}
                editable={false}
                hierarchicalCheckboxes={true}
                columnsResize={true}
                columns={gridData.columns}
                sortable={false}
                autoRowHeight={false}
                autoWidth={true}
                height={gridHeight}
                theme={'light'}
                selectionmode={'checkbox'}
                columnsreorder={true}
                columnsmenu={false}
                onCelldoubleclick={onCelldoubleclick}
            />
                }
                <CreateRules
                    user={props.user}
                    close={closeRulesModal}
                    isOpen={state.isRulesOpen}
                    ruleData={ruleData}
                />
                {state.isModalOpen &&
            <Modal
                isOpen={state.isModalOpen}
                addClass={modalClass}
            >
                <ConfirmDelete
                    modalClose={modalClose}
                    deleteRules={deleteRules}
                    modalName={modalName}
                    yesSaveChanges={addOrUpdateRules}
                    noSaveChanges={closeAllModal}></ConfirmDelete>
            </Modal>
                }
            </div>
            <RulesFooter
                addRule={openRulesModal}
                isChanged={state.isRulesOpen}
                cloneRules={cloneRules}
                closeRule={closeRulesModal}
                pauseRules={pauseRules}
                deleteRuleDialog={deleteRuleDialog}
                addOrUpdateRules={addOrUpdateRules}
                isOpen={state.isRulesOpen}
            />
        </div>
    );
};

export default RulesGridComponent;
