import React, { Component } from 'react';
import CancelModalDelete from '../../../common/modals/image-cancel.js';
import ImageUpload from './imageUpload';
import axios from 'axios';

// var NotificationManager = require('../../common/notification');
import NotificaitonManager from '../../../common/components/notification';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { ProductServices } from '../../services/product-services';

class VerticalImageViewer extends Component {
    constructor(props) {
        super(props);

        props.imageInfo.images = (props.imageInfo.images ? props.imageInfo.images : []);
        this.state = {
            imageArr: (props.imageInfo.images) ? props.imageInfo.images : [],
            mainImageSrc: '',
            imageName: (props.imageInfo.images.length > 0) ? props.imageInfo.images[0].imageName : '',
            imageId: (props.imageInfo.images.length > 0) ? props.imageInfo.images[0].imageId : '',
            isImageDeletable: (props.imageInfo.images.length > 0) ? props.imageInfo.images[0].deleteFlag : false,
            isCancelDelete: false,
            s3Images: [],
            downloadList: [],
        };

        this.updateMainViewer = this.updateMainViewer.bind(this);
        this.updateImageState = this.updateImageState.bind(this);
        this.getProductId = this.getProductId.bind(this);
        this.getDataLevel = this.getDataLevel.bind(this);
        this.getImageData = this.getImageData.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
        this.CancelModalDelete = this.CancelModalDelete.bind(this);
        this.deleteImageConfirm = this.deleteImageConfirm.bind(this);
        this.checkBoxChange = this.checkBoxChange.bind(this);
        this.updateMainImageS3 = this.updateMainImageS3.bind(this);
        this.downloadImages = this.downloadImages.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
    }

    componentDidMount() {
        const pIds = this.getProductId();

        const dataLevel = this.getDataLevel();
        const req = {'chldProuctId': pIds, 'dataLevel': dataLevel };

        this.getImageData(req);
    }

    /**
     * Updates the image values
     * @param {number} val // value of index
     */
    updateMainViewer(val, c) {
        if (c == 'clicked') {
            const imgId = val;
            let setImg;
            this.state.imageArr.map((i, x) => {
                if (i.imageId == imgId) {
                    setImg = i;
                }
            });
            const currentSrc = setImg.imageUrl;
            const imageName = setImg.imageName;
            const imageId = setImg.imageId;
            const isImageDeletable = setImg.deleteFlag;
            this.setState({ mainImageSrc: currentSrc, imageName: imageName, imageId: imageId, isImageDeletable: isImageDeletable });
        } else {
            let imageArr1 = Object.assign([], this.state.imageArr);
            imageArr1.pop();
            imageArr1.reverse();
            imageArr1.push(this.state.imageArr[this.state.imageArr.length - 1]);
            imageArr1.reverse();
            console.log('image array before reverse: ', this.state.imageArr, val);
            console.log('image array after reverse: ', imageArr1);
            const currentSrc = imageArr1[val].imageUrl;
            const imageName = imageArr1[val].imageName;
            const imageId = imageArr1[val].imageId;
            const isImageDeletable = imageArr1[val].deleteFlag;
            this.setState({ mainImageSrc: currentSrc, imageName: imageName, imageId: imageId, isImageDeletable: isImageDeletable });
        }
    }

    updateMainImageS3(index) {
        const _this = this;
        this.state.s3Images.map((i, x) => {
            if (x == index) {
                _this.setState({
                    mainImageSrc: i.mainImage
                });
            }
        });
    }

    /**
     * Updates image to state
     */
    updateImageState() {
        // let imageArr = Object.assign([],this.state.imageArr);
        // imageArr.reverse();
        // this.setState({ imageArr});
        if (this.state.imageArr.length > 0) {
            this.updateMainViewer(0);
        }
    }

    /**
     * Getter for the productID
     * @return {number} product id's are returned
     */
    getProductId() {
        const base = this.props.imageInfo;
        const pIds = [];
        // pIds.push(base.productId);

        if (base.dataLevel == 'SINGLE') {
            pIds.push(base.chldProuctId);
        } else if (base.dataLevel == 'PARENT') {
            pIds.push(base.chldProuctId);
        } else if (base.dataLevel == 'INTERMEDIATE') {
            pIds.push(base.childrenIds);
        } else if (base.dataLevel == 'CHILD') {
            pIds.push(base.childrenIds);
        }

        return pIds;
    }

    /**
     * Getter for the data level
     * @return {string} data level is returned
     */
    getDataLevel() {
        const base = this.props.imageInfo;
        return base.dataLevel;
    }

    currentImageData() {
        return this.state.s3Images;
    }

    setS3ImagesAfterUpload(data) {
        const _this = this;

        let imgData = [];
        let baseURL = data.responseData.baseUrl;
        data.responseData.digitalAssetInfos && data.responseData.digitalAssetInfos.map((i) => {
            let order = i.objectTags.filter((a) => a.key == 'order');

            if (i.objectTags && i.objectTags[0]) {
                imgData.push({
                    'mainImage': baseURL + i.digitalAssetSummary.mediumImage,
                    'thumbnailImage': baseURL + i.digitalAssetSummary.thumbnailImage,
                    'name': {'key': "fileName", 'value': i.fileName},
                    'order': order[0],
                    's3Url': i.s3ObjectSummary.key,
                });
            }
        });

        imgData.sort((a, b) => a.order.value - b.order.value);
        let heroImg = imgData && imgData.length > 0 ? imgData[0].mainImage : '';

        _this.setState({s3Images: imgData, mainImageSrc: heroImg}, () => {
            this.props.getHeroImage(imgData);
        });
    }

    /**
     * Getter for the image data calling the backend when the popup is opened
     * @param {object} req // Image data object
     */
    getImageData(req) {
        const _this = this;
        const prodId = this.props.node.data.productId;
        const colId = this.props.column.colId;
        let imgData = [];

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        axios({
            method: 'get',
            url: require('../../../common/configurations.js').product2.getImageDataV2 + colId + '/' + prodId,
            headers: headers,
        }).then((res) => {
            if (res.data.status == 'success') {
                let baseURL = res.data.responseData.baseUrl;
                res.data.responseData.digitalAssetInfos && res.data.responseData.digitalAssetInfos.map((i) => {
                    let order = i.objectTags.filter((a) => a.key == 'order');

                    if (i.objectTags && i.objectTags[0]) {
                        imgData.push({
                            'mainImage': baseURL + i.digitalAssetSummary.mediumImage,
                            'thumbnailImage': baseURL + i.digitalAssetSummary.thumbnailImage,
                            'name': {'key': "fileName", 'value': i.fileName},
                            'order': order[0],
                            's3Url': i.s3ObjectSummary.key,
                        });
                    }
                });

                imgData.sort((a, b) => a.order.value - b.order.value);
                let heroImg = imgData && imgData.length > 0 ? imgData[0].mainImage : '';

                _this.setState({s3Images: imgData, mainImageSrc: heroImg}, () => {
                    this.props.getHeroImage(imgData);
                });
                NotificaitonManager({ message: res.data.message, type: 1 });
            }
        }).catch((err) => {
            NotificaitonManager({
                message: err.message,
                type: 4,
            });
        });

        // 	ProductServices.getImageLst(req)
        // 	.then( (response) => {

        //       const imageData = response.data;
        //       _this.setState({
        //           imageArr: (imageData.data[0].images) ? imageData.data[0].images.reverse() : [],
        //           mainImageSrc: (imageData.data[0].images.length > 0) ? imageData.data[0].images[0].imageUrl : '',
        //           imageName: (imageData.data[0].images.length > 0) ? imageData.data[0].images[0].imageName : '',
        //           imageId: (imageData.data[0].images.length > 0) ? imageData.data[0].images[0].imageId : '',
        //           isImageDeletable: (imageData.data[0].images.length > 0) ? imageData.data[0].images[0].deleteFlag : false,

    //       });
    //   })
    //   .catch( (error) => {
    //       NotificationManager({
    //           message: 'Request failed due to technical reasons, please try again',
    //           type: 4,
    //       });
    //   });
    }

    /**
     * Deletes the Image and sets state
     */
    deleteImage() {
        this.setState({ isCancelDelete: true });
    }


    deleteS3Image() {
        const _this = this;
        const prodId = this.props.node.data.productId;
        const colId = this.props.column.colId;
        let currentImg = this.state.mainImageSrc;
        let imgName = '';

        this.state.s3Images.map((i, x) => {
            if (currentImg == i.mainImage) {
                imgName = i.name.value;
            }
        });

        //This handles delete on the front end
        // Removes the delete image from state and creates
        // a new state where index of images are changed
        // and updated on the grid as well when popup closes.

        let afterDelete = this.state.s3Images.filter((i, x) => {
            if (currentImg != i.mainImage) {
                return i;
            }
        });

        // This sets the order of the image
        // after an image has been deleted
        // making sure order is not broken
        afterDelete.map((i, x) => {
            i.order.value = x.toString();
        });

        let checkForMainImage = afterDelete.length > 0 && afterDelete[0].mainImage ? afterDelete[0].mainImage : '';

        this.setState({
            s3Images: afterDelete,
            mainImageSrc: checkForMainImage,
        }, () => {
            this.props.getHeroImage(afterDelete);
            this.updateImageTags();
            this.setState({ isCancelDelete: false });
        });

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        axios({
            method: 'delete',
            url: require('../../../common/configurations.js').product2.deleteS3Image + colId + '/' + prodId + '/' + imgName,
            headers: headers,
        }).then(() => {

        }).catch(() => {

        });
    }

    /**
     * Image confirmation popup
     */
    deleteImageConfirm() {
        const pIds = this.getProductId();
        const dataLevel = this.getDataLevel();
        const imageName = this.state.imageName;

        // let req = { 'imageName': imageName, 'chldProuctId': pIds, 'dataLevel': dataLevel };
        const req = { 'imageName': imageName, 'childrenIds': pIds, 'dataLevel': dataLevel };
        const _this = this;

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };
        axios({
            method: 'post',
            // url: 'http://localhost:1234/api/products/removeimage',
            // url: require('../../../common/configurations.js').product.deleteImageUploadData,
            url: require('../../../common/configurations.js').product2.deleteImageUploadDataV2,
            headers: headers,
            data: req,
        })
            .then( (response) => {
                const res = response.data;
                if (res.status) {
                    const imageObj = _this.state.imageArr;
                    const currentImageId = _this.state.imageId;

                    const data = $.grep(imageObj, function(e) {
                        return e.imageId != currentImageId;
                    });

                    _this.setState({ imageArr: data, isCancelDelete: false });

                    if (data.length > 0) {
                        _this.setState({
                            mainImageSrc: data[0].imageUrl,
                            imageName: data[0].imageName,
                            imageId: data[0].imageId,
                            isImageDeletable: data[0].deleteFlag,

                        });
                    } else {
                        _this.setState({
                            mainImageSrc: '',
                            imageName: '',
                            imageId: '',
                            isImageDeletable: false,

                        });
                    }

                    const obj = {};
                    obj.rowId = _this.props.imageInfo.uid;
                    obj.fieldName = 'images';
                    obj.data = data;

                    _this.props.updateImage(obj);
                    NotificationManager({
                        message: res.message,
                        type: 1,
                    });
                } else {
                    NotificationManager({
                        message: res.message,
                        type: 4,
                    });
                    _this.setState({ isCancelDelete: false });
                }
            })
            .catch( (error) => {
                console.log('productContainer helperComponents vertical-image-viewer API call failed', error);
                NotificationManager({
                    message: 'Something went wrong.Please try again after some time.',
                    type: 4,
                });
                _this.setState({ isCancelDelete: false });
            });
    }

    /**
     * Cancels the Delete or Approves it
     * @param {string} action // Yes/No to approve/cancel the delete
     */
    CancelModalDelete(action) {
        const _this = this;

        switch (action) {
            case 'yes': _this.deleteS3Image();
                break;
            case 'no': _this.setState({ isCancelDelete: false });
        }
    }

    checkBoxChange(event) {
        const _this = this;
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const id = target.id;

        let downloadImageList = this.state.s3Images;
        let newArr = this.state.downloadList;

        if (value == true) {
            downloadImageList.map((i) => {
                if (i.name.value == id) {
                    newArr.push(i.name.value);
                }
            });
            _this.setState({downloadList: newArr});
        } else {
            let removed = _this.state.downloadList.filter((i, x) => {
                if (i != id) {
                    return i;
                }
            });
            _this.setState({downloadList: removed});
        }
    }

    downloadImages(e) {
        let imageNames = this.state.downloadList;
        const prodId = this.props.node.data.productId;
        const colId = this.props.column.colId;

        imageNames.map((i) => {
            axios({
                method: 'get',
                url: require('../../../common/configurations.js').product2.downloadImageV2 + colId + '/' + prodId + '/' + i,
                responseType: 'blob',
            }).then((response) => {
                let bufferImg = URL.createObjectURL(response.data);
                // downloadImg.openInNewTab(bufferImg);

                // Create a link element
                const link = document.createElement("a");

                // Set link's href to point to the Blob URL
                link.href = bufferImg;
                link.download = i;

                // Append link to the body
                document.body.appendChild(link);

                // Dispatch click event on the link
                // This is necessary as link.click() does not work on the latest firefox
                link.dispatchEvent(
                    new MouseEvent('click', {
                        bubbles: true,
                        cancelable: true,
                        view: window
                    })
                );

                // Remove link from body
                document.body.removeChild(link);
            }).catch((err) => {
                console.log('image data downloaded', err);
            });
        });
    }


    onDragEnd(res) {
        // dropped outside the list
        if (!res.destination) {
            return;
        }

        const startIndex = res.source.index;
        const endIndex = res.destination.index;
        const items = this.state.s3Images;

        const result = Array.from(items);
        const [removed] = result.splice(startIndex, 1);

        result.splice(endIndex, 0, removed);

        result.map((i, x) => {
            i.order.value = '' + x; // sets value to string for the drag and drop
        });

        this.setState({
            s3Images: result,
            mainImageSrc: result[0].mainImage
        }, () => {
            this.updateImageTags();
            this.props.getHeroImage(result);
        });
    }

    updateImageTags() {
        let imageOrder = this.state.s3Images;
        const prodId = this.props.node.data.productId;
        const colId = this.props.column.colId;

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        let req = {};
        req['operationPolicy'] = 'delta';
        req['updateOperations'] = [];

        imageOrder.map((i, x) => {
            req['updateOperations'].push({
                'digitalAssetKey': i.s3Url,
                'keyName': i.order.key,
                'keyValue': i.order.value,
            },
            {
                'digitalAssetKey': i.s3Url,
                'keyName': i.name.key,
                'keyValue': i.name.value,
            });
        });

        let tagOperations = {};
        tagOperations['tagOperations'] = req;

        axios({
            method: 'post',
            url: require('../../../common/configurations.js').product2.updateImageTags + colId + '/' + prodId,
            headers: headers,
            data: tagOperations,
        }).then((res) => {
            console.log('updates tags', res);
        }).catch((error) => {
            console.log('updates tags', error);
        });
    }

    render() {
        const noImgFix = this.state.mainImageSrc ? '' : 'noImgFix';
        const props = this.props;
        // let imageArr = this.state.imageArr;
        // imageArr.reverse();

        return (
            <div className="vertical-image-holder-for-product">
                <div className="rowHolder">
                    <div className="thumbnail-holder scroll">
                        <DragDropContext onDragEnd={this.onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided) => (
                                    <ul
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {/* {
                this.state.imageArr.map((item, index) => (
                  <li className="thumbnails" key={index}
                    onClick={() => (this.updateMainViewer(item.imageId, 'clicked'))}>
                    <input type='checkbox' onChange={this.checkBoxChange()}></input>
                    {item.thumbNailUrl || item.imageUrl ? <img src={item.thumbNailUrl ? item.thumbNailUrl : item.imageUrl} /> :""}
                  </li>
                ))
              } */}
                                        {
                                            this.state.s3Images.map((item, index) => (
                                                <Draggable key={item.order.value} draggableId={item.order.value} index={index}>
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <input type='checkbox' key={item.name.value} id={item.name.value} onChange={this.checkBoxChange}></input>
                                                            <li className="thumbnails" key={item.thumbnailImage} onClick={() => (this.updateMainImageS3(index))}>
                                                                <img src={item.thumbnailImage} />
                                                            </li>
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))
                                        }
                                        {provided.placeholder}
                                    </ul>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                    <div className={'main-image ' + noImgFix}>
                        {this.state.mainImageSrc &&
              <div className="productImageContainer">
                  {this.props.modalName != 'publish_image' &&
                  <span className="closeIcon" onClick={this.deleteImage}>X</span>
                  }
                  {this.state.mainImageSrc && <img src={this.state.mainImageSrc} /> }
              </div>
                        }
                        {!this.state.mainImageSrc &&
              <p>No Image</p>}

                    </div>
                </div>

                <ImageUpload {...props}
                    downloadImages={this.downloadImages}
                    imageData={this.updateImageState}
                    getImageData={this.getImageData}
                    currentImageData = {this.currentImageData.bind(this)}
                    setS3ImagesAfterUpload = {this.setS3ImagesAfterUpload.bind(this)}
                />

                <CancelModalDelete isOpen={this.state.isCancelDelete} modalClose={this.CancelModalDelete} />

            </div>
        );
    }
}

export default VerticalImageViewer;

