import React, { Component } from 'react';
import Modal from '../../common/modals/commonModal.js';
import AddUserForm from './addUserForm';
import EditGlobalSetting from './editGlobalSetting';
import {connect} from 'react-redux';

class UserAddModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let modalContent = '';

        if (this.props.modalName === 'add_user') {
            modalContent = <AddUserForm modalClose={this.props.modalClose} user={this.props.userData} addUserRow={this.props.addUserRow} newSpecialityData={this.props.newSpecialityData}/>;
        } else if (this.props.modalName === 'edit_global_setting') {
            modalContent = <EditGlobalSetting modalClose={this.props.modalClose} user={this.props.userData}/>;
        }

        return (
            <Modal isOpen={this.props.isOpen}>
                {modalContent}
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        newSpecialityData: state.specialityData,
    };
};

export default connect(mapStateToProps) (UserAddModal);
