import React from 'react';


export class DivisionRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            categoriesList: props.getCategoriesList(),
        };
    }

    render() {
        let divisionName = ''; // Store division name
        const _this = this;
        // if (this.state.categoriesList && this.props.node.data && this.props.node.data.division) {
        //     this.state.categoriesList.forEach((row) =>{
        //         if (row.fieldId === _this.props.node.data.division) {
        //             divisionName = row.fieldName;
        //         }
        //     });
        // }

        return (
            <div>
                {this.props.node.data.divisionName}
            </div>
        );
    }
}

// //////////////////////////////////////////////////////////////////////////
