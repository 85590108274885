import React, { Component } from 'react';
import NotificationManager from '../../common/components/notification';

class RulesFooter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isRulesRevealed: false,
        };

        this.toggleReveal = this.toggleReveal.bind(this);
    }

    /**
     * Toggles footer options
     */
    toggleReveal() {
        this.setState((prevState) => ({
            isRulesRevealed: !prevState.isRulesRevealed
        }));
    }

    componentDidMount() {
        const _this = this;
    }

    componentWillUnmount() { }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.isChanged) {
            this.state = {
                isRulesRevealed: false,
            };
        }
    }

    render() {
        return (
            <div className="footer alerts-footer">
                <div className={`rules-footer-container `}>
                    <div className="rules-footer clearfix">
                        <div className="footer-content left">
                            {!this.props.isChanged &&
              <div className="pos-relative">
                  <button type="button"
                      className={`gray-button add-rule ${(this.state.isRulesRevealed) ? 'active' : ''}`}
                      onClick={this.toggleReveal}>
                  Manage Rule
                  </button>
                  <div className={`reveal-rules ${(this.state.isRulesRevealed) ? 'open' : ''} `}>
                      <ul>
                          <li onClick={() => {
                              this.props.addRule();
                              this.toggleReveal();
                          }}>Add Rule</li>
                          <li onClick={() => {
                              this.props.cloneRules();
                              this.toggleReveal();
                          }}>Clone Rule</li>
                          <li onClick={() => {
                              this.props.pauseRules();
                              this.toggleReveal();
                          }}>Pause Rule</li>
                          <li onClick={() => {
                              this.props.deleteRuleDialog();
                              this.toggleReveal();
                          }}>Delete Rule</li>
                      </ul>
                  </div>
              </div>
                            }
                        </div>
                        {this.props.isOpen &&
            <div className="footer-content right">
                <button type="button" className="gray-button" onClick={this.props.closeRule}>Cancel Changes</button>
                <button type="button" className="gray-button"
                    onClick={this.props.addOrUpdateRules}>Apply Changes</button>
            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default RulesFooter;
