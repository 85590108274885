import React from 'react';
import CancelModal from '../../common/modals/cancelModal';
import axios from 'axios';
import NotificationManager from '../../common/components/notification';
import SpecialityCategoryTree from './specialityCategoryTree';

import AdminServices from '../../admin/admin-services/admin-services';

class AddUserForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadForm: false,
            user: {
                userName: '',
                nickName: '',
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                company: '',
                superior: 0,
                designation: '',
                speciality: '',
                deptId: 'DES01',
                defaultRole: '',
                roles: [],
            },
            validation: {
                userName: true,
                nickName: true,
                firstName: true,
                lastName: true,
                email: true,
                phone: true,
                designation: true,
                speciality: false,
                company: false,
                deptId: true,
                defaultRole: true,
            },
            isDirty: false,
            errorMessage: '',
            userGroupList: [],
            isModalOpen: false,
            isModified: false,
        };

        this.specialityData = [];
        this.openModalCancel = this.openModalCancel.bind(this);
        this.modalClose = this.modalClose.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.saveUser = this.saveUser.bind(this);
        this.getMandatoryFields = this.getMandatoryFields.bind(this);
    }

    /**
     * Closes the modal
     * @param {*} key
     */
    modalClose(key) {
        this.setState({ isModalOpen: false });

        switch (key) {
            case 'updateUser':
                this.saveUser();
                break;

            case 'no':
                this.props.modalClose();
                break;
        }
    }

    /**
     * Opens the cancel modal
     */
    openModalCancel() {
        if (this.state.isModified) {
            this.setState({ isModalOpen: true });
        } else {
            this.props.modalClose();
        }
    }

    componentDidMount() {
        this.getUserGroup();
    }

    /**
     * Getter for mandatory fields
     * @param {*} groupList
     */
    getMandatoryFields(groupList) {
        AdminServices.getUserMandatoryfields().then((response) => {
            const res = response;
            const validationData = this.state.validation;

            if (res.length > 0) {
                res.map(function(o, i) {
                    if (o.userFields == 'phoneNo') {
                        validationData.phone = o.mandatoryFields;
                    } else {
                        validationData[o.userFields] = o.mandatoryFields;
                    }
                });
            }

            console.log('getusermandatoryfields: ', this.state.validation, validationData, groupList);

            this.setState({
                loadForm: true,
                userGroupList:
                groupList,
                validation: validationData
            });
        }).catch((error) => {
            console.log('User getMandatoryFields API call failed', error);
        });
    }

    /**
     * Getter for user group
     */
    getUserGroup() {
        AdminServices.getRoleList().then((res) => {
            const groupList = res;

            groupList.map((val, index) => {
                groupList[index].checked = false;
                groupList[index].default = false;
            });

            this.getMandatoryFields(groupList);
        }).catch((error) => {
            console.log('Server Error Failed', error);

            NotificationManager({
                message: error.message || "Unexpected error, Data didn't load",
                type: 4
            });
        });
    }

    /**
     * Checks input fields
     * @param {*} event
     */
    handleChange(event) {
        const target = event.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const regex = /^[a-zA-Z0-9\s]+$/i;
        const userNameRegex = /^[A-Za-z0-9-_]+$/i;
        const telregex = /^[0-9+-]+$/i;

        if (value.trim() === '' || value.trim() === undefined) {
            if (this.state.validation[name]) {
                target.className += ' invalid';
            }
        } else {
            target.classList.remove('invalid');
        }

        const {user} = this.state;
        const newUser = user;

        if (value.length > 0) {
            if ((userNameRegex.test(value) && name === 'userName') ||
                (regex.test(value) && name === 'nickName') ||
                (regex.test(value) && name === 'firstName') ||
                (regex.test(value) && name === 'lastName') ||
                (regex.test(value) && name === 'company') ||
                (regex.test(value) && name === 'designation') ||
                (regex.test(value) && name === 'speciality')) {
                if (($.trim(value)).length == 0) {
                    value = $.trim(value);
                }

                newUser[name] = value;
            }
        } else {
            newUser[name] = value;
        }

        if (name === 'email') {
            newUser[name] = value;
        } else if (name === 'phone' && telregex.test(value)) {
            newUser[name] = value;
        }

        this.setState({
            user: newUser,
            isModified: true
        });
    }

    /**
     * Toggles checkbox
     * @param {*} key
     */
    toggleCheckbox(key) {
        const {userGroupList} = this.state;
        const newList = userGroupList;

        newList.forEach((e, index) => {
            if (index == key) {
                e.checked = !e.checked;
                if (!e.checked) {
                    e.default = false;
                }
            }
        });

        this.setState({
            userGroupList: newList,
            isModified: true
        });
    }

    /**
     * Toggles radio button
     * @param {*} key
     */
    toggleRadio(key) {
        const {userGroupList} = this.state;
        const newList = userGroupList;

        newList.forEach((e, index) => {
            if (index != key) {
                e.default = false;
            }
        });

        newList[key].default = !newList[key].default;
        this.setState({
            userGroupList: newList,
            isModified: true
        });

        console.log('New List', this.state);
    }

    /**
     * Validates email
     * @param {*} email
     * @return {string}
     */
    validateEmail(email) {
        const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(email);
    }

    /**
     * Calls backend to save user
     * @param {*} event
     */
    saveUser(event) {
        const _this = this;
        const {user, userGroupList} = this.state;

        for (const key in user) {
            if (user.hasOwnProperty(key)) {
                user[key] = (typeof(user[key]) === 'string' ? $.trim(user[key]) : user[key]);
            }
        }

        const newUser = user;
        const newGroup = userGroupList;
        const checkedGroupList = [];

        const newSp = $.makeArray($('.speciality-tree').find('.tree-node-list.selected'));
        const newSpIds = [];
        const newSpDTO = [];

        newSp.map(function (sp, i) {
            const id = $(sp).find('.category').attr('data-catid');
            const name = $(sp).find('.category').text();

            newSpIds.push(id);

            const spData = {};
            spData.catId = id;
            spData.catName = name;
            spData.status = true;

            newSpDTO.push(spData);
        });

        newGroup.map((e, index) => {
            if (e.checked) {
                checkedGroupList.push({
                    roleId: e.roleId,
                    role: e.role,
                    status: 'True'
                });
            }

            if (e.default) {
                newUser.defaultRole = e.role;
            }
        });

        newUser.roles = checkedGroupList;

        // validation
        let flag = 0;
        let errorMessage = '';

        if (this.state.validation.userName && newUser.userName === '') {
            flag++;

            $('.form-control[name="userName"]').addClass('invalid');
        } else {
            this.setState({ isDirty: false });

            $('.form-control[name="userName"]').removeClass('invalid');
        }

        if (this.state.validation.nickName && newUser.nickName === '') {
            flag++;

            $('.form-control[name="nickName"]').addClass('invalid');
        } else {
            this.setState({ isDirty: false });

            $('.form-control[name="nickName"]').removeClass('invalid');
        }

        if (this.state.validation.firstName && newUser.firstName === '') {
            flag++;

            $('.form-control[name="firstName"]').addClass('invalid');
        } else {
            this.setState({ isDirty: false });

            $('.form-control[name="firstName"]').removeClass('invalid');
        }

        if (this.state.validation.lastName && newUser.lastName === '') {
            flag++;

            $('.form-control[name="lastName"]').addClass('invalid');
        } else {
            this.setState({ isDirty: false });

            $('.form-control[name="lastName"]').removeClass('invalid');
        }

        if (this.state.validation.email && newUser.email === '') {
            flag++;

            $('.form-control[name="email"]').addClass('invalid');
        } else {
            if (this.validateEmail(this.state.user.email)) {
                this.setState({ isDirty: false });

                $('.form-control[name="email"]').removeClass('invalid');
            } else {
                flag++;

                errorMessage = 'Invalid Email address.';

                $('.form-control[name="email"]').addClass('invalid');
            }
        }

        if (this.state.validation.phone && newUser.phone === '') {
            flag++;

            $('.form-control[name="phone"]').addClass('invalid');
        } else {
            this.setState({ isDirty: false });

            $('.form-control[name="phone"]').removeClass('invalid');
        }

        if (this.state.validation.company && (newUser.company === '' || newUser.company === undefined)) {
            flag++;

            $('.form-control[name="company"]').addClass('invalid');
        } else {
            this.setState({ isDirty: false });

            $('.form-control[name="company"]').removeClass('invalid');
        }

        if (this.state.validation.designation && newUser.designation === '') {
            flag++;

            $('.form-control[name="designation"]').addClass('invalid');
        } else {
            this.setState({ isDirty: false });

            $('.form-control[name="designation"]').removeClass('invalid');
        }

        if (this.state.validation.speciality && newSpIds.length < 1) {
            flag++;

            $('.speciality-tree').addClass('invalid');
        } else {
            this.setState({ isDirty: false });

            $('.speciality-tree').removeClass('invalid');
        }

        if (this.state.validation.defaultRole && newUser.defaultRole === '') {
            flag++;
        } else {
            this.setState({ isDirty: false });
        }

        if (flag > 1 || errorMessage == '') {
            errorMessage = '*Mandatory fields marked with an asterisk are missing.';
        }

        if (flag === 0) {
            const req = newUser;
            req.specialityListDTOs = newSpDTO;
            delete req['speciality'];

            AdminServices.insertUsersData(req).then((response) => {
                const res = response;

                if (res.status) {
                    NotificationManager({
                        message: res.responseMessage,
                        type: 1,
                    });

                    // Append new row in User Grid
                    const {user} = _this.state;
                    const newUser = user;
                    newUser.userId = res.user.userId;

                    _this.setState({ user: newUser });

                    _this.props.addUserRow(res.user);
                    _this.props.modalClose();
                } else {
                    _this.setState({ isDirty: false, errorMessage: '' });

                    NotificationManager({
                        message: res.responseMessage,
                        type: 4,
                    });
                }
            }).catch((error) => {
                console.log('User addUserForm saveUser API call failed', error);

                NotificationManager({
                    message: 'Failed to create user',
                    type: 4,
                });
            });
        } else {
            NotificationManager({
                message: errorMessage,
                type: 4,
            });

            this.setState({ isDirty: true });
        }
    }

    render() {
        let currentUser = this.props.user.company.toLowerCase() == 'sweft';

        const groups = this.state.userGroupList.map((val, index) => {
            return (
                <div className="row" key={index}>
                    <div className="col-4">
                        <label className="form-label">{val.role}</label>
                    </div>
                    <div className="col-4">
                        <label className="form-check-label">
                            <input
                                type="checkbox"
                                className="form-control-check"
                                id={val.role}
                                name={val.role}
                                checked={val.checked}
                                onChange={() => this.toggleCheckbox(index)} />
                            <span htmlFor={val.role} className="from-checkbox"></span>
                        </label>
                    </div>
                    <div className="col-4">
                        <label className="form-radio-label">
                            <input
                                type="radio"
                                name="default"
                                checked={val.default}
                                disabled={!val.checked}
                                className="form-control-radio"
                                onChange={() => this.toggleRadio(index)} />
                        </label>
                    </div>
                </div>
            );
        });

        return (
            <div>
                {this.state.loadForm &&
                <div>
                    <div className="modal-header">
                        Add New User
                        <span className="close" onClick={this.openModalCancel}>x</span>
                    </div>
                    <div className="modal-content">
                        <div className="add-user modal-form">
                            <form className="addNewUser" name="addNewUser">
                                <div className="form-container scroll">
                                    <h4>Account Details</h4>
                                    <hr/>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-label">Username{this.state.validation.userName ? '*' : ''}</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="userName"
                                                    value={this.state.user.userName}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-label">Nick name{this.state.validation.nickName ? '*' : ''}</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="nickName"
                                                    maxLength="10"
                                                    value={this.state.user.nickName}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="form-label">First Name{this.state.validation.firstName ? '*' : ''}</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="firstName"
                                                    value={this.state.user.firstName}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="form-label">Last Name{this.state.validation.lastName ? '*' : ''}</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="lastName"
                                                    value={this.state.user.lastName}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-label">Email*</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    name="email"
                                                    value={this.state.user.email}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-label">Phone No.{this.state.validation.phone ? '*' : ''}</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="phone"
                                                    value={this.state.user.phone}
                                                    onChange={this.handleChange}
                                                    maxLength="15"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="form-label">Company{this.state.validation.company ? '*' : ''}</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="company"
                                                    disabled={currentUser ? false : true}
                                                    value={this.state.user.company}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label className="form-label">Designation{this.state.validation.designation ? '*' : ''}</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="designation"
                                                    value={this.state.user.designation}
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-label">Speciality{this.state.validation.speciality ? '*' : ''}</label>
                                                <div className="speciality-tree">
                                                    <SpecialityCategoryTree
                                                        pageName={"newUser"}
                                                        user={this.props.user}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h4>Assign Group</h4>
                                    <hr/>
                                    <div className="row">
                                        <div className="col-4"><span className="form-subtitle">Groups*</span></div>
                                        <div className="col-4"><span className="form-subtitle"></span></div>
                                        <div className="col-4"><span className="form-subtitle">Default*</span></div>
                                    </div>
                                    <div className="user-group-section scroll">
                                        <div className="form-group">
                                            {groups}
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <input
                                        type="button"
                                        className="gray-button"
                                        value="Cancel"
                                        onClick={this.openModalCancel} />
                                    <input
                                        type="button"
                                        className="gray-button"
                                        value="Add User"
                                        onClick={this.saveUser} />
                                </div>
                                {this.state.isDirty &&
                                <span className="error-message">
                                    {this.state.errorMessage}
                                </span>
                                }
                            </form>
                        </div>
                    </div>

                    <CancelModal
                        data={this.state.user}
                        isOpen={this.state.isModalOpen}
                        modalClose={this.modalClose} />
                </div>
                }
            </div>
        );
    }
}

export default AddUserForm;
