import React, { Component } from 'react';

// import SwatchReferenceModal from './swatchReferenceModal'


export class SampleReferenceRender extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        return (
            <div >{this.props.value}</div>
        );
    }
}
// //////////////////////////////////////////////////////////////////////////////////////////////


export class SampleReferenceEditor extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    /**
     * Opens the sample swatch popup
     */
    componentWillMount() {
        console.log('SampleReferenceEditor editor', this.props, this.state);
        // this.props.openSwatchPopup(this.props.node);

        let flag = false; // Using a flag to set the edit status of the cell

        // Set of checks for setting flag
        if (this.props.node.data.displayLevel == 'Color' && this.props.node.level == 1) {
            flag = true;
        }

        if (this.props.node.data.displayLevel == 'Style' && this.props.node.level == 0) {
            flag = true;
        }

        if (this.props.node.data.displayLevel == 'Size' && this.props.node.level == 2) {
            flag = true;
        }

        if (this.props.node.data.dataLevel == 'SINGLE') {
            flag = true;
        }

        if (!flag) {
            this.props.api.stopEditing();
        } else {
            this.props.openSwatchPopup(this.props.node);
        }
    }


    isPopup() {
        return true;
    }

    // componentWillUnmount() {
    //     this.props.api.tabToNextCell();
    // }

    /**
     * Gets the value when the component unmounts and pushes it for render
     * @return {string} cell value
     */
    getValue() {
        return this.props.value;
    }

    render() {
        return (
            <div />
        );
    }
}

// ////////////////////////////////////////////////////////////////////////////////////
