export const addGridData = (data) => {
    return {
        type:"ADD_GRID_DETAILS",
        data
    };
};

export const addGridDropDowns = (data) => {
    return {
        type:"ADD_GRID_DROP_DOWNS",
        data
    };
};

export const updateCheckedPublicationRows = (data) => {
    return {
        type: "UPDATE_PUB_CHECKED_ROWS",
        data
    };
};

export const updateListDirty = (data) => {
    return {
        type: "UPDATE_LIST_DIRTY",
        data
    };
};

// used for rightDrawer
export const publicationCounter = () => {
    return {
        type: 'PUBLICATION_COUNTER'
    };
};

// used for rightDrawer
export const addPublicationGridData = (data) => {
    return {
        type:"ADD_PUBLICATION_GRID_DETAILS",
        data
    };
};

// used for right Drawer savedListDropDown
export const addSelectedPresentationName = (data) => {
    return {
        type: "ADD_SELECTED_PRES_NAME",
        data
    };
};

// used for right Drawer savedListDropDown
export const addListItemsNamesData = (data) => {
    return {
        type: "ADD_LIST_ITEMS_NAMES",
        data
    };
};

// used for right Drawer savedListDropDown
export const addPublicationColumnNames = (data) => {
    return {
        type: "ADD_PUBLICATION_COLUMN_NAMES",
        data
    };
};

// used for right Drawer savedListDropDown
export const addPubListDropdownData = (data) => {
    return {
        type: "ADD_PUB_LIST_DROPDOWN_DATA",
        data
    };
};

// below don't know where used
export const updateGridData = (updateData) =>{
    return {
        type:"UPDATE_GRID_DATA",
        updateData
    };
};

export const updateSelectedRows = (data) => {
    return {
        type:"UPDATE_SELECTED_ROWS",
        data
    };
};

export const deleteSelectedProducts = () =>{
    return {
        type:"DELETE_SELECTED_PRODUCTS"
    };
};

export const getFilteredProductData = (data) => {
    return {
        type: "GET_PRODUCT_DATA",
        data
    };
};

export const addColumnDetails = (data) => {
    return {
        type:"ADD_COLUMN_DETAILS",
        data
    };
};

export const gridDataChanged = () => {
    return {
        type:"GRID_DATA_CHANGED"
    };
};

export const gridColumnChanged = () => {
    return {
        type:"GRID_COLUMN_CHANGED"
    };
};

export const publicationToggleFind = (data)=>{
    return {
        type:"PUB_TOGGLE_FIND",
        data
    };
};
