import React, { Component } from 'react';
import axios from 'axios';
import NotificationManager from '../common/components/notification';
import FieldLengthComponent from './fieldLengthComponent';
import AdminServices from '../admin/admin-services/admin-services';

class FieldLength extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            updateBtnLabel: 'Apply Changes',
            btnStatus: false,
            fields: [],
            isModified: false,
        };
        this.updateFieldsLength = this.updateFieldsLength.bind(this);
        this.handleOnchange = this.handleOnchange.bind(this);
        this.resetFormData = this.resetFormData.bind(this);
    }

    componentDidMount() {
        this.initializeService();
    }

    initializeService() {
        const _this = this;

        AdminServices.getAdminsettingsVal().then((response) => {
            const res = response;
            if (res.status) {
                _this.setState({ isLoaded: true, fields: res.adminSettingsList });
            }
        }).catch((error) => {
            console.log('FieldLength initializeService API call failed', error);
            NotificationManager({ message: 'Service Failed to get data', type: 4 });
        });
    }

    resetFormData() {
        this.initializeService();
        this.setState({ isModified: false });
    }

    handleOnchange(event) {
        const fieldIndex = $(event.target).data('index');
        const value = event.target.value;

        if (isNaN(value)) {
            return false;
        }
        const fields = Object.assign([], this.state.fields);
        fields[fieldIndex].value = value;

        this.setState({ fields: fields, isModified: true });
    }

    updateFieldsLength() {
        const _this = this;
        let flag = 0;
        let countflag = 0;

        this.state.fields.map((item, index) => {
            if (item.value === '') {
                flag++;
            }
            if (item.value.toString().length > 10) {
                countflag++;
            }
        });
        if (flag) {
            NotificationManager({ message: '*Mandatory fields marked with an asterisk are missing.', type: 4 });
            return false;
        }
        if (countflag) {
            NotificationManager({ message: 'Value entered exceeds the maximum allowed for the field(s).', type: 4 });
            return false;
        }

        this.setState({ updateBtnLabel: 'Apply Changes', btnStatus: true, isModified: false });

        const data = this.state.fields;

        AdminServices.updateAdminsettingsVal(data).then((response) => {
            const res = response;

            if (res.status) {
                _this.setState({ updateBtnLabel: 'Apply Changes', btnStatus: false });
                NotificationManager({ message: res.responseMessage, type: 1 });
            } else {
                _this.initializeService();
                _this.setState({ updateBtnLabel: 'Apply Changes', btnStatus: false });
                NotificationManager({ message: res.responseMessage, type: 4 });
            }
        }).catch((error) => {
            console.log('FieldLength updateFieldsLength API call failed', error);
            NotificationManager({ message: 'Service Failed to get data', type: 4 });
        });
    }

    render() {
        $('.page-title a').text("ADMIN: Field Length");
        const formFields = this.state.fields.map((val, idx) => {
            return (
                <div className="row" key={idx}>
                    <div className="form-group">
                        <label className="form-label">
                            {
                                val.name === 'Max CopyWrite Value' ? 'Maximum characters for Display name field in Copy popup' :
                                    val.name === 'Max PersonalView Value' ? 'Maximum number of Personal Views' :
                                        val.name === 'Max Attribute Completed Value' ? 'Minimum Value for Attributes Completed' : 'Maximum number of Login Attempts'
                            }
                        </label>
                        <input type="text" maxLength="3" className="form-control" name="userName" value={val.value} data-index={idx} onChange={this.handleOnchange}></input>
                    </div>
                </div>
            );
        });

        return (
            <FieldLengthComponent
                state={this.state}
                props={this.props}
                formFields={formFields}
                updateFieldsLength={this.updateFieldsLength}
                resetFormData={this.resetFormData}
            />
        );
    }
}
export default FieldLength;
