import React, { Component } from 'react';
import UserFieldsComponent from './userFieldsComponent';
import GridDataBuilder from '../common/gridDataBuilder';
import NotificationManager from '../common/components/notification';
import axios from 'axios';
import AdminServices from '../admin/admin-services/admin-services';

class UserFields extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
        };
        this.initializeGrid = this.initializeGrid.bind(this);
        this.buildGridData = this.buildGridData.bind(this);
    }

    componentDidMount() {
        const _this = this;
        this.initializeGrid();
        $('.UserFieldGrid').on('click', '.sample-boolean', function(e) {
            const rowId = $(this).attr('data-row');
            const fieldName = $(this).attr('data-fieldname');
            const value = $(this).prop('checked');
            _this.userFieldsGrid.setcellvalue(rowId, fieldName, value);
            const rows = _this.userFieldsGrid.getrows();

            AdminServices.updateUserMandatoryfields(rows).then((response) => {
                const res = response;
                if (res.status) {
                    NotificationManager({message: res.responseMessage, type: 1});
                } else {
                    NotificationManager({message: res.responseMessage, type: 4});
                }
            }).catch((error) => {
                console.log('UserFields componentDidMount API call failed', error);
                NotificationManager({message: error, type: 4});
            });
        });
    }

    /**
     * Setter for grid data to build the grid
     * @param {*} resData
     * @return {Array}
     */
    buildGridData(resData) {
        const res = {};
        res.columnDetails = [{
            'columnTitle': 'Field Id',
            'fieldName': 'id',
            'type': 'int',
            'visibility': true,
            'pk': true,
            'order': 0,
            'map': null,
            'id': null,
            'popup': false,
        },
        {
            'columnTitle': 'User Fields',
            'fieldName': 'userFields',
            'type': 'string',
            'visibility': false,
            'pk': false,
            'order': 1,
            'map': null,
            'id': null,
            'popup': false,
        },
        {'columnTitle': 'Field Name',
            'fieldName': 'displayName',
            'type': 'string',
            'visibility': true,
            'pk': false,
            'order': 2,
            'map': null,
            'id': null,
            'popup': false,
        },
        {'columnTitle': 'Is Mandatory?',
            'fieldName': 'mandatoryFields',
            'type': 'bool',
            'visibility': true,
            'pk': false,
            'order': 3,
            'map': null,
            'id': null,
            'popup': false,
        },
        ];
        res.data = resData;
        return res;
    }


    /**
     * Calls backend to get data
     */
    initializeGrid() {
        const base = this;

        AdminServices.getUserMandatoryfields().then((response) => {
            const res = response;
            const gridData = base.buildGridData(res);
            base.gridData = GridDataBuilder(gridData, 'userfields');
            base.setState({isLoaded: true});
        }).catch((error) => {
            console.log('UserFields initializeGrid API call failed', error);
        });
    }


    render() {
        const footerHeight = 50;
        const headerHeight = 64;
        $('.page-title a').text("ADMIN: User Fields");

        const gridHeight = window.innerHeight - footerHeight - headerHeight;
        const editSettings = {
            saveOnPageChange: true,
            saveOnBlur: true,
            saveOnSelectionChange: true,
            cancelOnEsc: true,
            saveOnEnter: true,
            editSingleCell: true,
            editOnDoubleClick: true,
            editOnF2: true,
        };
        return (
            <UserFieldsComponent
                state = { this.state }
                props= { this.props }
                userFieldsGridRef={(grid) => {
                    this.userFieldsGrid = grid;
                }}
                gridData = { this.gridData }
                gridHeight = { gridHeight }
                editSettings = { editSettings }
            />
        );
    }
}
export default UserFields;
