import React from 'react';

export class CreativeDropdownRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            values: null,
            locationParentValues: null,
        };
    }

    componentWillMount() {
        console.log('Creative componentWillMount CreativeDropdownRenderer, ', this.props);

        let setValues = null;
        const getNodes = this.props.rowData;
        const getCol = this.props.colDef.colId;
        let newArr = [];

        if (this.props.rowData) {
            getNodes.map((i, x) => {
                if (i.id == this.props.node.key) {
                    setValues = i[getCol];
                }
            });
        }

        if (this.props.node.group) {
            const leafNodes = this.props.node.allLeafChildren;

            if (getCol == 'location') {
                newArr = leafNodes.map((i, x) => {
                    if (i.data.location != typeof (null)) {
                        return i.data.location;
                    }
                });
            }

            if (getCol == 'photographer') {
                newArr = leafNodes.map((i, x) => {
                    if (i.data.location != typeof (null)) {
                        return i.data.photographer;
                    }
                });
            }

            if (getCol == 'retoucher') {
                newArr = leafNodes.map((i, x) => {
                    if (i.data.location != typeof (null)) {
                        return i.data.retoucher;
                    }
                });
            }

            if (getCol == 'shotCount') {
                this.setState({
                    locationParentValues: this.props.value ? this.props.value : setValues,
                });

                return;
            }

            let arrFinal = newArr.filter((a, b, s) => {
                return s.indexOf(a) == b;
            });

            let remEmpty = arrFinal.filter(function (e) {
                return e != null;
            });

            arrFinal = remEmpty.join(", ");

            arrFinal = Array.from(new Set(arrFinal.split(','))).toString();

            this.setState({
                locationParentValues: arrFinal,
            });
        }

        this.setState({
            values: setValues,
        });
    }

    render() {
        let propsVals = this.props && this.props.value ? this.props.value : '';
        let setCurrentVals = this.state.values ? this.state.values : propsVals;

        const groupedRecords = this.state.locationParentValues;
        let showGrouped = this.props.node.group ? <div> {groupedRecords ? groupedRecords : <div className="arrow downPos"></div>} </div> : <div> {setCurrentVals ? setCurrentVals : <div className="arrow downPos"></div>} </div>;

        console.log('dropdown CreativeDropdownRenderer: ', this.props, this.state);

        return (
            <div>
                {showGrouped}
            </div>
        );
    }
}
