import React from 'react';
import NotificationManager from '../../common/components/notification';
import moment from 'moment';
/**
 * renders all the checkbox columns
 */
export class CheckboxRender extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: {},
            disabled: {},
            mandatoryFieldsNotPopulated: [],
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    // eslint-disable-next-line consistent-return
    componentWillMount() {
        const data = this.props.node.data;
        const checked = this.state.checked;
        const disabled = this.state.disabled;

        let DisplayFlag = false;
        let CopyFlag = false;
        if (data) {
            if (this.props.node.data.displayLevel == 'Style' && this.props.node.level == 0) {
                DisplayFlag = true;
            } else if (this.props.node.data.displayLevel == 'Color' && this.props.node.level == 1) {
                DisplayFlag = true;
            } else if (this.props.node.data.displayLevel == 'Size' && this.props.node.level == 2) {
                DisplayFlag = true;
            } else if (this.props.node.data.dataLevel == 'SINGLE') {
                DisplayFlag = true;
            }
            if (this.props.node.data.copyLevel == 'Style' && this.props.node.level == 0) {
                CopyFlag = true;
            } else if (this.props.node.data.copyLevel == 'Color' && this.props.node.level == 1) {
                CopyFlag = true;
            } else if (this.props.node.data.copyLevel == 'Size' && this.props.node.level == 2) {
                CopyFlag = true;
            } else if (this.props.node.data.dataLevel == 'SINGLE') {
                CopyFlag = true;
            }
        }

        if (this.props.data) {
            switch (this.props.column.colId) {
                case 'itemReadyToOrder':

                    // const mandatoryFields = this.props.itemReadyToOrderFields.map(function (obj) {
                    //     return { [obj.actualFieldName]: obj.fields };
                    // });

                    // const stringofMandatoryFields = [];

                    // mandatoryFields.forEach((obj) => {
                    //     for (const key in obj) {
                    //         if (obj.hasOwnProperty(key)) {
                    //             stringofMandatoryFields.push(key);
                    //         }
                    //     }
                    // });

                    // const mandatoryFieldsNotPopulated = [];

                    // for (let i = 0; i < stringofMandatoryFields.length; i++) {

                    //     if (!data[stringofMandatoryFields[i]] || data[stringofMandatoryFields[i]].length == 0) {
                    //         if (stringofMandatoryFields[i] == 'productId') {
                    //             if (!data['parentProductId'] && !data['intProdId'] && !data['chldProdId']) {
                    //                 mandatoryFieldsNotPopulated.push(stringofMandatoryFields[i]);
                    //             }
                    //         } else if (stringofMandatoryFields[i] == 'channel') {
                    //             if (data['channelIds']) {
                    //                 if (data['channelIds'].length == 0) {
                    //                     mandatoryFieldsNotPopulated.push(stringofMandatoryFields[i]);
                    //                 }
                    //             } else {
                    //                 mandatoryFieldsNotPopulated.push(stringofMandatoryFields[i]);
                    //             }
                    //         } else {
                    //             mandatoryFieldsNotPopulated.push(stringofMandatoryFields[i]);
                    //         }
                    //     }
                    // }

                    checked[this.props.column.colId] = this.props.value ? this.props.value : false;

                    if (!DisplayFlag) {
                        disabled[this.props.column.colId] = true;
                    }

                    // disables checkbox when its checked
                    if (data.itemReadyToOrder == true) {
                        disabled[this.props.column.colId] = true;
                    }

                    this.setState({ checked, disabled });
                    break;

                case 'isPiRequestSent':

                    if (!CopyFlag || this.props.value == true) {
                        disabled[this.props.column.colId] = true;
                    }

                    checked[this.props.column.colId] = this.props.value ? this.props.value : false;
                    // disabled[this.props.column.colId] = this.props.value == "true" ? true : false;

                    this.setState({ checked, disabled });

                    break;

                case 'isPiReceived':

                    if (!CopyFlag || this.props.value == true) {
                        disabled[this.props.column.colId] = true;
                    }

                    checked[this.props.column.colId] = this.props.value ? this.props.value : false;
                    // disabled[this.props.column.colId] = this.props.value ? true : false;

                    this.setState({ checked, disabled });

                    break;

                case 'isPiApproved':

                    if (!CopyFlag || this.props.value == true) {
                        disabled[this.props.column.colId] = true;
                    }

                    checked[this.props.column.colId] = this.props.value ? this.props.value : false;
                    // disabled[this.props.column.colId] = this.props.value ? true : false;

                    this.setState({ checked, disabled });

                    break;

                case 'isResendPiRequest':

                    if (!CopyFlag || this.props.value == true) {
                        disabled[this.props.column.colId] = true;
                    }

                    checked[this.props.column.colId] = this.props.value ? this.props.value : false;
                    // disabled[this.props.column.colId] = this.props.value ? true : false;

                    this.setState({ checked, disabled });

                    break;

                case 'earlyReqSampleSwatch':

                    if (!DisplayFlag || this.props.value == true) {
                        disabled[this.props.column.colId] = true;
                    }

                    checked[this.props.column.colId] = this.props.value ? this.props.value : false;
                    // disabled[this.props.column.colId] = this.props.value ? true : false;

                    this.setState({ checked, disabled });

                    break;


                case 'isSampleRequested':

                    if (!DisplayFlag || this.props.value == true) {
                        disabled[this.props.column.colId] = true;
                    }

                    checked[this.props.column.colId] = this.props.value ? this.props.value : false;
                    // disabled[this.props.column.colId] = this.props.value == "true" ? true : false;

                    this.setState({ checked, disabled });

                    break;

                case 'isSampleReceived':

                    if (!DisplayFlag || this.props.value == true) {
                        disabled[this.props.column.colId] = true;
                    }

                    checked[this.props.column.colId] = this.props.value ? this.props.value : false;
                    // disabled[this.props.column.colId] = this.props.value == "true" ? true : false;

                    this.setState({ checked, disabled });

                    break;

                case 'isSampleApproved':

                    if (!DisplayFlag || this.props.value == true) {
                        disabled[this.props.column.colId] = true;
                    }

                    checked[this.props.column.colId] = this.props.value ? this.props.value : false;
                    // disabled[this.props.column.colId] = this.props.value == "true" ? true : false;

                    this.setState({ checked, disabled });

                    break;

                case 'isCopywritterApproved':

                    disabled[this.props.column.colId] = true;
                    checked[this.props.column.colId] = this.props.value ? this.props.value : false;
                    // disabled[this.props.column.colId] = this.props.value == "true" ? true : false;

                    this.setState({ checked, disabled });

                    break;

                case 'isWebproducerapproved':

                    disabled[this.props.column.colId] = true;
                    checked[this.props.column.colId] = this.props.value ? this.props.value : false;
                    // disabled[this.props.column.colId] = this.props.value == "true" ? true : false;

                    this.setState({ checked, disabled });

                    break;

                case 'sampleMoved':

                    if (!DisplayFlag || this.props.value == true) {
                        disabled[this.props.column.colId] = true;
                    }

                    checked[this.props.column.colId] = this.props.value ? this.props.value : false;
                    // disabled[this.props.column.colId] = this.props.value == "true" ? true : false;

                    this.setState({ checked, disabled });

                    break;

                case 'attrCompleted':

                    if (!CopyFlag || this.props.value == true) {
                        disabled[this.props.column.colId] = true;
                    }

                    checked[this.props.column.colId] = this.props.value ? this.props.value : false;
                    // disabled[this.props.column.colId] = this.props.value == "true" ? true : false;

                    this.setState({ checked, disabled });

                    break;

                case 'allowBackorder':

                    if (this.props.value == true) {
                        disabled[this.props.column.colId] = true;
                    }

                    checked[this.props.column.colId] = this.props.value ? this.props.value : false;
                    // disabled[this.props.column.colId] = this.props.value == "true" ? true : false;

                    this.setState({ checked, disabled });

                    break;

                case 'piHandoff':

                    if (!CopyFlag || this.props.value == true) {
                        disabled[this.props.column.colId] = true;
                    }

                    checked[this.props.column.colId] = this.props.value ? this.props.value : false;
                    // disabled[this.props.column.colId] = this.props.value == "true" ? true : false;

                    this.setState({ checked, disabled });

                    break;

                case 'isActiveLive':

                    if (!DisplayFlag || this.props.value == true) {
                        disabled[this.props.column.colId] = true;
                    }

                    checked[this.props.column.colId] = this.props.value ? this.props.value : false;
                    // disabled[this.props.column.colId] = this.props.value == "true" ? true : false;

                    this.setState({ checked, disabled });

                    break;

                default:
                    if (data && this.props.column.colId == 'itemReadyToOrder') {
                        disabled[this.props.column.colId] = data['itemReadyToOrder'] == true ? true : false;
                    } else {
                        disabled[this.props.column.colId] = false;
                    }
                    checked[this.props.column.colId] = this.props.value ? this.props.value : false;
                    break;
            }
        } else {
            return null;
        }

        if (this.props.isEditable == 'R') {
            disabled[this.props.column.colId] = true;
            this.setState({ disabled });
        }
    }


    /**
     *
     * @param {Array} fields
     * Validates the fields vs required fields
     * Allows users to check or uncheck checkboxes
     */
    checkRequired(fields) {
        const data = this.props.data;
        let requiredList;

        requiredList = fields.map(function (obj) {
            return { [obj.actualFieldName]: obj.fields };
        });

        const stringofMpr2Fields = [];

        requiredList.forEach((obj) => {
            for (const key in obj) {
                if (obj.hasOwnProperty(key)) {
                    stringofMpr2Fields.push({'fieldName': key, 'displayName': obj[key]});
                }
            }
        });

        const fieldsNotPopulated = [];

        stringofMpr2Fields.map((i) => { // keys have been changed to match the data sent by the API
            if (i == 'channel') {
                i = 'channelIds';
            }
            if (i == 'division') {
                i = 'divisionName';
            }

            // Checking types beloew to make sure of Numbers and Bools
            // We dont have the types of the other columns so we check type.
            // The truthly check here passes for 0 and if the propery exists
            // so we check types after trutly to make sure of bools and numbers

            if (!data[i.fieldName] || typeof data[i.fieldName] == 'boolean' && data[i.fieldName] == false) {
                if (typeof data[i.fieldName] == 'number' && data[i.fieldName] == 0) {
                    return;
                }

                fieldsNotPopulated.push(i.displayName);
            }
        });

        if (fieldsNotPopulated.length > 0) {
            const listItems = fieldsNotPopulated.map((i) => '<li>' + i + '</li>').join('');

            NotificationManager({
                message: 'The following fields must be filled out prior to checking the </br>' + this.props.colDef.headerName + ' checkbox'
                + '<div id="req-items"><ul>' + listItems + '</ul></div>', type: 4,
            });

            return false;
        } else {
            return true;
        }
    }

    // readyToOrderDate

    handleInputChange(event) {
        console.log('Checkbox Contianer handleInputChange', this.props.data, this.state);

        const _this = this;
        let checked = this.state.checked;
        const data = this.props.data;
        const disabled = this.state.disabled;
        let piApproved = false;
        let sampleApproved = false;
        let isSampleRequested = false;
        let isPiRequestSent = false;
        const colId = this.props.column.colId;
        let flag;

        this.props.api.forEachNode(function (rowCheck) {
            if (rowCheck.data.productId == data.productId) {
                if (rowCheck.data.isPiRequestSent == true) {
                    isPiRequestSent = true;
                }
                if (rowCheck.data.isPiRequestSent == true && rowCheck.data.isPiReceived == true) {
                    piApproved = true;
                }
                if (rowCheck.data.isSampleRequested == true) {
                    isSampleRequested = true;
                }
                if (rowCheck.data.isSampleRequested == true && rowCheck.data.isSampleReceived == true) {
                    sampleApproved = true;
                }
            }
        });

        switch (this.props.column.colId) {
            case 'itemReadyToOrder':

                flag = this.checkRequired(this.props.itemReadyToOrderFields);

                if (flag) {
                    checked[this.props.column.colId] = event.target.checked;
                    disabled[this.props.column.colId] = true;

                    this.setState({ checked, disabled });
                    const rowData = this.props.node.data;
                    const readyToOrderDate = new Date();
                    rowData['itemReadyToOrder'] = true;
                    rowData['readyToOrderDate'] = readyToOrderDate;
                    this.props.rowUpdate(rowData, this.props.rowIndex, colId);
                }

                break;


            case 'mpr2Approved':
                //checks for required fields
                flag = this.checkRequired(this.props.mpr2Approved);

                if (flag) {
                    checked[this.props.column.colId] = event.target.checked;

                    this.setState({ checked });
                    const mpr2Data = this.props.node.data;
                    mpr2Data['mpr2Approved'] = event.target.checked;
                    this.props.rowUpdate(mpr2Data, this.props.rowIndex, colId);
                }

                break;

            // for isPiRequestSent Column
            case 'isPiRequestSent':
                let checkFlag = false;

                // checking data object to see if itemReadyToOrder exists
                if (data['itemReadyToOrder']) {
                    checkFlag = false;
                } else {
                    checkFlag = true;
                }

                if (checkFlag) {
                    NotificationManager({ message: 'Item Ready To Order flag has not been checked', type: 4 });
                    return;
                }

                checked[this.props.column.colId] = event.target.checked;
                disabled[this.props.column.colId] = true;

                this.setState({ checked, disabled });
                const isPiRequestSentRowData = this.props.node.data;
                const piRequestedDate = new Date();
                isPiRequestSentRowData['isPiRequestSent'] = true;
                isPiRequestSentRowData['piRequestedDate'] = piRequestedDate;
                this.props.rowUpdate(isPiRequestSentRowData, this.props.node.rowIndex, colId);

                break;

            case 'isPiReceived':

                if (isPiRequestSent == true) {
                    checked[this.props.column.colId] = event.target.checked;
                    disabled[this.props.column.colId] = true;
                    this.setState({ checked });
                    const isPiReceivedRowData = this.props.node.data;
                    const isPiReceivedDate = new Date();
                    isPiReceivedRowData['isPiReceived'] = true;
                    isPiReceivedRowData['piReceivedDate'] = isPiReceivedDate;
                    this.props.rowUpdate(isPiReceivedRowData, this.props.node.rowIndex, colId);
                    this.props.openPiUploadPopup(isPiReceivedRowData);
                } else {
                    NotificationManager({ message: 'PI Request Sent has to be checked for PI to be Received', type: 4 });
                    return;
                }

                break;

            case 'isPiApproved':

                if (piApproved == true) {
                    checked[this.props.column.colId] = event.target.checked;
                    disabled[this.props.column.colId] = true;
                    this.setState({ checked });
                    const isPiApprovedRowData = this.props.node.data;
                    const isPiApprovedDate = new Date();
                    isPiApprovedRowData['isPiApproved'] = true;
                    isPiApprovedRowData['piApprovedDate'] = isPiApprovedDate;
                    this.props.rowUpdate(isPiApprovedRowData, this.props.node.rowIndex, colId);
                } else {
                    NotificationManager({ message: 'PI Request Sent & PI Received has to be checked for PI to be Approved', type: 4 });
                    return;
                }

                break;

            case 'isResendPiRequest':

                checked[this.props.column.colId] = event.target.checked;
                disabled[this.props.column.colId] = true;
                this.setState({ checked });
                const isResendPiRequestRowData = this.props.node.data;
                // let isPiApprovedDate = new Date();
                isResendPiRequestRowData['isResendPiRequest'] = true;
                // earlyReqSampleSwatchRowData['isPiApprovedDate'] = isPiApprovedDate;
                this.props.rowUpdate(isResendPiRequestRowData, this.props.node.rowIndex, colId);

                break;

            case 'earlyReqSampleSwatch':

                checked[this.props.column.colId] = event.target.checked;
                disabled[this.props.column.colId] = true;
                this.setState({ checked });
                const earlyReqSampleSwatchRowData = this.props.node.data;
                const sampleSwatchRequestedDate = new Date();
                earlyReqSampleSwatchRowData['earlyReqSampleSwatch'] = true;
                earlyReqSampleSwatchRowData['sampleSwatchRequestedDate'] = sampleSwatchRequestedDate;
                this.props.rowUpdate(earlyReqSampleSwatchRowData, this.props.node.rowIndex, colId);

                break;

            case 'sampleMoved':

                checked[this.props.column.colId] = event.target.checked;
                disabled[this.props.column.colId] = true;
                this.setState({ checked });
                const sampleMovedRowData = this.props.node.data;
                const sampleMovedDate = new Date();
                sampleMovedRowData['sampleMoved'] = true;
                sampleMovedRowData['sampleMovedDate'] = sampleMovedDate;
                this.props.rowUpdate(sampleMovedRowData, this.props.node.rowIndex, colId);

                break;

            case 'isSampleRequested':

                let checkFlag2 = false;

                // checking data object to see if itemReadyToOrder exists
                if (data['itemReadyToOrder']) {
                    checkFlag2 = false;
                } else {
                    checkFlag2 = true;
                }

                if (checkFlag2) {
                    NotificationManager({ message: 'Item Ready To Order flag has not been checked', type: 4 });
                    return;
                }

                checked[this.props.column.colId] = event.target.checked;
                disabled[this.props.column.colId] = true;
                this.setState({ checked });
                const isSampleRequestedRowData = this.props.node.data;
                const isSampleRequestedDate = new Date();
                isSampleRequestedRowData['isSampleRequested'] = true;
                isSampleRequestedRowData['sampleRequestedDate'] = isSampleRequestedDate;
                this.props.rowUpdate(isSampleRequestedRowData, this.props.node.rowIndex, colId);

                break;

            case 'isSampleReceived':

                if (isSampleRequested == true) {
                    checked[this.props.column.colId] = event.target.checked;
                    disabled[this.props.column.colId] = true;
                    this.setState({ checked });
                    const isSamplerecievedRowData = this.props.node.data;
                    // let sampleMovedDate = new Date();
                    isSamplerecievedRowData['isSampleReceived'] = true;
                    // sampleMovedRowData['sampleMovedDate'] = sampleMovedDate;
                    this.props.rowUpdate(isSamplerecievedRowData, this.props.node.rowIndex, colId);
                    // this.props.api.startEditingCell({rowIndex: this.props.rowIndex, colKey: 'samples'});
                    // this.props.api.stopEditing();
                    this.props.openSamplesPopup(this.props.node.data);
                } else {
                    NotificationManager({ message: 'Sample Requested has to be checked before Samples can be received', type: 4 });
                    return;
                }

                break;

            case 'isSampleApproved':

                if (sampleApproved == true) {
                    checked[this.props.column.colId] = event.target.checked;
                    disabled[this.props.column.colId] = true;
                    this.setState({ checked });
                    const isSampleapprovedRowData = this.props.node.data;
                    const sampleApprovedDate = new Date();
                    isSampleapprovedRowData['isSampleApproved'] = true;
                    isSampleapprovedRowData['sampleApprovedDate'] = sampleApprovedDate;
                    this.props.rowUpdate(isSampleapprovedRowData, this.props.node.rowIndex, colId);
                } else {
                    NotificationManager({ message: 'Sample Requested and Sample Received has to be checked before Samples can be approved', type: 4 });
                    return;
                }

                break;

            case 'isCopywriterApproved':

                checked[this.props.column.colId] = event.target.checked;
                disabled[this.props.column.colId] = true;
                this.setState({ checked });
                const isCopywriterApprovedRowData = this.props.node.data;
                // let sampleMovedDate = new Date();
                isCopywriterApprovedRowData['isCopywriterApproved'] = true;
                // sampleMovedRowData['sampleMovedDate'] = sampleMovedDate;
                this.props.rowUpdate(isCopywriterApprovedRowData, this.props.node.rowIndex, colId);

                break;

            case 'isWebproducerApproved':

                checked[this.props.column.colId] = event.target.checked;
                disabled[this.props.column.colId] = true;
                this.setState({ checked });
                const isWebproducerApprovedRowData = this.props.node.data;
                // let sampleMovedDate = new Date();
                isWebproducerApprovedRowData['isWebproducerApproved'] = true;
                // sampleMovedRowData['sampleMovedDate'] = sampleMovedDate;
                this.props.rowUpdate(isWebproducerApprovedRowData, this.props.node.rowIndex, colId);

                break;

            case 'attrCompleted':
                checked[this.props.column.colId] = event.target.checked;
                disabled[this.props.column.colId] = true;
                this.setState({ checked });
                const attrCompletedRowData = this.props.node.data;
                const attrCompletedDate = new Date();
                attrCompletedRowData['attrCompleted'] = true;
                attrCompletedRowData['attrCompletedDate'] = attrCompletedDate;
                this.props.rowUpdate(attrCompletedRowData, this.props.node.rowIndex, colId);

                break;

            case 'allowBackorder':
                checked[this.props.column.colId] = event.target.checked;
                disabled[this.props.column.colId] = true;
                this.setState({ checked });
                const allowBackorderRowData = this.props.node.data;
                const allowBackorderDate = new Date();
                allowBackorderRowData['allowBackorder'] = true;
                allowBackorderRowData['backOrderdate'] = allowBackorderDate;
                this.props.rowUpdate(allowBackorderRowData, this.props.node.rowIndex, colId);

                break;

            case 'piHandoff':
                checked[this.props.column.colId] = event.target.checked;
                disabled[this.props.column.colId] = true;
                this.setState({ checked });
                const piHandoffRowData = this.props.node.data;
                const piHandoffDate = new Date();
                piHandoffRowData['piHandoff'] = true;
                piHandoffRowData['piHandoffDate'] = piHandoffDate;
                this.props.rowUpdate(piHandoffRowData, this.props.node.rowIndex, colId);

                break;

            case 'isActiveLive':
                checked[this.props.column.colId] = event.target.checked;
                disabled[this.props.column.colId] = true;
                this.setState({ checked });
                const isActiveLiveRowData = this.props.node.data;
                const activateLiveDate = new Date();
                isActiveLiveRowData['isActiveLive'] = true;
                isActiveLiveRowData['activeLiveDate'] = activateLiveDate;
                this.props.rowUpdate(isActiveLiveRowData, this.props.node.rowIndex, colId);
                this.props.node.setDataValue('activeLiveDate', moment(new Date()).format('MM/DD/YYYY')); // added to update active live date without refresh
                break;

            default:
                checked = this.state.checked;
                checked[this.props.column.colId] = event.target.checked;
                this.setState({ checked });
                const nodeData = this.props.node.data;
                nodeData[this.props.column.colId] = event.target.checked;
                this.props.rowUpdate(nodeData, this.props.node.rowIndex, colId);
        }

        /**
         * Added to set parent/itm/children records to checked without grid refresh
         */
        let flagChecks = true;
        let parentChecked = true;
        let parentClicked = false;
        let intmClicked = false;
        if (this.props.data.dataLevel == 'PARENT') {
            parentClicked = true;
        }
        if (this.props.data.dataLevel == 'INTERMEDIATE') {
            intmClicked = true;
        }
        this.props.api.forEachNode(function (rowNode) {
            const parentId = _this.props.data.parentProductId;
            const prodId = rowNode.data.productId;
            const colId = _this.props.column.colId;

            const childrenIds = rowNode.data.childrenIds;
            if (childrenIds.includes(_this.props.data.productId)) {
                console.log('check child checked 1', rowNode);
                _this.props.api.forEachNode(function (rowNodeInner) {
                    if (childrenIds.includes(rowNodeInner.data.productId) && !rowNodeInner.data[colId]) {
                        flagChecks = false;
                    }
                });
                if (flagChecks) {
                    rowNode.setDataValue(colId, true);
                    _this.props.api.forEachNode(function (rowNodeParent) {
                        if (rowNode.data.parentProductId == rowNodeParent.data.productId) {
                            const checkChilds = rowNodeParent.data.childrenIds;
                            _this.props.api.forEachNode(function (rowNodeInnerParent) {
                                if (checkChilds.includes(rowNodeInnerParent.data.productId) && !rowNodeInnerParent.data[colId]) {
                                    parentChecked = false;
                                }
                            });
                            if (parentChecked) {
                                rowNodeParent.setDataValue(colId, true);
                            }
                        }
                    });
                }
            }
            if (intmClicked) {
                if (rowNode.data.productId == _this.props.data.intProdId) {
                    rowNode.data.childrenIds.forEach((item, index) => {
                        _this.props.api.forEachNode(function (rowNodeIntm) {
                            if (rowNodeIntm.data.productId == item) {
                                rowNodeIntm.setDataValue(colId, true);
                            }
                        });
                    });
                }
            }
            if (parentClicked) {
                if (rowNode.data.productId == parentId) {
                    rowNode.data.childrenIds.forEach((item, index) => {
                        _this.props.api.forEachNode(function (rowNodeIntm2) {
                            if (rowNodeIntm2.data.productId == item) {
                                rowNodeIntm2.setDataValue(colId, true);
                                rowNodeIntm2.data.childrenIds.forEach((i, d) => {
                                    _this.props.api.forEachNode(function (rowNodeIntm3) {
                                        if (rowNodeIntm3.data.productId == i) {
                                            rowNodeIntm3.setDataValue(colId, true);
                                        }
                                    });
                                });
                            }
                        });
                    });
                }
            }
        });


        // Sets the RowData by Redraw method when a checkbox is checked
        // Using this as a fallback to make sure the correct value is set
        let redrawRode = [];
        redrawRode.push(this.props.node);
        _this.props.api.redrawRows({ rowNodes: redrawRode });
    }

    render() {
        const checked = this.state.checked && this.state.checked[this.props.column.colId] ? this.state.checked[this.props.column.colId] : false;
        const disabled = this.state.disabled && this.state.disabled[this.props.column.colId] ? this.state.disabled[this.props.column.colId] : false;


        return (

            <div className="ag-checkboxContainer">
                <div className="ag-check-box-container blue-checkbox custom-grid-checkbox" >
                    {
                        this.props.data && !this.props.data.childCount ?
                            <input
                                type="checkbox"
                                checked={checked}
                                disabled={disabled}
                                onChange={this.handleInputChange.bind(this)} />
                            :
                            <div></div>
                    }
                    <label></label>
                </div>
            </div>
        );
    }
}
