import React, { Component } from 'react';
import Modal from '../../modals/common-modal';

class ScanActionCreative extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
        };

        this.inputText = this.inputText.bind(this);
    }

    componentDidMount() {
        $('.modal-holder').css({'width' : '700px'});
    }

    componentWillUnmount() {
        $('.modal-holder').removeAttr("style");
    }

    inputText(e) {
        const value = e.currentTarget.value;
        const field = e.currentTarget.id;
        let data = this.state.data;

        data[field] = value;

        this.setState({data: data});
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen}>
                <div className="creative-scan-action">
                    <div className="modal-header">
                        STUDIO SCANNING
                        <span className="close" onClick={this.props.close}>x</span>
                    </div>
                    <div className="modal-content">
                        <div className="content-left">
                            <label className='top-label'>SELECTED ACTION*</label>
                            <input type="text" className="form-control input-text" id='action' onChange={this.inputText}></input>
                            <label className='top-label'>SCANNER*</label>
                            <input type="text" className="form-control input-text" id='scanner'onChange={this.inputText} ></input>
                            <label className='top-label'>ENTER SHOT ANGLE REF ID*</label>
                            <input type="text" className="form-control input-text" id='shotId' onChange={this.inputText}></input>
                            <label className='top-label'>Use QR code scanner or enter manually</label>

                            <div className="creative-footer">
                                <button className="footer-btn gray-button" onClick={this.props.close}>CANCEL</button>
                                <button className="footer-btn gray-button">SAVE</button>
                                <button className="footer-btn gray-button">SAVE & REPEAT</button>
                            </div>

                        </div>
                        <div className="content-right">
                            <label className='top-label'>STUDIO SET</label>
                            <input type="text" className="form-control input-text" id='studioSet' onChange={this.inputText}></input>
                            <label className='top-label'>STYLIST</label>
                            <input type="text" className="form-control input-text" id='stylist' onChange={this.inputText}></input>
                            <label className='top-label'>MODEL</label>
                            <input type="text" className="form-control input-text" id='model' onChange={this.inputText}></input>
                            <label className='top-label'>MODEL DETAILS</label>
                            <input type="text" className="form-control input-text" id='modelDetails' onChange={this.inputText}></input>
                            <label className='top-label'>HAIR/MAKEUP</label>
                            <input type="text" className="form-control input-text" id='hairMakup' onChange={this.inputText}></input>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default ScanActionCreative;
