import React, { Component } from 'react';
import Modal from '../../common/modals/commonModal.js';
import AddUserGroup from './addUserGroup';

class UserGroupModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen}>
                <AddUserGroup
                    modalClose={this.props.modalClose}
                    addNewGroup={this.props.addNewGroup}
                    user={this.props.user}
                />
            </Modal>
        );
    }
}

export default UserGroupModal;
