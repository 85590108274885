import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as utils from '../../common/utils.js';

import FindComponent from './findComponent';

class Find extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openFind: false,
            totalcount: 0,
            focuscount: 0,
            suggesions: [],
            openSuggestion: false
        };

        this.incrementer = 0;
        this.pageinc = 0;
        this.findResultsData = { total: 0, details: [] };
        this.searchKey = "";
        this.searchTimeout = null;
        this.findSkipFields = [
            'uid', 'keywords', 'copywrite', 'eventBrief', 'copyWriting',
            'layoutRdesign', 'photography', 'productSelection', 'eventBriefId',
            'copyWritingId', 'layoutRdesignId', 'photographyId',
            'productSelectionId', 'deleted', 'eventBriefeditable',
            'photographyeditable', 'layoutRdesigneditable',
            'productSelectioneditable', 'copyWritingeditable'
        ];
        this.toggleFind = this.toggleFind.bind(this);
        this.findResults = this.findResults.bind(this);
        this.findMatchStringObject = this.findMatchStringObject.bind(this);
        this.focusGridText = this.focusGridText.bind(this);
        this.up = this.up.bind(this);
        this.down = this.down.bind(this);
        this.highlightText = this.highlightText.bind(this);
        this.highlightResize = this.highlightResize.bind(this);
        this.getAllRows = this.getAllRows.bind(this);
        this.triggerFind = this.triggerFind.bind(this);
    }

    componentDidMount() {
        var _this = this;

        /*-------------- Start Find Section---------*/
        // JQUERY
        $.extend($.expr[":"], {
            "containsIN": function (elem, i, match, array) {
                return (elem.textContent || elem.innerText || "").toLowerCase().indexOf((match[3] || "").toLowerCase()) >= 0;
            }
        });
    }

    // Find
    toggleFind() {
        var toggle = !this.state.openFind;

        $("#find-text").val("");

        this.incrementer = 0;
        this.pageinc = 0;
        this.state.focuscount = 0;
        this.state.totalcount = 0;
        this.props.marketingGrid.marketingGrid.clearselection();

        $("#MarketingGrid .jqx-grid-cell").removeClass('highlight-text-focus');
        $("#MarketingGrid .jqx-grid-cell").removeClass('highlight-text');

        this.setState({ openFind: toggle });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.toggleFind !== this.props.toggleFind) {
            $("#find-text").val("");

            this.incrementer = 0;
            this.pageinc = 0;
            this.state.focuscount = 0;
            this.state.totalcount = 0;
            this.searchKey = "";

            $("#MarketingGrid .jqx-grid-cell").removeClass('highlight-text-focus');
            $("#MarketingGrid .jqx-grid-cell").removeClass('highlight-text');

            this.setState({ openFind: nextProps.toggleFind });
        }
    }

    getAllRows(obj, result, level, counter) {
        var _this = this;

        if (obj.length > 0) {
            for (var i = 0; i < obj.length; i++) {
                var newObj = Object.assign({}, obj[i]);
                newObj.indexcounter = counter;

                if (level == 0) {
                    counter++;
                }

                result.push(newObj);
            }
        }
    }

    /**
     * find match string product grid record obj
     * @param {*} obj
     * @param {*} keyStroke
     */
    findMatchStringObject(keyStroke) {
        let _this = this,
            allrows = Object.assign([], this.props.marketingGrid.marketingGrid ? this.props.marketingGrid.marketingGrid.getrows() : []),
            regExp = new RegExp(keyStroke, 'i'),
            columns = _this.props.columnDetails,
            list = [],
            totalCount = 0,
            rowExpandIds = [];

        let result = [];
        result = allrows;

        var obj = result;
        var visibleColumns = [];

        for (var k = 0; k < columns.length; k++) {
            if (columns[k].visibility) {
                visibleColumns.push(columns[k].fieldName);
            }
        }

        if (obj.length > 0) {
            for (var i = 0; i < obj.length; i++) {
                var c = obj[i], counter = 0;

                for (var j = 0; j < visibleColumns.length > 0; j++) {
                    var findMatch = false, matchobj = {};
                    var key = visibleColumns[j];

                    if (c[key]) {
                        if (["catId", "vendorId", "division", "department", "classColumn", "subClassColumn"].indexOf(key) > -1) {
                            var dropDownField = key;

                            if (["catId", "division", "department", "classColumn", "subClassColumn"].indexOf(key) > -1) {
                                dropDownField = 'allCatId';
                            }

                            var fieldText = utils.findObjByKeyVal(window.marketingGridDropDowns[dropDownField], c[key]);

                            if (fieldText) {
                                c[key] = fieldText;
                            }
                        }

                        if (_this.findSkipFields.indexOf(key) === -1 && ["number", "string"].indexOf(typeof (c[key])) > -1) {
                            var inputString = typeof (c[key]) === "number" ? c[key].toString() : c[key],
                                res = inputString.match(regExp);

                            if (res && res.length > 0) {
                                findMatch = true;

                                if (_this.state.suggesions.indexOf(inputString) === -1) {
                                    _this.state.suggesions.push(inputString);
                                }
                            }
                        }
                    }

                    if (findMatch) {
                        matchobj.id = c.eventId;
                        matchobj.matchcount = 1;
                        totalCount += 1;

                        matchobj.rowBoundIndex = c.boundindex;
                        matchobj.matchcolumn = key;

                        list.push(matchobj);
                    }
                }
            }
        }

        console.log(totalCount);
        console.log(list);

        return {
            total: totalCount,
            details: list
        };
    }

    /**
     *
     * @param {*} el
     */
    focusGridText(el) {
        var _this = this;

        $("#MarketingGrid .jqx-grid-cell").removeClass('highlight-text-focus');

        var rowindex = this.state.focuscount > 0 ? this.state.focuscount - 1 : 0;
        var detailsobj = this.findResultsData.details[rowindex];
        var rowBoundIndex = detailsobj.rowBoundIndex;

        _this.props.marketingGrid.marketingGrid.selectcell(rowBoundIndex, detailsobj.matchcolumn);

        $("#MarketingGrid .jqx-grid-cell").removeClass('highlight-text');

        let find_result = $("#MarketingGrid .jqx-grid-cell:containsIN(" + _this.searchKey + "):visible");

        find_result.each(function (ele) {
            $(this).addClass('highlight-text');
        });

        this.setState({ focuscount: this.state.focuscount });
    }

    down() {
        var _this = this;

        if (_this.findResultsData.total == 0) {
            return;
        }

        if (_this.state.focuscount === _this.state.totalcount) {
            _this.state.focuscount = 1;
        } else {
            this.state.focuscount++;
        }

        _this.highlightText();
    }

    up() {
        var _this = this;

        if (_this.findResultsData.total == 0) {
            return;
        }

        if (_this.state.focuscount === 1) {
            _this.state.focuscount = _this.state.totalcount;
            _this.incrementer = 0;
        } else {
            this.state.focuscount--;
            this.pageinc--;
        }

        _this.highlightText();
    }

    highlightText() {
        var _this = this;
        var details = _this.findResultsData.details;

        var rowindex = this.state.focuscount > 0 ? this.state.focuscount - 1 : 0;
        var rowBoundIndex = this.findResultsData.details[rowindex].rowBoundIndex;

        _this.props.marketingGrid.marketingGrid.ensurerowvisible(rowBoundIndex);
        _this.focusGridText();
    }

    highlightResize() {
        var _this = this;

        if (_this.searchKey != "" && _this.findResultsData.total > 0) {
            $("#MarketingGrid .jqx-grid-cell").removeClass('highlight-text');

            let find_result = $("#MarketingGrid .jqx-grid-cell:containsIN(" + _this.searchKey + "):visible");

            find_result.each(function (ele) {
                $(this).addClass('highlight-text');
            });

            let count = this.state.focuscount;

            count--;

            var ele = $("#MarketingGrid .jqx-grid-cell:containsIN(" + _this.searchKey + "):eq(" + count + ")");

            _this.focusGridText(ele);
        }
    }

    findResults(e) {
        var _this = this, target = e.target ? e.target : e[0], key = target.value;
        var isShowSuggestions = e.target ? 1 : 0;

        if (!key) {
            _this.setState({ totalcount: 0 });
            _this.setState({ focuscount: 0 });
        }

        if (key === "") {
            _this.findResultsData.total = 0;
            _this.searchKey = "";
        }

        if (event.keyCode == 10 || event.keyCode == 13) {
            _this.setState({openSuggestion:false});
            _this.down();

            return;
        }

        $("#MarketingGrid .jqx-grid-cell").removeClass('highlight-text');

        _this.props.marketingGrid.marketingGrid.clearselection();
        _this.state.suggesions = [];

        if (key != "" && key.length > 1) {
            this.incrementer = 0;
            this.pageinc = 0;
            this.setState({ focuscount: 0 });

            if (_this.searchTimeout) {
                clearTimeout(_this.searchTimeout);
            }

            _this.searchTimeout = setTimeout(function () {
                _this.searchKey = key;

                var results = _this.findMatchStringObject(key);

                if (results) {
                    _this.findResultsData = results;
                    _this.setState({ totalcount: results.total });

                    if (results.total > 0) {
                        if (isShowSuggestions) {
                            _this.setState({ openSuggestion: true, suggesions: _this.state.suggesions });
                        } else {
                            _this.setState({ openSuggestion: false });
                        }

                        _this.setState({ focuscount: 1 });
                        _this.highlightText();
                    }
                }
            }, 300);
        }
    }

    triggerFind(e) {
        var text = $(e.target).text();
        var e = $("#find-text");

        e.val(text);

        this.findResults(e);
    }

    // End Find
    render() {
        console.log('Find Container render: ', this.props);

        var _this = this;

        var MakeItem = function (o, i) {
            return <li onClick={_this.triggerFind}>{o}</li>;
        };

        return (
            <FindComponent
                state = {this.state}
                findResults = {this.findResults}
                MakeItem = {MakeItem}
                up = {this.up}
                down = {this.down}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        toggleFind: state.toggleFind,
        columnDetails: state.columnDetails,
    };
};

export default connect(mapStateToProps, null)(Find);
