import React, { useState, useEffect } from 'react';
import getData from './help.json';
import marked from 'marked';

function HelpData(props) {
    const [data, setData] = useState(0);

    useEffect(() => {
        console.log('Help Bug Component HelpData', props);

        if (props && props.currentPage == 'product2') {
            if (props.section == 'search') {
                setData(getData.product.search);
            }

            if (props.section == 'views') {
                setData(getData.product.views);
            }
        }

        // cleanup - remove the data when unmount
        return () => {
            setData();
        };
    });

    return (
        <div className="help-bug-content" style={{ 'display': props.showTip ? '' : 'none'}}>
            <div className="help-bug-text">
                {
                    data && data.map((i, x) => {
                        return <p key={x} dangerouslySetInnerHTML={{ __html: marked(i) }}></p>;
                    })
                }
            </div>
        </div>
    );
}

export default HelpData;
