import React from 'react';
import axios from 'axios';
import NotificationManager from '../../../common/components/notification';

class SwatchReferenceModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: props.isOpen,
            qty: 1,
            node: null,
            swatchReferenceList: null,
            selectedValue: '',
        };
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
    }

    componentDidMount() {
        console.log('SwatchReferenceModal displayLevel', this.props.node.data['displayLevel']);
        this.setState({ node: this.props.node, selectedValue: this.props.node.data.swatchReference });
        let displayLevel = 'Style';
        if (!this.props.node.data['styleNumber']) {
            NotificationManager({ message: 'Style Number cannot be empty', type: 4 });
            this.close();
            this.props.closeSwatchPopup();
            return;
        }
        if (this.props.node.level !== 0) {
            if (!['Style', 'Color', 'Size'].includes(this.props.node.data['displayLevel'])) {
                NotificationManager({ message: 'Select a Display Level', type: 4 });
                this.close();
                this.props.closeSwatchPopup();
                return;
            }
        }


        displayLevel = this.props.node.data['displayLevel'] &&
            this.props.node.data['displayLevel'] === 'Please Select' ? 'Style' : this.props.node.data['displayLevel'];

        if (!this.props.node.data['displayLevel']) {
            displayLevel = 'Style';
        }


        if (this.props.node.data['sampleOptions'] === 'Swatch Only') {
            const reqObject = {};
            this.props.gridApi.forEachNode((node) => {
                if (node.data.childrenIds.includes(this.props.node.data['productId'])) {
                    reqObject.parentProduct = node.data['productId'];
                }
            });

            // const prodId = this.props.node.data['productId']; //|| this.props.node.data['parentProductId']
            // || this.props.node.data['intProdId'];

            reqObject.productId = this.props.node.data['productId'];
            reqObject.styleNumber = this.props.node.data['styleNumber'];
            reqObject.displayLevel = displayLevel;

            const headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json',
            };
            axios({
                method: 'post',
                url: require('../../../common/configurations').product2.swatchReferenceList,
                headers: headers,
                data: reqObject,
            })
                .then((response) => {
                    console.log('productContainer componentWillMount productTreeGrid.on(click is pi request sent: ', response.data);
                    const res = response.data;

                    if (res.status === 'success') {
                        const swatchReferenceList = res.responseData;

                        if (this.props.node.data['displayLevel'] === 'Swatch Only' &&
                            this.props.node.data.dataLevel === 'SINGLE' &&
                            res.responseData.length === 0) {
                            NotificationManager({ message: 'Items with samples are required before \'Swatch Only\' can be set', type: 4 });
                            this.props.modalClose();
                            return;
                        }
                        if (swatchReferenceList.length === 0) {
                            NotificationManager({ message: 'Items with samples are required before \'Swatch Only\' can be set', type: 4 });
                            // this.props.node.setDataValue('swatchReference', '');
                            this.props.node.setDataValue('sampleOptions', 'Sample');
                            this.props.modalClose();
                            return;
                        }
                        this.setState({
                            swatchReferenceList,
                        });
                    } else {
                        NotificationManager({ message: res.message, type: 4 });
                    }
                })
                .catch((error) => {
                    console.log('Server Error Failed', error);
                });
        }
    }

    /**
     * Closes the popup
     */
    close() {
        this.setState({ isOpen: false });
    }

    /**
     * Opens the popup
     */
    open() {
        this.setState({ isOpen: true });
    }

    handleChange(event) {
        console.log('selected value: ', event.target.value);
        this.setState({ selectedValue: event.target.value });
    }

    handleOk() {
        const data = this.props.node.data;
        console.log('product id: ', this.props.node.data, this.state.selectedValue);
        if (this.state.selectedValue) {
            let reqObject = {
                'productId': this.props.node.data['productId'],
                'styleNumber': this.props.node.data['styleNumber'],
                'displayLevel': this.props.node.data['displayLevel'],
                'sampleOption': 'Swatch Only',
                'swatchReference': this.state.selectedValue,
            };
            const headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json',
            };
            axios({
                method: 'post',
                url: require('../../../common/configurations').product2.updateSwatchReference,
                headers: headers,
                data: reqObject,
            })
                .then((response) => {
                    console.log('swatchReferenceModal.js handleOk : ', response);

                    if (response.data.status === 'success') {
                        console.log('success !');
                        this.props.node.setDataValue('swatchReference', this.state.selectedValue);
                        this.props.node.setDataValue('sampleOptions', 'Swatch Only');
                    } else {
                        NotificationManager({ message: response.data.message, type: 4 });
                    }
                })
                .catch((error) => {
                    console.log('Server Error Failed', error);
                });

            this.props.modalClose();
        } else {
            this.props.modalClose();
        }
    }

    handleClose() {
        const data = this.props.node.data;
        this.props.modalClose();
        if (this.props.node.data.sampleOptions == 'Swatch Only' && !this.props.node.data.swatchReference) {
            data['sampleOptions'] = 'Sample';
            this.props.node.setDataValue('sampleOptions', 'Sample');
            this.props.rowUpdate(data, this.props.node.rowIndex);
        }
    }

    render() {
        const swatchReferenceList = this.state.swatchReferenceList;
        let isShowField = false;
        if (swatchReferenceList && swatchReferenceList.length > 0) {
            isShowField = true;
        }
        const MakeItem = function (x, index) {
            return <option key={index + 1} value={x}>{x}</option>;
        };
        return (
            <div className={`Sample-SimpleModal ${(this.state.isOpen) ? 'open' : ''}`}>
                <h3>SELECT SWATCH REFERENCE</h3>
                <div className="field-data">
                    {isShowField &&
                        // <select className="swatch-reference"></select>
                        <select
                            className="swatch-reference"
                            onChange={this.handleChange.bind(this)}
                            value={this.state.selectedValue}
                        >
                            <option key={0} value={''}>{'Please Select'}</option>;
                            {swatchReferenceList.map(MakeItem)}
                        </select>
                    }
                    {!isShowField &&
                        <p>No swatch reference for this product</p>
                    }
                </div>
                <div className="field-data">
                    {!isShowField &&
                        <button type="button" className="gray-button" onClick={this.props.close}>ok</button>
                    }
                    {isShowField &&
                        <a className="sample-btn ok" onClick={this.handleOk.bind(this)}>Ok</a>
                    }
                    <a className="sample-btn ok" onClick={this.handleClose.bind(this)}>Cancel</a>
                </div>
            </div>
        );
    }
}

export default SwatchReferenceModal;
