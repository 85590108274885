import React from 'react';

class PdfViewerModal extends React.Component {
    constructor(props) {
        super(props);
        this.download = this.download.bind(this);
    }

    /**
     * downloads file to display
     */
    download() {
        const link = document.createElement('a');
        link.href = this.props.pdfFile;
        link.download = this.props.pdfFile;
        link.dispatchEvent(new MouseEvent('click'));
    }
    render() {
        return (
            <div id="pdfPopup">
                <div className="Sample-Modal-Container">
                    <div className="modal-header">
            Sample Card
                        <span className="close" onClick={this.props.modalClose}>x</span>
                    </div>
                    <div className="modal-content">
                        <div className="modal-form">
                            <div className="form-container scroll">
                                <embed src={this.props.pdfFile} width="100%" height="450" type="application/pdf"/>
                            </div>
                            <div className="modal-footer text-center">
                                <button type="button" className="gray-button" onClick={this.props.modalClose}>Cancel</button>
                                <button type="button" className="gray-button" onClick={this.download}>Export As Pdf</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PdfViewerModal;
