import React from 'react';
import axios from 'axios';

class MoveSampleModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: this.props.isOpen,
            locations: null,
        };
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
    }


    componentWillMount() {
        this.getLocationDropdown();
    }

    getLocationDropdown() {
        var _this = this;

        let headers = {
            'Authorization': JSON.parse(localStorage.getItem("user")).token,
            'content-type': 'application/json'
        };
        axios({
            method: 'post',
            url: require('../../../common/configurations').admin.getDropdownData,
            headers: headers,
            data: { "id": 'Location' }
        })
            .then((response) => {
                console.log('productContainer helperComponents footer API call success data: ', response.data);
                let res = response.data;
                _this.setState({ locations: res.data });
            })
            .catch(error => {
                console.log('productContainer helperComponents footer API call failed', error);
            });
    }


    /**
     * Closes the popup
     */
    close() {
        this.setState({isOpen: false});
    }

    /**
     * Opens the popup
     */
    open() {
        this.setState({isOpen: true});
    }

    onChangeValue(e) {
        const value = e.currentTarget.value;
        this.props.selectedSampleLocation(value);
    }

    render() {
        const locations = this.state.locations;
        const items = locations && locations.map((i, x) => {
            return <option key={x} value={i.userGroup}>{i.userGroup}</option>;
        });
        return (
            <div className={`Sample-SimpleModal ${(this.state.isOpen) ? 'open movesample' : ''}`}>
                <h3>MOVE SAMPLE TO:</h3>
                <div className="field-data">
                    <label>
                        <select className="sample-location" onChange={this.onChangeValue.bind(this)}>
                            <option disabled selected value='Select Value'>Select Value</option>
                            {items}
                        </select>
                    </label>
                </div>
                <div className="field-data">
                    <button type="button" className="gray-button" onClick={this.props.close}>Cancel</button>
                    <a className="sample-btn ok" onClick={this.props.moveSample}>Ok</a>
                </div>
            </div>
        );
    }
}

export default MoveSampleModal;
