import {endpoint} from '../../common/REST/endpoints';
import {headers} from '../../common/REST/headers';
import request from '../../common/REST/request';

let PublicationServices = {
    checkListName: function(payload, query) {
        return request({
            method: endpoint.publication.checkListName.method,
            url: endpoint.publication.checkListName.url + query,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    saveListName: function(payload) {
        return request({
            method: endpoint.publication.saveListName.method,
            url: endpoint.publication.saveListName.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    getPublicationList: function(payload) {
        return request({
            method: endpoint.publication.getPublicationList.method,
            url: endpoint.publication.getPublicationList.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    getSingleListName: function(payload) {
        return request({
            method: endpoint.publication.getSingleListName.method,
            url: endpoint.publication.getSingleListName.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    deleteListName: function(payload) {
        return request({
            method: endpoint.publication.deleteListName.method,
            url: endpoint.publication.deleteListName.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    updatePublicationList: function(payload) {
        return request({
            method: endpoint.publication.updatePublicationList.method,
            url: endpoint.publication.updatePublicationList.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    clearPublicationList: function(payload) {
        return request({
            method: endpoint.publication.clearPublicationList.method,
            url: endpoint.publication.clearPublicationList.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    RemoveSelectedData: function(payload) {
        return request({
            method: endpoint.publication.RemoveSelectedData.method,
            url: endpoint.publication.RemoveSelectedData.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    generateCSV: function(payload) {
        return request({
            method: endpoint.publication.generateCSV.method,
            url: endpoint.publication.generateCSV.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    generatePDF: function(payload) {
        return request({
            method: endpoint.publication.generatePDF.method,
            url: endpoint.publication.generatePDF.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    }
};

export default PublicationServices;
