import React from 'react';
import DashboardComponent from './dashboardComponent';
import './dashboard.css';
import { connect } from 'react-redux';
import removeFilter from './redux/dashboardActions';
import { addAlertsData } from '../pageheader/redux/notificationsActions';
import ErrorBoundryComponent from '../common/error-boundry-component';

class Dashboard extends React.Component {
  	constructor(props) {
  		super(props);
        this.state = {
            showfilter:false,
			  error: null, errorInfo: null
        };
        this.errorReload = this.errorReload.bind(this);
  	}

    componentWillMount() {
        localStorage.setItem('currentPage', 'dashboard');

        if(this.props.leftDrawerPulled) {
            this.props.toggleLeftDrawer();
        }
        if($('.right-content-container').hasClass('presentation-drawer-open')) {
            $('.right-content-container').removeClass('presentation-drawer-open');
        }
        if($('.content-wrapper').hasClass('presentation-drawer-open')) {
            $('.content-wrapper').removeClass('presentation-drawer-open');
        }
        // if(this.props.showRightDrawer) {
        //     this.props.toggleRightDrawerVisibility()
        // }
        //only changes url and does not reload or redirect the page
        //window.history.pushState("", "", '/dashboard');
    }


    componentWillUnMount() {
        if(this.props.leftDrawerPulled) {
            this.props.toggleLeftDrawer();
        }
    }

    componentDidMount(){
        // let user = JSON.parse(localStorage.getItem('user')) || this.props.location.state.user

    }

    componentWillReceiveProps(nextProps){
        if(nextProps.gridDataCount.gridDataChange !== this.props.gridDataCount.gridDataChange){
            this.setState({showfilter:true});
        }
    }

    errorReload() {
        this.setState({
            error: null,
            errorInfo: null
        });
        setTimeout(() => {
            this.forceUpdate();
        }, 0);
    }
    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
        // You can also log error messages to an error reporting service here
    }

    render() {
        let footerHeight = 50;
        let headerHeight = 64;
        let gridHeight = window.innerHeight - headerHeight;

        if (this.state.error) {
            // Error path
            return (
                <ErrorBoundryComponent error={this.state.error} errorInfo={this.state.errorInfo} errorReload={this.errorReload} />
            );
        }

        return (
            <DashboardComponent
                state = {this.state}
                props = {this.props}
                gridHeight = {gridHeight}
                setCurrentPage = {this.setCurrentPage}
            />
        );
    }
}


const mapStateToProps = (state) =>{
    return {
        gridData:state.db_gridData,
        gridDataCount: state.db_gridDataUpdate,
        userId:state.db_updateDashboardView,
        appliedFilters: state.db_appliedFilters,
        addAlertsData: state.addAlertsData
    };
};

const mapDispatchToprops = (dispatch) =>{
    return {
        removeFilter: (data) => {
            dispatch(db_removeFilter(data));
        }
    };
};


export default connect(mapStateToProps, mapDispatchToprops)(Dashboard);

