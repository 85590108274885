import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import JqxGrid from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';

const CronJobSettingsComponent = ({
    state,
    props,
    cronJobSettingsGrid,
    gridData,
    gridHeight,
    editSettings,
}) => {
    return (
        <div className="cronjobGrid">
            {state.isLoaded &&
        <JqxGrid
            ref={cronJobSettingsGrid}
            width={'450'}
            source={gridData.dataAdapter}
            pageable={false}
            editable={true}
            hierarchicalCheckboxes={true}
            columnsResize={true}
            columns={gridData.columns}
            sortable={false}
            autoRowHeight={false}
            autoWidth={true}
            height={gridHeight}
            theme={'light'}
            editSettings={editSettings}
            columnsreorder={false}
            enabletooltips={true}
            enableellipsis={true}
        />
            }
        </div>
    );
};

CronJobSettingsComponent.propTypes = {
    state: PropTypes.object,
    props: PropTypes.object,
};

export default withRouter(CronJobSettingsComponent);
