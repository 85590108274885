import React from 'react';
import ConfirmModal from '../../common/modals/confirmModal';

class CancelModalAdminFooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
        };

        this.closeConfirmModal = this.closeConfirmModal.bind(this);
    }

    /**
	 * Closes the confirm modal
	 * @param {*} key
	 */
    closeConfirmModal(key) {
        this.props.modalClose(key);
    }

    render() {
        return (<ConfirmModal isOpen={this.props.isOpen}>
            <div className="modal-content">
                <h3 className="confirm-warning-text">Please confirm if you wish to delete the vendors.</h3>
                <div className="modal-footer">
                    <input
                        type="button"
                        className="gray-button"
                        value="No" onClick={() => this.props.modalClose()}>
                    </input>
                    <input type="button" className="gray-button" value="Yes" onClick={this.props.confirmDelete}></input>
                </div>
            </div>
        </ConfirmModal>);
    }
}

export default CancelModalAdminFooter;
