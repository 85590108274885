
export const removeFilter = (data) => {
    return {
        type:"REMOVE_FILTERS",
        data
    };
};

export const alertsBreawkdown = (data) => {
    return {
        type:"ALERT_BREAKDOWN_DATA",
        data
    };
};

export const addDashboardPriorityAlertsData = (data) => {
    return {
        type:"ADD_DB_PRIORITY_ALERTS_DETAILS",
        data
    };
};

// export const addGridData = (data) => {
//   return {
//     type:"ADD_GRID_DETAILS",
//     data
//   }
// }
// export const gridDataChanged =() => {
//   return {
//     type:"GRID_DATA_CHANGED"
//   }
// }
// export const appliedFilters = (data) => {
//   return {
//     type:"APPLIED_FILTERS",
//     data
//   }
// }

// export const updateView =(data) => {
//   return {
//     type:"UPDATE_DASHBOARD_VIEW",
//     data
//   }
// };

// export const removeFilter = (data) => {
//   return {
//     type:"REMOVE_FILTERS",
//     data
//   }
// }
