import React from 'react';
import ConfirmModal from '../../common/modals/confirmModal';

class CancelModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false
        };

        this.closeConfirmModal = this.closeConfirmModal.bind(this);
    }

    closeConfirmModal(key) {
        this.props.modalClose(key);
    }

    render() {
        return (
            <ConfirmModal isOpen={this.props.isOpen}>
                <div className="modal-content">
                    <h3 className="confirm-warning-text">There are users affiliated with that User Group. Are you sure you want to disable?</h3>
                    <div className="modal-footer">
                        <input
                            type="button"
                            className="gray-button"
                            value="Yes"
                            onClick={() => this.closeConfirmModal('save')}
                        />
                        <input
                            type="button"
                            className="gray-button"
                            value="No"
                            onClick={() => this.props.modalClose('no')}
                        />
                    </div>
                </div>
            </ConfirmModal>
        );
    }
}

export default CancelModal;
