import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import SwitchTheModal from './switchTheModal';

/**
 * holder for all the components in the admin
 * @param {*} param0
 * @return {*} returns one of the components via switch modal
 */
const AdminComponent = ({
    props,
}) => {
    return (
        <div>
            <SwitchTheModal {...props} />
        </div>
    );
};

AdminComponent.propTypes = {
    state: PropTypes.object,
    props: PropTypes.object,
};

export default withRouter(AdminComponent);
