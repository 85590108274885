import React, { Component } from 'react';
import UserAddModal from './userAddModal';
import CancelModal from './cancelModal';
import NotificationManager from '../../common/components/notification';
import { connect } from 'react-redux';
import axios from 'axios';
import AdminServices from '../../admin/admin-services/admin-services';

class AdminFooter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            addUserLabel: 'Add New User',
            editUserLabel: 'Edit Global User Settings',
            delete: 'Delete User',
            undoChanges: 'Undo Last Changes',
            addUserGroup: 'Add New Group',
            isModalOpen: false,
            isConfirmModalOpen: false,
            modalName: '',
        };

        this.modalOpen = this.modalOpen.bind(this);
        this.modalClose = this.modalClose.bind(this);

        this.deleteUsersModal = this.deleteUsersModal.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.deleteUsers = this.deleteUsers.bind(this);
    }

    modalOpen(target) {
        if (target === 'delete') {
            this.setState({ isConfirmModalOpen: true });
        } else if (target === 'add_user' || target === 'edit_global_setting') {
            this.setState({ isModalOpen: true });
        }
    }

    modalClose() {
        this.setState({ isModalOpen: false, isConfirmModalOpen: false });
    }

    triggerModalOpen(target) {
        this.setState({ modalName: target });
        this.modalOpen(target);
    }

    deleteUsersModal() {
        this.triggerModalOpen('delete');
    }

    confirmDelete() {
        this.deleteUsers();
        this.modalClose();
    }

    deleteUsers() {
        const _this = this;
        const userIds = _this.props.checkedUserData;

        if (userIds.length > 0) {
            const req = { id: userIds };

            AdminServices.deleteUserData(req).then((response) => {
                const data = response;

                if (data.responseCode) {
                    NotificationManager({
                        message: data.responseMessage,
                        type: 1
                    });

                    _this.props.reloadGrid();
                } else {
                    NotificationManager({
                        message: 'Failed to delete user.',
                        type: 4,
                    });
                }
            }).catch((error) => {
                console.log('User adminFooter deleteUsers API call failed', error);

                NotificationManager({
                    message: 'Failed to delete user.',
                    type: 4,
                });
            });
        }
    }

    render() {
        let isDisableDelete = true;

        if (this.props.selectedRows > 0) {
            isDisableDelete = false;
        }

        const footerContent = (
            <div>
                <div className="footer-content">
                    <button className="admin-addrecord gray-button" onClick={() => this.triggerModalOpen('add_user')}>{this.state.addUserLabel}</button>
                    <UserAddModal
                        isOpen={this.state.isModalOpen}
                        modalClose={this.modalClose}
                        addUserRow={this.props.addUserRow}
                        modalName={this.state.modalName}
                        userData={this.props.user}
                    />
                    <button className="admin-edit-setting gray-button" onClick={() => this.triggerModalOpen('edit_global_setting')}>{this.state.editUserLabel}</button>
                </div>
                <div className="footer-row-details">
                    <span className="row-count">{this.props.userCount} Total Rows</span>
                    <span className="row-selected"> | Selected: {this.props.selectedRows}</span>
                </div>
                <div className="footer-content right">
                    <button className="cancel_changes gray-button" disabled={isDisableDelete} onClick={this.deleteUsersModal}>{this.state.delete}</button>
                    <button className="apply_changes gray-button" onClick={this.props.undoLastChanges} disabled={!this.props.isUndoEnable}>{this.state.undoChanges}</button>
                </div>
                <CancelModal
                    isOpen={this.state.isConfirmModalOpen}
                    modalClose={this.modalClose}
                    confirmDelete={this.confirmDelete}
                />
            </div>
        );

        return (
            <div className="footer admin-footer">
                {footerContent}
            </div>
        );
    }
}

const mapSateToProps = (state) => {
    return {
        userView: state.userView,
    };
};

export default connect(mapSateToProps)(AdminFooter);
