import React from 'react';
import ReactDOM from "react-dom";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';

import axios from 'axios';
import NotificationManager from '../common/components/notification';
import moment from 'moment';

import * as actions from './redux/samplesActions';
import * as p_actions from '../product/redux/productActions';

import { connect } from 'react-redux';

import './samplesContainer.css';

import ScanModal from '../modals/scan-modal';

import SamplesFooter from "./samplesfooter.js";

import Modal from '../modals/common-modal';
import PdfViewerModal from '../product2/helperComponents/samples/pdfViewerModal';

import SampleTypeDropdownEditor from './helperComponents/sampleType';
import { LicenseManager } from 'ag-grid-enterprise';
import { ProductServices } from '../product2/services/product-services';
import MoveSampleModal from '../product2/helperComponents/samples/moveSampleModal';

import { agGridKey } from '../common/utils';
const getKey = agGridKey();
const agKey = atob(getKey);
LicenseManager.setLicenseKey(agKey);

//////////////////////////////////////////////////////////////////////
////////////////grouping not working//////////////////////////////////
//////////////////////////////////////////////////////////////////////

class Popup extends React.Component {
    render() {
        return (
            <div className="container">
                <div className='popup'>
                    <h1>Hi there</h1>
                    <button onClick={this.props.togglePopup}>Close </button>
                </div>
            </div>
        );
    }
}

class imageCellRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        let url = '';

        if (this.props.data && this.props.data.image && this.props.data.image.imgURL) {
            url = this.props.data.image.imgURL;
        }

        return (
            <div>
                <div onClick={this.props.togglePopup} className="grid-images" >
                    {
                        url ?
                            <img src={url} style={{ width: '22px', height: '22px' }} />
                            :
                            <div></div>
                    }
                </div>
            </div>
        );
    }
}

function currencyFormatter(params) {
    return "\xA3" + formatNumber(params.value);
}

function formatNumber(number) {
    return Math.floor(number)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

function currencyCellRenderer(params) {
    var usdFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    });

    return usdFormatter.format(params.value);
}

class Samples extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null, errorInfo: null,
            gridParams: null,
            columnDefs: [],
            rowData: [],
            totalRecordCount: 0,
            cacheBlockSize: 50,
            maxBlocksInCache: 2,
            selectedRowsCount: 0,
            getRowNodeId: function (item) {
                return item.id;
            },

            icons: {
                groupLoading:
                '<img src="https://raw.githubusercontent.com/ag-grid/ag-grid-docs/master/src/javascript-grid-server-side-model/spinner.gif" style="width:22px;height:22px;">'
            },

            showPopup: false,

            overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Loading</span>',
            overlayNoRowsTemplate:
            '<span style="padding: 10px; font-size: 28px; font-weight:\'lighter\' ; color: #525E61;">Please Select filter or alert to proceed</span>',

            defaultColDef: {
                editable: true,
                width: 100,
            },

            inputValue: '',
            sampleTypeDropdown: null,
            scanModalOpen: false,
            pdfModalOpen: false,
            pdfFile: '',
            isOpenMoveModal: false,
            sampleLocationValue: null,
        };
    }

    togglePopup() {
        this.setState({
            showPopup: !this.state.showPopup
        });
    }

    componentDidMount() {
    }

    componentWillReceiveProps(nextProps) {
        let _this = this;

        if (nextProps.gridData.data && nextProps.gridData.data.length > 0) {
            const columnDetails = nextProps.gridData.columnDefs;
            const data = nextProps.gridData.data;
            const columnDefs = this.colDefs(columnDetails);

            this.setState({
                rowData: data,
                columnDefs: columnDefs,
                totalRecordCount: nextProps.gridData.recordCount,
                sampleTypeDropdown: nextProps.gridData.dropDownData.sampleTypes,
            }, () => {
                this.onGridReady(this.state.gridParams);
            });
        }
    }

    getsampleTypeDropdown() {
        return this.state.sampleTypeDropdown;
    }

    openScanModal() {
        this.setState({ scanModalOpen: true });
    }

    closeScanModal() {
        this.setState({ scanModalOpen: false });
    }

    /**
     * Creates the columnDefs for the grid - Suraj
     * @param {Array} columnDetails
     */
    colDefs(columnDetails) {
        const _this = this;
        const newColDefs = [];

        columnDetails.forEach(function (obj) {
            switch (obj.field) {
                case 'image':
                    newColDefs.push({
                        headerName: obj.headerName,
                        field: obj.field,
                        width: obj.width,
                        editable: false,
                        cellRendererParams: {
                            togglePopup: _this.togglePopup.bind(this)
                        },
                        cellRendererFramework: imageCellRenderer,
                    });

                    break;

                case 'sampleType':
                    newColDefs.push({
                        headerName: obj.headerName,
                        field: obj.field,
                        width: 100,
                        editable: true,
                        cellRendererFramework: (params) => {
                            if (params.value) {
                                return <div>{params.value}</div>;
                            } else {
                                return <div className="arrow downPos"></div>;
                            }
                        },
                        cellEditorFramework: SampleTypeDropdownEditor,
                        cellEditorParams: {
                            dropdownVals: _this.getsampleTypeDropdown.bind(_this),
                        },
                    });

                    break;

                case 'movedDate':
                    newColDefs.push({
                        headerName: obj.headerName,
                        field: obj.field,
                        width: obj.width,
                        editable: false,
                        cellRendererFramework: (params) => {
                            if (params.value) {
                                return <div>{moment(new Date(params.value)).format('MM/DD/YYYY')}</div>;
                            } else {
                                return <div></div>;
                            }
                        },
                        filter: "agDateColumnFilter",
                        filterParams: {
                            comparator: function (filterLocalDateAtMidnight, cellValue) {
                                var dateAsString = '23/09/2018';

                                if (dateAsString == null) return -1;

                                var dateParts = dateAsString.split("/");
                                var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]));

                                if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
                                    return 0;
                                }

                                if (cellDate < filterLocalDateAtMidnight) {
                                    return -1;
                                }

                                if (cellDate > filterLocalDateAtMidnight) {
                                    return 1;
                                }
                            },
                            browserDatePicker: true
                        }
                    });

                    break;

                default:
                    newColDefs.push({
                        headerName: obj.headerName,
                        field: obj.field,
                        width: obj.width,
                        editable: false,
                        filter: "agTextColumnFilter",
                        filterParams: {
                            apply: true,
                            newRowsAction: 'keep'
                        },
                    });
            }
        });

        // adding the checkboxes to the grid
        newColDefs.unshift(
            {
                headerName: '',
                field: 'checkbox',
                width: 50,
                minWidth: 50,
                editable: false,
                checkboxSelection: true,
                pinned: 'left',
                lockPosition: true,
                cellStyle: function (params) {
                    return {
                        paddingLeft: '1px',
                        paddingRight: '0px',
                        paddingTop: '3px',
                        borderRight: '1px solid lightgrey',
                    };
                },
            },
        );

        return newColDefs;
    }


    onGridReady(params) {
        let _this = this;
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setState({ gridParams: params });

        var dataSource = {
            getRows: function (params) {
                params.successCallback(_this.state.rowData, _this.state.rowData.length);

                // adds the overlay for blank grid
                if (_this.state.rowData && _this.state.rowData.length === 0) {
                    _this.gridApi.showNoRowsOverlay();
                }
            }
        };

        this.refs.agGrid && this.refs.agGrid.api && this.refs.agGrid.api.setServerSideDatasource && this.refs.agGrid.api.setServerSideDatasource(dataSource);
    }

    onSelectionChanged() {
        let selectedRows = this.gridApi.getSelectedRows();

        this.setState({ selectedRowsCount: selectedRows.length });
        this.props.updateSelectedRows(selectedRows);
    }

    /**
     * Closes the pdf modal
     */
    closePdfModal() {
        this.setState({ pdfModalOpen: false });
    }

    reprintSampleCard() {
        const _this = this;
        const selectedRows = this.gridApi.getSelectedRows();

        let selectedArray = []; // adding to array for backend payload formatting

        selectedRows.map((i) => {
            selectedArray.push({
                'id': i.sampleId,
                'value': i.product_productId
            });
        });

        ProductServices.generateSampleCard(selectedArray).then((response) => {
            console.log('productContainer helperComponents sample-modal rePrintSampleCard API call success data: ', response.data);
            const res = response.data;

            if (res.responsestatus) {
                NotificationManager({ message: res.responseMessage, type: 1 });

                if (res.data) {
                    const pdfFileUrl = res.data;
                    _this.setState({ pdfModalOpen: true, pdfFile: pdfFileUrl });
                }
            } else {
                NotificationManager({ message: res.responseMessage, type: 4 });
            }
        })
            .catch((error) => {
                console.log('productContainer helperComponents sample-modal rePrintSampleCard API call failed', error);
                NotificationManager({ message: 'Please select atleast one samples to print', type: 4 });
            });
    }

    scanActionUpdate(data) {
        const _this = this;

        let setNode;
        let rows;

        this.gridApi.forEachNode(function (rowNode) {
            if (rowNode.data && rowNode.data.sampleId == data.sampleId) {
                rowNode.data.sampleCurrentLocation = data.sampleCurrentLocation;
                rowNode.data.samplePriorLocation = data.samplePriorLocation;
                rowNode.data.sampleReceivedDate = data.sampleReceivedDate;
                rowNode.data.movedDate = data.sampleMovedDate;
            }
        });

        this.gridApi.redrawRows();
    }

    updateInputValue(evt) {
        this.setState({
            inputValue: evt.target.value
        });
    }

    changeStyle(params) {
        if (params.value === this.state.inputValue) {
            return { color: 'black', backgroundColor: 'yellow' };
        } else {
            return null;
        }
    }

    /**
     * Opens move modal
     */
    openMoveModalBox() {
        this.setState({ isOpenMoveModal: true });
    }

    /**
     * Closes the move modal
     */
    closeMoveModalBox() {
        this.setState({ isOpenMoveModal: false });
    }

    moveSample() {
        const _this = this;
        const selectedRows = this.gridApi.getSelectedRows();

        let selectedArray = []; // adding to array for backend payload formatting

        selectedRows.map((i) => {
            selectedArray.push({
                'sampleId': i.sampleId,
                'sampleCurrentLocation': this.state.sampleLocationValue,
                'samplePriorLocation': i.sampleCurrentLocation,
                'productID': i.product_productId,
                'sampleIdLst': [i.sampleId],
                'swatch': 'false',
                'sampleComments': i.comments,
            });
        });

        ProductServices.moveSample(selectedArray)
            .then((response) => {
                console.log('productContainer helperComponents footer API call success data: ', response.data);
                const res = response.data;

                if (res.responsestatus) {
                    NotificationManager({ message: res.responseMessage, type: 1 });
                    res.data.map((i, x) => {
                        _this.gridApi.forEachNode((rowNode) => {
                            if (i.sampleId == rowNode.data.sampleId) {
                                rowNode.data.movedDate = i.sampleMovedDate;
                                rowNode.data.sampleCurrentLocation =  i.sampleCurrentLocation;
                                rowNode.data.samplePriorLocation = i.samplePriorLocation;
                            }
                        });
                    });

                    _this.gridApi.redrawRows();
                    _this.closeMoveModalBox();
                } else {
                    NotificationManager({ message: res.responseMessage, type: 4 });
                }
            })
            .catch((error) => {
                console.log('productContainer helperComponents footer API call failed', error);
            });
    }

    selectedSampleLocation(data) {
        this.setState({ sampleLocationValue: data});
    }

    clickMe() {
        this.gridApi.redrawRows();
    }

    render() {
        return (
            <div style={{ width: "100%", height: "100%" }}>
                <div style={{ width: "100%", height: "80%" }}>
                    <div style={{ height: "100%", boxSizing: "border-box" }}>

                        <div
                            id="myGrid"
                            style={{
                                boxSizing: "border-box",
                                height: "100%",
                                width: "100%"
                            }}
                            className="ag-theme-balham"
                        >
                            <AgGridReact
                                ref="agGrid"
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}

                                rowSelection={'multiple'}
                                enableColResize={true}
                                rowModelType={'serverSide'}
                                pivotPanelShow={"never"} // always, never

                                enableRangeSelection={true}
                                enableFilter={true}
                                singleClickEdit={true} // enables single click edit

                                enableServerSideSorting={true}
                                suppressRowClickSelection={true}

                                cacheBlockSize={this.state.cacheBlockSize}
                                maxBlocksInCache={this.state.maxBlocksInCache}

                                onGridReady={this.onGridReady.bind(this)}
                                onSelectionChanged={this.onSelectionChanged.bind(this)}
                                overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                overlayLoadingTemplate={this.state.overlayLoadingTemplate}

                                headerHeight = {38}
                            />
                        </div>
                    </div>

                    {this.state.showPopup ?
                        <Popup
                            text='Close Me'
                            togglePopup={this.togglePopup.bind(this)}
                        />
                        : null
                    }
                </div>

                <div style={{ width: "100%", height: "10%" }}>
                    <SamplesFooter
                        openScanModal={this.openScanModal.bind(this)}
                        totalRecordCount={this.state.totalRecordCount}
                        selectedRowsCount={this.state.selectedRowsCount}
                        reprintSampleCard = {this.reprintSampleCard.bind(this)}
                        openMoveModalBox = {this.openMoveModalBox.bind(this)}
                    />
                </div>

                <ScanModal
                    isOpen={this.state.scanModalOpen}
                    scanActionUpdate={this.scanActionUpdate.bind(this)}
                    modalClose={this.closeScanModal.bind(this)}
                />

                {this.state.pdfModalOpen &&
                <div className='samples-grid-reprint'>
                    <Modal isOpen={this.state.pdfModalOpen}>
                        <PdfViewerModal pdfFile={this.state.pdfFile} modalClose={this.closePdfModal.bind(this)} />
                    </Modal>
                </div>
                }

                {this.state.isOpenMoveModal &&
                <div className="swatchReferenceContainer">
                    <MoveSampleModal
                        isOpen={this.state.isOpenMoveModal}
                        selectedSampleLocation={this.selectedSampleLocation.bind(this)}
                        close={this.closeMoveModalBox.bind(this)}
                        moveSample={this.moveSample.bind(this)}
                    />
                </div>
                }

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        gridData: state.samplesGridData,
    };
};

const mapDispatchToprops = (dispatch) => {
    return {
        addGridData: (data) => {
            dispatch(actions.addSamplesGridData(data));
        },
        updateSelectedRows: (data) => {
            dispatch(p_actions.updateSelectedRows(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToprops)(Samples);
