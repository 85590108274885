import React from 'react';

class SwatchReferenceModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isOpen:this.props.isOpen,
            qty:1
        };
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
    }
    close(){
        this.setState({isOpen:false});
    }

    open(){
        this.setState({isOpen:true});
    }
    render(){
        var swatchReferenceList  = this.props.swatchReferenceList;
        var isShowField = false;
        if(swatchReferenceList.length > 0){
            isShowField = true;
        }
        let MakeItem = function(x) {
            return <option value={x}>{x}</option>;
        };
        return (
            <div className={`Sample-SimpleModal ${(this.state.isOpen) ? "open" : ""}`}>
                <h3>SELECT SWATCH REFERENCE</h3>
                <div className="field-data">
                    {isShowField &&
            <select className="swatch-reference">{swatchReferenceList.map(MakeItem)}</select>
                    }
                    {!isShowField &&
                <p>No swatch reference for this product</p>
                    }
                </div>
                <div className="field-data">
                    {!isShowField &&
                <button type="button" className="gray-button" onClick={this.props.close}>ok</button>
                    }
                    {isShowField &&
                <a className="sample-btn ok" onClick={this.props.ok}>Ok</a>
                    }
                </div>
            </div>
        );
    }
}

export default SwatchReferenceModal;
