import React from 'react';
import AlertsBreakDown from './alertsBreakDownComponent';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../product/redux/productActions';
import * as product2_actions from '../../product2/redux/product2Actions';
import * as alert_actions from '../redux/dashboardActions';
import DashboardServices from '../services/dashboard-services';

class Alerts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadPriorityAlert: false,
            isShowAlertsBreakDown: false,
            priorityAlerts: null,
            response: {},
            isLoaded: false,
            filtersCatIds: null,
            filtersSeasonIds: null,
            filtersChannelIds: null,
        };
        this.showAlertsBreakDown = this.showAlertsBreakDown.bind(this);
        this.currentAlerts = null;
        this.loadData = this.loadData.bind(this);
        this._isMounted = false;

        this.alertProductClick = this.alertProductClick.bind(this);
        this.loadFilteredData = this.loadFilteredData.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;

        this.loadData(this.props.user.userId);
        this.props.addDashboardPAData([]);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(nextProps, nextState) {
        if (nextProps.filterForSortingProductsGrid) {
            this.setState({
                customFilters: nextProps.filterForSortingProductsGrid.customFilters,
            });
        }

        // Warn if overriding existing method
        if (Array.prototype.equals)
            console.warn("Overriding existing Array.prototype.equals. Possible causes: New API defines the method, there's a framework conflict or you've got double inclusions in your code.");
        // attach the .equals method to Array's prototype to call it on any array
        Array.prototype.equals = function (array) {
            // if the other array is a falsy value, return
            if (!array)
                return false;

            // compare lengths - can save a lot of time
            if (this.length != array.length)
                return false;

            for (var i = 0, l = this.length; i < l; i++) {
                // Check if we have nested arrays
                if (this[i] instanceof Array && array[i] instanceof Array) {
                    // recurse into the nested arrays
                    if (!this[i].equals(array[i]))
                        return false;
                }
                else if (this[i] != array[i]) {
                    // Warning - two different object instances will never be equal: {x:20} != {x:20}
                    return false;
                }
            }
            return true;
        };
        // Hide method from for-in loops
        Object.defineProperty(Array.prototype, "equals", { enumerable: false });


        if (nextProps.appliedFilters.id && nextProps.priorityAlertsFromReducer.length > 0) {
            // let refinedFilters = Object.assign({}, nextProps.appliedFilters.refine);
            let filtersCatIds = nextProps.appliedFilters.catIds;
            let filtersSeasonIds = nextProps.appliedFilters.seasonIds;
            let filtersChannelIds = nextProps.appliedFilters.channelIds;
            let priorityAlerts = nextProps.priorityAlertsFromReducer;
            this.setState({ priorityAlerts, filtersCatIds, filtersSeasonIds, filtersChannelIds });
            if (this.props.appliedFilters.catIds != nextProps.appliedFilters.catIds) {
                this.showAlertsBreakDown(priorityAlerts[0]);
                $('.priority-alerts li').removeClass('active');
                $('.priority-alerts li:first').addClass('active');
            }
            // let refinedFilters = Object.assign({}, nextProps.appliedFilters.refine);
            // let filtersCatIds = refinedFilters.catIds;
            // let filtersSeasonIds = refinedFilters.seasons;
            // let filtersChannelIds = refinedFilters.channels;
            // if (!filtersCatIds.equals(nextState.filtersCatIds)) {
            //     let priorityAlerts = this.props.priorityAlertsFromReducer;
            //     // this.showAlertsBreakDown(priorityAlerts[0]);
            //     this.setState({ filtersCatIds, filtersSeasonIds, filtersChannelIds, priorityAlerts });
            // }
        }
    }



    loadFilteredData(refinedFilters) {
        this.props.toggleSpinner(true);

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        DashboardServices.getFilterData({ userID: this.props.user.userId, refine: refinedFilters }).then((response) => {
            console.log('Alerts loadFilteredData API call success data: ', response);
            let res = response;
            this.props.toggleSpinner(false);

            if (res.data) {
                var priorityAlerts = res.data.healthTab;
                this.setState({
                    isLoadPriorityAlert: true,
                    isShowAlertsBreakDown: false,
                    priorityAlerts: priorityAlerts,
                });
                if (priorityAlerts[0]) {
                    this.showAlertsBreakDown(priorityAlerts[0]);
                }
            } else {
                this.setState({ isLoadPriorityAlert: true, isShowAlertsBreakDown: false, priorityAlerts: [] });
            }
        })
            .catch(error => {
                console.log('Alerts loadFilteredData API call failed', error);
            });
    }

    loadData(userId) {
        this.props.toggleSpinner(true);
        let userSpeciality = this.props.user.speciality;
        console.log('user speciality is: ', userSpeciality);
        if (userSpeciality) {
            let tempArraySplitByComma = userSpeciality.split(',');
            let filtersCatIds = [];
            if (tempArraySplitByComma && tempArraySplitByComma.length > 1) {
                let tempArrayCommaLength = tempArraySplitByComma.length;
                for (let i = 0; i < tempArrayCommaLength; i++) {
                    let tempArraySplitByDash = tempArraySplitByComma[i].split('-');
                    let catId = tempArraySplitByDash && tempArraySplitByDash[0] ? tempArraySplitByDash[0] : null;
                    filtersCatIds.push(parseInt(catId));
                }
            }
            this.setState({ filtersCatIds });
        }

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        DashboardServices.priorityAlerts({ id: userId }).then((response) => {
            console.log('Alerts loadData API call success data: ', response);
            let res = response;
            this.props.toggleSpinner(false);

            if (res.responseData) {
                var priorityAlerts = res.responseData.healthTab;
                this.setState({
                    isLoadPriorityAlert: true,
                    isShowAlertsBreakDown: false,
                    priorityAlerts: priorityAlerts,
                    isLoaded: true,
                });
                if (priorityAlerts[0]) {
                    this.showAlertsBreakDown(priorityAlerts[0]); // To show first alert breakdown default
                }
            }
            else {
                this.setState({
                    isLoadPriorityAlert: true,
                    isShowAlertsBreakDown: false,
                    priorityAlerts: []
                });
            }
        })
            .catch(error => {
                console.log('alerts loadData API call failed', error);
                this.props.toggleSpinner(false);
            });
    }

    showAlertsBreakDown(obj, i) {
        this.currentAlerts = obj;
        this.selectedAlerts = obj.title;
        this.props.alertsBreawkdown(obj);

        if (this._isMounted) {
            this.setState({ isShowAlertsBreakDown: true });
        }
        if (!isNaN(i)) {
            $('.priority-alerts li').removeClass('active');
            $('.priority-alerts li:eq(' + i + ')').addClass('active');
        }
    }


    alertProductClick(obj) {
        console.log('alert Prod Click: ', obj);
        let pagenames = obj.page.toLowerCase() == 'product' ? 'product2' : obj.page.toLowerCase();

        let _this = this;
        _this.props.toggleSpinner(true);

        _this.props.setCurrentPage(pagenames, "alertClick");
        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        const reqData = {
            "catIds": this.state.filtersCatIds,
            "seasonIds": this.state.filtersSeasonIds,
            "channelIds": this.state.filtersChannelIds,
        };

        const url = require('../../common/configurations').apiURL + obj.url;

        axios({
            method: 'POST',
            url: url,
            headers: headers,
            data: reqData,
        })
            .then((response) => {
                console.log('notifierComponent alertProductClick API call success data: ', response.data);
                let res = response.data;

                if (res.code == 200) {
                    if (pagenames == 'product2') {
                        let product2GridData = {};
                        product2GridData['data'] = res.responseData.data;
                        product2GridData['columnDetails'] = res.responseData.columnDetails;
                        product2GridData['recordCount'] = res.responseData.data.length;
                        product2GridData['catIds'] = [];
                        product2GridData['customFilters'] = _this.state.customFilters;
                        product2GridData['fromAlerts'] = true;
                        product2GridData['priorityAlertsForSort'] = {
                            'filters': reqData,
                            'url': url,
                        };
                        _this.props.addProduct2GridData(product2GridData);
                        _this.props.filterDataChanged();
                        _this.props.actionPerformedFrom({fromAlerts: true});
                        _this.props.toggleSpinner(false);
                        return;
                    }

                    _this.props.addGridData(res.responseData.data);
                    _this.props.addColumnDetails(res.responseData.columnDetails);
                    _this.props.gridDataChanged();
                }
            })
            .catch(error => {
                console.log('notifierComponent alertProductClick API call failed', error);
            });
    }


    render() {
        var intViewportHeight = window.innerHeight;
        var containerheight = intViewportHeight - 64 - 30;
        var containerStyle = {
            height: containerheight
        };

        let alerts = this.state.priorityAlerts;

        if (alerts && alerts.length > 0) {
            alerts && alerts.sort(function (a, b) {
                return a.priority - b.priority;
            });
        }
        return (
            <div>
                <div className="priority-alerts scroll">
                    <h2>Priority Alerts</h2>
                    <ul>
                        {
                            alerts && alerts.map((list, index) => (
                                <li onClick={() => this.showAlertsBreakDown(list, index)} onDoubleClick={() => this.alertProductClick(list)} key={index}><label className="left-txt">{list.title}</label><span>{list.value}</span></li>
                            ))
                        }
                    </ul>
                </div>
                {this.state.isShowAlertsBreakDown &&
                    <AlertsBreakDown
                        alerts={this.currentAlerts}
                        selectedAlerts={this.selectedAlerts}
                        user={this.props.user}
                        priorityAlerts={this.state.priorityAlerts}
                        {...this.props}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        addAlertsData: state.addAlertsData,
        alertsBreawkdown: state.alertsBreawkdown,
        priorityAlertsFromReducer: state.db_pa_alerts,
        gridData: state.product2GridData,
        filterForSortingProductsGrid : state.filterForSortingProductsGrid,
    };
};

const mapDispatchToprops = (dispatch) => {
    return {
        addGridData: (data) => {
            dispatch(actions.addGridData(data));
        },
        addColumnDetails: (data) => {
            dispatch(actions.addColumnDetails(data));
        },
        addGridDropDowns: (data) => {
            dispatch(actions.addGridDropDowns(data));
        },
        gridDataChanged: () => {
            dispatch(actions.gridDataChanged());
        },
        filterDataChanged: () => {
            dispatch(actions.filterDataChanged());
        },
        addProduct2GridData: (data) => {
            dispatch(product2_actions.addProduct2GridData(data));
        },
        alertsBreawkdown: (data) => {
            dispatch(alert_actions.alertsBreawkdown(data));
        },
        actionPerformedFrom: (data) => {
            dispatch(product2_actions.actionPerformedFrom(data));
        },
        addDashboardPAData: (data) => {
            dispatch(alert_actions.addDashboardPriorityAlertsData(data));
        },
    };
};


export default withRouter(
    connect(mapStateToProps, mapDispatchToprops)(Alerts)
);

