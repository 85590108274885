import React from 'react';

export class CellRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            values: null,
        };
    }

    componentWillMount() {
        console.log('Creative componentWillMount cellRenderer, ', this.props);

        let setValues = null;
        const getNodes = this.props.rowData;
        const getCol = this.props.colDef.colId;

        if (getCol === 'images') {
            if (getNodes) {
                getNodes.map((i, x) => {
                    if (i.id == this.props.node.key) {
                        if (i[getCol]) {
                            setValues = i[getCol][0].imageUrl;
                        } else {
                            setValues = '';
                        }
                    }
                });
            }

            this.setState({
                values: <div style={{ height: '100%', textAlign: 'center' }}>
                    { setValues ?
                        <img src={setValues} width='23'/>
                        :

                        <div></div>
                    }
                </div>,
            });
        } else {
            if (getNodes) {
                getNodes.map((i, x) => {
                    if (i.id == this.props.node.key) {
                        setValues = i[getCol];
                    }
                });
            }

            this.setState({
                values: setValues,
            });
        }
    }

    render() {
        console.log('Creative component render cells, ', this.props, this.state);

        const _this = this;
        let checkVal = this.state.values ? this.state.values : this.props.value;
        let showData = checkVal ? checkVal : '';

        return (
            <div>
                {checkVal}
            </div>
        );
    }
}
