import React from 'react';
import axios from 'axios';
import NotificaitonManager from '../../../common/components/notification';

class ImageUpload extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inputFile: [],
            inputFileName: '',
        };

        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    /**
     * Handles the upload click and calls the backend to save data
     * @param {*} e // Event when sbumit is clicked
     */
    handleSubmit(e) {
        e.preventDefault();

        const _this = this;

        const obj = {};
        obj.rowId = this.props.imageInfo.uid;
        obj.fieldName = 'images';

        const form = document.getElementById('ProductImagesUpload');

        const formData = new FormData(form);
        formData.append('dataLevel', _this.props.imageInfo.dataLevel);

        const headers = {
            'Authorization': this.props.user.token,
        };

        axios({
            method: 'post',
            url: require('../../../common/configurations.js').product2.imageUploadV2,
            headers: headers,
            data: formData,
        })
            .then( (response) => {
                const data = response.data;

                if (data.status) {
                    obj.data = _this.props.imageArray;

                    if (data.responseData) {
                        obj.data.push(data.responseData);
                    }

                    _this.props.updateImage(obj);
                    _this.props.imageData(obj);

                    NotificaitonManager({
                        message: 'Image uploaded successfully',
                        type: 1
                    });
                } else {
                    NotificaitonManager({
                        message: data.responseMessage,
                        type: 4
                    });
                }
            })
            .catch( (error) => {
                console.log('productContainer helperComponents footer API call failed', error);

                NotificaitonManager({
                    message: 'failed to upload image due to technical reasons, please try again',
                    type: 4,
                });
            });

        _this.setState({ inputFile: [], inputFileName: '' });
    }

    /**
     * Checks if file has been changed and validates
     * @param {*} e // Event when file is changed
     * @return {void} setsState
     */
    handleFileChange(e) {
        e.preventDefault();

        const _this = this;
        const target = e.target;
        const list = target.files;
        const fileArray = [];

        for (let i = 0; i < list.length; i++) {
            const fileList = {};
            const allowedImageTypes = [
                'image/jpeg',
                'image/png',
                'image/gif',
                'image/jpg',
                'image/tiff',
                'image/tif'
            ];

            if (allowedImageTypes.indexOf(list[i].type) < 0) {
                NotificaitonManager({
                    message: 'Only PNG, JPG, GIF and TIF are allowed',
                    type: 4,
                });

                return false;
            }

            if (list) {
                for (const l in list[i]) {
                    fileList[l] = list[i][l];
                }

                fileArray.push(fileList);
            }

            if (list.length > 0 && fileArray.length > 0) {
                this.setState({
                    inputFile: fileArray,
                    inputFileName: fileArray[0].name
                });
            }
        }
    }

    render() {
        const props = this.props;

        const imgData = props.imageInfo;

        const pIds = [];

        if (imgData.dataLevel == 'SINGLE') {
            pIds.push(imgData.chldProuctId);
        } else if (imgData.dataLevel == 'PARENT') {
            pIds.push(imgData.chldProuctId);
        } else if (imgData.dataLevel == 'INTERMEDIATE') {
            pIds.push(imgData.childrenIds);
        } else if (imgData.dataLevel == 'CHILD') {
            pIds.push(imgData.childrenIds);
        }

        return (
            <div className="upload-holder">
                {
                    <form id="ProductImagesUpload" name="product-images-upload" className="images-upload" onSubmit={this.handleSubmit} encType="multipart/form-data">
                        <input type="hidden" name="pid" defaultValue={pIds} />
                        <div className="upload-input">
                            <div className="icon-holder">
                                <span className="plus-icon" />
                            </div>
                            <input type="file" name="files" onChange={this.handleFileChange} />
                        </div>

                        <label className="block text-center"> Add Photo(s)
                            <span className={'image-name ' + (this.state.inputFile.length > 0 ? '' : 'hide')}>{' : ' + this.state.inputFileName}</span>
                        </label>
                        <fieldset className="text-center upload-footer">
                            <button type="submit" className="orange-button"
                                disabled={this.state.inputFile.length > 0 ? false : true}
                            >
                            Save
                            </button>
                        </fieldset>
                        <input type="hidden" name="extraField" value="_dontcare" />
                    </form>
                }
            </div>
        );
    }
}

export default ImageUpload;
