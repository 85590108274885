import React from 'react';
import LoginPageComponent from './loginComponent.js';
import './login.less';
import { createCookie, deleteCookie, getCookie } from '../common/utils.js';
import { connect } from 'react-redux';
import { loginActions } from './redux/loginActions';
import PropTypes from 'prop-types';
import axios from 'axios';
import NotificationManager from '../common/components/notification';

window.$.fn.serializeObject = function ()
{
    var o = {};
    var a = this.serializeArray();
    $.each(a, function() {
        if (o[this.name]) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
};

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uname: '',
            password: '',
            loginView: true,
            id: '',
            rememberMe: false,
            isDirty: false,
            nameinput:''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.toggleView = this.toggleView.bind(this);
        this.forgotHandler = this.forgotHandler.bind(this);
        this.getYear = this.getYear.bind(this);
    }


    componentDidMount() {
        this.inputElement.focus();
        //window.history.pushState("", "", '/login');
        this.getYear();
    }

    componentWillMount() {
        const user = this.props.user;

        if (this.props.location.search.includes('changePassword')) {
            this.props.history.push({
                pathname: '/changePassword',
                search: this.props.location.search,
                state: { user },
            });
        }

        if (user) {
            this.props.history.push({
                pathname: '/dashboard',
                search: this.props.location.search,
                state: { user },
                props: { logout: this.props.onLogout }
            });
        } else {
            NotificationManager({ message: 'Unable to log you in. Please contact the administrator.', type: 4 });
        }
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({[name]: value});
    }

    getYear() {
        let newDateYear = new Date().getFullYear();
        $('.copyright-content').append('<span>Copyright &copy; ' + newDateYear + ' SWEFT. All rights reserved. </span>');
    }

    forgotHandler(e) {
        e.preventDefault();
        const formData = $('[name="forgot-view"]').serializeObject();
        // for more information about email regular expresion check
        // https://stackoverflow.com/questions/46155/how-to-validate-email-address-in-javascript
        const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(formData.id) {
            if(!emailReg.test(formData.id)) {
                $('.forgot-error').text('Invalid Email Format. Please re-enter email address.');
                return;
            }

            axios.post(require('../common/configurations.js').forgotPassword, formData)
                .then( (response) => {
                    let res = response.data;
                    if(res.status) {
                        $('.forgot-error').text(res.responseMessage);
                        $('.forgot-error').css('display', 'block');

                        NotificationManager({ message: res.responseMessage, type: 1 });
                        return;
                    }
                    else {
                        NotificationManager({ message: res.responseMessage, type: 4 });
                        return;
                    }
                })
                .catch( error => {
                    console.log('Failed due to technical errors', error);
                });
        } else {
            $('.forgot-error').text('Please enter associated Email Id');
        }
    }

    toggleView() {
        if(this.state.loginView) {
            this.setState({loginView: false});
        }else {
            this.setState({loginView: true});
        }

        $('.forgot-error').text('');
        $('#login-form').find('.error').text('');
    }

    handleSubmit(e) {
        e.preventDefault();
        const loginForm = $('#login-form');
        const data = loginForm.serialize();
        const data2 = loginForm.serializeArray();
        const validate = loginForm.serializeObject();

        if(validate.uname === '' && validate.password === '') {
            $(loginForm).find('.error').show();
            $(loginForm).find('.error').text('Username & Password is required to Sign In');
            this.setState({isDirty: true});
            return;
        } else if(validate.uname === '') {
            $(loginForm).find('.error').show();
            $(loginForm).find('.error').text('Username is required to Sign In');
            this.setState({isDirty: true});
            return;
        }
        else if (validate.password === '') {
            $(loginForm).find('.error').show();
            $(loginForm).find('.error').text('Password is required to Sign In');
            this.setState({isDirty: true});
            return;
        }

        if(this.state.rememberMe) {
            createCookie('sweftRemember', this.state.uname, 365);
        }else {
            deleteCookie('sweftRemember');
        }

        if(data2[0].value && data2[1].value) {
            this.props.onLogin(data2[0].value, data2[1].value).then((res) => {

                this.props.setUser(this.props.auth.user);
                
                if (res.type === 'LOGIN_SUCCESS') {
                //Forces route to dashboard page after logging in successfully
                this.props.history.push({
                    pathname: '/dashboard',
                });
                }
            });
        }
    }

    render() {
        return (
            <LoginPageComponent
                state={this.state}
                inputRef={el => {this.inputElement = el;} }
                forgotHandler={this.forgotHandler}
                handleInputChange={this.handleInputChange}
                toggleView={this.toggleView}
                handleSubmit={this.handleSubmit}
            />
        );
    }
}


LoginPage.propTypes = {
    onLogin: PropTypes.func,
    setUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        auth: state.authentication
    };
};

const mapDispatchToprops = (dispatch) => {
    return {
        onLogin: (username, password) => {
            return dispatch(loginActions.sweftLogin(username, password));
        },
        onLogout: () => {
            dispatch(loginActions.sweftLogout());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToprops)(LoginPage);


