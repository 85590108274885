import React, { Component } from 'react';
import Modal from '../../modals/common-modal.js';
import axios from 'axios';

// var NotificationManager = require('../../common/notification');
import NotificationManager from '../../common/components/notification';

import { ProductServices } from '../../product2/services/product-services';

class CampaignModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: this.props.isOpen,

            productData: this.props.campaignProduct,
            arrCampaigns: [],
            search: "",
            deleteCampaignOpen: false,
            deletingCampaign: "",
            viewMode: false,
            relatedProducts: [],
            isDirty: false
        };
        this.saveData = {};
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.preventClick = this.preventClick.bind(this);
        this.unassignCampaign = this.unassignCampaign.bind(this);
        this.assignCampaign = this.assignCampaign.bind(this);
        this.openConfirmPopup = this.openConfirmPopup.bind(this);
        this.closeConfirmPopup = this.closeConfirmPopup.bind(this);
        this.deleteConfirmed = this.deleteConfirmed.bind(this);
        this.saveOrUpdate = this.saveOrUpdate.bind(this);
    }

    close() {
        this.setState({isOpen: false});
    }

    open() {
        this.setState({isOpen: true});
    }

    handleSearchChange(e){
        e.stopPropagation();
        var target = e.target,
            value = target.value,
            name = target.name;

        this.setState({[name]:value});
    }

    preventClick(e) {
        e.stopPropagation();
    }

    assignCampaign(eventName, checked){
        let _this = this;
        let campaigns = _this.state.arrCampaigns;
        campaigns.some(function (campaign) {
            if(eventName == campaign.eventName) {
                if(campaign.newStatus == "" || campaign.newStatus == null) {
                    campaign.newStatus = checked;
                    campaign.status = checked;
                } else {
                    campaign.newStatus = "";
                }
            }
        });
        _this.setState({arrCampaigns: campaigns, isDirty: true});
    }

    unassignCampaign(eventName) {
        let campaigns = this.state.arrCampaigns;
        campaigns.some(function(campaign) {
            if(eventName == campaign.eventName) {
                if(campaign.newStatus == "" || campaign.newStatus == null) {
                    campaign.newStatus = false;
                    campaign.status = false;
                } else {
                    campaign.newStatus = "";
                    campaign.status = false;
                }
            }
        });
        this.setState({arrCampaigns: campaigns, isDirty: true});
    }

    openConfirmPopup(eventName) {
        if(!$(event.target).hasClass("disabled")) {
            this.setState({ deleteCampaignOpen: true, deletingCampaign: eventName});
        }
    }

    closeConfirmPopup() {
        this.setState({ deleteCampaignOpen: false, deletingCampaign: ""});
    }

    deleteConfirmed() {
        let eventName = this.state.deletingCampaign;
        this.unassignCampaign(eventName);
        this.closeConfirmPopup();
    }

    saveOrUpdate() {
        let _this = this;
        let postdata = {};
        postdata.data = _this.state.arrCampaigns;
        postdata.productIdLst = _this.saveData.productIdLst;

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.saveorupdateproductevent(postdata)
            .then( (response) => {
                console.log('productContainer helperComponents campaign modal API call success data: ', response.data);
                let res = response.data;

                if(res.status){
                    NotificationManager({message:res.responseMessage, type:1});
                    let prodKey = _this.state.productData.productId;
                    if(res.campaignDate) {
                        res.campaignDate.map(function (oProduct) {
                            let prodId = Object.keys(oProduct)[0];
                            let resultDate = oProduct[prodId];
                            _this.props.setCampaignCell(prodId, 'campaignDate', resultDate);
                        });
                    }
                    _this.props.modalClose();
                }else{
                    NotificationManager({message:res.responseMessage, type:4});
                }
            })
            .catch( error => {
                console.log('productContainer helperComponents campaign-modal API call failed', error);
            });
    }

    componentDidMount() {
        let _this = this;
        // let events = _this.props.loadCampaignEvents();  commented for ag-grid

        let requrl = require('../../common/configurations.js').product.listproductEvents;

        // let postdata = {};    // commented for ag-grid
        // postdata['id'] = events;    // commented for ag-grid

        let postdata = {"id":["AA34585493"]};

        // if(events) {         // commented for ag-grid


        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.listproductEvents(postdata)
            .then( (response) => {
                console.log('productContainer helperComponents campaign modal API call success data: ', response.data);
                let res = response.data;

                if(res.status){
                    // NotificationManager({message:res.responseMessage,type:1});
                    _this.setState({ arrCampaigns: res.data });
                    _this.saveData.data = res.data;
                    _this.saveData.productIdLst = res.productIdLst;
                    _this.setState({viewMode: res.merchandiserFlag, relatedProducts: res.productIdLst});
                } else {
                    NotificationManager({message:res.responseMessage, type:4});
                    _this.props.modalClose();
                }
            })
            .catch( error => {
                console.log('productContainer helperComponents campaign-modal API call failed', error);
                NotificationManager({message: "Could not get the data due to technical reasons.", type:4});
                _this.props.modalClose();
            });

        // } else {                             // commented for ag-grid
        //     _this.props.modalClose();    // commented for ag-grid
        // }                                    // commented for ag-grid
    }

    render() {
        let _this = this;
        let campaigns = _this.state.arrCampaigns;

        let filteredCampaigns = _this.state.arrCampaigns;
        if(_this.state.search.trim().length > 0){
            let reg = new RegExp(_this.state.search, "i");
            filteredCampaigns = _this.state.arrCampaigns.filter((item, index) =>{
                if(item.eventName.search(reg) > -1  || item.eventId.toString().search(reg) > -1){
                    return true;
                }
            });
        }

        let associatedCampaigns = campaigns.filter(function(campaign) { return campaign.status; });

        let relatedProducts = _this.state.relatedProducts.toString();

        return (

            <div>
                {/* {this.state.productData && */}
                <div className={"campaign-modal-container " + (this.state.viewMode ? "" : "view-mode")}>
                    <div className="modal-header">
                        Manage Campaigns
                        <span className="close" onClick={this.props.modalClose}>x</span>
                    </div>
                    <div className="modal-content">
                        {/* <p className="prod-info">{"Product Name: " + this.state.productData.prodName}</p> */}
                        {/*<h4 className="prod-info">{"Product Id: "+ this.state.productData.productId}</h4>*/}
                        <p className="prod-info">
                            {"Product Id: " + relatedProducts}
                        </p>

                        <div className={"associated-events"}>
                            <h3>ASSOCIATED CAMPAIGNS</h3>
                            {
                                associatedCampaigns.length == 0 ?
                                    <div className={"scroll event-list"}><h4 className={"text-center"}>No associated
                                        campaigns</h4></div> :
                                    <div className={"scroll event-list"}>
                                        <ul>
                                            {
                                                campaigns.map(function (obj, index) {
                                                    return (
                                                        <li key={index} className={"li-event " + (obj.status ? "" : "hide")}>{obj.eventId + " " + obj.eventName}
                                                            <span
                                                                className={"close-icon " + (_this.state.viewMode ? "" : "disabled")}
                                                                onClick={() => {
                                                                    _this.openConfirmPopup(obj.eventName);
                                                                }
                                                                }></span>
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </div>
                            }
                        </div>

                        <div className={"add-events"}>
                            <h3>ADD CAMPAIGN</h3>
                            <div className="list-viewer-holder">
                                <div className="search-field-holder">
                                    <input type="text" value={this.state.search} name="search" placeholder="Search"
                                        onChange={this.handleSearchChange} disabled={!this.state.viewMode}
                                        autoComplete="off"/>
                                </div>
                                <div className="list-scrollable event-list scroll">
                                    <ul>
                                        {
                                            filteredCampaigns.map((item, index) => {
                                                return (
                                                    <li key={index} onClick={this.preventClick}
                                                        className={(item.status ? "hide" : "")}>
                                                        <label className="blue-checkbox wt">
                                                            <input type="checkbox" checked={item.status}
                                                                name={item.eventName}
                                                                disabled={!this.state.viewMode}
                                                                onChange={(e) => {
                                                                    let checked = e.target.checked;
                                                                    _this.assignCampaign(item.eventName, checked);
                                                                }
                                                                }/><label></label>
                                                            {item.eventId + " " + item.eventName}
                                                        </label>
                                                    </li>);
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer text-center">
                            <button className="gray-button" disabled={!this.state.viewMode || !this.state.isDirty}
                                onClick={this.saveOrUpdate}>Save
                            </button>
                            <button className="gray-button" disabled={!this.state.viewMode}
                                onClick={this.props.modalClose}>Cancel
                            </button>
                        </div>
                    </div>
                </div>
                {/* } */}
                <Modal isOpen={_this.state.deleteCampaignOpen}>
                    <div>
                        <div className="modal-content">
                            <div className="text-center confirm-delete">
                                <h4>Are you sure on removing the associated campaign {'"' + _this.state.deletingCampaign + '"'}?</h4>
                            </div>
                            <div className="modal-footer text-center">
                                <button className="gray-button" onClick={_this.closeConfirmPopup}>No</button>
                                <button className="gray-button" onClick={_this.deleteConfirmed}>Yes</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default CampaignModal;
