import React, { Component } from 'react';
import CancelModal from '../../modals/cancelModal.js';
import axios from 'axios';

// var NotificationManager = require('../../common/notification');
import NotificationManager from '../../common/components/notification';
import {CommonServices} from '../../common/services/common-services';

class AddVendorForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vendor: {
                contactPerson: "",
                email: "",
                name: "",
                orgVendorId: "ovid1",
                phone: "",
                status: true,
                type: ""
            },
            validation: {
                contactPerson: false,
                email: true,
                name: true,
                orgVendorId: false,
                phone: false,
                status: false,
                type: false
            },
            isDirty: false,
            errorMessage: '',
            isModalOpen: false,
            isModified: false
        };

        this.openModalCancel = this.openModalCancel.bind(this);
        this.modalClose = this.modalClose.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.saveVendor = this.saveVendor.bind(this);
    }

    // modalOpen() {
    // 	this.setState({isModalOpen: true});
    // }

    modalClose(key) {
        this.setState({isModalOpen: false});
        switch(key) {
            case 'save':
                this.saveVendor();
                break;
            case 'no':
                this.props.modalClose();
                break;
        }
    }

    openModalCancel() {
        if(this.state.isModified) {
            this.setState({isModalOpen: true});
        } else {
            this.props.modalClose();
        }
    }


    handleChange(event) {
        const target = event.target;
	  	const value = target.type === 'checkbox' ? target.checked : target.value;
	  	const name = target.name;

	  	if (value.trim() === "" || value.trim() === undefined) {
	  		if(this.state.validation[name]) {
	  			target.className += ' invalid';
	  		}
	  	} else {
	  		target.classList.remove('invalid');
	  	}

	  	let {vendor} = this.state;
	  	let newVendor = vendor;

	  	if (name === 'phone' && isNaN(value)) {
            newVendor[name] = '';
	  	} else {
	  		newVendor[name] = value;
	  	}
	  	this.setState({vendor: newVendor, isModified: true});
    }

 	validateEmail(email) {
        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(email);
   	}

    saveVendor(event) {
        // event.preventDefault();
        var _this = this;
        let {vendor} = this.state;
        let newVendor = vendor;
        // validation
        var flag = 0, errorMessage = '*Mandatory fields marked with an asterisk are missing.';

        if(this.state.validation.name && newVendor.name === '') {
            flag++;
        } else {
            this.setState({isDirty: false});
        }

        if(this.state.validation.email && newVendor.email === '') {
            flag++;
        } else {
            if(this.validateEmail(this.state.vendor.email)) {
                this.setState({isDirty: false});
            } else {
                flag++;
                errorMessage = "Invalid Email address.";
            }
        }

        if(this.state.validation.phone && newVendor.phone === '') {
            flag++;
        } else {
            this.setState({isDirty: false});
        }

        //
        if(flag === 0) {
            var req = JSON.stringify(newVendor);

		    let headers = {
		          'Authorization': this.props.user.token,
		          'content-type': 'application/json'
            };

            CommonServices.addVendor(req)
		    .then( (response) => {
		        console.log('productContainer helperComponents add-vendor API call success data: ', response);
		        let res = response;

	        	if (res.status) {
                        window.newVendorList = window.newVendorList ? window.newVendorList : [];
                        window.newVendorListRes = window.newVendorListRes ? window.newVendorListRes : [];
                        var newData = {
                            fieldId:res.data.id,
                            fieldName:newVendor.name
                        };
                        window.newVendorList.push(newData);
                        window.newVendorListRes.push(newData);
                        _this.props.setCellValueAndSaveProduct(_this.props.productId, 'vendorId', newData.fieldId, null);
                        _this.props.modalClose();
                        NotificationManager({
                            message:res.responseMessage,
                            type:1
                        });
	        	} else {
	        		_this.setState({isDirty: true, errorMessage: res.responseMessage});
	        	}
		    })
		    .catch( error => {
		        console.log('productContainer helperComponents add-vendor API call failed', error);
	        	_this.setState({isDirty: true, errorMessage: 'Failed!!'});
		    });
        } else {
            this.setState({isDirty: true, errorMessage: errorMessage});
        }

        console.log('FINAL RESULT', newVendor);
    }

    render() {
        return(
            <div>
                <div className="modal-header">
					Add New Vendor
                    <span className="close" onClick={this.openModalCancel}>x</span>
                </div>
                <div className="modal-content">
                    <div className="add-vendor modal-form">
                        <form className="addNewVendor" name="addNewVendor">
                            <div className="form-container scroll">
                                <h4>Vendor Details</h4>
                                <hr/>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Name*</label>
                                            <input type="text" className="form-control" name="name" value={this.state.vendor.name} onChange={this.handleChange}></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Email*</label>
                                            <input type="text" className="form-control" name="email" value={this.state.vendor.email} onChange={this.handleChange}></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Contact Person</label>
                                            <input type="text" className="form-control" name="contactPerson" value={this.state.vendor.contactPerson} onChange={this.handleChange} maxLength={30}></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Phone</label>
                                            <input type="text" className="form-control" name="phone" value={this.state.vendor.phone} onChange={this.handleChange} maxLength="10"></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <input type="button" className="gray-button" value="Cancel" onClick={this.openModalCancel}></input>
                                <input type="button" className="gray-button" value="Add Vendor" onClick={this.saveVendor}></input>
                            </div>
                            {(this.state.isDirty) ? <span className="error-message">{this.state.errorMessage}</span> : ''}
                        </form>
                    </div>
                </div>

                <CancelModal data={this.state.user} isOpen={this.state.isModalOpen} modalClose={this.modalClose}/>
            </div>
        );
    }
}


export default AddVendorForm;
