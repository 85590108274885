import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import NotificationManager from '../common/components/notification';
import {CommonServices} from '../common/services/common-services';
import { withRouter } from 'react-router-dom';

$.fn.serializeObject = function()
{
    var o = {};
    var a = this.serializeArray();
    $.each(a, function() {
        if (o[this.name]) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
};

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userId:'',
            oldPassword:'',
            newPassword:'',
            confNewPassword:'',
            isMatching:false,
            notValid:false,
            errMsg:'',
            isSuccess:false,
            isDirty:false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.isValidPassword = this.isValidPassword.bind(this);
    }

    componentDidMount() {
        let queryparams = this.props.location.search;

        if (queryparams.indexOf('%') != -1) {
            try {
                var queryparams_dec = decodeURIComponent(queryparams);

                let params1 = queryparams_dec.split('?');

                let params = params1[2].split('&');
                let pair = null,
                    data = [];

                params.forEach(function(d) {
                    pair = d.split('=');
                    data.push({key: pair[0], value: pair[1]});
                });

                this.setState({
                    userId:data[1].value,
                    oldPassword:data[0].value
                });
            } catch (error) {
                this.setState({
                    error:error,
                    errorInfo: "Something went wrong, please reopen the link from email or contact your administrator"
                });
            }
        } else {
            try {
                let params = queryparams.replace("?", "").split('&');
                let pair = null,
                    data = [], paramObj = {};

                params.forEach(function(d) {
                    pair = d.split('=');

                    if (pair[0]) {
                        paramObj[pair[0]] = pair[1];
                    }

                    data.push({key: pair[0], value: pair[1]});
                });

                this.setState({
                    userId: paramObj.id,
                    oldPassword: paramObj.resulttoken
                });
            } catch (error) {
                this.setState({
                    error: error,
                    errorInfo: "Something went wrong, please reopen the link from email or contact your administrator"
                });
            }
        }

        window.history.pushState("", "", '/changePassword');
    }

    handleSubmit(e) {
        e.preventDefault();

        let base = this;

        if (this.state.newPassword !== this.state.confNewPassword) {
            this.setState({ isMatching: false });
        } else {
            this.setState({ isMatching: true });
        }

        var checkValid = this.isValidPassword();

        if (!checkValid.isValid) {
            NotificationManager({ message: checkValid.msg, type: 4 });

            this.setState({
                notValid:true,
                errMsg:checkValid.msg,
                isDirty:true
            });

            return;
        }

        const formData = $('[name="change-password"]').serializeObject();

        if (checkValid.isValid) {
            let headers = {
                'content-type': 'application/json'
            };

            CommonServices.changePassword(JSON.stringify(formData))
                .then( (response) => {
                    console.log('changePassword: handleSubmit API success', response);

                    let data = response;

                    if (data.status) {
                        base.setState({ isSuccess: true });

                        NotificationManager({
                            message: data.responseMessage,
                            type: 1
                        });
                    } else {
                        NotificationManager({
                            message: data.responseMessage,
                            type: 4
                        });

                        base.setState({
                            notValid:true,
                            errMsg:data.responseMessage
                        });
                    }
                })
                .catch( error => {
                    console.log("password updated failed due to technical errors");
                });
        }
    }

    isValidPassword() {
        var passwordLength = 8,
            empty = 'Mandatory field(s) is/are missing',
            length = `password must be at least ${passwordLength} characters`,
            notMatching = 'Password did not match. Please provide the same password.';

        var msg_str = "Your password must have min 8 to max 20 characters. It must contain atleast one numeric value (0-9), Uppercase letter (A-Z), Lowercase letter (a-z) and a Special character [!@#$%] . Special characters not allowed include & and * ";
        var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,20})");

        if (this.state.newPassword && this.state.confNewPassword) {
            if (strongRegex.test(this.state.newPassword)) {
                if (this.state.newPassword != this.state.confNewPassword) {
                    return {
                        isValid: false,
                        msg: notMatching
                    };
                } else {
                    if (this.state.newPassword.indexOf('*') != -1 || this.state.newPassword.indexOf('&') != -1 || this.state.newPassword.indexOf('^') != -1 || this.state.newPassword.indexOf('%') != -1)
                        return {
                            isValid: false,
                            msg: 'Passwords may not have & or * in special characters'
                        };
                    else
                        return { isValid: true };
                }
            } else {
                return {
                    isValid: false,
                    msg: msg_str
                };
            }
        } else {
            return {
                isValid: false,
                msg: empty
            };
        }
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        this.setState({ [name]: value });
    }

    render() {
        return (
            <div className="login-container">
                <div className="holder">
                    <div className="login-form-holder">
                        <h1 className="login-header"><img src={require("../common/icons/SweftLogo-horizontal.svg")} /></h1>
                        { !this.state.error && !this.state.isSuccess &&
                        <div className="login-form-container">
                            <div className="login-view">
                                <form
                                    name="change-password"
                                    onSubmit={this.handleSubmit}
                                >
                                    <input
                                        type="hidden"
                                        name="userId"
                                        value={this.state.userId}
                                        onChange={this.handleInputChange} />
                                    <input
                                        type="hidden"
                                        name="oldPassword"
                                        value={this.state.oldPassword}
                                        onChange={this.handleInputChange} />
                                    <p className="login-welcome-msg">
                                        <strong>Enter new password</strong>
                                    </p>
                                    <fieldset className="login-fields">
                                        <label className="login-label"><img src={require("../login/images/PasswordIcon.svg")}/></label>
                                        <input
                                            name="newPassword"
                                            type="password"
                                            placeholder="New password"
                                            className={(this.state.isDirty && this.state.newPassword === '') ? 'border-error' : ''}
                                            value={this.state.newPassword}
                                            onChange={this.handleInputChange}
                                        />
                                    </fieldset>
                                    <fieldset className="login-fields">
                                        <label className="login-label"><img src={require("../login/images/PasswordIcon.svg")} /></label>
                                        <input name="confNewPassword"
                                            type="password"
                                            placeholder="Re-enter New password"
                                            className={(this.state.isDirty && this.state.confNewPassword === '') ? 'border-error' : ''}
                                            value={this.state.confNewPassword}
                                            onChange={this.handleInputChange}
                                        />
                                        <span className="text-msg" onClick={this.toggleView}>
                                            {"Your password must have atleast 8 characters. It must contain atleast one numeric value (0-9), Uppercase letter (A-Z), Lowercase letter (a-z) and Special character (~`!@#$()+=_-{}[]\|:;”’?/<>,.)"}
                                        </span>
                                    </fieldset>
                                    <fieldset className="button-holder">
                                        <button type="submit" className="btn btn-default right login-button">Change</button>
                                    </fieldset>
                                </form>
                            </div>
                            <p></p>
                            {this.state.notValid &&
                            <p className="error">{this.state.errMsg}</p>
                            }
                        </div>
                        }

                        { this.state.isSuccess &&
                        <div className="login-form-container">
                            <p>
                                {'Your password has been successfully reset, please click on '}
                                <a href="/login" title="Login">login</a>
                                {' to continue'}
                            </p>
                        </div>
                        }

                        { this.state.error &&
                        <div className="login-form-container">
                            <p>{this.state.errorInfo}</p>
                        </div>
                        }
                    </div>

                    <div className="text-center copyright-content">
                        Copyright &copy; 2017 SWEFT. All rights reserved.
                    </div>
                </div>
                <div id="notifier-container" className="notifier-container"></div>
            </div>
        );
    }
}

export default withRouter(ChangePassword) ;
