import React from 'react';
import SelectCategory from './select-category';
import axios from 'axios';
import NotificationManager from '../../../common/components/notification';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate} from 'react-day-picker/moment';
import moment from 'moment';

export class BulkUpdatePopup extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedRows: props.selectedRows,
            newValueNotSet: true,
            selectedField: '',
            newValue: '',
            newValueId: '',
            channelValue: {},
            allValuesViewed: null,
            selectedFieldMaxLength: null,
            selectedFieldType: null,
        };
        this.trackScrolling = this.trackScrolling.bind(this);
        this.getChannelNames = this.getChannelNames.bind(this);
        this.getSelectedFieldHeaderName = this.getSelectedFieldHeaderName.bind(this);
        this.expandParent = this.expandParent.bind(this);
        this.checkChildRecords = this.checkChildRecords.bind(this);
    }

    componentDidMount() {
        this.props.toggleBulkUpdateMenu();
        const elem = this.refs.elementToFire;

        if (elem.scrollHeight === elem.clientHeight) {
            this.setState({ allValuesViewed: true });
        } else {
            elem.addEventListener('scroll', this.trackScrolling);
        }

        const channelValue = this.state.channelValue;
        for (let i = 0; i < this.state.selectedRows.length; i++) {
            if (this.state.selectedRows[i] && this.state.selectedRows[i]['channelIds']) {
                channelValue[i] = this.getChannelNames(this.state.selectedRows[i]['channelIds']);
            }
        }

        this.setState({ channelValue });

        this.props.toggleSpinner(true);

        // Check childrenIDs for at least one record having children
        // If no records have children, turn the spinner off
        this.state.selectedRows.map((i) => {
            if (i.childrenIds.length > 0) {
                this.expandParent();

                return;
            } else {
                this.props.toggleSpinner(false);
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const _this = this;
        if (_this.props.fetchChildren != prevProps.fetchChildren && !_this.props.fetchChildren) {
            // Added a timeout here because the grid
            // takes time to redraw with the new data
            setTimeout(() => {
                _this.checkChildRecords();
            }, 1000);
        }
    }

    expandParent() {
        const _this = this;

        //Expand all top level row nodes
        this.state.selectedRows.map((i) => {
            this.props.gridApi.forEachNode((node) => {
                if (i.childrenIds.length > 0) {
                    if (node.data && !node.expanded && i.productId == node.data.productId) {
                        node.setExpanded(true);
                    }
                } else {
                    _this.props.toggleSpinner(false);
                }
            });
        });
    }

    checkChildRecords() {
        const _this = this;

        _this.state.selectedRows.map((i) => {
            _this.props.gridApi.forEachNode((node) => {
                if (i.childrenIds.length > 0) {
                    if (node.data && i.productId == node.data.productId) {
                        node.childStore.allRowNodes.map((childNode) => {
                            childNode.setSelected(true);
                        });
                    }
                }
            });
        });

        this.setState(
            { selectedRows: this.props.gridApi.getSelectedRows() },
            () => {
                this.expandParent();
            });
    }

    getChannelNames(channelIds) {
        const channelsList = this.props.channelsList;
        const channelsName = [];
        const channelIdsArray = channelIds;

        for (let i = 0; i < channelIdsArray.length; i++) {
            for (let j = 0; j < channelsList.length; j++) {
                if (channelIdsArray[i] === channelsList[j].fieldId) {
                    channelsName.push(channelsList[j].fieldName);
                    break;
                }
            }
        }
        return channelsName;
    }

    componentWillUnmount() {
        const elem = this.refs.elementToFire;
        elem.removeEventListener('scroll', this.trackScrolling);
    }

    trackScrolling() {
        const wrappedElement = this.refs.elementToFire;

        if ((wrappedElement.scrollTop + wrappedElement.clientHeight) > (wrappedElement.scrollHeight - 20)) {
            this.setState({ allValuesViewed: true });
        }
    }

    handleChangeSelectField(event) {
        this.setState({
            selectedField: event.target.value,
            selectedFieldHeaderName: this.getSelectedFieldHeaderName(event.target.value),
            selectedFieldMaxLength: this.getSelectedFieldLength(event.target.value),
            selectedFieldType: this.getSelectedFieldType(event.target.value),
            newValue: '',
            maxLength: null,
        });
    }

    getSelectedFieldHeaderName(field) {
        let columns = this.props.columns;

        columns = columns.filter((obj) => obj.field === field);

        return columns[0].headerName;
    }

    getSelectedFieldLength(field) {
        let columns = this.props.columns;

        columns = columns.filter((obj) => obj.field === field);

        return columns[0].cellEditorParams ? columns[0].cellEditorParams.maxLength : columns[0].maxLength;
    }

    getSelectedFieldType(field) {
        let columns = this.props.columns;

        columns = columns.filter((obj) => obj.field === field);

        return columns[0].format;
    }

    handleChangeNewValue(event) {
        const id = event.target.id;

        if (id == 'systemDescription') {
            // searches the for the column in the props
            // from the columns array and send back
            // the maxLength allowed to be entered by users
            let getCharCount;
            this.props.columns.map((a) => {
                if (a.field == id && a.maxLength) {
                    getCharCount = a.maxLength;
                    return;
                }
            });

            // sets all the input values to uppercase for
            // systemDescription inputs by users
            let upperCase = event.target.value.toUpperCase();

            this.setState({ newValue: upperCase, maxLength: getCharCount});
        } else {
            this.setState({ newValue: event.target.value });
        }
    }

    handleClickNext() {
        const elem = this.refs.elementToFire;

        if (elem.scrollHeight === elem.clientHeight) {
            this.setState({ allValuesViewed: true, newValueNotSet: false });
        } else {
            this.setState({ newValueNotSet: false });
        }
    }

    handleClickBack() {
        const wrappedElement = this.refs.elementToFire;
        wrappedElement.scrollTop = 0;
        if (!this.state.newValueNotSet) {
            this.setState({ newValueNotSet: true, allValuesViewed: false, newValue: '' });
        }
    }

    handleChangeNewDropdownValue(event) {
        let flag = true;
        if (this.state.selectedField === 'displayLevel' || this.state.selectedField === 'copyLevel') {
            this.state.selectedRows.map((i, x) => {
                if (i.prdHirarchyLevel != 'PARENT') {
                    NotificationManager({ message: this.state.selectedFieldHeaderName + ' can only be changed for parent level records', type: 4 });
                    flag = false;
                }
            });
        }

        if (flag) {
            this.setState({ newValue: event.target.value });
        }
    }

    handleDateChange (selectedDay, modifiers, dayPickerInput) {
    // let d = new Date(e.target.value);
        const input = dayPickerInput.getInput();
        let parseDate = dayPickerInput.state.value;
        this.setState({newValue: parseDate});
    }

    setDepartmentFieldValue(valueObj) {
        let value;
        if (this.state.selectedField === 'departmentName') {
            value = valueObj.department;
        }
        if (this.state.selectedField === 'classColumnName') {
            value = valueObj.classColumn;
        }
        if (this.state.selectedField === 'subClassColumnName') {
            value = valueObj.subClassColumn;
        }
        // const value = valueObj.department;

        const categoriesList = this.props.categoriesList;
        let newValue;
        let newValueId;
        if (valueObj) {
            for (let i = 0; i < categoriesList.length; i++) {
                if (categoriesList[i].fieldId === value) {
                    newValue = categoriesList[i].fieldName;
                    newValueId = categoriesList[i].fieldId;
                    break;
                }
            }
        }

        if (newValue) {
            this.setState({ newValue, newValueId });
        }
    }


    handleBulkUpdate() {
        console.log('selected rows: ', this.state.selectedRows);
        console.log('selected fieidl: ', this.state.selectedField);
        console.log('new value: ', this.state.newValue);
        console.log('categoriesList: ', this.props.categoriesList);

        // unselects the rows when bulkupdate happens
        // rows get checked again after the API call
        // so updated data is persisted for selected records
        this.props.gridApi.deselectAll();
        this.props.gridApi.clearRangeSelection();

        let productIds = [];
        for (let i = 0; i < this.state.selectedRows.length; i++) {
            productIds.push(this.state.selectedRows[i]['productId']);
        }
        let fetchObject = {};
        fetchObject.productIds = productIds;

        let newValueId = this.state.newValueId;

        let fieldGraphName = this.props.findKeyGraphName(this.state.selectedField);
        fetchObject.field = fieldGraphName ? fieldGraphName.columnName : '';

        if (this.state.selectedField === 'departmentName') {
            fetchObject.field = 'catID';
            fetchObject.newValue = newValueId;
        } else if (this.state.selectedField === 'classColumnName') {
            fetchObject.field = 'catID';
            fetchObject.newValue = newValueId;
        } else if (this.state.selectedField === 'subClassColumnName') {
            fetchObject.field = 'catID';
            fetchObject.newValue = newValueId;
        } else if (this.state.selectedField === 'vendorName') {
            fetchObject.field = 'vendor.vendorId';
            fetchObject.newValue = this.state.newValue;
        } else if (this.state.selectedField === 'launchDate') {
            fetchObject.newValue = moment(new Date(this.state.newValue)).format('MM/DD/YYYY');
        } else {
            fetchObject.newValue = this.state.newValue;
        }

        console.log('bulk update before api: ', fetchObject);

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };
        axios({
            method: 'post',
            url: require('../../../common/configurations.js').product2.bulkUpdate,
            headers: headers,
            data: fetchObject,
        })
            .then((response) => {
                console.log('bulk update: ', response.data);
                const res = response.data;

                // sets the new value to be set in the cells
                let resData = res.responseData;

                if (res.status === 'success') {
                    this.props.gridApi.forEachNode((node) => {
                        for (let j = 0; j < resData.length; j++) {
                            if (resData[j]['productId'] == node.data['productId']) {
                                // sets the data to the updated data from the backend
                                node.setData(resData[j]);
                                // selects the nodes which were selected by the user
                                // so the new data is present in the selected rows
                                node.setSelected(node.data['productId'] == resData[j]['productId']);
                            }
                        }
                    });

                    setTimeout(() => {
                        this.props.hideBulkUpdatePopup();
                    }, 500);
                    if (this.state.maxLength) {
                        this.setState({
                            maxLength: null,
                        });
                    }
                    NotificationManager({ message: res.message, type: 1 });
                } else {
                    NotificationManager({ message: res.message, type: 4 });
                }
            })
            .catch((error) => {
                console.log('Server Error Failed', error);
            });
    }

    render() {
        let columns = this.props.columns;
        const removedCols = ['departmentName', 'classColumnName', 'images', 'itemReadyToOrder', 'mpr2Approved', 'setDetail', 'quantityBreaks'];
        columns = columns.filter((obj) => {
            return (!removedCols.includes(obj.field));
        });

        columns = columns.filter(obj => obj.hide == false && obj.feildLevel != 'R');

        let newValueHTML = <input
            type='text'
            maxLength={this.state.selectedFieldMaxLength}
            id={this.state.selectedField}
            value={this.state.newValue}
            onChange={this.handleChangeNewValue.bind(this)} />;

        let newValue = this.state.newValue;

        let dropdowns = this.props.attrDropDownValues;
        let selectedField = this.state.selectedField;

        if (this.state.selectedFieldType === 'LargeTextArea') {
            newValueHTML = <textarea
                type='text'
                rows="5" cols="50"
                maxLength={this.state.selectedFieldMaxLength}
                id={this.state.selectedField}
                value={this.state.newValue}
                onChange={this.handleChangeNewValue.bind(this)} />
        }

        if (this.state.selectedFieldType === 'Date') {
            newValueHTML = <DayPickerInput
                formatDate={formatDate}
                parseDate={parseDate}
                placeholder={'MM-DD-YYYY'}
                value={this.state.newValue}
                onDayChange={this.handleDateChange.bind(this)} />;
        }

        if (this.state.selectedField === 'isWebproducerapproved' ||
    this.state.selectedField === 'isResendPiRequest' ||
    this.state.selectedField === 'earlyReqSampleSwatch' ||
    this.state.selectedField === 'attrApproved' ||
    this.state.selectedField === 'attrCompleted') {
            newValueHTML = <select value={this.state.newValue} onChange={this.handleChangeNewDropdownValue.bind(this)}>
                <option value={''}>{''}</option>
                <option value={'true'}>{'Check'}</option>
                <option disabled value={'false'}>{'Uncheck'}</option>
            </select>;
        }

        if (this.state.selectedField === 'displayLevel' || this.state.selectedField === 'copyLevel') {
            newValueHTML = <select value={this.state.newValue} onChange={this.handleChangeNewDropdownValue.bind(this)}>
                <option value={''}>{''}</option>
                {
                    ['Style', 'Color', 'Size'].map((obj, index) => {
                        return (
                            <option value={obj} key={index}>{obj}</option>
                        );
                    })
                }
            </select>;

            if (this.state.newValue !== '') {
                const newValueObj = ['Style', 'Color', 'Size'].filter((obj) => obj === this.state.newValue);
                newValue = newValueObj[0];
            }
        }

        if (this.state.selectedField === 'vendorName') {
            newValueHTML = <select value={this.state.newValue} onChange={this.handleChangeNewDropdownValue.bind(this)}>
                <option value={''}>{''}</option>
                {
                    this.props.vendorsList.map((obj, index) => {
                        return (
                            <option value={obj.fieldId} key={index}>{obj.fieldName}</option>
                        );
                    })
                }
            </select>;

            if (this.state.newValue !== '') {
                const newValueObj = this.props.vendorsList.filter((obj) => obj.fieldId === this.state.newValue);
                newValue = newValueObj[0].fieldName;
            }
        }

        if (this.state.selectedField === 'season' || this.state.selectedField === 'WestelmSeason') {
            newValueHTML = <select value={this.state.newValue} onChange={this.handleChangeNewDropdownValue.bind(this)}>
                <option value={''}>{''}</option>
                {
                    this.props.seasonsList.map((obj, index) => {
                        return (
                            <option value={obj.fieldId} key={index}>{obj.fieldName}</option>
                        );
                    })
                }
            </select>;

            if (this.state.newValue !== '') {
                const newValueObj = this.props.seasonsList.filter((obj) => obj.fieldId === this.state.newValue);
                newValue = newValueObj[0].fieldName;
            }
        }

        if (this.state.selectedField === 'color_attribute') {
            newValueHTML = <select value={this.state.newValue} onChange={this.handleChangeNewDropdownValue.bind(this)}>
                <option value={''}>{''}</option>
                {
                    this.props.colorsList.map((val, index) => {
                        return (
                            <option value={val} key={index}>{val}</option>
                        );
                    })
                }
            </select>;
        }

        if (this.state.selectedField === 'size_attribute') {
            newValueHTML = <select value={this.state.newValue} onChange={this.handleChangeNewDropdownValue.bind(this)}>
                <option value={''}>{''}</option>
                {
                    this.props.sizeList.map((val, index) => {
                        return (
                            <option value={val} key={index}>{val}</option>
                        );
                    })
                }
            </select>;
        }

        if (this.state.selectedField === 'departmentName' || this.state.selectedField === 'classColumnName' || this.state.selectedField === 'subClassColumnName') {
            newValueHTML = <SelectCategory
                setValue={this.setDepartmentFieldValue.bind(this)}
                user={this.props.user}
            />;
        }

        if (this.state.selectedField === 'channel') {
            newValueHTML = <select value={this.state.newValue} onChange={this.handleChangeNewDropdownValue.bind(this)}>
                <option value={''}>{''}</option>
                {
                    this.props.channelsList.map((obj, index) => {
                        return (
                            <option value={obj.fieldId} key={index}>{obj.fieldName}</option>
                        );
                    })
                }
            </select>;

            if (this.state.newValue !== '') {
                const newValueObj = this.props.channelsList.filter((obj) => obj.fieldId === this.state.newValue);
                newValue = newValueObj[0].fieldName;
                console.log('new valueis: ', newValue);
            }
        }


        // added for Attr dropdowns
        if (this.state.selectedField === 'attr1') {
            newValueHTML = <select value={this.state.newValue} onChange={this.handleChangeNewDropdownValue.bind(this)}>
                <option value={''}>{''}</option>
                {
                    this.props.attrDropDownValues.attr1.map((val, index) => {
                        return (
                            <option value={val} key={index}>{val}</option>
                        );
                    })
                }
            </select>;
        }

        if (this.state.selectedField === 'attr2') {
            newValueHTML = <select value={this.state.newValue} onChange={this.handleChangeNewDropdownValue.bind(this)}>
                <option value={''}>{''}</option>
                {
                    this.props.attrDropDownValues.attr2.map((val, index) => {
                        return (
                            <option value={val} key={index}>{val}</option>
                        );
                    })
                }
            </select>;
        }

        if (this.state.selectedField === 'attr3') {
            newValueHTML = <select value={this.state.newValue} onChange={this.handleChangeNewDropdownValue.bind(this)}>
                <option value={''}>{''}</option>
                {
                    this.props.attrDropDownValues.attr3.map((val, index) => {
                        return (
                            <option value={val} key={index}>{val}</option>
                        );
                    })
                }
            </select>;
        }

        // added to dynamically pick dropdowns and display values as dropdowns
        for (const key in dropdowns) {
            if (Object.hasOwnProperty.call(dropdowns, key)) {
                const element = dropdowns[key];
                if (selectedField === key) {
                    newValueHTML = <select value={this.state.newValue} onChange={this.handleChangeNewDropdownValue.bind(this)}>
                        <option value={''}>{''}</option>
                        {
                            element.map((val, index) => {
                                return (
                                    <option value={val} key={index}>{val}</option>
                                );
                            })
                        }
                    </select>;
                }
            }
        }

        return (
            <div style={{ fontSize: '12px', height: '100%', width: '100%' }}>
                <div style={{
                    padding: '1.5rem 3rem',
                    fontSize: '1.9rem',
                    background: '#2FACB2',
                    color: '#fff',
                    borderRadius: '14px 14px 0 0',
                    position: 'relative',
                    width: '100%',
                }}>
          Bulk Product Update
                    <span
                        style={{
                            position: 'absolute',
                            right: '2rem',
                            cursor: 'pointer',
                        }}
                        onClick={this.props.hideBulkUpdatePopup}>
            x
                    </span>
                </div>
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'white',
                        paddingTop: '1rem',
                        paddingRight: '3rem',
                        paddingBottom: '1rem',
                        paddingLeft: '3rem',
                        maxHeight: '280px',
                        overflow: 'scroll',
                    }}
                    ref="elementToFire"
                >
                    <div className="bulkupdate-table" style={{ width: '100%', height: '100%' }}>
                        <div style={{ width: '50%', float: 'left' }}>

                            <div className="table-wrap">
                                <div className="table-head">
                                    <div className="table-row">
                                        <div className="table-detail product-name">
                      Product Name
					          </div>
                                        <div className="table-detail">
                      Vendor Part #
					          </div>
                                    </div>
                                </div>
                                <div>
                                    {
                                        this.state.selectedRows.map((obj, index) => {
                                            return (
                                                <div className="table-row" style={{ fontSize: '13px' }} key={index}>
                                                    <div className="table-detail  product-name">{obj.prodName}</div>
                                                    <div className="table-detail">{obj.vendorStyle}</div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>

                        </div>
                        {
                            this.state.newValueNotSet &&
              <div style={{ width: '50%', float: 'right', paddingLeft: '15px' }}>
                  <label style={{ padding: '5px', fontSize: '13px', fontWeight: 'bold' }}>
                  Select field: <br /><br />
                      <select style={{width: '100%'}} value={this.state.selectedField} onChange={this.handleChangeSelectField.bind(this)}>
                          <option value={''}>{''}</option>
                          {
                              columns.map((obj, index) => {
                                  return (
                                      <option value={obj.field} key={index}>{obj.headerName}</option>
                                  );
                              })
                          }
                      </select>
                  </label>
                  <br /><br /><br />
                  <label style={{ padding: '5px', fontSize: '13px', fontWeight: 'bold' }}>
                  New Update Value: <br /><br />

                      {newValueHTML}

                  </label>
                  <br /><br /><br />
                  <input type='button' value='NEXT' onClick={this.handleClickNext.bind(this)} />
              </div>
                        }
                        {
                            !this.state.newValueNotSet &&
              <div style={{ width: '50%', float: 'right' }}>

                  <div className="table-wrap changed-data" style={{ width: '100%', marginLeft: '5px' }}>
                      <div className="table-head">

                          <div className="table-row">
                              <div className="table-detail product-name">
                        Old Value for: {this.state.selectedFieldHeaderName}
                              </div>
                              <div className="table-detail">
                        New Value for: {this.state.selectedFieldHeaderName}
                              </div>
                          </div>

                      </div>
                      <div className="table-body">
                          {this.state.selectedField !== 'channel' &&
                      this.state.selectedRows.map((obj, index) => {
                          return (
                              <div className="table-row" style={{ fontSize: '13px' }} key={index}>
                                  <div className="table-detail  product-name">{obj[this.state.selectedField]}</div>
                                  <div className="table-detail">{newValue}</div>
                              </div>
                          );
                      })
                          }
                          {this.state.selectedField === 'channel' &&
                      this.state.selectedRows.map((obj, index) => {
                          return (

                              <div className="table-row" style={{ fontSize: '13px' }} key={index}>
                                  <div className="table-detail  product-name">
                                      {this.state.channelValue[index] ? this.state.channelValue[index].toString() : ' '}
                                  </div>
                                  <div className="table-detail">
                                      {this.state.channelValue[index] ? this.state.channelValue[index].toString() + ', ' : ' '}{this.state.channelValue[index]
                                          ? this.state.channelValue[index].indexOf(newValue) > 0 ? ' ' : newValue
                                          : ' '}
                                  </div>
                              </div>
                          );
                      })
                          }
                      </div>
                  </div>
              </div>
                        }
                    </div>
                </div>
                <div className="bulkupdate-actions" style={{ width: '100%', maxHeight: '150px', padding: '5px', backgroundColor: 'white' }}>
                    <input type='button' value='Cancel' style={{ marginLeft: '15px' }} onClick={this.props.hideBulkUpdatePopup} />
                    <div style={{ float: 'right' }}>
                        {!this.state.newValueNotSet &&
              <input type='button'
                  value='Back'
                  style={{ marginRight: '15px' }}
                  onClick={this.handleClickBack.bind(this)} />
                        }
                        <input type='button'
                            value='Update'
                            style={{ float: 'right', marginRight: '15px' }}
                            disabled={(!this.state.allValuesViewed || this.state.newValueNotSet) ? 'disabled' : ''}
                            onClick={this.handleBulkUpdate.bind(this)}

                        />
                    </div>
                </div>
            </div >
        );
    }
}
