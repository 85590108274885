export const addNewAlert = (state = {}, action) => {
    switch (action.type) {
        case 'ADD_NEW_ALERT':
            return action.data;
        default:
            return state;
    }
};

export const rulesForAlerts = (state = [], action) => {
    switch (action.type) {
        case 'ADD_RULES_FOR_ALERTS':
            return action.data;
        default:
            return state;
    }
};

export const specialityData = (state = {}, action) => {
    switch (action.type) {
        case 'ADD_SPECIALITY_DATA':
            return action.data;
        default:
            return state;
    }
};

export const userView = (state = 'user', action) => {
    switch (action.type) {
        case 'USER_VIEW_UPDATE':
            return action.data;
        default:
            return state;
    }
};
