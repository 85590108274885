import React from 'react';
import axios from 'axios';

import { RingLoader } from 'react-spinners';
import DashboardServices from '../services/dashboard-services';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as product2_actions from '../../product2/redux/product2Actions';


class Accomplishments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isloadAccomplishment: false,
            user: {},
            spinner: false,
            data: null,
            filtersCatIds: [],
        };
        this.data = null;
        this.buildAccompList = this.buildAccompList.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.loadData = this.loadData.bind(this);
        this.openProdLink = this.openProdLink.bind(this);
        this.refereshView = this.refereshView.bind(this);
        this.selectedStageStatus = this.selectedStageStatus.bind(this);
        this._isMounted = false;
    }

    componentDidMount() {
        console.log('Accomplishments: componentDidMount: ', this.props);
        this._isMounted = true;


        let userSpeciality = this.props.user.speciality;
        console.log('user speciality is: ', userSpeciality);
        if (userSpeciality) {
            let tempArraySplitByComma = userSpeciality.split(',');
            let filtersCatIds = [];
            if (tempArraySplitByComma && tempArraySplitByComma.length > 1) {
                let tempArrayCommaLength = tempArraySplitByComma.length;
                for (let i = 0; i < tempArrayCommaLength; i++) {
                    let tempArraySplitByDash = tempArraySplitByComma[i].split('-');
                    let catId = tempArraySplitByDash && tempArraySplitByDash[0] ? tempArraySplitByDash[0] : null;
                    filtersCatIds.push(parseInt(catId));
                }
            }
            this.setState({ filtersCatIds }, () => {
                this.getStageStatusData();
            });
        } else {
            this.getStageStatusData();
        }

        // this.loadData(this.props.user.userId);
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.setState({ spinner: false });
    }

    componentWillReceiveProps(nextProps) {
        const _this = this;
        console.log('componentWillReceiveProps accomplishments component', this.props, nextProps);
        if (JSON.stringify(this.props.appliedFilters.catIds) != JSON.stringify(nextProps.appliedFilters.catIds)) {
            this.setState({ filtersCatIds: nextProps.appliedFilters.catIds}, () => {
                this.getStageStatusData();
            });
        }
        // if (nextProps.userId !== this.props.user.userId) {
        //     this.refereshView(nextProps);
        // }
    }

    openProdLink(obj) {
        var allow = true;
        if (allow) {
            var redirecturl = obj.url;
            if (obj.page == "product") {
                require('../../common/utils').createCookie('alertProductClickUrl', redirecturl);
                location.href = "/product";
            }
            if (obj.page == "creative") {
                require('../../common/utils').createCookie('creativeAlert', redirecturl);
                location.href = "/creative";
            }
            if (obj.page == "Marketing") {
                require('../../common/utils').createCookie('marketingAlert', redirecturl);
                location.href = "/marketing";
            }
        }
    }

    buildAccompList(list) {
        var items = null;
        if (list && list.length > 0) {
            items = list.map((o, i) => {
                return (<li key={i} className="accoplish-cursor" data-row-key={i}>{o.title}<span>{o.value}</span></li>);
            });
        } else {
            items = <li>No Accomplishments to list</li>;
        }
        return items;
    }

    refereshView(nextProps) {
        // this.setState({isloadAccomplishment:false});
        this.loadData(nextProps.user.userId);
    }

    loadData(userId) {
        this.setState({ spinner: true });

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        DashboardServices.getAccomplishments({ id: userId }).then((response) => {
            console.log('dashboard accomplishments loadData API call success data: ', response);
            let res = response;
            if (res.general) {
                this.data = res.general;

                if (this._isMounted) {
                    this.setState({ isloadAccomplishment: true, spinner: false });
                }
            }
        })
            .catch(error => {
                console.log('dashboard accomplishments loadData API call failed', error);
                this.setState({ spinner: false });
            });
    }

    applyFilter(e) {
        console.log('apply filter: ', e.target.value);
        var target = e.target;
        var value = target.value;
        var userId = this.props.user.userId;

        if (value !== "") {
            var filter = {
                "field": "accomplishments",
                "value": value,
                "userId": userId
            };

            let headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json'
            };
            axios({
                method: 'post',
                url: require('../../common/configurations.js').dashboard.searchAlertsOrAccomplishment,
                headers: headers,
                data: filter
            })
                .then((response) => {
                    console.log('accomplishments applyFilter API call success data: ', response.data);
                    let res = response.data;
                    if (res.general) {
                        this.data = res.general;
                        this.setState({ isloadAccomplishment: true });
                    }
                })
                .catch(error => {
                    console.log('accomplishments applyFilter API call failed', error);
                });
        } else {
            var userId = this.props.user.userId;
            this.loadData(userId);
        }
    }


    getStageStatusData() {
        let obj = {};
        obj['id'] = this.props.user.userId;
        obj['catIds'] = this.state.filtersCatIds;

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };
        axios({
            method: 'post',
            url: require('../../common/configurations.js').dashboard.getStageStatus,
            headers: headers,
            data: obj
        })
            .then((response) => {
                console.log('accomplishments getStageStatus API call success data: ', response.data);
                let res = response.data;
                if (res.code == 200) {
                    this.setState({ data: res.responseData });
                }
            })
            .catch(error => {
                console.log('accomplishments getStageStatus API call failed', error);
            });
    }

    selectedStageStatus(data) {
        let obj = {};
        obj['id'] = this.props.user.id;
        obj['catIds'] = this.state.filtersCatIds;

        let _this = this;
        _this.props.toggleSpinner(true);

        _this.props.setCurrentPage('product2');

        const url = require('../../common/configurations.js').apiURL + data;

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };
        axios({
            method: 'post',
            url: url,
            headers: headers,
            data: obj
        })
            .then((response) => {
                console.log('accomplishments getStageStatus API call success data: ', response.data);
                let res = response.data;
                if (res.responseData) {
                    let product2GridData = {};
                    product2GridData['data'] = res.responseData.data;
                    product2GridData['columnDetails'] = res.responseData.columnDetails;
                    product2GridData['recordCount'] = res.responseData.data.length;
                    product2GridData['fromAlerts'] = true;
                    product2GridData['priorityAlertsForSort'] = {
                        'filters': obj,
                        'url': url,
                    };
                    _this.props.addProduct2GridData(product2GridData);
                    _this.props.actionPerformedFrom({fromAlerts: true});
                    _this.props.toggleSpinner(false);
                }
            })
            .catch(error => {
                console.log('accomplishments getStageStatus API call failed', error);
            });
    }

    render() {
        console.log('Accomplishments: render: ', this.props);

        var intViewportHeight = window.innerHeight;
        var containerheight = intViewportHeight - 64 - 30;
        var ht = (containerheight / 2) - 81;
        var containerStyle = {
            height: ht
        };

        let stageStatusData = this.state.data;

        stageStatusData && stageStatusData.sort((a, b) => a.order - b.order);

        return (
            <div className="accomplishments">
                <div className="headbox">
                    <i className="icon-acc"></i>
                    <span className="heading-text">Stage Status</span>
                    {/* <select onChange={this.applyFilter}>
                        <option value="">Filter By</option>
                        <option value="hourly">Last Hour</option>
                        <option value="daily">Last day</option>
                        <option value="weekly">Last Week</option>
                        <option value="monthly">Last Month</option>
                    </select> */}

                </div>

                <ul className="scroll">
                    {
                        stageStatusData && stageStatusData.map((i, x) => (
                            <li key={x} className="accoplish-cursor" >{i.stageStatus}
                                <span onDoubleClick={() => this.selectedStageStatus(i.url)}>{i.count}</span>
                            </li>
                        ))
                    }
                </ul>

                {/* {
                    // this.state.isloadAccomplishment &&
                    <ul className="scroll">
                        <li className="accoplish-cursor" >{'Items Turned Over'}<span>{327}</span></li>
                        <li className="accoplish-cursor" >{'Items Ready To Order'}<span>{158}</span></li>
                        <li className="accoplish-cursor" >{'Attributes Completed'}<span>{214}</span></li>
                        <li className="accoplish-cursor" >{'Carry-Forward Updates'}<span>{622}</span></li>
                        <li className="accoplish-cursor" >{'Samples Requested'}<span>{463}</span></li>
                        <li className="accoplish-cursor" >{'Images Approved'}<span>{846}</span></li>
                        <li className="accoplish-cursor" >{'Copy Approved'}<span>{571}</span></li>
                    </ul>
                } */}


                {/* <div className='react-spinner'>
                    <RingLoader
                      color={'#36D7B7'}
                      loading={this.state.spinner}
                      size={30}
                    />
                </div> */}

            </div>
        );
    }
}

const mapDispatchToprops = (dispatch) => {
    return {
        addProduct2GridData: (data) => {
            dispatch(product2_actions.addProduct2GridData(data));
        },
        actionPerformedFrom: (data) => {
            dispatch(product2_actions.actionPerformedFrom(data));
        },
    };
};

export default withRouter(
    connect(null, mapDispatchToprops)(Accomplishments)
);




