import React from 'react';
import ImageListerModal from './imageListerModal';
import NotificationManager from '../../../common/components/notification';

export class ImagePopupRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            rowHeight: 25,
        };
    }

    render() {
        const emprtyArray = [];
        const imgArray = this.props.value == null ? emprtyArray : this.props.value; // Array of data with image urls

        let url = null; // Store image url values

        imgArray.find((e) => {
            if (this.state.rowHeight === 25) {
                url = e.tinyImageUrl;
            } else if (this.state.rowHeight === 50) {
                url = e.thumbNailUrl;
            } else {
                url = e.imageUrl;
            }

            return url;
        });

        const width = this.state.rowHeight === 25 ? this.state.rowHeight - 2 : '100%';

        return (
            <div style={{ height: '100%', textAlign: 'center' }}>
                <span style={{ display: 'inline-block', verticalAlign: 'middle', height: '100%' }}></span>
                {url &&
                <img src={url} width={width} height={'100%'} />
                }
            </div>
        );
    }
}

export class ImagePopupEditor extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            showPopup: false,
            imageArr: this.props.value,
            mainImageSrc: this.props.value.length > 0 ? this.props.value[0].imageUrl : null,
        };

        this.updateMainViewer = this.updateMainViewer.bind(this);
    }

    componentWillMount() {
        this.setState({ showPopup: true });
    }

    // Comes with AG grid used to set a cell to be a popup or not
    isPopup() {
        return true;
    }

    getValue() {
        return this.state.imageArr;
    }

    // Closes the image popup
    closeImagePopup() {
        this.setState({ showPopup: false });
        this.props.api.stopEditing();
    }

    updateMainViewer(val) {
        const currentSrc = this.state.imageArr[val].imageUrl;
        const imageName = this.state.imageArr[val].imageName;
        const imageId = this.state.imageArr[val].imageId;
        const isImageDeletable = this.state.imageArr[val].deleteFlag;

        this.setState({
            mainImageSrc: currentSrc,
            imageName: imageName,
            imageId: imageId,
            isImageDeletable: isImageDeletable
        });
    }

    render() {
        return (
            <div>
                {this.state.showPopup &&
                <div className="ag-modal-container">
                    <div className="ag-modal-holder">
                        <div>
                            <div className="modal-header">
                                Pictures
                                <span className="close" onClick={this.closeImagePopup.bind(this)}>x</span>
                            </div>
                            <div className="modal-content product-pictures">
                                <div className="vertical-image-holder-for-product">
                                    <div className="rowHolder">
                                        <div className="thumbnail-holder scroll">
                                            <ul>
                                                {this.state.imageArr.map((item, index) => (
                                                    <li className="thumbnails" key={index}
                                                        onClick={() => (this.updateMainViewer(index))}
                                                    >
                                                        <img src={item.thumbNailUrl ? item.thumbNailUrl : item.imageUrl} />
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        <div className={'main-image '}>
                                            {this.state.mainImageSrc &&
                                            <div className="productImageContainer">
                                                {this.props.modalName != 'publish_image' && this.state.isImageDeletable &&
                                                <span className="closeIcon" onClick={this.deleteImage}>X</span>
                                                }
                                                <img src={this.state.mainImageSrc} />
                                            </div>
                                            }
                                            {!this.state.mainImageSrc &&
                                            <p>No Image</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
        );
    }
}
