import React from 'react';

import ShotAngles from './add-shot-angles';

export class ShotAngleRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        // Displays the icon
        const ShotAnglesIcon = <div className="ag-popupIndicator"><img src={require('../../../common/icons/Expand Grid.svg')} /></div>;
        // Checks the display level of the record for validation
        let dispAngle;

        if (this.props.node.data) {
            if (this.props.node.data.displayLevel == 'Color' && this.props.node.level == 1) {
                dispAngle = ShotAnglesIcon;
            }

            if (this.props.node.data.displayLevel == 'Style' && this.props.node.level == 0) {
                dispAngle = ShotAnglesIcon;
            }

            if (this.props.node.data.displayLevel == 'Size' && this.props.node.level == 2) {
                dispAngle = ShotAnglesIcon;
            }

            if (this.props.node.data.dataLevel == 'SINGLE') {
                dispAngle = ShotAnglesIcon;
            }
        }

        return (
            <div>
                {dispAngle}
            </div>
        );

        // let displayLevel = (this.props.data && this.props.data.displayLevel) ? this.props.data.displayLevel : null;

        // let ShotAnglesIcon = <div className="ag-popupIndicator"><img src="/icons/Expand Grid.svg"/></div>

        // if(displayLevel) {
        //     switch(displayLevel) {
        //         case 'Style':
        //             if(this.props.node.level == 0 || this.props.node.data.dataLevel == 'SINGLE') {
        //                 return(
        //                     <div>
        //                         {ShotAnglesIcon}
        //                     </div>
        //                 );
        //             } else {
        //                 return null;
        //             }
        //             case 'Color':
        //             if(this.props.node.level == 1 || this.props.node.data.dataLevel == 'SINGLE') {
        //                 return(
        //                     <div>
        //                         {ShotAnglesIcon}
        //                     </div>
        //                 );
        //             } else {
        //                 return null;
        //             }
        //             case 'Size':
        //             if(this.props.node.level == 2 || this.props.node.data.dataLevel == 'SINGLE') {
        //                 return(
        //                     <div>
        //                         {ShotAnglesIcon}
        //                     </div>
        //                 );
        //             } else {
        //                 return null;
        //             }
        //             default: return null;

        //     }
        // } else {
        //     return null;
        // }
    }
}


// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


export class ShotAngleEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: false,
        };
    }

    componentWillMount() {
        // this.setState ({ showPopup: true })

        let flag = false;

        if (this.props.node.data.displayLevel == 'Color' && this.props.node.level == 1 || this.props.node.data.dataLevel == 'SINGLE') {
            flag = true;
        } else if (this.props.node.data.displayLevel == 'Style' && this.props.node.level == 0 || this.props.node.data.dataLevel == 'SINGLE') {
            flag = true;
        } else if (this.props.node.data.displayLevel == 'Size' && this.props.node.level == 2 || this.props.node.data.dataLevel == 'SINGLE') {
            flag = true;
        }

        if (!flag) {
            this.props.api.stopEditing();
        } else {
            this.setState({ showPopup: true });
        }
    }

    // componentWillUnmount() {
    //     this.props.api.tabToNextCell();
    // }


    isPopup() {
        return true;
    }

    /**
     * Gets the value when the component unmounts and pushes it for render
     * @return {string} cell value
     */
    getValue() {
        return this.props.value;
    }

    /**
     * Closes the modal
     */
    modalClose() {
        this.setState({ showPopup: false });
        this.props.api.stopEditing();
    }


    render() {
        return (
            <div>
                {
                    this.state.showPopup &&
                    <div className="ag-modal-container">
                        <div className="ag-modal-holder">
                            <ShotAngles
                                rowData={this.props.node.data}
                                user={this.props.user}
                                modalClose={this.modalClose.bind(this)}
                            />
                        </div>
                    </div>
                }
            </div>
        );
    }
}


// ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
