import React, { Component } from 'react';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import JqxGrid from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';
import Modal from '../../common/modals/commonModal';
import GridDataBuilder from '../../common/gridDataBuilder';
import NotificationManager from '../../common/components/notification';
import CategoryServices from '../../product2/services/category-services';

import axios from 'axios';

class CategoryGrid extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            catId: this.props.catId ? this.props.catId : 13,
            isModalOpen: false,
            isModified: false,
            selectedRowCount: 0,
            oldData: [],
            selectedrowindex: null,
        };

        this.columnList = null;
        this.loadData = this.loadData.bind(this);
        this.addCategory = this.addCategory.bind(this);
        this.deleteCategory = this.deleteCategory.bind(this);
        this.saveCategory = this.saveCategory.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.getColumnNames = this.getColumnNames.bind(this);
        this.deleteConfirmDialog = this.deleteConfirmDialog.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.stripHtml = this.stripHtml.bind(this);
    }

    /**
     * Cancels the changes
     */
    cancelChanges() {
        this.setState({ isLoaded: false });
        this.loadData(this.state.catId);
    }

    /**
     * Closes the modal
     */
    modalClose() {
        this.setState({ isModalOpen: false });
    }

    /**
     * Clears the div
     * @param {*} html
     * @return {void} empty divs
     */
    stripHtml(html) {
        const tmp = document.createElement('DIV');
        tmp.innerHTML = html;

        return tmp.textContent || tmp.innerText || '';
    }

    /**
     * Shows the delete confirm popup
     */
    deleteConfirmDialog() {
        const _this = this;
        const selectedrows = _this.categoryGrid.getselectedrowindexes();
        const selectedCatIds = [];

        for (let i = 0; i < selectedrows.length; i++) {
            const rowdata = _this.categoryGrid.getrowdata(selectedrows[i]);

            selectedCatIds.push(rowdata.categoryId);
        }

        if (selectedCatIds.length > 0) {
            this.setState({ isModalOpen: true });
        } else {
            NotificationManager({
                message: 'Please select atleast one category to delete',
                type: 4
            });
        }
    }

    /**
     * Calls the backend to delete a category
     */
    deleteCategory() {
        const _this = this;
        const selectedrows = _this.categoryGrid.getselectedrowindexes();
        const selectedCatIds = [];
        const newlyAddedCatIds = [];

        for (let i = 0; i < selectedrows.length; i++) {
            const rowdata = _this.categoryGrid.getrowdata(selectedrows[i]);

            if (rowdata.uid && rowdata.uid.indexOf('CAT') === -1) {
                selectedCatIds.push(rowdata.uid);
            } else {
                newlyAddedCatIds.push(rowdata.uid);
            }
        }

        if (selectedCatIds.length > 0 || newlyAddedCatIds.length > 0) {
            if (newlyAddedCatIds.length > 0) {
                newlyAddedCatIds.map((id, i) => {
                    _this.categoryGrid.deleterow(id);
                });

                _this.modalClose();
            }

            if (selectedCatIds.length > 0) {
                const headers = {
                    'Authorization': this.props.user.token,
                    'content-type': 'application/json',
                };

                CategoryServices.deleteCategory({ id: selectedCatIds })
                    .then((response) => {
                        const res = response;

                        if (res.status) {
                            _this.modalClose();

                            NotificationManager({
                                message: res.responseMessage,
                                type: 1
                            });

                            _this.categoryGrid.deleterow(selectedCatIds);
                            _this.props.refreshView('category');
                            _this.setState({ isLoaded: false });
                            _this.loadData(_this.state.catId);
                        } else {
                            NotificationManager({
                                message: res.responseMessage,
                                type: 4
                            });
                        }
                    })
                    .catch((error) => {
                        console.log('AdminProductCategory category-grid API call failed', error);
                        NotificationManager({
                            message: 'Error:Response failed, Please try again.',
                            type: 4
                        });
                    });
            }
        } else {
            NotificationManager({
                message: 'Please select atleast one category to delete',
                type: 4
            });
        }
    }

    /**
     * Gets the column names
     * @return {array} row details
     */
    getColumnNames() {
        const columnList = this.columnList;
        const rowDetails = {};

        columnList.map(function (obj, i) {
            if (obj.type === 'bool') {
                rowDetails[obj.fieldName] = false;
            } else {
                rowDetails[obj.fieldName] = '';
            }
        });

        return rowDetails;
    }

    /**
     * Calc for new category addition
     */
    addCategory() {
        const _this = this;
        const newData = this.getColumnNames();

        newData.child = '-1';
        newData.parent = this.state.catId;
        newData.displayOrder = 0;

        const categoryCode = 'CAT' + Math.floor(Date.now());

        _this.categoryGrid.addrow(categoryCode, newData);
    }

    /**
     * Gets row data by category ID
     * @param {*} catId
     * @return {object} row data
     */
    getRowByCatId(catId) {
        const _this = this;
        const rowsData = _this.categoryGrid.getrows();

        let data = null;

        rowsData.map((o, i) => {
            if (o.categoryId === catId) {
                data = o;
            }
        });

        return data;
    }


    /**
     * Calls backend to save the category
     * @return {void}
     */
    saveCategory() {
        const _this = this;
        const rowsData = _this.categoryGrid.getrows();
        let isDepartment = false;
        let putDept = $('.tree-node-list.active').closest('.level1').attr('data-catname');
        const department = putDept ? putDept : 'department';

        if (department.toLowerCase() === 'department') {
            isDepartment = true;
        }

        const rowIds = [];
        let error = false;
        let displyError = false;

        if (rowsData.length > 0) {
            rowsData.map(function (o, i) {
                if (o.name === '') {
                    error = true;
                }

                if (o.categoryId.indexOf('CAT') > -1) {
                    o.categoryId = '0';
                }

                if (o.displayOrder < 0) {
                    displyError = true;
                }

                if (o.name) {
                    o.name = _this.stripHtml(o.name);
                }

                o.parent = _this.state.catId;
                o.isDepartment = isDepartment;
                o.displayOrder = (o.displayOrder) ? parseInt(o.displayOrder, 10) : 0;

                delete o.parentCategories;
                rowIds.push(o.categoryId);
            });

            if (error) {
                NotificationManager({
                    message: 'Category name should not be empty',
                    type: 4
                });

                return true;
            }

            if (displyError) {
                NotificationManager({
                    message: 'Negative value not allowed in Display Order.',
                    type: 4
                });

                return true;
            }

            console.log('SAVE CATEGORY BEFORE API CALL', rowsData);

            const headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json',
            };

            CategoryServices.saveCategory(rowsData).then((response) => {
                console.log('AdminProductCategory saveCategory API call success data: ', response);

                const res = response;

                if (res.status) {
                    NotificationManager({
                        message: 'Category has been successfully updated',
                        type: 1
                    });

                    _this.props.refreshView('category');
                    _this.setState({ isLoaded: false });
                    _this.setState({ isModified: false });
                    _this.loadData(_this.state.catId);
                } else {
                    NotificationManager({
                        message: res.responseMessage,
                        type: 4
                    });
                }
            })
                .catch((error) => {
                    console.log('AdminProductCategory saveCategory API call failed', error);

                    NotificationManager({
                        message: 'Error:Response failed, Please try again.',
                        type: 4
                    });
                });
        } else {
            NotificationManager({ message: 'No items found', type: 4 });
        }
    }

    /**
     * Calls backend to load the grid
     * @param {*} catId
     */
    loadData(catId) {
        const base = this;

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        CategoryServices.subcategories([catId]).then((response) => {
            console.log('AdminProductCategory loadData API call success data: ', response);

            const res = response;

            const columnDetails = [];

            base.columnList = res.columnDetails;
            base.oldData = res.data;
            let gridData = GridDataBuilder(res, 'category');

            base.setState({ isLoaded: true, gridData: gridData });
        })
            .catch((error) => {
                console.log('AdminProductCategory loadData API call failed', error);
                NotificationManager({ message: 'Error:Response failed, Please try again.', type: 4 });
            });
    }


    componentDidMount() {
        let catIdF = this.props.catId ? this.props.catId : 13;
        this.loadData(catIdF);

        const _this = this;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.catId != this.props.catId) {
            this.setState({ isLoaded: false, catId: nextProps.catId });
            this.loadData(nextProps.catId);
        }
    }

    onRowselect(event) {
        this.setState({ selectedRowCount: this.state.selectedRowCount + 1 });
    }

    onRowunselect(event) {
        this.setState({ selectedRowCount: this.state.selectedRowCount - 1 });
    }

    onCellvaluechanged(event) {
        const _this = this;
        const args = event.args;
        const value = args.newvalue;
        const oldvalue = args.oldvalue;

        if (oldvalue != value) {
            _this.catFooterRef.enableFooter();
        }
    }

    render() {
        const footerHeight = 50;
        const headerHeight = 64;
        const gridHeight = window.innerHeight - footerHeight - headerHeight;
        const modalClass = 'CategoryDeleteConfrim';

        return (
            <div>
                <div className="categoryGrid pos-relative">
                    {this.state.isLoaded &&
                    <JqxGrid
                        ref={(grid) => {
                            this.categoryGrid = grid;
                        }}
                        width={'100%'}
                        source={this.state.gridData.dataAdapter}
                        editable={true}
                        columnsResize={true}
                        columns={this.state.gridData.columns}
                        height={gridHeight}
                        columnsresize={true}
                        theme={'light'}
                        selectionmode={'checkbox'}
                        selectedrowindex={this.state.selectedrowindex}
                        onCellvaluechanged={this.onCellvaluechanged.bind(this)}
                        onRowselect={this.onRowselect.bind(this)}
                        onRowunselect={this.onRowunselect.bind(this)}
                    />
                    }
                    {this.state.isModalOpen &&
                    <Modal isOpen={this.state.isModalOpen} addClass={modalClass}>
                        <ConfirmDialog
                            deleteCategory={this.deleteCategory}
                            modalClose={this.modalClose}
                        />
                    </Modal>
                    }
                </div>
                <CategoryFooter
                    isModified={this.state.isModified}
                    selectedRowCount={this.state.selectedRowCount}
                    cancelChanges={this.cancelChanges}
                    addCategory={this.addCategory}
                    saveCategory={this.saveCategory}
                    deleteConfirmDialog={this.deleteConfirmDialog}
                    ref={(grid2) => {
                        this.catFooterRef = grid2;
                    }}
                />
            </div>
        );
    }
}

class ConfirmDialog extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="modal-content text-center">
                    <div className="add-copy modal-form">
                        <form className="confirmdelete" name="confirmdelete">
                            <div className="form-container scroll">
                                <p>Do you  want to delete this category?</p>
                            </div>
                            <div className="modal-footer text-center">
                                <input type="button" className="gray-button" name="noDelete" value="No" onClick={this.props.modalClose} />
                                <input type="button" className="gray-button" name="yesDelete" value="Yes" onClick={this.props.deleteCategory} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

class CategoryFooter extends Component {
    constructor(props) {
        super(props);

        this.state  = {
            isModified: false,
        };
    }

    enableFooter() {
        this.setState({ isModified: true });
    }

    disableFooter() {
        this.setState({ isModified: false });
    }

    render() {
        return (
            <div className="footer category-footer alerts-footer">
                <div className={`category-footer-container `}>
                    <div className="category-footer clearfix">
                        <div className="footer-content left">
                            <div className="pos-relative">
                                <button
                                    type="button"
                                    onClick={this.props.addCategory}
                                    className={`gray-button add-category `}
                                >
                                    Add Category
                                </button>
                                <button
                                    type="button"
                                    onClick={this.props.deleteConfirmDialog}
                                    className={`gray-button delete-category `}
                                    disabled={this.props.selectedRowCount > 0 ? false : true}
                                >
                                    Delete Category
                                </button>
                            </div>
                        </div>
                        <div className="footer-content right">
                            <button
                                type="button"
                                className="gray-button"
                                disabled={(this.state.isModified) ? false : true}
                                onClick={this.props.cancelChanges}
                            >
                                Cancel Changes
                            </button>
                            <button
                                type="button"
                                className="gray-button"
                                disabled={(this.state.isModified) ? false : true}
                                onClick={this.props.saveCategory}
                            >
                                Apply Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CategoryGrid;
