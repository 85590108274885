import React from 'react';
// import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import Notifier from '../pageheader/notifierComponent';
import FilterStrip from '../common/filterStripComponent';
import NewAlerts from './helperComponents/newAlerts';
import RTOSummary from './helperComponents/RTOSummary';
import Alerts from './helperComponents/alertsComponent';

import RecentAlerts from './helperComponents/recentAlertsComponent';
import Accomplishments from './helperComponents/accomplishmentsComponent';
import ErrorBoundryComponent from '../common/error-boundry-component';
import Messages from './helperComponents/messages';

const DashboardComponent = ({
    state,
    props,
    gridHeight,
    buildPriorityList,
}) => {
    return (

        <div className="dash-board-container">
            <div className="dash-board">
                <div>
                    {
                        state.showfilter &&
                        <ErrorBoundryComponent>
                            <FilterStrip  filters={props.appliedFilters.refine} updateFilters={props.removeFilter}/>
                        </ErrorBoundryComponent>
                    }
                    <div className="dash-board-left">
                        {/* <NewAlerts {...props} /> */}
                        <ErrorBoundryComponent>
                            <Alerts {...props}/>
                        </ErrorBoundryComponent>
                    </div>
                    {/* <div className="dash-board-center">
                        <Alerts {...props}/>
                    </div> */}
                    <div className="dash-board-right">
                        {/* <RecentAlerts {...props}/> */}
                        <ErrorBoundryComponent>
                            <Messages {...props}/>
                        </ErrorBoundryComponent>
                        <ErrorBoundryComponent>
                            <Accomplishments {...props}/>
                        </ErrorBoundryComponent>
                    </div>
                </div>
            </div>
        </div>


    );
};

DashboardComponent.propTypes = {
    state: PropTypes.object,
    props: PropTypes.object,
    gridHeight: PropTypes.number,
};

export default withRouter(DashboardComponent);
