
import React from 'react';
import axios from 'axios';
import NotificationManager from '../../common/components/notification';

/**
 * renders the pi upload modal
 */
class PiUploadModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            errorMsg: '',
            isValid: false,
            otherBtns: false,
            pdfUrl: this.props.piPdfUrl,
            pdfName: '',
        };
        this.closePIModal = this.closePIModal.bind(this);
    }

    /**
     * validates file requirements
     */

    onFileChange(event) {
        const _this = this;
        _this.setState({ isError: false });
        const file = document.getElementById('pi-file');
        const last = file.files[0].name.substring(file.files[0].name.lastIndexOf('.') + 1, file.files[0].name.length);
        if (last != 'csv' && last != 'pdf' && last.toLowerCase() != 'json') {
            _this.setState({ isError: true, errorMsg: 'Invalid file format.  Please upload files in CSV, JSON or PDF format.' });
            _this.setState({ isValid: false });
            $(file).val('');
        } else {
            _this.setState({ isValid: true });
        }
    }

    /**
    * uploads the file
    * @param {object} event object
    */
    uploadFile(event) {
        const _this = this;
        console.log('upload file: ', this.props);
        const file = document.getElementById('pi-file');
        const pIds = [this.props.rowData.productId];
        const last = file.files[0].name.substring(file.files[0].name.lastIndexOf('.') + 1, file.files[0].name.length);

        // uploads file if file is pdf
        if (last === 'pdf') {
            const formdata = new FormData();
            formdata.append('files', file.files[0]);
            formdata.append('productIdLst', pIds);

            const headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json',
            };
            axios({
                method: 'post',
                url: require('../../common/configurations.js').product.importFilePIUploadpdf,
                headers: headers,
                data: formdata,
            })
                .then((response) => {
                    const data = response.data;
                    if (data.status) {
                        NotificationManager({ message: 'PI has been updated.', type: 1 });
                        _this.setState({ isValid: false, pdfUrl: data.data.pdfUrl, pdfName: data.data.pdfName, otherBtns: true });
                        document.getElementById('piupload-form').reset();
                    } else {
                        NotificationManager({ message: data.responseMessage, type: 4 });
                    }
                })
                .catch((error) => {
                    console.log('product2Container piUploadModal importFilePIUploadpdf  API call failed', error);
                    NotificationManager({ message: 'Failed to update PI.', type: 4 });
                });
        } else {
            // uploads file if file is not pdf
            const formdata = new FormData();
            formdata.append('files', file.files[0]);
            formdata.append('productIdLst', pIds);

            const headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json',
            };
            axios({
                method: 'post',
                url: require('../../common/configurations.js').product.importFilePIUpload,
                headers: headers,
                data: formdata,
            })
                .then((response) => {
                    const data = response.data;

                    if (data.status) {
                        NotificationManager({ message: data.responseMessage, type: 1 });
                        _this.setState({ isValid: false, pdfUrl: data.data.pdfUrl, pdfName: data.data.pdfName, otherBtns: true });
                        _this.props.piReceivedModalClose(true);
                    } else {
                        NotificationManager({ message: data.responseMessage, type: 4 });
                    }
                })
                .catch((error) => {
                    NotificationManager({ message: 'Failed to update PI.', type: 4 });
                });
        }
    }

    /**
     * closes the Pi modal
     * @param {string} key
     */
    closePIModal(key) {
        this.setState({ pdfUrl: '' });
        this.props.modalClose();
    }

    /**
    * downloads the file
    *
    */
    downloadFile() {
        // utils.openInNewTab(this.state.pdfUrl);
        window.open(this.state.pdfUrl);
    }

    /**
    * deletes the file
    *
    */
    deleteFile() {
        const pIds = [this.props.rowData.productId];
        const _this = this;
        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };


        ProductServices.deletePiForProduct(pIds)
            .then((response) => {
                const data = response.data;

                if (data.status) {
                    _this.setState({ pdfUrl: '', otherBtns: false });
                    NotificationManager({ message: data.responseMessage, type: 1 });
                } else {
                    NotificationManager({ message: data.responseMessage, type: 4 });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    componentWillReceiveProps(props) {
        this.setState({ pdfUrl: props.piPdfUrl });
    }

    /**
    * renders the component
    * @param {void} void
    * @return {*} returns the component
    */
    render() {
        return (
            <div>
                <div className="modal-header">
                    Import PI from file
                    <span className="close" onClick={() => this.closePIModal(false)}>x</span>
                </div>
                <div className="modal-content">
                    <div className="pi-upload-form">
                        <form id="piupload-form" encType="multipart/form-data" multiple>
                            <div className="form-container">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <input accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
                                            application/vnd.ms-excel,application/pdf" type="file" id="pi-file" onChange={this.onFileChange.bind(this)} />
                                            {this.state.isError &&
                                                <span className="error-msg">
                                                    {this.state.errorMsg}
                                                </span>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.pdfUrl && this.state.pdfName &&
                                <div>
                                    Name: {this.state.pdfName} <br />
                                    URL: {this.state.pdfUrl}
                                </div>
                            }
                            <div className="modal-footer">
                                <input type="button" disabled={(this.state.isValid) ? false : true} className="gray-button" value="Upload Records" onClick={this.uploadFile.bind(this)} />
                                <input type="button" disabled={(this.state.otherBtns) ? false : true} className="gray-button" value="Download" onClick={this.downloadFile.bind(this)} />
                                <input type="button" disabled={(this.state.otherBtns) ? false : true} className="gray-button" value="Delete" onClick={this.deleteFile.bind(this)} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default PiUploadModal;
