import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import JqxGrid from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';

import UserGroupEditModal from './helperComponents/userGroupEditModal';
import CancelModal from './helperComponents/cancelModal';
import AdminFooter from './helperComponents/adminFooter';

const UserGroupComponent = ({
    state,
    props,

    userGroupGridReference,
    gridData,
    gridHeight,
    editSettings,

    modalClose,
    groupEditPopupData,
    keysToConvert,
    parseForBackend,
    updateGridRow,

    applyChanges,
    cancelChanges,
    addGroupRow,
    addNewGroup,
}) => {
    return (
        <div>
            { state.isLoaded &&
            <div id="userGroupGrid">

                <JqxGrid
                    ref={userGroupGridReference}
                    width={'99%'}
                    source={gridData.dataAdapter}
                    pageable={false}
                    editable={false}
                    columnsResize={true}
                    columns={gridData.columns}
                    sortable={true}
                    autoRowHeight={false}
                    autoWidth={true}
                    height={gridHeight}
                    enabletooltips={true}
                    enableellipsis={true}
                    theme={'light'}
                    editSettings={editSettings}
                    columnsreorder={true}
                    columnsmenu={false}
                />

                { state.isEditModalOpen &&
                <UserGroupEditModal
                    isOpen={state.isEditModalOpen}
                    modalClose={modalClose}
                    groupEditPopupData={groupEditPopupData}
                    keysToConvert={keysToConvert}
                    parseForBackend={parseForBackend}
                    updateGridRow={updateGridRow}
                    user={props.user}
                />
                }

                <CancelModal
                    isOpen={state.isCancelModalOpen}
                    modalClose={modalClose}
                />

            </div>
            }

            <AdminFooter
                userCount={state.userTotalCount}
                isModified={state.isModified}
                applyChanges={applyChanges}
                cancelChanges={cancelChanges}
                addGroupRow={addGroupRow}
                addNewGroup={addNewGroup}
                user={props.user}
            />

        </div>
    );
};

UserGroupComponent.propTypes = {
    state: PropTypes.object,
    props: PropTypes.object,
};

export default withRouter(UserGroupComponent);
