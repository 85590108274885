import React from 'react';
import CancelModal from './cancelModal';
import axios from 'axios';
import NotificationManager from '../../common/components/notification';
import AdminServices from '../../admin/admin-services/admin-services';

class AddOrEdit extends React.Component {
    constructor(props) {
        super(props);
        let initialFormdata = {
            fieldName: '',
            width: 15,
            type: '',
            format: '',
            displayName: '',
        };

        if (props.title === 'edit') {
            initialFormdata = props.rowData;
        }


        this.state = {
            formData: props.rowData || initialFormdata,
            modifiedFormData: null,
            isDirty: false,
            errorMessage: '*Mandatory information marked with an asterisk is not accurate.',
            isModified: false,
            isModalOpen: false,
            // fieldTypes: {
            // 	number: ["Standard", "Integers","Currency"],
            // 	text: ["String"],
            // 	date: ["Date", "Date & Time"],
            // 	boolean: ["Checkbox", "Radio Button"]
            // },
            fieldTypes: {
                int: {
                    name: 'Number',
                    value: 'int',
                    format: ['Standard', 'Integers', 'Currency'],
                },
                string: {
                    name: 'Text',
                    value: 'String',
                    format: ['String'],
                },
                date: {
                    name: 'Date',
                    value: 'Date',
                    format: ['Date', 'Date & Time'],
                },
                boolean: {
                    name: 'Boolean',
                    value: 'boolean',
                    format: ['Checkbox'],
                },
            },
            currentFormatList: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.saveField = this.saveField.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.openModalCancel = this.openModalCancel.bind(this);
        this.modalClose = this.modalClose.bind(this);
    }

    componentDidMount() {
        const _this = this;

        if (_this.props.modalType === 'edit') {
            if (_this.state.fieldTypes[_this.state.formData.type.toLowerCase()]) {
                _this.setState({
                    currentFormatList: _this.state.fieldTypes[_this.state.formData.type.toLowerCase()].format,
                });
            }
        }
    }

    modalClose(key, modalType) {
        this.setState({ isModalOpen: false });
        switch (key) {
            case 'save':
                (modalType === 'add') ? this.saveField() : this.saveChanges();
                break;
            case 'no':
                this.props.modalClose();
                break;
        }
    }

    openModalCancel() {
        if (this.state.isModified) {
            this.setState({ isModalOpen: true });
        } else {
            this.props.modalClose();
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const numericregex = /^[0-9]+$/i;

        const { formData, modifiedFormData } = this.state;

        let newformData = Object.assign({}, formData);

        console.log('modifiedFormData:: ', modifiedFormData);

        if (modifiedFormData) {
            console.log('setting newform data to modifiedformdata');
            newformData = Object.assign({}, modifiedFormData);
        } else {
            console.log('setting newform data to original form data');
            newformData = Object.assign({}, formData);
        }

        if (value.length > 0) {
            if (name === 'displayName') {
                if (value.replace(/['"]+/g, '').trim().length > 0) {
                    // newformData[name] = value.replace(/ /g,'').replace(/['"]+/g, ''); // blocking "space", "quotes"
                    newformData.fieldName = value.replace(/ /g, '').replace(/['"]+/g, '').replace(/[^a-zA-Z0-9 ]/g, ''); // blocking "quotes"
                    newformData[name] = value.replace(/['"]+/g, ''); // blocking "quotes"
                } else {
                    newformData.fieldName = ''; // blocking "quotes"
                    newformData[name] = ''; // blocking "quotes"
                }
            } else if (name === 'type' || name === 'format') {
                if (value != '') {
                    newformData[name] = value;
                    if (name === 'type') {
                        this.setState({ currentFormatList: this.state.fieldTypes[value.toLowerCase()].format });
                    }
                }
            } else if (name === 'width' && numericregex.test(value)) {
                newformData[name] = value;
            }
        } else {
            // if (name === "name") {
            // 	// newformData[name] = value.replace(/ /g,'').replace(/['"]+/g, ''); // blocking "space", "quotes"
            // 	newformData.fieldName = value.replace(/ /g,'').replace(/['"]+/g, ''); // blocking "quotes"
            // 	newformData.displayName = value.replace(/['"]+/g, ''); // blocking "quotes"
            // } else {
            newformData[name] = value;
            // }
        }

        console.log('newformData object:: ', newformData);
        this.setState({ modifiedFormData: newformData, isModified: true, isDirty: false });
        // this.setState({ formData: newformData, isModified: true, isDirty: false })
    }

    saveField() {
        const _this = this;
        // let { formData } = this.state;

        const { modifiedFormData } = this.state;
        const nameregex = /^[A-Za-z0-9-_\s]+$/i;

        // let newformData = formData;
        const newformData = modifiedFormData;

        let flag = 0;
        let nameFlag = 0;
        let nameNumericFlg = 0;

        if ($('.form-control[type="text"]').val() == '') {
            _this.setState({ isDirty: true, errorMessage: '*Mandatory fields marked with an asterisk are missing.' });
            return;
        } else if (!nameregex.test(newformData.displayName)) {
            nameFlag++;
        } else if (newformData.displayName.match(/^\d/)) {
            nameNumericFlg++;
        }

        if (newformData.displayName.trim() === '' || newformData.format === '' ||
			newformData.type === '' || newformData.width === '' || parseInt(newformData.width) === 0) {
            flag++;
        }
        if (nameFlag) {
            if (newformData.displayName == '') {
                _this.setState({ isDirty: true, errorMessage: '*Mandatory fields marked with an asterisk are missing.' });
            } else {
                _this.setState({ isDirty: true, errorMessage: 'Only alphanumerics are allowed for the field "Name".' });
            }
        } else if (nameNumericFlg) {
            _this.setState({ isDirty: true, errorMessage: 'First charecter cannot be a number for the field "Name".' });
        } else if (flag > 0) {
            if (parseInt(newformData.width) === 0 && flag == 1) {
                _this.setState({ isDirty: true, errorMessage: 'Field length value must be greater than zero.' });
            } else {
                _this.setState({ isDirty: true, errorMessage: '*Mandatory fields marked with an asterisk are missing.' });
            }
        } else {
            const data = this.state.modifiedFormData;
            AdminServices.addField(data).then((response) => {
                const res = response;
                if (res.status) {
                    _this.props.addFieldRow(res.data);
                    NotificationManager({ message: res.responseMessage, type: 1 });
                } else {
                    NotificationManager({ message: res.responseMessage, type: 4 });
                }
                _this.props.modalClose();
            }).catch((error) => {
                console.log('UserGroup updateUserGroup API call failed', error);
            });
        }
    }

    saveChanges(data) {
        const _this = this;
        const editedData = [this.state.modifiedFormData];
        const nameregex = /^[A-Za-z0-9\s]+$/i;

        let flag = 0;
        let nameFlag = 0;
        let nameNumericFlg = 0;

        // parsedData has array of objects in backend required format
        const parsedData = [];

        editedData.forEach((item, index) => {
            const normalProps = _this.props.cloneTheKeys(this.props.parseKeys.normalKeys, item);
            const stringProps = _this.props.cloneTheKeys(this.props.parseKeys.stringKeys, item, true);
            const obj = normalProps;
            //make the roleName dynamic, get it in backend response
            obj[this.props.parseKeys.parseField] = JSON.stringify(stringProps);
            parsedData.push(obj);
        });

        if (!nameregex.test(editedData[0].displayName) && editedData[0].displayName.length > 0) {
            nameFlag++;
        } else if (editedData[0].displayName.match(/^\d/)) {
            nameNumericFlg++;
        }

        if (editedData[0].displayName === '' || editedData[0].format === '' ||
			editedData[0].type === '' || editedData[0].width === '' || parseInt(editedData[0].width) === 0) {
            flag++;
        }

        if (nameFlag) {
            if (newformData.displayName == '') {
                _this.setState({ isDirty: true, errorMessage: '*Mandatory fields marked with an asterisk are missing.' });
            } else {
                _this.setState({ isDirty: true, errorMessage: 'Only alphanumerics are allowed for the field "Name".' });
            }
        } else if (nameNumericFlg) {
            _this.setState({ isDirty: true, errorMessage: 'First charecter cannot be a number for the field "Name".' });
        } else if (flag > 0) {
            if (parseInt(editedData[0].width) === 0 && flag == 1) {
                _this.setState({ isDirty: true, errorMessage: 'Field length value must be greater than zero.' });
            } else {
                _this.setState({ isDirty: true, errorMessage: '*Mandatory fields marked with an asterisk are missing.' });
            }
        } else {
            AdminServices.updateFieldPermissions(parsedData).then((response) => {
                const res = response;
                if (res.responseCode == 200) {
                    NotificationManager({
                        message: res.responseMessage,
                        type: 1,
                    });
                    _this.props.modalClose();
                    //let index=_this.props.grid.getrowboundindexbyid(editedData[0].fpId);
                    _this.props.grid.setcellvalue(_this.props.rowIndex, 'displayName', editedData[0].displayName);
                    _this.props.grid.setcellvalue(_this.props.rowIndex, 'width', editedData[0].width);
                    _this.props.grid.setcellvalue(_this.props.rowIndex, 'format', editedData[0].format);
                    _this.props.grid.setcellvalue(_this.props.rowIndex, 'type', editedData[0].type);
                } else {
                    NotificationManager({
                        message: res.responseMessage,
                        type: 4,
                    });
                }
            }).catch((error) => {
                console.log('FieldPermissions AddOrEdit saveChanges API call failed', error);
                NotificationManager({
                    message: 'failed to update',
                    type: 2,
                });
            });
        }
    }

    render() {
        const formData = this.state.modifiedFormData || this.state.formData;
        const errorSpacefix = {
            'paddingTop': '10px',
        };

        const typesDropdown = Object.keys(this.state.fieldTypes).map((type, typeIdx) => {
            if (this.state.formData.type.toLowerCase() == type) {
                return <option key={typeIdx} value={this.state.fieldTypes[type].value} defaultValue>{this.state.fieldTypes[type].name}</option>;
            } else {
                return <option key={typeIdx} value={this.state.fieldTypes[type].value}>{this.state.fieldTypes[type].name}</option>;
            }
        });

        const formatsDropdown = this.state.currentFormatList.map((format, formatIdx) => {
            if (this.state.formData.format == format) {
                return <option key={formatIdx} value={format} defaultValue>{format}</option>;
            } else {
                return <option key={formatIdx} value={format}>{format}</option>;
            }
        });

        return (
            <div>
                <div className="modal-header">
                    {this.props.header}
                    <span className="close" onClick={this.openModalCancel}>x</span>
                </div>
                <div className="modal-content">
                    <form className="modal-form field-permissions-form">
                        <div className="form-body">
                            {/* <h4>{this.props.title}</h4> */}
                            {/* <hr /> */}
                            <fieldset className="form-group">
                                <label className="form-label">Name*</label>
                                <input type="text" name="displayName" className="form-control"
                                    // value={this.state.formData.displayName} onChange={this.handleChange} />
                                    value={formData.displayName} onChange={this.handleChange} />

                            </fieldset>
                            <fieldset className="form-group">
                                <label className="form-label">Type*</label>
                                {
                                    this.props.modalType === 'edit' ?
                                        <input type="text" name="type" className="form-control"
                                            value={formData.type} disabled />
                                        :
                                        <select name="type" className="form-fluid-select"
                                            value={formData.type} onChange={this.handleChange}>
                                            <option value="">Please select type</option>
                                            {typesDropdown}
                                        </select>
                                }

                                {/* <select name="type" className="form-fluid-select"
									// value={this.state.formData.type} onChange={this.handleChange}>
									value={formData.type} onChange={this.handleChange}>

									<option value="">Please select type</option>
									{typesDropdown}
								</select> */}
                            </fieldset>
                            <fieldset className="form-group">
                                <label className="form-label">Format*</label>
                                {
                                    this.props.modalType === 'edit' ?
                                        <input type="text" name="format" className="form-control"
                                            value={formData.format} disabled />
                                        :
                                        <select name="format" className="form-fluid-select"
                                            value={formData.format} onChange={this.handleChange}>
                                            <option value="">Please select format</option>
                                            {formatsDropdown}
                                        </select>
                                }

                                {/* <select name="format" className="form-fluid-select"
									// value={this.state.formData.format} onChange={this.handleChange}>
									value={formData.format} onChange={this.handleChange}>

									<option value="">Please select format</option>
									{formatsDropdown}
								</select> */}
                            </fieldset>
                            <fieldset className="form-group">
                                <label className="form-label">Field Width*</label>
                                <input type="text" name="width" className="form-control-auto-width"
                                    // value={this.state.formData.width} onChange={this.handleChange} maxLength={3} />
                                    value={formData.width} onChange={this.handleChange} maxLength={3} />

                                <span className="info-text">Default viewable field width(Max:999)</span>
                            </fieldset>
                        </div>
                        <div className="form-footer">
                            <div className="text-center">
                                <button type="button" className="gray-button" onClick={this.openModalCancel}>Cancel</button>
                                {(this.props.modalType === 'edit') ?
                                // <button type="button" className="gray-button" onClick={() => this.saveChanges([this.state.formData])}>Save</button> :
                                    <button type="button" className="gray-button" onClick={() => this.saveChanges([formData])}>Save</button> :
                                    <button type="button" className="gray-button" onClick={this.saveField}>Save</button>
                                }
                                {(this.state.isDirty) ? <span className="error-message" style={errorSpacefix}>{this.state.errorMessage}</span> : ''}
                            </div>
                        </div>
                    </form>
                </div>
                <CancelModal data={this.state.user} isOpen={this.state.isModalOpen} modalClose={this.modalClose} modalType={this.props.modalType} />
            </div>
        );
    }
}

export default AddOrEdit;
