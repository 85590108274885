import React from "react";
import ConfirmModal from '../../common/modals/confirmModal';

class Confirm extends React.PureComponent {
    render(){
        return(
            <div>
                <ConfirmModal isOpen={this.props.open}>
                    <div className="modal-content">
                        <h3 className="confirm-warning-text">Do you want to Save Changes?</h3>
                        <div className="modal-footer">
                            <input type="button" className="gray-button" value="Yes" onClick={() => this.props.closeModal('yes')}></input>
                            <input type="button" className="gray-button" value="No" onClick={() => this.props.closeModal('no')}></input>
                            <input type="button" className="gray-button" value="Cancel" onClick={() => this.props.closeModal('cancel')}></input>
                        </div>
                    </div>
                </ConfirmModal>
            </div>
        );
    }
}

export default Confirm;
