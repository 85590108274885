export const gridData = (state = [], action) => {
    switch (action.type) {
        case "ADD_GRID_DETAILS":
            return action.data;
        case "UPDATE_GRID_DATA":
            return [...state, ...action.updateData];
        case "REPLACE_OR_UPDATE_GRID_DATA":
            return updateOrReplaceData(state, action.newData);
        default:
            return  state;
    }
};

function updateOrReplaceData(state, newData){
    var newState = state.slice();
    var newProducts = [];
    newData.map((item, index) =>{
        let count = 0;
        for(var i = 0; i < newState.length; i++){
            let stateData = newState[i];
            if (item.productId === stateData.productId){
                stateData = item;
            }else{
                count += 1;
            }
        }

        if(count == newState.length){
            newProducts.push(item);
        }
    });

    return newState.concat(newProducts);
}

export const selectedRows = (state = [], action) => {
    switch (action.type) {
        case "UPDATE_SELECTED_ROWS":
            return action.data ;
        default:
            return  state;
    }
};

let addProductRowCount = 0;

export const addProductRow = (state = 0, action) => {
    switch (action.type) {
        case "ADD_PRODUCT_ROW":
            return ++addProductRowCount;
        default:
            return state;
    }
};

let deleteProductsCount = 0;
export const deleteSeletedProducts = (state = 0, action) =>{
    switch (action.type) {
        case "DELETE_SELECTED_PRODUCTS":
            return ++deleteProductsCount;
        default:
            return state;
    }
};
// maintaining the count just for reference when count prev state is
//varying with the current state then clone event will triggerred
let clonedProductCount = 0;
export const cloneSelectedProducts = (state = 0, action) => {
    switch (action.type) {
        case "CLONE_SELECTED_PRODUCTS":
            return ++clonedProductCount;
        default:
            return state;
    }
};

export const columnDetails = (state = [], action) =>{
    switch(action.type) {
        case "ADD_COLUMN_DETAILS":
            return action.data;
        default:
            return state;
    }
};

let gridDataChange = 0, gridColumnChange = 0;
export const gridDataUpdate = (state = {gridDataChange, gridColumnChange}, action) =>{
    switch(action.type) {
        case "GRID_DATA_CHANGED":
            return {
                gridDataChange: ++gridDataChange,
                gridColumnChange
            };
        case "GRID_COLUMN_CHANGED":
            return {
                gridDataChange,
                gridColumnChange: ++gridColumnChange
            };
        default:
            return state;
    }
};

export const gridDropDowns = (state = {}, action) =>{
    switch(action.type){
        case "ADD_GRID_DROP_DOWNS":
            return action.data;
        default:
            return state;
    }
};

export const columnResequence = (state = {isSaveNewView: false}, action) => {
    switch(action.type) {
        case "COLUMN_RESEQUENCE":
            return action.data;
        default:
            return state;
    }
};
