/*column width declaration
*/
const columnWidth = {
    message: 350,
    logged_time: 250,
    loggedTime: 250,
    type: 100,
};

/* Grid data builder is used to create the object format required for
 * Jqwidgets.
*/
function GridDataBuilder(res, pageName) {
    // dataField is used for providing type of data and its name.
    // columnList is used to define column name, type, and extra fields
    //  to define additional plugins inside the cell eg: drop down, calender ...,
    const dataField = [];
    const columnList = [];
    let primayKey = res.columnDetails[0].fieldName;

    //sort columndetails in Workflow pageName
    res.columnDetails.sort(function (a, b) {
        return a.order - b.order;
    });

    for (let i = 0; i < res.columnDetails.length; i++) {
        dataField.push({
            'name': res.columnDetails[i].fieldName,
            'type': res.columnDetails[i].type.toLowerCase(),
        });
        /* Enable the below condition after pk(primary key) availabel from backend
    *  in JSON response.
    */
        if (res.columnDetails[i].hasOwnProperty('pk') && res.columnDetails[i].pk) {
            primayKey = res.columnDetails[i].fieldName;
        }
        columnList.push(columnObj(res.columnDetails[i], res.maxDays));
    }


    /* columnobj function will  return the object fields
  *  based on the field type in list.
  * e.g. for date type and other types need to build the cellrender function.
  */
    function columnObj(list, maxDays) {
        const obj = {};
        obj.text = list.columnTitle;
        obj.dataField = list.fieldName;
        obj.width = (columnWidth[list.fieldName]) ? columnWidth[list.fieldName] : 150;
        obj.hidden = !list.visibility;
        return obj;
    }

    const source = {
        dataType: 'json',
        dataFields: dataField,
        id: primayKey,
        localdata: res.data,
    };

    const dataAdapter = new $.jqx.dataAdapter(source);

    return {
        dataAdapter: dataAdapter,
        columns: columnList,
    };
}

export default GridDataBuilder;
