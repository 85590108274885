import React, { Component } from 'react';
import Modal from '../../../modals/common-modal.js';
import ConfirmSubmit from './confirmSubmit.js';
import axios from 'axios';
import moment from 'moment';
import NotificationManager from '../../../common/components/notification';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate} from 'react-day-picker/moment';


class AddPOModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            channel: '',
            ecomPoDueDate: null,
            ecomPoReceivedDate: '',
            retailPoDueDate: null,
            retailPoReceivedDate: '',
            quantity: '1',
            poNumber: '',
            productId: null,
            vendorId: this.props.vendorId,
            isModalOpen: false,
            channelText: '',
        };
        this.AllFields = ['channel', 'ecomPoDueDate', 'ecomPoReceivedDate', 'retailPoDueDate', 'retailPoReceivedDate', 'quantity', 'vendorId'];
        this.modalDialog = 'SaveChangesCancel';
        this.handleChange = this.handleChange.bind(this);
        this.addPODetails = this.addPODetails.bind(this);
        this.formatDateValue = this.formatDateValue.bind(this);
        this.closeConfirmModal = this.closeConfirmModal.bind(this);
        this.confirmSaveChanges = this.confirmSaveChanges.bind(this);
        this.didValueChanged = this.didValueChanged.bind(this);
        this.closeAllModal = this.closeAllModal.bind(this);
        this.getTodayDate = this.getTodayDate.bind(this);
        this.handleChangeAllowOnlyNumber = this.handleChangeAllowOnlyNumber.bind(this);
        this.getTodayDateEditor = this.getTodayDateEditor.bind(this);
    }

    /**
    * Getter for the current date
    * @return {date} edited date
    */
    getTodayDateEditor() {
        let today = new Date();
        let dd = today.getDate();

        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }
        today = mm + '/' + dd + '/' + yyyy;
        return today;
    }

    /**
    * Getter for the current date
    * @return {date} current date
    */
    getTodayDate() {
        let today = new Date();
        let dd = today.getDate();

        let mm = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }
        today = yyyy + '-' + mm + '-' + dd;
        return today;
    }

    /**
    * closeConfirmModal closes the confirm popup
    */
    closeConfirmModal() {
        this.setState({ isModalOpen: false });
    }

    /**
    * openConfirmModal opens the confirm popup
    */
    openConfirmModal() {
        this.setState({ isModalOpen: true });
    }

    /**
    * Close all the modals
    */
    closeAllModal() {
        this.closeConfirmModal();
        this.props.modalClose();
    }

    /** Handlechange for channel dropdown
    * @param {event} event handler
    * @return {void} sets value to state
    */
    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const regex = /^[a-z0-9]+$/i;
        if (value.length > 0 && name === 'poNumber') {
            if (regex.test(value)) {
                this.setState({ [name]: value });
            }
            return false;
        }
        if (name === 'channel') {
            const channelText = $('#channel option:selected').text().toLowerCase();
            this.setState({ channelText: channelText });
        }

        this.setState({ [name]: value });
    }

    /**
     * handleChange check to see if only numbers have been passed
     * @param {*} event
     */
    handleChangeAllowOnlyNumber(event) {
        const target = event.target;
        const name = target.name;
        const re = /^[0-9\b]+$/;
        if (parseInt(target.value, 10) === 0 && name == 'quantity') {
            target.value = 1;
        }
        if (target.value == '' || re.test(target.value)) {
            this.setState({ [name]: target.value });
        }
    }

    /** Showing popup to ask confirmation to cancel(Yes/No/Cancel) */
    confirmSaveChanges() {
        if (this.didValueChanged()) {
            this.modalDialog = 'SaveChangesCancel';
            this.openConfirmModal();
        } else {
            this.props.modalClose();
        }
    }

    /** Checking if values are chagned
     * @return {date} checks if date has been changed
    */
    didValueChanged() {
        let isChanged = false;
        let prev;
        let next;
        const cd = new Date();
        const y = cd.getFullYear();
        let d = cd.getDate();
        let m = cd.getMonth() + 1;
        m = m < 10 ? '0' + m : m;
        d = d < 10 ? '0' + d : d;
        const cdformat = y + '-' + m + '-' + d;
        this.initState = {
            channel: '',
            ecomPoDueDate: (this.state.ecomPoDueDate == '') ? '' : cdformat,
            ecomPoReceivedDate: (this.state.ecomPoReceivedDate == '') ? '' : cdformat,
            retailPoDueDate: (this.state.retailPoDueDate == '') ? '' : cdformat,
            retailPoReceivedDate: (this.state.retailPoReceivedDate == '') ? '' : cdformat,
            quantity: '1',
            vendorId: this.props.vendorId,
        };
        let i;
        for (i = 0; i < this.AllFields.length; i++) {
            prev = this.state[this.AllFields[i]];
            next = this.initState[this.AllFields[i]];
            // converting prev and next to string if incase they are number or some other type
            // Reason: localeCompare is string method it will not work for other litteral types
            prev = prev !== null ? '' + prev : '';
            next = next !== null ? '' + next : '';
            if (prev.localeCompare(next) !== 0) {
                isChanged = true;
                break;
            }
        }
        return isChanged;
    }

    /** Sending data when add po is clicked to the backend
    * @param {event} e Add PO click event passed
    * @return {void} calls the backend
    */
    addPODetails(e) {
        if (e) {
            e.preventDefault();
        }
        const _this = this;
        let ecomPoDueDate = '';
        let ecomPoReceivedDate = '';
        let retailPoDueDate = '';
        let retailPoReceivedDate = '';

        if (this.state.channelText == 'ecom') {
            ecomPoDueDate = this.state.ecomPoDueDate;
            ecomPoReceivedDate = this.getTodayDate();
        }
        if (this.state.channelText == 'retail') {
            retailPoDueDate = this.state.retailPoDueDate;
            retailPoReceivedDate = this.getTodayDate();
        }

        this.state.ecomPoDueDate = ecomPoDueDate;
        this.state.ecomPoReceivedDate = ecomPoReceivedDate;
        this.state.retailPoDueDate = retailPoDueDate;
        this.state.retailPoReceivedDate = retailPoReceivedDate;

        let error = false;
        if (this.state.channel == '' || this.state.vendorId == '' || this.state.poNumber == '' || isNaN(parseInt(this.state.quantity, 10))) {
            error = true;
        }
        if (error) {
            NotificationManager({ message: 'Please enter all fields', type: 4 });
            return;
        }


        const payload = {};
        payload.channel = this.state.channel;
        payload.poDueDate = ecomPoDueDate || retailPoDueDate;
        payload.poReceivedDate = ecomPoReceivedDate || retailPoReceivedDate;
        payload.quantity = this.state.quantity;
        payload.poNumber = this.state.poNumber;
        payload.productId = this.props.productId;
        payload.vendorId = this.state.vendorId;
        payload.isModalOpen = this.state.isModalOpen;
        if (this.state.channelText == 'retail') {
            payload.channelText = 'Retail';
        } else {
            payload.channelText = this.state.channelText;
        }

        let currentDate = moment(new Date()).format('YYYY-MM-DD');
        currentDate = new Date(currentDate);

        const payloadDate = new Date(payload.poDueDate);

        if (payloadDate.getTime() + 30000 < currentDate.getTime()) { // payloadDate is less than current Date
            NotificationManager({ message: 'The chosen date is in the past, please choose a valid date', type: 4 });
            return;
        }

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };
        axios({
            method: 'post',
            url: require('../../../common/configurations.js').product2.addorupdatepo,
            headers: headers,
            data: payload,
        })
            .then( (response) => {
                const res = response.data;

                if (res.status) {
                    NotificationManager({ message: 'PO created successfully', type: 1 });
                    _this.props.api.forEachNode(function(row) {
                        if (row.data.parentProductId == payload.productId || row.data.intProdId == payload.productId || row.data.chldProdId == payload.productId) {
                            if (ecomPoDueDate) {
                                row.data.ecomPoOnOrder = res.responseData.ecomPoOnOrder;
                                row.data.ecomPoDueDate = res.responseData.ecomPoDueDate;
                                row.setData(row.data);
                            }
                            if (retailPoDueDate) {
                                row.data.retailPoDueOrder = res.responseData.retailPoDueOrder;
                                row.data.retailPoDueDate = res.responseData.retailPoDueDate;
                                row.setData(row.data);
                            }
                        }
                    });

                    _this.props.modalClose();
                } else {
                    NotificationManager({ message: res.message, type: 4 });
                }
            })
            .catch( (error) => {
                NotificationManager({message: error, type: 4});
            });
    }

    /** Formatting date value
     * @param {date} val date value
     * @return {date} formats date value
     */
    formatDateValue(val) {
        const dateArr = val.split('/');
        return dateArr[2] + '-' + dateArr[0] + '-' + dateArr[1];
    }

    /** Handle change event from click action
    * @param {event} e handleChange event passed
    */
    handleOnChange(selectedDay, modifiers, dayPickerInput) {
        // let d = new Date(e.target.value);
        const input = dayPickerInput.getInput();

        if (dayPickerInput.props.className == 'ecomdate') {
            this.setState({ecomPoDueDate: selectedDay});
        }
        if (dayPickerInput.props.className == 'retaildate') {
            this.setState({retailPoDueDate: selectedDay});
        }
    }

    render() {
        const channelForProduct = this.props.productChannelList;
        const channelList = this.props.channelsList();
        const modalClass = 'ConfirmSubmitModal';
        const valarray = channelForProduct;
        const obj = [];
        let i;

        for (i = 0; i < channelList.length; i++) {
            if (valarray.includes(channelList[i]['fieldId'])) {
                obj.push(channelList[i]);
            }
        }


        return (
            <div>
                <div className="modal-header">
        Add PO  (SWEFT ID:{this.props.productId})
                    <span className="close" onClick={this.confirmSaveChanges}>x</span>
                </div>
                <div className="modal-content">
                    <div className="add-copy modal-form">
                        <form className="AddPODetails" name="AddPODetails" method="post" onSubmit={this.addPODetails}>
                            <div className="form-container scroll">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Channel</label>
                                            <select name="channel" id="channel" onChange={this.handleChange}>
                                                <option value="">Select Channel</option>
                                                {obj &&
                          obj.map((val, i) => {
                              return <option key={i} value={val.fieldId}>{val.fieldName}</option>;
                          })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">PO Number</label>
                                            <input type="text" placeholder="Add PO Number" className="form-control col-12" name="poNumber" maxLength="15" onChange={this.handleChange} value={this.state.poNumber} />
                                        </div>
                                    </div>
                                </div>
                                {this.state.channelText == 'ecom' &&
                  <div>
                      <div className="row">
                          <div className="col-12">
                              <div className="form-group">
                                  <label className="form-label">Ecom PO due date</label>
                                  <DayPickerInput className='ecomdate' formatDate={formatDate} parseDate={parseDate} placeholder={'MM-DD-YYYY'} value={this.state.ecomPoDueDate} onDayChange={this.handleOnChange.bind(this)} />
                              </div>
                          </div>
                      </div>

                  </div>
                                }
                                {
                                    this.state.channelText == 'retail' &&
                  <div>
                      <div className="row">
                          <div className="col-12">
                              <div className="form-group">
                                  <label className="form-label">Retail PO due date</label>
                                  <DayPickerInput className='retaildate' formatDate={formatDate} parseDate={parseDate} placeholder={'MM-DD-YYYY'} value={this.state.retailPoDueDate} onDayChange={this.handleOnChange.bind(this)} />
                              </div>
                          </div>
                      </div>

                  </div>
                                }
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Quantity</label>
                                            <input type="text" className="form-control col-12" name="quantity" maxLength="4" value={this.state.quantity} onChange={this.handleChangeAllowOnlyNumber} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Vendor Id</label>
                                            <input type="text" className="form-control col-12" disabled value={this.props.vendorId} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer text-center">
                                <input type="submit" className="gray-button saveBtn" title="Save" value="Save" />
                                <input type="button" className="gray-button" title="Cancel" value="Cancel" onClick={this.confirmSaveChanges} />
                            </div>
                        </form>
                    </div>
                </div>
                {this.state.isModalOpen &&
          <Modal isOpen={this.state.isModalOpen} addClass={modalClass}>
              <ConfirmSubmit dialog={this.modalDialog} modalClose={this.closeConfirmModal} yesSaveChange={this.addPODetails} noSaveChange={this.closeAllModal} />
          </Modal>
                }
            </div>
        );
    }
}
export default AddPOModal;
