import React from 'react';

class MoveSampleModal extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            isOpen:this.props.isOpen
        };
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
    }
    close(){
        this.setState({isOpen:false});
    }

    open(){
        this.setState({isOpen:true});
    }
    render(){
        var Locations  = ['My Location', 'PR', 'Sample Library', 'Store', 'Studio', 'Turnover Meeting', 'Warehouse'],
            MakeItem = function(x) {
                return <option value={x}>{x}</option>;
            };

        return (
            <div className={`Sample-SimpleModal ${(this.state.isOpen) ? "open movesample" : ""}`}>
                <h3>MOVE SAMPLE TO:</h3>
                <div className="field-data">
                    <label>
                        <select className="sample-location">{Locations.map(MakeItem)}</select>
                    </label>
                </div>
                <div className="field-data">
                    <button type="button" className="gray-button" onClick={this.props.close}>Cancel</button>
                    <a className="sample-btn ok" onClick={this.props.moveSample}>Ok</a>
                </div>
            </div>
        );
    }
}

export default MoveSampleModal;
