import React from 'react';


class CopyDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRowData: [],
            disabled: true,
        };
    }


    componentWillReceiveProps(nextProps) {
        const _this = this;
        console.log('copywriting CopyDetails componentWillReceiveProps', nextProps, this.props, this.state);
        if (nextProps.selectedRowData && _this.props.selectedRowData && nextProps.selectedRowData.productId != _this.props.selectedRowData.productId) {
            _this.setState({ selectedRowData: nextProps.selectedRowData });
        }
    }


    render () {
        console.log('copywriting CopyDetails render', this.props, this.state);
        const data = this.state.selectedRowData;
        const disabled = this.state.disabled;

        return(
            <div className="cw-top-section">
                <div className="form-container scroll">
                    <div className="cw-6">

                        <div className="row">
                            <label className="cw-label">Description</label>
                            <input type="text" disabled={disabled} className="form-control cw-input" name="prodName" defaultValue={data && data.prodName ? data.prodName : ''} />
                        </div>

                        <div className="row-small">
                            <label className="cw-label">Style Number</label>
                            <input type="text" disabled={disabled} className="form-control cw-input" name="styleNumber" defaultValue={data && data.styleNumber ? data.styleNumber : ''} />
                        </div>

                        <div className="row-small">
                            <label className="cw-label">Vendor Name</label>
                            <input type="text" disabled={disabled} className="form-control cw-input" name="vendorName" defaultValue={data && data.vendorName ? data.vendorName : ''} />
                        </div>

                        <div className="row-small">
                            <label className="cw-label">Direct Style</label>
                            <input type="text" disabled={disabled} className="form-control cw-input" name="directStyle" defaultValue={data && data.directStyle ? data.directStyle : ''} />
                        </div>

                        <div className="row-small">
                            <label className="cw-label">Vendor Style</label>
                            <input type="text" disabled={disabled} className="form-control cw-input" name="vendorStyle" defaultValue={data && data.vendorStyle ? data.vendorStyle : ''} />
                        </div>

                        <div className="row-small">
                            <label className="cw-label">SweftID</label>
                            <input type="text" disabled={disabled} className="form-control cw-input" name="productId" defaultValue={data && data.productId ? data.productId : ''} />
                        </div>

                        <div className="row-small">
                            <label className="cw-label">Vendor Code</label>
                            <input type="text" disabled={disabled} className="form-control cw-input" name="vendorCode" defaultValue="" />
                        </div>


                    </div>
                    <div className="cw-3">
                        <div className="row">
                            <label className="cw-label">Color</label>
                            <input type="text" disabled={disabled} className="form-control cw-input" name="color" defaultValue={data && data.color_attribute ? data.color_attribute : ''} />
                        </div>

                        <div className="row">
                            <label className="cw-label">Other Colors</label>
                            <input type="text" disabled={disabled} className="form-control cw-input" name="otherColor" defaultValue="" />
                        </div>

                        <div className="row">
                            <label className="cw-label">Feature Color</label>
                            <input type="text" disabled={disabled} className="form-control cw-input" name="featureColor" defaultValue={data && data.featureColor ? data.featureColor : ''} />
                        </div>

                        <div className="row">
                            <label className="cw-label">Brand / Label</label>
                            <input type="text" disabled={disabled} className="form-control cw-input" name="brandLabel" defaultValue="" />
                        </div>
                    </div>
                    <div className="cw-3">
                        <div className="row">
                            <label className="cw-label">Retail Price</label>
                            <input type="text" disabled={disabled} className="form-control cw-input" name="retailPrice" defaultValue={data && data.retailPrice ? data.retailPrice : ''} />
                        </div>

                        <div className="row">
                            <label className="cw-label">Web Price</label>
                            <input type="text" disabled={disabled} className="form-control cw-input" name="onlinePrice" defaultValue={data && data.onlinePrice ? data.onlinePrice : ''} />
                        </div>

                        <div className="row">
                            <label className="cw-copyStatus">Copy Status: </label>
                            <div className="cw-copyStatus">{data && data.copyStatus ? data.copyStatus : 'Incomplete'}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default CopyDetails;
