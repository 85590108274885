import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { connect } from 'react-redux';

import * as copywritingActions from './redux/leftDrawerActions';

class CopywritingSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            columnDefs: [],
            rowData: [],
            defaultColDef: {
                // set every column width
                width: 75,
                editable: false,
                sortable: true,
                filter: false,
                suppressMenu: true,
                overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Loading</span>',
                overlayNoRowsTemplate: '<span style="padding: 10px; font-size: 28px; font-weight:\'lighter\' ; '
                + 'color: #525E61;">Please Select a list in the Reporting Panel</span>',
            },
            selectedRowData: null,
        };

        this.colDefs = this.colDefs.bind(this);
    }

    componentDidMount() {
        const setColdefs = this.colDefs();
        const data = this.props.leftDrawerState.copywritingGridData.data;

        console.log('copywriting Grid componentDidMount', this.props, this.state, setColdefs);

        this.setState({ columnDefs: setColdefs, rowData: data });
    }

    componentDidUpdate(prevProps, prevState) {
        const _this = this;

        console.log('copywriting Grid componentDidUpdate', this.props, prevProps, this.state, prevState);

        if (prevProps.leftDrawerProps.currentPage != _this.props.leftDrawerProps.currentPage && _this.props.leftDrawerProps.currentPage != 'copywriting') {
            _this.props.removeCopywritingGrid();
        }
    }

    componentWillUnmount() {
        this.props.copyWritingGridSelectedRow([]);
    }

    /**
     * Converts date to the MM/DD/YY format for the grid
     * @param {string} date
     */
    convertDate(date) {
        const newDate = moment(new Date(date)).format('MM/DD/YYYY');

        return newDate;
    }

    /**
     * Converts the number value to USD format for the grid
     * @param {int} number
     */
    convertCurrency(number) {
        const usdFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        });

        return <div style={{textAlign: 'right'}}>{usdFormatter.format(number)}</div>;
    }

    /**
     * Creates the coldefs for the grid to render
     */
    colDefs() {
        const newColDefs = [];

        newColDefs.push({
            headerName: 'Images',
            headerTooltip: 'Images',
            colId: 'images',
            field: 'images',
            cellRendererFramework: (params) => {
                if (params.value && params.value.digitalAssetInfos) {
                    const imagePath = params.value.baseUrl + params.value.digitalAssetInfos[0].digitalAssetSummary.thumbnailImage;
                    return <img className='cw-center-image' width='50' height='100%' alt={params.value.digitalAssetInfos[0].fileName} src={imagePath} />;
                }

                return '';
            },
        });

        newColDefs.push({
            headerName: 'Item Description',
            headerTooltip: 'Item Description',
            colId: 'prodName',
            field: 'prodName',
            width: 150,
        });

        newColDefs.push({
            headerName: 'Color',
            headerTooltip: 'Color',
            colId: 'color_attribute',
            field: 'color_attribute',
        });

        newColDefs.push({
            headerName: 'Item Number',
            headerTooltip: 'Item Number',
            colId: 'webStyle',
            field: 'webStyle',
        });

        newColDefs.push({
            headerName: 'Sub Class',
            headerTooltip: 'Sub Class',
            colId: 'subClassColumnName',
            field: 'subClassColumnName',
        });

        newColDefs.push({
            headerName: 'Vendor Name',
            headerTooltip: 'Vendor Name',
            colId: 'vendorName',
            field: 'vendorName',
        });

        newColDefs.push({
            headerName: 'Copy Level',
            headerTooltip: 'Copy Level',
            colId: 'copyLevel',
            field: 'copyLevel',
        });

        newColDefs.push({
            headerName: 'Retail Price',
            headerTooltip: 'Retail Price',
            colId: 'retailPrice',
            field: 'retailPrice',
            cellRendererFramework: (params) => {
                if (params.value) {
                    const setCurrency = this.convertCurrency(params.value);

                    return setCurrency;
                }

                return '';
            }
        });

        newColDefs.push({
            headerName: 'PI Approved Date',
            headerTooltip: 'PI Approved Date',
            colId: 'piApprovedDate',
            field: 'piApprovedDate',
            cellRendererFramework: (params) => {
                if (params.value) {
                    const setDate = this.convertDate(params.value);

                    return setDate;
                }

                return '';
            }
        });

        newColDefs.push({
            headerName: 'Target Date',
            headerTooltip: 'Target Date',
            colId: 'targetDate',
            field: 'targetDate',
            cellRendererFramework: (params) => {
                if (params.value) {
                    const setDate = this.convertDate(params.value);

                    return setDate;
                }

                return '';
            }
        });

        newColDefs.push({
            headerName: 'Venue',
            headerTooltip: 'Venue',
            colId: 'seasonName',
            field: 'seasonName',
        });

        newColDefs.push({
            headerName: 'Size',
            headerTooltip: 'Size',
            colId: 'size_attribute',
            field: 'size_attribute',
        });

        return newColDefs;
    }

    onGridReady(params) {
        console.log('copywriting Grid onGridReady', params);
    }

    onRowClicked(params) {
        console.log('copywriting Grid onRowClicked', params);

        this.setState({ selectedRowData:params.data });

        this.props.copyWritingGridSelectedRow(params.data);
    }

    render() {
        console.log('copywriting Grid render', this.state);

        return (
            <div>
                { this.state.columnDefs && this.state.rowData &&
                <div>
                    { this.props.leftDrawerProps.leftDrawerPulled ?
                        <div className="copywriting-grid-container">
                            <div className="drawer-title">
                            Search Results
                                <span className="close-btn">
                                    <i className="icon-close" onClick={this.props.leftDrawerProps.toggleLeftDrawer}></i>
                                </span>
                            </div>

                            <div
                                id="copywritingAgGrid"
                                className="ag-theme-balham"
                                style={{
                                    height: 'calc(100% - 95px)',
                                }}
                            >
                                <AgGridReact
                                    columnDefs={this.state.columnDefs}
                                    defaultColDef={this.state.defaultColDef}
                                    rowData={this.state.rowData}
                                    onGridReady={this.onGridReady.bind(this)}
                                    rowSelection={'single'}
                                    overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                                    overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                    enableColResize={true}
                                    rowHeight={50}
                                    onRowClicked={this.onRowClicked.bind(this)}
                                />
                            </div>

                            <div style={{height: '50px'}} className='copywriting-grid-footer'>
                                <button className="copywriting-grid-footer-button" onClick={this.props.removeCopywritingGrid}><div className="footer-arrow">&larr;</div> Search</button>
                            </div>

                        </div>

                        :

                        <div></div>
                    }
                </div>
                }
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        copyWritingGridSelectedRow: (data) => {
            dispatch(copywritingActions.copyWritingGridSelectedRow(data));
        }
    };
};

export default connect (null, mapDispatchToProps) (CopywritingSection);
