import React from 'react';
import PageHeaderComponent from './pageHeaderComponent';
import './pageHeader.css';
import axios from 'axios';
import DashboardServices from '../dashboard/services/dashboard-services';

class PageHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fullScreen: false,
            isShowRecentMessage: false,
            allRolesList: null,
            showRolesDropDown: false,
            selectedOption: this.props.user,
            isOpenedBase: false,
        };

        this.enableFullScreen = this.enableFullScreen.bind(this);
        this.toggleRecentMessage = this.toggleRecentMessage.bind(this);
        this.toggleRolesDropDown = this.toggleRolesDropDown.bind(this);
        this.loadAllRoles = this.loadAllRoles.bind(this);
    }

    componentDidMount() {
        this.loadAllRoles();

        $(document).on('click', 'body', (event) => {
            if ($(event.target).is('.notification-message')) {
                this.setState({ isShowRecentMessage: true });
            } else
            if (!$(event.target).closest('.view-recent-messages').length && !$(event.target).is('.recentmessage-dropdown')) {
                this.setState({ isShowRecentMessage: false });
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        console.log('componentDidUpdate pageHeader', this.state);
    }

    toggleRecentMessage(e) {
        e.preventDefault();
        this.setState({ isShowRecentMessage: !this.state.isShowRecentMessage });
    }

    toggleRolesDropDown() {
        this.setState({ showRolesDropDown: !this.state.showRolesDropDown });
    }

    componentWillReceiveProps(nextProps) {
        console.log('page header componentWillReceiveProps', this.props, nextProps);

        if (this.state.fullScreen == true && nextProps.leftDrawerPulled == true) {
            if ($('.footer').hasClass('footerExpandClassFix-PulledDrawerRight')) {
                $('.footer').removeClass('footerExpandClassFix-PulledDrawerRight');
            }

            $('.footer').removeClass('footerExpandClassFix');
            $('.footer').addClass('footerExpandClassFix-PulledDrawer');
        } else if (this.state.fullScreen == true && nextProps.rightDrawerPulled == true) {
            if ($('.footer').hasClass('footerExpandClassFix-PulledDrawer')) {
                $('.footer').removeClass('footerExpandClassFix-PulledDrawer');
            }

            $('.footer').removeClass('footerExpandClassFix');
            $('.footer').addClass('footerExpandClassFix-PulledDrawerRight');
        } else {
            $('.footer').removeClass('footerExpandClassFix-PulledDrawer');
            $('.footer').removeClass('footerExpandClassFix-PulledDrawerRight');
        }

        if (nextProps.leftDrawerPulled == false && nextProps.rightDrawerPulled == false && this.state.fullScreen == true) {
            $('.footer').addClass('footerExpandClassFix');
        }
    }

    enableFullScreen(e) {
        e.preventDefault();

        $('.expand-screen-parent').prepend('<div id="stopSpamming"></div>');

        this.setState({fullScreen: !this.state.fullScreen});

        if (this.props.leftDrawerPulled) {
            this.props.toggleLeftDrawer();
        }

        if (this.props.rightDrawerPulled) {
            this.props.toggleRightDrawer();
        }

        if ($('.left-nav-content').width() > 90) {
            $('.left-nav-content').width(0);
        } else {
            $('.left-nav-content').width(100);
        }

        if ($('.sidenav').width() > 90) {
            $('.sidenav').width(0);
        } else {
            $('.sidenav').width(100);
        }

        $('.leftNav-logo').slideToggle();

        $('.page-header-logo').fadeToggle();

        $('.icon-expand').toggleClass('icon-collapse');

        console.log($('.right-content').css('margin-left'));

        if ($('.right-content').css('margin-left') === '100px') {
            $('.right-content').css('margin-left', '0px');
        } else {
            $('.right-content').css('margin-left', '100px');
        }

        console.log($('.footer').css('left'));

        if ($('.footer').hasClass('footerExpandClassFix')) {
            $('.footer').removeClass('footerExpandClassFix');
        } else {
            $('.footer').addClass('footerExpandClassFix');
        }

        setTimeout(function() {
            $('.jqx-grid').trigger('resize');
            $('#stopSpamming').remove();
        }, 300);

        return;
    }

    loadAllRoles() {
        let headers = {
            'Authorization': this.props.user?.token,
            'content-type': 'application/json',
        };

        DashboardServices.getSuperiorData({ id: this.props.user?.userId }).then( (response) => {
            if (response) {
                this.setState({ allRolesList: response });
            }
        })
            .catch( error => {
                console.log('pageHeader loadAllRoles API call failed ');
            });
    }

    render() {
        let currentPage = this.props.currentPage;

        console.log('current page is: ', currentPage);

        let currentPageTitle = currentPage == 'product2' ? 'product' : currentPage;
        let currentPageTitle2 = currentPageTitle == 'publication' ? 'Picture Pack' : currentPageTitle;

        let currentPage2 = currentPage == 'product' ? 'product2' : currentPage;

        return (
            <PageHeaderComponent
                state={this.state}
                props={this.props}
                toggleRecentMessage={this.toggleRecentMessage}
                toggleRolesDropDown={this.toggleRolesDropDown}
                enableFullScreen={this.enableFullScreen}
                currentPage={currentPage2}
                currentPageTitle={currentPageTitle2}
            />
        );
    }
}

export default PageHeader;
