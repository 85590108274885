import React from 'react';
import Profile from '../userProfile/profileContainer';
import CommonModal from '../modals/common-modal.js';
import Help from '../help/help';
import UserSettingsForm from './userSettings';

class LeftNavModalContainer extends React.Component {
    constructor(props) {
        super(props);
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.isOpen == true) {
            $(".sidenav").addClass("sidenav-popupFix");
        } else {
            $(".sidenav").removeClass("sidenav-popupFix");
        }
    }

    render() {
        let modalContent = '';
        if (this.props.modalName == 'help') {
            modalContent = <Help modalClose={this.props.modalClose} userId={this.props.userId} />;
        }
        else if (this.props.modalName == 'profile') {
            modalContent = <Profile modalClose={this.props.modalClose} user={this.props.user} {...this.props}/>;
        }
        // else {
        // 	modalContent = <UserSettingsForm modalClose={this.props.modalClose} userId={this.props.userId} />;
        // }

        return (
            <CommonModal isOpen={this.props.isOpen} addClass={(this.props.modalName == 'help') ? 'help-model' : ''}>
                {modalContent}
            </CommonModal>
        );
    }
}

export default LeftNavModalContainer;
