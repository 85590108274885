export const updateView = (data) => {
    return {
        type: "USER_VIEW_UPDATE",
        data
    };
};

export const addNewAlert = (data) => {
    return {
        type: "ADD_NEW_ALERT",
        data

    };
};

export const rulesForAlerts = (data) => {
    return {
        type: "ADD_RULES_FOR_ALERTS",
        data
    };
};

export const specialityData = (data) => {
    return {
        type: "ADD_SPECIALITY_DATA",
        data
    };
};
