import React, { Component } from 'react';
import Modal from './common-modal.js';
import CancelModal from './cancelModal.js';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import JqxDefaultDropDownList from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxdropdownlist';

import axios from 'axios';

import NotificationManager from '../common/components/notification';

import { ProductServices } from '../product2/services/product-services';
import CreativeServices from '../creative/services/creative-services';
import MarketingServices from '../marketing/services/marketing-services';
import { BrowserMultiFormatReader } from '@zxing/library';

class ScanModal extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen}>
                <ScanPopupForm {...this.props}
                    modalClose={this.props.modalClose} />
            </Modal>
        );
    }
}

class ScanPopupForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            scanData: {
                action: "0",
                actionedProduct: "",
                scanner: JSON.parse(localStorage.getItem("user")).firstName + ' ' + JSON.parse(localStorage.getItem("user")).lastName,
                isSample: false
            },
            isDirty: false,
            errorMessage: '',
            isModified: false,
            isModalOpen: false,
            actionDropdown: [],
            dropdownOptionSource: [],
            secondDropdownVals: [],
            locationDropdown: [],
            photographerDropdown: [],
            retoucherDropdown: [],
            campaignDropdown: [],
            currentDropdown: null,
            showVideo: false,
        };

        this.openModalCancel = this.openModalCancel.bind(this);
        this.modalClose = this.modalClose.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.getScannActionDropdownData = this.getScannActionDropdownData.bind(this);
        this.saveScanData = this.saveScanData.bind(this);
        this.formPreventDefault = this.formPreventDefault.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.createScanActionDropdown = this.createScanActionDropdown.bind(this);
        this.renderScanActionDropdown = this.renderScanActionDropdown.bind(this);
        this.renderLocationDropdown = this.renderLocationDropdown.bind(this);
        this.bindDropDownSelectEvent = this.bindDropDownSelectEvent.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.dropdownValueChanged = this.dropdownValueChanged.bind(this);
    }

    handleKeyPress(e) {
        if (e.key === "Enter") {
            this.saveScanData();
        }
    }

    formPreventDefault(e) {
        e.preventDefault();
    }

    componentDidMount() {
        this.getScannActionDropdownData();

        // getting all the dropdown values
        this.setLocationDropdown();
        this.getPhotographerAndRetoucher();
        this.getCampaigns();
    }

    componentDidUpdate() { }

    bindDropDownSelectEvent(_this, dropDownList, stateName) { }

    getScannActionDropdownData() {
        var _this = this;

        let headers = {
            'Authorization': JSON.parse(localStorage.getItem("user")).token,
            'content-type': 'application/json'
        };

        axios({
            method: 'post',
            url: require('../common/configurations.js').admin.getDropdownData,
            headers: headers,
            data: { "id": 'SCAN-ACTION' }
        })
            .then((response) => {
                let res = response.data;

                _this.setState({ actionDropdown: res.data });
                _this.createScanActionDropdown();
            })
            .catch(error => {
                console.log('productContainer helperComponents footer API call failed', error);
            });
    }

    getPhotographerAndRetoucher() {
        CreativeServices.gridData().then((response) => {
            console.log('scan action dropdown API call response', response);

            this.setState({
                photographerDropdown: response.responseData.dropdowns.photographer,
                retoucherDropdown: response.responseData.dropdowns.retoucher,
            });
        }).catch((error) => {
            console.log('scan action dropdown API call failed', error);
        });
    }

    getCampaigns() {
        let newArr = [];

        MarketingServices.getGridData().then((response) => {
            console.log('marketing dropdown scan', response);
            response.data.map((i) => {
                newArr.push(i.eventDesc);
            });

            this.setState({ campaignDropdown: newArr });
        }).catch((error) => {
            console.log('scan action dropdown API call failed', error);
        });
    }

    modalClose(key) {
        this.setState({ isModalOpen: false });

        switch (key) {
            case 'save':
                this.saveScanData();
                break;

            case 'no':
                this.props.modalClose();
                break;
        }
    }

    openModalCancel() {
        if (this.state.isModified) {
            this.setState({ isModalOpen: true });
        } else {
            this.props.modalClose();
        }
    }

    handleChange(event) {
        const target = event.target;

        let value = target.type === 'checkbox' ? target.checked : target.value;

        const name = target.name;

        if (value.trim() === "" || value.trim() === undefined || value.trim() === "0") {
            target.className += ' invalid';
        } else {
            target.classList.remove('invalid');
        }

        let { scanData } = this.state;
        let newScan = scanData;

        var regex = /^[A-Za-z0-9- ]+$/i;
        if (regex.test(value) || value === "") {
            newScan[name] = value;
        }

        if (name === 'action' && value !== 'Receive Sample to this Location') {
            newScan['location'] = '0';
        }

        if (name === 'action' && (value === 'Request Sample Move' || value === 'Receive Sample to this Location')) {
            newScan['isSample'] = true;
        } else if (name === 'action' && value !== 'Request Sample Move' && value !== 'Receive Sample to this Location') {
            newScan['isSample'] = false;
        }

        this.setState({ scanData: newScan, isModified: true });

        if (name === 'action' && value !== '0') {
            $('#actionProduct').focus();
        }
    }

    saveScanData() {
        var _this = this;
        let { scanData } = this.state;

        let newscanData = scanData;
        let actionedProduct = $('#actionProduct').val();

        newscanData['actionedProduct'] = actionedProduct;

        this.setState({ scanData: newscanData, isModified: true });

        var flag = 0;

        if (flag === 0) {
            var req = JSON.stringify(newscanData);

            let headers = {
                'Authorization': JSON.parse(localStorage.getItem("user")).token,
                'content-type': 'application/json'
            };

            ProductServices.saveScanner(req)
                .then((response) => {
                    console.log('productContainer helperComponents footer API call success data: ', response.data);

                    let res = response.data;

                    if (res.status) {
                        _this.setState({
                            scanData: {
                                action: newscanData['action'],
                                actionedProduct: "",
                                scanner: newscanData['scanner'],
                                location: newscanData['location'],
                                isSample: newscanData['isSample']
                            },
                            errorMessage: '',
                            isModified: false,
                        });

                        this.clickToScan();

                        NotificationManager({
                            message: res.message,
                            type: 1
                        });
                    } else {
                        _this.setState({
                            isDirty: true,
                            errorMessage: res.message
                        });
                    }
                })
                .catch(error => {
                    console.log('productContainer helperComponents footer API call failed', error);
                });

            return false;
        }
    }

    createScanActionDropdown() {
        let dropOptionSource = [];

        if (this.state.actionDropdown.length > 0) {
            this.state.actionDropdown.map((val, i) => {
                dropOptionSource.push(val.userGroup);
            });
        }

        this.setState({ dropdownOptionSource: dropOptionSource });
    }

    onSelect(event) {
        const _this = this;

        console.log('event: ', event.target.value);

        let scanData = this.state.scanData;

        if (event.target.value) {
            switch (event.target.value) {
                case 'Receive Sample to My Location':
                    scanData.action = event.target.value;
                    scanData.isSample = true;

                    break;

                case 'Move Sample to a New Location':
                    scanData.action = event.target.value;
                    scanData.isSample = false;
                    delete scanData.photographer;
                    delete scanData.retoucher;
                    delete scanData.campaign;

                    _this.setState({ secondDropdownVals: _this.state.locationDropdown, currentDropdown: 'location'});

                    break;

                case 'Shot Completed':
                    scanData.action = event.target.value;
                    scanData.isSample = false;
                    delete scanData.location;
                    delete scanData.retoucher;
                    delete scanData.campaign;

                    _this.setState({ secondDropdownVals: _this.state.photographerDropdown, currentDropdown: 'photographer'});

                    break;

                case 'Retouch Completed':
                    scanData.action = event.target.value;
                    scanData.isSample = false;
                    delete scanData.location;
                    delete scanData.photographer;
                    delete scanData.campaign;

                    _this.setState({ secondDropdownVals: _this.state.retoucherDropdown, currentDropdown: 'retoucher'});

                    break;

                case 'Assign to Campaign':
                    scanData.action = event.target.value;
                    scanData.isSample = false;
                    delete scanData.location;
                    delete scanData.photographer;
                    delete scanData.retoucher;

                    _this.setState({ secondDropdownVals: _this.state.campaignDropdown, currentDropdown: 'campaign'});

                    break;
            }

            this.setState({ scanData });
            this.setState({ isModified: true });
        }
    }

    dropdownValueChanged(e) {
        const value = e.currentTarget.value;
        let scanData = this.state.scanData;

        console.log('dropdown change value selected ', value);

        scanData[this.state.currentDropdown] = value;

        this.setState({ scanData: scanData });
    }

    setLocationDropdown() {
        var _this = this;

        let headers = {
            'Authorization': JSON.parse(localStorage.getItem("user")).token,
            'content-type': 'application/json'
        };

        axios({
            method: 'post',
            url: require('../common/configurations.js').admin.getDropdownData,
            headers: headers,
            data: { "id": 'Location' }
        })
            .then((response) => {
                console.log('productContainer helperComponents footer API call success data: ', response.data);

                let res = response.data;

                _this.setState({ locationDropdown: res.data });
            })
            .catch(error => {
                console.log('productContainer helperComponents footer API call failed', error);
            });
    }

    renderScanActionDropdown() {
        console.log('action dropdown: ', this.state.actionDropdown, this.state.dropdownOptionSource);

        let source = new jqx.dataAdapter({
            datatype: 'array',
            localdata: this.state.dropdownOptionSource
        });

        console.log('source: ', source);

        if (this.state.dropdownOptionSource.length > 0) { }
    }

    clickToScan() {
        this.setState({
            showVideo: true
        }, () => {
            this.callXing();
        });
    }

    callXing() {
        const _this = this;
        console.log('ZXing code reader initialized');

        const codeReader = new BrowserMultiFormatReader();

        codeReader
            .decodeOnceFromVideoDevice(undefined, 'video')
            .then((result) => {
                console.log(result.text);
                const code = result.text;

                if (code) {
                    let data = {
                        action: _this.state.scanData.action ? _this.state.scanData.action : "0",
                        actionedProduct: code,
                        scanner: _this.state.scanData.scanner ? _this.state.scanData.scanner : '',
                        isSample: _this.state.scanData.isSample ? _this.state.scanData.isSample : false,
                    };

                    _this.setState({
                        scanData: data,
                        showVideo: false,
                    }, () => {
                        this.saveScanData();
                    });
                }
            })
            .catch(err => console.error(err));
    }

    renderLocationDropdown() {
        let dropOptionSource = [
            "MY LOCATION",
            "PR",
            "SAMPLE LIBRARY",
            "STORE",
            "STUDIO",
            "TURNOVER MEETING",
            "WAREHOUSE"
        ];

        dropOptionSource.unshift({ label: 'SELECT LOCATION', value: '0' });

        return (
            <JqxDefaultDropDownList
                ref={(locationDropDownList) => {
                    this.locationDropDownList = locationDropDownList;
                }}
                width={412} height={33}
                source={dropOptionSource}
                selectedIndex={0} />
        );
    }

    render() {
        console.log('scan modal render data', this.state, this.props);

        let valuesList = '';
        let dropdownOptionSource = this.state.dropdownOptionSource;

        if (dropdownOptionSource.length > 0) {
            console.log('dropdownoptionsource: ', this.state.dropdownOptionSource);

            valuesList = dropdownOptionSource.map((val, index) => {
                if (!val.label) {
                    return <option key={index + 1} value={val}>{val}</option>;
                }
            });
        }

        return (
            <div>
                <div className="modal-header">
                    SCAN ACTIONS
                    <span className="close" onClick={this.openModalCancel}>x</span>
                </div>

                <div className="modal-content">

                    <div className="add-user-group modal-form">
                        <form className="addNewGroup" method="POST" name="addNewUser" autoComplete={'off'} onSubmit={this.formPreventDefault}>
                            <div className="form-container no-scrollbar-bottom">
                                <div className="form-group">
                                    <label className="form-label">SELECTED ACTION*</label>
                                    <select
                                        onChange={this.onSelect.bind(this)}
                                        value={this.state.selectedValue}
                                        style={{ width: '100%' }}
                                    >
                                        <option key={0} value={'Select Action'}>Select Action</option>
                                        {valuesList}
                                    </select>
                                </div>

                                { this.state.scanData.action == '0' &&
                                <div>
                                    <div className="form-group">
                                        <label className="form-label">Scanner*</label>
                                        <input readOnly={true} type="text" className="form-control" name="scanner" value={this.state.scanData.scanner}></input>
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label">Enter Sample ID*</label>
                                        <input type="text" onKeyPress={this.handleKeyPress} className="form-control" name="actionedProduct" id="actionProduct" value={this.state.scanData.actionedProduct} onChange={this.handleChange} maxLength="50"></input>
                                    </div>
                                </div>
                                }

                                { this.state.scanData.action == 'Receive Sample to My Location' &&
                                <div>
                                    <div className="form-group">
                                        <label className="form-label">Scanner*</label>
                                        <input readOnly={true} type="text" className="form-control" name="scanner" value={this.state.scanData.scanner}></input>
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label">Enter Sample ID*</label>
                                        <input type="text" onKeyPress={this.handleKeyPress} className="form-control" name="actionedProduct" id="actionProduct" value={this.state.scanData.actionedProduct} onChange={this.handleChange} maxLength="50"></input>
                                    </div>
                                </div>
                                }

                                { this.state.scanData.action == 'Move Sample to a New Location' &&
                                <div>
                                    <div className="form-group">
                                        <label className="form-label">Location*</label>
                                        {this.state.secondDropdownVals &&
                                        <select onChange={this.dropdownValueChanged} style={{ width: '100%' }}>
                                            <option key={0} value={'Select Location'}>Select Location</option>
                                            {this.state.secondDropdownVals.map((i, x) => {
                                                return      <option key={x + 1} value={i.userGroup}>{i.userGroup}</option>;
                                            })}
                                        </select>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label">Enter Sample ID*</label>
                                        <input type="text" onKeyPress={this.handleKeyPress} className="form-control" name="actionedProduct" id="actionProduct" value={this.state.scanData.actionedProduct} onChange={this.handleChange} maxLength="50"></input>
                                    </div>
                                </div>
                                }

                                { this.state.scanData.action == 'Shot Completed' &&
                                <div>
                                    <div className="form-group">
                                        <label className="form-label">Photographer*</label>
                                        {this.state.secondDropdownVals &&
                                        <select onChange={this.dropdownValueChanged} style={{ width: '100%' }}>
                                            <option key={0} value={'Select Photographer'}>Select Photographer</option>
                                            {this.state.secondDropdownVals.map((i, x) => {
                                                return      <option key={x + 1} value={i}>{i}</option>;
                                            })}
                                        </select>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label">Enter Shot Angle*</label>
                                        <input type="text" onKeyPress={this.handleKeyPress} className="form-control" name="actionedProduct" id="actionProduct" value={this.state.scanData.actionedProduct} onChange={this.handleChange} maxLength="50"></input>
                                    </div>
                                </div>
                                }

                                { this.state.scanData.action == 'Retouch Completed' &&
                                <div>
                                    <div className="form-group">
                                        <label className="form-label">Retoucher*</label>
                                        {this.state.secondDropdownVals &&
                                        <select onChange={this.dropdownValueChanged} style={{ width: '100%' }}>
                                            <option key={0} value={'Select Retoucher'}>Select Retoucher</option>
                                            {this.state.secondDropdownVals.map((i, x) => {
                                                return      <option key={x + 1} value={i}>{i}</option>;
                                            })}
                                        </select>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label">Enter Shot Angle*</label>
                                        <input type="text" onKeyPress={this.handleKeyPress} className="form-control" name="actionedProduct" id="actionProduct" value={this.state.scanData.actionedProduct} onChange={this.handleChange} maxLength="50"></input>
                                    </div>
                                </div>
                                }

                                { this.state.scanData.action == 'Assign to Campaign' &&
                                <div>
                                    <div className="form-group">
                                        <label className="form-label">Campaign*</label>
                                        {this.state.secondDropdownVals &&
                                        <select onChange={this.dropdownValueChanged} style={{ width: '100%' }}>
                                            <option key={0} value={'Select Campaign'}>Select Campaign</option>
                                            {this.state.secondDropdownVals.map((i, x) => {
                                                return      <option key={x + 1} value={i}>{i}</option>;
                                            })}
                                        </select>
                                        }
                                    </div>
                                    <div className="form-group">
                                        <label className="form-label">Enter Sweft ID*</label>
                                        <input type="text" onKeyPress={this.handleKeyPress} className="form-control" name="actionedProduct" id="actionProduct" value={this.state.scanData.actionedProduct} onChange={this.handleChange} maxLength="50"></input>
                                    </div>
                                </div>
                                }

                                <div className="form-group"></div>
                                <div className="form-group"></div>
                            </div>

                            <div onClick={this.clickToScan.bind(this)}>Click to scan</div>

                            { this.state.showVideo &&
                            <div>
                                <video
                                    id='video'
                                    width={200}
                                    height={200}
                                ></video>
                            </div>
                            }

                            <div className="modal-footer">
                                <input onClick={this.openModalCancel} disabled={this.state.isModified ? false : true} type="button" className="gray-button record-delete-holder" value="Cancel"></input>
                                <input disabled={this.state.isModified ? false : true} type="button" className="gray-button" value="SAVE" onClick={this.saveScanData}></input>
                            </div>

                            <br />
                            {(this.state.isDirty) ? <span className="error-message">{this.state.errorMessage}</span> : ''}
                        </form>
                    </div>
                </div>
                <CancelModal isOpen={this.state.isModalOpen} modalClose={this.modalClose} />
            </div>
        );
    }
}

export default ScanModal;
