import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/marketingActions';
import Modal from '../../modals/common-modal.js';
import ConfirmModal from '../../modals/confirm-modal.js';
import axios from 'axios';

import CancelModalForRejectComponent from './CancelModalForRejectComponent.js';

class CancelModalForReject extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false
        };

        this.closeConfirmModal = this.closeConfirmModal.bind(this);
    }

    closeConfirmModal(key) {
        this.props.modalClose(key);
    }

    render() {
        return (
            <ConfirmModal isOpen={this.props.isOpen}>
                <CancelModalForRejectComponent
                    closeConfirmModal={this.closeConfirmModal}
                />
            </ConfirmModal>
        );
    }
}

export default CancelModalForReject;
