import React from 'react';

class OverWritePopup extends React.Component {
    componentDidMount() {
        $('.gray-button').on('click', function () {
            $(this).prop('disabled', true);
        });
    }

    render(){
        let props = this.props;

        return (
            <div>
                <div className="modal-header">
                   Overwrite
                    <span className="close" onClick={() => {
                        props.modalClose(false);
                    }}>x</span>
                </div>
                <div className="modal-content">
                    <div className="modal-fields text-center">
                        <h4>Are you sure you want to Overwrite this view?</h4>
                    </div>

                    <div className="modal-footer text-center">
                        <button type="button" className="gray-button" onClick={props.cancel}>Cancel</button>
                        <button type="button" className="gray-button" onClick={props.saveView}>
                           Over-Write
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default OverWritePopup;
