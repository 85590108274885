
import React from 'react';
import axios from 'axios';
import NotificationManager from '../../common/components/notification';
import moment from 'moment';
import {ProductServices} from '../services/product-services';

class AddRecordsFromFiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputFile: [],
            fileStatus: {
                isSubimtted: false,
            },
            isError: false,
            errorMsg: '',
            isValid: false,
            data: null,
            currentProcessId: null,
            spinner: false,
            startPolling: false,
            pollingCount: [],
            modalType: '',
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
    }

    componentWillMount() {
        this.setState({
            modalType: this.props.modalType
        }, () => {
            this.getImportHistory();
        });
    }

    getImportHistory() {
        let urlVal = '';

        if (this.state.modalType == 'excel-import') {
            urlVal = 'VENDOR_IMPORT';
        }
        if (this.state.modalType == 'copy-import') {
            urlVal = 'COPY_IMPORT';
        }

        ProductServices.excelUploadHistory(urlVal).then((res) => {
            console.log('file upload', res);
            NotificationManager({ message: res.data.message, type: 1 });
            this.setState({
                data: res.data.responseData,
            });
        }).catch((err) => {
            console.log('file upload', err);
            NotificationManager({ message: err.message, type: 4 });
        });
    }

    componentWillUnmount() {
        clearInterval(window.intervalId);
    }


    pollingStatus() {
        const processId = this.state.currentProcessId;
        const _this = this;
        if (_this.state.startPolling) {
            window.intervalId = setInterval(() => {
                ProductServices.excelUploadStatus(processId).then((res) => {
                    console.log('polling start', res);
                    const data = res.data;
                    let pollCount = _this.state.pollingCount;
                    pollCount['recordCount'] = data.responseData.recordCount;
                    pollCount['errorCount'] = data.responseData.errorCount;
                    pollCount['successCount'] = data.responseData.successCount;

                    if (data.status == 'success' && data.responseData.importStatus == 'IMPORT_IN_PROGRESS' || data.responseData.importStatus == 'IMPORT_IN_QUEUE') {
                        _this.setState({
                            pollingCount: pollCount,
                        });
                    }
                    if (data.status == 'success' && data.responseData.importStatus == 'IMPORT_COMPLETE'
						|| data.responseData.importStatus == 'IMPORT_PARTIAL_COMPLETE'
						|| data.responseData.importStatus == 'IMPORT_FAILED'
						|| data.responseData.importStatus == 'IMPORT_REJECTED'
                    ) {
                        _this.setState({
                            spinner: false,
                            startPolling: false,
                        }, () => {
                            this.getImportHistory();
                        });
                        clearInterval(window.intervalId);
                    }
                }).catch((err) => {
                    NotificationManager({ message: err.data.message, type: 1 });
                });
            }, 10000);
        }
    }

    handleSubmit(e) {
        const _this = this;
        e.preventDefault();
        _this.setState({ isValid: false, spinner: true });
        let importApi = '';
        const formData = new FormData();

        if(this.state.modalType == 'excel-import') {
            importApi = require('../../common/configurations.js').product2.excelUpload;
            formData.append('uploadfile', _this.state.inputFile);
        }

        if(this.state.modalType == 'copy-import') {
            importApi = require('../../common/configurations.js').product2.copyUpload;
            formData.append('copyfile', _this.state.inputFile);
        }

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'multipart/form-data',
        };

        axios({
            method: 'post',
            url: importApi,
            headers: headers,
            data: formData,
        })
            .then((response) => {
                console.log('productContainer AddRecordsFromFiles handleSubmit API call success data: ', response);
                const res = response.data;
                if (res.status == "success") {
                    NotificationManager({ message: res.message, type: 1 });
                    let pollCount = this.state.pollingCount;
                    pollCount['recordCount'] = res.responseData.recordCount;
                    pollCount['errorCount'] = res.responseData.errorCount;
                    pollCount['successCount'] = res.responseData.successCount;
                    this.setState({
                        currentProcessId: res.responseData.processId,
                        startPolling: true,
                        pollingCount: pollCount
                    }, () => {
                        this.pollingStatus();
                    });
                }
            })
            .catch((error) => {
                console.log('productContainer AddRecordsFromFiles  handleSubmit API call failed', error.response.data);
                NotificationManager({ message: error.response.data.message, type: 4 });
                this.setState({
                    startPolling: false,
                    spinner: false,
                });
            });
    }

    handleFileChange(e) {
        const _this = this;

        const list = e.target.files[0];
        const listCheck = list.name ? list.name : '';

        const last = listCheck.substring(listCheck.lastIndexOf('.') + 1, listCheck.length);

        // console.log('handleFileChange: ', list);

        if (last != 'csv' && last != 'xls' && last != 'xlsx' && last.toLowerCase() != 'json') {
            _this.setState({ isError: true, errorMsg: 'Invalid file format.  Please upload files in CSV, XLS, XLSX or JSON format.' });
            _this.setState({ isValid: false });
        } else {
            _this.setState({ isError: false, errorMsg: '', isValid: true });
            _this.setState({ inputFile: list });
        }
    }

    render() {
        console.log('excel import modal', this.props, this.state);
        let modalName = '';

        if(this.state.modalType == 'excel-import') {
            modalName = 'Add Products from File';
        }

        if(this.state.modalType == 'copy-import') {
            modalName = 'Update Copy by File';
        }

        let listData = this.state.data && this.state.data.map((i, x) => {
            return(
                <div key={x} className="info-headings">
                    <div className="date">{moment(new Date(i.fileUploadStart)).format("MM/DD/YYYY, h:mm a")}</div>
                    <a href={i.modifiedFileLink} className="source-file">{i.originalFileName}</a>
                    <React.Fragment>
                        {
                            i.erroFileLink == 'NA' ?
                                <a className="error-file"></a>
                                :
                                <a href={i.erroFileLink} className="error-file">{i.errorFileName}</a>
                        }
                    </React.Fragment>
                    {/* <a href={i.erroFileLink} className="error-file">{i.errorFileName}</a> */}
                    <div className="success">{i.successCount}</div>
                    <div className="errors">{i.errorCount}</div>
                    <div className="fixed"><input type='checkbox'/></div>
                </div>
            );
        });

        return (
            <div>
                <div className="modal-header">
                    {modalName}
                    <span className="close" onClick={this.props.modalClose}>x</span>
                </div>
                <div className="modal-content add-record-files">
                    {!this.state.fileStatus.isSubimtted &&
						<div className="main-container">
						    <div className="main-section">
						        <form id="AddRecordFiels" name="records-from-files" onSubmit={this.handleSubmit} encType="multipart/form-data">
						            <fieldset>
						                <input type="file" id="uploadCSV" onChange={this.handleFileChange} name="uploadfile" />
						                {this.state.isError &&
									<span className="error-msg">
									    {this.state.errorMsg}
									</span>
						                }
						            </fieldset>
						            {/* <fieldset className={`error ${(validateRecordFiles.isValid) ? 'valid' : 'invalid'}`} >
								<span>
									{!validateRecordFiles.isValid && validateRecordFiles.list.length > 0 &&
										'The selected file format(s) "' +
											validateRecordFiles.list[0].split(".")[validateRecordFiles.list[0].split(".").length-1]
											+ '" is not allowed. Please choose the file | "' + validateRecordFiles.list[0] + '" | in one of the allowed formats "JSON, CSV"'
									}
								</span>
							</fieldset> */}
						            <fieldset>
						                <button type="submit" disabled={(this.state.isValid) ? false : true} className="gray-button">Import File</button>
						                {
						                    this.state.spinner &&
									<div className='load-icon'>
									    <i className="fas fa-sync"></i>
									</div>
						                }
						            </fieldset>
						            <input type="hidden" name="extraField" value="_dontcare" />
						        </form>
						    </div>
						    <div className="main-section">
						        <div className='sections'>
						            <div className="headings">Total Records</div>
						            <div className="headings">
						                <React.Fragment>
						                    {
						                        this.state.pollingCount &&
											this.state.pollingCount.recordCount
						                    }
						                </React.Fragment>
						            </div>
						        </div>
						        <div className='sections'>
						            <div className="headings">Successful Records</div>
						            <div className="headings">
						                <React.Fragment>
						                    {
						                        this.state.pollingCount &&
											this.state.pollingCount.successCount
						                    }
						                </React.Fragment>
						            </div>
						        </div>
						        <div className='sections'>
						            <div className="headings">Failed Records</div>
						            <div className="headings">
						                <React.Fragment>
						                    {
						                        this.state.pollingCount &&
											this.state.pollingCount.errorCount
						                    }
						                </React.Fragment>
						            </div>
						        </div>
						    </div>
						</div>
                    }

                    <div className="files-history">
                        <div className="heading">File History</div>
                        <div className="files-info">
                            <div className="info-headings">
                                <div className="date">File Date</div>
                                <div className="source-file">Source File</div>
                                <div className="error-file">Error File</div>
                                <div className="success">Successes</div>
                                <div className="errors">Errors</div>
                                <div className="fixed">Fixed</div>
                            </div>

                            {listData}


                        </div>
                    </div>
                    {/* <div className="submited">
						{this.state.fileStatus.isSubimtted &&
							<FileUploadMsg details={this.state.fileStatus.data} />
						}
					</div> */}
                </div>
            </div>
        );
    }
}

export default AddRecordsFromFiles;

