import React from 'react';

/**
 * renders the generic dropdown values on the grid
 */
export class GenericDropdownRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        let showValue = this.props.value || (this.props.value == 0);

        return (
            <React.Fragment>
                {
                    showValue ?
                        this.props.value
                        :
                        <div className="arrow downPos"></div>
                }
            </React.Fragment>
        );
    }
}

export class GenericDropdownEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedVal: '',
            dropdownVals: props.dropdownValues(),
            width: null,
        };
    }

    componentWillMount() {
        const dropdownVals = this.state.dropdownVals;
        const cellWidth = this.props.eGridCell.style.width;
        let setValue;

        setValue = this.props.value ? this.props.value : '';

        this.setState({
            dropdownVals,
            width: cellWidth,
            selectedVal: setValue
        });
    }

    componentWillUnmount() {
        this.props.api.stopEditing();
    }

    isPopup() {
        return true;
    }

    getValue() {
        return this.state.selectedVal;
    }

    changeSelectedValue(event) {
        const colID = this.props.column.colId;

        const rowData = this.props.node.data;
        rowData[colID] = event.target.value;

        this.setState({
            selectedVal: event.target.value,
        }, () => {
            this.props.rowUpdate(rowData);
            this.props.api.stopEditing();
        });
    }

    stopEditing() {
        this.props.api.stopEditing();
    }

    render() {
        let dropdownList = this.state.dropdownVals.map((i, x) => {
            return <option key={x} value={i}>{i}</option>;
        });

        return (
            <div>
                <select style={{width : this.state.width}} onChange={this.changeSelectedValue.bind(this)} value={this.state.selectedVal}>
                    <option value=''>Please Select</option>
                    {dropdownList}
                </select>
            </div>
        );
    }
}
