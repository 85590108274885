import React, { Component } from 'react';
import EmailTemplatesComponent from './emailTemplatesComponent';
import axios from 'axios';
import GridDataBuilder from '../common/gridDataBuilder';
import NotificationManager from '../common/components/notification';
import AdminServices from '../admin/admin-services/admin-services';

class EmailTemplates extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            isRulesOpen: false,
            isModalOpen: false,
        };

        this.existRuleData = {};
        this.inputData = {};
        this.AllFields = ['content', 'type'];
        this.modalName = '';
        this.closeRulesModal = this.closeRulesModal.bind(this);
        this.openRulesModal = this.openRulesModal.bind(this);
        this.pauseRules = this.pauseRules.bind(this);
        this.cloneRules = this.cloneRules.bind(this);
        this.deleteRules = this.deleteRules.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.modalOpen = this.modalOpen.bind(this);
        this.deleteRuleDialog = this.deleteRuleDialog.bind(this);
        this.addOrUpdateRules = this.addOrUpdateRules.bind(this);
        this.getRulesFormData = this.getRulesFormData.bind(this);
        this.didValueChanged = this.didValueChanged.bind(this);
        this.closeAllModal = this.closeAllModal.bind(this);
    }

    componentDidMount() {
        const base = this;

        AdminServices.getTemplateNames().then((response) => {
            const res = response;

            if (res.data.length > 0) {
                res.columnDetails = [{
                    'columnTitle': 'Id',
                    'fieldName': 'id',
                    'type': 'int',
                    'visibility': true,
                    'pk': true,
                    'order': 0,
                    'map': null,
                    'id': null,
                    'popup': false,
                },
                {
                    'columnTitle': 'Type',
                    'fieldName': 'value',
                    'type': 'string',
                    'visibility': true,
                    'pk': false,
                    'order': 1,
                    'map': null,
                    'id': null,
                    'popup': false,
                }];

                base.gridData = GridDataBuilder(res, 'email');
                base.setState({ isLoaded: true });
            }
        }).catch((error) => {
            console.log('emailTemplatesContainer componentDidMount API call failed', error);
        });

        this.sortDropDown();

        $('#drop-down').on('click', 'li', function () {
            const fieldName = $(base).closest('#drop-down').attr('data-field');

            let val = $(base).attr('data-val');
            val = (val === 'null') ? null : val;

            $('#drop-down').hide();

            base.emailGrid.sortby(fieldName, val);
        });
    }

    componentDidUpdate(prevProps, prevState) {
    }

    /**
     * Disable the rules from Active to Paused which are selected
     */
    pauseRules() {
        const _this = this;
        const selectedrowindexs = _this.emailGrid.getselectedrowindexes();
        let selectedrowids = [];

        if (selectedrowindexs.length > 0) {
            selectedrowids = selectedrowindexs.map((val, key) => {
                const rowData = _this.emailGrid.getrowdata(val);

                return { 'id': rowData.ruleId };
            });

            console.log(selectedrowids);

            AdminServices.pauseRule(selectedrowids).then((response) => {
                const res = response;

                if (res.status) {
                    NotificationManager({ message: res.responseMessage, type: 1 });

                    const rowids = res.data.map((val, key) => {
                        res.data[key].status = res.data[key].status == 'true' ? 'Active' : 'Paused';

                        return val.ruleId;
                    });

                    _this.emailGrid.updaterow(rowids, res.data);
                } else {
                    NotificationManager({
                        message: res.responseMessage,
                        type: 4
                    });
                }
            }).catch((error) => {
                console.log('emailTemplatesContainer pauseRules API call failed', error);
                NotificationManager({ message: error, type: 4 });
            });
        } else {
            NotificationManager({
                message: 'Please select one or more rules to perform this action',
                type: 4
            });
        }
    }

    /**
     * Copy and add new the rules which are selected
     */
    cloneRules() {
        const _this = this;
        const selectedrowindexs = _this.emailGrid.getselectedrowindexes();
        let selectedrowids = [];
        rowids = [];

        if (selectedrowindexs.length > 0) {
            selectedrowids = selectedrowindexs.map((val, key) => {
                const rowData = _this.emailGrid.getrowdata(val);

                rowids.push(rowData.ruleId);

                return { 'id': rowData.ruleId };
            });

            console.log(selectedrowids);

            const headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json',
            };

            axios({
                method: 'post',
                url: require('../common/configurations.js').admin.cloneRule,
                headers: headers,
                data: selectedrowids,
            })
                .then((response) => {
                    console.log('emailTemplatesContainer pauseRules API call success data: ', response.data);

                    const res = response.data;

                    if (res.status) {
                        NotificationManager({
                            message: res.responseMessage,
                            type: 1
                        });

                        const rowids = res.data.map((val, key) => {
                            res.data[key].status = res.data[key].status == 'true' ? 'Active' : 'Paused';

                            return val.ruleId;
                        });

                        _this.emailGrid.addrow(rowids, res.data);
                    } else {
                        NotificationManager({
                            message: res.responseMessage,
                            type: 4
                        });
                    }
                })
                .catch((error) => {
                    console.log('emailTemplatesContainer pauseRules API call failed', error);
                    NotificationManager({ message: error, type: 4 });
                });
        } else {
            NotificationManager({
                message: 'Please select one or more rules to perform this action',
                type: 4
            });
        }
    }

    deleteRules() {
        const _this = this;
        const selectedrowindexs = _this.emailGrid.getselectedrowindexes();

        let selectedrowids = [];

        const rowids = [];

        if (selectedrowindexs.length > 0) {
            selectedrowids = selectedrowindexs.map((val, key) => {
                const rowData = _this.emailGrid.getrowdata(val);

                rowids.push(rowData.ruleId);

                return { 'id': rowData.ruleId };
            });

            console.log(selectedrowids);

            const headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json',
            };

            axios({
                method: 'post',
                url: require('../common/configurations.js').admin.deleteRule,
                headers: headers,
                data: selectedrowids,
            })
                .then((response) => {
                    console.log('emailTemplatesContainer pauseRules API call success data: ', response.data);

                    const res = response.data;

                    if (res.status) {
                        _this.modalClose();

                        NotificationManager({ message: res.message, type: 1 });

                        _this.emailGrid.deleterow(rowids);
                    } else {
                        NotificationManager({ message: res.message, type: 4 });
                    }
                })
                .catch((error) => {
                    console.log('emailTemplatesContainer pauseRules API call failed', error);

                    NotificationManager({ message: error, type: 4 });
                });
        } else {
            NotificationManager({
                message: 'Please select one or more rules to perform this action',
                type: 4
            });
        }
    }

    /**
     * Delete selected rows in the rules grid
     */
    deleteRuleDialog() {
        const _this = this;
        const selectedrowindexs = _this.emailGrid.getselectedrowindexes();

        if (selectedrowindexs.length > 0) {
            this.modalName = 'ConfirmDelete';
            this.setState({ isModalOpen: true });
        } else {
            NotificationManager({
                message: 'Please select one or more rules to perform this action',
                type: 4
            });
        }
    }

    openRulesModal() {
        this.data = { id: '0', subject: '', type: '', content: '' };
        this.setState({ isRulesOpen: true });
    }

    closeAllModal() {
        this.setState({ isModalOpen: false });
        this.setState({ isRulesOpen: false });
    }

    closeRulesModal() {
        const _this = this;

        this.getRulesFormData();

        if (this.didValueChanged()) {
            this.modalName = 'SaveChanges';
            this.setState({ isModalOpen: true });
        } else {
            this.closeAllModal();
        }
    }

    didValueChanged() {
        let isChanged = false;
        let prev;
        let next;

        for (let i = 0; i < this.AllFields.length; i++) {
            prev = this.data[this.AllFields[i]];
            next = this.inputData[this.AllFields[i]];
            prev = prev !== null ? prev : '';
            next = next !== null ? next : '';

            if (this.AllFields[i] === 'status') {
                if (next == 'true') {
                    next = 'Active';
                }

                if (next == 'false') {
                    next = 'Paused';
                }
            }

            if (prev.localeCompare(next) !== 0) {
                isChanged = true;
                break;
            }
        }

        return isChanged;
    }

    getRulesFormData() {
        const id = $('.currentRuleId').val();
        const subject = $('.email-subject').val();
        const type = $('.email-type').val();
        const content = $('.email-content').val();

        this.inputData = {
            id: id ? id : '0',
            subject: subject,
            type: type,
            content: content,
        };
    }

    /**
     * Create/Update rules, if rold !=0 [update] else [create]
     * @param {*} e
     */
    addOrUpdateRules() {
        const _this = this;

        // Retreive values from input fields
        this.getRulesFormData();
        let error = false;

        //validation for all mandatory fields
        if (this.inputData.content === '' || this.inputData.content.trim() == '') {
            error = true;
        } else if (this.inputData.type === '') {
            error = true;
        }

        if (error) {
            const errmsg = '*Mandatory information marked with an asterisk is missing.';

            NotificationManager({ message: errmsg, type: 4 });

            _this.modalClose();

            return;
        }

        const data = [this.inputData];

        AdminServices.saveTemplates(data).then((response) => {
            const res = response;

            if (res.status) {
                NotificationManager({ message: res.responseMessage, type: 1 });

                _this.inputData.value = _this.inputData.type;
                _this.emailGrid.updaterow(_this.inputData.id, _this.inputData);
                _this.closeAllModal();
            } else {
                NotificationManager({ message: res.responseMessage, type: 4 });

                _this.modalClose();
            }
        }).catch((error) => {
            console.log('emailTemplatesContainer pauseRules API call failed', error);

            NotificationManager({ message: error, type: 4 });
        });
    }

    sortDropDown() {
        $('#drop-down').remove();

        const container = '<div id="drop-down" class="pd-hl"></div>';

        $('body').append(container);

        const list = this.generateDropDowns();

        $('#drop-down').append(list);
    }

    generateDropDowns() {
        const list = [{
            name: '0-9,A-Z,blank-non_blank',
            val: 'asc',
        }, {
            name: '9-0,Z-A,non_blank-blank',
            val: 'desc',
        }, {
            name: 'clear',
            val: 'null',
        }];

        let li = '';
        list.forEach((item, index) => {
            li += '<li class="product-sort-options" data-val="' + item.val + '">' + item.name + '</li>';
        });

        const ul = '<ul class=\'sort-holder\'>' + li + '</ul>';

        return ul;
    }

    modalOpen() {
        this.setState({ isModalOpen: true });
    }

    modalClose() {
        this.setState({ isModalOpen: false });
    }


    onCelldoubleclick(event) {
        const args = event.args;
        const dataField = args.datafield;
        const value = args.value;

        console.log('emailTemplates doubleclick: ', args);

        if (dataField === 'id') {
            const data = { id: value };

            AdminServices.getTemplate(data).then((response) => {
                const res = response;

                this.data = res.data;
                this.setState({ isRulesOpen: true });
            }).catch((error) => {
                console.log('emailTemplatesContainer componentDidMount API call failed', error);
            });
        }
    }

    render() {
        const footerHeight = 50;
        const headerHeight = 64;
        const gridHeight = window.innerHeight - footerHeight - headerHeight;
        const modalClass = 'ConfirmRulesDelete';

        $('.page-title a').text("ADMIN: Email Templates");

        return (
            <EmailTemplatesComponent
                state={this.state}
                props={this.props}

                emailGridRef={(grid) => {
                    this.emailGrid = grid;
                }}
                gridData={this.gridData}
                gridHeight={gridHeight}

                closeRulesModal={this.closeRulesModal}
                data={this.data}

                modalClass={modalClass}
                modalClose={this.modalClose}
                deleteRules={this.deleteRules}
                modalName={this.modalName}
                addOrUpdateRules={this.addOrUpdateRules}
                closeAllModal={this.closeAllModal}

                openRulesModal={this.openRulesModal}
                cloneRules={this.cloneRules}
                closeRulesModal={this.closeRulesModal}
                pauseRules={this.pauseRules}
                deleteRuleDialog={this.deleteRuleDialog}
                addOrUpdateRules={this.addOrUpdateRules}
                onCelldoubleclick={this.onCelldoubleclick.bind(this)}

            />
        );
    }
}

export default EmailTemplates;
