import React, { Component } from 'react';
import Modal from '../../../modals/common-modal';
import ViewDeleteConfirm from './viewDeleteConfirm';
import AddNewViewPopup from './addNewViewPopup';

import * as actions from '../../../product/redux/productActions';
import * as p2Actions from '../../../product2/redux/product2Actions';
import { connect } from 'react-redux';

import axios from 'axios';

import NotificationManager from '../../../common/components/notification';

import { CommonServices } from '../../../common/services/common-services';

import { ProductServices } from '../../../product2/services/product-services';

class GridViewDropDown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            viewName: "Default Grid View",
            viewType: "systemView",
            availabelList: {},
            deleteData: {},
            isModalOpen: false,
            modalName: '',
            isSaveViewEnable: false,
            showSaveView: false,
        };

        this.getNewGridView = this.getNewGridView.bind(this);
        this.deleteView = this.deleteView.bind(this);
        this.modalOpen = this.modalOpen.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.deleteViewConfirmed = this.deleteViewConfirmed.bind(this);
        this.addNewVeiwPopup = this.addNewVeiwPopup.bind(this);
        this.updateNewView = this.updateNewView.bind(this);

        //constants for backend
        this.constants = {
            'systemView': "SystemView",
            'personalView': "PersonalView"
        };
    }

    componentDidMount() {
        this.getViewList('systemView');
    }

    componentDidUpdate(prevProps, prevState) {
        let _this = this;

        if (prevProps.columnResequence !== this.props.columnResequence && this.props.columnResequence.afterSaveView == true) {
            _this.setState({ isSaveViewEnable: false, showSaveView: false });
        } else if (prevProps.columnResequence !== this.props.columnResequence && this.props.columnResequence.isSaveNewView == true) {
            _this.setState({ showSaveView: true });
        }

        if (prevProps.checkboxChanged !== _this.props.checkboxChanged && !_this.state.checkboxChanged) {
            _this.setState({ showSaveView: true });
        }

        if (_this.props.isColumnTouched != null && prevProps.isColumnTouched != _this.props.isColumnTouched) {
            _this.setState({ showSaveView: true });
        }
    }

    getViewList(viewType, isViewChanged) {
        let _this = this;
        let postData = { "id": this.props.user.userId };

        CommonServices.getGridView(postData)
            .then((data) => {
                if (data.responseStatus) {
                    _this.sortDesc(data.data);
                    _this.setState({ availabelList: data.data });

                    if (viewType === "systemView") {
                        _this.checkForDefaultView(data.data.systemView, isViewChanged);
                    }
                }
            })
            .catch(error => {
                console.log('pageHeader gridViewDropDown getViewList api api call failure', error);
            });
    }

    sortDesc(list) {
        list.systemView.sort(function (a, b) {
            return a.ranking - b.ranking;
        });

        list.personalView.sort(function (a, b) {
            return a.ranking - b.ranking;
        });
    }

    modalClose() {
        this.setState({ isModalOpen: false });
    }

    modalOpen() {
        this.setState({ isModalOpen: true });
    }

    /**
     * During initial load, default view is updated based on the response
     * @param {array} list - expects system view array
     */
    checkForDefaultView(list, isViewChanged) {
        let _this = this;

        for (var i = 0; i < list.length; i++) {
            var item = list[i];

            if (isViewChanged) {
                if (i > 0) {
                    this.setState({ viewName: item.viewName });
                    this.props.updateCurrentViewAction({
                        viewId: item.viewId,
                        viewType: "systemView"
                    });

                    break;
                }
            } else {
                if (item.defaultView) {
                    this.setState({ viewName: item.viewName });
                    this.props.updateCurrentViewAction({
                        viewId: item.viewId,
                        viewType: "systemView"
                    });

                    var postData = {
                        "id": item.viewId,
                        "type": "systemView",
                        "userId": this.props.user.userId
                    };

                    let headers = {
                        'Authorization': this.props.user.token,
                        'content-type': 'application/json'
                    };

                    ProductServices.viewColumndetails(postData)
                        .then((response) => {
                            console.log('pageHeader: productHeader getNewGridView api call', response);

                            let data = response.data;

                            // added to remove cols for Urban release
                            const filteredColumnDetails = data.columnDetailsDTOs.filter((obj) =>
                                !["MSRPPrice", "WholesalePrice", "onlineActivatedDate", ].includes(obj.fieldName)
                            );

                            _this.props.updateColumns(filteredColumnDetails);

                            _this.props._REDUX_DISPATCH_COLUMN_RESEQUENCE({
                                isSaveNewView: false,
                                afterSaveView: false,
                                where: 'grid-view-dropdown',
                                newColdefs: data.columnDetailsDTOs
                            });

                            _this.props.columnsUpdated();
                            typeof view !== "undefined" && _this.getViewList(true, view);
                        })
                        .catch(error => {
                            console.log('pageHeader: productHeader getNewGridView api call failure', error);
                        });

                    break;
                }
            }
        }
    }

    /**
     * upon selecting the new view fetch the column details related to current selcted view
     * @param {string} id - selected view id
     * @param {string} view - parentView or systemView
     * @param {integer} index - array index to get the object details
     */
    getNewGridView(id, view, index) {
        let _this = this;

        this.props.toggleOptions();
        this.setState({
            viewName: this.state.availabelList[view][index].viewName,
            viewType: view
        });

        var postData = {
            "id": id,
            "type": this.constants[view],
            "userId": this.props.user.userId
        };

        ProductServices.viewColumndetails(postData)
            .then((response) => {
                console.log('pageHeader: productHeader getNewGridView api call', response);

                let data = response.data;
                _this.props.updateColumns(data.columnDetailsDTOs);

                // updating current view into redux
                _this.props.updateCurrentViewAction({ viewId: id, viewType: view });
                _this.props.columnsUpdated();
                _this.getViewList(true, view);
                _this.props.actionPerformedFrom({ changeViews: true });
            })
            .catch(error => {
                console.log('pageHeader: productHeader getNewGridView api call failure', error);
            });
    }

    /**
     *  deletes the view id based on passed, id and type
     * @param {integer} id - view Id
     * @param {string} type - view Type
     */
    deleteView(id, type, index) {
        this.props.toggleOptions();
        var postData = {
            id,
            type: this.constants[type],
            index,
            typeInState: type
        };

        this.setState({ deleteData: postData });
        var name = this.state.availabelList[type][index];

        var actionData = {
            viewName: name.viewName,
            type
        };

        this.viewDetails = actionData;
        this.setState({ modalName: 'viewDeleteConfirm' });
        this.modalOpen();
    }

    /**
     * once user confirms on view delete, user view will be deleted
     */
    deleteViewConfirmed() {
        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.deleteView(this.state.deleteData)
            .then((response) => {
                let data = response.data;
                NotificationManager({ message: data.responseMessage, type: 1 });
                removeFromState();
            })
            .catch(error => {
                console.log('pageHeader: productHeader deleteViewConfirmed api call failure', error);
                NotificationManager({ message: error, type: 4 });
            });

        const removeFromState = () => {
            let availabel = Object.assign({}, this.state.availabelList);
            let deletedData = this.state.deleteData;

            availabel[deletedData.typeInState].splice(deletedData.index, 1);

            this.setState({ availabelList: availabel });
        };
    }

    addNewVeiwPopup() {
        // checking the condition for pre-populating the view name
        if (this.props.user.admin || this.state.viewType === 'personalView') {
            this.prepopulateName = this.state.viewName;
        } else {
            this.prepopulateName = '';
        }

        this.setState({ modalName: 'addNewView' });
        this.modalOpen();
    }

    /**
     * this will update the new view to available list and view name will be updated in dropdown
     * @param {object } obj - contains the details of new view properties
     */
    updateNewView(obj) {
        let newAvailableList = Object.assign({}, this.state.availabelList);
        let isExists = false;
        let id = (obj.type === "personalView") ? "customId" : "viewId";
        var newView = {
            viewName: obj.viewName,
            [id]: obj.viewId
        };

        newAvailableList[obj.type].forEach((item, index) => {
            if (item.viewId == obj.viewId || item.customId == obj.viewId) {
                isExists = true;
            }
        });

        if (!isExists) {
            newAvailableList[obj.type].push(newView);
        }

        this.setState({
            availabelList: newAvailableList,
            viewName: obj.viewName,
            viewType: obj.type
        });
    }

    render() {
        let props = this.props;
        let availabelList = this.state.availabelList;

        return (
            <div>
                <div className="grid-view-dropdown">
                    <div
                        className={`selected-view ${(this.props.isOpen) ? 'open' : ''}`}
                        onClick={this.props.toggleOptions}>
                        {this.state.viewName}
                    </div>
                    <div className="availabel-list">
                        <ul className="scroll">
                            { availabelList.hasOwnProperty('systemView') && availabelList.systemView.map((list, index) => {
                                if (list.viewName === this.state.viewName) {
                                    return <li key={list.viewId} className="selected">{list.viewName}</li>;
                                }

                                return (
                                    <li key={list.viewId} onClick={() => this.getNewGridView(list.viewId, 'systemView', index)}>
                                        {list.viewName}
                                        {this.props.user.admin &&
                                <span
                                    className="icon-close"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.deleteView(list.viewId, 'systemView', index);
                                    }}></span>
                                        }
                                    </li>
                                );
                            })}
                            { availabelList.hasOwnProperty('personalView') && availabelList.personalView.map((list, index) => {
                                if (list.viewName === this.state.viewName) {
                                    return <li key={list.viewId} className="personal-view selected">{list.viewName}</li>;
                                }

                                return (
                                    <li
                                        className="personal-view"
                                        key={list.viewId}
                                        onClick={() => this.getNewGridView(list.customId, 'personalView', index)}>
                                        {list.viewName}
                                        <span
                                            className="icon-close"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.deleteView(list.customId, 'personalView', index);
                                            }}></span>
                                    </li>
                                );
                            })}
                            {
                                <li
                                    key={'999'} className={"new-view " + (this.state.showSaveView ? "" : "hide")}
                                    onClick={() => {
                                        this.addNewVeiwPopup();
                                        props.toggleOptions();
                                    }}
                                >Save This View</li>
                            }
                        </ul>
                    </div>
                </div>
                <Modal
                    isOpen={this.state.isModalOpen}
                    addClass={`${(this.state.modalName == "addNewView") ? 'add-view-popup' : ''} `}>
                    {this.state.modalName === "viewDeleteConfirm" &&
                    <ViewDeleteConfirm
                        viewDetails={this.viewDetails}
                        confirmDelete={this.deleteViewConfirmed}
                        modalClose={this.modalClose}
                        user={this.props.user}
                    />
                    }
                    {this.state.modalName === "addNewView" &&
                    <AddNewViewPopup
                        modalClose={this.modalClose}
                        viewName={this.prepopulateName}
                        columnDetails={props.columnDetails}
                        updateNewView={this.updateNewView}
                        user={this.props.user}
                    />
                    }
                </Modal>
            </div>
        );
    }
}

// redux dispatch events mapping
const mapDispatchToprops = (dispatch) => {
    return {
        updateCurrentViewAction: (data) => {
            dispatch(actions.updateCurrentView(data));
        },
        _REDUX_DISPATCH_COLUMN_RESEQUENCE: (data) => {
            dispatch(actions.columnResequence(data));
        },
        actionPerformedFrom: (data) => {
            dispatch(p2Actions.actionPerformedFrom(data));
        },
    };
};

const mapStateToProps = (state) => {
    return {
        updateCurrentView: state.updateCurrentView,
        columnResequence: state.columnResequence,
        isColumnTouched: state.isColumnTouched,
    };
};

export default connect(mapStateToProps, mapDispatchToprops)(GridViewDropDown);
