import React, { Component } from 'react';
import axios from 'axios';
import NotificationManager from '../../../common/components/notification';
import CategoryServices from '../../services/category-services';

class CategoryTree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category: null,
            selectedCategoryId: null,
        };
        this.selectedCatHierarchy = { division: null, department: null, classColumn: null, subClassColumn: null };
        this.toggleView = this.toggleView.bind(this);
        this.refereshView = this.refereshView.bind(this);
        this.findCategoryObj = this.findCategoryObj.bind(this);
        this.setCategoryId = this.setCategoryId.bind(this);
    }

    componentDidMount() {
        const _this = this;

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        CategoryServices.productcategories().then((response) => {
            // let res = response.data.respPayload

            const res = response;

            const category = [];
            for (let i = 0; i < res.length; i++) {
                if (res[i].catName.toLowerCase() === 'department') {
                    category.push(res[i]);
                    break;
                }
            }
            _this.setState({ category: category }, () => this.openFirstCategoryDefault());
            setTimeout(function () {
                $('.product-treegrid .tree-node.level1').find('span:eq(0)').trigger('click');
            }, 300);
        })
            .catch((error) => {
                console.log('productContainer helperComponents categoryTree API call failed', error);
            });
    }

    // added to open the first category by default when the popup is opened - suraj
    openFirstCategoryDefault() {
        if (this.state.category) {
            this.toggleView(this.state.category[0].catId, 1);
        }
    }

    setCategoryId() {
        if (this.state.selectedCategoryId) {
            this.selectedCatHierarchy = { division: null, department: null, classColumn: null, subClassColumn: null };
            const data = this.state.category;
            const currentSelectedLevel = $('#SelectCategoryPopup .tree-node-list.selected').closest('.tree-node').attr('data-level');
            const length = parseInt(currentSelectedLevel);
            for (let i = length; i > 0; i--) {
                this.pushValueIntoObj(i);
            }
            // this.props.setCellValueAndSaveProduct(this.props.productId,'catId',this.state.selectedCategoryId,this.selectedCatHierarchy);  // commented for new ag-grid

            // below added for ag-grid
            const catId = this.state.selectedCategoryId;
            const catHierarchy = this.selectedCatHierarchy;
            this.props.setValue(catHierarchy);
            // above added for ag-grid

            // this.props.modalClose();
        } else {
            NotificationManager({ message: 'Please select any one category', type: 4 });
        }
    }

    /**
     * 	Pushes selected values into an object
     * @param {number} level Level to be pushed into the data object
     */
    pushValueIntoObj(level) {
        const catId = $('#SelectCategoryPopup .tree-node-list.selected').closest('.tree-node.level' + level).attr('data-catid');
        switch (level) {
            case 2:
                this.selectedCatHierarchy.division = catId;
                break;
            case 3:
                this.selectedCatHierarchy.department = catId;
                break;
            case 4:
                this.selectedCatHierarchy.classColumn = catId;
                break;
            case 5:
                this.selectedCatHierarchy.subClassColumn = catId;
                break;
            default:
                break;
        }
    }

    /**
     * Checks for the clicked category
     * @param {object} data Data object
     * @param {number} catId Category ID
     * @param {number} level Selected Level
     * @param {boolean} doSelect Bool for isSelected
     */
    findCategoryObj(data, catId, level, doSelect) {
        for (let i = 0; i < data.length; i++) {
            const o = data[i];
            o.isTabActive = o.isTabActive == undefined ? false : o.isTabActive;
            o.isOpen = o.isOpen == undefined ? false : o.isOpen;
            o.isSelected = o.isSelected == undefined ? false : o.isSelected;
            if (doSelect && level > 4) {
                o.isSelected = false;
            }
            if (o.catId === catId) {
                o.isOpen = !o.isOpen;
                o.isTabActive = !o.isTabActive;
                if (doSelect && level > 4) {
                    o.isSelected = true;
                    this.setState({ selectedCategoryId: o.catId }, () => {
                        this.setCategoryId();
                    });
                    // this.props.setValue(o.catId);
                    // this.state.selectedCategoryId = o.catId;
                }
            }
            if (o.productCategory.length) {
                this.findCategoryObj(o.productCategory, catId, level, doSelect);
            }
        }
    }

    /**
     * Toggles the category tree
     * @param {number} catId Category ID
     * @param {number} level Selected Level
     * @param {boolean} doSelect Bool for isSelected
     */
    toggleView(catId, level, doSelect) {
        const data = this.state.category;

        this.findCategoryObj(data, catId, level, doSelect);
        // if (this.state.selectedCategoryId) {
        //     this.setCategoryId();
        //     // this.props.enableOkayButton(true);
        // } else {
        //     // this.props.enableOkayButton(false);
        // }
        this.setState({ category: data });
    }

    /**
     * Refreshes the tree
     * @param {object} nextProps If new properties have been changed
     */
    refereshView(nextProps) {
        const data = nextProps.alerts;
        this.setState({ alerts: data });
    }

    /**
     * Opens up the category tree
     * @param {object} catlist List of all the categories
     * @param {number} level Current level number
     * @return {Element} categrory tree view items
     */
    categoryTreeView(catlist, level) {
        const items = catlist.map((category, index) => {
            return (
                <div key={index} className={'tree-node level' + level} data-level={level} data-catid={category.catId}>
                    <div className={`tree-node-list ${(category.isSelected) ? 'selected' : ''}`}>
                        {category.productCategory.length > 0 ?
                            <span className={`${(category.isOpen) ? 'icon-minus-blue' : 'icon-plus'}`} onClick={() => this.toggleView(category.catId, level)} />
                            : <span className={'no-expand'}>&nbsp;</span>}
                        <span id={'id-' + category.catId} className="category" data-node="0" data-catid={category.catId}
                            onClick={() => this.toggleView(category.catId, level, 1)}>{category.catName}</span>
                    </div>
                    {category.productCategory.length > 0 &&
                        <div className={`tree-node-item ${(category.isOpen) ? 'is-open' : ''}`}>
                            {this.categoryTreeView(category.productCategory, level + 1)}
                        </div>
                    }
                </div>
            );
        });
        return (<div>{items}</div>);
    }

    render() {
        const level = 1; // Default category level
        return (
            <div id="SelectCategoryPopup">
                {this.state.category &&
                    this.categoryTreeView(this.state.category, level)
                }
            </div>
        );
    }
}
export default CategoryTree;
