import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const LoginPageComponent = ({
    state,
    inputRef,
    forgotHandler,
    handleInputChange,
    toggleView,
    handleSubmit,
}) => {
    return (
        <div className="login-container">
            <div className="holder">
                <div className="login-form-holder">
                    <h1 className="login-header"><img src={require('../common/icons/SweftLogo-horizontal.svg')} /></h1>
                    <div className={'login-form-container ' + ((state.loginView) ? '' : 'forgot-view')}>

                        <div className="login-view">
                            <form method="" id="login-form" name="login-form" className="login-fields" onSubmit={handleSubmit}>
                                <p className="login-welcome-msg">Welcome, please sign in</p>

                                <fieldset className="login-fields">
                                    <label className="login-label"><img src={require('./images/ProfileIcon-login.svg')} /></label>
                                    <input autoFocus ref={inputRef} name="uname" id="uname" placeholder="Username"
                                        type="text" value={state.uname}
                                        className={(state.isDirty && state.uname === '') ? 'border-error' : ''}
                                        onChange={handleInputChange}/>
                                </fieldset>

                                <fieldset className="login-fields">
                                    <label className="login-label"><img src={require('./images/PasswordIcon.svg')} /></label>
                                    <input name="password" type="password"
                                        id="pwd" placeholder="Password"
                                        value={state.password}
                                        className={(state.isDirty && state.password === '') ? 'border-error' : ''}
                                        onChange={handleInputChange}
                                    />
                                </fieldset>

                                <div className="error"></div>
                                <div className="clearfix remember-me-holder">
                                    <a className="forgot-pass-link left" onClick={toggleView}>Forgot your password?</a>
                                    <label className="blue-checkbox right wt">
                                        <input type="checkbox" name="rememberMe" defaultChecked={state.rememberMe}
                                            onClick={handleInputChange}
                                        />
                                        <label></label>
                                          Remember Me
                                    </label>
                                </div>

                                <fieldset className="button-holder">
                                    <button type="submit" id="userSignin" name="userSignin" className="btn btn-default right login-button" >SIGN IN</button>
                                </fieldset>
                            </form>
                        </div>

                        <div className="forgot-view">

                            <form name="forgot-view" onSubmit={forgotHandler}>
                                <p className="login-welcome-msg"><strong>Forgot Password?</strong></p>
                                <p>Enter your recovery email associated with your account.</p>
                                <p className="error forgot-error"></p>
                                <fieldset className="login-fields">
                                    <label className="login-label"><img src={require('./images/ProfileIcon-login.svg')} /></label>
                                    <input name="id" type="email"
                                        id="email" placeholder="Email"
                                        value={state.id}
                                        onChange={handleInputChange}
                                    />
                                </fieldset>

                                <fieldset className="button-holder">
                                    <button type="submit" className="btn btn-default right login-button" >Send</button>
                                    <a onClick={toggleView} className="text-center block cancel-link">
                                        <i className="icon-close"></i> Cancel</a>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="text-center copyright-content">
                    {/* Copyright &copy; {getYear} SWEFT. All rights reserved. */}
                </div>

            </div>

            <div id="notifier-container" className="notifier-container"></div>

        </div>
    );
};

LoginPageComponent.propTypes = {
    state: PropTypes.object,
    forgotHandler: PropTypes.func,
    handleInputChange: PropTypes.func,
    toggleView: PropTypes.func,
    handleSubmit: PropTypes.func,
};

export default withRouter(LoginPageComponent);


//       <div className="forgot-view">
//         <form name="forgot-view" onSubmit={this.forgotHandler}>
//           <p className="login-welcome-msg"><strong>Forgot Password?</strong></p>
//           <p>Enter your recovery email associated with your account.</p>
//           <p className="error forgot-error"></p>
//           <fieldset className="login-fields">
//               <label className="login-label"><img src="/icons/ProfileIcon-login.svg"/></label>
//               <input name="id" type="email"
//                 id="email" placeholder="Email"
//                 value={this.state.id}
//                 onChange={this.handleInputChange}
//               />
//           </fieldset>
//           <fieldset className="button-holder">
//               <button type="submit" className="btn btn-default right login-button" >Send</button>
//               <a onClick={this.toggleView} className="text-center block cancel-link">
//                 <i className="icon-close"></i> Cancel</a>
//           </fieldset>
//         </form>
//       </div>

//   <div className="holder">

//     <div className="login-form-holder">
//     <h1 className="login-header"><img src="/icons/SweftLogo-horizontal.svg"/></h1>
//     <div className={"login-form-container" + ((this.state.loginView) ? "" : " forgot-view")}>
//       <div className="login-view">
//         <form method="" id="login-form" name="login-form" className="login-fields" onSubmit={this.handleSubmit}>
//         <p className="login-welcome-msg">Welcome, please sign in</p>

//             <fieldset className="login-fields">
//                 <label className="login-label"><img src="/icons/ProfileIcon-login.svg"/></label>
//                 <input name="uname" id="uname" placeholder="Username"
//                   type="text" value={this.state.uname}
//                   className={(this.state.isDirty && this.state.uname === '') ?'border-error':''}
//                   onChange={this.handleInputChange}/>
//             </fieldset>
//             <fieldset className="login-fields">
//                 <label className="login-label"><img src="/icons/PasswordIcon.svg"/></label>
//                 <input name="password" type="password"
//                   id="pwd" placeholder="Password"
//                   value={this.state.password}
//                   className={(this.state.isDirty && this.state.password === '') ?'border-error':''}
//                   onChange={this.handleInputChange}
//                 />
//             </fieldset>
//             <div className="error"></div>
//             <div className="clearfix remember-me-holder">
//               <a className="forgot-pass-link left" onClick={this.toggleView}>Forgot your password?</a>
//               <label className="blue-checkbox right wt">
//                 <input type="checkbox" name="rememberMe" checked={this.state.rememberMe}
//                   onClick={this.handleInputChange}
//                 />
//                 <label></label>
//                 Remember Me
//               </label>
//             </div>
//             <fieldset className="button-holder">
//               <button type="submit" id="userSignin" name="userSignin" className="btn btn-default right login-button" >SIGN IN</button>
//             </fieldset>
//         </form>
//       </div>

//       <div className="forgot-view">
//         <form name="forgot-view" onSubmit={this.forgotHandler}>
//           <p className="login-welcome-msg"><strong>Forgot Password?</strong></p>
//           <p>Enter your recovery email associated with your account.</p>
//           <p className="error forgot-error"></p>
//           <fieldset className="login-fields">
//               <label className="login-label"><img src="/icons/ProfileIcon-login.svg"/></label>
//               <input name="id" type="email"
//                 id="email" placeholder="Email"
//                 value={this.state.id}
//                 onChange={this.handleInputChange}
//               />
//           </fieldset>
//           <fieldset className="button-holder">
//               <button type="submit" className="btn btn-default right login-button" >Send</button>
//               <a onClick={this.toggleView} className="text-center block cancel-link">
//                 <i className="icon-close"></i> Cancel</a>
//           </fieldset>
//         </form>
//       </div>
//     </div>
//     </div>
//     <div className="text-center copyright-content">
//       Copyright &copy; 2017 SWEFT. All rights reserved.
//     </div>

//   </div>
