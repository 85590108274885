/*column width declaration
*/
const columnWidth = {
    userGroup: 500,
};
let DROPDOWNS_RESPONSE = null;


/*Date Editor funtion for Jqwidgets
*/
const createeditor = function (row, column, editor) {
    // assign a new data source to the combobox.
    const list = DROPDOWNS_RESPONSE.dropdowns['atrType'];
    editor.jqxDropDownList({
        autoDropDownHeight: true, source: list,
        displayMember: 'fieldName',
        valueMember: 'fieldId',
    });
};
// update the editor's value before saving it.
const cellvaluechanging = function (row, column, columntype, oldvalue, newvalue) {
    // return the old value, if the new value is empty.
    if (newvalue == '') {
        return oldvalue;
    }
};
const ddCellValueRender = function (row, datafield, value, rowData) {
    return '<div class=\'jqx-grid-cell-left-align\' style=\'margin-top:6px\'>' +
    value + '</div>';
};

const stringRenderer = function (row, columnfield, value, rowdata) {
    const val = (value) ? value.toString() : '';

    return ('<plaintext>' + val);
};

//Ends User Status - Enable/Disable

/* End User Grid Status*/
/* Grid data builder is used to create the object format required for
 * Jqwidgets.
*/
function GridDataBuilder(res) {
    DROPDOWNS_RESPONSE = res;
    // dataField is used for providing type of data and its name.
    // columnList is used to define column name, type, and extra fields
    //  to define additional plugins inside the cell eg: drop down, calender ...,
    const dataField = [];
    const columnList = [];
    let primayKey = res.columnDetails[0].fieldName;
    // check for the type of fieldname future

    //sort columndetails in product pageName
    res.columnDetails.sort(function (a, b) {
        return a.order - b.order;
    });

    for (let i = 0; i < res.columnDetails.length; i++) {
        dataField.push({
            'name': res.columnDetails[i].fieldName,
            'type': res.columnDetails[i].type.toLowerCase(),
        });

        /* Enable the below condition after pk(primary key) availabel from backend
    *  in JSON response.
    */

        if (res.columnDetails[i].hasOwnProperty('pk') && res.columnDetails[i].pk) {
            primayKey = res.columnDetails[i].fieldName;
        }

        columnList.push(columnObj(res.columnDetails[i]));
    }
    /* columnobj function will  return the object fields
  *  based on the field type in list.
  * e.g. for date type and other types need to build the cellrender function.
  */
    function columnObj(list) {
        const obj = {};

        obj.text = '<span title="' + list.columnTitle + '">' + list.columnTitle + '</span>';
        obj.dataField = list.fieldName;
        obj.width = (columnWidth[list.fieldName]) ? columnWidth[list.fieldName] : 120;
        obj.hidden = !list.visibility;
        /* if list type is date, pass the methods related to date to intialize
    *  date picker
    */
        if (list.type === 'DropDown') {
            obj.columntype = 'dropdownlist';
            obj.createeditor = createeditor;
            obj.cellvaluechanging = cellvaluechanging;
            obj.cellsrenderer = ddCellValueRender;
        } else if (list.fieldName === 'ordering') {
            obj.initeditor = function (row, column, editor) {
                editor.attr('maxlength', 5);
            };
        } else if (list.fieldName === 'userGroup') {
            obj.initeditor = function (row, column, editor) {
                editor.attr('maxlength', 150);
            };
        } else if (list.fieldName === 'exportCode') {
            obj.editable = true;
            obj.cellsrenderer = stringRenderer;
        }
        return obj;
    }


    const source = {
        dataType: 'json',
        dataFields: dataField,
        id: primayKey,
        localdata: res.data,
    };

    const dataAdapter = new $.jqx.dataAdapter(source);

    return {
        dataAdapter: dataAdapter,
        columns: columnList,
    };
}

export default GridDataBuilder;
