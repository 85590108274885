import React, { Component } from 'react';

class EmailCreator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: [],
            data: this.props.data,
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const data = Object.assign({}, this.state.data);
        data[name] = value;
        this.setState({ data: data });
    }
    componentWillReceiveProps(prevProps, nextProps) {
        if (this.props.isOpen !== prevProps.isOpen) {
            this.setState({ data: prevProps.data });
        }
    }
    render() {
        let templateId = 0;
        if (this.props.data) {
            templateId = this.props.data.id;
        }
        const style = {
            width: '60%',
            height: '200px',
        };
        const _this = this;
        return (
            <div className={`scroll add-edit-popup-emailtemplates ${(this.props.isOpen) ? 'active' : ''}`}>
                <div className="back" onClick={() => {
                    this.props.close();
                }}>&#8592;Back</div>
                <div className="modal-form">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <input type="hidden" className="currentRuleId" value={this.state.data.id} />
                                <label className="form-label">Type*</label>
                                <input type="text" className="form-control email-type" placeholder="Type" name="type" value={this.state.data.type}
                                    onChange={this.handleInputChange} readOnly={true} />
                            </div>
                        </div>
                    </div>
                    {/*
          <div className="row">
							<div className="col-12">
					      <div className="form-group">
                  <label className="form-label">Subject*</label>
                  <input type="text" className="form-control email-subject" placeholder="Subject" name="subject" value={this.state.data.subject}
                  onChange={this.handleInputChange}/>
                </div>
              </div>
          </div>
          */}
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label className="form-label">Content*</label>
                                <textarea style={style} className="form-control col-12 email-content" name="content" value={this.state.data.content} onChange={this.handleInputChange}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EmailCreator;
