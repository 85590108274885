import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import CategoryServices from '../../product2/services/category-services';

import * as actions from '../../admin/redux/adminActions';


class SpecialityCategoryTree extends Component {
    constructor(props) {
        super(props);

        this.state = {
            category: null,
            selectedCategoryId: null,
        };

        this.toggleView = this.toggleView.bind(this);
        this.findCategoryObj = this.findCategoryObj.bind(this);
        this.updateHierarchy = this.updateHierarchy.bind(this);

        this.selectCategory = this.selectCategory.bind(this);
        this.highlightData = this.highlightData.bind(this);
    }

    highlightData(data, catId) {
        let dataArray = data;
        let dataArrayLength = dataArray.length;

        for (let i = 0; i < dataArrayLength; i++) {
            if (parseInt(dataArray[i].catId) == catId) {
                dataArray[i].selected = true;

                break;
            }

            let catArray = dataArray[i].productCategory;
            let catArrayLength = catArray.length;

            if (catArrayLength > 0) {
                this.highlightData(catArray, catId);
            }
        }
    }

    componentDidMount() {
        const _this = this;

        if (_this.props.pageName == 'newUser') {
            CategoryServices.productcategories().then((response) => {
                console.log('User SpecialityCategoryTree componentDidMount getcatdepartmentlist API call success data: ', response);

                const res = response;
                const category = [];

                for (let i = 0; i < res.length; i++) {
                    if (res[i].catName.toLowerCase() === 'department') {
                        category.push(res[i]);

                        break;
                    }
                }

                _this.setState({ category: category });
                _this.props.addSpecialityData(category);
            }).catch((error) => {
                console.log('User SpecialityCategoryTree componentDidMount getcatdepartmentlist API call failed', error);
            });
        } else if (_this.props.pageName == 'editUser') {
            const postdata = {};
            postdata.id = parseInt(_this.props.userId);

            CategoryServices.productcategories().then((response) => {
                const res = response;
                const category = [];

                for (let i = 0; i < res.length; i++) {
                    if (res[i].catName.toLowerCase() === 'department') {
                        category.push(res[i]);

                        break;
                    }
                }

                let userSpeciality = this.props.user.speciality;

                if (userSpeciality) {
                    let tempArraySplitByComma = userSpeciality.split(',');

                    if (tempArraySplitByComma && tempArraySplitByComma.length > 1) {
                        let tempArrayCommaLength = tempArraySplitByComma.length;

                        for (let i = 0; i < tempArrayCommaLength; i++) {
                            let tempArraySplitByDash = tempArraySplitByComma[i].split('-');
                            let defCategoryId = tempArraySplitByDash && tempArraySplitByDash[0] ? tempArraySplitByDash[0] : null;

                            this.highlightData(category, defCategoryId);
                        }
                    } else {
                        let tempArray = userSpeciality.split('-');
                        let defaultCategoryId = tempArray && tempArray[0] ? tempArray[0] : null;

                        this.highlightData(category, defaultCategoryId);
                    }
                }

                _this.props.addSpecialityData(category);
                _this.updateHierarchy(category, category);
                _this.setState({ category: category });
            }).catch((error) => {
                console.log('User SpecialityCategoryTree componentDidMount getspecilitylistforupdate API call failed', error);
            });
        }
    }

    /**
     * Find category objects
     * @param {*} groupType
     * @param {*} data
     * @param {*} catId
     * @param {*} level
     * @param {*} doSelect
     * @param {*} parentCatIds
     * @param {*} parent
     */
    findCategoryObj(groupType, data, catId, level, doSelect, parentCatIds, parent) {
        for (let i = 0; i < data.length; i++) {
            const o = data[i];
            const parentSelected = false;

            o.isTabActive = o.isTabActive == undefined ? false : o.isTabActive;
            o.isOpen = o.isOpen == undefined ? false : o.isOpen;
            o.selected = o.selected == undefined ? false : o.selected;

            if (level != 1 && parentCatIds && parentCatIds.length > 0 && parentCatIds.indexOf(o.catId + '') > -1) {
                o.selected = false;
            }

            if (o.catId === catId) {
                if (doSelect && level != 1) {
                    o.selected = !o.selected;
                } else {
                    o.isOpen = !o.isOpen;
                    o.isTabActive = !o.isTabActive;
                }
            }

            if (o.productCategory.length) {
                this.findCategoryObj(groupType, o.productCategory, catId, level, doSelect, parentCatIds, parent);
            }
        }
    }

    /**
     * Updates category hierarchy
     * @param {*} data
     * @param {*} parent
     */
    updateHierarchy(data, parent) {
        for (let i = 0; i < data.length; i++) {
            const o = data[i];

            if (o.selected) {
                parent.isOpen = true;
            }

            if (o.productCategory.length) {
                o.productCategory.map(function (child, j) {
                    if (child.selected) {
                        o.isOpen = true;
                        parent.isOpen = true;
                    }

                    if (child.productCategory.length > 0) {
                        child.productCategory.map(function (grandChild, k) {
                            if (grandChild.selected) {
                                o.isOpen = true;
                                parent.isOpen = true;
                                child.isOpen = true;
                            }
                        });
                    }
                });

                this.updateHierarchy(o.productCategory, o);
            }
        }
    }

    /**
     * Toggles the view
     * @param {*} catId
     * @param {*} level
     * @param {*} groupType
     */
    toggleView(catId, level, groupType) {
        const data = this.state.category;

        this.findCategoryObj(groupType, data, catId, level, 0);
        this.setState({ category: data });
    }

    /**
     * selects the category
     * @param {*} event
     * @param {*} catId
     * @param {*} level
     * @param {*} groupType
     */
    selectCategory(event, catId, level, groupType) {
        const data = this.state.category;
        const target = event.target;
        const parentCatIds = [];
        const currentSelectedLevel = $(target).closest('.tree-node').attr('data-level');
        const length = parseInt(currentSelectedLevel, 10);

        for (let i = length - 1; i > 0; i--) {
            const datacatId = $(target).closest('.tree-node.level' + i).attr('data-catid');

            parentCatIds.push(datacatId);
        }

        $(target).closest('.tree-node').find('.tree-node').each(function () {
            const datacatId = $(this).attr('data-catid');

            parentCatIds.push(datacatId);
        });

        this.findCategoryObj(groupType, data, catId, level, 1, parentCatIds);
        this.setState({ data: data });
    }

    /**
     * Builds the tree view
     * @param {*} catlist
     * @param {*} level
     * @param {*} groupType
     * @return {object}
     */
    categoryTreeView(catlist, level, groupType) {
        const items = catlist.map((category, index) => {
            return (
                <div key={index} className={`tree-node level` + level} data-level={level} data-catId={category.catId} data-group={(level == 1) ? category.catName : groupType}>
                    <div className={`tree-node-list ${(category.isTabActive) ? 'active' : ''} ${(category.selected) ? 'selected' : ''}`}>
                        {
                            category.productCategory.length > 0
                                ?
                                <span className={`${(category.isOpen) ? 'icon-minus-blue' : 'icon-plus'}`}
                                    onClick={() => this.toggleView(category.catId, level, (level == 1) ? category.catName : groupType)}></span>
                                :
                                <span className={`no-expand`}>&nbsp;</span>
                        }

                        <span id={'id-' + category.catId} className={`category ${(category.selected) ? 'active' : ''}`} data-node="0" data-catid={category.catId}
                            onClick={(e) => {
                                this.selectCategory(e, category.catId, level, (level == 1) ? category.catName : groupType);
                            }} data-group={(level == 1) ? category.catName : groupType}>{category.catName}</span>
                    </div>

                    {
                        category.productCategory.length > 0 &&
                        <div className={`tree-node-item ${(category.isOpen) ? 'is-open' : ''}`}>
                            {this.categoryTreeView(category.productCategory, level + 1, (level == 1) ? category.catName : groupType)}
                        </div>
                    }
                </div>
            );
        });

        return (<div id="categoryFilter">{items}</div>);
    }

    render() {
        const level = 1;
        const groupType = '';

        return (
            <div>
                {this.state.category &&
                    this.categoryTreeView(this.state.category, level, groupType)
                }
            </div>
        );
    }
}

const mapDispatchToprops = (dispatch) => {
    return {
        addSpecialityData: (data) => {
            dispatch(actions.specialityData(data));
        },
    };
};

export default connect(null, mapDispatchToprops)(SpecialityCategoryTree);
