import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import JqxGrid from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';

import Modal from '../common/modals/commonModal';
import AddOrEdit from './helperComponents/addOrEdit';
import FieldPermissionsFooter from './helperComponents/footer';
import ConfirmDeleteModal from './helperComponents/confirmDeleteModal';

const FieldPermissionsComponent = ({
    state,
    props,
    fieldPermissionsGridRef,
    fieldPermissionsGridRef2,
    gridData,
    gridHeight,
    modalClose,
    addFieldRow,
    cloneTheKeys,
    parseKeys,
    fieldPermissionsGrid,

    addNewField,
    sendData,
    cancelChanges,
    deleteFields,

    deleteModalClose,

    onCellbeginedit,
    onRowselect,
    onRowunselect,
    onCellvaluechanged,
    onCelldoubleclick,
}) => {
    return (

        <div>
            {state.isLoaded &&
                <div className="field-permissions-grid">
                    <JqxGrid
                        ref={fieldPermissionsGridRef}
                        source={gridData.dataAdapter}
                        pageable={false}
                        checkboxes={true}
                        editable={true}
                        columns={gridData.columns}
                        sortable={true}

                        width={'100%'}
                        autoWidth={true}
                        autoRowHeight={false} // false shows the vertical scroll bar on the right side

                        height={gridHeight}
                        autoHeight={true}

                        resizable={true}
                        showsortmenuitems={false}
                        theme={'light'}
                        selectionmode={'checkbox'}
                        // editmode={'dblclick'}
                        editable={true}
                        columnsreorder={true}
                        columnsresize={true}
                        vertical={true}
                        columnsmenu={false}

                        onCellbeginedit={onCellbeginedit}
                        onRowselect={onRowselect}
                        onRowunselect={onRowunselect}
                        onCellvaluechanged={onCellvaluechanged}
                        onCelldoubleclick={onCelldoubleclick}
                    />
                </div>
            }
            <Modal isOpen={state.isModalOpen}>

                {
                    (state.currentModal === 'add') &&
                    <AddOrEdit
                        modalClose={modalClose}
                        header={'Add Field'}
                        title={'New'}
                        modalType={state.currentModal}
                        addFieldRow={addFieldRow}
                        cloneTheKeys={cloneTheKeys}
                        parseKeys={parseKeys}
                        grid={fieldPermissionsGrid}
                        user={props.user}
                    />
                }
                {
                    (state.currentModal === 'edit') &&
                    <AddOrEdit
                        modalClose={modalClose}
                        header={'Edit Field'}
                        title={state.rowData.displayName}
                        modalType={state.currentModal}
                        rowData={state.rowData}
                        cloneTheKeys={cloneTheKeys}
                        parseKeys={parseKeys}
                        grid={fieldPermissionsGrid}
                        rowIndex={state.activeRow}
                        user={props.user}
                    />
                }


            </Modal>

            <FieldPermissionsFooter
                addNewField={addNewField}
                applyChanges={sendData}
                cancelChanges={cancelChanges}
                isDeletable={state.isDeletable}
                deleteFields={deleteFields}
                isGridModified={state.isGridModified}
                ref={fieldPermissionsGridRef2}
            />

            <ConfirmDeleteModal
                isOpen={state.isDeleteModalOpen}
                modalClose={deleteModalClose}
            />
        </div>


    );
};

FieldPermissionsComponent.propTypes = {
    state: PropTypes.object,
    props: PropTypes.object,

};

export default withRouter(FieldPermissionsComponent);


