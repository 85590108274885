import React from 'react';
import { ProductServices } from '../../product2/services/product-services';
import NotificationManager from '../../common/components/notification';

class CopyInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            copyWriteData: [],
            selectedRowData: [],
            displayName: '',
            showRejectSection: false,
            copyNotes: '',
            disableReject: true,
        };

        this.cancelChanges = this.cancelChanges.bind(this);
        this.onChange = this.onChange.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.approve = this.approve.bind(this);
        this.reject = this.reject.bind(this);
    }

    componentDidMount() {
        console.log('CopyInfo componentDidMount', this.props);

        const _this = this;

        let contenteditable = 'true';

        if (_this.props.enableSubmitSave) {
            contenteditable = 'false';
        }

        $('.jqte-test').jqte({
            change: function () {
                console.log('cvlaue changed checks');
            },
        });
    }

    onChange(e) {
        const newVal = e.currentTarget.value;
        const id = e.currentTarget.id;

        if (id == 'copy-notes') {
            this.setState({ copyNotes: newVal });

            if (newVal.length > 0) {
                this.setState({ disableReject: false });
            } else {
                this.setState({ disableReject: true });
            }
        } else {
            this.setState({ newDisplayName: newVal });
        }
    }

    saveChanges() {
        let postdata = [];

        const shortDesc = $('.editor-short-desc').val();
        const fullDesc = $('.editor-long-desc').val();

        let dispVal = this.state.newDisplayName ? this.state.newDisplayName : this.state.displayName;

        postdata.push({
            'productId': this.props.selectedRowData.productId,
            'displayName':  dispVal,
            'shortdesc': shortDesc,
            'longdesdc':  fullDesc,
            'copyNotes':  this.state.copyNotes,
        });

        ProductServices.updateCopyWritter(postdata)
            .then((response) => {
                console.log('copyWriter helperComponents saveChanges API call success data: ', response.data);
                const res = response.data;
                let setNewStatus = this.state.selectedRowData;
                setNewStatus.copyStatus = res.currentStatus;

                NotificationManager({ message: res.message, type: 1 });
                this.props.setNewCopyStatus(setNewStatus);
            })
            .catch((error) => {
                console.log('copyWriter helperComponents saveChanges API call success data', error);
            });
    }

    submitChanges() {
        let postdata = [];

        const shortDesc = $('.editor-short-desc').val();
        const fullDesc = $('.editor-long-desc').val();

        let dispVal = this.state.newDisplayName ? this.state.newDisplayName : this.state.displayName;

        postdata.push({
            'productId': this.props.selectedRowData.productId,
            'displayName':  dispVal,
            'shortdesc': shortDesc,
            'longdesdc':  fullDesc,
            'copyNotes':  this.state.copyNotes,
        });

        ProductServices.submitCopyWritter(postdata)
            .then((response) => {
                console.log('copyWriter helperComponents submitChanges API call success data: ', response.data);

                const res = response.data;

                let setNewStatus = this.state.selectedRowData;
                setNewStatus.copyStatus = res.currentStatus;

                NotificationManager({ message: res.message, type: 1 });
                this.props.setNewCopyStatus(setNewStatus);
            })
            .catch((error) => {
                console.log('copyWriter helperComponents submitChanges API call success data', error);
                NotificationManager({ message: error, type: 4 });
            });
    }

    approveRejectApiCall(data) {
        const _this = this;

        ProductServices.approveByBuyer(data)
            .then((response) => {
                console.log('copyWriter helperComponents approveRejectApiCall API call success data: ', response.data);

                const res = response.data;

                let setNewStatus = _this.state.selectedRowData;
                setNewStatus.copyStatus = res.currentStatus;

                NotificationManager({ message: res.message, type: 1 });
                _this.props.setNewCopyStatus(setNewStatus);
            })
            .catch((error) => {
                console.log('copyWriter helperComponents approveRejectApiCall API call success data:', error);

                NotificationManager({ message: error, type: 4 });
            });
    }

    approve() {
        const data = [{
            'productcode': this.props.selectedRowData.productId,
            'status': true,
            'processName': 'copywritter',
            'copynotes': this.state.copyNotes,
            'userid': this.props.user.userId,
        }];

        this.approveRejectApiCall(data);
    }

    reject() {
        this.setState({ showRejectSection: true });
    }

    rejectCopy() {
        const data = [{
            'productcode': this.props.selectedRowData.productId,
            'status': false,
            'processName': 'copywritter',
            'copynotes': this.state.copyNotes,
            'userid': this.props.user.userId,
        }];

        this.approveRejectApiCall(data);
    }

    cancelChanges() {
        this.setState((state, props) => ({
            copyWriteData: state.copyWriteData,
            newDisplayName: '',
        }));

        $(".editor-short-desc").jqteVal(this.state.copyWriteData.shortdesc);
        $(".editor-long-desc").jqteVal(this.state.copyWriteData.longdesdc);
    }

    componentWillReceiveProps(nextProps) {
        const _this = this;

        console.log('copy info componentWillReceiveProps', nextProps.selectedRowData, this.props.selectedRowData, this.state);

        if (nextProps.selectedRowData && _this.props.selectedRowData && nextProps.selectedRowData.productId != _this.props.selectedRowData.productId) {
            _this.setState({ selectedRowData: nextProps.selectedRowData }, () => {
                _this.getData();
            });
        }
    }

    getData() {
        const _this = this;

        let sendId = null; // Value to be sent to the backend

        if (_this.state.selectedRowData.dataLevel == 'SINGLE') {
            sendId = _this.state.selectedRowData.chldProdId;
        } else if (_this.state.selectedRowData.dataLevel == 'PARENT') {
            sendId = _this.state.selectedRowData.parentProductId;
        } else if (_this.state.selectedRowData.dataLevel == 'INTERMEDIATE') {
            sendId = _this.state.selectedRowData.intProdId;
        } else if (_this.state.selectedRowData.dataLevel == 'CHILD') {
            sendId = _this.state.selectedRowData.chldProdId;
        }

        ProductServices.getCopyWritter({ id: sendId })
            .then((response) => {
                console.log('copy info API call success data: ', response.data);

                const res = response.data;

                if (res.status) {
                    _this.setState ({copyWriteData: res.data, displayName: res.data.displayName});
                    $(".editor-short-desc").jqteVal(res.data.shortdesc);
                    $(".editor-long-desc").jqteVal(res.data.longdesdc);
                } else {
                    console.log('Product data doesnot contains descriptions');
                }
            })
            .catch((error) => {
                console.log('copy info  API call failed', error);
            });
    }

    render () {
        console.log('copy info render', this.props, this.state);

        const data = this.state.copyWriteData;

        return(
            <div className="cw-right-section">
                <div className="row">
                    <label className="cw-info-label">Display name</label>
                    <input type="text" className="form-control" name="displayName" disabled={this.props.enableSubmitSave} onChange={this.onChange} value={this.state.newDisplayName ? this.state.newDisplayName : this.state.displayName} />
                </div>

                <div className="row">
                    <label className="cw-info-label">Product Copy</label>
                    <textarea name="textarea" className="jqte-test editor-short-desc" value={data && data.shortdesc ? data.shortdesc : ''} />
                </div>

                <div className="row">
                    <label className="cw-info-label">Product Details</label>
                    <textarea name="textarea" className="jqte-test editor-long-desc" value={data && data.longdesdc ? data.longdesdc : ''} />
                </div>

                { this.state.showRejectSection &&
               <div>
                   <div className="row">
                       <label className="cw-info-label">Copy Notes</label>
                       <textarea autoFocus name="textarea" className="copy-notes" id='copy-notes' onChange={this.onChange} defaultValue={data && data.copyNotes ? data.copyNotes : ''} />
                   </div>
                   <div className="row">
                       <button disabled={this.state.disableReject} onClick={() => this.rejectCopy()} className='cw-reject'>REJECT</button>
                   </div>
               </div>
                }

            </div>
        );
    }
}

export default CopyInfo;
