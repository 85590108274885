import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '../../modals/common-modal.js';
import ScanModal from '../../modals/scan-modal.js';
import AddRecordsFromFiles from './addRecordsFromFiles';
import { addProductRow, updateSelectedRows, deleteSelectedProducts, cloneSelectedProducts, gridDataChanged, addGridData } from '../redux/productActions';
import axios from 'axios';
// var NotificationManager = require('../../common/notification');
import NotificationManager from '../../common/components/notification';

import { ProductServices } from '../../product2/services/product-services';

class ProductFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clickedOnAddRecord: false,
            isModalOpen: false,
            modalName: 'files',
            showViewBy: false,
            scanModalOpen: false,
            totalrows: props.totalRows
        };

        this.modalOpen = this.modalOpen.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.addRecordFiles = this.addRecordFiles.bind(this);
        this.addSingleRecord = this.addSingleRecord.bind(this);
        this.deleteSelectedRecord = this.deleteSelectedRecord.bind(this);
        this.cloneSelectedProducts = this.cloneSelectedProducts.bind(this);
        this.triggerViewBy = this.triggerViewBy.bind(this);
        this.importPO = this.importPO.bind(this);
        this.exportPO = this.exportPO.bind(this);
        this.openScanModal = this.openScanModal.bind(this);
        this.closeScanModal = this.closeScanModal.bind(this);
        this.viewBy = this.viewBy.bind(this);
        this.reloadGrid = this.reloadGrid.bind(this);
        this.getTotalCount = this.getTotalCount.bind(this);
        this.getTotalRows = this.getTotalRows.bind(this);
    }

    viewBy(e) {
        var _this = this;
        var target = $(e.target);
        var value = target.text();
        var Urlval;
        if (value === 'Size/Sku') {
            value = 'Sku';
        }
        _this.triggerViewBy();
        var sourceFrom = this.props.sourceFrom;


        var postData = null;
        var method = null;
        var url = null;
        if (sourceFrom.type) {
            Urlval = sourceFrom.url;
            Urlval = Urlval.replace("null", value);
            method = sourceFrom.serviceType;
            postData = sourceFrom.navObj;
            url = Urlval;
            postData;
        } else {
            method = 'post';
            var filter = this.props.appliedFilters;
            url = require('../../common/configurations.js').product.filters;
            if (filter.type == "search") {
                url = require('../../common/configurations.js').product.productsearch;
            }
            filter.viewBy = value;
            postData = filter;
        }

	    let headers = {
	          'Authorization': this.props.user.token,
	          'content-type': 'application/json'
	    };
	    axios({
	      method: 'post',
	      url: require('../../common/configurations.js').product.filters,
	      headers: headers,
	      data: postData
	    })
	    .then( (response) => {
                let res = response.data;

                if (res.data) {
                    _this.props.addGridData(res.data);
                    _this.props.gridDataChanged();
                }
	    })
	    .catch( error => {
	        console.log('productContainer helperComponents footer API call failed', error);
	    });
    }

    openScanModal() {
        this.setState({ scanModalOpen: true });
    }

    closeScanModal() {
        this.setState({ scanModalOpen: false });
    }

    componentDidMount() {
        let _this = this;
        // $(document).on('click','body', function(event){
        // 	if($(event.target).is('.dropup')){
        // 		_this.setState({showViewBy: true});
        // 	} else
        // 	if(!$(event.target).closest('.dropup').length && !$(event.target).is('.dropup ul')) {
        // 		_this.setState({showViewBy: false});;
        // 	}
        // });
        // to hide dropdowns when user clicks outside the field area
        $(document).on('click.addrecord', function (e) {
            e.stopPropagation();
            var addRecord = '.record-action';

            if ($(addRecord).hasClass('open') && $(e.target).closest('.record-creation-holder').length === 0) {
                _this.toggleRecordOptions();
            }
        });

        if(this.props.storeGridData.length === 0 ) {
            // $('.gray-button').attr('disabled',true);
            $('.record-options').css('pointer-events', 'none');
        }
    }

    componentWillUnmount() {
        $(document).off('click.addrecord');
    }

    toggleRecordOptions() {
        this.setState((prevState, props) => (
            {
                clickedOnAddRecord: !prevState.clickedOnAddRecord
            })
        );
    }

    modalOpen() {
        this.setState({ isModalOpen: true });
    }

    modalClose() {
        this.setState({ isModalOpen: false });
    }
    addSingleRecord() {
        if(this.props.storeGridData.length === 0 ) {
            NotificationManager({ message: 'Please populate the Product Grid and Try again.', type: 4 });
        } else {
            this.toggleRecordOptions();
            this.props.callAddSingleRecord();
        }
    }

    importPO() {
        if(this.props.storeGridData.length === 0 ) {
            NotificationManager({ message: 'Please populate the Product Grid and Try again.', type: 4 });
        } else {
            this.modalOpen();
            this.toggleRecordOptions();
            this.actionRef = {
                method: "ImportPO",
                popupHeading: "Import PO from file[csv/json]"
            };
            this.setState({ modalName: 'files' });
        }
    }

    exportPO() {
        let req = [];
        this.props.selectedRows.map((prod) => {
            if (prod.dataLevel === "Sku") {
                req.push(prod.productId);
            }
        });

	    let headers = {
	          'Authorization': this.props.user.token,
	          'content-type': 'application/json'
	    };

        ProductServices.exportPO(req)
            .then( (response) => {
	        let res = response.data;

                if (res.status) {
                    NotificationManager({ message: res.responseMessage, type: 1 });
                } else {
                    NotificationManager({ message: res.responseMessage, type: 4 });
                }
	    })
	    .catch( error => {
	        console.log('productContainer updateAttrCompleted  API call failed', error);
                NotificationManager({ message: 'Failed to export PO', type: 4 });
	    });

        this.toggleRecordOptions();
    }

    addRecordFiles() {
        if(this.props.storeGridData.length === 0 ) {
            NotificationManager({ message: 'Please populate the Product Grid and Try again.', type: 4 });
        } else {
            this.modalOpen();
            this.toggleRecordOptions();
            this.actionRef = {
                method: "ImportProduct",
                popupHeading: "Add records from file[csv/json]"
            };
            this.setState({ modalName: 'files' });
        }
    }

    deleteSelectedRecord() {
        this.props.callDeleteSelectedProducts();
    }

    cloneSelectedProducts() {
        if(this.props.storeGridData.length === 0 ) {
            NotificationManager({ message: 'Please populate the Product Grid and Try again.', type: 4 });
        } else {
            this.toggleRecordOptions();
            if (this.props.selectedRows && this.props.selectedRows.length > 0) {
                this.props.callCloneSelectedProducts();
            } else {
                NotificationManager({ message: "Please select atleast one record to clone", type: 4 });
            }
        }
    }

    // Droupdown toggle event
    triggerViewBy(event) {
        let { showViewBy } = this.state;
        this.setState({ showViewBy: !showViewBy });
    }

    getTotalRows(result, rows) {
        for (var i = 0; i < rows.length; i++) {
            result.push(rows[i]);
            if (rows[i].children && rows[i].children.length > 0) {
                this.getTotalRows(result, rows[i].children);
            }
        }
    }

    getTotalCount(nextProps) {
        try {
            var totalrows = nextProps.productGrid.getView();
            var result = [];
            this.getTotalRows(result, totalrows);
            this.setState({ totalrows: result.length });
        }
        catch(e) {
            console.log(e);
        }
    }

    //Reload grid
    reloadGrid() {
        this.props.toggleSpinner(true);
        this.props.reloadGrid();
    }

    componentWillReceiveProps(nextProps) {
        //if(this.props.isfilterApplied!=nextProps.isfilterApplied){
        if (nextProps.productGrid) {
            this.getTotalCount(nextProps);
        }
        //}
    }

    render() {
        let modalContent;
        var selectedRowsCount = this.props.selectedRows.length;
        switch (this.state.modalName) {
            case "files":
                modalContent = <AddRecordsFromFiles modalClose={this.modalClose} actionRef={this.actionRef} user={this.props.user}/>;
                break;
            default:
                modalContent = null;
        }

        return (
            <div className="product-footer footer">
                <div className="record-creation-holder">
                    <div className={`record-action gray-button ${(this.state.clickedOnAddRecord) ? "open active" : ''}`}
                        onClick={this.toggleRecordOptions.bind(this)}>Add Record</div>
                    <div className="record-options">
                        <ul>
                            <li onClick={this.addSingleRecord}>Add Record [to grid]</li>
                            <li onClick={this.addRecordFiles}>Add Record(s) from file [csv/json]</li>
                            <li onClick={this.cloneSelectedProducts}>Clone Record</li>
                            <li onClick={this.importPO}>Import PO[csv/json]</li>
                            {/* <li onClick={this.exportPO}>Export PO</li> */}
                        </ul>
                    </div>
                </div>
                <div className="record-delete-holder inline-block">
                    <button type="button" className="gray-button"
                        disabled={this.props.selectedRows.length > 0 ? false : true}
                        onClick={this.deleteSelectedRecord}>
						Delete Record
                    </button>
                </div>

                <div className="footer-content right">
                    {(this.props.user.defaultRole.toLowerCase() == "planner") &&
						<button type="button" className="gray-button left record-delete-holder" disabled={(this.props.selectedRows && this.props.selectedRows.length > 0) ? false : true} onClick={this.exportPO}>Export PO</button>
                    }
                    <button type="button" className="gray-button left record-delete-holder" onClick={this.reloadGrid}>Reload</button>
                    <button type="button" className="gray-button left record-delete-holder" onClick={this.openScanModal}>Scan Actions
                    </button>

                    <div className={`dropup left ${(this.state.showViewBy) ? 'active' : ''}`}>
                        <button className="creative-viewby footer-btn gray-button" onClick={this.triggerViewBy}>View By</button>
                        <ul>
                            <li onClick={this.viewBy}>Color</li>
                            <li onClick={this.viewBy}>Size/Sku</li>
                            <li onClick={this.viewBy}>Style</li>
                        </ul>
                    </div>
                    <button type="button" className="gray-button" disabled={!this.props.isUndoEnable}
                        onClick={this.props.undoLastChanges}>Undo Last changes
                    </button>

                </div>
                <div className="total-row-count">
                    <span className="total-rows">{this.state.totalrows} Total Rows</span>
                    <span className="selected-rows"> | Selected: {selectedRowsCount} </span>
                </div>
                <Modal isOpen={this.state.isModalOpen}>
                    {modalContent}
                </Modal>
                <ScanModal isOpen={this.state.scanModalOpen} modalClose={this.closeScanModal} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selectedRows: state.selectedRows,
        appliedFilters: state.appliedFilters,
    };
};

const mapDispatchToprops = (dispatch) => {
    return {
        callAddSingleRecord: () => {
            dispatch(addProductRow());
        },
        updateSelectedRows: (data) => {
            dispatch(updateSelectedRows(data));
        },
        callDeleteSelectedProducts: () => {
            dispatch(deleteSelectedProducts());
        },
        callCloneSelectedProducts: () => {
            dispatch(cloneSelectedProducts());
        },
        addGridData: (data) => {
            dispatch(addGridData(data));
        },
        gridDataChanged: () => {
            dispatch(gridDataChanged());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToprops)(ProductFooter);

