import React from 'react';
import axios from 'axios';
import {endpoint} from '../REST/endpoints';
import {headers} from '../REST/headers';

export const UserServices = {
    getUser:  function() {
        //if user login 1st time, header obj won't have token so we need to pull from local storage
        if (!headers.Authorization && localStorage.getItem('token')) {
            headers.Authorization = localStorage.getItem('token');
        }

        return axios({
            method: endpoint.getUser.method,
            url: endpoint.getUser.url,
            headers: headers
        })
            .then( (userResponse) => {
                userResponse.data.responseData.token = headers.Authorization;
                return userResponse;
            });
    },

    getUserData: function(payload) {
        return axios({
            method: endpoint.getUserData.method,
            url: endpoint.getUserData.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    insertUsersData: function(payload) {
        return axios({
            method: endpoint.insertUsersData.method,
            url: endpoint.insertUsersData.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },

    updateUserStatus: function(payload) {
        return axios({
            method: endpoint.admin.updateUserStatus.method,
            url: endpoint.admin.updateUserStatus.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
};
