import React from 'react';
import ConfirmModal from '../../common/modals/confirmModal';

class CancelModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
        };

        this.closeConfirmModal = this.closeConfirmModal.bind(this);
    }

    closeConfirmModal(key) {
        this.props.modalClose(key);
    }
    render() {
        return (<ConfirmModal isOpen={this.props.isOpen}>
            <div className="modal-content">
                <h3 className="confirm-warning-text">Are you sure you want to delete the product(s)?</h3>
                <div className="modal-footer">
                    <input type="button" className="gray-button" value="No" onClick={() => this.props.modalClose()}></input>
                    <input type="button" className="gray-button" value="Yes" onClick={this.props.confirmDelete}></input>
                </div>
            </div>
        </ConfirmModal>);
    }
}

const mapSateToProps = (state) => {
    return {
        userView: state.userView,
    };
};

export default CancelModal;

