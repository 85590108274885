import React from 'react';
import DismissableConfirmModal from '../../common/modals/dismissableConfirmModal';

class NavigateAway extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
        };

        this.closeConfirmModal = this.closeConfirmModal.bind(this);
    }

    closeConfirmModal(key) {
        this.props.modalClose(key);
    }

    render() {
        return (
            <DismissableConfirmModal isOpen={this.props.isOpen} close={() => this.props.modalClose()}>
                <div className="modal-content">
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', cursor: 'pointer' }} onClick={() => this.props.modalClose()}>X</div>
                    <h1 style={{ fontSize: '20px' }} className="confirm-warning-text">
                        <p>Your changes are still saving.</p>
                        <p>Please click "OK" and allow another few seconds before proceeding to allow your updates to finish saving.</p>
                    </h1>
                    <div className="modal-footer">
                        <input type="button" className="gray-button" value="Ok" onClick={() => this.props.modalClose()} />
                    </div>
                </div>
            </DismissableConfirmModal>
        );
    }
}

export default NavigateAway;
