import React from 'react';
import CancelModal from '../../common/modals/cancelModal.js';
import axios from 'axios';
import NotificationManager from '../../common/components/notification';
import AdminServices from '../../admin/admin-services/admin-services.js';

class EditGlobalSetting extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            settings: [],
            settingsList: [],
            isDirty: false,
            errorMessage: '',
            isModified: false,
        };

        this.toggleViewCheckbox = this.toggleViewCheckbox.bind(this);
        this.toggleEditCheckbox = this.toggleEditCheckbox.bind(this);
        this.updateSetting = this.updateSetting.bind(this);

        this.openModalCancel = this.openModalCancel.bind(this);
        this.modalClose = this.modalClose.bind(this);
    }

    componentDidMount() {
        this.getSettings();
    }

    /**
     * Closes the modal
     * @param {*} key
     */
    modalClose(key) {
        this.setState({isModalOpen: false});

        switch (key) {
            case 'updateUser':
                this.updateSetting();
                break;

            case 'no':
                this.props.modalClose();
                break;
        }
    }

    /**
     * Opens the modal
     */
    openModalCancel() {
        if (this.state.isModified) {
            this.setState({ isModalOpen: true });
        } else {
            this.props.modalClose();
        }
    }

    /**
     * Calls backend to get current settings
     */
    getSettings() {
        const _this = this;

        AdminServices.getUserGlobalPermission().then((response) => {
            const data = response;
            const newList = data;

            newList.map((e, index) => {
                if (e.permission === 'W') {
                    newList[index].viewByUser = true;
                    newList[index].editByUser = true;
                } else if (e.permission === 'R') {
                    newList[index].viewByUser = true;
                    newList[index].editByUser = false;
                } else {
                    newList[index].viewByUser = false;
                    newList[index].editByUser = false;
                }
            });

            _this.setState({ settingsList: newList });
        }).catch((error) => {
            console.log('User EditGlobalSetting getSettings API call failed', error);
        });
    }

    /**
     * Toggles footer view
     * @param {*} key
     */
    toggleViewCheckbox(key) {
        const {settingsList} = this.state;
        const newSettingsList = settingsList;

        newSettingsList.forEach((e, index) => {
            if (index == key) {
                e.viewByUser = !e.viewByUser;
            }
        });

        this.setState({ settingsList: newSettingsList, isModified: true });
    }

    /**
     * Toogle edit checkboxes
     * @param {*} key
     */
    toggleEditCheckbox(key) {
        const {settingsList} = this.state;
        const newSettingsList = settingsList;

        newSettingsList.forEach((e, index) => {
            if (index == key) {
                e.editByUser = !e.editByUser;

                if (e.editByUser) {
                    e.viewByUser = true;
                }
            }
        });

        this.setState({ settingsList: newSettingsList, isModified: true });
    }

    /**
     * Calls backend to save current setting
     */
    updateSetting() {
        const _this = this;
        const settingsList = _this.state.settingsList;
        const newSettingsList = [];

        settingsList.forEach((e, index) => {
            newSettingsList.push({ id: e.id, fields: e.fields });

            if (e.viewByUser && e.editByUser) {
                newSettingsList[index].permission = 'W';
            } else if (e.viewByUser && !e.editByUser) {
                newSettingsList[index].permission = 'R';
            } else {
                newSettingsList[index].permission = 'N';
            }
        });

        const req = newSettingsList;

        AdminServices.updateUserGlobalPermission(req).then((response) => {
            const res = response;

            if (res.status) {
                _this.props.modalClose();

                NotificationManager({
                    message: res.responseMessage,
                    type: 1,
                });
            } else {
                _this.setState({
                    isDirty: true,
                    errorMessage: res.responseMessage
                });
            }
        }).catch((error) => {
            _this.setState({ isDirty: true, errorMessage: 'Failed!!' });

            console.log('User EditGlobalSetting updateSetting API call failed', error);
        });
    }

    render() {
        const permissionList = this.state.settingsList.map((val, index) => {
            return (
                <div className="form-group" key={index}>
                    <div className="row">
                        <div className="col-4">
                            <label className="form-label">{val.displayName}:</label>
                        </div>
                        <div className="col-4">
                            <label className="form-check-label">
                                <input
                                    type="checkbox"
                                    className="form-control-check"
                                    id={val.fields}
                                    name={val.fields}
                                    checked={val.viewByUser}
                                    disabled={val.editByUser}
                                    onChange={() => this.toggleViewCheckbox(index)}
                                />
                                <span htmlFor={val.fields} className="from-checkbox"></span>
                            </label>
                        </div>
                        <div className="col-4">
                            <label className="form-check-label">
                                <input
                                    type="checkbox"
                                    className="form-control-check"
                                    id={`${val.fields}-edit`}
                                    name={`${val.fields}-edit`}
                                    checked={val.editByUser}
                                    onChange={() => this.toggleEditCheckbox(index)}
                                    disabled={(val.fields === 'speciality')}
                                />
                                <span htmlFor={`${val.fields}-edit`} className={'from-checkbox ' + (val.fields === 'speciality' ? 'disabled' : '') }></span>
                            </label>
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div>
                <div className="modal-header">
                    Admin Global Profile Settings
                    <span className="close" onClick={this.openModalCancel}>x</span>
                </div>
                <div className="modal-content">
                    <div className="admin-global-setting modal-form">
                        <div className="row">
                            <div className="col-4"></div>
                            <div className="col-4">
                                <span className="form-subtitle">Viewable by User</span>
                            </div>
                            <div className="col-4">
                                <span className="form-subtitle">Editable by User</span>
                            </div>
                        </div>
                        <form className="admin_global_setting" name="user-profile" id="admin_global_setting">
                            <div className="form-container scroll">
                                {permissionList}
                            </div>
                            <div className="modal-footer">
                                <input
                                    type="button"
                                    className="gray-button"
                                    value="Cancel"
                                    onClick={this.openModalCancel} />
                                <input
                                    type="button"
                                    className="gray-button"
                                    value="Save"
                                    onClick={this.updateSetting} />
                            </div>
                            {this.state.isDirty &&
                            <span className="error-message">{this.state.errorMessage}</span>
                            }
                        </form>
                    </div>
                </div>
                <CancelModal
                    data={this.state.user}
                    isOpen={this.state.isModalOpen}
                    modalClose={this.modalClose} />
            </div>
        );
    }
}

export default EditGlobalSetting;
