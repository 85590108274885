import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import JqxGrid from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';

import UserEditModal from './helperComponents/userEditModal';
import AdminFooter from './helperComponents/adminFooter.js';

const UserComponent = ({
    state,
    props,

    userGridReference,
    gridData,
    gridHeight,
    editSettings,

    modalClose,
    userUpdateData,
    updateGridRow,

    removeGridRows,
    addUserRow,
    undoLastChanges,
    onCellvaluechanged,
    onRowselect,
    onRowunselect,
    onCellbeginedit,
    onCelldoubleclick,

    reloadGrid,
}) => {
    return (
        <div>
            <div className="pos-relative">
                {state.isLoaded &&
                    <div id="userGrid">
                        <JqxGrid
                            ref={userGridReference}
                            width={'100%'}
                            onCellvaluechanged = {onCellvaluechanged}
                            onCellbeginedit = {onCellbeginedit}
                            onRowselect = {onRowselect}
                            onRowunselect = {onRowunselect}
                            onCelldoubleclick = {onCelldoubleclick}
                            source={gridData.dataAdapter}

                            pageable={false}
                            checkboxes={true}
                            editable={'click'}
                            hierarchicalCheckboxes={true}
                            columnsResize={true}
                            columns={gridData.columns}
                            sortable={true}
                            autoRowHeight={false}
                            autoWidth={true}
                            height={gridHeight}
                            enabletooltips={true}
                            enableellipsis={true}
                            theme={'light'}
                            selectionmode={'checkbox'}
                            editSettings={editSettings}
                            columnsreorder={true}
                            columnsmenu={false}
                        />

                        {
                            state.isModalOpen &&
                            <UserEditModal
                                isOpen={state.isModalOpen}
                                modalClose={modalClose}
                                userUpdateData={userUpdateData}
                                updateGridRow={updateGridRow}
                                user={props.user}
                                reloadGrid = {reloadGrid}
                            />
                        }
                    </div>
                }
                {
                    state.isLoaded &&
                    <AdminFooter
                        userCount={state.userTotalCount}
                        selectedRows={state.selectedRowsCount}
                        checkedRows={state.checkedRows}
                        checkedUserData={state.checkedUserData}
                        removeRows={removeGridRows}
                        addUserRow={addUserRow}
                        undoLastChanges={undoLastChanges}
                        isUndoEnable={state.isUndoEnable}
                        user={props.user}
                        reloadGrid = {reloadGrid}
                    />
                }
            </div>
        </div>
    );
};

UserComponent.propTypes = {
    state: PropTypes.object,
    props: PropTypes.object,
};

export default withRouter(UserComponent);
