import React from 'react';
import axios from 'axios';
import request from '../../common/REST/request';
import {endpoint} from '../../common/REST/endpoints';
import {headers} from '../../common/REST/headers';

var CategoryServices = {
    /** Category List API with search query **/
    productcategories: function(payload, query) {
        return request({
            method: endpoint.category.productcategories.method,
            url: endpoint.category.productcategories.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    subcategories: function(payload, query) {
        return request({
            method: endpoint.category.subcategories.method,
            url: endpoint.category.subcategories.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    saveCategory: function(payload, query) {
        return request({
            method: endpoint.category.saveCategory.method,
            url: endpoint.category.saveCategory.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    deleteCategory: function(payload, query) {
        return request({
            method: endpoint.category.deleteCategory.method,
            url: endpoint.category.deleteCategory.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
};


export default CategoryServices;
