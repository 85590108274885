import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../redux/marketingActions';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import CancelModalForPhoto from './CancelModalForPhotoContainer';
import CancelModalForReject from './CancelModalForRejectContainer';
import Modal from '../../common/modals/commonModal';

const EditOrViewEventComponent = ({
    props,
    state,
    handleChange,
    changeValueHolder,
    closeBriefModal,
    saveEventBrief,
    enableReject,
    approveEventTask,
    closePhotographyModal,
    handleLayoutSubmit,
    submitPhotography,
    handleRejectField,
    closePhotoorLayoutModal,
    closeRejectModal,
    events,
    taskHtml,
    taskOwneer,
    handleFileChange,
    closeLayoutModal,
    closeCopyWriteModal,
    updateMainViewer,
    saveCopywrite,
    submitLayout,
    imageErrorCheck,
}) => {
    return (
        <div>
            {props.isLoaded && props.isOpen &&
                <div className={`add-edit-popup scroll ${(props.isOpen) ? "active" : ""}`}>
                    <div><a onClick={() => {
                        props.close();
                    }} className="BackButton">&#8592;Back</a></div>
                    {props.eventData.adminApprovedStatus === 'false' &&
                    <div className="modal-form">
                        <span className="error-message align-left">Rejected by {props.eventData.approvedBy} for {props.eventData.rejectComments}</span>
                    </div>
                    }
                    <div className="modal-form">
                        <div className="form-group">
                            <input
                                type="hidden"
                                className="editevent-id form-control"
                                value={props.eventData.eventId}
                            />
                        </div>
                        <div className="eventID-container">
                            <div className="form-group task-column label">
                                <span className="label">Event ID:</span>
                                <span>{props.eventData.eventId}</span>
                            </div>
                            <div className="form-group task-column label">
                                <span className="label">Event Status:</span>
                                <span>{props.eventData.eventStaus}</span>
                            </div>
                        </div>
                        <div className="task-container">
                            <div className="task-row-containerFirst task-event-row-manage">
                                <div className="form-group task-column label">
                                    <label>Event Name*:</label>
                                </div>
                                <div className="form-group task-column col-3">
                                    <input data-type="event" data-name="eventDesc" onChange={handleChange} type="text" value={state.eventStateData.eventDesc}
                                        className="editevent-desc form-control" placeholder="Event Name"
                                        readOnly={(props.user.defaultRole === "EventOwner" && props.user.userName === taskOwneer) ? '' : 'readonly'} maxLength={30} />
                                </div>
                                <div className="form-group task-column label margin-label">
                                    <label>Event Date*:</label>
                                </div>
                                <div className="form-group task-column col-3">
                                    <input data-type="event" data-name="eventDate" onChange={handleChange} type="text" value={state.eventStateData.eventDate}
                                        className=" datepicker editeventdate form-control" placeholder="mm/dd/yyyy"
                                        readOnly={(props.user.defaultRole === "EventOwner" && props.user.userName === taskOwneer) ? '' : 'readonly'}
                                        disabled={(props.user.defaultRole === "EventOwner" && props.user.userName === taskOwneer) ? false : true} />
                                </div>
                                <div className="form-group task-column label margin-label">
                                    <label>Event Type*:</label>
                                </div>
                                <div className="form-group task-column col-3">
                                    <select data-type="event" data-name="eventType" onChange={handleChange} value={state.eventStateData.eventType}
                                        className="editevent-type form-control"
                                        disabled={(props.user.defaultRole === "EventOwner" && props.user.userName === taskOwneer) ? false : true}>
                                        <option value="0">Please select the event type</option>
                                        {events}
                                    </select>
                                </div>
                                <div className="form-group task-column label margin-label">
                                    <label>End Date*:</label>
                                </div>
                                <div className="form-group task-column col-3">
                                    <input data-type="event" data-name="eventEndDate" onChange={handleChange} type="text" value={state.eventStateData.eventEndDate}
                                        className=" datepicker editeventenddate form-control" placeholder="mm/dd/yyyy"
                                        readOnly={(props.user.defaultRole === "EventOwner" && props.user.userName === taskOwneer) ? '' : 'readonly'}
                                        disabled={(props.user.defaultRole === "EventOwner" && props.user.userName === taskOwneer) ? false : true} />
                                </div>
                            </div>
                        </div>
                        <div className="task-container">
                            <div className="task-row-container">
                                <div className="task-column form-group"></div>
                                <div className="task-column form-group">Owner*</div>
                                <div className="task-column form-group">Due*</div>
                                <div className="task-column form-group">Complete</div>
                                <div className="task-column form-group task-action"></div>
                                <div className="task-column form-group task-action"></div>
                                <div className="task-column form-group task-action healthBoxTextalign">Health Check</div>
                            </div>
                            {taskHtml}
                        </div>
                    </div>
                </div>
            }

            {/* Event Modals */}
            <Modal isOpen={state.briefModalOpen} addClass={"brief-modal " + (state.viewMode ? "" : "view-mode")}>
                {state.modalName === "briefModal" &&
                    <div>
                        <div className="modal-header">
                            {state.isTaskReview ? 'Review' : 'Add'} Event Brief
                            <span className="close" onClick={closeBriefModal}>x</span>
                        </div>
                        <div className={"modal-content " + (state.noTaskContent ? "show" : "hide")}>
                            <h4 className={"text-center"}>No Event content to display</h4>
                        </div>
                        <div className={"modal-content " + (state.noTaskContent ? "hide" : "show")}>
                            <div className="row">
                                <div className="col-12">
                                    <p className={"status"}></p>
                                    <div className="form-group">
                                        <label className="form-label mb-10">Event Brief</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className={"brief"}>
                                        <textarea name="textarea" className="brief value-holder" onChange={changeValueHolder}></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer text-center " + (state.isTaskReview ? "hide" : "")}>
                                <button className="gray-button" onClick={closeBriefModal} data-val={state.activeEventID} data-action={"cancel"}>Cancel</button>
                                <button id="saveBriefInner" disabled={state.briefOrCopySavedisable ? 'disabled' : ''} className="gray-button" onClick={saveEventBrief} data-val={state.activeEventID} data-action={"save"}>Save</button>
                                <button disabled={state.saveSubmitDisable} className="gray-button" onClick={saveEventBrief} data-val={state.activeEventID} data-action={"submit"}>Submit for Approval
                                </button>
                            </div>
                            <div className={"clearfix modal-footer text-center " + (state.isTaskReview ? "" : "hide")}>
                                <div className="col-12 mtb-10">
                                    <button className="gray-button" onClick={closeBriefModal} data-val={state.activeEventID} data-action={"cancel"}>Cancel</button>
                                    <button className="gray-button" onClick={enableReject} data-val={state.activeEventID} data-action={"reject"}>Reject</button>
                                    <button className="gray-button" onClick={approveEventTask} data-val={state.activeEventID} data-action={"approve"}>Approve</button>
                                </div>
                                <div className="col-12 mtb-10 text-left">
                                    <label className={"form-label " + (state.showReject ? "" : "hide")}>Review notes</label>
                                    <textarea className={"reject-field " + (state.showReject ? "" : "hide")} onChange={handleRejectField}></textarea>
                                </div>
                                <div className={"col-12 mtb-10 " + (state.showReject ? "" : "hide")}>
                                    <button className="gray-button" disabled={state.rejectText.length == 0} onClick={approveEventTask} data-val={state.activeEventID} data-action={"rejectsubmit"}>Submit</button>
                                    <button className="gray-button" id="rejectBrief" disabled={state.rejectDisable} onClick={approveEventTask} data-val={state.activeEventID} data-action={"rejectsave"}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Modal>

            <Modal isOpen={state.copyWriteModalOpen} addClass={"copy-write-modal " + (state.viewMode ? "" : "view-mode")}>
                {state.modalName === "copyWriteModal" &&
                    <div>
                        <div className="modal-header">
                            {state.isTaskReview ? 'Review' : 'Add'} Event Copy
                            <span className="close" onClick={closeCopyWriteModal}>x</span>
                        </div>
                        <div className={"modal-content " + (state.noTaskContent ? "show" : "hide")}>
                            <h4 className={"text-center"}>No Event content to display</h4>
                        </div>
                        <div className={"modal-content " + (state.noTaskContent ? "hide" : "show")}>
                            <div className="row">
                                <p className={"status"}></p>
                                <div className="col-12">
                                    <div className="form-group">
                                        <label className="form-label mb-10">Event Details</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className={"description"}>
                                        <textarea name="textarea" className="jqte-test event-copywrite"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer text-center " + (state.isTaskReview ? "hide" : "")}>
                                <button className="gray-button" onClick={closeCopyWriteModal} data-val={state.activeEventID} data-action={"cancel"}>Cancel</button>
                                <button className="gray-button" id="saveCopyInner" disabled={state.briefOrCopySavedisable ? 'disabled' : ''} onClick={saveCopywrite} data-val={state.activeEventID} data-action={"save"}>Save</button>
                                <button className="gray-button" disabled={state.saveSubmitDisable} onClick={saveCopywrite} data-val={state.activeEventID} data-action={"submit"}>Submit for Approval
                                </button>
                            </div>
                            <div className={"clearfix modal-footer text-center " + (state.isTaskReview ? "" : "hide")}>
                                <div className="col-12 mtb-10">
                                    <button className="gray-button" onClick={closeCopyWriteModal} data-val={state.activeEventID} data-action={"cancel"}>Cancel</button>
                                    <button className="gray-button" onClick={enableReject} data-val={state.activeEventID} data-action={"reject"}>Reject</button>
                                    <button className="gray-button" onClick={approveEventTask} data-val={state.activeEventID} data-action={"approve"}>Approve</button>
                                </div>
                                <div className="col-12 mtb-10 text-left">
                                    <label className={"form-label " + (state.showReject ? "" : "hide")}>Review notes</label>
                                    <textarea className={"reject-field " + (state.showReject ? "" : "hide")} onChange={handleRejectField}></textarea>
                                </div>
                                <div className={"col-12 mtb-10 " + (state.showReject ? "" : "hide")}>
                                    <button className="gray-button" disabled={state.rejectText.length == 0} onClick={approveEventTask} data-val={state.activeEventID} data-action={"rejectsubmit"}>Submit</button>
                                    <button className="gray-button" id="rejectCopy" disabled={state.rejectDisable} onClick={approveEventTask} data-val={state.activeEventID} data-action={"rejectsave"}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Modal>

            <Modal isOpen={state.layoutModalOpen} addClass={"layout-modal " + (state.viewMode ? "" : "view-mode")}>
                {state.modalName === "layoutModal" &&
                    <div>
                        <div className="modal-header">
                            {state.isTaskReview ? 'Review' : 'Add'} Layout/Design
                            <span className="close" onClick={closeLayoutModal}>x</span>
                        </div>
                        <div className={"modal-content " + (state.noTaskContent ? "show" : "hide")}>
                            <h4 className={"text-center"}>No Event content to display</h4>
                        </div>
                        <div className={"modal-content " + (state.noTaskContent ? "hide" : "show")}>
                            <p className={"status"}></p>
                            <div className="vertical-image-holder">
                                <div className="thumbnail-holder scroll">
                                    <ul>
                                        {state.imageArr.map((item, index) => (
                                            <li className="thumbnails" key={index}
                                                onClick={() => (updateMainViewer(index))}>
                                                <img src={item} />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="main-image">
                                    {imageErrorCheck}
                                </div>
                                <div className="upload-holder">
                                    <form name="event-images-upload" className="images-upload" onSubmit={handleLayoutSubmit}>
                                        <input type='hidden' name="pid" defaultValue={state.activeEventID} />
                                        <div className="upload-input">
                                            <div className="icon-holder" disabled={state.isTaskReview}>
                                                <span className="plus-icon"></span>
                                            </div>
                                            <input type="file" name="files" accept="image/*" onChange={handleFileChange} disabled={state.isTaskReview} />
                                        </div>
                                        <label className="block text-center"> Add Photo(s)
                                            <span className={"image-name " + (state.inputFile.length > 0 ? "" : "hide")}>: {state.inputFileName}</span>
                                        </label>
                                    </form>
                                </div>
                            </div>
                            <div className={"modal-footer text-center " + (state.isTaskReview ? "hide" : "")}>
                                <button className="gray-button" onClick={closeLayoutModal} data-val={state.activeEventID} data-action={"cancel"}>Cancel</button>
                                <button className="gray-button" onClick={handleLayoutSubmit} data-val={state.activeEventID}
                                    disabled={state.inputFile.length > 0 ? '' : 'disabled'} data-action={"save"} id="saveLayoutDataInner">Save</button>
                                <button className="gray-button" onClick={submitLayout} data-val={state.activeEventID} data-action={"submit"}
                                    disabled={state.imageArr.length > 0 ? '' : 'disabled'}>Submit for Approval
                                </button>
                            </div>
                            <div className={"clearfix modal-footer text-center " + (state.isTaskReview ? "" : "hide")}>
                                <div className="col-12 mtb-10">
                                    <button className="gray-button" onClick={closeLayoutModal} data-val={state.activeEventID} data-action={"cancel"}>Cancel</button>
                                    <button className="gray-button" onClick={enableReject} data-val={state.activeEventID} data-action={"reject"}>Reject</button>
                                    <button className="gray-button" onClick={approveEventTask} data-val={state.activeEventID} data-action={"approve"}>Approve</button>
                                </div>
                                <div className="col-12 mtb-10 text-left">
                                    <label className={"form-label " + (state.showReject ? "" : "hide")}>Review notes</label>
                                    <textarea className={"reject-field " + (state.showReject ? "" : "hide")} onChange={handleRejectField}></textarea>
                                </div>
                                <div className={"col-12 mtb-10 " + (state.showReject ? "" : "hide")}>
                                    <button className="gray-button" onClick={approveEventTask} data-val={state.activeEventID} data-action={"rejectsubmit"} disabled={state.rejectText.length == 0}>Submit</button>
                                    <button id="rejectLayout" disabled={state.rejectDisable} className="gray-button" onClick={approveEventTask} data-val={state.activeEventID} data-action={"rejectsave"}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Modal>

            <Modal isOpen={state.photographyModalOpen} addClass={"photography-modal " + (state.viewMode ? "" : "view-mode")}>
                {state.modalName === "photographyModal" &&
                    <div>
                        <div className="modal-header">
                            {state.isTaskReview ? 'Review' : 'Add'} Photography
                            <span className="close" onClick={closePhotographyModal}>x</span>
                        </div>
                        <div className={"modal-content " + (state.noTaskContent ? "show" : "hide")}>
                            <h4 className={"text-center"}>No Event content to display</h4>
                        </div>
                        <div className={"modal-content " + (state.noTaskContent ? "hide" : "show")}>
                            <p className={"status"}></p>
                            <div className="vertical-image-holder">
                                <div className="thumbnail-holder scroll">
                                    <ul>
                                        {state.imageArr.map((item, index) => (
                                            <li className="thumbnails" key={index}
                                                onClick={() => (updateMainViewer(index))}>
                                                <img src={item} />
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="main-image">
                                    {imageErrorCheck}
                                </div>
                                <div className="upload-holder">
                                    <form name="event-images-upload" className="images-upload" onSubmit={handleLayoutSubmit}>
                                        <input type='hidden' name="pid" defaultValue={state.activeEventID} />
                                        <div className="upload-input">
                                            <div className="icon-holder" disabled={state.isTaskReview}>
                                                <span className="plus-icon"></span>
                                            </div>
                                            <input type="file" name="files" accept="image/*" onChange={handleFileChange} disabled={state.isTaskReview} />
                                        </div>
                                        <label className="block text-center">
                                            Add Photo(s)
                                            <span className={"image-name " + (state.inputFile.length > 0 ? "" : "hide")}>: {state.inputFileName}</span>
                                        </label>
                                    </form>
                                </div>
                            </div>
                            <div className={"modal-footer text-center " + (state.isTaskReview ? "hide" : "")}>
                                <button className="gray-button" onClick={closePhotographyModal} data-val={state.activeEventID} data-action={"cancel"}>Cancel</button>
                                <button id="savePhotodataInner" className="gray-button" onClick={handleLayoutSubmit} data-val={state.activeEventID} disabled={state.inputFile.length > 0 ? '' : 'disabled'} data-action={"save"}>Save</button>
                                <button className="gray-button" onClick={submitPhotography} data-val={state.activeEventID} data-action={"submit"}
                                    disabled={state.imageArr.length > 0 ? '' : 'disabled'}>Submit for
                                    Approval
                                </button>
                            </div>
                            <div className={"clearfix modal-footer text-center " + (state.isTaskReview ? "" : "hide")}>
                                <div className="col-12 mtb-10">
                                    <button className="gray-button" onClick={closePhotographyModal} data-val={state.activeEventID} data-action={"cancel"}>Cancel</button>
                                    <button className="gray-button" onClick={enableReject} data-val={state.activeEventID} data-action={"reject"}>Reject</button>
                                    <button className="gray-button" onClick={approveEventTask} data-val={state.activeEventID} data-action={"approve"}>Approve</button>
                                </div>
                                <div className="col-12 mtb-10 text-left">
                                    <label className={"form-label " + (state.showReject ? "" : "hide")}>Review notes</label>
                                    <textarea className={"reject-field " + (state.showReject ? "" : "hide")} onChange={handleRejectField}></textarea>
                                </div>
                                <div className={"col-12 mtb-10 " + (state.showReject ? "" : "hide")}>
                                    <button className="gray-button" disabled={state.rejectText.length == 0} onClick={approveEventTask} data-val={state.activeEventID} data-action={"rejectsubmit"}>Submit</button>
                                    <button disabled={state.rejectDisable} id="rejectPhoto" className="gray-button" onClick={approveEventTask} data-val={state.activeEventID} data-action={"rejectsave"}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Modal>
            <CancelModalForPhoto isOpen={state.isCancelModalForPhotoOpen} modalClose={closePhotoorLayoutModal} />
            <CancelModalForReject isOpen={state.isCancelModalForRejectOpen} modalClose={closeRejectModal} />
        </div>
    );
};

EditOrViewEventComponent.propTypes = {
    props: PropTypes.object,
    state: PropTypes.object,
    handleChange: PropTypes.func,
    changeValueHolder: PropTypes.func,
    closeBriefModal: PropTypes.func,
    saveEventBrief: PropTypes.func,
    enableReject: PropTypes.func,
    approveEventTask: PropTypes.func,
    closePhotographyModal: PropTypes.func,
    handleLayoutSubmit: PropTypes.func,
    submitPhotography: PropTypes.func,
    handleRejectField: PropTypes.func,
    closePhotoorLayoutModal: PropTypes.func,
    closeRejectModal: PropTypes.func,
};

export default EditOrViewEventComponent;
