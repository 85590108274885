import React, { Component } from 'react';
import ConfirmModal from './confirmModal.js';

class CancelModalDelete extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false
        };

        this.closeConfirmModal = this.closeConfirmModal.bind(this);
    }

    closeConfirmModal(key) {
        this.props.modalClose(key);
    }

    render() {
        return (
            <ConfirmModal isOpen={this.props.isOpen}>
                <div className="modal-content">
                    <h3 className="confirm-warning-text">Confirm Image Deletion</h3>
                    <div className="modal-footer">
                        <input type="button" className="gray-button" value="Cancel" onClick={() => this.closeConfirmModal('no')}></input>
                        <input type="button" className="gray-button" value="Delete" onClick={() => this.closeConfirmModal('yes')}></input>
                    </div>
                </div>
            </ConfirmModal>
        );
    }
}

export default CancelModalDelete;
