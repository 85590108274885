import React from 'react';
import VerticalImageViewer from './vertical-image-viewer';


class ImageListerModal extends React.Component {
    render() {
        const props = this.props;
        return (
            <div>
                <div className="modal-header">
				Pictures
                    <span className="close" onClick={this.props.modalClose}>x</span>
                </div>
                <div className="modal-content product-pictures">
                    <VerticalImageViewer {...props} />
                </div>
            </div>
        );
    }
}

export default ImageListerModal;
