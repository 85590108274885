import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { checkHeight } from '../utils';

export class SetDetailEditor extends React.Component {
    constructor() {
        super();
        this.state = {
            rowData: [],
            columnDefs: [],
            totalQty: '',
            totalCost: '',
            totalRetail: '',
            overlayNoRowsTemplate: 'No Data Found',
            defaultColDef: {
                editable: true,
                resizable: false,
                menuTabs: [],
            },
            showError: false,
            disableSave: false,
        };
    }

    componentDidMount() {
        let _this = this;
        let data = this.props.node.data.setDetail && this.props.node.data.setDetail ? this.props.node.data.setDetail : [];
        let parseData = data.length > 0 ? JSON.parse(data) : [] ;
        let type = this.props.node.data.type;
        let dispFlag = this.props.node.data.type == "BOM" || this.props.node.data.type == "PDB" ? true : false;

        if (dispFlag == false) {
            _this.props.api.stopEditing();
            return;
        }

        checkHeight(this.props);

        let columnDetails = [
            {
                headerName: 'SKU',
                field: 'sku',
                width: 60,
            },
            {
                headerName: 'Description',
                field: 'desc',
                width: 165,
            },
            {
                headerName: 'Quantity',
                field: 'qty',
                width: 75,
            },
            {
                headerName: 'Cost',
                field: 'cost',
                width: 60,
            },
            {
                headerName: 'Retail',
                field: 'retail',
                width: 60,
            },
            {
                headerName: 'Prop65',
                field: 'prop65',
                width: 75,
            },
        ];

        // let data = [
        //     {
        //         "sku":"12302",
        //         "desc":"test desc",
        //         "qty":"3",
        //         "cost":"20",
        //         "retail":"23.5",
        //         "prop65":"N"
        //     },
        //     {
        //         "sku":"4523",
        //         "desc":"test desc 2",
        //         "qty":"2",
        //         "cost":"5",
        //         "retail":"21",
        //         "prop65":"N"
        //     }
        // ]


        this.setState({
            columnDefs: columnDetails,
            rowData: parseData,
        }, () => {
            this.setTotals();
        });
    }


    setTotals() {
        const data = this.state.rowData;
        let qty = data.reduce((a, b) => a + parseFloat(b.qty), 0);
        let cost = data.reduce((a, b) => a + parseFloat(b.cost * b.qty), 0);
        let retail = data.reduce((a, b) => a + parseFloat(b.retail * b.qty), 0);

        this.setState({
            totalQty: qty,
            totalCost: cost,
            totalRetail: retail,
        });
    }


    isPopup() {
        return true;
    }

    getValue() {
        const _this = this;
        if (_this.state.rowData.length == 0) {
            return;
        } else {
            return JSON.stringify(this.state.rowData);
        }
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    addRow() {
        let _this = this;
        const newObj = {
            "sku":"",
            "desc":"",
            "qty":"",
            "cost":"",
            "retail":"",
            "prop65":""
        };
        let data = this.state.rowData;

        // stops user from creating more than 10 rows
        if (data.length == 10) {
            this.setState({
                showError: true,
            }, () => {
                setTimeout(() => {
                    this.setState({
                        showError: false,
                    });
                }, 5000);
            });

            return;
        }

        data.push(newObj);

        this.setState({
            rowData: data
        }, () => {
            _this.gridApi.updateRowData({
                add: [newObj],
            });
        });
    }

    closePopup() {
        this.props.api.stopEditing();
    }

    onCellValueChanged(params) {
        console.log('on cell value changed: ', params);
        const _this = this;
        let data = this.state.rowData;
        const colId = params.column.colId;
        const oldValue = params.oldValue;
        const newValue = params.value;

        if (oldValue == newValue) {
            return;
        }

        // only allows numbers for these cols
        if (colId == 'qty' | colId == 'cost' | colId == 'retail') {
            let checkNum = !!/\d/.test(newValue);

            if (!checkNum) {
                this.props.node.setDataValue(colId, oldValue);
            }
        }

        data.map((i, x) => {
            if (params.rowIndex == x) {
                i = params.data;
            }
        });

        this.setState({
            rowData: data,
        });
    }

    checkForNulls(data) {
        let newArr = [];
        let flag = false;
        data.filter((i, x) => {
            Object.keys(i).map((j, k) => {
                if (i[j] != "") {
                    flag = true;
                }
            });
            if (flag) {
                newArr.push(i);
                flag = false;
            }
        });

        return newArr;
    }

    saveData() {
        const rowData = this.props.node.data;
        const data = this.state.rowData;
        let newData = this.checkForNulls(data);
        rowData[this.props.column.colId] = JSON.stringify(newData);
        console.log('on saveData clicked: ', this.props, this.state, data, rowData, newData);

        if (newData.length == 0) {
            return;
        }

        this.setState({
            rowData: newData,
            disableSave: true,
        }, () => {
            this.setTotals();
            this.props.rowUpdate(rowData);
            setTimeout(() => {
                this.setState({
                    disableSave: false,
                });
            }, 5000);
        });
    }

    render() {
        const usdFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        });

        const totalCostFormatted = usdFormatter.format(this.state.totalCost ? this.state.totalCost : 0);
        const totalRetailFormatted = usdFormatter.format(this.state.totalRetail ? this.state.totalRetail : 0);

        return(
            <div className="message-editor-container set-detail-col">
                <div className="message-editor-header">
                    <div className="modal-header">{this.props.node.data.type.toUpperCase()} SKU</div>
                    <span className="close" onClick={this.closePopup.bind(this)}>x</span>
                </div>
                <div className="message-editor-body">
                    <React.Fragment>
                        {
                            this.state.showError &&
                    <span className="message-error">Cannot have more than ten rows</span>
                        }
                    </React.Fragment>
                    <div
                        id="myGrid"
                        style={{
                            height: '100%',
                            width: '100%',
                        }}
                        class
                        Name="ag-theme-alpine"
                    >
                        <AgGridReact
                            columnDefs={this.state.columnDefs}
                            rowData={this.state.rowData}
                            defaultColDef={this.state.defaultColDef}
                            overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                            onGridReady={this.onGridReady.bind(this)}
                            onCellValueChanged = {this.onCellValueChanged.bind(this)}
                            rowSelection={'multiple'}
                            stopEditingWhenGridLosesFocus={true}
                            singleClickEdit={true}
                            enterMovesDown={true}
                            enterMovesDownAfterEdit={true}
                            suppressRowClickSelection={true}
                            animateRows={true}
                            suppressContextMenu={true}
                            rowHeight={25}
                            headerHeight={25}

                            // groupUseEntireRow={false}
                            // groupHideOpenParents={true}
                        />
                    </div>
                </div>


                <div className="message-editor-footer">
                    <div className='add-row'>
                        <button className="message-button new" onClick={this.addRow.bind(this)}>Add Row</button>
                        <button className="message-button new" disabled={this.state.disableSave} onClick={this.saveData.bind(this)}>Save</button>
                    </div>
                    <div className='show-total'>
                        <div className='total'>Total: </div>
                        <div className='qty'>{this.state.totalQty ? this.state.totalQty : null}</div>
                        <div className='cost'>{totalCostFormatted}</div>
                        <div className='retail'>{totalRetailFormatted}</div>
                        <div></div>
                    </div>
                </div>

            </div>
        );
    }
}




export class SetDetailRenderer extends React.Component {
    render() {
        const IconDisplay = <div className="ag-popupIndicator"><img src={require('../../../common/icons/Expand Grid.svg')} /></div>;
        let newArr = [];
        let vals = this.props.value ? this.props.value : '';
        const data = vals.includes('"sku\":') ? JSON.parse(vals) : [];
        data && data.map((i) => {
            newArr.push(i.sku);
        });
        let dispFlag = this.props.node.data.type == "BOM" || this.props.node.data.type == "PDB" ? true : false;

        return(
            <React.Fragment>
                {
                    newArr.length > 0 ?
                        <React.Fragment>
                            {
                                newArr.join(", ")
                            }
                        </React.Fragment>
                        :
                        <React.Fragment>
                            {
                                dispFlag == true ?
                                    <React.Fragment>
                                        {IconDisplay}
                                    </React.Fragment>
                                    :
                                    ''
                            }
                        </React.Fragment>

                }
            </React.Fragment>
        );
    }
}
