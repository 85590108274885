export const addAlertsData = (state = {}, action) => {
    console.log("NOTIFICATION REDUCER", state, action);

    switch (action.type) {
        case "ADD_ALERTS_DATA":
            return action.data;
        default:
            return  state;
    }
};
