import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
// import JqxGrid from '../common/vendor/jqwidgets-react/react_jqxgrid.js';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import JqxGrid from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';

const AccomplishmentComponent = ({
    state,
    props,
    handleSelectChange,
    MakeItem,
    accomplishmentGridRef,
    gridData,
    gridHeight,
    editSettings,
}) => {
    return (

        <div id="accomplishment">
            <div className="pos-relative">
                <div className="dropdown-section">
                    <h4>User Groups:&nbsp;
                        {state.dropdownlist &&
                            <select
                                className="form-input-select atrGroup"
                                onChange={handleSelectChange}>{state.dropdownlist.map(MakeItem)}
                            </select>
                        }
                    </h4>
                </div>
                <div id="accomplishGrid">
                    {state.isLoaded &&

                        <JqxGrid
                            ref={accomplishmentGridRef}
                            width={'99%'}
                            source={gridData.dataAdapter}
                            pageable={true}
                            editable={false}
                            hierarchicalCheckboxes={true}
                            pagermode={'simple'}
                            columnsResize={true}
                            columns={gridData.columns}
                            sortable={true}
                            autoRowHeight={false}
                            autoWidth={true}
                            height={gridHeight}
                            theme={'light'}
                            editSettings={editSettings}
                            columnsreorder={true}
                            enabletooltips={true}
                            enableellipsis={true}
                            columnsmenu={false}
                        />
                    }
                </div>
            </div>
        </div>

    );
};

AccomplishmentComponent.propTypes = {
    state: PropTypes.object,
    props: PropTypes.object,

};

export default withRouter(AccomplishmentComponent);


