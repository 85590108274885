var counter = 0;

/**generates an notification based on the options passed
 *
 * @param {object} options - object contains the properties to intialize.
 * message - notification message;
 * autoHide - defulat 5 secs;
 * type - number 1-success, 2-warn, 3-info, 4-danger
 * confirmButton - button text
 */
function NotificationManager(options) {
    //increment to generate unique id
    counter++;

    var types = ["success", "warn", "info", 'danger overlay', 'userInform overlay'],
        confirmHtml = '', notification = '';

    if (options.autoHide !== false && !options.autoHide) {
        options.autoHide = 2500; //default 5 secnods
    }

    var selectedType = types[+options.type - 1];

    if (!selectedType) {
        selectedType = types[0];
    }

    if (selectedType == 'danger overlay' || selectedType == 'userInform overlay') {
        options.autoHide = false;
        confirmHtml = "<div class='text-center mt-20'> <button class='gray-button n-close' type='buton'>Ok</button></div>";

        notification = "<div class='danger-overlay'><div class='notification " + counter + " " + selectedType + "'>" +
            options.message + confirmHtml + "</div></div>";
        $('#notifier-container').html(''); // Making success message empty and addthe appending error overlay
        $('#notifier-container').addClass('overlay-container');

        if (selectedType == 'userInform overlay') {
            $('#notifier-container').addClass('user-overlay');
        }
    } else {
        const currentPage = localStorage.getItem('currentPage');
        let setPage;

        if (currentPage == 'admin') {
            setPage = 'notificationPosFix';
        } else if (currentPage == 'product2') {
            setPage = 'notificationPosFixProd';
        } else {
            setPage = '';
        }

        notification = "<div class='notification " + setPage + " " + counter + " " + selectedType + "'><span class='notification-message'>" +
            options.message + "</span>" + confirmHtml + "</div>";
    }

    Notifications(notification, counter, options.autoHide);
}

function closeFunction(){
    return function(counter, time) {
        // returns setTimeout id
        function setTime() {
            return setTimeout(function () {
                $('.notification.' + counter).fadeOut(function() {
                    $(this).remove();
                });
            }, time);
        }

        var timerId = setTime();

        // clear the time out when user hovers on the notification
        $('.notification.' + counter).on('mouseenter', function() {
            clearInterval(timerId);
        });

        $('.notification.' + counter).on('mouseleave', function() {
            timerId = setTime();
        });

        $('.notification.success .notification-message').bind('click', function(event) {
            $('.view-recent-messages a').trigger('click');
            $('.notification.success').fadeOut();
        });
    };
}

/** appends the generated notification to notifier holder
 *
 * @param {string} notification - html string generated based on options
 * @param {interger} counter - unique id
 * @param {interger,bool} time - integer for auto hide time, false in case of not to hide
 */
function Notifications(notification, counter, time) {
    $('#notifier-container').prepend(notification);

    var autoHide = closeFunction();

    if (time) {
        autoHide(counter, time);
    }

    $('.close-notification,.n-close').off().on('click', function() {
        $(this).closest('.notification').slideUp(function() {
            $(this).remove();

            $('#notifier-container').removeClass('overlay-container');
            $('#notifier-container').removeClass('user-overlay');
        });
    });
}

export default NotificationManager;
