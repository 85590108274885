/**
* column width declaration Vendor Grid
*/
const columnWidth = {
    productId: 120,
    prodName: 300,
    activeNotes: 400,
    //required fields column width
    fields: '40%',
    fieldsToClone: '20%',
    readyToOrder: '20%',
    reqdToAdd: '20%',
    //rules fields column width
    description: '30%',
    details: '30%',
    ruleName: '25%',
    status: 120,
    contactPerson: 150,
    orgVendorId: 150,
    name: 300,
};

const requiredFieldsList = ['fieldsToClone', 'readyToOrder', 'reqdToAdd'];
/*Date Editor funtion for Jqwidgets
*/

/**
 * Vendor ID
 * @param {*} row
 * @param {*} columnfield
 * @param {*} value
 * @param {*} rowdata
 * @return {object}
 */
const CellDoubleClickEvent = function(row, columnfield, value, rowdata) {
    return '<div class="grid-text gridVendorId" data-row="' + row + '">' + value + '<span class="icon-expand-grid right"></span></div>';
};

/**
 * User Status - Enable/Disable
 * @param {*} row
 * @param {*} columnfield
 * @param {*} value
 * @param {*} rowdata
 * @return {object}
 */
const StatusDropDownInitRenderer = function(row, columnfield, value, rowdata) {
    if (value === true || value === 'Enabled') {
        return '<div class="grid-dropdown"><select class="greenText" onchange="this.className= this.options[this.selectedIndex].className" data-row="' + row + '" data-vendorid=""><option value="true" selected class="greenText">Enabled</option><option value="false" class="redText">Disabled</option></select>';
    } else {
        return '<div class="grid-dropdown"><select class="redText" onchange="this.className= this.options[this.selectedIndex].className" data-row="' + row + '" data-vendorid=""><option value="true" class="greenText">Enabled</option><option value="false" class="redText" selected>Disabled</option></select>';
    }
};


//Ends User Status - Enable/Disable

/* End User Grid Status*/

/**
 * Grid data builder is used to create the object format required for
 * Jqwidgets.
 * @param {*} res
 * @param {*} pageName
 * @return {object}
 */
function GridDataBuilder(res, pageName) {
    // dataField is used for providing type of data and its name.
    // columnList is used to define column name, type, and extra fields
    //  to define additional plugins inside the cell eg: drop down, calender ...,
    const dataField = [];
    const columnList = [];
    let primayKey = res.columnDetails[0].fieldName;
    // check for the type of fieldname future

    //sort columndetails in product pageName
    res.columnDetails.sort(function (a, b) {
        return a.order - b.order;
    });

    for (let i = 0; i < res.columnDetails.length; i++) {
        dataField.push({'name': res.columnDetails[i].fieldName,
            'type': res.columnDetails[i].type.toLowerCase()});

        /* Enable the below condition after pk(primary key) availabel from backend
    *  in JSON response.
    */

        if (res.columnDetails[i].hasOwnProperty('pk') && res.columnDetails[i].pk) {
            primayKey = res.columnDetails[i].fieldName;
        }

        columnList.push(columnObj(res.columnDetails[i]));
    }


    const createDateEditor = function (row, cellvalue, editor, celltext, width, height) {
        // construct the editor.
        cellvalue = cellvalue ? new Date(cellvalue) : null;
        editor.jqxDateTimeInput({
            width: width,
            height: height,
            value: cellvalue,
            culture: 'en-US',
            formatString: 'MM/dd/yyyy',
            enableBrowserBoundsDetection: true,
            selectionMode: 'none',
            showFooter: true,
            clearString: 'Clear',
        });
    };
    const initDateEditor = function (row, cellvalue, editor, celltext, width, height) {
        // set the editor's current value. The callback is called each time the editor is displayed.
        cellvalue = cellvalue ? new Date(cellvalue) : null;
        editor.jqxDateTimeInput('val', cellvalue);
        editor.jqxDateTimeInput({width: width, height: height});
        //editor.jqxDateTimeInput('open');
        //editor.jqxDateTimeInput({  height: height});
    };
    const getDateEditor = function (row, cellvalue, editor) {
        const rowData = this.owner.getrowdata(row);
        const cdate = $(editor).jqxDateTimeInput('val');
        // return the editor's value.
        return cdate;
    };

    /**
   * columnobj function will  return the object fields
   *  based on the field type in list.
   * e.g. for date type and other types need to build the cellrender function.
   * @param {*} list
   * @return {object}
   */
    function columnObj(list) {
        const obj = {};

        obj.text = '<span title="' + list.columnTitle + '">' + list.columnTitle + '</span>';
        obj.dataField = list.fieldName;
        obj.width = (columnWidth[list.fieldName]) ? columnWidth[list.fieldName] : 120;
        obj.hidden = !list.visibility;
        obj.editable = false;
        /* if list type is date, pass the methods related to date to intialize
    *  date picker
    */
        if (list.fieldName === 'vendorId') {
            obj.cellsrenderer = CellDoubleClickEvent;
            obj.editable = false;
        } else if (list.fieldName === 'name') {
            obj.pinned = true;
        } else if (list.fieldName === 'status') {
        //obj.text = '<div class="grid-col-img"><span class="icon-info"></span></div>';
            obj.text = 'Status';
            obj.cellsrenderer = StatusDropDownInitRenderer;
            obj.editable = false;
            obj.cellsAlign = 'center';
            obj.pinned = true;
        }
        /*else if(list.type.toLowerCase()=="date"){
        obj.columntype = "datetimeinput";
        obj.cellsformat = 'MM/dd/yyyy';
        //obj.createeditor = createDateEditor;
        obj.initeditor = initDateEditor;
        //obj.geteditorvalue = getDateEditor;
    }*/
        return obj;
    }


    const source = {
        dataType: 'json',
        dataFields: dataField,
        id: primayKey,
        localdata: res.data,
    };

    const dataAdapter = new $.jqx.dataAdapter(source);

    return {
        dataAdapter: dataAdapter,
        columns: columnList,
    };
}

export default GridDataBuilder;
