import React from 'react';

class SampleMessagePopup extends React.Component {
    render() {
        let props = this.props;

        return (
            <div className="modal-content">
                <div className="text-center confirm-delete">
                    <h4>Items with samples are required before 'Swatch Only' can be set</h4>
                </div>
                <div className="modal-footer text-center">
                    <button className="gray-button"
                        onClick={() => {
                            props.setValueSampleOption();
                            props.modalClose();
                        }}>
						ok
            		</button>
                </div>
            </div>
        );
    }
}

export default SampleMessagePopup;
