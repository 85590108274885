import React from 'react';
import CategoryTreeView from './categoryTreeViewComponent';
import { connect } from 'react-redux';


class AlertsBreakDown extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            alertsBreawkdown : props.selectedAlerts,
            alertsData: props.alerts,
        };
    }

    componentWillReceiveProps(nextProps) {
        let _this = this;
        // if (nextProps.alertsBreawkdown.title != this.props.alertsBreawkdown.title) {
        //     this.setState ({ alertsBreawkdown: nextProps.alertsBreawkdown.title})

        //     this.props.priorityAlerts.map((item,index) =>{
        //         if (item.title == nextProps.alertsBreawkdown.title) {
        //             this.setState ({ alertsData: item})
        //         }
        //     });

        // }
        if (nextProps.selectedAlerts != _this.props.alertsBreawkdown) {
            _this.setState ({ alertsBreawkdown: nextProps.selectedAlerts, alertsData: nextProps.priorityAlerts});
        }
    }

    render(){
        var intViewportHeight = window.innerHeight;
        var containerheight = intViewportHeight - 180;
        var containerStyle = {
            height:containerheight,
            "overflowY":"auto"
        };
        var alertsPos = $('.priority-alerts li.active').position();
        var marginTop = {};
        if(alertsPos){
            marginTop = {
                "marginTop":alertsPos.top - 60
            };
        }
        return (
            <div className="alerts-breakdown scroll">
                <div className="a-pointer" style={marginTop}></div>
                <div className="a-box scroll">
                    <h2>Alerts Breakdown for<br/><span>"{this.state.alertsBreawkdown.toString()}"</span></h2>
                    <div className="scroll">
                        {/* <CategoryTreeView {...this.props}/> */}
                        <CategoryTreeView alerts = {this.state.alertsData} toggleSpinner = {this.props.toggleSpinner} user = {this.props.user} {...this.props}/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        alertsBreawkdown: state.alertsBreawkdown,

    };
};

// export default AlertsBreakDown;
export default connect(mapStateToProps)(AlertsBreakDown);
