
import React, { Component } from 'react';

/**
 * creates the breadcrumb bar on the products grid
 */

class FilterStrip extends Component {
    constructor(props) {
        super(props);
        this.filters = {};
        this.removeSingle = this.removeSingle.bind(this);
        this.handleResetFilterClick = this.handleResetFilterClick.bind(this);
        let savedFilter = localStorage.getItem('product2_filters') ? true : false;

        this.state = {
            isSavedFilters: savedFilter
        };
    }

    componentWillUnmount() {
        if (this.props.api) {
            this.props.api.tabToNextCell();
        }
    }
    createFilterArray(filtersObj) {
        const filters = [];
        if (filtersObj.catIds && filtersObj.catIds.length > 0 && filtersObj.catIdsObj) {
            filtersObj.catIds.map((item, index) => {
                const obj = {};
                obj.name = filtersObj.catIdsObj[item];
                obj.val = item;
                obj.type = 'catIds';
                obj.index = index;
                filters.push(obj);
            });
        }

        if (filtersObj.customFilters && filtersObj.customFilters.length > 0) {
            const obj = {};
            let isValuePresent = false;
            obj.name = 'Refinement';
            obj.type = 'customFilters';
            filtersObj.customFilters.map((item, index) => {
                if (item.field) {
                    isValuePresent = true;
                }
            });
            if (isValuePresent) {
                filters.push(obj);
            }
        }
        return filters;
    }
    componentWillReceiveProps(nextProps) {
        this.filters = this.createFilterArray(nextProps.filters);
    }

    // reset button for deleting saved product search request
    handleResetFilterClick(e) {
        let _this = this;
        localStorage.removeItem('product2_filters');
        window.location.reload();
    }


    /**
     * removes the filter from the breadcrumb
     * @param {string} type
     * @param {number} position
     */
    removeSingle(type, position, id) {
        const removeFilter = { type, position, id };
        this.props.updateFilters(removeFilter);
    }
    render() {
        return (
            <div className="filter-strip-holder">
                <ul className="filter-strip">
                    {this.filters.length > 0 &&
                        this.filters.map((item, index) => {
                            return (
                                <li key={index}>
                                    {item.name} &nbsp;&nbsp;
                                    {this.filters.length > 1 &&
                                        <span className="remove" onClick={() => {
                                            this.removeSingle(item.type, item.index, item.val);
                                        }}></span>
                                    }
                                </li>
                            );
                        })
                    }
                    { this.filters.length > 0 &&
						<button className="reset-filters" onClick={this.handleResetFilterClick}>Clear</button>
                    }
                </ul>
            </div>
        );
    }
}

export default FilterStrip;
