import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import RequiredFieldsFooter from './helperComponents/footer';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import JqxGrid from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';

const RequiredFieldsComponent = ({
    state,
    props,
    requiredFieldsGridRef,
    gridHeight,
    gridData,
    updateRequiredFields,
    cancelChanges,
    isApplyChangesDisabled,
    footerEnableDisableRef,
}) => {
    return (
        <div>
            <div className="required-fields-grid">
                { state.isLoaded &&
                <JqxGrid
                    ref={requiredFieldsGridRef}
                    width={'100%'}
                    source={gridData.dataAdapter}
                    pageable={false}
                    checkboxes={false}
                    editable={false}
                    hierarchicalCheckboxes={true}
                    columnsResize={true}
                    columns={gridData.columns}
                    sortable={true}
                    autoRowHeight={false}
                    autoWidth={true}
                    height={gridHeight}
                    theme={'light'}
                    columnsreorder={true}
                    columnsmenu={false}
                />
                }

                <RequiredFieldsFooter
                    ref={footerEnableDisableRef}
                    applyChanges={updateRequiredFields}
                    cancelChanges={cancelChanges}
                    isDisabled={state.isApplyChangesDisabled}
                />
            </div>
        </div>
    );
};

RequiredFieldsComponent.propTypes = {
    state: PropTypes.object,
    props: PropTypes.object,
};

export default withRouter(RequiredFieldsComponent);
