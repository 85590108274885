import React from 'react';
import { ProductServices } from '../../services/product-services';
import NotificationManager from '../../../common/components/notification';
import { checkHeight } from '../utils';
import { apiURL } from '../../../common/configurations';
import PropTypes from 'prop-types';
import { openInNewTab } from '../../../common/utils';

export class MultipleFileUploadEditor extends React.Component {
    constructor() {
        super();

        this.state = {
            checkedFiles: [],
            currentFile: [],
            selectedFile: null,
        };
    }

    componentDidMount() {
        const colId = this.props.colDef.colId;
        const productId = this.props.data?.productId;

        ProductServices.getMultiFiles(colId, productId).then((res) => {
            const resp = res.data;

            if (res.data) {
                NotificationManager({ message: resp.message, type: 1 });

                this.setState({
                    currentFile: resp.responseData.docsData,
                });
            }
        }).catch((err) => {
            console.error('get data fail', err.response);

            NotificationManager({ message: err.response.data.message, type: 1 });
        });

        checkHeight(this.props);
    }

    closePopup() {
        this.props.api.stopEditing();
    }

    onFileChange(e) {
        const files = e.target.files;
        const fileArr = [];

        for (let i = 0; i < files.length; i++) {
            fileArr.push(files[i]);
        }

        this.setState({ selectedFile: fileArr });
    }

    uploadFile(e) {
        e.preventDefault();

        const form = document.getElementById('multiFileUpload');
        const formData = new FormData(form);
        const colId = this.props.colDef.colId;
        const productId = this.props.data?.productId;
        const fileArr = [];

        this.state.selectedFile.forEach((file, index) => {
            fileArr.push({
                'fileName': file.name,
                'order': (this.state.currentFile?.length ?? 0) + index + 1,
            });
        });

        this.props.spinner(true);

        // Update the formData object

        formData.append('fileDetails', JSON.stringify(fileArr));

        ProductServices.uploadMultiFiles(formData, colId, productId).then((res) => {
            const resp = res.data;

            if (res.data) {
                NotificationManager({ message: resp.message, type: 1 });

                this.setState({
                    currentFile: resp.responseData.docsData,
                    selectedFile: null,
                });

                this.props.spinner(false);
            }
        }).catch((err) => {
            console.error('upload fail data', err);
        });
    }

    onCheckboxClick(e, file) {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let checkedList = this.state.checkedFiles;

        if (value) {
            checkedList.push({
                'file': file.fileName,
                'link': file.fileLink,
            });

            this.setState({ checkedFiles: checkedList });
        } else {
            let removedFile = checkedList.filter(f => f.file !== file.fileName);

            this.setState({ checkedFiles: removedFile });
        }
    }

    downloadAllFiles() {
        this.state.checkedFiles.forEach((file) => {
            openInNewTab(`${apiURL}${file.link}`);
        });
    }

    deleteFiles() {
        const colId = this.props.colDef.colId;
        const productId = this.props.data?.productId;

        this.state.checkedFiles.forEach((file) => {
            ProductServices.deleteMultiFiles(productId, colId, file.file).then((res) => {
                const resp = res.data;

                if (res.data) {
                    NotificationManager({ message: resp.message, type: 1 });

                    this.setState({
                        checkedFiles: [],
                        currentFile: resp.responseData.docsData,
                        selectedFile: null,
                    });
                }
            }).catch((err) => {
                console.error('upload fail data', err);
            });
        });
    }

    isPopup() {
        return true;
    }

    getValue() {
        return this.state.currentFile[0]?.fileName ?? '';
    }

    render() {
        const colName = this.props.column.colId;
        const cssClass = `custom-file-upload ${(this.state.currentFile?.length ? '' : 'grey')}`;

        return (
            <div className="message-editor-container multiple-file-upload">
                <div className="message-editor-header">
                    <div className="modal-header">{colName.toUpperCase()}</div>
                    <span className="close" onClick={this.closePopup.bind(this)}>x</span>
                </div>
                <div className="message-editor-body">
                    <div className="left-sec">
                        <form
                            className="file-upload"
                            encType="multipart/form-data"
                            id="multiFileUpload"
                            onSubmit={this.uploadFile.bind(this)}
                        >
                            <label htmlFor="file-upload" className="custom-file-upload">
                                {'Choose Files'}
                            </label>
                            <input
                                id="file-upload"
                                multiple
                                name="files"
                                onChange={(e) => this.onFileChange(e)}
                                style={{ display: 'none' }}
                                type="file"
                            />
                            <button
                                className={cssClass}
                                disabled={!this.state.selectedFile}
                            >
                                Upload Files
                            </button>
                        </form>
                        <button
                            className={cssClass}
                            disabled={!(this.state.currentFile?.length)}
                            onClick={this.downloadAllFiles.bind(this)}
                        >
                            Download Files
                        </button>

                        <button
                            className={cssClass}
                            disabled={!(this.state.currentFile?.length)}
                            onClick={this.deleteFiles.bind(this)}
                        >
                            Delete Files
                        </button>
                    </div>
                    <div className="right-sec">
                        <div className='selected-files'>
                            <div className='heading'>Selected Files</div>
                            <ol>
                                {
                                    this.state.selectedFile?.map((file) => (
                                        <li key={file.order}>{file.name}</li>
                                    ))
                                }
                            </ol>
                        </div>
                        <div className='uploaded-files'>
                            <div className='heading'>Uploaded Files</div>
                            <ol>
                                {
                                    this.state.currentFile?.map((file) => (
                                        <li key={file.order}>
                                            <input
                                                type='checkbox'
                                                onChange={
                                                    (e) => this.onCheckboxClick(e, file)
                                                }
                                            />
                                            {file.fileName}
                                        </li>
                                    ))
                                }
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

MultipleFileUploadEditor.propTypes = {
    spinner: PropTypes.func,
};
