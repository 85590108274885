import React from 'react';
import CopyDetails from './helperComponents/copyDetails';
import CopyInfo from './helperComponents/copyInfo';
import ImageAndPi from './helperComponents/imageAndPi';
import '../copywriting/copywriting.less';
import CopyWritingFooter from './helperComponents/footer';
import NotificationManager from '../common/components/notification';

import { connect } from 'react-redux';

class Copywriting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRowData: [],
            selectedTab: 'image',
            enableApproveReject: true,
            enableSubmitSave: true,
        };

        this.setSelectedTab = this.setSelectedTab.bind(this);
    }

    setSelectedTab(tab) {
        this.setState({ selectedTab: tab });
    }

    setNewCopyStatus(data) {
        this.setState({selectedRowData : data});
    }

    cancelChangesCopywrite() {
        if (this.state.allowActions) {
            this.infoRef.cancelChanges();
        } else {
            this.showMessage();
        }
    }

    saveCopywrite() {
        if (this.state.allowActions) {
            this.infoRef.saveChanges();
        } else {
            this.showMessage();
        }
    }

    submitCopywrite() {
        if (this.state.allowActions) {
            this.infoRef.submitChanges();
        } else {
            this.showMessage();
        }
    }

    approveCopywrite() {
        if (this.state.allowActions) {
            this.infoRef.approve();
        } else {
            this.showMessage();
        }
    }

    rejectCopywrite() {
        if (this.state.allowActions) {
            this.infoRef.reject();
        } else {
            this.showMessage();
        }
    }

    showMessage() {
        NotificationManager({ message: 'Please select a record to continue', type: 4 });
    }

    componentWillReceiveProps(nextProps) {
        const _this = this;
        const buyerRole = this.props.user.defaultRole.toLowerCase() == 'buyer';
        const editableRole = this.props.user.defaultRole.toLowerCase() == 'webproducer' || this.props.user.defaultRole.toLowerCase() == 'copywriter';

        if (nextProps.selectedRowData) {
            _this.setState({selectedRowData: nextProps.selectedRowData, allowActions: true});
        }

        if (nextProps.selectedRowData && _this.props.selectedRowData && buyerRole && nextProps.selectedRowData.copyStatus == "Submitted") {
            _this.setState ({ enableApproveReject: false, enableSubmitSave: true });
            return;
        } else {
            _this.setState ({ enableApproveReject: true, enableSubmitSave: true });
        }

        if (nextProps.selectedRowData && _this.props.selectedRowData && editableRole && nextProps.selectedRowData.copyStatus != "Approved") {
            _this.setState ({ enableSubmitSave: false, enableApproveReject: true });
        } else {
            _this.setState ({ enableSubmitSave: true, enableApproveReject: true });
        }
    }

    render() {
        return(
            <div id="cw-container">
                <div className="cw-details">
                    <CopyDetails
                        selectedRowData = {this.state.selectedRowData}
                    />
                </div>
                <div className="cw-body">
                    <ImageAndPi
                        selectedRowData = {this.state.selectedRowData}
                        setSelectedTab = {this.setSelectedTab}
                    />
                    <CopyInfo
                        selectedRowData = {this.state.selectedRowData}
                        ref = {(infoRef) => {this.infoRef = infoRef;}}
                        enableApproveReject = {this.state.enableApproveReject}
                        enableSubmitSave = {this.state.enableSubmitSave}
                        user = {this.props.user}
                        setNewCopyStatus = {() => this.setNewCopyStatus()}
                    />
                </div>
                <CopyWritingFooter
                    {...this.props}
                    selectedTab = {this.state.selectedTab}
                    cancelChangesCopywrite = {() => this.cancelChangesCopywrite()}
                    saveCopywrite = {() => this.saveCopywrite()}
                    enableApproveReject = {this.state.enableApproveReject}
                    enableSubmitSave = {this.state.enableSubmitSave}
                    rejectCopywrite = {() => this.rejectCopywrite()}
                    submitCopywrite = {() => this.submitCopywrite()}
                    approveCopywrite = {() => this.approveCopywrite()}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        selectedRowData: state.copyWritingGridSelectedRow,
    };
};

export default connect (mapStateToProps, null) (Copywriting);
