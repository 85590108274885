import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import JqxGrid from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';

import CancelModal from '../modals/cancelModal.js';
import CancelModalForPhoto from './helperComponents/CancelModalForPhotoContainer';
import Modal from '../modals/common-modal.js';
import ConfirmDelete from './helperComponents/confirmsubmit';
import CreateEvent from './helperComponents/event-creatorContainer';
import EditOrViewEvent from './helperComponents/editOrViewEventContainer';
import MarketingFooter from './helperComponents/footer.js';
import Find from './helperComponents/findContainer';

import { RingLoader } from 'react-spinners';

import RightDrawer from '../rightDrawer/rightDrawerContainer';

const MarketingComponent = ({
    state,
    props,
    marketingGrid,
    gridData,
    gridHeight,
    editSettings,
    closeAddEventModal,

    //edit or view event
    UpdateMarketingRow,
    editScreenModified,
    closeEditOrViewEventModal,

    //confirm delete
    modalClass,
    modalClose,
    submitRejectReason,
    modalName,
    saveEvent,
    closeAllModal,
    saveEditEvent,
    closeAllEditModal,

    //modals
    closeBriefModal,
    changeValueHolder,
    saveEventBrief,
    enableReject,
    approveEventTask,
    handleRejectField,
    closeCopyWriteModal,
    saveCopywrite,
    closeLayoutModal,
    updateMainViewer,
    handleLayoutSubmit,
    handleFileChange,
    submitLayout,
    closePhotographyModal,
    submitPhotography,

    //marketing footer
    openAddEvent,
    deleteEvent,
    submitEditEvent,
    submitEvent,
    approveEvent,
    rejectEvent,

    //find
    findRef,
    marketingGridThis,

    //CancelModal
    closePhotoorLayoutModal,
    //for RightDrawer
    currentPage,
    imageErrorCheckGrid,
    onRowselect,
    onRowunselect,
    openPublishPanel,

}) => {
    return (

        <div>
            <div>

                <div>

                    {state.isLoaded &&
                        <div id="MarketingGrid">
                            <JqxGrid
                                ref={marketingGrid}
                                width={'100%'}
                                source={gridData.dataAdapter}
                                pageable={false}
                                pagermode={'simple'}
                                checkboxes={true}
                                editable={false}
                                hierarchicalCheckboxes={true}
                                columnsResize={true}
                                columns={gridData.columns}
                                sortable={true}
                                autoRowHeight={false}
                                autoWidth={true}
                                vertical={true}
                                height={gridHeight}
                                theme={'light'}
                                editSettings={editSettings}
                                columnsreorder={true}
                                selectionmode={'checkbox'}
                                columnsmenu={false}
                                enabletooltips={true}
                                onRowselect = {onRowselect}
                                onRowunselect = {onRowunselect}
                                columnsresize = {true}
                                enableellipsis={true} />
                        </div>
                    }

                    <CreateEvent
                        user={props.user}
                        close={closeAddEventModal}
                        isOpen={state.isAddEventOpen}
                        addEventData={state.addEventData}
                        isLoaded={state.addEventLoaded}
                    />

                    <div id="EditOrViewEventMarketingGrid">
                        <EditOrViewEvent
                            UpdateMarketingRow={UpdateMarketingRow}
                            editScreenModified={editScreenModified}
                            confirmModal={state.isModalOpen}
                            close={closeEditOrViewEventModal}
                            isOpen={state.isEditrOrViewEventOpen}
                            addEventData={state.addEventData}
                            isLoaded={state.editEventLoaded}
                            eventData={state.editEventuntochedData}
                            eventStateData={state.editEventData}
                            user={props.user}
                        />
                    </div>

                    {
                        state.isModalOpen &&
                        <Modal isOpen={state.isModalOpen} addClass={modalClass}>
                            <ConfirmDelete
                                rejectReason={state.editEventuntochedData.rejectComments}
                                modalClose={modalClose}
                                submitReject={submitRejectReason}
                                modalName={modalName}
                                yesSaveChanges={saveEvent}
                                noSaveChanges={closeAllModal}
                                yesSaveEditChanges={saveEditEvent}
                                noSaveEditChanges={closeAllEditModal}
                                campaignOverviewData={state.campaignOverviewData} />
                        </Modal>
                    }

                    {/* Event Modals */}
                    <Modal isOpen={state.briefModalOpen} addClass={"brief-modal " + (state.viewMode ? "" : "view-mode")}>
                        {state.modalName === "briefModal" &&
                            <div>
                                <div className="modal-header">
                                    {state.isTaskReview ? 'Review' : 'Add'} Event Brief
                                    <span className="close" onClick={closeBriefModal}>x</span>
                                </div>
                                <div className={"modal-content " + (state.noTaskContent ? "show" : "hide")}>
                                    <h4 className={"text-center"}>No Event content to display</h4>
                                </div>
                                <div className={"modal-content " + (state.noTaskContent ? "hide" : "show")}>
                                    <div className="row">
                                        <div className="col-12">
                                            <p className={"status"}></p>
                                            <div className="form-group">
                                                <label className="form-label mb-10">Event Brief</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className={"brief"}>
                                                <textarea name="textarea" className="brief value-holder"
                                                    onChange={changeValueHolder}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"modal-footer text-center " + (state.isTaskReview ? "hide" : "")}>
                                        <button className="gray-button" onClick={closeBriefModal}
                                            data-val={state.activeEventID} data-action={"cancel"}>Cancel
                                        </button>
                                        <button id="saveBrief" disabled={state.briefOrCopySavedisable} className="gray-button" onClick={saveEventBrief}
                                            data-val={state.activeEventID} data-action={"save"}>Save
                                        </button>
                                        <button disabled={state.saveSubmitDisable} className="gray-button" onClick={saveEventBrief}
                                            data-val={state.activeEventID} data-action={"submit"}>Submit for
                                        Approval
                                        </button>
                                    </div>

                                    <div
                                        className={"row clearfix modal-footer text-center " + (state.isTaskReview ? "" : "hide")}>
                                        <div className="col-12 mtb-10">
                                            <button className="gray-button" onClick={closeBriefModal}
                                                data-val={state.activeEventID} data-action={"cancel"}>Cancel
                                            </button>
                                            <button className="gray-button" onClick={enableReject}
                                                data-val={state.activeEventID} data-action={"reject"}>Reject
                                            </button>
                                            <button className="gray-button" onClick={approveEventTask}
                                                data-val={state.activeEventID} data-action={"approve"}>Approve
                                            </button>
                                        </div>
                                        <div className="col-12 mtb-10 text-left">
                                            <label className={"form-label " + (state.showReject ? "" : "hide")}>Review
                                            notes</label>
                                            <textarea className={"reject-field " + (state.showReject ? "" : "hide")}
                                                onChange={handleRejectField}></textarea>
                                        </div>
                                        <div className={"col-12 mtb-10 " + (state.showReject ? "" : "hide")}>
                                            <button className="gray-button" disabled={state.rejectText.length == 0}
                                                onClick={approveEventTask} data-val={state.activeEventID}
                                                data-action={"rejectsubmit"}>Submit
                                            </button>
                                            <button className="gray-button"
                                                onClick={approveEventTask} data-val={state.activeEventID}
                                                data-action={"rejectsave"} disabled={state.disableRejectSave}>Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </Modal>

                    <Modal isOpen={state.copyWriteModalOpen} addClass={"copy-write-modal " + (state.viewMode ? "" : "view-mode")}>
                        {state.modalName === "copyWriteModal" &&
                            <div>
                                <div className="modal-header">
                                    {state.isTaskReview ? 'Review' : 'Add'} Event Copy
                                    <span className="close" onClick={closeCopyWriteModal}>x</span>
                                </div>
                                <div className={"modal-content " + (state.noTaskContent ? "show" : "hide")}>
                                    <h4 className={"text-center"}>No Event content to display</h4>
                                </div>
                                <div className={"modal-content " + (state.noTaskContent ? "hide" : "show")}>
                                    <div className="row">
                                        <p className={"status"}></p>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-label mb-10">Event Details</label>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className={"description"}>
                                                <textarea name="textarea" className="jqte-test event-copywrite" onChange={changeValueHolder}></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"modal-footer text-center " + (state.isTaskReview ? "hide" : "")}>
                                        <button className="gray-button" onClick={closeCopyWriteModal}
                                            data-val={state.activeEventID} data-action={"cancel"}>Cancel
                                        </button>
                                        <button className="gray-button" onClick={saveCopywrite}
                                            data-val={state.activeEventID} data-action={"save"}
                                            id="saveCopy" disabled={state.briefOrCopySavedisable ? 'disabled' : ''}>Save
                                        </button>
                                        <button className="gray-button" onClick={saveCopywrite}
                                            data-val={state.activeEventID} data-action={"submit"} disabled={state.saveSubmitDisable}>Submit for
                                        Approval
                                        </button>
                                    </div>
                                    <div
                                        className={"row clearfix modal-footer text-center " + (state.isTaskReview ? "" : "hide")}>
                                        <div className="col-12 mtb-10">
                                            <button className="gray-button" onClick={closeCopyWriteModal}
                                                data-val={state.activeEventID} data-action={"cancel"}>Cancel
                                            </button>
                                            <button className="gray-button" onClick={enableReject}
                                                data-val={state.activeEventID} data-action={"reject"}>Reject
                                            </button>
                                            <button className="gray-button" onClick={approveEventTask}
                                                data-val={state.activeEventID} data-action={"approve"}>Approve
                                            </button>
                                        </div>
                                        <div className="col-12 mtb-10 text-left">
                                            <label className={"form-label " + (state.showReject ? "" : "hide")}>Review
                                            notes</label>
                                            <textarea className={"reject-field " + (state.showReject ? "" : "hide")}
                                                onChange={handleRejectField}></textarea>
                                        </div>
                                        <div className={"col-12 mtb-10 " + (state.showReject ? "" : "hide")}>
                                            <button className="gray-button" disabled={state.rejectText.length == 0}
                                                onClick={approveEventTask} data-val={state.activeEventID}
                                                data-action={"rejectsubmit"}>Submit
                                            </button>
                                            <button className="gray-button" onClick={approveEventTask}
                                                data-val={state.activeEventID} data-action={"rejectsave"} disabled={state.disableRejectSave}>Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </Modal>

                    <Modal isOpen={state.layoutModalOpen} addClass={"layout-modal " + (state.viewMode ? "" : "view-mode")}>
                        {state.modalName === "layoutModal" &&
                            <div>
                                <div className="modal-header">
                                    {state.isTaskReview ? 'Review' : 'Add'} Layout/Design
                                    <span className="close" onClick={closeLayoutModal}>x</span>
                                </div>
                                <div className={"modal-content " + (state.noTaskContent ? "show" : "hide")}>
                                    <h4 className={"text-center"}>No Event content to display</h4>
                                </div>
                                <div className={"modal-content " + (state.noTaskContent ? "hide" : "show")}>
                                    <p className={"status"}></p>
                                    <div className="vertical-image-holder">
                                        <div className="thumbnail-holder scroll">
                                            <ul>
                                                {
                                                    state.imageArr.map((item, index) => (
                                                        <li className="thumbnails" key={index}
                                                            onClick={() => (updateMainViewer(index))}>
                                                            <img src={item} />
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                        <div className="main-image">
                                            {imageErrorCheckGrid}
                                        </div>
                                        <div className="upload-holder">
                                            <form name="event-images-upload" className="images-upload"
                                                onSubmit={handleLayoutSubmit}>
                                                <input type='hidden' name="pid" defaultValue={state.activeEventID} />
                                                <div className="upload-input">
                                                    <div className="icon-holder" disabled={state.isTaskReview}>
                                                        <span className="plus-icon"></span>
                                                    </div>
                                                    <input type="file" name="files" accept="image/*" onChange={handleFileChange}
                                                        disabled={state.isTaskReview} />
                                                </div>
                                                <label className="block text-center"> Add Photo(s)
                                                    <span
                                                        className={"image-name " + (state.inputFile.length > 0 ? "" : "hide")}>: {state.inputFileName}</span>
                                                </label>
                                            </form>
                                        </div>
                                    </div>
                                    <div className={"modal-footer text-center " + (state.isTaskReview ? "hide" : "")}>
                                        <button className="gray-button" onClick={closeLayoutModal}
                                            data-val={state.activeEventID} data-action={"cancel"}>Cancel
                                        </button>
                                        <button className="gray-button" onClick={handleLayoutSubmit}
                                            data-val={state.activeEventID}
                                            disabled={state.inputFile.length > 0 ? '' : 'disabled'}
                                            data-action={"save"}>Save
                                        </button>
                                        <button className="gray-button" onClick={submitLayout}
                                            data-val={state.activeEventID} data-action={"submit"}
                                            disabled={state.imageArr.length > 0 ? '' : 'disabled'}>Submit for
                                        Approval
                                        </button>
                                    </div>
                                    <div
                                        className={"row clearfix modal-footer text-center " + (state.isTaskReview ? "" : "hide")}>
                                        <div className="col-12 mtb-10">
                                            <button className="gray-button" onClick={closeLayoutModal}
                                                data-val={state.activeEventID} data-action={"cancel"}>Cancel
                                            </button>
                                            <button className="gray-button" onClick={enableReject}
                                                data-val={state.activeEventID} data-action={"reject"}>Reject
                                            </button>
                                            <button className="gray-button" onClick={approveEventTask}
                                                data-val={state.activeEventID} data-action={"approve"}>Approve
                                            </button>
                                        </div>
                                        <div className="col-12 mtb-10 text-left">
                                            <label className={"form-label " + (state.showReject ? "" : "hide")}>Review
                                            notes</label>
                                            <textarea className={"reject-field " + (state.showReject ? "" : "hide")}
                                                onChange={handleRejectField}></textarea>
                                        </div>
                                        <div className={"col-12 mtb-10 " + (state.showReject ? "" : "hide")}>
                                            <button className="gray-button" onClick={approveEventTask}
                                                data-val={state.activeEventID} data-action={"rejectsubmit"}
                                                disabled={state.rejectText.length == 0}>Submit
                                            </button>
                                            <button className="gray-button" onClick={approveEventTask}
                                                data-val={state.activeEventID} data-action={"rejectsave"} disabled={state.disableRejectSave}>Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </Modal>

                    <Modal isOpen={state.photographyModalOpen} addClass={"photography-modal " + (state.viewMode ? "" : "view-mode")}>
                        {state.modalName === "photographyModal" &&
                            <div>
                                <div className="modal-header">
                                    {state.isTaskReview ? 'Review' : 'Add'} Photography
                                    <span className="close" onClick={closePhotographyModal}>x</span>
                                </div>
                                <div className={"modal-content " + (state.noTaskContent ? "show" : "hide")}>
                                    <h4 className={"text-center"}>No Event content to display</h4>
                                </div>
                                <div className={"modal-content " + (state.noTaskContent ? "hide" : "show")}>
                                    <p className={"status"}></p>
                                    <div className="vertical-image-holder">
                                        <div className="thumbnail-holder scroll">
                                            <ul>
                                                {
                                                    state.imageArr.map((item, index) => (
                                                        <li className="thumbnails" key={index}
                                                            onClick={() => (updateMainViewer(index))}>
                                                            <img src={item} />
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                        <div className="main-image">
                                            {imageErrorCheckGrid}
                                        </div>
                                        <div className="upload-holder">
                                            <form name="event-images-upload" className="images-upload"
                                                onSubmit={handleLayoutSubmit}>
                                                <input type='hidden' name="pid" defaultValue={state.activeEventID} />
                                                <div className="upload-input">
                                                    <div className="icon-holder" disabled={state.isTaskReview}>
                                                        <span className="plus-icon"></span>
                                                    </div>
                                                    <input type="file" name="files" accept="image/*" onChange={handleFileChange}
                                                        disabled={state.isTaskReview} />
                                                </div>
                                                <label className="block text-center"> Add Photo(s)
                                                    <span
                                                        className={"image-name " + (state.inputFile.length > 0 ? "" : "hide")}>: {state.inputFileName}</span>
                                                </label>
                                            </form>
                                        </div>
                                    </div>
                                    <div className={"modal-footer text-center " + (state.isTaskReview ? "hide" : "")}>
                                        <button className="gray-button" onClick={closePhotographyModal}
                                            data-val={state.activeEventID} data-action={"cancel"}>Cancel
                                        </button>
                                        <button id="savePhotodata" className="gray-button" onClick={handleLayoutSubmit}
                                            data-val={state.activeEventID}
                                            disabled={state.inputFile.length > 0 ? '' : 'disabled'}
                                            data-action={"save"}>Save
                                        </button>
                                        <button className="gray-button" onClick={submitPhotography}
                                            data-val={state.activeEventID} data-action={"submit"}
                                            disabled={state.imageArr.length > 0 ? '' : 'disabled'}>Submit for
                                        Approval
                                        </button>
                                    </div>
                                    <div
                                        className={"row clearfix modal-footer text-center " + (state.isTaskReview ? "" : "hide")}>
                                        <div className="col-12 mtb-10">
                                            <button className="gray-button" onClick={closePhotographyModal}
                                                data-val={state.activeEventID} data-action={"cancel"}>Cancel
                                            </button>
                                            <button className="gray-button" onClick={enableReject}
                                                data-val={state.activeEventID} data-action={"reject"}>Reject
                                            </button>
                                            <button className="gray-button" onClick={approveEventTask}
                                                data-val={state.activeEventID} data-action={"approve"}>Approve
                                            </button>
                                        </div>
                                        <div className="col-12 mtb-10 text-left">
                                            <label className={"form-label " + (state.showReject ? "" : "hide")}>Review
                                            notes</label>
                                            <textarea className={"reject-field " + (state.showReject ? "" : "hide")}
                                                onChange={handleRejectField}></textarea>
                                        </div>
                                        <div className={"col-12 mtb-10 " + (state.showReject ? "" : "hide")}>
                                            <button className="gray-button" disabled={state.rejectText.length == 0}
                                                onClick={approveEventTask} data-val={state.activeEventID}
                                                data-action={"rejectsubmit"}>Submit
                                            </button>
                                            <button className="gray-button" onClick={approveEventTask}
                                                data-val={state.activeEventID} data-action={"rejectsave"} disabled={state.disableRejectSave}>Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </Modal>
                </div>





                <MarketingFooter
                    editSaveEnable={state.editSaveEnable}
                    userData={props.user}
                    totalRowCount={state.totalCount}
                    addEvent={openAddEvent}
                    deleteEvent={deleteEvent}
                    isOpen={state.isAddEventOpen}
                    isEditOpen={state.isEditrOrViewEventOpen}
                    cancelEvent={closeAddEventModal}
                    cancelEditEvent={closeEditOrViewEventModal}
                    saveEvent={saveEvent}
                    saveEditEvent={saveEditEvent}
                    submitEditEvent={submitEditEvent}
                    submitEvent={submitEvent}
                    approveEvent={approveEvent}
                    rejectEvent={rejectEvent}
                    eventData={state.editEventuntochedData}
                    openPublishPanel= {openPublishPanel}
                    {...props}
                />

                <Find
                    ref={findRef}
                    marketingGrid={marketingGridThis}> //was this instead of marketingGrid
                </Find>

                <CancelModalForPhoto
                    isOpen={state.isCancelModalForPhotoOpen}
                    modalClose={closePhotoorLayoutModal}
                />

            </div>







        </div>
    );
};

MarketingComponent.propTypes = {
    state: PropTypes.object,
    props: PropTypes.object,
    marketingGrid: PropTypes.func,
    gridData: PropTypes.object,
    gridHeight: PropTypes.number,
    editSettings: PropTypes.object,
    closeAddEventModal: PropTypes.func
};

export default withRouter(MarketingComponent);
