import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import './creative.css';
import './creativeAG.css';
import './creative.less';
import { connect } from 'react-redux';

const API = require('../common/configurations.js');

import axios from 'axios';
import moment from 'moment';

import { ImageRender } from './helperComponentsAgGrid/imageRenderer';
import { CheckboxRender } from './helperComponentsAgGrid/checkboxRenderer';
import { CreativeDropdownEditor } from './helperComponentsAgGrid/dropdownEditor';
import { CreativeDropdownRenderer } from './helperComponentsAgGrid/dropdownRenderer';

import NotificationManager from '../common/components/notification';
import ScanModal from '../modals/scan-modal';
import ConfirmModal from '../modals/confirm-modal';
import { CellRenderer } from './helperComponents/cellRender';
import ImageListerModal from './helperComponents/imageListerModal';
import CreativeServices from './services/creative-services';
import ParentDropdown from './helperComponentsAgGrid/parentDropdown';
import ScanActionCreative from './helperComponentsAgGrid/scanActions';
import * as creative_actions from '../creative/redux/creativeActions';
import * as PhotoDirection from './helperComponentsAgGrid/photoDirection';

class Creative extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rowData: [],
            columnDefs: [],
            photographer: [],
            retoucher: [],
            shotCount: [],
            sampleLocation: ['Studio Manager', 'Photographer', 'Retoucher'],
            totalRowCount: 0,
            totalShotCount: 0,
            isAddShot: false,
            isDeleteShot: false,
            isConfirmModalOpen: false,
            scanModalOpen: false,
            rowsSelected: false,
            sendAlert: false,
            isModalOpen: false,
            parentDropdownModal: false,
            parentDropdownValues: [],
            imageData: {},
            parentPopupValues: [],
            setModalPos: null,
            showScanOptions: false,
            scanActionsModal: false,
            imageRowNode: null,

            overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Loading</span>',
            overlayNoRowsTemplate:
            '<span style="padding: 10px; font-size: 28px; font-weight:\'lighter\' ; '
            + 'color: #525E61;">Please Select filter or alert to proceed</span>',
            autoGroupColumnDef: {
                headerName: ' ',
                field: 'parentId',
                width: 80,
                cellRendererParams: {
                    suppressCount: true,
                    checkbox: true,
                },
            },

            rowClassRules: {
                'shot-approved': function (params) {
                    const node = params.node;

                    if (node && node.group) {
                        if (node.data && node.data.approvedFlag) {
                            return 'shot-approved-true';
                        }
                    }
                },
            },
        };
    }

    componentWillMount() {
        localStorage.setItem('currentPage', 'creative');

        console.log('Creative componentWillMount: ', this.props);

        if (this.props.leftDrawerPulled) {
            console.log('calling toggleLeftDrawer');

            this.props.toggleLeftDrawer();
        }
    }

    componentDidMount() {
        console.log('creative component did mount: ', this.props);

        CreativeServices.gridData().then((response) => {
            let res = response.responseData;

            console.log('creative response data', res);

            if (res.data) {
                let columnDetails = res.columnDetails;
                let newColumnDetails = [];
                let columnDetailsLength = columnDetails.length;

                let retoucher = this.state.retoucher;
                retoucher = res.dropdowns.retouchers;

                let photographer = this.state.photographer;
                photographer = res.dropdowns.photographers;

                let shotCount = this.state.shotCount;
                shotCount = res.dropdowns.shotCount;

                let rowData = res.data;

                for (let i = 0; i < columnDetailsLength; i++) {
                    let obj = {};
                    obj.headerName = columnDetails[i].columnTitle;
                    obj.field = columnDetails[i].fieldName;
                    obj.colId = columnDetails[i].fieldName;
                    obj.width = 100;
                    obj.order = columnDetails[i].order;
                    obj.editable = false;
                    obj.lockPinned = true;
                    obj.hide = !columnDetails[i].visibility;

                    if (columnDetails[i].fieldName === 'department') {
                        obj.editable = false;
                        obj.resizable = true;
                        obj.cellRendererFramework = CellRenderer;
                        obj.cellRendererParams = {rowData: rowData};
                    }

                    if (columnDetails[i].fieldName === 'subclass') {
                        obj.editable = false;
                        obj.resizable = true;
                        obj.cellRendererFramework = CellRenderer;
                        obj.cellRendererParams = {rowData: rowData};
                    }

                    if (columnDetails[i].fieldName === 'description') {
                        obj.editable = false;
                        obj.width = 200;
                        obj.cellRendererFramework = CellRenderer;
                        obj.cellRendererParams = {rowData: rowData};
                    }

                    if (columnDetails[i].fieldName === 'reference') {
                        obj.editable = false;
                    }

                    if (columnDetails[i].fieldName === 'creativeId') {
                        obj.editable = false;
                        obj.cellRendererFramework = CellRenderer;
                        obj.cellRendererParams = {rowData: rowData};
                    }

                    if (columnDetails[i].fieldName === 'model') {
                        obj.editable = false;
                        obj.cellRendererFramework = CellRenderer;
                        obj.cellRendererParams = {rowData: rowData};
                    }

                    if (columnDetails[i].fieldName === 'model') {
                        obj.editable = function (params) {
                            if (params.node.group) {
                                return true;
                            }
                        };

                        obj.cellRendererFramework = CellRenderer;
                        obj.cellRendererParams = {rowData: rowData};
                    }

                    if (columnDetails[i].fieldName === 'stylist') {
                        obj.editable = function (params) {
                            if (params.node.group) {
                                return true;
                            }
                        };

                        obj.cellRendererFramework = CellRenderer;
                        obj.cellRendererParams = {rowData: rowData};
                    }

                    if (columnDetails[i].fieldName === 'modelDetails') {
                        obj.editable = function (params) {
                            if (params.node.group) {
                                return true;
                            }
                        };

                        obj.cellRendererFramework = CellRenderer;
                        obj.cellRendererParams = {rowData: rowData};
                    }

                    if (columnDetails[i].fieldName === 'hairMakeup') {
                        obj.editable = function (params) {
                            if (params.node.group) {
                                return true;
                            }
                        };

                        obj.cellRendererFramework = CellRenderer;
                        obj.cellRendererParams = {rowData: rowData};
                    }

                    if (columnDetails[i].fieldName === 'studioSet') {
                        obj.editable = function (params) {
                            if (params.node.group) {
                                return true;
                            }
                        };

                        obj.cellRendererFramework = CellRenderer;
                        obj.cellRendererParams = {rowData: rowData};
                    }

                    if (columnDetails[i].fieldName === 'photo_reshoot_notes') {
                        obj.editable = false;
                    }

                    if (columnDetails[i].columnTitle === 'ParentId') {
                        obj.rowGroup = true;
                    }

                    if (columnDetails[i].fieldName === 'images') {
                        obj.editable = false;
                        obj.resizable = true;
                        obj.width = 55;
                        obj.cellRendererFramework = CellRenderer;
                        obj.cellRendererParams = {
                            rowData: rowData,
                        };
                    }

                    if (columnDetails[i].fieldName === 'photographFlag') {
                        obj.cellRendererFramework = CheckboxRender;
                        obj.cellRendererParams = {
                            userData: this.props.user,
                            updatePhotographerStatus: this.updatePhotographerStatus.bind(this),
                            updateCheckboxes: this.updateCheckboxes.bind(this),
                        },
                        obj.editable = false;
                    }

                    if (columnDetails[i].fieldName === 'reTouchFlag') {
                        obj.cellRendererFramework = CheckboxRender;
                        obj.cellRendererParams = {
                            userData: this.props.user,
                            updateCheckboxes: this.updateCheckboxes.bind(this),
                        },
                        obj.editable = false;
                    }

                    if (columnDetails[i].fieldName === 'retouchNotes') {
                        obj.editable = this.retouchEditable.bind(this);
                    }

                    if (columnDetails[i].fieldName === 'photographer') {
                        obj.cellRendererFramework = CreativeDropdownRenderer;
                        obj.cellEditorFramework = CreativeDropdownEditor;
                        obj.cellEditorParams = {
                            dropdownList: photographer,
                            width: '100%',
                            updatePhotographerStatus: this.updatePhotographerStatus.bind(this),
                            updateFields: this.updateFields.bind(this),
                        };

                        obj.editable = function (params) {
                            if (params.node.group) {
                                return false;
                            } else {
                                return true;
                            }
                        };
                    }

                    if (columnDetails[i].fieldName === 'retoucher') {
                        obj.cellRendererFramework = CreativeDropdownRenderer;
                        obj.cellEditorFramework = CreativeDropdownEditor;
                        obj.cellEditorParams = {
                            dropdownList: retoucher,
                            width: '100%',
                            updatePhotographerStatus: this.updatePhotographerStatus.bind(this),
                            updateFields: this.updateFields.bind(this),
                        };

                        obj.editable = function (params) {
                            if (params.node.group) {
                                return false;
                            } else {
                                return true;
                            }
                        };
                    }

                    if (columnDetails[i].fieldName === 'shotCount') {
                        obj.cellRendererFramework = CreativeDropdownRenderer;
                        obj.cellRendererParams = {rowData: rowData};
                        obj.cellEditorFramework = CreativeDropdownEditor;
                        obj.cellEditorParams = {
                            dropdownList: shotCount,
                            addShots: this.addShots.bind(this),
                            width: '100%',
                        };

                        obj.cellStyle = function (params) {
                            if (params.value == '' || !params.value && params.node.level != 0) {
                                return { color: 'black', backgroundColor: '#EDA596', borderRight: '1px solid lightgrey' };
                            }
                        };

                        obj.editable = function (params) {
                            if (params.node.group) {
                                return false;
                            } else {
                                return true;
                            }
                        };
                    }

                    if (columnDetails[i].fieldName === 'location') {
                        obj.cellRendererFramework = CreativeDropdownRenderer;
                        obj.cellEditorFramework = CreativeDropdownEditor;
                        obj.cellEditorParams = {
                            dropdownList: this.state.sampleLocation,
                            width: '100%',
                            updateLocation: this.updateLocation.bind(this),
                            updateFields: this.updateFields.bind(this),
                        };

                        obj.editable = function (params) {
                            if (params.node.group) {
                                return false;
                            } else {
                                return true;
                            }
                        };
                    }

                    if (columnDetails[i].fieldName === 'photographerImageName') {
                        obj.editable = function (params) {
                            if (!params.node.group) {
                                return true;
                            }
                        };

                        obj.cellRendererFramework = CellRenderer;
                        obj.cellRendererParams = {rowData: rowData};
                    }

                    if (columnDetails[i].fieldName === 'photographyDirection') {
                        obj.editable = function (params) {
                            if (params.node.group) {
                                return true;
                            }
                        };

                        obj.cellRendererFramework = PhotoDirection.PhotoDirectionRender;
                        obj.cellRendererParams = {rowData: rowData};
                        obj.cellEditorFramework = PhotoDirection.PhotoDirectionEditor;
                        obj.cellEditorParams = {
                            updateData: this.updatePhotoDirection.bind(this),
                            user: this.props.user,
                        };
                    }

                    if (columnDetails[i].fieldName === 'photographedDate') {
                        obj.cellRendererFramework = this.dateRenderer.bind(this);
                    }

                    if (columnDetails[i].fieldName === 'retouchedDate') {
                        obj.cellRendererFramework = this.dateRenderer.bind(this);
                    }

                    newColumnDetails.push(obj);
                }

                let rowDataLength = rowData.length;
                let newRowData = [];

                newColumnDetails.sort((a, b) => b - a);

                let shotCountNumber = 0;

                rowData.forEach((i, x) => {
                    shotCountNumber = shotCountNumber + i.shotCount;

                    i.creativeShots.forEach((j, k)=> {
                        newRowData.push(j);
                    });
                });

                newRowData.sort((a, b) => b.parentId - a.parentId);

                console.log('columns creative: ', columnDetails);
                console.log('columns creative: ', newColumnDetails);
                console.log('columns creative: ', newRowData);

                this.setState({
                    columnDefs: newColumnDetails,
                    rowData: newRowData,
                    fullRowData: rowData,
                    retoucher,
                    shotCount,
                    photographer,
                    totalRowCount: res.totalDataCount,
                    totalShotCount: res.totalShotCount,
                }, () => {
                    setTimeout(() => {
                        this.gridApi.forEachNode((i) => {
                            rowData.forEach((j) => {
                                if (i.key == j.id) {
                                    i.data = j;
                                }
                            });
                        });
                        this.gridApi.redrawRows();
                        this.props.toggleSpinner(false);
                    }, 500);
                });
            }
        }).catch((error) => {
            console.log('creative componentDidMount API call failed', error);
        });
    }

    componentWillReceiveProps(nextProps) {
        const _this = this;

        console.log('creative componentWillReceiveProps', this.props, nextProps);

        let newData = nextProps.gridData.data;
        let colDefs = this.state.columnDefs;

        if (JSON.stringify(newData) != JSON.stringify(this.props.gridData.data) && nextProps.gridData.fromLeftPanel) {
            let newRowData = [];

            // moving all the creative shots into a new array for grouping
            newData.data.forEach((i, x) => {
                i.creativeShots.forEach((j, k)=> {
                    newRowData.push(j);
                });
            });

            _this.setState({
                totalRowCount: newData.totalDataCount,
                totalShotCount: newData.totalShotCount,
                rowData: newRowData,
            }, () => {
                this.gridApi.forEachNode((i) => {
                    newData.data.forEach((j) => {
                        if (i.key == j.id) {
                            i.data = j;
                        }
                    });
                });

                this.gridApi.redrawRows();
            });
        }
    }

    retouchEditable(params) {
        const userRole = this.props.user.defaultRole.toLowerCase();

        if (['photographer', 'retoucher', 'admin', 'buyer'].includes(userRole) && !params.node.group) {
            return true;
        } else {
            return false;
        }
    }

    dateRenderer(params) {
        console.log('dateRenderer check params', params);

        if (params.value) {
            return moment(new Date(params.value)).format('MM/DD/YYYY');
        } else {
            return '';
        }
    }

    updateCheckboxes(data, rowNode) {
        console.log('selected updateCheckboxes: ', data);

        //api call to update checkboxes
        CreativeServices.photographyComplete(data).then((response) => {
            console.log('checking data for update checkbox response', response);

            const data = response.responseData;

            NotificationManager({
                message: response.message,
                type: 1
            });

            if (response.responseData.photographImageName) {
                rowNode.setDataValue('photographerImageName', data.photographImageName);
                this.copyToClipboard(data.photographImageName); // copies the value to clipboard
            } else {
                rowNode.setDataValue('retoucherImagename', data.retoucherImageName);
                this.copyToClipboard(data.retoucherImageName); // copies the value to clipboard
            }
        }).catch((error) => {
            console.log('creative update checkbox API call failed', error);
        });
    }

    updateRetouchNotes(data) {
        console.log('selected updateRetouchNotes: ', data);

        //api call to update updateRetouchNotes
        CreativeServices.photographyComplete(data).then((response) => {
            console.log('checking data for update checkbox response', response);
            if (response.status == 'success') {
                NotificationManager({
                    message: response.message,
                    type: 1
                });
            } else {
                NotificationManager({
                    message: response.data.message,
                    type: 4
                });
            }
        }).catch((error) => {
            console.log('creative update checkbox API call failed', error);
        });
    }

    addShots(data, node) {
        CreativeServices.addShots(data).then((response) => {
            NotificationManager({
                message: response.message,
                type: 1
            });

            node.setDataValue('ppId', response.responseData.ppId);
            node.setDataValue('ppidLst', [response.responseData.ppId]);

            this.gridApi.redrawRows();
        }).catch((error) => {
            console.log('creative add shots API call failed', error);
        });
    }

    onGridReady(params) {
        console.log('creative component on grid ready');

        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    updateLocation(req) {
        CreativeServices.updateLocation(req).then((response) => {
            let res = response;

            if (res.status) {
                NotificationManager({
                    message: "Data updated Successfully",
                    type: 1
                });
            } else {
                NotificationManager({
                    message: "Failed to update status",
                    type: 4
                });
            }
        }).catch((error) => {
            console.log('creative updateLocation API call failed', error);
            NotificationManager({
                message: "Failed to update status",
                type: 4
            });
        });
    }

    updatePhotographerStatus(req) {
        let _this = this;

        CreativeServices.updatePhotographFlag(req).then((response) => {
            let res = response;

            if (res.status) {
                NotificationManager({
                    message: "Data updated Successfully. " + "<b class='text-red'>This action can't be undone.</b>",
                    type: 1
                });
            } else {
                NotificationManager({
                    message: "Failed to update status",
                    type: 4
                });
            }
        }).catch((error) => {
            console.log('creative updatePhotographerStatus API call failed', error);
            NotificationManager({
                message: "Failed to update status",
                type: 4
            });
        });
    }

    addShotRecord() {
        let _this = this;
        let selectedRows = this.gridApi.getSelectedRows();

        const obj = Object.assign({}, selectedRows[0]);

        Object.keys(obj).forEach(function(index) {
            if (index == 'parentId' || index == 'description' || index == 'dataLevel' || index == 'reference' || index == 'ppidLst' || index == 'prodMedidLst') {
                index = obj[index];
            } else {
                obj[index] = null;
            }
        });

        _this.gridApi.updateRowData({
            add: [obj],
        });

        _this.gridApi.deselectAll();

        console.log('selected row add record', obj, selectedRows);
    }

    toggleScanOptions() {
        this.setState((state) => {
            if (!state.showScanOptions) {
                return {showScanOptions : true};
            } else {
                return {showScanOptions : false};
            }
        });
    }

    openScanActions() {
        this.setState({ scanActionsModal: true });
    }

    closeScanActions() {
        this.setState({ scanActionsModal: false });
    }

    openScanModal() {
        this.setState({ scanModalOpen: true });
    }

    closeScanModal() {
        this.setState({ scanModalOpen: false });
    }

    modalClose() {
        this.setState({ isModalOpen: false, isConfirmModalOpen: false });
    }

    modalOpen() {
        this.setState({ isModalOpen: true});
    }

    parentDropdownModalClose() {
        this.setState({ parentDropdownModal: false});
    }

    parentDropdownModalOpen() {
        this.setState({ parentDropdownModal: true});
    }

    confirmDelete() {
        this.modalClose();
    }

    onRowSelected() {
        let selectedRows = this.gridApi.getSelectedRows();

        if (selectedRows.length > 0) {
            this.setState({ rowsSelected: true, isAddShot: true, isDeleteShot: true, sendAlert: true });
        } else {
            this.setState({ rowsSelected: false, isAddShot: false, isDeleteShot: false, sendAlert: false });
        }
    }

    sendAlertCall() {
        const _this = this;

        let sweftIdList = [];
        let selectedRows = this.gridApi.getSelectedRows();
        let selectedParent = this.gridApi.getBestCostNodeSelection();

        let req = {};

        if (selectedParent.length == 0) {
            NotificationManager({
                message: 'Alert can only be sent for Parent Records',
                type: 4
            });

            return;
        }

        selectedParent.map((i) => {
            sweftIdList.push(i.data.productId);
        });

        req['sweftIdList'] = sweftIdList;

        CreativeServices.sendAlertCreative(req).then((response) => {
            let res = response;

            console.log('checking the response from the backend', response);

            if (res.status == "success") {
                NotificationManager({
                    message:res.message,
                    type:1
                });
            } else {
                NotificationManager({
                    message:res.data.message,
                    type:4
                });
            }
        }).catch((error) => {
            console.log('creative sendAlert API call failed', error.data);
            NotificationManager({
                message:"Error - Failed to send alert.",
                type:4
            });
        });
    }

    copyToClipboard (str) {
        const el = document.createElement('textarea');
        el.value = str;

        document.body.appendChild(el);

        el.select();

        document.execCommand('copy');
        document.body.removeChild(el);

        NotificationManager({
            message: 'Copied to clipboard',
            type:1
        });
    }

    onCellValueChanged(params) {
        console.log('on cell value changed: ', params);

        const _this = this;
        const oldValue = params.oldValue;
        const newValue = params.value;
        const field = params.colDef.field;
        const parentId = params.node.key;

        if (oldValue == newValue || newValue == '') {
            return;
        }

        if (params.node.group && field != 'photographyDirection') {
            this.updateParentTextFields(newValue, parentId, field);
        }

        if (!params.node.group && ['photographerImageName'].includes(field)) {
            this.updateChildFields(newValue, params.data.ppId, field);
        }

        if (field == 'retouchNotes') {
            let data = {};
            data['photographFlag'] = params.data.photographFlag;
            data['ppId'] = params.data.ppId;
            data['sweftId'] = params.data.sweftId;
            data['retouchNotes'] = params.newValue;

            this.updateRetouchNotes(data);
        }
    }

    onCellDoubleClicked(params) {
        console.log('onCellDoubleClicked checks', params);

        const _this = this;
        let imageData = this.state.imageData;

        if (params.colDef.colId == 'images' && params.node.group) {
            const data = params.data.productId;

            imageData.parentId = data;

            _this.modalOpen();
            _this.setState({imageData, imageRowNode: params.node});
        }
    }

    onCellClicked(params) {
        let checkScreen = params.event.clientY <= 300 ? params.event.clientY + 25 : -50;

        console.log('onCellClicked checks', params);

        this.setState({parentDropdownValues: params, setModalPos: checkScreen});

        const _this = this;

        if (params.node.level == 0 && params.colDef.field == 'photographer'){
            _this.setState({ parentPopupValues: _this.state.photographer});
        }

        if (params.node.level == 0 && params.colDef.field == 'retoucher') {
            _this.setState({ parentPopupValues: _this.state.retoucher});
        }

        if (params.node.level == 0 && params.colDef.field == 'location') {
            _this.setState({ parentPopupValues: _this.state.sampleLocation});
        }

        if (params.node.level == 0 && params.colDef.field == 'photographer'
            || params.node.level == 0 && params.colDef.field == 'retoucher'
            || params.node.level == 0 && params.colDef.field == 'location') {
            _this.parentDropdownModalOpen();
        }
    }

    setParentDropdownValue(params) {
        const _this = this;
        const rows = [];
        let ppidLst = [];
        let id;

        let colId = _this.state.parentDropdownValues.colDef.colId;
        let setParent = _this.state.parentDropdownValues.node;
        rows.push(setParent);

        let getNodes = _this.state.parentDropdownValues.node.allLeafChildren;

        getNodes.map((i) => {
            console.log('selected setParentDropdownValue i: ',  i);

            i.data[colId] = params;
            rows.push(i);
            ppidLst.push(i.data.ppId);
        });

        console.log('selected setParentDropdownValue: ',  params, _this.state.parentDropdownValues, ppidLst);

        // formatting data for backend payload
        let data = {};

        if (colId == 'photographer') {
            this.state.photographer.forEach((i) => {
                if (i.name == params) {
                    id = i.id;
                }
            });

            data[colId] = params;
            data['ppidLst'] = ppidLst;
            data['photographerId'] = id;
        }

        if (colId == 'retoucher') {
            this.state.retoucher.forEach((i) => {
                if (i.name == params) {
                    id = i.id;
                }
            });

            data[colId] = params;
            data['ppidLst'] = ppidLst;
            data['retoucherId'] = id;
        }

        if (colId == 'location') {
            data[colId] = params;
            data['ppidLst'] = ppidLst;
        }

        // api call to update shots
        CreativeServices.addShots(data).then((response) => {
            console.log('checking data for add shots response', response);
            NotificationManager({
                message: response.message,
                type: 1
            });
        }).catch((error) => {
            console.log('creative add shots API call failed', error);
        });

        _this.gridApi.redrawRows({ rowNodes: rows });
        this.parentDropdownModalClose();
    }

    updateFields(data) {
        console.log('selected updateFields: ', data);

        // // api call to update shots
        CreativeServices.addShots(data).then((response) => {
            console.log('checking data for add shots response', response);

            NotificationManager({
                message: response.message,
                type: 1
            });

            this.gridApi.redrawRows();
        }).catch((error) => {
            console.log('creative add shots API call failed', error);
        });
    }

    updateParentTextFields(str, id, colId) {
        const _this = this;

        // formatting data for backend payload
        let data = {};
        data[colId] = str;
        data['parentId'] = id;

        // api call to update shots
        CreativeServices.addShots(data).then((response) => {
            NotificationManager({
                message: response.message,
                type: 1
            });
        }).catch((error) => {
            console.log('creative add shots API call failed', error);
        });
    }

    updateChildFields(str, id, colId) {
        const _this = this;

        // formatting data for backend payload
        let data = {};
        data[colId] = str;
        data['ppidLst'] = [id];

        // api call to update shots
        CreativeServices.addShots(data).then((response) => {
            NotificationManager({
                message: response.message,
                type: 1
            });
        }).catch((error) => {
            console.log('creative add shots API call failed', error);
        });
    }

    /**
     * Used for setting reject notes on the grid
     * @param {Object} data
     */
    setRejectedDataOnGrid(res) {
        const node = this.state.imageRowNode;
        const parentId = node.data.id;
        const pasrseData = JSON.parse(node.data.photographyDirection);
        let dataArr = pasrseData;

        const objN = {};

        objN['name'] = this.props.user.userName;
        objN['date'] = new Date();
        objN['comment'] = '[Rejected] ' + res.responseData.buyerNotes;
        objN['rejected'] = true;

        dataArr.unshift(objN);

        let parsed = JSON.stringify(dataArr);

        node.setDataValue('photographyDirection', parsed);
        this.updatePhotoDirection(parsed, parentId, 'photographyDirection');
        this.gridApi.redrawRows();
    }

    updatePhotoDirection(str, id, colId) {
        // formatting data for backend payload
        let data = {};
        data[colId] = str;
        data['parentId'] = id;

        // api call to update shots
        CreativeServices.addShots(data).then((response) => {
            NotificationManager({
                message: response.message,
                type: 1
            });
        }).catch((error) => {
            console.log('creative add shots API call failed', error);
        });
    }

    deleteShot() {
        var _this = this;

        let selectedRows = this.gridApi.getSelectedRows();
        let selectedNodes = this.gridApi.getSelectedNodes();
        let selectedParent = this.gridApi.getBestCostNodeSelection();

        let data = {};
        let ppidLst = [];
        let prodMedidLst = [];

        selectedRows.map((i) => {
            ppidLst.push(i.ppId);
            prodMedidLst.push(i.parentId);
        });

        data['parentId'] = prodMedidLst.toString();
        data['ppidLst'] = ppidLst;

        console.log('selected rows: ', selectedParent, selectedRows, selectedNodes, data, ppidLst, prodMedidLst);

        if (selectedParent.length > 0 && selectedParent[0].allChildrenCount == 1) {
            NotificationManager({
                message: 'Parent Records must have at least one Shot Angle',
                type: 4
            });

            return;
        }

        if (selectedParent.length > 0) {
            NotificationManager({
                message: 'Parent Records can not be deleted',
                type: 4
            });

            return;
        }

        if (selectedRows.length > 0) {
            CreativeServices.deleteShot(data).then((response) => {
                this.gridApi.updateRowData({ remove: selectedRows });

                NotificationManager({
                    message: response.message,
                    type:1
                });
            }).catch((error) => {
                console.log('creative delete shots API call failed', error);
            });
        }
    }

    render() {
        let userData = localStorage.getItem('user');

        let setPos = {
            width: '250px',
            top : this.state.setModalPos,
        };

        console.log('Creative rednerer check : ', this.state, this.props);

        return (
            <div>

                <div
                    id="myGrid"
                    style={{
                        boxSizing: 'border-box',
                        width: '100%',
                        marginLeft: '1px',
                        height: 'calc(100% - 115px)',
                    }}
                    className="ag-theme-balham"
                >
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        rowData={this.state.rowData}
                        onGridReady={this.onGridReady.bind(this)}
                        rowSelection={'multiple'}
                        stopEditingWhenGridLosesFocus={true}
                        overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                        singleClickEdit={true}
                        enterMovesDown={true}
                        enterMovesDownAfterEdit={true}
                        suppressRowClickSelection={true}
                        enableColResize={true}
                        groupSelectsChildren={true}
                        onRowSelected={this.onRowSelected.bind(this)}
                        onCellValueChanged = {this.onCellValueChanged.bind(this)}
                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                        onCellDoubleClicked = {this.onCellDoubleClicked.bind(this)}
                        onCellClicked = {this.onCellClicked.bind(this)}
                        headerHeight={38}
                        animateRows={true}
                        enableGroupEdit={true}
                        rowClassRules={this.state.rowClassRules}
                    />
                </div>
                <div className="footer">
                    <div className="footer-content" style={{ width: '100%' }}>
                        <button className="creative-addshot footer-btn gray-button" disabled={!(this.state.isAddShot)} onClick={this.addShotRecord.bind(this)}>Add Shot</button>
                        <button className="creative-deleteshot footer-btn footer-btn gray-button" disabled={!(this.state.isDeleteShot)} onClick={this.deleteShot.bind(this)}>Delete Shot</button>

                        <span className="creative-row-count">{this.state.totalRowCount} total Products, </span>
                        <span className="creative-row-count">{this.state.totalShotCount} total Shots</span>

                        <div className="right">
                            <div className="record-creation-holder creative-scan-actions">
                                <div className={`record-action gray-button ${(this.state.showScanOptions) ? 'open active' : ''}`}
                                    onClick={this.toggleScanOptions.bind(this)}>Scan By</div>
                                <div className="record-options">
                                    <ul>
                                        <li onClick={this.openScanModal.bind(this)}>Studio Scan</li>
                                        <li onClick={this.openScanActions.bind(this)}>Scan Actions</li>
                                    </ul>
                                </div>
                            </div>

                            {(userData.defaultRole != 'Photographer') && (userData.defaultRole != 'Retoucher') &&
                            <button className="undo_changes footer-btn gray-button" disabled={!(this.state.sendAlert)}
                                onClick={this.sendAlertCall.bind(this)}
                            >Send Alert</button>
                            }
                            <button className="creative_publish footer-btn gray-button" disabled>Creative Publish</button>
                        </div>
                        {this.state.isModalOpen &&
                        <div className="ag-modal-container">
                            <div className="ag-modal-holder">
                                <ImageListerModal
                                    isModalOpen={this.state.isModalOpen}
                                    modalClose={this.modalClose.bind(this)}
                                    data={this.state.imageRowNode}
                                    parentId={this.state.imageData.parentId}
                                    user={this.props.user}
                                    setRejectedDataOnGrid = {this.setRejectedDataOnGrid.bind(this)}
                                    shotAngles = {this.state.shotCount}
                                />
                            </div>
                        </div>
                        }

                        {this.state.parentDropdownModal && this.state.setModalPos &&
                        <div className="ag-modal-container">
                            <div className="ag-modal-holder" style={setPos}>
                                <ParentDropdown
                                    value = {this.state.parentPopupValues}
                                    close = {() => this.parentDropdownModalClose()}
                                    setParentDropdownValue = {this.setParentDropdownValue.bind(this)}
                                    colId = {this.state.parentDropdownValues.colDef.colId}
                                />
                            </div>
                        </div>
                        }

                        {this.state.scanModalOpen &&
                            <ScanActionCreative
                                isOpen = {this.state.scanModalOpen}
                                close = {this.closeScanModal.bind(this)}
                            />
                        }

                        <ScanModal isOpen={this.state.scanActionsModal} modalClose={this.closeScanActions.bind(this)} />

                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        gridData: state.cr_gridData,
    };
};

const mapDispatchToprops = (dispatch) => {
    return {
        addCreativeGridData: (data) => {
            dispatch(creative_actions.addCreativeGridData(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToprops)(Creative);
