
import React from 'react';
import { CustomDropdownEditor } from './customDropdownEditor';

/**
 * renders the size dropdown column
 */
export class SizeDropdownPopupRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        if (this.props.node.data && this.props.node.data.size_attribute) {
            return (
                <div>
                    {this.props.node.data.size_attribute}
                </div>
            );
        } else {
            return (
                <div className="arrow downPos">
                </div>
            );
        }
    }
}

// /////////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * renders the size dropdown when it is in the edit mode
 */

export class SizeDropdownPopupEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedVal: '',
            sizeList: props.getSizeList(),
        };
        // this.handleChange = this.handleChange.bind(this);
    }


    componentWillMount() {
        const sizeList = this.state.sizeList;
        const cellWidth = this.props.eGridCell.style.width;

        if (sizeList.indexOf('Create Variant') === -1) {
            sizeList.push('Create Variant');
        }
        this.setState({
            sizeList,
            selectedVal: this.props.node.data.size_attribute || '',
            width: cellWidth,
        });

        if (this.props.node.data.itemReadyToOrder) {
            this.props.api.stopEditing();
        } else if (this.props.node.data.dataLevel == 'PARENT') {
            this.props.api.stopEditing();
        }
    }

    componentWillUnmount() {
        this.props.api.stopEditing();
    }

    isPopup() {
        return true;
    }

    getValue() {
        return this.state.selectedVal;
    }

    changeSelectedValue(value) {
        this.setState({
            selectedVal: value,
        }, () => this.stopEditing());
    }

    stopEditing() {
        this.props.api.stopEditing();
    }

    render() {
        /**
         * the below class is a common dropdown editor for all dropdowns.
         */
        return (
            <div>
                <CustomDropdownEditor
                    width={this.state.width}
                    selectedVal={this.state.selectedVal}
                    sizeList={this.state.sizeList}
                    changeSelectedValue={this.changeSelectedValue.bind(this)}
                    {...this.props}
                />
            </div>
        );
    }
}

// ////////////////////////////////////////////////////////////////////////////////////
