import React, { Component } from 'react';
var constants = require('../../common/constants.js');
// var NotificationManager = require('../../common/notification');
import NotificationManager from '../../common/components/notification';

import ProductGrid from './product-grid-popup';
import * as utils from '../../common/utils.js';
import axios from 'axios';

import { ProductServices } from '../../product2/services/product-services';

class ProductGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isloaded: false,
            productresult: null
        };
        this.forceTrue = {
            copy: ["productId", "prodName", "color_attribute", "size_attribute", "itemReadyToOrder", "isPiRequestSent", "isPiReceived", "piUpload", "isPiApproved", 'copywrite', 'keywords',
                'attr1', 'attr2', 'attr3', 'attr4', 'attr5', 'attr6', 'attr7', 'attr8', 'attr9', 'attr10', 'attr11', 'attr12', 'attr13', 'attr14', 'attr15',
                'attr16', 'attr17', 'attr18', 'attr19', 'attr20', 'attr', 'attrCompleted', 'attrApproved', 'addPO', 'channel'],
            display: ["productId", "prodName", "color_attribute", "size_attribute", "earlyReqSampleSwatch",
                "isSamplerecieved", "isSampleapproved", "swatchOnly", 'swatchReference', 'featureColor', 'onlineActivatedDate',
                'activeNotes', 'quantityInventory', 'sampleMoved', 'imageApproval', 'shotAngle', 'isActiveLive', 'campaign', 'campaignDate']
        };
        this.updateProductGridRows = this.updateProductGridRows.bind(this);
    }

    componentDidMount() {
        var _this = this;
        let requestparms = this.props.childProdList;

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.groupByPopUp({ chldProuctId: requestparms, userRole: this.props.user.defaultRole, level: _this.props.productGridPopupType })
            .then( (response) => {
                console.log('productContainer helperComponents product-group-popup call success data: ', response.data);
                let res = response.data;

                res.customcolumdetails = [];
                for (var i = 0; i < res.columnDetails.length; i++) {
                    if (_this.forceTrue[_this.props.productGridPopupType].indexOf(res.columnDetails[i].fieldName) > -1) {
                        res.columnDetails[i].visibility = true;
                        res.columnDetails[i].order = _this.forceTrue[_this.props.productGridPopupType].indexOf(res.columnDetails[i].fieldName);
                        res.customcolumdetails.push(res.columnDetails[i]);
                    } else {
                        res.columnDetails[i].visibility = false;
                    }
                }
                _this.setState({ isloaded: true, productresult: res });
            })
            .catch( error => {
                console.log('productContainer helperComponents product-group-popup API call failed', error);
            });
    }

    updateProductGridRows(childrows) {
        var _this = this;
        var req = this.props.childProdList;


        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.groupByPopUp({ chldProuctId: req, userRole: this.props.user.defaultRole, level: 'default' })
            .then( (response) => {
                console.log('productContainer helperComponents product-group-popup call success data: ', response.data);
                let res = response.data;

                if (res.data) {
                    var list = utils.treeify(res.data, "productId", "referrence", "children");
                    if (list.length > 0) {
                        var olist = _this.props.productGrid.getRow(list[0].productId);
                        //list[0].state=olist.state;
                        if (olist.children && olist.children.length) {
                            olist.children.map(function (e, i) {
                                list[0].children[i].state = e.state;
                                if (e.state == "expand") {
                                    list[0].state = "expand";
                                }
                            });
                        }
                        _this.props.productGrid.updateRow(list[0].productId, list[0]);
                        _this.props.productGrid.refreshGrid();
                    }
                    _this.props.modalClose();
                }
            })
            .catch( error => {
                console.log('productContainer helperComponents product-group-popup API call failed', error);
            });
    }

    render() {
        let popupheader = this.props.productGridPopupType === "copy" ? "Copy-Level Grouping:" : "Display-Level Grouping:";
        if (this.props.currentFieldText) {
            popupheader += " " + this.props.currentFieldText.replace(/<[^>]+>/g, '');
        }

        return (
            <div>
                <div className="modal-header">
                    {popupheader}
                    <span className="close" onClick={this.updateProductGridRows}>x</span>
                </div>
                <div className="modal-content">
                    <div className="add-copy modal-form">

                        {this.state.isloaded &&
                            <ProductGrid productresult={this.state.productresult} />
                        }

                    </div>
                </div>
            </div>
        );
    }
}
export default ProductGroup;
