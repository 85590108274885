export const updateCurrentView = (state = {}, action) => {
    switch (action.type) {
        case "UPDATE_CURRENT_VIEW":
            return action.data;
        default:
            return  state;
    }
};
export const toggleFind = (state = {}, action) => {
    switch (action.type) {
        case "TOGGLE_FIND":
            return action.data;
        default:
            return  state;
    }
};
