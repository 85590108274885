import React from 'react';

class SampleSwatchDropDown extends React.Component {
    render() {
        let props = this.props;
        let swatchReferenceList = props.swatchReferenceList;
        let MakeItem = function (x) {
            return <option value={x}>{x}</option>;
        };
        return (
            <div className="modal-content">
                <div className="text-center confirm-delete">
                    <p>Please select swatch reference</p>
                    <select className="grid-swatch-reference">{swatchReferenceList.map(MakeItem)}</select>
                </div>
                <div className="modal-footer text-center">
                    <button className="gray-button"
                        onClick={() => {
                            props.setValueSwatchReference();
                            props.modalClose();
                        }}>
						ok
                    </button>
                </div>
            </div>
        );
    }
}

export default SampleSwatchDropDown;
