
import React from 'react';
import CancelModal from './cancelChanges';

export class ProductTypeDropdownEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedVal: '',
            oldVal: '',
            dropdownValues: [],
            width: null,
            confirmOpen: false,
        };
        // this.handleChange = this.handleChange.bind(this);
    }

    componentWillMount() {
        console.log('ProductTypeDropdownEditor render check', this.props);

        const cellWidth = this.props.eGridCell.style.width;
        this.setState({
            selectedVal: this.props.value ? this.props.value : '',
            dropdownValues: this.props.getproductTypes(),
            oldVal: this.props.value ? this.props.value : '',
            width: cellWidth,
        });
    }

    isPopup() {
        return true;
    }

    getValue() {
        return this.state.selectedVal;
    }

    closeConfirm(key) {
        const _this = this;
        console.log('close confirm', this.state, key);
        switch (key) {
            case 'yes':
                // rowData['setDetail'] = "";
                _this.setState({
                    confirmOpen: false,
                }, () => {
                    const rowData = _this.props.node.data;
                    const colID = _this.props.column.colId;
                    rowData[colID] = _this.state.selectedVal;
                    rowData['setDetail'] = "";

                    _this.props.rowUpdate(rowData);
                    _this.props.api.stopEditing();
                    _this.props.api.redrawRows();
                });
                break;
            case 'no':
                _this.setState({
                    selectedVal: _this.state.oldVal,
                }, () => {
                    _this.props.api.stopEditing();
                });
                break;

            default:
                break;
        }
    }

    changeSelectedValue(event) {
        const rowData = this.props.node.data;
        const colID = this.props.column.colId;
        rowData[colID] = event.target.value;

        this.setState({
            selectedVal: event.target.value,
        }, () => {
            if (rowData.setDetail) {
                this.setState({
                    confirmOpen: true
                });
            } else {
                this.props.rowUpdate(rowData);
                this.props.api.stopEditing();
                this.props.api.redrawRows();
            }
        });
    }

    render() {
        let dispDropdown = this.state.dropdownValues.map((i, x) => {
            return <option key={x} value={i}>{i}</option>;
        });

        return (
            <React.Fragment>
                <div>
                    <select style={{width : this.state.width}} onChange={this.changeSelectedValue.bind(this)} value={this.state.selectedVal}>
                        {dispDropdown}
                    </select>
                </div>
                <CancelModal
                    isOpen={this.state.confirmOpen}
                    modalClose={this.closeConfirm.bind(this)}
                />
            </React.Fragment>
        );
    }
}

//////////////////////////////////////////////////////////////////////////////////////


export class ProductTypeDropdownRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        console.log('ProductDropdownRenderer render check', this.props);

        return (
            <div>
                {this.props.node.data ?
                    <div className='ag-dropdown-custom'> {this.props.value}<div className='ag-icon-expand-grid'></div></div>
                    :
                    <div className='ag-dropdown-custom'><div className='ag-icon-expand-grid'></div></div>}
            </div>

        );
    }
}


