import React, { Component } from 'react';
import axios from 'axios';

import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import JqxTreeGrid from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxtreegrid';

// import JqxTreeGrid from '../../../common/vendor/jqwidgets-react/react_jqxtreegrid.js';
import NotificationManager from '../../../common/components/notification';
const GridDataBuilder = require('../../../common/gridDataBuilder');

import { ProductServices } from '../../services/product-services';

class ShotAngles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            anglesData: [],
        };
    }

    componentDidMount() {
        const _this = this;

        // added for row data AG-GRid
        const rowData = _this.props.rowData;

        // var productId = this.props.productId[0];
        const productIdPost = []; // added for ag grid

        if (rowData.dataLevel == 'SINGLE') {
            productIdPost.push(rowData.chldProdId);
        } else if (rowData.dataLevel == 'PARENT') {
            productIdPost.push(rowData.parentProductId);
        } else if (rowData.dataLevel == 'INTERMEDIATE') {
            productIdPost.push(rowData.intProdId);
        } else if (rowData.dataLevel == 'CHILD') {
            productIdPost.push(rowData.chldProdId);
        }

        const anglesGrid = $('.anglesGrid');
        $(document).on('click', '.sample-boolean', function (e) {
            const rowId = $(this).attr('data-row');
            const isChecked = $(this).prop('checked');
            // var rowdata = _this.anglesGrid ? _this.anglesGrid.getRowByUid(rowId): _this.state.anglesData[parseInt(rowId)-1];
            const rowdata2 = _this.state.anglesData[parseInt(rowId) - 1];


            const headers = {
                'Authorization': _this.props.user.token,
                'content-type': 'application/json',
            };

            ProductServices.saveAngles({ 'angle': rowdata2.angle, 'assigned': isChecked, 'productIdLst': productIdPost })
                .then((response) => {
                    console.log('productContainer helperComponents add-shot-angles saveAngles API call success data: ', response.data);
                    const res = response.data;

                    if (res.status) {
                        NotificationManager({ message: 'Shot Angle(s) updated successfully', type: 1 });
                    } else {
                        NotificationManager({ message: res.responseMessage, type: 4 });
                    }
                    return;
                })
                .catch((error) => {
                    console.log('productContainer helperComponents add-shot-angles saveAngles API call failed', error);
                    NotificationManager({ message: error, type: 4 });
                });
        });


        // var productId = this.props.productId[0];
        let productId = null; // added for ag grid

        if (rowData.dataLevel == 'SINGLE') {
            productId = rowData.chldProdId;
        } else if (rowData.dataLevel == 'PARENT') {
            productId = rowData.parentProductId;
        } else if (rowData.dataLevel == 'INTERMEDIATE') {
            productId = rowData.intProdId;
        } else if (rowData.dataLevel == 'CHILD') {
            productId = rowData.chldProdId;
        }

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        ProductServices.getAngles("?productId=" + productId)
            .then((response) => {
                console.log('productContainer helperComponents add-shot-angles getAngles API call success data: ', response.data);
                const res = response.data;

                if (res.status) {
                    const data = {};
                    data.columnDetails = [
                        {
                            'columnTitle': 'id',
                            'fieldName': 'id',
                            'type': 'int',
                            'visibility': true,
                            'pk': true,
                            'order': 0,
                            'map': null,
                            'id': null,
                            'popup': false,
                        },
                        {
                            'columnTitle': 'Angle',
                            'fieldName': 'angle',
                            'type': 'string',
                            'visibility': true,
                            'pk': false,
                            'order': 0,
                            'map': null,
                            'id': null,
                            'popup': false,
                        },
                        {
                            'columnTitle': 'Assigned',
                            'fieldName': 'assigned',
                            'type': 'boolean',
                            'visibility': true,
                            'pk': false,
                            'order': 1,
                            'map': null,
                            'id': null,
                            'popup': false,
                        },
                    ];
                    for (let i = 0; i < res.data.length; i++) {
                        res.data[i].id = i + 1;
                    }

                    data.data = res.data;

                    _this.gridData = GridDataBuilder(data, 'ShotAngles');
                    _this.setState({ isLoaded: true, anglesData: res.data });
                } else {
                    _this.props.modalClose();
                    NotificationManager({ message: res.responseMessage, type: 4 });
                }
            })
            .catch((error) => {
                console.log('productContainer helperComponents add-shot-angles getAngles API call failed', error);
                NotificationManager({ message: error, type: 4 });
            });
    }

    componentWillUnmount() {
        $(document).prop('click', null).off('click');
    }

    render() {
        const modalClass = 'SelectCategoryModal';
        const gridHeight = 300;
        const editSettings = {
            saveOnPageChange: true,
            saveOnBlur: true,
            saveOnSelectionChange: true,
            cancelOnEsc: true,
            saveOnEnter: true,
            editSingleCell: true,
            editOnDoubleClick: true,
            editOnF2: true,
        };
        return (
            <div>
                <div className="modal-header">
                    Shot Angles
                    <span className="close" onClick={this.props.modalClose}>x</span>
                </div>
                <div className="modal-content">
                    <div className="add-copy modal-form">
                        <div className="form- scroll">
                            <div className="anglesGrid">
                                {this.state.isLoaded ?
                                    <JqxTreeGrid ref={(grid) => {
                                        this.anglesGrid = grid;
                                    }}
                                    width={'100 %'} height={gridHeight} source={this.gridData.dataAdapter}
                                    columns={this.gridData.columns} checkboxes={false} editable autoRowHeight={false} pageable={false}
                                    sortable autowidth theme={'light'} editSettings={editSettings} />
                                    : 'Loading...'
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ShotAngles;
