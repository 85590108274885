import React from 'react';

class CategoryTreeView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            list: this.props.content
        };

        this.currentActiveCatIds = [];
        this.toggleView = this.toggleView.bind(this);
        this.refereshView = this.refereshView.bind(this);
        this.findCategoryObj = this.findCategoryObj.bind(this);
        this.setTabActive = this.setTabActive.bind(this);
    }

    componentWillReceiveProps(nextProps) {
    }

    setTabActive(catId, level) {
        if (level === 5 && this.props.menuId !== "workflow_allocation") {
            return;
        }

        var data = this.props.content;

        this.findCategoryObj(data, catId, false, true);

        this.setState({ list: data });
    }

    findCategoryObj(data, catId, toggle, isActive, parent) {
        for (var i = 0; i < data.length; i++) {
            var o = data[i];
            o.isOpen == undefined ? false : o.isOpen;

            if (isActive) {
                o.isTabActive = false;
            }

            if (parent != undefined && parent == false) {
                o.isOpen = false;
            }

            if (o.catId === catId) {
                if (toggle) {
                    o.isOpen = !o.isOpen;
                }

                if (isActive) {
                    o.isTabActive = true;

                    this.currentActiveCatId = o.catId;
                }
            }

            if (o.productCategory.length) {
                this.findCategoryObj(o.productCategory, catId, toggle, isActive, o.isOpen);
            }
        }
    }

    toggleView(catId) {
        var data = this.props.content;

        this.findCategoryObj(data, catId, true, false);

        this.setState({ list: data });
    }

    refereshView(nextProps) {
        var data = nextProps.content;

        this.findCategoryObj(data, this.currentActiveCatId, false, true);

        this.setState({ list: data });

        if (nextProps.menuId == "product_category") {
            setTimeout(function () {
                var currentSelectedLevel = $('.product_category .tree-node-list.active').closest('.tree-node').attr('data-level');
                var length = parseInt(currentSelectedLevel);

                for (var i = length; i > 0; i--) {
                    $('.product_category .tree-node-list.active').closest('.tree-node.level' + i).find('span:eq(0)').trigger('click');
                }
            }, 500);
        }
    }

    categoryTreeView(list, level) {
        let items = list.map((category, index) => {
            return (
                <div key={index} className={`tree-node level` + level} data-level={level} data-catname={category.catName}>
                    <div className={`tree-node-list ${(category.isTabActive) ? 'active' : ''}`}>
                        {category.productCategory.length > 0 ?
                            <span className={`${(category.isOpen) ? 'icon-minus-blue' : 'icon-plus'}`} onClick={() => this.toggleView(category.catId)}></span>
                            : <span className={`no-expand`}>&nbsp;</span>}
                        <span
                            id={"id-" + category.catId}
                            className="category "
                            data-node="0"
                            data-catid={category.catId}
                            onClick={() => {
                                this.setTabActive(category.catId, level);
                                this.props.secodLevelView(this.props.menuId, null, category.catId, level);
                            }}>{category.catName}</span>
                    </div>
                    {category.productCategory.length > 0 &&
                    <div className={`tree-node-item ${(category.isOpen) ? 'is-open' : ''}`}>
                        {this.categoryTreeView(category.productCategory, level + 1)}
                    </div>
                    }
                </div>
            );
        });

        return (<div>{items}</div>);
    }

    render() {
        let level = 1;

        return (
            <div className={"nav-categories scroll " + this.props.menuId}>
                {this.categoryTreeView(this.state.list, level)}
            </div>
        );
    }
}

export default CategoryTreeView;
