import React, { Component } from 'react';
import { connect } from 'react-redux';
import TotalRowCount from './totalRowCount';
import ConfirmModal from '../../common/modals/confirmModal';
import axios from 'axios';
import NotificationManager from '../../common/components/notification';
import MarketingServices from '../services/marketing-services';

class MarketingFooter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            undoButtondisble: true,
            marketingPublish: true,
            isConfirmOpen: false,
            ConfirmMessage: '',
            eventStatus: '0'
        };

        this.deleteEventPopup = this.deleteEventPopup.bind(this);
        this.closeConfirmModal = this.closeConfirmModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    deleteEventPopup() {
        if (this.props.eventData.eventStaus === 'On Schedule' || this.props.eventData.eventStaus === 'Delayed' || this.props.eventData.eventStaus === 'Ready') {
            this.setState({ ConfirmMessage: 'Workflow for this event is in process, are you sure you want to delete?' });
            this.setState({ isConfirmOpen: true });
        } else if (this.props.eventData.eventStaus === 'Active') {
            NotificationManager({ message: "This event is active and cannot be deleted. To delete the event, please change the event's status to 'Completed' and then click 'Delete Event'", type: 4 });

            return false;
        } else {
            this.setState({ ConfirmMessage: 'Are you sure you want to delete this event?' });
            this.setState({ isConfirmOpen: true });
        }
    }

    closeConfirmModal(action) {
        switch (action) {
            case 'save':
                this.setState({ isConfirmOpen: false });
                this.props.deleteEvent();
                break;

            case 'no':
                this.setState({ isConfirmOpen: false });
                break;

            case 'cancel':
                this.setState({ isConfirmOpen: false });
                break;

            default:
                this.setState({ isConfirmOpen: false });
                break;
        }
    }

    handleChange(event) {
        if ((this.props.eventData.eventStaus === 'Delayed' || this.props.eventData.eventStaus === 'Ready' || this.props.eventData.eventStaus === 'Active' || this.props.eventData.eventStaus === 'Completed' || this.props.eventData.eventStaus === 'On-Hold')) {
            const target = event.target;

            let value = target.type === 'checkbox' ? target.checked : target.value;

            const name = target.name;

            this.setState({ 'eventStatus': value });

            let req = { "id": this.props.eventData.eventId, "value": value };
            let _this = this;

            MarketingServices.updateEventStatus(req).then((response) => {
                let res = response;

                if (res.status) {
                    NotificationManager({ message: res.responseMessage, type: 1 });
                } else {
                    NotificationManager({ message: res.responseMessage, type: 4 });
                }
            }).catch((error) => {
                console.log('marketingContainer handleChange error: api call: ', error);
            });
        } else {
            NotificationManager({ message: "You can't update the status as the event is not in 'Ready' state", type: 4 });

            return false;
        }
    }

    componentWillReceiveProps(props) {
        if (props.eventData.eventStaus !== undefined && props.eventData.eventStaus !== 'Ready' && props.eventData.eventStaus !== 'Delayed' && props.eventData.eventStaus !== 'Preliminary' && props.eventData.eventStaus !== 'On Schedule') {
            this.setState({ eventStatus: props.eventData.eventStaus });
        } else {
            this.setState({ eventStatus: '0' });
        }
    }

    render() {
        console.log('Marketing footer checking for data', this.state, this.props);

        var taskOwneer = this.props.eventData.tasks ? this.props.eventData.tasks[0].owner : '';

        return (
            <div>
                <div className={`product-footer footer marketingFooter`}>
                    {!this.props.isOpen && !this.props.isEditOpen &&
                    <div>
                        {this.props.userData.defaultRole === "EventOwner" &&
                        <div className="record-creation-holder">
                            <div
                                className={`record-action-marketing gray-button`}
                                onClick={this.props.addEvent}
                            >
                                Add New Event
                            </div>
                        </div>
                        }
                        <div className="record-delete-holder inline-block">
                            <TotalRowCount />
                        </div>
                        <div className="footer-content right">
                            <div className="dropup left ">
                                <button
                                    onClick={this.props.openPublishPanel}
                                    className="marketing_publish footer-btn gray-button"
                                    disabled={(this.props.totalRowCount === 0) ? true : false}
                                >
                                    Marketing Publish
                                </button>
                            </div>
                        </div>
                    </div>
                    }
                    {this.props.isOpen && this.props.userData.defaultRole === "EventOwner" &&
                    <div className="add-event-footer clearfix">
                        <div className="footer-content right">
                            <button type="button" className="gray-button" onClick={this.props.cancelEvent}>Cancel</button>
                            <button type="button" className="gray-button"
                                onClick={this.props.saveEvent}>Save</button>
                            <button type="button" className="gray-button"
                                onClick={this.props.submitEvent}>Submit for Approval</button>
                        </div>
                    </div>
                    }
                    {this.props.isEditOpen && this.props.userData.defaultRole == "ADMIN" &&
                    <div>
                        <div className="record-creation-holder">
                            <button type="button" className="gray-button" onClick={this.deleteEventPopup}>Delete Event</button>
                        </div>
                        <div className="footer-content right">
                            <button type="button" className="gray-button" onClick={this.props.cancelEditEvent}>Cancel</button>
                            <button type="button" className="gray-button"
                                onClick={this.props.approveEvent} disabled={this.props.eventData.adminApprovedStatus === "none" ? false : true}>Approve</button>
                            <button type="button" className="gray-button"
                                onClick={this.props.rejectEvent} disabled={this.props.eventData.adminApprovedStatus === "none" ? false : true}>Reject</button>
                        </div>
                    </div>
                    }
                    {this.props.isEditOpen && this.props.userData.defaultRole == "EventOwner" && this.props.userData.userName === taskOwneer &&
                    <div>
                        <div className="record-creation-holder">
                            <button type="button" className="gray-button" onClick={this.deleteEventPopup}>Delete Event</button>
                        </div>
                        <div className="record-delete-holder inline-block">
                            <select name="eventStatus" value={this.state.eventStatus} className="form-control" onChange={this.handleChange}>
                                <option value="0" disabled="true">--Event Status--</option>
                                <option value="Active">Active</option>
                                <option value="Completed">Completed</option>
                                <option value="On-Hold">On-Hold</option>
                            </select>
                        </div>

                        <div className="footer-content right">
                            <button type="button" className="gray-button" onClick={this.props.cancelEditEvent}>Cancel</button>
                            <button type="button" className="gray-button"
                                onClick={this.props.saveEditEvent} disabled={this.props.editSaveEnable ? false : true}>Save</button>
                            <button type="button" className="gray-button"
                                onClick={this.props.submitEditEvent} disabled={(this.props.eventData.submitStatus === "true") ? true : false}>Submit for Approval</button>
                        </div>
                    </div>
                    }
                </div>

                <CancelChanges ConfirmMessage={this.state.ConfirmMessage} isOpen={this.state.isConfirmOpen} closeConfirmModal={this.closeConfirmModal} />
            </div>
        );
    }
}

class CancelChanges extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ConfirmModal isOpen={this.props.isOpen}>
                <div className="modal-content">
                    <h3 className="confirm-warning-text">{this.props.ConfirmMessage}</h3>
                    <div className="modal-footer">
                        <input type="button" className="gray-button" value="Yes" onClick={() => this.props.closeConfirmModal('save')}></input>
                        <input type="button" className="gray-button" value="No" onClick={() => this.props.closeConfirmModal('no')}></input>
                    </div>
                </div>
            </ConfirmModal>
        );
    }
}

export default MarketingFooter;
