import React from 'react';
import ConfirmModal from '../../modals/confirm-modal';

class CancelChanges extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <ConfirmModal isOpen={this.props.isOpen}>
                <div className="modal-content">
                    <h3 className="confirm-warning-text">Do you want to Save Changes?</h3>
                    <div className="modal-footer">
                        <input type="button" className="gray-button record-delete-holder" value="Yes" onClick={() => this.props.closeConfirmModal('save')}></input>
                        <input type="button" className="gray-button record-delete-holder" value="No" onClick={() => this.props.closeConfirmModal('no')}></input>
                        <input type="button" className="gray-button" value="Cancel" onClick={() => this.props.closeConfirmModal('cancel')}></input>
                    </div>
                </div>
            </ConfirmModal>
        );
    }
}

export default CancelChanges;
