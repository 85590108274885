import React, { Component } from 'react';
// import JqxTreeGrid from '../../common/vendor/jqwidgets-react/react_jqxtreegrid.js';
import Modal from '../../modals/common-modal.js';
import ConfirmSubmit from './confirmsubmit.js';
import ReceiveSampleModal from './receiveSampleModal';
import SwatchReferenceModal from './swatchReferenceModal';
import MoveSampleModal from './moveSampleModal';
import PdfViewerModal from './pdfViewerModal';
import axios from 'axios';

var constants = require('../../common/constants.js');
var utils = require('../../common/utils.js');
// var NotificationManager = require('../../common/notification');
import NotificationManager from '../../common/components/notification';
import { ProductServices } from '../../product2/services/product-services';

var GridDataBuilder = require('../../common/gridDataBuilder');



class SampleModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isOpenReceiveModal: false,
            isOpenMoveModal: false,
            isModalOpen: false,
            isOpenSwatchModal: false,
            pdfModalOpen: false,
            pdfFile: null
        };
        this.swatchReferenceList = null;
        this.currentGridData = null;
        this.modalDialog = "SampleConfirm";

        this.openReceiveModalBox = this.openReceiveModalBox.bind(this);
        this.closeReceiveModalBox = this.closeReceiveModalBox.bind(this);
        this.openMoveModalBox = this.openMoveModalBox.bind(this);
        this.closeMoveModalBox = this.closeMoveModalBox.bind(this);
        this.generateSampleCard = this.generateSampleCard.bind(this);
        this.rePrintSampleCard = this.rePrintSampleCard.bind(this);
        this.moveSample = this.moveSample.bind(this);
        this.sampleProductUpdate = this.sampleProductUpdate.bind(this);
        this.openConfirmModal = this.openConfirmModal.bind(this);
        this.closeConfirmModal = this.closeConfirmModal.bind(this);
        this.showSampleSwatchModal = this.showSampleSwatchModal.bind(this);
        this.unCheckAll = this.unCheckAll.bind(this);
        this.closeSwatchModalBox = this.closeSwatchModalBox.bind(this);
        this.openSwatchModalBox = this.openSwatchModalBox.bind(this);
        this.yesSwatchOnly = this.yesSwatchOnly.bind(this);
        this.noSwatchOnly = this.noSwatchOnly.bind(this);
        this.moveSampleRequest = this.moveSampleRequest.bind(this);
        this.enableSampleReceivedFlag = this.enableSampleReceivedFlag.bind(this);
        this.closePdfModal = this.closePdfModal.bind(this);
    }

    closeConfirmModal() {
        this.setState({ isModalOpen: false });
    }

    openConfirmModal() {
        this.setState({ isModalOpen: true });
    }

    openReceiveModalBox() {
        this.setState({ isOpenReceiveModal: true });
    }

    closeReceiveModalBox() {
        this.setState({ isOpenReceiveModal: false });
    }

    openMoveModalBox() {
        this.setState({ isOpenMoveModal: true });
    }

    closeMoveModalBox() {
        this.setState({ isOpenMoveModal: false });
    }

    openSwatchModalBox() {
        this.setState({ isOpenSwatchModal: true });
    }

    closeSwatchModalBox() {
        this.setState({ isOpenSwatchModal: false });
        this.closeConfirmModal();
    }

    closePdfModal() {
        this.setState({ pdfModalOpen: false });
    }

    unCheckAll() {
        var _this = this,
            gridData = _this.sampleGrid.getRows();
        var rowids = gridData.map((v, k) => {
            v.sampleSwatch = false;
            _this.sampleGrid.updateRow(v.sampleId, v);
        });
        _this.closeSwatchModalBox();
    }

    yesSwatchOnly() {
        this.sampleProductUpdate(true);
    }

    noSwatchOnly() {
        this.unCheckAll();
        this.sampleProductUpdate(false);
    }

    sampleProductUpdate(checked) {
        let _this = this;
        _this.closeSwatchModalBox();
        let totalSampleCheckbox = $('.sample-boolean').length,
            checkedCheckbox = $('.sample-boolean:checked').length,
            swatchRef = $('.swatch-reference') ? $('.swatch-reference').val() : "";
        var allRows = _this.sampleGrid.getRows();
        var sampleIdsList = [];
        allRows.map(function (p, i) {
            for (var i = 0; i < p.sampleIdLst.length; i++) {
                sampleIdsList.push(p.sampleIdLst[i]);
            }
        });


        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.updateProductSample({ checkedAll: checked, swatchRef: swatchRef, sampleIdLst: sampleIdsList })
            .then((response) => {
                console.log('productContainer helperComponents sample-modal sampleProductUpdate API call success data: ', response.data);
                let res = response.data;

                if (res.responseStatus) {
                    if (checked) {
                        window.currentValSampleSwatchOption = 'Swatch Only';
                        /** To refresh the grid view , looping and update row one by one */
                        var firstLevelRows = _this.sampleGrid.getRows();
                        for (var i = 0; i < firstLevelRows.length; i++) {
                            // get a row.
                            var rowData = firstLevelRows[i];
                            _this.sampleGrid.updateRow(rowData.sampleId, rowData);
                        }
                        _this.props.setValueSwatchonly(swatchRef);
                    }
                    NotificationManager({ message: res.responseMessage, type: 1 });
                } else {
                    NotificationManager({ message: res.responseMessage, type: 4 });
                }
                _this.closeConfirmModal();
                _this.closeSwatchModalBox();
            })
            .catch(error => {
                console.log('productContainer helperComponents sample-modal sampleProductUpdate API call failed', error);
                NotificationManager({ message: error, type: 4 });
            });
    }

    showSampleSwatchModal() {
        var _this = this;

        var styleId = this.props.childProdList[0].styleNumber;

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };
        ProductServices.getSampleIds({ id: this.props.productData, styleId: styleId })
            .then((response) => {
                console.log('productContainer helperComponents sample-modal showSampleSwatchModal API call success data: ', response.data);
                let res = response.data;

                if (res.responseStatus) {
                    _this.swatchReferenceList = res.data;
                } else {
                    _this.swatchReferenceList = [];
                }
                _this.openSwatchModalBox();
            })
            .catch(error => {
                console.log('productContainer helperComponents sample-modal showSampleSwatchModal API call failed', error);
            });
        _this.setState({ isModalOpen: false });
    }

    componentDidMount() {
        console.log("sample-modal componentDidMount props", this.props);

        let base = this;

        //Setting window object reference for grid check disable/enable;
        window.currentValSampleSwatchOption = base.props.productData.swatchOnly;

        var sampleGrid = $('.sampleGrid');
        sampleGrid.off();
        sampleGrid.on('click', '.sample-boolean', function (e) {
            var rowId = $(this).attr('data-row'),
                fieldName = $(this).attr('data-fieldname'),
                isChecked = $(this).prop('checked'),
                totalSampleCheckbox = $('.sample-boolean').length,
                checkedCheckbox = $('.sample-boolean:checked').length;
            let rowData = base.sampleGrid.getRowByUid(rowId);
            rowData.sampleSwatch = isChecked;
            base.sampleGrid.updateRow(rowId, rowData);
            base.currentGridData = base.sampleGrid.getRows();


            let headers = {
                'Authorization': base.props.user.token,
                'content-type': 'application/json'
            };

            ProductServices.updateSample({ id: rowData.sampleIdLst, value: isChecked })
                .then((response) => {
                    console.log('productContainer helperComponents sample-modal componentDidMount API call success data: ', response.data);
                    let res = response.data;

                    if (res.responseStatus) {
                        NotificationManager({ message: res.responseMessage, type: 1 });
                        if (totalSampleCheckbox === checkedCheckbox) {
                            base.openConfirmModal();
                        }
                    } else {
                        NotificationManager({ message: res.responseMessage, type: 4 });
                    }
                })
                .catch(error => {
                    console.log('productContainer helperComponents sample-modal componentDidMount API call failed', error);
                    NotificationManager({ message: error, type: 4 });
                });
        });

        var pids = base.props.productData;


        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };
        ProductServices.getSamplesData(pids)
            .then((response) => {
                console.log('productContainer helperComponents sample-modal componentDidMount 2 API call success data: ', response.data);
                let res = response.data;

                res.columnDetails.map((v, k) => {
                    if (v.fieldName === "sampleIdLst") {
                        v.visibility = false;
                    } else {
                        v.visibility = true;
                    }
                });
                base.currentGridData = res.data;
                // base.gridData=GridDataBuilder(res,"Sample");
                let _grid_data = GridDataBuilder(res, "Sample");
                _grid_data.columns = _grid_data.columns.map(function (col, i) {
                    col.width = (99.9 / _grid_data.columns.length).toFixed(4) + "%";
                    col.minwidth = 120;
                    return col;
                });
                base.gridData = _grid_data;
                base.setState({ isLoaded: true });
            })
            .catch(error => {
                console.log('productContainer helperComponents sample-modal componentDidMount 2 API call failed', error);
            });
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevState.isLoaded !== this.state.isLoaded && this.state.isLoaded === true) {
            var _this = this;
            _this.sampleGrid.on('cellEndEdit', function (event) {
                var args = event.args,
                    datafield = args.datafield,
                    rowdata = args.row;


                let headers = {
                    'Authorization': _this.props.user.token,
                    'content-type': 'application/json'
                };

                ProductServices.saveSamples([rowdata])
                    .then((response) => {
                        console.log('productContainer helperComponents sample-modal componentDidUpdate API call success data: ', response.data);
                        let res = response.data;

                        if (res.responsestatus) {
                            NotificationManager({ message: res.responseMessage, type: 1 });
                        } else {
                            NotificationManager({ message: res.responseMessage, type: 4 });
                        }
                    })
                    .catch(error => {
                        console.log('productContainer helperComponents sample-modal componentDidUpdate API call failed', error);
                        NotificationManager({ message: error, type: 4 });
                    });
            });
        }
    }



    enableSampleReceivedFlag() {
        var _this = this;
        var isSampleRecevied = _this.props.currentRowData.isSamplerecieved;
        var allowFlag = false;

        if (utils.convertDataType(isSampleRecevied) != true) {
            allowFlag = true;
        }
        if (allowFlag) {
            let headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json'
            };
            axios({
                method: 'post',
                url: require('../../common/configurations.js').product.isSampleRecevied,
                headers: headers,
                data: _this.props.productData
            })
                .then((response) => {
                    console.log('productContainer helperComponents footer API call success data: ', response.data);
                    let res = response.data;

                    _this.props.setCellValueAndCopyToChild("isSamplerecieved", true);
                })
                .catch(error => {
                    console.log('productContainer helperComponents footer API call failed', error);
                });
        }
    }

    generateSampleCard() {
        let _this = this;
        let qtyReceived = parseInt($('.qtyReceived').val(), 10);
        if (isNaN(qtyReceived)) {
            this.generateSampleCardCounter = 0;
            NotificationManager({ message: "Please enter quantity", type: 4 });
            return;
        }
        var postdata = this.props.productData.map((pid, i) => {
            let inputData = {
                "roleName": this.props.user.defaultRole,
                "productID": pid,
                "count": qtyReceived
            };
            return inputData;
        });

        console.log('=================CHECK===================');
        console.log(postdata);
        console.log('====================================');

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };
        ProductServices.receiveSamples(postdata)
            .then((response) => {
                console.log('productContainer helperComponents footer API call success data: ', response.data);
                let res = response.data;

                if (res.responsestatus) {
                    NotificationManager({ message: res.responseMessage, type: 1 });
                    var data = res.data;
                    if (data.length > 0) {
                        _this.enableSampleReceivedFlag();
                        var rowids = data.map((v, k) => {
                            return "" + v.sampleId;
                        });
                        _this.sampleGrid.addRow(rowids, data);
                        _this.closeReceiveModalBox();
                    }
                    // Open pdf file url in new tab
                    if (res.file) {
                        //utils.openInNewTab(res.file);
                        var pdfFileUrl = res.file;
                        _this.setState({ pdfModalOpen: true, pdfFile: pdfFileUrl });
                    }
                } else {
                    NotificationManager({ message: res.responseMessage, type: 4 });
                }
            })
            .catch(error => {
                console.log('productContainer helperComponents footer API call failed', error);
            });
    }

    rePrintSampleCard() {
        var _this = this,
            selectedrowindexs = _this.sampleGrid.getCheckedRows(),
            selectedrowids = [],
            selectedrowdata = null;
        if (selectedrowindexs.length > 0) {
            selectedrowdata = selectedrowindexs.map((val, key) => {
                return {
                    "id": val.sampleId, //Sample ID
                    "value": val.productID // Product ID
                };
            });


            let headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json'
            };

            ProductServices.generateSampleCard(selectedrowdata)
                .then((response) => {
                    console.log('productContainer helperComponents sample-modal rePrintSampleCard API call success data: ', response.data);
                    let res = response.data;

                    if (res.responsestatus) {
                        NotificationManager({ message: res.responseMessage, type: 1 });
                        if (res.data) {
                            //utils.openInNewTab(res.data);
                            var pdfFileUrl = res.data;
                            _this.setState({ pdfModalOpen: true, pdfFile: pdfFileUrl });
                        }
                    } else {
                        NotificationManager({ message: res.responseMessage, type: 4 });
                    }
                })
                .catch(error => {
                    console.log('productContainer helperComponents sample-modal rePrintSampleCard API call failed', error);
                    NotificationManager({ message: "Please select atleast one samples to print", type: 4 });
                });
        }
        else {
            NotificationManager({ message: "Please select atleast one samples to print", type: 4 });
        }
    }

    moveSampleRequest() {
        let _this = this,
            selectedrowindexs = _this.sampleGrid.getCheckedRows(),
            selectedrowids = [], selectedrowdata;
        if (selectedrowindexs.length > 0) {
            selectedrowdata = selectedrowindexs.map((val, key) => {
                return {
                    "requestUser": this.props.user.userName,
                    "currentUser": val.sampleCurrentLocation,
                    "sampleId": val.sampleId
                };
            });

            let headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json'
            };
            ProductServices.moveSampleRequest(selectedrowdata)
                .then((response) => {
                    console.log('productContainer helperComponents sample-modal rePrintSampleCard API call success data: ', response.data);
                    let res = response.data;
                    NotificationManager({ message: res.responseMessage, type: 1 });
                })
                .catch(error => {
                    console.log('productContainer helperComponents sample-modal rePrintSampleCard API call failed', error);
                    NotificationManager({ message: "Please select atleast one sample to proceed", type: 4 });
                });
        } else {
            NotificationManager({ message: "Please select atleast one sample to proceed", type: 4 });
        }
    }

    moveSample() {
        let _this = this,
            selectedrowindexs = _this.sampleGrid.getCheckedRows(),
            selectedrowids = [],
            currentLocation = $('.sample-location').val();
        var selectedrowdata = null;
        if (selectedrowindexs.length > 0) {
            selectedrowdata = selectedrowindexs.map((val, key) => {
                return {
                    "sampleId": val.sampleId,
                    "sampleCurrentLocation": currentLocation,
                    "samplePriorLocation": val.sampleCurrentLocation,
                    "productID": val.productID,
                    "sampleIdLst": val.sampleIdLst,
                    "swatch": "false",
                    "sampleComments": val.sampleComments
                };
            });


            let headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json'
            };
            ProductServices.moveSample(selectedrowdata)
                .then((response) => {
                    console.log('productContainer helperComponents footer API call success data: ', response.data);
                    let res = response.data;

                    if (res.responsestatus) {
                        NotificationManager({ message: res.responseMessage, type: 1 });
                        var rowids = res.data.map((v, k) => {
                            _this.sampleGrid.updateRow(v.sampleId, v);
                        });
                        _this.closeMoveModalBox();
                    } else {
                        NotificationManager({ message: res.responseMessage, type: 4 });
                    }
                })
                .catch(error => {
                    console.log('productContainer helperComponents footer API call failed', error);
                });
        } else {
            NotificationManager({ message: "Please select atleast one sample to move", type: 4 });
        }
    }

    render() {
        let props = this.props;
        let footerHeight = 50,
            headerHeight = 64;
        let gridHeight = 400 - footerHeight - headerHeight;

        let modalClass = "ConfirmSubmitModal";
        let editSettings = {
            saveOnPageChange: true,
            saveOnBlur: true,
            saveOnSelectionChange: true,
            cancelOnEsc: true,
            saveOnEnter: true,
            editSingleCell: true,
            editOnDoubleClick: true,
            editOnF2: true
        };
        var disableEditing = false;
        if (window.readableFields && window.readableFields.indexOf('swatchOnly') > -1) {
            disableEditing = true;
        }

        return (
            <div>
                <div className="Sample-Modal-Container">
                    <div className="modal-header">
                        Sample Management
                        <span className="close" onClick={this.props.modalClose}>x</span>
                    </div>
                    <div className="modal-content">
                        <div className="modal-form">
                            <div className="sampleGrid">
                                {/* {this.state.isLoaded &&
                      <JqxTreeGrid ref={(grid) => {this.sampleGrid = grid}}
                        width={'99.5%'} height={gridHeight} source={this.gridData.dataAdapter}
                        columns={this.gridData.columns} checkboxes={true} editable={true} autoRowHeight={false} pageable={false}
                        sortable={true} autowidth={true}  theme={'light'} editSettings={editSettings}
                    />
                    } */}
                            </div>
                        </div>
                        <div className="row">&nbsp;</div>
                        <div className="modal-footer text-center">
                            {!this.props.isCreative && !disableEditing &&
                                <button className="gray-button" onClick={this.openReceiveModalBox}>Receive Samples</button>}
                            {!disableEditing &&
                                <button className="gray-button" onClick={this.rePrintSampleCard}>Re-Print Sample card</button>
                            }
                            {!disableEditing &&
                                <button className="gray-button" onClick={this.openMoveModalBox}>Move Sample</button>
                            }
                            {!disableEditing &&
                                <button className="gray-button" onClick={this.moveSampleRequest}>Move Sample Request</button>
                            }
                        </div>
                        {this.state.isOpenReceiveModal &&
                            <ReceiveSampleModal isOpen={this.state.isOpenReceiveModal} close={this.closeReceiveModalBox} generateSampleCard={this.generateSampleCard} />
                        }
                        {this.state.isOpenMoveModal &&
                            <MoveSampleModal isOpen={this.state.isOpenMoveModal} close={this.closeMoveModalBox} moveSample={this.moveSample} />
                        }
                        {this.state.isOpenSwatchModal &&
                            <SwatchReferenceModal isOpen={this.state.isOpenSwatchModal}
                                swatchReferenceList={this.swatchReferenceList} close={this.noSwatchOnly} ok={this.yesSwatchOnly} />
                        }
                    </div>
                </div>
                {this.state.isModalOpen &&
                    <Modal isOpen={this.state.isModalOpen} addClass={modalClass}>
                        <ConfirmSubmit dialog={this.modalDialog} modalClose={this.noSwatchOnly} yes={this.showSampleSwatchModal}>

                        </ConfirmSubmit>
                    </Modal>
                }
                {this.state.pdfModalOpen &&
                    <Modal isOpen={this.state.pdfModalOpen}>
                        <PdfViewerModal pdfFile={this.state.pdfFile} modalClose={this.closePdfModal} />
                    </Modal>
                }
            </div>
        );
    }
}


export default SampleModel;
