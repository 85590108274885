import React from 'react';

export class DropdownEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedVal: '',
            list: null,
        };
    }

    componentWillMount() {
        if (this.props.node.data.dataLevel == 'SINGLE') {
            this.props.api.stopEditing();

            return;
        }

        const cellWidth = this.props.eGridCell.style.width;
        const dropdownVals = this.props.node.data.color_attribute.split(',');

        this.setState({
            selectedVal: this.props.value,
            width: cellWidth,
            list: dropdownVals
        });
    }

    componentWillUnmount() {
        this.props.api.stopEditing();
    }

    isPopup() {
        return true;
    }

    getValue() {
        return this.state.selectedVal;
    }

    handleChange(event) {
        console.log('event target: ', event.target.value, event.target.innerHTML);

        if (this.props.column.colId === 'season') {
            this.setState({
                selectedVal: event.target.value,
            }, () => this.props.api.stopEditing());
        } else {
            this.setState({
                selectedVal: event.target.innerHTML,
            }, () => this.props.api.stopEditing());
        }
    }

    stopEditing() {
        this.props.api.stopEditing();
    }

    render() {
        console.log('dropdown: ', this.state, this.props);

        let dropdownListValues;

        if (this.state.list) {
            if (this.props.column.colId === 'season') {
                dropdownListValues = this.state.list.map((obj, index) => {
                    return <li className="agCustomDropdownItems" onClick={this.handleChange.bind(this)} key={index} value={obj.id}>{obj.name}</li>;
                });
            } else {
                dropdownListValues = this.state.list.map((val, index) => {
                    return <li className="agCustomDropdownItems" onClick={this.handleChange.bind(this)} key={index} value={val}>{val}</li>;
                });
            }
        }

        return (
            <div
                className="ag-DropdownSearchContainer"
                style={{ width: this.state.width }}
            >
                <ul style={{ width: this.state.width }} className="agCustomDropdown">
                    {dropdownListValues}
                </ul>
            </div>
        );
    }
}

/////////////////////////////////// Dropdown Renderer for Arrow ///////////////////////////////////

export class DropdownRender extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            showArrow: false,
        };
    }

    componentWillMount() {
        console.log('componentWillMount Dropdown Publication', this.props);

        if (this.props.node.data.dataLevel == 'SINGLE') {
            this.setState({ showArrow: false });
        } else if (this.props.value){
            this.setState({ showArrow: false });
        } else {
            this.setState({ showArrow: true });
        }
    }

    render() {
        return (
            <div>
                {this.state.showArrow ?
                    <div className="arrow downPos"></div> :
                    <div>{this.props.value}</div>
                }
            </div>
        );
    }
}
