import React, { Component } from 'react';
import Modal from '../../../modals/common-modal.js';
import axios from 'axios';

// var NotificationManager = require('../../common/notification');
import NotificationManager from '../../../common/components/notification';

import { ProductServices } from '../../services/product-services';

class CampaignModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: this.props.isOpen,
            productData: this.props.campaignProduct,
            arrCampaigns: [],
            search: '',
            deleteCampaignOpen: false,
            deletingCampaign: '',
            viewMode: false,
            relatedProducts: [],
            isDirty: false,
        };
        this.saveData = {};
        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.preventClick = this.preventClick.bind(this);
        this.unassignCampaign = this.unassignCampaign.bind(this);
        this.assignCampaign = this.assignCampaign.bind(this);
        this.openConfirmPopup = this.openConfirmPopup.bind(this);
        this.closeConfirmPopup = this.closeConfirmPopup.bind(this);
        this.deleteConfirmed = this.deleteConfirmed.bind(this);
        this.saveOrUpdate = this.saveOrUpdate.bind(this);
    }

    /**
     * Opens the campaign popup
     */
    close() {
        this.setState({isOpen: false});
    }

    /**
    * Closes the campaign popup
    */
    open() {
        this.setState({isOpen: true});
    }

    /**
     * Handles in the input values in the search box
     * @param {event} e
     */
    handleSearchChange(e) {
        e.stopPropagation();
        const target = e.target;
        const value = target.value;
        const name = target.name;

        this.setState({[name]: value});
    }

    /**
     * Used to stop propogation of clicks on the li - checkboxes
     * @param {event} e
     */
    preventClick(e) {
        e.stopPropagation();
    }

    /**
     * Used to assign a campaign when a checkbox has been checked
     * @param {*} eventName Name of the checked event
     * @param {*} checked Checked true/false Value
     */
    assignCampaign(eventName, checked) {
        const _this = this;
        const campaigns = _this.state.arrCampaigns;
        campaigns.some(function(campaign) {
            if (eventName == campaign.eventName) {
                if (campaign.newStatus == '' || campaign.newStatus == null) {
                    campaign.newStatus = checked;
                    campaign.status = checked;
                } else {
                    campaign.newStatus = '';
                }
            }
        });
        _this.setState({arrCampaigns: campaigns, isDirty: true});
    }

    /**
     * Used to un-assign a campaign when a checkbox has been un-checked
     * @param {*} eventName Gets the event name when un-checked
     */
    unassignCampaign(eventName) {
        const campaigns = this.state.arrCampaigns;
        campaigns.some(function(campaign) {
            if (eventName == campaign.eventName) {
                if (campaign.newStatus == '' || campaign.newStatus == null) {
                    campaign.newStatus = false;
                    campaign.status = false;
                } else {
                    campaign.newStatus = '';
                    campaign.status = false;
                }
            }
        });
        this.setState({arrCampaigns: campaigns, isDirty: true});
    }

    /**
     * Opens the confirm popup when save is clicked
     * @param {*} eventName // Gets the event name for confirmation
     */
    openConfirmPopup(eventName) {
        if (!$(event.target).hasClass('disabled')) {
            this.setState({ deleteCampaignOpen: true, deletingCampaign: eventName});
        }
    }

    /**
     * Closes the popup
     */
    closeConfirmPopup() {
        this.setState({ deleteCampaignOpen: false, deletingCampaign: ''});
    }

    /**
     * Confirmation step popup to un-assign a campaign
     */
    deleteConfirmed() {
        const eventName = this.state.deletingCampaign;
        this.unassignCampaign(eventName);
        this.closeConfirmPopup();
    }

    /**
     * Saves or updates the current selected campaign list by calling backend
     */
    saveOrUpdate() {
        const _this = this;
        const postdata = {};
        postdata.data = _this.state.arrCampaigns;
        postdata.productIdLst = _this.saveData.productIdLst;

        ProductServices.saveorupdateproductevent(postdata)
            .then( (response) => {
                console.log('productContainer helperComponents campaign modal API call success data: ', response.data);
                const res = response.data;
                let campaignDateDisp = '';

                if (res.status) {
                    NotificationManager({message: res.responseMessage, type: 1});
                    // let prodKey = _this.state.productData.productId;

                    if (res.campaignDate) {
                        res.campaignDate.map(function(oProduct) {
                            const prodId = Object.keys(oProduct)[0];
                            const resultDate = oProduct[prodId];
                            // _this.props.setCampaignCell(prodId, 'campaignDate', resultDate);
                            _this.props.modalClose();
                            campaignDateDisp = resultDate;
                        });
                    }

                    let newRowData = this.props.rowData;
                    let setCampaigns = this.state.arrCampaigns.filter(i => i.status == true).map(i => i.eventName);
                    newRowData['campaign'] = setCampaigns;
                    newRowData['campaignDate'] = campaignDateDisp;
                    this.props.data.node.setData(newRowData);
                    _this.props.modalClose();
                } else {
                    NotificationManager({message: res.responseMessage, type: 4});
                }
            })
            .catch( (error) => {
                console.log('productContainer helperComponents campaign-modal API call failed', error);
            });
    }

    /**
     * Calls when the component needs to mount on click of the cell,
     * set of required data is called when the component mounts for the popup to open
     */
    componentDidMount() {
        const _this = this;

        const rowData = this.props.rowData; // data related to this row
        // let events = _this.props.loadCampaignEvents();  commented for ag-grid

        // let requrl = require('../../../common/configurations.js').product.listproductEvents;

        // let postdata = {};    // commented for ag-grid
        // postdata['id'] = events;    // commented for ag-grid

        const IdArray = [];
        const switchValue = rowData.dataLevel; // empty array to push all the data values, checking the datavel as well.

        if (switchValue == 'SINGLE') {
            IdArray.push(rowData.chldProdId);
        } else if (switchValue == 'PARENT') {
            IdArray.push(rowData.parentProductId);
        } else if (switchValue == 'INTERMEDIATE') {
            IdArray.push(rowData.intProdId);
        } else if (switchValue == 'CHILD') {
            IdArray.push(rowData.chldProdId);
        }

        const postdata = {'id': IdArray}; // converting to a format required by the api call

        // if(events) {         // commented for ag-grid

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        ProductServices.listproductEvents(postdata)
            .then( (response) => {
                console.log('productContainer helperComponents campaign modal API call success data: ', response.data);
                const res = response.data;

                if (res.status) {
                    // NotificationManager({message:res.responseMessage,type:1});
                    _this.setState({ arrCampaigns: res.data });
                    _this.saveData.data = res.data;
                    _this.saveData.productIdLst = res.productIdLst;
                    _this.setState({viewMode: res.merchandiserFlag, relatedProducts: res.productIdLst});
                } else {
                    NotificationManager({message: res.responseMessage, type: 4});
                    _this.props.modalClose();
                }
            })
            .catch( (error) => {
                console.log('productContainer helperComponents campaign-modal API call failed', error);
                NotificationManager({message: 'Could not get the data due to technical reasons.', type: 4});
                _this.props.modalClose();
            });

        // } else {                             // commented for ag-grid
        //     _this.props.modalClose();    // commented for ag-grid
        // }                                    // commented for ag-grid
    }

    render() {
        const _this = this;
        const campaigns = _this.state.arrCampaigns; // Getting values from state for render

        let filteredCampaigns = _this.state.arrCampaigns; // Getting values from state for render if the user has any searched values
        if (_this.state.search.trim().length > 0) {
            const reg = new RegExp(_this.state.search, 'i');
            filteredCampaigns = _this.state.arrCampaigns.filter((item, index) =>{
                if (item.eventName.search(reg) > -1 || item.eventId.toString().search(reg) > -1) {
                    return true;
                }
            });
        }

        const associatedCampaigns = campaigns.filter(function(campaign) {
            return campaign.status;
        }); // Getting associated campaigns list

        const relatedProducts = _this.state.relatedProducts.toString(); //

        return (

            <div>
                {/* {this.state.productData && */}
                <div className={'campaign-modal-container ' + (this.state.viewMode ? '' : 'view-mode')}>
                    <div className="modal-header">
                        Manage Campaigns
                        <span className="close" onClick={this.props.modalClose}>x</span>
                    </div>
                    <div className="modal-content">
                        {/* <p className="prod-info">{"Product Name: " + this.state.productData.prodName}</p> */}
                        {/* <h4 className="prod-info">{"Product Id: "+ this.state.productData.productId}</h4>*/}
                        <p className="prod-info">
                            {'Product Id: ' + relatedProducts}
                        </p>

                        <div className={"associated-events"}>
                            <h3>ASSOCIATED CAMPAIGNS</h3>
                            {
                                associatedCampaigns.length == 0 ?
                                    <div className={"scroll event-list"}><h4 className={"text-center"}>No associated
                                        campaigns</h4></div> :
                                    <div className={"scroll event-list"}>
                                        <ul>
                                            {
                                                campaigns.map(function(obj, index) {
                                                    return (
                                                        <li key={index} className={'li-event ' + (obj.status ? '' : 'hide')}>{obj.eventId + ' ' + obj.eventName}
                                                            <span
                                                                className={'close-icon ' + (_this.state.viewMode ? '' : 'disabled')}
                                                                onClick={() => {
                                                                    _this.openConfirmPopup(obj.eventName);
                                                                }
                                                                } />
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </div>
                            }
                        </div>

                        <div className={"add-events"}>
                            <h3>ADD CAMPAIGN</h3>
                            <div className="list-viewer-holder">
                                <div className="search-field-holder">
                                    <input type="text" value={this.state.search} name="search" placeholder="Search"
                                        onChange={this.handleSearchChange} disabled={!this.state.viewMode}
                                        autoComplete="off"/>
                                </div>
                                <div className="list-scrollable event-list scroll">
                                    <ul>
                                        {
                                            filteredCampaigns.map((item, index) => {
                                                return (
                                                    <li key={index} onClick={this.preventClick}
                                                        className={(item.status ? 'hide' : '')}>
                                                        <label className="blue-checkbox wt">
                                                            <input type="checkbox" checked={item.status}
                                                                name={item.eventName}
                                                                disabled={!this.state.viewMode}
                                                                onChange={(e) => {
                                                                    const checked = e.target.checked;
                                                                    _this.assignCampaign(item.eventName, checked);
                                                                }
                                                                }/><label />
                                                            {item.eventId + ' ' + item.eventName}
                                                        </label>
                                                    </li>);
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer text-center">
                            <button className="gray-button" disabled={!this.state.viewMode || !this.state.isDirty}
                                onClick={this.saveOrUpdate}>Save
                            </button>
                            <button className="gray-button" disabled={!this.state.viewMode}
                                onClick={this.props.modalClose}>Cancel
                            </button>
                        </div>
                    </div>
                </div>
                {/* } */}
                <Modal isOpen={_this.state.deleteCampaignOpen}>
                    <div>
                        <div className="modal-content">
                            <div className="text-center confirm-delete">
                                <h4>Are you sure on removing the associated campaign {'"' + _this.state.deletingCampaign + '"'}?</h4>
                            </div>
                            <div className="modal-footer text-center">
                                <button className="gray-button" onClick={_this.closeConfirmPopup}>No</button>
                                <button className="gray-button" onClick={_this.deleteConfirmed}>Yes</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default CampaignModal;
