export const addDashboardPriorityAlertsData = (state = [], action) => {
    switch (action.type) {
        case "ADD_DB_PRIORITY_ALERTS_DETAILS":
            return action.data;
        default:
            return  state;
    }
};

export const gridData = (state = [], action) => {
    switch (action.type) {
        case "ADD_GRID_DETAILS":
            return action.data;
        case "UPDATE_GRID_DATA":
            return [...state, ...action.updateData];
        default:
            return  state;
    }
};

let gridDataChange = 0, gridColumnChange = 0;
export const gridDataUpdate = (state = {gridDataChange, gridColumnChange}, action) =>{
    switch(action.type) {
        case "GRID_DATA_CHANGED":
            return {
                gridDataChange: ++gridDataChange,
                gridColumnChange
            };
        case "GRID_COLUMN_CHANGED":
            return {
                gridDataChange,
                gridColumnChange: ++gridColumnChange
            };
        default:
            return state;
    }
};

export const updateDashboardView = (state = null, action) => {
    switch(action.type) {
        case "UPDATE_DASHBOARD_VIEW" : {
            return action.data;
        }
        default: {
            return state;
        }
    }
};


export const appliedFilters = (state = {catIds: [], customFilters: []}, action) => {
    switch(action.type){
        case "APPLIED_FILTERS":
            return action.data;
        default:
            return state;
    }
};



var removeCount = 0,
    removeInit = {
        count: removeCount
    };

export const removeFilter = (state = removeInit, action) => {
    switch(action.type){
        case "REMOVE_FILTERS":
            let data = action.data;
            return {data,
                count: ++removeCount
            };
        default:
            return state;
    }
};

// export const filteredResData = (state = {}, action) => {
//   switch (action.type) {
//     case "GET_PRODUCT_DATA":
//       return action.data;
//     default:
//     return  state;
//   }
// }


export const alertsBreawkdown = (state = {}, action) => {
    switch (action.type) {
        case "ALERT_BREAKDOWN_DATA":
            return action.data;
        default:
            return  state;
    }
};
