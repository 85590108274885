import React from 'react';

class RequiredFieldsConfirm extends React.Component {
    render() {
        let props = this.props;
        return (
            <div >
                <div className="modal-content">
                    <div className="text-center confirm-delete">
                        <h4>Please update the mandatory field(s) -"{this.props.mandatoryAddColumns.join(", ")} - before editing this product"</h4>
                    </div>
                    <div className="modal-footer text-center">
                        <button className="gray-button" onClick={() => {
                            props.modalClose();
                            props.nextField();
                        }
                        }>Continue Editing</button>
                        {!this.props.productEditing &&
							<button className="gray-button" onClick={() => {
							    props.modalClose();
							    props.requireDelete();
							}
							}>
								Delete New Record</button>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default RequiredFieldsConfirm;
