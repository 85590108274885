import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../product/redux/productActions';
import * as product2_actions from '../../product2/redux/product2Actions';
import * as alert_actions from '../redux/dashboardActions';
import AlertsBreakDown from './alertsBreakDownComponent';

class NewAlerts extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            response:{},
            isLoaded: false,
        };

        this.alertProductClick = this.alertProductClick.bind(this);
    }

    // componentDidMount(){
    //     console.log('New Alerts  componentDidMount: ', this.props)


    //     let headers = {
    //         'Authorization': this.props.user.token,
    //         'content-type': 'application/json'
    //     }

    //     axios({
    //         method: 'post',
    //         url: require('../../common/configurations.js').dashboard.priorityAlerts,
    //         headers: headers,
    //         data: {id:this.props.user.userId}
    //       })
    //       .then( (response) => {
    //           console.log('Alerts loadData API call success data: ', response.data)
    //           let res = response.data

    //             if(res.data){
    //                 this.setState({response: res.data.healthTab, isLoaded : true});
    //             }

    //         //   if(res.data){
    //         //       var priorityAlerts = res.data.healthTab;
    //         //       if(this._isMounted) {
    //         //           this.setState({isLoadPriorityAlert:true,isShowAlertsBreakDown:false,priorityAlerts:priorityAlerts});
    //         //       }
    //         //       if(priorityAlerts[0]){
    //         //           this.showAlertsBreakDown(priorityAlerts[0]); // To show first alert breakdown default
    //         //       }
    //         //   }
    //         //   else{
    //         //       if(this._isMounted) {
    //         //           this.setState({isLoadPriorityAlert:true,isShowAlertsBreakDown:false,priorityAlerts:[]});
    //         //       }
    //         //   }
    //       })
    //       .catch( error => {
    //           console.log('alerts loadData API call failed', error)
    //       });

    //     }

    componentWillReceiveProps(nextProps){
        // console.log('New Alerts  componentWillReceiveProps: ', nextProps.addAlertsData.general.length)
        console.log('New Alerts  componentWillReceiveProps: PROPS ', this.props, nextProps);

        if (nextProps.addAlertsData.general) {
            this.setState({
                response:nextProps.addAlertsData.general,
                isLoaded: true,
            });
        }
    }

    showAlertsBreakDown(obj, i){
        this.currentAlerts = obj;
        this.selectedAlerts = obj.title;
        this.props.alertsBreawkdown(obj);
        if(this._isMounted) {
            this.setState({isShowAlertsBreakDown:true});
        }
        if(!isNaN(i)){
            $('.priority-alerts li').removeClass('active');
            $('.priority-alerts li:eq(' + i + ')').addClass('active');
        }
    }


    alertProductClick(obj){
        console.log('alert Prod Click: ', obj);
        let pagenames = obj.page.toLowerCase() == 'product' ? 'product2' : obj.page.toLowerCase();

        let _this = this;
        _this.props.toggleSpinner(true);

        this.props.setCurrentPage(pagenames);


        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        axios({
            method: 'get',
            url: require('../../common/configurations').apiURL + obj.url,
            headers: headers
        })
            .then( (response) => {
                console.log('notifierComponent alertProductClick API call success data: ', response.data);
                let res = response.data;

                if (res.code == 200) {
                    console.log('pagenames: ', pagenames);
                    if(pagenames == 'product2') {
                        console.log('moving to products 2 grid');
                        let product2GridData = {};
                        product2GridData['data'] = res.responseData.data;
                        product2GridData['columnDetails'] = res.responseData.columnDetails;
                        product2GridData['recordCount'] = res.responseData.data.length;
                        product2GridData['catIds'] = [];
                        product2GridData['customFilters'] = [];
                        product2GridData['fromAlerts'] = true;
                        _this.props.addProduct2GridData(product2GridData);
                        _this.props.filterDataChanged();
                        _this.props.toggleSpinner(false);
                        return;
                    }

                    this.props.addGridData(res.responseData.data);
                    this.props.addColumnDetails(res.responseData.columnDetails);
                    // this.props.addGridDropDowns(res.dropdowns)
                    this.props.gridDataChanged();
                }
            })
            .catch( error => {
                console.log('notifierComponent alertProductClick API call failed', error);
            });
    }


    render(){
        console.log('New Alerts  RENDER: ', this.props, this.state);

        var intViewportHeight = window.innerHeight;
        var containerheight = intViewportHeight - 64 - 30;
        var containerStyle = {
            height:containerheight
        };
        return (
            <div className="priority-alerts scroll">
                <h2>Priority Alerts</h2>
                <ul>
                    {this.state.isLoaded === true &&
                this.state.response.map((list, index)=>(
                    <li onClick={()=> this.showAlertsBreakDown(list, index)} onDoubleClick={() => this.alertProductClick(list)} key={index}><label className="left-txt">{list.title}</label><span>{list.value}</span></li>
                ))
                    }
                </ul>
            </div>
        );
    }
}

const mapStateToProps = (state) =>{
    return {
        addAlertsData: state.addAlertsData,
        alertsBreawkdown: state.alertsBreawkdown,
    };
};

const mapDispatchToprops = (dispatch) =>{
    return{

        addGridData: (data) =>{
            dispatch(actions.addGridData(data));
        },
        addColumnDetails: (data) => {
            dispatch(actions.addColumnDetails(data));
        },
        addGridDropDowns: (data) => {
            dispatch(actions.addGridDropDowns(data));
        },
        gridDataChanged: () => {
            dispatch(actions.gridDataChanged());
        },


        filterDataChanged: ()=>{
            dispatch(actions.filterDataChanged());
        },
        addProduct2GridData: (data) => {
            dispatch(product2_actions.addProduct2GridData(data));
        },

        alertsBreawkdown: (data)=>{
            dispatch(alert_actions.alertsBreawkdown(data));
        },
    };
};


export default connect(mapStateToProps, mapDispatchToprops)(NewAlerts);
