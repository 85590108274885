import React from 'react';
import SelectCategory from './select-category';

export class DepartmentPopUpRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            categoriesList: props.getCategoriesList(),
        };
    }

    render() {
        let departmentName = ''; // Set the deaprtment name
        const _this = this;
        if (this.props.node.data && this.props.node.data.departmentName) {
            departmentName = this.props.node.data.departmentName;
        }
        if (this.state.categoriesList && this.props.data && this.props.data.department) {
            this.state.categoriesList.forEach(function(row) {
                if (row.fieldId === _this.props.data.department) {
                    departmentName = row.fieldName;
                }
            });
            if (this.props.data && !this.props.data.departmentName) {
                this.props.node.setDataValue(this.props.colDef.colId, departmentName);
            }
        }

        return (
            <div>
                {departmentName}
            </div>
        );
    }
}

// //////////////////////////////////////////////////////////////////////////

export class DepartmentPopUpEditor extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            showPopup: false,
            department: null,
            class: null,
            subClass: null,
            categoriesList: props.getCategoriesList(),
            selectedHierarchy: {
                categoryId: null,
                division: null,
                department: null,
                classColumn: null,
                subClassColumn: null,
            },
        };
    }

    componentWillMount() {
        this.setState({showPopup: true});
    }

    /**
     * Comes with AG grid used to set a cell to be a popup or not
     * @return {boolean}
     */
    isPopup() {
        return true;
    }

    /**
     * Gets the value when the component unmounts and pushes it for render
     * @return {string} cell value
     */
    getValue() {
        const department = this.state.selectedHierarchy.department;
        let departmentName = this.props.value || '';
        this.state.categoriesList.forEach(function(row) {
            if (row.fieldId === department) {
                departmentName = row.fieldName;
            }
        });
        return departmentName;
    }

    /**
     * Setting the name using the id
     * @param {number} id // Get the id of department
     * @return {string} mapped name
     */
    getMappedName(id) {
        let name = '';
        this.state.categoriesList.forEach(function(row) {
            if (row.fieldId === id) {
                name = row.fieldName;
            }
        });
        return name;
    }

    // Closes the department popup
    closeDepartmentPopup() {
        // this.setState({showPopup: false});
        this.props.api.stopEditing(); // this command is required to set the value into the column.
    }

    // isCancelBeforeStart(){
    //     if(this.props.keyPress == 13) {
    //         this.setState({showPopup: true});
    //         return false
    //     }
    //     else{
    //         return true;
    //     }
    // }


    // isCancelAfterEnd(){
    //     const eDiv = this.refs.div;
    //     eDiv.focus();
    // }

    /**
     * Setting the values in the column cells
     * @param {number} catId ID of category
     * @param {object} catHierarchy object with Hierarchy of categories
     */
    setColumnValues(catId, catHierarchy) {
        // capture the values in the state to be accessible to the getValue function.
        const selectedHierarchy = this.state.selectedHierarchy;
        selectedHierarchy.categoryId = catId;
        selectedHierarchy.division = catHierarchy.division;
        selectedHierarchy.department = catHierarchy.department;
        selectedHierarchy.classColumn = catHierarchy.classColumn;
        selectedHierarchy.subClassColumn = catHierarchy.subClassColumn;
        this.setState({selectedHierarchy, showPopup: false});

        const rowData = this.props.node.data;

        let isDirty = false;
        if (
            rowData['catId'] != catId
        ) {
            isDirty = true;
        }

        if (isDirty) {
            rowData['catId'] = catId;
            rowData['division'] = catHierarchy.division;
            rowData['divisionName'] = this.getMappedName(catHierarchy.division);
            rowData['department'] = catHierarchy.department;
            rowData['departmentName'] = this.getMappedName(catHierarchy.department);
            rowData['classColumn'] = catHierarchy.classColumn;
            rowData['classColumnName'] = this.getMappedName(catHierarchy.classColumn);
            rowData['subClassColumn'] = catHierarchy.subClassColumn;
            rowData['subClassColumnName'] = this.getMappedName(catHierarchy.subClassColumn);

            this.props.rowUpdate(rowData, this.props.node.rowIndex);
        }
        this.props.api.stopEditing(); // this command is required to set the value into the column.
    }


    render() {
        return (
            <div>
                {
                    this.state.showPopup &&
                    <div className="ag-modal-container">
                        <div className="ag-modal-holder">
                            <SelectCategory
                                modalClose = {this.closeDepartmentPopup.bind(this)}
                                user = {this.props.user}
                                // setSelectedRow = {this.props.setSelectedRow}
                                setColumnValues = {this.setColumnValues.bind(this)}
                                productId = {this.props.node.data.parentProdId}
                            />;
                        </div>
                    </div>
                }
            </div>
        );
    }
}


