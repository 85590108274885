import React, { Component } from 'react';
import axios from 'axios';

// import JqxTreeGrid from '../../common/vendor/jqwidgets-react/react_jqxtreegrid.js';
var constants = require('../../common/constants.js');
// var NotificationManager = require('../../common/notification');
import NotificationManager from '../../common/components/notification';

var GridDataBuilder = require('../../common/gridDataBuilder');

import { ProductServices } from '../../product2/services/product-services';

class ShotAngles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        };
    }

    componentDidMount() {
        console.log('AddShotAngles: ', this.props);

        var _this = this;

        var anglesGrid = $('.anglesGrid');
        anglesGrid.on('click', '.sample-boolean', function (e) {
            var rowId = $(this).attr('data-row'),
                fieldName = $(this).attr('data-fieldname'),
                isChecked = $(this).prop('checked');
            var rowdata = _this.anglesGrid.getRowByUid(rowId);
            var productIdLst = _this.props.productId;

            let headers = {
                'Authorization': _this.props.user.token,
                'content-type': 'application/json'
            };

	  ProductServices.saveAngles({ angle: rowdata.angle, assigned: isChecked, productIdLst: productIdLst })
	  .then((response) => {
                    console.log('productContainer helperComponents add-shot-angles saveAngles API call success data: ', response.data);
                    let res = response.data;

                    if (res.status) {
                        NotificationManager({ message: "Shot Angle(s) updated successfully", type: 1 });
                    } else {
                        NotificationManager({ message: res.responseMessage, type: 4 });
                    }
                })
                .catch(error => {
                    console.log('productContainer helperComponents add-shot-angles saveAngles API call failed', error);
                    NotificationManager({ message: error, type: 4 });
                });
        });


        // var productId = this.props.productId[0];
        var productId = this.props.AgProductId; // added for ag grid

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.getAngles("?productId=" + productId)
            .then((response) => {
                console.log('productContainer helperComponents add-shot-angles getAngles API call success data: ', response.data);
                let res = response.data;

                if (res.status) {
                    var data = {};
                    data.columnDetails = [
                        {
                            "columnTitle": "id",
                            "fieldName": "id",
                            "type": "int",
                            "visibility": true,
                            "pk": true,
                            "order": 0,
                            "map": null,
                            "id": null,
                            "popup": false
                        },
                        {
                            "columnTitle": "Angle",
                            "fieldName": "angle",
                            "type": "string",
                            "visibility": true,
                            "pk": false,
                            "order": 0,
                            "map": null,
                            "id": null,
                            "popup": false
                        },
                        {
                            "columnTitle": "Assigned",
                            "fieldName": "assigned",
                            "type": "boolean",
                            "visibility": true,
                            "pk": false,
                            "order": 1,
                            "map": null,
                            "id": null,
                            "popup": false
                        }
                    ];
                    for (var i = 0; i < res.data.length; i++) {
                        res.data[i].id = i + 1;
                    }

                    data.data = res.data;

                    _this.gridData = GridDataBuilder(data, 'ShotAngles');
                    _this.setState({ isLoaded: true });
                } else {
                    _this.props.modalClose();
                    NotificationManager({ message: res.responseMessage, type: 4 });
                }
            })
            .catch(error => {
                console.log('productContainer helperComponents add-shot-angles getAngles API call failed', error);
                NotificationManager({ message: error, type: 4 });
            });
    }

    render() {
        let modalClass = 'SelectCategoryModal';
        let gridHeight = 300;
        let editSettings = {
            saveOnPageChange: true,
            saveOnBlur: true,
            saveOnSelectionChange: true,
            cancelOnEsc: true,
            saveOnEnter: true,
            editSingleCell: true,
            editOnDoubleClick: true,
            editOnF2: true
        };
        return (
            <div>
                <div className="modal-header">
          Shot Angles
                    <span className="close" onClick={this.props.modalClose}>x</span>
                </div>
                <div className="modal-content">
                    <div className="add-copy modal-form">
                        <div className="form- scroll">
                            {/* <div className="anglesGrid">
            {this.state.isLoaded ?
                <JqxTreeGrid ref={(grid)=> {this.anglesGrid = grid}}
                        width={'100 %'} height={gridHeight} source={this.gridData.dataAdapter}
                        columns={this.gridData.columns} checkboxes={false} editable={true} autoRowHeight={false} pageable={false}
                        sortable={true} autowidth={true}  theme={'light'} editSettings={editSettings}/>
            :"Loading..."}
            </div> */}
                        </div>
                        {/*
          <div className="modal-footer text-center">
            <input type="button" className="gray-button" value="Ok"/>
            <input type="button" className="gray-button" onClick={this.props.modalClose} value="Cancel"/>
          </div>
          */}
                    </div>
                </div>
            </div>
        );
    }
}
export default ShotAngles;
