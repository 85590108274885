import React, { Component } from 'react';

class EmailTemplatesFooter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isRulesRevealed: false,
        };

        this.toggleReveal = this.toggleReveal.bind(this);
    }

    toggleReveal() {
        this.setState((prevState) => (
            { isRulesRevealed: !prevState.isRulesRevealed }
        ));
    }

    componentDidMount() {
        const _this = this;
        // to hide dropdowns when user clicks outside the field area
        $(document).on('click.addrecord', function (e) {
            e.stopPropagation();
            const addRecord = '.reveal-rules';

            if ($(addRecord).hasClass('open') && $(e.target).closest('.pos-relative').length === 0) {
                _this.toggleReveal();
            }
        });
    }
    componentWillUnmount() {
        $(document).off('click.addrecord');
    }
    render() {
        return (
            <div className="footer alerts-footer">
                <div className={`rules-footer-container ${(this.props.isChanged) ? 'change-footer' : ''}`}>
                    <div className="add-rule-footer clearfix">
                        {/* <div className="footer-content left">
              <button type="button" className="gray-button">Add Clause</button>
            </div> */}
                        {this.props.enableFooterOption &&
              <div className="footer-content right">
                  <button type="button" className="gray-button" onClick={this.props.closeRule}>Cancel Changes</button>
                  <button type="button" className="gray-button"
                      onClick={this.props.addOrUpdateRules}>Apply Changes</button>
              </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default EmailTemplatesFooter;
