import createHistory from 'history/createBrowserHistory';
import { applyMiddleware, createStore } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import rootReducer from '../rootReducer';
import thunkMiddleware from 'redux-thunk';

export const history = createHistory();
const middleware = routerMiddleware(history);

export function configureStore() {
    return createStore(
        rootReducer,
        applyMiddleware(middleware, thunkMiddleware),
    );
}
