import React, { Component } from 'react';
const queryBuilderName = 'query-builder';

class RulesBuilder extends Component {
    componentDidMount() {
        this.initialize();
    }

    componentWillUnmount() {
        this.destroy();
    }

    componentWillReceiveProps(nextProps) {
        let _this = this;

        if (_this.props.isFilterChanged !== nextProps.isFilterChanged) {
            _this.destroy();

            let rulesBasic = nextProps.ruleData && this.IsJsonString(nextProps.ruleData.details) ? JSON.parse(nextProps.ruleData.details) : null;

            $('#' + queryBuilderName).queryBuilder({
                filters: nextProps.filters,
                select_placeholder: "select field",
                rules: null,
                conditions: ['AND', 'OR', 'THEN', 'AND_NOT']
            });
        }
    }

    /**
     * Checking if string
     * @param {*} str
     * @return {boolean}
     */
    IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }

        return true;
    }

    /**
     * initialize query
     */
    initialize() {
        let filterArray = this.props.filters;

        let config = {
            input: function (rule, name) {
                var $container = rule.$el.find('.rule-value-container');
                let dropdownVals = rule.__.filter.mathOperators;
                let dateField = rule.__.filter.field;
                let dateId = rule.__.filter.id;
                let currentInput;
                let todayRule = {};

                setTimeout(() => {
                    $('.datepicker').datepicker();
                }, 500);

                $container.on('click', '[name=' + name + '_1]', function () {
                    currentInput = $(this);

                    if (!$('#today-container').is(':visible') && rule.operator.type != "between") {
                        $('.ui-datepicker-calendar').append("<div id='today-container' data-id='" + rule.id + "'>Today</div>");
                    }

                    $(document).on('click', '#today-container', function (e) {
                        let id = $(this).attr('data-id');
                        let gettingRules = $('#query-builder').queryBuilder('getRules');
                        let selectedRule = rule.$el.find('.rule-value-container ').parents('.rule-container').children('.rule-filter-container').children('select').find(":selected").val();

                        rule.$el.find('.rule-value-container [name$=_1]').val("TODAY");
                        currentInput.val("TODAY");

                        todayRule['rules'] = [];
                        todayRule['rules'].push({ 'value': 'TODAY', 'id': dateId, 'field': dateField });
                        todayRule['valid'] = true;

                        if (rule.id == id && gettingRules) {
                            gettingRules.rules.map((i) => {
                                if (i.id == selectedRule) {
                                    let oldVal = i.value.split(',');
                                    let newArr = [];

                                    oldVal[0] = "TODAY";

                                    newArr.push(oldVal);

                                    let changed = newArr.toString();

                                    i.value = changed;
                                }
                            });
                        }

                        let setCheckRules = gettingRules ? gettingRules : todayRule;

                        $(".datepicker").datepicker("hide");
                        $('#query-builder').queryBuilder('setRules', setCheckRules);
                        $(this).trigger("change");
                    });
                });

                return '\
                    <input type="text" placeholder="MM-DD-YYYY" name="' + name + '_1" class="datepicker"></input> \
                    <select style="display:none;" name="' + name + '_2"> \
                    <option value="null">-</option> \
                ' + dropdownVals.map((i) => {
                    return '<option value="' + i + '">' + i + '</option>';
                }) + ' \
                    </select> \
                    <input type="number" style="display:none;" class="number-input" name="' + name + '_3"></input>';
            },
            valueGetter: function (rule) {

                if (rule.$el.find('.rule-value-container [name$=_1]').val() != "TODAY") {
                    rule.$el.find('.rule-value-container [name$=_2]').hide();
                    rule.$el.find('.rule-value-container [name$=_3]').hide();
                } else {
                    rule.$el.find('.rule-value-container [name$=_2]').show();
                    rule.$el.find('.rule-value-container [name$=_3]').show();
                }

                if (rule.$el.find('.rule-value-container [name$=_1]').val() != "TODAY" && rule.operator.type != "between") {
                    return rule.$el.find('.rule-value-container [name$=_1]').val();
                }

                if (rule.operator.type != "between") {
                    return rule.$el.find('.rule-value-container [name$=_1]').val()
                        + ',' + rule.$el.find('.rule-value-container [name$=_2]').val()
                        + ',' + rule.$el.find('.rule-value-container [name$=_3]').val();
                } else {
                    return rule.$el.find('.rule-value-container [name$=0_1]').val()
                        + ',' + rule.$el.find('.rule-value-container [name$=1_1]').val();
                }
            },
            valueSetter: function (rule, value) {
                let val;

                if (rule.operator.nb_inputs == 2 && rule.operator.type == "between") {
                    if (value.length > 0) {
                        rule.$el.find('.rule-value-container [name$=0_1]').val(value[0]);
                        rule.$el.find('.rule-value-container [name$=1_1]').val(value[1]);
                    } else {
                        val = value.split(',');
                        rule.$el.find('.rule-value-container [name$=0_1]').val(val[0]);
                        rule.$el.find('.rule-value-container [name$=1_1]').val(val[1]);
                    }
                }

                if (rule.operator.nb_inputs > 0 && rule.operator.type != "between") {
                    val = value.split(',');

                    if (val[0] == 'TODAY') {
                        rule.$el.find('.rule-value-container [name$=_2]').show();
                        rule.$el.find('.rule-value-container [name$=_3]').show();
                    }

                    rule.$el.find('.rule-value-container [name$=_1]').val(val[0]);
                    rule.$el.find('.rule-value-container [name$=_2]').val(val[1]);
                    rule.$el.find('.rule-value-container [name$=_3]').val(val[2]);
                }
            }
        };

        for (let i = 0; i < filterArray.length; i++) {
            if (filterArray[i].type == 'date') {
                filterArray[i]['input'] = config.input;
                filterArray[i]['valueGetter'] = config.valueGetter;
                filterArray[i]['valueSetter'] = config.valueSetter;
            }
        }

        const rulesBasic = this.props.ruleData && this.IsJsonString(this.props.ruleData.details) ? JSON.parse(this.props.ruleData.details) : null;
        // Fix for Bootstrap Datepicker

        this.checkBoolValues(rulesBasic)

        $('#' + queryBuilderName).queryBuilder({
            filters: filterArray,
            select_placeholder: 'select field',
            rules: rulesBasic,
            conditions: ['AND', 'OR', 'THEN', 'AND_NOT'],
        });
    }

    checkBoolValues(data) {
        data?.rules?.forEach((rule) => {
            if (rule.type === "boolean") {
                let parseValue = rule.value.toString();
                rule.value = parseValue;
            }
            if (rule.rules?.length > 0) {
                this.checkBoolValues(rule);
            }
        })

        return data;
    }

    /**
     * destory div
     */
    destroy() {
        $('#' + queryBuilderName).queryBuilder('destroy');
    }

    render() {
        return (<div id={queryBuilderName}></div>);
    }
}

export default RulesBuilder;
