import React from "react";
import {CustomDropdownEditor} from '../../product2/helperComponents/customDropdownEditor';

class SampleTypeDropdownEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            SampleTypeDropdownVals: this.props.dropdownVals(),
            selectedVal: '',
            width: this.props.eGridCell.style.width,
        };
    }

    componentWillUnmount() {
        this.props.api.stopEditing();
        this.props.api.tabToNextCell();
    }

    isPopup() {
        return true;
    }

    getValue() {
        return this.state.selectedVal;
    }

    changeSelectedValue(value) {
        this.setState({
            selectedVal: value,
        }, () => this.stopEditing());
    }

    stopEditing() {
        this.props.api.stopEditing();
    }

    render() {
        return (
            <div>
                <CustomDropdownEditor
                    width={this.state.width}
                    selectedVal={this.state.selectedVal}
                    SampleTypeDropdownVals={this.state.SampleTypeDropdownVals}
                    changeSelectedValue={this.changeSelectedValue.bind(this)}
                    {...this.props}
                />
            </div>
        );
    }
}

export default SampleTypeDropdownEditor;
