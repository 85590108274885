import React from 'react';

export class MultipleFileUploadRenderer extends React.Component {
    render() {
        const IconDisplay = (
            <div className="ag-popupIndicator">
                <img
                    alt="Expand"
                    src={require('../../../common/icons/Expand Grid.svg')}
                />
            </div>
        );

        return (
            <React.Fragment>
                {this.props.value ?? IconDisplay}
            </React.Fragment>
        );
    }
}
