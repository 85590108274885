import React from 'react';
import axios from 'axios';

class AddRecordsFromFiles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputFile: [],
            fileStatus: {
                isSubimtted: false
            }
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.checkTheFormat = this.checkTheFormat.bind(this);
    }

    handleSubmit(e) {
        let _this = this;
        e.preventDefault();
        // var form = document.getElementById("AddRecordFiels");
        // console.log('document get element by id', form)
        // var formData = new FormData(form);
        let importApi = this.props.actionRef.method === "ImportProduct" ? require('../../common/configurations.js').product.addRecordFiles : require('../../common/configurations.js').product.importPOFile;

        var formData = new FormData();
        formData.append('file', this.state.inputFile);


        console.log( 'handleSubmit formData', formData);

	      let headers = {
	            'Authorization': this.props.user.token,
	            'content-type': 'multipart/form-data'
	      };

	      axios({
	        method: 'post',
	        url: importApi,
	        headers: headers,
	        data: formData
	      })
	      .then( (response) => {
	          console.log('productContainer AddRecordsFromFiles handleSubmit API call success data: ', response.data);
	          let res = response.data;

                _this.setState({
                    fileStatus: {
                        isSubimtted: true,
                        data: res
                    }
                });
	      })
	      .catch( error => {
	          console.log('productContainer AddRecordsFromFiles  handleSubmit API call failed', error);
	      });
    }

    handleFileChange(e) {
        console.log('handleFileChange: ', e.target);
        this.setState({inputFile:e.target.files[0]});


        // var target = e.target,
        // 	list = target.files;

        // var key = "lastModifiedDate";
        // delete list[key];

        // var fileArray = [];
        // for (var i = 0; i < list.length; i++) {
        // 	var fileList = {};
        // 	for (var l in list[i]) {
        // 		if(l != 'lastModifiedDate') {
        // 			fileList[l] = list[i][l];
        // 		}
        // 	}
        // 	console.log('addRecordsFromFiles handleFileChange: ',fileList)
        // 	fileArray.push(fileList);
        // }

        // if (list.length > 0 && fileArray.length > 0) {
        // 	this.setState({ inputFile: fileArray })
        // }
    }

    checkTheFormat() {
        // the allowed file formats in regular expression
        const AllowedFileFormats = /(json|csv|xls|xlsx)/ig;
        const obj = { isValid: true, list: [], prestine: true };
        if (this.state.inputFile.length > 0) {
            let fileArray = this.state.inputFile;

            for (var i = 0; i < fileArray.length; i++) {
                if (!AllowedFileFormats.test(fileArray[i].name)) {
                    obj.isValid = false;
                    obj.list.push(fileArray[i].name);
                } else {
                    obj.prestine = false;
                }
            }

            return obj;
        }

        return obj;
    }

    render() {
        let validateRecordFiles = this.checkTheFormat();

        return (
            <div>
                <div className="modal-header">
                    {this.props.actionRef.popupHeading}
                    <span className="close" onClick={this.props.modalClose}>x</span>
                </div>
                <div className="modal-content add-record-files">
                    {!this.state.fileStatus.isSubimtted &&
						<form id="AddRecordFiels" name="records-from-files" onSubmit={this.handleSubmit} encType="multipart/form-data">
						    <fieldset>
						        <input type="file" onChange={this.handleFileChange} name="files" />
						    </fieldset>
						    <fieldset className={`error ${(validateRecordFiles.isValid) ? 'valid' : 'invalid'}`} >
						        <span>
						            {!validateRecordFiles.isValid && validateRecordFiles.list.length > 0 &&
										'The selected file format(s) "' + validateRecordFiles.list[0].split(".")[validateRecordFiles.list[0].split(".").length - 1] + '" is not allowed. Please choose the file | "' + validateRecordFiles.list[0] + '" | in one of the allowed formats "JSON, CSV"'
						            }
						        </span>
						    </fieldset>
						    <fieldset>
						        <button type="submit" className="orange-button"
						        >Upload Records</button>
						    </fieldset>
						    <input type="hidden" name="extraField" value="_dontcare" />
						</form>
                    }
                    <div className="submited">
                        {this.state.fileStatus.isSubimtted &&
							<FileUploadMsg details={this.state.fileStatus.data} />
                        }
                    </div>
                </div>
            </div>
        );
    }
}

function FileUploadMsg(props) {
    var fileStatus = props.details;

    if (fileStatus.failCount > 0) {
        return (<div >failed to upload <ul className="">
            {
                fileStatus.errorLogs.map((list, index) => {
                    return <li key={index}>{list}</li>;
                })
            }
        </ul></div>
        );
    } else if (fileStatus.successCount > 0) {
        return (
            <div>Successfully submitted {fileStatus.successCount} file(s)</div>
        );
    } else {
        return <div>No records updated</div>;
    }
}

export default AddRecordsFromFiles;

// disabled={!validateRecordFiles.isValid || validateRecordFiles.prestine}
