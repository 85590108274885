import React from 'react';

import User from '../adminUser/userContainer';
import UserGroup from '../adminUserGroup/userGroupContainer';
import FieldPermissions from '../adminFieldPermissions/fieldPermissionsContainer';
import RequriedFields from '../adminRequiredFields/requiredFieldsContainer';
import RulesGrid from '../adminRules/rules-gridContainer';
import AlertsGrid from '../adminAlerts/alerts-gridContainer';

import Vendor from '../adminVendor/vendorContainer';
import WorkflowAllocation from '../adminWorkflowAllocation/workflowAllocationContainer';
import DropDowns from '../adminDropDowns/dropDownContainer';
import UserFields from '../adminUserFields/userFieldsContainer';
import FieldLength from '../adminFieldLength/fieldLengthContainer';
import EmailTemplates from '../adminEmailTemplates/emailTemplatesContainer';
import CronJobSettings from '../adminCronJobSettings/cronJobSettingsContainer';
import AuditLog from '../adminAuditLog/auditLogContainer';
import Accomplishment from '../adminAccomplishment/accomplishmentContainer';
import Publishing from '../adminPublishing/publishingContainer';
import CategoryTab from '../adminProductCategories/productCategoryContainer';
import UserModules from '../adminModules/userModulesContainer';

class SwitchTheModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            setPage: null,
        };
    }

    componentDidMount() {
        const getPage = localStorage.getItem('currentAdminPage');
        const getPageCurrent = localStorage.getItem('currentPage');

        this.setState ({ setPage: getPage });

        $('.panel').each(function( index, element ) {
            const testdata = $(this).data('menu_id');

            if ( testdata ==  getPage) {
                $( this ).children('.panel-heading').addClass('active');
            } else {
                $( this ).children('.panel-heading').removeClass('active');
            }
        });
    }

    // Used to stop the overlap of the Header and View Recent Messages
    setWidth() {
        const widthGet = $('.page-title').text().length;

        $('.view-recent-messages').css({ 'padding-left': widthGet + "px" });
    }

    componentWillReceiveProps(nexpProps) {
        if (this.props.userView.menuId != nexpProps.userView.menuId) {
            this.setState({ setPage: nexpProps.userView.menuId }, () => {
                this.setWidth();
            });

            localStorage.setItem('currentAdminPage', nexpProps.userView.menuId);
        }
    }

    /**
     * holder for all the components in the admin
     * @return {*} returns one of the components
     */
    render() {
        const setCase = this.state.setPage;

        switch (setCase) {
            case 'user':
                return <User {...this.props} />;
            case 'user_group':
                return <UserGroup {...this.props} />;
            case 'user_modules':
                return <UserModules {...this.props} />;
            case 'fields_records':
                return <FieldPermissions {...this.props} />;
            case 'mandatory_records':
                return <RequriedFields {...this.props} />;
            case 'admin_rules':
                return <RulesGrid {...this.props} />;
            case 'admin_alerts':
                return <AlertsGrid {...this.props} />;
            case 'vendor':
                return <Vendor {...this.props} />;
            case 'workflow_allocation':
                return <WorkflowAllocation catId={this.props.userView.catId} {...this.props} />;
            case 'dropdowns':
                return <DropDowns {...this.props} />;
            case 'user_fields':
                return <UserFields {...this.props} />;
            case 'field_length':
                return <FieldLength {...this.props} />;
            case 'email_templates':
                return <EmailTemplates {...this.props} />;
            case 'cron_job_setting':
                return <CronJobSettings {...this.props} />;
            case 'audit_log':
                return <AuditLog {...this.props} />;
            case 'accomplishment':
                return <Accomplishment {...this.props} />;
            case 'publishing':
                return <Publishing {...this.props} />;
            case 'product_category':
                return <CategoryTab {...this.props} catId={this.props.userView.catId} refreshView={this.props.userView.refreshView} />;
            default:
                return <User {...this.props} />;
        }
    }
}

export default SwitchTheModal;
