import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class MobileMenu extends React.Component {
    constructor(props){
        super(props);

        this.state = {
        };
    }

    componentWillMount() {
    }

    render () {
        return (
            <div className="mobile-nav-container">
                <div className="mobile-items">
                    <Link to={{ pathname: '/dashboard' }} title='Dashboard'>
                        <i className="icon-globe" ></i>
                        <span>Dashboard</span>
                    </Link>
                </div>
                <div className="mobile-items">
                    <Link to={{ pathname: '/product2' }} title='Products'>
                        <i className="icon-product"></i>
                        <span>Product</span>
                    </Link>
                </div>
                <div className="mobile-items">
                    <Link to={{ pathname: '/creative' }} title='Creative'>
                        <i className="icon-creative"></i>
                        <span>Creative</span>
                    </Link>
                </div>
                <div className="mobile-items">
                    <Link to={{ pathname: '/scanning' }} title='Scanning'>
                        <i className="fas fa-barcode"></i>
                        <span>Scanning</span>
                    </Link>
                </div>
                <div className='mobile-sign-out'>
                    <i onClick={this.props.logout} className="fas fa-sign-out-alt" title='Signout'></i>
                </div>
            </div>
        );
    }
}

export default MobileMenu;
