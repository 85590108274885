import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import DropDown from '../common/dropDown';
import TreeView from '../TreeView/treeView';

import Accordion from '../common/components/accordion.js';
import CopywritingSection from './copywritingGrid';
import HelpBugs from '../common/help/helpBugs';

const LeftDrawerComponent = ({
    adminLeftMenu,

    toggleLeftDrawer,
    state,
    props,
    currentPage,
    levels,
    searchOperands,
    applyFilter,
    changeSearchField,
    handelSearchValue,
    addCriteriaLevel,
    showDeletedProduct,
    treeViewRef,
    displaySearch,
    displaySearchDropdown,
    displayBottomSearchDropdown,
    searchSearchDropdown,
    toggleSearchDropdown,
    displayDefaultSearch,
    selectedSearchName,
    hideSearchDropdown,
    fieldDropdownClose,
    removeCopywritingGrid,
    keepSearchValue,
    activeCategoryCheck,
    applySearch,
}) => {
    return (
        <div>
            { props.currentPage === 'admin' &&
            <div className="drawer">
                <div className="search-refine-holder">
                    <div className="left-container">
                        <div className="drawer-title">
                            Administration
                            <span className="close-btn">
                                <i className="icon-close" onClick={toggleLeftDrawer}></i>
                            </span>
                        </div>
                        <div className="drawer-content">
                            <div>
                                <div id="adminMenuGroup">
                                    <Accordion
                                        data={adminLeftMenu}
                                        updateView={props.updateView}
                                        user={props.user}
                                        currentPage={props.currentPage} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="puller" onClick={toggleLeftDrawer}>
                        <i className="pane-arrow"></i>
                    </div>
                </div>
            </div>
            }
            { (props.currentPage === 'copywriting' || props.currentPage === 'marketing' || props.currentPage === 'dashboard' || props.currentPage === 'creative' || props.currentPage === 'samples' || props.currentPage === 'product2') &&
            <div>
                <div className="drawer no-filter">

                    <div className="search-refine-holder product">
                        {state.isLoaded && !state.showCopywritingGrid ?
                            <div className="left-container">

                                <div className="drawer-title">
                                Filter and Search
                                    <HelpBugs
                                        currentPage={props.currentPage}
                                        section={'search'} />
                                    <span className="close-btn">
                                        <i className="icon-close" onClick={toggleLeftDrawer}></i>
                                    </span>
                                </div>

                                <div className="drawer-content">

                                    {(props.currentPage === 'copywriting' || props.currentPage === 'dashboard' || props.currentPage === 'samples' || props.currentPage === 'product2') &&
                                <div className="filter-section scroll">
                                    <h4 className="section-title">Filters</h4>
                                    <div className="">
                                        <TreeView
                                            data={state.filterDetails}
                                            ref={treeViewRef}
                                            removeFilter={props.removeFilter}
                                            filters={props.appliedFilters}
                                            userSpeciality={props.user?.speciality}
                                            activeCategoryCheck = {activeCategoryCheck}
                                        />
                                    </div>
                                </div>
                                    }

                                    <div className="custom-filter scroll">
                                        <h4 className="section-title">Custom Filters</h4>
                                        <div className="custom-filter-content">
                                            {levels}
                                        </div>
                                        <button className="button-link" id="addCriteria" onClick={addCriteriaLevel}>Add Criteria Level</button>
                                    </div>

                                    <div className="search-footer">
                                        <div className="filter-actions">
                                            {currentPage === 'product' || currentPage === 'product2'  &&
                                        <button id="save-filter" disabled={state.disableFilter} onClick= {(e) => applyFilter(e, false, true)} >
                                            Save &amp; Filter
                                        </button>
                                            }
                                            <button id="apply-filter" disabled={state.disableFilter} onClick={applyFilter}>Filter</button>
                                        </div>
                                    </div>

                                    {(props.currentPage === 'copywriting' || props.currentPage === 'dashboard' || props.currentPage === 'samples' || props.currentPage === 'product2' || props.currentPage === 'creative') &&
                                <div className="custom-search">
                                    <h4 className="section-title">Search</h4>
                                    <div className="custom-filter-content">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <label className="form-label">Field</label>
                                                    <input type="text" className="leftDrawer-ul-input" placeholder="Search.." style={displaySearch} onChange={searchSearchDropdown}></input>
                                                    <ul className={"form-control clickControlDD " + displaySearchDropdown} onClick={hideSearchDropdown}>
                                                        {searchOperands}
                                                    </ul>
                                                    <div className="form-control showSelectedFilterCF" style={displayDefaultSearch} onClick={toggleSearchDropdown}>{selectedSearchName}</div>
                                                </div>
                                            </div>
                                            { (state.searchValues.type === "Date")
                                                ?
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Value</label>
                                                        <input type="text" className="form-control datepicker searchDate" name="searchDate" value={state.searchValues.value}></input>
                                                    </div>
                                                </div>
                                                :
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label className="form-label">Value</label>
                                                        <input type="text" className="form-control" name="searchValue" value={state.searchValues.value} onChange={handelSearchValue}></input>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="keep-search-value">
                                                    <label className="form-check-label">
                                                        <input type="checkbox" className="form-control-check" id="keep-search-value" name="keep-search-value" checked={state.keepSearchVal} onChange={keepSearchValue}></input>
                                                        <span htmlFor="keep-search-value" className="from-checkbox"></span>
                                                        <div className='search-text'>Keep Search Value</div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <button id="apply-search" disabled={state.disableSearch} onClick={applySearch}>SEARCH</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    }
                                </div>
                            </div>
                            :

                            <div>
                                <CopywritingSection
                                    leftDrawerProps={props}
                                    leftDrawerState={state}
                                    removeCopywritingGrid={removeCopywritingGrid}
                                />
                            </div>

                        }

                        <div className="puller" onClick={toggleLeftDrawer}>
                            <i className="pane-arrow"></i>
                        </div>

                    </div>
                </div>

            </div>
            }

        </div>
    );
};

LeftDrawerComponent.propTypes = {
    toggleLeftDrawer: PropTypes.func,
    state: PropTypes.object,
    currentPage: PropTypes.string,
    levels: PropTypes.array,
    applyFilter: PropTypes.func,
    changeSearchField: PropTypes.func,
    handelSearchValue: PropTypes.func,
    addCriteriaLevel: PropTypes.func,
    showDeletedProduct: PropTypes.func
};

export default withRouter(LeftDrawerComponent);
