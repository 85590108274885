/*column width declaration
*/
const columnWidth = {
    jobName: 200,
    prodName: 300,
    activeNotes: 400,
};

const createDateTimeEditor = function (row, cellvalue, editor, cellText, width, height) {
    // construct the editor.
    cellvalue = cellvalue ? new Date(cellvalue) : new Date();
    editor.jqxDateTimeInput({
        width: '120px',
        height: '25px',
        value: cellvalue,

        culture: 'en-US',
        formatString: 'HH:mm',
        showTimeButton: false,
        showCalendarButton: false,
    });
};

const initDateEditor = function (row, cellvalue, editor, celltext) {
    // set the editor's current value. The callback is called each time the editor is displayed.
    cellvalue = cellvalue ? new Date(cellvalue) : new Date();
    editor.jqxDateTimeInput('setDate', cellvalue);
};

const getDateTimeEditor = function (row, cellvalue, editor) {
    const cdate = $(editor).jqxDateTimeInput('value');
    const newData = new Date(cdate);
    let hours = newData.getHours();
    let min = newData.getMinutes();

    if (min < 10) {
        min = '0' + min;
    }

    if (hours < 10) {
        hours = '0' + hours;
    }

    const formatDate = hours + ':' + min;

    return formatDate;
};

/** Grid data builder is used to create the object format required for
* Jqwidgets
* @param {*} res
* @param {*} pageName
* @return{*}
*/
function GridDataBuilder(res, pageName) {
    // dataField is used for providing type of data and its name.
    // columnList is used to define column name, type, and extra fields
    //  to define additional plugins inside the cell eg: drop down, calender ...,
    const dataField = [];
    const columnList = [];
    let primayKey = res.columnDetails[0].fieldName;
    // check for the type of fieldname future

    //sort columndetails in product pageName
    res.columnDetails.sort(function (a, b) {
        return a.order - b.order;
    });

    for (let i = 0; i < res.columnDetails.length; i++) {
        dataField.push({
            'name': res.columnDetails[i].fieldName,
            'type': res.columnDetails[i].type.toLowerCase(),
        });

        /* Enable the below condition after pk(primary key) availabel from backend
        *  in JSON response.
        */

        if (res.columnDetails[i].hasOwnProperty('pk') && res.columnDetails[i].pk) {
            primayKey = res.columnDetails[i].fieldName;
        }

        columnList.push(columnObj(res.columnDetails[i]));
    }

    /* columnobj function will  return the object fields
    *  based on the field type in list.
    * e.g. for date type and other types need to build the cellrender function.
    */
    function columnObj(list) {
        const obj = {};

        obj.text = '<span title="' + list.columnTitle + '">' + list.columnTitle + '</span>';
        obj.dataField = list.fieldName;
        obj.width = (columnWidth[list.fieldName]) ? columnWidth[list.fieldName] : 120;
        obj.hidden = !list.visibility;
        obj.cellClassName = function (row, column, value, data) {
            if (data.deleted) {
                return 'strike-through deleted';
            }
        };

        obj.editable = false;
        /* if list type is date, pass the methods related to date to intialize
        *  date picker
        */
        if (list.fieldName === 'startTime') {
            obj.editable = true;
        } else if (list.fieldName === 'displayValue') {
            obj.editable = true; // remove this if required
        }

        return obj;
    }

    const source = {
        dataType: 'json',
        dataFields: dataField,
        id: primayKey,
        localdata: res.data,
    };

    const dataAdapter = new $.jqx.dataAdapter(source);

    return {
        dataAdapter: dataAdapter,
        columns: columnList,
    };
}

export default GridDataBuilder;
