import React, { Component } from 'react';

export default class SamplesFooter extends Component {
    constructor(props) {
        super(props);

        console.log('footer: ', props);

        this.state = {
            clickedOnAddRecord:false,
            disableFooter: true,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const _this = this;

        console.log('footer: ', this.props, prevState, prevProps);

        if (prevProps.selectedRowsCount != this.props.selectedRowsCount && this.props.selectedRowsCount > 0) {
            _this.setState({
                disableFooter: false,
            });
        }

        if (prevProps.selectedRowsCount != this.props.selectedRowsCount && this.props.selectedRowsCount == 0) {
            _this.setState({
                disableFooter: true,
            });
        }
    }

    render() {
        return (
            <div className="product-footer footer">
                <div className='samples-footer-left'>
                    <button type="button" disabled={this.state.disableFooter} className="footer-btn gray-button" onClick={this.props.reprintSampleCard} >
                        Re-Print Sample Card
                    </button>
                    <button type="button" disabled={this.state.disableFooter} className="footer-btn gray-button" onClick={this.props.openMoveModalBox} >
                        Move Sample
                    </button>
                </div>

                <div className="footer-content right">
                    <button type="button" className="gray-button left record-delete-holder" onClick={this.props.openScanModal}>
                        Scan Actions
                    </button>
                    <button type="button" className="gray-button" >
                        Undo Last changes
                    </button>
                </div>
                <div className="total-row-count">
                    <span className="total-rows"> {this.props.totalRecordCount} Total Rows </span>
                    <span className="selected-rows"> | Selected: {this.props.selectedRowsCount}</span>
                </div>
            </div>
        );
    }
}
