import React, { Component } from 'react';
import CategoryGrid from './helperComponent/category-grid';

const ProductCategoryComponent = ({
    state,
    props,
}) => {
    return (
        <div>
            <CategoryGrid
                catId={state.catId}
                user={props.user}
                refreshView={props.refreshView}
            />
        </div>
    );
};

export default ProductCategoryComponent;
