import React, {Component} from 'react';

import Samples from './sample-modal';

export class SamplesEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPopup: false,
        };
        this.modalClose = this.modalClose.bind(this);
    }

    componentWillMount() {
        let flag = false;

        if (this.props.node.data.displayLevel == 'Color' && this.props.node.level == 1) {
            flag = true;
        }

        if (this.props.node.data.displayLevel == 'Style' && this.props.node.level == 0) {
            flag = true;
        }

        if (this.props.node.data.displayLevel == 'Size' && this.props.node.level == 2) {
            flag = true;
        }

        if (this.props.node.data.dataLevel == 'SINGLE') {
            flag = true;
        }

        if (!flag) {
            this.props.api.stopEditing();
        } else {
            // this.setState({ showPopup: true });
            this.props.openSamplesPopup(this.props.node.data);
        }

        // this.setState ({ showPopup: true })
    }

    componentWillUnmount() {
        this.props.api.tabToNextCell();
    }

    isPopup() {
        return true;
    }


    /**
     * Gets the value when the component unmounts and pushes it for render
     * @return {string} cell value
     */
    getValue() {
        return this.props.value;
    }

    /**
     * Closes the popup
     */
    modalClose() {
        this.setState({ showPopup: false });
        this.props.api.stopEditing();
    }


    render() {
        return (
            <div>
                {this.state.showPopup &&
                    <div className="ag-modal-container">
                        <div className="ag-modal-holder">
                            <Samples
                                productData = {this.props.node.data}
                                data = {this.props}
                                user = {this.props.user}
                                modalClose = {this.modalClose}
                            />
                        </div>
                    </div>
                }
            </div>
        );
    }
}

// ////////////////////////////////////////////////////////////////////////////////////


export class SamplesRender extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        // Checks for the channel list in the data array and holds the values
        const displayLevel = (this.props.data && this.props.data.displayLevel) ? this.props.data.displayLevel : null;

        // Checks the display level of the record for validation
        const SamplesDisplay = <div className="ag-popupIndicator"><img src={require('../../../common/icons/Expand Grid.svg')} /></div>;

        if (this.props.node.data) {
            if (this.props.node.data.dataLevel == 'SINGLE') {
                return (
                    <div>
                        {SamplesDisplay}
                    </div>
                );
            }
        }

        if (displayLevel) {
            switch (displayLevel) {
                case 'Style':
                    if (this.props.node.level == 0) {
                        return (
                            <div>
                                {SamplesDisplay}
                            </div>
                        );
                    } else {
                        return null;
                    }
                case 'Color':
                    if (this.props.node.level == 1) {
                        return (
                            <div>
                                {SamplesDisplay}
                            </div>
                        );
                    } else {
                        return null;
                    }
                case 'Size':
                    if (this.props.node.level == 2) {
                        return (
                            <div>
                                {SamplesDisplay}
                            </div>
                        );
                    } else {
                        return null;
                    }
                default: return null;
            }
        } else {
            return null;
        }
    }
}
