import React from 'react';
import ProductInfo from './productInfo';
import ImageInfo from './imageSection';

class ImageAndPi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 'image',
            selectedRow: [],
        };

        this.changeTabs = this.changeTabs.bind(this);
    }


    changeTabs(e) {
        const getId = e.currentTarget.id;
        this.setState({ activeTab: getId });
        this.props.setSelectedTab(getId);
    }


    componentWillReceiveProps(nextProps) {
        const _this = this;
        console.log('ImageAndPi componentWillReceiveProps', this.props, nextProps);

        if (nextProps.selectedRowData && _this.props.selectedRowData && nextProps.selectedRowData.productId != _this.props.selectedRowData.productId) {
            _this.setState({ selectedRow: nextProps.selectedRowData });
        }
    }



    render () {
        console.log('ImageAndPi render', this.state, this.props);
        const _this = this;
        return(
            <div className="cw-left-section">
                <div className="tabs">
                    <ul className="tabs-nav">
                        <li onClick={this.changeTabs} id='image'><div className={`tab-items ${(_this.state.activeTab == 'image' ? 'activeTab' : '')}`}>Image</div></li>
                        <li onClick={this.changeTabs} id='product'><div className={`tab-items ${(_this.state.activeTab == 'product' ? 'activeTab' : '')}`}>Product Info</div></li>
                    </ul>
                    <div className="tabs-stage">
                        {this.state.activeTab == 'image' &&
                        <ImageInfo
                            selectedRowData = {this.state.selectedRow}
                        />
                        }
                        {this.state.activeTab == 'product' &&
                        <ProductInfo
                            selectedRowData = {this.state.selectedRow}
                        />
                        }
                    </div>
                </div>
            </div>
        );
    }
}


export default ImageAndPi;
