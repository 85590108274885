import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import axios from 'axios';
import NotificationManager from '../common/components/notification';
import './we-seasons.css';
import { DropdownEditor } from './helperComponents/dropdownEditor';

export class WestElmSeasonsEditor extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            rowData: [],
            columnDefs: [
                {
                    colId: 'season',
                    field: 'season',
                    width: 150,
                    headerName: 'Seasons',
                    headerCheckboxSelection: true,
                    checkboxSelection: true,
                    editable: true,
                    cellEditorFramework: DropdownEditor,
                    cellEditorParams: {
                        width: 150,
                        getList: this.getSeasonsList.bind(this),
                    },
                },
                {
                    colId: 'channel',
                    field: 'channel',
                    width: 150,
                    headerName: 'Channel',
                    editable: true,
                    cellEditorFramework: DropdownEditor,
                    cellEditorParams: {
                        width: 150,
                        getList: this.getChannelsList.bind(this),
                    },
                },
                {
                    colId: 'storeGroupRanking',
                    field: 'storeGroupRanking',
                    width: 150,
                    headerName: 'Store Group Ranking',
                    editable: true,
                    cellEditorFramework: DropdownEditor,
                    cellEditorParams: {
                        width: 150,
                        getList: this.getStoreGroupRanking.bind(this),
                    },
                },
                {
                    colId: 'rtlRank',
                    field: 'rtlRank',
                    width: 150,
                    headerName: 'RTL Rank',
                    editable: true,
                },
                {
                    colId: 'rtlCollection',
                    field: 'rtlCollection',
                    width: 150,
                    headerName: 'RTL Collection',
                    editable: true,
                },
                {
                    colId: 'rtlStoreCount',
                    field: 'rtlStoreCount',
                    width: 150,
                    headerName: 'RTL Store Count',
                    editable: true,
                },
                {
                    colId: 'rtlSkuCount',
                    field: 'rtlSkuCount',
                    width: 150,
                    headerName: 'RTL SKU Count',
                    editable: true,
                },
                {
                    colId: 'dtcRank',
                    field: 'dtcRank',
                    width: 150,
                    headerName: 'DTC Rank',
                    editable: true,
                },
                {
                    colId: 'dtcCollection',
                    field: 'dtcCollection',
                    width: 150,
                    headerName: 'DTC Collection',
                    editable: true,
                },
                {
                    colId: 'dtcStoreCount',
                    field: 'dtcStoreCount',
                    width: 150,
                    headerName: 'DTC Store Count',
                    editable: true,
                },
                {
                    colId: 'dtcSkuCount',
                    field: 'dtcSkuCount',
                    width: 150,
                    headerName: 'DTC SKU Count',
                    editable: true,
                },
                {
                    colId: 'packShown',
                    field: 'packShown',
                    width: 150,
                    headerName: 'Pack Shown',
                    editable: true,
                },
                {
                    colId: 'rtcNotes',
                    field: 'rtcNotes',
                    width: 150,
                    headerName: 'RTC Notes',
                    editable: true,
                },
                {
                    colId: 'core',
                    field: 'core',
                    width: 150,
                    headerName: 'Core',
                    editable: true,
                    cellEditorFramework: DropdownEditor,
                    cellEditorParams: {
                        width: 150,
                        getList: this.getCore.bind(this),
                    },

                },
                {
                    colId: 'seasonIntroduced',
                    field: 'seasonIntroduced',
                    width: 150,
                    headerName: 'Season Introduced',
                    editable: true,
                    cellEditorFramework: DropdownEditor,
                    cellEditorParams: {
                        width: 150,
                        getList: this.getSeasonIntroduced.bind(this),
                    },

                },
            ],

            overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Loading</span>',
            overlayNoRowsTemplate:
            '<span style="padding: 10px; font-size: 28px; font-weight:\'lighter\' ; '
            + 'color: #525E61;">Please Select filter or alert to proceed</span>',

            season: null,
            channel: null,
            storeGroup: null,
            core: null,
            seasonIntroduced: null,

            showDeleteConfirmationBox: false,
            deleteConfirm: false,
        };

        this.showDeleteConfirmationBox = this.showDeleteConfirmationBox.bind(this);
        this.hideDeleteConfirmationBox = this.hideDeleteConfirmationBox.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
    }

    componentWillMount() {
        console.log('call api to get all the dropdown values for seasons update popup');

        if (this.props.node.data.productId.includes('SP')) {
            NotificationManager({ message: 'Please select a child record', type: 4 });

            return;
        }

        if (this.props.node.data.productId.includes('IN')) {
            NotificationManager({ message: 'Please select a child record', type: 4 });

            return;
        }

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        axios({
            method: 'get',
            url: require('../common/configurations.js').product2.getWESeasonsData + '?prodId=' + this.props.node.data.productId,
            headers: headers,
        })
            .then((response) => {
                console.log('we-seasons get all data: ', response.data);

                const res = response.data;

                if (res.status === 'success') {
                    const dropdowns = res.responseData.dropdown;
                    const season = dropdowns.season;
                    const channel = dropdowns.channel;
                    const storeGroup = dropdowns['store-group'];
                    const core = dropdowns.core;
                    const seasonIntroduced = dropdowns['season-introduced'];
                    const rowData = res.responseData.data;

                    this.setState({
                        season, channel, storeGroup, core, seasonIntroduced, rowData,
                    });

                    return;
                } else {
                    NotificationManager({ message: 'Failed to load Grouped Data in', type: 4 });

                    return;
                }
            })
            .catch((error) => {
                NotificationManager({ message: 'Failed to Load Seasons Dropdowns and Data: ' + error, type: 4 });

                return;
            });
    }

    showDeleteConfirmationBox() {
        this.setState({ showDeleteConfirmationBox: true });
    }

    hideDeleteConfirmationBox() {
        this.setState({ showDeleteConfirmationBox: false });
    }

    /**
     * return the seasonsList requested by the season column for the grid.
     * @return {Array} return seasonsList array
     */
    getSeasonsList() {
        return this.state.season;
    }

    /**
     * return the channelsList requested by channel column for the grid.
     * @return {Array} return channelsList array
     */
    getChannelsList() {
        return this.state.channel;
    }

    /**
     * return the store group ranking requested by store group column for the grid.
     * @return {Array} return store group ranking array
     */
    getStoreGroupRanking() {
        return this.state.storeGroup;
    }

    /**
     * return the core requested by core column for the grid.
     * @return {Array} return core array
     */
    getCore() {
        return this.state.core;
    }

    /**
     * return the core requested by core column for the grid.
     * @return {Array} return core array
     */
    getSeasonIntroduced() {
        return this.state.seasonIntroduced;
    }

    /**
     * Comes with AG grid used to set a cell to be a popup or not
     * @return {boolean}
     */
    isPopup() {
        return true;
    }

    /**
     * Gets the value when the component unmounts and pushes it for render
     * @return {string} cell value
     */
    getValue() {
        return '';
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    addRow() {
        const newData = {
            'season': '',
            'channel': '',
            'storeGroupRanking': '',
            'rtlRank': '',
            'rtlCollection': '',
            'rtlStoreCount': '',
            'rtlSkuCount': '',
            'dtcRank': '',
            'dtcCollection': '',
            'dtcStoreCount': '',
            'dtcSkuCount': '',
            'packShown': '',
            'rtcNotes': '',
            'core': '',
            'seasonIntroduced': '',
        };

        this.gridApi.updateRowData({ add: [newData] });
        const rowData = this.state.rowData;
        rowData.push(newData);

        this.setState({ rowData });
    }

    deleteRow() {
        this.hideDeleteConfirmationBox();
        const selectedData = this.gridApi.getSelectedRows();
        const res = this.gridApi.updateRowData({ remove: selectedData });
        const rowData = [];
        this.gridApi.forEachNode((node) => rowData.push(node.data));

        const updateData = {};
        updateData.productId = this.props.node.data.productId;
        updateData.data = rowData;

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        axios({
            method: 'post',
            url: require('../common/configurations.js').product2.addOrUpdateSeasonPopup,
            headers: headers,
            data: updateData,
        })
            .then((response) => {
                console.log('we-seasons get all data: ', response.data);

                const res = response.data;

                if (res.status === 'success') {
                    NotificationManager({ message: 'Seasons Data deleted successfully', type: 1 });

                    return;
                } else {
                    NotificationManager({ message: 'Failed to delete Seasons Data', type: 4 });

                    return;
                }
            })
            .catch((error) => {
                NotificationManager({ message: 'Failed to Delete Seasons Data at API: ' + error, type: 4 });

                return;
            });
    }

    update() {
        const rowData = [];
        const seasonsList = this.state.season;

        this.gridApi.forEachNode((node) => {
            const data = node.data;
            console.log('data.season', data.season);
            const sList = seasonsList.filter((obj) => obj.name === data.season);
            data.season = sList[0].id;
            rowData.push(data);
        });

        const updateData = {};
        updateData.productId = this.props.node.data.productId;
        updateData.data = rowData;

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        axios({
            method: 'post',
            url: require('../common/configurations.js').product2.addOrUpdateSeasonPopup,
            headers: headers,
            data: updateData,
        })
            .then((response) => {
                console.log('we-seasons get all data: ', response.data);

                const res = response.data;

                if (res.status === 'success') {
                    NotificationManager({ message: 'Seasons Data updated successfully', type: 1 });

                    return;
                } else {
                    NotificationManager({ message: 'Failed to update Seasons Data', type: 4 });

                    return;
                }
            })
            .catch((error) => {
                NotificationManager({ message: 'Failed to Update Seasons Data at API: ' + error, type: 4 });

                return;
            });
    }

    render() {
        console.log('West Elm seasons editor: ', this.state, this.props);

        return (
            <div>
                {!this.state.showDeleteConfirmationBox &&
                <div className="seasons-modal-container">
                    <div className={this.state.showDeleteConfirmationBox ? 'seasons-modal-holder-disabled' : 'seasons-modal-holder'}>
                        <div className="modal-header">
                            Season Update
                            <span className="close" onClick={() => this.props.api.stopEditing()}>x</span>
                        </div>
                        <div className='modal-content'>
                            <input type='button' hidden />
                            <div
                                id="myGrid"
                                style={{
                                    boxSizing: 'border-box',
                                    height: '100%',
                                    width: "100%",
                                    marginLeft: '1px',
                                }}
                                className="ag-theme-balham"
                            >
                                <AgGridReact
                                    columnDefs={this.state.columnDefs}
                                    components={this.state.components}
                                    rowData={this.state.rowData}
                                    onGridReady={this.onGridReady.bind(this)}
                                    rowSelection={'multiple'}
                                    stopEditingWhenGridLosesFocus={true}
                                    overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                                    overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                                    singleClickEdit={true}
                                    enterMovesDown={true}
                                    enterMovesDownAfterEdit={true}
                                />
                                <br></br>
                            </div>
                            <div>
                                <input type='button'
                                    value='Cancel'
                                    onClick={() => {
                                        this.props.api.stopEditing();
                                    }} /> &nbsp;&nbsp;
                                <input type='button'
                                    value='Delete'
                                    onClick={this.showDeleteConfirmationBox} />
                                <div style={{ 'float': 'right' }}>
                                    <input type='button' value='Add' onClick={this.addRow.bind(this)} /> &nbsp;&nbsp;
                                    <input type='button' value='Update' onClick={this.update.bind(this)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                { this.state.showDeleteConfirmationBox &&
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'rgba(0, 0, 0, 0.5)',
                    overflowY: 'auto',
                }}>
                    <div style={{
                        backgroundColor: 'white',
                        width: '400px',
                        margin: '15% auto',
                        fontSize: '14px',
                        textAlign: 'center',
                        padding: '15px',
                        border: '1px solid black',
                        borderRadius: '2px',
                    }}>
                        <h3 style={{
                            fontFamily: 'Roboto',
                            fontWeight: 400,
                            fontSize: '24px',
                            textAlign: 'center',
                            color: '#525E61',
                        }}>
                            Are you sure you want to delete the product(s)?
                        </h3>
                        <div style={{
                            margin: '3.3rem 0 1rem',
                            textAlign: 'center',
                        }}>
                            <input style={{
                                fontFamily: 'Roboto',
                                fontWeight: 300,
                                fontSize: '1.4rem',
                                textTransform: 'uppercase',
                                cursor: 'pointer',
                                padding: '1rem 1.2rem',
                                background: 'white',
                                borderRadius: '2px'
                            }}
                            type='button'
                            value='No'
                            onClick={this.hideDeleteConfirmationBox} />
                            &nbsp;&nbsp;&nbsp;
                            <input style={{
                                fontFamily: 'Roboto',
                                fontWeight: 300,
                                fontSize: '1.4rem',
                                textTransform: 'uppercase',
                                cursor: 'pointer',
                                padding: '1rem 1.2rem',
                                marginLeft: '1.5rem',
                                background: 'white',
                            }}
                            type='button'
                            value='Yes'
                            onClick={this.deleteRow} />
                        </div>
                    </div>
                </div>
                }
            </div>
        );
    }
}
