import React from 'react';
import RightDrawerComponent from './rightDrawerComponent';

import { connect } from 'react-redux';
import * as actions from '../publication/redux/publicationActions';
import axios from 'axios';
import './rightDrawer.css';
import { ProductServices } from '../product2/services/product-services';

var utils = require('../common/utils.js');

import NotificationManager from '../common/components/notification';
import PresentationServices from '../publication/services/presentation-services';
import PublicationServices from '../publication/services/publication-services';

class RightDrawer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isAddToListDisabled: false,
            selectedPresentationNames: [],
            selectedListData: [],
            isItemsinList: false,
            currentListName: '',
            currentListId:'',
            isSaveListModalOpen: false,
            isDropdownopen: false,
            isConfirmOverideModalOpen: false,
            modalName: '',
            confirmModalName: '',
            hideConfirmSection: true,
            isNewList: true,
            isClearAll: false,
            override: false,
            arrPdfFormats: [],

            bListSelected: false,
            oAddedProducts: [],
            disableActivateOnWebsite: false,
            bResetPanel: false,
            bClearOverride: false,
            isCancelModalOpen: false,
            loadAccordion: false,
            noFormat: false,
            cacheState: false,
            beforeEditListName: '',
            beforeEditListId: '',

            categoriesList: null,
            buyersList: null,
            channelsList: null,
            colorsList: null,
            sampleRecepientsList: null,
            seasonsList: null,
            sizeList: null,
            variantsList: null,
            vendorsList: null,
            isClearListModalOpen: false,
        };

        this.pdfFormats = [];
        this.isCacheList = false;
        this.addToList = this.addToList.bind(this);
        this.openListNameModal = this.openListNameModal.bind(this);
        this.openOverwritePopup = this.openOverwritePopup.bind(this);
        this.clearList = this.clearList.bind(this);
        this.saveListName = this.saveListName.bind(this);
        this.closeListNameModal = this.closeListNameModal.bind(this);
        this.closeConfirmOverideModal = this.closeConfirmOverideModal.bind(this);
        this.buildListData = this.buildListData.bind(this);
        this.finalSaveListName = this.finalSaveListName.bind(this);
        this.handleListName = this.handleListName.bind(this);
        this.finalClearList = this.finalClearList.bind(this);
        this.goToPresentationGrid = this.goToPresentationGrid.bind(this);
        this.resetLocalStorage = this.resetLocalStorage.bind(this);
        this.generateCsvForGrid = this.generateCsvForGrid.bind(this);
        this.buildCreativeCSVdata = this.buildCreativeCSVdata.bind(this);
        this.activateItemsOnWebsite = this.activateItemsOnWebsite.bind(this);
        this.getPdfFormats = this.getPdfFormats.bind(this);
        this.generateMarketingPDF = this.generateMarketingPDF.bind(this);
        this.serviceForPDF = this.serviceForPDF.bind(this);
        this.reportPdfType = this.reportPdfType.bind(this);
        this.resetPanel = this.resetPanel.bind(this);
        this.updateActivateOnWebsiteButton = this.updateActivateOnWebsiteButton.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.generateProductPDF = this.generateProductPDF.bind(this);
        this.clearAll = this.clearAll.bind(this);
        this.clrFlag = this.clrFlag.bind(this);
        this.openClearListModal = this.openClearListModal.bind(this);
        this.closeClearListModal = this.closeClearListModal.bind(this);
        this.exportFileForMagento = this.exportFileForMagento.bind(this);
    }

    componentWillMount() {
        ProductServices.getProductDropdown()
            .then( (response) => {
                const res = response.responseData;
                let categoriesList = res.catId;
                let buyersList = res.buyer;
                let channelsList = res.channel;
                let colorsList = res.color;
                let sampleRecepientsList = res.sampleRecepient;
                let seasonsList = res.season;
                let sizeList = res.size;
                let variantsList = res.variants;
                let vendorsList = res.vendorId;

                this.setState({
                    categoriesList,
                    buyersList,
                    channelsList,
                    colorsList,
                    sampleRecepientsList,
                    seasonsList,
                    sizeList,
                    variantsList,
                    vendorsList
                });
            })
            .catch( error => {
                NotificationManager({ message: error.message || "Unexpected error, Data didn't load", type: 4 });
            });
    }

    componentDidMount() {
        this.getPdfFormats();
        this.updateActivateOnWebsiteButton();
    }

    componentWillReceiveProps(nextProps) {
        // if you jump from prod to pub page with selected rows and then
        // click on save the list the popup will open only if it sees isItemsInList is true
        if (nextProps.prodNames !== this.props.prodNames) {
            let oProducts = nextProps.prodNames;

            let names = oProducts.map(function (obj) {
                return obj.name;
            });

            if (oProducts.length > 0) {
                this.setState({
                    selectedPresentationNames: names,
                    isItemsinList: true,
                    oAddedProducts: oProducts,
                });
            } else {
                this.setState({ isItemsinList: false });
            }
        }

        if (nextProps.currentListId) {
            this.setState({
                currentListId: nextProps.currentListId,
                currentListName: nextProps.currentListName,
                isItemsinList: true
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let _this = this;

        if (prevProps.selectedRows && this.props.selectedRows) {
            if ((prevProps.selectedRows.length != this.props.selectedRows.length) || (prevProps.checkedPubRows !== _this.props.checkedPubRows)) {
                _this.updateActivateOnWebsiteButton();
            }
        }

        if (this.props.pageType != prevProps.pageType) {
            this.getPdfFormats();
        }

        if ($('.presentation-drawer').hasClass('presentation-drawer-open')) {
            $('.right-content-container').addClass('presentation-drawer-open');
            $('.content-wrapper ').addClass('presentation-drawer-open');
        } else {
            $('.right-content-container').removeClass('presentation-drawer-open');
            $('.content-wrapper ').removeClass('presentation-drawer-open');
        }
    }

    toggleRightDrawer() {
        this.props.toggleRightDrawer();
    }

    clrFlag(flag) {
        this.setState({bResetPanel:flag});
    }

    resetPanel() {
        let _this = this;
        let resetPanelState = _this.state.bResetPanel;

        this.props.addPublicationGridData([]);
        _this.props.publicationCounter();

        _this.setState({
            selectedPresentationNames: [],
            isItemsinList: false,
            oAddedProducts: [],
            bResetPanel: !resetPanelState,
            selectedListData: []
        });
    }

    addToList() {
        let _this = this;
        let selectedRows = _this.props.selectedRows;

        if (_this.props.selectedRows.length > 0) {
            let addedProducts = [];

            //appending to list if any items already
            if (_this.state.oAddedProducts.length > 0) {
                addedProducts = _this.state.oAddedProducts;
            }

            let repeatProducts = [];
            let productsToAdd = addedProducts;
            let addedProductsLength = addedProducts.length;

            selectedRows.map((row, i) => {
                let objId = row.parentProductId || row.intProdId || row.chldProuctId;
                let match = false;

                for (let i = 0;i < addedProductsLength;i++) {
                    if (objId == addedProducts[i].id) {
                        match = true;

                        break;
                    }
                }

                if (!match) {
                    let obj = {};
                    obj.id = objId;
                    obj.name = row.prodName;

                    productsToAdd.push(obj);
                } else {
                    repeatProducts.push(row.prodName);
                }
            });

            let selectedPresentationNames = productsToAdd.map(function (obj) { return obj.name; });
            let isItemsinList = selectedPresentationNames.length > 0 ? true : false;

            _this.setState({
                selectedPresentationNames,
                isItemsinList,
                selectedListData: selectedRows,
                oAddedProducts: productsToAdd
            });
        } else {
            NotificationManager({ message: "Please select a record from the grid", type: 4 });
        }
    }

    openListNameModal() {
        let _this = this;

        _this.setState({ beforeEditListName: _this.props.currentListName });
        _this.setState({ beforeEditListId: _this.props.currentListId });
        _this.setState({ modalName: 'ListNameModal', isSaveListModalOpen: true });

        if (_this.props.currentListName != "") {
            _this.setState({
                currentListName: _this.props.currentListName,
                isDropdownopen: true
            });
        }
    }

    clearList() {
        let _this = this;

        if ($('.selected-presentation').text() == "Select List") {
            _this.setState({ bListSelected: false, isDropdownopen: false });
        } else {
            _this.setState({ bListSelected: true, isDropdownopen: true });
        }

        _this.setState({ isClearAll: true });
        _this.openListNameModal();

        // Setting this field to Override the list completely with the new list items
        _this.setState({ bClearOverride: true });
    }

    finalClearList() {
        let _this = this;

        _this.resetPanel();

        this.closeListNameModal();

        this.closeClearListModal();
    }

    saveListName() {
        let listName = this.state.currentListName.trim();

        this.setState({ isSaveListModalOpen: false });

        if (listName.trim() !== "") {
            if (listName.length > 50) {
                NotificationManager({ message: "Please shorten the list name to less than 50 characters", type: 4 });

                return false;
            }

            this.buildListData();
        } else {
            NotificationManager({ message: "Please enter a name for this list", type: 4 });
        }
    }

    closeListNameModal() {
        let _this = this;
        _this.setState({ isSaveListModalOpen: false, currentListName: "" });
        _this.closeConfirmOverideModal();

        if (_this.state.isClearAll) {
            _this.setState({ isClearAll: false });
        }

        // Unsetting clear override field
        _this.setState({ bClearOverride: false });
    }

    buildListData() {
        var _this = this;

        let listName = this.state.currentListName.trim();
        let groupName = this.props.pageType;

        // checking if list name already exists.
        PublicationServices.checkListName(null, "?listName=" + listName + "&groupName=" + groupName).then( (response) => {
            let res = response;

            if (res.responseCode === 200) {
                if (res.status) {
                    _this.finalSaveListName();
                } else {
                    _this.openOverwritePopup();
                }
            } else {
                NotificationManager({ message: res.responseMessage, type: 4 });
                _this.closeListNameModal();
            }
        })
            .catch( error => {
                NotificationManager({ message: "Exception while checking list name", type: 4 });

                _this.closeListNameModal();
            });
    }

    finalSaveListName() {
        let _this = this;
        let postdata = {};
        let rowdata;
        let publicationList = [];

        postdata.userId = this.props.user.userId;

        if (_this.state.override) {
            if (!_this.state.isDropdownopen) { //checking override scenario
                let pData = this.props.pubListDropdownData;

                if (pData.length > 0) {
                    let filtered = {};

                    pData.map(function (oList) {
                        if (oList.listName == _this.state.currentListName.trim()) {
                            filtered = oList;
                        }
                    });

                    let selectedData = _this.state.selectedListData;

                    filtered.publicationList = [];

                    publicationList = selectedData.map((row, i) => {
                        var listObj = {};
                        listObj.productPubId = '';
                        listObj.displayOrder = i + 1;
                        listObj.featureColor = '';
                        listObj.comment_1 = '';
                        listObj.comment_2 = '';
                        listObj.dataId = row.productId;
                        listObj.productDataDto = {};

                        let pObj = {};
                        pObj.parentRef = row.parentRef ? row.parentRef : "";
                        pObj.dataLevel = row.dataLevel ? row.dataLevel : "";
                        pObj.chldProuctId = row.chldProuctId ? row.chldProuctId : [];
                        pObj.productId = row.productId ? row.productId : "";

                        listObj.productDataDto = Object.assign({}, pObj);

                        return listObj;
                    });

                    filtered.publicationList = publicationList;

                    rowdata = filtered;
                    rowdata.override = true;
                }

                postdata = rowdata;

                _this.setState({ override: false, isDropdownopen: false });
            } else {
                let prevName = _this.state.beforeEditListName;
                let prevId = _this.state.beforeEditListId;
                let pData = _this.props.pubListDropdownData;
                let filtered = {};

                if (pData.length > 0) {
                    if (prevName.toLowerCase().trim() === _this.state.currentListName.toLowerCase().trim()) {
                        pData.map(function (oList) {
                            if (oList.listName.toLowerCase() == _this.state.currentListName.toLowerCase().trim()) {
                                filtered = oList;
                                filtered.publishListId = oList.publishListId;
                            }
                        });
                    } else {
                        pData.map(function (oList) {
                            if (oList.publishListId == prevId) {
                                filtered = oList;
                            } else if (oList.listName.toLowerCase() == _this.state.currentListName.toLowerCase().trim()) {
                                filtered.publishListId = oList.publishListId;
                            }
                        });
                    }

                    //  checking if clear override is true and setting existing data accordingly
                    if (_this.state.bClearOverride) {
                        filtered.publicationList = [];
                    }

                    let oldRowData = [];

                    if (_this.props.currentListName) {
                        oldRowData = _this.props.publicationGridData;
                    }

                    let selectedData = _this.state.selectedListData;
                    let combined = oldRowData.concat(selectedData);
                    let existingData = filtered.publicationList;

                    combined.map(function (oData, i) {
                        let isMatch = false;

                        existingData.some(function (oExist) {
                            if (oData.productId == oExist.dataId) {
                                isMatch = true;
                            }
                        });

                        if (!isMatch) {
                            let newObj = {};
                            newObj.productPubId = '';
                            newObj.displayOrder = filtered.publicationList.length + 1;
                            newObj.featureColor = '';
                            newObj.comment_1 = '';
                            newObj.comment_2 = '';
                            newObj.dataId = oData.productId;

                            let pObj = {};
                            pObj.parentRef = oData.parentRef ? oData.parentRef : "";
                            pObj.dataLevel = oData.dataLevel ? oData.dataLevel : "";
                            pObj.chldProuctId = oData.chldProuctId ? oData.chldProuctId : [];
                            pObj.productId = oData.productId ? oData.productId : "";

                            newObj.productDataDto = Object.assign({}, pObj);

                            filtered.publicationList.push(newObj);
                        }
                    });

                    rowdata = filtered;
                    rowdata.override = true;
                    rowdata.listName = _this.state.currentListName.trim();
                }

                postdata = rowdata;

                _this.setState({ override: false, isDropdownopen: false });
            }
        } else {
            let oldRowData = [];

            if (_this.props.currentListName) {
                oldRowData = _this.props.publicationGridData;
            }

            let newRowData = _this.state.selectedListData;
            let combined = oldRowData.concat(newRowData);
            rowdata = combined.filter(function (item, pos) { return combined.indexOf(item) == pos; });

            postdata.publishListId = '';

            publicationList = rowdata.map((row, i) => {
                var listObj = {};
                listObj.productPubId = '';
                listObj.displayOrder = i + 1;
                listObj.featureColor = '';
                listObj.comment_1 = '';
                listObj.comment_2 = '';
                listObj.dataId = row.productId;
                listObj.productDataDto = {};

                let pObj = {};
                pObj.parentRef = row.parentRef ? row.parentRef : "";
                pObj.dataLevel = row.dataLevel ? row.dataLevel : "";
                pObj.chldProuctId = row.chldProuctId ? row.chldProuctId : [];
                pObj.productId = row.productId ? row.productId : "";

                listObj.productDataDto = Object.assign({}, pObj);

                return listObj;
            });

            postdata.publicationList = publicationList;
            postdata.listName = _this.state.currentListName.trim();
            postdata.groupName = _this.props.pageType;
        }

        if (!this.props.notification) {
            this.props.toggleNotification();
        }

        PublicationServices.saveListName(postdata).then( (response) => {
            let res = response;

            if (res.status) {
                NotificationManager({ message: 'List Successfully Created', type: 1 });

                //create new list successful. Store list name in redux and set listDirty to true
                _this.props.addSelectedPresentationName({ name: postdata.listName, id: res.responseMessage });
                _this.props.listDirty(!_this.props.isListDirty);
            } else {
                NotificationManager({ message: res.responseMessage, type: 4 });
            }
        })
            .catch( error => {
                NotificationManager({ message: error, type: 4 });
            });

        _this.closeListNameModal();
    }

    openOverwritePopup() {
        this.setState({ isSaveListModalOpen:true, hideConfirmSection: false, override: true});

        // Setting this field to Override the list completely with the new list items
        this.setState({ bClearOverride: true });
    }

    closeConfirmOverideModal() {
        this.setState({ hideConfirmSection: true, override: false });
    }

    handleListName(e) {
        this.setState({ currentListName: e.target.value });
    }

    goToPresentationGrid() {
        this.props.addPublicationGridData(this.props.selectedRows);
        this.props.publicationCounter();
        this.props.addPublicationColumnNames(this.props.creativeColumns);

        localStorage.setItem("currentListName", this.props.currentListName);
        localStorage.setItem("currentListId", this.props.currentListId);

        localStorage.setItem('goToPresentationGrid', 'true');

        this.props.setCurrentPage('publication');
    }

    resetLocalStorage() {
        localStorage.removeItem("currentListName");
        localStorage.removeItem("currentListId");
    }

    generateCsvForGrid() {
        let _this = this;

        if (_this.props.pageType == "product2") {
            _this.generateProductCSV();
        } else if (_this.props.pageType == "publication") {
            _this.generatePublicationCSV();
        } else if (_this.props.pageType == "creative") {
            _this.generateCreativeCSV();
        } else if (_this.props.pageType == "marketing") {
            _this.generateMarketingCSV();
        } else {
            return false;
        }
    }

    generateProductCSV() {
        let _this = this;
        let postdata = {};
        let rowdata = _this.props.selectedRows;
        let columnNames = [];
        let columnDetails = [];

        //select only visible column
        _this.props.productColumns.forEach(function(v, i) {
            if (v.visibility) {
                columnDetails.push(v);
            }
        });

        columnDetails.sort(function (a, b) {
            return a.order - b.order;
        });

        for (let i = 0; i < rowdata.length; i++) {
            for (let j = 0; j < columnDetails.length; j++) {
                if (typeof rowdata[i][columnDetails[j].fieldName] === 'undefined') {
                    rowdata[i][columnDetails[j].fieldName] = null;
                }
            }
        }

        columnDetails.map(function (col) {
            columnNames.push(col.fieldName);
        });

        postdata = _this.buildProductCSV(columnNames, rowdata);

        if (postdata)
            postdata.pageType = "product2";
        else
            return false;

        _this.serviceForCSV(postdata);
    }

    generatePublicationCSV() {
        let _this = this;
        let postdata = {};
        let columnNames = [];
        let columnDetails = _this.props.publicationColumns;

        columnDetails.map(function (col) {
            let status = $("#publicationGrid .jqx-grid").jqxTreeGrid('getColumnProperty', col.fieldName, 'hidden');

            if (!status) {
                columnNames.push(col.fieldName);
            }
        });

        let rowdata = _this.props.checkedPubRows;
        postdata = _this.buildDataCSV(columnNames, rowdata);

        if (postdata)
            postdata.pageType = "Publish";
        else
            return false;

        _this.serviceForCSV(postdata);
    }

    generateCreativeCSV() {
        let _this = this;
        let postdata = {};
        let columnNames = [];
        let columnDetails = _this.props.creativeColumns;

        columnDetails.map(function (col) {
            let status = $("#creativeGrid .jqx-grid").jqxGrid('getColumnProperty', col.fieldName, 'hidden');

            if (!status) {
                columnNames.push(col.fieldName);
            }
        });

        let rowdata = _this.props.cr_selectedRows;

        postdata = _this.buildCreativeCSVdata(columnNames, rowdata);

        if (postdata)
            postdata.pageType = "Creative";
        else
            return false;

        _this.serviceForCSV(postdata);
    }

    generateMarketingCSV() {
        let _this = this;
        let postdata = {};
        let columnNames = [];
        let columnDetails = _this.props.marketingColumns;

        columnDetails.map(function (col) {
            let status = $("#MarketingGrid .jqx-grid").jqxGrid('getcolumnproperty', col.fieldName, 'hidden');

            if (!status) {
                columnNames.push(col.fieldName);
            }
        });

        let rowdata = _this.props.mk_selectedRows;
        postdata = _this.buildDataCSV(columnNames, rowdata);

        if (postdata)
            postdata.pageType = "Events";
        else
            return false;

        _this.serviceForCSV(postdata);
    }

    buildCreativeCSVdata(columnNames, rowdata) {
        let postdata = {};
        let data = [];

        if (rowdata.length > 0) {
            rowdata.map(function (ob, i) {
                if (ob.parentId) {
                    let idata = [];

                    columnNames.map(function (params) {
                        if (!ob.hasOwnProperty(params)) {
                            if (params == "images") {
                                ob[params] = [];
                            } else {
                                ob[params] = "";
                            }
                        }
                    });

                    for (var property in ob) {
                        if (columnNames.indexOf(property) > -1) {
                            if (property == "images" && ob[property]) {
                                let strImgUrl = "";

                                ob[property].map(function (imgdata, i) {
                                    strImgUrl += imgdata.imageUrl;

                                    if (i + 1 < ob[property].length) {
                                        strImgUrl += ",";
                                    }
                                });

                                idata.push(strImgUrl);
                            } else {
                                idata.push(ob[property] ? ob[property].toString() : "");
                            }
                        }
                    }

                    data.push(idata);
                } else if (ob.id) {
                    let idata = [];

                    columnNames.map(function (params) {
                        if (!ob.hasOwnProperty(params)) {
                            if (params == "images") {
                                ob[params] = [];
                            } else {
                                ob[params] = "";
                            }
                        }
                    });

                    for (var property in ob) {
                        if (columnNames.indexOf(property) > -1) {
                            if (property == "images" && ob[property]) {
                                let strImgUrl = "";

                                ob[property].map(function (imgdata, i) {
                                    strImgUrl += imgdata.imageUrl;

                                    if (i + 1 < ob[property].length) {
                                        strImgUrl += ",";
                                    }
                                });

                                idata.push(strImgUrl);
                            } else {
                                idata.push(ob[property] ? ob[property].toString() : "");
                            }
                        }
                    }

                    data.push(idata);

                    if (ob.picturePacketDTOs) {
                        if (ob.picturePacketDTOs.length > 0) {
                            let cdata = [];
                            let children = ob.picturePacketDTOs;

                            children.map(function (child, j) {
                                if (child.checked) {
                                    columnNames.map(function (params) {
                                        if (!ob.hasOwnProperty(params)) {
                                            if (params == "images") {
                                                ob[params] = [];
                                            } else {
                                                ob[params] = "";
                                            }
                                        }
                                    });

                                    for (var property in ob) {
                                        if (columnNames.indexOf(property) > -1) {
                                            if (property == "images") {
                                                let strImgUrl = "";

                                                ob[property].map(function (imgdata, i) {
                                                    strImgUrl += imgdata.imageUrl;

                                                    if (i + 1 < ob[property].length) {
                                                        strImgUrl += ",";
                                                    }
                                                });

                                                cdata.push(strImgUrl);
                                            } else {
                                                cdata.push(ob[property] ? ob[property].toString() : "");
                                            }
                                        }
                                    }

                                    data.push(cdata);
                                }
                            });
                        }
                    }
                } else {
                    let isNew = false;

                    data.map(function (ddata, k) {
                        if (ddata.parentId != ob.productId) {
                            isNew = true;
                        }
                    });

                    if (isNew) {
                        columnNames.map(function (params) {
                            if (!ob.hasOwnProperty(params)) {
                                if (params == "images") {
                                    ob[params] = [];
                                } else {
                                    ob[params] = "";
                                }
                            }
                        });

                        let idata = Array(columnNames.length).join(".").split(".");

                        for (var property in ob) {
                            if (columnNames.indexOf(property) > -1) {
                                if (property == "images") {
                                    let strImgUrl = "";

                                    ob[property].map(function (imgdata, i) {
                                        strImgUrl += imgdata.imageUrl;

                                        if (i + 1 < ob[property].length) {
                                            strImgUrl += ",";
                                        }
                                    });

                                    idata.push(strImgUrl);
                                } else {
                                    idata.push(ob[property] ? ob[property].toString() : "");
                                }
                            }
                        }

                        if (idata)
                            data.push(idata);
                    }
                }
            });

            postdata.columnNames = columnNames;
            postdata.data = data;

            return postdata;
        } else {
            NotificationManager({ message: "Please select atleast one record in the grid", type: 4 });

            return false;
        }
    }

    buildDataCSV(columnNames, rowdata) {
        let data = [];
        let postdata = {};

        if (rowdata.length > 0) {
            rowdata.map(function (ob, i) {
                let idata = [];

                columnNames.map(function (params) {
                    if (ob.hasOwnProperty(params)) {
                        if (params == "images" && ob[params]) {
                            let strImgUrl = "";
                            let imgArr = [];

                            ob['images'].digitalAssetInfos && ob['images'].digitalAssetInfos.map(function (imgdata, i) {
                                strImgUrl = imgdata.baseUrl + imgdata.digitalAssetSummary.thumbnailImage;
                                imgArr.push(strImgUrl);
                            });

                            idata.push(imgArr.toString());
                        } else {
                            idata.push(ob[params] ? ob[params].toString() : "");
                        }
                    } else {
                        idata.push("");
                    }
                });

                data.push(idata);

                postdata.columnNames = columnNames;
                postdata.data = data;
            });

            return postdata;
        } else {
            NotificationManager({ message: "Please select at least one record to Export to File", type: 4 });

            return false;
        }
    }

    buildProductCSV(columnNames, rowdata) {
        let _this = this;
        let data = [];
        let postdata = {};

        if (rowdata.length > 0) {
            rowdata.map(function (ob, i) {
                let idata = [];

                columnNames.map(function (params, index) {
                    if (ob.hasOwnProperty(params)) {
                        if (params == "images" && ob[params]) {
                            let strImgUrl = "";
                            let imgArr = [];

                            ob['images'].digitalAssetInfos && ob['images'].digitalAssetInfos.map(function (imgdata, i) {
                                strImgUrl = imgdata.baseUrl + imgdata.digitalAssetSummary.thumbnailImage;
                                imgArr.push(strImgUrl);
                            });

                            idata.push(imgArr.toString());
                        } else if (params == "department") {
                            _this.state.categoriesList.map(function (depObj, i) {
                                if (ob['department'] == depObj.fieldId) {
                                    idata.push(depObj.fieldName);
                                }
                            });
                        } else if (params == "classColumn") {
                            _this.state.categoriesList.map(function (depObj, i) {
                                if (ob['classColumn'] == depObj.fieldId) {
                                    idata.push(depObj.fieldName);
                                }
                            });
                        } else if (params == "subClassColumn") {
                            _this.state.categoriesList.map(function (depObj, i) {
                                if (ob['subClassColumn'] == depObj.fieldId) {
                                    idata.push(depObj.fieldName);
                                }
                            });
                        } else if (params == "division") {
                            _this.state.categoriesList.map(function (divObj, i) {
                                if (ob['vendorId'] == divObj.fieldId) {
                                    idata.push(divObj.fieldName);
                                }
                            });
                        } else if (params == "catId") {
                            _this.state.categoriesList.map(function (catObj, i) {
                                if (ob['catId'] == catObj.fieldId) {
                                    idata.push(catObj.fieldName);
                                }
                            });
                        } else if (params == "vendorId") {
                            _this.state.vendorsList.map(function (venObj, i) {
                                if (ob['vendorId'] == venObj.fieldId) {
                                    idata.push(venObj.fieldName);
                                }
                            });
                        } else {
                            if (ob[params] || ob[params] === 0 || typeof ob[params] === 'boolean') {
                                idata.push(String(ob[params]));
                            } else {
                                idata.push('');
                            }
                        }
                    }
                });

                data.push(idata);

                postdata.columnNames = columnNames;
                postdata.data = data;
            });

            return postdata;
        } else {
            NotificationManager({ message: "Please select at least one record to Export to File", type: 4 });

            return false;
        }
    }

    serviceForCSV(postdata) {
        PublicationServices.generateCSV(postdata).then( (response) => {
            let res = response;

            if (res.responseCode === 200) {
                NotificationManager({ message: res.responseMessage, type: 1 });

                if (res.data) {
                    utils.openInNewTab(res.data);
                }
            } else {
                NotificationManager({ message: res.responseMessage, type: 4 });
            }
        })
            .catch( error => {
                NotificationManager({ message: error, type: 4 });
            });
    }

    activateItemsOnWebsite(event) {
        let req = [];
        let tempArray = [];

        if (this.props.pageType !== 'product2' && this.props.pageType !== 'publication') {
            return false;
        }

        let productList, unique;

        if (this.props.pageType === 'product2') {
            if (this.props.selectedRows.length === 0) {
                NotificationManager({ message: 'Please select products from the grid to activate on website.', type: 4 });

                return false;
            }

            productList = this.props.selectedRows;

            productList.forEach((item, index) => {
                if (item.dataLevel == "PARENT") {
                    tempArray.push(item.productId);
                }
            });

            tempArray.forEach((id, index) => {
                let tempObj = { "id": id };
                req.push(tempObj);
                //Update Active Live flag
            });
        } else {
            let nonSkuProducts = [];

            if (this.props.checkedPubRows.length === 0) {
                NotificationManager({ message: 'Please select products from the grid to activate on website.', type: 4 });

                return false;
            }

            productList = this.props.checkedPubRows;

            productList.forEach((item, index) => {
                if (item.dataLevel === "PARENT") {
                    req.push(item.productId);
                } else {
                    nonSkuProducts.push(item.productId);
                }
            });

            if (nonSkuProducts.length !== 0) {
                NotificationManager({ message: 'Only the Parent level products can be pushed to live.', type: 4 });

                return false;
            }
        }

        let _this = this;

        if (req.length !== 0) {
            ProductServices.activateOnWebsite(req)
                .then( (response) => {
                    let res = response.data;

                    if (res.responseCode === 200) {
                        NotificationManager({ message: res.responseMessage, type: 1 });
                    } else if (res.responseCode === 150) {
                        _this.prodList = res.inventoryFailedLst;
                        _this.setState({ isCancelModalOpen: true });
                    } else {
                        NotificationManager({ message: res.responseMessage, type: 4 });
                    }
                })
                .catch( error => {
                    NotificationManager({ message: 'Some error Occurred. Please try again.', type: 4 });
                });
        } else {
            NotificationManager({ message: 'Please select products from the grid to activate on website.', type: 4 });
        }
    }

    modalClose(key) {
        switch (key) {
            case 'no':
                this.setState({ isCancelModalOpen: false });
                break;

            case 'yes':
                let req = this.prodList;

                ProductServices.forceActivateOnWebsite(req)
                    .then( (response) => {
                        let res = response.data;

                        if (res.responseCode === 200) {
                            NotificationManager({ message: res.responseMessage, type: 1 });
                        } else {
                            NotificationManager({ message: res.responseMessage, type: 4 });
                        }
                    })
                    .catch( error => {
                        NotificationManager({ message: 'Some error Occurred. Please try again.', type: 4 });
                    });

                this.setState({ isCancelModalOpen: false });

                break;
        }
    }

    updateActivateOnWebsiteButton() {
        let _this = this;

        if (_this.props.pageType == "product2" && this.props.selectedRows) {
            if ((this.props.selectedRows.length == 0)) {
                $('.panel[data-menu_id="activateItemsOnWebsite"] .panel-heading h4 a').addClass('anchorDisabled');
            } else {
                $('.panel[data-menu_id="activateItemsOnWebsite"] .panel-heading h4 a').removeClass('anchorDisabled');
            }
        } else if (_this.props.pageType == "publication") {
            if (_this.props.checkedPubRows.length == 0) {
                $('.panel[data-menu_id="activateItemsOnWebsite"] .panel-heading h4 a').addClass('anchorDisabled');
            } else {
                $('.panel[data-menu_id="activateItemsOnWebsite"] .panel-heading h4 a').removeClass('anchorDisabled');
            }
        } else {
            $('.panel[data-menu_id="activateItemsOnWebsite"] .panel-heading h4 a').addClass('anchorDisabled');
        }
    }

    getPdfFormats() {
        let _this = this;
        let page = "";
        let roleId = this.props.user.defaultRole;

        if (_this.props.pageType == "product2" || _this.props.pageType == "publication") {
            page = "Report";
        } else if (_this.props.pageType == "creative") {
            page = "creative";
        } else if (_this.props.pageType == "marketing") {
            page = "marketing";
        }

        PresentationServices.listReports("?defaultRole=" + roleId + "&report=" + page).then( (response) => {
            let res = response;

            let formats = [];

            if (res.status) {
                let resp = res.data.response;

                if (resp.length > 0) {
                    resp.map(function (format) {
                        let obj = {};
                        obj["title"] = format.reportName;
                        obj['formatId'] = format.reportID;
                        obj["menuId"] = "reportPdfType";

                        formats.push(obj);
                    });

                    _this.pdfFormats = formats;

                    _this.setState({ arrPdfFormats: formats, loadAccordion: true });
                } else {
                    formats = [{ title: "No Formats Available" }];

                    _this.setState({ arrPdfFormats: formats, loadAccordion: true, noFormat: true });
                }
            } else {
                formats = [{ title: res.responseMessage }];

                _this.setState({ arrPdfFormats: formats, loadAccordion: true, noFormat: true });
            }
        }).catch( error => {
            console.log('rightDrawer getPdfFormats  API call failed', error);
        });
    }

    reportPdfType() {
        let _this = this;

        if (!_this.state.noFormat) {
            if (_this.props.pageType == "product2") {
                _this.generateLaunchPlan();
            } else if (_this.props.pageType == "publication") {
                _this.generatePublicationPDF();
            } else if (_this.props.pageType == "creative") {
                _this.generateCreativePDF();
            } else if (_this.props.pageType == "marketing") {
                _this.generateMarketingPDF();
            } else {
                return false;
            }
        }
    }

    generateLaunchPlan() {
        let productRows = this.props.selectedRows;

        if (productRows.length == 0) {
            NotificationManager({ message: "Please select at least one record to Report to PDF", type: 4 });

            return;
        }

        if (productRows.length > 1) {
            NotificationManager({ message: "Please select a single record to generate a Launch Plan", type: 4 });

            return;
        }

        const prodId = productRows[0].productId;

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        axios({
            method: 'get',
            url: require('../common/configurations.js').product2.generateLaunchPlan + prodId,
            headers: headers,
        }).then( (response) => {
            let res = response.data;

            if (res.status == "success") {
                utils.openInNewTab(res.responseData.awsS3PdfUrl);
                NotificationManager({ message: "Created " + res.responseData.ReportType, type: 1 });
            }
        }).catch( error => {
            console.error('rightDrawer Launch Plan  API call failed', error);
        });
    }

    generateProductPDF() {
        let _this = this;
        let postdata = {};
        let list = [];
        let productRows = _this.props.selectedRows;

        var repId;
        this.pdfFormats.map(function (format) {
            repId = format.formatId;
        });

        if (productRows.length > 0) {
            productRows.map(function (rowdata) {
                list.push(rowdata.productId);
            });

            postdata['type'] = _this.props.pageType;
            postdata['list'] = list;
            postdata['reportId'] = parseInt(repId);
            postdata['roleId'] = this.props.user.defaultRole;
            postdata['userName'] = this.props.user.userName;
            postdata['uniqueID'] = (Math.floor(Math.random() * (999 - 1 + 1)) + 1) + this.props.user.defaultRole + this.props.user.email;

            _this.serviceForPDF(postdata);
        } else {
            NotificationManager({ message: "Please select at least one record to Report to PDF", type: 4 });
        }
    }

    generatePublicationPDF() {
        let _this = this;
        let postdata = {};
        let list = [];
        let productRows = _this.props.checkedPubRows;
        var repId;

        this.pdfFormats.map(function (format) {
            repId = format.formatId;
        });

        if (productRows.length > 0) {
            productRows.map(function (rowdata) {
                list.push(rowdata.productId);
            });

            postdata['type'] = "product";
            postdata['list'] = list;
            postdata['reportId'] = parseInt(repId);
            postdata['roleId'] = this.props.user.defaultRole;
            postdata['userName'] = this.props.user.userName;
            postdata['uniqueID'] = (Math.floor(Math.random() * (999 - 1 + 1)) + 1) + this.props.user.defaultRole + this.props.user.defaultRole + this.props.user.email;

            _this.serviceForPDF(postdata);
        } else {
            NotificationManager({ message: "Please select at least one record to Report to PDF", type: 4 });
        }
    }

    generateCreativePDF() {
        let _this = this;
        let postdata = {};
        let list = [];
        let creativeRows = _this.props.cr_selectedRows;

        var repId;
        this.pdfFormats.map(function (format) {
            repId = format.formatId;
        });

        if (creativeRows.length > 0) {
            creativeRows.map(function (rowdata) {
                if (rowdata.productId) {
                    list.push(rowdata.productId);
                }
            });

            postdata['type'] = _this.props.pageType;
            postdata['list'] = list;
            postdata['reportId'] = parseInt(repId);
            postdata['roleId'] = this.props.user.defaultRole;
            postdata['userName'] = this.props.user.userName;
            postdata['uniqueID'] = "test";

            _this.serviceForPDF(postdata);
        } else {
            NotificationManager({ message: "Please select at least one record to Report to PDF", type: 4 });
        }
    }

    generateMarketingPDF() {
        let _this = this;
        let postdata = {};
        let list = [];
        let checkedEvents = _this.props.mk_selectedRows;
        var repId;

        this.pdfFormats.map(function (format) {
            repId = format.formatId;
        });

        if (checkedEvents.length > 0) {
            checkedEvents.map(function (rowdata) {
                if (rowdata.eventId) {
                    list.push(rowdata.eventId);
                }
            });

            postdata['type'] = _this.props.pageType;
            postdata['list'] = list;
            postdata['reportId'] = parseInt(repId);
            postdata['roleId'] = this.props.user.defaultRole;
            postdata['userName'] = this.props.user.userName;
            postdata['uniqueID'] = "test";

            _this.serviceForPDF(postdata);
        } else {
            NotificationManager({ message: "Please select at least one record to Report to PDF", type: 4 });
        }
    }

    serviceForPDF(postdata) {
        ProductServices.generatePDF(postdata).then( (response) => {
            let res = response;

            if (res.responseCode === 200) {
                if (res.data) {
                    if (res.data.filePath) {
                        utils.openInNewTab(res.data.filePath);
                    }

                    NotificationManager({ message: res.responseMessage, type: 1 });
                } else {
                    NotificationManager({ message: "PDF generation failed", type: 1 });
                }
            } else {
                NotificationManager({ message: res.responseMessage, type: 4 });
            }
        }).catch( error => {
            NotificationManager({ message: "PDF generation failed due to some technical reason", type: 4 });
        });
    }

    exportFileForMagento() {
        let prodIds = [];

        this.props.selectedRows.map((i) => {
            prodIds.push(i.productId);
        });

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        axios({
            method: 'post',
            url: require('../common/configurations.js').product2.exportToMagento,
            headers: headers,
            data: prodIds,
        }).then((response) => {
            if (response.status == 200) {
                let url = response.data.responseData;

                utils.openInNewTab(url);
            } else {
                NotificationManager({ message: response.data.message, type: 4 });
            }
        }).catch((err) => {});
    }

    clearAll() {
        this.setState({ isClearAll: false });
    }

    openClearListModal() {
        this.setState({ isClearListModalOpen: true, isSaveListModalOpen: false });
    }

    closeClearListModal() {
        this.setState({ isClearListModalOpen: false });
    }

    render() {
        let currentPage = this.props.pageType;
        let addToListButtonDisabledStatus = true;

        if (currentPage == 'product2' && this.props.selectedRows && this.props.selectedRows.length > 0) {
            addToListButtonDisabledStatus = false;
        }

        var presentationPanelAccordions = [
            {
                title: "Export to File",
                isToggle: false,
                isCollapsed: false,
                isTabActive: false,
                menuId: "exportFileFormatCSV",
                accordionOnly: true
            },
            {
                title: "Export for Magento",
                isToggle: false,
                isCollapsed: false,
                isTabActive: false,
                menuId: "exportFileForMagento",
                accordionOnly: true
            },
            {
                title: "Report to PDF",
                isToggle: true,
                content: this.state.arrPdfFormats,
                isCollapsed: false,
                isTabActive: false,
                menuId: "reportPdfType",
                accordionOnly: false,
                disabled: false,
            },
            {
                title: "Activate on Website Now",
                isToggle: false,
                isCollapsed: false,
                isTabActive: false,
                menuId: "activateItemsOnWebsite",
                accordionOnly: true,
                disabled: true,
            }
        ];

        let selectedPresentationNames = this.state.selectedPresentationNames;

        return (
            <RightDrawerComponent
                state = {this.state}
                props = {this.props}
                presentationPanelAccordions = {presentationPanelAccordions}
                selectedPresentationNames = {selectedPresentationNames}
                openListNameModal = { this.openListNameModal }
                clearList = { this.clearList }
                addToList= { this.addToList }
                finalClearList= { this.finalClearList }
                reportPdfType= { this.reportPdfType }
                activateItemsOnWebsite= { this.activateItemsOnWebsite }
                generateCsvForGrid= { this.generateCsvForGrid }
                goToPresentationGrid= { this.goToPresentationGrid }
                closeListNameModal= { this.closeListNameModal }
                handleListName= { this.handleListName }
                saveListName= { this.saveListName }
                closeConfirmOverideModal= { this.closeConfirmOverideModal }
                finalSaveListName= { this.finalSaveListName }
                clearAll= { this.clearAll }
                modalClose = { this.modalClose }
                clrFlag = {this.clrFlag}
                toggleRightDrawer = {this.toggleRightDrawer}
                openClearListModal = {this.openClearListModal}
                closeClearListModal = {this.closeClearListModal}
                addToListButtonDisabledStatus = {addToListButtonDisabledStatus}
                exportFileForMagento = {this.exportFileForMagento}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        publicationGridData: state.publicationGridData,
        prodNames: state.listItemNames,
        currentListName: state.selectedPresentationName.name,
        currentListId: state.selectedPresentationName.id,
        pubListDropdownData: state.pubListDropdownData,
        checkedPubRows: state.checkedPublicationRows,
        checkedCreativeRows: state.gridData,
        productColumns: state.columnDetails,
        publicationColumns: state.publicationColumnNames,
        creativeColumns: state.cr_columnDetails,
        marketingColumns: state.mk_columnDetails,
        isListDirty: state.isListDirty,
        selectedRows: state.product2SelectedRows,
        cr_selectedRows: state.cr_selectedRows,
        mk_selectedRows: state.mk_selectedRows,
    };
};

const mapDispatchToprops = (dispatch) => {
    return {
        addPublicationGridData: (data) => {
            dispatch(actions.addPublicationGridData(data));
        },
        publicationCounter: (data) => {
            dispatch(actions.publicationCounter());
        },
        addPublicationColumnNames: (data) => {
            dispatch(actions.addPublicationColumnNames(data));
        },
        listDirty: (data) => {
            dispatch(actions.updateListDirty(data));
        },
        addSelectedPresentationName: (data) => {
            dispatch(actions.addSelectedPresentationName(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToprops)(RightDrawer);
