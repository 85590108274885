import React, { Component } from 'react';
import {connect} from 'react-redux';

class AdminFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cancelChanges: 'Cancel Changes',
            applyChanges: 'Apply Changes',
        };
    }

    render() {
        const footerContent = (
            <div>
                <div className="footer-row-details">
                    <span className="row-count">{this.props.userCount} Total Rows</span>
                </div>
                <div className="footer-content right">
                    <button className="cancel_changes gray-button" disabled={(this.props.disabled) ? 'disabled' : ''} onClick={this.props.cancelChanges}>{this.state.cancelChanges}</button>
                    <button className="apply_changes gray-button" disabled={(this.props.disabled) ? 'disabled' : ''} onClick={this.props.applyChanges}>{this.props.applayChangesText}</button>
                </div>
            </div>
        );

        return (
            <div className="footer admin-footer">
                {footerContent}
            </div>
        );
    }
}

const mapSateToProps = (state) => {
    return {
        userView: state.userView,
    };
};

export default connect(mapSateToProps)(AdminFooter);
