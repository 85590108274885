export const filteredResData = (state = {}, action) => {
    switch (action.type) {
        case "GET_PRODUCT_DATA":
            let data = action.data;
            // data.department = data.catIds
            return data;
        default:
            return  state;
    }
};

export const appliedFilters = (state = {catIds: [], customFilters: []}, action) => {
    switch(action.type){
        case "APPLIED_FILTERS":
            let data = action.data;
            data.department = data.catIds;
            return data;
        default:
            return state;
    }
};

var removeCount = 0,
    removeInit = {
        count: removeCount
    };
export const removeFilter = (state = removeInit, action) => {
    switch(action.type){
        case "REMOVE_FILTERS":
            console.log('product actions remove filters reducer: ', action.data);
            let data = action.data;
            data.department = data.catIds;
            return {
                data,
                count: ++removeCount
            };
        default:
            return state;
    }
};

export const filterDataChanged = (state = 0, action) => {
    switch(action.type){
        case "FILTER_DATA_CHANGED":
            let data = action.data;
            // data.department = data.catIds

            return {data,
                count: ++removeCount
            };
        default:
            return state;
    }
};
