import React from 'react';

import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import JqxTabs from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxtabs';

class Help extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false
        };
    }

    openModalCancel() {
        if (this.state.isModified) {
            this.setState({ isModalOpen: true });
        } else {
            this.props.modalClose();
        }
    }

    modalClose(key) {
        this.setState({ isModalOpen: false });
        this.props.modalClose();
    }

    render() {
        return (
            <div className="help-modal">
                <div className="modal-header">
                    Help
                    <span className="close" onClick={this.props.modalClose}>x</span>
                </div>
                <div className="modal-content">
                    <JqxTabs ref='myTabs' height={400} position={'top'}>
                        <ul>
                            <li style={{marginLeft: 30}}>UNIVERSAL FEATURES</li>
                            <li>PRODUCT WORKSPACE</li>
                            <li>COPY WRITING</li>
                            <li>SAMPLE PROCESS</li>
                            <li>PHOTOGRAPHY PROCESS</li>
                            <li>CREATIVE WORKSPACE</li>
                            <li>ACTIVATION ON THE WEBSITE</li>
                            <li>ADMIN SECTION</li>
                        </ul>
                        <div>
                            <ul>
                                <li>
                                    <p>User Profile</p>
                                    <ul>
                                        <li>
                                            <p>To view the User Profile, the user should click on their name in the left navigation menu, where they will see the options: Profile, Help and Logout.</p>
                                        </li>
                                        <li>
                                            <p>If the user clicks on <em><strong>Profile</strong></em>, Sweft will display information about the user, such as Email, First name, Last name, Company name, Department or Designation. Any change to these fields is done by the Admin.</p>
                                        </li>
                                        <li>
                                            <p>Other fields shown in the Profile can be edited by the user, such as Nickname, Password and User Group.</p>
                                            <ul>
                                                <li>
                                                    <p>Nickname: This field defines how the user name is displayed in Sweft and on some Sweft reports, and can be modified by the user.</p>
                                                </li>
                                                <li>
                                                    <p>Password: This provides a link that can be used to change the user&rsquo;s password. Upon clicking the Change Password link, Sweft will display 3 password fields: current password, new password and confirm password.</p>
                                                </li>
                                                <li>
                                                    <p>User group: This field will display the default User Group for the user. If the user is assigned to more than one type of User Group, this field has a dropdown menu where they can select which User Group they will work from in that session.</p>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p>Changing Your User Group Assignment</p>
                                    <ul>
                                        <li>
                                            <p>Users assigned more than one type of functional role in Sweft are able to switch between roles using the User Group field on the User Profile popup. This field displays the current User Group role for the user, and has a dropdown menu to select which User Group role they will assume in that session.</p>
                                        </li>
                                        <li>
                                            <p>To change roles, click the &ldquo;Hi <b>user</b>&rdquo; link in the left navigation and then click &ldquo;Profile&rdquo;. Click on the User Group dropdown, select a new User Group and click &ldquo;Save&rdquo;. For this new User role to take effect, it is necessary to log out and then back in again. Click the &ldquo;Hi <b>user</b>&rdquo; link in the left navigation, select &ldquo;Sign out&rdquo;, and then re-enter your credentials. The new role should be displayed in the &ldquo;Based on&rdquo; field in the header.</p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p>Based On Me</p>
                                    <ul>
                                        <li>
                                            <p>The &lsquo;Based on: Me&rsquo; field can be found in the header of the Sweft Dashboard. For most users, this will just display the user group they are active in. For users who have Supervisor status, the Based on Me field will display a dropdown menu that allows the Supervisor to select a subordinate user and view the dashboard Alerts of that user&rsquo;s account.</p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p>Deactivated Items</p>
                                    <ul>
                                        <li>
                                            <p>Deactivated items are those that have gone through the workflow and have completed their selling cycle, and no longer need to be frequently reviewed in the Workspace.</p>
                                        </li>
                                        <li>
                                            <p>There will be a Deactivated flag, the default state of which is unchecked. The user will have the ability to check the flag when the item no longer needs to be viewed, and can later uncheck the flag if the item is being used again.</p>
                                        </li>
                                        <li>
                                            <p>If the user marks the &ldquo;Show Deactivated&rdquo; checkbox on the Selection Panel and clicks on the apply button, Sweft will include deactivated items in the query results displayed on the product grid.</p>
                                        </li>
                                        <li>
                                            <p>Deactivated items will remain on the database indefinitely.</p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p>Deleted Items</p>
                                    <ul>
                                        <li>
                                            <p>Deleted items are those that have not completed their workflow process and are deleted to remove them from active consideration and review.</p>
                                        </li>
                                        <li>
                                            <p>Items that are activated on the client website and have inventory or sales information attached cannot be deleted, but they can be deactivated.</p>
                                        </li>
                                        <li>
                                            <p>Deleted items are permanently removed after 90 days.</p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p>Filtering / Querying / Finding groups of items</p>
                                    <ul>
                                        <li>
                                            <p>There is a filter section on the Selection Panel, where the user can select which records to view in the workspaces.</p>
                                        </li>
                                        <li>
                                            <p>The User is able to select more than one type of filter; for example, a product category, a season and a channel.</p>
                                        </li>
                                        <li>
                                            <p>The user is also able to select more than one sub-category from each category; for example, Beds &amp; Chairs or Dining Chairs &amp; Armchairs.</p>
                                        </li>
                                        <li>
                                            <p>Once the user selects any of the listed categories and clicks on the apply button, the dashboard and workspace contents will change based on the applied filters.</p>
                                        </li>
                                        <li>
                                            <p>The user must apply filters from the Criteria Panel or select an alert to see any content in the Product Workspace.</p>
                                        </li>
                                        <li>
                                            <p>When the user lands on the Product Workspace for the first time, Sweft will not display any product information until they apply filters or select an alert.</p>
                                        </li>
                                        <li>
                                            <p>Sweft will display the Apply button as shown in the above wireframe.</p>
                                        </li>
                                        <li>
                                            <p>Upon clicking the apply button, Sweft will check the below listed queries and display the associated results.</p>
                                            <ul>
                                                <li>
                                                    <p>If user has marked the Checkbox &ldquo;show Deactivated Items&rdquo;.</p>
                                                </li>
                                                <li>
                                                    <p>If any filters have been selected by the user.</p>
                                                </li>
                                                <li>
                                                    <p>If any custom filter has been provided by the user.</p>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p>Custom Filters</p>
                                    <ul>
                                        <li>
                                            <p>The user is able to further narrow the data query by using the custom filter option as shown in the above wireframe.</p>
                                        </li>
                                        <li>
                                            <p>There are three different fields for the custom filter,</p>
                                            <ul>
                                                <li>
                                                    <p>Field (dropdown)</p>
                                                </li>
                                                <li>
                                                    <p>Operator (dropdown)</p>
                                                </li>
                                                <li>
                                                    <p>Value (text field)</p>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <p>The user is able to select a field in the Field box and enter a text value in the Value box, then select an Operator function to define the relationship of the value to the Field (equals, is greater than, begins with, contains, etc).</p>
                                        </li>
                                        <li>
                                            <p>Note: The fields available in the dropdown are user-group specific, based on the field permissions set in Admin.</p>
                                        </li>
                                        <li>
                                            <p>The user can add more custom filters by clicking on Add criteria level.</p>
                                        </li>
                                        <li>
                                            <p>Sweft will display a dropdown menu showing connector values such as 'and', 'or', 'and not', plus another set of custom filters with the same options: Field, Operator and Value.</p>
                                        </li>
                                        <li>
                                            <p>Sweft will display another "add criteria level" link; the user is able to add as many new levels as needed.</p>
                                        </li>
                                        <li>
                                            <p>The Custom Filters are subordinate to the Filters. If a User selects "Furniture" from the Filters and then sets Custom Filters at "inventory&gt;10", Sweft will only search for Furniture records with more than 10 units of inventory.</p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p>Search for specific items</p>
                                </li>
                            </ul>
                            <p>There are two fields available for search:</p>
                            <ul>
                                <ul>
                                    <ul>
                                        <li>
                                            <p>An entry field for the user to provide the search term</p>
                                        </li>
                                        <li>
                                            <p>A dropdown to select the field to be searched.</p>
                                        </li>
                                    </ul>
                                </ul>
                            </ul>
                            <p>Note: The search is a global feature and searches all fields in the database, even if deleted or deactivated. The filters and custom filters in the Search &amp; Refine panel above do not constrain the search feature.</p>
                            <p>&nbsp;</p>
                        </div>

                        <div>
                            <ul>
                                <li>
                                    <p>&ldquo;View By&rdquo;</p>
                                    <ul>
                                        <li>
                                            <p>Products in the product workspace will be displayed based on the selected Display Option. By default, the Workspace will display at the Style parent level.</p>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <p>&nbsp;</p>
                            <ul>
                                <ul>
                                    <li>
                                        <p>For example, if there are two variations such as color and size for the product, the Display options will be Style, Color and SKU (Size)</p>
                                        <ul>
                                            <li>
                                                <p>Selecting 'Style' will display the style parent records, with the ability to expand the record down to the first and second child levels. Sorting or grouping will happen at the Style level.</p>
                                            </li>
                                            <li>
                                                <p>Selecting 'Color' will display the Color records as parents, with the ability to expand the record down to the second child levels (Size). Sorting or grouping will happen at the Color level.</p>
                                            </li>
                                            <li>
                                                <p>Selecting 'SKU(Size)' will display all records at the 'Size' child levels. Sorting or grouping will happen at the SKU(Size) level.</p>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <li>
                                    <p>PI Requests</p>
                                    <ul>
                                        <li>
                                            <p>Sweft will trigger an automated "Request for PI" to the vendor once PO details have been updated at the item Level in SWEFT, provided that:</p>
                                            <ul>
                                                <li>
                                                    <p>The record has a Vendor, vendor contact and vendor email listed</p>
                                                </li>
                                                <li>
                                                    <p>The selected record is at the hierarchy level indicated by &ldquo;Copy Level&rdquo; (e.g. if the copy level is set at Color, PI will be requested for each &ldquo;Colorway&rdquo; record, but not for the Style or Size)</p>
                                                </li>
                                                <li>
                                                    <p><em><strong>Request PI</strong></em> field is in &lsquo;checked&rsquo; status</p>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <p>When the <em><strong>Request PI</strong></em> flag is left unchecked by the buyer at the item level, SWEFT will not automatically send the &ldquo;Request for PI&rdquo; to the vendor.</p>
                                        </li>
                                        <li>
                                            <p>The buyer can manually trigger a request for PI from the vendor by marking the Request PI Now field in the Product Workspace. SWEFT will then send the vendor the appropriate email as part of the nightly email processing. If the vendor doesn&rsquo;t return the initial &ldquo;Request for PI&rdquo; in a timely fashion, there is a "Resend PI Request" button which will send the PI Request email again on the nightly processing.</p>
                                        </li>
                                        <li>
                                            <p>The Product Information Form is specific to different product types, which will be attached as a PDF document to each "Request for PI" email. This will enable the vendor to efficiently return the necessary product information to the buyer.</p>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <p>For example: Trousers and Shirts are under main class "Apparel", but the PDF form for Trouser is different from the one for Shirts.</p>
                            <ul>
                                <ul>
                                    <li>
                                        <p>The Buyer can mark the "<strong>PI Received</strong>" and &ldquo;<strong>PI Approved</strong>&rdquo; flags for each line item individually. &lsquo;PI Received&rsquo; acknowledges the buyer received the PI form and prevents reminder emails being sent to the vendor. When PI Approved is checked, Sweft will trigger an alert to the Web Producer &amp; Copywriter informing them to begin their next tasks</p>
                                    </li>
                                    <li>
                                        <p>The Buyer is able to upload two types of files when receiving PI</p>
                                        <ul>
                                            <li>
                                                <p>The PDF file can be uploaded and stored in Sweft, and other users can open this and refer to it for product specs.</p>
                                            </li>
                                            <li>
                                                <p>The Buyer can also upload a CSV file containing the product specs, and these details will be loaded into the correct fields for that information</p>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <li>
                                    <p>Keywords:</p>
                                    <ul>
                                        <li>
                                            <p>Once the &ldquo;Product Info Approved&rdquo; flag has been marked for an item, SWEFT will send the Web producer a &ldquo;Ready for Keywords&rdquo; Alert highlighting that item.</p>
                                        </li>
                                        <li>
                                            <p>Upon clicking the alert message, SWEFT will navigate the Web Producer to the Product Workspace and will display all the products for which the "Product Info Approved" flag is checked, provided no keywords or attributes have been saved for that record or its parent record (depending on Copy Level).</p>
                                        </li>
                                        <li>
                                            <p>To add or modify the keyword content for a product, click the Keyword field for the line item, and SWEFT will display a popup where the user can make those changes.</p>
                                        </li>
                                        <li>
                                            <p>The Keywords popup displays language tabs at the top, and multiple short text fields (approx. 15 fields with max 50 characters each).</p>
                                        </li>
                                        <li>
                                            <p>Whichever product hierarchy level was set for the Copy Level will also apply for Keywords, since the keywords are likely to be the same for all colors in a style or all sizes in a color.</p>
                                        </li>
                                        <li>
                                            <p>Upon clicking &ldquo;Submit for Approval&rdquo;, SWEFT will verify the predetermined number of mandatory fields have been filled. If Yes, SWEFT will display a success message and send that record via a "Keywords for Approval" Alert to the appropriate party. (typically: the buyers, planners or senior web producer). If No, SWEFT will display the warning message: "Only X of Y required fields were completed!! Do you still want to submit for approval?&rdquo;, where X will represent the number of fields that are not blank, and Y representing the required number of non-blank fields.</p>
                                            <ul>
                                                <li>
                                                    <p>If the Web producer clicks on &ldquo;Yes&rdquo; SWEFT will add those records to the "Keywords for Approval" alert for the users who handle keyword approval and show the success message.</p>
                                                </li>
                                                <li>
                                                    <p>If the Web producer clicks on &ldquo;No&rdquo;, SWEFT will close the confirmation message and return the user to the Keyword Popup.</p>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p>Approving Keywords</p>
                                    <ul>
                                        <li>
                                            <p>Sweft will display the Keyword Approval Pop-up in which the Buyer (or another designated user) can review the Keyword content and click either &ldquo;Approve&rdquo; or &ldquo;Reject&rdquo;.</p>
                                            <ul>
                                                <li>
                                                    <p>Approve Keyword: SWEFT will update the 'Keyword Approved' field for the product record and close the keyword popup.</p>
                                                </li>
                                                <li>
                                                    <p>Reject Keyword: SWEFT will display the <strong>required</strong> <em><strong>Keyword Review Notes</strong></em> field for the Approver to input comments. The Approver then clicks 'Save' to save their notes. Adding content to the Review Notes section is required before the &ldquo;Save&rdquo; button becomes active. The record will appear again in a &ldquo;Keywords Required&rdquo; Alert sent to the Web Producer.</p>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <p>&nbsp;</p>

                        </div>

                        <div>
                            <ul>
                                <li>
                                    <p>COPY WRITING</p>
                                    <ul>
                                        <li>
                                            <p>Once the Sample Approved and Product Info Approved flags are marked, SWEFT will send a &ldquo;Ready for Copywriting&rdquo; Alert to the Copy Writer.</p>
                                        </li>
                                        <li>
                                            <p>Upon clicking the alert message, SWEFT will navigate the copywriter to the Product Workspace View section and will display all the products for which both the &ldquo;Sample Product Approved" and the "Product Info Approved" flags are checked, AND no Copy has been saved for that record or its parent record (depending on Copy Level).</p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p>Copy Popup</p>
                                    <ul>
                                        <li>
                                            <p>Upon clicking the <em><strong>Copy </strong></em>field for the line item, SWEFT will display a popup wherein the user is able to add the content for the associated product. Different versions of this Popup are shown to different users:</p>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul>
                                <ul>
                                    <ul>
                                        <li>
                                            <p>The copywriters will see the Add and Edit Copy popups</p>
                                        </li>
                                        <li>
                                            <p>The buyers (and Senior Copywriters) will see the Approve Copy popup</p>
                                        </li>
                                    </ul>
                                </ul>
                            </ul>
                            <p>All other users with field read permission will see the View Copy popup, which has no functionality other than displaying the content.</p>
                            <ul>
                                <ul>
                                    <li>
                                        <p>Copy Popup operates at the product hierarchy level defined in the <em><strong>Copy Level</strong></em> field. For example, if the <em><strong>Copy Level </strong></em>is set to 'Style', then the Add/Edit Copy Popup is available in records at the Style level. Child records (color or SKU) for this Style will allow the Copy Popup to be viewed but not edited.</p>
                                    </li>
                                    <li>
                                        <p>The user will need to enter the Display name for the product, which is the product name that is shown publicly, such as on the website, marketplaces or affiliates. A maximum of 20 letters can be entered in the display name field.</p>
                                    </li>
                                    <li>
                                        <p>The <em><strong>Product Copy </strong></em>and <em><strong>Details</strong></em> text boxes have the following pre-loaded functionalities:</p>
                                    </li>
                                </ul>
                            </ul>
                            <ul>
                                <ul>
                                    <ul>
                                        <li>
                                            <p>Font Style, Point Size, Bold, Italic &amp; Underline, Align Left, Center or Right, and Justify. The Product Details box will also Support Bullet Point formatting.</p>
                                        </li>
                                    </ul>
                                </ul>
                            </ul>
                            <ul>
                                <ul>
                                    <li>
                                        <p>These entry fields will prevent the entry of special characters (e.g. &eacute;) that may not register on your website.</p>
                                    </li>
                                    <li>
                                        <p>Clicking on the &lsquo;Save&rsquo; button instructs SWEFT to save the entered information in the database for further editing and close the popup.</p>
                                    </li>
                                    <li>
                                        <p>Clicking &lsquo;Submit for Approval&rsquo; instructs SWEFT to check whether the required fields have been entered. If so, SWEFT will send the Buyer a &lsquo;Copy Approval&rsquo; alert . If not, SWEFT will inform the copywriter how many fields are missing.</p>
                                    </li>
                                    <li>
                                        <p>SWEFT will display the formatted text in the text boxes, but will store HTML markup code in the database for output to the client website.</p>
                                    </li>
                                </ul>
                            </ul>
                            <ul>
                                <li>
                                    <p>Copy Approval</p>
                                </li>
                            </ul>
                            <ul>
                                <ul>
                                    <li>
                                        <p>When the Buyer checks their Copy to Approve alert, they are shown the Product Workspace with the relevant products displayed. Click on the <em><strong>Copy</strong></em> field prompts Sweft to display the Copy Approval Pop-up, where the Buyer can review the copy content and then either approve or reject.</p>
                                    </li>
                                    <li>
                                        <p>If the Buyer clicks &lsquo;Reject&rsquo;, SWEFT will display the <em><strong>Review Notes</strong></em> field for the buyer to enter their comments. Adding content to the <em><strong>Review Notes</strong></em> section is required for all rejected Copy.</p>
                                    </li>
                                    <li>
                                        <p>Once the Buyer adds comments to the <em><strong>Review Notes </strong></em>field, they are able to click the &lsquo;Submit&rsquo; button, which is inactive until content is added. SWEFT will send an alert to the copywriter informing them that the Copy is ready for revision.</p>
                                    </li>
                                    <li>
                                        <p>If the Buyer clicks &lsquo;Approve&rsquo;, the &lsquo;Submit&rsquo; button becomes active and the Buyer must then click it to confirm their approval. SWEFT will update the Copy as approved, marking the copy process complete for that item.</p>
                                    </li>
                                </ul>
                            </ul>
                            <p>&nbsp;</p>

                        </div>

                        <div>
                            <ul>
                                <li>
                                    <p>Sample Requests</p>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <p>Sample and Swatch requests are automated in SWEFT. Once PO information is fed from your ERP the Buyer then selects &lsquo;Sample&rsquo; or &lsquo;Swatch&rsquo; and inputs requirements (comments), Sweft will send a Sample/Swatch Request email to the Vendor.</p>
                                </li>
                                <li>
                                    <p>Sweft will run a nightly process that accumulates all sample/swatch requests for a vendor into one email, and then sends that email each evening. The requests are at the product hierarchy level indicated in the Display Level field, so that the vendor will receive the correct number of sample requests per style.</p>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <p>Sample management</p>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <p>The User who receives the sample from the Vendor will complete the task of "Sample Card" generation and will attach that Sample Card to the new sample.</p>
                                </li>
                                <li>
                                    <p>After the user checks the "Sample Received" flag, Sweft will trigger a pop up for "Quantity Sample Items Received" (default is 1 unit) and will record the date &amp; time in "<em><strong>Sample Received Date"</strong></em> field in the database.</p>
                                </li>
                                <li>
                                    <p>The Buyer then clicks on &ldquo;<em><strong>Generate Sample Card</strong></em>&rdquo; which will generate Sample Cards based on the values defined by the buyer; such as product shot angles and details, buyer Comments, PO Due Date or Activation Date, Campaigns, keywords and other details. Each Sample Card will also have a SWEFT Sample ID number unique to each sample, in text and barcode.</p>
                                </li>
                                <li>
                                    <p>In addition, the Sample Card will display the client-determined image names for each shot angle, in the form of both 2D barcode and text. The image name will follow the naming convention set for each client.</p>
                                </li>
                            </ul>
                            <p>When samples are moved between departments, the Receiver can scan the sample upon receipt or manually update the location via Sample Popup located in the Product Workspace.</p>
                            <ul>
                                <li>
                                    <p>When moving samples to external parties (PR, call center, etc.), turnover meetings, shot completion, alt-tagging images, etc. the client or respresentative may scan or manually update the information before its sent.</p>
                                </li>
                                <li>
                                    <p>Approved users are able to reprint a Sample Card when it is lost or damaged, by selecting a sample record in the popup and clicking "Reprint Sample Card". (This could either be a button in the popup or a right-click action)</p>
                                </li>
                                <li>
                                    <p>When the "Sample Approved" flag is checked, Sweft will record the date &amp; time in the "Sample Approved" field in the database and send Alerts to the Copywriter and Studio Manager, provided other criteria are met. This is the trigger to begin the photography process.</p>
                                    <p>&nbsp;</p>
                                </li>
                            </ul>

                        </div>

                        <div>
                            <ul>
                                <li>
                                    <p>Photography Process</p>
                                    <ul>
                                        <li>
                                            <p>When the product sample is received from the vendor and approved by the buyer, the workflow will proceed according to one of three client &ndash;selected options:</p>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul>
                                <ul>
                                    <ol type="i">
                                        <li>
                                            <p>Sweft will trigger a 'Sample Received' alert to the studio manager (an 'active alert')</p>
                                        </li>
                                        <li>
                                            <p>The record is shown in the 'Samples Available' query Alert for the studio manager (a 'passive alert')</p>
                                        </li>
                                        <li>
                                            <p>The buyer will collect the available samples and pass them to the studio manager in a 'Turnover Meeting' (no alert; samples will be scanned by the Buyer to 'Turnover', and then received by the Studio Manager via scanning).</p>
                                        </li>
                                    </ol>
                                </ul>
                            </ul>
                            <ul>
                                <ul>
                                    <li>
                                        <p>Upon clicking either of the alert messages noted in i or ii above, Sweft will navigate the studio manager to the Creative Workspace and will display all the records that meet the 'products with samples needing photography' query criteria.</p>
                                    </li>
                                    <li>
                                        <p>Shot Angle Assignment</p>
                                        <ul>
                                            <li>
                                                <p>When the buyer sets up the Product, Sweft assigns the category specific shot angles to that record. The buyer can change these shot angles and add new ones as needed.</p>
                                            </li>
                                            <li>
                                                <p>In the Creative Workspace, the studio manager can review the individual shot angles assigned to that product.</p>
                                            </li>
                                            <li>
                                                <p>Studio manager can then assign the photographer, retoucher, stylist, model and other Talent to each product or shot angle.</p>
                                            </li>
                                            <li>
                                                <p>Studio manager will also be able to add comments for the photographer/Image re-toucher.</p>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <p>Once the photographer clicks on the &lsquo;Ready for Photography&rsquo; alert received from the studio manager, Sweft will navigate the Photographer to the Creative <span style={{color: '#ff0000'}}>Workspace</span> and will display the products assigned to him/her.</p>
                                    </li>
                                    <li>
                                        <p>Once the photo shot is completed, Photographer should upload the images to the DAM using the image reference filename from the Sample Card (either by scanning or entering the filename manually).</p>
                                    </li>
                                    <li>
                                        <p>For each shot angle completed and uploaded to the DAM, the Photographer will check the "Shot Completed" flag. Sweft will record the date &amp; time in <em><strong>Shot Completed Date</strong></em> field in the database.</p>
                                    </li>
                                    <li>
                                        <p>Once the Photographer checks the &lsquo;Shot Completed&rsquo; check box, Sweft will send an alert to the image Re-toucher. When clicked, Sweft will navigate the image Re-toucher to the Creative Workspace with the relevant items selected.</p>
                                    </li>
                                    <li>
                                        <p>The image Re-toucher can use the image reference filename shown on the Sample Card to download the image from the DAM, and proceed with the retouching in their preferred application.</p>
                                    </li>
                                    <li>
                                        <p>Once the image re-touch process is completed, the image Re-toucher will confirm that the filename saved to the DAM matches the filename in Sweft, and then check the &lsquo;Retouch Complete&rsquo; flag for the associated products. Sweft will then add the completed items to the "Images Ready for Approval" alert to the buyer.</p>
                                    </li>
                                    <li>
                                        <p>Note that when &ldquo;Retouch Complete&rdquo; is checked, Sweft will check that the mandatory information is entered and in the correct format. If not, Sweft will highlight the associated field and display an error message.</p>
                                    </li>
                                </ul>
                            </ul>
                            <p>&nbsp;</p>

                        </div>

                        <div>
                            <ul>
                                <li>
                                    <p>Creative Workspace contents</p>
                                    <ul>
                                        <li>
                                            <p><em><strong>Checkbox:</strong></em> For the User to select multiple line items to sort or publish.</p>
                                        </li>
                                        <li>
                                            <p><em><strong>Sample Image</strong></em>: Upon clicking an image field, Sweft will display the same image popup as shown on the product workspace.</p>
                                        </li>
                                        <li>
                                            <p><em><strong>Department:</strong></em> Sweft will display the department name of the associated product.</p>
                                        </li>
                                        <li>
                                            <p><em><strong>Description:</strong></em> The system will display product description (Description 1).</p>
                                        </li>
                                        <li>
                                            <p><em><strong>Sample Location:</strong></em> displays the current location of the Product Sample. Click on this field to display the Sample Popup and view the location for all samples available for this product. To populate this field, scan the sample card to receive items when the scanner is convenient or update the location manually.</p>
                                        </li>
                                        <li>
                                            <p><em><strong>Photographer:</strong></em> The Studio Manager will assign a Photographer to each product or shot angle. If there is only one photographer, Sweft will assign that photographer to the products. <span style={{color: '#ff0000'}}>(Within what range?)</span></p>
                                        </li>
                                        <li>
                                            <p><em><strong>Image Re-toucher</strong></em>: The Studio Manager will be able to assign the Image re-touch to each product or shot angle. If there is only one re-toucher, Sweft will assign that re-toucher to the products.</p>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <p>Note: The Creative Workspace has other fields, such as <em><strong>Models, Stylists, Props</strong></em>, etc. The grid will scroll left/right when the current view has more fields than are visible on the screen.</p>
                            <ul>
                                <ul>
                                    <li>
                                        <p>The Creative Workspace also offers System- and Personal View options.</p>
                                    </li>
                                    <li>
                                        <p><strong>Parent/Child Display </strong>: The Creative Workspace will show Display Level items as Parent records, with each of the Shot Angles shown as child records.</p>
                                    </li>
                                    <li>
                                        <p>The <strong>View By</strong> function allows the user to view the Creative Workspace at the summary Item level (Parent) or detailed shot angle level (Child). For example, an item with Display Level defined as &lsquo;color&rsquo; would show the color record when collapsed, and the shot angles when expanded.</p>
                                    </li>
                                    <li>
                                        <p>For some fields, the child record details are summarized at the parent level. For example, the number of shot angles assigned are displayed in the Shot Angles field in the Parent record.</p>
                                    </li>
                                    <li>
                                        <p>For other fields, if the user enters data at the Parent level, the same info is propagated to the Child levels. For example, if the user types a name (Brian Marcus) into the Photographer field at the Parent level, then that name is added into the Photographer field for the associated Child records. The Photographer field for these Child records are subsequently modified, in which case that field for the Parent record will show the names separated by commas.</p>
                                    </li>
                                    <li>
                                        <p><strong>View by</strong>: The &lsquo;View by&rsquo; button is found in the Creative Workspace footer. When clicked, the user has the choice of displaying the Creative Workspace at the Item (parent) level, or at the Shot Angle (child) level. When at the Item level, all displays and sorting are at the Item level, and the Item records must be individually expanded to show the shot angles. If set at the Shot level, only Shot Angles are shown and the Creative Workspace can be sorted or grouped by details, such as photographer or type of shot angle.</p>
                                    </li>
                                    <li>
                                        <p><strong>Add Shot: </strong>When the user clicks on Add Shot, Sweft opens a record on the Creative Workspace at the bottom of the current grouping (Parent/Children) that is selected. If no record is selected, then the Add Record button will be inactive. The user will be required to select a Shot Angle from the dropdown of category-specific Angle Types. If the user tries to exit the new Shot record without selecting a Shot Angle, Sweft will pop up an error message "Please select a Shot Type before proceeding", with the options: <em>Add Shot</em> closes popup message and returns to the added record; or <em>Cancel Shot Add</em> deletes the added record.</p>
                                    </li>
                                    <li>
                                        <p><strong>Delete Shot</strong>: If a Child record is selected, the Delete Shot button will be active. If no record is selected, or if a Parent record is selected, the Delete Shot button will be inactive.</p>
                                    </li>
                                    <li>
                                        <p>When the active Delete Shot button is clicked, Sweft will display a confirmation message "Are you sure you want to delete this Shot?" If yes, the Shot Angle and details are eliminated from the product record. If not, no changes are made<span style={{color: '#ff0000'}}>.</span></p>
                                    </li>
                                    <li>
                                        <p><strong>Undo Last Changes</strong>: The &ldquo;Undo Last Change&rdquo; button is found in the Creative Workspace footer. When clicked, Sweft will revert the previous change the User made in the Creative Workspace. There are 5 levels of undo.</p>
                                    </li>
                                </ul>
                            </ul>
                            <p>Note: In contrast to the Product Workspace, by default the Creative Workspace will load with all items marked "Sample Approved" but "Retouch Complete" is not checked, unless the User had clicked on an Alert or selected a filter.</p>
                            <ul>
                                <li>
                                    <p>Creative Publish</p>
                                    <ul>
                                        <li>
                                            <p>The Creative Publish button, located in the footer of the Creative Workspace, launches the Publication Panel and allows the Studio Manager to print out a manual PDF copy of task assignments for distribution to team members who are not on Sweft or connected to computers (e.g. agencies, on-location photoshoots, etc.). There are Presentation and Report formats specific to Creative.</p>
                                        </li>
                                        <li>
                                            <p>The select box in the Creative Workspace allows the Studio Manager to select multiple records, for such tasks as assigning a Photographer or a Re-toucher.</p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p>Samples</p>
                                    <ul>
                                        <li>
                                            <p>Sample location: This will display the current location of the Product Sample. If there is more than one sample, it will display 'Studio' if one sample is currently scanned to the studio, otherwise it will display the location of the first sample record. It will also display a '+' sign in the Location field when there is more than one sample. The Studio Manager can click on this field to display the Sample popup, and then scan the sample card to receive items when the scanner is convenient; otherwise they will update the location manually.</p>
                                        </li>
                                        <li>
                                            <p>The Sample popup displays the location for all samples available for this product, and allows the user to scan the sample card to receive items or update the location manually. The Sample popup can be viewed by clicking the Sample Location field.</p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p>Photography Approval</p>
                                    <ul>
                                        <li>
                                            <p>Once &ldquo;Retouch Complete&rdquo;, Sweft will trigger the "Image Ready for Approval" alert to the Buyer. When clicked, the Product Workspace is opened showing the products for which image approval is required.</p>
                                        </li>
                                        <li>
                                            <p>When the Approve Image popup is opened, all the images are checked and the &lsquo;Reject&rsquo; and &lsquo;Approve&rsquo; buttons are shown.</p>
                                        </li>
                                        <li>
                                            <p>The buyer can approve or reject all images at once, and have one set of Review Notes for the image set. When they have made their selection, they can still go and adjust the approval or rejection of individual images. The single Review Notes still apply to all images.</p>
                                        </li>
                                        <li>
                                            <p>If the buyer unchecks a few of the images and clicks on &lsquo;Approve&rsquo;, Sweft will display the Review Notes field and trigger the error message: &ldquo;Some images have been rejected. Please provide review notes in the below section and submit&rdquo;.</p>
                                        </li>
                                        <li>
                                            <p>If the buyer unchecks a few of the images and clicks on &lsquo;Reject&rsquo;, Sweft will display the Review Notes section to the Buyer, which must be entered before the Buyer can click &lsquo;Submit&rsquo;. Sweft will treat the unchecked items as approved and update the database accordingly.</p>
                                        </li>
                                        <li>
                                            <p>When images are rejected, Sweft will update the Photo Rejection alert for the Studio Manager. Upon clicking this alert, the studio manager is brought to the Creative Workspace showing a list of any images that have been rejected. Here, they can open the image viewer popup to see the Rejection Notes and assign new tasks for the studio team. When images are approved, Sweft updates the database and no further action is required from the Studio Manager.</p>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <p>&nbsp;</p>

                        </div>

                        <div>
                            <ul>
                                <li>
                                    <p>Website Activation from the Product Workspace</p>
                                    <ul>
                                        <li>
                                            <p>To activate an item on the Website, the item must be marked &ldquo;Ready to Activate&rdquo;, which requires that the Keywords, Attributes, Copywriting and Product Images are all in the "Approved" status. Once these four approval flags are marked, Sweft will mark the product as "Ready to Activate" and record the date.</p>
                                        </li>
                                        <li>
                                            <p>For some companies, the item will automatically be part of a nightly Sweft export of products to the client website. For other companies, a manual action by the web production team is required, marking the record as "Activate Product" which will instruct Sweft to export the record on the next nightly export to the client website. Check with your Sweft Administrator to see which approach is taken in your company.</p>
                                        </li>
                                        <li>
                                            <p><strong>Direct-to-site content update</strong>: Once the product is in &ldquo;Ready to activate&rdquo; status, there is an option to send the product content directly online without the usual inventory or launch date checks. This allows the web producers to prepare and review the product on the website prior to it being available for sale. If the user selects this option and pushes content to the web, Sweft will record the date, time and user for this action in the database. Note: This will not activate a product, as the "Go Live" flag will come later during the normal process.</p>
                                        </li>
                                        <li>
                                            <p><strong>Launch Date</strong>: Buyers, marketers and web producers can set the launch date for the product as part of their workflow. The Launch Date is a field available for each record, and sets timing for product activation that is an exception to the normal process.</p>
                                        </li>
                                        <li>
                                            <p>Once the product is in &ldquo;Ready to Activate&rdquo; status, Sweft will check if the launch date is the same as the system date. If yes, Sweft will further process the product for &ldquo;Inventory dependency check&rdquo;. If no, then Sweft will wait until the launch date and the system date becomes the same. Once it becomes the same, Sweft will look for the &ldquo;Inventory dependency check&rdquo;.</p>
                                        </li>
                                        <li>
                                            <p><strong>Inventory dependency: </strong> As part of the nightly process of exporting products to the website, Sweft will check if each product has any dependency on inventory levels (i.e. if the item can be backordered or &ldquo;presold&rdquo;).</p>
                                        </li>
                                        <li>
                                            <p>The buyers, planners, marketers and web producers can set the Inventory Dependency for a product as part of their workflow, by checking the &ldquo;Inventory Independent&rdquo; checkbox. When checked, Sweft will record the date and user taking this action. By default, this field is unchecked.</p>
                                        </li>
                                        <li>
                                            <p>Inventory Dependency is included in the logic used by Sweft each night to decide which products to export to the website. If an item is marked Inventory Independent, Sweft will export the item to the website as soon as its launch date is met, regardless of the inventory level. If the item is marked as Inventory Independent but there is no launch date set, then the item is exported to the website when the inventory is received.</p>
                                        </li>
                                        <li>
                                            <p>Sweft will utilize &ldquo;Dependency on Inventory&rdquo; logic to validate whether products marked &lsquo;Ready to Activate&rsquo; require inventory availability before exporting "Go-Live" to the website. If there is inventory dependency for the product, Sweft will check if the inventory is available in the daily download of inventory status and quantity from the ERP, and will make the inventory assessment accordingly.</p>
                                        </li>
                                        <li>
                                            <p>If there is inventory dependency and the inventory is not available, Sweft will not allow the product to go live and it will keep checking in the ERP system until the inventory becomes available. Once it is available the Go-Live flag is synched to the client website. Sweft will record the date &amp; time the "Go-Live&rdquo; was synched.</p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p>Attribute Approval</p>
                                    <ul>
                                        <li>
                                            <p>There are up to 20 customizable Attribute fields in the Product Workspace, with read Only/Edit access to be determined by Client.</p>
                                        </li>
                                        <li>
                                            <p>The Grid will also have an "Attributes Complete" checkbox field, which will be inactive until <em>X</em> fields contain data (<em>number X to be set by Admin</em>). Once enough fields contain data, the approved user is able to click the Attributes Complete field.</p>
                                        </li>
                                        <li>
                                            <p>A checked <em><strong>Attributes Complete</strong></em> field is required before the product is &lsquo;Ready to Activate&rsquo;.</p>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <p>Launch Date</p>
                                    <ul>
                                        <li>
                                            <p>The Buyer, Marketer and/or Web Producers can set the launch date for a product as part of their workflow. <em><strong>Launch Date</strong></em> is a field available for each record, and will set timing for product activation when there is an exception to the normal process.</p>
                                        </li>
                                        <li>
                                            <p>The launch date will be included in the logic used by Sweft each night to decide which products to export to the Website. The launch date supersedes the</p>
                                        </li>
                                        <li>
                                            <p>The admin determines which user groups can modify the Launch Date field</p>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                        </div>

                        <div>
                            <ul>
                                <li>
                                    <p>Functionality tab</p>
                                    <ul>
                                        <li>
                                            <p>This page has information related to mapping user group access to mega menu items like Product Workspace, Creative Workspace, other user group-specific functionality throughout the Sweft application. The admin determines what kind of information or actions are accessible to the user groups for the different functionalities.</p>
                                        </li>
                                        <li>
                                            <p>The admin can enable the functionalities for the listed groups by marking the checkbox or disable the functionalities for those groups by unmarking it.</p>
                                        </li>
                                        <li>
                                            <p>Functionality Tab Contents: Sweft will display the below listed column items:</p>
                                            <ul>
                                                <li>
                                                    <p>Enable/Disable option: Sweft will show enable/disabled options in the dropdown list as shown in the above wireframe. The admin can use the dropdown list to select the option to enable and disable the adjacent user group.</p>
                                                </li>
                                                <li>
                                                    <p>User group: Sweft displays the user groups available in the database as shown in the above wireframe.</p>
                                                </li>
                                                <li>
                                                    <p>Functionality: Each functionality will be listed out in the column headers across the workspace.</p>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <p>The Enable/Disable and User Group columns are fixed at the left side, and mirror the functionality on the User Group Views admin tab.</p>
                                        </li>
                                        <li>
                                            <p><strong>Note</strong>: Disabling a User Group means that any User with that user group affiliation will not have access to the functionalities available to that group. A User that belongs solely to a disabled User Group will not be able to do or see anything.</p>
                                        </li>
                                        <li>
                                            <p>When the admin disables a User Group, Sweft will check to see if there are any users belonging to that group. If so, Sweft will issue a warning dialog to confirm the action. "There are users affiliated with that User Group. Are you sure you want to disable?" If yes, Sweft will disable the user group. If no, Sweft will keep the user group as enabled.</p>
                                        </li>
                                        <li>
                                            <p>Apply Changes</p>
                                            <ul>
                                                <li>
                                                    <p>Upon clicking the Apply Changes button, Sweft will save the provided information in the database and will display a success message.</p>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <p>Cancel changes</p>
                                            <ul>
                                                <li>
                                                    <p>Upon clicking the cancel changes button, Sweft will display a confirmation message &ldquo;Are you sure you want to cancel the changes made?&rdquo; If &lsquo;Yes&rsquo;, Sweft will not update any of the changes in the database and will display the previous information (i.e. the previously saved information). If &lsquo;No&rsquo;, Sweft will bring the customer back to the previous Admin view, with the recent unsaved changes intact.</p>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <p>&nbsp;</p>

                        </div>
                    </JqxTabs>
                </div>
            </div>
        );
    }
}

export default Help;
