import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import LeftNavModalContainer from './leftNavModalContainer';
import './leftNav.css';
import { withRouter } from 'react-router-dom';

const LeftNavComponent = ({
    state,
    props,
    loadAllImages,
    checkForActive,
    modalOpen,
    modalClose,
    triggerModalOpen,
    toggleListItemClassName,
    signout,
    tabVisibility,
}) => {
    return (
        <div>
            <div>
                <LeftNavModalContainer isOpen={state.isModalOpen} modalClose={modalClose} userId={props.user?.userId} modalName={state.modalName} user={props.user} {...props} />

                <div className="left-nav-content">
                    <div className="text-center scroll">
                        <div className="main-nav">
                            <div className="logo"><Link to={{ pathname: '/dashboard' }} onClick={() => toggleListItemClassName('dashboard')}><img src={require('./images/Sweftlogo.svg')} /></Link></div>
                            <div>
                                <div className={"user-details " + (state.showUserDropdown ? "active" : "")}>
                                    <p className="user-name"><a href="javascript:void(0)" alt="">Hi<span className={"span-user-name"} id='userName'>{props.user?.nickName || props.user?.firstName}</span></a></p>
                                    <div className="dropdown-menu">
                                        <span className="arrow left"></span>
                                        <ul className="dropdown-list">
                                            <li onClick={() => triggerModalOpen('profile')} data-modaltype="profile"><a ><i className="icon-profile"></i><span>Profile</span></a></li>
                                            <li onClick={() => triggerModalOpen('help')}><a ><i className="icon-help"></i><span>Help</span></a></li>
                                            <li onClick={() => signout()}><a href="/"><i className="icon-signout"></i><span>Signout</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>


                            <ul className="menuList">
                                <li className={checkForActive('dashboard')} >
                                    <Link to={{ pathname: '/dashboard' }} onClick={() => toggleListItemClassName('dashboard')} title='Dashboard'>
                                        <i className="icon-globe" ></i>
                                        <span>Dashboard</span>
                                    </Link>
                                </li>
                                {
                                    tabVisibility['product'] &&
									<li className={checkForActive('product2')}  >
									    <Link to={{ pathname: '/product2' }} onClick={() => toggleListItemClassName('product2')} title='Products'>
									        <i className="icon-product"></i>
									        <span>Product</span>
									    </Link>
									</li>
                                }
                                {
                                    tabVisibility['samples'] &&
									<li className={checkForActive('samples')}  >
									    <Link to={{ pathname: '/samples' }} onClick={() => toggleListItemClassName('samples')} title='Samples'>
									        <i className="icon-samples"></i>
									        <span>Samples</span>
									    </Link>
									</li>
                                }
                                {/* <li className={state.menuItem.product} >
										<Link to={{ pathname: '/product' }}  onClick={() => toggleListItemClassName('product')}>
											<i className="icon-product"></i>
											<span>Product</span>
										</Link>
									</li> */}
                                {
                                    tabVisibility['marketing'] &&
									<li className={checkForActive('marketing')}  >
									    <Link to={{ pathname: '/marketing' }} onClick={() => toggleListItemClassName('marketing')} title='Marketing'>
									        <i className="icon-marketing"></i>
									        <span>Marketing</span>
									    </Link>
									</li>

                                }
                                {
                                    tabVisibility['creative'] &&
									<li className={state.menuItem.creative} >
									    <Link to={{ pathname: '/creative' }} onClick={() => toggleListItemClassName('creative')} title='Creative'>
									        <i className="icon-creative"></i>
									        <span>Creative</span>
									    </Link>
									</li>
                                }
                                {
                                    tabVisibility['publication'] &&
									<li className={checkForActive('publication')} >
									    <Link to={{ pathname: '/publication' }} onClick={() => toggleListItemClassName('publication')} title='Publication'>
									        <i className="icon-publication"></i>
									        <span>Picture Pack</span>
									    </Link>
									</li>
                                }
                                {
                                    tabVisibility['copywriting'] &&
									<li className={checkForActive('copywriting')} >
									    <Link to={{ pathname: '/copywriting' }} onClick={() => toggleListItemClassName('copywriting')} title='Copywriting'>
									        <i className="icon-publication"></i>
									        <span>Copywriting</span>
									    </Link>
									</li>
                                }
                            </ul>

                            <ul className="menuList">
                                {
                                    props.user?.admin && props.user?.defaultRole === 'ADMIN' ?

                                        <li className={state.menuItem.admin} >
                                            <Link to={{ pathname: '/admin' }} onClick={() => toggleListItemClassName('admin')} title='Admin'>
                                                <i className={"icon-admin"}></i>
                                                <span>Admin</span>
                                            </Link>
                                        </li>

                                        :

                                        ''
                                }
                            </ul>

                        </div>
                        <div className="leftNav-logo">
                            <img className="leftNav-logoImage" src={require('/images/logo.png')} />
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
};

LeftNavComponent.propTypes = {
    state: PropTypes.object,
    props: PropTypes.object,
    loadAllImages: PropTypes.func,
    checkForActive: PropTypes.func,
    modalOpen: PropTypes.func,
    modalClose: PropTypes.func,
    triggerModalOpen: PropTypes.func,
    toggleListItemClassName: PropTypes.func,
    signout: PropTypes.func
};

export default withRouter(LeftNavComponent);
