import React, { Component } from 'react';
import RulesBuilder from './query-builder';

import axios from 'axios';
import AdminServices from '../../admin/admin-services/admin-services';

class CreateRules extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: [],
            ruleData: {},
            arrRulesScope: [],
            arrRulesScopeData: [],
            showRulesBuilder: false,
            isFilterChanged: false,
        };

        this.arrDropdownData = [];
        this.arrDropdowns = [];
        this.arrLevels = [];
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleRuleScopeChange = this.handleRuleScopeChange.bind(this);
        this.handlePageNameChange = this.handlePageNameChange.bind(this);
        this.getFiltersData = this.getFiltersData.bind(this);
        this.getPageNameData = this.getPageNameData.bind(this);
    }

    componentDidMount() {
        const _this = this;

        AdminServices.filterOperators().then((response) => {
            const dataRes = response.responseData;

            _this.setState({
                filters: dataRes.data,
            });

            _this.arrDropdowns = dataRes.dropdowns ? dataRes.dropdowns : [];
            _this.arrLevels = dataRes.levels ? dataRes.levels : [];
        }).catch((error) => {
            console.log('rules-creator componentDidMount API call failed', error);

            NotificationManager({ message: xhr.message, type: 4 });
        });
    }

    componentWillReceiveProps(nextProps) {
        console.log(' rules creator: ', nextProps, this.props);

        const _this = this;

        if (_this.props.isOpen !== nextProps.isOpen) {
            const pageName = nextProps.ruleData.pageName ? nextProps.ruleData.pageName : null;
            const filterState = _this.state.isFilterChanged;

            _this.setState({
                ruleData: nextProps.ruleData,
                pageName: pageName,
            });
        }
    }

    /**
     * Checks input change for values
     * @param {*} event
     */
    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const RuleData = Object.assign({}, this.state.ruleData);
        RuleData[name] = value;

        this.setState({ ruleData: RuleData });
    }

    /**
     * Checks if rule has been changed
     * @param {*} event
     */
    handleRuleScopeChange(event) {
        const _this = this;

        _this.setState({ showRulesBuilder: false });

        const target = event.target;
        const value = target.value;
        const name = target.name;
        const RuleData = Object.assign({}, this.state.ruleData);
        RuleData[name] = value;

        const filterData = _this.getFiltersData(value);
        const filterState = _this.state.isFilterChanged;

        _this.setState({
            filters: filterData,
            showRulesBuilder: true,
            ruleData: RuleData,
            isFilterChanged: !filterState
        });
    }

    /**
     * Checks for pagename change
     * @param {*} event
     */
    handlePageNameChange(event) {
        const _this = this;
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const RuleData = Object.assign({}, this.state.ruleData);
        RuleData[name] = value;

        _this.setState({ ruleData: RuleData });
    }

    /**
     * Getter for filtered data
     * @param {*} name
     * @return {string}
     */
    getFiltersData(name) {
        const _this = this;

        if (name) {
            let returnData = [];

            _this.arrDropdownData.some(function (dropdowndata) {
                if (dropdowndata.id == name) {
                    returnData = dropdowndata.data;
                }
            });

            return returnData;
        } else {
            return _this.arrDropdownData[0].data;
        }
    }

    /**
     * Getter for pagename data
     * @param {*} page
     * @return {string}
     */
    getPageNameData(page) {
        const _this = this;
        let result = [];

        _this.arrDropdowns.some(function (val) {
            if (val.id == page) {
                result = val.values;
            }
        });

        return result;
    }

    render() {
        console.log('rules creator: ', this.props);

        let ruleId = 0;

        if (this.props.ruleData) {
            ruleId = this.props.ruleData.ruleId;
        }

        console.log('admin rules-creator render', this.state, this.props);

        return (
            <div className={`scroll add-edit-popup-admin ${(this.props.isOpen) ? 'active' : ''}`}>
                <div className="back" onClick={() => {
                    this.props.close();
                }}>&#8592;Back
                </div>
                <div className="modal-form">
                    <div className="form-group">
                        <input
                            type="hidden"
                            className="currentRuleId"
                            value={this.state.ruleData.ruleId} />
                        <label className="form-label">Rule Name*</label>
                        <input
                            type="text"
                            className="form-control rule-name"
                            placeholder="Rule Name*"
                            name="ruleName"
                            value={this.state.ruleData.ruleName}
                            onChange={this.handleInputChange} />
                    </div>
                    <div className="form-group">
                        <label className="form-label">Status*</label>
                        <select
                            id="ruleStatus"
                            name="status"
                            className="ruleStatus form-fluid-select"
                            onChange={this.handleInputChange}
                        >
                            <option value="">Select Status*</option>
                            <option value="true" selected={this.state.ruleData.status === 'Active' ? 'selected' : ''}>
                                Active
                            </option>
                            <option value="false" selected={this.state.ruleData.status === 'Paused' ? 'selected' : ''}>
                                Pause
                            </option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label className="form-label">Levels*</label>
                        <select
                            id="ruleLevel"
                            value={this.state.ruleData.level}
                            name="level"
                            className="ruleLevel form-fluid-select"
                            onChange={this.handleInputChange}
                        >
                            <option value="">Select Level *</option>
                            {
                                this.arrLevels.map(function (val) {
                                    return <option key={val} >{val}</option>;
                                })
                            }
                        </select>
                    </div>
                    <div className="form-group">
                        <label className="form-label">Page Name*</label>
                        <select
                            value={this.state.ruleData.pageName}
                            id="pageName"
                            name="pageName"
                            className="ruleStatus form-fluid-select"
                            onChange={this.handlePageNameChange}
                        >
                            <option value="">Select Page Name</option>
                            {
                                this.arrDropdowns.map(function (val) {
                                    return <option key={val} >{val}</option>;
                                })
                            }
                        </select>
                    </div>

                    <div className="rules-holder">
                        {this.props.isOpen &&
                        <RulesBuilder
                            filters={this.state.filters}
                            ruleData={this.state.ruleData}
                            isFilterChanged={this.state.isFilterChanged} />
                        }
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label className="form-label">Description*</label>
                                <textarea
                                    className="form-control col-12 texrarea-fld rule-description"
                                    name="description"
                                    cols="7"
                                    value={this.state.ruleData.description}
                                    onChange={this.handleInputChange}></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CreateRules;
