import React, { Component } from 'react';

class TreeView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            data2: {},
            selectedFilter: [],
            forFilterStrips: {},
            highlightFlag: false,
        };

        this.selectCategory = this.selectCategory.bind(this);
        this.toggleView = this.toggleView.bind(this);
        this.refSelectedFilter = this.refSelectedFilter.bind(this);
        this.findCategoryObj = this.findCategoryObj.bind(this);
        this.categoryTreeView = this.categoryTreeView.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const _this = this;

        let userSpeciality = this.props.userSpeciality;

        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) && this.props.removeFilter.count == 0) {
            if (userSpeciality) {
                let splitUserSpec = userSpeciality.split(',');

                if (splitUserSpec && splitUserSpec.length > 1) {
                    for (let i = 0; i < splitUserSpec.length; i++) {
                        let tempArraySplitByDash = splitUserSpec[i].split('-');
                        let defCategoryId = tempArraySplitByDash && tempArraySplitByDash[0] ? tempArraySplitByDash[0] : null;
                        let defCategoryName = tempArraySplitByDash && tempArraySplitByDash[1] ? tempArraySplitByDash[1] : null;

                        _this.setSelectedCategory(this.props.data, defCategoryId, defCategoryName);
                    }
                } else {
                    let tempArray = userSpeciality.split('-');
                    let defaultCategoryId = tempArray && tempArray[0] ? tempArray[0] : null;
                    let defaultCategoryName = tempArray && tempArray[1] ? tempArray[1] : null;

                    _this.setSelectedCategory(this.props.data, defaultCategoryId, defaultCategoryName);
                }
            }
        }

        if (JSON.stringify(prevProps.removeFilter) !== JSON.stringify(this.props.removeFilter) && this.props.removeFilter.count != 0) {

            this.setSelectedCategory(this.props.data, _this.props.removeFilter.data.id);

            setTimeout(() => {
                $('#categoryFilter .category.active').each(function(val, idx) {
                    var activeCatKey = $(idx).data('catid');

                    if (activeCatKey == _this.props.removeFilter.data.id) {
                        $(this).removeClass('active');
                        $(this).parent('.tree-node-list').removeClass('selected');
                    }
                });
            }, 3000);
        }
    }

    setSelectedCategory(data, catId, name) {
        let dataArray = data;
        let dataArrayLength = dataArray.length;

        for (let i = 0; i < dataArrayLength; i++) {
            if (dataArray[i].catId == catId && dataArray[i].catName == name) {
                dataArray[i].selected = true;

                break;
            }

            let catArray = dataArray[i].productCategory;
            let catArrayLength = catArray.length;

            if (catArrayLength > 0) {
                this.setSelectedCategory(catArray, catId, name);
            }
        }

        this.setState({ data: dataArray }, () => {
            this.props.activeCategoryCheck(this.refSelectedFilter());
        });
    }

    findCategoryObj(type, data, catId, level, doSelect, parentCatIds, parent) {
        for (var i = 0; i < data.length; i++) {
            var o = data[i];
            var parentSelected = false;

            o.isTabActive = o.isTabActive == undefined ? false : o.isTabActive;
            o.isOpen = o.isOpen == undefined ? false : o.isOpen;
            o.selected = o.selected == undefined ? false : o.selected;

            if (level != 1 && parentCatIds && parentCatIds.length > 0 && parentCatIds.indexOf(o.catId + "") > - 1) {
                o.selected = false;
            }

            if (o.catId === catId && o.type == type) {
                if (doSelect && level != 1) {
                    o.selected = !o.selected;
                } else {
                    o.isOpen = !o.isOpen;
                    o.isTabActive = !o.isTabActive;
                }
            }

            if (o.productCategory.length) {
                this.findCategoryObj(type, o.productCategory, catId, level, doSelect, parentCatIds, parent);
            }
        }
    }

    toggleView(catId, level, type) {
        var data = this.props.data;
        this.findCategoryObj(type, data, catId, level, 0);
        this.setState({ data: data });
    }

    selectCategory(event, catId, level, type) {
        console.log('treeview selectCategory: ', event, catId, level, type);

        var data = this.props.data,
            target = event.target,
            parentCatIds = [];

        var currentSelectedLevel = $(target).closest('.tree-node').attr('data-level');
        var length = parseInt(currentSelectedLevel, 10);

        for (var i = length - 1; i > 0; i--) {
            var datacatId = $(target).closest('.tree-node.level' + i).attr('data-catid');
            parentCatIds.push(datacatId);
        }

        $(target).closest('.tree-node').find('.tree-node').each(function() {
            var datacatId = $(this).attr('data-catid');
            parentCatIds.push(datacatId);
        });

        this.findCategoryObj(type, data, catId, level, 1, parentCatIds);

        this.setState({ data: data }, () => {
            this.props.activeCategoryCheck(this.refSelectedFilter());
        });
    }

    refSelectedFilter() {
        const _this = this;

        let category = {
            category: [],
            catIdsObj: {},
            department: [],
            seasons: [],
            channels: [],
            campaignType: []
        };

        let forFilterStrips = [];

        $('#categoryFilter .category.active').each(function(val, idx) {
            var activeCatKey = $(idx).data('catid');

            if ($(idx).data('group') == 'Campaign Type') {
                let getText = $(idx).text();
                category['campaignType'].push(getText);
            } else {
                category.catIdsObj[activeCatKey] = $(idx).text();
                category[$(idx).data('group').toLowerCase()].push(activeCatKey);
            }
        });

        this.setState({ activeFilters: category.category });

        return category;
    }

    categoryTreeView(catlist, level, type) {
        let items = catlist.map((category, index) => {
            return (
                <div
                    key={index}
                    className={`tree-node level` + level}
                    data-level={level}
                    data-catid={category.catId}
                    data-group={(level == 1) ? category.catName : type}
                >
                    <div className={`tree-node-list ${(category.isTabActive) ? 'active' : ''} ${(category.selected) ? 'selected' : ''}`}>
                        {
                            category.productCategory.length > 0
                                ?
                                <span className={`${(category.isOpen) ? 'icon-minus-blue' : 'icon-plus'}`} onClick={() =>this.toggleView(category.catId, level, category.type)}></span>
                                :
                                <span className={`no-expand`}>&nbsp;</span>
                        }

                        <span
                            id={"id-" + category.catId}
                            data-type={category.type}
                            className={`category ${(category.selected) ? 'active' : ''}`}
                            data-node="0"
                            data-catid={category.catId}
                            onClick={(e)=>{
                                this.selectCategory(e, category.catId, level, category.type, category.type);
                            }}
                            data-group={(level == 1) ? category.catName : type}
                        >{category.catName}</span>
                    </div>

                    { category.productCategory.length > 0 &&
                    <div className={`tree-node-item ${(category.isOpen) ? 'is-open' : ''}`}>
                        {this.categoryTreeView(category.productCategory, level + 1, category.type, category.type)}
                    </div>
                    }
                </div>
            );
        });

        return items;
    }

    render() {
        console.log('TreeView: in render: ', this.props, this.state);

        const data = this.props.data;

        var type = '';

        return (
            <React.Fragment>
                { data &&
                <div id="categoryFilter">{this.categoryTreeView(data, 1, type)}</div>
                }
            </React.Fragment>
        );
    }
}

export default TreeView;
