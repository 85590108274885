import React, { Component } from 'react';
import Modal from '../../modals/common-modal.js';
import ConfirmSubmit from './confirmsubmit.js';
import axios from 'axios';

var constants = require('../../common/constants.js');
var utils = require('../../common/utils.js');

import NotificationManager from '../../common/components/notification';

import { ProductServices } from '../../product2/services/product-services';

class AddCopyModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: this.props.copyData.status ? this.props.copyData.status : "",
            date: this.props.copyData.date ? this.props.copyData.date : "",
            buyerName: this.props.copyData.buyername ? this.props.copyData.buyername : '',
            isModalOpen: false,
            displayName: this.props.copyData.displayName ? this.props.copyData.displayName : '',
            approvalDisabled: true,
            displayNameMaxLength: this.props.copyData.maxLength ? this.props.copyData.maxLength : 20,
            copyNoteMaxLength: 100
        };
        this.copyData = {
            productId: "",
            displayName: this.props.copyData.displayName ? this.props.copyData.displayName : '',
            shortdesc: this.props.copyData.shortdesc ? this.props.copyData.shortdesc : '',
            longdesdc: this.props.copyData.longdesdc ? this.props.copyData.longdesdc : '',
            copyNotes: this.props.copyData.copyNotes ? this.props.copyData.copyNotes : '',
        };
        this.requiredFields = ["displayName", "shortdesc", "longdesdc"];
        this.AllFields = ["shortdesc", "longdesdc", "displayName"];
        this.validationData = {};
        this.bgClassList = {};
        this.NotEmpty = false;

        this.submitHandler = this.submitHandler.bind(this);
        this.submitForApproval = this.submitForApproval.bind(this);
        this.showCopyNotes = this.showCopyNotes.bind(this);
        this.textareaChange = this.textareaChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.approve = this.approve.bind(this);
        this.reject = this.reject.bind(this);
        this.closeConfirmModal = this.closeConfirmModal.bind(this);
        this.openConfirmModal = this.openConfirmModal.bind(this);
        this.confirmApproveSubmit = this.confirmApproveSubmit.bind(this);
        this.checkObjHasValueInRequiredKeys = this.checkObjHasValueInRequiredKeys.bind(this);
        this.didValueChanged = this.didValueChanged.bind(this);
        this.closeAllModal = this.closeAllModal.bind(this);
        this.confirmSaveChanges = this.confirmSaveChanges.bind(this);
        this.getAllElementValue = this.getAllElementValue.bind(this);
        this.ApplyBGColorClassName = this.ApplyBGColorClassName.bind(this);
        this.ApplyBGColorClassName(); //Call @ only once at the time of loading
    }

    openConfirmModal() {
        this.setState({ isModalOpen: true });
    }

    closeConfirmModal() {
        this.setState({ isModalOpen: false });
    }

    closeAllModal() {
        this.props.modalClose();
        this.closeConfirmModal();
    }

    textareaChange(e) {
        this.copyData.copyNotes = e.target.value;
        if (e.target.value.length > 3) {
            $('.submitBtn').removeAttr('disabled');
        } else {
            $('.submitBtn').attr('disabled', true);
        }
    }

    /**
   *Show Copy notes when click reject button and enable submit btn
   */
    showCopyNotes(e) {
        $(e.currentTarget).addClass('active');
        $('.copynotes').show();
        $('#copynotes').focus();
        if ($('#copynotes').val().length > 3) {
            $('.submitBtn').removeAttr('disabled');
        }
    }

    componentDidMount() {
        var _this = this;
        if ([constants.ROLES.BUYER, constants.ROLES.ADMIN].indexOf(this.props.user.defaultRole) !== -1) {
            $('.submitBtn').attr('disabled', true);
        }

        var contenteditable = "true";
        if ([constants.ROLES.BUYER, constants.ROLES.ADMIN].indexOf(this.props.user.defaultRole) > -1 || window.readableFields.indexOf('copywrite') > -1) {
            contenteditable = "false";
        }

        $('.jqte-test').jqte({
            // $('.jqte-test').jqte({
            change: function () {
                _this.getAllElementValue();
                if (_this.didValueChanged) {
                    $('.saveBtn').removeAttr('disabled');
                    if (["Approved", "Submitted"].indexOf(_this.props.copyData.status) === -1) {
                        _this.setState({ approvalDisabled: false });
                    }
                } else {
                    $('.saveBtn').attr('disabled');
                }
            },
            contenteditable: contenteditable
        });

        // $('.jqte-test').change(
        //   function(){
        //     _this.getAllElementValue();
        //     if(_this.didValueChanged){
        //       $('.saveBtn').removeAttr('disabled');
        //       if(["Approved","Submitted"].indexOf(_this.props.copyData.status)===-1){
        //         _this.setState({approvalDisabled:false});
        //       }
        //     }else{
        //       $('.saveBtn').attr('disabled');
        //     }
        //   });
    }

    approval(data, approve) {
        var _this = this;

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.approveByBuyer(data)
            .then((response) => {
                console.log('productContainer helperComponents add-copy-modal API call success data: ', response.data);
                let res = response.data;

                NotificationManager({ message: res.message, type: 1 });
                var status = 'rejected';
                if (approve) {
                    status = 'approved';
                }
                _this.props.setCellValueAndCopyToChild('copyStatus', status);
                _this.props.modalClose();
            })
            .catch(error => {
                console.log('productContainer helperComponents add-copy-modal API call failed', error);
                NotificationManager({ message: error, type: 4 });
            });
    }

    approve(e) {
        var _this = this;
        $(e.currentTarget).parent().find('.rejectBtn').removeClass('active');
        var postdata = _this.props.productId.map((e, i) => {
            var data = {
                "productcode": e,
                "status": true,
                "processName": "copywritter",
                "copynotes": _this.copyData.copyNotes,
                "userid": this.props.user.userId
            };
            // _this.props.productGrid.setCellValue(e,'isCopywriterApproved',true);
            return data;
        });
        _this.props.setCellValueAndCopyToChild('isCopywriterApproved', true);
        this.approval(postdata, 1);
    }

    reject() {
        var _this = this;
        var postdata = _this.props.productId.map((e, i) => {
            var data = {
                "productcode": e,
                "status": false,
                "processName": "copywritter",
                "copynotes": _this.copyData.copyNotes,
                "userid": this.props.user.userId
            };
            return data;
        });
        if (postdata[0].copynotes.length >= 100) {
            NotificationManager({
                message: 'Please enter less than 100 characters',
                type: 4
            });
            return false;
        } else {
            this.approval(postdata, 0);
        }
    }

    /**
   *
   * @param {object} e
   */
    submitHandler(e) {
        if (e) {
            e.preventDefault();
        }
        var _this = this;
        _this.getAllElementValue();
        console.log(this.copyData);
        this.checkObjHasValueInRequiredKeys(this.copyData);
        if (this.validationData.valid) {
            var postdata = _this.props.productId.map((e, i) => {
                var newobj = Object.assign({}, _this.copyData);
                newobj.productId = e;
                return newobj;
            });


            let headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json'
            };

	  ProductServices.updateCopyWritter(postdata)
                .then((response) => {
                    console.log('productContainer helperComponents add-copy-modal API call success data: ', response.data);
                    let res = response.data;

                    NotificationManager({ message: res.message, type: 1 });

                    _this.props.setCellValueAndCopyToChild('copywrite', '123');
                    _this.props.modalClose();
                })
                .catch(error => {
                    console.log('productContainer helperComponents add-copy-modal API call failed', error);
                    NotificationManager({ message: error, type: 4 });
                });
        } else {
            NotificationManager({ message: "Please enter atlease one description" });
        }
    }

    /**
   *
   * @param {*} e
   */
    submitForApproval(e) {
        e.preventDefault();
        //Validate Keyword data
        this.getAllElementValue();
        this.checkObjHasValueInRequiredKeys(this.copyData);
        if (this.validationData.valid) {
            if (this.validationData.force) {
                this.modalDialog = "SubmitConfirm";
                this.openConfirmModal();
            } else {
                this.confirmApproveSubmit();
            }
        } else {
            NotificationManager({ message: "Please enter atlease one keyword" });
        }
    /**/
    }

    getAllElementValue() {
        let shortDesc = $('.editor-short-desc').val();
        let fullDesc = $('.editor-long-desc').val();
        this.copyData.shortdesc = shortDesc ? $.trim(shortDesc) : "";
        this.copyData.longdesdc = fullDesc ? $.trim(fullDesc) : "";
        this.copyData.displayName = $.trim($('.displayName').val());
    }

    confirmApproveSubmit() {
        var _this = this;
        _this.getAllElementValue();
        var postdata = _this.props.productId.map((e, i) => {
            var newobj = Object.assign({}, _this.copyData);
            newobj.productId = e;
            return newobj;
        });


        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.submitCopyWritter(postdata)
            .then((response) => {
                console.log('productContainer helperComponents add-copy-modal API call success data: ', response.data);
                let res = response.data;

                NotificationManager({ message: res.message, type: 1 });
                _this.props.setCellValueAndCopyToChild('copyStatus', 'submitted');
                _this.props.modalClose();
            })
            .catch(error => {
                console.log('productContainer helperComponents add-copy-modal API call failed', error);
                NotificationManager({ message: error, type: 4 });
            });
    }

    checkObjHasValueInRequiredKeys(obj) {
        let needcount = this.requiredFields.length;
        this.validationData = { valid: false, validcount: 0, needcount: needcount };
        for (var i = 0; i < needcount; i++) {
            var val = obj[this.requiredFields[i]];
            val = $('<div/>').html(val).text();
            if (val != null && val != undefined && val != "" && val.localeCompare("") != 0) {
                this.validationData.valid = true;
                this.validationData.validcount++;
            }
        }
        if (this.validationData.validcount > 0 && this.validationData.validcount !== this.requiredFields.length) {
            this.validationData.force = true;
        }
    }

    /** Showing popup to ask confirmation to cancel(Yes/No/Cancel) */
    confirmSaveChanges() {
        this.getAllElementValue();
        if (this.didValueChanged() && [constants.ROLES.BUYER].indexOf(this.props.user.defaultRole) === -1) {
            this.modalDialog = "SaveChangesCancel";
            this.openConfirmModal();
        } else {
            this.props.modalClose();
        }
    }

    didValueChanged() {
        let isChanged = false;
        for (var i = 0; i < this.AllFields.length; i++) {
            let prev = this.props.copyData[this.AllFields[i]];
            let next = this.copyData[this.AllFields[i]];
            prev = prev !== null ? $('<div/>').html(prev).text() : "";
            next = next !== null ? $('<div/>').html(next).text() : "";
            if (prev.localeCompare(next) !== 0) {
                isChanged = true;
                break;
            }
        }
        return isChanged;
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.copyData[name] = value;
        this.setState({ [name]: value });
        if (this.didValueChanged) {
            $('.saveBtn').removeAttr('disabled');
            if (["Approved", "Submitted"].indexOf(this.props.copyData.status) === -1) {
                this.setState({ approvalDisabled: false });
            }
        } else {
            $('.saveBtn').attr('disabled');
            this.setState({ approvalDisabled: true });
        }
    }

    /** Apply bg color based on status */
    ApplyBGColorClassName() {
        let currentStatus = this.state.status.toLowerCase();
        for (var i = 0; i < this.requiredFields.length; i++) {
            this.bgClassList[this.requiredFields[i]] = "";
            if (this.props.copyData[this.requiredFields[i]]) {
                this.bgClassList[this.requiredFields[i]] = 'notempty';
                this.NotEmpty = true;
                if (['approved', 'rejected', 'submitted'].indexOf(currentStatus) !== -1) {
                    this.bgClassList[this.requiredFields[i]] = currentStatus;
                }
            }
        }
    }

    render() {
        console.log('productContainer addCopyModal render props: ', this.props);

        let tools = ' font size | bold italic underline | background  | left center right | outdent indent | ul ol';
        let isShowHideCopyNotes = { display: "none" },
            isCopyNotesDisabled = "true",
            isShowApproveRejectBtn = false,
            isDisableRejectBtn = false,
            isDisableApproveBtn = false,
            statusClassName = "",
            showStatusSec = false,
            currentStatus = this.state.status.toLowerCase(),
            popupheader = "Add/Edit Copy",
            dataString = this.props.copyData.date,
            date = new Date(dataString),
            dateFormat = constants.MONTHNAMEOFYEAR[date.getMonth()] + " " + utils.nth(date.getDate()) + " " + date.getFullYear(),
            modalClass = 'ConfirmSubmitModal',
            formFieldDisable = false;

        if (currentStatus === 'approved' || currentStatus === '') {
            isDisableApproveBtn = true;
            isDisableRejectBtn = true;
        }
        if (["rejected", "approved"].indexOf(currentStatus) !== -1) {
            isDisableRejectBtn = true;
        }
        if (this.copyData.copyNotes && this.copyData.copyNotes !== "") {
            isShowHideCopyNotes = {};
        }
        if (this.state.status) {
            statusClassName = currentStatus;
            if (['approved', 'rejected'].indexOf(currentStatus) !== -1) {
                showStatusSec = true;
            }
        }
        if (['approved', 'submitted', 'rejected'].indexOf(currentStatus) === -1 && this.NotEmpty) {
            this.state.approvalDisabled = false;
        }
        if ([constants.ROLES.BUYER].indexOf(this.props.user.defaultRole) !== -1) {
            popupheader = "Approve Copy";
            isShowApproveRejectBtn = true;
            formFieldDisable = true;
            isCopyNotesDisabled = "";
            this.state.approvalDisabled = true;
        }
        if ([constants.ROLES.ADMIN].indexOf(this.props.user.defaultRole) !== -1 || window.readableFields.indexOf('copywrite') > -1) {
            popupheader = "View Copy";
            isShowApproveRejectBtn = false;
            formFieldDisable = true;
            this.state.approvalDisabled = true;
            isCopyNotesDisabled = "true";
        }

        return (

            <div>
                <div className="modal-header">
                    {popupheader}
                    <span className="close" onClick={this.confirmSaveChanges}>x</span>
                </div>
                <div className="modal-content">
                    <div className="add-copy modal-form">
                        <form className="addCopyDetails" name="addCopyDetails" onSubmit={this.submitHandler}>
                            <div className="form-container scroll">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <div className="prodName">
                                                {this.props.currentRowData.prodName}
                                            </div>
                                            {showStatusSec &&
                        <div className={"status " + statusClassName}>{this.state.status}</div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Display Name</label>
                                            <input type="text" className={"form-control col-12 displayName " + this.bgClassList.displayName} name="displayName" value={this.state.displayName} onChange={this.handleChange} maxLength={this.state.displayNameMaxLength} disabled={formFieldDisable}></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Product Copy</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className={"description " + this.bgClassList.shortdesc}>
                                            <textarea name="textarea" className="jqte-test editor-short-desc" value={this.copyData.shortdesc} />

                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Product Details</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className={"description " + this.bgClassList.longdesdc}>
                                            <textarea name="textarea" className="jqte-test editor-long-desc" value={this.copyData.longdesdc} />
                                            {/*
                        <JqxEditor ref={(editor)=>{this.fullDesc= editor}} min-height={80} disabled={formFieldDisable}>
                          {this.copyData.longdesdc}
                        </JqxEditor>
                        */}
                                        </div>
                                    </div>
                                </div>
                                {isShowApproveRejectBtn &&
                  <div className="row">
                      <div className="col-12 addcopy-buyer-action">
                          <input type="button" disabled={isDisableRejectBtn} className="gray-button rejectBtn" value="Reject" onClick={this.showCopyNotes} />
                          <input type="button" disabled={isDisableApproveBtn} className="gray-button" value="Approve" onClick={this.approve} />
                      </div>
                  </div>
                                }
                                <div className="row copynotes" style={isShowHideCopyNotes}>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Copy Notes</label>
                                            <textarea disabled={isCopyNotesDisabled} className="form-control col-12 texrarea-fld" name="copynotes" id="copynotes" maxLength={this.state.copyNoteMaxLength} cols="7" onChange={this.textareaChange} value={this.copyData.copyNotes} />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {showStatusSec &&
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <p>&lt;{this.state.status}&gt; by {this.state.buyerName}, {dateFormat}</p>
                        </div>
                    </div>
                </div>
                            }
                            {isShowApproveRejectBtn &&
                <div className="modal-footer text-center">
                    <input type="button" className="gray-button submitBtn" value="Submit" onClick={this.reject} />
                    {this.props.user.defaultRole === constants.ROLES.ADMIN &&
                    <input type="submit" className="gray-button saveBtn" value="Save" />
                    }
                </div>
                            }
                            {!isShowApproveRejectBtn &&
                <div className="modal-footer text-right">
                    <input type="submit" disabled="true" className="gray-button saveBtn" value="Save" />
                    <input type="button" disabled={this.state.approvalDisabled} className="gray-button submitApprove" value="Submit for Approval" onClick={this.submitForApproval} />
                </div>
                            }
                        </form>
                    </div>
                </div>
                {this.state.isModalOpen &&
          <Modal isOpen={this.state.isModalOpen} addClass={modalClass}>
              <ConfirmSubmit dialog={this.modalDialog} modalClose={this.closeConfirmModal} confirmApproveSubmit={this.confirmApproveSubmit}
                  validationData={this.validationData} yesSaveChange={this.submitHandler} noSaveChange={this.closeAllModal}>

              </ConfirmSubmit>
          </Modal>
                }
            </div>
        );
    }
}

export default AddCopyModel;
