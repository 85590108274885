import React from 'react';
import axios from 'axios';
import {endpoint} from '../REST/endpoints';
import {headers} from '../REST/headers';
import request from '../REST/request';

export const CommonServices = {
    getGridView: function(dataObj) {
        return request({
            method: endpoint.getGridView.method,
            url: endpoint.getGridView.url,
            headers: headers,
            data: dataObj
        })
            .then((response) => {
                if (response.data && (response.data.status === 'success' || response.data.status === true)) {
                    return response.data;
                } else {
                    return Promise.reject(response);
                }
            })
            .catch(error => {
                console.error('leftDrawerContainer getProductCategory API call failed', error);

                return Promise.reject(error);
            });
    },
    login: function(payload) {
        return request({
            method: endpoint.login.method,
            url: endpoint.login.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response;
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },
    logout: function(payload) {
        return request({
            method: endpoint.logout.method,
            url: endpoint.logout.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },
    getViews: function(payload) {
        return request({
            method: endpoint.view.getViews.method,
            url: endpoint.view.getViews.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    addorupdatevendor: function(payload) {
        return request({
            method: endpoint.vendor.addorupdatevendor.method,
            url: endpoint.vendor.addorupdatevendor.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    listsvendor: function(payload) {
        return request({
            method: endpoint.vendor.listsvendor.method,
            url: endpoint.vendor.listsvendor.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    deletevendor: function(payload) {
        return request({
            method: endpoint.vendor.deletevendor.method,
            url: endpoint.vendor.deletevendor.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    vendorNameList: function(payload) {
        return request({
            method: endpoint.vendor.vendorNameList.method,
            url: endpoint.vendor.vendorNameList.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    changePassword: function(payload) {
        return request({
            method: endpoint.changePassword.method,
            url: endpoint.changePassword.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response;
            })
            .catch(error => {
                return Promise.reject(error);
            });
    },
    postData: function(payload) {
        return request({
            method: endpoint.postData.method,
            url: endpoint.postData.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    personalAlerts: function(payload) {
        return request({
            method: endpoint.personalAlerts.method,
            url: endpoint.personalAlerts.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    },
    addVendor: function(payload) {
        return request({
            method: endpoint.addVendor.method,
            url: endpoint.addVendor.url,
            headers: headers,
            data: payload || ''
        })
            .then((response) => {
                return response.data;
            })
            .catch(error => {
                return error;
            });
    }
};
