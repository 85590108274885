import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Modal from '../modals/common-modal';
import ConfirmModal from '../modals/confirm-modal';
import AdminFooter from './helperComponents/publishing-footer';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import JqxGrid from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';

import GridDataBuilder from './helperComponents/data-builder';
import NotificationManager from '../common/components/notification';
import PublishingComponent from './publishingComponent';
import axios from 'axios';

class Publishing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isModalOpen: false,
            publishingList: {},
            updatedPublishingList: [],
            isModified: false,
        };

        this.prevGridData = null;
        this.applyChanges = this.applyChanges.bind(this);
        this.gridRerender = this.gridRerender.bind(this);
    }

    componentDidMount() {
        this.initializeGrid();
    }

    /**
     * Calls backend to get data
     */
    initializeGrid() {
        const _this = this;


        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        axios({
            method: 'get',
            url: require('../common/configurations').publishing.getPublishing,
            headers: headers,
        })
            .then((response) => {
                console.log('publishingContainer initialize-grid API call success data: ', response.data);
                const res = response.data;

                if (res.status) {
                    _this.prevGridData = Object.assign({}, res);
                    _this.gridData = GridDataBuilder(res);
                    _this.setState({ isLoaded: true, publishingList: res });
                }
            })
            .catch((error) => {
                console.log('publishingContainer initialize-grid API call failed', error);
                NotificationManager({ message: error + 'Error:Response failed, Please try again.', type: 4 });
            });
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevState.isLoaded !== this.state.isLoaded) {
            const _this = this;
            const publishGrid = $('#publishingGrid');
            publishGrid.off();

            publishGrid.on('change', '.publishing-view', function (event) {
                const elem = $(this);
                const isChecked = elem.is(':checked');
                const row = elem.data('row');
                const fieldname = elem.data('fieldname');
                const rowData = _this.publishingGrid.getrowdata(row);

                const permissions = _this.updateGroupPermission(rowData, row);
                permissions[fieldname] = (isChecked) ? 'true' : 'false';

                const lastUpdateData = {
                    reportName: rowData.reportName,
                    fieldvalues: rowData.fieldvalues,
                    permission: JSON.stringify(permissions),
                    reporttype: rowData.reporttype,
                    row: row,
                };
                _this.updateNewPublishingList(lastUpdateData, row);
                _this.setState({ isModified: true });

                // Updating cell value
                _this.publishingGrid.setcellvalue(row, fieldname, (isChecked) ? true : false);
            });

            publishGrid.on('click', '.publish-reporturl', function (e) {
                e.preventDefault();
                const report = $(this).data('report');


                const headers = {
                    'Authorization': this.props.user.token,
                    'content-type': 'application/json',
                };

                axios({
                    method: 'post',
                    url: require('../common/configurations').publishing.getReport,
                    data: { id: report },
                    headers: headers,
                })
                    .then((response) => {
                        console.log('publishingContainer componentDidUpdate API call success data: ', response.data);
                        const res = response.data;

                        if (res.status) {
                            const win = window.open(res.data.id, '_blank');
                            win.focus();
                        } else {
                            NotificationManager({
                                message: res.responseMessage,
                                type: 4,
                            });
                        }
                    })
                    .catch((error) => {
                        console.log('publishingContainer componentDidUpdate API call failed', error);
                        NotificationManager({ message: error + 'Error:Response failed, Please try again.', type: 4 });
                    });
            });
        }
    }

    /**
     * Updates permission for group
     * @param {*} data
     * @param {*} rowindex
     * @return {string}
     */
    updateGroupPermission(data, rowindex) {
        const publishingList = Object.assign([], this.state.publishingList);
        return publishingList.data[rowindex].permission;
    }

    /**
     * Updates new publishing list data
     * @param {*} data
     * @param {*} rowid
     */
    updateNewPublishingList(data, rowid) {
        const { updatedPublishingList } = this.state;
        const newList = Object.assign([], updatedPublishingList);
        let updatedObject = false;
        if (newList.length > 0) {
            newList.map((val, key) => {
                if (val.row === rowid) {
                    newList[key] = data;
                    updatedObject = true;
                }
            });
            if (!updatedObject) {
                newList.push(data);
            }
        } else {
            newList.push(data);
        }
        this.setState({ updatedPublishingList: newList });
    }

    /**
     * Renders the grid
     */
    gridRerender() {
        this.setState({ isLoaded: false });
        this.initializeGrid();
    }

    /**
     * Calls backend to save data
     */
    applyChanges() {
        const postData = this.state.updatedPublishingList;
        const _this = this;


        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        axios({
            method: 'post',
            url: require('../common/configurations').publishing.saveReports,
            data: postData,
            headers: headers,
        })
            .then((response) => {
                console.log('publishingComponent applyChanges API call success data: ', response.data);
                const res = response.data;
                if (res.status) {
                    NotificationManager({
                        message: res.responseMessage,
                        type: 3,
                    });
                    _this.setState({ isModified: false });
                } else {
                    NotificationManager({
                        message: res.responseMessage,
                        type: 3,
                    });
                }
            })
            .catch((error) => {
                console.log('publishingComponent applyChanges API call failed', error);
                NotificationManager({ message: 'Error:Response failed, Please try again.', type: 4 });
            });
    }

    render() {
        const footerHeight = 50;
        const headerHeight = 64;
        $('.page-title a').text("ADMIN: Publishing");

        const gridHeight = window.innerHeight - footerHeight - headerHeight;
        const editSettings = {
            saveOnPageChange: true,
            saveOnBlur: true,
            saveOnSelectionChange: true,
            cancelOnEsc: true,
            saveOnEnter: true,
            editSingleCell: true,
            editOnF2: true,
        };

        return (

            <div>
                <PublishingComponent

                    publishingGridRef={(grid) => {
                        this.publishingGrid = grid;
                    }}

                    state={this.state}
                    props={this.props}
                    applyChanges={this.applyChanges}
                    gridRerender={this.gridRerender}
                    gridHeight={gridHeight}
                    editSettings={editSettings}
                    gridData={this.gridData}

                />
            </div>
        );
    }
}

export default Publishing;
