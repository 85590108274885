import React, { Component } from 'react';
import ScanModal from "../modals/scan-modal";

class MobileScanAction extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            modalOpen: false,
        };
    }

    openScanModal() {
        this.setState({
            modalOpen: true,
        });
    }

    closeScanModal() {
        this.setState({
            modalOpen: false,
        }, () => {
            window.location.href = '/dashboard';
        });
    }

    componentWillMount() {
        localStorage.setItem('currentPage', 'scanning');

        console.log('MobileScanAction componentWillMount: ', this.props);

        this.openScanModal();
    }

    render () {
        return (
            <div className='mobile-scan-actions'>
                <ScanModal
                    isOpen = {this.state.modalOpen}
                    modalClose = {this.closeScanModal.bind(this)}
                />
            </div>
        );
    }
}

export default MobileScanAction;
