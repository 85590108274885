import React, { Component } from 'react';
import CancelModalDelete from '../../common/modals/image-cancel.js';
import ImageUpload from './imageUpload';
import axios from 'axios';

// var NotificationManager = require('../../common/notification');
import NotificationManager from '../../common/components/notification';

import { ProductServices } from '../../product2/services/product-services';

class VerticalImageViewer extends Component {
    constructor(props) {
        super(props);
        console.log(props);
        props.imageInfo.images = (props.imageInfo.images ? props.imageInfo.images : []);
        this.state = {
            imageArr: (props.imageInfo.images) ? props.imageInfo.images : [],
            mainImageSrc: (props.imageInfo.images.length > 0) ? props.imageInfo.images[0].imageUrl : '',
            imageName: (props.imageInfo.images.length > 0) ? props.imageInfo.images[0].imageName : '',
            imageId: (props.imageInfo.images.length > 0) ? props.imageInfo.images[0].imageId : '',
            isImageDeletable: (props.imageInfo.images.length > 0) ? props.imageInfo.images[0].deleteFlag : false,
            isCancelDelete: false,
        };

        this.updateMainViewer = this.updateMainViewer.bind(this);
        this.updateImageState = this.updateImageState.bind(this);
        this.getProductId = this.getProductId.bind(this);
        this.getDataLevel = this.getDataLevel.bind(this);
        this.getImageData = this.getImageData.bind(this);
        this.deleteImage = this.deleteImage.bind(this);
        this.CancelModalDelete = this.CancelModalDelete.bind(this);
        this.deleteImageConfirm = this.deleteImageConfirm.bind(this);
    }

    componentDidMount() {
        let pIds = this.getProductId();
        let dataLevel = this.getDataLevel();
        let req = { 'chldProuctId': pIds, dataLevel: dataLevel };

        console.log('vertical image viewer req: ', req);
        this.getImageData(req);
    }

    updateMainViewer(val) {
        var currentSrc = this.state.imageArr[val].imageUrl;
        let imageName = this.state.imageArr[val].imageName;
        let imageId = this.state.imageArr[val].imageId;
        let isImageDeletable = this.state.imageArr[val].deleteFlag;
        this.setState({ mainImageSrc: currentSrc, imageName: imageName, imageId: imageId, isImageDeletable: isImageDeletable });
    }

    updateImageState(obj) {
        let imageState = this.state.imageArr;
        let imageTemp = imageState;
        this.setState({ imageArr: imageTemp });
        if (imageTemp.length > 0) {
            this.updateMainViewer(0);
        }
    }

    getProductId() {
        let base = this.props.imageInfo;
        let pIds = [];
        if (base.dataLevel == "Sku") {
            pIds.push(base.productId);
        } else if (base.dataLevel == "Intm") {
            let arrIntm = base.chldProuctId;
            arrIntm.map(function (id, ind) {
                pIds.push(id);
            });
        } else if (base.dataLevel == "SP") {
            let arrIntm = base.chldProuctId;
            arrIntm.map(function (id, ind) {
                pIds.push(id);
            });
        }
        return pIds;
    }

    getDataLevel() {
        let base = this.props.imageInfo;
        return base.dataLevel;
    }

    getImageData(req) {
        let _this = this;

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

	  ProductServices.getImageLst(req)
	  .then( (response) => {
                console.log('productContainer helperComponents vertical-image-viewer API call success data: ', response.data);

                let imageData = response.data;
                _this.setState({
                    imageArr: (imageData.data[0].images) ? imageData.data[0].images : [],
                    mainImageSrc: (imageData.data[0].images.length > 0) ? imageData.data[0].images[0].imageUrl : '',
                    imageName: (imageData.data[0].images.length > 0) ? imageData.data[0].images[0].imageName : '',
                    imageId: (imageData.data[0].images.length > 0) ? imageData.data[0].images[0].imageId : '',
                    isImageDeletable: (imageData.data[0].images.length > 0) ? imageData.data[0].images[0].deleteFlag : false,

                });
            })
            .catch( error => {
                console.log('productContainer helperComponents vertical-image-viewer API call failed', error);
                NotificationManager({
                    message: "Request failed due to technical reasons, please try again",
                    type: 4
                });
            });
    }

    deleteImage() {
        this.setState({ isCancelDelete: true });
    }

    deleteImageConfirm() {
        let pIds = this.getProductId();
        let dataLevel = this.getDataLevel();
        let imageName = this.state.imageName;

        let req = { 'imageName': imageName, 'chldProuctId': pIds, 'dataLevel': dataLevel };
        let _this = this;


        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.deleteImageUploadData(req)
            .then( (response) => {
                console.log('productContainer helperComponents vertical-image-viewer API call success data: ', response.data);
                let res = response.data;
                if (res.status) {
                    let imageObj = _this.state.imageArr;
                    let currentImageId = _this.state.imageId;

                    var data = $.grep(imageObj, function (e) {
                        return e.imageId != currentImageId;
                    });

                    _this.setState({ imageArr: data, isCancelDelete: false });

                    if (data.length > 0) {
                        _this.setState({
                            mainImageSrc: data[0].imageUrl,
                            imageName: data[0].imageName,
                            imageId: data[0].imageId,
                            isImageDeletable: data[0].deleteFlag

                        });
                    }
                    else {
                        _this.setState({
                            mainImageSrc: '',
                            imageName: '',
                            imageId: '',
                            isImageDeletable: false

                        });
                    }

                    let obj = {};
                    obj.rowId = _this.props.imageInfo.uid;
                    obj.fieldName = 'images';
                    obj.data = data;

                    _this.props.updateImage(obj);
                    NotificationManager({
                        message: res.responseMessage,
                        type: 1
                    });
                }
                else {
                    NotificationManager({
                        message: res.responseMessage,
                        type: 4
                    });
                    _this.setState({ isCancelDelete: false });
                }
            })
            .catch( error => {
                console.log('productContainer helperComponents vertical-image-viewer API call failed', error);
                NotificationManager({
                    message: "Something went wrong.Please try again after some time.",
                    type: 4
                });
                _this.setState({ isCancelDelete: false });
            });
    }

    CancelModalDelete(action) {
        let _this = this;

        switch (action) {
            case 'yes': _this.deleteImageConfirm();
                break;
            case 'no': _this.setState({ isCancelDelete: false });
        }
    }



    render() {
        console.log('vertical image viewer props', this.props);
        let props = this.props;
        return (
            <div className="vertical-image-holder-for-product">
                <div className="rowHolder">
                    <div className="thumbnail-holder scroll">
                        <ul>
                            {
                                this.state.imageArr.map((item, index) => (
                                    <li className="thumbnails" key={index}
                                        onClick={() => (this.updateMainViewer(index))}>
                                        <img src={item.thumbNailUrl ? item.thumbNailUrl : item.imageUrl} />
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className="main-image">
                        {this.state.mainImageSrc &&
              <div className="productImageContainer">
                  {this.props.modalName != 'publish_image' && this.state.isImageDeletable &&
                  <span className="closeIcon" onClick={this.deleteImage}>X</span>
                  }
                  <img src={this.state.mainImageSrc} />
              </div>
                        }
                        {!this.state.mainImageSrc &&
              <p>No Image</p>}

                    </div>
                </div>

                <ImageUpload {...props} imageArray={this.state.imageArr} imageData={this.updateImageState} />

                <CancelModalDelete isOpen={this.state.isCancelDelete} modalClose={this.CancelModalDelete} />

            </div>
        );
    }
}

export default VerticalImageViewer;


// {this.props.modalName != 'publish_image' &&
// <ImageUpload {...props} imageArray={this.state.imageArr} imageData={this.updateImageState} />
// }
