export const gridData = (state = [], action) => {
    switch (action.type) {
        case "ADD_GRID_DETAILS":
            return action.data;
        case "UPDATE_GRID_DATA":
            return [...state, ...action.updateData];
        default:
            return  state;
    }
};

let gridDataChange = 0, gridColumnChange = 0;
export const gridDataUpdate = (state = {gridDataChange, gridColumnChange}, action) =>{
    switch(action.type) {
        case "GRID_DATA_CHANGED":
            return {
                gridDataChange: ++gridDataChange,
                gridColumnChange
            };
        case "GRID_COLUMN_CHANGED":
            return {
                gridDataChange,
                gridColumnChange: ++gridColumnChange
            };
        default:
            return state;
    }
};

export const columnDetails = (state = [], action) =>{
    switch(action.type) {
        case "ADD_COLUMN_DETAILS":
            return action.data;
        default:
            return state;
    }
};


export const allEventDetails = (state = {}, action) =>{
    switch(action.type) {
        case "ADD_ALL_EVENT_DETAILS":
            return action.data;
        default:
            return state;
    }
};

export const eventTaskData = (state = {}, action) =>{
    switch(action.type) {
        case "ADD_EVENT_TASK_DATA":
            return action.data;
        default:
            return state;
    }
};

export const selectedRows = (state = [], action) => {
    switch (action.type) {
        case "UPDATE_SELECTED_ROWS":
            return action.data ;
        default:
            return  state;
    }
};
