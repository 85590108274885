import React from 'react';

class FeatureColorList extends React.Component {
    render() {
        let props = this.props;
        let colorList = props.colorList ? props.colorList : [];
        let MakeItem = function (x, i) {
            return <option key={i} value={x}>{x}</option>;
        };

        console.log('feature color list: ', this.props);

        return (
            <div className="modal-content">
                <div className="text-center confirm-delete">
                    <p>Select feature color</p>
                    <select className="grid-swatch-reference">{colorList.map(MakeItem)}</select>
                </div>
                <div className="modal-footer text-center">
                    <button className="gray-button"
                        onClick={() => {
                            var value = $('.grid-swatch-reference').val();
                            props.setCellValueAndSaveProduct(props.productId, 'featureColor', value);
                            props.modalClose();
                        }}>
						ok
              		</button>
                    <button className="gray-button" onClick={props.modalClose}>Cancel</button>
                </div>
            </div>
        );
    }
}

export default FeatureColorList;
