import React, { Component } from 'react';
import Proptypes from 'prop-types';

const FindComponent = ({
    state,
    findResults,
    MakeItem,
    up,
    down,
}) => {
    return (
        <div className={`find-section ${(state.openFind) ? 'open' : ''}`}>
            <div className="find-box">
                <div className="find-content">
                    <input type="text" id="find-text" placeholder="search" className="find-text" onKeyUp={findResults} />
                    <label><span className="focuscount">{state.focuscount}</span>/<span className="totres">{state.totalcount}</span></label>
                </div>
                <div className="find-controls">
                    <span className="up" onClick={up} title="Previous">&lt;</span>
                    <span className="down" onClick={down} title="Next">&gt;</span>
                    <span className="close-find">x</span>
                </div>
            </div>
            {state.openSuggestion && state.suggesions.length > 0 &&
                    <div className="suggestion scroll">
                        <ul>
                            {state.suggesions.map(MakeItem)}
                        </ul>
                    </div>
            }
        </div>
    );
};

FindComponent.Proptypes = {
    state: Proptypes.object,
    findResults: Proptypes.func,
    up: Proptypes.func,
    down: Proptypes.func,
};

export default FindComponent;
