let serviceUrl = SERVICE_URL;

if (!serviceUrl) {
    serviceUrl = `${window.location.protocol}//api.${window.location.hostname}`;
}

module.exports = {
    apiURL: `${serviceUrl}`,
    login: `${serviceUrl}/api/login`,
    userData: `${serviceUrl}/api/userdata`,
    logout: `${serviceUrl}/user/logout`,

    product: {
        listProducts: `${serviceUrl}/product/listproducts`,
        listSamples: `${serviceUrl}/product/listSamples`,
        updateOrAddProduct: `${serviceUrl}/product/addorupdateproduct`,

        productActivity: `${serviceUrl}/product/activitistatus`,
        activityNotesUpdate: `${serviceUrl}/product/updateactivenotes`,
        itemReadyCount: `${serviceUrl}/product/activestatuscount`,
        activeProducts: `${serviceUrl}/product/activeproducts`,
        addRecordFiles: `${serviceUrl}/Import_Export/importFile`, //upload?
        gridView: `${serviceUrl}/view/viewList`,
        deleteRecord: `${serviceUrl}/api/products/delete`,
        swatchUpdate: `${serviceUrl}/product/swatchupdate`, // post
        requiredFields: `${serviceUrl}/user/getAllMandatoryFields`,
        getProductId: `${serviceUrl}/product/getproductid`,
        productcategories: `${serviceUrl}/productcategory/productcategories`,
        filterproductcategory: `${serviceUrl}/productcategory/filterproductcategory`, // cant find
        listoperandsnoperators: `${serviceUrl}/customfilter/listoperandsnoperators`,
        updateKeywords: `${serviceUrl}/webproducer/save`,
        submitKeywords: `${serviceUrl}/webproducer/submit`,
        getKeywords: `${serviceUrl}/webproducer/keywords`,
        updateCopyWritter: `${serviceUrl}/copywritter/save`,
        submitCopyWritter: `${serviceUrl}/copywritter/submit`,
        viewColumndetails: `${serviceUrl}/view/getColumnDetails`,
        getCopyWritter: `${serviceUrl}/copywritter/copynotes`,
        saveNewView: `${serviceUrl}/view/insertviewname`,
        approveByBuyer: `${serviceUrl}/buyer/buyerapproval`,
        isViewExists: `${serviceUrl}/view/isviewexists`,
        filters: `${serviceUrl}/product/filters`,
        productsearch: `${serviceUrl}/product/productsearch`,
        deleteView: `${serviceUrl}/view/deleteview`,
        piRecieved: `${serviceUrl}/pi/updatePIRecevied`,
        piApproved: `${serviceUrl}/pi/updatePIApproved`,
        piRequestSent: `${serviceUrl}/pi/piRequestSent`,
        getSamplesData: `${serviceUrl}/sample/getsamplesdata`,
        receiveSamples: `${serviceUrl}/sample/recievesample`,
        moveSample: `${serviceUrl}/sample/movesample`,
        getSampleIds: `${serviceUrl}/sample/sampleID`,
        imageUpload: `${serviceUrl}/product/storeimage2`,
        sampleApproved: `${serviceUrl}/product/sampleApproved`,
        itemReadyToOrder: `${serviceUrl}/product/updateallmandatoryfields`,
        importPOFile: `${serviceUrl}/Import_Export/importPOFile`,
        addPO: `${serviceUrl}/Po/addorupdatepo`,
        generateSampleCard: `${serviceUrl}/sample/genarateSamplecard`,
        isSampleRecevied: `${serviceUrl}/product/sampleRecevied`,
        userDelete: `${serviceUrl}/product/deleterestoreproduct`,
        updateSample: `${serviceUrl}/sample/updateSampleID`,
        updateProductSample: `${serviceUrl}/sample/updateProduct`,
        saveSamples: `${serviceUrl}/sample/savesamples`,
        readyToActivate: `${serviceUrl}/product/readyToActivate`,
        attrAproved: `${serviceUrl}/product/attrAproved`,
        importFilePIUpload: `${serviceUrl}/Import_Export/importFilePIUpload`,
        importFilePIUploadpdf: `${serviceUrl}/pi/uploadPdfFile`,
        getPiForProduct: `${serviceUrl}/pi/pdfPath`,
        deletePiForProduct: `${serviceUrl}/pi/deleteFile`,
        piRequestEarly: `${serviceUrl}/pi/piRequestEarly`,
        resendPiRequest: `${serviceUrl}/pi/resendPiRequest`,
        resizeColOrderUpdate: `${serviceUrl}/resizeColOrderUpdate`,
        updateAttrCompleted: `${serviceUrl}/product/attributecompleteupdate`,
        activateOnWebsite: `${serviceUrl}/product/activateWebsiteNow`,
        saveScanner: `${serviceUrl}/product/saveScanner`,
        createParentChld: `${serviceUrl}/product/createParentChld`,
        saveParentChld: `${serviceUrl}/product/saveParentChld`,
        getLevelList: `${serviceUrl}/product/getLevelList`,
        groupByPopUp: `${serviceUrl}/product/groupByPopUp`,
        listproductEvents: `${serviceUrl}/productevent/listproductEvents`,
        saveorupdateproductevent: `${serviceUrl}/productevent/saveorupdateproductevent`,
        earlyRequestSampleSwatch: `${serviceUrl}/product/updateearlyreqsampleswatch`,
        backOrder: `${serviceUrl}/product/backOrder`,
        moveSampleRequest: `${serviceUrl}/product/moveSample`,
        samleMoveCompleted: `${serviceUrl}/product/moveSamplechk`,
        getcloneProductIds: `${serviceUrl}/product/getchldproductid`,
        activeliveeupdate: `${serviceUrl}/product/activeliveeupdate`,
        forceActivateOnWebsite: `${serviceUrl}/product/forceActivateOnWebsite`,
        getAngles: `${serviceUrl}/category/getAngles`,

        saveAngles: `${serviceUrl}/creativePacket/saveShotAngles`,
        sessionLock: `${serviceUrl}/product/sessionLock`,
        getAdminSettings: `${serviceUrl}/getmaxlengthvalue`,
        exportPO: `${serviceUrl}/Import_Export/exportPO`,
        sampleRequestSent: `${serviceUrl}/product/sampleRequestSent`,
        getAttributeDropDown: `${serviceUrl}/product/getAttributeDropDown`,
        getImageLst: `${serviceUrl}/product/getImageLst`,
        deleteImageUploadData: `${serviceUrl}/product/deleteImageUploadData`,
        getFeatureColor: `${serviceUrl}/product/getFeatureColor`,
        releaseLock: `${serviceUrl}/product/releaseLock`,
    },
    product2: {
        search: `${serviceUrl}/api/products/search`,
        addupdate: `${serviceUrl}/api/products/addupdate`,
        dropdownData: `${serviceUrl}/api/products/dropdownData`,
        addorupdatepo: `${serviceUrl}/api/po/addorupdatepo`,
        imageUploadV2: `${serviceUrl}/api/products/addimage`, // new upload image API
        deleteImageUploadDataV2: `${serviceUrl}/api/products/removeimage`,  // new image delete API
        addRecordFiles: `${serviceUrl}/api/import/uploadProducts`, //upload?
        swatchReferenceList: `${serviceUrl}/api/products/swatchReferences`,
        updateSwatchReference: `${serviceUrl}/api/products/updateSampleSwatch`,
        getWESeasonsData: `${serviceUrl}/api/products/we/seasonPopup`,
        addOrUpdateSeasonPopup: `${serviceUrl}/api/products/we/addOrUpdateseasonPopup`,
        bulkUpdate: `${serviceUrl}/api/products/bulkUpdate`,
        saveOrSendMessage: `${serviceUrl}/api/messaging/save`, // message column API
        getMessages: `${serviceUrl}/api/messaging/view/product/`, // message column API
        readMessage: `${serviceUrl}/api/messaging/read/`, // message column API
        usersList: `${serviceUrl}/user/usersList`, // new api to get all users
        excelUpload: `${serviceUrl}/api/import/products`, // new api for excel files upload
        excelUploadHistory: `${serviceUrl}/api/import/products/history/`, // new api for excel files upload
        excelUploadStatus: `${serviceUrl}/api/import/products/read/`, // new api for excel files upload - polling api
        uploadFiles: `${serviceUrl}/api/v1/uploadFile/`, // new api for general file upload
        downloadFile: `${serviceUrl}/api/v1/download?`, // new api for general file download
        imageUploadV2: `${serviceUrl}/api/v1/media/upload/product/`, // new api for image upload - multiple images - s3
        getImageDataV2: `${serviceUrl}/api/v1/media/product/`, // new api to get uploaded image data from s3
        downloadImageV2: `${serviceUrl}/api/v1/media/download/product/`, // new api to get uploaded image data from s3
        updateImageTags: `${serviceUrl}/api/v1/media/tag/product/`, // new api to update image tags in s3
        deleteS3Image: `${serviceUrl}/api/v1/media/delete/product/`, // new api to delete image from s3
        exportToMagento: `${serviceUrl}/api/export/magento-file`, // new api to export excel for magento
        copyUpload: `${serviceUrl}/api/import/products/copy-update`, // new api to upload copy excel
        generateLaunchPlan: `${serviceUrl}/api/v1/reports/generate/launch/product/`, // new api to upload copy excel
        getMultiFiles: `${serviceUrl}/api/v1/docs/list`, // new api for multiple file upload
        uploadMultiFiles: `${serviceUrl}/api/v1/docs/upload`, // new api for multiple file upload
        deleteMultiFiles: `${serviceUrl}/api/v1/docs/delete`, // new api to delete multiple files
    },
    creative: {
        filterData: `${serviceUrl}/creative/filterData`,
        gridData: `${serviceUrl}/api/creative/gridData`, // new creative API
        getMaxId: `${serviceUrl}/creativePacket/getMaxId`,
        addOrUpdatePicturePacket: `${serviceUrl}/creativePacket/addorupdatepicturepacket`, //-old-
        deletePicturePacket: `${serviceUrl}/creativePacket/deletepicturepacket`,
        updateTaskName: `${serviceUrl}/creativePacket/updateTaskName`, //-old-
        getAcceptedImages: `${serviceUrl}/creative/getAcceptedImages`,
        acceptBuyerResult: `${serviceUrl}/creative/acceptBuyerResult`,
        updatePhotographFlag: `${serviceUrl}/creativePacket/updatePhotographFlag`,
        updateRetoucherFlag: `${serviceUrl}/creativePacket/updateRetoucherFlag`,
        updateLocation: `${serviceUrl}/creativePacket/updatelocation`,
        findTaskNameRslt: `${serviceUrl}/studioManger/findTaskNameRslt`,
        updateImageName: `${serviceUrl}/creativePacket/updateImageName`, //-old-
        listoperandsnoperators: `${serviceUrl}/genericFilter/listoperandsnoperators`, // filter apis
        listproductcreative: `${serviceUrl}/creative/listproductcreative`, // filter apis
        filterSearchData: `${serviceUrl}/creative/filterSearchData`, // filter apis
        getAllProductId: `${serviceUrl}/creative/getAllProductId`, //-old-
        addShots: `${serviceUrl}/api/creative/addUpdateShot`, // new api for add shots
        deleteShot: `${serviceUrl}/api/creative/deleteShots`, // new api to delete shots
        sendAlertCreative: `${serviceUrl}/api/creative/sendAlert`, // new send alert API
        photographyComplete: `${serviceUrl}/api/creative/complete`, // new photography API
        buyerReview: `${serviceUrl}/api/creative/buyerReview`, // new approve/reject photography API
        loadImages: `${serviceUrl}/api/creative/loadImages`, // new image api
        creativeSearch: `${serviceUrl}/api/creative/search`, // new search api
    },
    mandate: {
        mandatory: `${serviceUrl}/admin/mandatory`,
        mandatoryUpdate: `${serviceUrl}/admin/updateMandatoryFields`
    },
    myaccount: {
        getUserData: `${serviceUrl}/user/getUserData`,
        insertUsersData: `${serviceUrl}/user/insertUsersData`
    },
    admin: {
        getRoleList: `${serviceUrl}/getRoleList`,
        getUserData: `${serviceUrl}/getUserData`,
        insertUsersData: `${serviceUrl}/admin/insertUsersData`,
        updateUserStatus: `${serviceUrl}/admin/updateUserStatus`,
        deleteUserData: `${serviceUrl}/admin/deleteuserdata`,
        addOrUpdateRule: `${serviceUrl}/api/rules/addUpdate`,
        cloneRule: `${serviceUrl}/rule/clonerule `,
        pauseRule: `${serviceUrl}/rule/pauserule`,
        deleteRule: `${serviceUrl}/rule/deleterule`,
        resetPassword: `${serviceUrl}/admin/resetPassword`,
        getUserMandatoryfields: `${serviceUrl}/admin/getusermandatoryfields`,
        updateUserMandatoryfields: `${serviceUrl}/admin/updateusermandatoryfields`,
        dropdownList: `${serviceUrl}/dropdownlist`,
        getDropdownData: `${serviceUrl}/getdropdowndata`,
        saveOrUpdateDropdownData: `${serviceUrl}/saveorupdatedropdowndata`,
        deleteDropdownData: `${serviceUrl}/deletedropdowndata`,
        getAuditList: `${serviceUrl}/admin/getAuditList`,
        getAdminsettingsVal: `${serviceUrl}/getadminsettingsval`,
        updateAdminsettingsVal: `${serviceUrl}/updateadminsettingsval`,
        getUserGroupList: `${serviceUrl}/getusergrouplist`,
        getAccomplishmentsList: `${serviceUrl}/getaccomplishmentslist`,
        updateAccomplishmentsData: `${serviceUrl}/updateaccomplishmentsdata`,
        getTemplateNames: `${serviceUrl}/admin/gettemplatenames`,
        saveTemplates: `${serviceUrl}/admin/savetemplates`,
        getTemplate: `${serviceUrl}/admin/getatemplate`,
        addField: `${serviceUrl}/admin/addfield`,
        getJobData: `${serviceUrl}/jobSettings/getData`,
        updateJobData: `${serviceUrl}/jobSettings/updateData`,
        deleteFieldRoles: `${serviceUrl}/admin/deleteFieldRoles`,
        userList: `${serviceUrl}/admin/usersList`,
        insertOrUpdate: `${serviceUrl}/admin/insertUsersData`,
        getAlertList2: `${serviceUrl}/api/alerts/alertsGrid`, // new api
        addAlert2: `${serviceUrl}/api/alerts/addUpdate`, // new api
        updateAlert2: `${serviceUrl}/api/alerts/updateAlertGrid`, // new api
        deleteAlert: `${serviceUrl}/alert/deletealertdata`, // new api
        getRulesForAlerts: `${serviceUrl}/rule/listrules`,
        getRulesList: `${serviceUrl}/api/rules/rulesGrid`,
        getRequiredFields: `${serviceUrl}/admin/getAllMandatoryFields`,
        updateRequiredFields: `${serviceUrl}/admin/udateAllMandatoryFields`,
        updateUserGlobalPermission: `${serviceUrl}/admin/updateUserGlobalPermission`,
        getFieldPermissions: `${serviceUrl}/admin/fieldPermissionFinal`,
        updateFieldPermissions: `${serviceUrl}/admin/updateFieldPermissionList`,
        getUserGlobalPermission: `${serviceUrl}/admin/getUserGlobalPermission`,
        filterOperators: `${serviceUrl}/api/rules/formData`,
        recentMessage: `${serviceUrl}/user/recentmessage`,
        insertUserGroups: `${serviceUrl}/insertUserGroups`,
        getUserGroupsList: `${serviceUrl}/getUserGroupsList`,
        updateUserGroupStatus: `${serviceUrl}/updateUserGroupStatus`,
        updateGroupPermissionList: `${serviceUrl}/updateGroupPermissionList`,
        isUserMapped: `${serviceUrl}/isUserMapped`
    },
    publishing: {
        getPublishing: `${serviceUrl}/reports/getPublishing`,
        saveReports: `${serviceUrl}/reports/saveReports`,
        getReport: `${serviceUrl}/reports/getReport`
    },
    view: {
        getViews: `${serviceUrl}/view/getViews`
    },
    vendor: {
        addorupdatevendor: `${serviceUrl}/admin/addorupdatevendor`,
        listsvendor: `${serviceUrl}/admin/listsvendor`,
        deletevendor: `${serviceUrl}/admin/deletevendor`,
        vendorNameList: `${serviceUrl}/vendor/vendornameslist`,
        updatevendorstatus: `${serviceUrl}/admin/updatevendorstatus`
    },
    workflowAllocation: {
        getWorkflowData: `${serviceUrl}/getworkflowalloclist`,
        updateWorkflowData: `${serviceUrl}/updateworkflowalloc`
    },
    marketing: {
        getGridData: `${serviceUrl}/events/listEventsView`,
        addEventDataDetails: `${serviceUrl}/events/addEventColumndetails`,
        addOrUpdateEvents: `${serviceUrl}/events/addorupdateEvents`,
        getEventDetails: `${serviceUrl}/events/eventDetailsPage`,
        approvedByAdmin: `${serviceUrl}/events/approvedByAdmin`,
        rejectEvent: `${serviceUrl}/events/adminRejectComments`,
        deleteEvent: `${serviceUrl}/events/deleteEvents`,
        updateEventStatus: `${serviceUrl}/events/updateEventStatus`,
        listOfOperends: `${serviceUrl}/genericFilter/eventoperandsnoperators`,
        searchValue: `${serviceUrl}/events/filterSearchData`,
        customFilter: `${serviceUrl}/events/eventfilters`,
        getEventBrief: `${serviceUrl}/events/getEventBrief`,
        submitEventBrief: `${serviceUrl}/events/submitEventTasks`,
        saveEventBrief: `${serviceUrl}/events/addEventBrief`,
        getPhotos: `${serviceUrl}/events/getLayout`,
        addPhotos: `${serviceUrl}/events/addphotosOrLayout`,
        submitPhotos: `${serviceUrl}/events/submitphotosOrLayout`,
        approveReject: `${serviceUrl}/events/approveOrRejectTask`,
        saveReject: `${serviceUrl}/events/saveComments`,
        campaignOverview: `${serviceUrl}/events/campaignOverView`
    },
    forgotPassword: `${serviceUrl}/public/forgotPassword`,
    changePassword: `${serviceUrl}/public/changePassword`,
    searchRefine: `${serviceUrl}/product/listproductcolumns`,
    personalAlerts: `${serviceUrl}/api/alerts/activitiAlerts`,
    addVendor: `${serviceUrl}/addorupdatevendor`,
    category: {
        productcategories: `${serviceUrl}/category/productcategories`,
        subcategories: `${serviceUrl}/category/productsubcategories`,
        saveCategory: `${serviceUrl}/productcategory/addproductcategory`,
        deleteCategory: `${serviceUrl}/productcategory/deleteproductcategory`,
    },
    dashboard: {
        priorityAlerts: `${serviceUrl}/api/dashBoard/getDashboardData`,
        getRecentAlerts: `${serviceUrl}/dashBoard/getRecentAlerts`,
        getAccomplishments: `${serviceUrl}/dashBoard/getAccomplishments`,
        searchAlertsOrAccomplishment: `${serviceUrl}/dashBoard/getSearchResults`,
        updateBasedOnMe: `${serviceUrl}/dashBoard/updateBasedOnMe`,
        getSuperiorData: `${serviceUrl}/dashBoard/getSuperiorData`,
        getFilterData: `${serviceUrl}/dashBoard/getFilterData`,
        getRTOSummary: `${serviceUrl}/dashBoard/seasons/RTOSummary`,
        getUnreadMessages: `${serviceUrl}/api/messaging/view/user`, // new api to get all unread messages
        getStageStatus: `${serviceUrl}/api/dashBoard/getStageStatusData`, // new api to get all stage status
    },
    publication: {
        checkListName: `${serviceUrl}/publicationList/listNameCheck`,
        saveListName: `${serviceUrl}/publicationList/saveList`,
        getPublicationList: `${serviceUrl}/publicationList/getPublicationList`,
        getSingleListName: `${serviceUrl}/publicationList/getSingleListName`,
        deleteListName: `${serviceUrl}/publicationList/deleteListName`,
        updatePublicationList: `${serviceUrl}/publicationList/updatePublicationList`,
        clearPublicationList: `${serviceUrl}/publicationList/clearPublicationList`,
        RemoveSelectedData: `${serviceUrl}/publicationList/removeListData`,
        generateCSV: `${serviceUrl}/report/reportToFile`,
        generatePDF: `${serviceUrl}/report/reportToPDF`
    },
    presentation: {
        getFolioFormats: `${serviceUrl}/reports/getFolioFormats`,
        getUserFolio: `${serviceUrl}/reports/getFolioInfo`,
        saveOrUpdate: `${serviceUrl}/picpac/savepicpac`,
        getPageByPageNo: `${serviceUrl}/picpac/pageInfo`,
        listReports: `${serviceUrl}/reports/listReports`
    },
    gridRowLockTime: 60000
};
