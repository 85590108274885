import React from 'react';
import axios from 'axios';
import SpecialityCategoryTree from './specialityCategoryTree.js';
import CancelModal from '../../common/modals/cancelModal';
import Modal from '../../common/modals/commonModal.js';
import NotificationManager from '../../common/components/notification';
import AdminServices from '../../admin/admin-services/admin-services';
import CategoryServices from '../../product2/services/category-services';

class UserEditModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: props.userUpdateData || {},
            userGroupList: [],

            validation: {
                userName: true,
                nickName: true,
                firstName: true,
                lastName: true,
                email: true,
                phone: true,
                company: false,
                designation: true,
                speciality: false,
                deptId: true,
                defaultRole: true,
            },

            isDirty: false,
            errorMessage: '',
            userGroupList: [],
            isModalOpen: false,
            isModified: false,
            specialityData: [],
            specialityTreeData: null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.openModalCancel = this.openModalCancel.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.getSpecialityData = this.getSpecialityData.bind(this);
        this.generateSpecialityData = this.generateSpecialityData.bind(this);
        this.getUserData = this.getUserData.bind(this);
    }

    componentDidMount() {
        const _this = this;

        // Get user mandatory fields and set validataion state

        AdminServices.getUserMandatoryfields().then((response) => {
            const res = response;
            const validationData = _this.state.validation;

            if (res.length > 0) {
                res.map(function (o, i) {
                    if (o.userFields == 'phoneNo') {
                        validationData.phone = o.mandatoryFields;
                    } else {
                        validationData[o.userFields] = o.mandatoryFields;
                    }
                });
            }

            const groupList = _this.state.user.roles;

            groupList.map((val, index) => {
                if (val.status === 'True') {
                    groupList[index].checked = true;
                } else {
                    groupList[index].checked = false;
                }

                if (_this.state.user.defaultRole === val.role) {
                    groupList[index].default = true;
                } else {
                    groupList[index].default = false;
                }
            });

            _this.setState({
                userGroupList: groupList,
                validation: validationData
            });

            _this.getUserData();
        }).catch((error) => {
            console.log('User userEditModal componentDidMount getusermandatoryfields API call failed', error);
        });
    }

    /**
     * Getter for user data
     */
    getUserData() {
        const _this = this;

        const req = JSON.stringify({ id: _this.state.user.userId });

        AdminServices.getUserData(req).then((response) => {
            const data = response;

            if (data.user != null) {
                const oUserData = _this.state.user;
                oUserData.userId = data.user.userId;
                oUserData.nickName = data.user.nickName;
                oUserData.firstName = data.user.firstName;
                oUserData.lastName = data.user.lastName;
                oUserData.email = data.user.email;
                oUserData.phone = data.user.phone;
                oUserData.company = data.user.company;
                oUserData.designation = data.user.designation;

                const arrUserGroupList = data.user.roles;
                arrUserGroupList.map((val, index) => {
                    if (val.status === 'True') {
                        arrUserGroupList[index].checked = true;
                    } else {
                        arrUserGroupList[index].checked = false;
                    }

                    if (data.user.defaultRole === val.role) {
                        arrUserGroupList[index].default = true;
                    } else {
                        arrUserGroupList[index].default = false;
                    }
                });

                let newData = data.user;

                _this.setState({
                    user: oUserData,
                    userGroupList: arrUserGroupList,
                    specialityTreeData : newData
                });
            }
        }).catch((error) => {
            console.log('Server Error Failed', error);

            NotificationManager({
                message: error.message || "Unexpected error, Data didn't load",
                type: 4
            });
        });
    }

    /**
     * Getter for speciality data
     */
    getSpecialityData() {
        //  to get speciality data using user ID in edit user modal
        const _this = this;
        const userId = _this.state.user.uid;
        const postdata = {};
        postdata.id = parseInt(userId);

        CategoryServices.productcategories().then( (response) => {
            console.log('User userEditModal getSpecialityData API call success data: ', response);

            const res = response;

            _this.setState({ specialityData: res });
        }).catch( (error) => {
            console.log('User userEditModal getSpecialityData API call failed', error);
        });
    }

    /**
     * Closes the modal
     * @param {*} key
     */
    modalClose(key) {
        this.setState({ isModalOpen: false });

        switch (key) {
            case 'updateUser':
                this.updateUser();
                break;

            case 'no':
                this.props.modalClose();
                break;
        }
    }

    /**
     * Update user confirm modal
     */
    openModalCancel() {
        if (this.state.isModified) {
            this.setState({ isModalOpen: true });
        } else {
            this.props.modalClose();
        }
    }

    /**
     * Handle input change
     * @param {*} event
     */
    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (value.trim() === '' || value.trim() === undefined) {
            if (this.state.validation[name]) {
                target.className += ' invalid';
            }
        } else {
            target.classList.remove('invalid');
        }

        const { user } = this.state;
        const newUser = user;

        newUser[name] = value;
        this.setState({ user: newUser, isModified: true });
    }

    /**
     * Toggles checkboxes
     * @param {*} key
     */
    toggleCheckbox(key) {
        const { userGroupList, user } = this.state;
        const newList = userGroupList;
        const newUser = user;

        newList.forEach((e, index) => {
            if (index == key) {
                e.checked = !e.checked;

                if (!e.checked && e.default) {
                    newUser.defaultRole = '';
                }

                if (!e.checked) {
                    e.default = false;
                }
            }
        });

        this.setState({
            userGroupList: newList,
            user: newUser,
            isModified: true
        });
    }

    /**
     * Toggle radio button
     * @param {*} key
     */
    toggleRadio(key) {
        const { userGroupList, user } = this.state;
        const newUser = user;
        const newList = userGroupList;

        newList.forEach((e, index) => {
            if (index != key) {
                e.default = false;
            }
        });

        newList[key].default = !newList[key].default;
        newUser.defaultRole = newList[key].role;

        this.setState({ userGroupList: newList, user: newUser, isModified: true });
    }

    /**
     * Validates email
     * @param {*} email
     * @return {string}
     */
    validateEmail(email) {
        const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(email);
    }

    /**
     * Calls backend to reset password
     * @param {*} event
     */
    resetPassword(event) {
        event.preventDefault();
        const postdata = JSON.stringify({ id: this.state.user.email });

        if (this.props.user.email) {
            AdminServices.resetPassword(postdata).then((response) => {
                const res = response;

                if (res.status) {
                    NotificationManager({
                        message: 'Reset password link has been sent to user.',
                        type: 3,
                    });
                }
            }).catch((error) => {
                console.log('User userEditModal resetPassword API call failed', error);

                NotificationManager({
                    message: 'Reset password failed.',
                    type: 4,
                });
            });
        } else {
            NotificationManager({
                message: 'Invalid Email address.',
                type: 4,
            });
        }
    }

    /**
     * Generates data
     * @param {*} newData
     * @return {object}
     */
    generateSpecialityData(newData) {
        const _this = this;
        const oldData = _this.state.specialityData;

        const initData = oldData.map(function (val, ii) {
            val.status = false;

            return val;
        });

        newData.map(function (nData, nind) {
            initData.some(function (oData) {
                if (nData == oData.catName) {
                    oData.status = true;
                }
            });
        });

        return oldData;
    }

    /**
     * Calls backend to update the server
     */
    updateUser() {
        const _this = this;
        const { user, userGroupList } = this.state;
        const newUser = user;
        const newGroup = userGroupList;
        const checkedGroupList = [];
        const groupRoles = newGroup; // seperate obj for update grid row - User group/Roles

        const newSp = $.makeArray($('.speciality-tree').find('.tree-node-list.selected'));
        const newSpIds = [];
        const newSpDTO = [];

        newSp.map(function (sp, i) {
            const id = $(sp).find('.category').attr('data-catid');
            const name = $(sp).find('.category').text();

            newSpIds.push(id);

            const spData = {};
            spData.catId = id;
            spData.catName = name;
            spData.status = true;

            newSpDTO.push(spData);
        });

        newGroup.map((e, index) => {
            if (e.checked) {
                groupRoles[index].status = 'True';

                checkedGroupList.push({ roleId: e.roleId, role: e.role, status: 'True' });
            } else {
                groupRoles[index].status = 'False';
            }

            if (e.default) {
                newUser.defaultRole = e.role;
            }
        });

        newUser.roles = checkedGroupList;

        // validation
        let flag = 0;
        let errorMessage = '*Mandatory fields marked with an asterisk are missing.';

        if (this.state.validation.userName && newUser.userName === '') {
            flag++;

            $('.form-control[name="userName"]').addClass('invalid');
        } else {
            this.setState({ isDirty: false });

            $('.form-control[name="userName"]').removeClass('invalid');
        }

        if (this.state.validation.nickName && (newUser.nickName === '' || newUser.nickName === undefined)) {
            flag++;

            $('.form-control[name="nickName"]').addClass('invalid');
        } else {
            this.setState({ isDirty: false });

            $('.form-control[name="nickName"]').removeClass('invalid');
        }

        if (this.state.validation.firstName && (newUser.firstName === '' || newUser.firstName === undefined)) {
            flag++;

            $('.form-control[name="firstName"]').addClass('invalid');
        } else {
            this.setState({ isDirty: false });

            $('.form-control[name="firstName"]').removeClass('invalid');
        }

        if (this.state.validation.lastName && (newUser.lastName === '' || newUser.lastName === undefined)) {
            flag++;

            $('.form-control[name="lastName"]').addClass('invalid');
        } else {
            this.setState({ isDirty: false });

            $('.form-control[name="lastName"]').removeClass('invalid');
        }

        if (this.state.validation.email && newUser.email === '') {
            flag++;

            $('.form-control[name="email"]').addClass('invalid');
        } else {
            if (this.validateEmail(this.state.user.email)) {
                this.setState({ isDirty: false });

                $('.form-control[name="email"]').removeClass('invalid');
            } else {
                flag++;

                errorMessage = 'Invalid Email Address, Please re-enter';
                $('.form-control[name="email"]').addClass('invalid');
            }
        }

        if (this.state.validation.phone && (newUser.phone === '' || newUser.phone === undefined)) {
            flag++;

            $('.form-control[name="phone"]').addClass('invalid');
        } else {
            this.setState({ isDirty: false });

            $('.form-control[name="phone"]').removeClass('invalid');
        }

        if (this.state.validation.company && (newUser.company === '' || newUser.company === undefined)) {
            flag++;

            $('.form-control[name="company"]').addClass('invalid');
        } else {
            this.setState({ isDirty: false });

            $('.form-control[name="company"]').removeClass('invalid');
        }

        if (this.state.validation.designation && (newUser.designation === '' || newUser.designation === undefined)) {
            flag++;

            $('.form-control[name="designation"]').addClass('invalid');
        } else {
            this.setState({ isDirty: false });

            $('.form-control[name="designation"]').removeClass('invalid');
        }

        if (this.state.validation.speciality && newSpIds.length < 1) {
            flag++;

            $('.speciality-tree').addClass('invalid');
        } else {
            this.setState({ isDirty: false });

            $('.speciality-tree').removeClass('invalid');
        }

        if (this.state.validation.defaultRole && newUser.defaultRole === '') {
            flag++;
        } else {
            if (checkedGroupList.find(checkforDefaultRole)) {
                this.setState({ isDirty: false });
            } else {
                flag++;

                this.setState({ isDirty: true });
            }
        }

        function checkforDefaultRole(role) {
            return role.role === newUser.defaultRole;
        }

        if (flag === 0) {
            // save user edit modal
            const finalData = newUser;
            const req = {};

            const trimmingFields = ['company', 'designation', 'email', 'firstName', 'lastName', 'nickName', 'phone', 'fullName'];

            for (const key in finalData) {
                if (trimmingFields.indexOf(key) >= 0) {
                    req[key] = finalData[key].trim();
                } else {
                    req[key] = finalData[key];
                }
            }

            req.specialityListDTOs = newSpDTO;

            delete req['speciality'];

            const data = JSON.stringify(req);

            AdminServices.insertUsersData(data).then((response) => {
                const data = response;

                if (data.status) {
                    // Append new row in User Grid
                    const { user } = _this.state;
                    const gridData = user;
                    gridData.roles = groupRoles;
                    gridData.speciality = data.user.speciality;

                    _this.props.updateGridRow(gridData);

                    _this.props.modalClose();

                    NotificationManager({
                        message: data.responseMessage,
                        type: 1,
                    });

                    _this.props.reloadGrid();
                } else {
                    NotificationManager({
                        message: data.responseMessage,
                        type: 4,
                    });

                    _this.setState({ isDirty: true});
                }
            }).catch((error) => {
                console.log('User userEditModal updateUser API call failed', error);
            });
        } else {
            NotificationManager({
                message: errorMessage,
                type: 4,
            });

            this.setState({ isDirty: true });
        }
    }

    render() {
        let currentUser = this.props.user.company.toLowerCase() == 'sweft';

        const groups = this.state.userGroupList.map((val, index) => {
            return (val.enabled) ? <div className="row" key={index}>
                <div className="col-4">
                    <label className="form-label">{val.role}</label>
                </div>
                <div className="col-4">
                    <label className="form-check-label">
                        <input type="checkbox" className="form-control-check" id={val.role} name={val.role} checked={val.checked} onChange={() => this.toggleCheckbox(index)}></input>
                        <span htmlFor={val.role} className="from-checkbox"></span>
                    </label>
                </div>
                <div className="col-4">
                    <label className="form-radio-label">
                        <input type="radio" name="default" checked={val.default} disabled={!val.checked} className="form-control-radio" onChange={() => this.toggleRadio(index)}></input>
                    </label>
                </div>
            </div> : '';
        });

        return (
            <Modal isOpen={this.props.isOpen}>
                <div className="modal-header">
                    Edit User
                    <span className="close" onClick={this.openModalCancel}>x</span>
                </div>
                <div className="modal-content">
                    <div className="add-user modal-form">
                        <form className="addNewUser" name="addNewUser">
                            <div className="form-container scroll">
                                <h4>Account Details</h4>
                                <hr />
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">User ID{this.state.validation.userName ? '*' : ''}</label>
                                            <input type="text" className="form-control" name="userName" disabled value={this.state.user.userId} onChange={this.handleChange}></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Username{this.state.validation.userName ? '*' : ''}</label>
                                            <input type="text" className="form-control" name="userName" disabled value={this.state.user.userName} onChange={this.handleChange}></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Nick Name{this.state.validation.nickName ? '*' : ''}</label>
                                            <input type="text" className="form-control" name="nickName" value={this.state.user.nickName} onChange={this.handleChange} maxLength="10"></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label className="form-label">First Name{this.state.validation.firstName ? '*' : ''}</label>
                                            <input type="text" className="form-control" name="firstName" value={this.state.user.firstName} onChange={this.handleChange}></input>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label className="form-label">Last Name{this.state.validation.lastName ? '*' : ''}</label>
                                            <input type="text" className="form-control" name="lastName" value={this.state.user.lastName} onChange={this.handleChange}></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Email{this.state.validation.email ? '*' : ''}</label>
                                            <input type="email" className="form-control" name="email" value={this.state.user.email} onChange={this.handleChange}></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Phone No.{this.state.validation.phone ? '*' : ''}</label>
                                            <input type="text" className="form-control" name="phone" value={this.state.user.phone} onChange={this.handleChange} maxLength="10"></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label className="form-label">Company{this.state.validation.company ? '*' : ''}</label>
                                            <input type="text" className="form-control" name="company" disabled={currentUser ? false : true} value={this.state.user.company} onChange={this.handleChange}></input>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label className="form-label">Designation{this.state.validation.designation ? '*' : ''}</label>
                                            <input type="text" className="form-control" name="designation" value={this.state.user.designation} onChange={this.handleChange}></input>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label className="form-label">Speciality{this.state.validation.speciality ? '*' : ''}</label>
                                            <div className="speciality-tree">
                                                <SpecialityCategoryTree pageName={"editUser"} userId={this.state.user.uid} user={this.state.specialityTreeData} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <input type="button" className="button-link" onClick={this.resetPassword} value="Reset Password" />
                                    </div>
                                </div>
                                <h4>Assign Group</h4>
                                <hr />
                                <div className="row">
                                    <div className="col-4"><span className="form-subtitle">Groups*</span></div>
                                    <div className="col-4"><span className="form-subtitle"></span></div>
                                    <div className="col-4"><span className="form-subtitle">Default*</span></div>
                                </div>
                                <div className="user-group-section scroll">
                                    <div className="form-group">
                                        {groups}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <input type="button" className="gray-button" value="Cancel" onClick={this.openModalCancel}></input>
                                <input type="button" className="gray-button" value="Save" onClick={this.updateUser}></input>
                            </div>
                            {(this.state.isDirty) ? <span className="error-message">{this.state.errorMessage}</span> : ''}
                        </form>
                    </div>
                </div>
                <CancelModal data={this.state.user} isOpen={this.state.isModalOpen} modalClose={this.modalClose} />
            </Modal>
        );
    }
}

export default UserEditModal;
