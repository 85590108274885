import React from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import NotificationManager from '../common/components/notification';
import Modal from '../common/modals/commonModal';
import axios from 'axios';
import './publicationGridAg.css';
import CreatePicturePacketModal from './helperComponents/createPicturePacketModal';
import { ImagePopupRenderer, ImagePopupEditor } from './helperComponentsAgGrid/images/image';
import { CurrencyRenderer, CurrencyEditor } from './helperComponentsAgGrid/currency';
import { DropdownEditor, DropdownRender } from './helperComponentsAgGrid/dropdownEditor';
import PublicationServices from './services/publication-services';
import PresentationServices from './services/presentation-services';

class Publication extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rowData: [],
            defaultColDef: {
                // set every column width
                width: 100,
                editable: true,
                sortable: true,
                filter: false,
                suppressMenu: true,
            },
            columnDefs: [],
            overlayLoadingTemplate: '<span class="ag-overlay-loading-center">Loading</span>',
            overlayNoRowsTemplate: '<span style="padding: 10px; font-size: 28px; font-weight:\'lighter\' ; '
            + 'color: #525E61;">Please Select a list in the Reporting Panel</span>',

            //state vars
            selectedRows: null,
            selectedRowCount: 0,
            featureColorDropdownList: null,
            isRemoveModalOpen: false,
            availablePicPacFormat: {},
            presentationModal: false,
        };

        this.colDefs = this.colDefs.bind(this);
        this.removeSelected = this.removeSelected.bind(this);
        this.removeModalClose = this.removeModalClose.bind(this);
        this.removeSelectedPubList = this.removeSelectedPubList.bind(this);
        this.removeSelectedPubListAPI = this.removeSelectedPubListAPI.bind(this);
        this.presentationModalOpen = this.presentationModalOpen.bind(this);
        this.presentationModalClose = this.presentationModalClose.bind(this);
        this.updateGridData = this.updateGridData.bind(this);
    }

    componentWillMount() {
        if (this.props.leftDrawerPulled) {
            this.props.toggleLeftDrawer();
        }

        const _this = this;

        const postData = { 'id': this.props.user };

        const req = {
            'id': postData.id.defaultRole,
            'value': 'picpac'
        };

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        PresentationServices.getFolioFormats(req).then( (response) => {
            console.log('publicationfooter componentDidMount API call after makeCallToRequiredFields success data: ', response);

            const res = response;

            if (res.responseCode === 200) {
                _this.setState({availablePicPacFormat: res.data, isLoaded: true});
            } else {
                NotificationManager({message: res.responseMessage, type: 4});
            }
        })
            .catch( error => {
                console.log('publicationfooter componentDidMount  API call failed', error);

                _this.setState({isLoaded: false});
            });
    }

    //Since we have changed the routing, data will be available in publish grid but componentWillReceiveProps will not be invoke when user clicks on "View Presentation Grid". So we need to set the values using componentDidMount
    componentDidMount(prop) {
        const _this = this,
            clmnName = _this.props.publicationColumnNames,
            gridData = _this.props.publicationGridData;

        if (clmnName && Object.keys(clmnName).length > 0 && gridData && Object.keys(gridData).length > 0) {
            const colDefs = clmnName;
            const gridData = _this.props.publicationGridData;
            const colDetails = _this.colDefs(colDefs);

            this.setState({
                columnDefs: colDetails,
                rowData: gridData,
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        const _this = this;

        console.log('componentWillReceiveProps PUBLICATION GRID will get props', nextProps);

        if (nextProps.publicationColumnNames && nextProps.publicationGridData && nextProps.publicationColumnNames.length > 0 && nextProps.publicationGridData.length > 0) {
            const colDefs = nextProps.publicationColumnNames || [];
            const gridData = nextProps.publicationGridData || [];
            const colDetails = _this.colDefs(colDefs);

            this.setState({
                columnDefs: colDetails,
                rowData: gridData,
            });
        }
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    }

    removeSelected() {
        const _this = this;

        _this.setState({ isRemoveModalOpen: true });

        $('.presentation-drawer-open .puller').trigger('click');
    }

    removeModalClose() {
        this.setState({ isRemoveModalOpen: false });
    }

    removeSelectedPubList() {
        this.props.removeSelectedPubList();
        this.removeModalClose();
    }

    presentationModalOpen() {
        $('.presentation-drawer-open .puller').trigger('click');

        this.setState({ presentationModal: true });
    }

    presentationModalClose() {
        this.setState({ presentationModal: false });
    }

    /**
     *  is called when a cell value is changed
     * @param {object} params
     */
    onCellValueChanged(params) {
        console.log('on cell value changed: ', params);

        const _this = this;
        const oldValue = params.oldValue;
        const newValue = params.value;

        if (oldValue == newValue) {
            return;
        }

        const field = params.colDef.field;
        const fieldType = params && params.colDef && params.colDef.fieldType ? params.colDef.fieldType : '';
        const format = params && params.colDef && params.colDef.format ? params.colDef.format : '';
        const telregex = /^[0-9]+$/i;

        const trimmedString = newValue.substring(0, 100);

        if (field == 'comment1' || field == 'comment2') {
            if (newValue.length > 100) {
                NotificationManager({
                    message: 'Please enter value upto 100 characters in length',
                    type: 4,
                });

                params.node.setDataValue(params.colDef.colId, trimmedString);
            }
        }

        if (field === 'displayOrder' && (newValue.trim() === '' || !telregex.test(newValue))) {
            NotificationManager({
                message: 'Please enter only numbers in Order field.',
                type: 4,
            });

            params.node.setDataValue(params.colDef.colId, oldValue);
        }
        _this.updateGridData(params.data);
    }

    updateGridData(rowdata) {
        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        PublicationServices.updatePublicationList(rowdata).then( (response) => {
            console.log('publicationContainer updateGridData API call after makeCallToRequiredFields success data: ', response);

            let res = response;

            if (res.status) {
                NotificationManager({
                    message: res.responseMessage,
                    type: 1
                });
            }
        })
            .catch( error => {
                console.log('publicationContainer updateGridData  API call failed', error);

                NotificationManager({
                    message: res.responseMessage,
                    type: 4
                });
            });
    }

    colDefs(cols) {
        const _this = this;
        const newColDefs = [];

        cols.forEach(function (obj) {
            // adding custom renders based on requirement
            switch (obj.fieldName) {
                case 'images':
                    newColDefs.push({
                        headerName: obj.columnTitle,
                        headerTooltip: obj.columnTitle,
                        colId: obj.fieldName,
                        field: obj.fieldName,
                        editable: true,
                        order: obj.order,
                        cellRendererFramework: ImagePopupRenderer,
                        cellEditorFramework: ImagePopupEditor,
                        cellEditorParams: {
                            user: _this.props.user,
                        },
                        hide: !obj.visibility,
                    });

                    break;

                case 'featureColor':
                    newColDefs.push({
                        headerName: obj.columnTitle,
                        headerTooltip: obj.columnTitle,
                        colId: obj.fieldName,
                        field: obj.fieldName,
                        editable: true,
                        order: obj.order,
                        cellEditorFramework: DropdownEditor,
                        cellRendererFramework: DropdownRender,
                        hide: !obj.visibility,
                    });

                    break;

                case 'ecomPoOnOrder':
                    newColDefs.push({
                        headerName: obj.columnTitle,
                        headerTooltip: obj.columnTitle,
                        colId: obj.fieldName,
                        field: obj.fieldName,
                        editable: false,
                        order: obj.order,
                        hide: !obj.visibility,
                    });

                    break;

                case 'comment1':
                case 'comment2':
                case 'displayOrder':
                    newColDefs.push({
                        headerName: obj.columnTitle,
                        headerTooltip: obj.columnTitle,
                        colId: obj.fieldName,
                        field: obj.fieldName,
                        editable: true,
                        order: obj.order,
                        hide: !obj.visibility,
                    });

                    break;

                default:
                    if (obj) {
                        switch (obj.type) {
                            case 'String':
                                newColDefs.push({
                                    editable: false,
                                    headerName: obj.columnTitle,
                                    headerTooltip: obj.columnTitle,
                                    colId: obj.fieldName,
                                    field: obj.fieldName,
                                    order: obj.order,
                                    hide: !obj.visibility,
                                });

                                break;

                            case 'number':
                                newColDefs.push({
                                    editable: false,
                                    headerName: obj.columnTitle,
                                    headerTooltip: obj.columnTitle,
                                    colId: obj.fieldName,
                                    field: obj.fieldName,
                                    order: obj.order,
                                    hide: !obj.visibility,
                                });

                                break;

                            case 'Long':
                                newColDefs.push({
                                    editable: false,
                                    headerName: obj.columnTitle,
                                    headerTooltip: obj.columnTitle,
                                    colId: obj.fieldName,
                                    field: obj.fieldName,
                                    order: obj.order,
                                    hide: !obj.visibility,
                                    cellRendererFramework: CurrencyRenderer,
                                    cellEditorFramework: CurrencyEditor,
                                });

                                break;
                        }
                    }
            }
        });

        newColDefs.unshift({
            headerName: '',
            field: 'checkbox',
            width: 50,
            minWidth: 50,
            editable: false,
            checkboxSelection: true,
            pinned: 'left',
            lockPosition: true,
        });

        // orders the columns based on order
        newColDefs.sort(function (a, b) {
            if (a.order > b.order) {
                return 1;
            }

            if (a.order < b.order) {
                return -1;
            }

            return 0;
        });

        return newColDefs;
    }

    onSelectionChanged() {
        const selectedRows = this.gridApi.getSelectedRows();

        this.setState({
            selectedRowCount: selectedRows.length,
            selectedRows: selectedRows
        });
    }

    removeSelectedPubListAPI() {
        const _this = this;
        const postdata = [];
        const selectedRows = _this.gridApi.getSelectedRows();

        selectedRows.forEach((item, index) => {
            const tempObj = { 'id': item.productPubId };
            postdata.push(tempObj);
        });

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        PublicationServices.RemoveSelectedData(postdata).then( (response) => {
            console.log('publicationContainer updateGridData API call after makeCallToRequiredFields success data: ', response);

            let res = response;

            if (res.responseCode === 200) {
                NotificationManager({ message: res.responseMessage, type: 1 });

                _this.gridApi.updateRowData({ remove: selectedRows });

                _this.removeModalClose();
            } else {
                NotificationManager({ message: res.responseMessage, type: 4 });
            }
        })
            .catch( error => {
                let msg = 'Remove selected failed';
                NotificationManager({ message: msg, type: 4 });
            });
    }

    render() {
        const totalCount = this.state.rowData.length ? this.state.rowData.length : 0;
        const selectedRowCount = this.state.selectedRowCount ? this.state.selectedRowCount : 0;

        return (
            <div>
                <div id="publicationGridAg" className="ag-theme-balham">
                    <AgGridReact
                        defaultColDef={this.state.defaultColDef}
                        columnDefs={this.state.columnDefs}
                        rowData={this.state.rowData}
                        onGridReady={this.onGridReady.bind(this)}
                        rowSelection={'multiple'}
                        stopEditingWhenGridLosesFocus={true}
                        overlayLoadingTemplate={this.state.overlayLoadingTemplate}
                        overlayNoRowsTemplate={this.state.overlayNoRowsTemplate}
                        singleClickEdit={true}
                        enterMovesDown={true}
                        enterMovesDownAfterEdit={true}
                        enableCellChangeFlash={true}
                        suppressRowClickSelection={true}
                        enableColResize={true}
                        enableSorting= {true}
                        //functions
                        onSelectionChanged = {this.onSelectionChanged.bind(this)}
                        onCellValueChanged={this.onCellValueChanged.bind(this)} // this is for catching when a cell in the grid changes values
                    />
                </div>
                <div>

                    <div className="product-footer footer">
                        <div className="footer-content right">

                            <button
                                className="gray-button"
                                onClick={this.removeSelected}
                                disabled={selectedRowCount > 0 ? false : true}
                            >
                                Remove Selected
                            </button>

                            <button
                                className={(this.state.rowData.length > 0) ? 'orange-button' : 'gray-button'}
                                disabled={(this.state.rowData.length > 0) ? '' : 'disabled'}
                                onClick={() => this.presentationModalOpen()}
                            >
                                Create Presentation
                            </button>

                        </div>

                        <div className="total-row-count">
                            <span className="total-rows">{totalCount} Total Rows</span>
                            <span className="selected-rows"> | Selected: {selectedRowCount}</span>
                        </div>

                        <Modal
                            isOpen={this.state.isRemoveModalOpen}
                            className={"confirm-remove-modal"}
                        >
                            <div className="modal-content">
                                <div className="text-center confirm-delete">
                                    <h4>Are you sure you want to remove the records?</h4>
                                </div>
                                <div className="modal-footer text-center">
                                    <button className="gray-button" onClick={this.removeModalClose}>No</button>
                                    <button className="gray-button" onClick={this.removeSelectedPubListAPI}>Yes</button>
                                </div>
                            </div>
                        </Modal>
                    </div>

                    <div className="picture-packet-modal">
                        <CreatePicturePacketModal
                            selectedListId={this.props.selectedId}
                            selectedListName={this.props.selectedName}
                            isOpen={this.state.presentationModal}
                            modalClose={this.presentationModalClose}
                            avalibaleFormat={this.state.availablePicPacFormat}
                            user = {this.props.user}
                        />
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        count: state.addProductRow,
        selectedRows: state.selectedRows,
        deleteCount: state.deleteSeletedProducts,
        storeGridData: state.gridData,
        cloneCount: state.cloneSelectedProducts,
        columnDetails: state.columnDetails,
        gridDataCount: state.gridDataUpdate,
        storeGridDropDowns: state.gridDropDowns,
        appliedFilters: state.appliedFilters,
        publicationGridData: state.publicationGridData,
        publicationColumnNames: state.publicationColumnNames,
        publicationCounter: state.PublicationCounter,
        gridDropDowns: state.gridDropDowns,
        addGridDropDowns: state.addGridDropDowns,
        selectedPublicationRows: state.checkedPublicationRows,
        selectedName: state.selectedPresentationName.name,
        selectedId: state.selectedPresentationName.id,
        columnDetails: state.columnDetails,
        selectedName: state.selectedPresentationName.name,
        selectedId: state.selectedPresentationName.id,
    };
};

const mapDispatchToprops = (dispatch) => {
    return {
        addGridData: (data) => {
            dispatch(actions.addGridData(data));
        },
        addGridDropDowns: (data) => {
            dispatch(actions.addGridDropDowns(data));
        },
        updateCheckedPublicationRows: (data) => {
            dispatch(actions.updateCheckedPublicationRows(data));
        },
        listDirty: (data) => {
            dispatch(actions.updateListDirty(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToprops)(Publication);
