
import React, { Component } from 'react';
import NotificationManager from '../../common/components/notification';


export class FeatureColorEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedVal: props.value ? props.value : null,
            featureColors: [],
            orgValue: props.node.data.featureColor || '',
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillMount() {
        let colorArray;
        if (this.props.node.data.dataLevel == 'PARENT') {
            colorArray = this.props.node.data.color_attribute;
        } else if (this.props.node.data.dataLevel == 'SINGLE') {
            NotificationManager({ message: 'Feature Color works only when a record has child variants', type: 4 });
            this.props.api.stopEditing();
            return;
        } else if (this.props.node.data.dataLevel == 'INTERMEDIATE' || this.props.node.data.dataLevel == 'CHILD') {
            NotificationManager({ message: 'Feature Color can be set only to parent level', type: 4 });
            this.props.api.stopEditing();
            return;
        }

        let temp = [];

        temp = colorArray.split(',');

        for (const a in temp) {
            if (temp[a]) {
                temp[a] = (temp[a]);
            }
        }

        this.setState({ featureColors: temp });
    }

    componentWillUnmount() {
        const rowData = this.props.node.data;

        rowData['featureColor'] = this.state.selectedVal;

        if (this.state.selectedVal) {
            if (this.state.selectedVal != this.state.orgValue) {
                this.props.rowUpdate(rowData, this.props.node.rowIndex);
            }
        }

        this.props.api.tabToNextCell();
    }

    isPopup() {
        return false;
    }

    handleChange(event) {
        this.setState({ selectedVal: event.target.value }, () => this.props.api.stopEditing());
    }

    getValue() {
        return this.state.selectedVal;
    }


    render() {
        const colorOptions = this.state.featureColors.map((obj, index) => {
            return <option key={index} value={obj}>{obj}</option>;
        });

        return (
            (this.props.node.level == 0) &&
            <div>
                <select style={{ width: '100%' }} onChange={this.handleChange} value={this.state.selectedVal ? this.state.selectedVal : 'Please Select'}>
                    <option disabled={true}>Please Select</option>
                    {colorOptions}
                </select>
            </div>
        );
    }
}

// ////////////////////////////////////////////////////////////////////////////////////

/**
 * renders the feature color column and a arrow in a dropdown when column has no values
 */
export class FeatureColorRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        // console.log('FeatureColorRenderer component will mount', this.props, this.state);
        return (
            (this.props.node.level == 0) &&
            <div>
                {this.props.value ?
                    <div>{this.props.value}</div>
                    :
                    <div className="arrow downPos"></div>
                }
            </div>
        );
    }
}
