import React from 'react';
import AccomplishmentComponent from './accomplishmentComponent';
import axios from 'axios';


import GridDataBuilder from './helperComponents/gridDataBuilder';
import NotificationManager from '../common/components/notification';
import AdminServices from '../admin/admin-services/admin-services';

/**
 * Accomplishment - renders the accomplishment in the dashboard
 */
class Accomplishment extends React.Component {
    /**
   * Represents a Vendor Page.
   * @constructor
   * @param {object} props - Component properties
   */
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            dropdownlist: null,
            vendorTotalCount: 0,
            addRowCount: 0,
            roleId: 0,
        };

        this.handleSelectChange = this.handleSelectChange.bind(this);

        this.loadDropdownGridData = this.loadDropdownGridData.bind(this);
    }

    /**
   * Menthod - To get vendor list and update state once component is created
   */
    componentDidMount() {
        const base = this;

        AdminServices.getUserGroupList().then((response) => {
            const res = response;
            base.setState({ dropdownlist: res.rolesList });
            const atrtype = res.rolesList[0].id;
            base.loadDropdownGridData(atrtype);
        }).catch((error) => {
            console.log('Admin Accomplishment componentDidMount API call failed', error);
        });


        $('#accomplishGrid').on('click', '.sample-boolean', (e) => {
            const rowId = $(this).attr('data-row');
            const fieldName = $(this).attr('data-fieldname');
            const value = $(this).prop('checked');
            base.accomplishGrid.setcellvalue(rowId, fieldName, value);
            const rows = base.accomplishGrid.getrowdata(rowId);
            // console.log(rows);
            const postdata = { 'roleId': base.state.roleId, 'accomplishmentsDTOList': [{ 'accomplishmentId': rows.accomplishmentId, 'tasks': rows.tasks, 'track': rows.track }] };

            AdminServices.getAccomplishmentsList(postdata).then((response) => {
                const res = response;
                if (res.status) {
                    NotificationManager({ message: res.responseMessage, type: 1 });
                } else {
                    NotificationManager({ message: res.responseMessage, type: 4 });
                }
            }).catch((error) => {
                console.log('Admin Accomplishment componentDidMount API call failed', error);
            });
        });
    }


    handleSelectChange(e) {
        const base = this;
        const target = e.target;
        const value = target.value;
        base.setState({ isLoaded: false });
        base.loadDropdownGridData(value);
    }

    /**
    * loads dropdown grid data
    * @param {string} atrType
    */
    loadDropdownGridData(atrType) {
        const data = { 'id': atrType };
        AdminServices.getAccomplishmentsList(data).then((response) => {
            const res = response;
            this.gridData = GridDataBuilder(res);
            this.setState({ isLoaded: true, vendorTotalCount: res.data.accomplishmentsDTOList.length, roleId: res.data.roleId });
        }).catch((error) => {
            console.log('Admin Accomplishment loadDropdownGridData API call failed', error);
        });
    }

    render() {
    // console.log('Accomplishment Container render: ', this.props);

        const footerHeight = 50;
        const headerHeight = 64;
        $('.page-title a').text("ADMIN: Accomplishment");

        const gridHeight = window.innerHeight - footerHeight - headerHeight - 35;
        const MakeItem = function (x, index) {
            return <option key={index} value={x.id}>{x.role}</option>;
        };
        const editSettings = {
            saveOnPageChange: true,
            saveOnBlur: true,
            saveOnSelectionChange: true,
            cancelOnEsc: true,
            saveOnEnter: true,
            editSingleCell: true,
            editOnDoubleClick: true,
            editOnF2: true,
        };

        return (
            <AccomplishmentComponent
                state={this.state}
                props={this.props}
                handleSelectChange={this.handleSelectChange}
                accomplishmentGridRef={(grid) => {
                    this.accomplishGrid = grid;
                }}
                gridData={this.gridData}
                gridHeight={gridHeight}
                editSettings={editSettings}
                MakeItem={MakeItem}
            />
        );
    }
}

export default Accomplishment;
