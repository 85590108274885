import React from 'react';
import RequriedFieldsComponent from './requiredFieldsComponent';
import axios from 'axios';
import GridDataBuilder from './helperComponents/gridDataBuilder';
import NotificationManager from '../common/components/notification';
import AdminServices from '../admin/admin-services/admin-services';

class RequriedFields extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            isApplyChangesDisabled: true,
        };

        this.prevGridData = null;
        this.updateRequiredFields = this.updateRequiredFields.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.checkAnyValueChanges = this.checkAnyValueChanges.bind(this);
        this.compareObject = this.compareObject.bind(this);
    }

    /**
     * Cancels the changes
     */
    cancelChanges() {
        this.setState({isLoaded: false});
        this.initializeGrid();
    }

    componentDidMount() {
        this.initializeGrid();
    }

    /**
     * Gets the initialized grid
     */
    initializeGrid() {
        const base = this;

        AdminServices.getRequiredFields().then((response) => {
            const res = response;

            base.prevGridData = res.data;
            base.gridData = GridDataBuilder(res, 'requiredFields');
            base.setState({ isLoaded: true, isApplyChangesDisabled: true });
        }).catch((error) => {
            console.log('RequiredFields initializeGrid API call failed', error);
        });
    }

    /**
     * Calls backend to save data
     */
    updateRequiredFields() {
        const _this = this;
        const updatedList = this.requiredFieldsGrid.getrows();

        AdminServices.updateRequiredFields(updatedList).then((response) => {
            const res = response.data;

            NotificationManager({
                message: 'Fields has been successfully updated!!!',
                type: 1,
            });

            _this.initializeGrid();
        }).catch((error) => {
            console.log('RequiredFields updateRequiredFields API call failed', error);
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.isLoaded !== this.state.isLoaded) {
            const _this = this;
            const requiredFields = $('.required-fields-grid');

            requiredFields.off();

            requiredFields.on('click', '.required-checkbox', function(e) {
                const rowId = $(this).attr('data-row');
                const fieldName = $(this).attr('data-fieldname');
                const value = $(this).prop('checked');

                _this.requiredFieldsGrid.setcellvalue(rowId, fieldName, value);

                if (_this.checkAnyValueChanges()) {
                    _this.footerEnableDisable.enableFooter();
                } else {
                    _this.footerEnableDisable.disableFooter();
                }
            });
        }
    }

    /**
     * Copmares values
     * @param {*} a
     * @param {*} b
     * @return {number}
     */
    compareObject(a, b) {
        if (a.id < b.id) {
            return -1;
        }

        if (a.id > b.id) {
            return 1;
        }

        return 0;
    }

    /**
     * Checks for any value changes
     * @return {boolean}
     */
    checkAnyValueChanges() {
        const prevData = Object.assign([], this.prevGridData);
        const nextData = this.requiredFieldsGrid.getrows();

        let isChanged = false;

        nextData.sort(this.compareObject);

        for (let i = 0; i < prevData.length; i++) {
            if (prevData[i].reqdToAdd !== nextData[i].reqdToAdd) {
                isChanged = true;
                break;
            }

            if (prevData[i].readyToOrder !== nextData[i].readyToOrder) {
                isChanged = true;
                break;
            }

            if (prevData[i].fieldsToClone !== nextData[i].fieldsToClone) {
                isChanged = true;
                break;
            }

            if (prevData[i].mpr2Approved !== nextData[i].mpr2Approved) {
                isChanged = true;
                break;
            }
        }

        return isChanged;
    }

    render() {
        const footerHeight = 50;
        const headerHeight = 64;
        const gridHeight = window.innerHeight - footerHeight - headerHeight - 20;

        $('.page-title a').text("ADMIN: Required Fields");

        return (
            <RequriedFieldsComponent
                state = { this.state }
                props = { this.props }
                requiredFieldsGridRef = {(grid) => {
                    this.requiredFieldsGrid = grid;
                }}
                footerEnableDisableRef = {(footer) => {
                    this.footerEnableDisable = footer;
                }}
                gridHeight = { gridHeight }
                gridData = { this.gridData }
                updateRequiredFields = { this.updateRequiredFields }
                cancelChanges = { this.cancelChanges }
                isApplyChangesDisabled = { this.isApplyChangesDisabled }
            />
        );
    }
}

export default RequriedFields;
