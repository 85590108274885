import React from 'react';
import LeftNavComponent from './leftNavComponent';
import { connect } from 'react-redux';
import { loginActions } from '../login/redux/loginActions';
import NotificationManager from '../common/components/notification';

class LeftNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // props: props,
            modalName: '',
            isModalOpen: false,
            showUserDropdown:false,
            menuItem: {
                dashboard: 'active',
                product: '',
                product2:'',
                creative: '',
                marketing: '',
                publication: '',
                samples:'',
                admin:''
            },
            tabVisibility: {},
        };
        this.modalOpen = this.modalOpen.bind(this);
        this.checkForActive = this.checkForActive.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.triggerModalOpen = this.triggerModalOpen.bind(this);
        this.toggleListItemClassName = this.toggleListItemClassName.bind(this);
        this.signout = this.signout.bind(this);
    }

    componentWillMount() {
        let user = localStorage.getItem('user');
        user = JSON.parse(user);
        console.log('leftNavContainer user: ', user);
        let tabVisibility = this.state.tabVisibility;
        let tabPermissions = user ? user['tabPermission'] : null;
        console.log('tabpermission: ', tabPermissions);
        let tabPermissionsLength = tabPermissions?.length || 0;
        for (let i = 0;i < tabPermissionsLength;i++){
            if(tabPermissions[i]['permitted'] == 'V'){
                tabVisibility[[tabPermissions[i]['tab']]] = true;
            }
        }
        console.log('leftNav componentwill mount: ', tabVisibility);
        this.setState({tabVisibility});
    }

    componentDidMount() {
        let currentPage = this.props.currentPage;
        //localStorage.getItem('currentPage') || 'dashboard'
        this.toggleListItemClassName(currentPage);

        let _this = this;
        $('body').on('click', function (event) {
            if($(event.target).hasClass('span-user-name')) {
                let currentState = _this.state.showUserDropdown;
                _this.setState({showUserDropdown: !currentState});
            } else {
                _this.setState({showUserDropdown: false});
            }
        });
    }


    // :
    // {tabId: 1, tab: "creative", permitted: "V"}
    // 1
    // :
    // {tabId: 2, tab: "marketing", permitted: "V"}
    // 2
    // :
    // {tabId: 5, tab: "product", permitted: "V"}
    // 3
    // :
    // {tabId: 3, tab: "publication", permitted: "V"}
    // 4
    // :
    // {tabId: 4, tab: "user", permitted: "V"}

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.currentPage != this.props.currentPage) {
            this.toggleListItemClassName(this.props.currentPage);
        }
    }

    componentWillUnmount() {
        // this.props.onRef(undefined)
    }

    componentWillReceiveProps(nextProps) {
    }


    toggleListItemClassName(pageName) {
        // localStorage.setItem('currentPage',pageName)

        // this.props.setCurrentPage(pageName);

        // let menuItemClassName = this.state.menuItem
        // $.each(menuItemClassName, function(key) {
        // 	if(key === pageName){
        // 		menuItemClassName[key] = 'active'
        // 	}
        // 	else {
        // 		menuItemClassName[key] = ''
        // 	}
        // });
        // this.setState({menuItem:menuItemClassName})

        //		if (!this.props.pageChange) {
        //			this.props.setCurrentPage(pageName);
        //			let menuItemClassName = this.state.menuItem
        //			$.each(menuItemClassName, function(key) {
        //				if(key === pageName){
        //					menuItemClassName[key] = 'active'
        //				}
        //				else {
        //					menuItemClassName[key] = ''
        //				}
        //			});
        //			this.setState({menuItem:menuItemClassName})
        //		} else {
        //			NotificationManager({message:'You have unsaved items which will be lost if you navigate outside, click save to save your work',type:4});
        //		}


    }

    checkForActive(pageName){
        let currentPage = this.props.currentPage,
            status = '';

        if(currentPage === pageName) {
            status = 'active';
        } else if(currentPage.indexOf("product") >= 0 && pageName.indexOf("product2") >= 0) {
            status = 'active';
        }

        return status;
        //		 return (localStorage.getItem('currentPage') === pageName) ? 'active' : '';
        //		return (this.props.history && (this.props.history.location.pathname).replace("/","") == pageName) ?  'active' : '';
    }

    modalOpen() {
        this.setState({isModalOpen: true});
    }

    modalClose() {
        this.setState({isModalOpen: false});
    }

    triggerModalOpen(target) {
        this.setState({modalName: target});
        this.modalOpen();
    }

    signout() {
        this.props.logout();
    }

    clickLink() {
        // this.toggleListItemClassName('product');
    }

    render() {
        let tabVisibility = this.state.tabVisibility || {};

        return (
            <LeftNavComponent
            	state = {this.state}
            	props = {this.props}
                loadAllImages= {this.loadAllImages}
                checkForActive= {this.checkForActive}
                modalOpen= {this.modalOpen}
                modalClose= {this.modalClose}
                triggerModalOpen= {this.triggerModalOpen}
                toggleListItemClassName = {this.toggleListItemClassName}
                signout = {this.signout}
                tabVisibility = {tabVisibility}
            />
        );
    }
}

export default LeftNav;


// const mapStateToProps = (state) => {
//     return {
//         auth: state.authentication
//     };
// };

// const mapDispatchToprops = (dispatch) => {
//     return {
//         onLogout: () => {
//             dispatch(loginActions.sweftLogout());
//         }
//     };
// };

// export default connect(mapStateToProps, mapDispatchToprops)(LeftNav);


