import React, { Component } from 'react';

class Modal extends Component {
    render() {
        let content;

        if (this.props.isOpen) {
            content = this.props.children;
        } else {
            content = null;
        }

        var extraClass = (this.props.addClass != undefined) ? this.props.addClass : '';

        return (
            <div className={`modal-container ${extraClass} ${(this.props.isOpen) ? "open" : ''} `}>
                <div className="modal-holder">
                    {content}
                </div>
            </div>
        );
    }
}

export default Modal;
