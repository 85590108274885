import React from 'react';

import MarketingComponent from './marketingComponent';

import './marketing.css';

import { connect } from 'react-redux';
import * as actions from './redux/marketingActions';
import axios from 'axios';

import * as utils from '../common/utils.js';

import GridDataBuilder from './helperComponents/gridDataBuilder';

import NotificationManager from '../common/components/notification';
import constants from '../common/constants.js';
import MarketingServices from './services/marketing-services';

class Marketing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isAddEventOpen: false,
            isModalOpen: false,
            modalName: '',
            eventData: '',
            totalCount: '0',
            addEventData: '',
            addEventLoaded: false,
            isEditrOrViewEventOpen: false,
            editEventLoaded: false,
            editEventData: {},
            editEventuntochedData: {},
            campaignOverviewData: '',
            editSaveEnable: false,
            currentRowData: null,

            modalName: '',
            photographyModalOpen: false,
            layoutModalOpen: false,
            copyWriteModalOpen: false,
            briefModalOpen: false,
            oActiveModal: {},
            oEditItems: [
                {
                    modName: "briefModal",
                    modState: "briefModalOpen"
                },
                {
                    modName: "copyWriteModal",
                    modState: "copyWriteModalOpen"
                },
                {
                    modName: "layoutModal",
                    modState: "layoutModalOpen"
                },
                {
                    modName: "photographyModal",
                    modState: "photographyModalOpen"
                },
                {
                    modName: "productModal",
                    modState: ""
                }
            ],
            activeEventID: "",
            saveSubmitDisable: true,
            briefOrCopySavedisable: true,
            imageArr: [],
            inputFile: [],
            inputFileName: "",
            mainImageSrc: "",
            isTaskReview: false,
            showReject: false,
            rejectText: "",
            rejectComments: "",
            isCancelModalForPhotoOpen: false,
            editable: true,
            noTaskContent: false,
            viewMode: false,
            copywriteData: "",
            disableRejectSave: true,
            spinner: false,
            user: {},
            rightDrawerPulled: false,

        };

        this.inputData = {};
        this.beginingData = {};
        this.openAddEvent = this.openAddEvent.bind(this);
        this.deleteEvent = this.deleteEvent.bind(this);
        this.closeAddEventModal = this.closeAddEventModal.bind(this);
        this.saveEvent = this.saveEvent.bind(this);
        this.submitEvent = this.submitEvent.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.closeAllModal = this.closeAllModal.bind(this);
        this.approveEvent = this.approveEvent.bind(this);
        this.rejectEvent = this.rejectEvent.bind(this);
        this.submitRejectReason = this.submitRejectReason.bind(this);
        this.closeEditOrViewEventModal = this.closeEditOrViewEventModal.bind(this);
        this.saveOrUpdateEvent = this.saveOrUpdateEvent.bind(this);
        this.getEventDetais = this.getEventDetais.bind(this);
        this.saveOrUpdateServicecall = this.saveOrUpdateServicecall.bind(this);
        this.closeAllEditModal = this.closeAllEditModal.bind(this);
        this.saveEditEvent = this.saveEditEvent.bind(this);
        this.submitEditEvent = this.submitEditEvent.bind(this);
        this.editScreenModified = this.editScreenModified.bind(this);


        this.validateEventName = this.validateEventName.bind(this);
        this.getEventOverview = this.getEventOverview.bind(this);
        this.handleDetailsModal = this.handleDetailsModal.bind(this);
        this.closeDetailsModal = this.closeDetailsModal.bind(this);
        this.closeBriefModal = this.closeBriefModal.bind(this);
        this.saveEventBrief = this.saveEventBrief.bind(this);
        this.closeCopyWriteModal = this.closeCopyWriteModal.bind(this);
        this.saveCopywrite = this.saveCopywrite.bind(this);
        this.closeLayoutModal = this.closeLayoutModal.bind(this);
        this.saveLayout = this.saveLayout.bind(this);
        this.submitLayout = this.submitLayout.bind(this);
        this.closePhotographyModal = this.closePhotographyModal.bind(this);
        this.savePhotography = this.savePhotography.bind(this);
        this.submitPhotography = this.submitPhotography.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
        this.changeValueHolder = this.changeValueHolder.bind(this);
        this.handleLayoutSubmit = this.handleLayoutSubmit.bind(this);
        this.updateMainViewer = this.updateMainViewer.bind(this);
        this.enableReject = this.enableReject.bind(this);
        this.approveEventTask = this.approveEventTask.bind(this);
        this.handleRejectField = this.handleRejectField.bind(this);
        this.UpdateMarketingRow = this.UpdateMarketingRow.bind(this);
        this.closeLayoutModalFunc = this.closeLayoutModalFunc.bind(this);
        this.closePhotographyModalFunc = this.closePhotographyModalFunc.bind(this);
        this.closePhotoorLayoutModal = this.closePhotoorLayoutModal.bind(this);
        this.resetTaskModalStates = this.resetTaskModalStates.bind(this);
        this.closeBriefModalFunc = this.closeBriefModalFunc.bind(this);
        this.closeCopyWriteModalFunc = this.closeCopyWriteModalFunc.bind(this);
        this.openPublishPanel = this.openPublishPanel.bind(this);

        this._isMounted = false;
    }


    componentWillMount() {
        localStorage.setItem('currentPage', 'marketing');
    }

    componentDidMount() {
        this._isMounted = true;

        this.setState({ spinner: true }, () => {
            let isNavigatedFromAlert = utils.getCookie('marketingAlert');
            if (isNavigatedFromAlert) {
                url = require('../common/configurations.js').apiURL + isNavigatedFromAlert;
                utils.deleteCookie('marketingAlert');
            }

            MarketingServices.getGridData().then((response) => {
                let res = response;
                if (res.responseCode === 200) {
                    let gridData = GridDataBuilder(res);
                    this.props.addColumnDetails(res.columnDetails);
                    this.props.addMarketingGridData(res.data);
                    if (res.data !== null) {
                        if (this._isMounted) {
                            this.setState({ isLoaded: true, eventData: res, totalCount: res.data.length, spinner: false, gridData: gridData });
                        }
                    }
                }
            }).catch((error) => {
                console.log('marketingContainer componentDidMount error: api call: ', error);
            });

            this.getAddEventObject();
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentWillReceiveProps(nextProps) {
        console.log('marketing container component will reveive props: ', nextProps, this.props);
        let res = {};
        res.data = nextProps.storeGridData;
        res.columnDetails = nextProps.columnDetails;
        if (nextProps.storeGridData && nextProps.storeGridData.length > 0) {
            console.log('hi inside: ', this.state);
            let gridData = GridDataBuilder(res);
            this.props.addColumnDetails(res.columnDetails);
            this.props.addMarketingGridData(res.data);
            this.setState({ isLoaded: false, gridData: gridData});
        }
    }



    componentDidUpdate(prevProps, prevState) {
        if (prevState.isLoaded !== this.state.isLoaded) {
            var _this = this;
            var marketView = $('#MarketingGrid');
            marketView.off();

            if (_this.marketingGrid) {
                let info = _this.marketingGrid.getpaginginformation();
                if (info.pagescount < 2) {
                    console.log("Page", info);
                    $(".jqx-position-absolute.jqx-grid-pager").html("<div style='line-height:35px;margin-left:5px;'>1-1 Page</div>");
                }
            }

            marketView.on('click', '.gridEventId', function (event) {
                if ($(this).parent('div').hasClass('deleted')) {
                    return false;
                }
                var _me = $(this),
                    row = _me.data('row'),
                    rowData = _this.marketingGrid.getrowdata(row);

                //Get Event data from the Service
                _this.getEventDetais(rowData.eventId);

                if (_this._isMounted) {
                    console.log('setting isEditrOrViewEventOpen and editEventLoaded to true');
                    _this.setState({ isEditrOrViewEventOpen: true, editEventLoaded: true, currentRowData: rowData });
                }

                if (_this.props.leftDrawerPulled) {
                    _this.props.toggleLeftDrawer();
                }

                if (_this.props.rightDrawerPulled) {
                    _this.props.toggleRightDrawer();
                }

                $('body').addClass('event-create');
            });

            marketView.on('click', '.gridEventName', function (event) {
                var _me = $(this),
                    row = _me.data('row'),
                    rowData = _this.marketingGrid.getrowdata(row);

                _this.getEventOverview(rowData.eventId);
                if (_this.props.leftDrawerPulled) {
                    _this.props.toggleLeftDrawer();
                }

                if (_this.props.rightDrawerPulled) {
                    _this.props.toggleRightDrawer();
                }
            });

            marketView.on('click', '.task-expand', function (event) {
                var _me = $(this),
                    row = _me.data('row'),
                    column = _me.data('ename'),
                    rowData = _this.marketingGrid.getrowdata(row),
                    targetId = 0,
                    targetModal = 0,
                    isAllowed = true,
                    defRole = _this.props.user.defaultRole,
                    editable = true;
                _this.setState({ currentRowData: rowData });
                if (rowData.status == "Prelimanary" || rowData.status == "Preliminary" || rowData.status == "preliminary") {
                    NotificationManager({
                        message: "Please try after admin approval.",
                        type: 4
                    });
                    return false;
                }

                if (defRole == "EventOwner") {
                    if (rowData[column] == "Ready") {
                        isAllowed = false;
                    }
                } else if (defRole == "Marketing") {
                    if ((rowData[column] == "Ready") || (column !== "eventBrief")) {
                        isAllowed = false;
                    }
                } else if (defRole == "CopyWriter") {
                    if ((rowData[column] == "Ready") || (column !== "copyWriting")) {
                        isAllowed = false;
                    }
                } else if (defRole == "GraphicDesigner") {
                    if ((rowData[column] == "Ready") || (column !== "layoutRdesign")) {
                        isAllowed = false;
                    }
                } else if (defRole == "Photographer") {
                    if ((rowData[column] == "Ready") || (column !== "photography")) {
                        isAllowed = false;
                    }
                } else {
                    isAllowed = false;
                }

                if (column == "eventBrief") {
                    targetId = rowData.eventBriefId;
                    targetModal = 0;
                    if (rowData.eventBriefeditable == "false") {
                        editable = false;
                    } else if (rowData.eventBriefeditable == "true") {
                        editable = true;
                    } else {
                        editable = false;
                    }
                } else if (column == "copyWriting") {
                    targetId = rowData.copyWritingId;
                    targetModal = 1;
                    if (rowData.copyWritingeditable == "false") {
                        editable = false;
                    } else if (rowData.copyWritingeditable == "true") {
                        editable = true;
                    } else {
                        editable = false;
                    }
                } else if (column == "layoutRdesign") {
                    targetId = rowData.layoutRdesignId;
                    targetModal = 2;
                    if (rowData.layoutRdesigneditable == "false") {
                        editable = false;
                    } else if (rowData.layoutRdesigneditable == "true") {
                        editable = true;
                    } else {
                        editable = false;
                    }
                } else if (column == "photography") {
                    targetId = rowData.photographyId;
                    targetModal = 3;
                    if (rowData.photographyeditable == "false") {
                        editable = false;
                    } else if (rowData.photographyeditable == "true") {
                        editable = true;
                    } else {
                        editable = false;
                    }
                } else {
                    targetId = "";
                }

                if (targetId)
                    _this.handleDetailsModal(targetId, targetModal, editable, isAllowed);
            });
        }

        // making the isloaded flase will destroy the marketing grid
        if (prevProps.gridDataCount.gridDataChange !== this.props.gridDataCount.gridDataChange
            || prevProps.gridDataCount.gridColumnChange !== this.props.gridDataCount.gridColumnChange) {
            if (this.state.isLoaded) {
                this.setState({ isLoaded: false });
            } else {
                this.rebuildTheGrid();
            }
        }

        if (prevState.isLoaded !== this.state.isLoaded && this.state.isLoaded === false) {
            this.rebuildTheGrid();
        }
    }

    reloadGrid() {
        this.setState({ isLoaded: false}, () => {
            MarketingServices.getGridData().then((response) => {
                let res = response;
                if (res.responseCode === 200) {
                    let gridData = GridDataBuilder(res);
                    this.props.addColumnDetails(res.columnDetails);
                    this.props.addMarketingGridData(res.data);
                    if (res.data !== null) {
                        if (this._isMounted) {
                            this.setState({ isLoaded: true, eventData: res, totalCount: res.data.length, spinner: false, gridData: gridData });
                        }
                    }
                }
            }).catch((error) => {
                console.log('marketingContainer componentDidMount error: api call: ', error);
            });
        });
    }

    openAddEvent() {
        let _this = this;

        this.setState({ isAddEventOpen: true });
        $('body').addClass('event-create');

        if (this.props.leftDrawerPulled) {
            this.props.toggleLeftDrawer();
        }

        if (_this.props.rightDrawerPulled) {
            _this.props.toggleRightDrawer();
        }

        var tasks = this.state.addEventData.listDropdowns;
        var taskarray = [];
        tasks.map((val, index) => {
            if (index == 0) {
                taskarray.push({ 'label': val.label, 'owner': this.props.user.userName, 'duedate': '' });
            } else {
                taskarray.push({ 'label': val.label, 'owner': '0', 'duedate': '' });
            }
        });

        this.beginingData = {
            eventId: "0",
            eventDesc: "",
            eventDate: "",
            eventEndDate: "",
            eventType: "0",
            tasks: taskarray
        };
    }

    deleteEvent() {
        let _this = this;
        let req = { "id": _this.state.editEventuntochedData.eventId };

        MarketingServices.deleteEvent(req).then((response) => {
            let res = response;
            if (res.status) {
                NotificationManager({ message: res.responseMessage, type: 1 });
                _this.reloadGrid();
                _this.closeAllEditModal();
            } else {
                NotificationManager({ message: res.responseMessage, type: 4 });
                _this.closeAllEditModal();
            }
        }).catch((error) => {
            console.log('marketingContainer deleteEvent error: api call: ', error);
        });
    }

    UpdateMarketingRow(rowId, rowData) {
        const _this = this;
        this.reloadGrid();
        if (_this.state.isModalOpen || _this.state.isEditrOrViewEventOpen) {
            _this.closeAllEditModal();
            return;
        }
        this.ensureVisible();
    }

    saveEvent() {
        // Retreive values from input fields
        this.getEventFormData('save');

        this.validateEventName();
    }

    saveEditEvent() {
        this.getEditEventFormData('save');

        this.validateEventName();
    }

    editScreenModified() {
        this.setState({ editSaveEnable: true });
    }

    validateEventName() {
        let error = false;

        if (this.inputData.eventDesc === "" || this.inputData.eventDesc.trim() === "") {
            error = true;
        }

        if (error) {
            let errmsg = "*Event name is mandatory to save an event.";
            NotificationManager({ message: errmsg, type: 4 });
            return;
        }
        this.saveOrUpdateServicecall(this.inputData);
    }

    getEventFormData(parm = 'save') {
        var eventDesc = $('.event-desc').val(),
            eventDate = $('.eventdate').val(),
            eventEndDate = $('.eventEndDate').val(),
            eventId = $('.event-id').val(),
            eventType = $('.event-type').val();
        var tasksCount = $('.task-row-container').length;
        var tasks = [];
        for (var i = 0; i < tasksCount - 1; i++) {
            var owner = $('#owner' + i).val();
            var label = $('#label' + i).html();
            var dueDate = $('#duedate' + i).val();
            tasks.push({ 'label': label, 'owner': owner, 'duedate': dueDate });
        }

        this.inputData = {
            action: parm,
            eventId: eventId,
            eventDesc: eventDesc,
            eventDate: eventDate,
            eventEndDate: eventEndDate,
            eventType: eventType,
            tasks: tasks
        };
    }

    getEditEventFormData(parm = 'save') {
        var eventDesc = $('.editevent-desc').val(),
            eventDate = $('.editeventdate').val(),
            eventEndDate = $('.editeventenddate').val(),
            eventId = $('.editevent-id').val(),
            eventType = $('.editevent-type').val();
        let tasksCount = $('.edit-task-row-container').length;
        let tasks = [];
        for (var i = 0; i < tasksCount; i++) {
            var owner = $('#editowner' + i).val();
            var label = $('#editlabel' + i).html();
            var dueDate = $('#editduedate' + i).val();
            var edittaskId = $('#edittaskId' + i).val();
            tasks.push({ 'label': label, 'owner': owner, 'duedate': dueDate });
        }

        this.inputData = {
            action: parm,
            eventId: eventId,
            eventDesc: eventDesc,
            eventDate: eventDate,
            eventEndDate: eventEndDate,
            eventType: eventType,
            tasks: tasks
        };
    }

    submitEvent() {
        // Retreive values from input fields
        this.getEventFormData('submit-for-approval');
        this.saveOrUpdateEvent();
    }

    submitEditEvent() {
        // Retreive values from input fields
        this.getEditEventFormData('submit-for-approval');
        this.saveOrUpdateEvent();
    }

    saveOrUpdateEvent() {
        let _this = this;
        let error = false;
        //validation for all mandatory fields
        if (this.inputData.eventDesc === "" || this.inputData.eventDesc.trim() === "") {
            error = true;
        } else if (this.inputData.eventDate == "") {
            error = true;
        } else if (this.inputData.eventEndDate == "") {
            error = true;
        } else if (this.inputData.eventType == "0") {
            error = true;
        }
        else {
            var tasks = this.inputData.tasks;
            $.each(tasks, function (index, value) {
                if (value.owner == "0") {
                    error = true;
                } else if (value.duedate == "" && value.owner != 'NA') {
                    error = true;
                }
            });
        }

        if (error) {
            let errmsg = "*Mandatory information marked with an asterisk is not accurate.";
            NotificationManager({ message: errmsg, type: 4 });
            _this.modalClose();
            return;
        }
        this.saveOrUpdateServicecall(this.inputData);
    }

    saveOrUpdateServicecall(req) {
        let _this = this;

        MarketingServices.addOrUpdateEvents(req).then((response) => {
            let res = response;
            if (res.status) {
                NotificationManager({ message: res.responseMessage, type: 1 });
                if (_this.inputData.eventId !== "0") {
                    _this.reloadGrid();
                    _this.closeAllEditModal();
                } else {
                    _this.reloadGrid();
                    _this.closeAllModal();
                }
            } else {
                NotificationManager({ message: res.responseMessage, type: 4 });
            }
        }).catch((error) => {
            console.log('marketingContainer saveOrUpdateServicecall error: api call: ', error);
        });

        this.ensureVisible();
    }

    modalClose() {
        this.setState({ isModalOpen: false });
    }

    closeAddEventModal() {
        var _this = this;
        this.getEventFormData();
        var intialData = this.beginingData;
        var inputData = this.inputData;
        delete inputData.action;
        if (this.didValueChanged(intialData, inputData)) {
            this.modalName = "SaveChanges";
            this.setState({ isModalOpen: true });
        } else {
            this.closeAllModal();
        }
    }

    didValueChanged(intialData, inputData) {
        if (JSON.stringify(intialData) === JSON.stringify(inputData)) {
            return false;
        } else {
            return true;
        }
    }

    closeAllModal() {
        this.setState({ isModalOpen: false });
        this.setState({ isAddEventOpen: false });
        $('body').removeClass('event-create');
    }

    ensureVisible() {
        console.log('====================================');
        console.log('ensureVisible getting called?', this.state.currentRowData.boundindex);
        console.log('====================================');
        const BIndex = this.state.currentRowData.boundindex;
        this.marketingGrid.ensurerowvisible(BIndex);
        this.setState ({ currentRowData: null });
    }

    closeAllEditModal() {
        this.setState({
            isEditrOrViewEventOpen: false,
            editEventLoaded: false,
            editEventuntochedData: {},
            editEventData: {},
            editSaveEnable: false
        });
        this.setState({ isModalOpen: false });
        $('body').removeClass('event-create');
        this.ensureVisible();
    }

    approveEvent() {
        let _this = this;
        let req = { "id": this.state.editEventuntochedData.eventId };

        MarketingServices.approvedByAdmin(req).then((response) => {
            let res = response;
            if (res.status) {
                NotificationManager({ message: res.responseMessage, type: 1 });

                _this.reloadGrid();
                _this.closeAllEditModal();
            } else {
                NotificationManager({ message: res.responseMessage, type: 4 });
                _this.modalClose();
            }
        }).catch((error) => {
            console.log('marketingContainer approveEvent error: api call: ', error);
        });
    }

    rejectEvent() {
        this.modalName = "RejectEvent";
        this.setState({ isModalOpen: true });
    }

    submitRejectReason() {
        let reason = $('.rejectReason').val();
        if (reason == '' || reason.trim() == "") {
            NotificationManager({ message: "Please specify the reason to Reject.", type: 4 });
            return false;
        } else if (reason.length > 1000) {
            NotificationManager({ message: "Rejection note exceeds the allowed length of <1000 characters", type: 4 });
            return false;
        }

        let eventId = this.state.editEventuntochedData.eventId;
        let req = { "id": eventId, 'value': reason };
        let _this = this;

        MarketingServices.rejectEvent(req).then((response) => {
            let res = response.data;
            if (res.status) {
                NotificationManager({ message: res.responseMessage, type: 1 });
                _this.closeAllEditModal();
                _this.reloadGrid();
            } else {
                NotificationManager({ message: res.responseMessage, type: 4 });
                _this.closeAllEditModal();
                _this.reloadGrid();
            }
        }).catch((error) => {
            console.log('marketingContainer submitRejectReason error: api call: ', error);
        });
    }


    closeEditOrViewEventModal() {
        var _this = this;
        _this.getEditEventFormData();
        var intialData = _this.beginingData;
        var inputData = _this.inputData;
        delete inputData.action;
        if (_this.didValueChanged(intialData, inputData)) {
            _this.modalName = "SaveEditChanges";
            _this.setState({ isModalOpen: true });
        } else {
            _this.closeAllEditModal();
        }
    }


    getAddEventObject() {
        var base = this;

        MarketingServices.addEventDataDetails().then((response) => {
            let res = response;
            if (res.responseCode === 200) {
                base.setState({ addEventData: res, addEventLoaded: true });
            }
        }).catch((error) => {
            console.log('marketingContainer getAddEventObject error: api call: ', error);
        });
    }

    getEventDetais(eventId) {
        let _this = this;
        let req = { "id": eventId };

        MarketingServices.getEventDetails(req).then((response) => {
            let res = response;
            if (res.responseCode === 200) {
                _this.props.allEventDetails(res.eventDetailsDTO);
                let taskarray = [];
                let taskarrayObj = [];
                let taskforUntoucheddata = [];
                let resultData = Object.assign({}, res.eventDetailsDTO);

                resultData.tasks.map((opt, opt_idx) => {
                    taskarray.push({ 'label': opt.label, 'owner': opt.owner, 'duedate': opt.duedate });
                });

                resultData.tasks.map((opt, opt_idx) => {
                    let className = opt.healthCheck.toLowerCase();
                    className = className.replace(/ /g, "-");
                    taskforUntoucheddata.push(
                        {
                            'label': opt.label,
                            'owner': opt.owner,
                            'duedate': opt.duedate,
                            'completedDate': opt.completedDate,
                            'taskId': opt.taskId,
                            'buttonStatus': opt.buttonStatus,
                            'healthCheck': opt.healthCheck,
                            'healthcheckClass': className,
                            'taskButtonStatus': opt.taskButtonStatus
                        });
                });

                let temObj = {
                    eventId: resultData.eventId,
                    eventDesc: resultData.eventDesc,
                    eventDate: resultData.eventDate,
                    eventEndDate: resultData.eventEndDate,
                    eventType: resultData.eventType,
                    tasks: taskarray
                };

                res.eventDetailsDTO.tasks.map((opt, opt_idx) => {
                    taskarrayObj.push({ 'label': opt.label, 'owner': opt.owner, 'duedate': opt.duedate ? opt.duedate : "" });
                });

                let temObjbegin = {
                    eventId: res.eventDetailsDTO.eventId,
                    eventDesc: res.eventDetailsDTO.eventDesc ? res.eventDetailsDTO.eventDesc : "",
                    eventDate: res.eventDetailsDTO.eventDate ? res.eventDetailsDTO.eventDate : "",
                    eventEndDate: res.eventDetailsDTO.eventEndDate ? res.eventDetailsDTO.eventEndDate : "",
                    eventType: res.eventDetailsDTO.eventType,
                    tasks: taskarrayObj
                };

                let temObjUntouched = {
                    eventId: resultData.eventId,
                    eventDesc: resultData.eventDesc,
                    eventDate: resultData.eventDate,
                    eventEndDate: resultData.eventEndDate,
                    eventType: resultData.eventType,
                    submitStatus: resultData.submitStatus,
                    adminApprovedStatus: resultData.adminApprovedStatus,
                    approvedBy: resultData.approvedBy,
                    eventStaus: resultData.eventStaus,
                    rejectComments: resultData.rejectComments,
                    tasks: taskforUntoucheddata
                };

                _this.beginingData = temObjbegin;
                _this.setState({ editEventData: temObj, editEventuntochedData: temObjUntouched });
            }
            else {
                NotificationManager({ message: res.responseMessage, type: 4 });
                _this.closeEditOrViewEventModal();
            }
        }).catch((error) => {
            console.log('marketingContainer getEventDetails error: api call: ', error);
        });
    }



    /**
     * Generate the Campain overview
     */
    getEventOverview(eventId) {
        let _this = this;
        let req = { "id": eventId };

        MarketingServices.campaignOverview(req).then((response) => {
            let res = response;
            if (res.status) {
                _this.modalName = 'CampaignOverview';
                _this.setState({ isModalOpen: true, campaignOverviewData: res });
            } else {
                NotificationManager({ message: res.responseMessage, type: 4 });
                _this.closeAllEditModal();
            }
        }).catch((error) => {
            console.log('marketingContainer getEventOverview error: api call: ', error);
        });
    }

    /**
     * rebuilds the grid with columndetails and data available from redux store
     */
    rebuildTheGrid() {
        this.setState({ isLoaded: false });

        let res = {};
        res.data = this.props.storeGridData;
        res.columnDetails = this.props.columnDetails;
        this.gridData = GridDataBuilder(res);
        this.props.addMarketingGridData(res.data);
        this.props.marketingCheckedRows([]);

        if (res.data !== null) {
            this.setState({ isLoaded: true, totalCount: res.data.length });
        }
        else {
            this.setState({ isLoaded: true, totalCount: '0' });
        }
    }

    handleDetailsModal(tid, tmodal, bEditable, bViewMode) {
        let _this = this;
        let thismodal = _this.state.oEditItems[tmodal];
        if (thismodal.modName != 'productModal') {
            _this.setState({
                modalName: thismodal.modName,
                [thismodal.modState]: true,
                oActiveModal: thismodal,
                editable: bEditable,
                viewMode: bViewMode
            });
        }

        if (this.props.user.defaultRole == "EventOwner") {
            _this.setState({ isTaskReview: true });
        }
        let eventID = tid;
        if (thismodal.modName == "briefModal") {
            _this.loadBriefData(eventID, thismodal.modName);
            _this.setState({ activeEventID: eventID });
        } else if (thismodal.modName == "copyWriteModal") {
            _this.loadBriefData(eventID, thismodal.modName);
            _this.setState({ activeEventID: eventID });
        } else if (thismodal.modName == "layoutModal") {
            _this.loadLayoutData(eventID);
            _this.setState({ activeEventID: eventID });
        } else if (thismodal.modName == "photographyModal") {
            _this.loadLayoutData(eventID);
            _this.setState({ activeEventID: eventID });
        }
    }

    loadBriefData(id, modal) {
        let _this = this;
        let postdata = {};
        postdata.id = id;

        MarketingServices.getEventBrief(postdata).then((response) => {
            let res = response;

            if (res.responseMessage === "success") {
                _this.props.eventTaskData(res.data[0]);

                if (res.data[0].taskData) {
                    if (modal == "briefModal") {
                        $('.value-holder').val(res.data[0].taskData);
                        let tempVal = $('.value-holder').val();
                        if (tempVal !== '') {
                            _this.setState({ saveSubmitDisable: false });
                        }
                        _this.setState({ briefOrCopySavedisable: true });
                    } else if (modal == "copyWriteModal") {
                        $(".event-copywrite").val(res.data[0].taskData);
                        _this.setState({ copywriteData: res.data[0].taskData });
                        if (res.data[0].taskData) {
                            _this.setState({ saveSubmitDisable: false });
                        }
                    }


                    if (_this.state.editable) {
                        _this.setState({ isTaskReview: false });
                    } else {
                        _this.setState({ isTaskReview: true });
                    }
                } else {
                    if (_this.state.editable) {
                        _this.setState({ noTaskContent: false, isTaskReview: false });
                    } else {
                        _this.setState({ noTaskContent: true });
                    }
                }

                if (!_this.state.noTaskContent) {
                    if (res.data[0].approvalStatus == "Approved") {
                        $('.status').text(res.data[0].approvalStatus + ' by ' + res.data[0].userId + ' on ' + res.data[0].approvalDate);
                        $('.gray-button:not([data-action="cancel"])').attr('disabled', true);
                        $('.value-holder').attr('disabled', true);
                        if (modal == "copyWriteModal") {
                            $('.modal-container').find('.jqte_editor').attr('contenteditable', "false");
                        }
                    } else if (res.data[0].approvalStatus == "Rejected") {
                        _this.setState({ rejectText: res.data[0].rejectComments });
                        if (res.data[0].submittedStatus == "Save") {
                            if (_this.state.isTaskReview) {
                                $('.value-holder').attr('disabled', true);
                                if (modal == "copyWriteModal") {
                                    $('.modal-container').find('.jqte_editor').attr('contenteditable', "false");
                                }
                                $('.gray-button[data-action="reject"]').trigger('click');
                                $('.reject-field').val(res.data[0].rejectComments).attr('disabled', true);
                                $('.gray-button:not([data-action="cancel"])').attr('disabled', true);
                            } else {
                                $('.status').html(res.data[0].approvalStatus + ' by ' + res.data[0].userId + ' on ' + res.data[0].approvalDate + '<br/><br/>' + "Review Notes: " + res.data[0].rejectComments);
                            }
                        } else if (res.data[0].submittedStatus == "Submitted") {
                            $('.value-holder').attr('disabled', true);
                            if (modal == "briefModal" && _this.state.editable) {
                                _this.setState({ isTaskReview: true });
                            } else if (modal == "copyWriteModal") {
                                $('.modal-container').find('.jqte_editor').attr('contenteditable', "false");
                            }

                            if (_this.state.isTaskReview) {
                                console.log('no need to do anything: Rejected Submitted EO');
                            } else {
                                $('.gray-button:not([data-action="cancel"])').attr('disabled', true);
                            }
                        }
                    } else if (res.data[0].approvalStatus == "none") {
                        if (res.data[0].submittedStatus == "Save") {
                            if (_this.state.isTaskReview) {
                                console.log('no need to do anything: none Save: EO');
                                if (!_this.state.editable) {
                                    $('.value-holder').attr('disabled', true);
                                    if (modal == "copyWriteModal") {
                                        $('.modal-container').find('.jqte_editor').attr('contenteditable', "false");
                                    }
                                }
                            } else {
                                console.log('no need to do anything: none Save: TO');
                            }
                        } else if (res.data[0].submittedStatus == "Submitted") {
                            $('.value-holder').attr('disabled', true);
                            if (modal == "briefModal" && _this.state.editable) {
                                _this.setState({ isTaskReview: true });
                            } else if (modal == "copyWriteModal") {
                                $('.modal-container').find('.jqte_editor').attr('contenteditable', "false");
                            }

                            if (_this.state.isTaskReview) {
                                console.log('no need to do anything: none Save: TO');
                            } else {
                                $('.gray-button:not([data-action="cancel"])').attr('disabled', true);
                            }
                        }
                    } else if (res.data[0].approvalStatus == "save") {
                        $('.value-holder').attr('disabled', true);
                        _this.setState({ rejectText: res.data[0].rejectComments });
                        if (modal == "briefModal" && _this.state.editable) {
                            _this.setState({ isTaskReview: true });
                        } else if (modal == "copyWriteModal") {
                            $('.modal-container').find('.jqte_editor').attr('contenteditable', "false");
                        }
                        if (_this.state.isTaskReview) {
                            $('.gray-button[data-action="reject"]').trigger('click');
                            $('.reject-field').val(res.data[0].rejectComments);
                        } else {
                            $('.gray-button:not([data-action="cancel"])').attr('disabled', true);
                        }
                    }
                }
            }
        }).catch((error) => {
            console.log('marketingContainer loadBriefData error: api call: ', error);
            NotificationManager({ message: `could not get the data`, type: 4 });
        });
    }

    loadLayoutData(id) {
        let _this = this;
        let postdata = {};
        postdata.id = id;

        MarketingServices.getPhotos(postdata).then((response) => {
            let res = response;
            if (res.responseCode === 200) {
                let arrImgData = res.data;

                if (res.data[0].taskData) {
                    _this.setState({ noTaskContent: false });
                } else {
                    if (_this.state.editable) {
                        _this.setState({ noTaskContent: false, isTaskReview: false });
                    } else {
                        _this.setState({ noTaskContent: true });
                    }
                }

                if (!_this.state.noTaskContent) {
                    let arrImgResult = [];
                    arrImgData.map(function (oData) {
                        if (oData.taskData) {
                            arrImgResult.push(oData.taskData);
                        }
                    });
                    _this.setState({
                        imageArr: arrImgResult,
                        mainImageSrc: ((arrImgResult.length > 0) ? arrImgResult[0] : '')
                    });

                    if (!_this.state.noTaskContent) {
                        if (res.data[0].approvalStatus == "Approved") {
                            $('.status').text(res.data[0].approvalStatus + ' by ' + res.data[0].userId + ' on ' + res.data[0].approvalDate);
                            $('.gray-button:not([data-action="cancel"])').attr('disabled', true);
                            $('.icon-holder').attr('disabled', true);
                        } else if (res.data[0].approvalStatus == "Rejected") {
                            _this.setState({ rejectText: res.data[0].rejectComments });
                            if (res.data[0].submittedStatus == "Save") {
                                if (_this.state.isTaskReview) {
                                    $('.icon-holder').attr('disabled', true);
                                    $('.gray-button[data-action="reject"]').trigger('click');
                                    $('.reject-field').val(res.data[0].rejectComments).attr('disabled', true);
                                    $('.gray-button:not([data-action="cancel"])').attr('disabled', true);
                                } else {
                                    $('.status').html(res.data[0].approvalStatus + ' by ' + res.data[0].userId + ' on ' + res.data[0].approvalDate + '<br/><br/>' + "Review Notes: " + res.data[0].rejectComments);
                                }
                            } else if (res.data[0].submittedStatus == "Submitted") {
                                $('.icon-holder').attr('disabled', true);
                                if (_this.state.isTaskReview) {
                                    console.log('no need to do anything: Rejected Submitted EO');
                                } else {
                                    $('.gray-button:not([data-action="cancel"])').attr('disabled', true);
                                }
                            }
                        } else if (res.data[0].approvalStatus == "none") {
                            if (res.data[0].submittedStatus == "Save") {
                                if (_this.state.isTaskReview) {
                                    console.log('no need to do anything: none Save: EO');
                                    if (!_this.state.editable) {
                                        $('.icon-holder').attr('disabled', true);
                                    }
                                } else {
                                    console.log('no need to do anything: none Save: TO');
                                }
                            } else if (res.data[0].submittedStatus == "Submitted") {
                                $('.icon-holder').attr('disabled', true);
                                if (_this.state.isTaskReview) {
                                    console.log('no need to do anything: none Save: TO');
                                } else {
                                    $('.gray-button:not([data-action="cancel"])').attr('disabled', true);
                                }
                            }
                        } else if (res.data[0].approvalStatus == "save") {
                            $('.icon-holder').attr('disabled', true);
                            _this.setState({ rejectText: res.data[0].rejectComments });
                            if (_this.state.isTaskReview) {
                                $('.gray-button[data-action="reject"]').trigger('click');
                                $('.reject-field').val(res.data[0].rejectComments);
                            } else {
                                $('.gray-button:not([data-action="cancel"])').attr('disabled', true);
                            }
                        }
                    }
                }
            }
        }).catch((error) => {
            console.log('marketingContainer loadLayoutData error: api call: ', error);
            NotificationManager({ message: res.responseMessage, type: 4 });
        });
    }

    resetTaskModalStates() {
        let _this = this;
        _this.setState({ editable: true, noTaskContent: false, viewMode: false });
        this.ensureVisible();
    }

    closeDetailsModal() {
        let _this = this;
        let clearActiveModal = {};
        _this.setState({ modalName: '', [oActiveModal.modState]: false, oActiveModal: clearActiveModal });
        _this.resetTaskModalStates();
    }

    closeBriefModal() {
        if (!this.state.briefOrCopySavedisable) {
            this.setState({ isCancelModalForPhotoOpen: true });
        }
        else {
            this.closeBriefModalFunc();
        }
    }

    closeBriefModalFunc() {
        let _this = this;
        let clearActiveModal = {};
        _this.setState({ modalName: '', briefModalOpen: false, oActiveModal: clearActiveModal, activeEventID: '' });
        if (_this.state.isTaskReview) {
            _this.setState({ isTaskReview: false, showReject: false });
        }
        _this.resetTaskModalStates();
    }

    saveSubmitCall(data, type, event) {
        const _this = this;
        if (event == 'brief') {
            if (type == 'save') {
                MarketingServices.saveEventBrief(data).then((response) => {
                    let res = response;
                    if (res.responseCode === 200) {
                        NotificationManager({ message: res.responseMessage, type: 1 });
                        _this.closeBriefModal();
                        _this.reloadGrid();
                        _this.setState({ briefOrCopySavedisable: true, saveSubmitDisable: true });
                    }
                }).catch((error) =>{
                    console.log('marketingContainer saveEventBrief  API call failed', error);
                    NotificationManager({ message: "Please enter the event brief.", type: 4 });
                });
            } else {
                MarketingServices.submitEventBrief(data).then((response) => {
                    let res = response;
                    if (res.responseCode === 200) {
                        NotificationManager({ message: res.responseMessage, type: 1 });
                        _this.closeBriefModal();
                        _this.reloadGrid();
                        _this.setState({ briefOrCopySavedisable: true, saveSubmitDisable: true });
                    }
                }).catch((error) =>{
                    console.log('marketingContainer saveEventBrief  API call failed', error);
                    NotificationManager({ message: "Please enter the event brief.", type: 4 });
                });
            }
        } else {
            if (type == 'save') {
                MarketingServices.saveEventBrief(data).then((response) => {
                    let res = response;
                    if (res.responseCode === 200) {
                        NotificationManager({ message: res.responseMessage, type: 1 });
                        _this.reloadGrid();
                        _this.setState({ briefOrCopySavedisable: true, saveSubmitDisable: true }, () => {
                            _this.closeCopyWriteModal();
                        });
                    } else {
                        NotificationManager({ message: res.responseMessage, type: 4 });
                    }
                }).catch((error) =>{
                    console.log('marketingContainer saveCopywrite  API call failed', error);
                    NotificationManager({ message: `could not get the data`, type: 4 });
                });
            } else {
                MarketingServices.submitEventBrief(data).then((response) => {
                    let res = response;
                    if (res.responseCode === 200) {
                        NotificationManager({ message: res.responseMessage, type: 1 });
                        _this.reloadGrid();
                        _this.setState({ briefOrCopySavedisable: true, saveSubmitDisable: true }, () => {
                            _this.closeCopyWriteModal();
                        });
                    } else {
                        NotificationManager({ message: res.responseMessage, type: 4 });
                    }
                }).catch((error) =>{
                    console.log('marketingContainer saveCopywrite  API call failed', error);
                    NotificationManager({ message: `could not get the data`, type: 4 });
                });
            }
        }
    }

    saveEventBrief() {
        let _this = this;
        let value = $('.value-holder').val();
        if (value !== "" || value.trim() !== "") {
            if (value.length > 1000) {
                NotificationManager({ message: "Please shorten your Event Breif to <1000 characters", type: 4 });
                return false;
            }
            let postdata = {};
            let listUrl = "";
            if ($(event.target).attr('data-action') == "save") {
                listUrl = 'save';
            } else if ($(event.target).attr('data-action') == "submit") {
                listUrl = 'submit';
            } else {
                return false;
            }
            let eventId = $(event.target).attr('data-val');
            postdata.id = eventId;
            postdata.value = value;

            this.saveSubmitCall(postdata, listUrl, 'brief');
        } else {
            NotificationManager({ message: "Please enter the event brief.", type: 4 });
        }
    }

    closeCopyWriteModal() {
        if (!this.state.briefOrCopySavedisable) {
            this.setState({ isCancelModalForPhotoOpen: true });
        }
        else {
            this.closeCopyWriteModalFunc();
        }
    }

    closeCopyWriteModalFunc() {
        let _this = this;
        let clearActiveModal = {};
        _this.setState({ modalName: '', copyWriteModalOpen: false, oActiveModal: clearActiveModal, activeEventID: '' });
        if (_this.state.isTaskReview) {
            _this.setState({ isTaskReview: false, showReject: false, copywriteData: "" });
        }
        _this.resetTaskModalStates();
    }


    saveCopywrite() {
        let _this = this;
        let value = $('.event-copywrite').val();
        if (value !== "" || value.trim() !== "") {
            if (value.length > 1000) {
                NotificationManager({ message: "Please shorten your Event Copy to <1000 characters", type: 4 });
                return false;
            }
            let postdata = {};
            let listUrl = "";
            if ($(event.target).attr('data-action') == "save") {
                listUrl = 'save';
            } else if ($(event.target).attr('data-action') == "submit") {
                listUrl = 'submit';
            } else {
                return false;
            }
            let eventId = $(event.target).attr('data-val');
            postdata.id = eventId;
            postdata.value = value;

            this.saveSubmitCall(postdata, listUrl, 'copy');
        } else {
            NotificationManager({ message: "Please enter the event copywrite.", type: 4 });
        }
    }

    closeLayoutModal() {
        if (this.state.inputFile.length > 0) {
            this.setState({ isCancelModalForPhotoOpen: true });
        }
        else {
            this.closeLayoutModalFunc();
        }
    }

    closeLayoutModalFunc() {
        let _this = this;
        let clearActiveModal = {};
        _this.setState({
            modalName: '',
            layoutModalOpen: false,
            oActiveModal: clearActiveModal,
            activeEventID: '',
            imageArr: [],
            mainImageSrc: "",
            inputFile: [],
            disableRejectSave: true
        });
        if (_this.state.isTaskReview) {
            _this.setState({ isTaskReview: false, showReject: false });
        }
        _this.resetTaskModalStates();
    }

    saveLayout() {
        let _this = this;
        let postdata = {};
        postdata.id = id;

        MarketingServices.submitPhotos(postdata).then((response) => {
            let res = response;
            if (res.responseCode === 200) {
                NotificationManager({ message: res.responseMessage, type: 1 });
            }
        }).catch((error) => {
            console.log('marketingContainer saveLayout  API call failed', error);
            NotificationManager({ message: `could not get the data`, type: 4 });
        });
    }

    handleLayoutSubmit(e) {
        e.preventDefault();
        let _this = this;
        var form = new FormData($('[name="event-images-upload"]')[0]);
        _this.setState({ inputFile: [], inputFileName: "" });

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        axios({
            method: 'post',
            url: require('../common/configurations.js').marketing.addPhotos,
            headers: headers,
            data: form
        })
            .then((response) => {
                console.log('marketingContainer saveLayout API call after makeCallToRequiredFields success data: ', response.data);
                let data = response.data;

                let arrImg = _this.state.imageArr;
                let arrResult = data.data[0].taskData;
                arrImg.push(arrResult);
                _this.setState({ imageArr: arrImg, mainImageSrc: arrResult });
                NotificationManager({ message: "Image uploaded successfully", type: 1 });
            })
            .catch(error => {
                console.log('marketingContainer saveLayout  API call failed', error);
                NotificationManager({
                    message: "failed to upload image due to technical reasons, please try again",
                    type: 4
                });
            });
    }

    submitLayout(e) {
        e.preventDefault();
        let _this = this;
        if (_this.state.imageArr.length > 0) {
            let postdata = {};
            postdata.id = $(e.target).attr('data-val');

            MarketingServices.submitPhotos(postdata).then((response) => {
                let data = response;
                _this.setState({ inputFile: [], inputFileName: "", inputFile: [] });
                NotificationManager({ message: data.responseMessage, type: 1 });

                _this.closeLayoutModal();
            }).catch((error) => {
                console.log('marketingContainer submitLayout  API call failed', error);
                _this.setState({ inputFile: [], inputFileName: "", inputFile: [] });
                NotificationManager({
                    message: "failed to submit due to technical reasons, please try again",
                    type: 4
                });
            });
        } else {
            NotificationManager({
                message: "Please save some images before submitting for approval",
                type: 4
            });
        }
    }

    closePhotographyModal() {
        if (this.state.inputFile.length > 0) {
            this.setState({ isCancelModalForPhotoOpen: true });
        }
        else {
            this.closePhotographyModalFunc();
        }
    }

    closePhotographyModalFunc() {
        let _this = this;
        let clearActiveModal = {};
        _this.setState({
            modalName: '',
            photographyModalOpen: false,
            oActiveModal: clearActiveModal,
            activeEventID: '',
            imageArr: [],
            mainImageSrc: "",
            inputFile: [],
            disableRejectSave: true
        });

        if (_this.state.isTaskReview) {
            _this.setState({ isTaskReview: false, showReject: false });
        }
        this.resetTaskModalStates();
    }

    closePhotoorLayoutModal(action) {
        let _this = this;

        switch (action) {
            case 'yes':
                if (_this.state.photographyModalOpen) {
                    $('#savePhotodata').trigger('click');
                    _this.closePhotoorLayoutModal('no');
                } else if (_this.state.layoutModalOpen) {
                    $('#saveLayoutData').trigger('click');
                    _this.closePhotoorLayoutModal('no');
                } else if (_this.state.briefModalOpen) {
                    $('#saveBrief').trigger('click');
                    _this.closePhotoorLayoutModal('no');
                } else if (_this.state.briefModalOpen) {
                    _this.closeBriefModalFunc();
                    _this.closePhotoorLayoutModal('cancel');
                    _this.setState({ briefOrCopySavedisable: true, saveSubmitDisable: true });
                } else if (_this.state.copyWriteModalOpen) {
                    $('#saveCopy').trigger('click');
                    _this.closePhotoorLayoutModal('no');
                }

                break;

            case 'no':
                if (_this.state.photographyModalOpen) {
                    _this.closePhotographyModalFunc();
                    _this.closePhotoorLayoutModal('cancel');
                } else if (_this.state.layoutModalOpen) {
                    _this.closeLayoutModalFunc();
                    _this.closePhotoorLayoutModal('cancel');
                } else if (_this.state.briefModalOpen) {
                    _this.closeBriefModalFunc();
                    _this.closePhotoorLayoutModal('cancel');
                    _this.setState({ briefOrCopySavedisable: true, saveSubmitDisable: true });
                }
                else if (_this.state.copyWriteModalOpen) {
                    _this.closeCopyWriteModalFunc();
                    _this.closePhotoorLayoutModal('cancel');
                    _this.setState({ briefOrCopySavedisable: true, saveSubmitDisable: true });
                }
                break;
            case 'cancel':
                _this.setState({ isCancelModalForPhotoOpen: false });
                break;
        }
    }

    savePhotography() {
    }

    submitPhotography(e) {
        e.preventDefault();
        let _this = this;
        if (_this.state.imageArr.length > 0) {
            let postdata = {};
            postdata.id = $(e.target).attr('data-val');

            MarketingServices.submitPhotos(postdata).then((response) => {
                let data = response;
                _this.setState({ inputFile: [], inputFileName: "", inputFile: [] });
                NotificationManager({ message: data.responseMessage, type: 1 });

                _this.closePhotographyModal();
            }).catch((error) => {
                console.log('marketingContainer submitPhotography  API call failed', error);
                _this.setState({ inputFile: [], inputFileName: "", inputFile: [] });
                NotificationManager({
                    message: "failed to submit due to technical reasons, please try again",
                    type: 4
                });
            });
        } else {
            NotificationManager({
                message: "Please save some images before submitting for approval",
                type: 4
            });
        }
    }

    handleFileChange(e) {
        let _this = this;
        e.preventDefault();

        var target = e.target,
            list = target.files;

        var fileArray = [];
        for (var i = 0; i < list.length; i++) {
            var fileList = {};

            for (var l in list[i]) {
                fileList[l] = list[i][l];
            }
            fileArray.push(fileList);
        }

        if (list.length > 0 && fileArray.length > 0) {
            this.setState({ inputFile: fileArray, inputFileName: fileArray[0].name });
        }
    }

    updateMainViewer(val) {
        let _this = this;
        var currentSrc = _this.state.imageArr[val];
        _this.setState({ mainImageSrc: currentSrc });
    }

    changeValueHolder() {
        let _this = this;
        if (event.target.value !== "" && event.target.value.trim() !== "") {
            _this.setState({ saveSubmitDisable: false, briefOrCopySavedisable: false });
        } else {
            _this.setState({ saveSubmitDisable: true, briefOrCopySavedisable: true });
        }
    }

    enableReject() {
        this.setState({ showReject: true });
    }

    approveEventTask(e) {
        e.preventDefault();
        let _this = this;
        let postdata = {};
        let flag = 'approve';

        postdata.id = $(event.target).attr('data-val');
        if ($(event.target).attr('data-action') == "approve") {
            postdata.comments = "";
            postdata.value = "true";
        } else if ($(event.target).attr('data-action') == "rejectsubmit") {
            postdata.comments = $('.reject-field').val();
            postdata.value = "false";
        } else if ($(event.target).attr('data-action') == "rejectsave") {
            postdata.comments = $('.reject-field').val();
            postdata.value = "false";
            flag = 'reject';
        }

        if (flag == 'approve') {
            MarketingServices.approveReject(postdata).then((response) => {
                let data = response;
                NotificationManager({ message: data.responseMessage, type: 1 });

                _this.reloadGrid();
                if (_this.state.briefModalOpen) {
                    _this.closeBriefModal();
                }
                if (_this.state.copyWriteModalOpen) {
                    _this.closeCopyWriteModal();
                }
                if (_this.state.layoutModalOpen) {
                    _this.closeLayoutModal();
                }
                if (_this.state.photographyModalOpen) {
                    _this.closePhotographyModal();
                }
            }).catch((error) => {
                console.log('marketingContainer approveEventTask  API call failed', error);
                NotificationManager({
                    message: "Failed to approve due to technical reasons",
                    type: 4
                });
            });
        } else {
            MarketingServices.saveReject(postdata).then((response) => {
                let data = response;
                NotificationManager({ message: data.responseMessage, type: 1 });

                _this.reloadGrid();
                if (_this.state.briefModalOpen) {
                    _this.closeBriefModal();
                }
                if (_this.state.copyWriteModalOpen) {
                    _this.closeCopyWriteModal();
                }
                if (_this.state.layoutModalOpen) {
                    _this.closeLayoutModal();
                }
                if (_this.state.photographyModalOpen) {
                    _this.closePhotographyModal();
                }
            }).catch((error) => {
                console.log('marketingContainer approveEventTask  API call failed', error);
                NotificationManager({
                    message: "Failed to approve due to technical reasons",
                    type: 4
                });
            });
        }
    }

    handleRejectField(e) {
        let _this = this;
        let elemText = $(event.target).val();
        _this.setState({ disableRejectSave: false });
        if (elemText !== "" && elemText.trim() !== "") {
            _this.setState({ rejectText: elemText });
        } else {
            _this.setState({ rejectText: "" });
        }
    }

    onRowselect(event) {
        console.log('====================================');
        console.log(event);
        console.log('====================================');
        const _this = this;
        let rowIndices = _this.marketingGrid.getselectedrowindexes();
        let newCheckedData = [];
        rowIndices.map((val, key) => {
            let eventData = _this.marketingGrid.getrowdata(val);
            let eventId = eventData.eventId;

            if (newCheckedData.indexOf(eventId) < 0) {
                newCheckedData.push(eventData);
            }
        });
        _this.setState({
            newCheckedData: newCheckedData,
        }, () => {
            _this.props.marketingCheckedRows(_this.state.newCheckedData);
        });
    }

    onRowunselect(event) {
        const _this = this;
        let rowIndices = _this.marketingGrid.getselectedrowindexes();
        let newCheckedData = [];
        rowIndices.map((val, key) => {
            let eventData = _this.marketingGrid.getrowdata(val);
            let eventId = eventData.eventId;
            if (newCheckedData.indexOf(eventId) < 0) {
                newCheckedData.push(eventData);
            }
        });
        _this.setState({
            newCheckedData: newCheckedData,
        }, () => {
            _this.props.marketingCheckedRows(_this.state.newCheckedData);
        });
    }

    openPublishPanel() {
        const _this = this;
        let lastElement;
        if (!_this.props.rightDrawerPulled) {
            _this.props.toggleRightDrawer();
        }
        lastElement = _this.state.newCheckedData[_this.state.newCheckedData.length - 1];
        if (_this.state.newCheckedData && _this.state.newCheckedData.length > 0) {
            setTimeout(() => {
                _this.state.newCheckedData.map((i, x) => {
                    _this.marketingGrid.selectrow(i.boundindex);
                });
                _this.marketingGrid.ensurerowvisible(lastElement.boundindex);
            }, 200);
        }
    }

    render() {
        console.log('Marketing Container render: ', this.props, this.state);

        let props = this.props;

        let footerHeight = 50,
            headerHeight = 64,
            filterStrip = 0;
        let gridHeight = window.innerHeight - footerHeight - headerHeight - filterStrip;


        let imageErrorCheckGrid = this.state.mainImageSrc ? <img src={this.state.mainImageSrc}/> : '';

        let editSettings = {
            saveOnPageChange: true,
            saveOnBlur: true,
            saveOnSelectionChange: true,
            cancelOnEsc: true,
            saveOnEnter: true,
            editSingleCell: true,
            editOnDoubleClick: true,
            editOnF2: true
        };

        let modalClass = "ConfirmRulesDelete " + this.modalName;

        let currentPage = localStorage.getItem('currentPage');

        return (
            <MarketingComponent
                state={this.state}
                props={this.props}

                marketingGrid={(grid) => { this.marketingGrid = grid; }}
                gridData={this.state.gridData}
                gridHeight={gridHeight}
                editSettings={editSettings}
                closeAddEventModal={this.closeAddEventModal}

                UpdateMarketingRow={this.UpdateMarketingRow}
                editScreenModified={this.editScreenModified}
                closeEditOrViewEventModal={this.closeEditOrViewEventModal}

                modalClass={this.modalClass}
                modalClose={this.modalClose}
                submitRejectReason={this.submitRejectReason}
                modalName={this.modalName}
                saveEvent={this.saveEvent}
                closeAllModal={this.closeAllModal}
                saveEditEvent={this.saveEditEvent}
                closeAllEditModal={this.closeAllEditModal}

                closeBriefModal={this.closeBriefModal}
                changeValueHolder={this.changeValueHolder}
                saveEventBrief={this.saveEventBrief}
                enableReject={this.enableReject}
                approveEventTask={this.approveEventTask}
                handleRejectField={this.handleRejectField}
                closeCopyWriteModal={this.closeCopyWriteModal}
                saveCopywrite={this.saveCopywrite}
                closeLayoutModal={this.closeLayoutModal}
                updateMainViewer={this.updateMainViewer}
                handleLayoutSubmit={this.handleLayoutSubmit}
                handleFileChange={this.handleFileChange}
                submitLayout={this.submitLayout}
                closePhotographyModal={this.closePhotographyModal}
                submitPhotography={this.submitPhotography}

                openAddEvent={this.openAddEvent}
                deleteEvent={this.deleteEvent}
                submitEditEvent={this.submitEditEvent}
                submitEvent={this.submitEvent}
                approveEvent={this.approveEvent}
                rejectEvent={this.rejectEvent}

                findRef={(ins) => { this.FindChild = ins; }}
                marketingGridThis={this}

                closePhotoorLayoutModal={this.closePhotoorLayoutModal}

                currentPage={currentPage}
                imageErrorCheckGrid = {imageErrorCheckGrid}
                onRowunselect = {this.onRowunselect.bind(this)}
                onRowselect = {this.onRowselect.bind(this)}
                openPublishPanel = {this.openPublishPanel}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        storeGridData: state.mk_gridData,
        gridDataCount: state.mk_gridDataUpdate,
        columnDetails: state.mk_columnDetails,
        currentTaskData: state.mk_eventTaskData,
        allTaskData: state.mk_allEventDetails
    };
};

const mapDispatchToprops = (dispatch) => {
    return {

        addColumnDetails: (data) => {
            dispatch(actions.addColumnDetails(data));
        },
        allEventDetails: (data) => {
            dispatch(actions.allEventDetails(data));
        },
        eventTaskData: (data) => {
            dispatch(actions.eventTaskData(data));
        },
        marketingCheckedRows: (data) => {
            dispatch(actions.updateSelectedRows(data));
        },
        addMarketingGridData: (data) => {
            dispatch(actions.addGridData(data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToprops)(Marketing);

