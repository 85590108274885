import React, { Component } from 'react';

class FieldPermissionsFooter extends Component {
    constructor(props) {
        super(props);
        this.state  = {
            isGridModified: false,
        };
    }

    enableFooter() {
        this.setState({isGridModified: true});
    }

    disableFooter() {
        this.setState({isGridModified: false});
    }

    render() {
        console.log('Field Permission Footer', this.props);
        return (
            <div className="footer alerts-footer">
                {/* <div className="footer-content left">
					<button type="button" className="gray-button" onClick={this.props.addNewField}>Add New Field</button>
					<button type="button" className="gray-button" onClick={this.props.deleteFields} disabled={(this.props.isDeletable) ? false : true}>Delete</button>
				</div> */}
                <div className="footer-content right">
                    <button type="button" className="gray-button" disabled={(this.state.isGridModified) ? false : true} onClick={this.props.cancelChanges}>Cancel Changes</button>
                    <button type="button" className="gray-button"
                        onClick={() => this.props.applyChanges()} disabled={(this.state.isGridModified) ? false : true}>Apply Changes</button>
                </div>
            </div>
        );
    }
}

export default FieldPermissionsFooter;
