import React, { Component } from 'react';
// import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
// import JqxTreeGrid from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxtreegrid';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import JqxGrid from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';

// import JqxTreeGrid from '../../../common/vendor/jqwidgets-react/react_jqxtreegrid.js';
import Modal from '../../../modals/common-modal.js';
import ConfirmSubmit from './confirmsubmit.js';
import ReceiveSampleModal from './receiveSampleModal';
import SwatchReferenceModal from './swatchReferenceModal';
import MoveSampleModal from './moveSampleModal';
import PdfViewerModal from './pdfViewerModal';
import axios from 'axios';

// var constants = require('../../common/constants.js');
const utils = require('../../../common/utils.js');
// var NotificationManager = require('../../common/notification');
import NotificationManager from '../../../common/components/notification';

const GridDataBuilder = require('../../../common/gridDataBuilder');

import { ProductServices } from '../../services/product-services';

class SampleModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isOpenReceiveModal: false,
            isOpenMoveModal: false,
            isModalOpen: false,
            isOpenSwatchModal: false,
            pdfModalOpen: false,
            pdfFile: null,
            currentRowData: null,
            addNewRow: false,
            gridData: null,
            disableClick: false,
        };
        this.swatchReferenceList = null;
        this.currentGridData = null;
        this.currentRowData = null;
        this.modalDialog = 'SampleConfirm';
        this.IdArray = [];
        this.openReceiveModalBox = this.openReceiveModalBox.bind(this);
        this.closeReceiveModalBox = this.closeReceiveModalBox.bind(this);
        this.openMoveModalBox = this.openMoveModalBox.bind(this);
        this.closeMoveModalBox = this.closeMoveModalBox.bind(this);
        this.generateSampleCard = this.generateSampleCard.bind(this);
        this.rePrintSampleCard = this.rePrintSampleCard.bind(this);
        this.moveSample = this.moveSample.bind(this);
        this.sampleProductUpdate = this.sampleProductUpdate.bind(this);
        this.openConfirmModal = this.openConfirmModal.bind(this);
        this.closeConfirmModal = this.closeConfirmModal.bind(this);
        this.showSampleSwatchModal = this.showSampleSwatchModal.bind(this);
        this.unCheckAll = this.unCheckAll.bind(this);
        this.closeSwatchModalBox = this.closeSwatchModalBox.bind(this);
        this.openSwatchModalBox = this.openSwatchModalBox.bind(this);
        this.yesSwatchOnly = this.yesSwatchOnly.bind(this);
        this.noSwatchOnly = this.noSwatchOnly.bind(this);
        this.moveSampleRequest = this.moveSampleRequest.bind(this);
        this.enableSampleReceivedFlag = this.enableSampleReceivedFlag.bind(this);
        this.closePdfModal = this.closePdfModal.bind(this);
    }

    /**
     * Closes the confirm modal
     */
    closeConfirmModal() {
        this.setState({ isModalOpen: false });
    }

    /**
     * Opens the confirm modal
     */
    openConfirmModal() {
        this.setState({ isModalOpen: true });
    }

    /**
     * Opens receive modal
     */
    openReceiveModalBox() {
        this.setState({ isOpenReceiveModal: true });
    }

    /**
     * Closes the receive modal
     */
    closeReceiveModalBox() {
        this.setState({ isOpenReceiveModal: false });
    }

    /**
     * Opens move modal
     */
    openMoveModalBox() {
        if (this.state.selectedRows && this.state.selectedRows.length > 0) {
            this.setState({ isOpenMoveModal: true });
        } else {
            NotificationManager({ message: 'Please select a record to "Move Samples"', type: 4 });
        }
    }

    /**
     * Closes the move modal
     */
    closeMoveModalBox() {
        this.setState({ isOpenMoveModal: false });
    }

    /**
     * Open swatch modal
     */
    openSwatchModalBox() {
        this.setState({ isOpenSwatchModal: true });
    }

    /**
     * Close swatch modal
     */
    closeSwatchModalBox() {
        this.setState({ isOpenSwatchModal: false });
        this.closeConfirmModal();
    }

    /**
     * Closes the pdf modal
     */
    closePdfModal() {
        this.setState({ pdfModalOpen: false });
    }

    /**
     * Un-checks all the rows
     */
    unCheckAll() {
        const _this = this;
        const gridData = _this.sampleGrid.getrows();
        const rowids = gridData.map((v, k) => {
            v.sampleSwatch = false;
            _this.sampleGrid.updaterow(v.sampleId, v);
        });
        _this.closeSwatchModalBox();
    }

    /**
     * Confirms if swatch only
     */
    yesSwatchOnly() {
        this.sampleProductUpdate(true);
    }

    /**
     * Confirms no swatch only
     */
    noSwatchOnly() {
        // this.unCheckAll();
        this.sampleProductUpdate(false);
    }

    /**
     * Updates samples grid and calls backend to save data
     * @param {boolean} checked
     */
    sampleProductUpdate(checked) {
        const _this = this;
        _this.closeSwatchModalBox();
        const totalSampleCheckbox = $('.sample-boolean').length;
        const checkedCheckbox = $('.sample-boolean:checked').length;
        const swatchRef = $('.swatch-reference') ? $('.swatch-reference').val() : '';
        const allRows = _this.sampleGrid.getrows();
        const sampleIdsList = [];
        const currentRowData = this.currentRowData;
        allRows.map(function (p, i) {
            for (let i = 0; i < p.sampleIdLst.length; i++) {
                // sampleIdsList.push(p.sampleIdLst[i]);
                if (p.sampleId == currentRowData.sampleId) {
                    sampleIdsList.push(p.sampleIdLst[i]);
                }
            }
        });


        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        ProductServices.updateProductSample({ checkedAll: checked, swatchRef: swatchRef, sampleIdLst: sampleIdsList })
            .then((response) => {
                const res = response.data;

                if (res.responseStatus) {
                    if (checked) {
                        window.currentValSampleSwatchOption = 'Swatch Only';
                        /** To refresh the grid view , looping and update row one by one */
                        const firstLevelRows = _this.sampleGrid.getrows();
                        for (let i = 0; i < firstLevelRows.length; i++) {
                            // get a row.
                            const rowData = firstLevelRows[i];
                            _this.sampleGrid.updaterow(rowData.sampleId, rowData);
                        }
                        _this.props.setValueSwatchonly(swatchRef);
                    }
                    NotificationManager({ message: res.responseMessage, type: 1 });
                } else {
                    NotificationManager({ message: res.responseMessage, type: 4 });
                }
                _this.closeConfirmModal();
                _this.closeSwatchModalBox();
            })
            .catch((error) => {
                console.log('productContainer helperComponents sample-modal sampleProductUpdate API call failed', error);
                NotificationManager({ message: error, type: 4 });
            });
    }

    /**
     * Opens sample swatch modal
     */
    showSampleSwatchModal() {
        const _this = this;

        const styleId = this.props.productData.styleNumber;

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        ProductServices.getSampleIds({ id: this.props.productData, styleId: styleId })
            .then((response) => {
                const res = response.data;

                if (res.responseStatus) {
                    _this.swatchReferenceList = res.data;
                } else {
                    _this.swatchReferenceList = [];
                }
                _this.openSwatchModalBox();
            })
            .catch((error) => {
                console.log('productContainer helperComponents sample-modal showSampleSwatchModal API call failed', error);
            });
        _this.setState({ isModalOpen: false });
    }

    /**
     * Getter when popup loads
     */
    componentDidMount() {
        const base = this;

        // Setting window object reference for grid check disable/enable;
        window.currentValSampleSwatchOption = base.props.productData.swatchOnly;

        const sampleGrid = $('.sampleGrid');
        sampleGrid.off();
        // sampleGrid.on('click','.sample-boolean', function(e){
        sampleGrid.on('click', '.sample-boolean', (event) => {
            const rowId = $(event.currentTarget).attr('data-row');
            const fieldName = $(event.currentTarget).attr('data-fieldname');
            const isChecked = $(event.currentTarget).prop('checked');
            const totalSampleCheckbox = $('.sample-boolean').length;
            const checkedCheckbox = $('.sample-boolean:checked').length;
            const rowData = base.sampleGrid.getrowdata(rowId);
            // this.setState({currentRowData: rowData});
            this.currentRowData = rowData;
            rowData.sampleSwatch = isChecked;
            base.sampleGrid.updaterow(rowId, rowData);
            base.currentGridData = base.sampleGrid.getrows();

            const headers = {
                'Authorization': base.props.user.token,
                'content-type': 'application/json',
            };

            ProductServices.updateSample({ id: rowData.sampleIdLst, value: isChecked })
                .then((response) => {
                    const res = response.data;

                    if (res.responseStatus) {
                        NotificationManager({ message: res.responseMessage, type: 1 });
                        if (totalSampleCheckbox === checkedCheckbox) {
                            base.openConfirmModal();
                        }
                    } else {
                        NotificationManager({ message: res.responseMessage, type: 4 });
                    }
                })
                .catch((error) => {
                    console.log('productContainer helperComponents sample-modal componentDidMount API call failed', error);
                    NotificationManager({ message: error, type: 4 });
                });
        });

        this.loadGrid();
    }

    /**
     * Checks for updates on the grid
     * @param {*} prevProps
     * @param {*} prevState
     */
    componentDidUpdate(prevProps, prevState) {
        if (prevState.isLoaded !== this.state.isLoaded && this.state.isLoaded === true) {
            $('.ag-modal-holder').addClass('samplesWidth');
            const _this = this;
            // _this.sampleGrid.on('cellEndEdit', function (event) {
            //     const args = event.args;
            //     const datafield = args.datafield;
            //     const rowdata = args.row;


            //     const headers = {
            //         'Authorization': _this.props.user.token,
            //         'content-type': 'application/json',
            //     };

            //     axios({
            //         method: 'post',
            //         url: require('../../../common/configurations.js').product.saveSamples,
            //         headers: headers,
            //         data: [rowdata],
            //     })
            //         .then((response) => {
            //             const res = response.data;

            //             if (res.responsestatus) {
            //                 NotificationManager({ message: res.responseMessage, type: 1 });
            //             } else {
            //                 NotificationManager({ message: res.responseMessage, type: 4 });
            //             }
            //         })
            //         .catch((error) => {
            //             NotificationManager({ message: error, type: 4 });
            //         });
            // });
        }
    }

    loadGrid() {
        const base = this;
        const rowData = this.props.productData;

        const IdArray = this.IdArray;
        const switchValue = rowData.dataLevel;

        if (switchValue == 'SINGLE') {
            IdArray.push(rowData.chldProdId);
        } else if (switchValue == 'PARENT') {
            IdArray.push(rowData.parentProductId);
        } else if (switchValue == 'INTERMEDIATE') {
            IdArray.push(rowData.intProdId);
        } else if (switchValue == 'CHILD') {
            IdArray.push(rowData.chldProdId);
        }

        // let postdata = {IdArray};

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };

        ProductServices.getSamplesData(IdArray)
            .then((response) => {
                const res = response.data;

                res.columnDetails.map((v, k) => {
                    if (v.fieldName === 'sampleIdLst') {
                        v.visibility = false;
                    } else {
                        v.visibility = true;
                    }
                });
                base.currentGridData = res.data;
                // base.gridData=GridDataBuilder(res,"Sample");
                const gridData = GridDataBuilder(res, 'Sample');
                this.setState ({gridData: gridData});
                // const gridData = GridDataBuilder(res, 'Sample');
                gridData.columns = gridData.columns.map(function (col, i) {
                    col.width = (99.9 / gridData.columns.length).toFixed(4) + '%';
                    col.minwidth = 120;
                    return col;
                });
                base.gridData = gridData;
                base.setState({ isLoaded: true });
            })
            .catch((error) => {
                console.log('productContainer helperComponents sample-modal componentDidMount 2 API call failed', error);
            });
    }

    onCellendedit(event) {
        const _this = this;
        const args = event.args;
        const datafield = args.datafield;
        const rowdata = args.row;

        const headers = {
            'Authorization': _this.props.user.token,
            'content-type': 'application/json',
        };

        ProductServices.saveSamples([rowdata])
            .then((response) => {
                const res = response.data;

                if (res.responsestatus) {
                    NotificationManager({ message: res.responseMessage, type: 1 });
                } else {
                    NotificationManager({ message: res.responseMessage, type: 4 });
                }
            })
            .catch((error) => {
                console.log('productContainer helperComponents sample-modal componentDidUpdate API call failed', error);
                NotificationManager({ message: error, type: 4 });
            });
    }

    componentWillUnmount() {
        $('.ag-modal-holder').removeClass('samplesWidth');
    }


    /**
     * Enables the samples received flag
     */
    enableSampleReceivedFlag() {
        const _this = this;
        const isSampleRecevied = _this.props.currentRowData.isSamplerecieved;
        let allowFlag = false;

        if (utils.convertDataType(isSampleRecevied) != true) {
            allowFlag = true;
        }
        if (allowFlag) {
            const headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json',
            };
            axios({
                method: 'post',
                url: require('../../../common/configurations.js').product.isSampleRecevied,
                headers: headers,
                data: _this.props.productData,
            })
                .then((response) => {
                    const res = response.data;

                    _this.props.setCellValueAndCopyToChild('isSamplerecieved', true);
                })
                .catch((error) => {
                    console.log('productContainer helperComponents footer API call failed', error);
                });
        }
    }

    /**
     * Generates the sample card, calls backend to get data
     */
    generateSampleCard() {
        const _this = this;
        _this.setState({ disableClick: true });
        const qtyReceived = parseInt($('.qtyReceived').val(), 10);
        if (isNaN(qtyReceived)) {
            this.generateSampleCardCounter = 0;
            NotificationManager({ message: 'Please enter quantity', type: 4 });
            return;
        }

        // const postdata = this.IdArray.map((pid, i) => {
        //     const inputData = {
        //         'roleName': this.props.user.defaultRole,
        //         'productID': pid,
        //         'count': qtyReceived,
        //     };
        //     return inputData;
        // });

        // changed when added refresh to keep the ID same
        const postdata = [];
        postdata.push({
            'roleName': this.props.user.defaultRole,
            'productID': this.IdArray[0],
            'count': qtyReceived,
        });

        const headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json',
        };
        ProductServices.receiveSamples(postdata)
            .then((response) => {
                const res = response.data;

                if (res.responsestatus) {
                    NotificationManager({ message: res.responseMessage, type: 1 });
                    const data = res.data;
                    if (data.length > 0) {
                        // _this.enableSampleReceivedFlag();
                        // const rowids = data.map((v, k) => {
                        //     // this.sampleGrid.addrow(v.sampleId, v);
                        //     // return '' + v.sampleId;
                        // });
                        // res.columnDetails.map((v, k) => {
                        //     if (v.fieldName === 'sampleIdLst') {
                        //         v.visibility = false;
                        //     } else {
                        //         v.visibility = true;
                        //     }
                        // });
                        // const gridData = GridDataBuilder(res, 'Sample');
                        // this.setState ({gridData: gridData});
                        // _this.sampleGrid.addrow(rowids, data);
                        // _this.sampleGrid.refresh();
                        _this.setState ({isLoaded: false, disableClick: false}, () => {
                            this.loadGrid();
                        });
                        _this.closeReceiveModalBox();
                    }
                    // Open pdf file url in new tab
                    if (res.file) {
                        // utils.openInNewTab(res.file);
                        const pdfFileUrl = res.file;
                        _this.setState({ pdfModalOpen: true, pdfFile: pdfFileUrl });
                    }
                } else {
                    NotificationManager({ message: res.responseMessage, type: 4 });
                }
            })
            .catch((error) => {
                console.log('productContainer helperComponents footer API call failed', error);
            });
    }

    onRowselect(event) {
        const selectedRows = [];
        selectedRows.push(event.args.row);
        this.setState({selectedRows: selectedRows});
    }

    onRowunselect(event) {
        const selectedRows = this.state.selectedRows;
        let newArr = selectedRows.filter((i, x) => i.sampleId !== event.args.row.sampleId);
        this.setState({selectedRows: newArr});
    }

    /**
     * Re-print samples card, calls backend to get data
     */
    rePrintSampleCard() {
        const _this = this;
        const selectedrowindexs = this.state.selectedRows;
        const selectedrowids = [];
        let selectedrowdata = null;
        if (selectedrowindexs.length > 0) {
            selectedrowdata = selectedrowindexs.map((val, key) => {
                return {
                    'id': val.sampleId, // Sample ID
                    'value': val.productID, // Product ID
                };
            });

            const headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json',
            };

            ProductServices.generateSampleCard(selectedrowdata)
                .then((response) => {
                    const res = response.data;

                    if (res.responsestatus) {
                        NotificationManager({ message: res.responseMessage, type: 1 });
                        if (res.data) {
                            // utils.openInNewTab(res.data);
                            const pdfFileUrl = res.data;
                            _this.setState({ pdfModalOpen: true, pdfFile: pdfFileUrl });
                        }
                    } else {
                        NotificationManager({ message: res.responseMessage, type: 4 });
                    }
                })
                .catch((error) => {
                    console.log('productContainer helperComponents sample-modal rePrintSampleCard API call failed', error);
                    NotificationManager({ message: 'Please select atleast one samples to print', type: 4 });
                });
        } else {
            NotificationManager({ message: 'Please select atleast one samples to print', type: 4 });
        }
    }

    /**
     * Requests Moves samples, calls backend
     */
    moveSampleRequest() {
        const _this = this;
        const selectedrowindexs = this.state.selectedRows;
        const selectedrowids = [];
        let selectedrowdata = null;
        if (selectedrowindexs.length > 0) {
            selectedrowdata = selectedrowindexs.map((val, key) => {
                return {
                    'requestUser': this.props.user.userName,
                    'currentUser': val.sampleCurrentLocation,
                    'sampleId': val.sampleId,
                };
            });

            const headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json',
            };
            ProductServices.moveSampleRequest(selectedrowdata)
                .then((response) => {
                    const res = response.data;
                    NotificationManager({ message: res.responseMessage, type: 1 });
                })
                .catch((error) => {
                    console.log('productContainer helperComponents sample-modal rePrintSampleCard API call failed', error);
                    NotificationManager({ message: 'Please select atleast one sample to proceed', type: 4 });
                });
        } else {
            NotificationManager({ message: 'Please select atleast one sample to proceed', type: 4 });
        }
    }

    /**
     * Moves samples, calls backend
     */
    moveSample() {
        const _this = this;
        const selectedrowindexs = this.state.selectedRows;

        const selectedrowids = [];
        const currentLocation = $('.sample-location').val();
        let selectedrowdata = null;
        if (selectedrowindexs.length > 0) {
            selectedrowdata = selectedrowindexs.map((val, key) => {
                return {
                    'sampleId': val.sampleId,
                    'sampleCurrentLocation': currentLocation,
                    'samplePriorLocation': val.sampleCurrentLocation,
                    'productID': val.productID,
                    'sampleIdLst': val.sampleIdLst,
                    'swatch': 'false',
                    'sampleComments': val.sampleComments,
                };
            });


            const headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json',
            };
            ProductServices.moveSample(selectedrowdata)
                .then((response) => {
                    const res = response.data;

                    if (res.responsestatus) {
                        NotificationManager({ message: res.responseMessage, type: 1 });
                        // const rowids = res.data.map((v, k) => {
                        //     const ids = v.sampleId.toString();
                        //     _this.sampleGrid.setcellvaluebyid(ids, 'sampleCurrentLocation', v.sampleCurrentLocation);
                        // });
                        _this.setState ({isLoaded: false}, () => {
                            this.loadGrid();
                        });
                        _this.closeMoveModalBox();
                    } else {
                        NotificationManager({ message: res.responseMessage, type: 4 });
                    }
                })
                .catch((error) => {
                    console.log('productContainer helperComponents footer API call failed', error);
                });
        } else {
            NotificationManager({ message: 'Please select atleast one sample to move', type: 4 });
        }
    }

    render() {
        const props = this.props;
        const footerHeight = 50;
        const headerHeight = 64;
        const gridHeight = 400 - footerHeight - headerHeight;

        const modalClass = 'ConfirmSubmitModalSamples';
        const editSettings = {
            saveOnPageChange: true,
            saveOnBlur: true,
            saveOnSelectionChange: true,
            cancelOnEsc: true,
            saveOnEnter: true,
            editSingleCell: true,
            editOnDoubleClick: true,
            editOnF2: true,
        };
        let disableEditing = false;
        if (window.readableFields && window.readableFields.indexOf('swatchOnly') > -1) {
            disableEditing = true;
        }

        return (
            <div className="sampleMgmtPopup">
                <div className="Sample-Modal-Container">
                    <div className="modal-header">
                        Sample Management
                        <span className="close" onClick={this.props.modalClose}>x</span>
                    </div>
                    <div className="modal-content">
                        <div className="modal-form">
                            <div className="sampleGrid">
                                {this.state.isLoaded &&
                                    <JqxGrid
                                        ref={(grid) => {
                                            this.sampleGrid = grid;
                                        }}
                                        // height={gridHeight} source={this.gridData.dataAdapter}
                                        // columns={this.gridData.columns} autoRowHeight={false} pageable={false}
                                        // theme={'light'} editable={true} width={'100%'}
                                        width={'99%'}
                                        source={this.state.gridData.dataAdapter}
                                        pageable={false}
                                        editable={'click'}
                                        columns={this.state.gridData.columns}
                                        sortable={true}
                                        checkboxes={true}
                                        autorowheight={false}
                                        autowidth={true}
                                        height={gridHeight}
                                        columnsautoresize={true}
                                        theme={'light'}
                                        selectionmode={'checkbox'}
                                        columnsreorder={true}
                                        columnsmenu={false}
                                        onCellendedit={this.onCellendedit.bind(this)}
                                        onRowselect={this.onRowselect.bind(this)}
                                        onRowunselect={this.onRowunselect.bind(this)}
                                    />
                                }
                            </div>
                        </div>
                        <div className="row">&nbsp;</div>
                        <div className="modal-footer text-center">
                            {!this.props.isCreative && !disableEditing &&
                                <button className="gray-button" onClick={this.openReceiveModalBox}>Receive Samples</button>}
                            {!disableEditing &&
                                <button className="gray-button" onClick={this.rePrintSampleCard}>Re-Print Sample card</button>
                            }
                            {!disableEditing &&
                                <button className="gray-button" onClick={this.openMoveModalBox}>Move Sample</button>
                            }
                            {/* {!disableEditing &&
                                <button className="gray-button" onClick={this.moveSampleRequest}>Move Sample Request</button>
                            } */}
                        </div>
                        {this.state.isOpenReceiveModal &&
                            <div className="swatchReferenceContainer">
                                <ReceiveSampleModal isOpen={this.state.isOpenReceiveModal} close={this.closeReceiveModalBox} generateSampleCard={this.generateSampleCard} disableClick={this.state.disableClick} />
                            </div>
                        }
                        {this.state.isOpenMoveModal &&
                            <div className="swatchReferenceContainer">
                                <MoveSampleModal isOpen={this.state.isOpenMoveModal} close={this.closeMoveModalBox} moveSample={this.moveSample} />
                            </div>
                        }
                        {this.state.isOpenSwatchModal &&
                            <div className="swatchReferenceContainer">
                                <SwatchReferenceModal isOpen={this.state.isOpenSwatchModal}
                                    swatchReferenceList={this.swatchReferenceList} close={this.noSwatchOnly} ok={this.yesSwatchOnly} />
                            </div>
                        }
                    </div>
                </div>
                {this.state.isModalOpen &&
                    <Modal isOpen={this.state.isModalOpen} addClass={modalClass}>
                        <ConfirmSubmit dialog={this.modalDialog} modalClose={this.noSwatchOnly} yes={this.showSampleSwatchModal} />
                    </Modal>
                }
                {this.state.pdfModalOpen &&
                    <Modal isOpen={this.state.pdfModalOpen}>
                        <PdfViewerModal pdfFile={this.state.pdfFile} modalClose={this.closePdfModal} />
                    </Modal>
                }
            </div>
        );
    }
}


export default SampleModel;
