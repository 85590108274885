import React, { Component } from 'react';
import Modal from '../../modals/common-modal.js';
import { connect } from 'react-redux';
import * as actions from '../redux/productActions';
import axios from 'axios';

var constants = require('../../common/constants.js');
var utils = require('../../common/utils.js');
// var NotificationManager = require('../../common/notification');
import NotificationManager from '../../common/components/notification';
import { ProductServices } from '../../product2/services/product-services';


class CopyDisplayLevel extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            isLoaded: false,
            levelLists: [],
            levelValue: ''
        };
        this.updateProductGridRows = this.updateProductGridRows.bind(this);
        this.updateChildRecord = this.updateChildRecord.bind(this);
        this.updateLeve = this.updateLeve.bind(this);
    }

    componentDidMount() {
        let _this = this;

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.getLevelList('?productId=' + this.props.productId)
            .then( (response) => {
                console.log('productContainer helperComponents copy display modal API call success data: ', response.data);
                let res = response.data;

                if (res.status) {
                    _this.setState({levelLists: res.data, levelValue: res.data[0]});
                }
            })
            .catch( error => {
                console.log('productContainer helperComponents copy display modal API call failed', error);
                NotificationManager({message:error, type:4});
            });
    }

    updateLeve(e) {
        let _this = this;
        let target = e.target;
        let value = target.value;
        _this.setState({levelValue: value});
    }

    updateChildRecord(e) {
        let _this = this;
        let req = _this.props.childRowData;

        _this.props.modalClose();
        this.props.toggleSpinner(true);

        req.map((val, idx) => {
            req[idx][_this.props.levelType] = _this.state.levelValue;
            delete req[idx].parent;
        });


        ProductServices.updateOrAddProduct(req)
            .then((res) => {
                _this.updateProductGridRows(req);
            })
            .catch( error => {
                console.log('productContainer helperComponents copy-display-modal updateChildRecord API call failed', error);
                _this.props.toggleSpinner(false);
                NotificationManager({message:'Service failed', type:4});
            });
    }

    updateProductGridRows(childrows){
        var _this = this;
        var req = childrows.map(function(p){
            return p.productId;
        });
        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        ProductServices.groupByPopUp({chldProuctId:req, userRole:this.props.user.defaultRole, level:'default'})
            .then( (response) => {
                console.log('productContainer helperComponents copy-display-modal updateProductGridRows API call success data: ', response.data);
                let res = response.data;

                this.props.toggleSpinner(false);

                if(res.data){
                    var list = utils.treeify(res.data, "productId", "referrence", "children");
                    if(list.length > 0){
                        var olist = _this.props.productGrid.getRow(list[0].productId);
                        if(olist.children && olist.children.length){
                            olist.children.map(function(e, i){
                                list[0].children[i].state = e.state;
                                if(e.state == "expand"){
                                    list[0].state = "expand";
                                }
                            });
                        }
                        _this.props.productGrid.updateRow(list[0].productId, list[0]);
                        _this.props.productGrid.refreshGrid();
                    }
                // _this.props.modalClose();
                }
            })
            .catch( error => {
                console.log('productContainer helperComponents copy-display-modal updateProductGridRows API call failed', error);
                NotificationManager({message:'Service failed', type:4});
            });
    }

    render(){
        var options = this.state.levelLists.map((value, index) => {
            return (<option key={index} value={value}>{value}</option>);
        });

        return (
            <div>
                <div className="modal-header">
					Copy/Display Level
                    <span className="close" onClick={this.props.modalClose}>x</span>
                </div>
                <div className="modal-content">
                    <div className="product-option modal-form">
					    <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="form-label">Select level type</label>
                                    <select className="form-control" onChange={this.updateLeve}>
                                        {options}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <input type="button" className="gray-button" value="OK" onClick={this.updateChildRecord}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        dropdowns: state.gridDropDowns
    };
};

const copydisplaymodal = connect(mapStateToProps, null)(CopyDisplayLevel);
export default copydisplaymodal;
