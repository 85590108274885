import React, {
    Component
} from 'react';

import './stylesheets/error-boundry.less';

class ErrorBoundryComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            error: null,
            errorInfo: null,
        };

        this.childProps = this.props && this.props.children && this.props.children.props;
        this.rollBarConfig = {
            accessToken: 'c99f9e6ca09b433bab7b474e6a286eb6',
            captureUncaught: true,
            captureUnhandledRejections: true
        };

        this.getUserInfo = this.getUserInfo.bind(this);
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {
            hasError: true
        };
    }

    getUserInfo() {
        if (this.childProps && this.childProps.user) {
            return {
                id: this.childProps.user.email,
                email: this.childProps.user.userId,
                username: this.childProps.user.userName
            };
        } else {
            return {};
        }
    }

    componentDidMount() {
        const _this = this,
            currentPage = this.childProps && this.childProps.currentPage,
            getLocation = window.location.hostname.split('.');

        window.onerror = function (errorMsg, url, lineNumber, column, errorObj) {
            console.warn('Error: ', errorMsg);
            console.warn(' Script: ', url);
            console.warn(' Line: ', lineNumber);
            console.warn(' Column: ', column);
            console.warn(' StackTrace: ', errorObj);
        };
    }

    componentDidCatch(error, errorInfo) {
        const	_this = this,
            currentPage = this.childProps && this.childProps.currentPage,
            getLocation = window.location.hostname.split('.');

        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
    }

    errorLogout() {
        localStorage.removeItem('user');
        localStorage.removeItem('token');

        setTimeout(() => {
            window.location.reload();
        }, 0);
    }

    errorReload() {
        this.setState({
            error: null,
            errorInfo: null
        });
    }

    render() {
        if (this.state.hasError && this.state.error) {
            return (
                <div className="error-boundry-wrap" >
                    <h2>
                        <i className="fa fa-exclamation-circle fa-5x" aria-hidden="true" > </i>
                        <br /> < br />
                        Oops!Something went wrong. <br />
                        This page didn 't load correctly, please reload the page to continue. <br /> < br />
                        <button onClick={this.errorReload.bind(this)} className="error-reload-button" > Continue </button>
                        <button onClick={this.errorLogout.bind(this)} className="error-reload-button" > Logout </button>
                    </h2>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundryComponent;
