import React from 'react';

export class FilterComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filterOperands: props.getFilterOperands(),
            currentList: [],
            searchValue: null,
            operatorValue: null,
            operatorType: null,
            filterDest: false,
        };
        this.valueGetter = this.props.valueGetter;
    }

    // componentDidMount() {
    //     const _this = this;
    //     const filterOperands = this.state.filterOperands;
    //     let currentList = this.state.currentList;
    //     let currentType = this.state.operatorType;

    //     filterOperands.forEach((element) => {
    //         if (this.props.colDef.headerName == element.displayName) {
    //             currentList = element.operator;
    //             currentType = element.type;
    //         }
    //     });
    //     _this.setState({currentList: currentList, operatorValue: currentList[0], operatorType: currentType});
    // }

    afterGuiAttached(params) {
        const _this = this;
        const filterOperands = this.state.filterOperands;
        let currentList = this.state.currentList;
        let currentType = this.state.operatorType;

        filterOperands.forEach((element) => {
            if (this.props.colDef.headerName == element.displayName) {
                currentList = element.operator;
                currentType = element.type;
            }
        });
        _this.setState({currentList: currentList, operatorValue: currentList[0], operatorType: currentType});
    }

    onChangeOperand(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        // const level = $(target).data('level');

        this.setState({ operatorValue: value });
    }

    onChangeInput(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ searchValue: value });
    }

    getModel() {
        // if (this.state.searchValue == '' || !this.state.searchValue) {
        //     // this.props.api.setFilterModel(null);
        //     // this.props.api.onFilterChanged();
        //     var athleteFilterComponent = this.props.api.getFilterInstance(this.props.column.colId);
        //     athleteFilterComponent.selectNothing();
        //     this.props.api.onFilterChanged();
        // } else {
        return {filter: this.state.searchValue, filterType: this.state.operatorType, type: this.state.operatorValue};
        // }
    }

    // setModel(model) {
    //     // if (this.state.searchValue == '' && !this.state.searchValue) {
    //     //     this.setState({ searchValue: '', operatorValue: this.state.operatorValue, operatorType: this.state.operatorType });
    //     //     this.props.filterChangedCallback();
    //     // } else if ( model == null) {
    //     //     this.setState({ searchValue: '', operatorValue: this.state.operatorValue, operatorType: this.state.operatorType });
    //     //     this.props.filterChangedCallback();
    //     // } else {
    //     //     this.setState({ searchValue: this.state.searchValue, operatorValue: this.state.operatorValue, operatorType: this.state.operatorType });
    //     // }
    // }

    isFilterActive() {
        return this.state.searchValue !== null && this.state.searchValue !== undefined && this.state.searchValue !== '';
    }

    applyFilter() {
        this.props.filterChangedCallback();
        this.props.openFilterCall(true);
        // this.setModel(null);
        // var countryFilterComponent = this.props.api.getFilterInstance(this.props.column.colId);
        // countryFilterComponent.resetFilterValues();
        // this.props.api.resetFilterValues();
        // countryFilterComponent.onFilterChanged();
    }

    clearFilter() {
        // this.refs.inputRef.value = '';
        this.props.api.destroyFilter(this.props.column.colId);
        this.props.api.hidePopupMenu();
        this.props.openFilterCall(false);
        // this.setState({ searchValue: ''}, () => this.props.filterChangedCallback());
    }

    render () {
        const style = {
            border: '2px solid #22ff22',
            borderRadius: '5px',
            backgroundColor: '#bbffbb',
            width: '200px',
            height: '50px',
        };

        const filterOperands = this.state.currentList.map((item, index) => {
            return (
                <option key={index}>{item}</option>
            );
        });

        return (
            <div ref="tabBody" className="ag-tab-body">
                <div className="ag-filter">
                    <div className="ag-filter-body-wrapper">
                        <select className="ag-filter-select" id="filterType" onChange={this.onChangeOperand.bind(this)}>{filterOperands}</select>
                    </div>
                    <div className="ag-filter-body">
                        <input className="ag-filter-filter" ref='inputRef' id="filterText" type="text" onChange={this.onChangeInput.bind(this)} placeholder="Filter..."></input>
                    </div>
                    <div className="ag-filter-apply-panel" id="applyPanel">
                        <button type="button" onClick={this.clearFilter.bind(this)} id="clearButton">Clear Filter</button>
                        <button type="button" onClick={this.applyFilter.bind(this)} id="applyButton">Apply Filter</button>
                    </div>
                </div>
            </div>
        );
    }
}

