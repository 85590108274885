import React from 'react';

class PacketDetails extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let formats = this.props.avalibaleFormat;

        var dropDownContent = formats.map((opt, opt_idx) => {
            return <option key={opt_idx} value={opt.id}>{opt.value}</option>;
        });

        return (
            <div>
                <form className="picture-packet-form" name="picture-packet-form">
                    <div className="form-group folio-name">
                        <label>FOLIO TITLE*</label>
                        <input type="text" className="form-control" name="folioName" value={this.props.folio.folioName} onChange={this.props.handleChangePic} />
                    </div>
                    <div className="form-group folio-notes">
                        <label>FOLIO NOTES</label>
                        <textarea className="form-control" name="folioNotes" value={this.props.folio.folioNotes} onChange={this.props.handleChangePic}></textarea>
                    </div>
                    <div className="form-group folio-format">
                        <label>FOLIO PRESENTATION FORMAT*</label>
                        <select value={this.props.folio.folioFormat} className="form-control" name="folioFormat" onChange={this.props.handleChangePic}>
                            <option value={'0'}>PLEASE SELECT FORMAT</option>
                            {dropDownContent}
                        </select>
                    </div>
                </form>
                <div className="record-creation-holder">
                    <div
                        className={`record-action gray-button ${(this.props.isFinaliseOpen) ? "open active" : ''}`}
                        onClick={this.props.toggleFinaliseOptions}
                    >Finalize</div>
                    <div className="record-options">
                        <ul>
                            <li onClick={this.props.saveToPdf}>Finalize & Export To PDF</li>
                            <li onClick={this.props.saveToFuture}>Save the Folio (for future use)</li>
                        </ul>
                    </div>
                </div>
                <span className="error-message text-left">{this.props.errorMessage}</span>
            </div>
        );
    }
}

export default PacketDetails;
