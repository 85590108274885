export const addProduct2GridData = (state = [], action) => {
    switch (action.type) {
        case "ADD_PRODUCT2_GRID_DATA":
            return action.data;
        default:
            return  state;
    }
};

export const addSelectedRows = (state = [], action) => {
    switch (action.type) {
        case "ADD_SELECTED_ROWS":
            return action.data;
        default:
            return  state;
    }
};

export const isColumnTouched = (state = [], action) => {
    switch (action.type) {
        case "IS_COLUMN_TOUCHED":
            return action.data;
        default:
            return  state;
    }
};

export const filterForSortingProductsGrid = (state = [], action) => {
    switch (action.type) {
        case "FILTER_FOR_SORTING_FROM_DASHBOARD":
            return action.data;
        default:
            return  state;
    }
};

export const actionPerformedFrom = (state = [], action) => {
    switch (action.type) {
        case "ACTION_PERFORMED_FROM":
            return action.data;
        default:
            return  state;
    }
};
