
import React from 'react';
import SelectCategory from './department/select-category';

/**
 * renders the class column column
 */
export class ClassColumnPopUpRenderer extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            categoriesList: props.getCategoriesList(),
        };
    }

    render() {
        console.log('render class column, ', this.props, this.state);

        let classColumnName = '';
        const _this = this;
        if (this.props.node.data && this.props.node.data.classColumnName) {
            classColumnName = this.props.node.data.classColumnName;
        }
        if (this.state.categoriesList && this.props.node.data && this.props.node.data.classColumn) {
            this.state.categoriesList.forEach((row) => {
                if (row.fieldId === _this.props.node.data.classColumn) {
                    classColumnName = row.fieldName;
                }
            });
            if (this.props.data && !this.props.data.classColumnName) {
                this.props.node.setDataValue(this.props.colDef.colId, classColumnName);
            }
        }

        return (
            <div>
                {classColumnName}
            </div>
        );
    }
}

// //////////////////////////////////////////////////////////////////////////

export class ClassColumnPopUpEditor extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            showPopup: false,
            department: null,
            class: null,
            subClass: null,
            categoriesList: props.getCategoriesList(),
            selectedHierarchy: {
                categoryId: null,
                division: null,
                department: null,
                classColumn: null,
                subClassColumn: null,
            },
        };
    }

    componentWillMount() {
        this.setState({ showPopup: true });
    }

    componentWillUnmount() {
        this.props.api.tabToNextCell();
    }

    isPopup() {
        return true;
    }

    getValue() {
        const classColumn = this.state.selectedHierarchy.classColumn;
        let classColumnName = this.props.value || '';
        this.state.categoriesList.forEach(function (row) {
            if (row.fieldId === classColumn) {
                classColumnName = row.fieldName;
            }
        });
        return classColumnName;
    }

    getMappedName(id) {
        let name = '';
        this.state.categoriesList.forEach(function (row) {
            if (row.fieldId === id) {
                name = row.fieldName;
            }
        });
        return name;
    }

    closeDepartmentPopup() {
        this.setState({ showPopup: false });
    }

    /**
     * function to update cell values of department, division, classcolumn and subclasscolumn
     * @param {*} catId
     * @param {*} catHierarchy
     */
    setColumnValues(catId, catHierarchy) {
        // capture the values in the state to be accessible to the getValue function.
        const selectedHierarchy = this.state.selectedHierarchy;
        selectedHierarchy.categoryId = catId;
        selectedHierarchy.division = catHierarchy.division;
        selectedHierarchy.department = catHierarchy.department;
        selectedHierarchy.classColumn = catHierarchy.classColumn;
        selectedHierarchy.subClassColumn = catHierarchy.subClassColumn;
        this.setState({ selectedHierarchy, showPopup: false });

        const rowData = this.props.node.data;

        let isDirty = false;
        if (rowData['catId'] != catId) {
            isDirty = true;
        }


        if (isDirty) {
            rowData['catId'] = catId;
            rowData['division'] = catHierarchy.division;
            rowData['divisionName'] = this.getMappedName(catHierarchy.division);
            rowData['department'] = catHierarchy.department;
            rowData['departmentName'] = this.getMappedName(catHierarchy.department);
            rowData['classColumn'] = catHierarchy.classColumn;
            rowData['classColumnName'] = this.getMappedName(catHierarchy.classColumn);
            rowData['subClassColumn'] = catHierarchy.subClassColumn;
            rowData['subClassColumnName'] = this.getMappedName(catHierarchy.subClassColumn);
            this.props.rowUpdate(rowData, this.props.node.rowIndex);
        }

        this.props.api.stopEditing(); // this command is required to set the value into the column.
    }

    render() {
        return (
            <div>
                {
                    this.state.showPopup &&
                    <div className="ag-modal-container">
                        <div className="ag-modal-holder">
                            <SelectCategory
                                modalClose={this.closeDepartmentPopup.bind(this)}
                                user={this.props.user}
                                setColumnValues={this.setColumnValues.bind(this)}
                                productId={this.props.node.data.parentProductId}
                            />;
                        </div>
                    </div>
                }
            </div>
        );
    }
}


