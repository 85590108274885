import { combineReducers } from 'redux';

//login reducer
import authentication from './login/redux/loginReducers';

//samples reducer
import * as samples from './samples/redux/samplesReducers';

//product2 reducer
import * as product2 from './product2/redux/product2Reducers';

//dashboard reducer
import * as db_grid from './dashboard/redux/dashboardReducers';

//product reducer
import { filteredResData, appliedFilters, removeFilter, filterDataChanged } from './product/redux/reducers/filters';
import * as grid from './product/redux/reducers/grid-data';
import userData from './product/redux/reducers/profile-data';
import { updateCurrentView, toggleFind } from './product/redux/reducers/header-data';
import * as publication from './product/redux/reducers/publication-grid-data';
import * as headerData from './product/redux/reducers/header-data';

//notification reducer
import { addAlertsData } from './pageheader/redux/reducers/alerts-data';

//get alertbreakdown from newAlerts.js
import { alertsBreawkdown } from './dashboard/redux/dashboardReducers';

//creative reducer
import * as cr_grid from './creative/redux/creativeReducers';

//marketing reducer
import * as mk_grid from './marketing/redux/reducers/grid-data';

// cw grid reducers
import * as cw_grid from './copywriting/redux/cwReducers';


import { userView, addNewAlert, rulesForAlerts, specialityData } from './admin/redux/adminReducers';

const rootReducer = combineReducers({
    //login reducer
    authentication,

    //samples reducer
    samplesGridData: samples.addSamplesGridData,

    //product2 reducer
    product2GridData: product2.addProduct2GridData,
    product2SelectedRows: product2.addSelectedRows,
    isColumnTouched: product2.isColumnTouched,
    filterForSortingProductsGrid: product2.filterForSortingProductsGrid,
    actionPerformedFrom: product2.actionPerformedFrom,

    //dashboard reducers
    db_pa_alerts: db_grid.addDashboardPriorityAlertsData,
    db_gridData: db_grid.gridData,
    db_updateDashboardView:db_grid.updateDashboardView,
    db_gridDataUpdate: db_grid.gridDataUpdate,
    db_appliedFilters:db_grid.appliedFilters,
    db_removeFilter:db_grid.removeFilter,

    //product and publication

    addProductRow: grid.addProductRow,
    selectedRows: grid.selectedRows,
    deleteSeletedProducts: grid.deleteSeletedProducts,
    gridData: grid.gridData,
    cloneSelectedProducts: grid.cloneSelectedProducts,
    columnDetails: grid.columnDetails,
    gridDataUpdate: grid.gridDataUpdate,
    gridDropDowns: grid.gridDropDowns,
    appliedFilters,
    updateCurrentView,
    columnResequence: grid.columnResequence,
    toggleFind: headerData.toggleFind,

    //below don't know where used
    removeFilter, //from page header used in leftDrawer
    userData,
    filteredResData,
    isListDirty: publication.updateListDirty,
    filterDataChanged,
    publicationToggleFind: publication.publicationToggleFind,

    //for right Drawer
    selectedPresentationName: publication.addSelectedPresentationName,
    pubListDropdownData: publication.addPubListDropdownData,
    listItemNames: publication.addListItemsNamesData,

    // for publication
    publicationGridData: publication.PublicationGridData,
    publicationColumnNames: publication.addPublicationColumnNames,
    PublicationCounter: publication.PublicationCounter,
    addGridDropDowns: publication.addGridDropDowns,
    checkedPublicationRows: publication.updateCheckedPublicationRows,

    //creative
    cr_gridData: cr_grid.gridData,
    cr_columnDetails: cr_grid.columnDetails,
    cr_gridDropDowns: cr_grid.gridDropDowns,
    cr_gridDataUpdate: cr_grid.gridDataUpdate,
    cr_selectedRows: cr_grid.selectedRows,

    //marketing
    mk_gridData: mk_grid.gridData,
    mk_gridDataUpdate: mk_grid.gridDataUpdate,
    mk_columnDetails: mk_grid.columnDetails,
    mk_eventTaskData: mk_grid.eventTaskData,
    mk_allEventDetails: mk_grid.allEventDetails,
    mk_selectedRows: mk_grid.selectedRows,

    // for admin
    userView,
    addNewAlert,
    rulesForAlerts,
    specialityData,

    //for notofications
    addAlertsData: addAlertsData,

    // used for psuhing data from newAlerts to alertBreakDownComponent
    alertsBreawkdown: alertsBreawkdown,

    // cw grid reducers
    copyWritingGridSelectedRow: cw_grid.copyWritingGridSelectedRow,
});

export default rootReducer;
