import React from 'react';
import axios from 'axios';
import DashboardServices from '../services/dashboard-services';
import moment from 'moment';
import { ProductServices } from '../../product2/services/product-services';
import { connect } from 'react-redux';
import * as product2_actions from '../../product2/redux/product2Actions';

class Messages extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            msgData: null,
            totalCount: 0,
        };
    }

    componentDidMount(){
        console.log('Messages Dashbaord: ', this.props);


        DashboardServices.getUnreadMessages().then( (response) => {
            console.log('Messages Dashbaord success data: ', response);
            let res = response;

            if(res.status == 'success') {
                let data = res.responseData;
                this.setState({
                    msgData: data.messages,
                    totalCount: data.total_messages,
                });
            }
        })
            .catch( error => {
                console.log('Messages Dashbaord API call failed', error);
            });
    }

    componentWillUnmount(){
    }

    componentWillReceiveProps(nextProps){

    }

    onDoubleClick(id) {
        this.props.setCurrentPage('product2');
        this.props.toggleSpinner(true);

        //create obj for api call
        let obj = {};
        obj["value"] = id,
        obj["field"] = "productId",
        obj["fieldIndex"] = 0,
        obj["type"] = "search",
        obj["fieldType"] = "String",
        obj["deactivated"] = false,
        obj["startRow"] = 0,
        obj["endRow"] = 2000,

        ProductServices.productSearch(obj).then((response) => {
            setTimeout(() => {
                this.props.addProduct2GridData(response);
                this.props.actionPerformedFrom({fromMessages: true});
                this.props.toggleSpinner(false);
            }, 2000);
        }).catch((error) => {
            console.log('Messages Dashbaord API call failed', error);
        });
    }


    render(){
        console.log('Dashboard Messages render: ', this.props);

        var intViewportHeight = window.innerHeight;
        var containerheight = intViewportHeight - 64 - 30;
        var ht = (containerheight / 2) - 81;
        var containerStyle = {
            height:ht
        };

        let messageList = this.state.msgData && this.state.msgData.map((i) => {
            // formatting the date
            let dateParse = moment(i.createDate).toNow('h').toString();
            let splitStr = dateParse.split(" ");
            let getTime = splitStr[0];
            let getAttr = splitStr[1].charAt(0);
            let showDate = getTime + getAttr;

            console.log('date format check: ', dateParse);

            if (dateParse == 'a few seconds' || dateParse == 'a minute') {
                showDate = '30s';
            }
            if (dateParse == 'a day' || dateParse == 'a month' || dateParse == 'a year') {
                showDate = '1' + getAttr;
            }

            return(
                <li className="accoplish-cursor message-items" onDoubleClick={() => this.onDoubleClick(i.sweftId)} key={i.id}>
                    <div className='style-number'>{i.vendorStyle}</div>
                    <div className='product-name'>{i.productName}</div>
                    <div className='sender-name'>{i.from}</div>
                    <div className='sent-time'>{showDate}</div>
                    <div className='message-desc'>{i.content}</div>
                </li>
            );
        });

        return (
            <div className="accomplishments">
                <div className="headbox">
                    {/* <i className="icon-acc"></i> */}
                    <div className="message-icon-dashboard">
                        {   this.state.totalCount < 1000
                            ?
                            this.state.totalCount
                            :
                            this.state.totalCount <= 9999
                                ?
                                this.state.totalCount.toString().charAt(0) + 'K'
                                : '*'
                        }</div>
                    <span className="heading-text">My Messages</span>

                </div>
                {
                    <ul className="scroll">
                        {messageList}

                    </ul>
                }

            </div>
        );
    }
}

const mapDispatchToprops = (dispatch) => {
    return{
        addProduct2GridData: (data) => {
            dispatch(product2_actions.addProduct2GridData(data));
        },
        actionPerformedFrom: (data) => {
            dispatch(product2_actions.actionPerformedFrom(data));
        },
    };
};

export default connect(null, mapDispatchToprops)(Messages);




