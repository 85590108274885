import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Route, history } from 'react-router-dom';
import { configureStore } from './app/store/configureStore';
import {Provider} from 'react-redux';
import App from './app/root/App';
import SweftRoutes from './app/routes';
import VersionNotification from './app/common/version-notification';

import 'normalize.css';
import 'jquery-ui-dist/jquery-ui.min.css';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import 'jqwidget-te/jQuery-TE_v.1.4.0/jquery-te-1.4.0.css';
import './global.css';

export const store = configureStore();

render(
    <Provider store={store}>
    	<BrowserRouter history={history}>
	        <App>
                <VersionNotification buildVersion={BUILD_VER} />
		       	<SweftRoutes />
	        </App>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);
