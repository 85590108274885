/*column width declaration
*/
var columnWidth = {
    productId: 120,
    prodName: 300,
    activeNotes: 400
};

//Display Event Status in different color
var eventStatusRender = function(row, columnfield, value, defaulthtml, columnproperties, rowdata) {
    var className = value.toLowerCase();
    className = className.replace(/ /g, "-");

    return '<div class="grid-text no-background ' + className + '">' + value + '</div>';
};

// Function to generate dots with different colors based on colors status
var taskStatusRender = function(row, columnfield, value, defaulthtml, columnproperties, rowdata) {
    var className = value.toLowerCase();
    className = className.replace(/ /g, "-");

    if (columnfield == "productSelection") {
        return '<div class="grid-text task-status-circle ' + className + '"></div>';
    } else {
        return '<div class="task-status-wrapper clearfix"><div class="grid-text task-status-circle ' + className + '"></div><span class="icon-expand-grid right task-expand" data-ename="' + columnfield + '" data-row="' + row + '"></span></div>';
    }
};

// Event Id
var eventIdRenderer = function(row, columnfield, value, rowdata) {
    return '<div class="grid-text gridEventId" data-row="' + row + '">' + value + '<span class="icon-expand-grid right"></span></div>';
};

var eventNameRender = function(row, columnfield, value, rowdata) {
    return '<div class="grid-text gridEventName" data-row="' + row + '">' + value + '<span class="icon-expand-grid right"></span></div>';
};

/** Grid data builder is used to create the object format required for
 * Jqwidgets.
 */
function GridDataBuilder(res, pageName){
    // dataField is used for providing type of data and its name.
    // columnList is used to define column name, type, and extra fields
    //  to define additional plugins inside the cell eg: drop down, calender ...,
    var dataField = [],
        columnList = [];
    var primayKey = res.columnDetails[0].fieldName;
    // check for the type of fieldname future

    //sort columndetails in product pageName
    res.columnDetails.sort(function (a, b) {
        return a.order - b.order;
    });

    for (var i = 0; i < res.columnDetails.length; i++) {
        dataField.push({
            "name":res.columnDetails[i].fieldName,
            "type":res.columnDetails[i].type.toLowerCase()
        });

        /* Enable the below condition after pk(primary key) availabel from backend
         *  in JSON response.
         */

        if (res.columnDetails[i].hasOwnProperty("pk") && res.columnDetails[i].pk) {
            primayKey = res.columnDetails[i].fieldName;
        }

        columnList.push(columnObj(res.columnDetails[i]));
    }

    function titleCase(str) {
        return str.toLowerCase().split(' ').map(function(word) {
            return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
    }

    /* columnobj function will  return the object fields
     *  based on the field type in list.
     * e.g. for date type and other types need to build the cellrender function.
     */
    function columnObj(list) {
        var obj = {};
        var colTitle = list.columnTitle != null ? titleCase(list.columnTitle) : "";

        obj.text = '<span class="pd-drop" title="' + colTitle + '" data-field="' + list.fieldName + '">' + colTitle + '</span>';
        obj.dataField = list.fieldName;
        obj.width = (columnWidth[list.fieldName]) ? columnWidth[list.fieldName] : 120;
        obj.hidden = !list.visibility;
        obj.cellClassName = function (row, column, value, data) {
            if (data.deleted) {
                return "strike-through deleted";
            }
        };

        /* if list type is date, pass the methods related to date to intialize
         *  date picker
         */
        if (list.fieldName === 'eventId') {
            obj.cellsrenderer = eventIdRenderer;
            obj.editable = false;
        } else if (list.fieldName === "status") {
            obj.cellsrenderer = eventStatusRender;
            obj.editable = false; // remove this if required
            obj.pinned = true;
        } else if (list.fieldName === "eventDesc") {
            obj.cellsrenderer = eventNameRender;
            obj.editable = false; // remove this if required
            obj.minwidth = 180;
            obj.pinned = true;
        } else if(list.type === 'taskstatus'){
            //Generate dots with different colors based on colors status
            obj.cellsrenderer = taskStatusRender;
            obj.editable = false;
        } else if(list.type === 'Date'){
            //Generate dots with different colors based on colors status
            obj.cellsformat = 'MM/dd/yyyy';
            obj.editable = false;
        }

        return obj;
    }

    var source = {
        dataType:"json",
        dataFields:dataField,
        id:primayKey,
        localdata: res.data
    };

    var dataAdapter = new $.jqx.dataAdapter(source);

    return {
        dataAdapter:dataAdapter,
        columns:columnList
    };
}

export default GridDataBuilder;
