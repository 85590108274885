import React from 'react';
import './helpbug.less';
import HelpData from './helpData';

class HelpBugs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showTip: false,
        };

        this.mouseEnter = this.mouseEnter.bind(this);
        this.mouseLeave = this.mouseLeave.bind(this);
    }

    componentDidMount() {
        console.log('Help Bug Component componentDidMount', this.props);
    }

    mouseEnter(e) {
        console.log('mnouse Enter event');

        this.setState({ showTip: true });
    }

    mouseLeave(e) {
        console.log('mnouse Enter event');

        this.setState({ showTip: false });
    }

    render() {
        console.log('Help Bug Component render', this.state);

        return (
            <div className='help-bug-container' onMouseLeave={this.mouseLeave} onMouseEnter={this.mouseEnter}>
                <i className="fa fa-info-circle" aria-hidden="true"></i>
                <HelpData
                    showTip={this.state.showTip}
                    currentPage={this.props.currentPage}
                    section={this.props.section}
                />
            </div>
        );
    }
}

export default HelpBugs;
