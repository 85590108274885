import React, { Component } from 'react';
import CategoryTree from './categoryTree';


class SelectCategory extends Component{
    constructor(props){
        super(props);
        this.setCategoryId = this.setCategoryId.bind(this);
        this.state = {
            okBtnDisabled:true
        };
        this.enableOkayButton = this.enableOkayButton.bind(this);
    }

    setCategoryId(){
        this.child.setCategoryId();
    }

    enableOkayButton(flag){
        this.setState({okBtnDisabled:!flag});
    }

    render(){
        let modalClass = 'SelectCategoryModal';

        return (
            <div>
                <div className="modal-header">
          Select Category
                    <span className="close" onClick={this.props.modalClose}>x</span>
                </div>
                <div className="modal-content">
                    <div className="add-copy modal-form">
                        <div className="form-container scroll">
                            <CategoryTree ref={instance => { this.child = instance; }} {...this.props} enableOkayButton={this.enableOkayButton}/>
                        </div>
                        <div className="modal-footer text-center">
                            <input type="button" className="gray-button" disabled={this.state.okBtnDisabled} onClick={this.setCategoryId} value="Ok"/>
                            <input type="button" className="gray-button" onClick={this.props.modalClose} value="Cancel"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default SelectCategory;
