import React, { Component } from 'react';
import axios from 'axios';
import CategoryTreeView from './categoryTreeView';
import CategoryServices from '../../product2/services/category-services';

import NotificationManager from './notification';

class Accordion extends Component {
    constructor(props) {
        super(props);

        const data = this.props.data;

        this.state = {
            data,
            page_type: ''
        };

        this.togglePane = this.togglePane.bind(this);
        this.secodLevelView = this.secodLevelView.bind(this);
        this.loadData = this.loadData.bind(this);
        this.refreshView = this.refreshView.bind(this);
    }

    componentDidMount() {
        this.loadData();

        //added to set Highlight selected tab - Suraj
        $('.drawer-content').on('click', 'li', function () {
            $('.drawer-content li').removeClass("adminHL");

            $(this).addClass("adminHL");
        });
    }

    componentDidUpdate(prevProps, prevState) {
        let _this = this;

        console.log('componentDidUpdate accordian.js', prevProps, this.props.data);

        if (prevProps.pageTitle != this.props.pageTitle) {
            setTimeout(function () {
                this.setState({ data: _this.props.data });
            }.bind(this), 1000);
        }

        if (_this.props.pageTitle == 'admin') {
            if (_this.state.data[5].isTabActive == true) {
                $('#adminMenuGroup').addClass('scrollFixAdmin');
            } else {
                $('#adminMenuGroup').removeClass('scrollFixAdmin');
            }
        }
    }

    secodLevelView(menuId, accordionOnly, catId, level) {
        $( '.panel' ).children('.panel-heading').removeClass('active');

        catId = catId ? catId : null; //Set null if undefined

        if (!accordionOnly) {
            this.props.updateView({
                menuId: menuId,
                catId: catId,
                refreshView: this.refreshView
            });
        }
    }

    togglePane(key) {
        $( '.panel' ).children('.panel-heading').removeClass('active');

        let { data } = this.state;
        let dataCopy = data;

        if (dataCopy[key].menuId === 'exportFileFormatCSV') {
            this.props.generateCsvForGrid();
        }

        if (dataCopy[key].menuId === 'activateItemsOnWebsite') {
            this.props.activateItemsOnWebsite();
        }

        if (dataCopy[key].menuId === 'exportFileForMagento') {
            this.props.exportFileForMagento();
        }

        // If - Toggle = true
        if (dataCopy[key].isToggle) {
            dataCopy.forEach((e, index) => {
                if (index != key) {
                    e.isCollapsed = false;
                    e.isTabActive = false;
                }
            });

            dataCopy[key].isCollapsed = !dataCopy[key].isCollapsed;
            dataCopy[key].isTabActive = !dataCopy[key].isTabActive;

            this.setState({ data: dataCopy });
        } else {
            let viewId;

            dataCopy.forEach((e, index) => {
                if (index != key) {
                    e.isTabActive = false;
                    e.isCollapsed = false;
                } else {
                    viewId = e.menuId;
                }
            });

            if (!dataCopy[key].accordionOnly) {
                this.props.updateView({ menuId: viewId, catId: null });
            }

            dataCopy[key].isTabActive = !dataCopy[key].isTabActive;

            this.setState({ data: dataCopy });
        }
    }

    refreshView(ref) {
        this.loadData(ref);
    }

    loadData(ref) {
        const data = this.state.data;
        var _this = this;

        let headers = {
            'Authorization': this.props.user.token,
            'content-type': 'application/json'
        };

        CategoryServices.productcategories().then( (response) => {
            console.log('right Drawer Accordion loadData API call  success data: ', response);
            let res = response;

            var category = [];

            for (var i = 0; i < res.length; i++) {
                if (res[i].catName.toLowerCase() === "department") {
                    category.push(res[i]);

                    break;
                }
            }

            data.map(function (o, i) {
                if (o.menuId === "product_category") {
                    o.content = category;
                }
            });

            _this.setState({ data: data });
        })
            .catch( error => {
                console.log('right Drawer Accordion loadData API call failed', error);
            });

        //For Workflow Allocation
        if (!ref) {
            let headers = {
                'Authorization': this.props.user.token,
                'content-type': 'application/json'
            };

            axios({
                method: 'get',
                url: require('../../common/configurations.js').category.getDepartmentCategory,
                headers: headers
            })
                .then( (response) => {
                    let res = response.data;

                    data.map(function (o, i) {
                        if (o.menuId === "workflow_allocation") {
                            o.content = res;
                        }
                    });

                    _this.setState({ data: data });
                })
                .catch( error => {
                    console.log('right Drawer Accordion loadData2 API call failed', error);
                });
        }
    }

    render() {
        const data = this.state.data;

        console.log('accordion data check: ', data, this.props);
        const disabled = {color: '#d7dcdd'};

        const items = data.map((item, index) =>
            <div className="panel" data-menu_id={item.menuId} key={index}>
                {(item.isToggle) ? (
                    <React.Fragment>
                        { item.disabled ?
                            <div className={`panel-heading ${(item.isTabActive) ? 'active' : ''}`}>
                                <h4><a style={disabled}>{item.title}</a></h4>
                                <span className="accordion-expand">
                                    <i style={{background: 'none'}} className={`${(item.isTabActive) ? 'icon-minus' : 'icon-plus'}`}></i>
                                </span>
                            </div>
                            :
                            <div className={`panel-heading ${(item.isTabActive) ? 'active' : ''}`} onClick={() => this.togglePane(index)}>
                                <h4><a>{item.title}</a></h4>
                                <span className="accordion-expand">
                                    <i className={`${(item.isTabActive) ? 'icon-minus' : 'icon-plus'}`}></i>
                                </span>
                            </div>
                        }
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        { item.disabled ?
                            <div className={`panel-heading ${(item.isTabActive) ? 'active' : ''}`}>
                                <h4 className={`${(item.menuId == 'activateItemsOnWebsite') ? 'activateItemsOnWebsite' : ''}`}><a style={disabled}>{item.title}</a></h4>
                            </div>

                            :

                            <div className={`panel-heading ${(item.isTabActive) ? 'active' : ''}`} onClick={() => this.togglePane(index)}>
                                <h4 className={`${(item.menuId == 'activateItemsOnWebsite') ? 'activateItemsOnWebsite' : ''}`}><a>{item.title}</a></h4>
                            </div>
                        }
                    </React.Fragment>
                )}
                {(item.isToggle && !item.disabled) &&
                <div className={`panel-content ${(item.isCollapsed) ? 'is-open' : ''}`} key={index}>
                    <SecondLevel
                        content={item.content}
                        secodLevelView={this.secodLevelView}
                        menuId={item.menuId}
                        accordionOnly={item.accordionOnly}
                    />
                </div>
                }
            </div>
        );

        return (<div>{items}</div>);
    }
}

function SecondLevel(props) {
    function listConstructor(list) {
        var createdList = list.map((item, index) => (
            <li key={index} onClick={() => props.secodLevelView(item.menuId, props.accordionOnly)}>
                <a>{item.title}</a>
            </li>
        ));

        return (
            <ul>{createdList}</ul>
        );
    }

    var content;

    if (typeof (props.content) === 'string') {
        content = props.content;
    } else if (typeof (props.content) === 'object' && props.content.length && props.content.length >= 0) {
        if (props.menuId === "product_category") {
            content = <CategoryTreeView {...props} />;
        } else if (props.menuId === "workflow_allocation") {
            content = <CategoryTreeView {...props} />;
        } else {
            content = listConstructor(props.content);
        }
    }

    return (
        <div>
            {content}
        </div>
    );
}

export default Accordion;
