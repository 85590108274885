import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Modal from '../modals/common-modal';
import ConfirmModal from '../modals/confirm-modal';
import AdminFooter from './helperComponents/publishing-footer';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import JqxGrid from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';
import GridDataBuilder from './helperComponents/data-builder';


const PublishingComponent = ({
    state,
    props,
    applyChanges,
    gridRerender,
    gridHeight,
    editSettings,
    gridData,
    publishingGridRef,

}) => {
    return (
        <div>
            <div className="pos-relative">
                {state.isLoaded &&
                    <div>
                        <div id="publishingGrid">
                            <JqxGrid ref={publishingGridRef}
                                width={'100%'} source={gridData.dataAdapter} pageable={false}
                                checkboxes={false} editable={false} hierarchicalCheckboxes={false}
                                columnsresize={true} columns={gridData.columns} sortable={true}
                                autoRowHeight={false} autoWidth={true} height={gridHeight}
                                showsortmenuitems={false} theme={'light'}
                                editSettings={editSettings} columnsreorder={true} columnsmenu={false} />
                        </div>

                        <AdminFooter isModified={state.isModified} applyChanges={applyChanges} gridRerender={gridRerender} />
                    </div>
                }

            </div>
        </div>
    );
};

export default PublishingComponent;
